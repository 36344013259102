import { connect } from 'react-redux'

import ShoppingCart from 'components/shopping_cart'
import { updateTabIndex, setCustomTagId } from 'actions/shopping_cart'
import {
  getShoppingCartTabIndex,
  getShoppingCartNewsCount,
  getShoppingCartUngroupedOnly,
  getAppLoading,
  getShoppingCartRunning,
  getExportOptions
} from 'selectors'

const mapStateToProps = state => ({
  tabIndex: getShoppingCartTabIndex(state),
  count: getShoppingCartNewsCount(state),
  ungroupedOnly: getShoppingCartUngroupedOnly(state),
  show: !getExportOptions(state).get('showExportDialog'),
  loading: getAppLoading(state) || getShoppingCartRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onTabChange: updateTabIndex,
    onCustomTagIdChange: setCustomTagId
  }
)(ShoppingCart)
