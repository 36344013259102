import React, { useState, useEffect } from 'react'
/* globals window */
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { fromJS, List, Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { Badge, ListItemIcon, ListItemText, MenuItem, Tooltip } from '@mui/material'
import { grey } from '@mui/material/colors'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PoolIcon from '@mui/icons-material/Pool'
import NotesIcon from '@mui/icons-material/Notes'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SendIcon from '@mui/icons-material/Send'
import InfoIcon from '@mui/icons-material/Info'

import { Capabilities } from 'static/constants'
import { listUniqueById } from 'utils/immutable'

import PublishConfirmDialog from 'containers/content_desk_new/contents/PublishConfirmDialog'
import ContentDelete from 'containers/content_desk_new/contents/content/ContentDelete'
import { IconButton, Menu } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

const SwitchToSearchMenuItem = CapSafe(MenuItem, Capabilities.HAS_NEWS_POOL_MODULE)

const useStyles = makeStyles()(() => ({
  menu: {
    zIndex: 10005 // needsto be higher than the zindex in Typeform
  },
  tooltip: {
    zIndex: 10006
  },
  actionIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 8
  },
  infoIcon: {
    color: grey[600]
  },
  actionName: {
    marginLeft: 12
  },
  resubmitContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))

const newEditorContentTypes = ['email', 'ots_press_release']

export default function ContentActions({
  content,
  contentDeskContentTypes,
  onEditClick,
  onCloneClick,
  onViewClick,
  onSwitchToSearchPoolClick,
  saveContentStart
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const scheduledForDate = content.get('scheduledFor')
  const campaignName = content.getIn(['ccdCampaign', 'name'])
  const status = content.get('status') || content.get('approvalStatus')
  const contentType = content.get('type').toLowerCase()
  const isOtsContent = contentType === 'ots_press_release'
  const otsPressRelease = content.get('otsPressRelease')
  const deletedExternally = content.get('deletedExternally')
  const showSearchPoolButton = (content.get('plainText') || '').length >= 1200
  const [editDisabled, setEditDisabled] = useState(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)
  const [deleteDisabled, setDeleteDisabled] = useState(false)
  const [publishConfDialogOpen, setPublishConfDialogOpen] = useState(false)

  const disableEdit = () => setEditDisabled(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)
  const disableDelete = () => setDeleteDisabled(moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 2)

  useEffect(() => {
    if (status === 'released') {
      setEditDisabled(true)
      setDeleteDisabled(false)

      return () => null
    }

    if (status === 'scheduled' && moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 15 && !editDisabled) {
      const intervalEdit = setInterval(() => disableEdit(), 5000)

      return () => {
        clearInterval(intervalEdit)
      }
    }

    if (status === 'scheduled' && moment.duration(moment(scheduledForDate).diff(moment())).minutes() < 15 && !deleteDisabled) {
      const intervalDelete = setInterval(() => disableDelete(), 5000)

      return () => {
        clearInterval(intervalDelete)
      }
    }

    setEditDisabled(false)
    setDeleteDisabled(false)

    return () => null
  }, [status])

  const handleNotes = () => onEditClick({ id: content.get('id'), openContentNotesDialog: true })
  const handleEdit = () => {
    if (newEditorContentTypes.includes(contentType)) {
      onEditClick({ id: content.get('id'), openEditContentDialog: true })
    } else {
      onEditClick({ id: content.get('id'), openOldEditContentDialog: true })
    }
  }
  const handleOtsEdit = () => {
    window.open('https://go.newsaktuell.de', '_blank', 'noopener,noreferrer')
  }
  const handleViewClick = () => onViewClick(content)

  const currentType = contentDeskContentTypes.find(c => c.get('id') === content.get('type').toLowerCase())

  const resetLinkedInSpecificFields = () => {
    let linkedInPost = content.get('linkedInPost').delete('createdPostId')

    const mediaFiles = content.get('ccdMediaFiles')

    // this could be an image post or an video post
    // field id needs to be reset to our internal id instead of the linkedIn image ID
    if (mediaFiles.size === 1) {
      linkedInPost = linkedInPost.setIn(['content', 'media', 'id'], String(mediaFiles.first().get('id')))
    }

    // for multi_image
    if (mediaFiles.size > 1) {
      let linkedInImages = mediaFiles.map(selected => (fromJS({
        altText: selected.get('filename'),
        id: String(selected.get('id'))
      })))

      linkedInImages = listUniqueById(linkedInImages)

      const mediaContent = fromJS({
        multiImage: {
          images: linkedInImages
        }
      })

      linkedInPost = linkedInPost.set('content', mediaContent)
    }

    return linkedInPost
  }

  const resetFacebookSpecificFields = () => {
    const facebookPost = content
      .get('facebookPost')
      .delete('createdPostId')
    const mediaAttachments = facebookPost
      .get('mediaAttachments')
      .map(attachment => attachment.delete('fbid'))

    return facebookPost.set('mediaAttachments', mediaAttachments)
  }

  const handleClone = () => {
    let linkedInPost
    let facebookPost

    if (content.get('type').toLowerCase() === 'linkedin') {
      linkedInPost = resetLinkedInSpecificFields()
    }

    if (content.get('type').toLowerCase() === 'facebook') {
      facebookPost = resetFacebookSpecificFields()
    }

    onCloneClick(
      content
        .set('ccdCampaign', fromJS({ value: content.getIn(['ccdCampaign', 'id']), label: campaignName }))
        .set('type', fromJS({ id: currentType.get('id'), value: currentType.get('id'), label: currentType.get('name') }))
        .set('id', null)
        .set('scheduledFor', null)
        .set('recipients', List())
        .set('linkedInPost', linkedInPost)
        .set('facebookPost', facebookPost)
        .set('title', `${content.get('title')} - duplicate(1)`)
        .set('scheduledJobName', null)
        .set('scheduledEventsJobName', null)
        .set('status', null)
    )

    saveContentStart({ openEditContentDialog: true })
  }

  const renderEditAction = () => {
    if (isOtsContent && otsPressRelease && status !== 'released') {
      return (
        <MenuItem
          onClick={handleOtsEdit}
          disabled={deletedExternally}
        >
          <ListItemIcon>
            <EditIcon className={classes.actionIcon} />
          </ListItemIcon>
          <ListItemText>
            {i18n.get('edit_in_na')}
          </ListItemText>
        </MenuItem>
      )
    }

    return (
      <MenuItem
        onClick={handleEdit}
        disabled={editDisabled}
      >
        <ListItemIcon>
          <EditIcon className={classes.actionIcon} />
        </ListItemIcon>
        <ListItemText>
          {i18n.get('edit')}
        </ListItemText>
      </MenuItem>
    )
  }

  const renderNoteAction = () => {
    const notes = content.get('notes')

    if (notes.isEmpty()) {
      return (
        <>
          <ListItemIcon>
            <NotesIcon className={classes.actionIcon} />
          </ListItemIcon>
          <ListItemText>
            {i18n.get('notes')}
          </ListItemText>
        </>
      )
    }

    return (
      <>
        <ListItemIcon>
          <Badge
            color="secondary"
            badgeContent={notes.size}
          >
            <NotesIcon className={classes.actionIcon} />
          </Badge>
        </ListItemIcon>
        <ListItemText>
          {i18n.get('notes')}
        </ListItemText>
      </>
    )
  }

  const renderResubmitOtsAction = () => {
    if (isOtsContent && otsPressRelease && status !== 'released') {
      return (
        <MenuItem
          onClick={() => setPublishConfDialogOpen(true)}
          disabled={deletedExternally}
        >
          <ListItemIcon>
            <SendIcon className={classes.actionIcon} />
          </ListItemIcon>
          <ListItemText>
            {i18n.get('resubmit_ots_content')}
          </ListItemText>
          <Tooltip
            classes={{ popper: classes.tooltip }}
            title={i18n.get('resubmit_ots_content_tooltip')}
            arrow
            placement="top"
          >
            <InfoIcon className={classes.infoIcon} />
          </Tooltip>
        </MenuItem>
      )
    }

    return null
  }

  return (
    <>
      <Menu
        className={classes.menu}
        handle={(
          <IconButton size="small">
            <MoreVertIcon />
          </IconButton>
        )}
      >
        <MenuItem onClick={handleViewClick}>
          <ListItemIcon>
            <RemoveRedEyeIcon className={classes.actionIcon} />
          </ListItemIcon>
          <ListItemText>
            {i18n.get('overview_and_kpis')}
          </ListItemText>
        </MenuItem>
        {renderEditAction()}
        {renderResubmitOtsAction()}
        <MenuItem onClick={handleClone}>
          <ListItemIcon>
            <ContentCopyIcon className={classes.actionIcon} />
          </ListItemIcon>
          <ListItemText>
            {i18n.get('duplicate')}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleNotes}>
          {renderNoteAction()}
        </MenuItem>
        {showSearchPoolButton && (
          <SwitchToSearchMenuItem
            onClick={() => onSwitchToSearchPoolClick({
              field: 'ccdContents',
              value: content
            })}
          >
            <ListItemIcon>
              <PoolIcon className={classes.actionIcon} />
            </ListItemIcon>
            <ListItemText>
              {i18n.get('view_earned_media')}
            </ListItemText>
          </SwitchToSearchMenuItem>
        )}
        <ContentDelete
          onClick={() => { /* to provoke menu close */ }}
          deleteDisabled={deleteDisabled}
          title={content.get('title')}
          deleteData={{
            id: content.get('id')
          }}
        />
      </Menu>
      <PublishConfirmDialog
        open={publishConfDialogOpen}
        content={content}
        status={status}
        onClose={() => setPublishConfDialogOpen(false)}
      />
    </>
  )
}

ContentActions.propTypes = {
  content: PropTypes.instanceOf(ImmutableMap).isRequired,
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,

  onCloneClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSwitchToSearchPoolClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired
}
