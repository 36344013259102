import { connect } from 'react-redux'

import AppBar from 'components/administration/dispatch_log/app_bar'

import { fetchDispatchLogStart } from 'actions/export'

import { getDispatchLogLoading } from 'selectors'

const mapStateToProps = state => ({
  loading: getDispatchLogLoading(state)
})

export default connect(
  mapStateToProps,
  {
    onClick: fetchDispatchLogStart
  }
)(AppBar)
