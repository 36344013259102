import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as SubscriptionsActions from 'actions/subscriptions'
import * as AppActions from 'actions/app'

const initialState = fromJS({
  selectedTopicIds: Set([])
})

export default handleActions({
  [SubscriptionsActions.setSubscriptions]: (state, { payload: { subscriptions, topics } }) => {
    const topTopicIds = Set(topics.filter(t => t.topnews).map(t => t.id))
    const selectableTopicIds = Set(topics.map(t => t.id))
    const topicIds = Set(fromJS(subscriptions)
      .map(s => s.get('codeIds')).flatten().filter(c => selectableTopicIds.has(c)))

    return state.set('selectedTopicIds', topicIds.union(topTopicIds))
  },
  [SubscriptionsActions.setTopics]: (state, { payload: topicIds }) => state.set('selectedTopicIds', Set(topicIds)),
  [SubscriptionsActions.updateTopicSelection]: (state, { payload: { topic, selected } }) => {
    if (selected) {
      return state.set('selectedTopicIds', state.get('selectedTopicIds').add(topic.get('id')))
    }

    return state.set('selectedTopicIds', state.get('selectedTopicIds').remove(topic.get('id')))
  },
  [AppActions.resetState]: () => initialState
}, initialState)
