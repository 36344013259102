import React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from 'containers/themed'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import { getDateString } from 'utils/date'

const useStyles = makeStyles()(theme => ({
  iconButton: {
    [theme.breakpoints.only('sm')]: {
      padding: 8
    }
  }
}))

export default function DateFilterButton({
  active,
  view,
  onClick,
  selectedDate
}) {
  const { classes } = useStyles()
  const device = useDevice()
  const i18n = useI18n()

  if (view === 'calendar') {
    return null
  }

  const renderDateButton = () => {
    let comp = (
      <div>

        <Button
          className={classes.dateButton}
          variant="contained"
          color="secondary"
          onClick={onClick}
          startIcon={<ScheduleIcon />}
        >
          <span>
            {getDateString(selectedDate.get('range'), selectedDate.get('from'), selectedDate.get('to'), i18n)}
          </span>
        </Button>
      </div>
    )

    if (device.get('lt-md')) {
      comp = (
        <IconButton
          classes={{ root: classes.iconButton }}
          color={active ? 'secondary' : 'inherit'}
          onClick={onClick}
          size="large"
        >
          <ScheduleIcon />
        </IconButton>
      )
    }

    return comp
  }

  return (<>{renderDateButton()}</>)
}

DateFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  selectedDate: PropTypes.instanceOf(Map)
}
