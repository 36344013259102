import { isImmutable, fromJS } from 'immutable'

export const verifyTheme = (themes, mode, colors) => {
  const immutableColors = isImmutable(colors) ? colors : fromJS(colors)
  let verified = null

  themes.forEach(theme => {
    if (immutableColors.equals(theme.getIn(['colors', mode]))) {
      verified = theme.get('name')
    }
  })

  return verified
}
