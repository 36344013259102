import { connect } from 'react-redux'

import Actions from 'components/media_reviews/media_review_detail/app_bar_actions'
import {
  showCustomTagDialog,
  showRemoveCustomTagDialog
} from 'actions/media_reviews'
import { showAiAnalysisDialog } from 'actions/ai'
import {
  getCapabilities
} from 'selectors'

const mapStateToProps = state => ({
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onCustomTagClick: showCustomTagDialog,
    onRemoveCustomTagClick: showRemoveCustomTagDialog,
    onAiAnalysisClick: showAiAnalysisDialog
  }
)(Actions)
