import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { ChartDataSources, ChartTypes } from 'static/constants'
import * as Actions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  firstLevel: 'timeline',
  secondLevel: null,
  thirdLevel: 'buzz',
  dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
  type: ChartTypes.HORIZONTAL_BAR,
  opts: {
    hideAxes: true,
    hideLabels: true,
    minBarHeight: 1
  }
})

export default handleActions({
  [Actions.fetchFilterTimelineStart]: state => state.set('loading', true),
  [Actions.fetchFilterTimelineError]: state => state.set('loading', false),
  [Actions.fetchFilterTimelineSuccess]: (state, { payload }) => state.merge({
    loading: false,
    data: fromJS(payload)
  }),

  [AppActions.resetState]: () => initialState
}, initialState)
