import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'

import TimeZones from 'static/time_zones'

import * as Actions from 'actions/saved_searches'
import * as FilterActions from 'actions/filter'
import * as AppActions from 'actions/app'

const defaultTimezone = 'Europe/Berlin'
/* istanbul ignore next */
let timezone = moment.tz.guess() || defaultTimezone

/* istanbul ignore next */
if (TimeZones.indexOf(timezone) === -1) {
  timezone = defaultTimezone
}

export const resetState = fromJS({
  selectedSavedSearch: {
    nameDe: '',
    nameEn: '',
    nameFr: '',
    nameRu: '',
    nameZh: '',
    nameJa: '',
    alertConfig: {
      type: 'none',
      threshold: 0,
      times: [],
      timezone,
      recipients: []
    }
  }
})

export const initialState = resetState.merge({
  executedSavedSearch: null
})

export default handleActions({
  [Actions.showEditSavedSearchDialog]: (state, action) => {
    if (action.payload) {
      return state.set('selectedSavedSearch', action.payload)
    }

    return state.merge(resetState)
  },

  [Actions.showSavedSearchDispatchesDialog]: (state, action) => state.set('selectedSavedSearch', action.payload),

  [Actions.updateSelectedSavedSearch]: (state, { payload: savedSearch }) => state.set('selectedSavedSearch', savedSearch),

  [Actions.showRemoveSavedSearchDialog]: (state, { payload: savedSearch }) => state.set('selectedSavedSearch', savedSearch),

  [Actions.setExecutedSavedSearch]: (state, { payload: savedSearch }) => state.set('executedSavedSearch', savedSearch),

  [Actions.saveSavedSearchSuccess]: (state, { payload: savedSearch }) => {
    if (state.get('executedSavedSearch') && savedSearch.id === state.getIn(['executedSavedSearch', 'id'])) {
      return state.set('executedSavedSearch', fromJS(savedSearch))
    }

    return state
  },

  [FilterActions.resetFilters]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)
