import { createAction } from 'redux-actions'

const defaultPrefix = 'UNSPLASH/'
let prefix = 'UNSPLASH/'

// DATA
prefix = `${defaultPrefix}DATA/`
export const searchUnsplashPhotosStart = createAction(`${prefix}SEARCH_UNSPLASH_PHOTOS_START`)
export const searchUnsplashPhotosSuccess = createAction(`${prefix}SEARCH_UNSPLASH_PHOTOS_SUCCESS`)
export const searchUnsplashPhotosError = createAction(`${prefix}SEARCH_UNSPLASH_PHOTOS_ERROR`)
export const addImageToLibraryStart = createAction(`${prefix}ADD_IMAGE_TO_LIBRARY_START`)
export const addImageToLibrarySuccess = createAction(`${prefix}ADD_IMAGE_TO_LIBRARY_SUCCESS`)
export const addImageToLibraryError = createAction(`${prefix}ADD_IMAGE_TO_LIBRARY_ERROR`)
export const fetchRandomUnsplashPhotosStart = createAction(`${prefix}FETCH_RANDOM_UNSPLASH_PHOTOS_START`)
export const setUnsplashPhotos = createAction(`${prefix}SET_UNSPLASH_PHOTOS`)
export const setUnsplashFormData = createAction(`${prefix}SET_UNSPLASH_FORM_DATA`)
export const resetUnsplashPhotos = createAction(`${prefix}RESET_UNSPLASH_PHOTOS`)

// UI
prefix = `${defaultPrefix}UI/`
export const toggleUnsplashComponent = createAction(`${prefix}TOGGLE_UNSPLASH_COMPONENT`)

// FILTER
prefix = `${defaultPrefix}FILTER/`
export const incrementPage = createAction(`${prefix}INCREMENT_PAGE`)
