import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

const lg = size => size
const md = size => (size > 6 ? 12 : 6)
const sm = size => (size > 6 ? 12 : 6)
const xs = _size => 12

export default function GridItem({ size, children }) {
  return (
    <Grid
      item
      lg={lg(size)}
      md={md(size)}
      sm={sm(size)}
      xs={xs(size)}
    >
      {children}
    </Grid>
  )
}

GridItem.defaultProps = {
  size: 4
}

GridItem.propTypes = {
  size: PropTypes.number,
  children: PropTypes.any
}
