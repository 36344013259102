import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import Label from '../../label'
import PlotlightsLabel from '../../plotlights_label'

const useStyles = makeStyles()({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  chart: {
    width: '100%',
    height: '100%',
    flex: 1
  }
})

export default function ChartWithLabel({
  children,
  label,
  topLabel,
  labelBackgroundColor,
  labelColor,
  topLabelColor,
  forcedTheme,
  classes: extraClasses
}) {
  const { classes, cx } = useStyles()
  const LabelComp = forcedTheme === 'plotlights' ? PlotlightsLabel : Label

  return (
    <div className={cx(classes.container, extraClasses.container)}>
      <LabelComp
        label={label}
        topLabel={topLabel}
        labelBackgroundColor={labelBackgroundColor}
        labelColor={labelColor}
        topLabelColor={topLabelColor}
        classes={classes}
      />

      <div className={cx(classes.chart, extraClasses.chart)}>
        {children}
      </div>
    </div>
  )
}

ChartWithLabel.defaultProps = {
  classes: {}
}

ChartWithLabel.propTypes = {
  children: PropTypes.any.isRequired,
  label: PropTypes.string,
  classes: PropTypes.object,
  topLabel: PropTypes.string,
  labelBackgroundColor: PropTypes.string,
  labelColor: PropTypes.string,
  topLabelColor: PropTypes.string,
  forcedTheme: PropTypes.string
}
