import { fromJS } from 'immutable'
import camelcase from 'camelcase'
import { ChartDataSources, ChartTypes } from './constants'

const metrics = ['impression_count', 'engagements', 'interactions']
const timelines = fromJS(metrics.map(metric => ({
  value: metric,
  chart: {
    firstLevel: 'timeline',
    secondLevel: null,
    thirdLevel: camelcase(metric),
    dataSource: ChartDataSources.TWITTER_ANALYTICS,
    type: ChartTypes.AREA
  }
})))
const kpis = fromJS(metrics.map(metric => ({
  value: `${metric}_total`,
  chart: {
    firstLevel: camelcase(metric),
    secondLevel: null,
    thirdLevel: null,
    dataSource: ChartDataSources.TWITTER_ANALYTICS,
    type: ChartTypes.RECTANGLE
  }
})))

const config = fromJS([
  {
    value: 'social_media_analytics_feed',
    instagram: false,
    chart: {
      firstLevel: 'socialMediaAnalyticsFeed',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.TWITTER_ANALYTICS,
      type: ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED
    }
  }
]).concat(timelines, kpis)

export default config

export const findValueByChart = chart => {
  const found = config.find(c => fromJS({
    firstLevel: chart.get('firstLevel'),
    secondLevel: chart.get('secondLevel'),
    thirdLevel: chart.get('thirdLevel'),
    dataSource: chart.get('dataSource'),
    type: chart.get('type')
  }).equals(c.get('chart')))

  if (found) {
    return found.get('value')
  }

  return null
}
