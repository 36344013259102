import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import SearchForm from 'containers/darknet/SearchForm'
import { Map } from 'immutable'
import { Drawer } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  drawer: {
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 500
    }
  }
}))

export default function DateDrawer({ device, open, onClose }) {
  const { classes, cx } = useStyles()
  const anchor = device.get('lt-lg') ? 'top' : 'right'

  return (
    <Drawer
      classes={{
        paper: cx(classes.drawer)
      }}
      anchor={anchor}
      open={open}
      onClose={() => onClose()}
    >
      <SearchForm />
    </Drawer>
  )
}

DateDrawer.propTypes = {
  device: PropTypes.instanceOf(Map),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
