import React from 'react'
import PropTypes from 'prop-types'

import { Tooltip, Badge, Icon } from '@mui/material'

import { IconButton } from 'containers/themed'
import useI18n from 'hooks/useI18n'

export default function Button({
  hasNotificationAlertsOn,
  hasNotifications,
  unreadCount,
  onOpen
}) {
  const i18n = useI18n()

  // If the list of notifications is empty, we don't render this feature. This way
  // we can decide in the backend who should see the notifications at all and we
  // just always return an empty list to everyone else.
  if (!hasNotifications) {
    return null
  }

  return (
    <Tooltip
      title={i18n.get('notifications')}
      placement="bottom"
    >
      <IconButton
        color="inherit"
        onClick={() => onOpen()}
        size="large"
      >
        {hasNotificationAlertsOn ? (
          <Badge
            badgeContent={unreadCount}
            color="error"
          >
            <Icon className="fas fa-bullhorn" />
          </Badge>
        ) : <Icon className="fas fa-bullhorn" />}
      </IconButton>
    </Tooltip>
  )
}

Button.propTypes = {
  hasNotificationAlertsOn: PropTypes.bool.isRequired,
  hasNotifications: PropTypes.bool.isRequired,
  unreadCount: PropTypes.number.isRequired,
  onOpen: PropTypes.func.isRequired
}
