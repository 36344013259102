import {
  put,
  takeEvery,
  all
} from 'redux-saga/effects'

import * as Actions from 'actions/themax'

export function* applyThemaxFilters() {
  yield put(Actions.themaxSearchRequestStart())
}

export function* uiInvertFilter({ payload }) {
  yield put(Actions.invertFilter(payload))
  yield put(Actions.themaxSearchRequestStart())
}

export function* uiRemoveFilter({ payload: { index, field } }) {
  yield put(Actions.removeFilter({ index, field }))
  yield put(Actions.themaxSearchRequestStart())
}

export function* uiUpdateFilter({ payload }) {
  yield put(Actions.updateFilter(payload))
  yield put(Actions.themaxSearchRequestStart())
}

export function* resetThemaxFilters() {
  yield put(Actions.themaxSearchRequestStart())
}

export function* watchApplyThemaxFilters() {
  yield takeEvery(Actions.applyThemaxFilters, applyThemaxFilters)
}

export function* watchUiInvertFilter() {
  yield takeEvery(Actions.uiInvertFilter, uiInvertFilter)
}

export function* watchUiRemoveFilter() {
  yield takeEvery(Actions.uiRemoveFilter, uiRemoveFilter)
}

export function* watchUiUpdateFilter() {
  yield takeEvery(Actions.uiUpdateFilter, uiUpdateFilter)
}

export function* watchResetThemaxFilters() {
  yield takeEvery(Actions.resetThemaxFilters, resetThemaxFilters)
}

export default function* filterSaga() {
  yield all([
    watchApplyThemaxFilters(),
    watchUiInvertFilter(),
    watchUiRemoveFilter(),
    watchUiUpdateFilter(),
    watchResetThemaxFilters()
  ])
}
