import { connect } from 'react-redux'

import ContentHeader from 'components/content_desk_new/content_header'

const mapStateToProps = _state => ({})

export default connect(
  mapStateToProps,
  {}
)(ContentHeader)
