import React from 'react'
import PropTypes from 'prop-types'

import useI18n from 'hooks/useI18n'

import CloseIcon from '@mui/icons-material/Close'
import red from '@mui/material/colors/red'
import grey from '@mui/material/colors/grey'

import { IconButton } from 'containers/themed'

const DeeplApiKeyCard = ({ deeplApiKey, loading, onDelete }) => {
  const i18n = useI18n()

  return (
    <div>
      {`API Key: ${deeplApiKey.get('key')}`}
      <IconButton
        onClick={() => onDelete(deeplApiKey.get('id'))}
        title={i18n.get('delete')}
        disabled={loading}
      >
        <CloseIcon style={{ color: loading ? grey[500] : red[500] }} />
      </IconButton>
    </div>
  )
}

DeeplApiKeyCard.propTypes = {
  deeplApiKey: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default DeeplApiKeyCard
