import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import selectedNews from './selected_news'
import snapshot from './snapshot'

export default combineReducers({
  ui,
  data,
  selectedNews,
  snapshot
})
