import { connect } from 'react-redux'

import Journalist from 'components/anewstip/results/journalists'

import {
  openDrillDownDialog,
  setDrillDownEntity,
  drillDownRequestStart as onDrillDownClick,
  setDrillDownFiltersForm
} from 'actions/anewstip'

const mapStateToProps = (_state, {
  journalist,
  drillDownEntity,
  drillDown,
  isLimitedFunctionality,
  isDistListsLocked,
  onAddToDistributionListClick
}) => ({
  journalist,
  drillDownEntity,
  drillDown,
  isLimitedFunctionality,
  isDistListsLocked,
  onAddToDistributionListClick
})

export default connect(
  mapStateToProps,
  {
    openDrillDownDialog,
    setDrillDownEntity,
    onDrillDownClick,
    setDrillDownFiltersForm
  }
)(Journalist)
