import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalizeIt } from 'utils/normalizr'

import * as CustomTagActions from 'actions/custom_tags'
import * as ShoppingCartActions from 'actions/shopping_cart'
import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  entities: {},
  result: []
})

export const sortCustomTags = (state, sortBy) => {
  const entities = state.get('entities')

  let sortPath = [sortBy]

  if (sortBy === 'lastNewsAssigned') {
    sortPath = ['lastNewsCustomTag', 'createdAt']
  }

  return state.update('result', result => {
    const newResult = result.sortBy(id => {
      const value = entities.get(id.toString()).getIn(sortPath)

      if (typeof value === 'string') {
        return value.toLowerCase()
      }

      return value
    })

    if (sortBy !== 'name') {
      return newResult.reverse()
    }

    return newResult
  })
}

export default handleActions({
  [CustomTagActions.setCustomTags]: (state, { payload: { customTags } }) => (
    state.merge(fromJS(normalizeIt({ customTags }, 'customTag')))
  ),
  [CustomTagActions.resetCustomTagNewNewsCount]: (state, { payload: customTagId }) => state
    .setIn(['entities', customTagId.toString(), 'newNewsCount'], 0),
  [CustomTagActions.saveCustomTagSuccess]: (state, { payload: { customTag, withNewsCount = false } }) => state
    .update('entities', entities => entities.update(customTag.id.toString(), c => {
      const newTag = fromJS(customTag)

      if (!withNewsCount && c) {
        return newTag.set('newsCount', c.get('newsCount'))
      }

      return newTag
    }))
    .update('result', result => {
      if (result.indexOf(customTag.id) === -1) {
        return result.push(customTag.id)
      }

      return result
    }),
  [CustomTagActions.deleteCustomTagSuccess]: (state, { payload: customTagId }) => state
    .update('entities', entities => entities.delete(customTagId.toString()))
    .update('result', result => result.filter(c => c !== customTagId)),
  [CustomTagActions.emptyCustomTagSuccess]: (state, { payload: customTag }) => state
    .setIn(['entities', String(customTag.get('id')), 'newsCount'], 0),
  [CustomTagActions.assignCustomTagToNewsSuccess]: (state, { payload: { customTag, news } }) => state
    .updateIn(['entities', String(customTag.get('id')), 'newsCount'], count => count + (news.get('clusterSize') || 1))
    .setIn(['entities', String(customTag.get('id')), 'lastNewsCustomTag'], fromJS({ createdAt: new Date() })),
  [CustomTagActions.removeCustomTagFromNewsSuccess]: (state, { payload: { customTag, removedCount } }) => {
    let newCustomTag = state.getIn(['entities', String(customTag.get('id'))]).update('newsCount', count => count - removedCount)

    if (!newCustomTag.get('newsCount')) {
      newCustomTag = newCustomTag.set('lastNewsCustomTag', null)
    }

    return state.setIn(['entities', String(customTag.get('id'))], newCustomTag)
  },

  [CustomTagActions.sortBy]: (state, { payload: sortBy }) => sortCustomTags(state, sortBy),

  [CustomTagActions.setCustomTagsViewType]: state => sortCustomTags(state, 'name'),

  [CustomTagActions.assignCustomTagToGroupSuccess]: (state, { payload: { groupId, result } }) => {
    const newCustomTag = state.getIn(['entities', String(result.customTagId)]).update('customTagGroupIds', ids => ids.push(groupId))

    return state.setIn(['entities', String(result.customTagId)], newCustomTag)
  },

  [CustomTagActions.removeCustomTagFromGroupSuccess]: (state, { payload: { groupId, _id, customTagId } }) => {
    const newCustomTag = state.getIn(['entities', String(customTagId)])
      .update('customTagGroupIds', ids => ids.filterNot(id => id === groupId))

    return state.setIn(['entities', String(customTagId)], newCustomTag)
  },

  [ShoppingCartActions.pinAllSuccess]: (state, { payload: { customTag, pinnedCount } }) => state
    .updateIn(['entities', String(customTag.get('id')), 'newsCount'], count => count + pinnedCount),

  [MediaReviewsActions.pinAllSuccess]: (state, { payload: { customTag, pinnedCount } }) => state
    .updateIn(['entities', String(customTag.get('id')), 'newsCount'], count => count + pinnedCount),

  [AppActions.resetState]: () => initialState
}, initialState)
