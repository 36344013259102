import React, { memo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import { List, fromJS } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { separate } from 'utils/number'

import { getTonalityColor } from 'utils/tonality'
import ChannelIcon from 'components/channel_icon'
import { ViewConfigCapSafe } from 'containers/CapSafe'
import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Checkbox, Icon, Grid, ListItem, ListItemText, Divider } from '@mui/material'
import Codes from './codes'
import Headline from './headline'

const useStyles = makeStyles()(theme => ({
  newsListItem: {
    borderLeft: `5px solid ${theme.nrx.paper.paperOnBackgroundColor}`,
    fontSize: '10pt'
  },
  itemContent: {
    width: '100%'
  },
  snippet: {
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    borderRadius: '10px',
    padding: '10px',
    margin: '10px',
    overflow: 'hidden',
    whiteSpace: 'normal'
  },
  divider: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
    backgroundColor: theme.nrx.paper.backgroundPaperColor
  },
  listItem: {
    padding: '0',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  listItemText: {
    fontSize: '10pt',
    margin: '0'
  },
  actions: {
    textAlign: 'end',
    paddingRight: 5
  },
  action: {
    padding: '2px'
  },
  text: {
    fontSize: '10pt',

    [theme.breakpoints.only('xs')]: {
      fontSize: '8pt'
    }
  },
  date: {
    color: theme.isDark ? theme.palette.common.white : theme.palette.grey[700],
    fontSize: '8pt',

    [theme.breakpoints.only('xs')]: {
      fontSize: '7pt'
    }
  },
  publication: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.only('xs')]: {
      fontSize: '8pt'
    },

    '& > i:first-of-type': {
      paddingTop: '0.5px'
    },

    '& > *:last-child': {
      marginLeft: '5px'
    }
  },
  meta: {
    display: 'flex',
    alignItems: 'center'
  },
  cluster: {
    fontWeight: '500',
    marginLeft: 3,
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8
    }
  },
  clusterIcon: {
    fontSize: '22px !important'
  },
  flag: {
    fontSize: '10pt',
    marginLeft: '0.5em'
  },
  publicationName: {
    wordBreak: 'break-word'
  },
  keyFigures: {
    color: theme.palette.grey[600],
    fontSize: '8pt',
    fontWeight: 300
  }
}))

const EditButton = ViewConfigCapSafe(IconButton, 'editing')

const areEqual = (prevProps, nextProps) => {
  if (!nextProps.news.equals(prevProps.news)) {
    return false
  }

  if (!nextProps.newsClusterIds.equals(prevProps.newsClusterIds)) {
    return false
  }

  if (nextProps.isMobile !== prevProps.isMobile) {
    return false
  }

  if (nextProps.showCodes !== prevProps.showCodes) {
    return false
  }

  if (nextProps.showSnippets !== prevProps.showSnippets) {
    return false
  }

  if (nextProps.selectedForSorting !== prevProps.selectedForSorting) {
    return false
  }

  if (nextProps.selected !== prevProps.selected) {
    return false
  }

  return true
}

const NewsListItem = ({
  codeId,
  isMobile,
  news,
  newsClusterIds,
  onEditClick,
  onRemove,
  onSelect,
  onShiftSortingSelect,
  onSortingSelect,
  onClusterClick,
  selected,
  selectedForSorting,
  showCodes,
  showSnippets
}) => {
  const { classes, cx } = useStyles()
  const i18n = useI18n()

  let date = moment(news.get('articleDate'))

  if (news.getIn(['flags', 'germanTime'])) {
    date = date.tz('Europe/Berlin')
  }

  const tonality = news.get('tonality')
  let tonalityStyle = {}

  if (tonality !== null) {
    tonalityStyle = {
      borderLeft: `5px solid ${getTonalityColor(tonality)}`
    }
  }

  const handleOnClick = event => {
    const item = fromJS([{
      codeId,
      newsId: news.get('id')
    }])

    const keyPress = {
      shiftKey: event.shiftKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey
    }

    if (keyPress.shiftKey) {
      onShiftSortingSelect({
        newsIds: item,
        isReplace: false,
        keyPress
      })
    } else {
      onSortingSelect({
        news: item,
        isReplace: !keyPress.ctrlKey && !keyPress.metaKey,
        keyPress
      })
    }
  }

  const getCountryIcon = () => {
    const shortName = news.getIn(['publication', 'country', 'shortName'])

    if (!shortName) {
      return null
    }

    return <span className={`fi fi-${shortName.toLowerCase()} ${classes.flag}`} />
  }

  const keyFigures = [
    {
      label: i18n.get('reach'),
      value: news.getIn(['keyFigures', 'reach'])
    },
    {
      label: i18n.get('interactions'),
      value: news.getIn(['keyFigures', 'interactions'])
    }
  ].filter(({ value }) => value !== null && value !== undefined).map(({ label, value }) => `${label}: ${separate(value)}`)

  return (
    <div
      className={classes.newsListItem}
      style={tonalityStyle}
    >
      <ListItem
        classes={{ root: classes.listItem }}
        onClick={handleOnClick}
        selected={selectedForSorting}
      >
        <ListItemText classes={{ root: classes.listItemText }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              classes={{ root: cx(classes.text, classes.meta) }}
              item
              xs={5.5}
              sm={3.5}
              md={3.5}
              lg={3.5}
              xl={3.5}
            >
              <Checkbox
                onChange={event => {
                  event.stopPropagation()

                  onSelect({
                    codeId,
                    newsIds: newsClusterIds,
                    omitIntersection: false
                  })
                }}
                checked={selected}
              />

              <div>
                <span className={classes.date}>{date.format('L')} | {date.format('LT')}</span>
                <div className={classes.publication}>
                  <ChannelIcon
                    circle={false}
                    id={news.getIn(['publication', 'channelId'])}
                  />
                  <span>
                    <span className={classes.publicationName}>
                      {news.getIn(['publication', 'name'])}
                    </span>

                    {newsClusterIds.size > 1 && (
                      <span
                        className={classes.cluster}
                        onClick={() => onClusterClick(newsClusterIds)}
                        title={i18n.get('reorder_cluster')}
                      >
                        +{newsClusterIds.size - 1}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid
              item
              xs={0.5}
              sm={0.5}
              md={0.5}
              lg={0.5}
              xl={0.5}
            >
              {getCountryIcon()}
            </Grid>

            <Grid
              classes={{ root: classes.text }}
              item
              xs={4}
              sm={6}
              md={6}
              lg={6}
              xl={6}
            >
              <Headline news={news} />

              {showCodes && <Codes news={news} />}

              <span className={classes.keyFigures}>
                {keyFigures.join(' | ')}
              </span>
            </Grid>

            <Grid
              classes={{ root: classes.actions }}
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
            >
              {!isMobile && newsClusterIds.size > 1 && (
                <IconButton
                  classes={{ root: classes.action }}
                  onClick={() => onClusterClick(newsClusterIds)}
                  title={i18n.get('reorder_cluster')}
                  size="large"
                >
                  <Icon className={`fad fa-layer-group ${classes.clusterIcon}`} />
                </IconButton>
              )}

              {!isMobile && (
                <EditButton
                  classes={{ root: classes.action }}
                  onClick={() => onEditClick(news)}
                >
                  <EditIcon />
                </EditButton>
              )}

              {!!onRemove && (
                <IconButton
                  classes={{ root: classes.action }}
                  onClick={() => onRemove({
                    newsIds: [news.get('id')],
                    codeIds: codeId ? [codeId] : undefined
                  })}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>

      {showSnippets && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            <div
              className={classes.snippet}
              dangerouslySetInnerHTML={{
                __html: news.get('snippet')
              }}
            />
          </Grid>
        </Grid>
      )}

      <Divider classes={{ root: classes.divider }} />
    </div>
  )
}

NewsListItem.defaultProps = {
  showCodes: true
}

NewsListItem.propTypes = {
  news: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  showCodes: PropTypes.bool,
  showSnippets: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  newsClusterIds: PropTypes.instanceOf(List).isRequired,
  codeId: PropTypes.number,
  selectedForSorting: PropTypes.bool,

  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSortingSelect: PropTypes.func.isRequired,
  onShiftSortingSelect: PropTypes.func.isRequired,
  onClusterClick: PropTypes.func.isRequired
}

export default memo(NewsListItem, areEqual)
