import { connect } from 'react-redux'

import Chart from 'components/charts/chart'
import { navigate } from 'actions/navigation'
import { getI18n, getColors, getCurrency, getDevice, getDarkMode, getWhitelabelTheme, getWidth } from 'selectors'

const mapStateToProps = (state, { chart, data, colors, darkMode }) => ({
  i18n: getI18n(state),
  colors: colors || getColors(state),
  chart,
  currency: getCurrency(state),
  data,
  device: getDevice(state),
  deviceWidth: getWidth(state),
  darkMode: darkMode || getDarkMode(state),
  whitelabelTheme: getWhitelabelTheme(state)
})

export default connect(
  mapStateToProps,
  {
    navigate
  }
)(Chart)
