import { connect } from 'react-redux'

import DateFilterButton from 'components/news_page/date_filter_button'

import { getDevice, getWhitelabelTheme } from 'selectors'

const mapStateToProps = state => ({
  device: getDevice(state),
  whitelabelTheme: getWhitelabelTheme(state)
})

export default connect(mapStateToProps, {})(DateFilterButton)
