import { put, call, takeEvery, all, select } from 'redux-saga/effects'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/notifications'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* markAsReadStart({ payload: notificationIds }) {
  const body = {
    groot_notification_ids: notificationIds
  }

  try {
    const result = yield call(Api.markNotificationAsRead, body)

    yield put(Actions.updateNotifications(result))
    yield put(Actions.markAsReadSuccess())
  } catch (error) {
    yield put(Actions.markAsReadError())
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* fetchNotificationsStart() {
  const filters = yield select(Selectors.getNotificationsFilter)

  const page = filters.get('page') + 1
  const limit = filters.get('limit')
  const sortBy = filters.get('sortBy')
  const sortOrder = filters.get('sortOrder')

  try {
    const { hasMoreResults, notificationsList } = yield call(Api.fetchNotifications, page, limit, sortBy, sortOrder)

    yield put(Actions.setNotificationsPage(page))

    if (!hasMoreResults) {
      yield put(Actions.setLastPage())
    }

    yield put(Actions.fetchNotificationsSuccess(notificationsList))
  } catch (error) {
    yield put(Actions.fetchNotificationsError())
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchMarkNotificationAsRead() {
  yield takeEvery(Actions.markAsReadStart, markAsReadStart)
}

export function* watchFetchNotifications() {
  yield takeEvery(Actions.fetchNotificationsStart, fetchNotificationsStart)
}

export default function* notificationSaga() {
  yield all([
    watchMarkNotificationAsRead(),
    watchFetchNotifications()
  ])
}
