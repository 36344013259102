import { connect } from 'react-redux'

import DistributionListViewDialog from 'components/contact_management/distribution_list_view_dialog/DistributionListViewDialog'

import {
  getCmSelectedContact,
  getCmDistributionListViewDialogOpen
} from 'selectors'

import {
  closeDistributionListViewDialog as onClose
} from 'actions/contact_management'

const mapStateToProps = state => ({
  contact: getCmSelectedContact(state),
  open: getCmDistributionListViewDialogOpen(state)
})

export default connect(mapStateToProps, {
  onClose
})(DistributionListViewDialog)
