import { connect } from 'react-redux'

import Actions from 'components/anewstip/actions'

const mapStateToProps = _state => ({
})

export default connect(
  mapStateToProps,
  {
  }
)(Actions)
