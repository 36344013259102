import { connect } from 'react-redux'

import { navigate } from 'actions/navigation'
import { getDevice } from 'selectors'

import Actions from 'components/darknet/dashboard/actions'

const mapStateToProps = state => ({
  device: getDevice(state)
})

const mapDispatchToProps = dispatch => ({
  onQueryManagerClick: () => dispatch(navigate('query_manager'))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Actions)
