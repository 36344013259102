import { connect } from 'react-redux'

import RecipientsTable from 'components/content_desk/contents/content_view/recipients_table'

import { downloadEmailEventsStart } from 'actions/content_desk'

import { getCDeskDownloadEmailEventsLoading } from 'selectors/content_desk'

const mapStateToProps = (state, { data }) => ({
  data,
  loading: getCDeskDownloadEmailEventsLoading(state)
})

export default connect(
  mapStateToProps,
  {
    onEventsDownload: downloadEmailEventsStart
  }
)(RecipientsTable)
