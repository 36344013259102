import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Grid, ListItem } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  summary: {
    padding: '1rem 2rem',
    lineHeight: '1.7rem',
    fontSize: '0.9rem',
    whiteSpace: 'pre-line'
  },
  summaryHeadline: {
    fontWeight: 500
  },
  summaryContainer: {
    paddingTop: '0 !important'
  }
}))

export default function Summary({ news, className }) {
  const { classes } = useStyles()

  return (
    <ListItem
      className={className}
      disableGutters
    >
      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={10}
          xl={10}
        >
          <Grid
            container
            direction="row"
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.summaryContainer}
            >
              <div className={classes.summary}>
                {news.get('summaryHeadline') && (
                  <div
                    className={`${classes.summaryHeadline}`}
                    dangerouslySetInnerHTML={{ __html: news.get('summaryHeadline') }}
                  />
                )}
                <div
                  className={`${classes.text}`}
                  dangerouslySetInnerHTML={{ __html: news.get('summary') }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
        />
      </Grid>
    </ListItem>
  )
}

Summary.propTypes = {
  news: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string
}
