function isFloat(value) {
  if (
    typeof value === 'number'
    && !Number.isNaN(value)
    && !Number.isInteger(value)
  ) {
    return true
  }

  return false
}

export const calcRate = (x, y) => {
  if (y === 0 || (x === 0 && y !== 0)) {
    return `${0}%`
  }

  const result = (x / y) * 100

  return isFloat(result) ? `${result.toFixed(2)}%` : `${result}%`
}
