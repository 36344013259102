import { fromJS, Map, List } from 'immutable'
import moment from 'moment'

import { ChartTypes } from 'static/constants'

export const DarknetCharts = {
  LANGUAGES: 'languagesChart',
  MENTIONS_TIMELINE: 'mentionsTimelineChart',
  MENTIONS_TOTAL: 'mentionsChart',
  HACKISHNESS_TOTAL: 'avgHackishnessChart',
  HACKISHNESS_TIMELINE: 'avgHackishnessTimelineChart',
  HACKISHNESS_NETWORKS: 'avgHackishnessNetworksChart',
  NETWORKS: 'networksChart',
  NETWORKS_TIMELINE: 'networksTimelineChart',
  SCORE_RESULT_TIMELINE: 'scoreResultTimelineChart',
  SEARCH_QUERIES_TIMELINE: 'searchQueriesTimelineChart',
  EXTERNAL_SCORE_CHART: 'externalScoreChart'
}

export const byChartId = (chart, id) => chart.get('id') === id
export const getChartById = (charts, id) => charts.find(c => byChartId(c, id)) || Map()
export const getChartsById = (charts, id) => charts.filter(c => byChartId(c, id)) || List()
export const addStylesToChart = (chart, layout = Map(), opts = Map()) => chart
  .updateIn(['layout'], (c = Map()) => c.merge(layout))
  .updateIn(['opts'], (c = Map()) => c.merge(opts))
export const docCountSum = chart => chart.map(c => c.docCount).reduce((acc, value) => acc + value, 0)
export const oneDayRange = dateAsString => ({
  from: moment(dateAsString).toISOString(),
  to: moment(dateAsString).add(1, 'days').subtract(1, 'seconds').toISOString()
})
export const sortByDocCount = data => data.sort((a, b) => {
  if (b.docCount > a.docCount) {
    return 1
  }

  if (a.docCount > b.docCount) {
    return -1
  }

  return 0
})

export const darknetChartsConfigs = fromJS({
  languagesChart: {
    id: DarknetCharts.LANGUAGES,
    type: ChartTypes.PIE,
    label: 'languages',
    firstLevel: 'languages'
  },
  mentionsTimelineChart: {
    id: DarknetCharts.MENTIONS_TIMELINE,
    type: ChartTypes.LINE,
    label: 'mentions_over_time',
    firstLevel: 'timeline',
    secondLevel: 'mentions'
  },
  mentionsChart: {
    id: DarknetCharts.MENTIONS_TOTAL,
    type: ChartTypes.RECTANGLE,
    label: 'mentions',
    firstLevel: 'buzz',
    opts: {}
  },
  avgHackishnessChart: {
    id: DarknetCharts.HACKISHNESS_TOTAL,
    type: ChartTypes.RECTANGLE,
    label: 'avg_hackishness',
    firstLevel: 'hackishness',
    opts: {}
  },
  avgHackishnessTimelineChart: {
    id: DarknetCharts.HACKISHNESS_TIMELINE,
    type: ChartTypes.LINE,
    label: 'hackishness_over_time',
    secondLevel: 'hackishness',
    firstLevel: 'timeline'
  },
  avgHackishnessNetworksChart: {
    id: DarknetCharts.HACKISHNESS_NETWORKS,
    type: ChartTypes.BAR,
    label: 'hackishness_per_network',
    firstLevel: 'channels'
  },
  networksChart: {
    id: DarknetCharts.NETWORKS,
    type: ChartTypes.PIE,
    label: 'mentions_per_network',
    firstLevel: 'channels'
  },
  networksTimelineChart: {
    id: DarknetCharts.NETWORKS_TIMELINE,
    type: ChartTypes.LINE,
    label: 'mentions_per_network_over_time',
    firstLevel: 'timeline',
    secondLevel: 'networks'
  },
  scoreResultTimelineChart: {
    id: DarknetCharts.SCORE_RESULT_TIMELINE,
    type: ChartTypes.LINE,
    label: 'score_result_over_time',
    firstLevel: 'timeline',
    secondLevel: 'queries'
  },
  searchQueriesTimelineChart: {
    id: DarknetCharts.SEARCH_QUERIES_TIMELINE,
    type: ChartTypes.LINE,
    label: 'mentions_per_search_query_over_time',
    firstLevel: 'timeline',
    secondLevel: 'searchQueries'
  },
  externalScoreChart: {
    id: DarknetCharts.EXTERNAL_SCORE_CHART,
    type: ChartTypes.LINE,
    label: 'score_result_over_time',
    firstLevel: 'timeline',
    secondLevel: 'queries',
    opts: {
      disableAnimations: false,
      addPercentSign: false,
      showStatementTonality: false,
      showSentiment: false,
      zoomable: true,
      forcedTheme: 'plotlights',
      darkMode: true,
      axesLabelColor: '#ffffff',
      axesStrokeColor: '#789789',
      labelBackgroundColor: '#143D52',
      labelColor: '#ffffff',
      topLabelColor: '#ffffff',
      backgroundColor: '#143D52'
    }
  }
})

/* eslint-disable max-len, no-useless-escape */
const domainValidationRegex = /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/g
const emailValidationRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
/* eslint-enable max-len, no-useless-escape */

export const validDomain = domain => (!!domain.match(domainValidationRegex))
export const validEmail = email => (!!email.match(emailValidationRegex))

export const addTypeToDomains = (list, type) => (
  list.map(domain => {
    if (validDomain(domain)) {
      return { domain, type }
    }

    throw new Error('Invalid Domain')
  })
)
