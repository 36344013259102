import { connect } from 'react-redux'

import Actions from 'components/email_templates_library/actions'

import {
  openEditorDialog as openDialog,
  togglePublicTemplates
} from 'actions/email_templates_library'

import {
  getETLibraryShowPublic,
  getCapabilities,
  getETLibraryFetchRequestRunning,
  getETLibraryDeleteRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  isPublicOnly: getETLibraryShowPublic(state),
  capabilities: getCapabilities(state),
  loading: getETLibraryFetchRequestRunning(state) || getETLibraryDeleteRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    openDialog,
    togglePublicTemplates
  }
)(Actions)
