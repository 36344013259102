import { connect } from 'react-redux'

import Sidebar from 'components/themax/calendar/sidebar'

import {
  setSelectedThemaxFilters,
  applyThemaxFilters,
  setThemaxSort
} from 'actions/themax'

import { getThemaxFilters } from 'selectors'

const mapStateToProps = state => ({
  filters: getThemaxFilters(state)
})

export default connect(
  mapStateToProps,
  {
    setFilters: setSelectedThemaxFilters,
    applyFilters: applyThemaxFilters,
    setSorting: setThemaxSort
  }
)(Sidebar)
