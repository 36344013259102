import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  Grid,
  List,
  ListItem
} from '@mui/material'

import { blue, blueGrey } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import CampaignName from 'containers/content_desk_new/campaigns/campaign/CampaignName'
import CampaignStatus from 'containers/content_desk_new/campaigns/campaign/CampaignStatus'
import CampaignProgress from 'containers/content_desk_new/campaigns/campaign/CampaignProgress'
import ContentTitle from 'containers/content_desk_new/contents/content/ContentTitle'

const useStyles = makeStyles()({
  summaryHeader: {
    width: '95%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsContainer: {
    marginTop: 10,
    fontSize: 12
  },
  label: {
    fontWeight: 600
  },
  active: {
    borderLeft: '5px solid',
    borderColor: blue[500]
  },
  inactive: {
    borderLeft: '5px solid',
    borderColor: blueGrey[500]
  }
})

const Campaign = ({
  campaign
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()
  const campaignActive = campaign.get('active') && !campaign.get('defaultAssigned')
  const maxContentTitleLength = 38

  let maxLength = 16

  if (device.get('sm') || device.get('gt-sm')) {
    maxLength = 50
  }

  let assignedUserName = ''

  if (campaign.get('assignedUser')) {
    assignedUserName = `${campaign.getIn(['assignedUser', 'firstName'])} ${campaign.getIn(['assignedUser', 'lastName'])}`
  }

  const renderContent = content => (
    <>
      <ListItem key={content.get('id')}>
        <ContentTitle
          content={content}
          maxLength={maxContentTitleLength}
        />
      </ListItem>
      <Divider />
    </>
  )

  return (
    <Accordion
      className={campaignActive ? classes.active : classes.inactive}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Box className={classes.summaryHeader}>
          <CampaignName
            campaign={campaign}
            maxLength={maxLength}
          />
          <Chip
            size="small"
            label={campaign.get('ccdContentsCount')}
            color="primary"
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails
        className={classes.detailsContainer}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('duration')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            <CampaignProgress campaign={campaign} />
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('status')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            <CampaignStatus
              campaign={campaign}
            />
          </Grid>

          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('assigned_user')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            {assignedUserName}
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.label}
          >
            {i18n.get('contents')}:
          </Grid>
          <Grid
            item
            xs={12}
          >
            <List dense>
              {campaign.get('ccdContents').map(content => (
                renderContent(content)
              ))}
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

Campaign.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired
}

export default Campaign
