import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { replace } from 'redux-first-history'

import { getUser, getCapabilities } from 'selectors'
import { Capabilities } from 'static/constants'
import { getFirstValidRoute } from 'utils/routing'
import { createLoadable } from 'utils/loadable'
import LinkedInPopUp from 'containers/administration/external_accounts/LinkedInPopup'

import NewsView from 'containers/NewsView'

import CustomTags from 'containers/custom_tags/CustomTags'
import Dashboard from 'containers/dashboard/Dashboard'
import MailingLists from 'containers/administration/mailing_lists/MailingLists'
import ExternalAccounts from 'containers/administration/ExternalAccounts'
import MediaReviewDetail from 'containers/media_reviews/media_review_detail/MediaReviewDetail'
import MediaReviews from 'containers/media_reviews/MediaReviews'
import NewsPage from 'containers/news_page/NewsPage'
import PersonalSettings from 'containers/personal_settings/PersonalSettings'
import SavedSearches from 'containers/saved_searches/SavedSearches'
import Settings from 'containers/administration/Settings'
import ShoppingCart from 'containers/shopping_cart/ShoppingCart'
import Subscriptions from 'containers/subscriptions'
import NewsRadar from 'containers/NewsRadar'
import DarknetDashboard from 'containers/darknet/Dashboard'
import DarknetQueryManager from 'containers/darknet/QueryManager'
import ExternalChart from 'containers/darknet/ExternalChart'
import ContentDesk from 'containers/content_desk_new/ContentDesk'
import ContentsCampaignsShowAll from 'containers/content_desk_new/contents_campaigns_show_all/ContentsCampaignsShowAll'
import ContactManagement from 'containers/contact_management/ContactManagement'
import Anewstip from 'containers/anewstip/Anewstip'
import ContentDeskSettings from 'containers/administration/ContentDeskSettings'
import EmailTemplatesLibrary from 'containers/email_templates_library/EmailTemplatesLibrary'
import SavedCharts from 'containers/administration/SavedCharts'
import DispatchLog from 'containers/administration/DispatchLog'
import Themax from 'containers/themax/Themax'
import ThemaxSavedSearches from 'containers/themax/SavedSearches'

const App = createLoadable(() => import(/* webpackChunkName: "App" */ 'containers/App'))
const Testing = createLoadable(() => import(/* webpackChunkName: "Testing" */ 'components/testing'))
const UserActivation = createLoadable(() => import(/* webpackChunkName: "UserActivation" */ 'containers/login/UserActivation'))
const Login = createLoadable(() => import(/* webpackChunkName: "Login" */ 'containers/Login'))
const ForgotPassword = createLoadable(() => import(/* webpackChunkName: "ForgotPassword" */ 'containers/login/ForgotPassword'))
const ResetPassword = createLoadable(() => import(/* webpackChunkName: "ResetPassword" */ 'containers/login/ResetPassword'))
const Fail = createLoadable(() => import(/* webpackChunkName: "Fail" */ 'containers/Fail'))
const DirectLogin = createLoadable(() => import(/* webpackChunkName: "DirectLogin" */ 'containers/login/DirectLogin'))
const Register = createLoadable(() => import(/* webpackChunkName: "Register" */ 'containers/login/Register'))
const SavedDashboard = createLoadable(() => import(/* webpackChunkName: "SavedDashboard" */ 'containers/saved_dashboard/SavedDashboard'))
const SavedChart = createLoadable(() => import(/* webpackChunkName: "SavedDashboard" */ 'containers/saved_chart/SavedChart'))

const userIsAuthenticated = Comp => {
  const WrapperComponent = props => {
    const user = useSelector(getUser)

    if (user.get('isAuthenticated')) {
      return <Comp {...props} />
    }

    return <Login />
  }

  WrapperComponent.displayName = `UserIsAuthenticated(${Comp.name || Comp.displayName})`

  return WrapperComponent
}

const createCapSafeWrapper = (capName, inverse = false) => Comp => {
  const WrapperComponent = props => {
    const capabilities = useSelector(getCapabilities)
    const dispatch = useDispatch()

    let hasCap = capabilities.get(capName)

    if (inverse) {
      hasCap = !capabilities.get(capName, false)
    }

    const firstValidRoute = getFirstValidRoute(capabilities)

    useEffect(() => {
      if (!hasCap) {
        dispatch(replace(firstValidRoute))
      }
    }, [hasCap])

    if (hasCap) {
      return <Comp {...props} />
    }

    return null
  }

  WrapperComponent.displayName = `UserHasCap(${Comp.name || Comp.displayName})`

  return WrapperComponent
}

const userHasGlobalSettings = createCapSafeWrapper(Capabilities.HAS_GLOBAL_SETTINGS)
const userHasMailingList = createCapSafeWrapper(Capabilities.HAS_MAILING_LISTS)
const userHasExternalAccounts = createCapSafeWrapper(Capabilities.HAS_EXTERNAL_ACCOUNT_SETTINGS)
const userHasSubscriptionsModule = createCapSafeWrapper(Capabilities.HAS_SUBSCRIPTIONS_MODULE)
const userHasMediaReviewModule = createCapSafeWrapper(Capabilities.HAS_MEDIA_REVIEW_MODULE)
const userHasPinnboardsModule = createCapSafeWrapper(Capabilities.HAS_CUSTOM_TAGS_MODULE)
const userHasProfileMonitoringModule = createCapSafeWrapper(Capabilities.HAS_PROFILE_MONITORING_MODULE)
const userHasNewsPoolModule = createCapSafeWrapper(Capabilities.HAS_NEWS_POOL_MODULE)
const userHasNewsModule = createCapSafeWrapper(Capabilities.HAS_NEWS_MODULE)
const userHasAnalysisModule = createCapSafeWrapper(Capabilities.HAS_ANALYSIS_MODULE)
const userHasDashboardModule = createCapSafeWrapper(Capabilities.HAS_DASHBOARD_MODULE)
const userHasDarknetDashboard = createCapSafeWrapper(Capabilities.HAS_DARKNET_DASHBOARD)
const userHasDarknetQueryManager = createCapSafeWrapper(Capabilities.HAS_DARKNET_QUERY_MANAGER)
const userHasDisabledSettings = createCapSafeWrapper(Capabilities.HAS_DISABLED_USER_SETTINGS, true)
const userHasContentDeskModule = createCapSafeWrapper(Capabilities.HAS_CONTENT_DESK_MODULE)
const userHasContactManagementModule = createCapSafeWrapper(Capabilities.HAS_CONTACTS_MANAGEMENT_MODULE)
const userHasAnewstipModule = createCapSafeWrapper(Capabilities.HAS_CONTACTS_MANAGEMENT_ANEWSTIP)
const userHasSavedChartsModule = createCapSafeWrapper(Capabilities.HAS_SAVED_CHARTS)
const userHasDispatchLogModule = createCapSafeWrapper(Capabilities.HAS_DISPATCH_LOG)
const userHasThemaxModule = createCapSafeWrapper(Capabilities.HAS_THE_MAX_MODULE)

const buildDefaultNewsViewRoutes = (parentPath, view, authFunc) => [
  {
    path: `/app/${parentPath}`,
    exact: true,
    component: authFunc(NewsView(NewsPage, view))
  },
  {
    path: `/app/${parentPath}/saved_searches`,
    exact: true,
    component: NewsView(SavedSearches, view)
  }
]

// Need NewsView on all routes to prevent reloads due to config changes
const buildNewsViewRoutes = (parentPath, view, authFunc) => buildDefaultNewsViewRoutes(parentPath, view, authFunc).concat([
  {
    path: `/app/${parentPath}/shopping_cart`,
    component: NewsView(ShoppingCart, view)
  },
  {
    path: `/app/${parentPath}/pin_boards`,
    exact: true,
    component: NewsView(userHasPinnboardsModule(CustomTags), view)
  },
  {
    path: `/app/${parentPath}/pin_boards/shopping_cart`,
    exact: true,
    component: NewsView(ShoppingCart, view)
  },
  {
    path: `/app/${parentPath}/pin_boards/:custom_tag_id/shopping_cart`,
    exact: true,
    component: NewsView(ShoppingCart, view)
  }
])

const mapRoutes = routes => routes.map(route => {
  const newRoute = { ...route }

  if (route.component) {
    const Comp = route.component
    newRoute.element = <Comp />
  }

  if (route.children) {
    newRoute.children = mapRoutes(route.children)
  }

  return newRoute
})

export default mapRoutes([
  {
    path: '/',
    component: NewsRadar,
    children: [
      {
        index: true,
        component: Login
      },
      {
        path: '/register',
        component: Register
      },
      {
        path: '/forgot_password',
        component: ForgotPassword
      },
      {
        path: '/linked_in',
        component: LinkedInPopUp
      },
      {
        path: '/reset_password/:access_token',
        component: ResetPassword
      },
      {
        path: '/direct_login/:secret',
        component: DirectLogin
      },
      {
        path: '/infoboard/:urlName',
        component: SavedDashboard
      },
      {
        path: '/charts/:id/embed',
        component: SavedChart
      },
      {
        path: 'testing',
        element: <Testing />
      },
      {
        path: '/app',
        component: userIsAuthenticated(App),
        children: [
          ...buildNewsViewRoutes('news', 'earned', userHasNewsModule),
          ...buildNewsViewRoutes('profile_monitoring', 'profileMonitoring', userHasProfileMonitoringModule),
          ...buildNewsViewRoutes('search_pool', 'searchPool', userHasNewsPoolModule),
          ...buildNewsViewRoutes('media_monitoring', 'searchPool', userHasNewsPoolModule),
          ...buildNewsViewRoutes('analysis', 'analysis', userHasAnalysisModule),
          {
            path: '/app/administration/settings',
            component: userHasGlobalSettings(Settings)
          },
          {
            path: '/app/administration/mailing_lists',
            component: userHasMailingList(MailingLists)
          },
          {
            path: '/app/administration/external_accounts',
            component: userHasExternalAccounts(ExternalAccounts)
          },
          {
            path: '/app/administration/content_desk_settings',
            component: userHasContentDeskModule(ContentDeskSettings)
          },
          {
            path: '/app/administration/saved_charts',
            component: userHasSavedChartsModule(SavedCharts)
          },
          {
            path: '/app/administration/dispatch_log',
            component: userHasDispatchLogModule(DispatchLog)
          },
          {
            path: '/app/personal_settings',
            component: userHasDisabledSettings(PersonalSettings)
          },
          {
            path: '/app/subscriptions',
            component: userHasSubscriptionsModule(Subscriptions)
          },
          {
            path: '/app/media_reviews',
            component: userHasMediaReviewModule(MediaReviews)
          },
          {
            path: '/app/media_reviews/:id',
            component: MediaReviewDetail
          },
          {
            path: '/app/fail',
            component: Fail
          },
          {
            path: '/app/dashboard/:id',
            component: userHasDashboardModule(Dashboard)
          },
          {
            path: '/app/dashboard',
            component: userHasDashboardModule(Dashboard)
          },
          {
            path: '/app/darknet/dashboard',
            component: userHasDarknetDashboard(DarknetDashboard)
          },
          {
            path: '/app/darknet/dashboard/query_manager',
            component: userHasDarknetQueryManager(DarknetQueryManager)
          },
          // {
          //   path: '/app/darknet/administration',
          //   component: userHasDarknetDashboard(DarknetAdministration)
          // },
          {
            path: '/app/content_desk/content_management',
            exact: true,
            component: userHasContentDeskModule(ContentDesk)
          },
          {
            path: '/app/content_desk/content_management/contents_and_campaigns',
            exact: true,
            component: userHasContentDeskModule(ContentsCampaignsShowAll)
          },
          {
            path: '/app/content_desk/template_library',
            exact: true,
            component: userHasContentDeskModule(EmailTemplatesLibrary)
          },
          {
            path: '/app/contact_management/my_contacts',
            exact: true,
            component: userHasContactManagementModule(ContactManagement)
          },
          {
            path: '/app/contact_management/journalist_search',
            exact: true,
            component: userHasAnewstipModule(Anewstip)
          },
          {
            path: '/app/themax',
            exact: true,
            component: userHasThemaxModule(Themax)
          },
          {
            path: '/app/themax/saved_searches',
            exact: true,
            component: userHasThemaxModule(ThemaxSavedSearches)
          },
          {
            path: '*',
            component: Fail
          }
        ]
      },
      {
        path: '/user_activation/:aid/:mod',
        component: UserActivation
      },
      {
        path: '/external_chart/',
        component: ExternalChart
      },
      {
        path: '*',
        component: Fail
      }
    ]
  }
])
