import { connect } from 'react-redux'

import EmailEditor from 'components/content_desk/content_edit_dialog/content_editor/email_editor'

import {
  emailEditorLoadingStart,
  emailEditorLoadingStop,
  uploadEditorImageStart,
  resetUploadedImageUrl,
  openSaveTemplateDialog,
  fetchMoreMediaFilesStart,
  deleteMediaFileStart as onMediaFileDelete
} from 'actions/content_desk'

import {
  openPickerDialog,
  setReadMode as setTemplatesReadModeOnly
} from 'actions/email_templates_library'

import { getCDeskContentUploadedImageUrl, getNewsradarId } from 'selectors'

const mapStateToProps = (state, { onChange, moduleScope, data }) => ({
  onChange,
  moduleScope,
  editorDesign: data.get('editorDesign'),
  json: data.get('json'),
  preHeader: data.get('preHeader'),
  imageUrl: getCDeskContentUploadedImageUrl(state),
  newsradarId: getNewsradarId(state)
})

export default connect(
  mapStateToProps,
  {
    onEditorLoadingStart: emailEditorLoadingStart,
    onEditorLoadingStop: emailEditorLoadingStop,
    onImageUpload: uploadEditorImageStart,
    resetImageUrl: resetUploadedImageUrl,
    setTemplatesReadModeOnly,
    openPickerDialog,
    onSaveTemplateClick: openSaveTemplateDialog,
    fetchMoreMediaFilesStart,
    onMediaFileDelete
  }
)(EmailEditor)
