import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import { Link } from '@mui/material'

const useStyles = makeStyles()({
  link: {
    cursor: 'pointer'
  },
  mediaReviewLabel: {
    marginRight: 5
  }
})

export default function ModuleName({ data, mediaReviewIds, navigate }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const mediaReview = data.get('mediaReview')

  if (!mediaReview) {
    return (
      <>
        {i18n.get(`module_${data.get('moduleName')}`)}
      </>
    )
  }

  const startDate = moment(mediaReview.get('startDate'))
  const endDate = moment(mediaReview.get('endDate'))

  let date = `${startDate.format('L')} - ${endDate.format('L')}`

  if (startDate.isSame(endDate, 'day')) {
    date = startDate.format('L')
  }

  const mediaReviewLabel = (
    <span className={classes.mediaReviewLabel}>
      {data.getIn(['mediaReview', 'name'])} {date}
    </span>
  )

  if (mediaReviewIds.includes(data.getIn(['mediaReview', 'id']))) {
    return (
      <>
        <Link
          classes={{ root: classes.link }}
          underline="hover"
          onClick={() => navigate(`/app/media_reviews/${data.getIn(['mediaReview', 'id'])}`)}
        >
          {mediaReviewLabel}
        </Link>

        ({i18n.get(`module_${data.get('moduleName')}`)})
      </>
    )
  }

  return (
    <>
      {mediaReviewLabel}

      ({i18n.get(`module_${data.get('moduleName')}`)})
    </>
  )
}

ModuleName.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  mediaReviewIds: PropTypes.instanceOf(List).isRequired,

  navigate: PropTypes.func.isRequired
}
