import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'

import useI18n from 'hooks/useI18n'

import { Typography, Tooltip } from '@mui/material'

export default function Description({
  socialListening,
  ownedChannelAnalytics
}) {
  const i18n = useI18n()

  const renderTextItem = (title, text) => (
    <>
      <Typography variant="h6">
        {title}
      </Typography>

      <Typography variant="body1">
        {text}
      </Typography>

      <br />
    </>
  )

  return (
    <Tooltip
      title={(
        <div>
          {Boolean(socialListening) && renderTextItem(i18n.get('social_listening'), socialListening)}
          {Boolean(ownedChannelAnalytics) && renderTextItem(i18n.get('owned_channel_analytics'), ownedChannelAnalytics)}
          {renderTextItem(i18n.get('privacy_notice'), i18n.get('external_accounts_privacy_notice'))}
        </div>
      )}
    >
      <InfoIcon />
    </Tooltip>
  )
}

Description.propTypes = {
  socialListening: PropTypes.string,
  ownedChannelAnalytics: PropTypes.string
}
