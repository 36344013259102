import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { Chip } from '@mui/material'

const useStyles = makeStyles()({
  codes: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  code: {
    margin: '0 5px 5px 0',
    textAlign: 'center'
  }
})

export default function Codes({ mediaReview }) {
  const { classes } = useStyles()
  const codes = mediaReview.getIn(['stats', 'codes'])

  return (
    <div className={classes.codes}>
      {codes.map(code => (
        <Chip
          classes={{ root: classes.code }}
          key={code.get('id')}
          label={(
            <>
              <b>{code.get('count')}</b>&nbsp;{code.get('name')}
            </>
          )}
        />
      ))}
    </div>
  )
}

Codes.propTypes = {
  mediaReview: PropTypes.instanceOf(Map).isRequired
}
