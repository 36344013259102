/* global DOMParser */
import { useEffect } from 'react'

import {
  $getSelection,
  $isRangeSelection,
  KEY_ENTER_COMMAND,
  PASTE_COMMAND,
  COMMAND_PRIORITY_LOW
} from 'lexical'

import { $generateNodesFromDOM } from '@lexical/html'
import { $createListNode } from '@lexical/list'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

const StoryTextPlugin = () => {
  const [editor] = useLexicalComposerContext()

  const handlePasteCommand = () => editor.registerCommand(
    PASTE_COMMAND,
    event => {
      const { clipboardData } = event
      const html = clipboardData.getData('text/html')

      if (html) {
        event.preventDefault()
        event.stopPropagation()

        const parser = new DOMParser()
        const dom = parser.parseFromString(html, 'text/html')

        const lexicalNodes = $generateNodesFromDOM(editor, dom)

        editor.update(() => {
          const processedNodes = []
          let listItemNodes = []

          const flattenListNodes = node => {
            if (node.getType() === 'extended-listitem') {
              const firstChild = node.getFirstChild()

              if (firstChild && firstChild.getType() === 'extended-list') {
                const childNodes = firstChild.getChildren()
                childNodes.forEach(child => {
                  flattenListNodes(child)
                })
              } else {
                listItemNodes.push(node.getWritable())
              }
            } else {
              listItemNodes.push(node.getWritable())
            }
          }

          lexicalNodes.forEach(node => {
            if (node.getType() === 'extended-list') {
              const listNode = $createListNode()

              const childNodes = node.getChildren()
              childNodes.forEach(child => {
                flattenListNodes(child)
              })

              listNode.append(...listItemNodes)
              listItemNodes = []

              processedNodes.push(listNode)
            } else {
              processedNodes.push(node.getWritable())
            }
          })

          const selection = $getSelection()

          if (selection) {
            selection.insertNodes(processedNodes)
          }
        })

        return true
      }

      return false
    },
    COMMAND_PRIORITY_LOW
  )

  useEffect(() => {
    editor.registerCommand(
      KEY_ENTER_COMMAND,
      event => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
          if (event.shiftKey) {
            event.preventDefault()
            event.stopPropagation()

            return true
          }
        }

        return true
      },
      COMMAND_PRIORITY_LOW
    )
  }, [editor])

  useEffect(() => {
    handlePasteCommand()
  }, [editor])

  return null
}

export default StoryTextPlugin
