import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'

import { Input, RaisedPrimaryButton } from 'containers/themed'

import AlphaNavigation from '../mail_autocomplete/alpha_navigation'

const split = value => value.split(/;|,|\n/)

export default function SmsAutocomplete({
  onChange,
  value,
  disabled,
  i18n
}) {
  const [state, setState] = useState({
    invalidPhoneNumbers: [],
    newValue: '',
    value
  })

  useEffect(() => {
    setState({ ...state, value })
  }, [value])

  const handleChange = e => {
    setState({ ...state, newValue: e.target.value })
  }

  const handleSubmit = res => {
    import(/* webpackChunkName libphonenumber */ 'libphonenumber-js').then(({ parsePhoneNumberFromString }) => {
      let newValue = res.map(v => v.trim())
      const invalidPhoneNumbers = []
      newValue = newValue.map(v => {
        const phoneNumber = parsePhoneNumberFromString(v, 'DE')

        if (!phoneNumber) {
          invalidPhoneNumbers.push(v)
        }

        return phoneNumber
      })
      newValue = newValue.map(v => v && v.format('E.164')).filter(v => v)
      setState({ ...state, newValue: '', invalidPhoneNumbers })
      onChange(newValue)
    })
  }

  const handleAdd = () => {
    const result = split(state.newValue).concat(state.value)
    handleSubmit(result)
  }

  const handleDelete = index => {
    handleSubmit(value.filter(v => v !== index))
  }

  const renderError = () => {
    const { invalidPhoneNumbers } = state

    if (!invalidPhoneNumbers.length) {
      return null
    }

    return `${i18n.get('invalid_phone_numbers')}: ${invalidPhoneNumbers}`
  }

  const renderInput = () => {
    const { newValue } = state

    const values = fromJS(state.value)

    const alpha = values.map(v => v.slice(0, 3)).toSet().toList().sortBy(a => a)
    const groups = values.groupBy(v => v.slice(0, 3))

    return (
      <div>
        <Input
          disabled={disabled}
          multiline
          rows={2}
          value={newValue}
          label={i18n.get('recipients_sms')}
          hint={i18n.get('phone_number_hint')}
          variant="outlined"
          onChange={handleChange}
        />

        <br />
        <br />

        <RaisedPrimaryButton
          disabled={!newValue || disabled}
          onClick={handleAdd}
        >
          {i18n.get('add')}
        </RaisedPrimaryButton>

        <AlphaNavigation
          alpha={alpha}
          groups={groups}
          onDelete={handleDelete}
        />
      </div>
    )
  }

  return (
    <div>
      {renderError()}
      {renderInput()}
    </div>
  )
}

SmsAutocomplete.defaultProps = {
  disabled: false
}

SmsAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,

  i18n: PropTypes.object.isRequired
}
