import { combineReducers } from 'redux-immutable'

import data from './data'
import filter from './filter'
import ui from './ui'

export default combineReducers({
  data,
  filter,
  ui
})
