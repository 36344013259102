import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  FormControlLabel,
  Switch
} from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'

import { Button } from 'containers/themed'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'

import { Capabilities } from 'static/constants'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  publicTemplatesSwitchTrack: {
    backgroundColor: theme.palette.secondary.main
  },
  addIcon: {
    marginLeft: '10px'
  }
}))

const Actions = ({
  isPublicOnly,
  capabilities,
  loading,
  openDialog,
  togglePublicTemplates
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const hasPublicReadRights = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_READ_RIGHTS)

  const actions = []

  actions.push(
    <div>
      {hasPublicReadRights && (
        <FormControlLabel
          sx={{
            marginRight: '40px',
            '& .MuiFormControlLabel-label.Mui-disabled': {
              color: 'white'
            }
          }}
          control={(
            <Switch
              classes={{ track: classes.publicTemplatesSwitchTrack }}
              color="secondary"
              checked={isPublicOnly}
              onClick={togglePublicTemplates}
              disabled={loading}
            />
          )}
          label={isPublicOnly ? i18n.get('show_my_templates') : i18n.get('show_public_templates')}
        />
      )}
      <Button
        variant="contained"
        color="secondary"
        onClick={openDialog}
      >
        {i18n.get('new_template')}
        <AddOutlinedIcon className={classes.addIcon} />
      </Button>
    </div>

  )

  return (
    <StaggeredList>
      <div className={classes.actionsWrapper}>
        {actions.map((action, index) => (
          <StaggeredListItem
            key={index}
            delay={index * 50}
          >
            {action}
          </StaggeredListItem>
        ))}
      </div>
    </StaggeredList>
  )
}

Actions.propTypes = {
  isPublicOnly: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,

  openDialog: PropTypes.func.isRequired,
  togglePublicTemplates: PropTypes.func.isRequired
}

export default Actions
