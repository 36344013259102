import React from 'react'
import { PropTypes } from 'prop-types'

import { Slider, Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  title: {
    color: theme.palette.text.secondary
  }
}))

function valueText(value) {
  return `${value}%`
}

const marks = [
  {
    value: 0,
    label: '0%'
  },
  {
    value: 20,
    label: '20%'
  },
  {
    value: 40,
    label: '40%'
  },
  {
    value: 60,
    label: '60%'
  },
  {
    value: 80,
    label: '80%'
  },
  {
    value: 100,
    label: '100%'
  }
]

export default function HackishnessSlider({ hackishness, setHackishness, showTitle }) {
  const { classes } = useStyles()
  const handleChange = (event, minHackishness) => {
    setHackishness({ minHackishness })
  }
  const i18n = useI18n()

  return (
    <>
      {showTitle
        && (
          <Typography className={classes.title}>
            {i18n.get('min_hackishness')}
          </Typography>
        )}
      <Slider
        value={hackishness}
        onChange={handleChange}
        track="inverted"
        valueLabelDisplay="auto"
        getAriaValueText={valueText}
        marks={marks}
        color="secondary"
        step={10}
      />
    </>

  )
}

HackishnessSlider.defaultProps = {
  showTitle: false
}

HackishnessSlider.propTypes = {
  hackishness: PropTypes.number.isRequired,
  setHackishness: PropTypes.func.isRequired,
  showTitle: PropTypes.bool
}
