import {
  put,
  takeEvery,
  all,
  select
} from 'redux-saga/effects'

import * as Actions from 'actions/email_templates_library'
import * as Selectors from 'selectors'

export function* fetchMoreTemplatesStart({ payload }) {
  let shared

  if (payload) {
    shared = payload.shared
  } else {
    shared = yield select(Selectors.getETLibraryShowPublic)
  }

  yield put(Actions.increaseTemplatesPage())
  yield put(Actions.fetchTemplatesStart({ shared }))
}

export function* watchFetchMoreTemplatesStart() {
  yield takeEvery(Actions.fetchMoreTemplatesStart, fetchMoreTemplatesStart)
}

export default function* filterSaga() {
  yield all([
    watchFetchMoreTemplatesStart()
  ])
}
