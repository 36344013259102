import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useI18n from 'hooks/useI18n'

import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: 15
  },
  accordion: {
    marginBottom: 20,
    backgroundColor: theme.isDark ? theme.palette.grey[800] : theme.palette.grey[300]
  },
  element__single: {
    padding: '10px 0',
    width: '100%'
  },
  element__multi: {
    paddingRight: 20,
    flexGrow: 1,
    flexBasis: 'auto',
    minWidth: 170,
    '&:last-child': {
      paddingRight: 0
    }
  },
  element__2: {
    flexBasis: '50%'
  },
  element__3: {
    flexBasis: '33%'
  },
  element__image: {
    paddingTop: 20
  },
  element__text: {
    paddingTop: 10,
    paddingBottom: 10
  },
  image: {
    width: '100%',
    minWidth: 150,
    '& img': {
      width: '100%'
    }
  },
  text: {}
}))

export default function ManagementSummary({ mediaReview }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (mediaReview.get('summaryContainers').length === 0) {
    return null
  }

  const containers = mediaReview.get('summaryContainers').sort((a, b) => a.sorting - b.sorting)

  const renderText = element => {
    const { text, textAlign, fontSize } = element
    const html = text ? text.replace(/(\n|\r|\s+(\n|\r)|(\n|\r)\s+)+/g, '</p><p>') : ''

    return (
      <div
        className={classes.text}
        style={{ textAlign, fontSize }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    )
  }

  const renderImage = element => {
    const { imageUrl, imageLink, textAlign } = element

    return (
      <>
        {imageLink && (
          <div
            className={classes.image}
            style={{ textAlign }}
          >
            <a href={imageLink}>
              <img
                alt=""
                src={imageUrl}
              />
            </a>
          </div>
        )}
        {!imageLink && (
          <div
            className={classes.image}
            style={{ textAlign }}
          >
            <img
              alt=""
              src={imageUrl}
            />
          </div>
        )}
      </>
    )
  }

  const renderElement = (element, size, containerSorting) => {
    const { type, sorting } = element
    const style = size > 1 ? 'multi' : 'single'

    return (
      <div
        key={`element__${containerSorting}__${sorting}`}
        className={`${classes[`element__${style}`]} ${classes[`element__${type}`]} ${classes[`element__${size}`]}`}
      >
        {type === 'image' && renderImage(element)}
        {type === 'text' && renderText(element)}
      </div>
    )
  }

  const renderContainer = container => {
    const elements = container.mediaReviewSummaryElements.sort((a, b) => a.sorting - b.sorting)
    const { borderTop, borderRight, borderBottom, borderLeft, borderStyle, borderColor, sorting } = container

    const styles = {
      borderTop: borderTop ? `${borderTop}px ${borderStyle} ${borderColor}` : 'medium none transparent',
      borderRight: borderRight ? `${borderRight}px ${borderStyle} ${borderColor}` : 'medium none transparent',
      borderBottom: borderBottom ? `${borderBottom}px ${borderStyle} ${borderColor}` : 'medium none transparent',
      borderLeft: borderLeft ? `${borderLeft}px ${borderStyle} ${borderColor}` : 'medium none transparent'
    }

    return (
      <div
        key={`container__${sorting}`}
        className={classes.container}
        style={styles}
      >
        {elements.map(e => renderElement(e, elements.size, sorting))}
      </div>
    )
  }

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="h6">{i18n.get('summary')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {containers.map(renderContainer)}
      </AccordionDetails>
    </Accordion>
  )
}

ManagementSummary.propTypes = {
  mediaReview: PropTypes.instanceOf(Map).isRequired
}
