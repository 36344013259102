import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/notifications'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  page: 1,
  lastPage: false,
  limit: 5,
  sortBy: 'released_at',
  sortOrder: 'desc'
})

export default handleActions({
  [Actions.setNotificationsPage]: (state, { payload }) => state.set('page', payload),
  [Actions.setLastPage]: state => state.set('lastPage', true),
  [AppActions.resetState]: () => initialState
}, initialState)
