import { combineActions, handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  importResult: [],
  importContactsForDistributionLists: []
})

// TODO: make sure importResult does not corrupt importContactsForDistributionLists
// check if importResult is acutally correct after replacing (merge wtf)
// I think it should always replace the first element, which is clearly wrong (write a test)
export default handleActions({
  [combineActions(
    Actions.importStart,
    Actions.addJournalistsToDistributionList
  )]: state => state.set('importResult', fromJS([])),
  [Actions.importSuccess]: (state, { payload }) => state.update(
    'importResult', oldValue => (oldValue.merge(fromJS(payload)))
  ),
  [Actions.removeContactFromDuplicateList]: (state, { payload }) => state.update(
    'importResult',
    oldValue => oldValue.filter(v => v !== payload)
  ),
  [Actions.replaceContactSuccess]: (state, { payload: { existingContact, replaceContactResult } }) => state.update(
    'importResult',
    oldValue => oldValue.filter(v => v.get('existingContact') !== existingContact)
  ).update(
    'importContactsForDistributionLists',
    oldContacts => oldContacts.map(c => (
      (c.get('id') === existingContact.get('id')) ? (
        fromJS(replaceContactResult).getIn([0, 'contactImported'])
      ) : c
    ))
  ),
  [Actions.addJournalistsToDistributionList]: state => state.set('importContactsForDistributionLists', fromJS([])),
  [Actions.setImportContactsForDistributionLists]: (state, { payload }) => state.set(
    'importContactsForDistributionLists',
    fromJS(payload).map(e => e.get('existingContact') || e.get('contactImported'))
  ),
  [Actions.clearModuleData]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
