import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import useI18n from 'hooks/useI18n'
import { stripDossier } from 'utils/html'

import { makeStyles } from 'tss-react/mui'

import { TextField, Grid, Autocomplete } from '@mui/material'

import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()({
  numberField: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      display: 'none'
    }
  }
})

export default function EditContactInfo({ contact, countries, states, onChange }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const country = contact.get('country')
    ? {
      value: contact.getIn(['country', 'id']),
      label: contact.getIn(['country', 'name'])
    }
    : null
  const state = contact.get('state')
    ? {
      value: contact.getIn(['state', 'id']),
      label: contact.getIn(['state', 'name'])
    }
    : null

  const statesByCountry = states.filter(s => s.get('countryId') === contact.getIn(['country', 'id']))

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })

  const handleAutocompleteChange = (key, option) => {
    if (!option) {
      return onChange({ key, value: null })
    }

    return onChange({ key, value: { id: option.value, name: option.label } })
  }

  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={2}
    >
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Autocomplete
          options={mapToOptions(countries)}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={country}
          onChange={(_e, option) => handleAutocompleteChange('country', option)}
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('country')}
              placeholder=""
            />
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Autocomplete
          options={mapToOptions(statesByCountry)}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={state}
          onChange={(_e, option) => handleAutocompleteChange('state', option)}
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('state')}
              placeholder=""
            />
          )}
          disabled={!contact.get('country') || contact.getIn(['country', 'value']) === 0}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          InputProps={{ classes: { input: classes.numberField } }}
          fullWidth
          label={i18n.get('phone')}
          name="phone"
          value={contact.get('phone') || ''}
          onChange={handleChange}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          InputProps={{ classes: { input: classes.numberField } }}
          fullWidth
          label={i18n.get('fax')}
          name="fax"
          value={contact.get('fax') || ''}
          onChange={handleChange}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          fullWidth
          label={i18n.get('company')}
          name="company"
          value={contact.get('company') || ''}
          onChange={handleChange}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          fullWidth
          label={i18n.get('address')}
          name="address"
          value={contact.get('address') || ''}
          onChange={handleChange}
          size="small"
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          multiline
          minRows={4}
          fullWidth
          label={i18n.get('dossier')}
          name="dossier"
          value={contact.get('dossier') ? stripDossier(contact.get('dossier')) : ''}
          onChange={handleChange}
          size="small"
          inputProps={{ maxLength: 1000 }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <TextField
          multiline
          minRows={4}
          fullWidth
          label={i18n.get('notes')}
          name="notes"
          value={contact.get('notes') || ''}
          onChange={handleChange}
          size="small"
        />
      </Grid>
    </Grid>
  )
}

EditContactInfo.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  states: PropTypes.instanceOf(ImmutableList).isRequired,
  onChange: PropTypes.func.isRequired
}
