import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { Backdrop } from '@mui/material'

import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'

const useStyles = makeStyles()({
  backdrop: {
    zIndex: 10
  }
})

export default function NrxProgressBackdrop({ open }) {
  const { classes } = useStyles()

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
    >
      <NrxProgressBar />
    </Backdrop>
  )
}

NrxProgressBackdrop.propTypes = {
  open: PropTypes.bool.isRequired
}
