import { connect } from 'react-redux'

import MailAutocomplete from 'components/mail_autocomplete'
import { getI18n } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state)
})

export default connect(mapStateToProps)(MailAutocomplete)
