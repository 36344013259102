import { connect } from 'react-redux'

import DetailedViewDialog from 'components/themax/detailed_view_dialog'

import {
  closeDetailedViewDialog,
  themaxFetchMoreRelatedEditorialPlansStart,
  applyThemaxFilters as applyFilters,
  setSelectedPublicationIds,
  changeTabView
} from 'actions/themax'

import {
  getThemaxTabView,
  getThemaxData,
  getThemaxShowDetailedViewDialog,
  getSelectedEditorialPlan,
  getHasThemaxPaidVersion,
  getThemaxFetchMoreRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  view: getThemaxTabView(state),
  open: getThemaxShowDetailedViewDialog(state),
  editorialPlan: getSelectedEditorialPlan(state),
  relatedEditorialPlans: getThemaxData(state).getIn(['relatedEditorialPlans', 'items']),
  relatedEditorialPlansTotal: getThemaxData(state).getIn(['relatedEditorialPlans', 'total']),
  hasPaidVersion: getHasThemaxPaidVersion(state),
  fetchMoreRequestRunning: getThemaxFetchMoreRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeDetailedViewDialog,
    onScrollBottom: themaxFetchMoreRelatedEditorialPlansStart,
    setSelectedPublicationIds,
    applyFilters,
    changeTabView
  }
)(DetailedViewDialog)
