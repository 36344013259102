import { connect } from 'react-redux'

import ContentEditDialog from 'components/content_desk/content_edit_dialog'
import {
  lockContentStart,
  saveContentStart as onSave,
  unlockContentStart as onClose,
  openTestMailDialog as onTestMail,
  openOldScheduleDialog as openScheduleDialog,
  openPlanDialog,
  setContentFormData
} from 'actions/content_desk'
import {
  getCDeskShowOldEditContentDialog,
  getCDeskNewDialogView,
  getCDeskContentIsLoading,
  getCDeskContentDialogCloneMode,
  getCDeskContentFormData,
  getCDeskDuplicateError
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowOldEditContentDialog(state),
  view: getCDeskNewDialogView(state),
  isLoading: getCDeskContentIsLoading(state),
  isInCloneMode: getCDeskContentDialogCloneMode(state),
  contentFormData: getCDeskContentFormData(state),
  duplicateError: getCDeskDuplicateError(state)
})

export default connect(
  mapStateToProps,
  {
    onRelock: () => lockContentStart(),
    onUnlock: () => onClose(),
    onSave,
    onClose,
    onTestMail,
    openScheduleDialog,
    openPlanDialog,
    onChange: setContentFormData
  }
)(ContentEditDialog)
