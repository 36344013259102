import { handleActions } from 'redux-actions'
import { fromJS, List } from 'immutable'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  networks: [],
  languages: [],
  searchQueries: [],
  darknetSearchQueries: [],
  minHackishness: 0
})

const prefix = field => {
  if (field === 'networks') {
    return 'Network'
  }

  return ''
}
const mapSelectedItem = (item, field) => (fromJS(item).merge({
  id: item.id,
  label: item.label || item.name,
  prefix: prefix(field),
  field
}))

const mapSelectedItems = (items, state, field) => {
  const currentFilters = state.get(field, fromJS([]))

  // Do not replace existing filters to keep flags like inverted
  return fromJS(items.map(item => {
    const found = currentFilters.find(f => f.get('id') === item.id)

    return found || mapSelectedItem(item, field)
  }))
}

export default handleActions({
  [Actions.addSelectedFilter]: (state, { payload: { filterType, value, name } }) => (
    state.update(filterType, oldValue => (
      oldValue
        .concat(mapSelectedItems([{ id: value, name: name || value }], state, filterType))
        .groupBy(f => f.get('id'))
        .map(f => f.first())
        .toList()
    ))
  ),
  [Actions.setSelectedFilters]: (state, { payload }) => {
    const {
      networks,
      languages,
      searchQueries,
      minHackishness
    } = payload

    return state.merge({
      networks: mapSelectedItems(networks, state, 'networks'),
      languages: mapSelectedItems(languages, state, 'languages'),
      searchQueries: mapSelectedItems(searchQueries, state, 'searchQueries'),
      minHackishness
    })
  },
  [Actions.addDarknetSearchQuery]: (state, { payload: { query } }) => (
    state.update('darknetSearchQueries', queries => queries.push(fromJS({
      label: query,
      value: query,
      field: 'darknetSearchQueries'
    })))
  ),
  [Actions.removeFilter]: (state, { payload: { field, index } }) => {
    if (List.isList(state.get(field))) {
      return state.update(field, list => list.delete(index))
    }

    // If field is a number
    return state.set(field, 0)
  },
  [Actions.invertFilter]: (state, { payload: { field, index } }) => {
    if (List.isList(state.get(field))) {
      return state.updateIn([field, index, 'inverted'], inverted => !inverted)
    }

    return state
  },
  [Actions.updateFilter]: (state, { payload: { filter, index } }) => {
    if (List.isList(state.get(filter.get('field')))) {
      return state.setIn([filter.get('field'), index], filter.set('updated', true))
    }

    return state
  },
  [Actions.resetFilters]: state => (
    state.merge(initialState)
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
