import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  total: 0,
  influencers: []
})

const mapInfluencers = influencers => fromJS(influencers)

export default handleActions({
  [Actions.influencersRequestSuccess]: (state, { payload: { influencers, total } }) => state.merge({
    influencers: mapInfluencers(influencers),
    total
  }),
  [Actions.moreInfluencersRequestSuccess]: (state, { payload: { influencers } }) => (
    state.update('influencers', old => old.concat(mapInfluencers(influencers)))
  ),
  [Actions.addInfluencerToContactsSuccess]: (state, { payload: { influencer } }) => (
    state.update('influencers',
      influencers => influencers.map(
        i => (
          (i.get('id') === influencer.get('id')) ? (
            i.setIn(['flags', 'contactAdded'], true)
              .setIn(['flags', 'interestExpressed'], true)
          ) : i
        )
      ))
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
