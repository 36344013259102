import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material'

import { Button, IconButton, RaisedPrimaryButton } from 'containers/themed'

import { Capabilities } from 'static/constants'

const useStyles = makeStyles()({
  closeSaveDialogButton: {
    position: 'absolute',
    right: 7,
    top: 7
  }
})

const SaveTemplateDialog = ({ open, loading, capabilities, onClose, onChange, onSave }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const hasPublicWriteRights = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_WRITE_RIGHTS)

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })
  const handleChangeCheckbox = e => onChange({ key: e.target.name, value: e.target.checked })

  const handleSave = () => onSave('emailEditor')

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      <IconButton
        className={classes.closeSaveDialogButton}
        icon="close"
        onClick={() => onClose()}
        size="normal"
        disabled={loading}
      />
      <DialogTitle className={classes.saveDialogTitle}>
        {`${i18n.get('save')} ${i18n.get('template')}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label={i18n.get('name')}
          name="name"
          onChange={handleChange}
          margin="normal"
          variant="standard"
          size="small"
          disabled={loading}
        />
        {hasPublicWriteRights && (
          <FormControlLabel
            name="shared"
            control={(
              <Checkbox
                onChange={handleChangeCheckbox}
                disabled={loading}
              />
            )}
            label={i18n.get('public')}
          />
        )}
        <FormControlLabel
          name="released"
          control={(
            <Checkbox
              onChange={handleChangeCheckbox}
              disabled={loading}
            />
          )}
          label={i18n.get('released')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => onClose()}
        >
          {i18n.get('cancel')}
        </Button>
        <RaisedPrimaryButton
          loading={loading}
          variant="contained"
          onClick={handleSave}
          disabled={loading}
        >
          {i18n.get('confirm')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

SaveTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default SaveTemplateDialog
