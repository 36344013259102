import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Paper,
  Typography,
  Link,
  CircularProgress
} from '@mui/material'

const useStyles = makeStyles()({
  articleMainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  relatedArticleContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: '#e0e0e04f',
    padding: '20px 30px 20px 30px',
    gap: '20px'
  },
  relatedArticleInfoContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  relatedArticleImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageLoaderWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  relatedArticleDateContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right'
  },
  articleImage: {
    display: 'flex',
    objectFit: 'cover'
  },
  imageFailedToLoad: {
    display: 'none'
  }
})

const PrArticle = ({
  article
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [imageHidden, setImageHidden] = useState(true)
  const [imageLoaded, setImageLoaded] = useState(false)

  const handleImageError = _event => {
    setImageLoaded(true)
  }

  const handleImageLoad = _event => {
    setImageHidden(false)
    setImageLoaded(true)
  }

  return (
    <Paper
      sx={{ padding: 2, width: '100%' }}
      elevation={2}
    >
      <div className={classes.articleMainContainer}>
        <div className={classes.relatedArticleContainer}>
          <div className={classes.relatedArticleImageContainer}>
            {!imageLoaded && article.get('image')
              ? (
                <div className={classes.imageLoaderWrapper}>
                  <CircularProgress
                    size={30}
                    variant="indeterminate"
                  />
                </div>
              ) : null}
            <img
              onLoad={handleImageLoad}
              onError={handleImageError}
              className={imageHidden ? classes.imageFailedToLoad : classes.articleImage}
              src={article.get('image')}
              alt=""
              height="100"
              width="100"
            />
          </div>
          <div className={classes.relatedArticleInfoContainer}>
            <Link
              target="_blank"
              rel="noopener"
              href={article.get('url')}
            ><b>{article.get('headline')}</b>
            </Link>
            <Typography>{article.get('snippet')}</Typography>
          </div>
          <div className={classes.relatedArticleDateContainer}>
            <Typography fontSize="small">{i18n.get('date')}: {moment(article.get('articleDate')).format('L')}</Typography>
          </div>
        </div>
      </div>
    </Paper>

  )
}

PrArticle.propTypes = {
  article: PropTypes.instanceOf(Map).isRequired
}

export default PrArticle
