import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_dashboard'
import * as AppActions from 'actions/app'
import { sortCharts } from 'utils/charts'

export const initialState = null

export default handleActions({
  [Actions.fetchSavedDashboardSuccess]: (state, { payload: { data } }) => sortCharts(fromJS(data)),
  [Actions.fetchSavedDashboardError]: () => fromJS({ error: 'not_found' }),

  [AppActions.resetState]: () => initialState
}, initialState)
