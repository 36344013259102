import { fromJS } from 'immutable'
import { Capabilities } from 'static/constants'

export const capabilitiesWithRoutes = fromJS([
  {
    name: Capabilities.HAS_DASHBOARD_MODULE,
    route: '/app/dashboard'
  },
  {
    name: Capabilities.HAS_NEWS_MODULE,
    route: '/app/news'
  },
  {
    name: Capabilities.HAS_NEWS_POOL_MODULE,
    route: '/app/search_pool'
  },
  {
    name: Capabilities.HAS_NEWS_POOL_MODULE,
    route: '/app/media_monitoring'
  },
  {
    name: Capabilities.HAS_PROFILE_MONITORING_MODULE,
    route: '/app/profile_monitoring'
  },
  {
    name: Capabilities.HAS_SUBSCRIPTIONS_MODULE,
    route: '/app/subscriptions'
  },
  {
    name: Capabilities.HAS_MEDIA_REVIEW_MODULE,
    route: '/app/media_reviews'
  },
  {
    name: Capabilities.HAS_ANALYSIS_MODULE,
    route: '/app/analysis'
  },
  {
    name: Capabilities.HAS_DARKNET_MODULE,
    route: '/app/darknet/dashboard'
  },
  {
    name: Capabilities.HAS_CONTENT_DESK_MODULE,
    route: '/app/content_desk'
  },
  {
    name: Capabilities.HAS_CONTACTS_MANAGEMENT_MODULE,
    route: '/app/contact_management/my_contacts'
  },
  {
    name: Capabilities.HAS_CONTACTS_MANAGEMENT_ANEWSTIP,
    route: '/app/contact_management/journalist_search'
  },
  {
    name: Capabilities.HAS_THE_MAX_MODULE,
    route: '/app/themax'
  }
])

export const defaultRoute = capabilitiesWithRoutes.first().get('route')
export const failPath = '/app/fail'

export const hasCapForPath = (capabilities, pathname) => {
  const cap = capabilitiesWithRoutes.find(c => c.get('route') === pathname)

  return capabilities.get(cap.get('name'))
}

export const getFirstValidRoute = capabilities => {
  if (!capabilities) {
    return defaultRoute
  }

  const found = capabilitiesWithRoutes.find(cap => capabilities.get(cap.get('name')) === true)

  if (found) {
    return found.get('route')
  }

  return failPath
}
