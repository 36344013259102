import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

export const initialState = fromJS({
  name: '',
  config: {},
  uploadedBackgroundImage: null
})

export default handleActions({
  [Actions.showEditDialog]: (state, { payload }) => (payload || initialState),
  [Actions.setEditDashboard]: (state, { payload: dashboard }) => dashboard,

  [Actions.setEditDashboardColor]: (state, { payload: { key, value } }) => (
    state
      .update('config', config => (config || fromJS({})))
      .updateIn(['config', 'colors'], colors => (colors || fromJS({})).set(key, value))
  ),

  [Actions.setEditDashboardUploadedBackgroundImage]: (state, { payload }) => state.set('uploadedBackgroundImage', payload),
  [Actions.setEditDashboardBackgroundImageUrl]: (state, { payload }) => (
    state
      .update('config', config => (config || fromJS({})))
      .setIn(['config', 'backgroundImageUrl'], payload)
  ),

  [Actions.resetEditDashboardBackgroundImage]: state => (
    state
      .delete('uploadedBackgroundImage')
      .deleteIn(['config', 'backgroundImageUrl'])
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
