import { connect } from 'react-redux'

import DeleteFileDialog from 'components/content_desk_new/contents/content_edit_dialog/attachments/delete_file_dialog'

import {
  deleteMediaFileStart as onMediaDelete,
  setContentFormData as onChange,
  closeAttachmentsDeleteDialog as closeDeleteDialog
} from 'actions/content_desk'

import {
  getCDeskAttachmentsDeleteDialogOpen,
  getCDeskContentFormData,
  getCDeskAttachmentsDeleteMediaFileId
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskAttachmentsDeleteDialogOpen(state),
  contentFormData: getCDeskContentFormData(state),
  mediaFileId: getCDeskAttachmentsDeleteMediaFileId(state)
})

export default connect(
  mapStateToProps,
  {
    onMediaDelete,
    onChange,
    closeDeleteDialog
  }
)(DeleteFileDialog)
