import { connect } from 'react-redux'

import ContentPreview from 'components/content_desk_new/contents/content_preview'

import { getCDeskSelectedContent, getCDeskShowViewContentDialog, getCDeskNewDialogView } from 'selectors'

const mapStateToProps = (state, ownProps) => ({
  content: ownProps.content ? ownProps.content : getCDeskSelectedContent(state),
  showContentDetailsView: getCDeskShowViewContentDialog(state),
  editorDialogView: getCDeskNewDialogView(state),
  onChange: ownProps.onChange
})

export default connect(
  mapStateToProps,
  {
  }
)(ContentPreview)
