import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { OrderedSet } from 'immutable'

import LanguagesChart from 'containers/darknet/charts/LanguagesChart'
import MentionsTotalChart from 'containers/darknet/charts/MentionsTotalChart'
import MentionsTimelineChart from 'containers/darknet/charts/MentionsTimelineChart'
import AvgHackishnessTimelineChart from 'containers/darknet/charts/AvgHackishnessTimelineChart'
import AvgHackishnessChart from 'containers/darknet/charts/AvgHackishnessChart'
import AvgHackishnessNetworksChart from 'containers/darknet/charts/AvgHackishnessNetworksChart'
import NetworksChart from 'containers/darknet/charts/NetworksChart'
import NetworksTimelineChart from 'containers/darknet/charts/NetworksTimelineChart'
import SearchQueriesTimelineChart from 'containers/darknet/charts/SearchQueriesTimelineChart'
import ScoreResultTimelineChart from 'containers/darknet/charts/ScoreResultTimelineChart'
import NoHits from 'containers/news_page/NoHits'

import { DarknetCharts } from 'utils/darknet'

const chartComponents = {
  [DarknetCharts.LANGUAGES]: LanguagesChart,
  [DarknetCharts.MENTIONS_TIMELINE]: MentionsTimelineChart,
  [DarknetCharts.MENTIONS_TOTAL]: MentionsTotalChart,
  [DarknetCharts.HACKISHNESS_TOTAL]: AvgHackishnessChart,
  [DarknetCharts.HACKISHNESS_TIMELINE]: AvgHackishnessTimelineChart,
  [DarknetCharts.HACKISHNESS_NETWORKS]: AvgHackishnessNetworksChart,
  [DarknetCharts.NETWORKS]: NetworksChart,
  [DarknetCharts.NETWORKS_TIMELINE]: NetworksTimelineChart,
  [DarknetCharts.SEARCH_QUERIES_TIMELINE]: SearchQueriesTimelineChart,
  [DarknetCharts.SCORE_RESULT_TIMELINE]: ScoreResultTimelineChart
}

const getChartOrder = breakpoint => {
  let order

  switch (breakpoint) {
    case 'xs':
    case 'sm':
    case 'md': order = OrderedSet([
      DarknetCharts.HACKISHNESS_TOTAL,
      DarknetCharts.MENTIONS_TOTAL,
      DarknetCharts.HACKISHNESS_NETWORKS,
      DarknetCharts.NETWORKS,
      DarknetCharts.HACKISHNESS_TIMELINE,
      DarknetCharts.MENTIONS_TIMELINE,
      DarknetCharts.NETWORKS_TIMELINE,
      DarknetCharts.LANGUAGES,
      DarknetCharts.SEARCH_QUERIES_TIMELINE,
      DarknetCharts.SCORE_RESULT_TIMELINE
    ]); break
    case 'lg':
    default: order = OrderedSet([
      DarknetCharts.HACKISHNESS_TOTAL,
      DarknetCharts.HACKISHNESS_TIMELINE,
      DarknetCharts.HACKISHNESS_NETWORKS,
      DarknetCharts.MENTIONS_TOTAL,
      DarknetCharts.MENTIONS_TIMELINE,
      DarknetCharts.NETWORKS,
      DarknetCharts.NETWORKS_TIMELINE,
      DarknetCharts.LANGUAGES,
      DarknetCharts.SEARCH_QUERIES_TIMELINE,
      DarknetCharts.SCORE_RESULT_TIMELINE
    ])
  }

  return order
}

export default function StaticCharts({ noHits, breakpoint }) {
  const chartOrder = getChartOrder(breakpoint)

  const renderChart = (chartId, index) => {
    const Comp = chartComponents[chartId]

    return <Comp key={index} />
  }

  if (noHits) {
    return (
      <>
        <br />
        <br />
        <NoHits />
      </>
    )
  }

  return (
    (
      <Grid
        container
        style={{ paddingRight: '1em' }}
      >
        {chartOrder.map((chartId, index) => renderChart(chartId, index))}
      </Grid>
    )
  )
}

StaticCharts.propTypes = {
  noHits: PropTypes.bool.isRequired,
  breakpoint: PropTypes.string
}
