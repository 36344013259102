import { connect } from 'react-redux'

import CenterMessage from 'components/center_message'

import { getColors } from 'selectors'

const mapStateToProps = state => ({
  colors: getColors(state)
})

export default connect(mapStateToProps)(CenterMessage)
