import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CacheProvider } from '@emotion/react'
import { GlobalStyles, TssCacheProvider } from 'tss-react'
import createCache from '@emotion/cache'
import { Outlet } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { CssBaseline } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import WithMuiTheme from 'containers/WithMuiTheme'
import ForceReload from 'components/force_reload'
import AcceptCookies from 'containers/AcceptCookies'

import usePrefersDarkMode from 'hooks/usePrefersDarkMode'

export const muiCache = createCache({
  key: 'mui',
  prepend: true
})

export const tssCache = createCache({
  key: 'nrx'
})

const NewsRadar = ({
  initEnvironment,
  environmentIsLoaded,
  forceReload,
  showCookieDialog,
  isYouTubeReview
}) => {
  usePrefersDarkMode()
  const theme = useTheme()

  useEffect(() => {
    initEnvironment()
  }, [])

  // const routesElement = useRoutes(route.routes)

  let reloadComp

  if (forceReload) {
    reloadComp = <ForceReload />
  }

  let body

  if (environmentIsLoaded) {
    body = (
      <>
        <CssBaseline />
        {showCookieDialog && <AcceptCookies />}
        <Outlet />
      </>
    )
  }

  if (!body) {
    return null
  }

  return (
    <>
      <GlobalStyles
        styles={{
          '*': {
            fontFamily: 'Roboto, sans-serif'
          },
          li: {
            listStyleType: 'none'
          },
          ul: {
            listStyleType: 'none'
          },
          a: {
            textDecoration: 'none'
          },
          'input::-ms-clear': {
            display: 'none'
          },
          'svg text': {
            letterSpacing: 'normal'
          },
          body: {
            [theme.breakpoints.only('xs')]: {
              maxWidth: '100%',
              overflowX: 'hidden'
            }
          },
          '.clip': {
            color: theme.nrx.colors.highlighting,
            backgroundColor: theme.nrx.colors.highlightingBackground,
            padding: theme.nrx.colors.highlightingBackground ? 2 : 0,
            fontWeight: 500,
            fontStyle: 'italic',
            borderRadius: 7
          },
          '.recharts-default-tooltip': {
            backgroundColor: `${theme.palette.background.paper} !important`,
            color: `${theme.palette.text.primary} !important`
          },
          '.recharts-tooltip-wrapper': {
            outline: 'none'
          },
          '.recharts-tooltip-item': {
            color: `${theme.palette.text.primary} !important`
          },
          '.recharts-tooltip-cursor': {
            fill: `${theme.palette.background.default} !important`
          },
          '.tf-v1-sidetab-button': {
            backgroundColor: '#64D470 !important'
          }
        }}
      />

      {isYouTubeReview && (
        <GlobalStyles
          styles={{
            // YouTube compliance requirement
            '.fa-youtube': {
              fontSize: '20px !important'
            }
          }}
        />
      )}

      {reloadComp}
      {body}
    </>
  )
}

NewsRadar.propTypes = {
  environmentIsLoaded: PropTypes.bool.isRequired,
  initEnvironment: PropTypes.func.isRequired,
  forceReload: PropTypes.bool.isRequired,
  showCookieDialog: PropTypes.bool.isRequired,
  isYouTubeReview: PropTypes.bool.isRequired
}

const StyledNewsRadar = WithMuiTheme(NewsRadar)

export default function NRX(props) {
  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <SnackbarProvider
          maxSnack={3}
          preventDuplicate
        >
          <StyledNewsRadar {...props} />
        </SnackbarProvider>
      </TssCacheProvider>
    </CacheProvider>
  )
}
