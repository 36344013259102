import React from 'react'
import PropTypes from 'prop-types'

import useI18n from 'hooks/useI18n'

import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import { Box, Chip, Divider, Typography } from '@mui/material'

function TopicChip({ label }) {
  return (
    <Box
      mr={1}
      my={1}
      display="inline-block"
    >
      <Chip
        px={2}
        size="small"
        label={label}
      />
    </Box>
  )
}

export default function ContactTopics({ contact, mediaTopics }) {
  const i18n = useI18n()
  const topics = contact.get('mediaTopics')

  if (!topics || topics.size < 1) {
    return null
  }

  const ids = topics.map(t => t.get('id'))
  const topicsWithNames = mediaTopics.filter(mt => ids.includes(mt.get('id')))

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Typography
        variant="subtitle2"
        pb={2}
        sx={{ fontSize: '90%' }}
      >{i18n.get('themes')}
      </Typography>
      <Box>
        {topicsWithNames.map(topic => (
          <TopicChip
            key={`topic__${topic.get('id')}`}
            label={topic.get('name')}
          />
        ))}
      </Box>
    </>
  )
}

ContactTopics.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  mediaTopics: PropTypes.instanceOf(ImmutableList).isRequired
}

TopicChip.propTypes = {
  label: PropTypes.string.isRequired
}
