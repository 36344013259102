import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'

const MuiInput = ({ innerRef, ...props }) => (
  <TextField
    ref={innerRef}
    {...props}
  />
)

function InputWrapper(props, ref) {
  return (
    <MuiInput
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(InputWrapper)

MuiInput.defaultProps = {
  fullWidth: true
}

MuiInput.propTypes = {
  innerRef: PropTypes.any,
  fullWidth: PropTypes.bool
}
