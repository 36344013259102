import { handleActions } from 'redux-actions'
import moment from 'moment-timezone'
import { fromJS } from 'immutable'

import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

import { getChartData } from 'utils/darknetCharts'
import { DarknetCharts } from 'utils/darknet'

export const initialState = fromJS({
  hits: [],
  stats: {
    total: 0,
    avgHackishness: 0
  },
  previous: {
    total: 0,
    avgHackishness: 0
  },
  scores: [],
  charts: {
    scores: [],
    search: []
  }
})

const chartsFromStats = (stats, previous) => {
  const total = getChartData(DarknetCharts.MENTIONS_TOTAL, { current: stats.total, previous: previous.total })
  const networks = getChartData(DarknetCharts.NETWORKS, stats.networks)
  const avgHackishness = getChartData(DarknetCharts.HACKISHNESS_TOTAL, { current: stats.avgHackishness, previous: previous.avgHackishness })
  const avgHackishnessTimeline = getChartData(DarknetCharts.HACKISHNESS_TIMELINE, stats.timeline)
  const totalTimeline = getChartData(DarknetCharts.MENTIONS_TIMELINE, stats.timeline)
  const avgHackishnessNetworks = getChartData(DarknetCharts.HACKISHNESS_NETWORKS, stats.networks)
  const languages = getChartData(DarknetCharts.LANGUAGES, stats.languages)
  const networksTimeline = getChartData(DarknetCharts.NETWORKS_TIMELINE, stats.timeline)
  const searchQueriesTimeline = getChartData(DarknetCharts.SEARCH_QUERIES_TIMELINE, stats.timeline)

  return [
    avgHackishness,
    avgHackishnessNetworks,
    avgHackishnessTimeline,
    total,
    totalTimeline,
    networks,
    networksTimeline,
    searchQueriesTimeline,
    languages
  ]
}

const chartsFromScores = (scores, demoRequest) => {
  const groupedScores = fromJS(scores)
    .map(el => el.set('day', moment(el.get('date')).format('DD. MMM')))
    .groupBy(x => x.get('day'))
    .valueSeq()
    .sortBy(s => s.get('date'))

  if (demoRequest) {
    return [getChartData(DarknetCharts.EXTERNAL_SCORE_CHART, groupedScores.toJS())]
  }

  return [getChartData(DarknetCharts.SCORE_RESULT_TIMELINE, groupedScores.toJS())]
}

export default handleActions({
  [Actions.searchRequestSuccess]: (state, { payload: { stats, data, previous } }) => state
    .set('hits', fromJS(data))
    .set('stats', fromJS(stats))
    .set('previous', fromJS(previous))
    .setIn(['charts', 'search'], fromJS(chartsFromStats(stats, previous))),

  [Actions.scoresRequestSuccess]: (state, { payload: { scores, demoRequest } }) => {
    const scoresWithName = scores.data.map(d => ({ ...d, scoreQueryName: d.darkowlScoreQuery.name }))

    return state
      .set('scores', fromJS(scoresWithName))
      .setIn(['charts', 'scores'], fromJS(chartsFromScores(scoresWithName, demoRequest)))
  },

  [Actions.loadMoreDarknetEntriesSuccess]: (state, { payload: { data } }) => {
    const currentIds = state.get('hits').map(h => h.get('id'))

    return state
      .update('hits', hits => hits.concat(fromJS(data).filter(el => !currentIds.contains(el.get('id')))))
  },

  [AppActions.resetState]: () => initialState
}, initialState)
