import { handleActions } from 'redux-actions'
import { fromJS, Map, List } from 'immutable'

import * as Actions from 'actions/themax'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  editorialPlans: {
    items: [],
    total: 0
  },
  relatedEditorialPlans: {
    items: [],
    total: 0
  },
  publications: {
    items: [],
    total: 0
  },
  savedSearches: {
    items: []
  },
  selectedEditorialPlan: {}
})

export default handleActions({
  [Actions.themaxSearchEditorialPlansSuccess]: (state, { payload: { response, page } }) => state.set('editorialPlans',
    fromJS({
      items: (page === 1) ? fromJS(response.editorialPlans) : state.getIn(['editorialPlans', 'items'])
        .concat(fromJS(response.editorialPlans)),
      total: response.total
    })),
  [Actions.themaxFetchRelatedEditorialPlansSuccess]: (state, { payload: { response, page } }) => state.set('relatedEditorialPlans',
    fromJS({
      items: (page === 1) ? fromJS(response.editorialPlans) : state.getIn(['relatedEditorialPlans', 'items'])
        .concat(fromJS(response.editorialPlans)),
      total: response.total
    })),
  [Actions.themaxSearchPublicationsSuccess]: (state, { payload: { response, page } }) => state.set('publications',
    fromJS({
      items: (page === 1) ? fromJS(response.publications) : state.getIn(['publications', 'items'])
        .concat(fromJS(response.publications)),
      total: response.total
    })),

  [Actions.searchThemaxSavedSearchesSuccess]: (state, { payload }) => state.setIn(['savedSearches', 'items'], fromJS(payload)),
  [Actions.saveThemaxSavedSearchSuccess]: (state, { payload }) => {
    const items = state.getIn(['savedSearches', 'items'])
    const index = items.findIndex(item => item.get('id') === payload.id)

    if (index !== -1) {
      return state.setIn(['savedSearches', 'items', index], fromJS(payload))
    }

    return state.updateIn(['savedSearches', 'items'], list => list.push(fromJS(payload)))
  },
  [Actions.deleteThemaxSavedSearchSuccess]: (state, { payload }) => {
    const items = state.getIn(['savedSearches', 'items'])
    const index = items.findIndex(item => item.get('id') === payload.id)

    if (index !== -1) {
      return state.deleteIn(['savedSearches', 'items', index])
    }

    return state
  },
  [Actions.favoriteThemaxSavedSearchSuccess]: (state, { payload }) => {
    let newState = state
    const items = state.getIn(['savedSearches', 'items'])

    const currentFavoriteIndex = items.findIndex(item => item.get('favorite') === true)

    if (currentFavoriteIndex !== -1) {
      newState = newState.setIn(['savedSearches', 'items', currentFavoriteIndex, 'favorite'], false)
    }

    const newFavoriteIndex = items.findIndex(item => item.get('id') === payload.id)

    if (newFavoriteIndex !== -1) {
      return newState.setIn(['savedSearches', 'items', newFavoriteIndex], fromJS(payload))
    }

    return newState
  },
  [Actions.setSelectedEditorialPlan]: (state, { payload }) => state.set('selectedEditorialPlan', payload),
  [Actions.themaxSaveBookmarkSuccess]: (state, { payload: { response, editorialPlanId } }) => {
    const grootEditorialPlans = fromJS([response.grootEditorialPlan])
    const editorialPlans = state.getIn(['editorialPlans', 'items'])
    const index = editorialPlans.findIndex(item => item.get('id') === editorialPlanId)

    let newState = state

    if (index !== -1) {
      const editorialPlan = editorialPlans.get(index).set('grootEditorialPlans', grootEditorialPlans)

      newState = newState.setIn(['editorialPlans', 'items', index], editorialPlan)
    }

    const relatedEditorialPlans = state.getIn(['relatedEditorialPlans', 'items'])
    const relatedIndex = relatedEditorialPlans.findIndex(item => item.get('id') === editorialPlanId)

    if (relatedIndex !== -1) {
      const editorialPlan = relatedEditorialPlans.get(relatedIndex).set('grootEditorialPlans', grootEditorialPlans)

      newState = newState.setIn(['relatedEditorialPlans', 'items', relatedIndex], editorialPlan)
    }

    if (state.getIn(['selectedEditorialPlan', 'id']) === editorialPlanId) {
      newState = newState.setIn(['selectedEditorialPlan', 'grootEditorialPlans'], grootEditorialPlans)
    }

    return newState
  },
  [Actions.themaxDeleteBookmarkSuccess]: (state, { payload: { editorialPlanId } }) => {
    const editorialPlans = state.getIn(['editorialPlans', 'items'])
    const index = editorialPlans.findIndex(item => item.get('id') === editorialPlanId)

    let newState = state

    if (index !== -1) {
      const editorialPlan = editorialPlans.get(index).set('grootEditorialPlans', fromJS([]))

      newState = newState.setIn(['editorialPlans', 'items', index], editorialPlan)
    }

    const relatedEditorialPlans = state.getIn(['relatedEditorialPlans', 'items'])
    const relatedIndex = relatedEditorialPlans.findIndex(item => item.get('id') === editorialPlanId)

    if (relatedIndex !== -1) {
      const editorialPlan = relatedEditorialPlans.get(relatedIndex).set('grootEditorialPlans', fromJS([]))

      newState = newState.setIn(['relatedEditorialPlans', 'items', relatedIndex], editorialPlan)
    }

    if (state.getIn(['selectedEditorialPlan', 'id']) === editorialPlanId) {
      newState = newState.setIn(['selectedEditorialPlan', 'grootEditorialPlans'], fromJS([]))
    }

    return newState
  },
  [Actions.closeDetailedViewDialog]: state => state
    .set('selectedEditorialPlan', Map())
    .setIn(['relatedEditorialPlans', 'items'], List())
    .setIn(['relatedEditorialPlans', 'total'], 0),

  [AppActions.resetState]: () => initialState
}, initialState)
