import React from 'react'
import PropTypes from 'prop-types'

import { Dialog, Button } from 'containers/themed'

import { DialogContent, DialogContentText } from '@mui/material'

export default function ConfirmDialog({
  active,
  deleting,
  i18n,
  title,
  payload,
  text,
  actionText,
  autoHide,
  onSubmit,
  onHide
}) {
  const handleOk = () => {
    onSubmit(payload)

    if (autoHide) {
      onHide()
    }
  }

  const renderActions = () => {
    const actions = [
      <Button
        key="cancel"
        onClick={() => onHide()}
      >
        {i18n.get('cancel')}
      </Button>,
      <Button
        key="save"
        loading={deleting}
        disabled={deleting}
        color="primary"
        variant="contained"
        onClick={() => handleOk()}
      >
        {actionText || i18n.get('delete')}
      </Button>
    ]

    return actions
  }

  return (
    <Dialog
      open={active}
      title={title}
      actions={renderActions()}
      onClose={onHide}
    >
      <DialogContent>
        <DialogContentText>
          {text}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  deleting: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  title: PropTypes.string,
  payload: PropTypes.any,
  text: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  autoHide: PropTypes.bool,

  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
}
