import { connect } from 'react-redux'

import Contacts from 'components/contact_management/contacts/Contacts'

import {
  setContactsPage,
  toggleContactsShowUnsubscribed,
  fetchContactsStart
} from 'actions/contact_management'

import {
  getCmContacts,
  getCmContactPage,
  getCmContactsTotal,
  getCmContactsSearchRunning,
  getCmContactSearchQueries,
  getCmShowUnsubscribed
} from 'selectors'

const mapStateToProps = state => ({
  contacts: getCmContacts(state),
  page: getCmContactPage(state),
  total: getCmContactsTotal(state),
  loading: getCmContactsSearchRunning(state),
  hasSearchQueries: getCmContactSearchQueries(state).size > 0,
  showUnsubscribed: getCmShowUnsubscribed(state)
})

export default connect(mapStateToProps, {
  onPageChange: setContactsPage,
  toggleUnsubscribed: toggleContactsShowUnsubscribed,
  fetchContacts: fetchContactsStart
})(Contacts)
