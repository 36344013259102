import { listMove } from 'utils/immutable'

export const newsIdFromDraggableId = draggableId => (
  parseInt(draggableId.substr(draggableId.indexOf('_') + 1), 10)
)

export const reorder = (list, startIndex, endIndex) => (
  listMove(list, startIndex, endIndex)
)

const reorderSingleDrag = ({
  draggableIds,
  _selectedDraggableIds,
  sourceIndex,
  destinationIndex
}) => (
  reorder(
    draggableIds,
    sourceIndex,
    destinationIndex
  )
)

const reorderMultiDrag = ({
  draggableIds,
  selectedDraggableIds,
  destinationIndex
}) => {
  const orderedSelectedDraggableIds = [...selectedDraggableIds]
  orderedSelectedDraggableIds.sort(
    (a, b) => {
      const indexOfA = draggableIds.indexOf(a)
      const indexOfB = draggableIds.indexOf(b)

      if (indexOfA !== indexOfB) {
        return indexOfA - indexOfB
      }

      return -1
    }
  )

  const remainingDraggableIds = draggableIds.filter(id => !selectedDraggableIds.includes(id))
  const withInserted = remainingDraggableIds.splice(destinationIndex, 0, ...orderedSelectedDraggableIds)

  return withInserted
}

export const multiDragAwareReorder = args => {
  if (args.selectedDraggableIds.size > 1) {
    return reorderMultiDrag(args)
  }

  return reorderSingleDrag(args)
}
