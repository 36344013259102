import { createAction } from 'redux-actions'

const defaultPrefix = 'CM/'
let prefix = defaultPrefix

// DATA
prefix = `${defaultPrefix}DATA/`
export const fetchAll = createAction(`${prefix}FETCH_ALL`)

export const fetchContactsStart = createAction(`${prefix}FETCH_CONTACTS_START`)
export const fetchContactsSuccess = createAction(`${prefix}FETCH_CONTACTS_SUCCESS`)
export const fetchContactsError = createAction(`${prefix}FETCH_CONTACTS_ERROR`)

export const fetchDistributionListsStart = createAction(`${prefix}FETCH_DISTRIBUTION_LISTS_START`)
export const fetchDistributionListsSuccess = createAction(`${prefix}FETCH_DISRIBUTION_LISTS_SUCCESS`)
export const fetchDistributionListsError = createAction(`${prefix}FETCH_DISTRIBUTION_LISTS_ERROR`)

export const fetchTagsStart = createAction(`${prefix}FETCH_TAGS_START`)
export const fetchTagsSuccess = createAction(`${prefix}FETCH_TAGS_SUCCESS`)
export const fetchTagsError = createAction(`${prefix}FETCH_TAGS_ERROR`)

export const saveContactsStart = createAction(`${prefix}SAVE_CONTACTS_START`)
export const saveContactsSuccess = createAction(`${prefix}SAVE_CONTACTS_SUCCESS`)
export const saveContactsError = createAction(`${prefix}SAVE_CONTACTS_ERROR`)

export const deleteContactsStart = createAction(`${prefix}DELETE_CONTACTS_START`)
export const deleteContactsSuccess = createAction(`${prefix}DELETE_CONTACTS_SUCCESS`)
export const deleteContactsError = createAction(`${prefix}DELETE_CONTACTS_ERROR`)

export const changeTagStart = createAction(`${prefix}CHANGE_TAG_START`)
export const changeTagSuccess = createAction(`${prefix}CHANGE_TAG_SUCCESS`)
export const changeTagError = createAction(`${prefix}CHANGE_TAG_ERROR`)

export const renameTagStart = createAction(`${prefix}RENAME_TAG_START`)
export const renameTagSuccess = createAction(`${prefix}RENAME_TAG_SUCCESS`)
export const renameTagError = createAction(`${prefix}RENAME_TAG_ERROR`)

export const deleteTagStart = createAction(`${prefix}DELETE_TAG_START`)
export const deleteTagSuccess = createAction(`${prefix}DELETE_TAG_SUCCESS`)
export const deleteTagError = createAction(`${prefix}DELETE_TAG_ERROR`)

export const createDistributionListStart = createAction(`${prefix}CREATE_DISTRIBUTION_LIST_START`)
export const createDistributionListSuccess = createAction(`${prefix}CREATE_DISTRIBUTION_LIST_SUCCESS`)
export const createDistributionListError = createAction(`${prefix}CREATE_DISTRIBUTION_LIST_ERROR`)

export const addContactsToDistributionListStart = createAction(`${prefix}ADD_CONTACTS_TO_DISTRIBUTION_LIST_START`)
export const addContactsToDistributionListSuccess = createAction(`${prefix}ADD_CONTACTS_TO_DISTRIBUTION_LIST_SUCCESS`)
export const addContactsToDistributionListError = createAction(`${prefix}ADD_CONTACTS_TO_DISTRIBUTION_LIST_ERROR`)

export const removeContactFromListStart = createAction(`${prefix}REMOVE_CONTACT_FROM_LIST_START`)
export const removeContactFromListSuccess = createAction(`${prefix}REMOVE_CONTACT_FROM_LIST_SUCCESS`)
export const removeContactFromListError = createAction(`${prefix}REMOVE_CONTACT_FROM_LIST_ERROR`)

export const updateDistributionListStart = createAction(`${prefix}UPDATE_DISTRIBUTION_LIST_START`)
export const updateDistributionListSuccess = createAction(`${prefix}UPDATE_DISTRIBUTION_LIST_SUCCESS`)
export const updateDistributionListError = createAction(`${prefix}UPDATE_DISTRIBUTION_LIST_ERROR`)

export const deleteDistributionListStart = createAction(`${prefix}DELETE_DISTRIBUTION_LIST_START`)
export const deleteDistributionListSuccess = createAction(`${prefix}DELETE_DISTRIBUTION_LIST_SUCCESS`)
export const deleteDistributionListError = createAction(`${prefix}DELETE_DISTRIBUTION_LIST_ERROR`)

export const uploadContactsStart = createAction(`${prefix}UPLOAD_CONTACTS_START`)
export const uploadContactsSuccess = createAction(`${prefix}UPLOAD_CONTACTS_SUCCESS`)
export const uploadContactsError = createAction(`${prefix}UPLOAD_CONTACTS_ERROR`)

export const setUploadedPhoto = createAction(`${prefix}SET_UPLOADED_PHOTO`)
export const setContactTags = createAction(`${prefix}SET_TAGS`)
export const setDistributionList = createAction(`${prefix}SET_DISTRIBUTION_LIST`)

export const changeContactFormData = createAction(`${prefix}CHANGE_CONTACT_FORM_DATA`)
export const changeContactMediaTopics = createAction(`${prefix}CHANGE_MEDIA_TOPICS`)
export const changeContactLanguages = createAction(`${prefix}CHANGE_LANGUAGES`)
export const changeContactMediaTypes = createAction(`${prefix}CHANGE_MEDIA_TYPES`)
export const createTag = createAction(`${prefix}CREATE_TAG`)
export const updateDistributionListFormData = createAction(`${prefix}UPDATE_DISTRIBUTION_LIST_FORM_DATA`)
export const selectContact = createAction(`${prefix}SELECT_CONTACT`)
export const selectAllContacts = createAction(`${prefix}SELECT_ALL_CONTACTS`)
export const clearListData = createAction(`${prefix}CLEAR_LIST_DATA`)
export const selectDistributionList = createAction(`${prefix}SELECT_DISTRIBUTION_LIST`)
export const clearSelectedLists = createAction(`${prefix}CLEAR_SELECTED_LISTS`)
export const setMergedDistributionListData = createAction(`${prefix}SET_MERGED_DISTRIBUTION_LIST_DATA`)

export const downloadContactsExcelStart = createAction(`${prefix}DOWNLOAD_CONTACTS_EXCEL_START`)
export const downloadContactsExcelSuccess = createAction(`${prefix}DOWNLOAD_CONTACTS_EXCEL_SUCCESS`)
export const downloadContactsExcelError = createAction(`${prefix}DOWNLOAD_CONTACTS_EXCEL_ERROR`)

export const mergeDistributionListsStart = createAction(`${prefix}MERGE_DISTRIBUTION_LISTS_START`)
export const mergeDistributionListsSuccess = createAction(`${prefix}MERGE_DISTRIBUTION_LISTS_SUCCESS`)
export const mergeDistributionListsError = createAction(`${prefix}MERGE_DISTRIBUTION_LISTS_ERROR`)

export const downloadDistributionListStart = createAction(`${prefix}DOWNLOAD_DISTRIBUTION_LIST_START`)
export const downloadDistributionListSuccess = createAction(`${prefix}DOWNLOAD_DISTRIBUTION_LIST_SUCCESS`)
export const downloadDistributionListError = createAction(`${prefix}DOWNLOAD_DISTRIBUTION_LIST_ERROR`)

export const searchDistributionListStart = createAction(`${prefix}SEARCH_DISTRIBUTION_LIST_START`)
export const searchDistributionListSuccess = createAction(`${prefix}SEARCH_DISTRIBUTION_LIST_SUCCESS`)
export const searchDistributionListError = createAction(`${prefix}SEARCH_DISTRIBUTION_LIST_ERROR`)

// UI
prefix = `${defaultPrefix}UI/`

export const openContactEditDialog = createAction(`${prefix}OPEN_CONTACT_EDIT_DIALOG`)
export const closeContactEditDialog = createAction(`${prefix}CLOSE_CONTACT_EDIT_DIALOG`)

export const openContactViewDialog = createAction(`${prefix}OPEN_CONTACT_VIEW_DIALOG`)
export const closeContactViewDialog = createAction(`${prefix}CLOSE_CONTACT_VIEW_DIALOG`)

export const openContactsUploadDialog = createAction(`${prefix}OPEN_CONTACTS_UPLOAD_DIALOG`)
export const closeContactsUploadDialog = createAction(`${prefix}CLOSE_CONTACTS_UPLOAD_DIALOG`)

export const openContactDeleteDialog = createAction(`${prefix}OPEN_CONTACT_DELETE_DIALOG`)
export const closeContactDeleteDialog = createAction(`${prefix}CLOSE_CONTACT_DELETE_DIALOG`)

export const openTagsDialog = createAction(`${prefix}OPEN_TAGS_DIALOG`)
export const closeTagsDialog = createAction(`${prefix}CLOSE_TAGS_DIALOG`)

export const openTagRenameDialog = createAction(`${prefix}OPEN_TAG_RENAME_DIALOG`)
export const closeTagRenameDialog = createAction(`${prefix}CLOSE_TAG_RENAME_DIALOG`)

export const openTagDeleteDialog = createAction(`${prefix}OPEN_TAG_DELETE_DIALOG`)
export const closeTagDeleteDialog = createAction(`${prefix}CLOSE_TAG_DELETE_DIALOG`)

export const openDistributionListCreateDialog = createAction(`${prefix}OPEN_DISTRIBUTION_LIST_CREATE_DIALOG`)
export const closeDistributionListCreateDialog = createAction(`${prefix}CLOSE_DISTRIBUTION_LIST_CREATE_DIALOG`)

export const openDistributionListEditDialog = createAction(`${prefix}OPEN_DISTRIBUTION_LIST_EDIT_DIALOG`)
export const closeDistributionListEditDialog = createAction(`${prefix}CLOSE_DISTRIBUTION_LIST_EDIT_DIALOG`)

export const openDistributionListViewDialog = createAction(`${prefix}OPEN_DISTRIBUTION_LIST_VIEW_DIALOG`)
export const closeDistributionListViewDialog = createAction(`${prefix}CLOSE_DISTRIBUTION_LIST_VIEW_DIALOG`)

export const sortBy = createAction(`${prefix}SORT_BY`)
export const listSortBy = createAction(`${prefix}LIST_SORT_BY`)
export const tagsSortBy = createAction(`${prefix}TAGS_SORT_BY`)

export const setSelectedQueryType = createAction(`${prefix}SET_SELECTED_QUERY_TYPE`)

export const openImportHelpDialog = createAction(`${prefix}OPEN_IMPORT_HELP_DIALOG`)
export const closeImportHelpDialog = createAction(`${prefix}CLOSE_IMPORT_HELP_DIALOG`)

export const uiEnteredQuery = createAction(`${prefix}ENTERED_QUERY`)
export const uiRemoveFilter = createAction(`${prefix}REMOVE_FILTER`)
export const uiUpdateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const toggleActiveFilters = createAction(`${prefix}TOGGLE_ACTIVE_FILTERS`)

export const showEmailExistingDialog = createAction(`${prefix}SHOW_EMAIL_EXISTING_DIALOG`)
export const closeEmailExistingDialog = createAction(`${prefix}CLOSE_EMAIL_EXISTING_DIALOG`)

export const showUploadResultDialog = createAction(`${prefix}SHOW_UPLOAD_RESULT_DIALOG`)
export const closeUploadResultDialog = createAction(`${prefix}CLOSE_UPLOAD_RESULT_DIALOG`)

export const setAutoCreateDistributionList = createAction(`${prefix}SET_AUTO_CREATE_DISTRIBUTION_LIST`)
export const setAutoAddToExistingList = createAction(`${prefix}SET_AUTO_ADD_TO_EXISTING_LIST`)

export const openDistributionListMergeDialog = createAction(`${prefix}OPEN_DISTRIBUTION_LIST_MERGE_DIALOG`)
export const closeDistributionListMergeDialog = createAction(`${prefix}CLOSE_DISTRIBUTION_LIST_MERGE_DIALOG`)

// FILTER
prefix = `${defaultPrefix}FILTER/`
export const addSearchQuery = createAction(`${prefix}ADD_SEARCH_QUERY`)
export const removeFilter = createAction(`${prefix}REMOVE_FILTER`)
export const updateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const addDistributionListContactFilter = createAction(`${prefix}ADD_DISTRIBUTION_LIST_CONTACT_FILTER`)
export const removeDistributionListContactFilter = createAction(`${prefix}REMOVE_DISTRIBUTION_LIST_CONTACT_FILTER`)
export const addDistributionListContactsSearchQuery = createAction(`${prefix}ADD_DISTRIBUTION_LIST_CONTACTS_SEARCH_QUERY`)
export const resetFilters = createAction(`${prefix}RESET_FILTER`)
export const setContactsPage = createAction(`${prefix}SET_CONTACTS_PAGE`)
export const setDistributionListsPage = createAction(`${prefix}SET_DISTRIBUTION_LISTS_PAGE`)
export const toggleContactsShowUnsubscribed = createAction(`${prefix}TOGGLE_CONTACTS_SHOW_UNSUBSCRIBED`)
