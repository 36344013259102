const imageLinks = {
  GOOGLE_ANALYTICS_LOGO: 'https://static.pressrelations.de/pics/groot/logos/google_analytics_logo.svg',
  YOUTUBE_ANALYTICS_LOGO: 'https://static.pressrelations.de/pics/groot/logos/youtube_analytics_logo.png',
  LINKEDIN_ANALYTICS_LOGO: 'https://static.pressrelations.de/pics/groot/logos/linked_in_logo.png',
  FACEBOOK_ANALYTICS_LOGO: 'https://static.pressrelations.de/pics/groot/logos/facebook_analytics_logo.png',
  TWITTER_ANALYTICS_LOGO: 'https://static.pressrelations.de/pics/groot/logos/twitter_logo.png',
  MOZ_LOGO: 'https://static.pressrelations.de/pics/groot/moz/moz.png',
  MOZ_LOGO_WHITE: 'https://static.pressrelations.de/pics/groot/moz/moz_white.png',

  IMAGE_PLACEHOLDER: 'https://static.pressrelations.de/pics/groot/placeholder.png',
  PLAY_IMAGE: 'https://static.pressrelations.de/pics/clipping_play_icon.png',
  TIKTOK_DUMMY_IMAGE: 'https://static.pressrelations.de/pics/groot/tiktok_dummy.png',
  MAILING_LIST_IMAGE: 'https://static.pressrelations.de/pics/groot/mailing_list.png',
  WORLD_MAP_COLOR: 'https://static.pressrelations.de/pics/groot/world_map_color.png',
  WORLD_MAP_BW: 'https://static.pressrelations.de/pics/groot/world_map_bw.png',

  NRX_LOGO_TRANSPARENT: 'https://static.pressrelations.de/pics/groot/logos/nrx_logo_transparent.png',
  NRX_LOGO_COLOR: 'https://static.pressrelations.de/pics/groot/logos/nrx_logo_color.png'
}

export default imageLinks
