import { call, put, select, takeEvery, race, take, all, delay } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import { listChunk } from 'utils/immutable'
import { scrollIntoView } from 'utils/scroll'

import { SearchFields, CustomTagsNewsTypes } from 'static/constants'

import * as Actions from 'actions/custom_tags'
import * as Api from 'api/bff'
import * as AppActions from 'actions/app'
import * as FilterActions from 'actions/filter'
import * as UiActions from 'actions/ui'
import * as ShoppingCartActions from 'actions/shopping_cart'
import * as MediaReviewsActions from 'actions/media_reviews'
import * as NewsActions from 'actions/news'
import * as Selectors from 'selectors'
import { navigate } from 'actions/navigation'
import { getCluster } from 'utils/sagas'

export function* saveCustomTag() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const customTag = yield select(Selectors.getSelectedCustomTag)
    const uploadedPhoto = yield select(Selectors.getUploadedCustomTagPhoto)
    const moduleName = yield select(Selectors.getViewConfigModuleName)
    const newsToAdd = yield select(Selectors.getCustomTagsAssignCustomTagsToNewsSelectedNews)
    const shoppingCartNewsToAdd = yield select(Selectors.getShoppingCartNews)
    const autoAddShoppingCartNews = yield select(Selectors.getAutoAddShoppingCartNews)
    const mediaReviewsNewsToAdd = yield select(Selectors.getMediaReviewSelectedNews)
    const autoAddMediaReviewsNews = yield select(Selectors.getAutoAddMediaReviewsNews)

    let photo = customTag.get('photo')

    if (uploadedPhoto) {
      const formData = [
        {
          key: 'file',
          value: uploadedPhoto
        }
      ]

      const { url } = yield call(Api.uploadFile, formData)
      photo = url
    }

    const body = {
      name_de: customTag.get('nameDe'),
      name_en: customTag.get('nameEn'),
      name_fr: customTag.get('nameFr'),
      name_ru: customTag.get('nameRu'),
      name_zh: customTag.get('nameZh'),
      name_ja: customTag.get('nameJa'),
      description: customTag.get('description'),
      queries: customTag.get('queries'),
      percolation_enabled: customTag.get('percolationEnabled'),
      news_count: customTag.get('newsCount'),
      news_type: CustomTagsNewsTypes[moduleName],
      rss_enabled: customTag.get('rssEnabled'),
      xml_enabled: customTag.get('xmlEnabled'),
      json_enabled: customTag.get('jsonEnabled'),
      color: customTag.get('color'),
      custom_tag_group_ids: customTag.get('customTagGroupIds'),
      photo
    }

    let result
    let successMessage = i18n.get('created')

    if (customTag.get('id')) {
      successMessage = i18n.get('updated')
      result = yield call(Api.updateCustomTag, customTag.get('id'), body)
    } else {
      result = yield call(Api.createCustomTag, body)
    }

    yield put(Actions.saveCustomTagSuccess({ customTag: result }))

    if (newsToAdd) {
      yield put(Actions.assignCustomTagToNewsStart({ customTag: fromJS(result), news: newsToAdd }))
    }

    if (shoppingCartNewsToAdd && autoAddShoppingCartNews && !newsToAdd) {
      yield put(ShoppingCartActions.pinAllStart({ customTag: fromJS(result), action: 'pin' }))
    }

    if (mediaReviewsNewsToAdd && autoAddMediaReviewsNews && !newsToAdd) {
      yield put(MediaReviewsActions.pinAllStart({ customTag: fromJS(result), action: 'pin' }))
    }

    if (!autoAddMediaReviewsNews && !autoAddShoppingCartNews) {
      yield put(AppActions.showAppMessage({ text: `<b>${result.name}</b> ${successMessage}` }))
    }
  } catch (error) {
    yield put(Actions.saveCustomTagError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* emptyCustomTag() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const customTag = yield select(Selectors.getSelectedCustomTag)

    yield put(Actions.hideEmptyCustomTagDialog())
    yield put(AppActions.showUndo())
    yield put(Actions.emptyCustomTagSuccess(customTag))
    yield put(AppActions.showAppMessage({ text: `<b>${customTag.get('name')}</b> ${i18n.get('emptied')}` }))

    const { undo, deleteIt } = yield race({
      undo: take(AppActions.undo),
      deleteIt: delay(5000)
    })

    yield put(AppActions.hideUndo())

    if (undo) {
      yield put(Actions.saveCustomTagSuccess({ customTag: customTag.toJS(), withNewsCount: true }))
      yield put(AppActions.showAppMessage({ text: i18n.get('undone') }))
    } else if (deleteIt) {
      const newsType = yield select(Selectors.getViewConfigIndexType)
      const body = {
        id: customTag.get('id'),
        news_type: newsType
      }

      yield call(Api.emptyCustomTag, body)
    }
  } catch (error) {
    yield put(Actions.emptyCustomTagError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* deleteCustomTag() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const customTag = yield select(Selectors.getSelectedCustomTag)

    yield put(Actions.hideRemoveCustomTagDialog())
    yield put(AppActions.showUndo())
    yield put(Actions.deleteCustomTagSuccess(customTag.get('id')))
    yield put(AppActions.showAppMessage({ text: `<b>${customTag.get('name')}</b> ${i18n.get('deleted')}` }))

    const { undo, deleteIt } = yield race({
      undo: take(AppActions.undo),
      deleteIt: delay(5000)
    })

    yield put(AppActions.hideUndo())

    if (undo) {
      yield put(Actions.saveCustomTagSuccess({ customTag: customTag.toJS() }))
      yield put(AppActions.showAppMessage({ text: i18n.get('undone') }))
    } else if (deleteIt) {
      const newsType = yield select(Selectors.getViewConfigIndexType)
      const body = {
        id: customTag.get('id'),
        news_type: newsType,
        _method: 'DELETE'
      }
      yield call(Api.deleteCustomTag, customTag.get('id'), body)
    }
  } catch (error) {
    yield put(Actions.deleteCustomTagError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* assignCustomTagToNews({ payload: { customTag, news } }) {
  try {
    const clusterSize = news.get('clusterSize')
    const moduleName = yield select(Selectors.getViewConfigModuleName)
    let newNews = news

    if (clusterSize > 1 && news.get('clusteredNews').size !== clusterSize - 1) {
      const clusteredNews = yield call(getCluster, news)

      newNews = newNews.set('clusteredNews', clusteredNews)
    }

    const clusterNewsIds = (newNews.get('clusteredNews') || fromJS([])).unshift(newNews)

    const body = fromJS({
      data: clusterNewsIds.map(cn => (
        fromJS({
          '+custom_tag_ids': [customTag.get('id')],
          news_id: cn.get('id'),
          article_date: cn.get('articleDate')
        })
      )),
      news_type: CustomTagsNewsTypes[moduleName]
    })

    yield call(Api.assignCustomTagToNews, body)

    const rlyNewNews = newNews.update('customTags', customTags => (customTags.push(customTag.update('newsCount', count => count + 1))))

    yield put(NewsActions.updateNews(fromJS([rlyNewNews])))
    yield put(Actions.assignCustomTagToNewsSuccess({ customTag, news: newNews }))
  } catch (error) {
    yield put(Actions.assignCustomTagToNewsError({ error, customTag }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* removeCustomTagFromNews({ payload: { customTag, news } }) {
  try {
    let newNews = news

    const clusterSize = news.get('clusterSize')
    const moduleName = yield select(Selectors.getViewConfigModuleName)

    if (clusterSize > 1 && news.get('clusteredNews').size !== clusterSize - 1) {
      const clusteredNews = yield call(getCluster, news)

      newNews = newNews.set('clusteredNews', clusteredNews)
    }

    const clusterNewsIds = (newNews.get('clusteredNews') || fromJS([])).unshift(newNews)
    const body = fromJS({
      data: clusterNewsIds.map(cn => (
        fromJS({
          '-custom_tag_ids': [customTag.get('id')],
          news_id: cn.get('id'),
          article_date: cn.get('articleDate')
        })
      )),
      news_type: CustomTagsNewsTypes[moduleName]
    })

    yield call(Api.assignCustomTagToNews, body)

    const rlyNewNews = newNews.update('customTags', customTags => (customTags.filter(tag => tag.get('id') !== customTag.get('id'))))

    yield put(NewsActions.updateNews(fromJS([rlyNewNews])))
    yield put(Actions.removeCustomTagFromNewsSuccess({
      customTag,
      newsId: newNews.get('id'),
      removedCount: newNews.get('clusterSize') || 1
    }))
  } catch (error) {
    yield put(Actions.removeCustomTagFromNewsError({ error, customTag }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* assignCustomTagToGroup({ payload: customTagId }) {
  try {
    const groupId = yield select(Selectors.getCustomTagsAssignCustomTagsToGroupSelectedGroupId)

    const result = yield call(Api.assignCustomTagToGroup, groupId, customTagId)

    yield put(Actions.assignCustomTagToGroupSuccess({ groupId, result, customTagId }))
  } catch (error) {
    yield put(Actions.assignCustomTagToGroupError({ error, customTagId }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* removeCustomTagFromGroup({ payload: customTagId }) {
  try {
    const groupId = yield select(Selectors.getCustomTagsAssignCustomTagsToGroupSelectedGroupId)

    const { id } = yield call(Api.removeCustomTagFromGroup, groupId, customTagId)

    yield put(Actions.removeCustomTagFromGroupSuccess({ groupId, id, customTagId }))
  } catch (error) {
    yield put(Actions.removeCustomTagFromGroupError({ error, customTagId }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* fetchNewsForCustomTag({ payload: customTagId }) {
  try {
    const indexType = yield select(Selectors.getViewConfigIndexType)
    const body = {
      news: {
        booleans: {
          global_clusters: true,
          with_inner_hits: true
        }
      },
      size: 1000,
      index_type: indexType
    }

    const result = yield call(Api.fetchNewsForCustomTag, customTagId, body)

    yield put(NewsActions.addNews(result.news))

    yield put(Actions.fetchNewsForCustomTagSuccess(result))
    yield put(Actions.resetCustomTagNewNewsCount(customTagId))
  } catch (error) {
    yield put(Actions.fetchNewsForCustomTagError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* filterBySelectedCustomTag({ payload: customTag }) {
  const moduleNameName = yield select(Selectors.getMainModule)

  yield put(navigate(`/app/${moduleNameName}`))
  const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
  yield put(FilterActions.resetFilters(globalClusterDefault))
  yield put(FilterActions.changeDate({
    dateFrom: null,
    dateTo: null
  }))
  yield put(UiActions.uiAddFilters([{
    filter: customTag,
    field: SearchFields.CUSTOM_TAGS
  }]))
}

export function* filterBySelectedCustomTagGroup({ payload: group }) {
  const moduleNameName = yield select(Selectors.getMainModule)
  yield put(navigate(`/app/${moduleNameName}`))
  const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
  yield put(FilterActions.resetFilters(globalClusterDefault))
  yield put(FilterActions.changeDate({
    dateFrom: null,
    dateTo: null
  }))
  yield put(UiActions.uiAddFilters([{
    filter: group,
    field: SearchFields.CUSTOM_TAG_GROUPS
  }]))
}

export function* runCustomTagQueries({ payload: customTag }) {
  yield put(Actions.hideCustomTagDialog())

  const moduleNameName = yield select(Selectors.getMainModule)
  yield put(navigate(`/app/${moduleNameName}`))
  const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
  yield put(FilterActions.resetFilters(globalClusterDefault))
  const i18n = yield select(Selectors.getI18n)

  yield put(FilterActions.changeDate({
    dateFrom: null,
    dateTo: null
  }))

  yield put(FilterActions.addFilters([{
    filter: customTag.set('inverted', true),
    field: SearchFields.CUSTOM_TAGS
  }]))

  const queries = customTag.get('queries').map(query => ({
    type: 'news',
    query,
    prefix: i18n.get('news')
  })).toJS()

  yield put(FilterActions.addNewsQueries(queries))
  yield put(NewsActions.newsRequestStart())
}

export function* openCustomTagInShoppingCart({ payload: customTag }) {
  yield put(navigate(`${customTag.get('id')}/shopping_cart`))
}

export function* removeCustomTagFromNewsChunked({ payload: { customTag, newsList, toBeRemovedIds } }) {
  try {
    const i18n = yield select(Selectors.getI18n)
    const moduleName = yield select(Selectors.getViewConfigModuleName)
    const data = newsList.map(n => {
      const clusteredUpdates = (n.get('clusteredNews') || fromJS([]))
        .filter(cn => !toBeRemovedIds || toBeRemovedIds.has(cn.get('id')))
        .map(cn => (
          fromJS({
            news_id: cn.get('id'),
            article_date: cn.get('articleDate'),
            '-custom_tag_ids': [customTag.get('id')]
          })
        ))

      return clusteredUpdates.unshift(fromJS({
        news_id: n.get('id'),
        article_date: n.get('articleDate'),
        '-custom_tag_ids': [customTag.get('id')]
      }))
    }).flatten(true)

    const chunkCount = Math.ceil(data.size / 10)
    const chunks = listChunk(data, chunkCount)

    yield put(AppActions.setAppBarMessage(i18n.get('purging_progress', { progress: '0%' })))

    for (let i = 0; i < chunks.size; i += 1) {
      const body = {
        data: chunks.get(i),
        news_type: CustomTagsNewsTypes[moduleName]
      }

      yield call(Api.assignCustomTagToNews, body)

      let progress = Math.ceil(((i + 1) / chunks.size) * 100)
      progress = `${progress}%`
      yield put(AppActions.setAppBarMessage(i18n.get('purging_progress', { progress })))
    }
    const news = newsList.map(n => n.update('customTags', customTags => (customTags.filter(tag => tag.get('id') !== customTag.get('id')))))

    yield put(NewsActions.updateNews(news))
    yield put(AppActions.setAppBarMessage(null))
    yield put(Actions.removeCustomTagFromNewsSuccess({ customTag, removedCount: data.size }))
  } catch (error) {
    yield put(Actions.removeCustomTagFromNewsError({ customTag, error }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* afterStructuredFormatExportAction() {
  try {
    const ui = yield select(Selectors.getCustomTagsUi)
    const action = ui.get('afterStructuredFormatExportAction')
    const customTag = yield select(Selectors.getShoppingCartCustomTag)

    switch (action) {
      case 'substitute': {
        const customTagNewsIds = yield select(Selectors.getNewsIdsForCustomTag)
        const customTagNews = yield select(Selectors.getNewsByIds, customTagNewsIds)
        const shoppingCartNews = yield select(Selectors.getShoppingCartNews)
        const newsToBeRemoved = customTagNews.filter(cN => shoppingCartNews.findIndex(sN => sN.get('id') === cN.get('id')) === -1)
        yield call(removeCustomTagFromNewsChunked, { payload: { customTag, newsList: newsToBeRemoved } })
        yield put(Actions.afterStructuredFormatExportActionSuccess())
        break
      }
      case 'purge': {
        const newsIds = yield select(Selectors.getNewsIdsForCustomTag)
        const news = yield select(Selectors.getNewsByIds, newsIds)
        yield call(removeCustomTagFromNewsChunked, { payload: { customTag, newsList: news } })
        yield put(ShoppingCartActions.removeNewsFromShoppingCart(newsIds))
        yield put(Actions.afterStructuredFormatExportActionSuccess())
        break
      }
      default:
        yield put(Actions.afterStructuredFormatExportActionSuccess())
        break
    }
  } catch (error) {
    yield put(Actions.afterStructuredFormatExportActionError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* saveGroup() {
  try {
    const group = yield select(Selectors.getSelectedCustomTagGroup)

    const body = {
      name: group.get('name')
    }

    let result

    if (group.get('id')) {
      result = yield call(Api.updateCustomTagGroup, group.get('id'), body)
    } else {
      result = yield call(Api.createCustomTagGroup, body)
    }

    yield put(Actions.saveGroupSuccess(result))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.saveGroupError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* deleteGroup() {
  try {
    const group = yield select(Selectors.getSelectedCustomTagGroup)

    yield call(Api.deleteCustomTagGroup, group.get('id'))

    yield put(Actions.deleteGroupSuccess(group))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.deleteGroupError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* setFilteredGroupId() {
  yield delay(200)
  const id = yield select(Selectors.getCustomTagGroupsHeaderId)
  yield call(scrollIntoView, id)
}

export function* watchSaveCustomTag() {
  yield takeEvery(Actions.saveCustomTagStart, saveCustomTag)
}

export function* watchDeleteCustomTag() {
  yield takeEvery(Actions.deleteCustomTagStart, deleteCustomTag)
}

export function* watchEmptyCustomTag() {
  yield takeEvery(Actions.emptyCustomTagStart, emptyCustomTag)
}

export function* watchAssignCustomTagToNews() {
  yield takeEvery(Actions.assignCustomTagToNewsStart, assignCustomTagToNews)
}

export function* watchRemoveCustomTagFromNews() {
  yield takeEvery(Actions.removeCustomTagFromNewsStart, removeCustomTagFromNews)
}

export function* watchAssignCustomTagToGroup() {
  yield takeEvery(Actions.assignCustomTagToGroupStart, assignCustomTagToGroup)
}

export function* watchRemoveCustomTagFromGroup() {
  yield takeEvery(Actions.removeCustomTagFromGroupStart, removeCustomTagFromGroup)
}

export function* watchFetchNewsForCustomTag() {
  yield takeEvery(Actions.fetchNewsForCustomTagStart, fetchNewsForCustomTag)
}

export function* watchFilterBySelectedCustomTag() {
  yield takeEvery(Actions.filterBySelectedCustomTag, filterBySelectedCustomTag)
}

export function* watchFilterBySelectedCustomTagGroup() {
  yield takeEvery(Actions.filterBySelectedCustomTagGroup, filterBySelectedCustomTagGroup)
}

export function* watchRunCustomTagQueries() {
  yield takeEvery(Actions.runCustomTagQueries, runCustomTagQueries)
}

export function* watchOpenCustomTagInShoppingCart() {
  yield takeEvery(Actions.openCustomTagInShoppingCart, openCustomTagInShoppingCart)
}

export function* watchAfterStructuredFormatExportAction() {
  yield takeEvery(Actions.afterStructuredFormatExportActionStart, afterStructuredFormatExportAction)
}

export function* watchSaveGroup() {
  yield takeEvery(Actions.saveGroupStart, saveGroup)
}

export function* watchDeleteGroup() {
  yield takeEvery(Actions.deleteGroupStart, deleteGroup)
}

export function* watchSetFilteredGroupId() {
  yield takeEvery(Actions.setFilteredGroupId, setFilteredGroupId)
}

export default function* saga() {
  yield all([
    watchOpenCustomTagInShoppingCart(),
    watchAfterStructuredFormatExportAction(),
    watchDeleteCustomTag(),
    watchEmptyCustomTag(),
    watchFetchNewsForCustomTag(),
    watchFilterBySelectedCustomTag(),
    watchFilterBySelectedCustomTagGroup(),
    watchRunCustomTagQueries(),
    watchAssignCustomTagToNews(),
    watchRemoveCustomTagFromNews(),
    watchAssignCustomTagToGroup(),
    watchRemoveCustomTagFromGroup(),
    watchSaveCustomTag(),
    watchSaveGroup(),
    watchDeleteGroup(),
    watchSetFilteredGroupId()
  ])
}
