import { fromJS } from 'immutable'
import { ChartDataSources } from './constants'

const config = fromJS([
  {
    value: 'ga_chart_timeline',
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'area'
    }
  },
  {
    value: 'ga_chart_timeline_countries',
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'ga:countryIsoCode',
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'horizontalBar'
    }
  },
  {
    value: 'ga_chart_countries',
    chart: {
      firstLevel: 'countries',
      secondLevel: null,
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'worldMap'
    }
  },
  {
    value: 'ga_chart_devices',
    chart: {
      firstLevel: 'ga:deviceCategory',
      secondLevel: null,
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'pie'
    }
  },
  {
    value: 'ga_chart_devices_browsers',
    chart: {
      firstLevel: 'ga:deviceCategory',
      secondLevel: 'ga:browser',
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'bar'
    }
  },
  {
    value: 'ga_chart_referrers',
    chart: {
      firstLevel: 'ga:source',
      secondLevel: null,
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'bar'
    }
  },
  {
    value: 'ga_chart_pages',
    chart: {
      firstLevel: 'ga:pagePath',
      secondLevel: null,
      thirdLevel: 'ga:pageviews',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'pie'
    }
  },
  {
    value: 'ga_chart_timeline_channel_grouping',
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'ga:channelGrouping',
      thirdLevel: 'ga:sessions',
      dataSource: ChartDataSources.GOOGLE_ANALYTICS,
      type: 'horizontalBar'
    }
  }
])

export default config

export const findValueByChart = chart => {
  const found = config.find(c => fromJS({
    firstLevel: chart.get('firstLevel'),
    secondLevel: chart.get('secondLevel'),
    dataSource: chart.get('dataSource')
  }).equals(c.get('chart').deleteAll(['thirdLevel', 'type'])))

  if (found) {
    return found.get('value')
  }

  return null
}
