import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import selected from './selected'
import edit from './edit'
import previous from './previous'
import drilldown from './drilldown'
import selectedChart from './selected_chart'

export default combineReducers({
  data,
  drilldown,
  edit,
  previous,
  selected,
  selectedChart,
  ui
})
