import { connect } from 'react-redux'

import { List } from 'immutable'
import DateForm from 'components/date_form'
import { uiApplyDateFilter, uiChooseQuickSelect, toggleDateForm } from 'actions/themax'
import {
  getThemaxSelectedDate,
  getLocale,
  getI18n,
  getDevice,
  getThemaxShowDateForm
} from 'selectors'

const mapStateToProps = state => {
  const selectedDate = getThemaxSelectedDate(state)

  return {
    active: getThemaxShowDateForm(state),
    dateFrom: selectedDate.get('from'),
    dateTo: selectedDate.get('to'),
    dateRange: selectedDate.get('range'),
    dateType: selectedDate.get('type'),
    locale: getLocale(state),
    i18n: getI18n(state),
    device: getDevice(state),
    allowedDateTypes: List(['releaseDate']),
    hasDateRestriction: false,
    timeLimit: 5, // irrelevant
    timeUnit: 'years', // irrelevant
    hasTimelineFilter: false
  }
}

export default connect(
  mapStateToProps,
  {
    uiApplyDateFilter,
    uiChooseQuickSelect,
    onChangeDateType: () => {},
    onClose: toggleDateForm
  }
)(DateForm)
