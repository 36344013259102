import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'

import { green, red } from '@mui/material/colors'

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
  Typography
} from '@mui/material'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/Save'

import { RaisedPrimaryButton, Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()((theme, { mobile }) => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogContent: {
    maxHeight: mobile ? '100vh' : '60vh',
    padding: '25px'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px'
  },
  noteWrapper: {
    marginBottom: '20px'
  },
  noteInfo: {
    display: 'flex',
    flexDirection: mobile ? 'column' : 'row',
    alignItems: mobile ? 'flex-start' : 'center',
    gap: mobile ? '0px' : '10px'
  },
  noteInfoAndActions: {
    display: 'flex',
    alignItems: mobile ? 'flex-start' : 'center',
    justifyContent: 'space-between'
  },
  addActionIcon: {
    marginLeft: '10px'
  },
  dateNameDivider: {
    height: '14px',
    border: '1px solid'
  },
  closeSaveActionsWrapper: {
    display: 'flex',
    gap: '10px'
  },
  deleteButton: {
    color: red[400]
  },
  confirmButton: {
    color: green[400]
  },
  cancelButton: {
    color: red[400]
  }
}))

const ContentNotesDialog = ({
  data,
  isOpen,
  userId,
  userFirstName,
  userLastName,
  saving,
  onChange,
  onClose,
  onSave
}) => {
  const device = useDevice()
  const mobile = device.get('mobile')
  const { classes } = useStyles({ mobile })
  const i18n = useI18n()

  const notes = data.get('notes')
  const status = data.get('status')

  const isViewOnly = status === 'released'

  const [currentNotes, setCurrentNotes] = useState(notes)
  const [editMode, setEditMode] = useState(null)

  useEffect(() => {
    setCurrentNotes(notes)
  }, [notes])

  const handleNoteChange = (text, index) => {
    setCurrentNotes(currentNotes.map((n, i) => {
      if (i === index) {
        return n.set('text', text)
      }

      return n
    }))
  }

  const handleAdd = () => {
    setCurrentNotes(List([fromJS({
      id: null,
      text: '',
      createdBy: userId,
      createdByAccount: {
        firstName: userFirstName,
        lastName: userLastName
      },
      createdAt: moment().toISOString()
    }), ...currentNotes]))
    setEditMode({ index: 0, lastValue: '' })
  }

  const handleConfirmClick = () => {
    setEditMode(null)
  }

  const handleCancelClick = index => {
    if (editMode.lastValue) {
      setCurrentNotes(currentNotes.map((n, i) => {
        if (i === index) {
          return n.set('text', editMode.lastValue)
        }

        return n
      }))
    } else {
      setCurrentNotes(currentNotes.filter((_n, i) => i !== index))
    }

    setEditMode(null)
  }

  const handleEditClick = (note, index) => {
    setEditMode({ index, lastValue: note.get('text') })
  }

  const handleDeleteClick = index => {
    setCurrentNotes(currentNotes.filter((_n, i) => i !== index))
  }

  const handleSave = () => {
    onChange({ key: 'notes', value: currentNotes })
    onSave()
  }

  const handleClose = () => {
    setEditMode(null)
    onClose({ closeContentNotesDialog: true })
  }

  const renderNoteAuthor = account => {
    const firstName = account.get('firstName') || ''
    const lastName = account.get('lastName') || ''
    const name = `${firstName} ${lastName}`.trim()

    return <Typography variant="button">{name}</Typography>
  }

  const renderNoteDate = date => (
    <Typography variant="button">{moment(date).format('lll')}</Typography>
  )

  const renderNoteActions = (note, index) => {
    if (editMode && editMode.index === index) {
      return (
        <Box>
          <IconButton
            className={classes.confirmButton}
            size="small"
            onClick={() => handleConfirmClick()}
            title={i18n.get('confirm')}
            disabled={note.get('text').length === 0}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            className={classes.cancelButton}
            size="small"
            onClick={() => handleCancelClick(index)}
            title={i18n.get('cancel')}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    }

    return (
      <Box>
        <IconButton
          size="small"
          onClick={() => handleEditClick(note, index)}
          title={i18n.get('edit')}
          disabled={!!(editMode) || userId !== note.get('createdBy') || saving || isViewOnly}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          className={classes.deleteButton}
          size="small"
          onClick={() => handleDeleteClick(index)}
          title={i18n.get('delete')}
          disabled={!!(editMode) || userId !== note.get('createdBy') || saving || isViewOnly}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    )
  }

  const renderDialogActions = () => {
    if (device.get('mobile') && device.get('xs')) {
      return (
        <>
          <IconButton
            color="primary"
            onClick={handleAdd}
            disabled={!!(editMode) || saving || isViewOnly}
            title={i18n.get('add')}
          >
            <AddIcon />
          </IconButton>
          <Box className={classes.closeSaveActionsWrapper}>
            <IconButton
              color="secondary"
              onClick={handleClose}
              disabled={saving}
              title={i18n.get('close')}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={handleSave}
              disabled={!!(editMode) || saving || isViewOnly}
              title={i18n.get('save')}
            >
              <SaveIcon />
            </IconButton>
          </Box>
        </>
      )
    }

    return (
      <>
        <RaisedPrimaryButton
          onClick={handleAdd}
          disabled={!!(editMode) || saving || isViewOnly}
        >
          {i18n.get('add')}
          <AddIcon className={classes.addActionIcon} />
        </RaisedPrimaryButton>
        <Box className={classes.closeSaveActionsWrapper}>
          <Button
            color="secondary"
            onClick={handleClose}
            disabled={saving}
          >
            {i18n.get('close')}
          </Button>
          <RaisedPrimaryButton
            onClick={handleSave}
            disabled={!!(editMode) || saving || isViewOnly}
            saving={saving}
          >
            {i18n.get('save')}
          </RaisedPrimaryButton>
        </Box>
      </>
    )
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      onClose={saving ? null : handleClose}
      open={isOpen}
      fullScreen={mobile}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('notes')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={saving ? null : handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {currentNotes.size === 0 && !isViewOnly && (
          <Typography variant="body1">{i18n.get('no_content_notes')}</Typography>
        )}
        {currentNotes.size === 0 && isViewOnly && (
          <Typography variant="body1">{i18n.get('no_content_notes_view_only')}</Typography>
        )}
        {currentNotes.map((n, i) => (
          <Box
            key={i}
            className={classes.noteWrapper}
          >
            <Box className={classes.noteInfoAndActions}>
              <Box className={classes.noteInfo}>
                {renderNoteDate(n.get('createdAt'))}
                {!mobile && (
                  <Divider
                    className={classes.dateNameDivider}
                    orientation="vertical"
                  />
                )}
                {renderNoteAuthor(n.get('createdByAccount'))}
              </Box>
              {renderNoteActions(n, i)}
            </Box>
            <TextField
              multiline
              inputRef={input => (editMode !== null && editMode.index === i ? input && input.focus() : input)}
              variant="standard"
              minRows={1}
              maxRows={4}
              fullWidth
              value={n.get('text')}
              onChange={e => handleNoteChange(e.target.value, i)}
              inputProps={{
                readOnly: !(editMode !== null && editMode.index === i),
                maxLength: 20000,
                style: { textAlign: 'justify', whiteSpace: 'normal', paddingRight: '10px' }
              }}
              disabled={saving || (editMode !== null && editMode.index !== i)}
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {renderDialogActions()}
      </DialogActions>
    </Dialog>
  )
}

ContentNotesDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  isOpen: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  userFirstName: PropTypes.string.isRequired,
  userLastName: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default ContentNotesDialog
