import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CheckIcon from '@mui/icons-material/Check'
import copy from 'copy-to-clipboard'
import green from '@mui/material/colors/green'
import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Chip } from '@mui/material'

const useStyles = makeStyles()({
  copyIcon: {
    fontSize: 20
  },
  checkIcon: {
    color: green[500],
    fontSize: 20,
    animation: `${keyframes`
            0% {
              opacity: 0;
              transform: scale(1);
            }
            25% {
              opacity: 1;
              transform: scale(2);
            }
            50% {
              opacity: 1;
              transform: scale(1);
            },
            100% {
              opacity: 0;
              transform: scale(1);
            }
          `} 1s;`
  }
})

export default function Query({ query, onDelete }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    copy(query)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <Chip
      label={query}
      avatar={(
        <IconButton
          title={i18n.get('copy_content')}
          onClick={() => {
            handleCopy(query)
          }}
        >
          {!copied && <ContentCopyIcon classes={{ root: classes.copyIcon }} />}
          {copied && <CheckIcon classes={{ root: classes.checkIcon }} />}
        </IconButton>
      )}
      onDelete={() => onDelete(query)}
    />
  )
}

Query.propTypes = {
  query: PropTypes.string.isRequired,

  onDelete: PropTypes.func.isRequired
}
