import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import tinycolor from 'tinycolor2'
import { makeStyles } from 'tss-react/mui'
import { grey } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  label: {
    color: theme.palette.grey[700],
    letterSpacing: 0.3,
    lineHeight: 1.5,
    fontWeight: 500,
    fontSize: 14
  },
  topLabel: {
    color: theme.palette.grey[500],
    fontSize: '10pt',
    letterSpacing: 0.3,
    lineHeight: 1,
    fontWeight: 400
  },
  labelContainer: {
    padding: '10px 10px 5px',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },
  labelOnly: {
    padding: '5px 10px'
  },
  topLabelOnly: {
    padding: '5px 10px',
    fallbacks: [{
      padding: '5px 10px'
    }]
  }
}))

const getStyle = (labelBackgroundColor, shade, forcedColor) => {
  if (forcedColor) {
    return { color: forcedColor }
  }

  let color

  if (labelBackgroundColor) {
    const tc = tinycolor(labelBackgroundColor)

    // We do not use the method isLight() on purpose! For example
    // the color #54B7A7 should be combined with a white font.
    if (tc.getBrightness() > 152) {
      color = grey[shade]
    } else {
      color = '#ffffff'
    }
  }

  return { color }
}

export default function Label({
  topLabel,
  label,
  labelBackgroundColor,
  labelColor,
  topLabelColor,
  classes: extraClasses
}) {
  const { classes, cx } = useStyles()
  const topLabelStyle = useMemo(() => getStyle(labelBackgroundColor, 800, topLabelColor), [labelBackgroundColor, topLabelColor])
  const labelStyle = useMemo(() => getStyle(labelBackgroundColor, 900, labelColor), [labelBackgroundColor, labelColor])

  if (!(topLabel || label)) {
    return null
  }

  let containerExtraClass

  if (label && !topLabel) {
    containerExtraClass = classes.labelOnly
  } else if (!label && topLabel) {
    containerExtraClass = classes.topLabelOnly
  }

  return (
    <div
      className={cx(classes.labelContainer, containerExtraClass, extraClasses.labelContainer)}
      style={{
        backgroundColor: labelBackgroundColor
      }}
    >
      {topLabel && (
        <div
          className={cx(classes.topLabel, extraClasses.topLabel)}
          style={topLabelStyle}
        >
          {topLabel}
        </div>
      )}

      {label && (
        <div
          className={cx(classes.label, extraClasses.label)}
          style={labelStyle}
        >
          {label}
        </div>
      )}
    </div>
  )
}

Label.defaultProps = {
  classes: {}
}

Label.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  topLabel: PropTypes.string,
  labelBackgroundColor: PropTypes.string,
  labelColor: PropTypes.string,
  topLabelColor: PropTypes.string
}
