import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'
import * as CmActions from 'actions/contact_management'
import * as Selectors from 'selectors'
import * as Api from 'api/contacts_management'

import { buildSort, buildFilter } from 'utils/anewstip'

export function* searchJournalistsStart() {
  const search = yield select(Selectors.getAnewstipSearchData)
  const allFilters = yield select(Selectors.getAnewstipFiltersData)

  const facetFilters = allFilters.getIn(['journalists', 'facetFilters'])

  const filters = {
    topic: buildFilter('topic', allFilters, facetFilters),
    media_type: buildFilter('mediaType', allFilters, facetFilters),
    country: buildFilter('country', allFilters, facetFilters),
    state: buildFilter('state', allFilters, facetFilters),
    city: buildFilter('city', allFilters, facetFilters),
    title: buildFilter('title', allFilters, facetFilters),
    lang: buildFilter('lang', allFilters, facetFilters)
  }

  const page = allFilters.getIn(['journalists', 'pagination', 'page'])
  const pageSize = allFilters.getIn(['journalists', 'pagination', 'pageSize'])
  const sortBy = allFilters.getIn(['journalists', 'sort', 'by'])
  const sortDirection = allFilters.getIn(['journalists', 'sort', 'direction'])

  const body = {
    q: search.get('query'),
    name: search.get('name'),
    title: search.get('jobTitle'),
    outlet: search.get('outletName'),
    filters,
    page,
    page_size: pageSize,
    facets: Object.keys(filters),
    sort: buildSort(sortBy, sortDirection),
    must_has_email: true,
    collapse_email: true
  }

  try {
    const result = yield call(Api.searchAnewstipJournalists, body)
    const { journalists, paginator, facets, limitedResults } = result

    if (limitedResults) {
      yield put(Actions.setLimitedFunctionality())
    }

    yield put(Actions.setEntity({ entity: 'journalists', value: { entities: journalists, paginator, facets } }))
    yield put(Actions.searchJournalistsSuccess())
  } catch (error) {
    yield put(Actions.searchJournalistsError(error))

    if (error.response.statusCode === 403) {
      yield put(Actions.lockNavigation())
      yield put(Actions.setLimitedFunctionality())
      yield put(Actions.switchResultsTab('outlets'))
    }

    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* searchOutletsStart() {
  const i18n = yield select(Selectors.getI18n)
  const search = yield select(Selectors.getAnewstipSearchData)
  const allFilters = yield select(Selectors.getAnewstipFiltersData)

  const facetFilters = allFilters.getIn(['outlets', 'facetFilters'])

  const filters = {
    topic: buildFilter('topic', allFilters, facetFilters),
    media_type: buildFilter('mediaType', allFilters, facetFilters),
    country: buildFilter('country', allFilters, facetFilters),
    state: buildFilter('state', allFilters, facetFilters),
    city: buildFilter('city', allFilters, facetFilters),
    lang: buildFilter('lang', allFilters, facetFilters)
  }

  const page = allFilters.getIn(['outlets', 'pagination', 'page'])
  const pageSize = allFilters.getIn(['outlets', 'pagination', 'pageSize'])
  const sortBy = allFilters.getIn(['outlets', 'sort', 'by'])
  const sortDirection = allFilters.getIn(['outlets', 'sort', 'direction'])

  const q = [search.get('query'), search.get('name'), search.get('jobTitle')]

  const body = {
    q: q.join(' ').trim(),
    name: search.get('outletName'),
    filters,
    page,
    page_size: pageSize,
    facets: Object.keys(filters),
    sort: buildSort(sortBy, sortDirection),
    must_has_email: true,
    collapse_email: true
  }

  try {
    const result = yield call(Api.searchAnewstipOutlets, body)
    const { outlets, paginator, facets } = result

    yield put(Actions.setEntity({ entity: 'outlets', value: { entities: outlets, paginator, facets } }))
    yield put(Actions.searchOutletsSuccess())
  } catch (error) {
    yield put(Actions.searchOutletsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* searchArticlesStart() {
  const search = yield select(Selectors.getAnewstipSearchData)
  const allFilters = yield select(Selectors.getAnewstipFiltersData)

  const facetFilters = allFilters.getIn(['articles', 'facetFilters'])

  const filters = {
    topic: buildFilter('topic', allFilters, facetFilters),
    media_type: buildFilter('mediaType', allFilters, facetFilters),
    country: buildFilter('country', allFilters, facetFilters),
    state: buildFilter('state', allFilters, facetFilters),
    city: buildFilter('city', allFilters, facetFilters),
    title: buildFilter('title', allFilters, facetFilters),
    lang: buildFilter('lang', allFilters, facetFilters),
    contact_type: buildFilter('contactType', allFilters, facetFilters)
  }

  const cursorToFetch = allFilters.getIn(['articles', 'pagination', 'cursor'])
  const pageSize = allFilters.getIn(['articles', 'pagination', 'pageSize'])
  const sortBy = allFilters.getIn(['articles', 'sort', 'by'])
  const sortDirection = allFilters.getIn(['articles', 'sort', 'direction'])

  const q = [search.get('query'), search.get('name'), search.get('jobTitle'), search.get('outletName')]

  const body = {
    q: q.join(' ').trim(),
    filters,
    cursor: cursorToFetch,
    page_size: pageSize,
    facets: Object.keys(filters),
    sort: buildSort(sortBy, sortDirection),
    must_has_email: true
  }

  try {
    const result = yield call(Api.searchAnewstipArticles, body)
    const { articles, cursor, previousCursor, facets } = result

    yield put(Actions.setEntity({ entity: 'articles', value: { entities: articles, cursor, previousCursor, facets } }))
    yield put(Actions.searchArticlesSuccess())
  } catch (error) {
    yield put(Actions.searchArticlesError(error))

    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* searchDrillDownJournalistsStart({ payload }) {
  const entity = payload
  const i18n = yield select(Selectors.getI18n)
  const drillDownFilters = yield select(Selectors.getAnewstipDrillDownFilters)
  const isLimitedFunctionality = yield select(Selectors.getIsLimitedFunctionality)

  if (isLimitedFunctionality) {
    return
  }

  const page = drillDownFilters.getIn(['journalists', 'pagination', 'page'])
  const pageSize = drillDownFilters.getIn(['journalists', 'pagination', 'pageSize'])

  const body = {
    page,
    page_size: pageSize,
    must_has_email: true,
    collapse_email: true
  }

  if (entity === 'journalist') {
    const contactId = drillDownFilters.get('contactId')
    body.filters = {
      topic_ids: drillDownFilters.get('topicIds'),
      topic: drillDownFilters
        .get('topics')
        .filter(topic => topic.get('active') === true)
        .map(topic => topic.get('name'))
    }

    if (contactId) {
      body.filters.exclude_contact_ids = [contactId]
    }
  }

  if (entity === 'outlet') {
    body.outlet_id = drillDownFilters.get('outletId')
  }

  try {
    // prevent empty search for journalists
    if (entity !== 'journalist' || body.filters.topic.size > 0) {
      const result = yield call(Api.searchAnewstipJournalists, body)
      const { journalists, paginator } = result
      const filteredJournalists = journalists.filter(journalist => journalist.id !== drillDownFilters.get('drillDownEntityId'))

      yield put(Actions.setDrillDownResult({ entity: 'journalists', value: { entities: filteredJournalists, paginator } }))
    }

    yield put(Actions.searchDrillDownJournalistsSuccess())
  } catch (error) {
    let msg = i18n.get('failed')
    yield put(Actions.searchDrillDownJournalistsError(error))

    if (error.response && error.response.statusCode === 403) {
      msg = error.response.message
    }

    yield put(AppActions.showAppSnackbarMessage({
      text: msg,
      variant: 'error'
    }))
  }
}

export function* searchDrillDownOutletsStart() {
  const i18n = yield select(Selectors.getI18n)
  const drillDownFilters = yield select(Selectors.getAnewstipDrillDownFilters)

  const page = drillDownFilters.getIn(['outlets', 'pagination', 'page'])
  const pageSize = drillDownFilters.getIn(['outlets', 'pagination', 'pageSize'])

  const body = {
    filters: {
      topic: drillDownFilters
        .get('topics')
        .filter(topic => topic.get('active') === true)
        .map(topic => topic.get('name'))
    },
    page,
    page_size: pageSize,
    must_has_email: true,
    collapse_email: true
  }

  try {
    const result = yield call(Api.searchAnewstipOutlets, body)
    const { outlets, paginator } = result

    yield put(Actions.setDrillDownResult({ entity: 'outlets', value: { entities: outlets, paginator } }))
    yield put(Actions.searchDrillDownOutletsSuccess())
  } catch (error) {
    yield put(Actions.searchDrillDownOutletsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* searchDrillDownArticlesStart() {
  const i18n = yield select(Selectors.getI18n)
  const drillDownFilters = yield select(Selectors.getAnewstipDrillDownFilters)
  const isLimitedFunctionality = yield select(Selectors.getIsLimitedFunctionality)

  if (isLimitedFunctionality) {
    return
  }

  const cursorToFetch = drillDownFilters.getIn(['articles', 'pagination', 'cursor'])
  const pageSize = drillDownFilters.getIn(['articles', 'pagination', 'pageSize'])
  const sortBy = drillDownFilters.getIn(['articles', 'sortBy'])

  const body = {
    phrase: [drillDownFilters.get('name')],
    cursor: cursorToFetch,
    sort: sortBy,
    page_size: pageSize,
    identity_id: drillDownFilters.get('identityId'),
    person_id: drillDownFilters.get('personId')
  }

  try {
    const result = yield call(Api.searchAnewstipArticles, body)
    const { articles, cursor, previousCursor } = result

    yield put(Actions.setDrillDownResult({ entity: 'articles', value: { entities: articles, cursor, previousCursor } }))
    yield put(Actions.searchDrillDownArticlesSuccess())
  } catch (error) {
    let msg = i18n.get('failed')
    yield put(Actions.searchDrillDownArticlesError(error))

    if (error.response && error.response.statusCode === 403) {
      msg = error.response.message
    }

    yield put(AppActions.showAppSnackbarMessage({
      text: msg,
      variant: 'error'
    }))
  }
}

export function* fetchStaticsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.fetchAnewstipStatics)

    yield put(Actions.setStatics(result))
    yield put(CmActions.fetchDistributionListsStart())
    yield put(Actions.fetchStaticsSuccess())
  } catch (error) {
    yield put(Actions.fetchStaticsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* searchSuggestStart({ payload: { keywords, source } }) {
  const i18n = yield select(Selectors.getI18n)

  const body = {
    keywords,
    source,
    count: 10
  }

  try {
    const { suggest } = yield call(Api.searchAnewstipSuggest, body)

    yield put(Actions.setSuggestions(suggest))
    yield put(Actions.searchSuggestSuccess())
  } catch (error) {
    yield put(Actions.searchSuggestError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* combinedSearchStart() {
  try {
    yield call(searchJournalistsStart)
    yield call(searchOutletsStart)
    yield call(searchArticlesStart)
    yield put(Actions.combinedSearchSuccess())
  } catch (error) {
    yield put(Actions.combinedSearchError(error))
  }
}

export function* drillDownRequestStart({ payload }) {
  const { entity, isHistorySearch } = payload
  try {
    if (!isHistorySearch) {
      const drillDownFilters = yield select(Selectors.getAnewstipDrillDownFilters)
      const drillDownHistory = yield select(Selectors.getAnewstipDrillDownHistory)
      const drillDownHistoryFilters = drillDownHistory.get('filtersHistory')

      let entityId
      let filterFound

      if (entity === 'journalist') {
        entityId = drillDownFilters.get('drillDownEntityId')
        filterFound = drillDownHistoryFilters.find(filtersHistory => filtersHistory.getIn(['filters', 'drillDownEntityId']) === entityId)
      }

      if (entity === 'outlet') {
        entityId = drillDownFilters.get('outletId')
        filterFound = drillDownHistoryFilters.find(filtersHistory => filtersHistory.getIn(['filters', 'outletId']) === entityId)
      }

      if (filterFound) {
        yield put(Actions.setDrillDownHistoryIndex(filterFound.get('index')))
      } else {
        yield put(Actions.addDrillDownFiltersToHistory(drillDownFilters))
      }
    }

    if (entity === 'journalist') {
      yield call(searchDrillDownJournalistsStart, { payload: entity })
      yield call(searchDrillDownArticlesStart)
    }

    if (entity === 'outlet') {
      yield call(searchDrillDownJournalistsStart, { payload: entity })
      yield call(searchDrillDownOutletsStart)
    }

    yield put(Actions.drillDownRequestSuccess())
  } catch (error) {
    yield put(Actions.drillDownRequestError(error))
  }
}

export function* drillDownHistorySearch({ payload }) {
  const { index, entity } = payload
  const drillDownHistory = yield select(Selectors.getAnewstipDrillDownHistory)

  yield put(Actions.setDrillDownHistoryIndex(index))

  const filtersFound = drillDownHistory.get('filtersHistory').find(filtersHistory => filtersHistory.get('index') === index)

  yield put(Actions.setDrillDownFiltersState(filtersFound.get('filters')))
  yield call(drillDownRequestStart, { payload: { entity, isHistorySearch: true } })
}

export function* watchCombinedSearchStart() {
  yield takeEvery(Actions.combinedSearchStart, combinedSearchStart)
}

export function* watchSearchJournalistsStart() {
  yield takeEvery(Actions.searchJournalistsStart, searchJournalistsStart)
}

export function* watchSearchOutletsStart() {
  yield takeEvery(Actions.searchOutletsStart, searchOutletsStart)
}

export function* watchSearchArticlesStart() {
  yield takeEvery(Actions.searchArticlesStart, searchArticlesStart)
}

export function* watchFetchStaticsStart() {
  yield takeEvery(Actions.fetchStaticsStart, fetchStaticsStart)
}

export function* watchSearchSuggestStart() {
  yield takeEvery(Actions.searchSuggestStart, searchSuggestStart)
}

export function* watchDrillDownRequestStart() {
  yield takeEvery(Actions.drillDownRequestStart, drillDownRequestStart)
}

export function* watchSearchDrillDownJournalistsStart() {
  yield takeEvery(Actions.searchDrillDownJournalistsStart, searchDrillDownJournalistsStart)
}

export function* watchSearchDrillDownOutletsStart() {
  yield takeEvery(Actions.searchDrillDownOutletsStart, searchDrillDownOutletsStart)
}

export function* watchSearchDrillDownArticlesStart() {
  yield takeEvery(Actions.searchDrillDownArticlesStart, searchDrillDownArticlesStart)
}

export function* watchDrillDownHistorySearch() {
  yield takeEvery(Actions.drillDownHistorySearch, drillDownHistorySearch)
}

export default function* searchSaga() {
  yield all([
    watchCombinedSearchStart(),
    watchSearchJournalistsStart(),
    watchSearchOutletsStart(),
    watchSearchArticlesStart(),
    watchFetchStaticsStart(),
    watchSearchSuggestStart(),
    watchDrillDownRequestStart(),
    watchSearchDrillDownJournalistsStart(),
    watchSearchDrillDownOutletsStart(),
    watchSearchDrillDownArticlesStart(),
    watchDrillDownHistorySearch()
  ])
}
