import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

import { Select, IconButton } from 'containers/themed'
import { UNGROUPED, GROUPED } from 'components/shopping_cart/enum'

const useStyles = makeStyles()(theme => ({
  leftActions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      justifyContent: 'space-between'
    },
    '& > span:first-of-type': {
      [theme.breakpoints.only('xs')]: {
        marginRight: 5
      }
    },
    '& > button': {
      [theme.breakpoints.only('xs')]: {
        minWidth: 32,
        fontSize: 10,
        padding: 2
      }
    },
    minWidth: 200
  },
  sortDirectionButton: {
    marginLeft: 5
  }
}))

export default function Sortbar({
  i18n,
  tab,
  onUngroupedSortBy,
  onSortBy
}) {
  const { classes } = useStyles()
  const [sortDirection, setSortDirection] = useState('asc')
  const [selectedField, setSelectedField] = useState('-')

  useEffect(() => {
    if (selectedField !== '-') {
      if (tab === GROUPED) {
        onSortBy({ field: selectedField, order: sortDirection })
      } else if (tab === UNGROUPED) {
        onUngroupedSortBy({ field: selectedField, order: sortDirection })
      }
    }
  }, [sortDirection, selectedField])

  const options = [
    {
      value: '-',
      label: i18n.get('none')
    },
    {
      value: 'articleDate',
      label: i18n.get('article_date')
    },
    {
      value: 'channelId',
      label: i18n.get('channel')
    },
    {
      value: 'publicationName',
      label: i18n.get('sort_by_publication')
    },
    {
      value: 'headline',
      label: i18n.get('sort_by_title')
    },
    {
      value: 'country',
      label: i18n.get('country')
    },
    {
      value: 'reach',
      label: i18n.get('reach')
    },
    {
      value: 'interactions',
      label: i18n.get('interactions')
    }
  ]

  if (tab === GROUPED) {
    options.push({
      value: 'topic',
      label: i18n.get('topic')
    })
  }

  return (
    <div className={classes.leftActions}>
      <Select
        size="small"
        options={options}
        value={selectedField}
        onChange={({ value }) => {
          setSelectedField(value)
        }}
        label={i18n.get('sort_by')}
        variant="outlined"
      />

      <IconButton
        disabled={selectedField === '-'}
        classes={{ root: classes.sortDirectionButton }}
        title={i18n.get(sortDirection === 'desc' ? 'descending' : 'ascending')}
        onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
        size="small"
      >
        {sortDirection === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    </div>
  )
}

Sortbar.propTypes = {
  i18n: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,

  onUngroupedSortBy: PropTypes.func.isRequired,
  onSortBy: PropTypes.func.isRequired
}
