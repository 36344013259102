import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Checkbox
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { RaisedPrimaryButton, Button, IconButton } from 'containers/themed'

import WarningIcon from '@mui/icons-material/Warning'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  },
  warningWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: red[500],
    marginTop: '1em'
  },
  warning: {
    marginLeft: '0.3em'
  },
  otsInfo: {
    '& ul': {
      paddingLeft: '1em',
      listStyleType: 'disc'
    },
    '& li': {
      listStyleType: 'disc'
    }
  }
}))

const PublishConfirmDialog = ({
  open,
  content,
  approvalStatus,
  onChange,
  onClose,
  onSave,
  createOtsDraft,
  closeEditContentDialog
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [approveOnPublish, setApproveOnPublish] = useState(true)
  const notApproved = ['draft', 'review_pending', 'revisions_needed'].includes(approvalStatus)
  const isMailContent = content.get('type') === 'email' || content.getIn(['type', 'id']) === 'email'
  const isOtsContent = content.get('type') === 'ots_press_release' || content.getIn(['type', 'id']) === 'ots_press_release'

  const handlePublish = () => {
    const currentDateAndTime = moment.utc().local()

    if (onChange) {
      onChange({ key: 'scheduledFor', value: currentDateAndTime })

      if (approveOnPublish) {
        onChange({ key: 'approvalStatus', value: 'approved' })
      }
    }

    if (onSave) {
      onSave({ closeEditContentDialog: true })
    }

    onClose()
  }

  const handleCreateOtsDraft = () => {
    createOtsDraft({ id: content.get('id') })
    onClose()
    closeEditContentDialog()
  }

  const renderWarning = () => (
    <div className={classes.warningWrapper}>
      <WarningIcon
        size="small"
      />
      <Typography className={classes.warning}>{i18n.get('content_not_approved')}</Typography>
    </div>
  )

  const renderSetAsApproved = () => (
    <>
      <br />
      <Checkbox
        sx={{ marginLeft: '-9px' }}
        checked={approveOnPublish}
        onChange={e => setApproveOnPublish(e.target.checked)}
      />
      {i18n.get('set_content_as_approved')}
    </>
  )

  return (
    <Dialog
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        {isMailContent && i18n.get('please_confirm')}
        {isOtsContent && i18n.get('please_confirm_ots')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isMailContent && i18n.get('publish_confirmation')}
        {isOtsContent && (
          <Box
            className={classes.otsInfo}
            dangerouslySetInnerHTML={{ __html: i18n.get('publish_confirmation_ots') }}
          />
        )}
        {notApproved && renderWarning()}
        {notApproved && isMailContent && renderSetAsApproved()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {isMailContent && (
          <>
            <Button onClick={onClose}>
              {i18n.get('no')}
            </Button>
            <RaisedPrimaryButton onClick={handlePublish}>
              {i18n.get('yes')}
            </RaisedPrimaryButton>
          </>
        )}
        {isOtsContent && (
          <>
            <Button onClick={onClose}>
              {i18n.get('cancel')}
            </Button>
            <RaisedPrimaryButton onClick={handleCreateOtsDraft}>
              {i18n.get('submit_draft')}
            </RaisedPrimaryButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

PublishConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  content: PropTypes.instanceOf(Map).isRequired,
  approvalStatus: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  createOtsDraft: PropTypes.func.isRequired,
  closeEditContentDialog: PropTypes.func.isRequired,

  onChange: PropTypes.func,
  onSave: PropTypes.func
}

export default PublishConfirmDialog
