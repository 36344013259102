import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as SubscriptionsActions from 'actions/subscriptions'
import * as AppActions from 'actions/app'

const initialState = fromJS({
  selectedEditionIds: Set([])
})

export default handleActions({
  [SubscriptionsActions.setSubscriptions]: (state, { payload: { subscriptions } }) => {
    const editionIds = subscriptions.filter(s => !s.deactivated).map(s => s.mediaReviewTypeId)

    return state.set('selectedEditionIds', Set(editionIds))
  },
  [SubscriptionsActions.updateEditionSelection]: (state, { payload: { edition, selected } }) => {
    if (selected) {
      return state.set('selectedEditionIds', state.get('selectedEditionIds').add(edition.get('id')))
    }

    return state.set('selectedEditionIds', state.get('selectedEditionIds').remove(edition.get('id')))
  },
  [AppActions.resetState]: () => initialState
}, initialState)
