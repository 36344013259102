import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/shopping_cart'
import * as AppActions from 'actions/app'

export const initialState = Set([])

export default handleActions({
  [Actions.setSelectedNews]: (state, { payload: { codeNewsIdPairs, omitIntersection } }) => {
    let newPairs = fromJS([codeNewsIdPairs]).flatten().toSet()

    if (omitIntersection) {
      return state.union(newPairs)
    }

    const intersection = state.intersect(newPairs)
    newPairs = newPairs.subtract(intersection)

    return state.subtract(intersection).union(newPairs)
  },

  [Actions.resetNewsSelection]: () => initialState,

  [Actions.updateTabIndex]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)
