/* global document window */
/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useI18n from 'hooks/useI18n'

import {
    $getSelection,
    $isRangeSelection,
    SELECTION_CHANGE_COMMAND,
    COMMAND_PRIORITY_LOW
} from 'lexical'

import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'

import { getSelectedNode, setFloatingElemPosition } from 'utils/lexicalEditorHelper'

const FloatingLinkEditor = ({
    toolbarTrackerRef,
    editor,
    linkUrl,
    setLinkUrl,
    isEditMode,
    setEditMode,
    lastSelection,
    setLastSelection
}) => {
    const i18n = useI18n()
    const editorRef = useRef(null)
    const inputRef = useRef(null)
    const mouseDownRef = useRef(false)

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection()

        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection)
            const parent = node.getParent()

            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL())
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL())
            } else {
                setLinkUrl('')
            }
        }

        const editorElem = editorRef.current
        const nativeSelection = window.getSelection()
        const { activeElement } = document

        if (editorElem === null) {
            return
        }

        const rootElement = editor.getRootElement()

        if (
            selection !== null
            && !nativeSelection.isCollapsed
            && rootElement !== null
            && rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0)
            let rect

            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild
                }
                rect = inner.getBoundingClientRect()
            } else {
                rect = domRange.getBoundingClientRect()
            }

            if (!mouseDownRef.current) {
                setFloatingElemPosition(rect, editorElem, rootElement.parentElement)
            }

            setLastSelection(selection)
        } else if (!activeElement || activeElement.className !== 'link-input') {
            setFloatingElemPosition(null, editorElem, rootElement.parentElement)
            setLastSelection(null)
            setEditMode(false)
            setLinkUrl('')
        }
    }, [editor])

    useEffect(() => {
        const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
            editorState.read(() => {
                updateLinkEditor()
            })
        })

        const removeCommandListener = editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            () => {
                updateLinkEditor()

                return true
            },
            COMMAND_PRIORITY_LOW
        )

        return () => {
            removeUpdateListener()
            removeCommandListener()
        }
    }, [editor, updateLinkEditor])

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor()
        })
    }, [editor, updateLinkEditor])

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus()
        }
    }, [isEditMode])

    return (
        <div
            ref={el => {
                editorRef.current = el; toolbarTrackerRef.current = el
            }}
            className="link-editor"
        >
            {isEditMode ? (
                <input
                    ref={inputRef}
                    className="link-input"
                    value={linkUrl}
                    onChange={event => {
                        setLinkUrl(event.target.value)
                    }}
                    onKeyDown={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault()

                            if (lastSelection !== null) {
                                if (linkUrl !== '') {
                                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url: linkUrl, target: '_blank' })
                                }

                                setEditMode(false)
                            }
                        } else if (event.key === 'Escape') {
                            event.preventDefault()
                            setEditMode(false)
                        }
                    }}
                />
            ) : (
                <div className="link-input">
                    <a
                        href={linkUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {linkUrl}
                    </a>
                    <div className='link-actions'>
                        <button
                            onMouseDown={event => event.preventDefault()}
                            onClick={() => {
                                setEditMode(true)
                            }}
                            tabIndex={0}
                            title={i18n.get('edit')}
                        >
                            <i className="fal fa-pen-to-square"></i>
                        </button>
                        <button
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={() => {
                                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                            }}
                            tabIndex={0}
                            title={i18n.get('remove')}
                        >
                            <i className="fal fa-trash"></i>
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

const LinkFormat = ({ toolbarTrackerRef, editor, isLink }) => {
    const i18n = useI18n()

    const [linkUrl, setLinkUrl] = useState('')
    const [isEditMode, setEditMode] = useState(false)
    const [lastSelection, setLastSelection] = useState(null)

    const anchorElem = editor.getRootElement() ? editor.getRootElement().parentElement : null

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
        }
    }, [editor, isLink])

    return (
        <>
            <button
                onClick={insertLink}
                className={`toolbar-item spaced ${isLink ? 'active' : ''}`}
                title={i18n.get('link_format')}
            >
                <i className="toolbar-icon fal fa-link"></i>
            </button>
            {isLink && createPortal(
                <FloatingLinkEditor
                    linkUrl={linkUrl}
                    setLinkUrl={setLinkUrl}
                    isEditMode={isEditMode}
                    setEditMode={setEditMode}
                    lastSelection={lastSelection}
                    setLastSelection={setLastSelection}
                    toolbarTrackerRef={toolbarTrackerRef}
                    editor={editor}
                />,
                anchorElem
            )}
        </>
    )
}

export default LinkFormat
