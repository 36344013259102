import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Alert,
  CircularProgress,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Pagination,
  Backdrop
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import DistributionListsSortbar from 'containers/contact_management/DistributionListsSortbar'
import DistributionListItem from 'containers/contact_management/DistributionListItem'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '1em',
    backgroundColor: theme.isDark ? '#1E1E1E' : null
  },
  plusColumn: {
    width: '50px'
  },
  icon: {
    marginRight: '0.5em'
  },
  title: {
    color: !theme.isDark && '#404040',
    marginLeft: '1em'
  },
  accordion: {
    backgroundColor: theme.isDark ? null : '#F0F0F0',
    marginLeft: '2em',
    marginRight: '2em',
    marginBottom: '2em',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'transparent'
    }
  },
  pagination: {
    display: 'flex',
    marginTop: '1em',
    marginBottom: '1em',
    justifyContent: 'center'
  },
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer - 1,
    opacity: 0.5
  },
  circularProgress: {
    marginLeft: '1em',
    display: 'flex'
  }
}))

const DistributionLists = ({
  distributionLists,
  page,
  total,
  loading,
  hasSearchQueries,
  isDistListsLocked,
  distListsLimit,
  onPageChange
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (total === 0 && hasSearchQueries) {
      setExpanded(true)
    }
  }, [total, hasSearchQueries])

  const handlePageChange = (event, value) => {
    onPageChange(value)
  }

  const renderSortedLists = () => distributionLists.map(list => (
    <DistributionListItem
      distObject={list}
      key={`distribution_list__${list.get('id')}`}
    />
  ))

  const renderResult = () => {
    if (total === 0 && hasSearchQueries) {
      return (
        <AccordionDetails>
          <Alert severity="warning">
            {i18n.get('no_hits_title')}
          </Alert>
        </AccordionDetails>
      )
    }

    if (total > 0) {
      return (
        <AccordionDetails>
          <Backdrop
            className={classes.backdrop}
            open={loading}
          />
          <TableContainer className={classes.container}>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
            >
              <TableHead>
                <DistributionListsSortbar />
              </TableHead>
              <TableBody>
                {renderSortedLists()}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      )
    }

    return null
  }

  return (
    <>
      <Accordion
        className={classes.accordion}
        disableGutters
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize="large" />}
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <Typography
            className={classes.title}
            variant="h6"
          >
            {i18n.get('distribution_lists')} ({total})
          </Typography>
          {isDistListsLocked && (
            <Alert
              variant="outlined"
              sx={{ border: 'none' }}
              severity="error"
            >
              {i18n.get('distribution_lists_limit_reached_with_number', { number: distListsLimit })}
            </Alert>
          )}
          {loading ? (
            <div className={classes.circularProgress}>
              <CircularProgress
                size={40}
              />
            </div>
          ) : null}
        </AccordionSummary>
        {renderResult()}
        {(total >= 10) ? (
          <Pagination
            className={classes.pagination}
            showFirstButton
            showLastButton
            count={Math.ceil(total / 10)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        ) : null}
      </Accordion>
    </>
  )
}

DistributionLists.propTypes = {
  distributionLists: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,
  distListsLimit: PropTypes.number.isRequired,

  onPageChange: PropTypes.func.isRequired
}

export default DistributionLists
