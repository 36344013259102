import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  htmlPreview: {
    border: 'none',
    transformOrigin: '0 0',
    width: '100%',
    height: '82vh',
    overflow: 'auto'
  },
  dialogContent: {
    minHeight: '82vh'
  }
}))

const TemplatePreviewDialog = ({
  open,
  editorDesign,
  closePreviewDialog
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleCloseDialog = () => closePreviewDialog()

  const htmlContent = html => `
  <style>
    html { 
      overflow: auto; 
      user-select: none;
    }
  </style>
  <div style='width: 100%; transform-origin: 0 0; pointer-events: none;'>
    ${html}
  </div>
`

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('preview')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <iframe
          title="template-preview"
          className={classes.htmlPreview}
          srcDoc={htmlContent(editorDesign)}
        />
      </DialogContent>
    </Dialog>
  )
}

TemplatePreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  editorDesign: PropTypes.string,

  closePreviewDialog: PropTypes.func.isRequired
}

export default TemplatePreviewDialog
