import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'
import {
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Divider,
  Tooltip
} from '@mui/material'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown'

import IconButton from 'components/themed/icon_button'
import BlurredSection from 'components/themax/hidden_content/BlurredSection'
import LockedContentTooltip from 'components/themax/hidden_content/LockedContentTooltip'
import { shorten } from 'utils/string'

const useStyles = makeStyles()((theme, { isShownInDetailedView }) => ({
  card: {
    ...(!isShownInDetailedView && {
      cursor: 'pointer',
      '&:hover': {
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
      }
    })
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 16px',
    marginTop: '15px'
  },
  cardContent: {
    minHeight: '200px',
    maxHeight: '200px'
  },
  expandedCardContent: {
    minHeight: 'auto',
    maxHeight: 'auto'
  },
  blurredContent: {
    filter: 'blur(4px)',
    pointerEvents: 'none',
    userSelect: 'none'
  },
  logoContainer: {
    maxHeight: '20px',
    maxWidth: '100px',
    height: 'auto',
    width: 'auto'
  },
  publicationLogoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  publicationSpacer: {
    height: '20px',
    width: '100px'
  },
  divider: {
    marginTop: 10
  },
  advertisingDeadlineContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  releaseDateContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  advDeadlineTypography: {
    display: 'flex',
    alignItems: 'center'
  },
  releaseDateTypography: {
    display: 'flex',
    alignItems: 'center'
  },
  '& .clip': {
    color: theme.nrx.colors.highlighting
  }
}))

const EditorialPlansItem = ({
  bookmarkRequestRunningFor,
  editorialPlan,
  hasPaidVersion,
  isFirst,
  isShownInDetailedView,

  onRemoveBookmark,
  onSetBookmark,
  openDetailedViewDialog,
  setSelectedEditorialPlan
}) => {
  const { classes } = useStyles({ isShownInDetailedView })
  const i18n = useI18n()
  const [imgError, setImgError] = useState(false)
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const device = useDevice()

  let text = editorialPlan.get('text') || ''

  if (text.length === 0 || text === '0') {
    text = i18n.get('no_editorial_plan_text')
  }

  const toggleShowMore = e => {
    e.preventDefault()

    setSelectedEditorialPlan(editorialPlan)
    openDetailedViewDialog()
  }

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSetBookmark = (e, payload) => {
    e.preventDefault()
    e.stopPropagation()
    onSetBookmark(payload)
  }

  const handleRemoveBookmark = (e, payload) => {
    e.preventDefault()
    e.stopPropagation()
    onRemoveBookmark(payload)
  }

  const formatPublicationName = () => {
    const publicationName = editorialPlan.getIn(['publication', 'name'])
    let maxLength = 24

    if (device.get('xl')) {
      maxLength = 40
    }

    return publicationName.length > maxLength ? (
      <Tooltip
        title={publicationName}
        arrow
      >
        <Typography
          fontSize={19}
          fontWeight={500}
        >
          {shorten(publicationName, maxLength)}
        </Typography>
      </Tooltip>
    ) : (
      <Typography
        fontSize={19}
        fontWeight={500}
      >
        {publicationName}
      </Typography>
    )
  }

  const formatText = () => {
    const textLength = 240
    const shortenedText = text.substr(0, textLength)
    let displayedText = isExpanded ? text : shortenedText

    if (isShownInDetailedView && (text.length > textLength)) {
      return (
        <>
          <Typography
            variant="body2"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: displayedText
            }}
          />
          <Typography
            variant="body2"
            color="text.secondary"
          >
            <IconButton
              size="small"
              onClick={toggleExpanded}
            >
              {isExpanded ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
            </IconButton>
          </Typography>
        </>
      )
    }

    displayedText = text.length > textLength ? `${shortenedText}...` : text

    return (
      <Typography
        variant="body2"
        color="text.secondary"
        dangerouslySetInnerHTML={{
          __html: displayedText
        }}
      />
    )
  }

  const renderEditorialDeadline = () => (hasPaidVersion ? (
    <Typography
      fontSize={12}
      color="text.secondary"
    >
      {moment(editorialPlan.get('editorialDeadline')).format('L')}
    </Typography>
  ) : (
    <BlurredSection />
  ))

  const renderAdvertisingDeadline = () => {
    let advertisingDeadlineDate = editorialPlan.get('advertisingDeadline')

    if (!hasPaidVersion) {
      advertisingDeadlineDate = advertisingDeadlineDate.slice(2)
    } else {
      advertisingDeadlineDate = moment(advertisingDeadlineDate).format('L')
    }

    return (
      <div className={classes.advertisingDeadlineContainer}>
        <Typography
          fontSize={12}
          color="text.secondary"
          className={classes.advDeadlineTypography}
        >
          {!hasPaidVersion && (<span className={classes.blurredContent}>XX</span>)}
          {advertisingDeadlineDate}
        </Typography>
        {!hasPaidVersion && <LockedContentTooltip />}
      </div>
    )
  }

  const renderReleaseDate = () => {
    let releaseDate = editorialPlan.get('releaseDate')

    if (!hasPaidVersion) {
      releaseDate = editorialPlan.get('releaseDate').slice(2)
    } else {
      releaseDate = moment(releaseDate).format('L')
    }

    return (
      <div className={classes.releaseDateContainer}>
        <Typography
          fontSize={12}
          color="text.secondary"
          className={classes.releaseDateTypography}
        >
          {!hasPaidVersion && (<span className={classes.blurredContent}>XX</span>)}
          {releaseDate}
        </Typography>
        {!hasPaidVersion && <LockedContentTooltip />}
      </div>
    )
  }

  let publicationLogo = (
    <div className={classes.publicationSpacer}>
      <img
        src={editorialPlan.getIn(['publication', 'logoUrl'])}
        alt={editorialPlan.getIn(['publication', 'name'])}
        className={classes.logoContainer}
        style={{ display: isImageLoaded ? 'block' : 'none' }}
        onLoad={() => setIsImageLoaded(true)}
        onError={() => setImgError(true)}
      />
    </div>
  )

  if (imgError || !hasPaidVersion) {
    publicationLogo = <div className={classes.publicationSpacer} />
  }

  const renderBookmarkedIcon = () => {
    const editorialPlanId = editorialPlan.get('id')

    if (!hasPaidVersion) {
      return (
        <IconButton>
          <BookmarkBorderIcon />
          <LockedContentTooltip />
        </IconButton>
      )
    }

    if (editorialPlan.get('grootEditorialPlans').size > 0) {
      const grootEditorialPlan = editorialPlan.get('grootEditorialPlans').first()
      const firstName = grootEditorialPlan.get('createdByUser').get('firstName')
      const lastName = grootEditorialPlan.get('createdByUser').get('lastName')
      const createdBy = `${i18n.get('created_by')}: ${firstName} ${lastName}`

      return (
        <IconButton
          disabled={bookmarkRequestRunningFor === editorialPlanId}
          loading={bookmarkRequestRunningFor === editorialPlanId}
          onClick={e => handleRemoveBookmark(e, { editorialPlanId, grootEditorialPlanId: grootEditorialPlan.get('id') })}
        >
          <Tooltip
            title={createdBy}
            arrow
          >
            <BookmarkIcon color="secondary" />
          </Tooltip>
        </IconButton>
      )
    }

    return (
      <IconButton
        disabled={bookmarkRequestRunningFor === editorialPlanId}
        loading={bookmarkRequestRunningFor === editorialPlanId}
        onClick={e => handleSetBookmark(e, { editorialPlanId })}
      >
        <BookmarkBorderIcon />
      </IconButton>
    )
  }

  return (
    <Card
      className={classes.card}
      onClick={toggleShowMore}
    >
      <CardContent>
        <div className={classes.publicationLogoContainer}>
          {publicationLogo}
          {renderBookmarkedIcon()}
        </div>
        {
          hasPaidVersion ? (
            <>
              {formatPublicationName()}
            </>
          ) : (
            <BlurredSection />
          )
        }
        <Divider className={classes.divider} />
      </CardContent>
      <CardContent
        className={
          isFirst || isExpanded
            ? classes.expandedCardContent
            : classes.cardContent
        }
      >
        {hasPaidVersion ? (
          <Typography
            variant="body2"
            fontSize={13}
            fontWeight={500}
          >
            {i18n.get('edition')}: {editorialPlan.get('edition')}
          </Typography>
        ) : (
          <BlurredSection />
        )}
        {isFirst ? (
          <Typography
            variant="body2"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        ) : formatText()}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid container>
          <Grid
            item
            xl={4}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              fontSize={13}
              fontWeight={500}
            >
              {i18n.get('editorial_deadline')}
            </Typography>
            {renderEditorialDeadline()}
          </Grid>
          <Grid
            item
            xl={4}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              fontSize={13}
              fontWeight={500}
            >
              {i18n.get('advertising_deadline')}
            </Typography>
            {renderAdvertisingDeadline()}
          </Grid>
          <Grid
            item
            xl={4}
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Typography
              fontSize={13}
              fontWeight={500}
            >
              {i18n.get('release_date')}
            </Typography>
            {renderReleaseDate()}
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

EditorialPlansItem.defaultProps = {
  isShownInDetailedView: false,
  isFirst: false
}

EditorialPlansItem.propTypes = {
  bookmarkRequestRunningFor: PropTypes.number,
  editorialPlan: PropTypes.instanceOf(Map).isRequired,
  hasPaidVersion: PropTypes.bool.isRequired,
  isFirst: PropTypes.bool,
  isShownInDetailedView: PropTypes.bool,

  onRemoveBookmark: PropTypes.func.isRequired,
  onSetBookmark: PropTypes.func.isRequired,
  openDetailedViewDialog: PropTypes.func.isRequired,
  setSelectedEditorialPlan: PropTypes.func.isRequired
}

export default EditorialPlansItem
