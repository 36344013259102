import { call, put, takeEvery, all } from 'redux-saga/effects'

import * as Actions from 'actions/mailing_lists'
import * as Api from 'api/bff'
import { genericErrorMessage, genericSuccessMessage, exception } from 'actions/app'

export function* saveMailingList({ payload: mailingList }) {
  try {
    const body = {
      name: mailingList.get('name'),
      recipients: mailingList.get('recipients').toJS()
    }

    if (mailingList.get('id')) {
      const newMailingList = yield call(Api.updateMailingList, mailingList.get('id'), body)
      yield put(Actions.saveMailingListSuccess(newMailingList))
      yield put(Actions.hideMailingListDialog())
    } else {
      const newMailingList = yield call(Api.createMailingList, body)
      yield put(Actions.saveMailingListSuccess(newMailingList))
      yield put(Actions.hideMailingListDialog())
    }

    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.saveMailingListError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* deleteMailingList({ payload: mailingListId }) {
  try {
    yield call(Api.deleteMailingList, mailingListId)

    yield put(Actions.deleteMailingListSuccess(mailingListId))
    yield put(Actions.hideRemoveMailingListDialog())
    yield put(genericSuccessMessage())
  } catch (error) {
    yield put(Actions.deleteMailingListError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* watchdeleteMailingList() {
  yield takeEvery(Actions.deleteMailingListStart, deleteMailingList)
}

export function* watchSaveMailingList() {
  yield takeEvery(Actions.saveMailingListStart, saveMailingList)
}

export default function* mailingListSaga() {
  yield all([
    watchSaveMailingList(),
    watchdeleteMailingList()
  ])
}
