import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'

const DEFAULT_IMAGE_URL = 'https://static.pressrelations.de/pics/groot/ccd/portrait_placeholder.png'

const useStyles = makeStyles()(() => ({
  pictureMask: {
    objectFit: 'cover',
    height: '90px',
    width: '90px'
  }
}))

export default function ContactImage({ url }) {
  const { classes } = useStyles()

  return (
    <Box sx={{
      height: 90,
      width: 90,
      borderRadius: '50%',
      margin: 'auto',
      overflow: 'hidden'
    }}
    >
      <img
        className={classes.pictureMask}
        src={url || DEFAULT_IMAGE_URL}
        alt=""
      />
    </Box>
  )
}

ContactImage.propTypes = {
  url: PropTypes.string
}
