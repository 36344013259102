/* global window */
import ReactGA from 'react-ga4'

let initialized = false

const action = callback => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  return initialized && callback()
}

export const init = () => {
  initialized = true
  action(() => {
    ReactGA.initialize('G-Z4LHK60Y6B')
  })
}
export const pageView = pathname => action(() => {
  ReactGA.send({ hitType: 'pageview', page: pathname || window.location.href })
})
export const setGaOption = (key, value) => action(() => ReactGA.set({ [key]: value }))
export const event = args => action(() => ReactGA.event(args))
