/* eslint-disable */
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ParagraphNode, TextNode, LineBreakNode } from 'lexical'
import { ListItemNode, ListNode } from '@lexical/list';

import EditorTheme from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/themes/EditorTheme'
import { ExtendedParagraphNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedParagraphNode'
import { ExtendedTextNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedTextNode'
import { ExtendedLinkNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedLinkNode'
import { ExtendedAutoLinkNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedAutoLinkNode'
import { ExtendedListNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedListNode'
import { ExtendedListItemNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedListItemNode'

const editorConfig = () => {
  return {
    theme: EditorTheme,
    readOnly: false,
    onError(error) {
      throw error
    },
    nodes: [
      LineBreakNode,
      ExtendedListNode,
      ExtendedListItemNode,
      ExtendedLinkNode,
      ExtendedAutoLinkNode,
      ExtendedParagraphNode,
      ExtendedTextNode,
      { replace: ParagraphNode, with: () => new ExtendedParagraphNode() },
      { replace: TextNode, with: node => new ExtendedTextNode(node.__text) },
      { replace: LinkNode, with: node => new ExtendedLinkNode(node.__url) },
      { replace: AutoLinkNode, with: node => new ExtendedAutoLinkNode(node.__url) },
      { replace: ListNode, with: node => new ExtendedListNode(node.__listType, node.__start) },
      { replace: ListItemNode, with: node => new ExtendedListItemNode(node.__value, node.__checked) }
    ]
  }
}

export default editorConfig
