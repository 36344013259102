import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { ChartDataSources, ChartTypes } from 'static/constants'
import * as Actions from 'actions/analysis'
import * as AppActions from 'actions/app'
import allowedChartConfigs from 'static/allowed_chart_configs'

export const initialState = fromJS({
  firstLevel: 'analysisCodes',
  secondLevel: 'analysisCodes',
  thirdLevel: 'mentionsCount',
  dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
  type: ChartTypes.BAR
})

const updateOpt = (state, key, value) => state.update('opts', opts => (opts || fromJS({})).set(key, value))

export default handleActions({
  [Actions.fetchChartDataStart]: state => state.set('loading', true),
  [Actions.fetchChartDataError]: state => state.set('loading', false),
  [Actions.fetchChartDataSuccess]: (state, { payload }) => state.merge({
    loading: false,
    data: fromJS(payload)
  }),

  [Actions.setChartFields]: (state, { payload }) => {
    const data = { ...payload }

    if (data.firstLevel) {
      const chartTypes = allowedChartConfigs.getIn([data.firstLevel, 'chartTypes']).keySeq().toList()

      if (!chartTypes.includes(state.get('type'))) {
        if (chartTypes.includes(ChartTypes.BAR)) {
          data.type = ChartTypes.BAR
        } else {
          data.type = chartTypes.first()
        }
      }
    }

    if (data.secondLevel && data.secondLevel === 'ungrouped') {
      data.secondLevel = null
    }

    return state.merge(fromJS(data))
  },

  [Actions.setChartOpt]: (state, { payload: { key, value } }) => updateOpt(state, key, value),

  [AppActions.resetState]: () => initialState
}, initialState)
