import { all } from 'redux-saga/effects'
import mainSaga from './main'
import searchSaga from './search'
import filterSaga from './filter'

export default function* emailTemplatesLibrarySaga() {
  yield all([
    mainSaga(),
    searchSaga(),
    filterSaga()
  ])
}
