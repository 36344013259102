import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import { Chip, Icon, Tooltip, Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { orange } from '@mui/material/colors'

import ReportIcon from '@mui/icons-material/Report'

import { Button } from 'containers/themed'
import EditDialog from 'containers/themax/saved_searches/EditDialog'
import UpdateDialog from 'containers/themax/saved_searches/UpdateDialog'

const useStyles = makeStyles()(theme => ({
  title: {
    borderRadius: '3px'
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '10px'
    },
    gap: '10px',
    display: 'flex',
    flexWrap: 'wrap'
  }
}))

export default function SavedSearchesOptions({
  executedSavedSearch,
  onSaveButtonClick,
  onNewButtonClick,
  onResetButtonClick
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      {executedSavedSearch ? (
        <>
          <Chip
            className={classes.title}
            onDelete={() => onResetButtonClick()}
            label={(
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                {executedSavedSearch.get('outdated') && (
                  <Tooltip
                    title={i18n.get('saved_search_outdated')}
                    placement="top-start"
                  >
                    <ReportIcon sx={{ color: orange[400] }} />
                  </Tooltip>
                )}
                {executedSavedSearch.get('name')}
              </Box>
            )}
          />
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => onSaveButtonClick(executedSavedSearch)}
            startIcon={<Icon>edit</Icon>}
          >
            {i18n.get('save_changes')}
          </Button>
        </>
      ) : null}

      <Button
        size="small"
        color={theme.isDark && executedSavedSearch !== null ? 'secondary' : 'primary'}
        variant="contained"
        onClick={() => onNewButtonClick()}
        startIcon={<Icon>notifications_none</Icon>}
      >
        {i18n.get(executedSavedSearch ? 'new_saved_search' : 'save_search')}
      </Button>

      <EditDialog />
      <UpdateDialog />
    </div>
  )
}

SavedSearchesOptions.propTypes = {
  executedSavedSearch: PropTypes.instanceOf(Map),

  onSaveButtonClick: PropTypes.func.isRequired,
  onNewButtonClick: PropTypes.func.isRequired,
  onResetButtonClick: PropTypes.func.isRequired
}
