import { connect } from 'react-redux'

import KpiDateFilter from 'components/content_desk_new/content_header/kpi_date_filter'

import {
  fetchKpiStatisticsStart,
  search,
  setKpiStatisticsFilters
} from 'actions/content_desk'

import {
  getCDeskKpiStatisticsFilters,
  getCDeskRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  requestRunning: getCDeskRequestRunning(state),
  dateRange: getCDeskKpiStatisticsFilters(state).get('dateRange')
})

export default connect(
  mapStateToProps,
  {
    fetchKpiStatistics: fetchKpiStatisticsStart,
    search,
    setKpiFilters: setKpiStatisticsFilters
  }
)(KpiDateFilter)
