import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  }
}))

export default function ContentsEditBlockDialog({ lockDate, lockedBy, open, onClose }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const handleClose = () => onClose()

  if (!lockDate || !lockedBy) {
    return null
  }

  const name = `${lockedBy.get('firstName')} ${lockedBy.get('lastName')}`.trim()

  return (
    <Dialog
      open={open}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('content_being_edited')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>{i18n.get('content_current_editor')}:</b> {name}
          <br />
          {i18n.get('content_being_edited_info')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

ContentsEditBlockDialog.propTypes = {
  lockDate: PropTypes.string,
  lockedBy: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
