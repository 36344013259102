import { connect } from 'react-redux'

import DateDrawer from 'components/darknet/dashboard/date_drawer'

import { toggleDateForm as onClose } from 'actions/news'

import { getNewsUi } from 'selectors'

const mapStateToProps = state => ({
  open: getNewsUi(state).get('showDateForm')
})

export default connect(
  mapStateToProps, {
    onClose
  }
)(DateDrawer)
