import { connect } from 'react-redux'

import GroupedNewsSublist from 'components/media_reviews/media_review_detail/grouped_news_sublist'
import { moveNewsInMediaReviewStart, resetSortingSelection } from 'actions/media_reviews'
import { getCapabilities, getMediaReviewSelectedNewsForSorting } from 'selectors'
import { Capabilities } from 'static/constants'

const mapStateToProps = state => ({
  selectedNews: getMediaReviewSelectedNewsForSorting(state),
  sortingDisabled: !getCapabilities(state).get(Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)
})

export default connect(
  mapStateToProps,
  {
    onSortEnd: moveNewsInMediaReviewStart,
    onReset: resetSortingSelection
  }
)(GroupedNewsSublist)
