import { connect } from 'react-redux'

import Selected from 'components/shopping_cart/selected_news_bar'

import { getShoppingCartSelectedNewsIds, getCapabilities, getAllowedNewsViews } from 'selectors'

import {
  showRemoveCustomTagDialog,
  showCustomTagDialog,
  resetNewsSelection,
  invertNewsSelection
} from 'actions/shopping_cart'

const mapStateToProps = state => {
  const newsIds = getShoppingCartSelectedNewsIds(state)

  return {
    count: newsIds.size,
    capabilities: getCapabilities(state),
    allowedViews: getAllowedNewsViews(state),
    newsIds
  }
}

export default connect(
  mapStateToProps,
  {
    onReset: resetNewsSelection,
    onPinClick: showCustomTagDialog,
    onUnpinClick: showRemoveCustomTagDialog,
    onInvertSelectionClick: invertNewsSelection
  }
)(Selected)
