import React, { useState, useEffect } from 'react'
import { fromJS, List } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'
import { rgbaColorFromHex } from 'utils/color'
import { makeStyles } from 'tss-react/mui'
import {
  Alert,
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography
} from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { listUniqueByKeyName } from 'utils/immutable'
import RecipientsList from 'containers/content_desk_new/contents/content_edit_dialog/RecipientsList'
import DistributionLists from 'containers/content_desk_new/contents/content_edit_dialog/DistributionLists'

const useStyles = makeStyles()({
  container: {
    padding: '40px 20px 0px 20px'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    marginTop: '1em',
    marginBottom: '1em',
    minHeight: 50
  },
  recipientsActions: {
    display: 'flex',
    gap: 20,
    marginBottom: '1em'
  },
  recipientsHeader: {
    marginTop: '1em'
  },
  loadingContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  }
})

const RecipientsSelection = ({
  recipients,
  distributionListSearchQuery,
  contactDropdownOptions,
  contactDropdownOptionsLoading,
  selectedDistributionLists,
  distributionListDropdownOptions,
  distributionListDropdownOptionsLoading,
  isDistListsLocked,
  fetchContactDropdownOptionsStart,
  fetchDistributionListDropdownOptionsStart,
  setContentFormData,
  setDistributionListSearchQuery
}) => {
  const colors = useColors()
  const { classes } = useStyles()
  const i18n = useI18n()
  const [contactOpen, setContactOpen] = useState(false)
  const [contactOptions, setContactOptions] = useState(contactDropdownOptions.filter(contact => !contact.get('unsubscribed')).toJS())
  const [contactInputValue, setContactInputValue] = useState('')
  const [dlOpen, setDlOpen] = useState(false)
  const [dlOptions, setDlOptions] = useState(distributionListDropdownOptions.toJS())
  // const anythingLoading = distributionListDropdownOptionsLoading || contactDropdownOptionsLoading

  useEffect(() => {
    fetchDistributionListDropdownOptionsStart()
  }, [])

  useEffect(() => {
    fetchContactDropdownOptionsStart({ searchString: contactInputValue })
  }, [contactInputValue])

  useEffect(() => {
    setContactOptions(contactDropdownOptions.filter(contact => !contact.get('unsubscribed'))
      .map(c => c.set('contactId', c.get('id'))).toJS())
    setDlOptions(distributionListDropdownOptions
      .filter(dl => !dl.get('contacts').isEmpty())
      .map(dl => {
        const selected = selectedDistributionLists.find(sdl => sdl.get('id') === dl.get('id'))

        return dl.set('selectedContacts', selected ? selected.get('contacts').filter(
          s => selected.get('recipients').some(r => r.get('contactId') === s.get('id'))
        ) : List())
      }).toJS())
  }, [distributionListDropdownOptions, contactDropdownOptions, recipients])

  const dlHandleOnChange = (_event, value, changeType, opt) => {
    if (changeType === 'clear') {
      return setContentFormData({ key: 'recipients', value: recipients.filter(r => r.get('distributionLists').isEmpty()) })
    }

    if (!value) {
      return null
    }

    const selectedRecipients = value.map(dl => (
      dl.contacts.map(contact => (
        fromJS(contact).set('distributionListId', dl.id)
          .set('contactId', contact.id)
          .set('new', !recipients.some(r => r.get('contactId') === contact.id))
          .set('distributionListName', dl.name)
          .set('distributionListId', dl.id)
          .toJS()
      ))
    )).flat(1)
      .map(r => (
        r.contact ? r : (
          {
            ...r,
            contact: {
              searchable: r.searchable,
              externalPublication: r.externalPublication
            }
          }
        )
      ))

    let newRecipients = fromJS([...selectedRecipients, ...recipients.toJS()])

    if (changeType === 'removeOption') {
      const { option } = opt
      newRecipients = newRecipients.filter(r => (
        r.get('distributionLists').every(dl => (
          dl.get('id') !== option.id
        ))
      ))
    }

    const uniqueRecipients = listUniqueByKeyName(newRecipients, 'contactId')

    return setContentFormData({ key: 'recipients', value: uniqueRecipients })
  }

  const contactHandleOnChange = (_event, value, changeType, opt) => {
    if (changeType === 'clear') {
      return
    }

    if (value) {
      let newRecipients

      const { option } = opt

      if (changeType === 'removeOption') {
        newRecipients = recipients.filter(r => r.get('contactId') !== option.id)
      } else {
        const newOne = fromJS(option)
          .set('new', true)
          .set('contact', fromJS({
            searchable: option.searchable,
            externalPublication: option.externalPublication
          }))

        newRecipients = recipients.push(newOne)
      }

      const uniqueRecipients = listUniqueByKeyName(newRecipients, 'contactId')

      setContentFormData({ key: 'recipients', value: uniqueRecipients })
    }
  }

  const selectedOptions = dlOptions.filter(option => option.contacts.length === (option.selectedContacts || []).length)
  const getDlOptionLabel = option => {
    const suffix = ((option.contacts.length === option.selectedContacts.length)
      || option.selectedContacts.length === 0) ? (
        ` (${option.contacts.length})`
      ) : (
        ` (${option.selectedContacts.length}/${option.contacts.length})`
      )

    return `${option.name}${suffix}`
  }

  const dlOptionStyle = partiallySelected => (
    partiallySelected ? ({
      backgroundColor: rgbaColorFromHex(colors.get('primary'), 0.06)
    }) : ({})
  )

  const renderDlAutocomplete = () => (
    <Autocomplete
      sx={{ mb: '1em' }}
      multiple
      disabled={isDistListsLocked}
      disableCloseOnSelect
      clearOnEscape
      value={selectedOptions}
      inputValue={distributionListSearchQuery}
      open={dlOpen}
      onOpen={() => {
        setDlOpen(true)
      }}
      onClose={() => {
        setDlOpen(false)
      }}
      getOptionLabel={option => option.name}
      options={dlOptions}
      loading={distributionListDropdownOptionsLoading}
      onChange={dlHandleOnChange}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setDistributionListSearchQuery(newInputValue)
        }
      }}
      renderOption={(props, option, { selected }) => {
        const partiallySelected = option.selectedContacts.length > 0
          && option.selectedContacts.length < option.contacts.length

        return (
          <li
            {...props}
            style={dlOptionStyle(partiallySelected)}
          >
            <Typography
              style={{ flex: 1, fontWeight: selected ? 500 : 400 }}
            >
              {getDlOptionLabel(option)}
            </Typography>
            {selected && (
              <CheckIcon
                color="primary"
              />
            )}
            {partiallySelected && (
              (
                <CheckIcon
                  sx={{ color: rgbaColorFromHex(colors.get('primary'), 0.2) }}
                />
              )
            )}
          </li>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.get('choose_a_list')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {distributionListDropdownOptionsLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderTags={() => null}
      disableClearable
    />
  )

  const getOptionLabel = option => `${option.firstName || ''} ${option.lastName} [${option.mail}]`
  const value = contactOptions.filter(option => recipients.some(r => option.id === r.get('contactId')))

  const renderContactAutocomplete = () => (
    <Autocomplete
      sx={{ mb: '1em' }}
      multiple
      disableCloseOnSelect
      clearOnEscape
      value={value}
      inputValue={contactInputValue}
      open={contactOpen}
      onOpen={() => {
        setContactOpen(true)
      }}
      onClose={() => {
        setContactOpen(false)
      }}
      getOptionLabel={getOptionLabel}
      options={contactOptions}
      loading={contactDropdownOptionsLoading}
      onChange={contactHandleOnChange}
      onInputChange={(_event, newInputValue, reason) => {
        if (reason !== 'reset') {
          setContactInputValue(newInputValue)
        }
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
        >
          <Typography
            style={{ flex: 1, fontWeight: selected ? 500 : 400 }}
          >
            {getOptionLabel(option)}
          </Typography>
          {selected && (
            <CheckIcon
              color="primary"
            />
          )}
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.get('search_for_contacts')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {contactDropdownOptionsLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      renderTags={() => null}
      disableClearable
    />
  )

  return (
    <Box className={classes.container}>
      {/* {anythingLoading && (
        <div className={classes.loadingContainer}>
          <CircularProgress
            color="primary"
            size={26}
          />
        </div>
      )} */}
      <Typography variant="h6">
        {i18n.get('add_distribution_lists')}
      </Typography>
      {renderDlAutocomplete()}
      {isDistListsLocked && (
        <Alert
          variant="outlined"
          sx={{ border: 'none' }}
          severity="error"
        >
          {i18n.get('distribution_lists_limit_reached')}
        </Alert>
      )}
      <DistributionLists />
      <Typography
        variant="h6"
        className={classes.recipientsHeader}
      >
        {i18n.get('add_recipients')}
      </Typography>
      {renderContactAutocomplete()}
      <RecipientsList />
    </Box>
  )
}

RecipientsSelection.propTypes = {
  recipients: PropTypes.instanceOf(List).isRequired,
  distributionListSearchQuery: PropTypes.string.isRequired,
  contactDropdownOptions: PropTypes.instanceOf(List).isRequired,
  contactDropdownOptionsLoading: PropTypes.bool.isRequired,
  selectedDistributionLists: PropTypes.instanceOf(List).isRequired,
  distributionListDropdownOptions: PropTypes.instanceOf(List).isRequired,
  distributionListDropdownOptionsLoading: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  fetchContactDropdownOptionsStart: PropTypes.func.isRequired,
  fetchDistributionListDropdownOptionsStart: PropTypes.func.isRequired,
  setContentFormData: PropTypes.func.isRequired,
  setDistributionListSearchQuery: PropTypes.func.isRequired
}

export default RecipientsSelection
