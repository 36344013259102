import { call, put, takeEvery, all, select, race, delay, take } from 'redux-saga/effects'
import moment from 'moment-timezone'
import decamelizeKeysDeep from 'decamelize-keys-deep'

import * as Actions from 'actions/saved_charts'
import * as AppActions from 'actions/app'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { determineMomentLocale, getLocale } from 'utils/locale'
import { navigate } from 'actions/navigation'

export function* fetchSavedChart({ payload: { id, locale: localeFromParams, timezone: timezoneFromParams } }) {
  try {
    const timezone = yield select(Selectors.getTimezone)
    const locale = yield call(getLocale)
    const result = yield call(Api.fetchSavedChart, id, timezoneFromParams || timezone, localeFromParams || locale)

    yield put(Actions.fetchSavedChartSuccess(result))
  } catch (error) {
    yield put(Actions.fetchSavedChartError({ error, id }))
    yield put(AppActions.exception(error))
  }
}

export function* createSavedChart({ payload: chart }) {
  try {
    const timezone = yield select(Selectors.getTimezone)

    const body = {
      chart: JSON.stringify(decamelizeKeysDeep(chart.delete('data').toJS())),
      expires_at: moment().add(1, 'years').toISOString(),
      timezone
    }

    const result = yield call(Api.createSavedChart, body)

    yield put(Actions.createSavedChartSuccess(result))
    yield put(Actions.showDialog())
  } catch (error) {
    yield put(Actions.createSavedChartError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* deleteSavedChart({ payload: savedChart }) {
  try {
    const id = savedChart.get('id')
    const i18n = yield select(Selectors.getI18n)

    yield put(Actions.deleteSavedChartSuccess(id))
    yield put(AppActions.showUndo())
    yield put(AppActions.genericSuccessMessage())

    const { undo } = yield race({
      undo: take(AppActions.undo),
      deleteIt: delay(10000)
    })

    yield put(AppActions.hideUndo())

    if (undo) {
      yield put(Actions.createSavedChartSuccess(savedChart))
      yield put(AppActions.showAppMessage({ text: i18n.get('undone') }))
    } else {
      yield call(Api.deleteSavedChart, id)

      yield put(Actions.deleteSavedChartSuccess(id))
    }
  } catch (error) {
    yield put(Actions.deleteSavedChartError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* updateSavedChart({ payload: savedChart }) {
  try {
    const body = {
      expires_at: savedChart.get('expiresAt')
    }

    const result = yield call(Api.updateSavedChart, savedChart.get('id'), body)

    yield put(Actions.updateSavedChartSuccess(result))
    yield put(Actions.hideDialog())
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.updateSavedChartError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* discardSavedChart({ payload: savedChart }) {
  try {
    yield call(Api.deleteSavedChart, savedChart.get('id'))

    yield put(Actions.discardSavedChartSuccess(savedChart.get('id')))
    yield put(Actions.hideDialog())
  } catch (error) {
    yield put(Actions.discardSavedChartError(error))
    yield put(AppActions.exception(error))
  }
}

export function* fetchSavedChartSuccess({ payload: { locale, timezone } }) {
  const momentLocale = determineMomentLocale(locale, locale, timezone)

  yield call(moment.locale, momentLocale)
}

export function* goToAdministration() {
  yield put(navigate('/app/administration/saved_charts'))
  yield put(Actions.hideDialog())
}

export function* watchFetchSavedChart() {
  yield takeEvery(Actions.fetchSavedChartStart, fetchSavedChart)
}

export function* watchFetchSavedChartSuccess() {
  yield takeEvery(Actions.fetchSavedChartSuccess, fetchSavedChartSuccess)
}

export function* watchCreateSavedChart() {
  yield takeEvery(Actions.createSavedChartStart, createSavedChart)
}

export function* watchDeleteSavedChart() {
  yield takeEvery(Actions.deleteSavedChartStart, deleteSavedChart)
}

export function* watchUpdateSavedChart() {
  yield takeEvery(Actions.updateSavedChartStart, updateSavedChart)
}

export function* watchDiscardSavedChart() {
  yield takeEvery(Actions.discardSavedChartStart, discardSavedChart)
}

export function* watchGoToAdministration() {
  yield takeEvery(Actions.goToAdministration, goToAdministration)
}

export default function* saga() {
  yield all([
    watchFetchSavedChart(),
    watchFetchSavedChartSuccess(),
    watchCreateSavedChart(),
    watchDeleteSavedChart(),
    watchUpdateSavedChart(),
    watchDiscardSavedChart(),
    watchGoToAdministration()
  ])
}
