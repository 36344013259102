import { connect } from 'react-redux'

import Desription from 'components/charts/chart/description'

import { getHasAiSummariesPaidVersion, getIsEmployee } from 'selectors'

const mapStateToProps = state => ({
  hasAiSummariesPaidVersion: getHasAiSummariesPaidVersion(state),
  hasAdminVersion: getIsEmployee(state)
})

export default connect(mapStateToProps)(Desription)
