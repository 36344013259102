import { fromJS } from 'immutable'
import { ChartDataSources } from './constants'

const config = fromJS([
  {
    value: 'moz_domain_authority',
    chart: {
      firstLevel: 'mozDomainAuthority',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.MOZ,
      type: 'rectangle'
    }
  },
  {
    value: 'moz_page_authority',
    chart: {
      firstLevel: 'mozPageAuthority',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.MOZ,
      type: 'rectangle'
    }
  },
  {
    value: 'moz_timeline_domain_authority',
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'pages',
      thirdLevel: 'mozDomainAuthority',
      dataSource: ChartDataSources.MOZ,
      type: 'area',
      opts: {
        domain: [0, 100]
      }
    }
  },
  {
    value: 'moz_timeline_page_authority',
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'pages',
      thirdLevel: 'mozPageAuthority',
      dataSource: ChartDataSources.MOZ,
      type: 'area',
      opts: {
        domain: [0, 100]
      }
    }
  }
])

export default config

export const findValueByChart = chart => {
  const found = config.find(c => fromJS({
    firstLevel: chart.get('firstLevel'),
    secondLevel: chart.get('secondLevel'),
    dataSource: chart.get('dataSource')
  }).equals(c.get('chart').deleteAll(['thirdLevel', 'type'])))

  if (found) {
    return found.get('value')
  }

  return null
}
