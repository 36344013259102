import {
  all,
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as AppActions from 'actions/app'
import * as Actions from 'actions/themax'
import * as Selectors from 'selectors'
import * as LocalStorage from 'utils/localStorage'
import { downloadBlob } from 'utils/sagas'

import { ExcelMimeType } from 'static/constants'

export function* onMount() {
  const moduleInitialized = yield select(Selectors.getThemaxModuleInitialized)

  if (!moduleInitialized) {
    yield put(Actions.themaxSearchRequestStart())
    yield put(Actions.initializeThemaxSavedSearches())
    yield put(Actions.setThemaxModuleInitialized(true))
  }
}

export function* onUnmount() {
  const isSavedSearchesOverview = yield select(Selectors.isSavedSearchesOverview)

  if (!isSavedSearchesOverview) {
    yield put(Actions.setThemaxModuleInitialized(false))
  }
}

export function* saveBookmark({ payload: { editorialPlanId } }) {
  try {
    const body = {
      editorial_plan_id: editorialPlanId
    }

    const response = yield call(Api.createGrootEditorialPlan, body)

    yield put(Actions.themaxSaveBookmarkSuccess({ response, editorialPlanId }))
  } catch (error) {
    yield put(Actions.themaxSaveBookmarkError(error))
  }
}

export function* deleteBookmark({ payload: { editorialPlanId, grootEditorialPlanId } }) {
  try {
    yield call(Api.deleteGrootEditorialPlan, grootEditorialPlanId)

    yield put(Actions.themaxDeleteBookmarkSuccess({ editorialPlanId }))
  } catch (error) {
    yield put(Actions.themaxDeleteBookmarkError(error))
  }
}

export function* exportEditorialPlans() {
  try {
    const themaxFilters = yield select(Selectors.getThemaxFilters)
    const timezone = yield select(Selectors.getTimezone)
    const publicationFilter = yield select(Selectors.getThemaxPublicationsFilter)
    const editorialPlanFilter = yield select(Selectors.getThemaxEditorialPlansFilter)

    const body = {
      publications: publicationFilter,
      editorial_plans: editorialPlanFilter,
      sort_by: themaxFilters.getIn(['editorialPlansSorting', 'sortBy']),
      sort_order: themaxFilters.getIn(['editorialPlansSorting', 'sortOrder']),
      timezone
    }
    const result = yield call(Api.exportThemaxEditorialPlans, body)
    yield call(downloadBlob, result, 'editorial_plans.xlsx', ExcelMimeType)
    yield put(Actions.exportEditorialPlansSuccess())
  } catch (error) {
    yield put(Actions.exportEditorialPlansError(error))
  }
}

export function* loadFromLocalStorage() {
  try {
    const hasThemaxPaidVersion = yield select(Selectors.getHasThemaxPaidVersion)
    const userId = yield select(Selectors.getUserId)
    const newsradarId = yield select(Selectors.getNewsradarId)
    const key = `themax_${newsradarId}_${userId}:show_welcome_message`
    const value = yield call(LocalStorage.getItem, key)

    if (value !== 'false' && !hasThemaxPaidVersion) {
      yield put(Actions.openWelcomeDialog())
    }
  } catch (error) {
    yield put(AppActions.exception(error))
    yield put(AppActions.setAppReady())
  }
}

export function* closeWelcomeDialog() {
  try {
    const userId = yield select(Selectors.getUserId)
    const newsradarId = yield select(Selectors.getNewsradarId)
    const key = `themax_${newsradarId}_${userId}:show_welcome_message`
    yield call(LocalStorage.setItem, key, 'false')
  } catch (error) {
    yield put(AppActions.exception(error))
  }
}

export function* watchLoadFromLocalStorage() {
  yield takeEvery(AppActions.loadFromLocalStorage, loadFromLocalStorage)
}

export function* watchCloseWelcomeDialog() {
  yield takeEvery(Actions.closeWelcomeDialog, closeWelcomeDialog)
}

export function* watchOnMount() {
  yield takeEvery(Actions.onMount, onMount)
}

export function* watchOnUnmount() {
  yield takeEvery(Actions.onUnmount, onUnmount)
}

export function* watchThemaxSaveBookmarkStart() {
  yield takeEvery(Actions.themaxSaveBookmarkStart, saveBookmark)
}

export function* watchThemaxDeleteBookmarkStart() {
  yield takeEvery(Actions.themaxDeleteBookmarkStart, deleteBookmark)
}

export function* watchExportEditorialPlansStart() {
  yield takeEvery(Actions.exportEditorialPlansStart, exportEditorialPlans)
}

export default function* mainSaga() {
  yield all([
    watchOnMount(),
    watchOnUnmount(),
    watchThemaxSaveBookmarkStart(),
    watchThemaxDeleteBookmarkStart(),
    watchExportEditorialPlansStart(),
    watchLoadFromLocalStorage(),
    watchCloseWelcomeDialog()
  ])
}
