import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List, Set } from 'immutable'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'

import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CancelIcon from '@mui/icons-material/Cancel'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'

import useI18n from 'hooks/useI18n'

import ContentContainer from 'components/content_container'
import NrxProgressBar from 'components/nrx_progress_bar'

import { Button } from 'containers/themed'
import CenterMessage from 'containers/CenterMessage'
import ModuleName from 'containers/administration/dispatch_log/ModuleName'
import AppBar from 'containers/administration/dispatch_log/AppBar'

import Attachments from './attachments'
import RecipientStats from './recipient_stats'
import Recipients from './recipients'
import User from './user'

const useStyles = makeStyles()(theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '89vh',
    width: '100%'
  },
  dispatchTime: {
    display: 'flex',
    alignItems: 'center'
  },
  scheduledForActions: {
    display: 'flex',
    alignItems: 'center'
  },
  scheduledForInfo: {
    padding: 10
  },
  problemIcon: {
    color: theme.palette.error.main,
    marginRight: 5
  },
  dispatchTimeIcon: {
    marginRight: 5,
    color: theme.palette.grey[500]
  },
  cancelButton: {
    marginLeft: 10
  },
  cancelled: {
    textDecoration: 'line-through',
    color: theme.palette.warning.main
  },
  failed: {
    cursor: 'pointer',
    color: theme.palette.error.main
  }
}))

export default function DispatchLog({ onCancelClick, onMount, dispatchLog, loading }) {
  const { classes, cx } = useStyles()
  const i18n = useI18n()
  const [openIds, setOpenIds] = useState(Set([]))

  useEffect(() => {
    onMount()
  }, [onMount])

  const renderDispatchTime = data => {
    const scheduledFor = data.get('scheduledFor') ? moment(data.get('scheduledFor')) : null
    const scheduledJobState = data.getIn(['scheduledJob', 'state'])

    if (scheduledJobState === 'SCHEDULED') {
      return (
        <div className={classes.scheduledForActions}>
          <HistoryToggleOffIcon className={classes.dispatchTimeIcon} />
          {scheduledFor.format('L')} {scheduledFor.format('LTS')}
          <Button
            className={classes.cancelButton}
            color="error"
            variant="outlined"
            size="small"
            onClick={() => onCancelClick(data.get('id'))}
          >
            <CancelIcon />
          </Button>
        </div>
      )
    }

    if (data.get('dispatchStatus') === 'CANCELLED') {
      return (
        <div className={cx(classes.scheduledForActions, classes.cancelled)}>
          <HistoryToggleOffIcon className={classes.dispatchTimeIcon} />
          {scheduledFor.format('L')} {scheduledFor.format('LTS')}
        </div>
      )
    }

    if (data.get('dispatchStatus') === 'FAILED') {
      if (scheduledFor) {
        return (
          <div
            className={cx(classes.dispatchTime, classes.failed)}
            title={i18n.get('dispatch_failed')}
          >
            <ReportProblemRoundedIcon className={classes.problemIcon} />
            {scheduledFor.format('L')} {scheduledFor.format('LTS')}
          </div>
        )
      }

      // fallback: should be removed once scheduledFor is always set
      return (
        <div className={classes.dispatchTime}>
          <ReportProblemRoundedIcon className={classes.problemIcon} />
          {moment(data.get('createdAt')).format('L')} {moment(data.get('createdAt')).format('LTS')}
        </div>
      )
    }

    if (scheduledFor) {
      return (
        <div className={classes.dispatchTime}>
          <AccessTimeIcon className={classes.dispatchTimeIcon} />
          {scheduledFor.format('L')} {scheduledFor.format('LTS')}
        </div>
      )
    }

    // fallback: should be removed once scheduledFor is always set
    return (
      <div className={classes.dispatchTime}>
        <AccessTimeIcon className={classes.dispatchTimeIcon} />
        {moment(data.get('createdAt')).format('L')} {moment(data.get('createdAt')).format('LTS')}
      </div>
    )
  }

  return (
    <>
      <AppBar />

      <ContentContainer>
        {loading && (
          <div className={classes.loading}>
            <NrxProgressBar />
          </div>
        )}

        {!loading && dispatchLog.isEmpty() && (
          <CenterMessage title={i18n.get('nothing_here')} />
        )}

        {!loading && !dispatchLog.isEmpty() && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n.get('created_at')}</TableCell>
                  <TableCell>{i18n.get('dispatch_time')}</TableCell>
                  <TableCell>{i18n.get('module')}</TableCell>
                  <TableCell>{i18n.get('subject')}</TableCell>
                  <TableCell>{i18n.get('user')}</TableCell>
                  <TableCell>{i18n.get('files')}</TableCell>
                  <TableCell>{i18n.get('views')}</TableCell>
                  <TableCell>{i18n.get('recipients')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dispatchLog.map((data, index) => (
                  <Fragment key={data.get('id')}>
                    <TableRow selected={index % 2 === 0}>
                      <TableCell>
                        {moment(data.get('createdAt')).format('L')} {moment(data.get('createdAt')).format('LTS')}
                      </TableCell>
                      <TableCell>
                        {renderDispatchTime(data)}
                      </TableCell>
                      <TableCell>
                        <ModuleName data={data} />
                      </TableCell>
                      <TableCell>
                        {data.get('subject')}
                      </TableCell>
                      <TableCell>
                        <User data={data} />
                      </TableCell>
                      <TableCell>
                        <Attachments data={data} />
                      </TableCell>
                      <TableCell>
                        {data.get('viewCount')}
                      </TableCell>
                      <TableCell>
                        <RecipientStats
                          data={data}
                          openIds={openIds}
                          onClick={setOpenIds}
                        />
                      </TableCell>
                    </TableRow>

                    {openIds.has(data.get('id')) ? (
                      <TableRow selected={index % 2 === 0}>
                        <TableCell colSpan={8}>
                          <Recipients data={data} />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </ContentContainer>
    </>
  )
}

DispatchLog.propTypes = {
  dispatchLog: PropTypes.instanceOf(List).isRequired,
  loading: PropTypes.bool.isRequired,

  onCancelClick: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired
}
