import { put, takeEvery, all, call, select, take, race, delay } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import moment from 'moment'

import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'
import * as ContactActions from 'actions/contact_management'
import * as Selectors from 'selectors'
import * as Api from 'api/content_desk'
import * as ContactsManagementApi from 'api/contacts_management'

import { OtsStatuses } from 'static/constants'

export function* search() {
  // reset page number
  yield put(Actions.setPage({ key: 'campaignPage', value: 1 }))
  yield put(Actions.setPage({ key: 'contentPage', value: 1 }))
  yield put(Actions.searchCampaignsStart())
  yield put(Actions.searchContentsStart())
}

export function* searchCampaignsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const { campaigns, contents } = yield select(Selectors.getCDeskSearchBody)
    const page = yield select(Selectors.getCDeskCampaignPage)
    const limit = yield select(Selectors.getCDeskCampaignLimit)
    const sortBy = yield select(Selectors.getCDeskCampaignSortBy)
    const sortOrder = yield select(Selectors.getCDeskCampaignSortOrder)
    const multiFilter = yield select(Selectors.getCDeskCampaignFilters)

    const body = {
      filter: campaigns.includes,
      multi_filter: multiFilter,
      content_filter: contents.includes,
      page,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder
    }

    const result = yield call(Api.searchCampaigns, body)
    yield put(Actions.setCampaigns(result))

    yield put(Actions.searchCampaignsSuccess())
  } catch (error) {
    yield put(Actions.searchCampaignsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* searchContentsStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const { contents, campaigns } = yield select(Selectors.getCDeskSearchBody)
    const page = yield select(Selectors.getCDeskContentPage)
    const limit = yield select(Selectors.getCDeskContentLimit)
    const sortBy = yield select(Selectors.getCDeskContentSortBy)
    const sortOrder = yield select(Selectors.getCDeskContentSortOrder)
    const multiFilter = yield select(Selectors.getCDeskContentFilters)

    const body = {
      filter: contents.includes,
      multi_filter: multiFilter,
      campaign_filter: campaigns.includes,
      page,
      limit,
      sort_by: sortBy,
      sort_order: sortOrder
    }

    const result = yield call(Api.searchContents, body)
    yield put(Actions.setContents(result))

    yield put(Actions.searchContentsSuccess())
  } catch (error) {
    yield put(Actions.searchContentsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* fetchRecipientContact({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const body = {
      filter: [],
      page: 1,
      limit: 10,
      ids: [payload.id]
    }

    const result = yield call(ContactsManagementApi.fetchContacts, body)
    yield put(ContactActions.fetchContactsSuccess(result))
    yield put(ContactActions.openContactViewDialog(fromJS(result.contacts[0])))
    yield put(Actions.fetchRecipientContactSuccess())
  } catch (error) {
    yield put(Actions.fetchRecipientContactError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* refreshContentsStatusStart() {
  const i18n = yield select(Selectors.getI18n)
  const contentsData = yield select(Selectors.getCDeskContents)
  const campaignsContentsData = yield select(Selectors.getCDeskCampaignsContents)
  const calendarContents = yield select(Selectors.getCDeskCalendarContents)
  const calendarCampaignsContentsData = yield select(Selectors.getCDeskCalendarCampaignsContents)
  const { items } = contentsData.toJS()
  const { items: calendarContentsData } = calendarContents.toJS()

  const filterCond = c => {
    let notTooOld = true

    if (c.otsPressRelease && c.plannedFor) {
      const plannedFor = moment(c.plannedFor)
      const now = moment()
      notTooOld = now.diff(plannedFor, 'days') <= 7
    }

    return c.status === 'scheduled'
      || (
        c.otsPressRelease
        && c.otsPressRelease.status !== OtsStatuses.DISTRIBUTED
        && notTooOld
      )
  }

  const scheduledContents = [
    ...items.filter(c => filterCond(c)),
    ...campaignsContentsData.filter(c => filterCond(c)),
    ...calendarContentsData.filter(c => filterCond(c)),
    ...calendarCampaignsContentsData.filter(c => filterCond(c))
  ]

  const contentIds = [...new Set(scheduledContents.map(c => c.id))]

  try {
    if (contentIds.length !== 0) {
      while (true) {
        const { stop } = yield race({
          stop: take(Actions.stopRefreshContentsStatus),
          refresh: delay(30000)
        })

        if (stop) {
          break
        }

        const body = {
          ids: contentIds
        }

        const { contents } = yield call(Api.fetchContents, body)
        yield put(Actions.setUpdatedContents(contents))
        yield put(Actions.setUpdatedCampaignsContents(contents))
        yield put(Actions.refreshContentsStatusSuccess())
      }
    }
  } catch (error) {
    yield put(Actions.refreshContentsStatusError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* linkedInCompanySearchStart({ payload }) {
  try {
    const results = yield call(Api.searchLinkedInCompany, { query: payload })
    yield put(Actions.linkedInCompanySearchSuccess(results))
  } catch (error) {
    yield put(Actions.linkedInCompanySearchError(error))

    const i18n = yield select(Selectors.getI18n)

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* watchLinkedInCompnaySearchStart() {
  yield takeEvery(Actions.linkedInCompanySearchStart, linkedInCompanySearchStart)
}

export function* watchSearch() {
  yield takeEvery(Actions.search, search)
}

export function* watchSearchCampaignsStart() {
  yield takeEvery(Actions.searchCampaignsStart, searchCampaignsStart)
}

export function* watchSearchContentsStart() {
  yield takeEvery(Actions.searchContentsStart, searchContentsStart)
}

export function* watchRefreshContentsStatusStart() {
  yield takeEvery(Actions.refreshContentsStatusStart, refreshContentsStatusStart)
}

export function* watchFetchRecipientContactStart() {
  yield takeEvery(Actions.fetchRecipientContactStart, fetchRecipientContact)
}

export default function* searchSaga() {
  yield all([
    watchSearch(),
    watchLinkedInCompnaySearchStart(),
    watchSearchCampaignsStart(),
    watchSearchContentsStart(),
    watchRefreshContentsStatusStart(),
    watchFetchRecipientContactStart()
  ])
}
