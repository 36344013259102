import { createAction } from 'redux-actions'

const defaultPrefix = 'AI/'
let prefix = defaultPrefix

prefix = `${defaultPrefix}SETTINGS/`
export const setOption = createAction(`${prefix}SET_OPTION`)

prefix = `${defaultPrefix}ANALYSIS/`
export const aiAnalysisStart = createAction(`${prefix}ANALYSIS_START`)
export const aiAnalysisError = createAction(`${prefix}ANALYSIS_ERROR`)
export const aiAnalysisSuccess = createAction(`${prefix}ANALYSIS_SUCCESS`)
export const showAiAnalysisDialog = createAction(`${prefix}SHOW_ANALYSIS_DIALOG`)
export const hideAiAnalysisDialog = createAction(`${prefix}HIDE_ANALYSIS_DIALOG`)

prefix = `${defaultPrefix}UI/`
export const showOrderSummariesDialog = createAction(`${prefix}SHOW_ORDER_SUMMARIES_DIALOG`)
export const hideOrderSummariesDialog = createAction(`${prefix}HIDE_ORDER_SUMMARIES_DIALOG`)
