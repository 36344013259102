import { connect } from 'react-redux'

import {
  setCalendarSelectedCampaignId
} from 'actions/content_desk'

import {
  getCDeskCalendarSelectedCampaignId,
  getCDeskCalendarLoadingCampaignIds
} from 'selectors'

import ShortCampaign from 'components/content_desk_new/campaigns/short_campaigns/short_campaign'

const mapStateToProps = (state, ownProps) => ({
  selectedCampaignId: getCDeskCalendarSelectedCampaignId(state),
  requestRunning: !!getCDeskCalendarLoadingCampaignIds(state).get(ownProps.campaign.get('id'))
})

export default connect(
  mapStateToProps,
  {
    onContentsClick: setCalendarSelectedCampaignId
  }
)(ShortCampaign)
