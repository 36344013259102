import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import {
  Chip,
  Button,
  Tooltip
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

const useStyles = makeStyles()({
  list: {
    flexWrap: 'wrap',
    gap: 5,
    minHeight: 50
  }
})

const RecipientsList = ({
  recipients,
  setContentFormData,
  openRecipientsBrowser,
  maxRecipients,
  withOpenAllLink,
  hideOnEmpty
}) => {
  const i18n = useI18n()
  const device = useDevice()
  const { classes } = useStyles()
  const maxRecip = maxRecipients || (device.get('gt-md') ? 7 : 4)
  const minOtherRecipients = 2
  const showOtherRecipients = recipients.size > maxRecip
  const displayedRecipients = showOtherRecipients ? Math.min(maxRecip - 1, recipients.size - minOtherRecipients) : maxRecip

  const hide = (hideOnEmpty && recipients.size === 0)

  const handleDelete = currentRecipient => {
    const newRecipients = recipients.filter(
      r => r.get('id') !== currentRecipient.get('id') && r.get('contactId') !== currentRecipient.get('contactId')
    )

    setContentFormData({
      key: 'recipients',
      value: newRecipients
    })
  }

  const renderRecipients = () => recipients.slice(0, displayedRecipients).map(
    currentRecipient => (
      <div
        key={`${currentRecipient.get('id')}_${currentRecipient.get('contactId')}`}
      >
        <Tooltip
          title={currentRecipient.get('distributionListName')
            ? `${i18n.get('distribution_list')}: ${currentRecipient.get('distributionListName')}`
            : `${i18n.get('added_manually')}`}
          placement="right-start"
          arrow
        >
          <Chip
            color={currentRecipient.get('new') ? 'primary' : 'default'}
            variant={currentRecipient.get('new') ? 'outlined' : 'filled'}
            label={currentRecipient.get('mail')}
            onDelete={() => handleDelete(currentRecipient)}
          />
        </Tooltip>
      </div>
    )
  )

  const renderOtherRecipients = () => (showOtherRecipients ? (
    <div
      key={`${maxRecip}`}
    >
      <Chip
        color="primary"
        variant="filled"
        label={`+${recipients.size - displayedRecipients}`}
        onClick={openRecipientsBrowser}
      />
    </div>

  ) : null)

  return (
    <>
      <div
        className={classes.list}
        style={{ display: hide ? 'none' : 'flex' }}
      >
        {renderRecipients()}
        {renderOtherRecipients()}
      </div>
      {withOpenAllLink && (
        <Button
          sx={{ pl: 0, pr: 0 }}
          variant="text"
          color="primary"
          onClick={openRecipientsBrowser}
        >
          {i18n.get('see_all_recipients')} ({recipients.size})
        </Button>
      )}
    </>
  )
}

RecipientsList.defaultProps = {
  withOpenAllLink: false,
  hideOnEmpty: false
}

RecipientsList.propTypes = {
  recipients: PropTypes.instanceOf(List).isRequired,
  setContentFormData: PropTypes.func.isRequired,
  openRecipientsBrowser: PropTypes.func.isRequired,
  maxRecipients: PropTypes.number,
  withOpenAllLink: PropTypes.bool,
  hideOnEmpty: PropTypes.bool
}

export default RecipientsList
