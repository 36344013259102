import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  reactionIndicator: {
    fontSize: 24
  },
  reactionIndicatorContainer: {
    position: 'relative'
  },
  total: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -1,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    color: theme.isDark ? theme.palette.grey[700] : theme.palette.common.white
  },
  totalChanged: {
    animation: `${keyframes`
            0% {
              transform: scale(1);
            },
            50% {
              transform: scale(2);
            },
            100% {
              transform: scale(1);
            }
          `} 1s;`
  }
}))

export default function ReactionIndicator({ total: totalFromProps, className }) {
  const [total, setTotal] = useState(totalFromProps)
  const [totalChanged, setTotalChanged] = useState(false)
  const { classes, cx } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    if (total !== totalFromProps) {
      setTotal(totalFromProps)
      setTotalChanged(true)

      setTimeout(() => {
        setTotalChanged(false)
      }, 1225)
    }
  }, [total, totalFromProps, setTotal])

  return (
    <IconButton
      className={cx(
        classes.container,
        className,
        totalChanged && classes.totalChanged
      )}
      title={total > 0 ? i18n.get('nrx_reactions') : i18n.get('nrx_reactions_missing')}
      size="small"
      icon={(
        <span className={classes.reactionIndicatorContainer}>
          <i className={cx('fas fa-comment', classes.reactionIndicator)} />

          <span className={classes.total}>
            {total}
          </span>
        </span>
      )}
    />
  )
}

ReactionIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  className: PropTypes.string
}
