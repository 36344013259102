import { connect } from 'react-redux'

import EmailEditor from 'components/content_desk_new/contents/content_edit_dialog/editor/email_editor'

import {
  emailEditorLoadingStart,
  emailEditorLoadingStop,
  uploadEditorImageStart,
  resetUploadedImageUrl,
  openSaveTemplateDialog,
  fetchMoreMediaFilesStart,
  deleteMediaFileStart as onMediaFileDelete,
  emailEditorForceLoadDesignStop as forceLoadDesignStop
} from 'actions/content_desk'

import {
  getCDeskContentUploadedImageUrl,
  getNewsradarId,
  getCDeskEmailEditorForceLoadDesign,
  getStage,
  getCDeskConfig
} from 'selectors'

const mapStateToProps = (state, { onChange, moduleScope, data }) => ({
  onChange,
  moduleScope,
  editorDesign: data.get('editorDesign'),
  json: data.get('json'),
  preHeader: data.get('preHeader'),
  imageUrl: getCDeskContentUploadedImageUrl(state),
  newsradarId: getNewsradarId(state),
  signature: getCDeskConfig(state).get('emailEditorSignature'),
  forceLoadDesign: getCDeskEmailEditorForceLoadDesign(state),
  env: getStage(state)
})

export default connect(
  mapStateToProps,
  {
    onEditorLoadingStart: emailEditorLoadingStart,
    onEditorLoadingStop: emailEditorLoadingStop,
    onImageUpload: uploadEditorImageStart,
    resetImageUrl: resetUploadedImageUrl,
    onSaveTemplateClick: openSaveTemplateDialog,
    fetchMoreMediaFilesStart,
    onMediaFileDelete,
    forceLoadDesignStop
  }
)(EmailEditor)
