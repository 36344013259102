import { connect } from 'react-redux'
import { List } from 'immutable'

import { DefaultExportFormats } from 'static/constants'
import MediaReviewDetail from 'components/media_reviews/media_review_detail'
import {
  collapseAllTopics,
  expandAllTopics,
  showAllSnippets,
  hideAllSnippets,
  showAllSummaries,
  hideAllSummaries,
  fetchNewsForMediaReviewStart,
  checkMediaReviewReactionsStop,
  acceptDiff,
  rejectDiff
} from 'actions/media_reviews'

import { showEditNewsDialog } from 'actions/news'
import { setValueInSettings } from 'actions/export'

import {
  getMediaReviewTotalNewsCount,
  getMediaReviewNewsCount,
  getMediaReviewDetailRunning,
  getPdfCoverTitle,
  getMediaReviewDetialShowDiffWarning,
  getConfig,
  getCapabilities
} from 'selectors'

const mapStateToProps = state => ({
  exportFormats: List(DefaultExportFormats),
  pdfCoverTitle: getPdfCoverTitle(state),
  totalCount: getMediaReviewTotalNewsCount(state),
  newsCount: getMediaReviewNewsCount(state),
  loading: getMediaReviewDetailRunning(state),
  showDiffWarning: getMediaReviewDetialShowDiffWarning(state),
  config: getConfig(state),
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onMount: fetchNewsForMediaReviewStart,
    onUnmount: checkMediaReviewReactionsStop,
    onAcceptDiff: acceptDiff,
    onRejectDiff: rejectDiff,
    setValueInSettings,
    collapseAllTopics,
    expandAllTopics,
    showAllSnippets,
    hideAllSnippets,
    showAllSummaries,
    hideAllSummaries,
    showEditNewsDialog
  }
)(MediaReviewDetail)
