import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import useI18n from 'hooks/useI18n'
import { mapToOptions } from 'utils/autocomplete'

import { Grid, Autocomplete, TextField } from '@mui/material'

export default function EditContactTags({ contact, tags, onChange }) {
  const i18n = useI18n()
  const values = mapToOptions(contact.get('customTags'))
  const tagOptions = mapToOptions(tags)

  const handleChange = (_e, newValues) => {
    onChange({ contactId: contact.get('id'), newValues })
  }

  const filterOptions = (options, params) => {
    const filtered = options.filter(option => option.label.toLowerCase().includes(params.inputValue.toLowerCase()))

    if (params.inputValue !== '' && !filtered.some(option => option.label.toLowerCase() === params.inputValue.toLowerCase())) {
      filtered.push({ isNew: true, label: params.inputValue, value: null })
    }

    return filtered
  }

  return (
    <Grid
      item
      xs={12}
    >
      <Autocomplete
        fullWidth
        multiple
        onChange={handleChange}
        options={tagOptions}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        value={values}
        clearOnBlur
        selectOnFocus
        disableCloseOnSelect
        size="small"
        renderInput={params => (
          <TextField
            {...params}
            label={i18n.get('tags')}
            placeholder=""
          />
        )}
        limitTags={10}
      />
    </Grid>
  )
}

EditContactTags.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  tags: PropTypes.instanceOf(ImmutableList).isRequired,
  onChange: PropTypes.func.isRequired
}
