import { fromJS } from 'immutable'
import { getDevice } from 'selectors/device'
import { getNewsUi } from './ui'
import { getNewsGroups, getNewsGroupsSortOrder } from './index'

const calculateNumberOfVisibleGroups = state => {
  const device = getDevice(state)

  if (device.get('gt-md')) {
    return 4
  }

  if (device.get('gt-sm')) {
    return 3
  }

  if (device.get('gt-xs')) {
    return 2
  }

  return 1
}

const isVisible = (start, end, i) => (i >= start) && (i <= end)

export const getGroupStatus = state => {
  const newsGroups = getNewsGroups(state)
  const groupSortOrder = getNewsGroupsSortOrder(state)
  const currentIndex = getNewsUi(state).get('currentIndex')

  const single = newsGroups.size === 1
  let i = 0

  let existingGroups = groupSortOrder.map((id, index) => (
    fromJS({
      id,
      index,
      single,
      channelId: newsGroups.getIn([id, 'channelId']),
      name: newsGroups.getIn([id, 'name']),
      showSpinner: newsGroups.getIn([id, 'loadingMore']) || false,
      total: newsGroups.getIn([id, 'stats', 'total']),
      count: newsGroups.getIn([id, 'stats', 'count']),
      reach: newsGroups.getIn([id, 'stats', 'reach']),
      photo: newsGroups.getIn([id, 'photo']),
      interactions: newsGroups.getIn([id, 'stats', 'interactions']),
      newsList: newsGroups.getIn([id, 'news'])
    })
  )).filter(g => g.get('newsList'))

  // add a visibilty info to each channel
  const numberOfVisibleGroups = calculateNumberOfVisibleGroups(state)
  const start = Math.min(currentIndex, existingGroups.size - numberOfVisibleGroups)
  const end = (start + numberOfVisibleGroups) - 1
  for (i = 0; i < existingGroups.size; i += 1) {
    existingGroups = existingGroups.setIn([i, 'visible'], isVisible(start, end, i))
  }

  return existingGroups
}
