/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import Divider from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/ui/divider'
import UndoRedo from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin/toolbar_options/options/UndoRedo'
import TextFormat from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin/toolbar_options/options/TextFormat'
import TextStyle from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin/toolbar_options/options/TextStyle'
import LinkFormat from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin/toolbar_options/options/LinkFormat'

const ToolbarOptions = ({
  editor,
  toolbarTrackerRef,
  canUndo,
  canRedo,
  blockType,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isEditable,
  disableTextFormat
}) => {
  const options = [
    {
      name: 'UndoRedo',
      component: <UndoRedo
        editor={editor}
        undoDisabled={!canUndo}
        redoDisabled={!canRedo}
      />
    },
    {
      name: 'TextFormat',
      enabled: !disableTextFormat,
      component: <TextFormat
        editor={editor}
        toolbarTrackerRef={toolbarTrackerRef}
        isEditable={isEditable}
        blockType={blockType}
      />
    },
    {
      name: 'TextStyle',
      component: <TextStyle
        editor={editor}
        blockType={blockType}
        isBold={isBold}
        isItalic={isItalic}
        isUnderline={isUnderline}
      />
    },
    {
      name: 'LinkFormat',
      component: <LinkFormat
        editor={editor}
        toolbarTrackerRef={toolbarTrackerRef}
        isLink={isLink}
      />
    }
  ]

  return options.map((opt, index) => {
    if (opt.enabled === false) return null

    return (
      <React.Fragment key={index}>
        {opt.component}
        <Divider />
      </React.Fragment>
    )
  })
}

ToolbarOptions.propTypes = {
  editor: PropTypes.object.isRequired,
  toolbarTrackerRef: PropTypes.object.isRequired,
  canUndo: PropTypes.bool.isRequired,
  canRedo: PropTypes.bool.isRequired,
  blockType: PropTypes.string.isRequired,
  isLink: PropTypes.bool.isRequired,
  isBold: PropTypes.bool.isRequired,
  isItalic: PropTypes.bool.isRequired,
  isUnderline: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool,
  disableTextFormat: PropTypes.bool
}

export default ToolbarOptions
