import { ChartDataSources, ChartTypes } from '../static/constants'

export const isPressrelationsNewsChart = chart => (
  chart.get('dataSource', ChartDataSources.PRESSRELATIONS_NEWS) === ChartDataSources.PRESSRELATIONS_NEWS
)

export const isGoogleAnalyticsChart = chart => (
  chart.get('dataSource') === ChartDataSources.GOOGLE_ANALYTICS
)

export const isYoutubeAnalyticsChart = chart => (
  chart.get('dataSource') === ChartDataSources.YOUTUBE_ANALYTICS
)

export const isLinkedInAnalyticsChart = chart => (
  chart.get('dataSource') === ChartDataSources.LINKED_IN_ANALYTICS
)

export const isFacebookAnalyticsChart = chart => (
  chart.get('dataSource') === ChartDataSources.FACEBOOK_ANALYTICS
)

export const isTwitterAnalyticsChart = chart => (
  chart.get('dataSource') === ChartDataSources.TWITTER_ANALYTICS
)

export const isImageChart = chart => (
  chart.get('dataSource') === ChartTypes.IMAGE || chart.get('type') === ChartTypes.IMAGE
)

export const isMozChart = chart => (
  chart.get('dataSource') === ChartDataSources.MOZ
)

export const isTextChart = chart => (
  chart.get('dataSource') === ChartDataSources.TEXT || chart.get('type') === ChartTypes.TEXT
)

export const isExternalWidgetChart = chart => (
  chart.get('dataSource') === ChartDataSources.EXTERNAL_WIDGET || chart.get('type') === ChartTypes.EXTERNAL_WIDGET
)
