import React from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Pagination,
  Select
} from '@mui/material'

import useDevice from 'hooks/useDevice'

const useStyles = makeStyles()({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 20
  },
  mobilePaginationContainer: {
    padding: 10
  },
  mobilePaginationBar: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})

function ContentCampaignsPagination({
  total,
  page,
  pageName,
  pageSize,
  search,
  setPage,
  setPageSize
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()
  const count = Math.ceil(total / pageSize)

  const handlePageChange = (_event, value) => {
    setPage({ key: pageName, value })
    search()
  }

  const handleLimitChange = event => {
    if (pageName === 'contentPage') {
      setPageSize({ key: 'contentLimit', value: event.target.value })
    }

    if (pageName === 'campaignPage') {
      setPageSize({ key: 'campaignLimit', value: event.target.value })
    }

    setPage({ key: pageName, value: 1 })
    search()
  }

  return (
    <Box className={device.get('mobile') ? classes.mobilePaginationContainer : classes.paginationContainer}>
      <FormControl size="small">
        <InputLabel id="pageSize">{i18n.get('show')}</InputLabel>
        <Select
          labelId="pageSize"
          label={i18n.get('show')}
          value={pageSize}
          onChange={handleLimitChange}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <Box className={device.get('mobile') ? classes.mobilePaginationBar : null}>
        <Pagination
          size={device.get('mobile') ? 'small' : 'medium'}
          count={count}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Box>
  )
}

ContentCampaignsPagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageName: PropTypes.string.isRequired,
  pageSize: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,

  search: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired
}

export default ContentCampaignsPagination
