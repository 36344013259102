import { connect } from 'react-redux'

import InfoDialog from 'components/administration/content_desk_settings/allowed_domains/info_dialog'

import {
  toggleAllowedDomainsInfoDialog as onClose
} from 'actions/administration'

import {
  getAllowedDomainsInfoDialogOpen
} from 'selectors'

const mapStateToProps = state => ({
  open: getAllowedDomainsInfoDialogOpen(state)
})

export default connect(
  mapStateToProps,
  {
    onClose
  }
)(InfoDialog)
