import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  actionBar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    width: '100%',

    '& button': {
      marginLeft: 10
    },

    '& button:first-of-type': {
      marginLeft: 0
    },

    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      padding: 10,
      width: 500,
      backgroundColor: theme.nrx.paper.backgroundPaperColor
    }
  }
}))

export default function ActionBar({ children, className }) {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.actionBar, className)}>
      {children}
    </div>
  )
}

ActionBar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
}
