import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { List, Map, Set, fromJS } from 'immutable'
import { createLoadable } from 'utils/loadable'
import tinycolor from 'tinycolor2'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import { keyframes } from 'tss-react'

import { NewsPageModules, ChartTypes } from 'static/constants'
import { defaultChartOpts as profileMonitoringOpts } from 'utils/page_identities'

import ErrorBoundary from 'components/error_boundary'

import ChartCard from 'components/chart_card'
import Description from 'containers/charts/chart/Description'
import { getTonalityColor } from 'utils/tonality'
import {
  formatData,
  useTonalityColor,
  useSentimentColor,
  generateChartLabel,
  generateChartTopLabel,
  isImageChart,
  isTextChart,
  isExternalWidgetChart,
  isPressrelationsNewsChart
} from 'utils/chart'
import ChartBranding from 'components/chart_branding'
import NoDataMessage from 'containers/charts/chart/NoDataMessage'

import ChartWithLabel from './chart_with_label'

const useStyles = makeStyles()(theme => ({
  noLabel: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  message: {
    width: '100%',
    height: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.warning.dark
  },
  description: {
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  ready: {
    animation: `${keyframes`
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          `} 1s;`
  },
  chartCardBranding: {
    position: 'absolute',
    right: 5,
    bottom: 0
  },
  link: {
    cursor: 'pointer'
  }
}))

const RadialBarChart = createLoadable(() => (
  import(/* webpackChunkName: "RadialBarChart" */ 'components/charts/radial_bar_chart')
))
const BarChart = createLoadable(() => (
  import(/* webpackChunkName: "BarChart" */ 'components/charts/bar_chart')
))
const LineChart = createLoadable(() => (
  import(/* webpackChunkName: "LineChart" */ 'components/charts/line_chart')
))
const PieChart = createLoadable(() => (
  import(/* webpackChunkName: "PieChart" */ 'components/charts/pie_chart')
))
const Treemap = createLoadable(() => (
  import(/* webpackChunkName: "Treemap" */ 'components/charts/treemap')
))
const RectangleChart = createLoadable(() => (
  import(/* webpackChunkName: "RectangleChart" */ 'components/charts/rectangle_chart')
))
const TagCloud = createLoadable(() => (
  import(/* webpackChunkName: "TagCloud" */ 'components/charts/tag_cloud')
))
const RadarChart = createLoadable(() => (
  import(/* webpackChunkName: "RadarChart" */ 'components/charts/radar_chart')
))
const Gauge = createLoadable(() => (
  import(/* webpackChunkName: "Gauge" */ 'components/charts/gauge')
))
const NewsFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "NewsFeedChart" */ 'components/charts/news_feed_chart')
))
const NewsTickerChart = createLoadable(() => (
  import(/* webpackChunkName: "NewsTickerChart" */ 'components/charts/news_ticker_chart')
))
const InfluencerFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "InfluencerFeedChart" */ 'components/charts/influencer_feed_chart')
))
const ImageFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "ImageFeedChart" */ 'components/charts/image_feed_chart')
))
const PageIdentityFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "PageIdentityFeedChart" */ 'components/charts/page_identity_feed_chart')
))
const PublicationFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "PublicationFeedChart" */ 'components/charts/publication_feed_chart')
))
const WorldMapChart = createLoadable(() => (
  import(/* webpackChunkName: "WorldMapChart" */ 'components/charts/world_map_chart')
))
const StateMapChart = createLoadable(() => (
  import(/* webpackChunkName: "StateMapChart" */ 'components/charts/state_map_chart')
))
const FunnelChart = createLoadable(() => (
  import(/* webpackChunkName: "FunnelChart" */ 'components/charts/funnel_chart')
))
const BubbleChart = createLoadable(() => (
  import(/* webpackChunkName: "BubbleChart" */ 'components/charts/bubble_chart')
))
const ImageChart = createLoadable(() => (
  import(/* webpackChunkName: "ImageChart" */ 'components/charts/image_chart')
))
const DarknetRectangleChart = createLoadable(() => (
  import(/* webpackChunkName: "DarknetRectangleChart" */ 'components/darknet/charts/rectangle')
))
const SocialMediaAnalyticsFeedChart = createLoadable(() => (
  import(/* webpackChunkName: "SocialMediaAnalyticsFeedChart" */ 'components/charts/social_media_analytics_feed_chart')
))
const TextChart = createLoadable(() => (
  import(/* webpackChunkName: "TextChart" */ 'components/charts/text_chart')
))
const ExternalWidgetChart = createLoadable(() => (
  import(/* webpackChunkName: "ExternalWidgetChart" */ 'components/charts/external_widget_chart')
))
const HeatmapChart = createLoadable(() => (
  import(/* webpackChunkName: "BarChart" */ 'components/charts/heatmap_chart')
))

const getSelfRenderingLabelCharts = theme => (theme === 'plotlights' ? Set([
  ChartTypes.NEWS_FEED,
  ChartTypes.NEWS_TICKER,
  ChartTypes.INFLUENCER_FEED,
  ChartTypes.PAGE_IDENTITY_FEED,
  ChartTypes.PUBLICATION_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED,
  ChartTypes.TEXT,
  ChartTypes.IMAGE_FEED
]) : Set([
  ChartTypes.NEWS_FEED,
  ChartTypes.NEWS_TICKER,
  ChartTypes.INFLUENCER_FEED,
  ChartTypes.PAGE_IDENTITY_FEED,
  ChartTypes.PUBLICATION_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED,
  ChartTypes.RECTANGLE,
  ChartTypes.TEXT,
  ChartTypes.IMAGE_FEED
]))

const isProfileMonitoring = Set(['pageIdentitiesTimeline'])

let threatStatus
const buildThreatInfo = (chartOpts, chart) => {
  let threatLevelColor
  const threatLevel = chart.getIn(['data', 'current'])
  const rgbBackgroundColor = chartOpts.backgroundColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
    (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))
    .toString()

  if (threatLevel <= 19) {
    threatLevelColor = '191,247,5'
    threatStatus = 'low'
  }

  if (threatLevel >= 20 && threatLevel <= 49) {
    threatLevelColor = '255,179,0'
    threatStatus = 'medium'
  }

  if (threatLevel >= 50) {
    threatLevelColor = '233,0,0'
    threatStatus = 'high'
  }

  return `linear-gradient(45deg, rgba(${threatLevelColor},1) 30%, rgba(${rgbBackgroundColor},1) 100%)`
}

const getChartOpts = ({ chart, chartOpts, cardProps, device, darkMode, currency, deviceWidth, disableAnimations }, theme, forcedTheme) => {
  const overrideOpts = chart.get('opts', Map({})).toJS()

  const selfRenderingLabelCharts = getSelfRenderingLabelCharts(forcedTheme)

  let result = {
    ...chartOpts,
    capFloatDigits: chart.get('dataSource') === 'linked_in_analytics',
    sortBy: chart.get('sortBy'),
    disableAnimations: device.get('ios') || disableAnimations,
    addPercentSign: chartOpts.addPercentSign || chart.get('thirdLevel') === 'interactionRates',
    showStatementTonality: chart.getIn(['savedSearch', 'moduleName']) === NewsPageModules.ANALYSIS,
    showSentiment: chart.getIn(['savedSearch', 'moduleName']) === NewsPageModules.NEWS_POOL,
    autoScroll: chartOpts.autoScroll && (overrideOpts.autoScroll || overrideOpts.autoScroll === undefined),
    zoomable: chart.get('firstLevel') === 'timeline',
    sortStacksByCount: ['tonalities', 'statementTonalities', 'sentiments'].indexOf(chart.get('secondLevel')) === -1,
    forcedTheme,
    currency: (chart.get('firstLevel') === 'ave' || chart.get('thirdLevel') === 'ave') ? currency : undefined,
    darkMode
  }

  if (isTextChart) {
    result = { ...result, deviceWidth, mobile: device.get('mobile') }
  }

  if (isProfileMonitoring.has(chart.get('firstLevel'))) {
    result = { ...result, ...profileMonitoringOpts }
  }

  if (selfRenderingLabelCharts.has(chart.get('type'))) {
    if (cardProps.hideScrollBars) {
      result = { ...result, hideScrollBars: true }
    }
  }

  if (chart.get('type') !== ChartTypes.TAG_COULD && chart.get('firstLevel') === 'emojis') {
    result = { ...result, minSize: 30, maxSize: 100 }
  }

  if (!overrideOpts.domain && (overrideOpts.usePercentAsValue || (chart.get('thirdLevel') || '').startsWith('moz'))) {
    result = { ...result, domain: [0, 100] }
  }

  delete overrideOpts.autoScroll
  delete overrideOpts.forcedTheme

  if (darkMode) {
    result = {
      ...result,
      axesLabelColor: theme.nrx.charts.axesLabelColor,
      axesStrokeColor: theme.nrx.charts.axesStrokeColor,
      labelBackgroundColor: theme.nrx.charts.labelBackgroundColor,
      labelColor: theme.nrx.charts.labelColor,
      topLabelColor: theme.nrx.charts.topLabelColor,
      gradientStartColor: theme.nrx.charts.gradientStartColor,
      gradientEndColor: theme.nrx.charts.gradientEndColor
    }
  }

  return { ...result, ...overrideOpts }
}

const getCardProps = ({ cardProps, chart, darkMode }, chartOpts) => {
  const isExternalDatasource = !isPressrelationsNewsChart(chart)
  const selfRenderingLabelCharts = getSelfRenderingLabelCharts(chartOpts.forcedTheme)

  let result = fromJS(cardProps)
  result = result.update('style', style => style || fromJS({}))

  if (chart.get('type') === ChartTypes.RECTANGLE) {
    result = result.setIn(['style', 'inverted'], true)
  }

  if (chartOpts.backgroundColor) {
    result = result.setIn(['style', 'backgroundColor'], chartOpts.backgroundColor)
  } else if (isExternalDatasource && !darkMode && !isTextChart(chart) && !isExternalWidgetChart(chart)) {
    result = result.setIn(['style', 'backgroundColor'], grey[100])
    result = result.setIn(['style', 'inverted'], false)
  }

  if (tinycolor(result.getIn(['style', 'backgroundColor'])).getBrightness() > 152) {
    result = result.setIn(['style', 'inverted'], false)
  }

  result = result.merge({
    isImageChart: isImageChart(chart),
    isTextChart: isTextChart(chart),
    isExternalWidgetChart: isExternalWidgetChart(chart)
  })

  if (selfRenderingLabelCharts.has(chart.get('type'))) {
    if (cardProps.hideScrollBars) {
      result = result.delete('hideScrollBars')
    }
  }

  if (chartOpts.forcedTheme === 'plotlights' && chart.get('type') === ChartTypes.RECTANGLE && chart.get('id') === 'avgHackishnessChart') {
    result = result.setIn(['style', 'background'], buildThreatInfo(chartOpts, chart))
    result = result.setIn(['style', 'backgroundColor'], 'none')
  }

  if ((chartOpts.plotlights && chart.get('type') === ChartTypes.RECTANGLE) || isTextChart(chart)) {
    result = result.set('withLabel', false)
  }

  return result.toJS()
}

const setBackgroundColor = (labelBackgroundColor, chartOpts, chart) => {
  if (chartOpts.forcedTheme === 'plotlights' && chart.get('type') === ChartTypes.RECTANGLE && chart.get('id') === 'avgHackishnessChart') {
    return 'none'
  }

  return labelBackgroundColor
}

const getLabelOpts = ({ label, topLabel }, chartOpts, chart) => {
  const { labelBackgroundColor, labelColor, topLabelColor, forcedTheme } = chartOpts

  return {
    label,
    topLabel,
    labelBackgroundColor: setBackgroundColor(labelBackgroundColor, chartOpts, chart),
    labelColor,
    topLabelColor,
    plotlights: forcedTheme === 'plotlights'
  }
}

const areEqual = (prevProps, nextProps) => {
  if (nextProps.data && !nextProps.data.equals(prevProps.data)) {
    return false
  }

  if (nextProps.darkMode !== prevProps.darkMode) {
    return false
  }

  if (!nextProps.chart.equals(prevProps.chart)) {
    return false
  }

  if (!nextProps.colors.equals(prevProps.colors)) {
    return false
  }

  if (!fromJS(nextProps.chartOpts).equals(fromJS(prevProps.chartOpts))) {
    return false
  }

  if (!fromJS(nextProps.cardProps).equals(fromJS(prevProps.cardProps))) {
    return false
  }

  return true
}

const Chart = props => {
  const {
    card,
    chart,
    classes: extraClasses,
    colors,
    currency,
    i18n,
    onClick,
    onLoadMoreClick,
    onMorePublicationsClick,
    onGenerateDescriptionClick
  } = props
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const forcedTheme = chart.get('opts', Map()).get('forcedTheme') || props.chartOpts.forcedTheme || props.whitelabelTheme

  const chartOpts = getChartOpts(props, theme, forcedTheme)
  const autoLabel = Boolean(
    (chartOpts.autoLabel !== undefined && chartOpts.autoLabel) || (chartOpts.autoLabel === undefined && props.autoLabel)
  )

  const topLabel = generateChartTopLabel(chart, i18n, autoLabel)
  const label = generateChartLabel(chart, i18n, autoLabel)
  const labelledImage = isImageChart(chart) && Boolean(label)
  const data = formatData(props.data, chart, colors)

  const cardProps = getCardProps(props, chartOpts)
  const withLabel = !cardProps.withLabel
    && props.label
    && (label || topLabel)
    && !getSelfRenderingLabelCharts(forcedTheme).has(chart.get('type'))

  const labelOpts = getLabelOpts({ label, topLabel }, chartOpts, chart)

  const renderMessage = message => (
    <div className={classes.message}>
      {message}
    </div>
  )

  const renderLoading = () => renderMessage(`${i18n.get('loading')}...`)
  const renderNoData = () => renderMessage(<NoDataMessage chart={chart} />)

  const renderDescription = () => (
    <div className={classes.description}>
      <Description
        chart={chart}
        onGenerateDescriptionClick={onGenerateDescriptionClick}
      />
    </div>
  )

  const renderBranding = () => (
    <ChartBranding
      inverted={Boolean(cardProps.style.inverted)}
      className={classes.chartCardBranding}
      dataSource={chart.get('dataSource')}
      thirdLevel={chart.get('thirdLevel')}
      firstLevel={chart.get('firstLevel')}
    />
  )

  const renderRectangleChart = () => {
    let backgroundColor = chartOpts.backgroundColor || cardProps.style.backgroundColor || colors.getIn(['charts', 'color01'])

    let fontColor = '#FFFFFF'
    let previousFontColor = chartOpts.axesLabelColor

    if (tinycolor(backgroundColor).getBrightness() > 152) {
      fontColor = '#424242'
    }

    if (useTonalityColor(chart.get('firstLevel'))) {
      backgroundColor = getTonalityColor(data.get('current'))
      fontColor = '#424242'
    }

    if (useSentimentColor(chart.get('firstLevel'))) {
      backgroundColor = getTonalityColor(data.get('current'))
      fontColor = '#424242'
    }

    if (['hackishness'].includes(chart.get('firstLevel')) && forcedTheme === 'plotlights') {
      fontColor = '#FFFFFF'
      previousFontColor = '#FFFFFF'
      backgroundColor = 'none'
    }

    let unit = null

    if (chart.get('firstLevel') === 'ave') {
      unit = currency
    } else if (['interactionRate'].includes(chart.get('firstLevel'))) {
      unit = '%'
    } else if (['hackishness'].includes(chart.get('firstLevel'))) {
      unit = ' /100'
    }

    let roundPrecision
    let prefix

    if (['interactionRate'].includes(chart.get('firstLevel'))) {
      roundPrecision = 4
      prefix = 'Ø'
    }

    const comp = forcedTheme === 'plotlights' ? (
      <>
        <DarknetRectangleChart
          {...chartOpts}
          firstLevel={chart.get('firstLevel')}
          threatText={i18n.get(`${threatStatus}_threat_info`, { count: data.get('current') })}
          currency={currency}
          data={data.toJS()}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          previousFontColor={previousFontColor}
          title={i18n.get('click_to_show_full_number')}
          roundPrecision={roundPrecision}
          prefix={prefix}
        />
      </>
    ) : (
      <RectangleChart
        {...chartOpts}
        unit={unit}
        data={data.toJS()}
        label={label}
        topLabel={topLabel}
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        title={i18n.get('click_to_show_full_number')}
        roundPrecision={roundPrecision}
        prefix={prefix}
      />
    )

    return comp
  }

  const renderChart = () => {
    const newClasses = { ...extraClasses }

    if (chart.get('loading')) {
      if (!withLabel) {
        return renderLoading()
      }

      return (
        <ChartWithLabel
          classes={newClasses}
          {...labelOpts}
        >
          {renderLoading()}
        </ChartWithLabel>
      )
    }

    if ((data === null || data === undefined) && !isImageChart(chart) && !isTextChart(chart) && !isExternalWidgetChart(chart)) {
      if (!withLabel && !getSelfRenderingLabelCharts(forcedTheme).has(chart.get('type'))) {
        return renderNoData()
      }

      return (
        <ChartWithLabel
          classes={newClasses}
          {...labelOpts}
        >
          {renderNoData()}
        </ChartWithLabel>
      )
    }

    newClasses.chart = cx(newClasses.chart, classes.ready)

    let comp
    let fontColor = '#FFFFFF'

    switch (chart.get('type')) {
      case ChartTypes.TREEMAP:
        if (['tonality', 'sentiment'].indexOf(chart.get('secondLevel')) !== -1) {
          fontColor = '#424242'
        }

        comp = (
          <Treemap
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
            fontColor={fontColor}
          />
        )
        break
      case ChartTypes.PIE:
        comp = (
          <PieChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.BAR:
        comp = (
          <BarChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.HORIZONTAL_BAR:
        comp = (
          <BarChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
            layout="horizontal"
          />
        )
        break
      case ChartTypes.LINE:
        if (data.size === 1) {
          comp = (
            <BarChart
              {...chartOpts}
              onClick={onClick}
              data={data.toJS()}
              layout="horizontal"
            />
          )
        } else {
          comp = (
            <LineChart
              {...chartOpts}
              onClick={onClick}
              data={data.toJS()}
            />
          )
        }

        break
      case ChartTypes.AREA:
        comp = (
          <LineChart
            {...chartOpts}
            area
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.RADIAL_BAR:
        comp = (
          <RadialBarChart
            {...chartOpts}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.TAG_CLOUD:
        comp = (
          <TagCloud
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.GAUGE:
        if (chart.get('firstLevel') === 'tonality') {
          comp = (
            <Gauge
              {...chartOpts}
              data={data.toJS()}
              onClick={onClick}
            />
          )
        } else {
          comp = (
            <Gauge
              {...chartOpts}
              data={data.toJS()}
            />
          )
        }

        break
      case ChartTypes.RADAR:
        comp = (
          <RadarChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.RECTANGLE:
        comp = renderRectangleChart()
        break
      case ChartTypes.NEWS_FEED:
        comp = (
          <NewsFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            onLoadMoreClick={onLoadMoreClick}
            onMorePublicationsClick={onMorePublicationsClick}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.NEWS_TICKER:
        comp = (
          <NewsTickerChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
          />
        )
        break
      case ChartTypes.INFLUENCER_FEED:
        comp = (
          <InfluencerFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.PAGE_IDENTITY_FEED:
        comp = (
          <PageIdentityFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.PUBLICATION_FEED:
        comp = (
          <PublicationFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED:
        comp = (
          <SocialMediaAnalyticsFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            onLoadMoreClick={onLoadMoreClick}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED:
        comp = (
          <SocialMediaAnalyticsFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            onLoadMoreClick={onLoadMoreClick}
            cardProps={cardProps}
          />
        )
        break
      case ChartTypes.WORLD_MAP:
        comp = (
          <WorldMapChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.STATE_MAP:
        comp = (
          <StateMapChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.FUNNEL:
        comp = (
          <FunnelChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      case ChartTypes.BUBBLE:
        comp = (
          <BubbleChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
            secondLevel={chart.get('secondLevel') || ''}
          />
        )
        break
      case ChartTypes.IMAGE:
        comp = (
          <ImageChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
          />
        )
        break
      case ChartTypes.IMAGE_FEED:
        comp = (
          <ImageFeedChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
            data={data}
            onLoadMoreClick={onLoadMoreClick}
            cardProps={cardProps}
            i18n={i18n}
          />
        )
        break
      case ChartTypes.TEXT:
        comp = (
          <TextChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
          />
        )
        break
      case ChartTypes.EXTERNAL_WIDGET:
        comp = (
          <ExternalWidgetChart
            {...chartOpts}
            label={label}
            topLabel={topLabel}
          />
        )
        break
      case ChartTypes.HEATMAP:
        comp = (
          <HeatmapChart
            {...chartOpts}
            onClick={onClick}
            data={data.toJS()}
          />
        )
        break
      default:
        comp = null
        break
    }

    if (!withLabel) {
      return comp
    }

    return (
      <ChartWithLabel
        classes={newClasses}
        {...labelOpts}
      >
        {comp}
      </ChartWithLabel>
    )
  }

  let result = (
    <>
      <ErrorBoundary>
        {renderChart()}
      </ErrorBoundary>
      {renderDescription(chartOpts)}
    </>
  )

  if (card && (labelledImage || !isImageChart(chart))) {
    result = (
      <ChartCard
        disableCard={!!chartOpts.disableCard}
        {...labelOpts}
        branding={renderBranding()}
        {...cardProps}
      >
        <ErrorBoundary>
          {renderChart()}
        </ErrorBoundary>
        {renderDescription(chartOpts)}
      </ChartCard>
    )
  }

  return result
}

Chart.defaultProps = {
  chartOpts: {},
  classes: {},
  autoLabel: false,
  card: false,
  label: true,
  cardProps: {},
  disableAnimations: false
}

Chart.propTypes = {
  colors: PropTypes.instanceOf(Map).isRequired,
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(List)
  ]),
  chartOpts: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  chart: PropTypes.instanceOf(Map).isRequired,
  device: PropTypes.instanceOf(Map).isRequired,
  deviceWidth: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  autoLabel: PropTypes.bool,
  classes: PropTypes.object,
  card: PropTypes.bool,
  cardProps: PropTypes.object,
  label: PropTypes.bool,
  darkMode: PropTypes.bool,
  whitelabelTheme: PropTypes.string,
  disableAnimations: PropTypes.bool,

  onClick: PropTypes.func,
  onLoadMoreClick: PropTypes.func,
  onMorePublicationsClick: PropTypes.func,
  onGenerateDescriptionClick: PropTypes.func
}

export default memo(Chart, areEqual)
