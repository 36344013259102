import { connect } from 'react-redux'

import CampaignActions from 'components/content_desk_new/campaigns/campaign/campaign_actions'

import {
  openCampaignForm,
  setCampaignDialogEditMode,
  switchToSearchPool
} from 'actions/content_desk'

const mapStateToProps = (_state, ownProps) => ({
  campaign: ownProps.campaign
})

export default connect(
  mapStateToProps,
  {
    onEditClick: openCampaignForm,
    onSwitchToSearchPoolClick: switchToSearchPool,
    setEditMode: setCampaignDialogEditMode
  }
)(CampaignActions)
