import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import moment from 'moment-timezone'

import { generateChartLabel, generateChartTopLabel } from 'utils/chart'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import red from '@mui/material/colors/red'
import { makeStyles } from 'tss-react/mui'

import Chart from 'containers/charts/Chart'
import SavedChartForm from 'containers/administration/saved_charts/SavedChartForm'

import useI18n from 'hooks/useI18n'

import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'

const useStyles = makeStyles()({
  chartContainer: {
    width: '100%',
    height: 300
  },
  savedChartSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingRight: 10
  },
  expiresAt: {
    width: '50%'
  },
  expired: {
    color: red[500]
  }
})

export default function SavedChart({
  savedChart,
  expanded,
  onExpand,
  data
}) {
  const { cx, classes } = useStyles()
  const i18n = useI18n()
  const chart = savedChart.get('chart')
  const autoLabel = chart.getIn(['opts', 'autoLabel']) !== false

  const isExpired = moment(savedChart.get('expiresAt')).diff(moment()) < 0

  return (
    <Accordion
      expanded={expanded}
      onChange={() => onExpand(savedChart.get('id'))}
      TransitionProps={{
        unmountOnExit: true
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{ content: classes.savedChartSummary }}
      >
        <Typography>
          <span>{generateChartTopLabel(chart, i18n)}</span>
          {!autoLabel && !!chart.get('topLabel') && <span> ({chart.get('topLabel')})</span>}
          <br />

          <span>{generateChartLabel(chart, i18n)}</span>
          {!autoLabel && !!chart.get('label') && <span> ({chart.get('label')})</span>}
        </Typography>

        <Typography classes={{ root: cx(classes.expiresAt, isExpired && classes.expired) }}>
          {i18n.get('expires_at')}: {moment(savedChart.get('expiresAt')).format('ll')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SavedChartForm savedChart={savedChart} />

        <br />

        <div className={classes.chartContainer}>
          <Chart
            card
            chart={chart}
            autoLabel
            data={data}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

SavedChart.propTypes = {
  savedChart: PropTypes.instanceOf(Map).isRequired,
  expanded: PropTypes.bool.isRequired,
  data: PropTypes.any,

  onExpand: PropTypes.func.isRequired
}
