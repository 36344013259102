import { connect } from 'react-redux'

import CloseConfirmDialog from 'components/content_desk_new/contents/content_edit_dialog/close_confirm_dialog'

import {
  saveContentStart,
  unlockContentStart
} from 'actions/content_desk'

const mapStateToProps = (_state, ownProps) => ({
  open: ownProps.open,
  onClose: ownProps.onClose
})

export default connect(
  mapStateToProps,
  {
    saveContentStart,
    unlockContentStart
  }
)(CloseConfirmDialog)
