import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      marginLeft: -10,
      marginRight: -10
    }
  }
}))

export default function FlexList({ children, className, columns }) {
  const { classes, cx } = useStyles()
  const columnCount = columns || 4

  const hiddenElements = []
  for (let i = 0; i < columnCount; i += 1) {
    hiddenElements.push((
      <div key={i} />
    ))
  }

  const childrenWithProps = React.Children.map(
    children,
    child => React.cloneElement(child, {
      columns: columnCount
    })
  )

  return (
    <div className={cx(classes.container, className)}>
      {childrenWithProps}
      {hiddenElements}
    </div>
  )
}

FlexList.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  columns: PropTypes.number
}
