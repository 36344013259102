import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import AddIcon from '@mui/icons-material/Add'

import AppBar from 'containers/AppBar'
import QueriesTable from 'containers/darknet/QueriesTable'
import { Button } from 'containers/themed'
import ScoreQueryDialog from 'containers/darknet/ScoreQueryDialog'
import SearchQueryDialog from 'containers/darknet/SearchQueryDialog'

import TransitionComponent from 'components/transition_component'

import TypeDialog from './type_dialog'

const useStyles = makeStyles()(theme => ({
  button: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2)
  }
}))

export default function QueryManager({
  scoreQueryDialogOpen,
  searchQueryDialogOpen,
  testRunning,
  onScoreDialogClose,
  onSearchDialogClose,
  onScoreDialogOpen,
  onSearchDialogOpen
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [typeDialogOpen, setTypeDialogOpen] = useState(false)
  const [editing, setEditing] = useState(true)
  const handleCreateClick = () => {
    setEditing(false)
    setTypeDialogOpen(true)
  }

  const handleTypeDialogClose = () => {
    setEditing(true)
    setTypeDialogOpen(false)
  }

  const handleOpenScoreQueryDialog = () => {
    setTypeDialogOpen(false)
    onScoreDialogOpen()
  }

  const handleOpenSearchQueryDialog = () => {
    setTypeDialogOpen(false)
    onSearchDialogOpen()
  }

  const handleScoreDialogClose = () => {
    setEditing(true)
    onScoreDialogClose()
  }

  const handleSearchDialogClose = () => {
    if (testRunning) {
      return
    }

    setEditing(true)
    onSearchDialogClose()
  }

  return (
    <>
      <TransitionComponent type="slideFadeDown">
        <AppBar
          title={i18n.get('query_manager')}
          color="inherit"
        />
      </TransitionComponent>

      <QueriesTable />

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleCreateClick}
      >
        {i18n.get('new')}
      </Button>

      <TypeDialog
        onClose={handleTypeDialogClose}
        onScoreClick={handleOpenScoreQueryDialog}
        onSearchClick={handleOpenSearchQueryDialog}
        open={typeDialogOpen}
      />

      <SearchQueryDialog
        onClose={handleSearchDialogClose}
        open={searchQueryDialogOpen}
        editing={editing}
      />

      <ScoreQueryDialog
        onClose={handleScoreDialogClose}
        open={scoreQueryDialogOpen}
        editing={editing}
      />
    </>
  )
}

QueryManager.propTypes = {
  scoreQueryDialogOpen: PropTypes.bool.isRequired,
  searchQueryDialogOpen: PropTypes.bool.isRequired,
  testRunning: PropTypes.bool.isRequired,

  onScoreDialogClose: PropTypes.func.isRequired,
  onSearchDialogClose: PropTypes.func.isRequired,
  onScoreDialogOpen: PropTypes.func.isRequired,
  onSearchDialogOpen: PropTypes.func.isRequired
}
