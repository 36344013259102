import { connect } from 'react-redux'

import GroupMode from 'components/custom_tags/group_mode'
import { getCustomTagGroups } from 'selectors'
import { showGroupDialog } from 'actions/custom_tags'

const mapStateToProps = state => ({
  groups: getCustomTagGroups(state)
})

export default connect(
  mapStateToProps,
  {
    onNewClick: showGroupDialog
  }
)(GroupMode)
