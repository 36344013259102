import { handleActions } from 'redux-actions'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = 0

export default handleActions({
  [NewsActions.newsRequestSuccess]: (state, { payload: { count } }) => count,
  [AppActions.resetState]: () => initialState
}, initialState)
