import React, { forwardRef, useState } from 'react'
import { v4 } from 'uuid'
import PropTypes from 'prop-types'

import { Button } from '../button'

const MuiBrowseButton = ({ children, innerRef, accept, ...props }) => {
  const id = useState(`upload-${v4()}`)
  const buttonProps = { ...props }

  return (
    <div>
      <input
        id={id}
        key="file"
        type="file"
        style={{ display: 'none' }}
        value=""
        accept={accept}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <label htmlFor={id}>
        <Button
          component="span"
          ref={innerRef}
          {...buttonProps}
        >
          {children}
        </Button>
      </label>
    </div>
  )
}

function BrowseButtonWrapper(props, ref) {
  return (
    <MuiBrowseButton
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(BrowseButtonWrapper)

MuiBrowseButton.propTypes = {
  children: PropTypes.any,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  innerRef: PropTypes.any
}
