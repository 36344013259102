import { connect } from 'react-redux'

import ContentsPagination from 'components/content_desk_new/contents_campaigns_show_all/contents_campaigns_pagination'
import {
  searchContentsStart,
  setLimit,
  setPage
} from 'actions/content_desk'
import {
  getCDeskContents,
  getCDeskContentLimit,
  getCDeskContentPage
} from 'selectors'

const mapStateToProps = state => ({
  pageName: 'contentPage',
  page: getCDeskContentPage(state),
  pageSize: getCDeskContentLimit(state),
  total: getCDeskContents(state).get('total') || 0
})

export default connect(
  mapStateToProps,
  {
    search: searchContentsStart,
    setPage,
    setPageSize: setLimit
  }
)(ContentsPagination)
