import { connect } from 'react-redux'

import OrderDialog from 'components/themax/order_dialog'
import { closeOrderDialog } from 'actions/themax'
import { orderPaidVersionStart } from 'actions/config'
import {
  getShowOrderDialog,
  getOrderRequestRunning,
  getStaticPaidFeatureById,
  getWhitelabelTermsAndConditionsLink,
  getWhitelabelPrivacyLink
} from 'selectors'

const mapStateToProps = state => ({
  open: getShowOrderDialog(state),
  loading: getOrderRequestRunning(state),
  paidFeature: getStaticPaidFeatureById(state, 'themax'),
  termsAndConditionsLink: getWhitelabelTermsAndConditionsLink(state),
  privacyLink: getWhitelabelPrivacyLink(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeOrderDialog,
    onOrderClick: orderPaidVersionStart
  }
)(OrderDialog)
