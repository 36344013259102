import moment from 'moment-timezone'
import { List, fromJS } from 'immutable'
import { createImmutableSelector } from 'utils/reselect'
import { datesToRange } from 'utils/date'
import decamelize from 'decamelize'
import camelcase from 'camelcase'

import { FilterNameMapping } from 'static/constants'
import { getDeletedNewsIds } from './news'

export const getNewsFilters = state => state.news.get('filter')

export const getSelectedChannels = state => getNewsFilters(state).get('channels')
export const getSelectedCountries = state => getNewsFilters(state).get('countries')
export const getSelectedCustomTagGroups = state => getNewsFilters(state).get('customTagGroups')
export const getSelectedCustomTags = state => getNewsFilters(state).get('customTags')
export const getSelectedDistributionAreas = state => getNewsFilters(state).get('distributionAreas')
export const getSelectedFilterGroups = state => getNewsFilters(state).get('filterGroups')
export const getSelectedIdentitySets = state => getNewsFilters(state).get('identitySets')
export const getSelectedLanguages = state => getNewsFilters(state).get('languages')
export const getSelectedMediaReviewCodes = state => getNewsFilters(state).get('mediaReviewCodes')
export const getSelectedMediaReviewTypes = state => getNewsFilters(state).get('mediaReviewTypes')
export const getSelectedMediaTypes = state => getNewsFilters(state).get('mediaTypes')
export const getSelectedNewsguardOrientations = state => getNewsFilters(state).get('newsguardOrientations')
export const getSelectedNewsguardRanks = state => getNewsFilters(state).get('newsguardRanks')
export const getSelectedOutlinkDomains = state => getNewsFilters(state).get('outlinkDomains')
export const getSelectedOutlinkUrls = state => getNewsFilters(state).get('outlinkUrls')
export const getSelectedCDCampaigns = state => getNewsFilters(state).getIn(['similarity', 'ccdCampaigns'])
export const getSelectedInterfaces = state => getNewsFilters(state).get('interfaces')
export const getSelectedSuppliers = state => getNewsFilters(state).get('suppliers')

export const getNewsFiltersAnalysis = state => getNewsFilters(state).get('analysis')
export const getNewsFiltersStatementCodes = state => getNewsFilters(state).get('statementCodes')
export const getNewsFiltersGroupedStatementCodes = state => getNewsFilters(state).get('groupedStatementCodes')
export const getNewsFiltersNewsQueries = state => getNewsFilters(state).get('newsQueries')
export const getNewsFiltersAnalysisSelectedCodeIds = createImmutableSelector(
  getNewsFiltersAnalysis,
  analysis => analysis.get('selectedCodes').map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisSelectedCodeIdsForExport = createImmutableSelector(
  getNewsFiltersAnalysis,
  getNewsFiltersStatementCodes,
  (analysis, statementCodes) => (statementCodes.isEmpty() ? analysis.get('selectedCodes') : statementCodes).map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisRestrictedCodeIds = createImmutableSelector(
  getNewsFiltersAnalysis,
  analysis => analysis.get('restrictedCodes').map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisExcludedCodeIds = createImmutableSelector(
  getNewsFiltersAnalysis,
  analysis => analysis.get('excludedCodes').map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisGroupedCodeIds = createImmutableSelector(
  getNewsFiltersAnalysis,
  analysis => analysis.get('groupedCodes').map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisGroupedCodeIdsForExport = createImmutableSelector(
  getNewsFiltersAnalysis,
  getNewsFiltersGroupedStatementCodes,
  (analysis, statementCodes) => (statementCodes.isEmpty() ? analysis.get('groupedCodes') : statementCodes).map(c => c.get('id')).toSet()
)
export const getNewsFiltersAnalysisArticleLevel = state => getNewsFiltersAnalysis(state).get('articleLevel')
export const getNewsFiltersAnalysisUseMediaReviewCodes = state => getNewsFiltersAnalysis(state).get('useMediaReviewCodes')
export const getNewsFiltersAnalysisStatementTonalities = state => getNewsFiltersAnalysis(state).get('statementTonalities')
export const getNewsFiltersAnalysisNewsStatementTonalities = state => getNewsFilters(state).get('newsStatementTonalities')
export const getNewsFiltersAnalysisStatementTonalityValues = createImmutableSelector(
  getNewsFiltersAnalysisStatementTonalities,
  getNewsFiltersAnalysisNewsStatementTonalities,
  (tonalities, newsTonalities) => (newsTonalities.isEmpty() ? tonalities : newsTonalities).map(t => t.get('value'))
)

export const getSelectedTonalities = state => getNewsFilters(state).get('tonalities')
export const getSelectedBooleans = state => getNewsFilters(state).get('booleans')
export const getSelectedGeoBoundingBox = state => getNewsFilters(state).get('geoBoundingBox')

export const getActiveSelectedBooleans = createImmutableSelector(
  state => getNewsFilters(state).get('booleans'),
  booleans => booleans.delete('globalClusters')
)

export const getActiveGeoFilter = createImmutableSelector(
  state => getNewsFilters(state).get('geoBoundingBox'),
  value => {
    const topLeftLat = value.getIn(['topLeft', 'lat'])
    const topLeftLon = value.getIn(['topLeft', 'lon'])
    const bottomRightLat = value.getIn(['bottomRight', 'lat'])
    const bottomRightLon = value.getIn(['bottomRight', 'lon'])

    if (topLeftLat && topLeftLon && bottomRightLat && bottomRightLon) {
      return value
    }

    return null
  }
)

export const getGlobalClusters = state => getNewsFilters(state).getIn(['booleans', 'globalClusters'])

export const getSelectedDateFrom = state => getNewsFilters(state).get('dateFrom')
export const getSelectedDateTo = state => getNewsFilters(state).get('dateTo')
export const getSelectedDateRange = state => getNewsFilters(state).get('dateRange')
export const getSelectedDateType = state => getNewsFilters(state).get('dateType')
export const getSelectedDateInterval = state => getNewsFilters(state).get('dateInterval')
export const getAllowedDateIntervals = state => getNewsFilters(state).get('allowedDateIntervals')
export const getAllDateIntervals = state => getNewsFilters(state).get('allDateIntervals')

export const getActiveFilters = createImmutableSelector(
  getNewsFilters,
  filters => {
    const similarityFilters = filters
      .get('similarity')
      .filter(value => List.isList(value))
      .reduce((acc, value, key) => acc.set(camelcase(`similarity_${key}`), value), fromJS({}))

    return filters.merge(similarityFilters).filter(value => List.isList(value) && !value.isEmpty())
  }
)

const addFilters = (data, filters, sourceKey, targetKey) => {
  const isExcludeFilter = f => (
    f.get('inverted') || (f.get('id') === -1 && f.get('excludeIds'))
  )
  const allEntities = filters.get(sourceKey) ? filters.get(sourceKey) : fromJS([])
  const entities = allEntities.filter(f => !(isExcludeFilter(f))).toJS()
  const excludedEntities = allEntities.filter(f => isExcludeFilter(f)).toJS()

  let result = data

  if (entities.length) {
    result = { ...result, [targetKey]: entities.map(el => el.id) }
  }

  if (excludedEntities.length) {
    result = { ...result, [`exclude_${targetKey}`]: excludedEntities.map(el => (el.excludeIds ? el.excludeIds : [el.id])).flat() }
  }

  return result
}

const addQueries = (data, filters, sourceKey, targetKey) => {
  const queries = filters.get(sourceKey).filter(f => !f.get('inverted')).toJS()
  const excludedQueries = filters.get(sourceKey).filter(f => f.get('inverted')).toJS()

  let result = data

  if (queries.length) {
    result = { ...result, [targetKey]: queries.map(el => el.value) }
  }

  if (excludedQueries.length) {
    result = { ...result, [`exclude_${targetKey}`]: excludedQueries.map(el => el.value) }
  }

  return result
}

export const getSelectedNewsFilters = (state, dateFrom, dateTo) => {
  let filters = getNewsFilters(state)
  const dateType = getSelectedDateType(state)

  if (dateFrom !== undefined && dateTo !== undefined) {
    filters = filters.merge({
      dateFrom,
      dateTo
    })
  }

  let news = {}

  news = addQueries(news, filters, 'newsQueries', 'queries')

  Object.keys(FilterNameMapping.news).filter(key => ['analysis', 'similarity'].indexOf(key) === -1).forEach(key => {
    news = addFilters(news, filters, key, FilterNameMapping.news[key])
  })

  const influencers = filters.get('influencers').filter(i => !i.get('inverted'))

  if (!influencers.isEmpty()) {
    news.identity_id = news.identity_id || []
    news.identity_id = news.identity_id.concat(influencers.flatMap(influencer => influencer.get('identityIds')).toJS())
  }

  const excludedInfluencers = filters.get('influencers').filter(i => i.get('inverted'))

  if (!excludedInfluencers.isEmpty()) {
    news.exclude_identity_id = news.exclude_identity_id || []
    news.exclude_identity_id = news.exclude_identity_id.concat(
      excludedInfluencers.flatMap(influencer => influencer.get('identityIds')).toJS()
    )
  }

  const deletedNewsIds = getDeletedNewsIds(state).toJS()

  if (deletedNewsIds.length) {
    news.exclude_ids = deletedNewsIds
  }

  if (filters.get('dateFrom')) {
    if (dateType === 'mediareview') {
      news[`${dateType}_date_from`] = moment(filters.get('dateFrom'))
        .startOf('day')
        .format()
    } else {
      news[`${dateType}_date_from`] = moment(filters.get('dateFrom'))
        .format()
    }
  }

  if (filters.get('dateTo')) {
    if (dateType === 'mediareview') {
      news[`${dateType}_date_to`] = moment(filters.get('dateTo'))
        .endOf('day')
        .format()
    } else {
      news[`${dateType}_date_to`] = moment(filters.get('dateTo'))
        .format()
    }
  }

  news.date_range = datesToRange(news[`${dateType}_date_from`], news[`${dateType}_date_to`])

  news.booleans = filters.get('booleans')
    .reduce((acc, value, key) => acc.set(decamelize(key), value), fromJS({}))
    .toJS()

  const geoBoundingBox = filters.get('geoBoundingBox')

  if (geoBoundingBox) {
    news.geo_bounding_box = {
      top_left: {
        lat: geoBoundingBox.getIn(['topLeft', 'lat']),
        lon: geoBoundingBox.getIn(['topLeft', 'lon'])
      },
      bottom_right: {
        lat: geoBoundingBox.getIn(['bottomRight', 'lat']),
        lon: geoBoundingBox.getIn(['bottomRight', 'lon'])
      }
    }
  }

  const analysisFilters = filters.get('analysis')

  if (analysisFilters) {
    news.analysis = {}

    Object.keys(FilterNameMapping.news.analysis).forEach(key => {
      news.analysis = addFilters(news.analysis, analysisFilters, key, FilterNameMapping.news.analysis[key])
    })

    news.analysis.article_level = analysisFilters.get('articleLevel')
    news.analysis.use_media_review_codes = analysisFilters.get('useMediaReviewCodes')
  }

  const similarityFilters = filters.get('similarity')

  if (similarityFilters) {
    news.similarity = {}

    Object.keys(FilterNameMapping.news.similarity).forEach(key => {
      news.similarity = addFilters(news.similarity, similarityFilters, key, FilterNameMapping.news.similarity[key])
    })
  }

  return news
}

export const getSelectedPublicationFilters = state => {
  const filters = getNewsFilters(state)

  let publication = {}

  publication = addQueries(publication, filters, 'publicationQueries', 'queries')

  Object.keys(FilterNameMapping.publication).forEach(key => {
    publication = addFilters(publication, filters, key, FilterNameMapping.publication[key])
  })

  return publication
}

export const getSelectedOutlinkFilters = state => {
  const filters = getNewsFilters(state)

  let outlink = {}

  Object.keys(FilterNameMapping.outlink).forEach(key => {
    outlink = addFilters(outlink, filters, key, FilterNameMapping.outlink[key])
  })

  return outlink
}

export const getSelectedAuthorFilters = state => {
  if (state.news.getIn(['filter', 'authorQueries']).isEmpty()) {
    return null
  }

  return {
    queries: state.news.get('filter').get('authorQueries').toJS().map(el => el.value)
  }
}

export const getSelectedPageIdentityFilters = state => {
  const filters = getNewsFilters(state)
  let result = {}

  Object.keys(FilterNameMapping.pageIdentity).forEach(key => {
    result = addFilters(result, filters, key, FilterNameMapping.pageIdentity[key])
  })

  return result
}

export const getFilterCount = createImmutableSelector(
  getActiveFilters,
  getActiveSelectedBooleans,
  getActiveGeoFilter,
  (activeFilters, activeBooleanFilters, activeGeoFilter) => {
    const filterCount = activeFilters.valueSeq().toList().concat(activeBooleanFilters.valueSeq().filter(v => v)).flatten(true).size

    if (activeGeoFilter) {
      return filterCount + 1
    }

    return filterCount
  }
)

export const hasFilters = state => getFilterCount(state) > 0
