import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { listMove } from 'utils/immutable'

import * as Actions from 'actions/shopping_cart'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  loading: false,
  activeNewsIdFirst: null,
  activeNewsIdSecond: null,
  hoveredNewsIdFirst: null,
  hoveredNewsIdSecond: null,
  newsIds: [],
  news: []
})

export default handleActions({
  [Actions.showClusterDialog]: (state, { payload }) => state.set('newsIds', payload),
  [Actions.hideClusterDialog]: () => initialState,

  [Actions.fetchReorderClusteredNewsStart]: state => state.set('loading', true),
  [Actions.fetchReorderClusteredNewsError]: state => state.set('loading', false),
  [Actions.fetchReorderClusteredNewsSuccess]: (state, { payload: news }) => state.merge({
    loading: false,
    news: fromJS(news)
  }),

  [Actions.moveNewsInCluster]: (state, { payload: { id, newIndex } }) => {
    const index = state.get('news').findIndex(n => id === n.get('id'))

    return state.update('news', news => listMove(news, index, newIndex))
  },

  [Actions.setActiveNewsIdsInCluster]: (state, { payload: { first, second } }) => state.merge({
    activeNewsIdFirst: first,
    activeNewsIdSecond: second
  }),

  [Actions.setHoveredNewsIdsInCluster]: (state, { payload: { first, second } }) => state.merge({
    hoveredNewsIdFirst: first,
    hoveredNewsIdSecond: second
  }),

  [AppActions.resetState]: () => initialState
}, initialState)
