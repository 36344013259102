// DATA
export const getInfluencersData = state => state.influencers.get('data')
export const getInfluencers = state => getInfluencersData(state).get('influencers')
export const getInfluencersTotal = state => getInfluencersData(state).get('total')

// UI
export const getInfluencersUi = state => state.influencers.get('ui')
export const getInfluencersFrom = state => getInfluencersUi(state).get('from')
export const getInfluencersSortBy = state => getInfluencersUi(state).get('sortBy')
export const getInfluencersRunning = state => getInfluencersUi(state).get('running')
export const getSelectedInfluencerIds = state => getInfluencersUi(state).get('selectedIds')
export const getSelectedInfluencers = state => (
  getInfluencersUi(state)
    .get('selectedIds').map(
      id => getInfluencers(state).find(p => p.get('id') === id)
    )
    .filter(p => p)
    .toList()
)

// ADD_TO_CONTACTS/DATA
export const getInfluencersAddToContactsData = state => state.influencers.getIn(['addToContacts', 'data'])
export const getAddInfluencerToContactsDialogInfluencer = state => getInfluencersAddToContactsData(state).get('influencer')
export const getAddInfluencerToContactsDialogContactData = state => getInfluencersAddToContactsData(state).get('contactData')
export const getInfluencerImportContactsForDistributionLists = state => getInfluencersAddToContactsData(state)
  .get('importContactsForDistributionLists')
export const getAddToContactsAlreadyImportedContacts = state => getInfluencersAddToContactsData(state).get('alreadyImportedContacts')

// ADD_TO_CONTACTS/UI
export const getInfluencersAddToContactsUi = state => state.influencers.getIn(['addToContacts', 'ui'])
export const getShowAddInfluencerToContactsDialog = state => getInfluencersAddToContactsUi(state).get('show')
export const getAddInfluencerToContactsDialogLoading = state => getInfluencersAddToContactsUi(state).get('loading')
export const getInfluencerDistributionListContactDialogOpen = state => getInfluencersAddToContactsUi(state)
  .get('distributionListCreateDialogOpen')
export const getAddToContactsContactsLoading = state => getInfluencersAddToContactsUi(state).get('contactsLoading')

// DETAILS/DATA
export const getInfluencersDetailsData = state => state.influencers.getIn(['details', 'data'])
export const getInfluencersDetailsInfluencer = state => getInfluencersDetailsData(state).get('influencer')
export const getInfluencersDetailsContacts = state => getInfluencersDetailsData(state).get('contacts')
export const getInfluencersDetailsReachChart = state => getInfluencersDetailsData(state).get('reachChart')
export const getInfluencersDetailsGroupedIdentities = state => getInfluencersDetailsData(state).get('groupedIdentities')
export const getInfluencersDetailsChannels = state => getInfluencersDetailsData(state).get('channels')
export const getInfluencersDetailsTotalReach = state => getInfluencersDetailsData(state).get('totalReach')
export const getInfluencersDetailsHasContacts = state => (
  getInfluencersDetailsContacts(state).valueSeq().find(c => !c.isEmpty()) !== undefined
)

// DETAILS/UI
export const getInfluencersDetailsUi = state => state.influencers.getIn(['details', 'ui'])
export const getShowInfluencerDetails = state => getInfluencersDetailsUi(state).get('show')
export const getInfluencersDetailsRunning = state => getInfluencersDetailsUi(state).get('running')
export const getInfluencersDetailsSelectedChannelId = state => getInfluencersDetailsUi(state).get('selectedChannelId')
