import { connect } from 'react-redux'

import DistributionListsDelete from
  'components/contact_management/distribution_lists/distribution_lists_item/distribution_lists_delete/DistributionListsDelete'

import { deleteDistributionListStart } from 'actions/contact_management'

const mapStateToProps = (_state, { distObject }) => ({
  distObject
})

export default connect(mapStateToProps, {
  onDelete: deleteDistributionListStart
})(DistributionListsDelete)
