import { createAction } from 'redux-actions'

const defaultPrefix = 'NEWS/'
let prefix = defaultPrefix

export const setSortBy = createAction(`${prefix}SET_SORT_BY`)
export const setSortDirection = createAction(`${prefix}SET_SORT_DIRECTION`)
export const currentIndexChange = createAction(`${prefix}CURRENT_INDEX_CHANGE`)
export const increasePaging = createAction(`${prefix}INCREASE_PAGING`)

export const moreNewsRequestError = createAction(`${prefix}MORE_NEWS_REQUEST_ERROR`)
export const moreNewsRequestStart = createAction(`${prefix}MORE_NEWS_REQUEST_START`)
export const moreNewsRequestSuccess = createAction(`${prefix}MORE_NEWS_REQUEST_SUCCESS`)

export const newsRequestError = createAction(`${prefix}NEWS_REQUEST_ERROR`)
export const newsRequestStart = createAction(`${prefix}NEWS_REQUEST_START`)
export const newsRequestSuccess = createAction(`${prefix}NEWS_REQUEST_SUCCESS`)
export const startNewsRequest = createAction(`${prefix}START_NEWS_REQUEST`)

export const fetchClusterStatsError = createAction(`${prefix}FETCH_CLUSTER_STATS_ERROR`)
export const fetchClusterStatsStart = createAction(`${prefix}FETCH_CLUSTER_STATS_START`)
export const fetchClusterStatsSuccess = createAction(`${prefix}FETCH_CLUSTER_STATS_SUCCESS`)

export const setNewsView = createAction(`${prefix}SET_NEWS_VIEW`)
export const toggleAdvancedSearch = createAction(`${prefix}TOGGLE_ADVANCED_SEARCH`)
export const toggleDateForm = createAction(`${prefix}TOGGLE_DATE_FORM`)
export const toggleHelpDialog = createAction(`${prefix}TOGGLE_HELP_DIALOG`)

export const selectViewConfigPreset = createAction(`${prefix}SELECT_VIEW_CONFIG_PRESET`)
export const viewConfigPresetSelected = createAction(`${prefix}VIEW_CONFIG_PRESET_SELECTED`)
export const setViewConfigPreset = createAction(`${prefix}SET_VIEW_CONFIG_PRESET`)
export const resetNews = createAction(`${prefix}RESET_NEWS`)

prefix = `${defaultPrefix}UI/`
export const toggleActiveFilters = createAction(`${prefix}TOGGLE_ACTIVE_FILTERS`)
export const setLastSeenNewsId = createAction(`${prefix}SET_LAST_SEEN_NEWS_ID`)
export const setShowNewsList = createAction(`${prefix}SET_SHOW_NEWS_LIST`)
export const setSelectedNewsIdToShowInDrawer = createAction(`${prefix}SET_SELECTED_NEWS_ID_TO_SHOW_IN_DRAWER`)
export const blockNewsRequest = createAction(`${prefix}BLOCK_NEWS_REQUEST`)
export const unblockNewsRequest = createAction(`${prefix}UNBLOCK_NEWS_REQUEST`)
export const setNewsRequestIsRunning = createAction(`${prefix}SET_NEWS_REQUEST_IS_RUNNING`)
export const setLastNewsIdClickedOnMorePublications = createAction(`${prefix}SET_LAST_NEWS_ID_CLICKED_ON_MORE_PUBLICATIONS`)
export const setYoutubePlayerActiveNewsId = createAction(`${prefix}SET_YOUTUBE_PLAYER_ACTIVE_NEWS_ID`)
export const setTiktokPlayerActiveNewsId = createAction(`${prefix}SET_TIKTOK_PLAYER_ACTIVE_NEWS_ID`)
export const removeTranslations = createAction(`${prefix}REMOVE_TRANSLATIONS`)
export const showAiAnalysisDialog = createAction(`${prefix}SHOW_AI_ANALYSIS_DIALOG`)

prefix = `${defaultPrefix}DATA/`
export const addNews = createAction(`${prefix}ADD_NEWS`)
export const updateNews = createAction(`${prefix}UPDATE_NEWS`)
export const decrementReferences = createAction(`${prefix}DECREMENT_REFERENCES`)
export const translateRequestStart = createAction(`${prefix}TRANSLATE_REQUEST_START`)
export const translateRequestSuccess = createAction(`${prefix}TRANSLATE_REQUEST_SUCCESS`)
export const translateRequestError = createAction(`${prefix}TRANSLATE_REQUEST_ERROR`)
export const setImageAsIllustration = createAction(`${prefix}SET_IMAGE_AS_ILLUSTRATION`)
export const setExtractTextFromPdf = createAction(`${prefix}SET_EXTRACT_TEXT_FROM_PDF`)
export const mergeExtractedText = createAction(`${prefix}MERGE_EXTRACTED_TEXT`)

prefix = `${defaultPrefix}EDIT/`
export const saveNews = createAction(`${prefix}SAVE_NEWS`)

export const updateNewsError = createAction(`${prefix}UPDATE_NEWS_ERROR`)
export const updateNewsStart = createAction(`${prefix}UPDATE_NEWS_START`)
export const updateNewsSuccess = createAction(`${prefix}UPDATE_NEWS_SUCCESS`)

export const createNewsError = createAction(`${prefix}CREATE_NEWS_ERROR`)
export const createNewsStart = createAction(`${prefix}CREATE_NEWS_START`)
export const createNewsSuccess = createAction(`${prefix}CREATE_NEWS_SUCCESS`)

export const deleteNewsError = createAction(`${prefix}DELETE_NEWS_ERROR`)
export const deleteNewsStart = createAction(`${prefix}DELETE_NEWS_START`)
export const deleteNewsSuccess = createAction(`${prefix}DELETE_NEWS_SUCCESS`)

export const getNewsError = createAction(`${prefix}GET_NEWS_ERROR`)
export const getNewsStart = createAction(`${prefix}GET_NEWS_START`)
export const getNewsSuccess = createAction(`${prefix}GET_NEWS_SUCCESS`)

export const uploadFileError = createAction(`${prefix}UPLOAD_FILE_ERROR`)
export const uploadFileStart = createAction(`${prefix}UPLOAD_FILE_START`)
export const uploadFileSuccess = createAction(`${prefix}UPLOAD_FILE_SUCCESS`)
export const removeUploadedFile = createAction(`${prefix}REMOVE_UPLOADED_FILE`)

export const extractTextFromPdfStart = createAction(`${prefix}EXTRACT_TEXT_FROM_PDF_START`)
export const extractTextFromPdfSuccess = createAction(`${prefix}EXTRACT_TEXT_FROM_PDF_SUCCESS`)
export const extractTextFromPdfError = createAction(`${prefix}EXTRACT_TEXT_FROM_PDF_ERROR`)

export const uploadVideoFileError = createAction(`${prefix}UPLOAD_VIDEO_FILE_ERROR`)
export const uploadVideoFileStart = createAction(`${prefix}UPLOAD_VIDEO_FILE_START`)
export const uploadVideoFileSuccess = createAction(`${prefix}UPLOAD_VIDEO_FILE_SUCCESS`)
export const removeUploadedVideoFile = createAction(`${prefix}REMOVE_UPLOADED_VIDEO_FILE`)

export const updateSelectedNews = createAction(`${prefix}UPDATE_SELECTED_NEWS`)
export const updateFieldOfSelectedNews = createAction(`${prefix}UPDATE_FIELD_OF_SELECTED_NEWS`)
export const showEditNewsDialog = createAction(`${prefix}SHOW_EDIT_NEWS_DIALOG`)
export const hideEditNewsDialog = createAction(`${prefix}HIDE_EDIT_NEWS_DIALOG`)
export const showNewsPageRemoveNewsDialog = createAction(`${prefix}SHOW_REMOVE_NEWS_DIALOG`)
export const hideNewsPageRemoveNewsDialog = createAction(`${prefix}HIDE_REMOVE_NEWS_DIALOG`)

export const searchPublicationsError = createAction(`${prefix}SEARCH_PUBLICATIONS_ERROR`)
export const searchPublicationsStart = createAction(`${prefix}SEARCH_PUBLICATIONS_START`)
export const searchPublicationsSuccess = createAction(`${prefix}SEARCH_PUBLICATIONS_SUCCESS`)
export const setPublicationsLoading = createAction(`${prefix}SET_PUBLICATIONS_LOADING`)

export const setSummaryOption = createAction(`${prefix}SET_SUMMARY_OPTION`)
export const automaticSummaryStart = createAction(`${prefix}AUTOMATIC_SUMMARY_START`)
export const automaticSummarySuccess = createAction(`${prefix}AUTOMATIC_SUMMARY_SUCCESS`)
export const automaticSummaryError = createAction(`${prefix}AUTOMATIC_SUMMARY_ERROR`)

export const invalidSearch = createAction(`${prefix}INVALID_SEARCH`)

prefix = `${defaultPrefix}SHOPPING/`
export const submitShoppingStart = createAction(`${prefix}SUBMIT_SHOPPING_START`)
export const submitShoppingSuccess = createAction(`${prefix}SUBMIT_SHOPPING_SUCCESS`)
export const submitShoppingError = createAction(`${prefix}SUBMIT_SHOPPING_ERROR`)

export const showShoppingNewsDialog = createAction(`${prefix}SHOW_SHOPPING_NEWS_DIALOG`)
export const hideShoppingNewsDialog = createAction(`${prefix}HIDE_SHOPPING_NEWS_DIALOG`)

prefix = `${defaultPrefix}SELECTED/`
export const selectNews = createAction(`${prefix}SELECT_NEWS`)
export const resetNewsSelection = createAction(`${prefix}RESET_NEWS_SELECTION`)

prefix = `${defaultPrefix}FILTER_TIMELINE/`
export const fetchFilterTimelineStart = createAction(`${prefix}FETCH_FILTER_TIMELINE_START`)
export const fetchFilterTimelineSuccess = createAction(`${prefix}FETCH_FILTER_TIMELINE_SUCCESS`)
export const fetchFilterTimelineError = createAction(`${prefix}FETCH_FILTER_TIMELINE_ERROR`)

prefix = `${defaultPrefix}GROUPS/`
export const updateGroupsStart = createAction(`${prefix}UPDATE_GROUPS_START`)
export const updateGroups = createAction(`${prefix}UPDATE_GROUPS`)
export const updateGroupStats = createAction(`${prefix}UPDATE_GROUP_STATS`)
