import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Button, Menu } from 'containers/themed'

import { getContentLanguageOptions } from 'utils/translation'

import { CdEditorMoods } from 'static/constants'
import { renderMood } from 'utils/ai_editor'
import { MenuItem } from '@mui/material'

const useStyles = makeStyles()({
  wrapper: {
    display: 'flex',
    marginTop: '1em'
  },
  actionButton: {
    marginRight: '0.5em',
    marginBottom: '0.5em'
  }
})

const EditorAiWorkspace = ({ contentType, loading, onButtonClick }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleTranslateClick = language => {
    onButtonClick({ contentType, actionType: 'TRANSLATE', language })
  }

  const handleCreateMoodClick = mood => {
    onButtonClick({ contentType, actionType: 'CREATE_MOOD', mood })
  }

  const renderTranslationOptions = option => (
    <MenuItem
      onClick={() => handleTranslateClick(option.label)}
      key={option.value}
    >
      {option.label}
    </MenuItem>
  )

  const renderMoodOptions = option => (
    <MenuItem
      onClick={() => handleCreateMoodClick(option.label)}
      key={option.id}
    >
      {i18n.get(`${renderMood(option.label)}`)}
    </MenuItem>
  )

  const translationOptions = getContentLanguageOptions().map(option => renderTranslationOptions(option))
  const moodOptions = CdEditorMoods.map(option => renderMoodOptions(option))

  return (
    <div className={classes.wrapper}>
      <div>
        <Button
          className={classes.actionButton}
          disabled={loading}
          onClick={() => onButtonClick({ contentType, actionType: 'PROLONG_TEXT' })}
          variant="contained"
          color="primary"
        >
          {i18n.get('prolong_text')}
        </Button>
        <Button
          className={classes.actionButton}
          disabled={loading}
          onClick={() => onButtonClick({ contentType, actionType: 'SHORTEN_TEXT' })}
          variant="contained"
          color="primary"
        >
          {i18n.get('shorten_text')}
        </Button>
        <Button
          className={classes.actionButton}
          disabled={loading}
          onClick={() => onButtonClick({ contentType, actionType: 'SPELL_CHECK' })}
          variant="contained"
          color="primary"
        >
          {i18n.get('spell_check')}
        </Button>
        <Menu
          handle={(
            <Button
              className={classes.actionButton}
              startIcon={<KeyboardArrowDownIcon />}
              disabled={loading}
              variant="contained"
              color="primary"
            >{i18n.get('translate')}
            </Button>
            )}
        >
          {translationOptions}
        </Menu>
        <Menu
          handle={(
            <Button
              className={classes.actionButton}
              startIcon={<KeyboardArrowDownIcon />}
              disabled={loading}
              variant="contained"
              color="primary"
            >{i18n.get('create_mood')}
            </Button>
            )}
        >
          {moodOptions}
        </Menu>
        <Button
          className={classes.actionButton}
          disabled={loading}
          onClick={() => onButtonClick({ contentType, actionType: 'CREATE_HASHTAGS' })}
          variant="contained"
          color="primary"
        >
          {i18n.get('create_hashtags')}
        </Button>
        {contentType === 'facebook' && (
          <Button
            className={classes.actionButton}
            disabled={loading}
            onClick={() => onButtonClick({ contentType, actionType: 'CREATE_FACEBOOK_POST' })}
            variant="contained"
            color="primary"
          >
            {i18n.get('create_facebook_post')}
          </Button>
        )}
        {contentType === 'linkedIn' && (
          <Button
            className={classes.actionButton}
            disabled={loading}
            onClick={() => onButtonClick({ contentType, actionType: 'CREATE_LINKEDIN_POST' })}
            variant="contained"
            color="primary"
          >
            {i18n.get('create_linkedin_post')}
          </Button>
        ) }
      </div>
    </div>
  )
}

EditorAiWorkspace.propTypes = {
  contentType: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired
}

export default EditorAiWorkspace
