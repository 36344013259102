import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import metrics from './metrics'
import chart from './chart'

export default combineReducers({
  ui,
  data,
  metrics,
  chart
})
