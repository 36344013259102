import { connect } from 'react-redux'

import OrderSuccessDialog from 'components/themax/order_success_dialog'
import { closeOrderSuccessDialog } from 'actions/themax'
import { getShowOrderSuccessDialog, getStaticPaidFeatureById } from 'selectors'

const mapStateToProps = state => ({
  open: getShowOrderSuccessDialog(state),
  paidFeature: getStaticPaidFeatureById(state, 'themax')
})

export default connect(
  mapStateToProps,
  {
    onClose: closeOrderSuccessDialog
  }
)(OrderSuccessDialog)
