const editorTheme = {
  paragraph: 'editor-paragraph',
  list: {
    ul: 'editor-listitem-ul',
    ol: 'editor-listitem-ol',
    listitem: 'editor-listitem',
    listitemChecked: 'editor-listitem-checked',
    listitemUnchecked: 'editor-listitem-unchecked',
    nested: {
      listitem: 'editor-listitem-nested'
    },
    olDepth: [
      'editor-listitem-ol1',
      'editor-listitem-ol2',
      'editor-listitem-ol3',
      'editor-listitem-ol4',
      'editor-listitem-ol5'
    ]
  },
  link: 'editor-link',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline'
  }
}

export default editorTheme
