import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { sort } from 'utils/normalizr'

import * as ConfigActions from 'actions/config'
import * as AppActions from 'actions/app'
import * as SavedDashboardActions from 'actions/saved_dashboard'
import * as SavedChartActions from 'actions/saved_charts'

const defaultValues = {
  channels: [],
  countries: [],
  states: [],
  darkowlLanguages: [],
  dispatchConfigs: [],
  distributionAreas: [],
  externalAccounts: [],
  filterGroups: [],
  languages: [],
  mediaReviewCodes: [],
  mediaReviewTypes: [],
  mediaSources: [],
  mediaTypes: [],
  mediaTopics: [],
  newsguardOrientations: [],
  newsguardRanks: [],
  tonalities: [],
  interfaces: [],
  suppliers: [],
  ccdContentTypes: [],
  ssoLinks: [],
  reactionTypes: [],
  paidFeatures: []
}

export const initialState = fromJS({
  entities: defaultValues,
  result: defaultValues,
  sorted: defaultValues
})

const mapStatics = payload => {
  const data = {
    ...payload,
    googleAnalyticsWebsites: (payload.googleAnalytics || {}).websites || [],
    linkedInAnalyticsOrganizations: (payload.linkedInAnalytics || {}).organizations || [],
    facebookAnalyticsPages: (payload.facebookAnalytics || {}).pages || []
  }

  const result = { entities: {}, result: {} }

  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      result.entities[key] = data[key].reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
      result.result[key] = data[key].map(item => item.id)
    }
  })

  return result
}

const addSorted = state => {
  const keys = state.get('entities').keySeq()

  return keys.reduce((acc, key) => acc.setIn(['sorted', key], sort(
    state.getIn(['result', key]),
    state.getIn(['entities', key])
  )), state)
}

export default handleActions({
  [ConfigActions.setStatics]: (state, action) => addSorted(fromJS(mapStatics(action.payload))),

  [combineActions(
    SavedDashboardActions.fetchSavedDashboardSuccess,
    SavedChartActions.fetchSavedChartSuccess
  )]: (state, action) => {
    const newData = addSorted(fromJS(mapStatics(action.payload)))

    return state
      .mergeIn(['entities'], newData.get('entities'))
      .mergeIn(['result'], newData.get('result'))
      .mergeIn(['sorted'], newData.get('sorted'))
  },

  [AppActions.resetState]: () => initialState
}, initialState)
