import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import moment from 'moment-timezone'
import copyToClipboard from 'copy-to-clipboard'
import fetch from 'cross-fetch'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import { makeStyles } from 'tss-react/mui'
import { isFeedChart, isRectangleChart } from 'utils/chart'

import { IconButton, Button, DatePicker } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Typography, Card, CardContent, CardActions } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  code: {
    width: '100%',
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    display: 'flex',
    alignItems: 'center',

    '& > pre': {
      fontSize: '90%',
      padding: 10,
      width: '100%',
      margin: 0,
      overflowY: 'auto',
      overflowX: 'hidden',
      color: 'inherit',
      backgroundColor: 'transparent'
    }
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const buildDynamicImageLink = (savedChart, baseUrl, timezone) => {
  const params = new URLSearchParams()
  params.set('width', 750)
  params.set('height', 300)
  params.set('timezone', timezone)

  if (isFeedChart(savedChart.get('chart'))) {
    params.set('width', 500)
    params.set('height', 1000)
  }

  if (isRectangleChart(savedChart.get('chart'))) {
    params.set('width', 300)
    params.set('height', 300)
  }

  return `${baseUrl}/bff/saved_charts/${savedChart.get('id')}/image.png?${params}`
}

const buildDynamicImageCode = (savedChart, baseUrl, timezone) => `<img src="${buildDynamicImageLink(savedChart, baseUrl, timezone)}">`

export default function SavedChartForm({
  savedChart: savedChartFromProps,
  baseUrl,
  saving,
  savable,
  timezone,
  labels,
  onDelete,
  onSave
}) {
  const { classes } = useStyles()
  const [codeToCopy, setCodeToCopy] = useState('')
  const [savedChart, setSavedChart] = useState(savedChartFromProps)
  const i18n = useI18n()
  const chart = savedChart.get('chart')
  const interactiveChartLink = `${baseUrl}/#/charts/${savedChart.get('id')}/embed`
  const interactiveChartCode = (
    `<iframe src="${interactiveChartLink}" width="750" height="300" frameBorder="0"></iframe>`
  )

  const dynamicImageCode = buildDynamicImageCode(savedChart, baseUrl, timezone)
  const dynamicImageLink = buildDynamicImageLink(savedChart, baseUrl, timezone)

  useEffect(() => {
    if (savedChartFromProps.getIn(['chart', 'loading']) !== chart.get('loading')) {
      setSavedChart(savedChart.setIn(['chart', 'loading'], savedChartFromProps.getIn(['chart', 'loading'])))
    }
  }, [savedChartFromProps.getIn(['chart', 'loading']), chart.get('loading')])

  useEffect(() => {
    fetch(dynamicImageLink.replace('image.png', 'update_cache'))
  }, [dynamicImageLink])

  const handleCopyClick = code => {
    copyToClipboard(code)
    setCodeToCopy(code)

    setTimeout(() => {
      setCodeToCopy('')
    }, 500)
  }

  const handleExpiresAtChange = value => {
    setSavedChart(savedChart.set('expiresAt', moment(value).format()))
  }

  return (
    <Card>
      <CardContent>
        <Typography>{i18n.get('paste_to_html_page', { type: `1. ${i18n.get('interactive_chart')} - ` })}</Typography>

        <div className={classes.code}>
          <pre>
            {interactiveChartCode}
          </pre>

          <IconButton
            title={i18n.get('copy_to_clipboard')}
            icon={<ContentCopyIcon />}
            onClick={() => handleCopyClick(interactiveChartCode)}
            saving={codeToCopy === interactiveChartCode}
          />
        </div>

        <br />

        <Typography>2. {i18n.get('interactive_chart')} Link</Typography>

        <div className={classes.code}>
          <pre>
            {interactiveChartLink}
          </pre>

          <IconButton
            title={i18n.get('copy_to_clipboard')}
            icon={<ContentCopyIcon />}
            onClick={() => handleCopyClick(interactiveChartLink)}
            saving={codeToCopy === interactiveChartLink}
          />
        </div>

        <br />

        <Typography>{i18n.get('paste_to_html_page', { type: `3. ${i18n.get('image')} - ` })}</Typography>

        <div className={classes.code}>
          <pre>
            {dynamicImageCode}
          </pre>

          <IconButton
            title={i18n.get('copy_to_clipboard')}
            icon={<ContentCopyIcon />}
            onClick={() => handleCopyClick(dynamicImageCode)}
            saving={codeToCopy === dynamicImageCode}
          />
        </div>

        <br />

        <Typography>4. {i18n.get('image')} Link</Typography>

        <div className={classes.code}>
          <pre>
            {dynamicImageLink}
          </pre>

          <IconButton
            title={i18n.get('copy_to_clipboard')}
            icon={<ContentCopyIcon />}
            onClick={() => handleCopyClick(dynamicImageLink)}
            saving={codeToCopy === dynamicImageLink}
          />
        </div>

        <br />

        <DatePicker
          inputVariant="outlined"
          label={i18n.get('expires_at')}
          value={savedChart.get('expiresAt')}
          minDate={moment().toDate()}
          maxDate={moment().add(12, 'months')}
          onChange={handleExpiresAtChange}
          inputProps={{
            fullWidth: true
          }}
        />
      </CardContent>

      <CardActions classes={{ root: classes.actions }}>
        <Button
          startIcon={<DeleteIcon />}
          color="secondary"
          onClick={() => onDelete(savedChart)}
        >
          {labels.delete || i18n.get('delete')}
        </Button>
        <Button
          disabled={!savable}
          startIcon={<SaveIcon />}
          variant="contained"
          color="primary"
          onClick={() => onSave(savedChart)}
          saving={saving}
        >
          {labels.save || i18n.get('save')}
        </Button>
      </CardActions>
    </Card>
  )
}

SavedChartForm.defaultProps = {
  labels: {},
  savable: true
}

SavedChartForm.propTypes = {
  savedChart: PropTypes.instanceOf(Map).isRequired,
  baseUrl: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
  saving: PropTypes.bool.isRequired,
  savable: PropTypes.bool,
  labels: PropTypes.object,

  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
