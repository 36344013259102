import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import DownloadIcon from '@mui/icons-material/Download'

import { IconButton } from 'containers/themed'
import DistributionListsDelete from 'containers/contact_management/DistributionListsDelete'
import DistributionListsFilter from 'containers/contact_management/DistributionListsFilter'

import { TableRow, TableCell } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  item: {
    backgroundColor: theme.isDark ? null : 'white'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  listIcon: {
    color: theme.isDark ? null : '#707070'
  }
}))

const DistributionListItem = ({
  distObject,
  selected,
  loading,
  isDistListsLocked,
  onEdit,
  onSelect,
  onDownload
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <TableRow
      className={classes.item}
    >
      <TableCell align="center">
        <PeopleAltOutlinedIcon className={classes.listIcon} />
      </TableCell>
      <TableCell>{distObject.get('name')}</TableCell>
      <TableCell>
        {distObject.get('contacts').size} {i18n.get('recipients')}
      </TableCell>
      <TableCell className={classes.actions}>
        <IconButton
          loading={loading}
          onClick={() => onDownload(distObject)}
          title={i18n.get('export')}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          disabled={isDistListsLocked}
          onClick={() => onEdit(distObject)}
          title={i18n.get('edit')}
        >
          <EditIcon />
        </IconButton>
        <DistributionListsFilter
          distObject={distObject}
          isDistListsLocked={isDistListsLocked}
        />
        <DistributionListsDelete distObject={distObject} />
      </TableCell>
      <TableCell>
        <IconButton
          size="small"
          onClick={() => onSelect(distObject)}
          title={selected ? i18n.get('in_cart') : i18n.get('select_list')}
        >
          {selected ? <CheckIcon color="primary" /> : <AddIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

DistributionListItem.propTypes = {
  distObject: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  onSelect: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired
}

export default DistributionListItem
