import { combineReducers } from 'redux-immutable'

import ui from './ui'
import filter from './filter'
import data from './data'

export default combineReducers({
  ui,
  filter,
  data
})
