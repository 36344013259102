import { connect } from 'react-redux'

import SavedCharts from 'components/administration/saved_charts'

import { getSavedCharts } from 'selectors'

import { fetchSavedChartStart } from 'actions/saved_charts'

const mapStateToProps = state => ({
  savedCharts: getSavedCharts(state)
})

export default connect(
  mapStateToProps,
  {
    onExpand: fetchSavedChartStart
  }
)(SavedCharts)
