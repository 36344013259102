import { connect } from 'react-redux'

import ShoppingCartNewsListItem from 'components/shopping_cart/news_list_item'

import {
  removeNewsFromShoppingCart,
  selectNews,
  selectNewsForSorting,
  shiftSelectNewsForSorting,
  showClusterDialog
} from 'actions/shopping_cart'

import { showEditNewsDialog } from 'actions/news'
import {
  getDevice,
  getShowSnippetsInShoppingCart,
  isShoppingCartNewsSelected,
  getNewsClusterIdsOfNewsInShoppingCart,
  getShoppingCartSelectedNews
} from 'selectors'

const mapStateToProps = (state, { news, codeId }) => ({
  isMobile: getDevice(state).get('mobile'),
  showSnippets: getShowSnippetsInShoppingCart(state),
  selected: isShoppingCartNewsSelected(state, news.get('id'), codeId) || getShoppingCartSelectedNews(state).isEmpty(),
  newsClusterIds: getNewsClusterIdsOfNewsInShoppingCart(state, news.get('id'))
})

export default connect(
  mapStateToProps,
  {
    onRemove: removeNewsFromShoppingCart,
    onSelect: selectNews,
    onEditClick: showEditNewsDialog,
    onSortingSelect: selectNewsForSorting,
    onShiftSortingSelect: shiftSelectNewsForSorting,
    onClusterClick: showClusterDialog
  }
)(ShoppingCartNewsListItem)
