import { connect } from 'react-redux'

import FilterableCustomTagList from 'components/custom_tags/filterable_custom_tag_list'
import { getI18n, getCustomTags, getCustomTagGroups, getGroupedAssignableCustomTags } from 'selectors'
import { toggleGroupedAssignableCustomTags } from 'actions/custom_tags'

const mapStateToProps = (state, { onAddClick, onRemoveClick, isAssigned }) => ({
  i18n: getI18n(state),
  customTags: getCustomTags(state),
  customTagGroups: getCustomTagGroups(state),
  grouped: getGroupedAssignableCustomTags(state),
  onAddClick,
  onRemoveClick,
  isAssigned
})

export default connect(
  mapStateToProps,
  {
    onGroupedToggle: toggleGroupedAssignableCustomTags
  }
)(FilterableCustomTagList)
