import { connect } from 'react-redux'

import TemplatesListItem from 'components/email_templates_library/templates_list_item'

import {
  deleteTemplateStart as onDelete,
  loadSelectedTemplate,
  openEditorDialog as openDialog,
  closePickerDialog,
  setReadMode as setTemplatesReadModeOnly,
  cloneTemplateStart as onTemplateClone
} from 'actions/email_templates_library'

import {
  getNewsradarId,
  getETLibraryReadMode
} from 'selectors'

const mapStateToProps = (state, { template, loadTemplate }) => ({
  loadTemplate,
  template,
  activeNewsradarId: getNewsradarId(state),
  readMode: getETLibraryReadMode(state)
})

export default connect(
  mapStateToProps,
  {
    onDelete,
    openDialog,
    loadSelectedTemplate,
    closePickerDialog,
    setTemplatesReadModeOnly,
    onTemplateClone
  }
)(TemplatesListItem)
