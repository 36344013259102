import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'

import {
  Paper,
  Typography
} from '@mui/material'

import ContactImage from 'components/contact_management/contact_image'

const useStyles = makeStyles()(({
  paper: {
    padding: 20
  },
  chip: {
    marginRight: '0.2em'
  }
}))

export default function ContactCard({
  data,
  countries,
  states,
  // topics,
  mediaTypes,
  languages
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const country = (countries.find(c => c.get('id') === data.get('countryId')) || ImmutableMap()).get('name')
  const state = (states.find(c => c.get('id') === data.get('stateId')) || ImmutableMap()).get('name')
  const publication = ((data.get('publications') || ImmutableList()).first() || ImmutableMap()).get('name')

  const outlet = data.get('outlet') || data.get('externalPublication')

  const mediaTypeIds = (data.get('mediaTypes') || data.get('contactsMediaTypes') || ImmutableMap())
    .map(type => type.get('id') || type.get('mediaTypeId'))
  const contactMediaTypes = mediaTypes.filter(mt => mediaTypeIds.includes(mt.get('id')))

  // const mediaTopicIds = (data.get('contactsMediaTopics') || ImmutableMap()).map(topic => topic.get('mediaTopicId'))
  // const contactMediaTopics = topics.filter(cmt => mediaTopicIds.includes(cmt.get('id')))

  // const journalistTopics = (data.get('topic') || ImmutableList()).map(el => el).join(', ')

  const languageIds = (data.get('languages') || data.get('contactsLanguages') || ImmutableMap())
    .map(l => l.get('id') || l.get('languageId'))
  const contactLanguages = languages.filter(cl => languageIds.includes(cl.get('id')))

  return (
    <Paper className={classes.paper}>
      <ContactImage url={data.get('picture')} />
      <Typography>{i18n.get('name')}: {data.get('firstName')} {data.get('lastName')}</Typography>
      <Typography>{i18n.get('job_title')}: {data.get('jobTitle')}</Typography>
      <Typography>{i18n.get('outlet')}: {publication || outlet}</Typography>
      <Typography>{i18n.get('country')}: {country}</Typography>
      <Typography>{i18n.get('state')}: {state}</Typography>
      <Typography>{i18n.get('website')}: <a href={data.get('website')}>{data.get('website')}</a></Typography>
      <Typography>{i18n.get('phone')}: {data.get('phone')}</Typography>
      <Typography>{i18n.get('fax')}: {data.get('fax')}</Typography>
      <Typography>{i18n.get('address')}: {data.get('address')}</Typography>
      <br />
      <Typography>{i18n.get('media_types')}: {contactMediaTypes.map(mt => mt.get('name')).join(', ')}</Typography>
      <Typography>{i18n.get('languages')}: {contactLanguages.map(mt => mt.get('name')).join(', ')}</Typography>
      <br />
      <Typography>{i18n.get('linkedin_url')}: <a href={data.get('linkedinUrl')}>{data.get('linkedinUrl')}</a></Typography>
      <Typography>{i18n.get('twitter_url')}: <a href={data.get('twitterUrl')}>{data.get('twitterUrl')}</a></Typography>
      <Typography>{i18n.get('facebook_url')}: <a href={data.get('facebookUrl')}>{data.get('facebookUrl')}</a></Typography>
      <Typography>{i18n.get('tiktok_url')}: <a href={data.get('tiktokUrl')}>{data.get('tiktokUrl')}</a></Typography>
      <Typography>{i18n.get('threads_url')}: <a href={data.get('threadsUrl')}>{data.get('threadsUrl')}</a></Typography>
      <Typography>{i18n.get('instagram_url')}: <a href={data.get('instagramUrl')}>{data.get('instagramUrl')}</a></Typography>
      <Typography>{i18n.get('youtube_url')}: <a href={data.get('youtubeUrl')}>{data.get('youtubeUrl')}</a></Typography>
    </Paper>
  )
}

ContactCard.propTypes = {
  data: PropTypes.instanceOf(ImmutableMap).isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  states: PropTypes.instanceOf(ImmutableList).isRequired,
  // topics: PropTypes.instanceOf(ImmutableList).isRequired,
  mediaTypes: PropTypes.instanceOf(ImmutableList).isRequired,
  languages: PropTypes.instanceOf(ImmutableList).isRequired
}
