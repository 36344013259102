import { connect } from 'react-redux'

import Settings from 'components/administration/settings'
import {
  updateConfigRequestStart,
  updateConfig,
  resetConfigToDefault,
  validateFilenameInConfig,
  resetColors,
  setTheme
} from 'actions/config'
import {
  getConfig,
  getCapabilities,
  getI18n,
  getDarkMode,
  getThemes,
  getStaticPdfDispatchConfigs,
  getWhitelabelTheme,
  isPlotlights
} from 'selectors'

const getWhitelabeledThemes = state => getThemes(state).filter(
  t => t.get('theme') === undefined || t.get('theme') === 'default' || t.get('theme') === getWhitelabelTheme(state)
)

const mapStateToProps = state => ({
  config: getConfig(state),
  i18n: getI18n(state),
  caps: getCapabilities(state),
  darkmode: getDarkMode(state),
  updateConfigRequestStarted: getConfig(state).get('updateConfigRequestStarted'),
  isValidFilename: getConfig(state).get('isValidFilename'),
  themes: getWhitelabeledThemes(state),
  isPlotlights: isPlotlights(state),
  pdfDispatchConfigs: getStaticPdfDispatchConfigs(state)
})

export default connect(
  mapStateToProps,
  {
    onSave: updateConfigRequestStart,
    onChange: updateConfig,
    reset: resetConfigToDefault,
    resetColors,
    validateFilename: validateFilenameInConfig,
    onThemeClick: setTheme
  }
)(Settings)
