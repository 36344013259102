import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'

import { Input } from 'containers/themed'
import TransitionComponent from 'components/transition_component'
import Card from 'components/card'

import useI18n from 'hooks/useI18n'

import { FormControl, FormHelperText } from '@mui/material'

const useStyles = makeStyles()({
  info: {
    top: 70,
    position: 'absolute',
    zIndex: 300,
    textAlign: 'center',
    backgroundColor: 'rgba(97, 97, 97, 0.9)',
    color: '#ffffff',
    width: '100%'
  },
  container: {
    position: 'relative'
  }
})

export default function ExportFilenameInput({
  value,
  variant,
  onChange,
  disabled,
  validate,
  valid,
  condensed
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [state, setState] = useState({ showInfo: false })

  const handleChange = event => {
    if (validate) {
      validate(event.target.value)
    }

    onChange(event.target.value)
  }

  const onFocus = () => {
    setState({ ...state, showInfo: true })
  }

  const onBlur = () => {
    setState({ ...state, showInfo: false })
  }

  const errorText = () => {
    if (valid !== undefined && valid) {
      return null
    }

    return (
      <FormHelperText>
        {i18n.get('filename_error_text')}
      </FormHelperText>
    )
  }

  const formatFilename = () => {
    let result = value || ''
    result = result.replace(/%year%/g, moment().format('YYYY'))
    result = result.replace(/%month%/g, moment().format('MM'))
    result = result.replace(/%day%/g, moment().format('DD'))
    result = result.replace(/%jahr%/g, moment().format('YYYY'))
    result = result.replace(/%monat%/g, moment().format('MM'))
    result = result.replace(/%tag%/g, moment().format('DD'))

    return result
  }

  const renderInfo = () => {
    if (!state.showInfo || condensed) {
      return null
    }

    return (
      <Card className={classes.info}>
        <p>{i18n.get('preview')}: {formatFilename()}[.pdf/.xlsx]</p>
        <p>{i18n.get('allowed_placeholders')}</p>
      </Card>
    )
  }

  return (
    <div className={classes.container}>
      <TransitionComponent>
        {renderInfo()}
      </TransitionComponent>
      <FormControl
        error={Boolean(errorText())}
        fullWidth
      >
        <Input
          error={Boolean(errorText())}
          variant={variant}
          type="text"
          label={i18n.get('export_filename')}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={100}
          disabled={disabled}
        />
        {errorText()}
      </FormControl>
    </div>
  )
}

ExportFilenameInput.defaultProps = {
  disabled: false,
  condensed: false
}

ExportFilenameInput.propTypes = {
  value: PropTypes.string.isRequired,
  variant: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  validate: PropTypes.func,
  valid: PropTypes.bool,
  condensed: PropTypes.bool
}
