import { connect } from 'react-redux'

import Selected from 'components/media_reviews/media_review_detail/selected_news_bar'

import { getMediaReviewSelectedNews, getCapabilities } from 'selectors'

import {
  showRemoveCustomTagDialog,
  showCustomTagDialog,
  resetNewsSelection,
  invertNewsSelection
} from 'actions/media_reviews'

const mapStateToProps = state => {
  const selectedNews = getMediaReviewSelectedNews(state)

  return {
    count: selectedNews.size,
    capabilities: getCapabilities(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onReset: resetNewsSelection,
    onPinClick: showCustomTagDialog,
    onUnpinClick: showRemoveCustomTagDialog,
    onInvertSelectionClick: invertNewsSelection
  }
)(Selected)
