import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '0 40px 40px',
    minHeight: '94%',
    width: '100%',
    position: 'absolute',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  noHeader: {
    paddingTop: 40,
    [theme.breakpoints.only('xs')]: {
      paddingTop: 10
    }
  },
  header: {
    paddingBottom: 40,
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 20
    }
  }
}))

export default function ContentContainer({ children, header, className, style }) {
  const { classes, cx } = useStyles()
  let containerClass = cx(classes.container, className)

  if (!header) {
    containerClass = cx(classes.container, classes.noHeader, className)
  }

  const renderHeader = () => {
    if (!header) {
      return null
    }

    return (
      <div className={classes.header}>
        {header}
      </div>
    )
  }

  return (
    <div
      className={containerClass}
      style={style}
    >
      {renderHeader()}
      {children}
    </div>
  )
}

ContentContainer.propTypes = {
  children: PropTypes.any,
  header: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object
}
