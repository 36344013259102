import { connect } from 'react-redux'

import Editions from 'components/subscriptions/editions'
import { getI18n, getStaticMediaReviewTypesForSubscription, getSelectedEditionIds } from 'selectors'
import { updateEditionSelection } from 'actions/subscriptions'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  editions: getStaticMediaReviewTypesForSubscription(state),
  selectedEditionIds: getSelectedEditionIds(state)
})

export default connect(
  mapStateToProps,
  {
    onSelect: updateEditionSelection
  }
)(Editions)
