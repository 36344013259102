import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  item1: {
    width: 'calc(100% / 1) !important',
    padding: 10
  },
  hidden1: {
    width: 'calc(100% / 1) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden'
  },
  item2: {
    width: 'calc(100% / 2) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    }
  },
  hidden2: {
    width: 'calc(100% / 2) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    }
  },
  item3: {
    width: 'calc(100% / 3) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    }
  },
  hidden3: {
    width: 'calc(100% / 3) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    }
  },
  item4: {
    width: 'calc(100% / 4) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden4: {
    width: 'calc(100% / 4) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item5: {
    width: 'calc(100% / 5) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden5: {
    width: 'calc(100% / 5) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item6: {
    width: 'calc(100% / 6) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden6: {
    width: 'calc(100% / 6) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item7: {
    width: 'calc(100% / 7) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden7: {
    width: 'calc(100% / 7) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item8: {
    width: 'calc(100% / 8) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden8: {
    width: 'calc(100% / 8) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item9: {
    width: 'calc(100% / 9) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden9: {
    width: 'calc(100% / 9) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  item10: {
    width: 'calc(100% / 10) !important',
    padding: 10,
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  },
  hidden10: {
    width: 'calc(100% / 10) !important',
    padding: 10,
    height: 0,
    visibility: 'hidden',
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% / 2) !important'
    },
    [theme.breakpoints.only('xs')]: {
      padding: '0 0 10px',
      width: '100% !important'
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3) !important'
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(100% / 4) !important'
    }
  }
}))

export default function FlexListItem(props) {
  const { classes, cx } = useStyles()
  const { children, className, columns } = props

  const itemClass = classes[`item${columns}`]

  const newProps = { ...props }
  delete newProps.children
  delete newProps.className
  delete newProps.columns

  return (
    <div
      className={cx(itemClass, className)}
      {...newProps}
    >
      {children}
    </div>
  )
}

FlexListItem.defaultProps = {
  columns: 4
}

FlexListItem.propTypes = {
  children: PropTypes.any.isRequired,
  columns: PropTypes.number,
  className: PropTypes.string
}
