import { Set, List, Map } from 'immutable'

import { NewsPageModules } from 'static/constants'

import { createImmutableSelector } from 'utils/reselect'
import { getNewsByIds } from './data'
import { getViewConfigModuleName } from './view_config'
import { getCustomTagById } from '../custom_tags'

export const getShoppingCart = state => state.news.get('shoppingCart')
export const getShoppingCartData = state => getShoppingCart(state).get('data')
export const getShoppingCartMainNewsIds = state => getShoppingCartData(state).get('news').map(n => n.first())
export const getShoppingCartFlatNewsIds = state => getShoppingCartData(state).get('news').flatten()
export const getShoppingCartCodesSize = state => getShoppingCartData(state).get('codes').size
export const getShoppingCartCode = (state, id) => getShoppingCartData(state).get('codes').find(code => code.get('id') === id)
export const getShoppingCartCodesRaw = state => getShoppingCartData(state).get('codes')
export const getShoppingCartCodes = createImmutableSelector(
  state => getShoppingCartData(state).get('codes').map(
    code => code.update('news', news => getNewsByIds(state, news).filter(n => n))
  ).filter(code => code.get('news') && !code.get('news').isEmpty()),
  codes => codes
)
export const getShoppingCartNewsByCodeId = (state, codeId) => (
  getShoppingCartCodes(state).find(g => g.get('id') === codeId).get('news')
)
export const getShoppingCartNews = createImmutableSelector(
  state => getNewsByIds(state, getShoppingCartMainNewsIds(state)),
  news => news.filter(n => n)
)
export const getAllShoppingCartNews = createImmutableSelector(
  state => getNewsByIds(state, getShoppingCartFlatNewsIds(state)),
  news => news.filter(n => n)
)

export const getShoppingCartNewsIds = state => getShoppingCartData(state).get('news')
export const getShoppingCartNewsCount = state => getShoppingCartFlatNewsIds(state).size
export const getShoppingCartNewsClusterCount = state => getShoppingCartData(state).get('news').size
export const isInShoppingCart = (state, id) => getShoppingCartMainNewsIds(state).includes(id)
export const getNewsClusterIdsOfNewsInShoppingCart = (state, id) => (
  getShoppingCartData(state).get('news').find(ids => ids.first() === id) || List([])
)

export const getNewsClusterIdsOfCodeInShoppingCart = (state, code) => getShoppingCartData(state)
  .get('news')
  .filter(ids => code.get('news').map(n => n.get('id')).includes(ids.first()))
  .flatten(true)

export const getNewsClusterIdsOfAllCodesInShoppingCart = state => (
  getShoppingCartCodes(state)
    .map(code => Map({
      codeId: code.get('id'),
      newsIds: getNewsClusterIdsOfCodeInShoppingCart(state, code)
    }))
)

export const getShoppingCartUi = state => getShoppingCart(state).get('ui')
export const getShoppingCartCustomTag = state => (
  getCustomTagById(state, `${getShoppingCartUi(state).get('customTagId')}`) || Map({})
)
export const getShoppingCartIsStructuredExport = state => {
  const customTag = getShoppingCartCustomTag(state)

  return Boolean(customTag.get('rssEnabled') || customTag.get('xmlEnabled') || customTag.get('jsonEnabled'))
}

export const getShoppingCartUngroupedOnly = state => (
  getShoppingCartIsStructuredExport(state)
  || getShoppingCartCodesSize(state) === 0
  || [NewsPageModules.NEWS_POOL].indexOf(getViewConfigModuleName(state)) > -1
)

export const getShoppingCartTabIndex = state => (getShoppingCartUngroupedOnly(state) ? 1 : getShoppingCartUi(state).get('tabIndex'))
export const getShowShoppingCartCustomTagDialog = state => getShoppingCartUi(state).get('showCustomTagDialog')
export const getShowShoppingCartRemoveCustomTagDialog = state => getShoppingCartUi(state).get('showRemoveCustomTagDialog')
export const getShoppingCartPinAllProgress = state => getShoppingCartUi(state).get('pinAllProgress')
export const getShowSnippetsInShoppingCart = state => getShoppingCartUi(state).get('showSnippets')
export const getShoppingCartRunning = state => getShoppingCartUi(state).get('running')

export const getShoppingCartSelectedNews = state => getShoppingCart(state).get('selectedNews') || Set([])
export const getShoppingCartSelectedNewsIds = state => getShoppingCartSelectedNews(state).map(n => {
  const a = n.split('_')

  return parseInt(a[a.length - 1], 10)
})
export const getShoppingCartSelectedNewsObjects = createImmutableSelector(
  state => getNewsByIds(state, getShoppingCartSelectedNewsIds(state)),
  news => news.filter(n => n)
)

export const isShoppingCartNewsSelected = (state, newsId, codeId) => {
  if (codeId === undefined) {
    return getShoppingCartSelectedNews(state).includes(`${newsId}`)
  }

  return getShoppingCartSelectedNews(state).includes(`${codeId}_${newsId}`)
}
export const isShoppingCartGroupedNewsListSelected = (state, id) => (
  getShoppingCartCode(state, id).get('news').every(n => getShoppingCartSelectedNews(state).includes(`${id}_${n}`))
)
export const isAllExpanded = state => !getShoppingCartData(state).get('codes').map(c => c.get('showNews')).includes(false)

export const getShoppingCartSorting = state => getShoppingCart(state).get('sorting')
export const getShoppingCartSelectedNewsForSorting = state => getShoppingCartSorting(state).get('selectedNews')

export const getShowShoppingCartClusterDialog = state => getShoppingCartUi(state).get('showClusterDialog')
export const getAutoAddShoppingCartNews = state => getShoppingCartUi(state).get('autoAddShoppingCartNews')
export const getShoppingCartCluster = state => getShoppingCart(state).get('cluster')
export const getShoppingCartClusterNewsIds = state => getShoppingCartCluster(state).get('newsIds')
export const getShoppingCartClusterNews = state => getShoppingCartCluster(state).get('news')
export const getShoppingCartClusterLoading = state => getShoppingCartCluster(state).get('loading')
export const getShoppingActiveNewsIdFirst = state => getShoppingCartCluster(state).get('activeNewsIdFirst')
export const getShoppingActiveNewsIdSecond = state => getShoppingCartCluster(state).get('activeNewsIdSecond')
export const getShoppingHoveredNewsIdFirst = state => getShoppingCartCluster(state).get('hoveredNewsIdFirst')
export const getShoppingHoveredNewsIdSecond = state => getShoppingCartCluster(state).get('hoveredNewsIdSecond')
