import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import Label from 'components/charts/label'
import PlotlightsLabel from 'components/charts/plotlights_label'

import { Card, CardContent } from '@mui/material'

const useStyles = makeStyles()({
  cardContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    overflow: 'visible'
  },
  cardContent: {
    width: '100%',
    padding: '15px !important',
    height: 1,
    flex: 1
  },
  cardContentExternalWidget: {
    width: '100%',
    padding: '0 !important',
    height: 1,
    flex: 1
  },
  cardContentNoPadding: {
    height: '100%',
    width: '100%'
  },
  card: {
    height: '100%',
    width: '100%',
    overflow: 'visible',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none'
  },
  padding: {
    paddingBottom: 15
  },
  analyticsImg: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '0 2px 2px 0'
  },
  labelContainer: {
    borderBottom: '0.5px solid #eeeeee'
  }
})

export default function ChartCard({
  children,
  legend,
  dummyCard,
  withLabel,
  isImageChart,
  isTextChart,
  isExternalWidgetChart,
  plotlights,
  label,
  topLabel,
  labelBackgroundColor,
  labelColor,
  topLabelColor,
  disableCard,
  branding,
  ...newProps
}) {
  const { classes, cx } = useStyles()

  const cardProps = {
    ...newProps,
    className: cx(
      classes.card,
      newProps.className,
      legend && classes.padding
    )
  }

  delete cardProps.hideScrollBars
  delete cardProps.blurContainerBottom

  if (dummyCard) {
    cardProps.style = { ...(cardProps.style), backgroundColor: 'transparent' }
  }

  if ((label || topLabel) && withLabel) {
    cardProps.style = { ...(cardProps.style), borderTopLeftRadius: 0, borderTopRightRadius: 0 }
  }

  const LabelComp = plotlights ? PlotlightsLabel : Label

  let labelRendered

  if (!dummyCard && withLabel) {
    labelRendered = (
      <LabelComp
        label={label}
        topLabel={topLabel}
        labelBackgroundColor={labelBackgroundColor || cardProps.style.backgroundColor || '#ffffff'}
        labelColor={labelColor}
        topLabelColor={topLabelColor}
        classes={{ labelContainer: classes.labelContainer }}
      />
    )
  }

  if (disableCard) {
    return (
      <div className={classes.cardContainer}>
        {labelRendered}

        <div className={cx(classes.cardContent, isExternalWidgetChart && classes.cardContentExternalWidget)}>
          {children}
        </div>
      </div>
    )
  }

  return (
    <Card classes={{ root: classes.cardContainer }}>
      {labelRendered}

      <Card {...cardProps}>
        {(!dummyCard && !isImageChart && !isTextChart && !isExternalWidgetChart) ? (
          <>
            {branding}

            <CardContent className={classes.cardContent}>
              {children}
            </CardContent>
          </>
        ) : (
          <div className={classes.cardContentNoPadding}>
            {branding}

            {children}
          </div>
        )}
      </Card>
    </Card>
  )
}

ChartCard.defaultProps = {
  legend: true,
  dummyCard: false,
  disableCard: false
}

ChartCard.propTypes = {
  children: PropTypes.any,
  legend: PropTypes.bool,
  dummyCard: PropTypes.bool,
  withLabel: PropTypes.bool,
  isImageChart: PropTypes.bool,
  isTextChart: PropTypes.bool,
  isExternalWidgetChart: PropTypes.bool,
  plotlights: PropTypes.bool,
  disableCard: PropTypes.bool,
  label: PropTypes.string,
  topLabel: PropTypes.string,
  labelBackgroundColor: PropTypes.string,
  labelColor: PropTypes.string,
  topLabelColor: PropTypes.string,
  branding: PropTypes.any
}
