import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap } from 'immutable'
import useI18n from 'hooks/useI18n'

import { TextField, Grid, Box } from '@mui/material'

export default function EditContactChannels({ contact, onChange }) {
  const i18n = useI18n()

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })

  return (
    <Box
      display="flex"
      component="form"
      justifyContent="space-between"
      sx={{
        '& .MuiTextField-root': { margin: 0 }
      }}
      gap={2}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('linkedin_url')}
            name="linkedinUrl"
            value={contact.get('linkedinUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('twitter_url')}
            name="twitterUrl"
            value={contact.get('twitterUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('facebook_url')}
            name="facebookUrl"
            value={contact.get('facebookUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('threads_url')}
            name="threadsUrl"
            value={contact.get('threadsUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('youtube_url')}
            name="youtubeUrl"
            value={contact.get('youtubeUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('tiktok_url')}
            name="tiktokUrl"
            value={contact.get('tiktokUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('instagram_url')}
            name="instagramUrl"
            value={contact.get('instagramUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('other_url')}
            name="otherUrl"
            value={contact.get('otherUrl') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
      </Grid>
    </Box>
  )
}

EditContactChannels.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  onChange: PropTypes.func.isRequired
}
