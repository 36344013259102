import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import {
  Tooltip
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import { shorten } from 'utils/string'

const CampaignName = ({
  campaign,
  maxLength
}) => {
  const i18n = useI18n()

  let campaignName = campaign.get('name') || ''

  if (campaignName.toLowerCase() === 'no campaign') {
    campaignName = i18n.get('no_campaign')
  }

  return (
    <Tooltip
      title={(campaignName).length < maxLength ? '' : campaignName}
      placement="top"
      arrow
    >
      <span>
        {shorten(campaignName, maxLength)}
      </span>
    </Tooltip>
  )
}

CampaignName.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  maxLength: PropTypes.number.isRequired
}

export default CampaignName
