import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import { capitalize } from 'utils/string'

import { makeStyles } from 'tss-react/mui'
import { Typography, Chip, Grid, Popover, Card, CardContent } from '@mui/material'
import NetworkIcon from 'components/darknet/network_icon'

const useStyles = makeStyles()(theme => ({
  networkIcon: {
    width: 26,
    height: 26
  },
  hackishness: {
    color: theme.palette.primary.light,
    fontSize: 14
  },
  hackishnessBackground: {
    fontSize: 14,
    color: theme.palette.text.disabled
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14
  },
  languagesWrapper: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  popover: {
    pointerEvents: 'none',
    width: 400,
    marginLeft: '0.5em'
  },
  cardContent: {
    '&:last-child': {
      padding: 10
    }
  },
  networkInfo: {
    fontSize: 14
  }
}))

export default function InfoSummary({ entry, query, searchResultDrilldown }) {
  const { crawlDate, network, hackishness, languages } = entry.toJS()

  const [anchorEl, setAnchorEl] = useState(null)
  const { classes } = useStyles()
  const i18n = useI18n()

  const roundHack = Math.round(hackishness * 100)

  const renderHackIcons = score => {
    const m = Math.round(score / 25) + 1

    return (
      <span
        className="fa-stack"
        style={{ verticalAlign: 'unset' }}
      >
        <i
          className={`${classes.hackishnessBackground} fas fa-signal-4 fa-stack-1x`}
        />
        <i
          className={`${classes.hackishness} fas fa-signal-${Math.min(m, 4)} fa-stack-1x`}
        />
      </span>
    )
  }

  const handleClickNetworkFilter = () => {
    searchResultDrilldown({ filterType: 'networks', value: network, name: capitalize(network) })
  }
  const handleClickLanguageFilter = el => {
    searchResultDrilldown({ filterType: 'languages', value: el.shortName, name: capitalize(el.name) })
  }
  const handleClickQueryFilter = () => {
    searchResultDrilldown({ filterType: 'searchQueries', value: query.get('id'), name: query.get('name') })
  }
  const handlePopoverOpen = event => setAnchorEl(event.currentTarget)
  const handlePopoverClose = () => setAnchorEl(null)

  const renderQuery = () => query && (
    <Grid
      item
      xs={12}
    >
      <Chip
        label={query.get('name')}
        variant="outlined"
        onClick={handleClickQueryFilter}
      />
    </Grid>
  )

  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          xs={6}
        >
          <Chip
            aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleClickNetworkFilter}
            icon={(
              <NetworkIcon
                network={network}
                className={classes.networkIcon}
              />
            )}
            label={i18n.get(network)}
            variant="outlined"
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <Typography className={classes.label}>
            {moment(crawlDate).format('lll')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={6}
          className={classes.languagesWrapper}
        >
          {languages.map((el, index) => (
            <Chip
              onClick={() => handleClickLanguageFilter(el)}
              label={capitalize(el.name)}
              variant="outlined"
              key={`${el.name}-${index}`}
            />
          ))}
        </Grid>

        <Grid
          item
          xs={6}
        >
          <Grid
            container
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography className={classes.label}>
                {`${i18n.get('hackishness')} ${roundHack}%`}
              </Typography>
            </Grid>
            <Grid item>
              {renderHackIcons(roundHack)}
            </Grid>
          </Grid>
        </Grid>

        {renderQuery()}
      </Grid>

      {network !== 'unclassified' && (
        <Popover
          className={classes.popover}
          id="mouse-over-popover"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          disableRestoreFocus
        >
          <Card
            variant="outlined"
          >
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.networkInfo}
              >
                {i18n.get(`${network}_network_info`)}
              </Typography>
            </CardContent>
          </Card>
        </Popover>
      )}
    </>
  )
}

InfoSummary.propTypes = {
  entry: PropTypes.instanceOf(Map),
  query: PropTypes.instanceOf(Map),
  searchResultDrilldown: PropTypes.func.isRequired
}
