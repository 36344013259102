import { connect } from 'react-redux'

import AverageStatistics from 'components/darknet/dashboard/average_statistics'
import { getDarknetStats } from 'selectors'

const mapStateToProps = state => ({
  avgHackishness: getDarknetStats(state).get('avgHackishness')
})

export default connect(mapStateToProps, {})(AverageStatistics)
