import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'

import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { Box } from '@mui/material'

import { Button } from 'containers/themed'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  switchActions: {
    display: 'flex',
    height: '45px !important',
    width: '100%'
  },
  switchAction: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    flexBasis: 0,
    '& > button': {
      minHeight: '100%',
      [theme.breakpoints.only('xs')]: {
        fontSize: '3.5dvw'
      }
    },
    [theme.breakpoints.only('xs')]: {
      flexGrow: 1,
      justifyContent: 'center'
    }
  },
  switchActionContent: {
    paddingTop: '4px',
    paddingLeft: '5px',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
    opacity: 0.7,
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '3px',
      paddingRight: '3px'
    }
  },
  activeBorder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    animation: `${keyframes`
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
        `} 225ms;`
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 25,
    marginRight: 5
  }
}))

export default function Tabs({
  hasFacebook,
  hasLinkedIn,
  hasOtsPr,
  view,
  requestRunning,
  changeContentTabView,
  resetFilters,
  search,
  setContentFilters,
  setKpiFilters,
  fetchKpiStatistics
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  if (!hasLinkedIn && !hasFacebook) {
    return null
  }

  const onTabChange = useCallback(buttonView => {
    changeContentTabView(buttonView)
    setKpiFilters({ key: 'type', value: buttonView })
    fetchKpiStatistics()

    let conditions = []

    if (buttonView === 'all') {
      resetFilters()
    }

    if (buttonView === 'email') {
      conditions.push({ field: 'type', name: 'type', value: 'email', comparison: 'equals' })

      if (hasOtsPr) {
        conditions.push({ field: 'type', name: 'type', value: 'ots_press_release', comparison: 'equals' })
      }

      conditions = conditions.map((condition, index) => ({ ...condition, index }))

      setContentFilters({
        operator: 'or',
        conditions
      })
    }

    if (buttonView === 'social_media') {
      if (hasLinkedIn) {
        conditions.push({ field: 'type', name: 'type', value: 'linkedin', comparison: 'equals' })
      }

      if (hasFacebook) {
        conditions.push({ field: 'type', name: 'type', value: 'facebook', comparison: 'equals' })
      }

      conditions = conditions.map((condition, index) => ({ ...condition, index }))

      setContentFilters({
        operator: 'or',
        conditions
      })
    }

    search()
  }, [view])

  useEffect(() => {
    onTabChange(view)
  }, [view])

  const handleTabChange = newButtonView => {
    if (view !== newButtonView) {
      onTabChange(newButtonView)
    }
  }

  const renderNavigationAction = (currentButtonView, label, isDisabled = false, Comp = ButtonContainer) => {
    const title = null

    let active

    if (view === currentButtonView) {
      active = (
        <Box
          className={classes.activeBorder}
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    const tabButton = () => (
      <Button
        disableRipple
        onClick={() => handleTabChange(currentButtonView)}
        disabled={isDisabled}
        loading={requestRunning}
      >
        <Box
          className={classes.switchActionContent}
          style={active && { color: colors.get('primary'), opacity: 1 }}
        >
          {label}
        </Box>
      </Button>
    )

    return (
      <Comp
        key={currentButtonView}
        className={classes.switchAction}
        title={title}
      >
        {tabButton()}
        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      'all',
      `${i18n.get('all_contents')}`
    )
  )

  buttons.push(
    renderNavigationAction(
      'email',
      `${i18n.get('email')}`
    )
  )

  buttons.push(
    renderNavigationAction(
      'social_media',
      `${i18n.get('social_media')}`
    )
  )

  if (buttons.length <= 1) {
    return null
  }

  return (
    <>
      <div className={classes.switchActions}>
        {buttons}
      </div>
    </>
  )
}

Tabs.propTypes = {
  hasFacebook: PropTypes.bool.isRequired,
  hasLinkedIn: PropTypes.bool.isRequired,
  hasOtsPr: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,

  changeContentTabView: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  setContentFilters: PropTypes.func.isRequired,
  setKpiFilters: PropTypes.func.isRequired,
  fetchKpiStatistics: PropTypes.func.isRequired
}
