import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  influencer: null,
  contactData: {
    selectedEmail: 0
  },
  importContactsForDistributionLists: [],
  alreadyImportedContacts: []
})

export default handleActions({
  [Actions.openAddInfluencerToContactsDialog]: (state, { payload }) => (
    state.set('influencer', fromJS(payload))
  ),

  [Actions.closeAddInfluencerToContactsDialog]: state => (
    state.set('influencer', null)
  ),
  [Actions.updateContactData]: (state, { payload }) => (
    state.setIn(['contactData', payload.key], fromJS(payload.value))
  ),
  [Actions.setImportContactsForDistributionLists]: (state, { payload }) => (
    state.set('importContactsForDistributionLists', fromJS([payload.contact]))
  ),

  [Actions.searchContactsSuccess]: (state, { payload }) => (
    state.set('alreadyImportedContacts', fromJS(payload.contacts))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
