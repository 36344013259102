import { put, call, takeEvery, all, delay } from 'redux-saga/effects'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/survey'
import * as Api from 'api/bff'

export function* submitSurvey() {
  try {
    const result = yield call(Api.submitSurvey)
    yield delay(1800)
    yield put(Actions.submitSurveySuccess(result))
  } catch (error) {
    yield put(Actions.submitSurveyError())
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchSubmitSurveyStart() {
  yield takeEvery(Actions.submitSurveyStart, submitSurvey)
}

export default function* surveySaga() {
  yield all([
    watchSubmitSurveyStart()
  ])
}
