import { connect } from 'react-redux'

import MediaReviews from 'components/media_reviews'
import {
  getMediaReviewsData,
  getStaticMediaReviewTypes,
  getMediaReviewsFilter,
  getMediaReviewsFilterRequestRunning,
  getUser
} from 'selectors'
import { setMediaReviewFilter, setMediaReviewFilterSize, filterMediaReviewsStart } from 'actions/media_reviews'
import { navigate } from 'actions/navigation'

const mapStateToProps = state => ({
  mediaReviews: getMediaReviewsData(state),
  mediaReviewTypes: getStaticMediaReviewTypes(state),
  filter: getMediaReviewsFilter(state),
  loading: getMediaReviewsFilterRequestRunning(state),
  user: getUser(state)
})

export default connect(
  mapStateToProps,
  {
    setMediaReviewFilter,
    setMediaReviewFilterSize,
    onFilterClick: filterMediaReviewsStart,
    navigate
  }
)(MediaReviews)
