import React, { useState } from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import { makeStyles } from 'tss-react/mui'
import { Button, Popover, IconButton } from 'containers/themed'

import { Typography } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  leftActions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      justifyContent: 'space-between'
    },
    '& > span:first-of-type': {
      [theme.breakpoints.only('xs')]: {
        marginRight: 5
      }
    },
    '& > button': {
      [theme.breakpoints.only('xs')]: {
        minWidth: 32,
        fontSize: 10,
        padding: 2
      }
    }
  },
  help: {
    maxWidth: 400,
    padding: 10
  }
}))

const defaultSortingDirections = {
  articleDate: 'shuffle',
  publicationName: 'shuffle',
  headline: 'shuffle',
  channelId: 'shuffle',
  topic: 'shuffle'
}

export default function Sortbar({ i18n, onSortBy }) {
  const { classes } = useStyles()
  const [sortingDirections, setSortingDirections] = useState(defaultSortingDirections)
  const [selectedField, setSelectedField] = useState(null)

  const handleSortingClick = field => {
    const order = sortingDirections[field] !== 'desc' ? 'desc' : 'asc'

    setSortingDirections({ ...defaultSortingDirections, [field]: order })
    setSelectedField(field)
    onSortBy({ field, order })
  }

  const sortButtonIcon = () => {
    if (sortingDirections[selectedField] === 'desc') {
      return (
        <i
          className="fa fa-sort-down"
          onClick={() => handleSortingClick(selectedField)}
        />
      )
    }

    if (sortingDirections[selectedField] === 'asc') {
      return (
        <i
          className="fa fa-sort-up"
          onClick={() => handleSortingClick(selectedField)}
        />
      )
    }

    return <i className={`fa fa-sort ${classes.unused}`} />
  }

  return (
    <div className={classes.leftActions}>
      <span>
        {sortButtonIcon()}
      </span>
      <Button
        onClick={() => handleSortingClick('articleDate')}
        color={selectedField === 'articleDate' ? 'secondary' : undefined}
      >
        {i18n.get('sort_by_article_date')}
      </Button>
      <Button
        onClick={() => handleSortingClick('channelId')}
        color={selectedField === 'channelId' ? 'secondary' : undefined}
      >
        {i18n.get('channel')}
      </Button>
      <Button
        onClick={() => handleSortingClick('publicationName')}
        color={selectedField === 'publicationName' ? 'secondary' : undefined}
      >
        {i18n.get('sort_by_publication')}
      </Button>
      <Button
        onClick={() => handleSortingClick('headline')}
        color={selectedField === 'headline' ? 'secondary' : undefined}
      >
        {i18n.get('sort_by_title')}
      </Button>
      <Button
        onClick={() => handleSortingClick('topic')}
        color={selectedField === 'topic' ? 'secondary' : undefined}
      >
        {i18n.get('topic')}
      </Button>

      <Popover
        classes={{ paper: classes.help }}
        handle={(
          <IconButton size="large">
            <HelpIcon />
          </IconButton>
        )}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html: i18n.get('sorting_hint')
          }}
        />
      </Popover>
    </div>
  )
}

Sortbar.propTypes = {
  i18n: PropTypes.object.isRequired,

  onSortBy: PropTypes.func.isRequired
}
