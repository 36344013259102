import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import WarningIcon from '@mui/icons-material/Warning'
import useI18n from 'hooks/useI18n'

import { Link } from '@mui/material'

const messageIcons = {
  warning: <WarningIcon />
}

const useStyles = makeStyles()({
  noData: {
    display: 'flex',
    alignItems: 'center',

    '& > div:first-of-type': {
      marginRight: 5
    }
  },
  link: {
    cursor: 'pointer'
  }
})

export default function NoDataMessage({ chart, navigate }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const defaultNoDataMessage = Map({
    message: i18n.get('no_chart_data'),
    moduleLink: null,
    icon: null
  })
  const noDataMessage = chart.get('chartError') || defaultNoDataMessage

  let content = (
    <div className={classes.noData}>
      <div>
        {messageIcons[noDataMessage.get('icon')]}
      </div>

      <div>
        {noDataMessage.get('message')}
        {noDataMessage.get('info') && (
          <>
            <br />
            {noDataMessage.get('info')}
          </>
        )}
      </div>
    </div>
  )

  if (noDataMessage.get('moduleLink')) {
    content = (
      <Link
        classes={{ root: classes.link }}
        color="inherit"
        underline="hover"
        onClick={() => navigate && navigate(noDataMessage.get('moduleLink'))}
      >
        {content}
      </Link>
    )
  }

  return content
}

NoDataMessage.propTypes = {
  chart: PropTypes.instanceOf(Map).isRequired,
  navigate: PropTypes.func
}
