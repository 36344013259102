import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/analysis'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  showPushChartToDashboardDialog: false,
  chartPushRunning: false,

  expandedNodes: {
    selected: [],
    restricted: [],
    excluded: [],
    grouped: []
  },

  expandedPanels: {
    selected: false,
    restricted: false,
    excluded: false,
    grouped: false
  }
})

export default handleActions({
  [Actions.showPushChartToDashboardDialog]: state => state.set('showPushChartToDashboardDialog', true),
  [Actions.hidePushChartToDashboardDialog]: state => state.set('showPushChartToDashboardDialog', false),

  [Actions.pushChartToDashboardStart]: state => state.set('chartPushRunning', true),
  [Actions.pushChartToDashboardSuccess]: state => state.set('chartPushRunning', false),
  [Actions.pushChartToDashboardError]: state => state.set('chartPushRunning', false),

  [Actions.toggleExpandedPanel]: (state, { payload }) => {
    const current = state.getIn(['expandedPanels', payload])

    return state.updateIn(['expandedPanels'], panels => panels.map((value, key) => {
      if (key === payload) {
        return !value
      }

      return current ? value : false
    }))
  },

  [Actions.setExpandedNodes]: (state, { payload: { type, nodes } }) => (
    state.setIn(['expandedNodes', type], (
      fromJS(nodes)
        .map(id => id.toString())
        .toSet()
        .toList()))
  ),

  [Actions.addExpandedNodes]: (state, { payload: { type, nodes } }) => (
    state.updateIn(['expandedNodes', type], oldNodes => (
      oldNodes.toSet().union(fromJS(nodes).map(id => id.toString()))
    ).toList())
  ),

  [Actions.removeExpandedNodes]: (state, { payload: { type, nodes } }) => (
    state.updateIn(['expandedNodes', type], oldNodes => (
      oldNodes.toSet().subtract(fromJS(nodes).map(id => id.toString()))
    ).toList())
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
