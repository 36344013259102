import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import ArticleText from 'components/media_reviews/media_review_detail/news_list_item/article_text'
import Item from 'containers/media_reviews/media_review_detail/news_list_item/Item'

import { Grid, ListItem, ListItemText } from '@mui/material'
import Summary from './summary'
import TopicalCluster from './topical_cluster'

const useStyles = makeStyles()(theme => ({
  itemImage: {
    marginBottom: '10px'
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  snippet: {
    marginTop: '5px',
    marginBottom: '15px',
    overflow: 'hidden',
    whiteSpace: 'normal',
    fontSize: '0.9rem'
  },
  snippetNoImage: {
    paddingLeft: '2rem',
    paddingRight: '2rem'
  },
  fullText: {
    paddingTop: '0',
    marginTop: '-10px',
    borderTop: `1px solid ${theme.palette.grey[400]}`
  },
  detailData: {
    marginTop: '-10px',
    borderTop: `1px solid ${theme.palette.grey[400]}`
  },
  newsListItem: {
    backgroundColor: theme.isDark ? theme.palette.grey[500] : theme.palette.grey[100]
  },
  detailDataOnly: {
    marginBottom: '10px'
  }
}))

const Row = props => (
  <Grid
    container
    direction="row"
    spacing={2}
    {...props}
  />
)

const Col = props => (
  <Grid
    item
    {...props}
  />
)

export default function NewsListItem({
  news,
  subsequentlyLoadedFullText,
  expanded,
  codeId,
  summaryExpanded
}) {
  const { classes, cx } = useStyles()
  const [fullTextVisible, setFullTextVisible] = useState(false)
  const isTopicalCluster = news.get('clusterType') === 'topical'

  const renderDetailData = () => {
    if (!expanded || isTopicalCluster) {
      return null
    }

    let image = null

    if (news.get('image')) {
      image = (
        <img
          width="150"
          className={classes.itemImage}
          src={news.get('image')}
          alt=""
        />
      )
    }

    return (
      <ListItem
        className={cx(classes.detailData, !(fullTextVisible || summaryExpanded) && classes.detailDataOnly)}
        disableGutters
      >
        <Row>
          {!!image && (
            <Col
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
            >
              <Row>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  lg={3}
                  xl={3}
                />
                <Col
                  xs={9}
                  sm={9}
                  md={9}
                  lg={9}
                  xl={9}
                >
                  <div>
                    {image}
                  </div>
                </Col>
              </Row>
            </Col>
          )}
          <Col
            xs={!image ? 12 : 7}
            sm={!image ? 12 : 7}
            md={!image ? 12 : 7}
            lg={!image ? 12 : 7}
            xl={!image ? 12 : 7}
          >
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div
                  className={cx(classes.snippet, classes.text, !image && classes.snippetNoImage)}
                  dangerouslySetInnerHTML={{
                    __html: news.get('snippet')
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          />
        </Row>
      </ListItem>
    )
  }

  const renderSummary = () => {
    if (isTopicalCluster) {
      return (
        <TopicalCluster
          news={news}
          codeId={codeId}
        />
      )
    }

    return (
      <Summary
        news={news}
        className={cx(fullTextVisible ? classes.detailData : classes.detailDataOnly)}
      />
    )
  }

  const maybeRenderFullText = () => {
    if (!fullTextVisible || !subsequentlyLoadedFullText) {
      return null
    }

    const text = subsequentlyLoadedFullText.get('text')

    return (
      <ListItem
        classes={{ root: cx(classes.fullText) }}
        disableGutters
      >
        <ListItemText style={{ marginTop: 0 }}>
          <ArticleText
            text={text}
            useColumns={text.length > 100}
          />
        </ListItemText>
      </ListItem>
    )
  }

  return (
    <div className={classes.newsListItem}>
      <Item
        news={news}
        codeId={codeId}
        onFullTextClick={setFullTextVisible}
        fullTextVisible={fullTextVisible}
      />

      {expanded && renderDetailData()}
      {summaryExpanded && !!news.get('summary') && renderSummary()}
      {maybeRenderFullText()}
    </div>
  )
}

NewsListItem.propTypes = {
  news: PropTypes.object.isRequired,
  codeId: PropTypes.number,
  expanded: PropTypes.bool.isRequired,
  summaryExpanded: PropTypes.bool.isRequired,
  subsequentlyLoadedFullText: PropTypes.object
}
