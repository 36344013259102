import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import useSavingSuccess from 'hooks/useSavingSuccess'
import { green } from '@mui/material/colors'
import { CircularProgress, IconButton, Icon } from '@mui/material'

const useStyles = makeStyles()({
  loading: {
    position: 'relative'
  },
  success: {
    top: 12,
    left: 12,
    position: 'absolute',
    color: green[500],
    opacity: 0,
    animation: `${keyframes`
            0% {
              opacity: 0;
              transform: scale(1);
            }
            25% {
              opacity: 1;
              transform: scale(2);
            }
            50% {
              opacity: 1;
              transform: scale(1);
            }
            100% {
              opacity: 0;
              transform: 'scale(1);
            }
          `} 2s;`
  }
})

const MuiIconButton = ({ innerRef, loading, saving, children, ...props }) => {
  const { classes, cx } = useStyles()
  const showSuccess = useSavingSuccess(saving)

  const buttonProps = { ...props }

  if (loading || saving || showSuccess) {
    buttonProps.disabled = true
    buttonProps.className = cx(buttonProps.className, classes.loading)
  }

  const iconComp = typeof props.icon === 'string' ? <Icon key="icon">{props.icon}</Icon> : props.icon

  return (
    <IconButton
      ref={innerRef}
      size="large"
      {...buttonProps}
    >
      {loading || saving ? (
        <CircularProgress
          variant="indeterminate"
          color="secondary"
          size={24}
        />
      ) : (
        <>
          {iconComp}
          {children}
        </>
      )}

      {showSuccess && <CheckIcon classes={{ root: classes.success }} />}
    </IconButton>
  )
}

function IconButtonWrapper(props, ref) {
  return (
    <MuiIconButton
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(IconButtonWrapper)

MuiIconButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  innerRef: PropTypes.any
}
