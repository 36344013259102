import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { debounce } from 'throttle-debounce'
import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import { grey } from '@mui/material/colors'

import AddIcon from '@mui/icons-material/Add'

import { BrowseButton } from 'containers/themed'

import { UploadedMediaFilesSizeLimit, UploadedMediaSingleFileSizeLimit } from 'static/constants'

const allowedMimeTypes = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv'
]

const useStyles = makeStyles()({
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    border: '2px dashed',
    borderColor: grey[400]
  },
  uploadButtonInnerContent: {
    textAlign: 'center'
  },
  addIcon: {
    fontSize: '3vw',
    color: grey[400]
  },
  fileSizeHint: {
    fontSize: 10,
    color: grey[400]
  }
})

const UploadMedia = ({
  mediaFileUploading,
  uploadedMediaFilesTotalSize,
  onUpload
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const onMediaUpload = debounce(50, file => {
    let doUpload = true

    if (!file) {
      doUpload = false
    }

    if (file && (file.size > UploadedMediaSingleFileSizeLimit)) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('error_filesize_exceeded')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (file && ((file.size + uploadedMediaFilesTotalSize) > UploadedMediaFilesSizeLimit)) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('exceeding_upload_storage_size_warning')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (doUpload) {
      onUpload({ file })
    }
  })

  return (
    <BrowseButton
      className={classes.uploadButton}
      accept={allowedMimeTypes.toString()}
      loading={mediaFileUploading}
      onChange={e => onMediaUpload(e.target.files[0])}
    >
      {!mediaFileUploading && (
        <div className={classes.uploadButtonInnerContent}>
          <AddIcon className={classes.addIcon} />
          <div className={classes.fileSizeHint}>
            {i18n.get('upload_file_limit', { n: 5 })}
          </div>
        </div>
      )}
    </BrowseButton>
  )
}

UploadMedia.propTypes = {
  mediaFileUploading: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired,

  onUpload: PropTypes.func.isRequired
}

export default UploadMedia
