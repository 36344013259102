import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  journalists: {
    entities: [],
    paginator: {
      totalCount: 0,
      pageSize: 20,
      currentPage: 1
    },
    facets: {}
  },
  outlets: {
    entities: [],
    paginator: {
      totalCount: 0,
      pageSize: 20,
      currentPage: 1
    },
    facets: {}
  },
  articles: {
    entities: [],
    cursor: '',
    previousCursor: '',
    pageSize: 20,
    facets: {}
  }
})

export default handleActions({
  [Actions.setEntity]: (state, { payload: { entity, value } }) => state.set(entity, fromJS(value)),
  [combineActions(
    Actions.combinedSearchStart,
    Actions.clearModuleData
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
