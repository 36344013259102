/* global navigator */

export const guessLocale = () => {
  let lang

  if (navigator.languages && navigator.languages.length) {
    // latest versions of Chrome and Firefox set this correctly
    [lang] = navigator.languages
  } else if (navigator.userLanguage) {
    // IE only
    lang = navigator.userLanguage
  } else {
    // latest versions of Chrome, Firefox, and Safari set this correctly
    lang = navigator.language
  }

  return lang ? lang.toLowerCase() : lang
}

export const getLocale = () => {
  const lang = guessLocale()

  let locale = 'en'

  /* istanbul ignore next */
  if (lang && lang.match(/de/)) {
    locale = 'de'
    /* istanbul ignore next */
  } else if (lang && lang.match(/ru/)) {
    locale = 'ru'
    /* istanbul ignore next */
  } else if (lang && lang.match(/fr/)) {
    locale = 'fr'
  }

  return locale
}

export const determineMomentLocale = (languageShort, locale, timezone) => {
  if (languageShort !== 'en') {
    return languageShort
  }

  const isEurope = Boolean(timezone.match(/Europe/))

  if (!isEurope && ['en-us', 'en-ca', 'en-ie'].indexOf(locale) !== -1) {
    return locale
  }

  return 'en-gb'
}
