import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { mapToOptions } from 'utils/autocomplete'
import { Autocomplete, RaisedPrimaryButton } from 'containers/themed'
import AppBar from 'containers/AppBar'

import ContentContainer from 'components/content_container'
import { StaggeredListItem } from 'components/staggered_list'

import { FlexList } from 'components/flex_list'
import Actions from 'components/media_reviews/actions'
import MediaReview from 'containers/media_reviews/MediaReview'

import MediaReviewStats from 'containers/media_reviews/MediaReviewStats'
import MediaReviewItem from 'containers/media_reviews/MediaReviewItem'
import SearchIcon from '@mui/icons-material/Search'

import { MediaReviewFilterSizes } from 'static/constants'

import useI18n from 'hooks/useI18n'

import { Typography } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  subtitle: {
    marginTop: 10,
    fontWeight: 300
  },
  newestMediaReviewContainer: {
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  item: {
    minHeight: 260
  },
  newestMediaReview: {
    width: '20%',
    paddingRight: 15,
    cursor: 'pointer',
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3)'
    },
    [theme.breakpoints.down('md')]: {
      paddingRight: 0,
      width: '100%',
      marginBottom: 10
    }
  },
  newestMediaReviewStats: {
    width: '80%',
    paddingLeft: 5,
    minHeight: 260,
    cursor: 'pointer',
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% / 3 * 2)'
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      width: '100%'
    }
  },
  headline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  filters: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  sizeSelect: {
    width: '10%',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  templateSelect: {
    width: '20%',
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  }
}))

export default function MediaReviews({
  mediaReviews,
  mediaReviewTypes,
  filter,
  loading,
  setMediaReviewFilter,
  setMediaReviewFilterSize,
  onFilterClick,
  navigate
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const templateOptions = mapToOptions(mediaReviewTypes)
  const sizeOptions = MediaReviewFilterSizes.map(value => ({ value, label: value.toString() }))
  const olderMediaReviews = mediaReviews.slice(1)

  return (
    <div>
      <AppBar
        title={i18n.get('media_reviews')}
        actions={
          <Actions />
        }
      />

      <ContentContainer>
        <div className={classes.headline}>
          <div>
            <Typography variant="h4">
              {i18n.get('media_reviews')}
            </Typography>
            <Typography
              variant="h6"
              className={classes.subtitle}
            >
              {i18n.get('newest_media_review')}
            </Typography>
          </div>

          <div className={classes.filters}>
            <Autocomplete
              className={classes.sizeSelect}
              onChange={option => setMediaReviewFilterSize(option.value)}
              placeholder=""
              label={i18n.get('editions')}
              value={sizeOptions.find(el => el.value === filter.get('size'))}
              options={sizeOptions}
            />
            <Autocomplete
              className={classes.templateSelect}
              isClearable
              isMulti
              onChange={value => setMediaReviewFilter(value.map(el => el.value))}
              placeholder=""
              label={i18n.get('select_media_review_types')}
              options={templateOptions}
            />
            <div>
              <RaisedPrimaryButton
                loading={loading}
                onClick={() => onFilterClick()}
                startIcon={<SearchIcon />}
              >{i18n.get('search')}
              </RaisedPrimaryButton>
            </div>
          </div>
        </div>

        <div className={classes.newestMediaReviewContainer}>
          {
            mediaReviews.slice(0, 1).map(
              (mediaReview, index) => (
                <StaggeredListItem
                  delay={0}
                  key={index}
                  className={classes.newestMediaReview}
                  onClick={() => navigate(`/app/media_reviews/${mediaReview.get('id')}`)}
                >
                  <MediaReview
                    className={classes.item}
                    mediaReview={mediaReview}
                    showChannels={false}
                  />
                </StaggeredListItem>
              )
            )
          }

          {
            mediaReviews.slice(0, 1).map(
              (mediaReview, index) => (
                <StaggeredListItem
                  delay={200}
                  key={index}
                  className={classes.newestMediaReviewStats}
                  onClick={() => navigate(`/app/media_reviews/${mediaReview.get('id')}`)}
                >
                  <MediaReviewStats
                    className={classes.item}
                    mediaReview={mediaReview}
                  />
                </StaggeredListItem>
              )
            )
          }
        </div>

        {!olderMediaReviews.isEmpty() && (
          <>
            <Typography
              variant="h6"
              className={classes.subtitle}
            >
              {i18n.get('last_media_reviews', { count: olderMediaReviews.size })}
            </Typography>

            <FlexList columns={5}>
              {olderMediaReviews.map((mediaReview, index) => (
                <MediaReviewItem
                  key={mediaReview.get('id')}
                  index={index}
                  mediaReview={mediaReview}
                  initialDelay={500}
                />
              ))}
            </FlexList>
          </>
        )}
      </ContentContainer>
    </div>
  )
}

MediaReviews.propTypes = {
  mediaReviews: PropTypes.instanceOf(List).isRequired,
  mediaReviewTypes: PropTypes.instanceOf(List).isRequired,
  filter: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,

  setMediaReviewFilter: PropTypes.func.isRequired,
  setMediaReviewFilterSize: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired
}
