import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { fromJS, Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Typography,
  Tooltip
} from '@mui/material'

import {
  Add as AddIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material'

import { StaggeredListItem } from 'components/staggered_list'

import { Button, Select, IconButton } from 'containers/themed'

import Journalist from 'containers/anewstip/results/Journalist'
import Outlet from 'containers/anewstip/results/Outlet'
import Article from 'containers/anewstip/results/Article'
import Pagination from 'containers/anewstip/results/Pagination'
import FilterButton from '../filter_button'

const useStyles = makeStyles()(theme => ({
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: '81vh'
  },
  resultsActions: {
    flex: '0 1 auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 10px'
  },
  resultsMainContainer: {
    flex: '1 1 auto',
    overflow: 'auto',
    paddingRight: '10px'
  },
  pagination: {
    flex: '0 1 auto'
  },
  resultsInnerContainer: {
    marginBottom: '20px'
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '20px'
  },
  selectAndAddAll: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '55%'
  },
  sorting: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    '& > div:first-of-type': {
      minWidth: 205,
      [theme.breakpoints.only('xs')]: {
        minWidth: '30vw'
      }
    },
    '& > *:last-child': {
      top: 10
    },
    paddingRight: '5px'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '80vh'
  },
  noCheckbox: {
    marginLeft: '40px'
  },
  checkboxHidden: {
    display: 'hidden'
  }
}))

const Result = ({
  resultsTab,
  results,
  filtersFormData,
  selectedResults,
  importResult,
  importRequestRunning,
  journalistsSearchRequestRunning,
  outletsSearchRequestRunning,
  articlesSearchRequestRunning,
  isLimitedFunctionality,
  isDistListsLocked,
  onFilterChange,
  onSearchJournalists,
  onSearchOutlets,
  onSearchArticles,
  setSelectedResults,
  onAddClick,
  onAddToDistributionListClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const sortBy = filtersFormData.getIn([resultsTab, 'sort', 'by'])
  const sortDirection = filtersFormData.getIn([resultsTab, 'sort', 'direction'])
  const entities = results.get('entities')
  const selected = selectedResults.get(resultsTab)
  const imported = importResult.map(el => el.getIn(['contactImported', 'externalId']))
  const filteredIds = entities.filter(el => !(imported.includes(el.get('id')) || el.get('alreadyImported'))).map(e => e.get('id'))

  const hideSelect = () => resultsTab !== 'journalists'
  const hideBtn = ent => resultsTab === 'outlets' || (resultsTab === 'articles' && ent.get('contactType') === 'outlet')

  const handleResultSelected = event => {
    const { target: { value } } = event

    if (selected.toJS().includes(value)) {
      const selectedUpdated = selected.toJS().filter(id => id !== value)
      setSelectedResults({ key: resultsTab, value: fromJS(selectedUpdated) })
    } else {
      setSelectedResults({ key: resultsTab, value: selected.push(value) })
    }
  }

  const handleSelectAllResults = () => {
    if (selected.size === filteredIds.size) {
      setSelectedResults({ key: resultsTab, value: fromJS([]) })
    } else {
      setSelectedResults({ key: resultsTab, value: fromJS(filteredIds) })
    }
  }

  const onSearch = () => {
    if (resultsTab === 'journalists') {
      onSearchJournalists()
    }

    if (resultsTab === 'outlets') {
      onSearchOutlets()
    }

    if (resultsTab === 'articles') {
      onSearchArticles()
    }
  }

  const renderAddToDistListButton = () => (
    <Button
      variant="contained"
      color="primary"
      disabled={!selected?.size > 0 || isDistListsLocked}
      loading={importRequestRunning}
      onClick={() => onAddToDistributionListClick()}
    >
      {i18n.get('add_to_distribution_list')}
    </Button>
  )

  const renderSelectAndAddAll = () => (
    <div className={classes.selectAndAddAll}>
      <FormGroup>
        <FormControlLabel
          control={(
            <Checkbox
              disabled={entities.size === 0}
              checked={entities.size > 0 && selected.size === filteredIds.size}
              onChange={handleSelectAllResults}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          )}
          label={i18n.get('select_all')}
        />
      </FormGroup>
      <Button
        disabled={!selected.size > 0}
        loading={importRequestRunning}
        variant="contained"
        color="primary"
        onClick={() => onAddClick()}
      >
        <AddIcon fontSize="small" />
        {i18n.get('add_to_contacts')}
      </Button>
      {isDistListsLocked ? (
        <Tooltip
          title={i18n.get('distribution_lists_limit_reached')}
          placement="left-start"
          arrow
        >
          <span>{renderAddToDistListButton()}</span>
        </Tooltip>
      ) : renderAddToDistListButton()}
    </div>
  )

  const onSortDirectionChange = () => {
    let direction

    if (sortDirection === 'asc') {
      direction = 'desc'
    }

    if (sortDirection === 'desc') {
      direction = 'asc'
    }

    const filters = filtersFormData.get(resultsTab)
    const sortFilters = filters.get('sort').set('direction', direction)
    onFilterChange({ key: resultsTab, value: filters.set('sort', sortFilters) })
    onSearch()
  }

  const onSortByChange = sort => {
    const filters = filtersFormData.get(resultsTab)
    const sortFilters = filters.get('sort').set('by', sort.value)
    onFilterChange({ key: resultsTab, value: filters.set('sort', sortFilters) })
    onSearch()
  }

  const renderSortingSwitch = () => {
    let options

    if (resultsTab === 'journalists') {
      options = [
        { value: 'first_name', label: i18n.get('first_name') },
        { value: 'last_name', label: i18n.get('last_name') },
        { value: 'influence_score', label: i18n.get('influence_score') },
        { value: 'outlet', label: i18n.get('outlet') }
      ]
    }

    if (resultsTab === 'outlets') {
      options = [
        { value: 'name', label: i18n.get('name') },
        { value: 'influence_score', label: i18n.get('influence_score') }
      ]
    }

    if (resultsTab === 'articles') {
      options = [
        { value: 'name', label: i18n.get('name') },
        { value: 'influence_score', label: i18n.get('influence_score') },
        { value: 'publish_date', label: i18n.get('publish_date') }
      ]
    }

    return (
      <div className={classes.sorting}>
        <FilterButton />
        <Select
          disabled={entities.size === 0}
          displayEmpty
          label={i18n.get('sort_by')}
          options={options}
          onChange={onSortByChange}
          value={sortBy}
          variant="outlined"
          size="small"
        />
        <div>
          <IconButton
            disabled={entities.size === 0}
            title={i18n.get(sortDirection === 'desc' ? 'descending' : 'ascending')}
            onClick={onSortDirectionChange}
            size="large"
          >
            {sortDirection === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          </IconButton>
        </div>

      </div>
    )
  }

  const renderResults = () => (
    <>
      {entities.map((entity, index) => (
        <div
          className={classes.resultsInnerContainer}
          key={index}
        >
          <StaggeredListItem delay={index * 50}>
            <Divider />
            <div className={classes.resultWrapper}>
              <div>
                {!hideSelect() && (
                  <Checkbox
                    className={hideSelect() ? classes.checkboxHidden : ''}
                    checked={selected.toJS().includes(entity.get('id'))}
                    onChange={handleResultSelected}
                    inputProps={{ 'aria-label': 'controlled' }}
                    value={entity.get('id')}
                    disabled={imported.includes(entity.get('id')) || entity.get('alreadyImported')}
                  />
                )}
              </div>
              {resultsTab === 'journalists' && (
                <Journalist
                  journalist={entity}
                  importResult={importResult}
                  requestRunning={importRequestRunning}
                  isLimitedFunctionality={isLimitedFunctionality}
                  isDistListsLocked={isDistListsLocked}
                  onAddClick={onAddClick}
                  onAddToDistributionListClick={onAddToDistributionListClick}
                />
              )}
              {resultsTab === 'outlets' && (
                <Outlet
                  outlet={entity}
                  isLimitedFunctionality={isLimitedFunctionality}
                />
              )}
              {resultsTab === 'articles' && (
                <Article
                  article={entity}
                  importResult={importResult}
                  requestRunning={importRequestRunning}
                  onAddClick={onAddClick}
                  hideButton={hideBtn(entity)}
                  onAddToDistributionListClick={onAddToDistributionListClick}
                  isDistListsLocked={isDistListsLocked}
                />
              )}
            </div>
          </StaggeredListItem>
        </div>
      ))}
    </>
  )

  const renderLimitedFunctionalityMessage = () => (
    <div className={classes.loaderContainer}>
      <Typography sx={{ fontWeight: 'bold' }}>{i18n.get('limited_results_message')}</Typography>
    </div>
  )

  const renderLoader = (
    <div className={classes.loaderContainer}>
      <CircularProgress
        size={34}
        variant="indeterminate"
      />
    </div>
  )

  if (resultsTab === 'journalists' && journalistsSearchRequestRunning) {
    return renderLoader
  }

  if (resultsTab === 'outlets' && outletsSearchRequestRunning) {
    return renderLoader
  }

  if (resultsTab === 'articles' && articlesSearchRequestRunning) {
    return renderLoader
  }

  return (
    <div className={classes.flexWrapper}>
      <div
        className={classes.resultsActions}
        style={{ justifyContent: resultsTab !== 'journalists' ? 'flex-end' : 'space-between' }}
      >
        {resultsTab === 'journalists' && renderSelectAndAddAll()}
        {renderSortingSwitch()}
      </div>
      <div className={classes.resultsMainContainer}>
        {entities.size > 0 ? renderResults()
          : (
            <> {isLimitedFunctionality ? (renderLimitedFunctionalityMessage()) : (
              <div className={classes.loaderContainer}>
                <Typography sx={{ fontWeight: 'bold' }}>{i18n.get('no_results_found').toUpperCase()}</Typography>
              </div>
            )}
            </>
          )}
      </div>
      <div className={classes.pagination}>
        <Pagination
          resultsTab={resultsTab}
          results={results}
        />
      </div>
    </div>
  )
}

Result.propTypes = {
  resultsTab: PropTypes.string.isRequired,
  results: PropTypes.instanceOf(Map).isRequired,
  filtersFormData: PropTypes.instanceOf(Map).isRequired,
  selectedResults: PropTypes.instanceOf(Map).isRequired,
  importResult: PropTypes.instanceOf(List).isRequired,
  journalistsSearchRequestRunning: PropTypes.bool.isRequired,
  outletsSearchRequestRunning: PropTypes.bool.isRequired,
  articlesSearchRequestRunning: PropTypes.bool.isRequired,
  importRequestRunning: PropTypes.bool.isRequired,
  isLimitedFunctionality: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  onFilterChange: PropTypes.func.isRequired,
  onSearchJournalists: PropTypes.func.isRequired,
  onSearchOutlets: PropTypes.func.isRequired,
  onSearchArticles: PropTypes.func.isRequired,
  setSelectedResults: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onAddToDistributionListClick: PropTypes.func.isRequired
}

export default Result
