import { useEffect, useRef, useState } from 'react'

export default function useInterval(callback, delay) {
  const savedCallback = useRef()
  const [intervalId, setIntervalId] = useState(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()

    const id = setInterval(tick, delay)
    setIntervalId(id)

    return () => clearInterval(id)
  }, [delay])

  return intervalId
}
