import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import {
  Box,
  Typography,
  TextField,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@mui/material'
import { red } from '@mui/material/colors'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'

import { Button, RaisedPrimaryButton, IconButton } from 'containers/themed'

import SelectedDistributionListsTable from 'containers/contact_management/SelectedDistributionListsTable'

const DistributionListMergeDialog = ({ data, open, loading, onChange, onClose, onMerge }) => {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const lists = data.get('lists')
  const uniqueContactIds = lists.map(l => l.get('contacts').map(c => c.get('id'))).flatten().toSet()

  // TO DO: Change the hardcoded limit to reflect the actual limit determined by the user's plan.
  const limit = 5000
  const limitExceeded = uniqueContactIds.size > limit

  const handleClose = () => onClose()

  const handleTextFieldChange = e => {
    onChange({ key: 'name', value: e.target.value })
  }

  const handleCheckboxChange = e => {
    onChange({ key: 'deleteMergedLists', value: e.target.checked })
  }

  const handleMergeClick = () => {
    onMerge()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('merge_distribution_lists')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: 3 }}
      >
        <Typography
          sx={{ marginBottom: '1em' }}
        >
          {i18n.get('merging_lists_message', { n: lists.size, c: uniqueContactIds.size })}
        </Typography>
        {limitExceeded && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }}>
            <WarningIcon
              fontSize="small"
              sx={{ color: red[600], marginRight: '0.5em' }}
            />
            <Typography
              variant="body2"
              sx={{ color: red[600] }}
            >
              {i18n.get('distribution_list_limit_warning', { n: limit })}
            </Typography>
          </div>
        )}
        <SelectedDistributionListsTable />
        <TextField
          sx={{
            marginTop: '1em'
          }}
          fullWidth
          required
          label={i18n.get('name')}
          inputProps={{ maxLength: 50 }}
          onChange={handleTextFieldChange}
          disabled={loading}
        />
        <FormGroup
          sx={{
            marginTop: '1em'
          }}
        >
          <FormControlLabel
            control={(
              <Checkbox
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleCheckboxChange}
                disabled={loading}
              />
          )}
            label={i18n.get('delete_merged_distribution_lists')}
          />
        </FormGroup>
      </DialogContent>

      <DialogActions>
        <Box
          m={1}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <Button
            onClick={handleClose}
            disabled={loading}
          >
            {i18n.get('close')}
          </Button>
          <RaisedPrimaryButton
            onClick={handleMergeClick}
            disabled={!data.get('name') || limitExceeded}
            loading={loading}
          >
            {i18n.get('merge')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DistributionListMergeDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onMerge: PropTypes.func.isRequired
}

export default DistributionListMergeDialog
