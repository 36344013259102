import { connect } from 'react-redux'

import SavedSearchesOptions from 'components/themax/saved_searches/SavedSearchesOptions'

import {
  resetThemaxSavedSearches
} from 'actions/themax'

import {
  showEditSavedSearchDialog,
  showUpdateSavedSearchDialog
} from 'actions/saved_searches'

import {
  getExecutedSavedSearch
} from 'selectors'

const mapStateToProps = state => ({
  executedSavedSearch: getExecutedSavedSearch(state)
})

export default connect(
  mapStateToProps,
  {
    onSaveButtonClick: showUpdateSavedSearchDialog,
    onNewButtonClick: showEditSavedSearchDialog,
    onResetButtonClick: resetThemaxSavedSearches
  }
)(SavedSearchesOptions)
