import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  contentContainer: {
    width: '100%',
    padding: '0px 0px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: theme.palette.common.black
  },
  content: {
    padding: 40,
    textAlign: 'center'
  },
  successMessage: {
    '& a': {
      color: theme.palette.secondary.main,
      fontWeight: 700
    }
  }
}))

const OrderSuccessDialog = ({
  open,
  paidFeature,
  onClose
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.closeButtonContainer}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.imageContainer}>
            <img
              src="https://static.pressrelations.de/pics/groot/themax/success_flag.png"
              alt="newspaper"
              width="200"
            />
          </Box>
          <Typography
            variant="h5"
            gutterBottom
          >
            {i18n.get('paid_feature_order_success_title')}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            className={classes.successMessage}
            dangerouslySetInnerHTML={{
              __html: i18n.get('paid_feature_order_success_message', { email: paidFeature.getIn(['config', 'supportMail']) })
            }}
          />
        </Box>
      </Paper>
    </Dialog>
  )
}

OrderSuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  paidFeature: PropTypes.instanceOf(Map).isRequired,

  onClose: PropTypes.func.isRequired
}

export default OrderSuccessDialog
