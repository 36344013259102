import { connect } from 'react-redux'

import { Capabilities } from 'static/constants'

import ContentNewDialog from 'components/content_desk_new/content_new_dialog'

import {
  closeNewContentDialog,
  setContentFormData,
  saveContentStart
} from 'actions/content_desk'

import {
  getCDeskShowNewContentDialog,
  getStaticCdContentTypes,
  getStaticFacebookAnalyticsPages,
  getLinkedInProfile,
  getCDeskNewContentDialogSocialOnly,
  hasCapability,
  getCDeskConfig
} from 'selectors'

const mapStateToProps = state => ({
  contentDeskContentTypes: getStaticCdContentTypes(state),
  hasContactsManagement: !!hasCapability(state, Capabilities.HAS_CONTACTS_MANAGEMENT_MODULE),
  hasFacebook: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_FACEBOOK),
  hasLinkedIn: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_LINKED_IN),
  hasOts: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_OTS_PR),
  open: getCDeskShowNewContentDialog(state),
  pages: getStaticFacebookAnalyticsPages(state),
  profile: getLinkedInProfile(state),
  socialOnly: getCDeskNewContentDialogSocialOnly(state),
  config: getCDeskConfig(state)
})

export default connect(
  mapStateToProps,
  {
    closeNewContentDialog,
    setContentFormData,
    saveContentStart
  }
)(ContentNewDialog)
