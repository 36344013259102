import { fromJS, Range, List, Set } from 'immutable'
import arrayMove from './array_move'

export const listMove = (list, oldIndex, newIndex) => fromJS(arrayMove(list.toJS(), oldIndex, newIndex))
export const listUniqueById = list => {
  const ids = list.map(e => e.get('id'))

  return list.reduce((a, b) => a.set(b.get('id'), b), fromJS({})).toList().sortBy(e => ids.indexOf(e.get('id')))
}

export const listUniqueByKeyName = (list, key) => {
  const keys = list.map(e => e.get(key))

  return list.reduce((a, b) => a.set(b.get(key), b), fromJS({})).toList().sortBy(e => keys.indexOf(e.get(key)))
}

export const listChunk = (list, chunkCount, fill = false) => {
  if (list.isEmpty()) {
    return List()
  }

  const chunkSize = Math.ceil(list.count() / chunkCount)
  const range = Range(0, list.count(), chunkSize)
  let result = range.map(chunkStart => list.slice(chunkStart, chunkStart + chunkSize)).toList()

  if (fill) {
    result = result.concat(Range(0, chunkCount - result.count()).map(_a => List().toList()))
  }

  return result
}

export const mergeDeepKeepLists = (a, b) => {
  if (a && a.mergeWith && !List.isList(a) && !List.isList(b)) {
    return a.mergeWith(mergeDeepKeepLists, b)
  }

  return b
}

export const keyIn = (...keys) => {
  const keySet = Set(keys)

  return (v, k) => keySet.has(k)
}
