import { connect } from 'react-redux'

import Topics from 'components/subscriptions/topics'
import {
  getAllowedStaticMediaReviewCodes,
  getSelectedEditionIds,
  getSelectedTopicIds,
  getSelectedCodeFilters
} from 'selectors'
import { updateTopicSelection, selectAllTopics, deselectAllTopics } from 'actions/subscriptions'

const mapStateToProps = state => ({
  topics: getAllowedStaticMediaReviewCodes(state),
  selectedTopicIds: getSelectedTopicIds(state),
  selectedEditionIds: getSelectedEditionIds(state),
  selectedCodeFilters: getSelectedCodeFilters(state)
})

export default connect(
  mapStateToProps,
  {
    onSelect: updateTopicSelection,
    onSelectAll: selectAllTopics,
    onDeselectAll: deselectAllTopics
  }
)(Topics)
