/* global window */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

export default function LinkedInPopUp({ params }) {
  useEffect(() => {
    if (params.error) {
      const errorMessage = params.error_description || 'Login failed. Please try again.'
      /* eslint-disable no-unused-expressions */
      window.opener && window.opener.postMessage(
        { error: params.error, state: params.state, errorMessage, from: 'Linked In' },
        window.location.origin
      )

      // if (params.error === 'user_cancelled_login') {
      //   window.close()
      // }
    }

    if (params.code) {
      window.opener && window.opener.postMessage({ code: params.code, state: params.state, from: 'Linked In' }, window.location.origin)
    }

    if (params.linkedin_redirect_url) {
      window.location.href = params.linkedin_redirect_url
    }
  })

  return (
    <>
      Code: {params.code}
      <br /><br />
      Error: {params.error && params.error_description}
    </>
  )
}

LinkedInPopUp.propTypes = {
  params: PropTypes.object.isRequired
}
