import { connect } from 'react-redux'

import EditorialPlansItem from 'components/themax/editorial_plans/editorial_plans_item/'
import { getHasThemaxPaidVersion, getThemaxBookmarkRequestRunningFor } from 'selectors'

import {
  openDetailedViewDialog,
  setSelectedEditorialPlan,
  themaxDeleteBookmarkStart,
  themaxSaveBookmarkStart
} from 'actions/themax'

const mapStateToProps = (state, { editorialPlan, isShownInDetailedView, isFirst }) => ({
  isShownInDetailedView,
  isFirst,
  editorialPlan,
  bookmarkRequestRunningFor: getThemaxBookmarkRequestRunningFor(state),
  hasPaidVersion: getHasThemaxPaidVersion(state)
})

export default connect(
  mapStateToProps,
  {
    onRemoveBookmark: themaxDeleteBookmarkStart,
    onSetBookmark: themaxSaveBookmarkStart,
    openDetailedViewDialog,
    setSelectedEditorialPlan
  }
)(EditorialPlansItem)
