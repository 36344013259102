import { connect } from 'react-redux'

import SearchQueryDialog from 'components/darknet/query_manager/search_query_dialog'

import {
  getDarknetSearchQueryFormData,
  getDarknetTestSearchQueryRunning,
  getDarknetTestSearchQueryDisabled,
  getDarknetSearchQueryFormTestResult
} from 'selectors'

import {
  saveSearchQueryRequestStart as onSave,
  updateSearchQueryFormData as onUpdate,
  testSearchQueryStart as onTest
} from 'actions/darknet'

const mapStateToProps = state => ({
  data: getDarknetSearchQueryFormData(state),
  testRunning: getDarknetTestSearchQueryRunning(state),
  testDisabled: getDarknetTestSearchQueryDisabled(state),
  testResult: getDarknetSearchQueryFormTestResult(state)
})

export default connect(
  mapStateToProps,
  {
    onSave,
    onUpdate,
    onTest
  }
)(SearchQueryDialog)
