import { combineReducers } from 'redux-immutable'

import data from './data'
import timelines from './timelines'
import dispatches from './dispatches'
import ui from './ui'
import dialogs from './dialogs'
import feeds from './feeds'

export default combineReducers({
  data,
  dialogs,
  dispatches,
  feeds,
  timelines,
  ui
})
