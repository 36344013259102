import { fromJS, List, Map, Set } from 'immutable'
import decamelize from 'decamelize'
import moment from 'moment-timezone'
import tinycolor from 'tinycolor2'
import { v4 } from 'uuid'

import { NewsPageSize, ChartTypes } from '../static/constants'
import { getTonalityColor } from './tonality'
import { getCustomKpiName, isCustomKpi } from './custom_kpis'

import {
  isPressrelationsNewsChart,
  isGoogleAnalyticsChart,
  isMozChart,
  isYoutubeAnalyticsChart,
  isLinkedInAnalyticsChart,
  isFacebookAnalyticsChart,
  isTwitterAnalyticsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart
} from './data_source'

export {
  isPressrelationsNewsChart,
  isGoogleAnalyticsChart,
  isMozChart,
  isYoutubeAnalyticsChart,
  isLinkedInAnalyticsChart,
  isFacebookAnalyticsChart,
  isTwitterAnalyticsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart
}

const sortByByType = chart => {
  if (chart.get('type').startsWith('news') || chart.get('type').startsWith('image')) {
    return ''
  }

  return 'buzz'
}
const stackableSecondLevels = fromJS({
  tonality: 'tonalities',
  statementTonality: 'statementTonalities',
  sentiment: 'sentiments'
})

const getThirdLevel = chart => chart.get('thirdLevel') || 'buzz'

const levelsWithTonalityColor = ['tonality', 'tonalities', 'statementTonality', 'statementTonalities']
export const useTonalityColor = key => levelsWithTonalityColor.indexOf(key) !== -1

const levelsWithSentimentColor = ['sentiment', 'sentiments']
export const useSentimentColor = key => levelsWithSentimentColor.indexOf(key) !== -1

const chartTypesWithChangingColors = [ChartTypes.BAR, ChartTypes.PIE, ChartTypes.FUNNEL]
const useChangingColors = key => chartTypesWithChangingColors.indexOf(key) !== -1

const chartTypesWithFirstLevelOnly = [
  ChartTypes.RECTANGLE,
  ChartTypes.GAUGE,
  ChartTypes.NEWS_FEED,
  ChartTypes.NEWS_TICKER,
  ChartTypes.INFLUENCER_FEED,
  ChartTypes.PAGE_IDENTITY_FEED,
  ChartTypes.PUBLICATION_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED,
  ChartTypes.IMAGE_FEED
]
const chartTypesWithThirdLevelOnly = [
  'pageIdentitiesTimeline'
]
const hasFirstLevelOnly = key => chartTypesWithFirstLevelOnly.indexOf(key) !== -1
const hasThirdLevelOnly = key => chartTypesWithThirdLevelOnly.indexOf(key) !== -1

const chartTypesWithSorting = [
  ChartTypes.NEWS_FEED,
  ChartTypes.NEWS_TICKER,
  ChartTypes.INFLUENCER_FEED,
  ChartTypes.PUBLICATION_FEED,
  ChartTypes.IMAGE_FEED
]
const hasSorting = key => chartTypesWithSorting.indexOf(key) !== -1

const chartTypesWithoutLimit = [ChartTypes.TAG_CLOUD, ChartTypes.TREEMAP]
const hasLimit = key => chartTypesWithoutLimit.indexOf(key) === -1

const timelineFirstLevels = [
  'timeline',
  'savedSearchesTimeline',
  'pageIdentitiesTimeline'
]
export const isTimeline = chart => timelineFirstLevels.indexOf(chart.get('firstLevel')) !== -1

const socialMediaAnalyticsFeeds = [
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED
]
export const isSocialMediaAnalyticsFeed = chart => socialMediaAnalyticsFeeds.indexOf(chart.get('firstLevel')) !== -1

export const tonalityValues = {
  extremely_positive: 3,
  very_positive: 2,
  positive: 1,
  neutral: 0,
  negative: -1,
  very_negative: -2,
  extremely_negative: -3,
  unknown: null
}

export const sentimentValues = tonalityValues

const feedChartTypes = Set([
  ChartTypes.NEWS_FEED,
  ChartTypes.NEWS_TICKER,
  ChartTypes.INFLUENCER_FEED,
  ChartTypes.PAGE_IDENTITY_FEED,
  ChartTypes.PUBLICATION_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED,
  ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED,
  ChartTypes.RECTANGLE,
  ChartTypes.IMAGE_FEED
])

export const isFeedChart = chart => feedChartTypes.has(chart.get('type'))

export const isRectangleChart = chart => chart.get('type') === ChartTypes.RECTANGLE

const getLabel = (label, i18n) => i18n.get(decamelize((label || '')).toString())

const addAverageTimeSpan = (label, chart, i18n) => {
  const averageByTimespan = chart.getIn(['opts', 'averageByTimespan'])

  if (!averageByTimespan || isTimeline(chart)) {
    return label
  }

  return `${i18n.get(`${decamelize(averageByTimespan || '')}_average`, { measure: label })}`
}

const formatDate = dateString => moment(dateString)
  .format('L')

const getTonalityFromAgg = agg => (
  agg.getIn(['stats', 'tonality']) !== undefined ? agg.getIn(['stats', 'tonality']) : agg.getIn(['stats', 'statementTonality'])
)

const buildThirdLevelLabel = (chart, i18n) => {
  let label = getLabel(chart.get('thirdLevel'), i18n)

  if (isCustomKpi(decamelize(chart.get('thirdLevel')))) {
    label = getCustomKpiName(decamelize(chart.get('thirdLevel')))
  }

  return addAverageTimeSpan(label, chart, i18n)
}

const buildFirstLevelLabel = (chart, i18n) => {
  let label = getLabel(chart.get('firstLevel'), i18n)

  if (isCustomKpi(decamelize(chart.get('firstLevel')))) {
    label = getCustomKpiName(decamelize(chart.get('firstLevel')))
  }

  return label
}

export const addDates = (chart, i18n, labels = []) => {
  if (isLinkedInAnalyticsChart(chart) && chart.get('type') === ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED) {
    labels.push(i18n.get('until_now'))
  } else if (chart.get('type') === ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED) {
    labels.push(i18n.get('last_24_hours'))
  } else if (chart.get('dateRange')) {
    if (chart.get('dateRange').startsWith('relative_range')) {
      const [count, unit] = chart.get('dateRange').replace('relative_range_', '').split('_')
      labels.push(i18n.get('relative_date_range', { count, unit: i18n.get(unit) }))
    } else {
      labels.push(i18n.get(chart.get('dateRange')))
    }
  } else if (chart.get('dateFrom') && chart.get('dateTo')) {
    labels.push(`${formatDate(chart.get('dateFrom'))} - ${formatDate(chart.get('dateTo'))}`)
  }

  return labels
}

export const generateChartLabel = (chart, i18n, autoLabel = true) => {
  if (autoLabel === false) {
    const labels = [chart.get('label')]

    if (chart.getIn(['opts', 'addDateToLabel'])) {
      addDates(chart, i18n, labels)
    }

    return labels.filter(l => l).join(' / ')
  }

  if (isImageChart(chart) || isTextChart(chart) || isExternalWidgetChart(chart)) {
    return ''
  }

  const labels = []

  if (!isPressrelationsNewsChart(chart)) {
    if (!isFacebookAnalyticsChart(chart)) {
      labels.push(i18n.get(chart.get('dataSource')))
    }

    if (isFacebookAnalyticsChart(chart)) {
      let pageName = chart.getIn(['opts', 'facebookAnalytics', 'pageName'])

      if (pageName) {
        const [type, ...rest] = pageName.split(' | ')
        let dataSource = i18n.get(chart.get('dataSource'))

        if (rest.length) {
          dataSource = dataSource.replace('Facebook', type)
          pageName = rest.join(' | ')
        }

        labels.push(dataSource)
        labels.push(pageName)
      }
    }

    if (isGoogleAnalyticsChart(chart)) {
      labels.push(chart.getIn(['opts', 'googleAnalytics', 'viewName']))
    }

    if (isLinkedInAnalyticsChart(chart)) {
      labels.push(chart.getIn(['opts', 'linkedInAnalytics', 'organizationName']))
    }
  }

  if (hasFirstLevelOnly(chart.get('type')) || hasFirstLevelOnly(chart.get('firstLevel'))) {
    labels.push(addAverageTimeSpan(buildFirstLevelLabel(chart, i18n), chart, i18n))

    if (hasSorting(chart.get('type'))) {
      const sortingLabel = i18n.get(`sorting_select_${(chart.get('sortBy', '') || sortByByType(chart))}`)
      labels.push(`${i18n.get('sort_by')}: ${sortingLabel}`)
    }

    return labels.filter(l => l).join(' / ')
  }

  if (hasThirdLevelOnly(chart.get('firstLevel'))) {
    return buildThirdLevelLabel(chart, i18n)
  }

  labels.push(getLabel(chart.get('firstLevel'), i18n))

  if (chart.get('secondLevel')) {
    if (chart.get('secondLevel') === 'analysisCodes') {
      labels.push(i18n.get('code_grouping'))
    } else {
      labels.push(getLabel(chart.get('secondLevel'), i18n))
    }
  }

  if (chart.get('thirdLevel')) {
    labels.push(buildThirdLevelLabel(chart, i18n))
  } else {
    labels.push(buildThirdLevelLabel(chart.set('thirdLevel', 'buzz'), i18n))
  }

  return labels.filter(l => l).join(' / ')
}

export const generateChartTopLabel = (chart, i18n, autoLabel = true) => {
  if (autoLabel === false) {
    const labels = [chart.get('topLabel')]

    if (chart.getIn(['opts', 'addDateToTopLabel'])) {
      addDates(chart, i18n, labels)
    }

    return labels.filter(l => l).join(' / ')
  }

  if (isImageChart(chart) || isTextChart(chart) || isExternalWidgetChart(chart)) {
    return ''
  }

  const labels = []

  if (chart.getIn(['savedSearch', 'moduleName'])) {
    labels.push(i18n.get(`module_${chart.getIn(['savedSearch', 'moduleName'])}`))
  }

  if (chart.getIn(['savedSearch', 'name'])) {
    if (chart.get('additionalSavedSearchIds') && chart.get('additionalSavedSearchIds').size > 0) {
      labels.push(i18n.get('multi_search', { count: chart.get('additionalSavedSearchIds').size + 1 }))
    } else {
      labels.push(i18n.get(chart.getIn(['savedSearch', 'name'])))
    }
  }

  addDates(chart, i18n, labels)

  return labels.join(' / ')
}

export const buildFirstLevelColorKey = (data, chart) => {
  if (isTimeline(chart)) {
    return 'timeline'
  }

  if (chart.get('type') === ChartTypes.LINE) {
    return 'line'
  }

  return `${chart.get('firstLevel')}_${Map.isMap(data) ? data.get('id') : data.id}`
}

export const findConfiguredFirstLevelColor = (data, chart) => {
  const config = chart.getIn(['opts', 'colorConfig'])

  if (config) {
    const found = config.find(item => item.get('key') === buildFirstLevelColorKey(data, chart))

    if (found) {
      return found.get('value')
    }
  }

  return null
}

export const buildSecondLevelColorKey = (data, chart) => `${chart.get('secondLevel')}_${Map.isMap(data) ? data.get('id') : data.id}`

export const findConfiguredSecondLevelColor = (data, chart) => {
  const config = chart.getIn(['opts', 'colorConfig'])

  if (config) {
    const found = config.find(item => item.get('key') === buildSecondLevelColorKey(data, chart))

    if (found) {
      return found.get('value')
    }
  }

  return null
}

const updateFill = (entity, color, forcedColor) => entity.update('fill', fill => forcedColor || fill || entity.get('color') || color)

const buildStack = (chart, agg, colorScheme, domain) => {
  const secondLevel = stackableSecondLevels.get(chart.get('secondLevel'), chart.get('secondLevel'))
  const thirdLevel = getThirdLevel(chart)

  return agg.getIn(['stats', secondLevel], fromJS([])).map(item => {
    let newItem = item

    // Override colors with tonality colors when set in first or second level
    if (useTonalityColor(secondLevel)) {
      newItem = updateFill(item, null, findConfiguredSecondLevelColor(item, chart) || getTonalityColor(tonalityValues[item.get('id')]))

      // Override colors with sentiment colors when set in first or second level
    } else if (useSentimentColor(secondLevel)) {
      newItem = updateFill(item, null, findConfiguredSecondLevelColor(item, chart) || getTonalityColor(sentimentValues[item.get('id')]))

      // Round robin colors
    } else {
      const color = colorScheme[domain.indexOf(item.get('id'))]
      newItem = updateFill(item, color, findConfiguredSecondLevelColor(item, chart))
    }

    if (thirdLevel) {
      newItem = newItem.set('count', newItem.getIn(['stats', thirdLevel]))
    }

    return newItem.delete('stats')
  })
}

export const formatData = (data, chart, colors) => {
  // Special news "aggregation"
  if (chart.get('firstLevel') === 'newsFeed') {
    if (data && data.has('groups') && !data.get('groups').size) {
      return null
    }

    if (data && data.has('news') && !data.get('news').size) {
      return null
    }

    return data
  }

  // Special posts "aggregation"
  if (isSocialMediaAnalyticsFeed(chart)) {
    if (data && data.has('posts') && !data.get('posts').size) {
      return null
    }

    return data
  }

  if (chart.get('firstLevel') === 'topInfluencers') {
    if (data && data.has('influencers') && !data.get('influencers').size) {
      return null
    }
  } else if (chart.get('firstLevel') === 'pageIdentities') {
    if (data && data.hasIn(['pageIdentities', 'pageIdentities']) && !data.getIn(['pageIdentities', 'pageIdentities']).size) {
      return null
    }
  } else if (chart.get('firstLevel') === 'topPublications') {
    if (data && data.has('publications') && !data.get('publications').size) {
      return null
    }
  }

  let aggregation = data

  const colorsArr = colors.get('charts')
    .sortBy((value, key) => key)
    .valueSeq()
    .toJS()

  const primary = colorsArr[0]

  if (aggregation === null || (List.isList(aggregation) && !aggregation.size)) {
    return null
  }

  // Simple aggregations like numbers do not need to mapped
  if (!List.isList(aggregation)) {
    return aggregation
  }

  // We need all countries in world map charts only
  if (
    (!chart.get('type', '').startsWith('worldMap') && chart.get('firstLevel') === 'countries')
    || (isGoogleAnalyticsChart(chart) && !isTimeline(chart) && hasLimit(chart.get('type')))
  ) {
    aggregation = aggregation.slice(0, chart.getIn(['opts', 'size']) || NewsPageSize)
  }

  // Set entire entity as filterData to be used for drilldown
  aggregation = aggregation.map(agg => agg.set('filterData', agg))

  const secondLevel = stackableSecondLevels.get(chart.get('secondLevel'), chart.get('secondLevel'))
  const thirdLevel = getThirdLevel(chart)

  // Switch values based on third level (i.e. buzz, reach, interactions)
  if (thirdLevel) {
    if (secondLevel) {
      if (!aggregation.getIn([0, 'stats', secondLevel])) {
        return null
      }

      aggregation = aggregation.map(agg => (
        agg.set('count', agg.getIn(['stats', secondLevel]).reduce((acc, s) => acc + s.getIn(['stats', thirdLevel]), 0))
      ))
    } else {
      aggregation = aggregation.map(agg => agg.set('count', agg.getIn(['stats', thirdLevel])))

      if (aggregation.every(agg => !agg.get('count'))) {
        return null
      }
    }

    // Count has to be the sum of second level counts
  } else if (secondLevel) {
    if (!aggregation.getIn([0, 'stats', secondLevel])) {
      return null
    }

    aggregation = aggregation.map(agg => (
      agg.set('count', agg.getIn(['stats', secondLevel]).reduce((acc, s) => acc + s.get('count'), 0))
    ))
  }

  // Do not sort values based on count for timeline charts because they have to be sorted by date
  if (!isTimeline(chart)) {
    const bucketSortBy = chart.getIn(['opts', 'bucketSortBy'])
    const bucketSortOrder = chart.getIn(['opts', 'bucketSortOrder'])

    aggregation = aggregation.sortBy(agg => {
      if (bucketSortBy === 'alphabetically') {
        return (agg.get('name') || '').toLowerCase()
      }

      if (bucketSortBy === 'sortcode') {
        return (agg.get('sortcode') || '').toLowerCase()
      }

      return -agg.get('count')
    })

    if (bucketSortBy === 'alphabetically' && bucketSortOrder === 'desc') {
      aggregation = aggregation.reverse()
    }
  }

  // Pie charts with a stack need to use changing colors in inner circle
  if (chart.get('type') === ChartTypes.PIE && chart.get('secondLevel') && chart.getIn(['opts', 'showGrouping'], true)) {
    aggregation = aggregation.map((agg, i) => updateFill(agg, colorsArr[i % 10], findConfiguredFirstLevelColor(agg, chart)))

    // Override colors with tonality colors when set in first or second level
  } else if (useTonalityColor(chart.get('secondLevel')) || useTonalityColor(chart.get('firstLevel'))) {
    aggregation = aggregation.map(agg => (
      updateFill(agg, primary, findConfiguredFirstLevelColor(agg, chart) || getTonalityColor(getTonalityFromAgg(agg)))
    ))

    // Override colors with sentiment colors when set in first or second level
  } else if (useSentimentColor(chart.get('secondLevel')) || useSentimentColor(chart.get('firstLevel'))) {
    aggregation = aggregation.map(
      agg => updateFill(agg, primary, findConfiguredFirstLevelColor(agg, chart) || getTonalityColor(agg.getIn(['stats', 'sentiment'])))
    )

    // Round robin colors
  } else if (useChangingColors(chart.get('type'))) {
    aggregation = aggregation.map((agg, i) => updateFill(agg, colorsArr[i % colorsArr.length], findConfiguredFirstLevelColor(agg, chart)))

    // Mono-color
  } else {
    aggregation = aggregation.map(agg => updateFill(agg, primary, findConfiguredFirstLevelColor(agg, chart)))
  }

  // Stack is used in chart components to group/stack by given second level (i.e. timeline stacked by topic)
  const domain = aggregation.map(agg => agg.getIn(['stats', secondLevel], fromJS([])).map(e => e.get('id')))
    .flatten(true)
    .toSet()
    .toList()
    .sortBy(id => id)
    .toJS()

  const colorScheme = domain.map((_d, i) => {
    let percent = (Math.floor(i / colorsArr.length) / 10) * 100

    if (percent > 20) {
      percent -= 20

      if (percent > 20) {
        return tinycolor.random().toString()
      }

      return tinycolor(colorsArr[i % colorsArr.length]).lighten(percent).toString()
    }

    return tinycolor(colorsArr[i % colorsArr.length]).darken(percent).toString()
  })
  aggregation = aggregation.map(agg => agg.set('stack', buildStack(chart, agg, colorScheme, domain)))

  // Do not filter 0 values for timelines
  if (!isTimeline(chart)) {
    aggregation = aggregation.filter(agg => !agg.has('count') || Boolean(agg.get('count')))
  }

  // Return nothing if data from second level is empty
  if (chart.get('secondLevel') && aggregation.every(agg => agg.get('stack').isEmpty())) {
    aggregation = null
  }

  return aggregation
}

export const aiAnalysisId = `ai-analysis-${v4()}`
