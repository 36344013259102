import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/unsplash'
import * as CDActions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  photos: {
    total: null,
    totalPages: null,
    items: []
  },
  query: ''
})

export default handleActions({
  [Actions.setUnsplashPhotos]: (state, { payload }) => state
    .updateIn(['photos', 'items'], items => items.push(...fromJS(payload.results)))
    .setIn(['photos', 'total'], payload.total)
    .setIn(['photos', 'totalPages'], payload.total_pages),
  [Actions.setUnsplashFormData]: (state, { payload: { name, value } }) => state.set(name, value),
  [Actions.resetUnsplashPhotos]: state => state.set('photos', initialState.get('photos')),
  [Actions.toggleUnsplashComponent]: () => initialState,
  [combineActions(
    CDActions.closeNewContentDialog,
    CDActions.closeEditContentDialog,
    CDActions.closeOldEditContentDialog
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
