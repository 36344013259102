/* global document, window, XMLSerializer, Image, Blob */
import html2canvas from 'utils/html2canvas'
import { ChartBackgroundColors } from 'static/themes'

const viewBoxFactor = 3.3
const viewXYFactor = viewBoxFactor + ((viewBoxFactor / 3) * 2)

const resolveCanvas = (canvas, resolve, callback, opts = {}) => {
  const result = {
    dataUrl: canvas.toDataURL(opts.mimeType || 'image/png'),
    canvas
  }

  if (canvas.msToBlob) {
    resolve({ ...result, blob: canvas.msToBlob() })
  } else {
    canvas.toBlob(blob => resolve({ ...result, blob }))
  }

  if (callback) {
    callback()
  }
}
const removeElements = elms => elms.forEach(el => el.remove())

export default (containerId, legendSelector, darkMode, whitelabelTheme) => {
  const container = document.getElementById(containerId)
  const svgEl = container.querySelector('svg').cloneNode(true)

  const elementsRemove = svgEl.querySelectorAll('.recharts-layer.recharts-line-dots, .recharts-layer.recharts-area-dots')
  removeElements(elementsRemove)

  let legendEl = null

  if (legendSelector) {
    legendEl = container.querySelector(legendSelector)
  }

  const width = svgEl.getAttribute('width') * 5
  const height = svgEl.getAttribute('height') * 5
  const viewBoxWidth = width / viewBoxFactor
  const viewBoxHeight = height / viewBoxFactor
  const viewBoxX = viewBoxWidth / viewXYFactor
  const viewBoxY = viewBoxHeight / viewXYFactor
  const viewBox = `-${viewBoxX} -${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`

  let backgroundColor

  if (whitelabelTheme === 'plotlights') {
    backgroundColor = ChartBackgroundColors.PLOTLIGHTS
  } else {
    backgroundColor = darkMode ? ChartBackgroundColors.DARKMODE : ChartBackgroundColors.DEFAULT
  }

  svgEl.setAttribute('style', `background-color: ${backgroundColor}; font-family: Roboto, sans-serif`)
  svgEl.setAttribute('width', width)
  svgEl.setAttribute('height', height)
  svgEl.setAttribute('viewBox', viewBox)

  const svgString = new XMLSerializer().serializeToString(svgEl)
  const svg = new Blob([svgString], {
    type: 'image/svg+xml;charset=utf-8'
  })
  const DOMURL = window.URL || window.webkitURL || window
  const url = DOMURL.createObjectURL(svg)

  const canvas = document.createElement('canvas')
  canvas.width = width - (viewBoxX * viewBoxFactor)
  canvas.height = height - (viewBoxY * viewBoxFactor)
  const ctx = canvas.getContext('2d')

  return new Promise(resolve => {
    const img = new Image()

    img.onload = () => {
      ctx.drawImage(img, -(viewBoxX * 2), -(viewBoxY * 2))

      if (legendEl) {
        const newLegendEl = legendEl.cloneNode(true)
        newLegendEl.style.position = 'relative'
        newLegendEl.style.top = `${window.innerHeight}px`
        newLegendEl.style.left = 0
        newLegendEl.style.width = `${canvas.width * 0.8}px`
        newLegendEl.style['font-size'] = '40px'
        newLegendEl.style['background-color'] = backgroundColor
        const legendSvgs = newLegendEl.getElementsByTagName('svg')
        for (let i = 0; i < legendSvgs.length; i += 1) {
          if (legendSvgs[i]) {
            legendSvgs[i].setAttribute('width', 56)
            legendSvgs[i].setAttribute('height', 56)
          }
        }
        document.body.appendChild(newLegendEl)
        html2canvas(newLegendEl, { logging: false }, can => {
          ctx.drawImage(can, (canvas.width / 2) - (can.width / 2), canvas.height - (can.height + 5))
          resolveCanvas(canvas, resolve)
          newLegendEl.remove()
        })
      } else {
        resolveCanvas(canvas, resolve)
      }
    }
    img.src = url
  })
}

export const downloadCanvas = (containerId, selector, opts = {}) => {
  const container = document.getElementById(containerId)
  let el = container.firstChild

  if (selector) {
    el = container.querySelector(selector)
    el.setAttribute('style', 'animation: none')
  }

  if (opts.backgroundImageUrl) {
    el.style.background = `url(${opts.backgroundImageUrl}) no-repeat center center fixed`
    el.style.backgroundSize = 'cover'
  }

  const buttons = Array.prototype.slice.call(el.getElementsByTagName('button'))
  const tooltips = Array.prototype.slice.call(el.getElementsByClassName('recharts-tooltip-wrapper'))

  buttons.forEach(button => {
    button.style.visibility = 'hidden' // eslint-disable-line
  })

  tooltips.forEach(tooltip => {
    tooltip.style.visibility = 'hidden' // eslint-disable-line
  })

  const options = { ...({ logging: false, scale: 5, allowTaint: true, useCORS: true }), ...(opts || {}) }

  return new Promise(resolve => {
    html2canvas(el, options, canvas => {
      resolveCanvas(canvas, resolve, null, options)

      if (opts.backgroundImageUrl) {
        el.style.removeProperty('background')
        el.style.removeProperty('background-size')
      }

      buttons.forEach(button => {
        button.style.visibility = 'visible' // eslint-disable-line
      })
    })
  })
}

export const downloadDomToImage = containerId => {
  const container = document.getElementById(containerId)
  const el = container.firstChild
  const buttons = Array.prototype.slice.call(el.getElementsByTagName('button'))

  buttons.forEach(button => {
    button.style.visibility = 'hidden' // eslint-disable-line
  })

  const opts = {
    scale: 3
  }

  return new Promise(resolve => {
    import(/* webpackChunkName: "dom-to-image" */ 'dom-to-image-improved').then(({ default: domtoimage }) => {
      domtoimage.toCanvas(el, opts).then(canvas => {
        resolveCanvas(canvas, resolve)

        buttons.forEach(button => {
          button.style.visibility = 'visible' // eslint-disable-line
        })
      })
    })
  })
}
