import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import camelcaseKeysDeep from 'camelcase-keys-deep'

import * as Actions from 'actions/export'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
})

export default handleActions({
  [Actions.setLastExport]: (state, { payload }) => state.merge(fromJS(camelcaseKeysDeep(payload))),

  [AppActions.resetState]: () => initialState
}, initialState)
