import { connect } from 'react-redux'

import DistributeForm from 'components/content_desk/content_edit_dialog/distribute_form'

import { getCDeskContentFormData } from 'selectors'

const mapStateToProps = state => ({
  data: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(DistributeForm)
