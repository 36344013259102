import { connect } from 'react-redux'

import SelectedDateRange from 'components/news_page/selected_date_range'
import {
  getSelectedDateFrom,
  getSelectedDateTo,
  getSelectedDateRange
} from 'selectors'

const mapStateToProps = state => ({
  dateFrom: getSelectedDateFrom(state),
  dateTo: getSelectedDateTo(state),
  dateRange: getSelectedDateRange(state)
})

export default connect(mapStateToProps)(SelectedDateRange)
