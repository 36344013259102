import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Box, Select, MenuItem, InputAdornment } from '@mui/material'
import { grey } from '@mui/material/colors'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles()(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  select: {
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '4dvw'
    }
  },
  icon: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '6dvw'
    }
  },
  colors: {
    fontWeight: '500',
    color: grey[900]
  }
}))

export default function KpiDateFilter({
  requestRunning,
  dateRange,
  fetchKpiStatistics,
  search,
  setKpiFilters
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleChange = event => {
    setKpiFilters({ key: 'dateRange', value: event.target.value })
    fetchKpiStatistics()
    search()
  }

  return (
    <Box className={classes.container}>
      <Select
        size="small"
        disabled={requestRunning}
        className={`${classes.select} ${classes.colors}`}
        IconComponent={ExpandMoreIcon}
        value={dateRange}
        onChange={handleChange}
        startAdornment={(
          <InputAdornment position="start">
            <AccessTimeIcon
              className={`${classes.icon} ${classes.colors}`}
              sx={{ opacity: requestRunning ? 0.5 : 1 }}
            />
          </InputAdornment>
        )}
      >
        <MenuItem value="last_7_days">{i18n.get('last_7_days')}</MenuItem>
        <MenuItem value="last_30_days">{i18n.get('last_30_days')}</MenuItem>
        <MenuItem value="last_3_months">{i18n.get('last_3_months')}</MenuItem>
      </Select>
    </Box>
  )
}

KpiDateFilter.propTypes = {
  requestRunning: PropTypes.bool.isRequired,
  dateRange: PropTypes.string.isRequired,

  fetchKpiStatistics: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  setKpiFilters: PropTypes.func.isRequired
}
