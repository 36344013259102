import { connect } from 'react-redux'

import SavedSearchesActions from 'components/themax/saved_searches/SavedSearchesActions'

import {
  getDevice
} from 'selectors'

const mapStateToProps = state => ({
  device: getDevice(state)
})

export default connect(
  mapStateToProps,
  {}
)(SavedSearchesActions)
