import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import NewsListItem from 'containers/media_reviews/media_review_detail/NewsListItem'

import MultisortNewsList from 'components/sortable/multisort_news_list'
import { List } from '@mui/material'

const useStyles = makeStyles()({
  list: {
    padding: 0
  }
})

export default function GroupedNewsSublist({
  codeId,
  news,
  onSortEnd,
  onReset,
  selectedNews,
  sortingDisabled
}) {
  const { classes } = useStyles()

  return (
    <List classes={{ root: classes.list }}>
      <MultisortNewsList
        news={news}
        onSortEnd={onSortEnd}
        onReset={onReset}
        selectedNews={selectedNews}
        codeId={codeId}
        sortingDisabled={sortingDisabled}
        NewsComponent={NewsListItem}
        NewsComponentProps={{
          codeId,
          showCodes: false
        }}
      />
    </List>
  )
}

GroupedNewsSublist.propTypes = {
  news: PropTypes.object.isRequired,
  codeId: PropTypes.number,
  selectedNews: PropTypes.object.isRequired,
  sortingDisabled: PropTypes.bool.isRequired,

  onSortEnd: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}
