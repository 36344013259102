import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  selectedNews: {
    articleDate: new Date(),
    codes: [],
    images: []
  }
})

export default handleActions({
  [NewsActions.showShoppingNewsDialog]: (state, { payload: news }) => state.set('selectedNews', news),

  [NewsActions.resetNews]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
