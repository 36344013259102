import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/administration'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'

import { getDefaultDomains } from 'utils/mailgun'

export const initialState = fromJS({
  ccdAllowedDomains: {
    quickSearchQuery: '',
    newAllowedDomain: '',
    newAllowedDomainRegion: '',
    newAllowedSenderDomain: '',
    requestRunning: false,
    deleteMode: false,
    infoDialogOpen: false,
    statusDialogOpen: false,
    defaultAllowedDomainIds: []
  }
})

const defaultDomainIds = (state, config) => {
  const { ccdAllowedDomains } = config

  if (!ccdAllowedDomains) {
    return state
  }

  const defaultDomains = getDefaultDomains()
  const ids = []

  defaultDomains.forEach(defaultDomain => {
    const found = ccdAllowedDomains.find(d => d.name === defaultDomain.get('domain'))

    if (found) {
      ids.push(found.id)
    }
  })

  return state.setIn(['ccdAllowedDomains', 'defaultAllowedDomainIds'], fromJS(ids))
}

export default handleActions({
  [Actions.setAllowedDomainsQuickSearchQuery]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'quickSearchQuery'], payload),
  [Actions.setNewAllowedDomain]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'newAllowedDomain'], payload),
  [Actions.setNewAllowedDomainRegion]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'newAllowedDomainRegion'], payload),
  [Actions.setNewAllowedDomainSenderDomain]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'newAllowedSenderDomain'], payload),
  [Actions.toggleAllowedDomainsDeleteMode]: state => state
    .setIn(['ccdAllowedDomains', 'deleteMode'], !state.getIn(['ccdAllowedDomains', 'deleteMode'])),
  [combineActions(
    Actions.searchAllowedDomainsStart,
    Actions.createAllowedDomainStart,
    Actions.updateAllowedDomainStart,
    Actions.deleteAllowedDomainStart,
    Actions.refreshStatusAllowedDomainStart,
    Actions.fetchAllowedDomainStatusStart
  )]: state => state.setIn(['ccdAllowedDomains', 'requestRunning'], true),
  [Actions.createAllowedDomainSuccess]: state => state.setIn(['ccdAllowedDomains', 'newAllowedDomain'], ''),
  [combineActions(
    Actions.searchAllowedDomainsSuccess,
    Actions.searchAllowedDomainsError,
    Actions.createAllowedDomainSuccess,
    Actions.createAllowedDomainError,
    Actions.updateAllowedDomainSuccess,
    Actions.updateAllowedDomainError,
    Actions.deleteAllowedDomainSuccess,
    Actions.deleteAllowedDomainError,
    Actions.refreshStatusAllowedDomainSuccess,
    Actions.refreshStatusAllowedDomainError,
    Actions.fetchAllowedDomainStatusSuccess,
    Actions.fetchAllowedDomainStatusError
  )]: state => state
    .setIn(['ccdAllowedDomains', 'requestRunning'], false)
    .setIn(['ccdAllowedDomains', 'deleteMode'], false)
    .setIn(['ccdAllowedDomains', 'newAllowedDomain'], '')
    .setIn(['ccdAllowedDomains', 'newAllowedSenderDomain'], ''),
  [Actions.toggleAllowedDomainsInfoDialog]: state => state
    .setIn(['ccdAllowedDomains', 'infoDialogOpen'], !state.getIn(['ccdAllowedDomains', 'infoDialogOpen'])),
  [Actions.openAllowedDomainsStatusDialog]: state => state
    .setIn(['ccdAllowedDomains', 'statusDialogOpen'], true),
  [Actions.closeAllowedDomainsStatusDialog]: state => state
    .setIn(['ccdAllowedDomains', 'statusDialogOpen'], false),
  [combineActions(
    Actions.fetchAllowedDomainStatusSuccess,
    Actions.fetchAllowedDomainStatusError
  )]: state => state
    .setIn(['ccdAllowedDomains', 'requestRunning'], false),
  [ConfigActions.setCDeskConfig]: (state, { payload }) => defaultDomainIds(state, payload),
  [AppActions.resetState]: () => initialState
}, initialState)
