import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Box,
  CircularProgress
} from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import MobileCampaign from 'containers/content_desk_new/campaigns/mobile_campaigns/MobileCampaign'
import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    position: 'relative',
    width: '100%',
    padding: 10
  },
  loadingContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 30
  },
  sortIconActive: {
    fontSize: '0.8rem !important'
  },
  sortIconInActive: {
    fontSize: '0.8rem',
    opacity: 0.5
  }
})

const MobileCampaigns = ({
  campaigns,
  loading,
  sortBy,
  sortOrder,

  onSortBy,
  setPage
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleSortingClick = field => {
    let newSortOrder = ''
    let newSortField = field

    if (field === sortBy) {
      if (sortOrder === '') {
        newSortOrder = 'asc'
      }

      if (sortOrder === 'asc') {
        newSortOrder = 'desc'
      }

      if (sortOrder === 'desc') {
        newSortOrder = ''
        newSortField = ''
      }
    } else {
      newSortOrder = 'asc'
    }

    setPage({ key: 'campaignPage', value: 1 })
    onSortBy({ type: 'campaigns', sortBy: newSortField, sortOrder: newSortOrder })
  }

  const renderSortIcon = field => {
    let title = i18n.get('sort')
    let icon = <SwapVertIcon />
    let iconClass = classes.sortIconInActive

    if (field === sortBy) {
      if (sortOrder === 'asc') {
        iconClass = classes.sortIconActive
        icon = <ArrowUpwardIcon />
        title = i18n.get('ascending')
      }

      if (sortOrder === 'desc') {
        iconClass = classes.sortIconActive
        icon = <ArrowDownwardIcon />
        title = i18n.get('descending')
      }
    }

    return (
      <IconButton
        title={title}
        icon={icon}
        size="small"
        className={iconClass}
        onClick={() => handleSortingClick(field)}
      />
    )
  }

  const renderCampaigns = () => campaigns.get('items').map((el, index) => (
    <MobileCampaign
      key={index}
      campaign={el}
    />
  ))

  return (
    <Box className={classes.container}>
      {loading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress
            color="primary"
            size={26}
          />
        </Box>
      )}
      <Box className={classes.header}>
        <Box>
          {i18n.get('name')} {renderSortIcon('name')}
        </Box>
      </Box>
      {renderCampaigns()}
    </Box>
  )
}

MobileCampaigns.propTypes = {
  campaigns: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,

  onSortBy: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

export default MobileCampaigns
