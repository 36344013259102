import { connect } from 'react-redux'

import ContentTitle from 'components/content_desk_new/contents/content/content_title'

const mapStateToProps = (_state, ownProps) => ({
  content: ownProps.content,
  maxLength: ownProps.maxLength
})

export default connect(
  mapStateToProps,
  {}
)(ContentTitle)
