import { connect } from 'react-redux'

import SearchBar from 'components/search_bar'
import { uiEnteredQuery } from 'actions/themax'
import { showAppMessage } from 'actions/app'

import {
  getI18n,
  getColors,
  getThemaxSearchBarLabel,
  getWhitelabelTheme,
  getThemaxSearchRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  colors: getColors(state),
  whitelabelTheme: getWhitelabelTheme(state),
  label: getThemaxSearchBarLabel(state),
  loading: getThemaxSearchRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onSubmit: uiEnteredQuery,
    onError: showAppMessage
  }
)(SearchBar)
