import { connect } from 'react-redux'

import UploadMedia from 'components/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/upload_media'

import {
  uploadFacebookMediaFileStart as onUpload
} from 'actions/content_desk'

import {
  getCDeskMediaFileUploading,
  getCDeskUploadedMediaFilesTotalSize
} from 'selectors'

const mapStateToProps = state => ({
  mediaFileUploading: getCDeskMediaFileUploading(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state)
})

export default connect(
  mapStateToProps,
  {
    onUpload
  }
)(UploadMedia)
