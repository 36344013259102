import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { useReactToPrint } from 'react-to-print'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import { green, orange } from '@mui/material/colors'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(8),
    color: theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  cell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  verifiedIcon: {
    color: green[500],
    fontSize: 28
  },
  unverifiedIcon: {
    color: orange[500],
    fontSize: 28
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '50px',
    marginBottom: '20px'
  },
  domainName: {
    fontSize: '18px',
    marginBottom: '10px'
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '500',
    marginBottom: '10px'
  },
  table: {
    tableLayout: 'fixed',
    width: '100%'
  }
}))

export default function StatusDialog({
  open,
  loading,
  formData,
  status,
  onClose
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const contentRef = useRef(null)

  const domainName = formData.get('name')
  const sendingRecords = status.get('sendingRecords') || List()
  const receivingRecords = status.get('receivingRecords') || List()
  const trackingRecords = status.get('trackingRecords') || List()

  const reactToPrintFn = useReactToPrint({
    documentTitle: domainName,
    pageStyle: `
    @page {
      size: landscape A4;
      margin: 0;
    }
    @media print {
      body {
        min-width: 100%;
        zoom: 90%;
      }
      header, footer {
        display: none;
      }
    }
  `,
    contentRef
  })

  const renderStatus = s => {
    if (s === 'valid') {
      return (
        <Box className={classes.status}>
          <CheckCircleOutlineIcon
            className={classes.verifiedIcon}
            fontSize="medium"
          />
          {i18n.get('verified')}
        </Box>
      )
    }

    return (
      <Box className={classes.status}>
        <WarningAmberOutlinedIcon
          className={classes.unverifiedIcon}
          fontSize="medium"
        />
        {i18n.get('unverified')}
      </Box>
    )
  }

  const getHost = (type, record) => {
    if (type === 'sending' || type === 'tracking') {
      return record.get('name')
    }

    return domainName
  }

  const getCurrentValue = value => {
    if (value.size === 0) {
      return '-'
    }

    return value.map(v => (
      <>
        {v}
        <br />
      </>
    ))
  }

  const renderTable = (type, records, title) => (
    <Box>
      <Typography className={classes.tableTitle}>{title}</Typography>
      <TableContainer
        sx={{ marginBottom: '10px' }}
        component={Paper}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10%' }}>{i18n.get('status')}</TableCell>
              <TableCell sx={{ width: '10%' }}>{i18n.get('type')}</TableCell>
              <TableCell sx={{ width: '20%' }}>{i18n.get('host')}</TableCell>
              <TableCell sx={{ width: '30%' }}>{i18n.get('value')}</TableCell>
              <TableCell sx={{ width: '30%' }}>{i18n.get('current_value')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ width: '10%' }}
                >
                  {renderStatus(record.get('valid'))}
                </TableCell>
                <TableCell
                  sx={{ width: '10%' }}
                >
                  {record.get('recordType')}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  sx={{ width: '20%' }}
                >
                  {getHost(type, record)}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  sx={{ width: '30%' }}
                >
                  {record.get('value')}
                </TableCell>
                <TableCell
                  className={classes.cell}
                  sx={{ width: '30%' }}
                >
                  {getCurrentValue(record.get('cached'))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('ccd_allowed_domain_status_title')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={contentRef}>
        {loading ? (
          <Box className={classes.loaderContainer}>
            <CircularProgress
              size={35}
              variant="indeterminate"
            />
          </Box>
        ) : (
          <Box>
            <Typography className={classes.domainName}>{domainName}</Typography>
            {renderTable('sending', sendingRecords, i18n.get('sending_records'))}
            {renderTable('receiving', receivingRecords, i18n.get('receiving_records'))}
            {renderTable('tracking', trackingRecords, i18n.get('tracking_records'))}
          </Box>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => reactToPrintFn()}
        >
          {i18n.get('export')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  formData: PropTypes.instanceOf(Map).isRequired,
  status: PropTypes.instanceOf(Map).isRequired,
  onClose: PropTypes.func.isRequired
}
