import { connect } from 'react-redux'

import DeleteDialog from 'components/content_desk/delete_dialog'

import { getCDeskRequestRunning, getCDeskShowDeleteDialog, getCDeskDeleteData } from 'selectors/content_desk'

import { deleteContentStart, deleteCampaignStart, closeDeleteDialog } from 'actions/content_desk'

const mapStateToProps = (state, { entity }) => ({
  entity,
  requestRunning: getCDeskRequestRunning(state),
  open: getCDeskShowDeleteDialog(state),
  deleteData: getCDeskDeleteData(state)
})

export default connect(
  mapStateToProps,
  {
    deleteContent: deleteContentStart,
    deleteCampaign: deleteCampaignStart,
    onClose: closeDeleteDialog
  }
)(DeleteDialog)
