import React from 'react'
import PropTypes from 'prop-types'
import SaveAltIcion from '@mui/icons-material/SaveAlt'
import { makeStyles } from 'tss-react/mui'
import { Button, IconButton } from 'containers/themed'

import { MenuItem } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  disabled: {
    color: `${theme.palette.grey[300]} !important`,
    cursor: 'default !important'
  }
}))

export default function ExportMenu(props) {
  const { classes } = useStyles()
  const {
    color,
    disabled,
    i18n,
    icon,
    iconButton,
    label,
    menu,
    onBeforeClick,
    onClick,
    variant,
    payload
  } = props

  let className = ''

  if (disabled) {
    className = classes.disabled
  }

  if (menu) {
    return (
      <MenuItem
        disabled={disabled}
        onClick={() => {
          if (onBeforeClick) {
            onBeforeClick()
          }

          if (!disabled) {
            onClick(payload)
          }
        }}
      >
        {label || i18n.get('export')}
      </MenuItem>
    )
  }

  let Comp = Button

  if (iconButton) {
    Comp = IconButton
  }

  return (
    <Comp
      disabled={disabled}
      className={className}
      color={color}
      variant={variant}
      startIcon={!iconButton ? icon : undefined}
      onClick={() => {
        if (onBeforeClick) {
          onBeforeClick()
        }

        if (!disabled) {
          onClick(payload)
        }
      }}
    >
      {iconButton && icon}
      {iconButton ? null : label || i18n.get('export')}
    </Comp>
  )
}

ExportMenu.defaultProps = {
  menu: false,
  color: 'secondary',
  variant: 'contained',
  icon: <SaveAltIcion />,
  iconButton: false
}

ExportMenu.propTypes = {
  i18n: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  menu: PropTypes.bool,
  label: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.any,
  iconButton: PropTypes.bool,
  payload: PropTypes.string,

  onClick: PropTypes.func.isRequired,
  onBeforeClick: PropTypes.func
}
