import { createAction } from 'redux-actions'

let prefix = 'PAGE_IDENTITIES/'
const defaultPrefix = prefix

export const pageIdentitiesRequestError = createAction(`${prefix}PAGE_IDENTITIES_REQUEST_ERROR`)
export const pageIdentitiesRequestStart = createAction(`${prefix}PAGE_IDENTITIES_REQUEST_START`)
export const pageIdentitiesRequestSuccess = createAction(`${prefix}PAGE_IDENTITIES_REQUEST_SUCCESS`)

export const pageIdentitiesStatisticsRequestError = createAction(`${prefix}PAGE_IDENTITIES_STATISTICS_REQUEST_ERROR`)
export const pageIdentitiesStatisticsRequestStart = createAction(`${prefix}PAGE_IDENTITIES_STATISTICS_REQUEST_START`)
export const pageIdentitiesStatisticsRequestSuccess = createAction(`${prefix}PAGE_IDENTITIES_STATISTICS_REQUEST_SUCCESS`)

export const pageIdentityPostsRequestError = createAction(`${prefix}PAGE_IDENTITY_POSTS_REQUEST_ERROR`)
export const pageIdentityPostsRequestStart = createAction(`${prefix}PAGE_IDENTITY_POSTS_REQUEST_START`)
export const pageIdentityPostsRequestSuccess = createAction(`${prefix}PAGE_IDENTITY_POSTS_REQUEST_SUCCESS`)

export const selectPageIdentity = createAction(`${prefix}SELECT_PAGE_IDENTITY`)
export const resetPageIdentitySelection = createAction(`${prefix}RESET_PAGE_IDENTITY_SELECTION`)

export const exportPageIdentitiesAsExcelStart = createAction(`${prefix}EXPORT_PAGE_IDENTITIES_AS_EXCEL_START`)
export const exportPageIdentitiesAsExcelSuccess = createAction(`${prefix}EXPORT_PAGE_IDENTITIES_AS_EXCEL_SUCCESS`)
export const exportPageIdentitiesAsExcelError = createAction(`${prefix}EXPORT_PAGE_IDENTITIES_AS_EXCEL_ERROR`)

export const toggleSelectedInteractionsChart = createAction(`${prefix}TOGGLE_SELECTED_INTERACTIONS_CHART`)

prefix = `${defaultPrefix}/UI/`
export const setSortBy = createAction(`${prefix}SET_SORT_BY`)
export const toggleShowInactive = createAction(`${prefix}TOGGLE_SHOW_INACTIVE`)
export const toggleGrouped = createAction(`${prefix}TOGGLE_GROUPED`)

prefix = `${defaultPrefix}/DATA/`
export const setChartData = createAction(`${prefix}SET_CHART_DATA`)
export const setChartLoading = createAction(`${prefix}SET_CHART_LOADING`)
