import { createAction } from 'redux-actions'

const prefix = 'API_KEYS/'

export const submitApiKeyStart = createAction(`${prefix}SUBMIT_API_KEY_START`)
export const submitApiKeySuccess = createAction(`${prefix}SUBMIT_API_KEY_SUCCESS`)
export const submitApiKeyError = createAction(`${prefix}SUBMIT_API_KEY_ERROR`)

export const deleteApiKeyStart = createAction(`${prefix}DELETE_API_KEY_START`)
export const deleteApiKeySuccess = createAction(`${prefix}DELETE_API_KEY_SUCCESS`)
export const deleteApiKeyError = createAction(`${prefix}DELETE_API_KEY_ERROR`)
