import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map, List, fromJS } from 'immutable'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'
import moment from 'moment'
import { grey } from '@mui/material/colors'

import { makeStyles } from 'tss-react/mui'

import { Box, Autocomplete, TextField } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px'
  },
  autocompleteContainer: {
    width: '100%'
  },
  newCampaignButton: {
    gap: '5px',
    textWrap: 'nowrap'
  },
  listItem: {
    paddingLeft: 10
  },
  groupHeader: {
    color: grey[500],
    fontWeight: 700
  }
})

const CampaignSelection = ({
  campaigns,
  campaignOptionsLoading,
  content,
  updateContent,
  openCampaignForm,
  updateCampaignFormData,
  openClosedCampaignDialog
}) => {
  if (!content.get('ccdCampaign')) {
    return null
  }

  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const translateCampaignName = campaignName => (campaignName.toLowerCase() === 'no campaign' ? i18n.get('no_campaign') : campaignName)

  const initialCampaign = content.get('ccdCampaign').toJS()
  initialCampaign.label = translateCampaignName(initialCampaign.label)

  const [selectedCampaign, setSelectedCampaign] = useState(initialCampaign)
  const [sortedOptions, setSortedOptions] = useState(List())
  const [noCampaingOption, setNoCampaignOption] = useState(null)
  const [selectedNoCampaign, setSelectedNoCampaign] = useState(null)

  const mapToOptions = options => options.map(campaign => {
    const campaignName = translateCampaignName(campaign.get('name'))

    return (
      {
        label: campaignName,
        value: campaign.get('id'),
        category: (campaign.get('active') ? i18n.get('active') : i18n.get('closed')).toUpperCase()
      }
    )
  })

  useEffect(() => {
    if (!campaignOptionsLoading && campaigns) {
      const sorted = campaigns.filter(c => c.get('name').toLowerCase() !== 'no campaign')
        .unshift(campaigns.find(c => c.get('name').toLowerCase() === 'no campaign'))
      const noCampaign = campaigns.find(c => c.get('name').toLowerCase() === 'no campaign')

      const selectedOption = {
        label: i18n.get('no_campaign'),
        value: noCampaign.get('id'),
        category: (noCampaign.get('active') ? i18n.get('active') : i18n.get('closed')).toUpperCase()
      }

      setSortedOptions(mapToOptions(sorted))
      setSelectedNoCampaign(selectedOption)
      setNoCampaignOption(noCampaign)
    }
  }, [campaignOptionsLoading, campaigns])

  const handleCampaignChange = (_event, option) => {
    let campaign = noCampaingOption
    let selectedOption = selectedNoCampaign

    if (option) {
      campaign = campaigns.find(c => c.get('id') === option.value)

      selectedOption = option
    }

    const endDate = campaign.get('endDate')

    if (endDate && !campaign.get('active') && moment(endDate).isBefore(moment())) {
      updateCampaignFormData(campaign)
      openClosedCampaignDialog()
    }

    setSelectedCampaign(selectedOption)
    updateContent({ ccdCampaign: fromJS(selectedOption) })
  }

  const renderNewCampaignButton = () => {
    if (device.get('lt-sm')) {
      return (
        <IconButton
          color="primary"
          onClick={() => openCampaignForm()}
          title={i18n.get('create_campaign')}
        >
          <AddIcon />
        </IconButton>
      )
    }

    return (
      <Button
        className={classes.newCampaignButton}
        color="primary"
        onClick={() => openCampaignForm()}
      >
        <AddIcon color="primary" />
        {i18n.get('create_campaign')}
      </Button>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.autocompleteContainer}>
        <Autocomplete
          options={sortedOptions.sort((a, b) => -b.category.localeCompare(a.category)).toJS()}
          groupBy={option => option.category}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          loading={campaignOptionsLoading}
          getOptionLabel={option => option.label}
          value={selectedCampaign}
          onChange={handleCampaignChange}
          renderGroup={params => (
            <div
              key={params.key}
              className={classes.listItem}
            >
              <div className={classes.groupHeader}>{params.group}</div>
              <div>{params.children}</div>
            </div>
          )}
          renderOption={(props, option) => {
            if (option.label === i18n.get('no_campaign')) {
              return (
                <li {...props}>
                  <i>{option.label}</i>
                </li>
              )
            }

            return (
              <li {...props}>
                {option.label}
              </li>
            )
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('campaign')}
              placeholder={i18n.get('select_ccd_campaign')}
              fullWidth
            />
          )}
        />
      </Box>
      <Box>
        {renderNewCampaignButton()}
      </Box>
    </Box>
  )
}

CampaignSelection.propTypes = {
  campaigns: PropTypes.instanceOf(List).isRequired,
  campaignOptionsLoading: PropTypes.bool.isRequired,
  content: PropTypes.instanceOf(Map).isRequired,

  updateContent: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired,
  updateCampaignFormData: PropTypes.func.isRequired,
  openClosedCampaignDialog: PropTypes.func.isRequired
}

export default CampaignSelection
