import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { $generateHtmlFromNodes } from '@lexical/html'

import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import {
  replaceHTMLEntities,
  importNodesAsPlainText,
  importNodesAsHTML
} from 'utils/lexicalEditorHelper'

const StatePlugin = ({ usePlainText, editorState, setEditorState }) => {
  const [editor] = useLexicalComposerContext()
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [isFirstRenderEditor, setIsFirstRenderEditor] = useState(true)
  editor.isFirstRender = isFirstRenderEditor

  useEffect(() => {
    // Using `isUserInput` in case we want to reinitialize the editor when the state is updated not from user input.
    let value
    let isUserInput
    let isHTMLMode

    if (typeof editorState === 'object') {
      value = editorState.value
      isUserInput = editorState.isUserInput
      isHTMLMode = editorState.isHTML
    } else {
      value = editorState
      isUserInput = true
      isHTMLMode = !usePlainText
    }

    if (isFirstRender || !isUserInput) {
      setIsFirstRender(false)

      editor.update(() => {
        // Set the content of the editor as plain text or parse it as html.
        if (isHTMLMode) {
          importNodesAsHTML(editor, value, isUserInput)
        } else {
          importNodesAsPlainText(editor, value, isUserInput)
        }

        setIsFirstRenderEditor(false)
      }, { tag: 'skip-scroll-into-view' })
    }
  }, [isFirstRender, editorState, editor])

  const onChange = useCallback(
    (_editorState, _editor, tags) => {
      // This callback is invoked always when the editor's state is changed so it can update the external state provided.
      editor.update(() => {
        editor.isExport = true
        let html = $generateHtmlFromNodes(editor, null)
        html = replaceHTMLEntities(html)
        setEditorState(html, editor, tags)
        editor.isExport = false
      })
    }, [setEditorState]
  )

  return (
    <OnChangePlugin
      onChange={onChange}
      ignoreSelectionChange
    />
  )
}

StatePlugin.defaultProps = {
  usePlainText: false
}

StatePlugin.propTypes = {
  usePlainText: PropTypes.bool,
  editorState: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),

  setEditorState: PropTypes.func.isRequired
}

export default StatePlugin
