import { connect } from 'react-redux'
import InfoSummary from 'components/darknet/dashboard/entry/info_summary'

import {
  searchResultDrilldown
} from 'actions/darknet'

export default connect(
  null,
  {
    searchResultDrilldown
  }
)(InfoSummary)
