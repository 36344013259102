import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { red, green } from '@mui/material/colors'

const useStyles = makeStyles()({
  recipients: {
    maxHeight: 200,
    overflowY: 'auto'
  },
  green: {
    color: green[500]
  },
  red: {
    color: red[500]
  }
})

export default function Recipients({ data }) {
  const { classes } = useStyles()

  return data.get('recipients').map(recipient => (
    <div
      className={`${classes.recipients} ${recipient.get('succeeded') ? classes.green : classes.red}`}
      key={recipient.get('id')}
    >
      {recipient.get('emailAddress')}
    </div>
  ))
}

Recipients.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired
}
