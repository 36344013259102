/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isElementNode
} from 'lexical'

import {
  $isLinkNode
} from '@lexical/link'

import {
  $isListNode,
  ListNode
} from '@lexical/list'

import {
  $isHeadingNode
} from '@lexical/rich-text'

import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister
} from '@lexical/utils'

import ToolbarOptions from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/toolbar_plugin/toolbar_options'

import { getSelectedNode } from 'utils/lexicalEditorHelper'

import './ToolbarPlugin.css'

const ToolbarPlugin = (props) => {
  const toolbarRef = useRef(null)

  const [editor] = useLexicalComposerContext()

  const [activeEditor, setActiveEditor] = useState(editor)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [blockType, setBlockType] = useState('extended-paragraph')
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()

      let element = anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, e => {
          const parent = e.getParent()

          return parent !== null && $isRootOrShadowRoot(parent)
        })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))

      const node = getSelectedNode(selection)
      const parent = node.getParent()

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getTag() : element.getTag()
          setBlockType(type)
        } else if ($isHeadingNode(parent)) {
          setBlockType(parent.getTag())
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          setBlockType(type)
        }
      }
    }
  }, [activeEditor])

  useEffect(() => editor.registerCommand(
    SELECTION_CHANGE_COMMAND,
    (_payload, newEditor) => {
      updateToolbar()
      setActiveEditor(newEditor)

      return false
    },
    COMMAND_PRIORITY_CRITICAL
  ), [editor, updateToolbar])

  useEffect(() => mergeRegister(
    editor.registerEditableListener(editable => {
      setIsEditable(editable)
    }),
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar()
      })
    }),
    editor.registerCommand(
      CAN_UNDO_COMMAND,
      payload => {
        setCanUndo(payload)

        return false
      },
      COMMAND_PRIORITY_CRITICAL
    ),
    editor.registerCommand(
      CAN_REDO_COMMAND,
      payload => {
        setCanRedo(payload)

        return false
      },
      COMMAND_PRIORITY_CRITICAL
    )
  ), [updateToolbar, activeEditor, editor])

  return (
    <div
      className="toolbar"
      ref={toolbarRef}
    >
      <ToolbarOptions
        editor={activeEditor}
        canUndo={canUndo}
        canRedo={canRedo}
        blockType={blockType}
        isLink={isLink}
        isBold={isBold}
        isItalic={isItalic}
        isUnderline={isUnderline}
        isEditable={isEditable}
        {...props}
      />
    </div>
  )
}

export default ToolbarPlugin
