import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Box } from '@mui/material'
import { Button, RaisedPrimaryButton } from 'containers/themed'

export default function EditDialogActions({ isEditMode, loading, onClose, onSave, onDelete }) {
  const i18n = useI18n()

  return (
    <Box
      m={2}
      sx={{
        '& .MuiButtonBase-root': { marginLeft: 2 }
      }}
    >
      {isEditMode && (
        <Button
          onClick={onDelete}
          disabled={loading}
        >
          {i18n.get('delete')}
        </Button>
      )}
      <Button
        onClick={onClose}
        disabled={loading}
      >
        {i18n.get('cancel')}
      </Button>
      <RaisedPrimaryButton
        onClick={onSave}
        disabled={loading}
        loading={loading}
      >
        {i18n.get('save')}
      </RaisedPrimaryButton>
    </Box>
  )
}

EditDialogActions.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
