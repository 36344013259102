import { put, takeEvery, all, call } from 'redux-saga/effects'
import * as Actions from 'actions/unsplash'
import * as CDActions from 'actions/content_desk'
import * as AppActions from 'actions/app'
import * as Api from 'api/bff'

export function* addImageToLibraryStart({ payload: { url, filename } }) {
  const body = {
    url,
    filename
  }

  try {
    yield call(Api.downloadUnsplashImage, body)
    yield put(CDActions.fetchMediaFilesStart())
    yield put(Actions.addImageToLibrarySuccess())
  } catch (error) {
    yield put(Actions.addImageToLibraryError(error))
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* watchAddImageToLibrary() {
  yield takeEvery(Actions.addImageToLibraryStart, addImageToLibraryStart)
}

export default function* mainSaga() {
  yield all([
    watchAddImageToLibrary()
  ])
}
