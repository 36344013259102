/* global navigator */
import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import { useSnackbar } from 'notistack'

import { Paper, Typography, CircularProgress } from '@mui/material'

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

import { IconButton, Popover } from 'containers/themed'

import { renderMood } from 'utils/ai_editor'

const useStyles = makeStyles()({
  heading: {
    display: 'flex',
    marginBottom: '0.5em'
  },
  resultHeading: {
    display: 'flex',
    alignItems: 'center'

  },
  resultHeadingText: {
    fontStyle: 'italic'
  },
  circularProgress: {
    marginLeft: '1em'
  },
  noResults: {
    marginBottom: '0.5em'
  },
  resultEntry: {
    padding: 10,
    marginBottom: '0.5em'
  },
  source: {
    padding: 10
  }
})

const PromptHistory = ({ entries, loading }) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyClick = entry => {
    navigator.clipboard.writeText(entry.get('text'))

    return enqueueSnackbar(`${i18n.get('result_copied')}`, {
      variant: 'success',
      preventDuplicate: true,
      autoHideDuration: 2000
    })
  }

  const renderResult = entry => (
    <Paper
      className={classes.resultEntry}
      key={entry.get('resultId')}
    >
      <div className={classes.resultHeading}>
        <Typography className={classes.resultHeadingText}>
          {`${entry.get('resultId')}. ${i18n.get(`${entry.get('action')}`)} ${
            entry.get('mood')
              ? `(${i18n.get(`${renderMood(entry.get('mood'))}`)})`
              : ''
          }`}
        </Typography>
        <Popover
          handle={(
            <IconButton title={`${i18n.get('view')} ${i18n.get('source')} ${i18n.get('text')}`}>
              <RemoveRedEyeIcon fontSize="small" />
            </IconButton>
          )}
        >
          <Paper
            className={classes.source}
            variant="outlined"
          >
            {`${i18n.get('source')} ${i18n.get('text')}: ${entry.get('source')}`}
          </Paper>
        </Popover>
        <IconButton
          title={i18n.get('copy_to_clipboard')}
          onClick={() => handleCopyClick(entry)}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>

      </div>
      {entry.get('text')}
    </Paper>
  )

  return (
    <div>
      <div className={classes.heading}>
        <Typography variant="h6">{i18n.get('ai_suggestions')}</Typography>
        {loading && (
          <CircularProgress
            className={classes.circularProgress}
            size={20}
          />
        )}
      </div>
      <div>
        {!entries.size && (
          <Typography className={classes.noResults}>
            {i18n.get('editor_ai_no_results')}
          </Typography>
        )}
        {entries.map(el => renderResult(el))}
      </div>
      <div />
    </div>
  )
}

PromptHistory.propTypes = {
  entries: PropTypes.instanceOf(List).isRequired,
  loading: PropTypes.bool.isRequired
}

export default PromptHistory
