import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/unsplash'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* searchUnsplashPhotosStart() {
  try {
    const page = yield select(Selectors.getUnsplashPage)
    const perPage = yield select(Selectors.getUnsplashPerPage)
    const orderBy = yield select(Selectors.getUnsplashOrderBy)
    const formData = yield select(Selectors.getUnsplashFormData)

    const body = {
      query: formData.get('query'),
      page,
      per_page: perPage,
      order_by: orderBy
    }

    const result = yield call(Api.searchUnsplashPhotos, body)
    yield put(Actions.setUnsplashPhotos(result))
    yield put(Actions.incrementPage())
    yield put(Actions.searchUnsplashPhotosSuccess())
  } catch (_error) {
    yield put(Actions.searchUnsplashPhotosError())
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* fetchRandomUnsplashPhotosStart() {
  try {
    const body = {
      count: 20
    }

    const result = yield call(Api.fetchRandomUnsplashPhotos, body)
    yield put(Actions.setUnsplashPhotos(result))
    yield put(Actions.searchUnsplashPhotosSuccess())
  } catch (_error) {
    yield put(Actions.searchUnsplashPhotosError())
    yield put(
      AppActions.showAppMessage({
        success: false
      })
    )
  }
}

export function* watchSearchUnsplashPhotosStart() {
  yield takeEvery(Actions.searchUnsplashPhotosStart, searchUnsplashPhotosStart)
}

export function* watchFetchRandomUnsplashPhotos() {
  yield takeEvery(Actions.fetchRandomUnsplashPhotosStart, fetchRandomUnsplashPhotosStart)
}

export default function* searchSaga() {
  yield all([
    watchSearchUnsplashPhotosStart(),
    watchFetchRandomUnsplashPhotos()
  ])
}
