import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import details from './details'
import addToContacts from './add_to_contacts'

export default combineReducers({
  ui,
  data,
  details,
  addToContacts
})
