import { connect } from 'react-redux'

import SearchBar from 'components/search_bar'
import { uiEnteredQuery } from 'actions/darknet'
import { showAppMessage } from 'actions/app'

import { getI18n, getColors, getDarknetRequestRunning, getWhitelabelTheme } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  colors: getColors(state),
  label: 'Search',
  loading: getDarknetRequestRunning(state),
  whitelabelTheme: getWhitelabelTheme(state)
})

export default connect(
  mapStateToProps,
  {
    onSubmit: uiEnteredQuery,
    onError: showAppMessage
  }
)(SearchBar)
