import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Box,
  Chip,
  MenuItem
} from '@mui/material'

import useI18n from 'hooks/useI18n'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { blue, blueGrey } from '@mui/material/colors'

import { Menu } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: !theme.isDark && '#404040'
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: 12
    }
  },
  active: {
    background: blue[500],
    color: 'white'
  },
  inactive: {
    background: blueGrey[500],
    color: 'white'
  }
}))

const CampaignStatus = ({
  campaign,
  requestRunning,
  onChange
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [isStatusDisabled, setIsStatusDisabled] = useState(false)
  const campaignActive = campaign.get('active') && !campaign.get('defaultAssigned')

  useEffect(() => {
    const endDate = campaign.get('endDate')

    if ((endDate && moment(endDate).isBefore(moment())) || campaign.get('defaultAssigned')) {
      setIsStatusDisabled(true)
    } else {
      setIsStatusDisabled(false)
    }
  }, [campaign])

  const handleStatusChange = status => onChange({
    ...campaign.toJS(),
    active: status
  })

  let label

  if (campaignActive) {
    label = (
      <div className={classes.chipLabel}>
        {i18n.get('active')}
        <ArrowDropDownIcon />
      </div>
    )
  } else {
    label = (
      <div className={classes.chipLabel}>
        {i18n.get('closed')}
        <ArrowDropDownIcon />
      </div>
    )
  }

  const renderStatusChip = () => (
    <div className={classes.statusWrapper}>
      <Menu
        handle={(
          <Chip
            disabled={isStatusDisabled}
            label={label}
            className={classes[campaignActive ? 'active' : 'inactive']}
            variant="outlined"
          />
        )}
      >
        <MenuItem
          disabled={requestRunning}
          onClick={() => handleStatusChange(true)}
        >
          {i18n.get('active')}
        </MenuItem>
        <MenuItem
          disabled={requestRunning}
          onClick={() => handleStatusChange(false)}
        >
          {i18n.get('closed')}
        </MenuItem>
      </Menu>
    </div>
  )

  return (
    <Box>
      {renderStatusChip()}
    </Box>
  )
}

CampaignStatus.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired
}

export default CampaignStatus
