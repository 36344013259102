import { connect } from 'react-redux'

import OtsSuccessDialog from 'components/content_desk_new/ots_success_dialog/'

import { getCDeskSelectedContent, getCDeskShowOtsSuccessDialog } from 'selectors'

import {
  closeOtsSuccessDialog
} from 'actions/content_desk'

const mapStateToProps = state => ({
  open: getCDeskShowOtsSuccessDialog(state),
  selectedContent: getCDeskSelectedContent(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeOtsSuccessDialog
  }
)(OtsSuccessDialog)
