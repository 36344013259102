import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import ShortCampaigns from 'containers/content_desk_new/campaigns/short_campaigns/ShortCampaigns'
import ShortContents from 'containers/content_desk_new/contents/short_contents/ShortContents'

const useStyles = makeStyles()({
  detailsContainer: {
    overflowX: 'auto'
  },
  title: {
    margin: '16px 0 8px 16px'
  },
  sortIconActive: {
    fontSize: '0.8rem !important'
  },
  sortIconInActive: {
    fontSize: '0.8rem',
    opacity: 0.5
  }
})

const CalendarEvents = ({
  selectedDay
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const renderCampaigns = () => (
    <Accordion
      disableGutters
      defaultExpanded
    >
      <AccordionSummary
        sx={{
          pointerEvents: 'none'
        }}
        expandIcon={(
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto'
            }}
          />
        )}
      >
        {i18n.get('campaigns')}
      </AccordionSummary>
      <AccordionDetails
        className={classes.detailsContainer}
      >
        <ShortCampaigns />
      </AccordionDetails>
    </Accordion>
  )

  const renderContents = () => (
    <Accordion
      disableGutters
      defaultExpanded
    >
      <AccordionSummary
        sx={{
          pointerEvents: 'none'
        }}
        expandIcon={(
          <ExpandMoreIcon
            sx={{
              pointerEvents: 'auto'
            }}
          />
        )}
      >
        {i18n.get('contents')}
      </AccordionSummary>
      <AccordionDetails
        className={classes.detailsContainer}
      >
        <ShortContents />
      </AccordionDetails>
    </Accordion>
  )

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper>
          <Typography
            variant="h6"
            className={classes.title}
          >
            {moment(selectedDay).calendar(null, {
              sameDay: `[${i18n.get('today')}]`,
              nextDay: `[${i18n.get('tomorrow')}]`,
              nextWeek: 'dddd',
              lastDay: `[${i18n.get('yesterday')}]`,
              lastWeek: `[${i18n.get('last_calendar')}] dddd`,
              sameElse: 'll'
            })}
          </Typography>
          {renderCampaigns()}
          {renderContents()}
        </Paper>
      </Box>
    </>
  )
}

CalendarEvents.propTypes = {
  selectedDay: PropTypes.string.isRequired
}

export default CalendarEvents
