import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Input, PrimaryButton, Button } from 'containers/themed'
import { Capabilities } from 'static/constants'
import CapSafe from 'containers/CapSafe'

import useI18n from 'hooks/useI18n'

import Query from './query'

const useStyles = makeStyles()({
  assistant: {
    marginTop: 20
  },
  queryInputContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    '& > div:first-of-type': {
      width: '100%'
    }
  },
  runIt: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    userSelect: 'auto'
  }
})

const CustomTagAssistantSafe = CapSafe(props => <div {...props} />, Capabilities.HAS_CUSTOM_TAG_ASSISTANT)

export default function CustomTagAssistant({ customTag, onChange }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [query, setQuery] = useState('')

  const handleQueryDeleteClick = q => {
    onChange(customTag.update('queries', queries => queries.delete(q)))
  }

  const handleQueryAdd = () => {
    onChange(customTag.update('queries', queries => queries.add(query)))
    setQuery('')
  }

  const handleQueryKeyUp = e => {
    if (e.keyCode === 13) {
      handleQueryAdd()
    }
  }

  return (
    <CustomTagAssistantSafe className={classes.assistant}>
      {i18n.get('custom_tag_assistant')}&nbsp;

      <Button onClick={() => onChange(customTag.update('percolationEnabled', value => !value))}>
        {i18n.get(customTag.get('percolationEnabled') ? 'deactivate' : 'activate')}
      </Button>

      {customTag.get('percolationEnabled') && (
        <>
          <div className={classes.queryInputContainer}>
            <Input
              value={query}
              label={i18n.get('new_query')}
              onChange={e => setQuery(e.target.value)}
              onKeyUp={e => handleQueryKeyUp(e)}
            />
            {query && (
              <PrimaryButton onClick={handleQueryAdd}>
                {i18n.get('add')}
              </PrimaryButton>
            )}
          </div>

          <br />

          {customTag.get('queries').isEmpty() && i18n.get('no_queries_selected')}

          {customTag.get('queries').toList().map((q, key) => (
            <Query
              key={key}
              query={q}
              onDelete={() => handleQueryDeleteClick(q)}
            />
          ))}
        </>
      )}
    </CustomTagAssistantSafe>
  )
}

CustomTagAssistant.propTypes = {
  customTag: PropTypes.instanceOf(Map).isRequired,

  onChange: PropTypes.func.isRequired
}
