import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import SaveIcon from '@mui/icons-material/Save'

import { Select, Button } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { CardContent, Typography, FormControlLabel, Switch } from '@mui/material'

export default function MiscSettings({
  config,
  onDarkModeChange,
  onSave,
  onChange
}) {
  const i18n = useI18n()

  const darkModeOptions = [
    {
      value: 1,
      realValue: true,
      label: i18n.get('always')
    },
    {
      value: 2,
      realValue: false,
      label: i18n.get('never')
    },
    {
      value: 3,
      realValue: null,
      label: i18n.get('dark_mode_automatically')
    }
  ]

  const darkMode = darkModeOptions.find(({ realValue }) => config.get('darkMode') === realValue)?.value || 2
  const preferSummaries = config.get('preferSummaries')
  const globalClusterDefault = config.get('globalClusterDefault')
  const disableNotificationAlerts = config.get('disableNotificationAlerts')
  const preferMedia = config.get('preferMedia')

  return (
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
      >
        {i18n.get('misc_settings')}
      </Typography>
      <br />

      <Select
        displayEmpty
        variant="outlined"
        label={i18n.get('use_dark_mode')}
        options={darkModeOptions}
        onChange={({ realValue }) => onDarkModeChange(realValue)}
        value={darkMode}
      />

      <br />
      <br />

      <FormControlLabel
        label={i18n.get('show_summaries_always')}
        control={(
          <Switch
            checked={preferSummaries}
            onChange={() => onChange({
              field: 'preferSummaries',
              value: !preferSummaries
            })}
          />
        )}
      />

      <br />

      <FormControlLabel
        label={i18n.get('global_cluster_default')}
        control={(
          <Switch
            checked={globalClusterDefault}
            onChange={() => onChange({
              field: 'globalClusterDefault',
              value: !globalClusterDefault
            })}
          />
        )}
      />

      <br />

      <FormControlLabel
        label={i18n.get('disable_notification_alerts')}
        control={(
          <Switch
            checked={disableNotificationAlerts}
            onChange={() => onChange({
              field: 'disableNotificationAlerts',
              value: !disableNotificationAlerts
            })}
          />
        )}
      />

      <br />

      <FormControlLabel
        label={i18n.get('show_media')}
        control={(
          <Switch
            checked={preferMedia}
            onChange={() => onChange({
              field: 'preferMedia',
              value: !preferMedia
            })}
          />
          )}
      />

      <br />
      <br />

      <Button
        color="primary"
        variant="contained"
        onClick={() => onSave({
          darkMode: config.get('darkMode'),
          preferSummaries,
          globalClusterDefault,
          disableNotificationAlerts,
          preferMedia
        })}
        startIcon={<SaveIcon />}
        saving={config.get('updating')}
      >
        {i18n.get('save')}
      </Button>
    </CardContent>
  )
}

MiscSettings.propTypes = {
  config: PropTypes.instanceOf(Map),

  onDarkModeChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
