import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/page_identities'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  running: false,
  sortBy: 'name',
  showInactive: false,
  grouped: true,
  selectedIds: Set([])
})

export default handleActions({
  [Actions.pageIdentitiesRequestSuccess]: state => state.set('running', false),
  [Actions.pageIdentitiesRequestError]: state => state.set('running', false),
  [Actions.pageIdentitiesRequestStart]: state => state.merge({
    running: true,
    selectedIds: Set([])
  }),

  [Actions.selectPageIdentity]: (state, { payload: pageIdentity }) => {
    if (state.get('selectedIds').has(pageIdentity.get('id'))) {
      return state.update('selectedIds', selectedIds => selectedIds.delete(pageIdentity.get('id')))
    }

    return state.update('selectedIds', selectedIds => selectedIds.add(pageIdentity.get('id')))
  },
  [Actions.resetPageIdentitySelection]: state => state.set('selectedIds', Set([])),

  [Actions.setSortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [Actions.toggleShowInactive]: state => state.update('showInactive', value => !value),

  [Actions.toggleGrouped]: state => state.update('grouped', value => !value),

  [AppActions.resetState]: () => initialState
}, initialState)
