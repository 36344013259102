import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as ShoppingCartActions from 'actions/shopping_cart'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  running: false,
  tabIndex: 0,
  showCustomTagDialog: false,
  showClusterDialog: false,
  showRemoveCustomTagDialog: false,
  showSnippets: false,
  customTagId: null,
  autoAddShoppingCartNews: false
})

export default handleActions({
  [ShoppingCartActions.updateTabIndex]: (state, { payload: tabIndex }) => state.set('tabIndex', tabIndex),
  [ShoppingCartActions.setCustomTagId]: (state, { payload: customTagId }) => state.set('customTagId', customTagId || null),
  [ShoppingCartActions.showCustomTagDialog]: state => state.set('showCustomTagDialog', true),
  [ShoppingCartActions.hideCustomTagDialog]: state => state
    .set('showCustomTagDialog', false)
    .set('autoAddShoppingCartNews', false),
  [ShoppingCartActions.showRemoveCustomTagDialog]: state => state.set('showRemoveCustomTagDialog', true),
  [ShoppingCartActions.hideRemoveCustomTagDialog]: state => state.set('showRemoveCustomTagDialog', false),
  [ShoppingCartActions.toggleSnippets]: state => state.update('showSnippets', value => !value),
  [ShoppingCartActions.fetchNewsStart]: state => state.set('running', true),
  [ShoppingCartActions.fetchNewsSuccess]: state => state.set('running', false),
  [ShoppingCartActions.fetchNewsError]: state => state.set('running', false),
  [ShoppingCartActions.addAllNewsToShoppingCartStart]: state => state.set('running', true),
  [ShoppingCartActions.addAllNewsToShoppingCartSuccess]: state => state.set('running', false),
  [ShoppingCartActions.addAllNewsToShoppingCartError]: state => state.set('running', false),
  [ShoppingCartActions.fetchClusterForShoppingCartStart]: state => state.set('running', true),
  [ShoppingCartActions.fetchClusterForShoppingCartSuccess]: state => state.set('running', false),
  [ShoppingCartActions.fetchClusterForShoppingCartError]: state => state.set('running', false),
  [ShoppingCartActions.showClusterDialog]: state => state.set('showClusterDialog', true),
  [ShoppingCartActions.hideClusterDialog]: state => state.set('showClusterDialog', false),
  [ShoppingCartActions.setRunning]: (state, { payload: running }) => state.set('running', running),
  [ShoppingCartActions.setAutoAddShoppingCartNews]: (state, { payload }) => (
    state.set('autoAddShoppingCartNews', payload)
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
