import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'

import * as FilterActions from 'actions/filter'
import * as NewsActions from 'actions/news'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

import { rangeToDates } from 'utils/date'

export function* uiApplyDateFilter({ payload: { dateFrom, dateTo, dateRange } }) {
  yield put(NewsActions.toggleDateForm(false))
  yield put(FilterActions.changeDate({ dateFrom, dateTo, dateRange }))
  yield put(Actions.searchRequestStart())
  yield put(Actions.scoresRequestStart())
}

export function* uiChooseQuickSelect({ payload: range }) {
  const { from, to } = yield call(rangeToDates, range)

  if (from || to) {
    yield put(Actions.uiApplyDateFilter({
      dateFrom: from,
      dateTo: to,
      dateRange: range
    }))
  }
}

export function* uiEnteredQuery({ payload: query }) {
  if (query !== '') {
    let isValid = false

    try {
      const body = {
        query,
        index_type: 'Darknet_entries'
      }

      const { valid } = yield call(Api.validate, body)

      isValid = valid
    } catch (e) {
      isValid = false
    }

    if (isValid) {
      const queries = query.split(',').map(q => q.trim()).filter(q => q)

      for (let i = 0; i < queries.length; i += 1) {
        yield put(Actions.addDarknetSearchQuery({
          query: queries[i]
        }))
      }

      yield put(Actions.searchRequestStart())
    } else {
      const i18n = yield select(Selectors.getI18n)

      yield put(AppActions.showAppMessage({
        text: i18n.get('invalid_query'),
        success: false
      }))
    }
  } else {
    yield put(Actions.searchRequestStart())
  }
}

export function* uiApplyFilters() {
  yield put(Actions.toggleAdvancedSearch())
  yield put(Actions.searchRequestStart())
}

export function* uiResetFilters() {
  yield put(Actions.resetFilters())
  yield put(Actions.toggleAdvancedSearch())
  yield put(Actions.searchRequestStart())
}

export function* uiRemoveFilter({ payload: { index, field } }) {
  yield put(Actions.removeFilter({ index, field }))
  yield put(Actions.searchRequestStart())
}

export function* uiInvertFilter({ payload: { index, field } }) {
  yield put(Actions.invertFilter({ index, field }))
  yield put(Actions.searchRequestStart())
}

export function* uiUpdateFilter({ payload }) {
  yield put(Actions.updateFilter(payload))
  yield put(Actions.searchRequestStart())
}

export function* watchUiApplyDateFilter() {
  yield takeEvery(Actions.uiApplyDateFilter, uiApplyDateFilter)
}

export function* watchUiChooseQuickSelect() {
  yield takeEvery(Actions.uiChooseQuickSelect, uiChooseQuickSelect)
}

export function* watchUiEnteredQuery() {
  yield takeEvery(Actions.uiEnteredQuery, uiEnteredQuery)
}

export function* watchUiRemoveFilter() {
  yield takeEvery(Actions.uiRemoveFilter, uiRemoveFilter)
}

export function* watchUiInvertFilter() {
  yield takeEvery(Actions.uiInvertFilter, uiInvertFilter)
}

export function* watchUiApplyFilters() {
  yield takeEvery(Actions.uiApplyFilters, uiApplyFilters)
}

export function* watchUiUpdateFilter() {
  yield takeEvery(Actions.uiUpdateFilter, uiUpdateFilter)
}

export function* watchUiResetFilters() {
  yield takeEvery(Actions.uiResetFilters, uiResetFilters)
}

export default function* filterSaga() {
  yield all([
    watchUiChooseQuickSelect(),
    watchUiEnteredQuery(),
    watchUiApplyFilters(),
    watchUiRemoveFilter(),
    watchUiInvertFilter(),
    watchUiUpdateFilter(),
    watchUiApplyDateFilter(),
    watchUiResetFilters()
  ])
}
