import { connect } from 'react-redux'

import ClusterDialog from 'components/shopping_cart/cluster_dialog'
import {
  getShowShoppingCartClusterDialog,
  getShoppingCartClusterNews,
  getShoppingCartClusterNewsIds,
  getShoppingCartClusterLoading,
  getShoppingActiveNewsIdFirst,
  getShoppingActiveNewsIdSecond,
  getShoppingHoveredNewsIdFirst,
  getShoppingHoveredNewsIdSecond
} from 'selectors'
import {
  hideClusterDialog,
  moveNewsInCluster,
  replaceCluster,
  setActiveNewsIdsInCluster,
  setHoveredNewsIdsInCluster
} from 'actions/shopping_cart'

const mapStateToProps = state => ({
  active: getShowShoppingCartClusterDialog(state),
  newsIds: getShoppingCartClusterNewsIds(state),
  news: getShoppingCartClusterNews(state),
  loading: getShoppingCartClusterLoading(state),
  activeNewsIdFirst: getShoppingActiveNewsIdFirst(state),
  activeNewsIdSecond: getShoppingActiveNewsIdSecond(state),
  hoveredNewsIdFirst: getShoppingHoveredNewsIdFirst(state),
  hoveredNewsIdSecond: getShoppingHoveredNewsIdSecond(state)
})

export default connect(
  mapStateToProps,
  {
    onHide: hideClusterDialog,
    onMoveClick: moveNewsInCluster,
    onMoveStart: setActiveNewsIdsInCluster,
    onHover: setHoveredNewsIdsInCluster,
    onSaveClick: replaceCluster
  }
)(ClusterDialog)
