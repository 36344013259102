import { call, put, select, takeEvery, all } from 'redux-saga/effects'

import * as Actions from 'actions/identity_sets'
import * as Api from 'api/bff'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'

export function* saveIdentitySet() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const identitySet = yield select(Selectors.getSelectedIdentitySet)
    const uploadedPhoto = yield select(Selectors.getIdentitySetsUploadedPhoto)

    let photo = null

    if (uploadedPhoto) {
      const formData = [
        {
          key: 'file',
          value: uploadedPhoto
        }
      ]

      const { url } = yield call(Api.uploadFile, formData)
      photo = url
    }

    const body = {
      name: identitySet.get('name'),
      photo
    }

    let result
    let successMessage = i18n.get('created')

    if (identitySet.get('id')) {
      successMessage = i18n.get('updated')
      result = yield call(Api.updateIdentitySet, identitySet.get('id'), body)
    } else {
      result = yield call(Api.createIdentitySet, body)
    }

    yield put(Actions.saveIdentitySetSuccess({ identitySet: result }))
    yield put(Actions.hideIdentitySetDialog())
    yield put(AppActions.showAppMessage({ text: `<b>${result.name}</b> ${successMessage}` }))
  } catch (error) {
    yield put(Actions.saveIdentitySetError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* deleteIdentitySet() {
  try {
    const i18n = yield select(Selectors.getI18n)
    const identitySet = yield select(Selectors.getSelectedIdentitySet)

    yield put(Actions.hideDeleteIdentitySetDialog())

    yield call(Api.deleteIdentitySet, identitySet.get('id'))

    yield put(Actions.deleteIdentitySetSuccess(identitySet.get('id')))
    yield put(AppActions.showAppMessage({ text: `<b>${identitySet.get('name')}</b> ${i18n.get('deleted')}` }))
  } catch (error) {
    yield put(Actions.deleteIdentitySetError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* addIdentityToIdentitySet({ payload: { identitySet } }) {
  try {
    const identity = yield select(Selectors.getIdentitySetsSelectedIdentity)

    const result = yield call(Api.addIdentityToIdentitySet, identitySet.get('id'), identity.get('id'))

    yield put(Actions.addIdentityToIdentitySetSuccess({ identitySet, identityId: identity.get('id'), identitySetsIdentities: result }))
  } catch (error) {
    yield put(Actions.addIdentityToIdentitySetError({ error, identitySet }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* removeIdentityFromIdentitySet({ payload: { identitySet, identityId, showSuccess } }) {
  try {
    yield call(Api.removeIdentityFromIdentitySet, identitySet.get('id'), identityId)

    yield put(Actions.removeIdentityFromIdentitySetSuccess({ identitySet, identityId }))

    if (showSuccess) {
      const i18n = yield select(Selectors.getI18n)
      yield put(AppActions.showAppMessage({ text: `<b>${identitySet.get('name')}</b> ${i18n.get('removed')}` }))
    }
  } catch (error) {
    yield put(Actions.removeIdentityFromIdentitySetError({ error, identitySet }))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchSaveIdentitySet() {
  yield takeEvery(Actions.saveIdentitySetStart, saveIdentitySet)
}

export function* watchDeleteIdentitySet() {
  yield takeEvery(Actions.deleteIdentitySetStart, deleteIdentitySet)
}

export function* watchAddIdentityToIdentitySet() {
  yield takeEvery(Actions.addIdentityToIdentitySetStart, addIdentityToIdentitySet)
}

export function* watchRemoveIdentityFromIdentitySet() {
  yield takeEvery(Actions.removeIdentityFromIdentitySetStart, removeIdentityFromIdentitySet)
}

export default function* saga() {
  yield all([
    watchSaveIdentitySet(),
    watchDeleteIdentitySet(),
    watchAddIdentityToIdentitySet(),
    watchRemoveIdentityFromIdentitySet()
  ])
}
