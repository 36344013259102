import { getNewsById } from './data'

export const getNewsUi = state => state.news.get('ui')
export const getSelectedQueryType = state => getNewsUi(state).get('selectedQueryType')
export const getSearchBarLabel = state => getNewsUi(state).get('searchBarLabel')
export const getShowAdvancedSearch = state => getNewsUi(state).get('showAdvancedSearch')
export const getNewsPageShowRemoveNewsDialog = state => getNewsUi(state).get('displayRemoveDialog')
export const getRemoveNewsPageNewsDeleting = state => getNewsUi(state).get('deletingNews')
export const getSelectedNewsToRemove = state => getNewsUi(state).get('selectedNewsToRemove')
export const getNewsRequestIsRunning = state => getNewsUi(state).get('newsRequestIsRunning')
export const getNewsView = state => getNewsUi(state).get('newsView')
export const isCurrentView = (state, ...names) => names.some(name => getNewsView(state) === name)
export const getShoppingCartSortBy = state => getNewsUi(state).get('sortBy')
export const getShoppingCartSortByUngrouped = state => getNewsUi(state).get('sortByUngrouped')
export const getActiveFiltersCollapsed = state => getNewsUi(state).get('activeFiltersCollapsed')
export const getLastSeenNewsId = state => getNewsUi(state).get('lastSeenNewsId')
export const getLastNewsIdClickedOnMorePublications = state => getNewsUi(state).get('lastNewsIdClickedOnMorePublications')
export const getSelectedNewsIdForAiAnalysis = state => getNewsUi(state).get('selectedNewsIdForAiAnalysis')

export const getShowNewsList = state => getNewsUi(state).get('showNewsList')
export const getShowNewsListDrawer = state => getNewsUi(state).get('showNewsListDrawer')

export const getSelectedNewsIdToShowInDrawer = state => getNewsUi(state).get('selectedNewsIdToShowInDrawer')
export const getSelectedNewsToShowInDrawer = state => getNewsById(state, getSelectedNewsIdToShowInDrawer(state))
export const getNewsToShowInDrawerSelected = state => Boolean(getSelectedNewsToShowInDrawer(state))
export const getNewsRequestBlocked = state => getNewsUi(state).get('newsRequestBlocked')
export const getYoutubePlayerActiveNewsId = state => getNewsUi(state).get('youtubePlayerActiveNewsId')
export const getTiktokPlayerActiveNewsId = state => getNewsUi(state).get('tiktokPlayerActiveNewsId')
export const getTranslateRunningRequestForNewsId = state => getNewsUi(state).get('translateRunningRequestForNewsId')
