import React from 'react'

import { makeStyles } from 'tss-react/mui'

import { Box } from '@mui/material'

import StorageLimit from 'containers/content_desk_new/contents/content_edit_dialog/attachments/StorageLimit'
import SelectMedia from 'containers/content_desk_new/contents/content_edit_dialog/attachments/SelectMedia'
import StorageWarning from 'containers/content_desk_new/contents/content_edit_dialog/attachments/StorageWarning'
import FilesGallery from 'containers/content_desk_new/contents/content_edit_dialog/attachments/FilesGallery'
import DeleteFileDialog from 'containers/content_desk_new/contents/content_edit_dialog/attachments/DeleteFileDialog'
import ImagePreviewDialog from 'containers/content_desk_new/contents/content_edit_dialog/attachments/ImagePreviewDialog'

const useStyles = makeStyles()({
  container: {
    padding: '40px 20px 0px 20px'
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

const Attachments = () => {
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.innerContainer}>
        <StorageLimit />
        <SelectMedia />
      </Box>
      <StorageWarning />
      <FilesGallery />
      <DeleteFileDialog />
      <ImagePreviewDialog />
    </Box>
  )
}

export default Attachments
