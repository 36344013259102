import { createAction } from 'redux-actions'

const defaultPrefix = 'MEDIA_REVIEWS/'
let prefix = defaultPrefix

export const getMediaReviews = createAction(`${prefix}GET_MEDIA_REVIEWS`)
export const setMediaReviews = createAction(`${prefix}SET_MEDIA_REVIEWS`)

export const addNewsToMediaReview = createAction(`${prefix}ADD_NEWS_TO_MEDIA_REVIEW`)

export const collapseAllTopics = createAction(`${prefix}COLLAPSE_ALL_TOPICS`)
export const expandAllTopics = createAction(`${prefix}EXPAND_ALL_TOPICS`)

export const collapseTopics = createAction(`${prefix}COLLAPSE_TOPICS`)
export const expandTopics = createAction(`${prefix}EXPAND_TOPICS`)

export const fetchNewsForMediaReviewStart = createAction(`${prefix}FETCH_NEWS_FOR_MEDIA_REVIEW_START`)
export const fetchNewsForMediaReviewError = createAction(`${prefix}FETCH_NEWS_FOR_MEDIA_REVIEW_ERROR`)
export const fetchNewsForMediaReviewSuccess = createAction(`${prefix}FETCH_NEWS_FOR_MEDIA_REVIEW_SUCCESS`)

export const selectMediaReview = createAction(`${prefix}SELECT_MEDIA_REVIEW`)

export const loadFullTextStart = createAction(`${prefix}LOAD_FULL_TEXT_START`)
export const loadFullTextSuccess = createAction(`${prefix}LOAD_FULL_TEXT_SUCCESS`)
export const loadFullTextError = createAction(`${prefix}LOAD_FULL_TEXT_ERROR`)

export const moveNewsInMediaReviewStart = createAction(`${prefix}MOVE_NEWS_IN_MEDIA_REVIEW_START`)
export const moveNewsInMediaReviewSuccess = createAction(`${prefix}MOVE_NEWS_IN_MEDIA_REVIEW_SUCCESS`)
export const moveNewsInMediaReviewError = createAction(`${prefix}MOVE_NEWS_IN_MEDIA_REVIEW_ERROR`)

export const moveCodeInMediaReviewStart = createAction(`${prefix}MOVE_CODE_IN_MEDIA_REVIEW_START`)
export const moveCodeInMediaReviewSuccess = createAction(`${prefix}MOVE_CODE_IN_MEDIA_REVIEW_SUCCESS`)
export const moveCodeInMediaReviewError = createAction(`${prefix}MOVE_CODE_IN_MEDIA_REVIEW_ERROR`)

export const removeNewsFromMediaReviewStart = createAction(`${prefix}REMOVE_NEWS_FROM_MEDIA_REVIEW_START`)
export const removeNewsFromMediaReviewSuccess = createAction(`${prefix}REMOVE_NEWS_FROM_MEDIA_REVIEW_SUCCESS`)
export const removeNewsFromMediaReviewError = createAction(`${prefix}REMOVE_NEWS_FROM_MEDIA_REVIEW_ERROR`)

export const showRemoveNewsDialog = createAction(`${prefix}SHOW_REMOVE_NEWS_DIALOG`)
export const hideRemoveNewsDialog = createAction(`${prefix}HIDE_REMOVE_NEWS_DIALOG`)

export const showAllSnippets = createAction(`${prefix}SHOW_ALL_SNIPPETS`)
export const showSnippet = createAction(`${prefix}SHOW_SNIPPET`)
export const hideAllSnippets = createAction(`${prefix}HIDE_ALL_SNIPPETS`)
export const hideSnippet = createAction(`${prefix}HIDE_SNIPPET`)

export const pinAllStart = createAction(`${prefix}PIN_ALL_START`)
export const pinAllError = createAction(`${prefix}PIN_ALL_ERROR`)
export const pinAllSuccess = createAction(`${prefix}PIN_ALL_SUCCESS`)
export const unpinAll = createAction(`${prefix}UNPIN_ALL`)
export const showCustomTagDialog = createAction(`${prefix}SHOW_CUSTOM_TAG_DIALOG`)
export const hideCustomTagDialog = createAction(`${prefix}HIDE_CUSTOM_TAG_DIALOG`)
export const showRemoveCustomTagDialog = createAction(`${prefix}SHOW_REMOVE_CUSTOM_TAG_DIALOG`)
export const hideRemoveCustomTagDialog = createAction(`${prefix}HIDE_REMOVE_CUSTOM_TAG_DIALOG`)
export const setAutoAddMediaReviewsNews = createAction(`${prefix}SET_AUTO_ADD_MEDIA_REVIEWS_NEWS`)

export const showAllSummaries = createAction(`${prefix}SHOW_ALL_SUMMARIES`)
export const showSummary = createAction(`${prefix}SHOW_SUMMARY`)
export const hideAllSummaries = createAction(`${prefix}HIDE_ALL_SUMMARIES`)
export const hideSummary = createAction(`${prefix}HIDE_SUMMARY`)

export const toggleHighlight = createAction(`${prefix}TOGGLE_HIGHLIGHT`)
export const toggleNewsList = createAction(`${prefix}TOGGLE_NEWS_LIST`)

export const setCodes = createAction(`${prefix}SET_CODES`)
export const setNews = createAction(`${prefix}SET_NEWS`)

export const regroupNews = createAction(`${prefix}REGROUP_NEWS`)
export const updateMediaReviewStats = createAction(`${prefix}UPDATE_MEDIA_REVIEW_STATS`)
export const recalculateMediaReviewStats = createAction(`${prefix}RECALCULATE_MEDIA_REVIEW_STATS`)

export const selectNewsForSorting = createAction(`${prefix}SELECT_NEWS_FOR_SORTING`)
export const shiftSelectNewsForSorting = createAction(`${prefix}ON_SHIFT_SELECT_NEWS`)
export const deselectNewsForSorting = createAction(`${prefix}DESELECT_NEWS_FOR_SORTING`)
export const resetSortingSelection = createAction(`${prefix}RESET_SORTING_SELECTION`)

export const sortBy = createAction(`${prefix}SORT_BY`)
export const setGroupedNews = createAction(`${prefix}SET_GROUPED_NEWS`)

export const setMediaReviewFilter = createAction(`${prefix}SET_MEDIA_REVIEW_FILTER`)
export const setMediaReviewFilterSize = createAction(`${prefix}SET_MEDIA_REVIEW_FILTER_SIZE`)

export const filterMediaReviewsStart = createAction(`${prefix}FILTER_MEDIA_REVIEW_START`)
export const filterMediaReviewsSuccess = createAction(`${prefix}FILTER_MEDIA_REVIEW_SUCCESS`)
export const filterMediaReviewsError = createAction(`${prefix}FILTER_MEDIA_REVIEW_ERROR`)

export const replaceMediaReviewReactionStart = createAction(`${prefix}REPLACE_MEDIA_REVIEW_REACTION_START`)
export const replaceMediaReviewReactionSuccess = createAction(`${prefix}REPLACE_MEDIA_REVIEW_REACTION_SUCCESS`)
export const replaceMediaReviewReactionError = createAction(`${prefix}REPLACE_MEDIA_REVIEW_REACTION_ERROR`)

export const fetchMediaReviewReactionsStart = createAction(`${prefix}FETCH_MEDIA_REVIEW_REACTIONS_START`)
export const fetchMediaReviewReactionsSuccess = createAction(`${prefix}FETCH_MEDIA_REVIEW_REACTIONS_SUCCESS`)
export const fetchMediaReviewReactionsError = createAction(`${prefix}FETCH_MEDIA_REVIEW_REACTIONS_ERROR`)

export const checkMediaReviewReactionsStart = createAction(`${prefix}CHECK_MEDIA_REVIEW_REACTIONS_START`)
export const checkMediaReviewReactionsStop = createAction(`${prefix}CHECK_MEDIA_REVIEW_REACTIONS_STOP`)

export const setSnapshot = createAction(`${prefix}SET_SNAPSHOT`)

export const reorderStart = createAction(`${prefix}REORDER_START`)
export const reorderSuccess = createAction(`${prefix}REORDER_SUCCESS`)
export const reorderError = createAction(`${prefix}REORDER_ERROR`)

export const checkDiffStart = createAction(`${prefix}CHECK_DIFF_START`)
export const checkDiffSuccess = createAction(`${prefix}CHECK_DIFF_SUCCESS`)
export const checkDiffError = createAction(`${prefix}CHECK_DIFF_ERROR`)

export const showDiffWarning = createAction(`${prefix}SHOW_DIFF_WARNING`)
export const hideDiffWarning = createAction(`${prefix}HIDE_DIFF_WARNING`)
export const acceptDiff = createAction(`${prefix}ACCEPT_DIFF`)
export const rejectDiff = createAction(`${prefix}REJECT_DIFF`)

prefix = `${defaultPrefix}SELECTED/`
export const selectNews = createAction(`${prefix}SELECT_NEWS`)
export const resetNewsSelection = createAction(`${prefix}RESET_NEWS_SELECTION`)
export const invertNewsSelection = createAction(`${prefix}INVERT_NEWS_SELECTION`)
export const setSelectedNews = createAction(`${prefix}SET_SELECTED_NEWS`)
