import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { styled } from '@mui/material/styles'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import RemoveIcon from '@mui/icons-material/Remove'
import { IconButton } from 'containers/themed'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

const SelectedDistributionListsTable = ({ data, onChange }) => {
  const i18n = useI18n()
  const distributionLists = data.get('lists') || []

  const handleRemove = list => {
    const lists = data.get('lists').filter(l => l.get('id') !== list.get('id'))

    onChange({ key: 'lists', value: lists })
  }

  const renderDistributionListRow = list => (
    <StyledTableRow
      key={`list__${list.get('id')}`}
    >
      <StyledTableCell sx={{ width: '60px' }}>
        <PeopleAltOutlinedIcon sx={{
          color: t => !t.isDark && t.palette.grey[800]
        }}
        />
      </StyledTableCell>
      <StyledTableCell>{list.get('name')}</StyledTableCell>
      <StyledTableCell>{`${list.get('contacts').size} ${i18n.get('contacts')}`}</StyledTableCell>
      {distributionLists.size > 2 && (
      <StyledTableCell sx={{ textAlign: 'right', paddingRight: t => t.spacing(2) }}>
        <IconButton
          size="small"
          color="error"
          onClick={() => handleRemove(list)}
        >
          <RemoveIcon />
        </IconButton>
      </StyledTableCell>
      )}
    </StyledTableRow>
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          padding="none"
          size="small"
        >
          <TableHead py={2} />
          <TableBody>
            {distributionLists.map(list => renderDistributionListRow(list))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

SelectedDistributionListsTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired
}

export default SelectedDistributionListsTable
