import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, List } from 'immutable'
import { Chip, Box, Divider, Typography } from '@mui/material'

function LanguageChip({ label }) {
  return (
    <Box
      mr={1}
      my={1}
      display="inline-block"
    >
      <Chip
        px={2}
        size="small"
        label={label}
      />
    </Box>
  )
}

export default function ContactLanguages({ contact, languages }) {
  const contactLanguages = contact.get('languages')
  const i18n = useI18n()

  if (!contactLanguages || contactLanguages.isEmpty()) {
    return null
  }

  const ids = contactLanguages.map(t => t.get('id'))
  const languagesWithNames = languages.filter(l => ids.includes(l.get('id')))

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Typography
        variant="subtitle2"
        pb={2}
        sx={{ fontSize: '90%' }}
      >{i18n.get('languages')}
      </Typography>
      <Box>
        {languagesWithNames.map(language => (
          <LanguageChip
            key={`language__${language.get('id')}`}
            label={language.get('name')}
          />
        ))}
        {contact.get('preferredLanguageId') && (
        <Typography
          px={0.5}
          py={1}
          sx={{ fontSize: '90%', fontStyle: 'italic', color: t => t.palette.grey[700] }}
        >{`${i18n.get('preferred_language')}: ${languages.find(l => l.get('id') === contact.get('preferredLanguageId')).get('name')}`}
        </Typography>
        )}
      </Box>
    </>
  )
}

ContactLanguages.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  languages: PropTypes.instanceOf(List).isRequired
}

LanguageChip.propTypes = {
  label: PropTypes.string.isRequired
}
