import { combineReducers } from 'redux-immutable'

import ui from './ui'
import filter from './filter'
import data from './data'
import calendar from './calendar'

export default combineReducers({
  calendar,
  ui,
  filter,
  data
})
