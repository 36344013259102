import { connect } from 'react-redux'

import LinkedInPopUp from 'components/administration/external_accounts/linked_in_popup'

import {
  getQueryParamsFromUrl
} from 'selectors'

const mapStateToProps = state => ({
  params: getQueryParamsFromUrl(state)
})

export default connect(mapStateToProps, {})(LinkedInPopUp)
