import { connect } from 'react-redux'

import Navigation from 'components/content_desk_new/contents/content_edit_dialog/navigation'

import {
  changeContentEditTabView
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskSearchContentsRunning,
  getCDeskContentEditTabView
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  contentsLoading: getCDeskSearchContentsRunning(state),
  view: getCDeskContentEditTabView(state)
})

export default connect(
  mapStateToProps,
  {
    onNavigationChange: changeContentEditTabView
  }
)(Navigation)
