import { connect } from 'react-redux'

import PasswordForm from 'components/personal_settings/password_form'
import { getUser } from 'selectors'
import { updateUserRequestStart } from 'actions/user'

const mapStateToProps = state => ({
  user: getUser(state)
})

export default connect(
  mapStateToProps,
  {
    onSave: updateUserRequestStart
  }
)(PasswordForm)
