import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material'
import { grey } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  headlineDivider: {
    margin: '1em 0em 1em 0em'
  },
  card: {
    borderRadius: 20,
    minHeight: 147,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200]
  },
  cardContent: {
    marginTop: 18,
    display: 'flex',
    alignContent: 'center'
  },
  kpiWrapper: {
    height: '100%'
  },
  value: {
    fontSize: '30pt',
    fontWeight: '700',
    color: !theme.isDark && '#404040'
  },
  label: {
    fontSize: '12pt',
    color: !theme.isDark && '#757575'
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  }
}))

const Facebook = ({ data, requestRunning, contentType }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const loadOrShowData = key => {
    if (requestRunning) {
      return <CircularProgress />
    }

    if (data.getIn(['owned', 'keyFigures']) && data.getIn(['owned', 'keyFigures']).size !== 0) {
      return data.getIn(['owned', 'keyFigures']).find(k => k.get('name') === key).get('value')
    }

    return 'n/a'
  }

  return (
    <>
      <Divider className={classes.headlineDivider} />
      <Typography
        className={classes.headline}
        variant="h6"
      >
        {i18n.get('owned')}
      </Typography>
      <Grid
        container
        spacing={2}
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('reactions')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_reactions_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('likes')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_likes_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_love')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_love_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_wow')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_wow_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_haha')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_haha_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_sad')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_sad_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_anger')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_anger_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('facebook_unique_impressions')}</div>
                <div className={classes.value}>{loadOrShowData('facebook_unique_impressions_count')}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get(contentType === 'video' ? 'facebook_views' : 'facebook_clicks')}</div>
                <div className={classes.value}>
                  {loadOrShowData(contentType === 'video' ? 'facebook_video_views' : 'facebook_clicks_count')}
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

Facebook.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired,
  contentType: PropTypes.string
}

export default Facebook
