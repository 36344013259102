import { fromJS } from 'immutable'

import { getNewsByIds } from 'selectors/news/data'
import camelcase from 'camelcase'
import { createImmutableSelector } from 'utils/reselect'
import { listUniqueById } from 'utils/immutable'
import { getIdentitySets } from './identity_sets'
import { getI18n } from './i18n'

export const getPageIdentitiesUi = state => state.pageIdentities.get('ui')
export const getPageIdentitiesSortBy = state => getPageIdentitiesUi(state).get('sortBy')

const sortPageIdentity = (p, sortBy) => {
  if (sortBy === 'name') {
    return p.getIn(['pageIdentity', sortBy]).toLowerCase()
  }

  if (sortBy === 'channel') {
    return p.getIn(['pageIdentity', 'channel_id'])
  }

  return -p.getIn(['pageIdentity', 'stats', sortBy])
}

const sortedPageIdentities = (pageIdentities, identitySets, sortBy) => pageIdentities
  .map(pageIdentity => pageIdentity.setIn(
    ['pageIdentity', 'identitySets'],
    pageIdentity.getIn(['pageIdentity', 'identitySetIds'])
      .map(id => identitySets.find(set => set.get('id') === id))
      .filter(set => set)
  ))
  .sortBy(p => sortPageIdentity(p, sortBy))

const sortedPageIdentitiesSelector = selector => (
  createImmutableSelector(selector, getIdentitySets, getPageIdentitiesSortBy, sortedPageIdentities)
)

// DATA
export const getPageIdentitiesData = state => state.pageIdentities.get('data')
const pageIdenititiesSelector = state => getPageIdentitiesData(state).get('pageIdentities')
const inactivePageIdenititiesSelector = state => getPageIdentitiesData(state).get('inactivePageIdentities')
const allPageIdentitiesSelector = state => pageIdenititiesSelector(state).concat(inactivePageIdenititiesSelector(state))

export const getPageIdentities = sortedPageIdentitiesSelector(pageIdenititiesSelector)
export const getInactivePageIdentities = sortedPageIdentitiesSelector(inactivePageIdenititiesSelector)
export const getAllPageIdentities = sortedPageIdentitiesSelector(allPageIdentitiesSelector)

export const getPageIdentitiesTotal = state => getPageIdentitiesData(state).get('total')
export const getGroupedPageIdentities = createImmutableSelector(
  getAllPageIdentities,
  getPageIdentitiesSortBy,
  getI18n,
  (pageIdentities, sortBy, i18n) => {
    const allSets = listUniqueById(pageIdentities.map(p => p.getIn(['pageIdentity', 'identitySets'])).flatten(true))
    const allSetIds = allSets.map(a => a.get('id'))

    const ungroupedPageIdentities = (
      pageIdentities.filter(p => !p.getIn(['pageIdentity', 'identitySetIds']).some(i => allSetIds.includes(i)))
    )

    let result = allSets.map(
      set => set.set(
        'pageIdentities',
        set.get('identitySetsIdentities')
          .map(isi => pageIdentities.find(p => p.getIn(['pageIdentity', 'id']) === isi.get('identityId')))
          .filter(p => p)
          .sortBy(p => sortPageIdentity(p, sortBy))
          .map(p => p.updateIn(['pageIdentity', 'identitySets'], sets => sets.map(s => s.delete('identitySetsIdentities'))))
      )
        .delete('identitySetsIdentities')
    )

    if (!ungroupedPageIdentities.isEmpty()) {
      result = result.push(fromJS({
        id: null,
        name: i18n.get('ungrouped'),
        pageIdentities: ungroupedPageIdentities
      }))
    }

    return result
      .map(set => set.set('stats', set.get('pageIdentities').map(p => p.getIn(['pageIdentity', 'stats'])).reduce(
        (acc, stats) => (
          acc
            .update('buzz', buzz => buzz + stats.get('buzz'))
            .update('avgReach', avgReach => avgReach + stats.get('avgReach'))
            .update('interactions', interactions => interactions + stats.get('interactions'))
            .update('interactionRate', interactionRate => interactionRate + stats.get('interactionRate'))
        ),
        fromJS({
          buzz: 0,
          avgReach: 0,
          interactions: 0,
          interactionRate: 0.0
        })
      )))
      .sortBy(set => {
        if (set.get('id') === null) {
          return 'zzzzzzzzzzzzzzzzzzzzzzz'
        }

        if (sortBy === 'name') {
          return set.get(sortBy).toLowerCase()
        }

        return -set.getIn(['stats', sortBy])
      })
  }
)

// UI
export const getPageIdentitiesShowInactive = state => getPageIdentitiesUi(state).get('showInactive')
export const getPageIdentitiesGrouped = state => getPageIdentitiesUi(state).get('grouped')
export const getPageIdentitiesRunning = state => getPageIdentitiesUi(state).get('running')
export const getSelectedPageIdentityIds = state => getPageIdentitiesUi(state).get('selectedIds')
export const getSelectedPageIdentities = state => (
  getPageIdentitiesUi(state)
    .get('selectedIds').map(
      id => getAllPageIdentities(state)
        .find(p => p.getIn(['pageIdentity', 'id']) === id)
    )
    .filter(p => p)
    .toList()
)
export const getShowChartSubMenu = state => getPageIdentitiesUi(state).get('showChartSubMenu')

// STATISTICS/UI
export const getPageIdentitiesStatisticsUi = state => state.pageIdentities.getIn(['statistics', 'ui'])
export const getPageIdentitiesStatisticsSelectedInteractionCharts = state => (
  getPageIdentitiesStatisticsUi(state).get('selectedInteractionCharts')
)

// STATISTICS/DATA
export const getPageIdentitiesStatisticsData = state => state.pageIdentities.getIn(['statistics', 'data'])
export const getPageIdentitiesStatisticsBuzzChart = state => getPageIdentitiesStatisticsData(state).get(0)
export const getPageIdentitiesStatisticsInteractionRatesChart = state => getPageIdentitiesStatisticsData(state).get(1)
export const getPageIdentitiesStatisticsFollowersChart = state => getPageIdentitiesStatisticsData(state).get(3)
export const getPageIdentitiesStatisticsInteractionsChart = state => {
  const selectedInteractionCharts = getPageIdentitiesStatisticsSelectedInteractionCharts(state).toList().sort()
  const label = selectedInteractionCharts.join(' / ').toUpperCase()
  const thirdLevel = camelcase(selectedInteractionCharts.join('_'))

  return getPageIdentitiesStatisticsData(state).get(2).merge({ label, thirdLevel })
}

export const getPageIdentitiesStatisticsEmojisChart = state => getPageIdentitiesStatisticsData(state).get(4)
export const getPageIdentitiesStatisticsHashtagsChart = state => getPageIdentitiesStatisticsData(state).get(5)
export const getPageIdentitiesStatisticsMentionsChart = state => getPageIdentitiesStatisticsData(state).get(6)

export const getPageIdentitiesStatisticsTimelineInterval = state => (
  getPageIdentitiesStatisticsData(state).getIn(['0', 'data', 0, 'interval'])
)

// POSTS/DATA
export const getPageIdentitiesPostsData = state => state.pageIdentities.getIn(['posts', 'data'])
export const getBestPageIdentityPosts = state => getNewsByIds(state, getPageIdentitiesPostsData(state).get('best'))
export const getWorstPageIdentityPosts = state => getNewsByIds(state, getPageIdentitiesPostsData(state).get('worst'))
export const getBestPageIdentityPostIds = state => getPageIdentitiesPostsData(state).get('best')
export const getWorstPageIdentityPostIds = state => getPageIdentitiesPostsData(state).get('worst')
