import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import ClearAllIcon from '@mui/icons-material/ClearAll'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from 'containers/themed'

import useDevice from 'hooks/useDevice'

import { Snackbar, SnackbarContent } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  spacer: {
    flex: 1
  },
  snackbar: {
    bottom: 5,
    zIndex: 200,
    marginLeft: '1em'
  },
  elevatedSnackbar: {
    bottom: 70,
    zIndex: 200,
    marginLeft: '1em'
  },
  snackbarContent: {
    minHeight: 60,
    minWidth: '95vw',
    maxWidth: '95vw',
    [theme.breakpoints.only('xs')]: {
      minWidth: '100vw',
      maxWidth: '100vw'
    },
    backgroundColor: theme.palette.secondary.main
  },
  action: {
    width: '100%',
    marginLeft: 0
  },
  button: {
    marginRight: '1em'
  }
}))

export default function SelectedDistributionListsBar({ lists, contactsBarOpen, onClick, onClear }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const snackbarContent = (
    <>
      {lists.size} {i18n.get('distribution_lists')} {i18n.get('selected')}
      <div className={classes.spacer} />
      {device.get('gt-xs') ? (
        <>
          <Button
            className={classes.button}
            onClick={onClear}
            startIcon={<ClearAllIcon />}
            color="inherit"
          >
            {i18n.get('clear')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={lists.size < 2}
            sx={{
              '&.Mui-disabled': {
                background: '#e0e0e0'
              }
            }}
          >
            {i18n.get('merge_distribution_lists')}
          </Button>
        </>
      ) : (
        <>
          <IconButton
            onClick={onClear}
            color="inherit"
            size="large"
          >
            <ClearAllIcon />
          </IconButton>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClick}
            disabled={lists.size < 2}
          >
            {i18n.get('merge')}
          </Button>
        </>
      )}
    </>
  )

  return (
    <Snackbar
      open={lists.size > 0}
      classes={{ root: contactsBarOpen ? classes.elevatedSnackbar : classes.snackbar }}
    >
      <SnackbarContent
        classes={{
          root: classes.snackbarContent,
          action: classes.action
        }}
        action={snackbarContent}
      />
    </Snackbar>
  )
}

SelectedDistributionListsBar.propTypes = {
  lists: PropTypes.object.isRequired,
  contactsBarOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
}
