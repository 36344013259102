import React from 'react'
import PropTypes from 'prop-types'
import GroupedNewsListItem from 'containers/media_reviews/media_review_detail/GroupedNewsListItem'

import SimpleSortableList from 'components/sortable/simple_sortable_list'
import { List } from '@mui/material'

const SortableItem = ({ item }) => <GroupedNewsListItem code={item} />

SortableItem.propTypes = {
  item: PropTypes.any.isRequired
}

export default function GroupedNewsList({ codes, sortingDisabled, onSortEnd }) {
  return (
    <List disablePadding>
      <SimpleSortableList
        items={codes}
        Component={SortableItem}
        onSortEnd={onSortEnd}
        disabled={sortingDisabled}
      />
    </List>
  )
}

GroupedNewsList.propTypes = {
  codes: PropTypes.object.isRequired,
  sortingDisabled: PropTypes.bool.isRequired,

  onSortEnd: PropTypes.func.isRequired
}
