import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJs } from '@fortawesome/free-brands-svg-icons/faJs'
import moment from 'moment-timezone'
import RssFeedIcon from '@mui/icons-material/RssFeed'
import PageviewIcon from '@mui/icons-material/Pageview'
import CodeIcon from '@mui/icons-material/Code'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { makeStyles } from 'tss-react/mui'
import { lightBlue, grey, deepOrange } from '@mui/material/colors'
import { CardActions, CardHeader, CardContent, Tooltip } from '@mui/material'

import Card from 'components/card'
import WithI18n from 'containers/WithI18n'
import WithColors from 'containers/WithColors'
import DummyCardIcon from 'components/dummy_card_icon'
import { IconButton } from 'containers/themed'

import masterConfig from 'static/master_config'

const useStyles = makeStyles()(theme => ({
  customTag: {
    position: 'relative'
  },
  actions: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: -5
  },
  spacer: {
    flex: 1
  },
  image: {
    minHeight: '100%'
  },
  media: {
    height: 200
  },
  previewMedia: {
    height: 25
  },
  sDocIcon: {
    position: 'absolute',
    left: -20,
    top: -20,
    padding: 25,
    color: '#ffffff',
    borderRadius: 60,
    boxShadow: '1px 1px rgba(0, 0, 0, 0.26)',
    '& > *': {
      position: 'relative',
      left: 5,
      top: 5,
      fontSize: '45pt !important'
    }
  },
  sDocMini: {
    position: 'absolute',
    color: '#ffffff',
    borderRadius: 60,
    boxShadow: '1px 1px rgba(0, 0, 0, 0.26)',
    padding: 6,
    left: -7,
    top: -10,
    '& > *': {
      position: 'relative',
      left: 5,
      top: 5,
      fontSize: '10pt !important'
    }
  },
  rss: {
    backgroundColor: deepOrange[500]
  },
  xml: {
    backgroundColor: lightBlue[900]
  },
  json: {
    backgroundColor: grey[700]
  },
  title: {
    fontSize: '15pt'
  },
  lastNews: {
    paddingTop: 0,
    fontSize: '12pt'
  },
  clickable: {
    cursor: 'pointer'
  },
  cardText: {
    margin: '-28px 0 -15px !important',
    minHeight: '6rem',
    color: theme.palette.grey[600]
  },
  iconButton: {
    [theme.breakpoints.only('sm')]: {
      padding: 8
    }
  }
}))

const iconConfig = {
  rss: <RssFeedIcon />,
  xml: <CodeIcon />,
  json: <FontAwesomeIcon icon={faJs} />
}

const Info = ({ i18n, customTag, preview, imageOnly, info }) => {
  const { classes } = useStyles()

  if (preview || imageOnly) {
    return null
  }

  const lastNewsCustomTag = customTag.get('lastNewsCustomTag')

  let lastNewsDate = i18n.get('no_news_assigned_yet')

  if (lastNewsCustomTag) {
    const date = moment(lastNewsCustomTag.get('createdAt')).format('L')
    const time = moment(lastNewsCustomTag.get('createdAt')).format('LTS')
    lastNewsDate = `${date} ${time}`
  }

  const name = customTag.get('name')

  if (!info) {
    return (
      <CardHeader
        classes={{ root: classes.title }}
        title={name}
      />
    )
  }

  return (
    <>
      <CardHeader
        classes={{ title: classes.title }}
        title={name}
        subheader={`${customTag.get('newsCount')} ${i18n.get('news')}`}
      />

      <CardContent classes={{ root: classes.cardText }}>
        {customTag.get('description')}
      </CardContent>

      <CardHeader
        classes={{ root: classes.lastNews, title: classes.lastNews }}
        title={i18n.get('last_news_assigned_at')}
        subheader={lastNewsDate}
      />
    </>
  )
}

const Actions = ({
  i18n,
  customTag,
  preview,
  imageOnly,
  actions,
  onEditClick,
  onFilterClick,
  onExportClick,
  onEmptyCustomTag,
  onDelete,
  onRunQueriesClick
}) => {
  const { classes } = useStyles()

  if (preview || imageOnly) {
    return null
  }

  if (actions) {
    return (
      <CardActions>
        <div className={classes.actions}>
          {actions}
        </div>
      </CardActions>
    )
  }

  let title
  let icon

  if (customTag.get('rssEnabled')) {
    title = i18n.get('export_as_rss')
    icon = iconConfig.rss
  } else if (customTag.get('xmlEnabled')) {
    title = i18n.get('export_as_xml')
    icon = iconConfig.xml
  } else if (customTag.get('jsonEnabled')) {
    title = i18n.get('export_as_json')
    icon = iconConfig.json
  } else {
    title = i18n.get('export_custom_tag')
    icon = <FileDownloadIcon />
  }

  return (
    <CardActions>
      <div className={classes.actions}>
        <Tooltip
          placement="top"
          title={title}
        >
          <div>
            <IconButton
              classes={{ root: classes.iconButton }}
              icon={icon}
              disabled={customTag.get('newsCount') === 0}
              onClick={() => onExportClick(customTag)}
              size="large"
            />
          </div>
        </Tooltip>
        <Tooltip
          placement="top"
          title={i18n.get('filter_by_custom_tag')}
        >
          <IconButton
            classes={{ root: classes.iconButton }}
            icon="remove_red_eye"
            onClick={() => onFilterClick(customTag)}
            size="large"
          />
        </Tooltip>
        {customTag.get('percolationEnabled') && !customTag.get('queries').isEmpty() && (
          <Tooltip
            placement="top"
            title={i18n.get('run_custom_tag_queries')}
          >
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={() => onRunQueriesClick(customTag)}
              size="large"
            >
              <PageviewIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          placement="top"
          title={i18n.get('edit')}
        >
          <IconButton
            classes={{ root: classes.iconButton }}
            icon="edit"
            onClick={() => onEditClick(customTag)}
            size="large"
          />
        </Tooltip>

        <span className={classes.spacer} />

        <Tooltip
          placement="top"
          title={i18n.get('delete')}
        >
          <IconButton
            classes={{ root: classes.iconButton }}
            icon="delete_forever"
            onClick={() => onDelete(customTag)}
            size="large"
          />
        </Tooltip>
        <Tooltip
          placement="top"
          title={i18n.get('empty_custom_tag')}
        >
          <div>
            <IconButton
              classes={{ root: classes.iconButton }}
              icon="delete_outline"
              onClick={() => onEmptyCustomTag(customTag)}
              disabled={customTag.get('newsCount') === 0}
              size="large"
            />
          </div>
        </Tooltip>
      </div>
    </CardActions>
  )
}

const Icon = ({ customTag, preview, imageOnly }) => {
  const { classes, cx } = useStyles()

  const classNameConfig = {
    rss: classes.rss,
    xml: classes.xml,
    json: classes.json
  }

  let sDocType = null

  if (customTag.get('rssEnabled')) {
    sDocType = 'rss'
  } else if (customTag.get('xmlEnabled')) {
    sDocType = 'xml'
  } else if (customTag.get('jsonEnabled')) {
    sDocType = 'json'
  }

  const icon = iconConfig[sDocType]

  if (!icon) {
    return null
  }

  const className = cx(
    classNameConfig[sDocType],
    (preview || imageOnly) ? classes.sDocMini : null
  )

  return (
    <div className={`${classes.sDocIcon} ${className}`}>
      {icon}
    </div>
  )
}

const Media = ({ customTag, preview, onFilterClick, i18n, imageOnly }) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(preview ? classes.previewMedia : classes.media, preview ? null : classes.clickable)}
      onClick={preview || imageOnly ? undefined : () => onFilterClick(customTag)}
      title={preview || imageOnly ? undefined : i18n.get('filter_by_custom_tag')}
    >
      <DummyCardIcon
        icon={masterConfig.getIn(['icons', 'customTags'])}
        image={customTag.get('photo')}
        preview={preview}
      />
    </div>
  )
}

const CustomTag = props => {
  const { classes } = useStyles()

  const { customTag, accentColor } = props

  const mediaProps = {
    aspectRatio: 'wide',
    image: customTag.get('photo')
  }

  if (!customTag.get('photo')) {
    mediaProps.style = {
      backgroundColor: accentColor
    }
  }

  return (
    <Card className={classes.customTag}>
      <Media {...props} />
      <Info {...props} />
      <Actions {...props} />
      <Icon {...props} />
    </Card>
  )
}

Info.propTypes = {
  i18n: PropTypes.object.isRequired,
  imageOnly: PropTypes.bool,
  info: PropTypes.bool,
  preview: PropTypes.bool,
  customTag: PropTypes.instanceOf(Map).isRequired
}

Actions.propTypes = {
  actions: PropTypes.any,
  customTag: PropTypes.instanceOf(Map).isRequired,
  i18n: PropTypes.object.isRequired,
  imageOnly: PropTypes.bool,
  preview: PropTypes.bool,

  onDelete: PropTypes.func.isRequired,
  onRunQueriesClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onEmptyCustomTag: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired
}

Icon.propTypes = {
  customTag: PropTypes.instanceOf(Map).isRequired,
  imageOnly: PropTypes.bool,
  preview: PropTypes.bool
}

Media.propTypes = {
  customTag: PropTypes.instanceOf(Map).isRequired,
  preview: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  imageOnly: PropTypes.bool
}

CustomTag.defaultProps = {
  preview: false,
  imageOnly: false,
  info: true
}

CustomTag.propTypes = {
  customTag: PropTypes.instanceOf(Map).isRequired,
  i18n: PropTypes.object.isRequired,
  accentColor: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  imageOnly: PropTypes.bool,
  info: PropTypes.bool,
  actions: PropTypes.any,

  onEditClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEmptyCustomTag: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  onExportClick: PropTypes.func.isRequired
}

export default WithI18n()(WithColors()(CustomTag))
