import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import AppBar from 'containers/AppBar'
import ContentContainer from 'components/content_container'
import EditDialog from 'containers/administration/mailing_lists/dialogs/EditDialog'
import RemoveDialog from 'containers/administration/mailing_lists/dialogs/RemoveDialog'
import { StaggeredListItem } from 'components/staggered_list'
import { FlexList, FlexListItem } from 'components/flex_list'
import NewItemCard from 'components/new_item_card'
import MailingList from 'containers/administration/mailing_lists/MailingList'

import WithI18n from 'containers/WithI18n'

const useStyles = makeStyles()({
  content: {
    paddingTop: 10
  },
  container: {
    marginTop: 30
  }
})

const MailingLists = ({
  i18n,
  mailingLists,
  onNewClick
}) => {
  const { classes } = useStyles()
  const renderNewCard = () => (
    <FlexListItem>
      <NewItemCard
        label={i18n.get('create_mailing_list')}
        onClick={() => onNewClick()}
      />
    </FlexListItem>
  )

  const renderContent = () => mailingLists.valueSeq().map((mailingList, listIndex) => (
    <FlexListItem key={listIndex}>
      <StaggeredListItem delay={listIndex * 50}>
        <MailingList mailingList={mailingList} />
      </StaggeredListItem>
    </FlexListItem>
  ))

  return (
    <div>
      <AppBar title={i18n.get('mailing_list')} />

      <ContentContainer className={classes.content}>
        <FlexList columns={5}>
          {renderNewCard()}
        </FlexList>
        <FlexList columns={5}>
          {renderContent()}
        </FlexList>
      </ContentContainer>

      <EditDialog />
      <RemoveDialog />
    </div>
  )
}

MailingLists.propTypes = {
  i18n: PropTypes.object.isRequired,
  mailingLists: PropTypes.object.isRequired,

  onNewClick: PropTypes.func.isRequired
}

export default WithI18n()(MailingLists)
