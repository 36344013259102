import { put, select, all, debounce, takeEvery, call, takeLatest } from 'redux-saga/effects'
import { fromJS } from 'immutable'

import { ChartDataSources } from 'static/constants'

import * as Actions from 'actions/analysis'
import * as AppActions from 'actions/app'
import * as FilterActions from 'actions/filter'
import * as NewsActions from 'actions/news'
import * as ChartsActions from 'actions/charts'
import * as DashboardActions from 'actions/dashboard'
import * as SavedSearchesActions from 'actions/saved_searches'
import * as Selectors from 'selectors'
import { fetchChartData as fetchChartDataSaga } from 'sagas/charts'
import { createSavedSearch } from 'sagas/saved_searches'
import { saveDashboard } from 'sagas/dashboard'

export function* applyAnalysisFilter() {
  yield put(NewsActions.newsRequestStart())
}

export function* reloadChart(metricsToLoad) {
  yield put(Actions.fetchChartDataStart({ metricsToLoad }))
}

export function* setAnalysisFilter({ payload: { field, value } }) {
  let newValue
  switch (field) {
    case 'selectedCodes': {
      newValue = yield select(Selectors.getStaticAnalysisCodesByIds, value)
      break
    }
    case 'restrictedCodes': {
      newValue = yield select(Selectors.getStaticAnalysisCodesByIds, value)
      break
    }
    case 'excludedCodes': {
      newValue = yield select(Selectors.getStaticAnalysisCodesByIds, value)
      break
    }
    case 'groupedCodes': {
      newValue = yield select(Selectors.getStaticAnalysisCodesByIds, value)
      break
    }
    default: {
      newValue = value
    }
  }

  yield put(FilterActions.setAnalysisFilter({ field, value: newValue }))
  yield put(Actions.applyAnalysisFilter())
}

export function* fetchChartData({ payload }) {
  yield put(Actions.fetchMetricsChartsData(payload))

  const chart = yield select(Selectors.getNewsAnalysisChart)
  const searchBody = yield select(Selectors.getSearchBody)

  const result = yield call(fetchChartDataSaga, chart, searchBody)

  yield put(Actions.fetchChartDataSuccess(result ? result[chart.get('firstLevel')] : null))
}

export function* fetchMetricsChartData(chart, index) {
  yield put(Actions.setMetricsChartLoading(index))
  const analysisChart = yield select(Selectors.getNewsAnalysisChart)
  const newChart = chart
    .set('secondLevel', analysisChart.get('secondLevel'))
    .update('opts', fromJS({}), opts => (
      chart.get('firstLevel') === 'mentionsCount' ? opts.set('targetBucket', analysisChart.get('firstLevel')) : opts
    ))

  const searchBody = yield select(Selectors.getSearchBody)
  const result = yield call(fetchChartDataSaga, newChart, searchBody)

  yield put(Actions.setMetricsChartData({
    data: result ? result[chart.get('firstLevel')] : null,
    index
  }))
}

export function* fetchMetricsChartsData({ payload }) {
  let charts = yield select(Selectors.getNewsAnalysisMetrics)

  if (payload && payload.metricsToLoad) {
    charts = charts.filter((chart, index) => payload.metricsToLoad.indexOf(index) !== -1)
  }

  const effects = []
  charts.forEach((chart, index) => {
    effects.push(call(fetchMetricsChartData, chart, index))
  })

  yield all(effects)
}

export function* setChartFields({ payload }) {
  yield call(reloadChart, (!payload || !payload.type) ? undefined : [])
}

export function* setChartOpt() {
  yield call(setChartFields, {})
}

export function* toggleUseMediaReviewCodes() {
  const value = yield select(Selectors.getNewsFiltersAnalysisUseMediaReviewCodes)

  yield put(FilterActions.resetAnalysisCodesFilter())

  yield put(FilterActions.setAnalysisFilter({
    field: 'useMediaReviewCodes',
    value: !value
  }))

  yield call(applyAnalysisFilter)
}

export function* drilldown({ payload }) {
  const chart = yield select(Selectors.getNewsAnalysisChart)

  const newChart = chart
    .update('firstLevel', l => (l === 'analysisCodes' ? 'statementCodes' : l))
    .update('secondLevel', l => (l === 'analysisCodes' ? 'groupedStatementCodes' : l))
    .update('firstLevel', l => (l === 'statementTonalities' ? 'newsStatementTonalities' : l))
    .update('secondLevel', l => (l === 'statementTonalities' ? 'newsStatementTonalities' : l))

  yield put(ChartsActions.drilldown({ ...payload, chart: newChart }))
}

export function* toggleStatementTonality({ payload }) {
  yield put(FilterActions.toggleStatementTonality(payload))
  yield put(Actions.applyAnalysisFilter())
}

export function* pushChartToDashboard({ payload: { savedSearchId, name, dashboardId } }) {
  try {
    const dashboard = yield select(Selectors.getDashboardById, dashboardId)

    if (dashboard && (name || savedSearchId)) {
      yield put(DashboardActions.setSelectedDashboard(dashboard))

      let savedSearch

      if (!savedSearchId) {
        savedSearch = fromJS({
          nameDe: name,
          nameEn: name,
          nameFr: name,
          nameRu: name,
          nameZh: name,
          nameJa: name
        })
        savedSearch = yield call(createSavedSearch, savedSearch)

        yield put(SavedSearchesActions.saveSavedSearchSuccess(savedSearch))

        savedSearch = fromJS(savedSearch)
      } else {
        savedSearch = yield select(Selectors.getSavedSearchById, savedSearchId)
      }

      const dateRange = yield select(Selectors.getSelectedDateRange)
      const dateFrom = yield select(Selectors.getSelectedDateFrom)
      const dateTo = yield select(Selectors.getSelectedDateTo)
      const dateInterval = yield select(Selectors.getSelectedDateInterval)
      const dateType = yield select(Selectors.getSelectedDateType)

      let chart = yield select(Selectors.getNewsAnalysisChart)
      chart = chart.merge(fromJS({
        savedSearch,
        savedSearchId: savedSearch.get('id'),
        dateRange,
        dateFrom: dateRange ? null : dateFrom,
        dateTo: dateRange ? null : dateTo,
        dateInterval,
        dateType,
        dataSource: ChartDataSources.PRESSRELATIONS_NEWS
      }))

      const breakpoint = yield select(Selectors.getBreakpoint)

      yield put(DashboardActions.setChart({
        chart,
        index: null,
        breakpoint
      }))

      yield call(saveDashboard, DashboardActions.saveDashboardStart({
        toggleEditing: false
      }))

      yield put(Actions.hidePushChartToDashboardDialog())
    }

    yield put(Actions.pushChartToDashboardSuccess())
  } catch (error) {
    yield put(Actions.pushChartToDashboardError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* watchSetAnalysisFilter() {
  yield takeEvery(Actions.setAnalysisFilter, setAnalysisFilter)
}

export function* watchApplyAnalysisFilter() {
  yield debounce(1000, Actions.applyAnalysisFilter, applyAnalysisFilter)
}

export function* watchFetchChartData() {
  yield takeLatest(Actions.fetchChartDataStart, fetchChartData)
}

export function* watchFetchMetricsChartsData() {
  yield takeLatest(Actions.fetchMetricsChartsData, fetchMetricsChartsData)
}

export function* watchSetChartFields() {
  yield takeEvery(Actions.setChartFields, setChartFields)
}

export function* watchSetChartOpt() {
  yield takeEvery(Actions.setChartOpt, setChartOpt)
}

export function* watchToggleUseMediaReviewCodes() {
  yield takeEvery(Actions.toggleUseMediaReviewCodes, toggleUseMediaReviewCodes)
}

export function* watchToggleStatementTonality() {
  yield takeEvery(Actions.toggleStatementTonality, toggleStatementTonality)
}

export function* watchPushChartToDashboard() {
  yield takeEvery(Actions.pushChartToDashboardStart, pushChartToDashboard)
}

export function* watchDrilldown() {
  yield takeEvery(Actions.drilldown, drilldown)
}

export default function* saga() {
  yield all([
    watchSetAnalysisFilter(),
    watchApplyAnalysisFilter(),
    watchFetchChartData(),
    watchFetchMetricsChartsData(),
    watchSetChartFields(),
    watchSetChartOpt(),
    watchToggleUseMediaReviewCodes(),
    watchToggleStatementTonality(),
    watchPushChartToDashboard(),
    watchDrilldown()
  ])
}
