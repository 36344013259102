import { connect } from 'react-redux'

import Dialog from 'components/anewstip/results/dialog'

import {
  getShowDrillDownDialog,
  getDrillDownTab,
  getDrillDownEntity,
  getJournalistsDrillDownRequestRunning,
  getOutletsDrillDownRequestRunning,
  getArticlesDrillDownRequestRunning,
  getAnewstipDrillDownData,
  getAnewstipDrillDownTopics,
  getAnewstipImportResult,
  getAnewstipImportRequestRunning,
  getAnewstipDrillDownHistory,
  getIsLimitedFunctionality,
  getCDeskIsDistributionListsLocked
} from 'selectors'

import {
  closeDrillDownDialog as onClose,
  switchDrillDownTab as switchTab,
  toggleDrillDownTopic as toggleTopic,
  searchDrillDownJournalistsStart as onDrillDownJournalists,
  searchDrillDownOutletsStart as onDrillDownOutlets,
  importStart as onAddClick,
  addJournalistsToDistributionList as onAddToDistributionListClick,
  drillDownHistorySearch as onDrillDownHistory
} from 'actions/anewstip'

const mapStateToProps = state => ({
  data: getAnewstipDrillDownData(state),
  open: getShowDrillDownDialog(state),
  activeTab: getDrillDownTab(state),
  drillDownEntity: getDrillDownEntity(state),
  journalistsDrillDownRequestRunning: getJournalistsDrillDownRequestRunning(state),
  outletsDrillDownRequestRunning: getOutletsDrillDownRequestRunning(state),
  articlesDrillDownRequestRunning: getArticlesDrillDownRequestRunning(state),
  topics: getAnewstipDrillDownTopics(state),
  importResult: getAnewstipImportResult(state),
  importRequestRunning: getAnewstipImportRequestRunning(state),
  drillDownHistory: getAnewstipDrillDownHistory(state),
  isLimitedFunctionality: getIsLimitedFunctionality(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(
  mapStateToProps,
  {
    onClose,
    switchTab,
    toggleTopic,
    onDrillDownJournalists,
    onDrillDownOutlets,
    onAddClick,
    onAddToDistributionListClick,
    onDrillDownHistory
  }
)(Dialog)
