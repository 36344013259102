import { connect } from 'react-redux'

import AvgHackishnessTimelineChart from 'components/darknet/static_charts/avg_hackishness_timeline_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'
import { chartDrilldown } from 'actions/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.HACKISHNESS_TIMELINE)
})

export default connect(mapStateToProps, {
  drilldown: chartDrilldown
})(AvgHackishnessTimelineChart)
