import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import {
  Box,
  Chip,
  MenuItem
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import TripOriginRoundedIcon from '@mui/icons-material/TripOriginRounded'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import WatchLaterRoundedIcon from '@mui/icons-material/WatchLaterRounded'
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle'
import LanguageIcon from '@mui/icons-material/Language'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

import { indigo, green, purple, grey, orange, red } from '@mui/material/colors'

import { Button, Menu } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  contentActions: {
    display: 'flex',
    justifyContent: 'center'
  },
  moreActionsButton: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: !theme.isDark && '#404040'
  }
}))

const statusColor = status => {
  switch (status) {
    case 'draft':
      return grey[500]
    case 'review_pending':
      return orange[500]
    case 'revisions_needed':
      return purple[500]
    case 'approved':
      return green[500]
    default:
      return grey[300]
  }
}
const renderStatusIcon = (status, color) => {
  switch (status) {
    case 'draft':
      return <TripOriginRoundedIcon style={{ color: color || grey[500] }} />
    case 'review_pending':
      return <WatchLaterRoundedIcon style={{ color: color || orange[500] }} />
    case 'revisions_needed':
      return <SwapVerticalCircleIcon style={{ color: color || purple[500] }} />
    case 'approved':
      return <CheckCircleIcon style={{ color: color || green[500] }} />
    default:
      return <HelpOutlineOutlinedIcon />
  }
}
const otsStatusColor = status => {
  switch (status) {
    case 'draft':
      return grey[500]
    case 'review':
      return orange[500]
    case 'declined':
      return red[700]
    case 'approved':
      return green[500]
    case 'distributed':
      return indigo[500]
    default:
      return grey[300]
  }
}
const renderOtsStatusIcon = (status, color) => {
  switch (status) {
    case 'draft':
      return <TripOriginRoundedIcon style={{ color: color || grey[500] }} />
    case 'review':
      return <WatchLaterRoundedIcon style={{ color: color || orange[500] }} />
    case 'declined':
      return <RemoveCircleIcon style={{ color: color || red[700] }} />
    case 'approved':
      return <CheckCircleIcon style={{ color: color || green[500] }} />
    case 'distributed':
      return <LanguageIcon style={{ color: color || indigo[500] }} />
    default:
      return <HelpOutlineOutlinedIcon />
  }
}

export default function ContentApprovalStatusSelect({
  buttonClass,
  content,
  mode,
  onChange
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()
  const campaignId = content.getIn(['ccdCampaign', 'id'])
  const status = content.get('approvalStatus')
  const otsStatus = content.getIn(['otsPressRelease', 'status'])
  const theme = useTheme()

  const handleStatusChange = value => {
    onChange({
      id: content.get('id'),
      title: content.get('title'),
      plannedFor: content.get('plannedFor'),
      scheduledFor: content.get('scheduledFor'),
      scheduledForJobName: content.get('scheduledJobName'),
      scheduledEventsJobName: content.get('scheduledEventsJobName'),
      approvalStatus: value,
      ccdCampaignId: campaignId
    })
  }

  const label = (
    <div className={classes.chipLabel}>
      {device.get('mobile') ? null : i18n.get(status)}
      <ArrowDropDownIcon />
    </div>
  )

  const handleComp = mode === 'chip' ? (
    <Chip
      icon={renderStatusIcon(status, 'white')}
      label={label}
      style={{ backgroundColor: statusColor(status), color: 'white' }}
    />
  ) : (
    <Button
      variant="outlined"
      startIcon={renderStatusIcon(status, theme.palette.primary.main)}
      className={buttonClass}
    >{i18n.get('status')}: {i18n.get(status)}
    </Button>
  )

  const renderStatusChip = () => (
    <div className={classes.statusWrapper}>
      <Menu
        handle={handleComp}
      >
        <MenuItem onClick={() => handleStatusChange('draft')}>
          <TripOriginRoundedIcon style={{ color: grey[500], marginRight: '0.3em' }} />
          {i18n.get('draft')}
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange('review_pending')}>
          <WatchLaterRoundedIcon style={{ color: orange[500], marginRight: '0.3em' }} />
          {i18n.get('review_pending')}
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange('revisions_needed')}>
          <SwapVerticalCircleIcon style={{ color: purple[500], marginRight: '0.3em' }} />
          {i18n.get('revisions_needed')}
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange('approved')}>
          <CheckCircleIcon style={{ color: green[500], marginRight: '0.3em' }} />
          {i18n.get('approved')}
        </MenuItem>
      </Menu>
    </div>
  )

  const renderOtsStatusChip = () => {
    const otsLabel = device.get('mobile') ? null : i18n.get(`ots_${otsStatus.toLowerCase()}`)

    return (
      <Chip
        icon={renderOtsStatusIcon(otsStatus.toLowerCase(), 'white')}
        label={otsLabel}
        style={{ backgroundColor: otsStatusColor(otsStatus.toLowerCase()), color: 'white' }}
      />
    )
  }

  return (
    <Box>
      {otsStatus ? renderOtsStatusChip() : renderStatusChip()}
    </Box>
  )
}

ContentApprovalStatusSelect.defaultProps = {
  buttonClass: null,
  mode: 'chip'
}

ContentApprovalStatusSelect.propTypes = {
  buttonClass: PropTypes.string,
  content: PropTypes.instanceOf(ImmutableMap).isRequired,
  mode: PropTypes.oneOf(['chip', 'button']),
  onChange: PropTypes.func.isRequired
}
