import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_dashboard'
import * as UserActions from 'actions/user'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  id: null,
  timezone: 'Europe/Berlin',
  locale: 'en',
  urlName: '',
  baseUrl: '',
  saving: false,
  autoReload: false,
  darkMode: false,
  autoScroll: false,
  hideShareMenu: false,
  hideFooter: false,
  checkingUrlName: false,
  urlNameAvailable: true,
  showDialog: false,
  fetchRequestRunning: false,
  disableCookies: false,
  searchProfileGroupId: null,
  headerLogoLeftUrl: null,
  headerLogoLeftLink: null,
  headerLogoRightUrl: null,
  headerLogoRightLink: null,
  headerTitle: null,
  headerSubtitle: null,
  headerBackgroundColor: null,
  uploadedHeaderLogoLeft: null,
  uploadedHeaderLogoRight: null
})

export default handleActions({
  [Actions.setSavedDashboardValue]: (state, { payload: { key, value } }) => state.set(key, value),

  [Actions.saveSavedDashboardStart]: state => state.set('saving', true),
  [Actions.saveSavedDashboardSuccess]: (state, { payload }) => state.set('saving', false).merge(fromJS(payload)),
  [Actions.saveSavedDashboardError]: state => state.set('saving', false),

  [Actions.deleteSavedDashboardStart]: state => state.set('saving', true),
  [Actions.deleteSavedDashboardSuccess]: state => initialState.merge({
    locale: state.get('locale'),
    showDialog: state.get('showDialog')
  }),
  [Actions.deleteSavedDashboardError]: state => state.set('saving', false),

  [Actions.fetchSavedDashboardStart]: state => state.set('fetchRequestRunning', true),
  [Actions.fetchSavedDashboardSuccess]: (state, { payload: { _data, ...payload } }) => (
    state.merge(fromJS(payload)).set('fetchRequestRunning', false)
  ),
  [Actions.fetchSavedDashboardError]: state => state.set('fetchRequestRunning', false),

  [Actions.fetchSavedDashboardInfoSuccess]: (state, { payload }) => state.merge(fromJS(payload)),

  [Actions.checkUrlNameStart]: state => state.set('checkingUrlName', true),
  [Actions.checkUrlNameSuccess]: (state, { payload: { available } }) => state.merge({
    checkingUrlName: false,
    urlNameAvailable: available
  }),
  [Actions.checkUrlNameError]: state => state.set('checkingUrlName', false),

  [Actions.showSavedDashboardDialog]: state => state.set('showDialog', true),
  [Actions.hideSavedDashboardDialog]: state => state.set('showDialog', false),

  [Actions.startAutoReload]: state => state.set('autoReload', true),

  [UserActions.setUser]: (state, { payload: { languageShort } }) => state.set('locale', languageShort),

  [AppActions.resetState]: () => initialState
}, initialState)
