export const getApp = state => state.app
export const getLoginMsg = state => getApp(state).get('loginMsg')
export const getLoginError = state => getApp(state).get('loginError')
export const getLoginRunning = state => getApp(state).get('loginRunning')
export const appIsReady = state => getApp(state).get('appIsReady')
export const getNewsradarId = state => getApp(state).get('activeNewsradarId')
export const getAppBarMessage = state => getApp(state).get('appBarMessage')
export const getAppMessage = state => getApp(state).get('appMessage')
export const getShowUndo = state => getApp(state).get('showUndo')
export const getAppLoading = state => getApp(state).get('loading')
export const getTimezone = state => getApp(state).get('timezone')
export const getBffVersion = state => getApp(state).get('bffVersion')
export const getForceReload = state => getApp(state).get('forceReload')
export const getViewVersion = state => getApp(state).get('viewVersion')
export const getGenericSuccess = state => getApp(state).get('success')
export const getShowAppMessage = state => getApp(state).get('showAppMessage')
export const getAppGeneratedFile = state => getApp(state).get('generatedFile')
export const getSnackbarMessageInfo = state => getApp(state).get('appSnackbarMessage')
