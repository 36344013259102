import { connect } from 'react-redux'

import NewsPageAppBar from 'components/news_page_app_bar'
import { toggleDateForm, toggleAdvancedSearch } from 'actions/news'
import {
  getNewsUi,
  getDevice,
  getI18n,
  getViewConfigAppBarTitleKey
} from 'selectors'

const mapStateToProps = state => ({
  showDateForm: getNewsUi(state).get('showDateForm'),
  showAdvancedSearch: getNewsUi(state).get('showAdvancedSearch'),
  i18n: getI18n(state),
  device: getDevice(state),
  titleKey: getViewConfigAppBarTitleKey(state)
})

export default connect(
  mapStateToProps,
  {
    toggleAdvancedSearch,
    toggleDateForm
  }
)(NewsPageAppBar)
