import { connect } from 'react-redux'

import TagsDialog from 'components/contact_management/tags_dialog'

import { getCmTagsDialogOpen, getCmSelectedTagType } from 'selectors/contact_management'

import { closeTagsDialog as onClose } from 'actions/contact_management'

const mapStateToProps = state => ({
  open: getCmTagsDialogOpen(state),
  type: getCmSelectedTagType(state) || ''
})

export default connect(mapStateToProps, {
  onClose
})(TagsDialog)
