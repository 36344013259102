import * as Api from 'utils/api'
import { v4 } from 'uuid'

const noCache = v4()

export default domain => {
  if (domain === 'cn.pressrelations.de') {
    return Api.camelize(
      {
        login_links: {
          terms_and_conditions: 'https://www.pressrelations.com/de/agb',
          legal_notice: 'https://www.pressrelations.com/de/impressum',
          privacy: 'https://www.pressrelations.com/de/datenschutz',
          homepage: {
            name: 'pressrelations',
            link: 'https://www.pressrelations.com/de/'
          }
        },
        bff_url: {
          production: 'https://china.pressrelations.de/newsradar-bff'
        }
      }
    )
  }

  return Api.doJsonRequest(
    'GET',
    `https://customers.pressrelations.de/nrx_domains/${domain}/config.json?n=${noCache}`,
    Api.jsonHeaders,
    {},
    false
  ).then(Api.camelize)
}
