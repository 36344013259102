export const buildSort = (sortBy, sortDirection) => {
  if (sortDirection === 'asc') {
    return [sortBy]
  }

  return [`-${sortBy}`]
}

export const buildFilter = (filterKey, allFilters, facetFilters) => {
  let finalFilter = []

  const filter = allFilters.get(filterKey)

  const facetFilter = facetFilters.get(filterKey)

  if (filter) {
    finalFilter.push(filter)
  }

  if (facetFilter && facetFilter.toJS().length > 0) {
    finalFilter = finalFilter.concat(facetFilter.toJS())
  }

  return finalFilter.filter((value, index, array) => array.indexOf(value) === index)
}
