import { connect } from 'react-redux'

import ContentView from 'components/content_desk/contents/content_view'

import {
  resetEvents,
  closeViewContentDialog as onClose,
  lockContentStart as onEditClick
} from 'actions/content_desk'
import {
  getCDeskShowViewContentDialog,
  getCDeskStatistics,
  getCDeskSelectedContent,
  getCDeskStatisticsRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowViewContentDialog(state),
  content: getCDeskSelectedContent(state),
  statisticsData: getCDeskStatistics(state),
  requestRunning: getCDeskStatisticsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onClose,
    resetEvents,
    onEditClick
  }
)(ContentView)
