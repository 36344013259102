// UI
export const getNotificationsUi = state => state.notifications.get('ui')
export const getNotificationsDrawerOpen = state => getNotificationsUi(state).get('isDrawerOpen')
export const getIsReadRequestRunning = state => getNotificationsUi(state).get('readRequestRunning')
export const getIsFetchRequestRunning = state => getNotificationsUi(state).get('fetchRequestRunning')
export const getSelectedIds = state => getNotificationsUi(state).get('selectedIds')

// DATA
export const getNotificationsData = state => state.notifications.get('data')
export const getNotifications = state => getNotificationsData(state).get('notifications')

// FILTER
export const getNotificationsFilter = state => state.notifications.get('filter')
export const getNotificationsLastPage = state => getNotificationsFilter(state).get('lastPage')
