import { connect } from 'react-redux'

import DateForm from 'components/date_form'
import { uiApplyDateFilter, uiChooseQuickSelect } from 'actions/darknet'
import { changeDateType } from 'actions/filter'
import { toggleDateForm } from 'actions/news'
import {
  getSelectedDateFrom,
  getSelectedDateTo,
  getSelectedDateRange,
  getSelectedDateType,
  getLocale,
  getI18n,
  getDevice,
  getAllowedDateTypes,
  getViewConfigHasDateRestriction,
  getNewsPoolTimeLimit,
  getNewsPoolTimeUnit,
  getNewsUi
} from 'selectors'

const mapStateToProps = state => ({
  active: getNewsUi(state).get('showDateForm'),
  dateFrom: getSelectedDateFrom(state),
  dateTo: getSelectedDateTo(state),
  dateRange: getSelectedDateRange(state),
  dateType: getSelectedDateType(state),
  locale: getLocale(state),
  i18n: getI18n(state),
  device: getDevice(state),
  allowedDateTypes: getAllowedDateTypes(state),
  hasDateRestriction: getViewConfigHasDateRestriction(state),
  timeLimit: getNewsPoolTimeLimit(state),
  timeUnit: getNewsPoolTimeUnit(state),
  hasTimelineFilter: false,
  secondary: true
})

export default connect(
  mapStateToProps,
  {
    uiApplyDateFilter,
    uiChooseQuickSelect,
    onChangeDateType: changeDateType,
    onClose: toggleDateForm
  }
)(DateForm)
