import { fromJS } from 'immutable'

export const getNewsData = state => state.news.get('data')
export const getNewsById = (state, id) => getNewsData(state).getIn(['news', id.toString()])
export const getClusterNewsIdsById = (state, id) => {
  const n = getNewsById(state, id)

  return (n.get('clusteredNews') || fromJS([])).map(cn => cn.get('id')).unshift(n.get('id'))
}
export const getNewsByIds = (state, ids) => ids.map(id => getNewsById(state, id)).filter(n => n)
