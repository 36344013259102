import { connect } from 'react-redux'

import StaticCharts from 'components/darknet/static_charts'

import { getDarknetResults, getBreakpoint } from 'selectors'

const mapStateToProps = state => ({
  noHits: getDarknetResults(state).isEmpty(),
  breakpoint: getBreakpoint(state)
})

export default connect(
  mapStateToProps,
  {}
)(StaticCharts)
