import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/charts'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  aggregationValue: 'buzz'
})

export default handleActions({
  [Actions.setAggregationValue]: (state, { payload: value }) => state.set('aggregationValue', value),
  [AppActions.resetState]: () => initialState
}, initialState)
