import { connect } from 'react-redux'

import Result from 'components/anewstip/results/result'

import {
  getAnewstipFiltersData,
  getAnewstipSelectedData,
  getAnewstipImportResult,
  getAnewstipImportRequestRunning,
  getJournalistsSearchRequestRunning,
  getOutletsSearchRequestRunning,
  getArticlesSearchRequestRunning,
  getIsLimitedFunctionality,
  getCDeskIsDistributionListsLocked
} from 'selectors'

import {
  setFiltersForm as onFilterChange,
  searchJournalistsStart as onSearchJournalists,
  searchOutletsStart as onSearchOutlets,
  searchArticlesStart as onSearchArticles,
  setSelectedResults,
  importStart as onAddClick,
  addJournalistsToDistributionList as onAddToDistributionListClick
} from 'actions/anewstip'

const mapStateToProps = (state, ownProps) => ({
  resultsTab: ownProps.resultsTab,
  results: ownProps.results,
  importResult: getAnewstipImportResult(state),
  filtersFormData: getAnewstipFiltersData(state),
  selectedResults: getAnewstipSelectedData(state),
  journalistsSearchRequestRunning: getJournalistsSearchRequestRunning(state),
  outletsSearchRequestRunning: getOutletsSearchRequestRunning(state),
  articlesSearchRequestRunning: getArticlesSearchRequestRunning(state),
  importRequestRunning: getAnewstipImportRequestRunning(state),
  isLimitedFunctionality: getIsLimitedFunctionality(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(
  mapStateToProps,
  {
    onFilterChange,
    onSearchJournalists,
    onSearchOutlets,
    onSearchArticles,
    setSelectedResults,
    onAddClick,
    onAddToDistributionListClick
  }
)(Result)
