import { connect } from 'react-redux'

import OtsWelcomeDialog from 'components/content_desk_new/ots_welcome_dialog/'

import { getCDeskShowOtsWelcomeDialog } from 'selectors'

import {
  closeOtsWelcomeDialog,
  openNewContent as openContent
} from 'actions/content_desk'

const mapStateToProps = state => ({
  open: getCDeskShowOtsWelcomeDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeOtsWelcomeDialog,
    openContent
  }
)(OtsWelcomeDialog)
