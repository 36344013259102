import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_searches'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  loading: false,
  dispatchesLoading: false,
  resolving: false,
  initialSearchLoaded: false,
  viewType: 'list',
  tab: 'saved_searches'
})

export default handleActions({
  [Actions.fetchSavedSearchesTimelinesStart]: state => state.set('loading', true),
  [Actions.fetchSavedSearchesTimelinesError]: state => state.set('loading', false),
  [Actions.fetchSavedSearchesTimelinesSuccess]: state => state.set('loading', false),

  [Actions.fetchSavedSearchDispatchesStart]: state => state.set('dispatchesLoading', true),
  [Actions.fetchSavedSearchDispatchesError]: state => state.set('dispatchesLoading', false),
  [Actions.fetchSavedSearchDispatchesSuccess]: state => state.set('dispatchesLoading', false),

  [Actions.resolveSavedSearchStart]: state => state.set('resolving', true),
  [Actions.resolveSavedSearchError]: state => state.set('resolving', false),
  [Actions.resolveSavedSearchSuccess]: state => state.set('resolving', false),

  [Actions.setInitialSearchLoaded]: (state, { payload }) => state.set('initialSearchLoaded', payload),

  [Actions.setViewType]: (state, { payload }) => state.set('viewType', payload),

  [Actions.setTab]: (state, { payload }) => state.set('tab', payload),

  [AppActions.resetState]: () => initialState
}, initialState)
