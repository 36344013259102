/* global window */
import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'
import { parse } from 'query-string-es5'
import { CookieNames } from 'static/constants'

import { readCookie } from 'utils/cookieHelper'

import * as Actions from 'actions/app'

/* istanbul ignore next */
const timezone = moment.tz.guess() || 'Europe/Berlin'

moment.tz.setDefault(timezone)

const nid = parseInt(parse(window.location.hash.split('?')[1] || '').nid, 10)

export const initialState = fromJS({
  bffVersion: null,
  viewVersion: null,
  forceReload: false,
  activeNewsradarId: nid || parseInt(readCookie(CookieNames.NEWSRADAR), 10) || null,
  appIsReady: false,
  loginMsg: '',
  loginError: false,
  snackbarText: '',
  showNavigation: false,
  showUndo: false,
  loading: false,
  loginRunning: false,
  appBarMessage: null,
  appMessage: null,
  success: false,
  showAppMessage: false,
  appSnackbarMessage: {
    show: false,
    variant: 'info',
    text: '',
    preventDuplicate: false
  },
  generatedFile: null,
  timezone
})

export default handleActions({
  [combineActions(Actions.loginRequestStart, Actions.ssoRequestStart)]: state => (
    state.merge({ loginMsg: '', loginError: false, loginRunning: true })
  ),

  [Actions.loginRequestSuccess]: state => state.merge({ loginMsg: '', loginError: false, loginRunning: false }),

  [combineActions(Actions.loginRequestError, Actions.ssoRequestError)]: (state, { payload: msg }) => (
    state.merge({ loginMsg: msg, loginError: true, loginRunning: false })
  ),

  [Actions.loginRequestAborted]: (state, { payload: msg }) => state.merge({ loginMsg: msg, loginError: true, loginRunning: false }),

  [Actions.setLoginMessage]: (state, { payload: { loginMsg, loginError } }) => state
    .set('loginMsg', loginMsg)
    .set('loginError', loginError),
  [Actions.logoutRequestSuccess]: state => initialState.set('activeNewsradarId', state.get('activeNewsradarId')),
  [Actions.setActiveNewsradar]: (state, { payload: newsradarId }) => state.set('activeNewsradarId', newsradarId),
  [Actions.setAppBarMessage]: (state, { payload: message }) => state.set('appBarMessage', message),
  [Actions.setAppMessage]: (state, { payload: message }) => state.set('appMessage', message),
  [Actions.setAppReady]: state => state.set('appIsReady', true),
  [Actions.setAppBusy]: state => state.set('appIsReady', false),
  [Actions.toggleNavigation]: state => state.set('showNavigation', !state.get('showNavigation')),
  [Actions.showUndo]: state => state.set('showUndo', true),
  [Actions.hideUndo]: state => state.set('showUndo', false),
  [Actions.toggleLoading]: (state, { payload }) => {
    if (payload === undefined) {
      return state.update('loading', loading => !loading)
    }

    return state.set('loading', payload)
  },
  [Actions.setBffVersion]: (state, { payload: bffVersion }) => state.set('bffVersion', bffVersion),
  [Actions.setViewVersion]: (state, { payload: viewVersion }) => state.set('viewVersion', viewVersion),
  [Actions.forceReload]: state => state.set('forceReload', true),
  [Actions.toggleSuccess]: (state, { payload }) => state.update('success', success => (payload !== undefined ? payload : !success)),
  [Actions.toggleAppMessage]: (state, { payload }) => (
    state.update('showAppMessage', success => (payload !== undefined ? payload : !success))
  ),
  [Actions.triggerAppSnackbarMessage]: (state, { payload: { text, variant, preventDuplicate } }) => (
    state.set('appSnackbarMessage', fromJS({ text, variant, preventDuplicate, show: true }))
  ),
  [Actions.hideAppSnackbarMessage]: state => (
    state.set('appSnackbarMessage', initialState.get('appSnackbarMessage'))
  ),

  [Actions.uploadGeneratedFileStart]: state => state.set('generatedFile', null),
  [Actions.uploadGeneratedFileError]: state => state.set('generatedFile', null),
  [Actions.uploadGeneratedFileSuccess]: (state, { payload }) => (
    state.set('generatedFile', fromJS(payload).update('url', url => `files${url.split('files-api')[1]}/${payload.filename}`))
  ),

  [Actions.resetGeneratedFile]: state => state.set('generatedFile', null),

  [Actions.resetState]: state => state.set('showNavigation', false)
}, initialState)
