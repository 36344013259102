import { createImmutableSelector } from 'utils/reselect'
import { getViewConfigModuleName } from './news/view_config'

// DATA
export const getSavedSearchesData = state => state.savedSearches.get('data')
export const getAllSavedSearches = state => getSavedSearchesData(state).get('sorted')
export const getSavedSearchById = (state, id) => getSavedSearchesData(state).getIn(['entities', id])
export const getSavedSearchesDataSorted = state => getSavedSearchesData(state).get('sorted')

export const getSavedSearchesByModule = (state, moduleName) => (
  getSavedSearchesData(state).get('sorted').filter(s => s.get('moduleName') === moduleName)
)

export const getSavedSearches = createImmutableSelector(
  getViewConfigModuleName,
  getSavedSearchesDataSorted,
  (moduleName, savedSearches) => savedSearches.filter(s => s.get('moduleName') === moduleName)
)

// UI
export const getSavedSearchesUi = state => state.savedSearches.get('ui')
export const getSavedSearchesLoading = state => getSavedSearchesUi(state).get('loading')
export const getInitialSearchLoaded = state => getSavedSearchesUi(state).get('initialSearchLoaded')
export const getSavedSearchDispatchesLoading = state => getSavedSearchesUi(state).get('dispatchesLoading')
export const getSavedSearchResolving = state => getSavedSearchesUi(state).get('resolving')
export const getSavedSearchViewType = state => getSavedSearchesUi(state).get('viewType')
export const getSavedSearchesTab = state => getSavedSearchesUi(state).get('tab')

// DIALOGS
export const getSavedSearchesDialogs = state => state.savedSearches.get('dialogs')

// DATA
export const getSelectedSavedSearch = state => getSavedSearchesDialogs(state).get('data').get('selectedSavedSearch')
export const getExecutedSavedSearch = state => getSavedSearchesDialogs(state).get('data').get('executedSavedSearch')

// UI
export const getSavedSearchesDialogsUi = state => getSavedSearchesDialogs(state).get('ui')
export const getShowRemoveSavedSearchDialog = state => getSavedSearchesDialogsUi(state).get('removeActive')
export const getShowAffectedDashboardsDialog = state => getSavedSearchesDialogsUi(state).get('affectedDashboardsActive')
export const getShowUpdateSavedSearchDialog = state => getSavedSearchesDialogsUi(state).get('updateActive')
export const getShowEditSavedSearchDialog = state => getSavedSearchesDialogsUi(state).get('editActive')
export const getSavedSearchesUploadedPhoto = state => getSavedSearchesDialogsUi(state).get('uploadedPhoto')
export const getShowSavedSearchDispatchesDialog = state => getSavedSearchesDialogsUi(state).get('dispatchesActive')
export const getAffectedDashboards = state => getSavedSearchesDialogsUi(state).get('affectedDashboards')

// TIMELINES
export const getSavedSearchesTimelines = state => state.savedSearches.get('timelines')

// DISPATCHES
export const getSavedSearchDispatches = state => state.savedSearches.get('dispatches')

// FEEDS
export const getSavedSearchesFeedsData = state => state.savedSearches.getIn(['feeds', 'data'])
export const getSavedSearchesFeedsDataSorted = state => state.savedSearches.getIn(['feeds', 'data', 'sorted'])

export const getSavedSearchesFeeds = createImmutableSelector(
  getViewConfigModuleName,
  getSavedSearchesFeedsDataSorted,
  getSavedSearchesData,
  (moduleName, feeds, savedSearches) => feeds
    .map(feed => feed.set('savedSearch', savedSearches.getIn(['entities', feed.get('savedSearchId')])))
    .filter(feed => feed.getIn(['savedSearch', 'moduleName']) === moduleName)
)

export const getSavedSearchesFeedsTotalCount = state => getSavedSearchesFeedsData(state).get('entities').size
export const getSavedSearchesFeedsUi = state => state.savedSearches.getIn(['feeds', 'ui'])
export const getSavedSearchesFeedsUiExpandedId = state => getSavedSearchesFeedsUi(state).get('expandedId')
export const getSavedSearchesFeedsUiSaving = state => getSavedSearchesFeedsUi(state).get('saving')
export const getSavedSearchesFeedsUiDeleting = state => getSavedSearchesFeedsUi(state).get('deleting')
export const getSavedSearchesFeedsUiCreating = state => getSavedSearchesFeedsUi(state).get('creating')
