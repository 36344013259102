import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setPrefersDarkMode } from 'actions/user'

import { useMediaQuery } from '@mui/material'

export default function usePrefersDarkMode() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPrefersDarkMode(prefersDarkMode))
  }, [prefersDarkMode])

  return prefersDarkMode
}
