import React from 'react'
import PropTypes from 'prop-types'

import { Map } from 'immutable'

import { structuredFormatByCustomTag } from 'utils/custom_tags'

import MuiAppBar from 'containers/AppBar'
import Actions from 'containers/shopping_cart/app_bar/Actions'

import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

export default function AppBar({
  count,
  progress,
  shoppingCartCustomTag,
  onCustomTagBackClick,
  onReset
}) {
  const i18n = useI18n()
  const device = useDevice()

  let actions
  let title = `${progress}%`

  if (!progress) {
    actions = <Actions />
    title = device.get('lt-sm') ? '' : `${count} ${i18n.get('news')}`
  }

  const structuredFormat = structuredFormatByCustomTag(shoppingCartCustomTag)

  if (structuredFormat) {
    title = device.get('lt-sm') ? i18n.get(structuredFormat) : i18n.get(`${structuredFormat}_export_title`)
  }

  if (shoppingCartCustomTag.get('name') && device.get('gt-sm')) {
    title = `${title}: ${shoppingCartCustomTag.get('name')}`
  }

  let handleBackClick

  if (shoppingCartCustomTag.get('id')) {
    handleBackClick = () => {
      onCustomTagBackClick(2)
      onReset()
    }
  }

  return (
    <MuiAppBar
      actions={actions}
      title={title}
      onBackClick={handleBackClick}
    />
  )
}

AppBar.propTypes = {
  count: PropTypes.number.isRequired,
  progress: PropTypes.number,
  shoppingCartCustomTag: PropTypes.instanceOf(Map),

  onCustomTagBackClick: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}
