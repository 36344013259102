import { connect } from 'react-redux'

import Actions from 'components/themax/actions/Actions'

import { getDevice } from 'selectors'

const mapStateToProps = state => ({
  device: getDevice(state)
})

export default connect(
  mapStateToProps,
  {}
)(Actions)
