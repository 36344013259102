import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Divider, Grid, Typography, CircularProgress } from '@mui/material'

import { calcRate } from 'utils/mailgunCalc'

const useStyles = makeStyles()(theme => ({
  engagement: {
    fontSize: '30pt',
    fontWeight: '700',
    textAlign: 'center',
    color: !theme.isDark && '#404040'
  },
  engagementLabel: {
    fontSize: '12pt',
    textAlign: 'center',
    color: !theme.isDark && '#757575'
  },
  headlineDivider: {
    margin: '0.5em 0em 1.5em 0em'
  },
  divider: {
    marginTop: '1em',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  }
}))

const Email = ({ data, requestRunning }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const total = data.get('total')
  const isTotalEmpty = total ? (Object.keys(total).length === 0 && total.constructor === Object) : true
  const clickToOpenRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.uniqueClicked, total.uniqueOpened)
  const clickThroughRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.uniqueClicked, total.delivered)
  const unsubscribeRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.unsubscribed, total.delivered)
  const undeliveredRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.undelivered, total.recipients)
  const loadOrShowData = value => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return value
  }

  return (
    <>
      <Typography
        className={classes.headline}
        variant="h6"
      >
        {i18n.get('engagement')}
      </Typography>
      <Divider className={classes.headlineDivider} />
      <Grid
        container
        spacing={2}
        columns={13}
        justifyContent="center"
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.opened)}</div>
          <div className={classes.engagementLabel}>{i18n.get('views')}</div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.uniqueOpened)}</div>
          <div className={classes.engagementLabel}>{i18n.get('unique_views')}</div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.clicked)}</div>
          <div className={classes.engagementLabel}>{i18n.get('clicks')}</div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.uniqueClicked)}</div>
          <div className={classes.engagementLabel}>{i18n.get('unique_clicks')}</div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(clickToOpenRate)}</div>
          <div className={classes.engagementLabel}>
            {i18n.get('click_to_open_rate')}
          </div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(clickThroughRate)}</div>
          <div className={classes.engagementLabel}>{i18n.get('click_through_rate')}</div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(unsubscribeRate)}</div>
          <div className={classes.engagementLabel}>
            {i18n.get('unsubscribe_rate')}
          </div>
        </Grid>
        <Divider
          className={classes.divider}
          orientation="vertical"
          flexItem
        />
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <div className={classes.engagement}>{loadOrShowData(undeliveredRate)}</div>
          <div className={classes.engagementLabel}>
            {i18n.get('undelivered_rate')}
          </div>
        </Grid>
      </Grid>
    </>
  )
}

Email.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired
}

export default Email
