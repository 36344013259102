import { connect } from 'react-redux'
import SearchForm from 'components/darknet/search_form'
import { fromJS } from 'immutable'
import { capitalize } from 'utils/string'

import {
  toggleAdvancedSearch, uiResetFilters,
  uiApplyFilters,
  setSelectedFilters
} from 'actions/darknet'

import { toggleHelpDialog } from 'actions/news'

import {
  getDarknetShowAdvancedSearch,
  getDarknetSearchFilters,
  getStaticDarkowlLanguages,
  getDarkowlSearchQueries
} from 'selectors'

const mapStateToProps = state => ({
  active: getDarknetShowAdvancedSearch(state),
  languages: getStaticDarkowlLanguages(state).map(el => (
    fromJS({ id: el.get('shortName'), name: capitalize(el.get('name')), value: el.get('shortName') })
  )),
  searchQueries: getDarkowlSearchQueries(state),
  selectedLanguages: getDarknetSearchFilters(state).get('languages'),
  selectedMinHackishness: getDarknetSearchFilters(state).get('minHackishness'),
  selectedNetworks: getDarknetSearchFilters(state).get('networks'),
  selectedSearchQueries: getDarknetSearchFilters(state).get('searchQueries')
})

export default connect(
  mapStateToProps,
  {
    uiResetFilters,
    uiApplyFilters,
    setSelectedFilters,
    onClose: toggleAdvancedSearch,
    toggleHelpDialog
  }
)(SearchForm)
