import { connect } from 'react-redux'

import SelectedDistributionListsTable
  from 'components/contact_management/selected_distribution_lists_bar/selected_distribution_lists_table/SelectedDistributionListsTable'

import { getCmSelectedLists } from 'selectors'

import {
  setMergedDistributionListData as onChange
} from 'actions/contact_management'

const mapStateToProps = state => ({
  data: getCmSelectedLists(state)
})

export default connect(mapStateToProps, {
  onChange
})(SelectedDistributionListsTable)
