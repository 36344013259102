import { StructuredExportFormatFlags } from 'static/constants'

export const structuredFormatByCustomTag = customTag => {
  let result = null

  StructuredExportFormatFlags.forEach(flag => {
    if (customTag.get(flag)) {
      result = flag.replace(/Enabled/, '')
    }
  })

  return result
}
