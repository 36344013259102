import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import ThemedAppBar from 'containers/AppBar'
import Actions from 'containers/themax/Actions'
import SearchBar from 'containers/themax/SearchBar'
import QueryTypeDropdown from 'containers/themax/QueryTypeDropdown'
import DateFilterButton from 'containers/themax/DateFilterButton'
import SearchForm from 'containers/themax/SearchForm'
import DateForm from 'containers/themax/DateForm'
import { Button, IconButton } from 'containers/themed'

import { Drawer } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'

const useStyles = makeStyles()(theme => ({
  searchBar: {
    transition: 'width 150ms',
    width: '25%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '1em',
      width: '25%'
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginLeft: '1em'
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1em'
    }
  },
  searchActions: {
    marginRight: 10,
    display: 'flex',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 5,
      marginRight: 0
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20
    },
    '& > div:first-of-type': {
      marginRight: 10
    }
  },
  button: {
    marginLeft: '0.5em'
  },
  drawer: {
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 500
    }
  },
  searchFormDrawer: {
    [theme.breakpoints.up('lg')]: {
      width: 750
    }
  }
}))

export default function AppBar({ showAdvancedSearch, toggleAdvancedSearch, showDateForm, toggleDateForm }) {
  const { classes, cx } = useStyles()
  const [barStyle, setBarStyle] = useState(null)
  const i18n = useI18n()
  const device = useDevice()

  const handleFocus = () => {
    let width = '100%'

    if (device.get('gt-md')) {
      width = '50%'
    }

    if (device.get('md')) {
      width = '40%'
    }

    setBarStyle({ width })
  }

  const handleBlur = () => {
    setTimeout(() => setBarStyle(null), 500)
  }

  const title = device.get('gt-md') ? `${i18n.get('themax')}` : undefined

  const renderFilterButton = () => {
    let comp = (
      <div>
        <Button
          variant="contained"
          color={showAdvancedSearch ? 'secondary' : undefined}
          onClick={() => toggleAdvancedSearch()}
          startIcon={<FilterListIcon />}
        >
          {i18n.get('filter')}
        </Button>
      </div>
    )

    if (device.get('lt-sm')) {
      comp = (
        <IconButton
          classes={{ root: classes.iconButton }}
          color={showAdvancedSearch ? 'secondary' : 'inherit'}
          onClick={() => toggleAdvancedSearch()}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
      )
    }

    return comp
  }

  const renderActions = acts => {
    if (device.get('lt-lg') && barStyle) {
      return null
    }

    return acts
  }

  const renderSearchActions = () => renderActions(
    <div className={classes.searchActions}>
      {renderFilterButton()}
      <DateFilterButton
        onClick={() => toggleDateForm()}
        active={showDateForm}
      />
    </div>
  )

  const renderForms = () => {
    const anchor = device.get('lt-lg') ? 'top' : 'right'

    return (
      <>
        <Drawer
          classes={{
            paper: cx(classes.drawer, classes.searchFormDrawer)
          }}
          anchor={anchor}
          open={showAdvancedSearch}
          onClose={() => toggleAdvancedSearch()}
        >
          <SearchForm />
        </Drawer>
        <Drawer
          classes={{
            paper: classes.drawer
          }}
          anchor={anchor}
          open={showDateForm}
          onClose={() => toggleDateForm()}
        >
          <DateForm />
        </Drawer>
      </>
    )
  }

  return (
    <div>
      <ThemedAppBar
        title={title}
        actions={<Actions />}
      >
        <div
          style={barStyle}
          className={classes.searchBar}
        >
          <SearchBar
            onFocus={handleFocus}
            onBlur={handleBlur}
            queryTypeDropdown={<QueryTypeDropdown />}
          />
        </div>

        {renderSearchActions()}
      </ThemedAppBar>
      {renderForms()}

    </div>
  )
}

AppBar.propTypes = {
  showAdvancedSearch: PropTypes.bool.isRequired,
  showDateForm: PropTypes.bool.isRequired,
  toggleAdvancedSearch: PropTypes.func.isRequired,
  toggleDateForm: PropTypes.func.isRequired
}
