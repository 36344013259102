import { connect } from 'react-redux'

import Attachments from 'components/content_desk_new/contents/content_edit_dialog/attachments'

const mapStateToProps = _state => ({
})

export default connect(
  mapStateToProps,
  {}
)(Attachments)
