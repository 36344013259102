import { connect } from 'react-redux'

import ContentDelete from 'components/content_desk_new/contents/content/content_actions/ContentDelete'

import { openDeleteDialog, setDeleteContentData } from 'actions/content_desk'

const mapStateToProps = (_state, { deleteDisabled, deleteData }) => ({
  deleteDisabled,
  deleteData
})

export default connect(
  mapStateToProps,
  {
    onOpen: () => openDeleteDialog('content'),
    setDeleteData: setDeleteContentData
  }
)(ContentDelete)
