import React from 'react'
import moment from 'moment-timezone'
import CloseIcon from '@mui/icons-material/Close'
import { TextField, InputAdornment, IconButton } from '@mui/material'

export const handleProps = ({ resetable, ...props }) => {
  const newProps = { ...props }
  delete newProps.dispatch

  newProps.maxDate = newProps.maxDate ? moment(newProps.maxDate) : (newProps.maxDate || undefined)
  newProps.minDate = newProps.minDate ? moment(newProps.minDate) : (newProps.minDate || undefined)
  newProps.value = newProps.value ? moment(newProps.value) : (newProps.value || null)

  newProps.onChange = value => {
    if (props.onChange && (!value || (value && moment(value).isValid()))) {
      props.onChange(value)
    }
  }

  newProps.renderInput = inputProps => {
    const newInputProps = { ...inputProps, ...(props.inputProps || {}) }
    let InputProps = newInputProps.InputProps || {}

    if (resetable) {
      InputProps = {
        ...InputProps,
        endAdornment: (
          <>
            {InputProps.endAdornment}

            <InputAdornment position="end">
              <IconButton onClick={() => newProps.onChange(null)}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          </>
        )
      }
    }

    return (
      <TextField
        {...newInputProps}
        InputProps={InputProps}
      />
    )
  }

  return newProps
}
