import { fromJS } from 'immutable'
import { createImmutableSelector } from 'utils/reselect'

export * from './chart_dialog'

export const getChartsData = state => state.news.getIn(['charts', 'data'])
export const getChartsPrevious = state => state.news.getIn(['charts', 'previous'])

export const getRawCharts = state => getChartsData(state).get('charts')
export const getChartsCharts = state => getRawCharts(state)

export const getChartsLayouts = state => getChartsData(state).get('layouts')
export const getChartsPreviousCharts = state => getChartsPrevious(state).get('charts')
export const getChartsPreviousLayouts = state => getChartsPrevious(state).get('layouts')

export const getChartsUi = state => state.news.getIn(['charts', 'ui'])
export const getChartsIsEditing = state => getChartsUi(state).get('isEditing')
export const getChartsIsRunning = state => getChartsUi(state).get('isRunning')

export const getChartsSelectedChart = state => getChartsUi(state).get('selectedChart')
export const getChartsSelectedChartIndex = state => getChartsUi(state).get('selectedChartIndex')
export const getChartsSelectedChartStepType = state => getChartsUi(state).get('selectedChartStepType')
export const getChartsShowChartDialog = state => getChartsUi(state).get('showChartDialog')
export const getAllowedChartConfigs = state => getChartsUi(state).get('allowedChartConfigs')

export const getChartsBreakpoints = createImmutableSelector(
  getChartsLayouts,
  layouts => layouts.reduce((acc, value, key) => (value.isEmpty() ? acc : acc.push(key)), fromJS([]))
)

export const getChartsAiChartAnalysis = state => state.news.getIn(['charts', 'aiChartAnalysis'])
export const getChartsAiChartAnalysisShowDialog = state => getChartsAiChartAnalysis(state).get('showDialog')
export const getChartsAiChartAnalysisLoading = state => getChartsAiChartAnalysis(state).get('loading')
export const getChartsAiChartAnalysisChart = state => getChartsAiChartAnalysis(state).get('chart')
export const getChartsAiChartAnalysisAnalysis = state => getChartsAiChartAnalysis(state).get('analysis')

// STATIC_CHARTS
export const getStaticChartsRawCharts = state => state.news.getIn(['staticCharts', 'charts'])
export const getStaticChartsLayouts = state => state.news.getIn(['staticCharts', 'layouts'])
export const getStaticChartsCharts = state => getStaticChartsRawCharts(state)
