import { connect } from 'react-redux'
import { getDefaultDomains } from 'utils/mailgun'

import ContentForm from 'components/content_desk/content_edit_dialog/content_form'
import {
  updateContent as onChange,
  openCampaignForm,
  resetSocialMediaPostsAndUploads
} from 'actions/content_desk'

import {
  getCDeskCampaignDropdownOptionsLoading,
  getCDeskContentFormData,
  getUserLocale,
  getCDeskCampaignDropdownOptions,
  getStaticCdContentTypes,
  getCDeskConfig,
  getCDeskEmailEditorLoadingStatus
} from 'selectors'

const mapStateToProps = state => ({
  campaignOptionsLoading: getCDeskCampaignDropdownOptionsLoading(state),
  data: getCDeskContentFormData(state),
  config: getCDeskConfig(state),
  defaultDomains: getDefaultDomains(),
  locale: getUserLocale(state),
  campaigns: getCDeskCampaignDropdownOptions(state),
  types: getStaticCdContentTypes(state),
  emailEditorLoading: getCDeskEmailEditorLoadingStatus(state)
})

export default connect(
  mapStateToProps,
  {
    onChange,
    openCampaignForm,
    resetSocialMediaPostsAndUploads
  }
)(ContentForm)
