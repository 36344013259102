import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/administration'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  ccdAllowedDomains: {
    items: [],
    total: 0
  },
  ccdAllowedDomainFormData: {
    id: null,
    name: '',
    sendingVerified: false,
    receivingVerified: false,
    trackingVerified: false,
    region: '',
    openTracking: false,
    clickTracking: false,
    senderDomains: []
  },
  ccdAllowedDomainStatus: {
    sendingRecords: [],
    receivingRecords: [],
    trackingRecords: []
  }
})

export default handleActions({
  [Actions.setAllowedDomains]: (state, { payload: { domains, total } }) => state
    .setIn(['ccdAllowedDomains', 'items'], fromJS(domains))
    .setIn(['ccdAllowedDomains', 'total'], total),
  [Actions.setAllowedDomainFormData]: (state, { payload }) => state.set('ccdAllowedDomainFormData', fromJS(payload)),
  [combineActions(
    Actions.searchAllowedDomainsSuccess,
    Actions.searchAllowedDomainsError,
    Actions.createAllowedDomainSuccess,
    Actions.createAllowedDomainError,
    Actions.updateAllowedDomainSuccess,
    Actions.updateAllowedDomainError,
    Actions.deleteAllowedDomainSuccess,
    Actions.deleteAllowedDomainError,
    Actions.refreshStatusAllowedDomainSuccess,
    Actions.refreshStatusAllowedDomainError,
    Actions.fetchAllowedDomainStatusError
  )]: state => state.set('ccdAllowedDomainFormData', initialState.get('ccdAllowedDomainFormData')),
  [Actions.fetchAllowedDomainStatusSuccess]: (state, { payload }) => state.set('ccdAllowedDomainStatus', fromJS(payload)),
  [Actions.closeAllowedDomainsStatusDialog]: state => state.set('ccdAllowedDomainStatus', initialState.get('ccdAllowedDomainStatus')),
  [AppActions.resetState]: () => initialState
}, initialState)
