/* global navigator */

export const capitalize = (
  [first, ...rest],
  locale = navigator.language
) => (first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join(''))

export const equals = (string1, string2) => `${string1}` === `${string2}`

export const shorten = (string, length) => (string.length > length ? `${string.slice(0, length - 3)}...` : string)

export const toSnakeCase = string => string.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`)
