import React, { useEffect } from 'react'
import { List, Map } from 'immutable'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Backdrop, MenuItem, Typography, Paper } from '@mui/material'

import { getMediaReviewById } from 'selectors'

import { createLoadable } from 'utils/loadable'

import { StaggeredListItem } from 'components/staggered_list'
import CapSafe from 'containers/CapSafe'
import { Capabilities } from 'static/constants'
import AppBar from 'containers/AppBar'
import AppBarActions from 'containers/media_reviews/media_review_detail/AppBarActions'
import { Menu, IconButton, Button } from 'containers/themed'
import Summary from 'containers/media_reviews/media_review_detail/Summary'
import Codes from 'components/media_reviews/media_review_stats/codes'
import ChannelBar from 'components/media_reviews/media_review_stats/channel_bar'
import ContentContainer from 'components/content_container'
import NrxProgressBar from 'components/nrx_progress_bar'
import CenterMessage from 'containers/CenterMessage'
import GroupedNewsList from 'containers/media_reviews/media_review_detail/GroupedNewsList'
import SortBar from 'containers/media_reviews/media_review_detail/SortBar'
import RemoveDialog from 'containers/media_reviews/media_review_detail/RemoveDialog'
import Selected from 'containers/media_reviews/media_review_detail/Selected'
import RemoveCustomTagDialog from 'containers/media_reviews/media_review_detail/RemoveCustomTagDialog'
import ManagementSummary from 'containers/media_reviews/media_review_detail/ManagementSummary'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'relative'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      marginLeft: 10
    }
  },
  codes: {
    minHeight: 37
  },
  iconMenu: {
    position: 'absolute',
    zIndex: 1,
    right: 0,
    top: 0
  },
  loading: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mediaReviewHeader: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 20,
    '& > div:first-of-type': {
      width: '25%'
    },
    '& > :last-child': {
      width: '75%',
      display: 'flex',
      flexFlow: 'column'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px 20px',
    marginBottom: 20
  },
  backdrop: {
    fontSize: 28,
    color: theme.palette.common.white,
    zIndex: theme.zIndex.drawer,
    flexDirection: 'column'
  },
  backdropButtons: {
    marginTop: 20,
    '& > *:first-of-type': {
      marginRight: 20
    }
  }
}))

const ExportMenuDialogs = createLoadable(() => (
  import(/* webpackChunkName: "MediaReviewDetailExportMenuDialogs" */ 'containers/export_menu/ExportMenuDialogs')
))
const EditDialog = createLoadable(() => (
  import(/* webpackChunkName: "MediaReviewDetailEditDialog" */ 'containers/news/edit_dialog/EditDialog')
))
const CustomTagDialog = createLoadable(() => (
  import(/* webpackChunkName: "MediaReviewDetailCustomTagDialog" */ 'containers/media_reviews/media_review_detail/CustomTagDialog')
))
const AiAnalysisDialog = createLoadable(() => (
  import(/* webpackChunkName: "MediaReviewDetailAiAnalysisDialog" */ 'containers/media_reviews/media_review_detail/AiAnalysisDialog')
))

const SafeAddMenuItem = CapSafe(MenuItem, [Capabilities.HAS_EDITING_FEATURE, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE])
const ExportDialogs = CapSafe(ExportMenuDialogs, Capabilities.HAS_MEDIA_REVIEW_DISPATCH_FEATURE)
const SafeSortbar = CapSafe(SortBar, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)

export default function MediaReviewDetail({
  exportFormats,
  loading,
  collapseAllTopics,
  expandAllTopics,
  showAllSnippets,
  hideAllSnippets,
  showAllSummaries,
  hideAllSummaries,
  showEditNewsDialog,
  totalCount,
  newsCount,
  onMount,
  onUnmount,
  onAcceptDiff,
  onRejectDiff,
  setValueInSettings,
  pdfCoverTitle,
  showDiffWarning,
  config,
  capabilities
}) {
  const { id } = useParams()
  const mediaReview = useSelector(state => getMediaReviewById(state, id))
  const { classes } = useStyles()
  const i18n = useI18n()

  const shouldAutoCollapseTopics = !config.get('expandTopics')
  const shouldAutoShowSummaries = config.get('showSummaries')
  const shouldAutoShowSnippets = config.get('showSnippets')

  useEffect(() => {
    if (mediaReview) {
      onMount(mediaReview.get('id'))
      setValueInSettings({ name: 'pdfCoverTitle', value: mediaReview.get('name') })
    }

    return () => {
      setValueInSettings({ name: 'pdfCoverTitle', value: pdfCoverTitle })
      onUnmount()
    }
  }, [onMount, setValueInSettings])

  useEffect(() => {
    if (!loading) {
      if (shouldAutoCollapseTopics) {
        collapseAllTopics()
      }

      if (shouldAutoShowSummaries) {
        showAllSummaries()
      }

      if (shouldAutoShowSnippets) {
        showAllSnippets()
      }
    }
  }, [loading])

  if (!mediaReview) {
    return (
      <div>
        <AppBar
          title={i18n.get('back_title')}
        />
        <CenterMessage
          title={i18n.get('media_review_not_available')}
          message={i18n.get('please_navigate_back')}
        />
      </div>
    )
  }

  const startDate = moment(mediaReview.get('startDate'))
  const endDate = moment(mediaReview.get('endDate'))

  let date = `${startDate.format('L')} - ${endDate.format('L')}`

  if (startDate.isSame(endDate, 'day')) {
    date = startDate.format('L')
  }

  let headerLabel = i18n.get('media_review_headline', { count: `<b>${newsCount}</b>` })

  if (totalCount !== newsCount) {
    headerLabel = `${headerLabel} (${totalCount} ${i18n.get('hits')})`
  }

  return (
    <>
      <Selected />
      <AppBar
        actions={<AppBarActions />}
        title={i18n.get('media_review_of', {
          name: mediaReview.get('name'),
          date
        })}
      />

      <EditDialog />
      <RemoveDialog />
      <ExportDialogs exportFormats={exportFormats} />
      <CustomTagDialog />
      <RemoveCustomTagDialog />
      <AiAnalysisDialog />

      <Backdrop
        classes={{ root: classes.backdrop }}
        open={showDiffWarning}
      >
        {i18n.get('media_review_diff_warning')}

        <div className={classes.backdropButtons}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onAcceptDiff()}
          >
            {i18n.get('save_and_overwrite')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onRejectDiff()}
          >
            {i18n.get('cancel_and_reload')}
          </Button>
        </div>
      </Backdrop>

      {loading && (
        <div className={classes.loading}>
          <NrxProgressBar />
        </div>
      )}

      {!loading && (
        <ContentContainer>
          <div className={classes.container}>
            <Menu
              handle={(
                <IconButton
                  classes={{ root: classes.iconMenu }}
                  size="large"
                >
                  <MoreHorizIcon />
                </IconButton>
              )}
            >
              <MenuItem onClick={() => collapseAllTopics()}>
                {i18n.get('collapse_all_topics')}
              </MenuItem>
              <MenuItem onClick={() => expandAllTopics()}>
                {i18n.get('expand_all_topics')}
              </MenuItem>
              <MenuItem onClick={() => showAllSnippets()}>
                {i18n.get('show_snippets')}
              </MenuItem>
              <MenuItem onClick={() => hideAllSnippets()}>
                {i18n.get('hide_snippets')}
              </MenuItem>
              <MenuItem onClick={() => showAllSummaries()}>
                {i18n.get('show_summaries')}
              </MenuItem>
              <MenuItem onClick={() => hideAllSummaries()}>
                {i18n.get('hide_summaries')}
              </MenuItem>
              <SafeAddMenuItem onClick={() => showEditNewsDialog()}>
                {i18n.get('new_news')}
              </SafeAddMenuItem>
            </Menu>

            <div className={classes.mediaReviewHeader}>
              <StaggeredListItem delay={0}>
                <Typography dangerouslySetInnerHTML={{ __html: headerLabel }} />
              </StaggeredListItem>

              <StaggeredListItem delay={200}>
                <div className={classes.codes}>
                  <Codes mediaReview={mediaReview} />
                </div>
                <br />
                <ChannelBar mediaReview={mediaReview} />
                <br />
              </StaggeredListItem>
            </div>

            <StaggeredListItem
              delay={500}
              type="fade"
            >
              <Summary />
              <ManagementSummary />
              {capabilities.get(Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE) && (
                <Paper
                  className={classes.toolbar}
                  square
                >
                  <SafeSortbar />
                </Paper>
              )}

              <GroupedNewsList />
            </StaggeredListItem>
          </div>
        </ContentContainer>
      )}
    </>
  )
}

MediaReviewDetail.propTypes = {
  exportFormats: PropTypes.instanceOf(List).isRequired,
  pdfCoverTitle: PropTypes.string.isRequired,
  newsCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  showDiffWarning: PropTypes.bool.isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,

  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  setValueInSettings: PropTypes.func.isRequired,
  collapseAllTopics: PropTypes.func.isRequired,
  expandAllTopics: PropTypes.func.isRequired,
  showAllSnippets: PropTypes.func.isRequired,
  hideAllSnippets: PropTypes.func.isRequired,
  showAllSummaries: PropTypes.func.isRequired,
  hideAllSummaries: PropTypes.func.isRequired,
  showEditNewsDialog: PropTypes.func.isRequired,
  onAcceptDiff: PropTypes.func.isRequired,
  onRejectDiff: PropTypes.func.isRequired
}
