import { connect } from 'react-redux'

import AvgHackishnessChart from 'components/darknet/static_charts/avg_hackishness_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.HACKISHNESS_TOTAL)
})

export default connect(mapStateToProps, {})(AvgHackishnessChart)
