import { call, put, select, takeEvery, takeLatest, all, delay } from 'redux-saga/effects'

import * as Actions from 'actions/page_identities'
import * as NewsActions from 'actions/news'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'
import { retriable } from 'utils/sagas'
import { fetchChartData } from 'sagas/charts'

export function* fetchPageIdentitiesTry() {
  const body = yield select(Selectors.getPageIdentitiesSearchBody)

  const result = yield call(Api.searchPageIdentities, body)

  yield put(Actions.pageIdentitiesRequestSuccess(result))
}

export function* fetchPageIdentitiesFail(error) {
  yield put(Actions.pageIdentitiesRequestError(error))
  yield put(AppActions.exception(error))
}

export function* fetchPageIdentities() {
  yield put(Actions.pageIdentitiesStatisticsRequestStart())
  yield put(Actions.pageIdentityPostsRequestStart())

  yield call(retriable, fetchPageIdentitiesTry, fetchPageIdentitiesFail)
}

export function* fetchChartAggregation(chart, index, data) {
  try {
    yield put(Actions.setChartLoading(index))
    let result

    if (!data) {
      const searchBody = yield select(Selectors.getSearchBody)
      result = yield call(fetchChartData, chart, searchBody)
    }

    if (data || result) {
      yield put(Actions.setChartData({
        index,
        data: (data || result)[chart.get('firstLevel')]
      }))
    } else {
      yield put(Actions.setChartData({
        index,
        data: null
      }))
    }

    return data || result
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.setChartData({
      index,
      data: null
    }))
  }

  return null
}

export function* fetchPageIdentitiesStatistics() {
  const charts = yield select(Selectors.getPageIdentitiesStatisticsData)

  let effects = []
  charts.forEach((chart, index) => {
    effects.push(put(Actions.setChartLoading(index)))
  })
  yield all(effects)

  effects = []
  charts.forEach((chart, index) => {
    if (!chart.get('isPageIdentitiesChart')) {
      effects.push(call(fetchChartAggregation, chart, index))
    }
  })
  yield all(effects)

  const firstChartIndex = charts.findIndex(chart => chart.get('isPageIdentitiesChart'))
  const pageIdentityData = yield call(fetchChartAggregation, charts.get(firstChartIndex), firstChartIndex)

  effects = []
  charts.forEach((chart, index) => {
    if (chart.get('isPageIdentitiesChart')) {
      effects.push(call(fetchChartAggregation, chart, index, pageIdentityData))
      effects.push(delay(200))
    }
  })

  for (let i = 0; i < effects.length; i += 1) {
    yield effects[i]
  }
}

export function* fetchPageIdentityPostsTry() {
  const body = yield select(Selectors.getPageIdentityPostsSearchBody)

  const { posts } = yield call(Api.searchPageIdentityPosts, body)

  yield put(NewsActions.addNews(posts.best))
  yield put(NewsActions.addNews(posts.worst))
  yield put(Actions.pageIdentityPostsRequestSuccess(posts))
}

export function* fetchPageIdentityPostsFail(error) {
  yield put(Actions.pageIdentityPostsRequestError(error))
  yield put(AppActions.exception(error))
}

export function* fetchPageIdentityPosts() {
  yield call(retriable, fetchPageIdentityPostsTry, fetchPageIdentityPostsFail)
}

export function* toggleShowInactive() {
  yield put(Actions.pageIdentitiesRequestStart())
}

export function* watchFetchPageIdentities() {
  yield takeLatest(Actions.pageIdentitiesRequestStart, fetchPageIdentities)
}

export function* watchFetchPageIdentitiesStatistics() {
  yield takeLatest(Actions.pageIdentitiesStatisticsRequestStart, fetchPageIdentitiesStatistics)
}

export function* watchFetchPageIdentityPosts() {
  yield takeLatest(Actions.pageIdentityPostsRequestStart, fetchPageIdentityPosts)
}

export function* watchToggleShowInactive() {
  yield takeEvery(Actions.toggleShowInactive, toggleShowInactive)
}

export default function* saga() {
  yield all([
    watchFetchPageIdentities(),
    watchFetchPageIdentitiesStatistics(),
    watchFetchPageIdentityPosts(),
    watchToggleShowInactive()
  ])
}
