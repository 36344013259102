import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

const useRightToLeft = makeStyles()({
  item: {
    opacity: 0,
    animation: `${keyframes`
              0% {
                transform: translateX(100px);
                opacity: 0;
              }
              100% {
                opacity: 1;
                transform: translateX(0);
              }
            `} 500ms`,
    animationFillMode: 'forwards'
  }
})

const useLeftToRight = makeStyles()({
  item: {
    opacity: 0,
    animation: `${keyframes`
              0% {
                transform: translateX(-25%);
                opacity: 0;
              }
              100% {
                opacity: 1;
                transform: translateX(0);
              }
            `} 500ms`,
    animationFillMode: 'forwards'
  }
})

const useWave = makeStyles()({
  item: {
    animation: `${keyframes`
              0% {
                transform: translateY(0);
              }
              50% {
                transform: translateY(-10px);
              }
              100% {
                transform: translateY(0);
              }
            `} 500ms`,
    animationFillMode: 'forwards'
  }
})

const useBottomToTop = makeStyles()({
  item: {
    opacity: 0,
    animation: `${keyframes`
              0% {
                transform: translateY(100px);
                opacity: 0;
              }
              100% {
                opacity: 1;
                transform: translateY(0);
              }
            `} 500ms`,
    animationFillMode: 'forwards'
  },
  itemNoFill: {
    animation: `${keyframes`
              0% {
                transform: translateY(100px);
                opacity: 0;
              }
              100% {
                opacity: 1;
                transform: translateY(0);
              }
            `} 500ms`
  }
})

const useFade = makeStyles()({
  item: {
    opacity: 0,
    animation: `${keyframes`
              0% {
                opacity: 0;
              }
              100% {
                opacity: 1;
              }
            `} 500ms`,
    animationFillMode: 'forwards'
  }
})

export default function StaggeredListItem(props) {
  const { classes: bottomToTop, cx } = useBottomToTop()
  const { classes: wave } = useWave()
  const { classes: rightToLeft } = useRightToLeft()
  const { classes: leftToRight } = useLeftToRight()
  const { classes: fade } = useFade()
  const { delay, children, className, type, hideInitial } = props
  const newProps = { ...props }
  delete newProps.delay
  delete newProps.children
  delete newProps.className
  delete newProps.hideInitial

  let theme = bottomToTop

  if (type === 'rightToLeft') {
    theme = rightToLeft
  }

  if (type === 'leftToRight') {
    theme = leftToRight
  }

  if (type === 'wave') {
    theme = wave
  }

  if (type === 'fade') {
    theme = fade
  }

  let itemClass = theme.item

  if (!hideInitial) {
    itemClass = theme.itemNoFill
  }

  return (
    <div
      className={cx(itemClass, className)}
      style={{ animationDelay: `${delay}ms` }}
      {...newProps}
    >
      {children}
    </div>
  )
}

StaggeredListItem.defaultProps = {
  hideInitial: true,
  delay: 0
}

StaggeredListItem.propTypes = {
  children: PropTypes.any.isRequired,
  delay: PropTypes.number,
  className: PropTypes.string,
  hideInitial: PropTypes.bool,
  type: PropTypes.string
}
