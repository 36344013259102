/* global window */
import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/content_desk'

import moment from 'moment-timezone'

export function* fetchCalendarEvents({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const page = 1
    const limit = 300
    const dateFilter = [
      { field: 'scheduled_for_from', value: moment(payload).subtract(7, 'days') },
      { field: 'scheduled_for_to', value: moment(payload).endOf('month').add(7, 'days') },
      { field: 'planned_for_from', value: moment(payload).subtract(7, 'days') },
      { field: 'planned_for_to', value: moment(payload).endOf('month').add(7, 'days') }
    ]

    const body = {
      date_filter: dateFilter,
      page,
      limit
    }

    const contentsResult = yield call(Api.searchContents, body)

    const campaignDateFilter = [
      { field: 'start_date_to', value: moment(payload).endOf('month').add(7, 'days') },
      { field: 'end_date_from', value: moment(payload).subtract(7, 'days') }
    ]

    const campaignsResult = yield call(Api.searchCampaigns, {
      date_filter: campaignDateFilter,
      page,
      limit
    })

    yield put(Actions.fetchCalendarEventsSuccess({
      contents: {
        total: contentsResult.total,
        items: contentsResult.ccdContents
      },
      campaigns: {
        total: campaignsResult.total,
        items: campaignsResult.ccdCampaigns,
        allNames: campaignsResult.allNames
      }
    }))
  } catch (error) {
    yield put(Actions.fetchCalendarEventsError(error))

    yield put(AppActions.showAppMessage({
      text: i18n.get('failed'),
      success: false
    }))
  }
}

export function* onCalendarContentClick({ payload }) {
  const type = payload.get('type')
  const status = payload.get('status')

  if (type === 'ots_press_release') {
    if (status === 'distributed' || status === 'declined') {
      yield put(Actions.openViewContentDialog(payload))
    } else {
      const otsPressRelease = payload.get('otsPressRelease')

      if (otsPressRelease) {
        yield call(window.open, 'https://go.newsaktuell.de', '_blank', 'noopener,noreferrer')
      } else {
        yield put(Actions.lockContentStart({ id: payload.get('id'), openEditContentDialog: true }))
      }
    }
  }

  if (type === 'email') {
    if (status === 'released' || status === 'failed') {
      yield put(Actions.openViewContentDialog(payload))
    } else {
      yield put(Actions.lockContentStart({ id: payload.get('id'), openEditContentDialog: true }))
    }
  }

  if (type === 'facebook' || type === 'linkedin') {
    if (status === 'released' || status === 'failed') {
      yield put(Actions.openViewContentDialog(payload))
    } else {
      yield put(Actions.lockContentStart({ id: payload.get('id'), openOldEditContentDialog: true }))
    }
  }
}

export function* watchFetchCalendarContentsStart() {
  yield takeEvery(Actions.fetchCalendarEventsStart, fetchCalendarEvents)
}

export function* watchOnCalendarContentClick() {
  yield takeEvery(Actions.onCalendarContentClick, onCalendarContentClick)
}

export default function* calendarSaga() {
  yield all([
    watchFetchCalendarContentsStart(),
    watchOnCalendarContentClick()
  ])
}
