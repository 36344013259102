import { connect } from 'react-redux'

import CampaignProgress from 'components/content_desk_new/campaigns/campaign/campaign_progress'

const mapStateToProps = (_state, ownProps) => ({
  campaign: ownProps.campaign
})

export default connect(
  mapStateToProps,
  {
  }
)(CampaignProgress)

