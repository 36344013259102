import { connect } from 'react-redux'

import ContactsListItem from 'components/contact_management/distribution_list_edit_dialog/contacts_list_item'

import {
  removeContactFromListStart
} from 'actions/contact_management'

const mapStateToProps = _state => ({
})

export default connect(mapStateToProps, {
  onRemove: removeContactFromListStart
})(ContactsListItem)
