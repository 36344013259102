import { connect } from 'react-redux'

import Item from 'components/media_reviews/media_review_detail/news_list_item/item'

import {
  showRemoveNewsDialog,
  showSnippet,
  hideSnippet,
  showSummary,
  selectNews,
  hideSummary,
  selectNewsForSorting,
  shiftSelectNewsForSorting,
  loadFullTextStart,
  replaceMediaReviewReactionStart
} from 'actions/media_reviews'
import { showEditNewsDialog } from 'actions/news'
import {
  getDevice,
  isExpandedMediaReviewNewsByCode,
  isSummaryExpandedMediaReviewNewsByCode,
  isMediaReviewSelectedNewsForSorting,
  isMediaReviewNewsSelected,
  getMediaReviewSelectedNews,
  getFullTextById
} from 'selectors'

const mapStateToProps = (state, { codeId, news }) => ({
  device: getDevice(state),
  codeId,

  subsequentlyLoadedFullText: getFullTextById(state, news.get('id')) || null,
  expanded: isExpandedMediaReviewNewsByCode(state, news.get('id'), codeId),
  summaryExpanded: isSummaryExpandedMediaReviewNewsByCode(state, news.get('id'), codeId),
  selectedForSorting: isMediaReviewSelectedNewsForSorting(state, news.get('id'), codeId),
  selected: isMediaReviewNewsSelected(state, news.get('id'), codeId) || getMediaReviewSelectedNews(state).isEmpty()
})

export default connect(
  mapStateToProps,
  {
    onRemoveClick: showRemoveNewsDialog,
    onEditClick: showEditNewsDialog,
    onSelect: selectNews,
    showSnippet,
    hideSnippet,
    showSummary,
    hideSummary,
    selectNewsForSorting,
    shiftSelectNewsForSorting,
    loadFullText: loadFullTextStart,
    onReactionClick: replaceMediaReviewReactionStart
  }
)(Item)
