import { connect } from 'react-redux'

import LanguagesChart from 'components/darknet/static_charts/languages_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'
import { chartDrilldown } from 'actions/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.LANGUAGES)
})

export default connect(mapStateToProps, {
  drilldown: chartDrilldown
})(LanguagesChart)
