/* global window */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { debounce } from 'throttle-debounce'
import { useSnackbar } from 'notistack'
import useI18n from 'hooks/useI18n'
import getUrls from 'get-urls'
import ApartmentIcon from '@mui/icons-material/Apartment'

import EmojiPicker from 'emoji-picker-react'

import { Alert,
  Grid,
  Switch,
  FormControlLabel,
  List as MuiList, ListItem, ListItemAvatar, ListItemText, ListItemButton,
  LinearProgress } from '@mui/material'

import CircularProgress from '@mui/material/CircularProgress'
import YoutubeSearchedFor from '@mui/icons-material/YoutubeSearchedFor'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import { Autocomplete, BrowseButton, Input, IconButton, Popover, Button } from 'containers/themed'
import { green, red } from '@mui/material/colors'
import TextField from '@mui/material/TextField'

import MediaGallery from 'containers/content_desk/content_edit_dialog/linked_in_editor/MediaGallery'
import ContentPreview from 'containers/content_desk/ContentPreview'
import EditorAiWorkspace from 'containers/content_desk/EditorAiWorkspace'
import PromptHistory from 'containers/content_desk/PromptHistory'
import Unsplash from 'containers/Unsplash'

import { linkedInPost as initialLinkedInPost, linkedInRequiredPermissions } from 'static/linked_in_post_config'

import { mapToOptions } from 'utils/autocomplete'
import { formatBytes } from 'utils/number'
import { UploadedMediaFilesSizeLimit } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  postLengthInfo: {
    marginTop: '0.5em',
    textAlign: 'right'
  },
  validPostLength: {
    color: green[500]
  },
  invalidPostLength: {
    fontWeight: 'bold',
    color: red[500]
  },
  uploadButton: {
    width: '205px',
    height: '56px'
  },
  previewContainer: {
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: theme.isDark ? '#1E1E1E' : '#F0F0F0',
    padding: '1em',
    width: '100%',
    marginTop: '0.5em'
  },
  previewInnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    margin: 'auto'
  },
  previewLabel: {
    fontStyle: 'italic',
    color: !theme.isDark && '#757575',
    fontSize: '11pt',
    fontWeight: 500
  },
  aiModeSwitch: {
    float: 'left'
  },
  compaynSearchPopOver: {
    padding: 10,
    minWidth: 350
  },
  linkLoader: {
    marginTop: 5,
    height: 5
  }
}))

const LinkedInEditor = ({
  contentFormData,
  organizations,
  permissions,
  profile,
  mediaFileUploading,
  uploadedMediaFilesTotalSize,
  linkLoading,
  socialMediaLinkBuildFailed,
  aiMode,
  onChange,
  onUpload,
  onSelect,
  toggleEditorAiMode,
  unsplashIsVisible,
  toggleUnsplash,
  navigate,
  companySearchStart,
  companySearchResults,
  buildSocialMediaLink
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const inputRef = useRef(0)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [isEmojiClick, setIsEmojiClick] = useState(false)
  const [emojiLength, setEmojiLength] = useState(0)
  const [inputRows, setInputRows] = useState(6)
  const [matchedUrl, setMatchedUrl] = useState(null)
  const [companySearchValue, setCompanySearchValue] = useState('')
  const [companySearchOpen, setCompanySearchOpen] = useState(false)
  const [results, setResults] = useState(fromJS([]))
  const [loading, setLoading] = useState(false)

  const profileName = profile.get('name')
  const profileUrn = profile.get('urn')

  const currentLinkedInPost = contentFormData.get('linkedInPost') || fromJS(initialLinkedInPost)
  const currentPostDestination = contentFormData.getIn(['linkedInPost', 'author'], '')
  const contentType = contentFormData.getIn(['linkedInPost', 'type'])

  const postDestinations = List([
    ...profileUrn ? [fromJS({ id: 0, urn: profileUrn, name: profileName })] : [],
    ...organizations
  ])

  const postDestinationOptions = [
    ...profileUrn ? [{ label: profileName, value: 0 }] : [],
    {
      label: i18n.get('organizations'),
      options: mapToOptions(organizations)
    }
  ]

  const handleSelectionChange = () => {
    const selection = window.getSelection().toString()
    onSelect(selection)
  }

  useEffect(() => {
    setLoading(false)
    setResults(companySearchResults.map(r => ({ label: r.get('id'),
      title: r.get('localizedName'),
      logo: r.get('logoUrl') }
    )).toJS())
  }, [companySearchResults])

  useEffect(() => {
    inputRef.current.focus()

    if (inputRef.current && !isEmojiClick) {
      setCursorPosition(inputRef.current.selectionStart)
    }

    if (inputRef.current && isEmojiClick) {
      setCursorPosition(current => current + emojiLength)
    }

    setIsEmojiClick(false)
    setEmojiLength(0)
  }, [currentLinkedInPost])

  useEffect(() => {
    if (contentType !== 'text_only' || !matchedUrl) {
      return () => { }
    }

    const timer = setTimeout(() => {
      buildSocialMediaLink({ url: matchedUrl, socialMedia: 'linkedin' })
      setMatchedUrl(null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [matchedUrl])

  useEffect(() => {
    if (socialMediaLinkBuildFailed) {
      enqueueSnackbar(
        `${i18n.get('error_building_social_media_link')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [socialMediaLinkBuildFailed])

  let selectedPostDestination

  if (currentPostDestination) {
    selectedPostDestination = postDestinations.find(o => o.get('urn') === currentPostDestination)

    if (selectedPostDestination) {
      selectedPostDestination = { id: selectedPostDestination.get('id'), label: selectedPostDestination.get('name') }
    }
  }

  const handleCompanySearch = () => {
    setLoading(true)
    companySearchStart(companySearchValue)
  }

  const handleChange = e => {
    const { name, value } = e.target

    if (contentType === 'text_only') {
      const urls = Array.from(getUrls(value, { forceHttps: true, stripWWW: false }))
      const url = urls.length > 0 ? urls[0] : null

      if (url) {
        setMatchedUrl(url)
      }
    }

    if (name === 'commentary') {
      onChange({ key: 'plainText', value })
    }

    onChange({ key: 'linkedInPost', value: currentLinkedInPost.set(name, value) })
  }

  const handleChannelChange = option => {
    const newLinkedInPost = currentLinkedInPost.set('author', postDestinations.find(o => o.get('id') === option.value).get('urn'))

    onChange({ key: 'linkedInPost', value: newLinkedInPost })
  }

  const handleMediaTitleChange = e => {
    onChange({ key: 'linkedInPost', value: currentLinkedInPost.setIn(['content', 'media', 'title'], e.target.value) })
  }

  const handleInputClick = () => {
    if (inputRef.current) {
      setCursorPosition(inputRef.current.selectionStart)
      setInputRows(12)
    }
  }

  const handleBlur = () => {
    setInputRows(6)
  }

  const handleEmojiClick = (emoji, _event) => {
    onChange({
      key: 'linkedInPost',
      value: currentLinkedInPost
        .set('commentary', `${currentLinkedInPost.get('commentary')
          .slice(0, cursorPosition) + emoji.emoji + currentLinkedInPost.get('commentary')
          .slice(cursorPosition)}`)
    })
    setIsEmojiClick(true)
    setEmojiLength(emoji.emoji.length)
  }

  const onMediaUpload = debounce(50, file => {
    const maxSize = 209715200 // max video file size 200MB
    let doUpload = true

    if (!file) {
      doUpload = false
    }

    if (file && ((file.size + uploadedMediaFilesTotalSize) > UploadedMediaFilesSizeLimit)) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('exceeding_upload_storage_size_warning')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (file && file.size > maxSize) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('media_file_too_large', { size: formatBytes(maxSize) })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (doUpload) {
      onUpload({ file })
    }
  }) // BrowseButton calls onChange twice, so debounce

  const renderMediaGallery = () => {
    if (contentType === 'link') {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        {unsplashIsVisible ? <Unsplash /> : (
          <MediaGallery />
        )}
      </Grid>
    )
  }

  const renderWarning = () => {
    const hasRequiredPermissions = linkedInRequiredPermissions.every(p => permissions.split(',').includes(p))

    if (hasRequiredPermissions) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Alert severity="warning">
          {i18n.get('linked_in_facebook_permission_warning')}
          <Button
            sx={{ marginLeft: '1em' }}
            onClick={() => navigate('/app/administration/external_accounts')}
            color="primary"
            variant="outlined"
          >{i18n.get('external_accounts')}
          </Button>
        </Alert>
      </Grid>
    )
  }

  const maxLength = 6000
  const postTextLength = currentLinkedInPost.get('commentary').length
  let postLengthClasses = `${classes.postLengthInfo} ${classes.validPostLength}`

  if (postTextLength > maxLength) {
    postLengthClasses = `${classes.postLengthInfo} ${classes.invalidPostLength}`
  }

  return (
    <Grid
      container
      spacing={2}
    >
      {renderWarning()}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
      >

        <Input
          required
          type="text"
          rows={inputRows}
          multiline
          inputRef={inputRef}
          value={currentLinkedInPost.get('commentary')}
          maxLength={maxLength}
          name="commentary"
          onChange={handleChange}
          onClick={handleInputClick}
          onBlur={handleBlur}
          onSelect={handleSelectionChange}
        />
        <div className={classes.linkLoader}>
          {linkLoading ? (
            <LinearProgress
              color="primary"
              sx={{ borderRadius: 5 }}
            />
          ) : null}
        </div>
        <FormControlLabel
          className={classes.aiModeSwitch}
          control={(
            <Switch
              checked={aiMode}
              onChange={() => toggleEditorAiMode()}
              color="primary"
            />
          )}
          label={i18n.get('ai_suggestions')}
        />
        <div
          className={postLengthClasses}
        >
          <Popover
            onClose={() => setCompanySearchOpen(false)}
            open={companySearchOpen}
            handle={(
              <IconButton>
                <ApartmentIcon
                  onClick={() => {
                    setCompanySearchOpen(true)
                  }}
                />
              </IconButton>
            )}
          >
            <br />
            <TextField
              label={i18n.get('mention_a_company')}
              className={classes.compaynSearchPopOver}
              inputRef={input => input && input.focus()}
              value={companySearchValue}
              onChange={e => setCompanySearchValue(e.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={20}
                      />
                    ) : (
                      <IconButton>
                        <YoutubeSearchedFor
                          size={20}
                          onClick={() => handleCompanySearch()}
                        />
                      </IconButton>
                    )}
                  </>
                )
              }}
            />

            <MuiList
              dense
              className={classes.compaynSearchPopOver}
            >
              { results.map(r => (
                <ListItem
                  key={r.label}
                  disablePadding
                >
                  <ListItemButton
                    onClick={() => {
                      onChange({
                        key: 'linkedInPost',
                        value: currentLinkedInPost
                          .set(
                            'commentary',
                            `${
                              currentLinkedInPost.get('commentary').slice(0, cursorPosition)
                            } @[${
                              r.title
                            }](urn:li:organization:${
                              r.label
                            }) ${currentLinkedInPost.get('commentary').slice(cursorPosition)}`
                          )
                      })
                      setCompanySearchOpen(false)
                    }}
                  >
                    <ListItemAvatar>
                      <img
                        alt="logo"
                        src={r.logo}
                        width={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={r.title}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </MuiList>
          </Popover>
          <Popover
            handle={(
              <IconButton>
                <InsertEmoticonIcon />
              </IconButton>
            )}
          >
            <EmojiPicker
              emojiStyle="native"
              emojiVersion="4.0"
              onEmojiClick={handleEmojiClick}
            />
          </Popover>
          {postTextLength}/{maxLength}
        </div>
        {aiMode && (
          <EditorAiWorkspace
            contentType="linkedIn"
          />
        )}
        {!aiMode && (
          <>
            <div className={classes.previewLabel}>
              {`${i18n.get('preview')}:`}
            </div>
            <div className={classes.previewContainer}>
              <div className={classes.previewInnerContainer}>
                <ContentPreview
                  content={contentFormData}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
      >
        {aiMode && (
          <Grid
            container
          >
            <PromptHistory />
          </Grid>
        )}
        {!aiMode && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Autocomplete
                required
                onChange={option => handleChannelChange(option)}
                options={postDestinationOptions}
                placeholder=""
                value={selectedPostDestination}
                label={i18n.get('choose_linked_in_channel')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <BrowseButton
                  className={classes.uploadButton}
                  accept={'image/*, video/mp4, application/pdf'}
                  color="primary"
                  variant="outlined"
                  size="large"
                  loading={mediaFileUploading}
                  onChange={e => onMediaUpload(e.target.files[0])}
                >
                  <ImageOutlinedIcon />&nbsp;{i18n.get('upload_media')}
                </BrowseButton>
                <Button
                  className={classes.uploadButton}
                  startIcon={unsplashIsVisible ? <PhotoLibraryIcon /> : <ImageSearchIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={toggleUnsplash}
                >
                  {`${i18n.get('image')} ${i18n.get(unsplashIsVisible ? 'library' : 'search')}`}
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Input
                fullWidth
                required={contentType === 'video' || contentType === 'document'}
                disabled={contentType !== 'video' && contentType !== 'document'}
                label={contentType === 'video' ? i18n.get('video_title') : i18n.get('title')}
                type="text"
                name="title"
                value={currentLinkedInPost.getIn(['content', 'media', 'title'], '')}
                onChange={handleMediaTitleChange}
              />
            </Grid>
            {renderMediaGallery()}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

LinkedInEditor.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  organizations: PropTypes.instanceOf(List).isRequired,
  permissions: PropTypes.string.isRequired,
  profile: PropTypes.instanceOf(Map).isRequired,
  aiMode: PropTypes.bool.isRequired,
  mediaFileUploading: PropTypes.bool.isRequired,
  unsplashIsVisible: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired,
  linkLoading: PropTypes.bool.isRequired,
  socialMediaLinkBuildFailed: PropTypes.bool.isRequired,
  companySearchResults: PropTypes.instanceOf(List).isRequired,

  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEditorAiMode: PropTypes.func.isRequired,
  toggleUnsplash: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  buildSocialMediaLink: PropTypes.func.isRequired,
  companySearchStart: PropTypes.func.isRequired
}

export default LinkedInEditor
