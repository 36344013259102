import { connect } from 'react-redux'

import Dashboard from 'components/darknet/dashboard'

import {
  searchRequestStart as onMountSearch,
  scoresRequestStart as onMountScores,
  toggleAdvancedSearch
} from 'actions/darknet'

import { toggleDateForm } from 'actions/news'

import {
  getDarknetRequestRunning,
  getDarknetView,
  getDarkowlSearchQueries,
  getDarknetShowAdvancedSearch,
  getNewsUi
} from 'selectors'

const mapStateToProps = state => ({
  view: getDarknetView(state),
  requestRunning: getDarknetRequestRunning(state),
  darkowlSearchQueries: getDarkowlSearchQueries(state),
  showDateForm: getNewsUi(state).get('showDateForm'),
  showAdvancedSearch: getDarknetShowAdvancedSearch(state)
})

export default connect(
  mapStateToProps,
  {
    onMountSearch,
    onMountScores,
    toggleAdvancedSearch,
    toggleDateForm
  }
)(Dashboard)
