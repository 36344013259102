import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/export'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  loading: false
})

export default handleActions({
  [Actions.fetchDispatchLogStart]: state => state.set('loading', true),
  [Actions.fetchDispatchLogSuccess]: state => state.set('loading', false),
  [Actions.fetchDispatchLogError]: state => state.set('loading', false),

  [AppActions.resetState]: () => initialState
}, initialState)
