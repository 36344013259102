import React from 'react'
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import useI18n from 'hooks/useI18n'
import { handleProps } from '../utils'

export default function DateTimePicker(props) {
  const i18n = useI18n()
  const newProps = handleProps(props)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MobileDateTimePicker
        autoOk
        clearable
        cancelText={i18n.get('cancel')}
        clearText={i18n.get('reset')}
        {...newProps}
      />
    </LocalizationProvider>
  )
}
