import { createImmutableSelector } from 'utils/reselect'
import { getSavedSearchesData } from './saved_searches'

export const getSavedCharts = createImmutableSelector(
  state => state.savedCharts.get('data'),
  getSavedSearchesData,
  (savedCharts, savedSearches) => (
    savedCharts.map(savedChart => (
      savedChart.setIn(['chart', 'savedSearch'], savedSearches.getIn(['entities', savedChart.getIn(['chart', 'savedSearchId'])]))
    ))
  )
)

export const getSavedChartsChart = state => state.savedCharts.getIn(['chart', 'data'])
export const getSavedChartsChartData = (state, id) => {
  const savedChart = getSavedChartsChart(state)

  if (savedChart.get('id') === id) {
    return savedChart.getIn(['chart', 'data'])
  }

  return null
}

export const getSavedChartsUi = state => state.savedCharts.get('ui')
export const getSavedChartsSaving = state => getSavedChartsUi(state).get('saving')
export const getSavedChartsShowDialog = state => getSavedChartsUi(state).get('showDialog')
