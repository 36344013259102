import { connect } from 'react-redux'

import {
  saveContentFormlessStart as onChange
} from 'actions/content_desk'

import ContentApprovalStatusSelect from 'components/content_desk_new/contents/content_approval_status_select'

const mapStateToProps = (_state, ownProps) => ({
  content: ownProps.content
})

export default connect(
  mapStateToProps,
  {
    onChange
  }
)(ContentApprovalStatusSelect)

