import { connect } from 'react-redux'

import Anewstip from 'components/anewstip'

import {
  fetchStaticsStart as fetchStatics,
  clearModuleData as onUnmount
} from 'actions/anewstip'

import { getAnewstipApiKey } from 'selectors/config'

import {
  getAnewstipStaticsRequestRunning,
  getAnewstipHasStatics,
  getAnewstipSearchRequestRunning,
  getIsInitialSearch
} from 'selectors/anewstip'

const mapStateToProps = state => ({
  apiKey: getAnewstipApiKey(state),
  hasStatics: getAnewstipHasStatics(state),
  staticsRequestRunning: getAnewstipStaticsRequestRunning(state),
  searchRequestRunning: getAnewstipSearchRequestRunning(state),
  isInitialSearch: getIsInitialSearch(state)
})

export default connect(
  mapStateToProps,
  {
    fetchStatics,
    onUnmount
  }
)(Anewstip)
