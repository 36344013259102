import { connect } from 'react-redux'

import SavedChart from 'components/administration/saved_charts/saved_chart'

import { getSavedChartsChartData } from 'selectors'

const mapStateToProps = (state, { savedChart }) => ({
  savedChart,
  data: getSavedChartsChartData(state, savedChart.get('id'))
})

export default connect(mapStateToProps)(SavedChart)
