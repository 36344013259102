import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { Select, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  sorting: {
    display: 'flex',
    maxWidth: 250,
    marginRight: 15,
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    '& > div:first-of-type': {
      minWidth: 200,
      [theme.breakpoints.only('xs')]: {
        minWidth: '30vw'
      }
    },
    '& > *:last-child': {
      top: 10
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export default function Sorting({ i18n, sortBy, sortDirection, noHits, onSortByChange, onSortDirectionChange }) {
  if (noHits) {
    return null
  }

  const { classes } = useStyles()

  const options = [
    { value: 'crawlDate', label: i18n.get('date') },
    { value: 'hackishness', label: i18n.get('hackishness') },
    {
      value: 'relevance', label: i18n.get('relevance'), title: i18n.get('relevance_description')
    }
  ]

  const selected = options.find(opt => {
    if (sortBy === '' && opt.value === 'crawl_date') {
      return true
    }

    if (sortBy === opt.value) {
      return true
    }

    return false
  })

  if (!selected) {
    onSortByChange(options[0].value)

    return null
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sorting}>
        <Select
          displayEmpty
          label={i18n.get('sort_by')}
          options={options}
          onChange={value => onSortByChange(value.value)}
          value={selected.value}
        />
        <IconButton
          title={i18n.get(sortDirection === 'desc' ? 'descending' : 'ascending')}
          onClick={() => onSortDirectionChange(sortDirection === 'desc' ? 'asc' : 'desc')}
          size="large"
        >
          {sortDirection === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
        </IconButton>
      </div>

    </div>
  )
}

Sorting.propTypes = {
  i18n: PropTypes.object.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  noHits: PropTypes.bool.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  onSortDirectionChange: PropTypes.func.isRequired
}
