import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  topic: null,
  mediaType: null,
  country: null,
  state: null,
  city: '',
  journalists: {
    facetFilters: {},
    pagination: {
      page: 1,
      pageSize: 20
    },
    sort: {
      by: 'influence_score',
      direction: 'desc'
    }
  },
  outlets: {
    facetFilters: {},
    pagination: {
      page: 1,
      pageSize: 20
    },
    sort: {
      by: 'influence_score',
      direction: 'desc'
    }
  },
  articles: {
    facetFilters: {},
    pagination: {
      cursor: '',
      pageSize: 20
    },
    sort: {
      by: 'influence_score',
      direction: 'desc'
    }
  }
})

export default handleActions({
  [Actions.setFiltersForm]: (state, { payload: { key, value } }) => state.set(key, value),
  [Actions.resetEntityFilters]: state => state
    .set('journalists', initialState.get('journalists'))
    .set('outlets', initialState.get('outlets'))
    .set('articles', initialState.get('articles')),
  [Actions.resetPaginationFilter]: (state, { payload }) => state
    .setIn([payload, 'pagination'], initialState.getIn([payload, 'pagination'])),
  [Actions.switchSearchType]: state => state
    .set('topic', null)
    .set('mediaType', null)
    .set('country', null)
    .set('state', null)
    .set('city', ''),
  [Actions.clearModuleData]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
