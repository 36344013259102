import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor'
import luminate from 'utils/luminate'
import { InputBase, Paper, Divider, IconButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { makeStyles } from 'tss-react/mui'

import WithDevice from 'containers/WithDevice'
import useDevice from 'hooks/useDevice'

const useStyles = makeStyles()({
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff'
  },
  input: {
    flex: 1,
    color: '#ffffff',
    width: '100%',
    '&::placeholder': {
      color: '#ffffff',
      opacity: '0.7 !important'
    }
  },
  divider: {
    width: 1,
    height: 28,
    margin: '4px 4px 4px 0',
    backgroundColor: 'rgba(255, 255, 255, 0.12)'
  },
  searchIcon: {
    padding: 6
  }
})

const calculateBgColor = color => luminate(color, -0.27)

const SearchBar = ({
  i18n,
  label,
  colors,
  queryTypeDropdown,
  onFocus,
  onBlur,
  onSubmit,
  onError,
  loading,
  accent,
  inherit,
  whitelabelTheme,
  autoFocus
}) => {
  const { classes } = useStyles()
  const theme = useTheme()
  const [query, setQuery] = useState('')
  const device = useDevice()

  useEffect(() => {
    if (loading && query !== '') {
      setQuery('')
    }
  }, [loading, query])

  const submit = () => {
    if (query.charAt(0) === '-') {
      return onError({ text: i18n.get('invalid_query'), success: false })
    }

    return onSubmit(query.trim())
  }

  const style = useMemo(() => {
    let color = whitelabelTheme === 'plotlights' ? theme.palette.background.paper : colors.get('primary')
    color = accent ? colors.get('accent') : color
    color = inherit ? theme.palette.background.paper : color

    return ({
      backgroundColor: calculateBgColor(color)
    })
  }, [colors.get('primary')])

  return (
    <Paper
      className={classes.searchBar}
      elevation={1}
      style={style}
    >
      {queryTypeDropdown}
      <Divider className={classes.divider} />
      <InputBase
        className={classes.input}
        placeholder={device.get('gt-xs') ? i18n.get(label) : i18n.get('search')}
        onChange={e => setQuery(e.target.value)}
        autoFocus={autoFocus}
        onKeyUp={e => {
          if (e.keyCode === 13) {
            submit()
          }
        }}
        type="text"
        value={query}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{
          className: classes.inputElement
        }}
      />
      <IconButton
        classes={{ root: classes.searchIcon }}
        color="inherit"
        onClick={submit}
        title={i18n.get('refresh_search')}
        size="large"
      >
        <YoutubeSearchedForIcon />
      </IconButton>
    </Paper>
  )
}

SearchBar.propTypes = {
  i18n: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  colors: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  accent: PropTypes.bool,
  inherit: PropTypes.bool,
  whitelabelTheme: PropTypes.string,
  autoFocus: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  queryTypeDropdown: PropTypes.element
}

export default WithDevice()(SearchBar)
