export const sortGroupedNews = (groupedNews, pathToSortingField, order, type = 'string') => (
  groupedNews.map(group => {
    const sortLast = type === 'string' ? 'zzzzzzzzzzzzz' : 0
    let news = group.get('news')

    const distinctElements = news.map(n => n.getIn(pathToSortingField) || sortLast).toSet()

    // check if we really need to sort anything
    if (distinctElements.size > 1) {
      news = news.sortBy(n => {
        if (type === 'number') {
          return n.getIn(pathToSortingField) || sortLast
        }

        return (n.getIn(pathToSortingField) || sortLast).toString().toLowerCase()
      })

      if (order === 'desc') {
        news = news.reverse()
      }
    }

    return group.set('news', news.map(n => n.get('id')))
  })
)
