import { connect } from 'react-redux'

import SelectedContactsBar from 'components/contact_management/selected_contacts_bar'
import { clearListData, openDistributionListCreateDialog, openContactDeleteDialog } from 'actions/contact_management'
import { getCmListData, getCmContactDialogsOpen, getCDeskIsDistributionListsLocked } from 'selectors'

const mapStateToProps = state => ({
  contacts: getCmListData(state).get('contacts'),
  dialogOpen: getCmContactDialogsOpen(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(mapStateToProps, {
  onClear: clearListData,
  onClick: openDistributionListCreateDialog,
  onDelete: openContactDeleteDialog
})(SelectedContactsBar)
