import { connect } from 'react-redux'

import CampaignSelection from 'components/content_desk_new/contents/content_edit_dialog/campaign_selection'

import {
  updateContent,
  openCampaignForm,
  updateCampaignFormData,
  openClosedCampaignDialog
} from 'actions/content_desk'

import {
  getCDeskCampaignDropdownOptionsLoading,
  getCDeskCampaignDropdownOptions,
  getCDeskContentFormData
} from 'selectors'

const mapStateToProps = state => ({
  campaignOptionsLoading: getCDeskCampaignDropdownOptionsLoading(state),
  campaigns: getCDeskCampaignDropdownOptions(state),
  content: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    updateContent,
    openCampaignForm,
    updateCampaignFormData,
    openClosedCampaignDialog
  }
)(CampaignSelection)
