import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  articleTextContainer: {
    padding: '1rem 2rem'
  },
  articleText: {
    lineHeight: '1.7rem',
    fontSize: '0.9rem',
    columns: '3 auto'
  },
  articleTextNoColumns: {
    lineHeight: '1.7rem',
    fontSize: '0.9rem',
    whiteSpace: 'pre-line'
  }
})

export default function ArticleText({ text, useColumns }) {
  const { classes } = useStyles()
  const formattedText = (
    <div
      className={useColumns ? classes.articleText : classes.articleTextNoColumns}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )

  return (
    <div
      className={classes.articleTextContainer}
    >
      {formattedText}
    </div>
  )
}

ArticleText.propTypes = {
  text: PropTypes.string.isRequired,
  useColumns: PropTypes.bool
}

ArticleText.defaultProps = {
  useColumns: false
}
