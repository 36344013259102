import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import camelcaseKeysDeep from 'camelcase-keys-deep'

import * as Actions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

export default handleActions({
  [Actions.fetchNewsForMediaReviewStart]: () => initialState,
  [Actions.fetchNewsForMediaReviewSuccess]: (_state, { payload: { snapshot } }) => fromJS(snapshot),

  [Actions.setSnapshot]: (_state, { payload: snapshot }) => fromJS(camelcaseKeysDeep(fromJS(snapshot).toJS())),

  [AppActions.resetState]: () => initialState
}, initialState)
