import { put, takeEvery, select, all, call, debounce, delay, take, race } from 'redux-saga/effects'

import * as Actions from 'actions/navigation'
import * as EnvironmentActions from 'actions/environment'
import * as ConfigActions from 'actions/config'
import { pageView } from 'actions/ga'
import * as Selectors from 'selectors'
import { push, LOCATION_CHANGE } from 'redux-first-history'

export function* navigate({ payload: path }) {
  const search = yield select(Selectors.getSearchFromUrl)

  if (!path.startsWith('/')) {
    const currentPath = yield select(Selectors.getCurrentPath)
    yield put(push(`${currentPath}/${path}${search}`))
  } else {
    yield put(push(`${path}${search}`))
  }
}

export function* backToLogin() {
  yield put(Actions.navigate('/'))
}

export function* back({ payload: steps }) {
  const stepsBack = steps || 1
  const currentPath = yield select(Selectors.getCurrentPath)
  const parts = currentPath.split('/').filter(v => v).map(v => v.split('?'))
  const search = yield select(Selectors.getSearchFromUrl)

  for (let i = 0; i < stepsBack; i += 1) {
    parts.pop()
  }

  const newPath = [
    parts.map(v => v.join('')).join('/'),
    search.replace(/^\?/, '')
  ].filter(v => v).join('?')

  yield put(Actions.navigate(`/${newPath}`))
}

export function* redirectAuthenticated() {
  const isAuthenticated = yield select(Selectors.getIsAuthenticated)

  if (isAuthenticated) {
    const route = yield select(Selectors.getFallbackRoute)
    yield put(push(route))
  }
}

export function* locationChange({ payload: { location: { pathname } } }) {
  const redirect = yield select(Selectors.getRedirectParam)

  if (pathname === '/') {
    if (!redirect) {
      yield put(ConfigActions.setStartedWithDeepLink(false))
    }

    const environment = yield select(Selectors.getEnvironment)

    if (!environment.get('environmentIsLoaded')) {
      yield race({
        success: take(EnvironmentActions.stageRequestSuccess),
        timeout: delay(5000)
      })
    }

    yield call(redirectAuthenticated)
  } else if (pathname.startsWith('/direct_login')) {
    if (!redirect) {
      yield put(ConfigActions.setStartedWithDeepLink(false))
    }
  }
}

export function* trackLocationChange({ payload: { location: { pathname } } }) {
  let path = pathname

  if (path && path.startsWith('/direct_login')) {
    path = '/direct_login'
  }

  yield put(pageView(path))
}

export function* watchNavigate() {
  yield takeEvery(Actions.navigate, navigate)
}

export function* watchBackToLogin() {
  yield takeEvery(Actions.backToLogin, backToLogin)
}

export function* watchBack() {
  yield takeEvery(Actions.back, back)
}

export function* watchLocationChange() {
  yield takeEvery(LOCATION_CHANGE, locationChange)
}

export function* watchTrackLocationChange() {
  yield debounce(50, LOCATION_CHANGE, trackLocationChange)
}

export function* watchRedirectAuthenticated() {
  yield takeEvery(Actions.redirectAuthenticated, redirectAuthenticated)
}

export default function* navigationSaga() {
  yield all([
    watchNavigate(),
    watchBackToLogin(),
    watchBack(),
    watchLocationChange(),
    watchTrackLocationChange(),
    watchRedirectAuthenticated()
  ])
}
