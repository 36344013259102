import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

import { RaisedPrimaryButton, IconButton } from 'containers/themed'
import TagList from 'containers/contact_management/TagList'

export default function TagsDialog({ open, onClose }) {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const handleClose = () => onClose()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(3),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('tags')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: 0 }}
      >
        <TagList />
      </DialogContent>

      <DialogActions>
        <Box
          m={2}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <RaisedPrimaryButton onClick={handleClose}>
            {i18n.get('close')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

TagsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
