import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import DeleteIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from 'containers/themed'
import DummyCardIcon from 'components/dummy_card_icon'
import Card from 'components/card'

import masterConfig from 'static/master_config'

import { Typography, Tooltip, CardContent, CardActions, CardActionArea } from '@mui/material'

const useStyles = makeStyles()({
  cardMedia: {
    minHeight: 200
  },
  images: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  image: {
    width: '50%',
    height: 'calc(200px / 3)'
  },
  disabled: {
    opacity: 0.2
  }
})

export default function Group({
  i18n,
  group,
  disabled,
  onDeleteClick,
  onEditClick,
  onAddCustomTagClick,
  onFilterClick,
  filterBySelectedCustomTagGroup
}) {
  const { classes } = useStyles()
  const getCustomTags = () => group.get('customTagsCustomTagGroups').map(c => c.get('customTag'))

  const handleClick = onClick => () => onClick(group)

  const renderImages = () => {
    const customTags = getCustomTags()

    const images = customTags.sortBy(c => (c.get('photo') ? 0 : 1)).slice(0, 6)

    return (
      <div className={classes.images}>
        {images.map(c => (
          <DummyCardIcon
            preview
            className={classes.image}
            icon={masterConfig.getIn(['icons', 'customTags'])}
            key={c.get('id')}
            image={c.get('photo')}
          />
        ))}
      </div>
    )
  }

  const renderInfo = () => {
    const customTags = getCustomTags()

    if (customTags.isEmpty()) {
      return (
        <Typography>
          {i18n.get('no_custom_tags_in_group')}
        </Typography>
      )
    }

    return (
      <Typography component="p">
        {i18n.get('custom_tags_count', { n: customTags.size })}
      </Typography>
    )
  }

  return (
    <Card className={disabled ? classes.disabled : null}>
      <CardActionArea onClick={() => onFilterClick(group.get('id'))}>
        <div className={classes.cardMedia}>
          {renderImages()}
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
          >
            {group.get('name')}
          </Typography>

          {renderInfo()}
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Tooltip
          placement="top"
          title={i18n.get('filter_by_custom_tag_group')}
        >
          <IconButton
            classes={{ root: classes.iconButton }}
            icon="remove_red_eye"
            onClick={() => filterBySelectedCustomTagGroup(group)}
            size="large"
          />
        </Tooltip>
        <Tooltip title={i18n.get('edit')}>
          <div>
            <IconButton
              disabled={group.get('auto')}
              onClick={handleClick(onEditClick)}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={i18n.get('delete')}>
          <div>
            <IconButton
              disabled={group.get('auto')}
              onClick={handleClick(onDeleteClick)}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip title={i18n.get('add_remove_custom_tags')}>
          <div>
            <IconButton
              disabled={group.get('auto')}
              onClick={() => onAddCustomTagClick(group.get('id'))}
              size="large"
            >
              <PlaylistAddIcon />
            </IconButton>
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

Group.propTypes = {
  i18n: PropTypes.object.isRequired,
  group: PropTypes.instanceOf(Map),
  disabled: PropTypes.bool.isRequired,

  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onAddCustomTagClick: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func.isRequired,
  filterBySelectedCustomTagGroup: PropTypes.func.isRequired
}
