import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Toolbar,
  Typography,
  AppBar,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save'

import { Button, IconButton, RaisedPrimaryButton } from 'containers/themed'
import EmailEditor from 'containers/content_desk_new/contents/content_edit_dialog/editor/EmailEditor'
import TemplatesEditorVariables from 'containers/email_templates_library/TemplatesEditorVariables'

import { Capabilities } from 'static/constants'

const useStyles = makeStyles()({
  hidden: {
    position: 'absolute !important',
    left: '-9999px !important',
    top: '-9999px !important'
  },
  emailEditorContainer: {
    marginTop: '10px'
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh'
  },
  spacer: {
    flex: 1
  },
  saveIcon: {
    marginLeft: 5
  },
  close: {
    color: 'white !important',
    marginRight: 5
  },
  closeSaveDialogButton: {
    position: 'absolute',
    right: 7,
    top: 7
  },
  saveBtn: {
    marginLeft: '10px'
  }
})

const TemplatesEditorDialog = ({
  emailEditorLoading,
  isEditorDialogOpen,
  isSaveDialogOpen,
  data,
  loading,
  capabilities,
  closeEditorDialog,
  openSaveDialog,
  closeSaveDialog,
  onChange,
  onSave
}) => {
  const { classes } = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const i18n = useI18n()
  const htmlData = data.get('html') || ''
  const isEditorEmpty = htmlData.length < 1
  const requiredFields = ['name']
  const hasPublicWriteRights = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_WRITE_RIGHTS)

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })
  const handleChangeCheckbox = e => onChange({ key: e.target.name, value: e.target.checked })

  const handleSave = () => {
    if (requiredFields.map(field => !!data.get(field)).some(value => !value)) {
      requiredFields.map(field => {
        const key = field

        if (!data.get(field)) {
          enqueueSnackbar(
            `${i18n.get(key)} ${i18n.get('is_required')}`,
            { variant: 'error', preventDuplicate: true }
          )
        }

        return true
      })

      return false
    }

    closeSnackbar()

    return onSave()
  }

  const renderSaveDialog = () => (
    <Dialog
      maxWidth="sm"
      fullWidth
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
      open={isSaveDialogOpen}
    >
      <IconButton
        className={classes.closeSaveDialogButton}
        icon="close"
        onClick={() => closeSaveDialog()}
        size="normal"
        disabled={loading}
      />
      <DialogTitle className={classes.saveDialogTitle}>
        {`${i18n.get('save')} ${i18n.get('template')}`}
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label={i18n.get('name')}
          name="name"
          value={data.get('name') || ''}
          onChange={handleChange}
          margin="normal"
          variant="standard"
          size="small"
          disabled={loading}
        />
        {hasPublicWriteRights && (
          <FormControlLabel
            name="shared"
            control={(
              <Checkbox
                checked={data.get('shared')}
                onChange={handleChangeCheckbox}
                disabled={loading}
              />
            )}
            label={i18n.get('public')}
          />
        )}
        <FormControlLabel
          name="released"
          control={(
            <Checkbox
              checked={data.get('template_released')}
              onChange={handleChangeCheckbox}
              disabled={loading}
            />
          )}
          label={i18n.get('template_released')}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => closeSaveDialog()}
        >
          {i18n.get('cancel')}
        </Button>
        <RaisedPrimaryButton
          loading={loading}
          variant="contained"
          onClick={handleSave}
          disabled={loading}
        >
          {i18n.get('confirm')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      <Dialog
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        open={isEditorDialogOpen}
        fullScreen
      >
        <AppBar
          enableColorOnDark
          position="static"
        >
          <Toolbar>
            <IconButton
              classes={{ root: classes.close }}
              icon="close"
              onClick={() => closeEditorDialog()}
              size="normal"
            />
            <Typography
              variant="h6"
              color="inherit"
            >
              {i18n.get('template_editor')}
            </Typography>
            <div className={classes.spacer} />
            <TemplatesEditorVariables />
            <Button
              className={classes.saveBtn}
              variant="contained"
              onClick={() => openSaveDialog()}
              disabled={emailEditorLoading || isEditorEmpty}
            >
              {i18n.get('save')}
              <SaveIcon
                className={classes.saveIcon}
                fontSize="small"
              />
            </Button>
          </Toolbar>
        </AppBar>
        {
          emailEditorLoading && (
            <div className={classes.circularLoader}>
              <CircularProgress
                variant="indeterminate"
                size={70}
              />
            </div>
          )
        }
        <div
          className={
            emailEditorLoading
              ? classes.hidden
              : classes.emailEditorContainer
          }
        >
          <EmailEditor
            onChange={onChange}
            moduleScope="templates"
            data={data}
            editorHeight="820px"
          />
        </div>
      </Dialog>
      {renderSaveDialog()}
    </>
  )
}

TemplatesEditorDialog.propTypes = {
  emailEditorLoading: PropTypes.bool.isRequired,
  isEditorDialogOpen: PropTypes.bool.isRequired,
  isSaveDialogOpen: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  closeEditorDialog: PropTypes.func.isRequired,
  openSaveDialog: PropTypes.func.isRequired,
  closeSaveDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default TemplatesEditorDialog
