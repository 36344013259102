import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import WithColors from 'containers/WithColors'

import { Icon, CardMedia } from '@mui/material'

const useStyles = makeStyles()({
  dummyIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  dummyIcon: {
    color: '#ffffff',
    fontSize: '70pt !important'
  },
  dummyIconPreview: {
    color: '#ffffff',
    fontSize: '10pt !important'
  },
  media: {
    height: '100%',
    width: '100%'
  }
})

const DummyCardIcon = ({ icon, accentColor, image, preview, className, style, onClick }) => {
  const { classes, cx } = useStyles()
  const newStyle = { backgroundColor: accentColor, ...(style || {}) }

  if (!image && icon) {
    return (
      <div
        className={cx(classes.media, className)}
        style={newStyle}
      >
        <div className={classes.dummyIconContainer}>
          <Icon className={preview ? classes.dummyIconPreview : classes.dummyIcon}>
            {icon}
          </Icon>
        </div>
      </div>
    )
  }

  return (
    <CardMedia
      image={image}
      className={cx(classes.media, className)}
      style={newStyle}
      onClick={onClick}
    />
  )
}

DummyCardIcon.defaultProps = {
  preview: false
}

DummyCardIcon.propTypes = {
  icon: PropTypes.string,
  image: PropTypes.string,
  accentColor: PropTypes.string.isRequired,
  preview: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
}

export default WithColors()(DummyCardIcon)
