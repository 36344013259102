import { createAction } from 'redux-actions'

const prefix = 'USER/'

export const setUser = createAction(`${prefix}SET_USER`)
export const setLocale = createAction(`${prefix}SET_LOCALE`)

export const updateUserRequestError = createAction(`${prefix}UPDATE_USER_REQUEST_ERROR`)
export const updateUserRequestStart = createAction(`${prefix}UPDATE_USER_REQUEST_START`)
export const updateUserRequestSuccess = createAction(`${prefix}UPDATE_USER_SUCCESS`)

export const forgotPasswordRequestError = createAction(`${prefix}FORGOT_PASSWORD_REQUEST_ERROR`)
export const forgotPasswordRequestStart = createAction(`${prefix}FORGOT_PASSWORD_REQUEST_START`)
export const forgotPasswordRequestSuccess = createAction(`${prefix}FORGOT_PASSWORD_REQUEST_SUCCESS`)

export const resetPasswordRequestError = createAction(`${prefix}RESET_PASSWORD_REQUEST_ERROR`)
export const resetPasswordRequestStart = createAction(`${prefix}RESET_PASSWORD_REQUEST_START`)
export const resetPasswordRequestSuccess = createAction(`${prefix}RESET_PASSWORD_REQUEST_SUCCESS`)

export const registerUserRequestError = createAction(`${prefix}REGISTER_USER_REQUEST_ERROR`)
export const registerUserRequestStart = createAction(`${prefix}REGISTER_USER_REQUEST_START`)
export const registerUserRequestSuccess = createAction(`${prefix}REGISTER_USER_REQUEST_SUCCESS`)

export const activateUserStart = createAction(`${prefix}ACTIVATE_USER_START`)
export const activateUserError = createAction(`${prefix}ACTIVATE_USER_ERROR`)

export const reset = createAction(`${prefix}RESET`)

export const setCookieOption = createAction(`${prefix}SET_COOKIE_OPTION`)

export const setDarkMode = createAction(`${prefix}SET_DARK_MODE`)
export const setPrefersDarkMode = createAction(`${prefix}SET_PREFERS_DARK_MODE`)
export const setField = createAction(`${prefix}SET_FIELD`)
