import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import decamelize from 'decamelize'

import Card from 'components/card'
import ColorPicker from 'components/color_picker'
import { mapToOptions } from 'utils/autocomplete'
import CustomTag from 'containers/custom_tags/CustomTag'
import { Dialog, Input, Button, Autocomplete } from 'containers/themed'
import { Capabilities } from 'static/constants'
import CapSafe from 'containers/CapSafe'
import PhotoUpload from 'components/photo_upload'
import MultiLanguageNameForm from 'components/multi_language_name_form'

import CustomTagAssistantComp from 'containers/custom_tags/custom_tag_dialog/CustomTagAssistant'

import useI18n from 'hooks/useI18n'

import { CardMedia, Checkbox, FormControlLabel, Typography } from '@mui/material'

const useStyles = makeStyles()({
  assistant: {
    marginTop: 20
  },
  preview: {
    marginTop: 10,
    marginBottom: 20,
    width: 300
  },
  queryInputContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    '& > div:first-of-type': {
      width: '100%'
    }
  },
  cardMedia: {
    width: '100%',
    '&:after': {
      paddingTop: '56.25%',
      content: '""',
      display: 'block',
      height: 0
    }
  },
  customTagColor: {
    backgroundColor: 'white',
    marginBottom: 5,
    marginRight: 10
  },
  deleteIcon: {
    color: 'white'
  },
  button: {
    float: 'right',
    marginBottom: -20
  }
})

const RssFormControlLabel = CapSafe(FormControlLabel, Capabilities.HAS_CUSTOM_TAG_RSS_EXPORT)
const XmlFormControlLabel = CapSafe(FormControlLabel, Capabilities.HAS_CUSTOM_TAG_XML_EXPORT)
const JsonFormControlLabel = CapSafe(FormControlLabel, Capabilities.HAS_CUSTOM_TAG_JSON_EXPORT)
const CustomTagAssistant = CapSafe(CustomTagAssistantComp, Capabilities.HAS_CUSTOM_TAG_ASSISTANT)
const ColorPickerContainer = CapSafe(props => <div {...props} />, Capabilities.HAS_CHARTS_MODULE, Capabilities.HAS_DASHBOARD_MODULE)
const ExportDeprecationWarning = CapSafe(
  props => <div {...props} />,
  Capabilities.HAS_CUSTOM_TAG_RSS_EXPORT,
  Capabilities.HAS_CUSTOM_TAG_XML_EXPORT,
  Capabilities.HAS_CUSTOM_TAG_JSON_EXPORT
)

export default function CustomTagDialog({
  active,
  customTag,
  groups,
  module,
  onHide,
  onPhotoChange,
  onSave,
  onChange,
  selectedCustomTagGroups,
  isInParentDialog,
  hideParentDialog
}) {
  const { classes, cx } = useStyles()
  const i18n = useI18n()
  const [previewUrl, setPreviewUrl] = useState('')

  const hide = () => {
    setPreviewUrl('')
    onHide()
  }

  const handleOk = () => {
    hide()
    onSave()

    if (isInParentDialog) {
      hideParentDialog()
    }
  }

  const handleChange = name => event => {
    let newValue

    if (name === 'customTagGroupIds') {
      if (event && event.length) {
        newValue = List(event.map(e => e.value))
      } else {
        newValue = List([])
      }
    } else {
      newValue = event.target.value
    }

    onChange(customTag.set(name, newValue))
  }

  const handleCheckboxChange = name => event => {
    if (name === 'xmlEnabled') {
      onChange(customTag.merge({
        [name]: event.target.checked,
        rssEnabled: false,
        jsonEnabled: false
      }))
    }

    if (name === 'rssEnabled') {
      onChange(customTag.merge({
        [name]: event.target.checked,
        xmlEnabled: false,
        jsonEnabled: false
      }))
    }

    if (name === 'jsonEnabled') {
      onChange(customTag.merge({
        [name]: event.target.checked,
        xmlEnabled: false,
        rssEnabled: false
      }))
    }
  }

  let title = `${i18n.get('edit').toUpperCase()}: ${customTag.get('name').toUpperCase()}`

  if (!customTag.get('id')) {
    title = i18n.get('create_pin_board').toUpperCase()
  }

  const renderActions = () => (
    <>
      {!isInParentDialog && (
        <Button
          onClick={() => onHide()}
        >
          {i18n.get('cancel')}
        </Button>
      )}

      <Button
        variant="contained"
        color="primary"
        className={cx(isInParentDialog && classes.button)}
        disabled={!customTag.get('nameEn')}
        onClick={handleOk}
      >
        {i18n.get('save')}
      </Button>
    </>
  )

  const renderNameInput = (key, required) => (
    <Input
      value={customTag.get(key)}
      label={i18n.get(decamelize(key))}
      required={required}
      onChange={handleChange(key)}
    />
  )
  const renderContent = () => (
    <div>
      <MultiLanguageNameForm renderNameInput={renderNameInput} />
      <br />
      <br />
      <Input
        value={customTag.get('description') || ''}
        label={i18n.get('description')}
        onChange={handleChange('description')}
      />
      <br />
      <br />

      {!groups.isEmpty() && (
        <Autocomplete
          key="select_custom_tag_groups"
          variant="outlined"
          isMulti
          isClearable
          onChange={handleChange('customTagGroupIds')}
          options={mapToOptions(groups)}
          value={mapToOptions(selectedCustomTagGroups)}
          placeholder=""
          label={i18n.get('select_custom_tag_groups')}
        />
      )}

      <ColorPickerContainer>
        <br />
        <Typography>{i18n.get('color_in_charts')}:</Typography>
        <ColorPicker
          onChange={color => onChange(customTag.set('color', color))}
          onReset={() => onChange(customTag.set('color', null))}
          defaultValue="#FFFFFF"
          value={customTag.get('color')}
        />
      </ColorPickerContainer>

      <br />

      <PhotoUpload
        photoUrl={previewUrl}
        onFileChange={onPhotoChange}
        onFileUrlChange={setPreviewUrl}
        onDelete={() => {
          onChange(customTag.set('photo', null))
        }}
      />

      {!(previewUrl || customTag.get('photo')) ? (
        <div className={classes.preview}>
          <CustomTag
            customTag={customTag}
            imageOnly
          />
        </div>
      ) : (
        <Card className={classes.preview}>
          <CardMedia
            classes={{ root: classes.cardMedia }}
            image={previewUrl || customTag.get('photo')}
          />
        </Card>
      )}

      <ExportDeprecationWarning>
        {i18n.get('feature_is_deprecated')}
      </ExportDeprecationWarning>

      <XmlFormControlLabel
        disabled={!customTag.get('xmlEnabled')}
        label={i18n.get('function_as_xml')}
        control={(
          <Checkbox
            checked={customTag.get('xmlEnabled')}
            onChange={handleCheckboxChange('xmlEnabled')}
          />
        )}
      />

      <JsonFormControlLabel
        disabled={!customTag.get('jsonEnabled')}
        label={i18n.get('function_as_json')}
        control={(
          <Checkbox
            checked={customTag.get('jsonEnabled')}
            onChange={handleCheckboxChange('jsonEnabled')}
          />
        )}
      />

      <RssFormControlLabel
        disabled={!customTag.get('rssEnabled')}
        label={i18n.get('function_as_rss')}
        control={(
          <Checkbox
            checked={customTag.get('rssEnabled')}
            onChange={handleCheckboxChange('rssEnabled')}
          />
        )}
      />

      {module !== 'news_pool' && <CustomTagAssistant />}
    </div>
  )

  return (
    <div>
      {!isInParentDialog && (
        <Dialog
          open={active}
          title={title}
          onClose={hide}
          actions={renderActions()}
        >
          <br />
          {renderContent()}
        </Dialog>
      )}

      {isInParentDialog && (
        <div>
          {renderContent()}
          {renderActions()}
        </div>
      )}
    </div>
  )
}

CustomTagDialog.defaultProps = {
  isInParentDialog: false
}

CustomTagDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  customTag: PropTypes.instanceOf(Map).isRequired,
  groups: PropTypes.instanceOf(List).isRequired,
  selectedCustomTagGroups: PropTypes.instanceOf(List).isRequired,
  module: PropTypes.string.isRequired,
  isInParentDialog: PropTypes.bool,

  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPhotoChange: PropTypes.func.isRequired,
  hideParentDialog: PropTypes.func
}
