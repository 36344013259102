import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { useSnackbar } from 'notistack'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Table,
  TableContainer,
  TableBody
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ContactsListItem from 'containers/contact_management/ContactsListItem'
import { Button, RaisedPrimaryButton } from 'containers/themed'

export default function DistributionListEditDialog({
  open,
  onClose,
  list,
  onUpdate,
  onSubmit,
  loading,
  newListName
}) {
  const i18n = useI18n()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [changed, setChanged] = useState(false)

  const onListChange = event => {
    setChanged(true)
    onUpdate({ key: event.target.name, value: event.target.value })
  }
  const handleClose = () => {
    setChanged(false)
    closeSnackbar()
    onClose()
  }

  const handleSave = () => {
    if (newListName === '') {
      return enqueueSnackbar(
        `${i18n.get('name')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    return onSubmit()
  }

  useEffect(() => {
    if (!open) {
      setChanged(false)
    }
  }, [open])

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('distribution_list')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: t => t.spacing(3) }}
      >

        <TextField
          fullWidth
          name="name"
          label={i18n.get('name')}
          defaultValue={list.get('name')}
          onChange={onListChange}
        />

        <TextField
          multiline
          minRows={4}
          fullWidth
          label={i18n.get('description')}
          name="description"
          value={list.get('description') ? list.get('description') : ''}
          onChange={onListChange}
          margin="normal"
          size="small"
          inputProps={{ maxLength: 1000 }}
        />

        <TableContainer
          sx={{ backgroundColor: t => t.palette.grey[200], marginTop: t => t.spacing(2) }}
        >
          <Table
            sx={{ minWidth: 650 }}
            size="medium"
          >
            <TableBody>
              {list.get('contacts').map(contact => (
                <ContactsListItem
                  contact={contact}
                  key={`contact__${contact.get('id')}`}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>
      <DialogActions sx={{ padding: t => t.spacing(2) }}>
        <Button
          disabled={loading}
          onClick={handleClose}
        >
          {i18n.get('close')}
        </Button>
        {changed && (
          <RaisedPrimaryButton
            disabled={loading || !changed}
            loading={loading}
            onClick={handleSave}
          >
            {i18n.get('save')}
          </RaisedPrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

DistributionListEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  newListName: PropTypes.string.isRequired
}
