import { all } from 'redux-saga/effects'
import mainSaga from './main'
import filterSaga from './filter'
import searchSaga from './search'

export default function* darknetSaga() {
  yield all([
    mainSaga(),
    filterSaga(),
    searchSaga()
  ])
}
