/* global FileReader */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { makeStyles } from 'tss-react/mui'
import DeleteIcon from '@mui/icons-material/Delete'
import { BrowseButton, IconButton } from 'containers/themed'
import useI18n from 'hooks/useI18n'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()({
  withDelete: {
    display: 'flex',
    alignItems: 'center',

    '& > div:first-of-type': {
      marginRight: 5
    }
  },
  fileSizeError: {
    marginTop: 5,
    fontSize: 14,
    color: red[500]
  }
})

export default function PhotoUpload({
  buttonProps,
  label,
  maxSize,
  onFileChange,
  onFileUrlChange,
  photoUrl,
  onDelete
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [fileSizeError, setFileSizeError] = useState(false)

  const changePhoto = ({ target: { files: [file] } }) => {
    let doChange = true

    if (!file) {
      doChange = false
    }

    if (file && maxSize && file.size > maxSize) {
      doChange = false
      setFileSizeError(true)
    }

    if (doChange) {
      onFileChange(file)
      const reader = new FileReader()
      reader.onload = event => {
        if (photoUrl !== event.target.result) {
          onFileUrlChange(event.target.result)
        }
      }

      reader.readAsDataURL(file)
      setFileSizeError(false)
    }
  }

  const uploadButton = (
    <BrowseButton
      accept="image/*"
      {...buttonProps}
      onChange={changePhoto}
    >
      <CloudUploadIcon />&nbsp;{label || i18n.get('upload_photo')}
    </BrowseButton>
  )

  const handleDelete = () => {
    onFileChange(null)
    onFileUrlChange('')
    onDelete()
    setFileSizeError(false)
  }

  if (!onDelete) {
    return (
      <>
        <div>
          {uploadButton}
        </div>
        {fileSizeError && (
          <div className={classes.fileSizeError}>{i18n.get('error_filesize_exceeded')}</div>
        )}
      </>
    )
  }

  return (
    <>
      <div className={classes.withDelete}>
        {uploadButton}

        <IconButton
          onClick={handleDelete}
          title={i18n.get('delete')}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </div>
      {fileSizeError && (
        <div className={classes.fileSizeError}>{i18n.get('error_filesize_exceeded')}</div>
      )}
    </>
  )
}

PhotoUpload.defaultProps = {
  buttonProps: {
    variant: 'contained',
    color: 'primary'
  },
  maxSize: null,
  onFileUrlChange: () => null
}

PhotoUpload.propTypes = {
  photoUrl: PropTypes.string,
  buttonProps: PropTypes.object,
  label: PropTypes.string,
  maxSize: PropTypes.number,

  onFileChange: PropTypes.func.isRequired,
  onFileUrlChange: PropTypes.func,
  onDelete: PropTypes.func
}
