import { handleActions, combineActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'

import googleAnalyticsChartConfig from 'static/google_analytics_charts'
import mozChartConfig from 'static/moz_charts'
import youtubeAnalyticsChartConfig, { getThirdLevelByFirstLevel, getTypeByFirstLevel } from 'static/youtube_analytics_charts'
import linkedInAnalyticsChartConfig from 'static/linked_in_analytics_charts'
import facebookAnalyticsChartConfig from 'static/facebook_analytics_charts'
import twitterAnalyticsChartConfig from 'static/twitter_analytics_charts'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'
import { NewsPageModules, ChartDataSources, ChartTypes } from 'static/constants'
import { updateInterval } from 'utils/charts'
import {
  isPressrelationsNewsChart,
  isGoogleAnalyticsChart,
  isMozChart,
  isYoutubeAnalyticsChart,
  isLinkedInAnalyticsChart,
  isFacebookAnalyticsChart,
  isTwitterAnalyticsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart
} from 'utils/data_source'

const createNewChart = () => fromJS({
  firstLevel: 'channels',
  dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
  dateRange: 'last_7_days',
  type: 'bar'
})

export const initialState = createNewChart()

export default handleActions({
  [Actions.setSelectedChart]: (state, { payload: chart }) => {
    if (state.get('dataSource') !== chart.get('dataSource')) {
      if (isPressrelationsNewsChart(chart)) {
        return createNewChart()
      }

      if (isGoogleAnalyticsChart(chart)) {
        return googleAnalyticsChartConfig.getIn([0, 'chart'])
      }

      if (isMozChart(chart)) {
        return mozChartConfig.getIn([0, 'chart'])
      }

      if (isYoutubeAnalyticsChart(chart)) {
        return youtubeAnalyticsChartConfig.getIn([0, 'chart'])
      }

      if (isLinkedInAnalyticsChart(chart)) {
        return linkedInAnalyticsChartConfig.getIn([0, 'chart'])
      }

      if (isFacebookAnalyticsChart(chart)) {
        return facebookAnalyticsChartConfig.getIn([0, 'chart'])
      }

      if (isTwitterAnalyticsChart(chart)) {
        return twitterAnalyticsChartConfig.getIn([0, 'chart'])
      }

      if (isImageChart(chart)) {
        return fromJS({
          dataSource: ChartDataSources.IMAGE,
          imageUrl: chart.get('imageUrl'),
          type: ChartTypes.IMAGE,
          label: chart.get('label'),
          topLabel: chart.get('topLabel')
        })
      }

      if (isTextChart(chart)) {
        return chart.merge({
          dataSource: ChartDataSources.TEXT,
          type: ChartTypes.TEXT,
          loading: false
        })
      }

      if (isExternalWidgetChart(chart)) {
        return chart.merge({
          dataSource: ChartDataSources.EXTERNAL_WIDGET,
          type: ChartTypes.EXTERNAL_WIDGET,
          loading: false
        })
      }
    }

    return chart
  },
  [Actions.setSelectedChartOpt]: (state, { payload: { key, value } }) => state.update('opts', opts => (opts || Map({})).set(key, value)),

  [Actions.selectGoogleAnalyticsView]: (state, { payload: view }) => (
    state.update('opts', opts => (opts || Map({})).update('googleAnalytics', ga => (ga || Map({})).merge({
      viewId: view.get('id'),
      viewName: view.get('name')
    })))
  ),

  [Actions.selectMozTargets]: (state, { payload: targets }) => (
    state.update('opts', opts => (opts || Map({})).update('moz', ga => (ga || Map({})).set('targets', fromJS(targets))))
  ),

  [Actions.selectLinkedInAnalyticsOrganization]: (state, { payload: organization }) => (
    state.update('opts', opts => (opts || Map({})).update('linkedInAnalytics', ga => (ga || Map({})).merge({
      organizationId: organization.get('id'),
      organizationName: organization.get('name')
    })))
  ),

  [Actions.selectFacebookAnalyticsPage]: (state, { payload: page }) => (
    state.update('opts', opts => (opts || Map({})).update('facebookAnalytics', fba => (fba || Map({})).merge({
      pageId: page.get('id'),
      pageName: page.get('name')
    })))
  ),

  [Actions.selectSavedSearch]: (state, { payload: savedSearch }) => {
    const currentModule = state.getIn(['savedSearch', 'moduleName'])
    const newModule = savedSearch.get('moduleName')

    const newProps = {
      savedSearchId: savedSearch.get('id'),
      savedSearch
    }

    if (newModule === NewsPageModules.NEWS_POOL) {
      newProps.dateType = 'article'
    }

    if (state.get('additionalSavedSearchIds')) {
      newProps.additionalSavedSearchIds = state.get('additionalSavedSearchIds')

      if (currentModule !== newModule) {
        newProps.additionalSavedSearchIds = fromJS([])
      }

      newProps.additionalSavedSearchIds = newProps.additionalSavedSearchIds.filter(id => id !== savedSearch.get('id'))
    }

    return state
      .merge(fromJS(newProps))
      .delete('groupingType')
  },

  [Actions.selectAdditionalSavedSearches]: (state, { payload }) => state.set('additionalSavedSearchIds', fromJS(payload)),

  [Actions.selectDateRange]: (state, { payload: dateRange }) => updateInterval(state.merge({
    dateRange,
    dateFrom: null,
    dateTo: null
  })),
  [Actions.selectDate]: (state, { payload: { dateFrom, dateTo } }) => updateInterval(state.merge({
    dateRange: dateFrom && dateTo ? null : state.get('dateRange'),
    dateFrom,
    dateTo
  })),
  [Actions.selectDateMode]: (state, { payload: dateMode }) => {
    let dateRange = state.get('dateRange')
    let dateTo = state.get('dateTo')
    let dateFrom = state.get('dateFrom')

    if (dateMode === 'relative' && (!dateRange || !dateRange.startsWith('relative_range'))) {
      dateRange = 'relative_range_3_days'
      dateTo = null
      dateFrom = null
    } else if (dateMode === 'date_range' && (!dateRange || dateRange.startsWith('relative_range'))) {
      dateRange = 'today'
      dateTo = null
      dateFrom = null
    }

    return state.merge({
      dateRange,
      dateTo,
      dateFrom
    })
  },

  [Actions.selectDateType]: (state, { payload: dateType }) => state.set('dateType', dateType),
  [Actions.selectDateInterval]: (state, { payload: dateInterval }) => state.set('dateInterval', dateInterval),
  [Actions.selectThirdLevel]: (state, { payload: thirdLevel }) => state.set('thirdLevel', thirdLevel),
  [Actions.selectFirstLevel]: (state, { payload: firstLevel }) => {
    let thirdLevel = state.get('thirdLevel')
    let type = state.get('type')

    if (isYoutubeAnalyticsChart(state)) {
      thirdLevel = getThirdLevelByFirstLevel(firstLevel, thirdLevel)
      type = getTypeByFirstLevel(firstLevel, type)
    }

    return state.merge({
      firstLevel,
      type,
      thirdLevel
    })
  },

  [Actions.selectChartDialogGroupingType]: (state, { payload: groupingType }) => state.set('groupingType', groupingType),
  [Actions.selectChartDialogSortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [Actions.selectGoogleAnalyticsChart]: (state, { payload: value }) => {
    const data = googleAnalyticsChartConfig.find(c => c.get('value') === value)

    if (data) {
      return state.mergeDeep(data.get('chart'))
    }

    return state
  },
  [Actions.selectMozChart]: (state, { payload: value }) => {
    const data = mozChartConfig.find(c => c.get('value') === value)

    if (data) {
      return state.mergeDeep(data.get('chart'))
    }

    return state
  },
  [Actions.selectLinkedInAnalyticsChart]: (state, { payload: value }) => {
    const data = linkedInAnalyticsChartConfig.find(c => c.get('value') === value)

    if (data) {
      return state.mergeDeep(data.get('chart'))
    }

    return state
  },
  [combineActions(Actions.selectFacebookAnalyticsChart, Actions.setFacebookAnalyticsChart)]: (state, { payload: value }) => {
    const data = facebookAnalyticsChartConfig.find(c => c.get('value') === value)

    if (data) {
      return state.mergeDeep(data.get('chart'))
    }

    return state
  },

  [Actions.selectTwitterAnalyticsChart]: (state, { payload: value }) => {
    const data = twitterAnalyticsChartConfig.find(c => c.get('value') === value)

    if (data) {
      return state.mergeDeep(data.get('chart'))
    }

    return state
  },

  [Actions.fetchSavedSearchAggregationsStart]: (state, { payload }) => {
    const chart = payload ? payload.chart : state

    if (!isImageChart(chart) && !isTextChart(chart) && !isExternalWidgetChart(chart)) {
      return chart.set('loading', true)
    }

    return chart
  },
  [Actions.fetchSavedSearchAggregationsError]: state => state.set('loading', false),
  [Actions.fetchSavedSearchAggregationsSuccess]: state => state.set('loading', false),

  [Actions.setSelectedChartMultipleSavedSearchesMode]: (state, { payload }) => state.merge(payload
    ? {
      firstLevel: 'savedSearches',
      secondLevel: null
    } : {
      firstLevel: 'channels',
      secondLevel: null,
      additionalSavedSearchIds: fromJS([])
    }),

  [AppActions.resetState]: () => initialState
}, initialState)
