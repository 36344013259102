import { connect } from 'react-redux'

import NewsguardInfo from 'components/newsguard_info'

import { getBaseUrl, getStaticNewsguardRanks } from 'selectors'

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state),
  ranks: getStaticNewsguardRanks(state)
})

export default connect(mapStateToProps)(NewsguardInfo)
