import { connect } from 'react-redux'

import CustomTag from 'components/custom_tags/custom_tag'
import {
  showCustomTagDialog,
  filterBySelectedCustomTag,
  openCustomTagInShoppingCart,
  showRemoveCustomTagDialog,
  showEmptyCustomTagDialog,
  runCustomTagQueries
} from 'actions/custom_tags'

const mapStateToProps = (state, ownProps) => ({
  customTag: ownProps.customTag
})

export default connect(
  mapStateToProps,
  {
    onEditClick: showCustomTagDialog,
    onFilterClick: filterBySelectedCustomTag,
    onExportClick: openCustomTagInShoppingCart,
    onEmptyCustomTag: showEmptyCustomTagDialog,
    onDelete: showRemoveCustomTagDialog,
    onRunQueriesClick: runCustomTagQueries
  }
)(CustomTag)
