import { connect } from 'react-redux'

import ScoreResultTimelineChart from 'components/darknet/external_charts/score_result_timeline_chart'

import { getThemes, getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartsById } from 'utils/darknet'

const getDefaultPlotlightsColors = themes => {
  const plotlights = themes.find(t => t.get('theme') === 'plotlights').getIn(['colors', 'dark'])

  return plotlights
}

const mapStateToProps = state => ({
  charts: getChartsById(getDarknetCharts(state), DarknetCharts.EXTERNAL_SCORE_CHART),
  colors: getDefaultPlotlightsColors(getThemes(state))
})

export default connect(mapStateToProps, {})(ScoreResultTimelineChart)
