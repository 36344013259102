import { connect } from 'react-redux'

import QueryTypeDropdown from 'components/query_type_dropdown'
import { setSelectedQueryType } from 'actions/content_desk'
import { getCDeskSelectedQueryType, getI18n } from 'selectors'

const mapStateToProps = state => ({
  value: getCDeskSelectedQueryType(state),
  i18n: getI18n(state),
  moduleName: 'contentDesk'
})

export default connect(
  mapStateToProps,
  {
    onChange: setSelectedQueryType
  }
)(QueryTypeDropdown)
