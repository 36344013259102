import { call, put, select, takeEvery, takeLatest, all, debounce } from 'redux-saga/effects'
import { fromJS, Map } from 'immutable'
import moment from 'moment-timezone'

import * as Actions from 'actions/news'
import * as ShoppingCartActions from 'actions/shopping_cart'
import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { genericErrorMessage, exception, showAppErrorMessage } from 'actions/app'
import { getCluster } from 'utils/sagas'

export const groupingTypesWithNewStats = ['custom_tag', 'media_review_code']

export function* updateGroups({ payload: news }) {
  const groupingType = yield select(Selectors.getNewsGroupingType)

  if (groupingTypesWithNewStats.indexOf(groupingType) === -1) {
    return
  }

  const newCodeIds = news.get('codes').map(c => c.get('id'))
  const newCustomTagIds = news.get('customTags').map(c => c.get('id'))

  const oldNews = yield select(Selectors.getNewsById, news.get('id'))

  if (!oldNews) {
    return
  }

  const oldCodeIds = oldNews.get('codes').map(c => c.get('id'))
  const oldCustomTagIds = oldNews.get('customTags').map(c => c.get('id'))

  if (
    newCodeIds.sort().toSet().equals(oldCodeIds.sort().toSet())
    && newCustomTagIds.sort().toSet().equals(oldCustomTagIds.sort().toSet())
  ) {
    return
  }

  let newGroupIds = newCodeIds

  if (groupingType === 'custom_tag') {
    newGroupIds = newCustomTagIds
  }

  if (newGroupIds.isEmpty()) {
    newGroupIds = fromJS([-1])
  }

  const currentGroupIds = yield select(Selectors.getNewsGroupIds)
  const missingGroupIds = newGroupIds.filter(el => !currentGroupIds.includes(el))

  let updatedGroups = Map({})
  for (let i = 0; i < missingGroupIds.size; i += 1) {
    const fetchMoreNewsBody = yield select(Selectors.getFetchMoreNewsBody, missingGroupIds.get(i))
    delete fetchMoreNewsBody.from

    const result = yield call(Api.search, fetchMoreNewsBody)

    if (result.groups[0]) {
      updatedGroups = updatedGroups.set(result.groups[0].id, fromJS({ ...result.groups[0], news: result.groups[0].news.map(el => el.id) }))
      yield put(Actions.increasePaging(result))
    }
  }

  yield put(Actions.updateGroups({ newGroupIds, updatedGroups, newsId: news.get('id') }))

  const searchBody = yield select(Selectors.getSearchBody)
  const result = yield call(Api.search, { ...searchBody, size: 0 })
  yield put(Actions.updateGroupStats(result))
}

export function* updateDefaultNews(news) {
  try {
    const isMediaReviewDetail = yield select(Selectors.isMediaReviewDetail)
    const drillDownNews = yield select(Selectors.getDashboardDrilldownDataNewsById, news.get('id'))
    const isDrillDown = drillDownNews && !drillDownNews.isEmpty()

    if (!isMediaReviewDetail) {
      yield put(Actions.hideEditNewsDialog())
    }

    const uploadedFiles = yield select(Selectors.getEditNewsUploadedFiles)
    const videoFile = yield select(Selectors.getEditNewsUploadedVideoFile)
    const isTopicalCluster = news.get('clusterType') === 'topical' && isMediaReviewDetail

    const newCodeIds = news.get('codes').map(c => c.get('id'))
    const newCustomTagIds = news.get('customTags').map(c => c.get('id'))

    const clusterSize = news.get('clusterSize')
    let clusteredNews = news.get('clusteredNews')

    if (
      clusterSize > 1
      && clusteredNews.size !== clusterSize - 1
      && !isTopicalCluster
      && !isDrillDown
    ) {
      clusteredNews = yield call(getCluster, news)
    } else if (isTopicalCluster || isDrillDown) {
      clusteredNews = fromJS([])
    }

    const body = {
      article_date: news.get('articleDate'),
      mediareview_date: news.get('mediareviewDate'),
      code_ids: newCodeIds,
      custom_tag_ids: newCustomTagIds,
      headline: news.get('headline'),
      images: news.get('images'),
      search_profile_id: news.get('searchProfileId'),
      snippet: news.get('snippet'),
      subtitle: news.get('subtitle'),
      summary_headline: news.get('summaryHeadline'),
      summary: news.get('summary'),
      text: news.get('text'),
      tonality: news.get('tonality'),
      url: news.get('originalUrl'),
      files: uploadedFiles,
      image_as_illustration: news.get('imageAsIllustration'),
      refresh: !isMediaReviewDetail,
      video_file: videoFile,
      clustered_news_ids: clusteredNews.map(n => n.get('id')).toJS()
    }

    if (news.get('licenseExpired')) {
      delete body.text
    }

    let { news: newNews } = yield call(Api.updateNews, news.get('id'), body)

    newNews = fromJS(newNews)

    // Keep cluster information
    newNews = newNews.merge({
      clusteredNews: news.get('clusteredNews'),
      clusterSize: news.get('clusterSize'),
      flags: newNews.get('flags').set('clusteredNews', news.getIn(['flags', 'clusteredNews']))
    })

    const isInShoppingCart = yield select(Selectors.isInShoppingCart, news.get('id'))

    if (isInShoppingCart) {
      yield put(ShoppingCartActions.recalculateGroupedSorting(fromJS([newNews])))
    }

    if (!isMediaReviewDetail) {
      yield put(Actions.updateGroupsStart(news))
    } else {
      if (!news.getIn(['flags', 'clusteredNews'])) {
        yield put(MediaReviewsActions.regroupNews(newNews))
      }

      yield put(Actions.hideEditNewsDialog())
    }

    if (isMediaReviewDetail && news.getIn(['flags', 'clusteredNews'])) {
      let mainNews = yield select(Selectors.getNewsById, newNews.get('mainNewsId'))

      mainNews = mainNews.update('clusteredNews', cn => cn.map(ccn => {
        if (ccn.get('id') === newNews.get('id')) {
          return ccn.set('tonality', newNews.get('tonality'))
        }

        return ccn
      }))

      yield put(Actions.updateNews(fromJS([mainNews])))
    } else {
      yield put(Actions.updateNews(fromJS([newNews])))
    }

    yield put(Actions.updateNewsSuccess(newNews))
  } catch (error) {
    yield put(Actions.updateNewsError({ error, news }))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* updateBackwardsNews(news) {
  try {
    yield put(Actions.updateNews(fromJS([news])))

    const body = {
      sentiment: news.get('sentiment')
    }

    yield call(Api.updateBackwardsNews, news.get('id'), body)

    yield put(Actions.updateNewsSuccess(news))
  } catch (error) {
    yield put(Actions.updateNewsError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* updateNews({ payload: news }) {
  if (news.getIn(['flags', 'backwardsNews'])) {
    yield* updateBackwardsNews(news)
  } else {
    yield* updateDefaultNews(news)
  }
}

export function* createNews({ payload: news }) {
  try {
    const uploadedFiles = yield select(Selectors.getEditNewsUploadedFiles)
    const videoFile = yield select(Selectors.getEditNewsUploadedVideoFile)

    const codeIds = news.get('codes').map(c => c.get('id'))
    const customTagIds = news.get('customTags').map(c => c.get('id'))

    let body = {
      article_date: news.get('articleDate'),
      mediareview_date: news.get('mediareviewDate'),
      code_ids: codeIds,
      custom_tag_ids: customTagIds,
      headline: news.get('headline'),
      page_count: news.get('pageCount'),
      page: news.get('page'),
      publication_id: news.getIn(['publication', 'id']),
      snippet: news.get('snippet'),
      subtitle: news.get('subtitle'),
      summary_headline: news.get('summaryHeadline'),
      summary: news.get('summary'),
      text: news.get('text'),
      tonality: news.get('tonality'),
      url: news.get('originalUrl'),
      files: uploadedFiles,
      video_file: videoFile
    }

    const isMediaReviewDetail = yield select(Selectors.isMediaReviewDetail)
    const isShoppingCart = yield select(Selectors.isShoppingCart)

    if (isMediaReviewDetail) {
      const mediaReview = yield select(Selectors.getSelectedMediaReview)

      body = {
        ...body,
        search_profile_id: mediaReview.get('searchProfileId'),
        media_review: {
          start_date: mediaReview.get('startDate'),
          end_date: mediaReview.get('endDate'),
          media_review_type_id: mediaReview.get('mediaReviewTypeId')
        }
      }
    } else if (news.get('codes').size) {
      body = { ...body, search_profile_id: news.get('codes').first().get('searchProfileId') }
    }

    let { news: newNews } = yield call(Api.createNews, body)

    yield put(Actions.addNews([newNews]))
    yield put(Actions.createNewsSuccess(newNews))
    newNews = yield select(Selectors.getNewsById, newNews.id)

    if (isMediaReviewDetail) {
      yield put(MediaReviewsActions.regroupNews(newNews))
    } else if (isShoppingCart) {
      yield put(ShoppingCartActions.addNewsToShoppingCart([newNews.get('id')]))
    } else {
      yield put(Actions.newsRequestStart())
    }

    yield put(Actions.hideEditNewsDialog())
  } catch (error) {
    yield put(Actions.createNewsError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* saveNews() {
  const news = yield select(Selectors.getSelectedNews)

  if (news.get('id')) {
    yield put(Actions.updateNewsStart(news))
  } else {
    yield put(Actions.createNewsStart(news))
  }
}

export function* searchPublications({ payload: { name, channelIds } }) {
  try {
    yield put(Actions.setPublicationsLoading())

    const body = {
      name: `*${name}*`,
      channel_ids: channelIds
    }

    const { result } = yield call(Api.searchAllPublications, body)

    yield put(Actions.searchPublicationsSuccess(result))
  } catch (error) { // eslint-disable-line
    yield put(Actions.searchPublicationsSuccess([]))
  }
}

export function* uploadFile({ payload: file }) {
  const news = yield select(Selectors.getSelectedNews)
  try {
    const formData = [
      {
        key: 'file',
        value: file
      },
      {
        key: 'keep_until',
        value: moment().add(1, 'hour').toISOString()
      }
    ]

    const result = yield call(Api.uploadFile, formData)

    yield put(Actions.uploadFileSuccess(result))

    if (news.get('extractTextFromPdf') === true && file.type === 'application/pdf') {
      yield put(Actions.extractTextFromPdfStart(file))
    }
  } catch (error) {
    yield put(Actions.uploadFileError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* uploadVideoFile({ payload: file }) {
  try {
    const formData = [
      {
        key: 'file',
        value: file
      },
      {
        key: 'keep_until',
        value: moment().add(1, 'hour').toISOString()
      }
    ]

    const result = yield call(Api.uploadFile, formData)

    yield put(Actions.uploadVideoFileSuccess(result))
  } catch (error) {
    yield put(Actions.uploadVideoFileError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* showEditDialog({ payload: news }) {
  if (news) {
    yield put(Actions.getNewsStart(news))
  }
}

export function* getNews({ payload: news }) {
  try {
    const result = yield call(Api.getNews, news.get('id'))
    yield put(Actions.getNewsSuccess({ oldNews: news, newNews: result.news }))
  } catch (error) {
    yield put(Actions.getNewsError(error))

    if (error.response && error.response.statusCode === 404) {
      const i18n = yield select(Selectors.getI18n)
      yield put(showAppErrorMessage(i18n.get('news_not_found')))
    } else {
      yield put(exception(error))
      yield put(genericErrorMessage())
    }

    yield put(Actions.hideEditNewsDialog())
  }
}

export function* extractTextFromPdf({ payload: file }) {
  const i18n = yield select(Selectors.getI18n)
  try {
    const result = yield call(Api.extractTextFromPdf, file)
    yield put(Actions.extractTextFromPdfSuccess(result))

    if (result) {
      yield put(Actions.mergeExtractedText(result))
    } else {
      yield put(
        AppActions.showAppMessage({
          success: false,
          text: i18n.get('extract_text_fail')
        })
      )
    }
  } catch (error) {
    yield put(Actions.extractTextFromPdfError(error))
    yield put(
      AppActions.showAppMessage({
        success: false,
        text: i18n.get('extract_text_fail')
      })
    )
  }
}

export function* automaticSummary() {
  try {
    const selectedNews = yield select(Selectors.getSelectedNews)
    const summaryOptions = yield select(Selectors.getSummaryOptions)

    const body = {
      headline: selectedNews.get('headline'),
      language_code: selectedNews.getIn(['language', 'iso6391']),
      news_id: selectedNews.get('id'),
      mode: summaryOptions.get('mode'),
      text: selectedNews.get('text'),
      target_lang: summaryOptions.get('targetLang'),
      word_count: summaryOptions.get('wordCount'),
      bullet_points_count: summaryOptions.get('bulletPointsCount')
    }

    const { summary, summaryHeadline } = yield call(Api.summarizeNews, body)
    yield put(Actions.updateFieldOfSelectedNews({ field: 'summaryHeadline', value: summaryHeadline }))
    yield put(Actions.updateFieldOfSelectedNews({ field: 'summary', value: summary }))

    yield put(Actions.automaticSummarySuccess())
  } catch (e) {
    yield put(Actions.automaticSummaryError(e))
    yield put(exception(e))
    yield put(genericErrorMessage())
  }
}
export function* watchAutomaticSummary() {
  yield takeEvery(Actions.automaticSummaryStart, automaticSummary)
}

export function* watchUpdateNews() {
  yield takeEvery(Actions.updateNewsStart, updateNews)
}

export function* watchCreateNews() {
  yield takeEvery(Actions.createNewsStart, createNews)
}

export function* watchSaveNews() {
  yield takeEvery(Actions.saveNews, saveNews)
}

export function* watchSearchPublications() {
  yield debounce(500, Actions.searchPublicationsStart, searchPublications)
}

export function* watchUploadFile() {
  yield takeEvery(Actions.uploadFileStart, uploadFile)
}

export function* watchUploadVideoFile() {
  yield takeEvery(Actions.uploadVideoFileStart, uploadVideoFile)
}

export function* watchShowEditDialog() {
  yield takeEvery(Actions.showEditNewsDialog, showEditDialog)
}

export function* watchGetNews() {
  yield takeEvery(Actions.getNewsStart, getNews)
}

export function* watchUpdateGroups() {
  yield takeLatest(Actions.updateGroupsStart, updateGroups)
}

export function* watchExtractTextFromPdf() {
  yield takeEvery(Actions.extractTextFromPdfStart, extractTextFromPdf)
}

export default function* newsSaga() {
  yield all([
    watchAutomaticSummary(),
    watchUpdateNews(),
    watchCreateNews(),
    watchSaveNews(),
    watchSearchPublications(),
    watchUploadFile(),
    watchUploadVideoFile(),
    watchShowEditDialog(),
    watchGetNews(),
    watchUpdateGroups(),
    watchExtractTextFromPdf()
  ])
}
