import React, { memo } from 'react'
import PropTypes from 'prop-types'

import WithI18n from 'containers/WithI18n'

import { getDateString } from 'utils/date'

const SelectedDateRange = ({ dateRange, dateFrom, dateTo, i18n }) => (
  <span>
    {getDateString(dateRange, dateFrom, dateTo, i18n)}
  </span>
)

SelectedDateRange.propTypes = {
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  dateRange: PropTypes.string,
  i18n: PropTypes.object
}

export default memo(WithI18n()(SelectedDateRange))
