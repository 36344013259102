import React, { useState } from 'react'
import PropTypes from 'prop-types'

import useI18n from 'hooks/useI18n'
import { useSnackbar } from 'notistack'

import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'

import { IconButton, Button, Input } from 'containers/themed'

const DeeplButton = ({ onSubmit, loading }) => {
  const i18n = useI18n()
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleCancel = () => {
    setOpen(false)
    closeSnackbar()
    setInput('')
  }

  const handleSubmit = () => {
    if (input) {
      onSubmit({ name: input, typeId: 1 })

      return closeSnackbar()
    }

    return enqueueSnackbar(`${i18n.get('missing_api_key')}`, {
      variant: 'error',
      preventDuplicate: true
    })
  }

  return (
    <div>
      {!open && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          {i18n.get('enter_deepl_key')}
        </Button>
      )}
      {open && (
        <div style={{ display: 'flex', alignContent: 'center' }}>
          <Input
            value={input}
            onChange={event => {
              setInput(event.target.value)
            }}
            label="DeepL API Key"
            disabled={loading}
          />
          <IconButton
            onClick={handleCancel}
            title={i18n.get('cancel')}
            disabled={loading}
          >
            <CancelIcon style={{ color: loading ? grey[500] : red[500] }} />
          </IconButton>
          <IconButton
            onClick={handleSubmit}
            title={i18n.get('submit')}
          >
            <CheckIcon style={{ color: loading ? grey[500] : green[500] }} />
          </IconButton>
        </div>
      )}
    </div>
  )
}

DeeplButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default DeeplButton
