import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getColors, getWhitelabelColors, getDarkMode, getWhitelabelTheme, getSavedDashboardDarkMode, isInfoboard } from 'selectors'
import grey from '@mui/material/colors/grey'
import orange from '@mui/material/colors/orange'

const customPalette = {
  plotlights: {
    light: {
      background: {
        paper: '#FAFAFA',
        default: '#FFFFFF'
      }
    },
    dark: {
      background: {
        paper: '#143D52',
        default: '#1F465A'
      }
    }
  },
  nrx: {
    light: {},
    dark: {}
  }
}

const customProps = (primary, accent) => ({
  plotlights: {
    light: {
      paper: {
        backgroundPaperColor: grey[50],
        paperOnBackgroundPaperColor: customPalette.plotlights.light.background.default,
        paperOnBackgroundColor: customPalette.plotlights.light.background.default
      },
      drawer: {
        linkColor: grey[900],
        activeLinkColor: accent
      },
      appBar: {
        backgroundColor: 'inherit'
      },
      charts: {
        axesLabelColor: '#000000',
        axesStrokeColor: '#ffffff',
        labelBackgroundColor: '#ffffff',
        labelColor: grey[700],
        topLabelColor: grey[500],
        backgroundColor: '#ffffff'
      }
    },
    dark: {
      paper: {
        backgroundPaperColor: customPalette.plotlights.dark.background.paper,
        paperOnBackgroundPaperColor: customPalette.plotlights.dark.background.default,
        paperOnBackgroundColor: customPalette.plotlights.dark.background.default
      },
      drawer: {
        linkColor: grey[200],
        activeLinkColor: accent
      },
      appBar: {
        backgroundColor: 'inherit'
      },
      charts: {
        axesLabelColor: '#ffffff',
        axesStrokeColor: '#789789',
        labelBackgroundColor: '#143D52',
        labelColor: '#ffffff',
        topLabelColor: '#ffffff',
        backgroundColor: '#143D52'
      }
    }
  },
  nrx: {
    light: {
      paper: {
        backgroundPaperColor: grey[200],
        paperOnBackgroundColor: grey[200]
      },
      charts: {
        axesLabelColor: '#000000',
        axesStrokeColor: '#ffffff',
        labelBackgroundColor: '#ffffff',
        labelColor: grey[700],
        topLabelColor: grey[500],
        gradientStartColor: '#f6efa6',
        gradientEndColor: '#bf444c'
      }
    },
    dark: {
      paper: {
        backgroundPaperColor: grey[800],
        paperOnBackgroundColor: grey[800]
      },
      drawer: {
        linkColor: grey[200],
        activeLinkColor: primary
      },
      charts: {
        axesLabelColor: '#ffffff',
        axesStrokeColor: '#ffffff',
        labelBackgroundColor: '#1e1e1e',
        labelColor: '#ffffff',
        topLabelColor: '#ffffff',
        backgroundColor: '#1e1e1e',
        gradientStartColor: '#f6efa6',
        gradientEndColor: '#bf444c'
      }
    }
  }
})

const componentOverrides = {
  plotlights: {
    light: {},
    dark: {
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundImage: 'unset'
          }
        }
      }
    }
  },
  nrx: {
    light: {},
    dark: {}
  }
}

const cachedThemes = {}
export const createThemeWithColors = (primary, accent, highlighting, highlightingBackground, darkMode, whitelabelTheme) => {
  const cacheKey = `${primary}${accent}${highlighting}${highlightingBackground}${darkMode}${whitelabelTheme}`
  const cached = cachedThemes[cacheKey]
  const type = darkMode ? 'dark' : 'light'
  const isPlotlights = whitelabelTheme === 'plotlights'

  if (cached) {
    return cached
  }

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    },
    palette: {
      mode: type,
      primary: {
        main: primary,
        contrastText: grey[200]
      },
      secondary: {
        main: accent,
        contrastText: grey[200]
      },
      warning: {
        main: '#ED6C02',
        light: orange[500],
        dark: orange[900],
        contrastText: grey[200]
      },
      ...customPalette[whitelabelTheme][type]
    },
    components: { ...componentOverrides[whitelabelTheme][type] },
    nrx: {
      colors: {
        highlighting: !isPlotlights && darkMode && highlighting === '#000000' ? grey[600] : highlighting,
        highlightingBackground
      },
      drawer: {
        linkColor: grey[900],
        activeLinkColor: primary
      },
      appBar: {
        backgroundColor: 'default'
      },
      smallScrollbar: {
        scrollbarWidth: 'thin',
        scrollbarColor: `${grey[500]} transparent`,
        '&::-webkit-scrollbar': {
          width: '5px'
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: grey[500],
          borderRadius: '20px'
        }
      },
      ...customProps(primary, accent)[whitelabelTheme][type]
    },
    isDark: type === 'dark',
    isPlotlights
  })

  cachedThemes[cacheKey] = theme

  return theme
}

const buildHoc = colorSelector => Comp => {
  const WrapperComponent = ({ nrxColors, darkMode, whitelabelTheme, ...props }) => {
    const theme = createThemeWithColors(
      nrxColors.get('primary'),
      nrxColors.get('accent'),
      nrxColors.get('highlighting'),
      nrxColors.get('highlightingBackground'),
      darkMode,
      whitelabelTheme
    )

    const compProps = { ...props }
    delete compProps.dispatch

    return (
      <ThemeProvider theme={theme}>
        <Comp {...compProps} />
      </ThemeProvider>
    )
  }

  WrapperComponent.propTypes = {
    nrxColors: PropTypes.object.isRequired,
    darkMode: PropTypes.bool.isRequired,
    whitelabelTheme: PropTypes.string.isRequired
  }

  WrapperComponent.displayName = `WithMuiTheme(${Comp.name})`

  const useDarkMode = (darkMode, state) => {
    if (darkMode !== undefined) {
      return darkMode
    }

    if (isInfoboard(state)) {
      return getSavedDashboardDarkMode(state) || false
    }

    return getDarkMode(state)
  }

  const mapStateToProps = (state, { darkMode }) => ({
    nrxColors: colorSelector(state),
    darkMode: useDarkMode(darkMode, state),
    whitelabelTheme: getWhitelabelTheme(state)
  })

  return connect(mapStateToProps)(WrapperComponent)
}

const WithMuiTheme = buildHoc(getColors)
export const WithWhitelabelMuiTheme = buildHoc(getWhitelabelColors)

export default WithMuiTheme
