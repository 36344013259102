import { connect } from 'react-redux'

import ContactsSortbar from 'components/contact_management/contacts/contacts_sortbar/ContactsSortbar'

import { sortBy, selectAllContacts } from 'actions/contact_management'

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    onSortBy: sortBy,
    onSelectAll: selectAllContacts
  }
)(ContactsSortbar)
