import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faJs } from '@fortawesome/free-brands-svg-icons/faJs'
import { Map } from 'immutable'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import TableViewIcon from '@mui/icons-material/TableView'
import CodeIcon from '@mui/icons-material/Code'
import { makeStyles } from 'tss-react/mui'
import { Link } from '@mui/material'

const useStyles = makeStyles()({
  faIcon: {
    fontSize: 20,
    marginTop: -1
  },
  attachments: {
    display: 'flex',
    alignItems: 'center'
  },
  attachment: {
    marginRight: 5,

    '&:hover': {
      opacity: 0.7
    }
  }
})

export default function Attachments({ data }) {
  const { classes } = useStyles()

  const iconConfig = {
    'application/json': (
      <FontAwesomeIcon
        icon={faJs}
        className={classes.faIcon}
      />
    ),
    'application/pdf': <PictureAsPdfIcon />,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': <TableViewIcon />,
    'application/xml': <CodeIcon />,
    'text/html': <i className={`fa fa-file-code-o ${classes.faIcon}`} />
  }

  return (
    <div className={classes.attachments}>
      {data.get('attachments').map(attachment => (
        <Link
          key={attachment.get('url')}
          target="_blank"
          href={attachment.get('url')}
          classes={{ root: classes.attachment }}
        >
          {iconConfig[attachment.get('mimeType')]}
        </Link>
      ))}
    </div>
  )
}

Attachments.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired
}
