export const renderMood = mood => {
  if (mood === 'optimistic but not exaggerated') {
    return 'optimistic'
  }

  if (mood === 'pessimistic but not exaggerated') {
    return 'pessimistic'
  }

  return mood
}
