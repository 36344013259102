import { connect } from 'react-redux'

import AcceptCookies from 'components/accept_cookies'

import { setCookieOption } from 'actions/user'

export default connect(
  null,
  {
    onAccept: setCookieOption
  }
)(AcceptCookies)
