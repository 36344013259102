import { connect } from 'react-redux'

import TemplateConfirmDialog from 'components/content_desk_new/contents/content_edit_dialog/templates/template_confirm_dialog'

import {
  updateContent as onChange,
  closeTemplateConfirmDialog as closeConfirmDialog,
  changeContentEditTabView as onNavigationChange,
  emailEditorForceLoadDesignStart as forceLoadDesign
} from 'actions/content_desk'

import {
  getCDeskTemplateConfirmDialogOpen,
  getCDeskTemplateDesign
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskTemplateConfirmDialogOpen(state),
  editorDesign: getCDeskTemplateDesign(state)
})

export default connect(
  mapStateToProps,
  {
    onChange,
    closeConfirmDialog,
    onNavigationChange,
    forceLoadDesign
  }
)(TemplateConfirmDialog)
