import { connect } from 'react-redux'

import MentionsTotalChart from 'components/darknet/static_charts/mentions_total_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.MENTIONS_TOTAL)
})

export default connect(mapStateToProps, {})(MentionsTotalChart)
