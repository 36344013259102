import { connect } from 'react-redux'

import ContentPlanDialog from 'components/content_desk/content_edit_dialog/content_plan_dialog'

import {
  setContentFormData,
  closePlanDialog,
  saveContentStart
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskShowPlanDialog,
  getUserLocale
} from 'selectors'

const mapStateToProps = state => ({
  data: getCDeskContentFormData(state),
  locale: getUserLocale(state),
  isOpen: getCDeskShowPlanDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    onSave: saveContentStart,
    closePlanDialog
  }
)(ContentPlanDialog)
