import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  CircularProgress
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { calcRate } from 'utils/mailgunCalc'

const useStyles = makeStyles()(theme => ({
  card: {
    borderRadius: 20,
    minHeight: 147,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200]
  },
  cardContent: {
    marginTop: 18,
    display: 'flex',
    alignContent: 'center'
  },
  kpiWrapper: {
    height: '100%'
  },
  engagement: {
    fontSize: '30pt',
    fontWeight: '700',
    color: !theme.isDark && '#404040'
  },
  engagementLabel: {
    fontSize: '12pt',
    color: !theme.isDark && '#757575'
  },
  headlineDivider: {
    margin: '1em 0em 1em 0em'
  },
  divider: {
    marginTop: '1em',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  }
}))

const Email = ({ data, requestRunning }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const total = data.get('total')
  const isTotalEmpty = total ? (Object.keys(total).length === 0 && total.constructor === Object) : true
  const clickToOpenRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.uniqueClicked, total.uniqueOpened)
  const clickThroughRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.uniqueClicked, total.delivered)
  const unsubscribeRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.unsubscribed, total.delivered)
  const undeliveredRate = isTotalEmpty
    ? 'n/a'
    : calcRate(total.undelivered, total.recipients)
  const loadOrShowData = value => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return value
  }

  return (
    <>
      <Divider className={classes.headlineDivider} />
      <Typography
        className={classes.headline}
        variant="h6"
      >
        {i18n.get('engagement')}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="center"
      >
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>{i18n.get('views')}</div>
                <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.opened)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>{i18n.get('unique_views')}</div>
                <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.uniqueOpened)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>{i18n.get('clicks')}</div>
                <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.clicked)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>{i18n.get('unique_clicks')}</div>
                <div className={classes.engagement}>{loadOrShowData(isTotalEmpty ? 'n/a' : total.uniqueClicked)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>
                  {i18n.get('click_to_open_rate')}
                </div>
                <div className={classes.engagement}>{loadOrShowData(clickToOpenRate)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>{i18n.get('click_through_rate')}</div>
                <div className={classes.engagement}>{loadOrShowData(clickThroughRate)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>
                  {i18n.get('unsubscribe_rate')}
                </div>
                <div className={classes.engagement}>{loadOrShowData(unsubscribeRate)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          md={3}
          lg={3}
        >
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.engagementLabel}>
                  {i18n.get('undelivered_rate')}
                </div>
                <div className={classes.engagement}>{loadOrShowData(undeliveredRate)}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

Email.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired
}

export default Email
