import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/contact_management'
import * as ContentDeskActions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  contactsTotal: 0,
  distributionListsTotal: 0,
  contactFormData: {
    id: null,
    firstName: '',
    lastName: '',
    mail: '',
    title: '',
    jobTitle: '',
    dossier: '',
    picture: '',
    address: '',
    phone: '',
    fax: '',
    externalPublication: '',
    country: null,
    state: null,
    linkedinUrl: '',
    twitterUrl: '',
    facebookUrl: '',
    threadsUrl: '',
    tiktokUrl: '',
    instagramUrl: '',
    youtubeUrl: '',
    otherUrl: '',
    distributionLists: [],
    customTags: [],
    mediaTopics: [],
    languages: [],
    mediaTypes: []
  },
  contacts: [],
  selectedContactId: null,
  contactsToDelete: [],
  tags: [],
  distributionLists: [],
  distributionListsSearch: [],
  listData: {
    id: null,
    name: '',
    description: null,
    contacts: []
  },
  selectedLists: {
    lists: [],
    newListName: '',
    deleteMergedLists: false
  },
  uploadResult: {
    uploaded: [],
    failed: [],
    total_uploaded: 0,
    total_failed: 0
  }
})

export default handleActions(
  {
    [Actions.changeContactFormData]: (state, { payload: { key, value } }) => {
      let contactFormData = state.get('contactFormData').set(key, value)

      if (key === 'country') {
        contactFormData = contactFormData.set('state', null)
      }

      return state.set('contactFormData', contactFormData)
    },
    [Actions.setContactTags]: (state, { payload }) => state.merge({ tags: fromJS(payload) }),
    [Actions.changeTagSuccess]: (state, { payload }) => state.setIn(['contactFormData', 'customTags'], fromJS(payload)),
    [Actions.createTag]: (state, { payload }) => state.mergeIn(['tags'], fromJS(payload)),
    [Actions.renameTagSuccess]: (state, { payload }) => {
      const { id } = payload
      const newTags = state.get('tags').filter(t => t.get('id') !== id).push(fromJS(payload))
      const newContacts = state.get('contacts').map(c => {
        const newContactTags = c.get('customTags').filter(t => t.get('id') !== id).push(fromJS(payload))

        return c.set('customTags', newContactTags)
      })

      return state
        .set('tags', newTags)
        .set('contacts', newContacts)
    },
    [Actions.deleteTagSuccess]: (state, { payload }) => {
      const { id } = payload
      const newTags = state.get('tags').filter(t => t.get('id') !== id)
      const newContacts = state.get('contacts').map(c => {
        const newContactTags = c.get('customTags').filter(t => t.get('id') !== id)

        return c.set('customTags', newContactTags)
      })

      return state
        .set('tags', newTags)
        .set('contacts', newContacts)
    },

    [Actions.changeContactMediaTopics]: (state, { payload }) => {
      const mediaTopicIds = payload.map(t => ({ id: t }))

      return state.setIn(['contactFormData', 'mediaTopics'], fromJS(mediaTopicIds))
    },

    [Actions.changeContactLanguages]: (state, { payload }) => {
      const languageIds = payload.map(t => ({ id: t }))

      return state.setIn(['contactFormData', 'languages'], fromJS(languageIds))
    },

    [Actions.changeContactMediaTypes]: (state, { payload }) => {
      const mediaTypeIds = payload.map(t => ({ id: t }))

      return state.setIn(['contactFormData', 'mediaTypes'], fromJS(mediaTypeIds))
    },

    [Actions.openContactEditDialog]: (state, { payload: { contact } }) => state
      .mergeIn(['contactFormData'], contact)
      .set('selectedContactId', contact.get('id')),

    [Actions.closeContactEditDialog]: state => state.set('contactFormData', initialState.get('contactFormData')),
    [Actions.openContactViewDialog]: (state, { payload }) => state.set('selectedContactId', payload.get('id')),
    [Actions.closeContactViewDialog]: state => state.set('selectedContactId', initialState.get('selectedContactId')),

    [Actions.openContactDeleteDialog]: (state, { payload }) => state.set('contactsToDelete', fromJS(payload)),

    [combineActions(
      Actions.closeContactDeleteDialog,
      Actions.deleteContactsSuccess,
      Actions.deleteContactsError
    )]: state => state.set('contactsToDelete', initialState.get('contactsToDelete')),

    [Actions.updateDistributionListFormData]: (state, { payload: { key, value } }) => state.setIn(['listData', key], value),
    [Actions.selectContact]: (state, { payload: contactData }) => {
      const contactIds = state.getIn(['listData', 'contacts']).map(contact => contact.get('id'))

      if (contactIds.includes(contactData.get('id'))) {
        return state.updateIn(['listData', 'contacts'], contacts => contacts.filter(contact => contact.get('id') !== contactData.get('id')))
      }

      return state.updateIn(['listData', 'contacts'], contacts => contacts.push(contactData))
    },
    [Actions.selectAllContacts]: state => {
      const allContacts = state.get('contacts')
      const selectedContacts = state.getIn(['listData', 'contacts'])

      const newSelectedContacts = allContacts
        .filter(contact => !selectedContacts.map(c => c.get('id')).includes(contact.get('id')) && !contact.get('unsubscribed'))

      return state.updateIn(['listData', 'contacts'], contacts => contacts.concat(newSelectedContacts))
    },
    [Actions.selectDistributionList]: (state, { payload: dl }) => {
      const listIds = state.getIn(['selectedLists', 'lists']).map(l => l.get('id'))

      if (listIds.includes(dl.get('id'))) {
        return state.updateIn(['selectedLists', 'lists'], lists => lists.filter(l => l.get('id') !== dl.get('id')))
      }

      return state.updateIn(['selectedLists', 'lists'], lists => lists.push(dl))
    },
    [Actions.clearSelectedLists]: state => state.set('selectedLists', initialState.get('selectedLists')),
    [Actions.openDistributionListEditDialog]: (state, { payload }) => state.set('listData', payload),
    [Actions.closeDistributionListEditDialog]: state => state.set('listData', initialState.get('listData')),
    [Actions.closeDistributionListCreateDialog]: state => state.set('listData', initialState.get('listData')),
    [combineActions(
      Actions.deleteContactsSuccess,
      Actions.deleteContactsError,
      Actions.clearListData
    )]: state => state.set('listData', initialState.get('listData')),

    [Actions.openDistributionListViewDialog]: (state, { payload }) => state.set('selectedContactId', payload.get('id')),

    [Actions.setDistributionList]: (state, { payload }) => state.setIn(['listData', 'id'], payload),
    [Actions.fetchDistributionListsSuccess]: (state, { payload: { distributionLists, total } }) => (
      state.set('distributionLists', fromJS(distributionLists))).set('distributionListsTotal', fromJS(total)),
    [Actions.searchDistributionListSuccess]: (state, { payload: { distributionLists } }) => (
      state.set('distributionListsSearch', fromJS(distributionLists))),
    [Actions.createDistributionListSuccess]: (state, { payload }) => {
      const distLists = state.get('distributionLists')
      const newDistList = fromJS({ ...payload, contacts: fromJS([]) })

      return state
        .set('distributionLists', distLists.unshift(newDistList))
        .set('distributionListsTotal', state.get('distributionListsTotal') + 1)
    },
    [Actions.updateDistributionListSuccess]: (state, { payload }) => {
      const elemIndex = state
        .get('distributionLists')
        .findIndex(dl => dl.get('id') === payload.id)

      return state.setIn(['distributionLists', elemIndex], fromJS(payload))
    },
    [Actions.deleteDistributionListSuccess]: (state, { payload }) => {
      const filteredList = state.get('distributionLists').filter(dl => dl.get('id') !== payload.id)
      const selectedLists = state.get('selectedLists').get('lists').filter(l => l.get('id') !== payload.id)
      const distListTotal = state.get('distributionListsTotal')

      return state
        .set('distributionLists', filteredList)
        .setIn(['selectedLists', 'lists'], selectedLists)
        .set('distributionListsTotal', distListTotal !== 0 ? distListTotal - 1 : 0)
    },
    [Actions.removeContactFromListSuccess]: (state, { payload }) => {
      const elemIndex = state
        .get('distributionLists')
        .findIndex(dl => dl.get('id') === payload.id)

      if (state.get('selectedContactId') !== null) {
        const contact = state.get('contacts').find(c => c.get('id') === state.get('selectedContactId'))
        const updatedDistributionLists = contact.get('distributionLists').filter(dl => dl.get('id') !== payload.id)
        const contactIndex = state.get('contacts').findIndex(c => c.get('id') === state.get('selectedContactId'))

        return state
          .setIn(['distributionLists', elemIndex], fromJS(payload))
          .setIn(['contacts', contactIndex, 'distributionLists'], updatedDistributionLists)
      }

      return state
        .setIn(['distributionLists', elemIndex], fromJS(payload))
    },
    [Actions.addContactsToDistributionListSuccess]: (state, { payload }) => {
      const elemIndex = state
        .get('distributionLists')
        .findIndex(dl => dl.get('id') === payload.id)

      const updatedContacts = state.get('contacts').map(contact => {
        const updatedContactIndex = payload.contacts.findIndex(
          updatedContact => updatedContact.id === contact.get('id')
        )
        const isNewList = !contact.get('distributionLists').map(l => l.get('id')).contains(payload.id)

        if (updatedContactIndex !== -1 && isNewList) {
          return contact.set(
            'distributionLists',
            contact.get('distributionLists').unshift(fromJS({ id: payload.id, name: payload.name }))
          )
        }

        return contact
      })

      return state
        .set('contacts', updatedContacts)
        .setIn(['distributionLists', elemIndex], fromJS(payload))
        .set('listData', initialState.get('listData'))
    },
    [Actions.fetchContactsSuccess]: (state, { payload: { contacts, total } }) => (
      state.set('contacts', fromJS(contacts))).set('contactsTotal', fromJS(total)),

    // Contacts need to be reset on setRecipientBrowserOpen, because this state is shared between ContentDesk and ContactManagement
    [ContentDeskActions.setRecipientBrowserOpen]: state => state.set('contacts', initialState.get('contacts'))
      .set('contactsTotal', initialState.get('contactsTotal')),

    [Actions.saveContactsSuccess]: (state, { payload }) => {
      const newContact = fromJS(payload)
      const index = state.get('contacts').findIndex(c => c.get('id') === newContact.get('id'))
      let newState

      if (index > -1) {
        newState = state.setIn(['contacts', index], newContact)
      } else {
        newState = state
          .set('contacts', state.get('contacts').unshift(newContact))
          .set('contactsTotal', state.get('contactsTotal') + 1)
      }

      return newState.set('contactFormData', initialState.get('contactFormData'))
    },
    [Actions.uploadContactsSuccess]: (state, { payload }) => state.set('uploadResult', fromJS(payload)),
    [Actions.closeUploadResultDialog]: state => state
      .set('uploadResult', initialState.get('uploadResult'))
      .set('listData', initialState.get('listData')),
    [Actions.closeContactsUploadDialog]: state => state.set('listData', initialState.get('listData')),
    [Actions.closeUploadResultDialog]: state => state.set('uploadResult', initialState.get('uploadResult')),
    [Actions.setMergedDistributionListData]: (state, { payload: { key, value } }) => state
      .update('selectedLists', data => data.set(key, value)),
    [Actions.mergeDistributionListsSuccess]: (state, { payload: { mergedList, deletedLists } }) => {
      const distLists = state.get('distributionLists').filter(l => !deletedLists.includes(l.get('id')))

      return state
        .set('distributionLists', distLists.unshift(fromJS(mergedList)))
        .set('selectedLists', initialState.get('selectedLists'))
        .set('distributionListsTotal', state.get('distributionListsTotal') + 1 - deletedLists.length)
    },
    [AppActions.resetState]: () => initialState
  }, initialState
)
