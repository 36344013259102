import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_charts'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  loading: false
})

export default handleActions({
  [Actions.fetchSavedChartStart]: state => state.set('loading', true),
  [Actions.fetchSavedChartSuccess]: state => state.set('loading', false),
  [Actions.fetchSavedChartError]: state => state.set('loading', false),

  [AppActions.resetState]: () => initialState
}, initialState)
