import { connect } from 'react-redux'

import DistributionListsSortbar from 'components/contact_management/distribution_lists/distribution_lists_sortbar/DistributionListsSortbar'
import { listSortBy } from 'actions/contact_management'

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    onSortBy: listSortBy
  }
)(DistributionListsSortbar)
