import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  newsguardInfo: {
    '&:hover': {
      opacity: 0.7
    },
    verticalAlign: 'inherit'
  },
  img: {
    height: 20,
    verticalAlign: 'inherit'
  }
})

export default function NewsguardInfo({ rank, ranks, baseUrl, url }) {
  const { classes } = useStyles()
  const found = ranks.find(r => r.get('id') === rank)

  if (!found) {
    return null
  }

  if (url) {
    return (
      <a
        href={`${baseUrl}/dereferer/derefer?dest=${encodeURIComponent(url)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className={classes.img}
          alt={found.get('name')}
          src={found.get('photo')}
        />
      </a>
    )
  }

  return (
    <img
      className={classes.img}
      alt={found.get('name')}
      src={found.get('photo')}
    />
  )
}

NewsguardInfo.propTypes = {
  rank: PropTypes.string,
  url: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  ranks: PropTypes.instanceOf(List).isRequired
}
