import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent, Grid, Typography, DialogActions, Switch, FormControlLabel } from '@mui/material'

import { Dialog, RaisedPrimaryButton } from 'containers/themed'
import FilterableCustomTagList from 'containers/custom_tags/FilterableCustomTagList'
import useI18n from 'hooks/useI18n'
import NewCustomTagDialog from 'containers/custom_tags/CustomTagDialog'

export default function CustomTagDialog({
  actionLabelKey,
  active,
  createActive,
  onHide,
  onCreateClick,
  onHideCreateClick,
  onSave,
  titleKey,
  setAutoAddMediaReviewsNews
}) {
  const i18n = useI18n()

  const handleShowCreate = () => {
    if (!createActive) {
      setAutoAddMediaReviewsNews(true)
      onCreateClick()
    } else {
      setAutoAddMediaReviewsNews(false)
      onHideCreateClick()
    }
  }

  const handleOk = customTag => {
    onHide()
    onSave({ customTag })
  }

  return (
    <Dialog
      open={active}
      title={i18n.get(titleKey || actionLabelKey)}
      onClose={onHide}
      maxWidth="lg"
    >
      <DialogContent
        style={{
          minHeight: '400px',
          marginLeft: '-1em',
          marginRight: '-1em'
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h6">
              {createActive ? i18n.get('create_pin_board') : i18n.get('choose_pin_board')}
            </Typography>
          </Grid>
          {actionLabelKey === 'pin' && (
            <Grid item>
              <FormControlLabel
                control={(
                  <Switch
                    checked={createActive}
                    onChange={handleShowCreate}
                    color="primary"
                  />
                )}
                label={i18n.get('create_pin_board')}
              />
            </Grid>
          )}
        </Grid>
        <br />
        {!createActive && (
          <FilterableCustomTagList
            assignedLabel={i18n.get(actionLabelKey)}
            assignLabel={i18n.get(actionLabelKey)}
            onAddClick={handleOk}
            onRemoveClick={handleOk}
            isAssigned={() => false}
            isSaving={() => false}
          />
        )}

        {createActive && (
          <NewCustomTagDialog
            isInParentDialog
            hideParentDialog={onHide}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!createActive && (
          <RaisedPrimaryButton onClick={() => onHide()}>
            {i18n.get('close')}
          </RaisedPrimaryButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

CustomTagDialog.defaultProps = {
  actionLabelKey: 'pin'
}

CustomTagDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  actionLabelKey: PropTypes.string,
  titleKey: PropTypes.string,
  createActive: PropTypes.bool,

  onHide: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func,
  onHideCreateClick: PropTypes.func,
  setAutoAddMediaReviewsNews: PropTypes.func
}
