import { connect } from 'react-redux'

import SelectMedia from 'components/content_desk_new/contents/content_edit_dialog/attachments/select_media'

import {
  changeAttachmentsMediaType
} from 'actions/content_desk'

import {
  getCDeskAttachmentsMediaType
} from 'selectors'

const mapStateToProps = state => ({
  mediaType: getCDeskAttachmentsMediaType(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: changeAttachmentsMediaType
  }
)(SelectMedia)
