import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { makeStyles } from 'tss-react/mui'

import {
  SearchFields,
  ThemaxSearchFields,
  ThemaxReachFilterOptions as reachFilterOptions,
  ThemaxPageAveFilterOptions as pageAveFilterOptions
} from 'static/constants'

import { mapToOptions } from 'utils/autocomplete'
import { scale, descale } from 'utils/slider'
import { formatNumber } from 'utils/number'
import { RaisedPrimaryButton, Button, Autocomplete, IconButton } from 'containers/themed'
import ActionBar from 'components/search_form/action_bar'

import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Slider,
  Switch,
  Typography
} from '@mui/material'

const useStyles = makeStyles()(theme => ({
  autoComplete: {
    minWidth: 300
  },
  buttonContainer: {
    backgroundColor: theme.nrx.paper.paperOnBackgroundColor,
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      width: 750
    },
    '& > div:last-child': {
      '& > *': {
        marginRight: 10
      },
      '& > *:last-child': {
        marginRight: 0
      }
    },
    '& > div:first-of-type': {
      marginRight: 0
    }
  },
  outlinksTitle: {
    marginBottom: 10
  },
  spacer: {
    flex: 0.9,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  searchForm: {
    overflow: 'visible',
    padding: 20,
    [theme.breakpoints.up('lg')]: {
      height: '90%',
      overflowY: 'auto'
    }
  },
  tonality: {
    padding: '0 12px'
  },
  divider: {
    marginBottom: 10
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:last-child': {
      marginRight: -5
    }
  }
}))

const mapToEntity = (entities, values) => (values || List([]))
  .map(({ value }) => {
    const found = entities.find(entity => entity.get('id') === value)

    if (found) {
      return found.toJS()
    }

    return null
  })
  .filter(e => e)

const Row = props => (
  <Grid
    container
    direction="row"
    spacing={1}
    {...props}
  />
)

const Col = props => (
  <Grid
    item
    xl={6}
    lg={6}
    sm={6}
    xs={12}
    {...props}
  />
)

const SearchForm = props => {
  const { classes } = useStyles()
  const {
    mediaTypes,
    mediaTopics,
    countries,
    i18n,
    hasPaidVersion,
    device,
    applyFilters,
    resetFilters,
    setSelectedFilters,
    onClose
  } = props

  const [selectedMediaTypes, setSelectedMediaTypes] = useState(mapToOptions(props.selectedMediaTypes))
  const [selectedMediaTopics, setSelectedMediaTopics] = useState(mapToOptions(props.selectedMediaTopics))
  const [selectedCountries, setSelectedCountries] = useState(mapToOptions(props.selectedCountries))
  const [booleans, setBooleans] = useState(props.booleans)
  const [reachFilter, setReachFilter] = useState(props.reachFilter)
  const [pageAveFilter, setPageAveFilter] = useState(props.pageAveFilter)
  const [active, setActive] = useState(props.active)
  const [previousActive, setPreviousActive] = useState(null)

  if (active !== previousActive) {
    setSelectedMediaTypes(mapToOptions(props.selectedMediaTypes))
    setSelectedMediaTopics(mapToOptions(props.selectedMediaTopics))
    setSelectedCountries(mapToOptions(props.selectedCountries))
    setPreviousActive(active)
    setActive(props.active)
    setBooleans(props.booleans)
    setReachFilter(props.reachFilter)
    setPageAveFilter(props.pageAveFilter)
  }

  const handleApply = () => {
    setSelectedFilters({
      mediaTypes: mapToEntity(mediaTypes, selectedMediaTypes),
      mediaTopics: mapToEntity(mediaTopics, selectedMediaTopics),
      countries: mapToEntity(countries, selectedCountries),
      booleans,
      reachFilter,
      pageAveFilter
    })

    applyFilters()
  }

  const renderFilter = (comp, ...names) => {
    if (!hasPaidVersion && names.includes(ThemaxSearchFields.BOOKMARKED_ONLY)) {
      return null
    }

    return comp
  }

  const renderAutocomplete = (
    labelKey,
    onChange,
    source,
    value,
    name,
    helperText,
    creatable,
    allowEmptySource,
    onCreateOption,
    disableOptionsSelect
  ) => {
    if (!source.size && !allowEmptySource) {
      return null
    }

    return renderFilter((
      <div className={classes.autoComplete}>
        <Autocomplete
          key={labelKey}
          variant="outlined"
          isMulti
          isClearable
          creatable={creatable}
          onChange={onChange}
          options={mapToOptions(source)}
          value={value}
          placeholder=""
          label={i18n.get(labelKey)}
          formatCreateLabel={inputValue => `${i18n.get('add')}: ${inputValue}`}
          onCreateOption={onCreateOption}
          disableOptionsSelect={disableOptionsSelect}
          textFieldProps={{
            helperText
          }}
        />
        <br />
      </div>
    ), name)
  }

  const renderCol = (children, Comp = Col) => {
    if (!children) {
      return null
    }

    return <Comp>{children}</Comp>
  }

  const renderReachFilter = () => (
    <>
      <FormControlLabel
        label={i18n.get('filter_by_reach')}
        control={(
          <Switch
            checked={reachFilter.get('filtered')}
            onChange={e => setReachFilter(reachFilter.set('filtered', e.target.checked))}
          />
        )}
      />
      {reachFilter.get('filtered') && (
        <Box sx={{ width: 300 }}>
          <Slider
            onChange={(_, value) => setReachFilter(
              reachFilter.set('min', scale(value[0], reachFilterOptions.marks))
                .set('max', scale(value[1], reachFilterOptions.marks))
            )}
            value={[
              descale(reachFilter.get('min'), reachFilterOptions.marks),
              descale(reachFilter.get('max'), reachFilterOptions.marks)
            ]}
            scale={value => scale(value, reachFilterOptions.marks)}
            marks={reachFilterOptions.marks}
            min={0}
            valueLabelFormat={formatNumber}
            valueLabelDisplay="auto"
            max={100}
          />
        </Box>
      )}
    </>
  )

  const renderPageAveFilter = () => (
    <>
      <FormControlLabel
        label={i18n.get('filter_by_page_ave')}
        control={(
          <Switch
            checked={pageAveFilter.get('filtered')}
            onChange={e => setPageAveFilter(pageAveFilter.set('filtered', e.target.checked))}
          />
        )}
      />
      {pageAveFilter.get('filtered') && (
        <Box sx={{ width: 300 }}>
          <Slider
            onChange={(_, value) => setPageAveFilter(
              pageAveFilter.set('min', scale(value[0], pageAveFilterOptions.marks))
                .set('max', scale(value[1], pageAveFilterOptions.marks))
            )}
            value={[
              descale(pageAveFilter.get('min'), pageAveFilterOptions.marks),
              descale(pageAveFilter.get('max'), pageAveFilterOptions.marks)
            ]}
            scale={value => scale(value, pageAveFilterOptions.marks)}
            marks={pageAveFilterOptions.marks}
            min={0}
            valueLabelFormat={formatNumber}
            valueLabelDisplay="auto"
            max={100}
          />
        </Box>
      )}
    </>
  )

  return (
    <div className={classes.searchForm}>
      <div className={classes.header}>
        <Typography variant="h5">
          {i18n.get('advanced_search')}
        </Typography>
        <IconButton
          onClick={() => onClose()}
          title={i18n.get('close')}
          size="large"
        >
          {device.get('lt-lg') ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>
      <Divider classes={{ root: classes.divider }} />

      <Row>
        <Col>
          {renderCol(
            renderAutocomplete(
              'select_media_types',
              value => setSelectedMediaTypes(value),
              mediaTypes,
              selectedMediaTypes,
              SearchFields.MEDIA_TYPES
            )
          )}
        </Col>
        <Col>
          {renderCol(
            renderAutocomplete(
              'select_media_topics',
              value => setSelectedMediaTopics(value),
              mediaTopics,
              selectedMediaTopics,
              SearchFields.MEDIA_TOPICS
            )
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {renderCol(
            renderAutocomplete(
              'select_countries',
              value => setSelectedCountries(value),
              countries,
              selectedCountries,
              SearchFields.COUNTRIES
            )
          )}
        </Col>
        <Col>
          <FormGroup>
            {renderFilter((
              <FormControlLabel
                label={i18n.get('bookmarked_only')}
                control={(
                  <Switch
                    checked={booleans.get('bookmarkedOnly')}
                    onChange={e => setBooleans(booleans.set('bookmarkedOnly', e.target.checked))}
                  />
                )}
              />

            ), ThemaxSearchFields.BOOKMARKED_ONLY)}
          </FormGroup>
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          {renderReachFilter()}
        </Col>
        <Col>
          {renderPageAveFilter()}
        </Col>
      </Row>

      {device.get('lt-lg') && (
        <>
          <br />
          <Divider />
          <br />
        </>
      )}

      <ActionBar className={classes.buttonContainer}>
        <div className={classes.spacer} />

        <div>
          {device.get('lt-sm') && (
            <IconButton
              onClick={() => onClose()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}

          {device.get('gt-xs') && (
            <Button onClick={() => onClose()}>
              {i18n.get('close')}
            </Button>
          )}

          {device.get('lt-sm') && (
            <IconButton
              color="secondary"
              onClick={() => resetFilters()}
              size="large"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          )}

          {device.get('gt-xs') && (
            <Button
              color="secondary"
              onClick={() => resetFilters()}
            >
              {i18n.get('reset_filters')}
            </Button>
          )}

          <RaisedPrimaryButton onClick={handleApply}>
            {i18n.get('apply_filters')}
          </RaisedPrimaryButton>
        </div>
      </ActionBar>
    </div>
  )
}

export default React.memo(SearchForm, (prevProps, nextProps) => (nextProps.active))

SearchForm.propTypes = {
  active: PropTypes.bool.isRequired,
  mediaTypes: PropTypes.instanceOf(List).isRequired,
  mediaTopics: PropTypes.instanceOf(List).isRequired,
  countries: PropTypes.instanceOf(List).isRequired,
  reachFilter: PropTypes.instanceOf(Map),
  pageAveFilter: PropTypes.instanceOf(Map),
  hasPaidVersion: PropTypes.bool.isRequired,
  selectedMediaTypes: PropTypes.instanceOf(List).isRequired,
  selectedMediaTopics: PropTypes.instanceOf(List).isRequired,
  selectedCountries: PropTypes.instanceOf(List).isRequired,
  i18n: PropTypes.object.isRequired,
  device: PropTypes.instanceOf(Map).isRequired,
  booleans: PropTypes.instanceOf(Map).isRequired,

  applyFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
