import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { createLoadable } from 'utils/loadable'
import { makeStyles } from 'tss-react/mui'
import { useParams } from 'react-router-dom'

import { ViewConfigCapSafe } from 'containers/CapSafe'
import Selected from 'containers/shopping_cart/Selected'
import RemoveCustomTagDialog from 'containers/shopping_cart/RemoveCustomTagDialog'
import ClusterDialog from 'containers/shopping_cart/ClusterDialog'
import AppBar from 'containers/shopping_cart/app_bar/AppBar'
import GroupedNewsList from 'containers/shopping_cart/GroupedNewsList'
import NewsList from 'containers/shopping_cart/NewsList'

import useI18n from 'hooks/useI18n'

import { Tabs, Tab } from '@mui/material'

const width = 1200
const breakpoint = `@media (max-width: ${width - 1}px)`

const useStyles = makeStyles()(theme => ({
  centered: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tabs: {
    marginTop: 5
  },
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  news: {
    minWidth: width,
    width,
    [breakpoint]: {
      width: '100%',
      minWidth: '100%'
    }
  },
  dummyIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '93vh',
    flex: 1,
    color: theme.palette.grey[200],
    [theme.breakpoints.down('xl')]: {
      display: 'none'
    },
    '& > *': {
      fontSize: '20vw'
    }
  }
}))

const DocumentDownloadDialog = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartDocumentDownloadDialog" */ 'containers/DocumentDownloadDialog')
))
const ExportMenuDialogs = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartExportMenuDialogs" */ 'containers/export_menu/ExportMenuDialogs')
))
const NrxProgressBar = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartNrxProgressBar" */ 'components/nrx_progress_bar/NrxProgressBar')
))
const CustomTagDialog = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartCustomTagDialog" */ 'containers/shopping_cart/CustomTagDialog')
))
const EditDialog = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartEditDialog" */ 'containers/news/edit_dialog/EditDialog')
))
const CenterMessage = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartCenterMessage" */ 'containers/CenterMessage')
))
const AiAnalysisDialog = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartAiAnalysisDialog" */ 'containers/shopping_cart/AiAnalysisDialog')
))

const ExportDialogs = ViewConfigCapSafe(ExportMenuDialogs, 'export')

export default function ShoppingCart({
  count,
  loading,
  onTabChange,
  onCustomTagIdChange,
  show,
  tabIndex,
  ungroupedOnly
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const { custom_tag_id: customTagId } = useParams()

  useEffect(() => {
    onCustomTagIdChange(customTagId)
  }, [customTagId, onCustomTagIdChange])

  const showBody = Boolean(show && count && !loading)

  return (
    <>
      <Selected />
      <ExportDialogs />
      <CustomTagDialog />
      <RemoveCustomTagDialog />
      <ClusterDialog />
      <EditDialog />
      <AiAnalysisDialog />

      <AppBar />

      {loading && (
        <div className={classes.centered}>
          <NrxProgressBar />
        </div>
      )}

      {!loading && !count && (
        <CenterMessage
          icon="clear_all"
          title={i18n.get('shopping_cart_empty_header')}
          message={i18n.get('shopping_cart_empty_body')}
        />
      )}

      <div className={classes.container}>
        <div className={classes.dummyIcon}>
          <i className="far fa-clipboard" />
        </div>

        <div className={classes.news}>
          {ungroupedOnly && showBody && (
            <>
              <br />
              <NewsList />
            </>
          )}

          {!ungroupedOnly && showBody && (
            <>
              <Tabs
                value={tabIndex}
                onChange={(_event, index) => onTabChange(index)}
                variant="fullWidth"
                indicatorColor="primary"
                className={classes.tabs}
              >
                <Tab label={i18n.get('grouped')} />
                <Tab label={i18n.get('ungrouped')} />
              </Tabs>

              <div className={classes.tabs}>
                {tabIndex === 0 && <GroupedNewsList />}
                {tabIndex === 1 && <NewsList />}
              </div>
            </>
          )}
        </div>

        <div className={classes.dummyIcon}>
          <i className="far fa-clipboard" />
        </div>
      </div>

      {
        /*
           This may seem odd, because the DownloadDialog is rendered in the ExportMenu as well.
           This is to ensure you can see the DownloadDialog while the AppBar is used to show
           the progress of deleting News from RSS pin boards.
        */
      }
      <DocumentDownloadDialog />
    </>
  )
}

ShoppingCart.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  ungroupedOnly: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,

  onTabChange: PropTypes.func.isRequired,
  onCustomTagIdChange: PropTypes.func.isRequired
}
