import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'components/darknet/charts/chart'
import { addStylesToChart } from 'utils/darknet'
import GridItem from 'components/darknet/grid_item/GridItem'

const opts = {
  domain: [dataMin => Math.floor(dataMin / 10) * 10, dataMax => Math.ceil(dataMax / 100) * 100]
}

export default function MentionsTimelineChart({ chart, size, drilldown }) {
  const chartWithStyles = addStylesToChart(chart, {}, opts)

  return (
    <GridItem
      size={size}
    >
      <Chart
        chart={chartWithStyles}
        drilldown={drilldown}
      />
    </GridItem>
  )
}

MentionsTimelineChart.defaultProps = {
  size: 6
}

MentionsTimelineChart.propTypes = {
  chart: PropTypes.any,
  size: PropTypes.number,
  drilldown: PropTypes.func.isRequired
}
