import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > div:first-of-type': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  name: {
    marginLeft: 5
  },
  users: {
    fontSize: 12,
    marginBottom: 10
  }
})

export default function Users({
  users,
  reactionType
}) {
  const { classes, cx } = useStyles()

  return (
    <>
      <div className={classes.header}>
        <div>
          <i
            key={reactionType.get('id')}
            className={cx(classes.reaction, reactionType.get('iconClass'))}
            style={{
              color: reactionType.get('iconColor')
            }}
          />

          <span className={classes.name}>
            {reactionType.get('name')}
          </span>
        </div>

        {users.size}
      </div>

      <div className={classes.users}>
        {users.map(user => (
          <div key={user.get('name')}>
            {user.get('name')}
          </div>
        ))}
      </div>
    </>
  )
}

Users.propTypes = {
  users: PropTypes.instanceOf(List).isRequired,
  reactionType: PropTypes.instanceOf(Map).isRequired
}
