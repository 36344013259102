import { connect } from 'react-redux'
import { List } from 'immutable'

import ActiveFilterList from 'components/active_filter_list'
import {
  getActiveDarknetFilters, getActiveDarknetFiltersCollapsed, getActiveDarknetNumberFilters
} from 'selectors'
import { toggleActiveFilters } from 'actions/news'

const mapStateToProps = state => ({
  activeFilters: getActiveDarknetFilters(state),
  activeNumberFilters: getActiveDarknetNumberFilters(state),
  activeBooleanFilters: List(),
  activeGeoFilter: null,
  filterCount: getActiveDarknetFilters(state).size,
  collapsed: getActiveDarknetFiltersCollapsed(state),
  activeFilterType: 'darknet'
})

export default connect(
  mapStateToProps,
  {
    onCollapseClick: toggleActiveFilters
  }
)(ActiveFilterList)
