import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/email_templates_library'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  privateTemplates: {
    hasMore: false,
    items: []
  },
  publicTemplates: {
    hasMore: false,
    items: []
  },
  templateFormData: {
    id: null,
    name: null,
    shared: false,
    released: false,
    json: null,
    html: null,
    isDirty: false
  }
})

export default handleActions({
  [Actions.setTemplateFormData]: (state, { payload: { key, value } }) => (
    state.setIn(['templateFormData', key], value).setIn(['templateFormData', 'isDirty'], true)
  ),
  [Actions.loadSelectedTemplate]: (state, { payload }) => (
    state.set('templateFormData', payload)
  ),
  [Actions.closeEditorDialog]: state => state.set('templateFormData', initialState.get('templateFormData')),
  [Actions.saveTemplateSuccess]: (state, { payload }) => {
    if (!payload) {
      return state
    }

    const { id, shared } = payload
    const key = shared ? 'publicTemplates' : 'privateTemplates'
    const currentTemplates = state.getIn([key, 'items'])

    if (currentTemplates.some(template => template.get('id') === id)) {
      const index = currentTemplates.findIndex(template => template.get('id') === id)

      return state.setIn([key, 'items', index], fromJS(payload))
    }

    return state.updateIn([key, 'items'], templatesList => templatesList.unshift(fromJS(payload)))
  },
  [Actions.fetchTemplatesSuccess]: (state, { payload: { shared, result: { templates, hasMore } } }) => {
    const key = shared ? 'publicTemplates' : 'privateTemplates'

    return state
      .updateIn([key, 'items'], templatesList => templatesList.push(...fromJS(templates)))
      .setIn([key, 'hasMore'], hasMore)
  },
  [Actions.deleteTemplateSuccess]: (state, { payload }) => {
    const key = payload.shared ? 'publicTemplates' : 'privateTemplates'

    return state.setIn([key, 'items'], state.getIn([key, 'items']).filter(template => template.get('id') !== payload))
  },
  [Actions.resetTemplatesModule]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
