import React from 'react'
import PropTypes from 'prop-types'

import useI18n from 'hooks/useI18n'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  ListSubheader
} from '@mui/material'

const emptyValueDummy = 'dummy_empty_value'

export default function Select({
  options,
  required,
  label,
  value,
  error,
  info,
  disabled,
  name,
  size,
  onChange,
  displayEmpty,
  variant,
  open,
  onOpen,
  onClose
}) {
  const i18n = useI18n()

  let errorText

  if (error === true) {
    errorText = <FormHelperText>{i18n.get('required_field')}</FormHelperText>
  } else if (error) {
    errorText = <FormHelperText>{error}</FormHelperText>
  } else if (required && !value) {
    errorText = <FormHelperText>{i18n.get('required_field')}</FormHelperText>
  } else if (info) {
    errorText = <FormHelperText>{info}</FormHelperText>
  }

  let newOptions = options
  let newValue = value

  if (displayEmpty) {
    const hasEmptyValue = options.find(o => o.value === '')

    if (hasEmptyValue) {
      newOptions = options.map(o => (o.value === '' ? { ...o, value: emptyValueDummy } : o))
      newValue = value || emptyValueDummy
    }
  }

  return (
    <FormControl
      error={Boolean(error)}
      required={required}
      fullWidth
      variant={variant || 'standard'}
      size={size}
    >
      <InputLabel>
        {label}
      </InputLabel>
      <MuiSelect
        displayEmpty={displayEmpty}
        value={newValue}
        name={name}
        disabled={disabled}
        onChange={event => (
          onChange(
            options.find(o => o.value === (event.target.value === emptyValueDummy ? '' : event.target.value))
          )
        )}
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        label={label}
        size={size}
      >
        {
          newOptions.map(option => {
            if (option.isCategory) {
              return (
                <ListSubheader key={option.label}>
                  {option.label}
                </ListSubheader>
              )
            }

            return (
              <MenuItem
                key={option.value}
                value={option.value}
                title={option.title}
              >
                {option.label}
              </MenuItem>
            )
          })
        }
      </MuiSelect>

      {errorText}
    </FormControl>
  )
}

Select.defaultProps = {
  value: '',
  onChange: () => null,
  required: false,
  displayEmpty: false,
  size: 'medium'
}

Select.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  error: PropTypes.any,
  info: PropTypes.any,
  name: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  displayEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
}
