import { connect } from 'react-redux'

import ContactCard from 'components/anewstip/duplicate_contacts_dialog/duplicate_contact_item/contact_card/ContactCard'

import {
  getAllStaticCountries,
  getAllStaticMediaTopics,
  getAllStaticMediaTypes,
  getAllStaticLanguages,
  getStaticStates
} from 'selectors'

const mapStateToProps = (state, ownProps) => ({
  data: ownProps.data,
  countries: getAllStaticCountries(state),
  states: getStaticStates(state),
  topics: getAllStaticMediaTopics(state),
  mediaTypes: getAllStaticMediaTypes(state),
  languages: getAllStaticLanguages(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(ContactCard)
