import { connect } from 'react-redux'

import SpecificSettings from 'components/personal_settings/specific_settings'
import { setUserConfigField, updateUserConfigRequestStart } from 'actions/config'

import { getUser, getUserConfig } from 'selectors'

const mapStateToProps = state => ({
  user: getUser(state),
  userConfig: getUserConfig(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setUserConfigField,
    onSave: updateUserConfigRequestStart
  }
)(SpecificSettings)
