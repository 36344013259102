import { call, put, select, takeEvery, all, delay, race, take } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import { listChunk } from 'utils/immutable'
import moment from 'moment-timezone'

import { Capabilities, NewsPageViews, NewsPageModules } from 'static/constants'

import * as Actions from 'actions/news'
import * as InfluencersActions from 'actions/influencers'
import * as PublicationsActions from 'actions/publications'
import * as PageIdentitiesActions from 'actions/page_identities'
import * as FilterActions from 'actions/filter'
import * as SavedSearchesActions from 'actions/saved_searches'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'
import * as ChartsActions from 'actions/charts'
import * as AnalysisActions from 'actions/analysis'
import * as ExportActions from 'actions/export'
import { retriable, getCluster } from 'utils/sagas'
import { rangeToDates } from 'utils/date'
import { saveCookie, readCookie } from 'utils/cookieHelper'

export function* fetchNewsTry() {
  const newsIds = yield select(Selectors.getNewsGroupNewsIds)
  const searchBody = yield select(Selectors.getSearchBody)
  const lastSeenNewsId = yield select(Selectors.getLastSeenNewsId)
  const lastSeenNewsIdCookieName = yield select(Selectors.getLastSeenNewsIdCookieName)

  const result = yield call(Api.search, searchBody)

  const news = fromJS(result.groups).flatMap(group => group.get('news')).toJS()
  const maxNewsId = Math.max(...news.map(({ id }) => id))

  if (maxNewsId > lastSeenNewsId) {
    yield call(saveCookie, lastSeenNewsIdCookieName, maxNewsId, moment().startOf('day').add(1, 'years').toDate())
  }

  yield put(Actions.addNews(news))
  yield put(Actions.newsRequestSuccess(result))
  yield put(Actions.increasePaging(result))
  yield put(Actions.decrementReferences(newsIds))
}

export function* fetchNewsFail(error) {
  yield put(AppActions.genericErrorMessage())
  yield put(Actions.newsRequestError(error))
  yield put(AppActions.exception(error))
}

export function* fetchNews() {
  const isValidSearch = yield select(Selectors.isValidSearch)
  const newsRequestBlocked = yield select(Selectors.getNewsRequestBlocked)

  if (isValidSearch && !newsRequestBlocked) {
    const effects = [put(Actions.startNewsRequest())]

    const capabilities = yield select(Selectors.getCapabilities)
    const viewConfigCapabilties = yield select(Selectors.getViewConfigCapabilities)

    const influencersViews = [NewsPageViews.INFLUENCERS]
    let influencersAllowed = yield select(Selectors.isAllowedNewsView, ...influencersViews)
    let capNames = viewConfigCapabilties.get('influencers')
    influencersAllowed = influencersAllowed && capNames.some(capName => capabilities.get(capName))

    if (influencersAllowed) {
      effects.push(put(InfluencersActions.influencersRequestStart()))
    }

    const publicationsViews = [NewsPageViews.PUBLICATIONS]
    let publicationsAllowed = yield select(Selectors.isAllowedNewsView, ...publicationsViews)
    capNames = viewConfigCapabilties.get('publications')
    publicationsAllowed = publicationsAllowed && capNames.some(capName => capabilities.get(capName))

    if (publicationsAllowed) {
      effects.push(put(PublicationsActions.publicationsRequestStart()))
    }

    const profilesViews = [NewsPageViews.PROFILES]
    const profilesAllowed = yield select(Selectors.isAllowedNewsView, ...profilesViews)

    if (capabilities.get(Capabilities.HAS_PROFILE_MONITORING_MODULE) && profilesAllowed) {
      effects.push(put(PageIdentitiesActions.pageIdentitiesRequestStart()))
    }

    const analysisViews = [NewsPageViews.ANALYSIS_QUERIES]
    const analysisAllowed = yield select(Selectors.isAllowedNewsView, ...analysisViews)

    if (capabilities.get(Capabilities.HAS_ANALYSIS_MODULE) && analysisAllowed) {
      effects.push(put(AnalysisActions.fetchChartDataStart()))
    }

    const aggregationsViews = [
      NewsPageViews.CHARTS,
      NewsPageViews.STATIC_CHARTS
    ]
    const aggregationsAllowed = yield select(
      Selectors.isAllowedNewsView,
      ...aggregationsViews
    )
    const hasAggregationsCap = [
      Capabilities.HAS_CHARTS_MODULE,
      Capabilities.HAS_NEWS_POOL_MODULE
    ].some(c => capabilities.get(c))
    const isAggregationsView = yield select(Selectors.isCurrentView, ...aggregationsViews)
    const moduleName = yield select(Selectors.getViewConfigModuleName)

    if (hasAggregationsCap && aggregationsAllowed && !(moduleName === NewsPageModules.NEWS_POOL && !isAggregationsView)) {
      if (isAggregationsView) {
        if (moduleName === NewsPageModules.NEWS_POOL) {
          effects.unshift(delay(1000))
        }

        effects.unshift(put(ChartsActions.aggregationsRequestStart()))
      } else {
        effects.push(put(ChartsActions.aggregationsRequestStart()))
      }
    }

    for (let i = 0; i < effects.length; i += 1) {
      yield effects[i]
    }
  } else {
    yield put(Actions.setNewsRequestIsRunning(false))
  }
}

export function* deleteNews({ payload: newsId }) {
  try {
    let newsIds = fromJS([newsId])
    const news = yield select(Selectors.getNewsById, newsId)

    if (news.get('clusterSize') > 1) {
      const result = yield call(getCluster, news, Api.fetchNewsIds)

      newsIds = newsIds.concat(result)
    }

    newsIds = fromJS(newsIds)

    const chunkCount = Math.ceil(newsIds.size / 50)
    const chunks = listChunk(newsIds, chunkCount)

    let fun = Api.deleteNews

    if (news.getIn(['flags', 'backwardsNews'])) {
      fun = Api.deleteBackwardsNews
    }

    for (let i = 0; i < chunks.size; i += 1) {
      yield call(fun, chunks.get(i).join(','))
    }

    yield put(Actions.deleteNewsSuccess(newsIds))
    yield put(Actions.newsRequestStart())
  } catch (error) {
    yield put(Actions.deleteNewsError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* changeDateInterval() {
  const isProfileMonitoring = yield select(Selectors.isAllowedNewsView, NewsPageViews.STATISTICS)
  const isAnalysis = yield select(Selectors.isAllowedNewsView, NewsPageViews.ANALYSIS_QUERIES)

  if (isProfileMonitoring) {
    yield put(PageIdentitiesActions.pageIdentitiesRequestStart())
  } else if (isAnalysis) {
    yield put(AnalysisActions.fetchChartDataStart())
  } else {
    yield put(ChartsActions.aggregationsRequestStart())
  }
}

export function* fetchMoreNewsTry({ payload: id }) {
  const fetchMoreNewsBody = yield select(Selectors.getFetchMoreNewsBody, id)

  const result = yield call(Api.search, fetchMoreNewsBody)

  const groupData = result.groups.filter(c => c.id === id)[0]

  if (groupData) {
    yield put(Actions.addNews(groupData.news))
  }

  yield put(Actions.moreNewsRequestSuccess({
    data: result,
    id
  }))

  yield put(Actions.increasePaging({ groupId: id }))
}

export function* fetchMoreNewsFail(error) {
  yield put(Actions.moreNewsRequestError(error))
  yield put(AppActions.exception(error))
}

export function* fetchMoreNews(action) {
  yield call(retriable, fetchMoreNewsTry, fetchMoreNewsFail, action)
}

export function* selectViewConfigPreset({ payload: { name, keepFilters, keepDateType } }) {
  const previousConfig = yield select(Selectors.getNewsViewConfig)

  yield put(Actions.setViewConfigPreset(name))

  const newConfig = yield select(Selectors.getNewsViewConfig)

  if (!previousConfig.equals(newConfig)) {
    const allowedViews = yield select(Selectors.getAllowedNewsViews)

    yield put(SavedSearchesActions.setInitialSearchLoaded(false))
    yield put(ExportActions.resetExportConfig())

    if (!keepDateType) {
      const dateType = yield select(Selectors.getViewConfigDefaultDateType)

      if (dateType == null) {
        const configDefaultDateType = yield select(Selectors.getConfigDefaultDateType)
        yield put(FilterActions.changeDateType(configDefaultDateType))
      } else {
        yield put(FilterActions.changeDateType(dateType))
      }
    }

    const moduleName = yield select(Selectors.getViewConfigModuleName)

    if (moduleName === NewsPageModules.NEWS_POOL) {
      const { from, to } = yield call(rangeToDates, 'today')

      yield put(FilterActions.changeDate({
        dateFrom: from,
        dateTo: to,
        dateRange: 'today'
      }))
    }

    const groupNewsIds = yield select(Selectors.getNewsGroupNewsIds)
    const bestPostIds = yield select(Selectors.getBestPageIdentityPostIds)
    const worstPostIds = yield select(Selectors.getWorstPageIdentityPostIds)

    const newsIds = groupNewsIds.concat(bestPostIds, worstPostIds)

    const groupingTypeChanged = newConfig.get('groupingType') !== previousConfig.get('groupingType')
    const moduleNameChanged = moduleName !== previousConfig.get('moduleName')

    yield put(Actions.resetNews())

    if (!keepFilters) {
      const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
      yield put(FilterActions.resetFilters(globalClusterDefault))
      yield put(AppActions.loadFromLocalStorage(false))
    }

    yield put(Actions.decrementReferences(newsIds))

    if (moduleNameChanged) {
      yield put(Actions.setNewsView(allowedViews.first()))
    }

    const lastSeenNewsIdCookieName = yield select(Selectors.getLastSeenNewsIdCookieName)
    let lastSeenNewsId = yield call(readCookie, lastSeenNewsIdCookieName)
    lastSeenNewsId = parseInt(lastSeenNewsId || 0, 10)
    yield put(Actions.setLastSeenNewsId(lastSeenNewsId))

    const savedSearch = yield select(Selectors.getFavoritedSavedSearch)

    // Prevent loading saved search when switching grouping (i.e.: Topics, Channels, Pin boards) in news module.
    // Only load saved search when the entire module changes.
    if (moduleNameChanged && savedSearch) {
      yield put(SavedSearchesActions.loadInitialSearch())
    } else if (newConfig.get('loadNewsOnEnter') || (!moduleNameChanged && groupingTypeChanged)) {
      yield put(Actions.newsRequestStart())
    }
  }

  yield put(Actions.viewConfigPresetSelected())
}

export function* fetchClusterStats({ payload: newsId }) {
  try {
    const news = yield select(Selectors.getNewsById, newsId)

    if (news.get('clusterSize') > 1) {
      const clusteredNews = yield call(getCluster, news)

      yield put(Actions.updateNews([news.set('clusteredNews', clusteredNews)]))
    }
  } catch (error) {
    yield put(Actions.fetchClusterStatsError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* startNewsRequest() {
  yield race({
    task: call(retriable, fetchNewsTry, fetchNewsFail),
    cancel: take(Actions.startNewsRequest)
  })
}

export function* startTranslateRequest({ payload: { news, targetLang } }) {
  try {
    const newsId = news.get('id')

    const body = {
      headline: news.get('headline'),
      snippet: news.get('snippet'),
      target_lang: targetLang,
      news_id: newsId
    }

    if (news.get('summary')) {
      body.summary = news.get('summary')
    }

    if (news.get('summaryHeadline')) {
      body.summary_headline = news.get('summaryHeadline')
    }

    const result = yield call(Api.translate, body)
    yield put(Actions.translateRequestSuccess({ translations: result.translations, newsId, targetLang }))
  } catch (error) {
    yield put(Actions.translateRequestError(error))
  }
}

export function* watchDeleteNews() {
  yield takeEvery(Actions.deleteNewsStart, deleteNews)
}

export function* watchFetchNews() {
  yield takeEvery(Actions.newsRequestStart, fetchNews)
}

export function* watchFetchChangeDateInterval() {
  yield takeEvery(FilterActions.changeDateInterval, changeDateInterval)
}

export function* watchFetchMoreNews() {
  yield takeEvery(Actions.moreNewsRequestStart, fetchMoreNews)
}

export function* watchSelectViewConfigPreset() {
  yield takeEvery(Actions.selectViewConfigPreset, selectViewConfigPreset)
}

export function* watchFetchClusterStats() {
  yield takeEvery(Actions.fetchClusterStatsStart, fetchClusterStats)
}

export function* watchStartNewsRequest() {
  yield takeEvery(Actions.startNewsRequest, startNewsRequest)
}

export function* watchTranslateRequestStart() {
  yield takeEvery(Actions.translateRequestStart, startTranslateRequest)
}

export default function* newsSaga() {
  yield all([
    watchDeleteNews(),
    watchFetchNews(),
    watchFetchChangeDateInterval(),
    watchFetchMoreNews(),
    watchSelectViewConfigPreset(),
    watchFetchClusterStats(),
    watchStartNewsRequest(),
    watchTranslateRequestStart()
  ])
}
