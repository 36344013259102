import { connect } from 'react-redux'

import FilterableMenu from 'components/filterable_menu'

import { executeThemaxSavedSearch } from 'actions/themax'
import { navigate } from 'actions/navigation'

import {
  getThemaxSavedSearches as getSavedSearches,
  getThemaxSavedSearchesRequestRunning as getRequestRunning
} from 'selectors/themax'

const mapStateToProps = state => ({
  items: getSavedSearches(state).get('items'),
  loading: getRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onItemClick: executeThemaxSavedSearch,
    onMainClick: () => navigate('saved_searches')
  }
)(FilterableMenu)
