import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import SaveIcon from '@mui/icons-material/Save'
import { makeStyles } from 'tss-react/mui'
import { Button } from 'containers/themed'
import { CardContent, Typography } from '@mui/material'
import { red } from '@mui/material/colors'

import PasswordInput from 'containers/PasswordInput'
import TransitionComponent from 'components/transition_component'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  error: {
    color: red[500]
  }
})

export default function PasswordForm({ user, onSave }) {
  const { classes } = useStyles()
  const [state, setState] = useState({ oldPassword: '', newPassword1: '', newPassword2: '' })
  const i18n = useI18n()

  const handleChange = (name, value) => {
    setState({ ...state, [name]: value })
  }

  const handleSaveClick = () => {
    const { newPassword1, oldPassword } = state

    onSave({
      newPassword: newPassword1,
      oldPassword
    })
  }

  const isValid = () => {
    const { oldPassword, newPassword1, newPassword2 } = state

    if (!(oldPassword && newPassword1 && newPassword2)) {
      return false
    }

    if (newPassword1 !== newPassword2) {
      return false
    }

    return true
  }

  const renderUnequalPasswordsError = () => {
    const { newPassword1, newPassword2 } = state

    if ((newPassword1 === newPassword2) || !(newPassword1 && newPassword2)) {
      return null
    }

    return (
      <div className={classes.error}>
        {i18n.get('passwords_do_not_match')}
        <br />
        <br />
      </div>
    )
  }

  const { oldPassword, newPassword1, newPassword2 } = state

  return (
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
      >
        {i18n.get('change_password')}
      </Typography>

      <PasswordInput
        autoComplete="new-password"
        label={i18n.get('old_password')}
        value={oldPassword}
        onChange={value => handleChange('oldPassword', value)}
      />

      <br />

      <PasswordInput
        autoComplete="new-password"
        label={i18n.get('new_password')}
        value={newPassword1}
        onChange={value => handleChange('newPassword1', value)}
        validateStrength
      />

      <br />

      <PasswordInput
        autoComplete="new-password"
        label={i18n.get('repeat_password')}
        value={newPassword2}
        onChange={value => handleChange('newPassword2', value)}
      />

      <br />

      <TransitionComponent type="fade">
        {renderUnequalPasswordsError()}
      </TransitionComponent>

      <Button
        color="primary"
        variant="contained"
        saving={user.get('updating')}
        disabled={!isValid()}
        onClick={handleSaveClick}
        startIcon={<SaveIcon />}
      >
        {i18n.get('save')}
      </Button>
    </CardContent>
  )
}

PasswordForm.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,

  onSave: PropTypes.func.isRequired
}
