import { connect } from 'react-redux'

import FilesGallery from 'components/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/FilesGallery'

import {
  deleteMediaFileStart as onMediaDelete,
  fetchMoreMediaFilesStart as fetchMore,
  setContentFormData as onChange,
  uploadFacebookMediaFileStart as onUpload
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskUploadedMediaFiles,
  getCDeskUploadedMediaFilesTotal,
  getCDeskMediaFilesPage,
  getCDeskMediaFilesLimit,
  getCDeskMediaFilesLoading,
  getCDeskMediaFileUploading,
  getCDeskUploadedMediaFilesTotalSize,
  getCDeskAttachmentsMediaType
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  uploadedMediaFiles: getCDeskUploadedMediaFiles(state),
  attachmentsMediaType: getCDeskAttachmentsMediaType(state),
  loadMoreDisabled: getCDeskUploadedMediaFilesTotal(state) < (getCDeskMediaFilesPage(state) - 1) * getCDeskMediaFilesLimit(state),
  requestRunning: getCDeskMediaFilesLoading(state),
  mediaFileUploading: getCDeskMediaFileUploading(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state)
})

export default connect(
  mapStateToProps,
  {
    fetchMore,
    onMediaDelete,
    onChange,
    onUpload
  }
)(FilesGallery)
