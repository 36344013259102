import { sort } from 'utils/normalizr'
import { createImmutableSelector } from 'utils/reselect'

// DATA
export const getIdentitySetsData = state => state.identitySets.get('data')

export const getIdentitySets = createImmutableSelector(
  state => getIdentitySetsData(state).get('result').toList(),
  state => getIdentitySetsData(state).get('entities'),
  sort
)

// SELECTED_IDENTITY_SET
export const getSelectedIdentitySet = state => state.identitySets.get('selectedIdentitySet')

// SELECTED_IDENTITY
export const getIdentitySetsSelectedIdentity = state => state.identitySets.get('selectedIdentity')

// UI
export const getIdentitySetsUi = state => state.identitySets.get('ui')
export const getShowAddIdentityToIdentitySetDialog = state => getIdentitySetsUi(state).get('showAddIdentityToIdentitySetDialog')
export const getShowIdentitySetDialog = state => getIdentitySetsUi(state).get('showIdentitySetDialog')
export const getShowDeleteIdentitySetDialog = state => getIdentitySetsUi(state).get('showDeleteIdentitySetDialog')
export const getIdentitySetsUploadedPhoto = state => getIdentitySetsUi(state).get('uploadedPhoto')
export const getIdentitySetIsLoading = (state, id) => getIdentitySetsUi(state).get('loadingIdentitySetIds').has(id)
export const getIdentitySetSaving = state => getIdentitySetsUi(state).get('savingIdentitySet')
export const getIdentitySetIsAdded = (state, identitySetId) => (
  getIdentitySetsSelectedIdentity(state).get('identitySetIds').some(id => id === identitySetId)
)
