import { put, call, takeEvery, all, select } from 'redux-saga/effects'

import * as Actions from 'actions/api_keys'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'

export function* submitApiKey({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    yield call(Api.submitApiKey, {
      api_key: payload.name, groot_external_api_key_type_id: payload.typeId
    })
    yield put(Actions.submitApiKeySuccess())
    yield put(ConfigActions.configRequestStart())
  } catch (error) {
    yield put(Actions.submitApiKeyError())

    if (error.response.statusCode === 401) {
      yield put(AppActions.showAppMessage({ text: i18n.get('invalid_api_key'), success: false }))
    } else {
      yield put(AppActions.genericErrorMessage())
    }
  }
}

export function* deleteApiKey({ payload: id }) {
  try {
    yield call(Api.deleteApiKey, id)
    yield put(Actions.deleteApiKeySuccess())
    yield put(ConfigActions.configRequestStart())
  } catch (error) {
    yield put(Actions.deleteApiKeyError())
    yield put(AppActions.genericErrorMessage())
  }
}

export function* watchSubmitApiKey() {
  yield takeEvery(Actions.submitApiKeyStart, submitApiKey)
}

export function* watchDeleteApiKey() {
  yield takeEvery(Actions.deleteApiKeyStart, deleteApiKey)
}

export default function* appSaga() {
  yield all([
    watchSubmitApiKey(),
    watchDeleteApiKey()
  ])
}
