import { connect } from 'react-redux'

import Navigation from 'components/themax/navigation'
import { changeTabView } from 'actions/themax'
import { getHasThemaxPaidVersion, getThemaxTabView, getThemaxData, getThemaxSearchRequestRunning } from 'selectors'

const mapStateToProps = state => ({
  hasPaidVersion: getHasThemaxPaidVersion(state),
  plansCount: getThemaxData(state).getIn(['editorialPlans', 'total']),
  publicationsCount: getThemaxData(state).getIn(['publications', 'total']),
  searchRequestRunning: getThemaxSearchRequestRunning(state),
  view: getThemaxTabView(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: changeTabView
  }
)(Navigation)
