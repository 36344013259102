import { createAction } from 'redux-actions'

const prefix = 'NEWS/EXPORT/'

export const changeExportFormatSelection = createAction(`${prefix}CHANGE_EXPORT_FORMAT_SELECTION`)

export const exportError = createAction(`${prefix}EXPORT_ERROR`)

export const exportSuccess = createAction(`${prefix}EXPORT_SUCCESS`)
export const exportStart = createAction(`${prefix}EXPORT_START`)
export const exportStructuredFormatStart = createAction(`${prefix}EXPORT_STRUCTURED_FORMAT_START`)
export const exportBackwardsNewsHtmlStart = createAction(`${prefix}EXPORT_BACKWARDS_NEWS_HTML_START`)

export const exportCoverError = createAction(`${prefix}EXPORT_COVER_ERROR`)
export const exportCoverSuccess = createAction(`${prefix}EXPORT_COVER_FINSISHED`)
export const exportCoverStart = createAction(`${prefix}EXPORT_COVER_START`)

export const exportHtmlPreviewSuccess = createAction(`${prefix}EXPORT_HTML_PREVIEW_SUCCESS`)
export const exportHtmlPreviewStart = createAction(`${prefix}EXPORT_HTML_PREVIEW_START`)
export const exportHtmlPreviewError = createAction(`${prefix}EXPORT_HTML_PREVIEW_ERROR`)

export const exportTxtPreviewSuccess = createAction(`${prefix}EXPORT_TXT_PREVIEW_SUCCESS`)
export const exportTxtPreviewStart = createAction(`${prefix}EXPORT_TXT_PREVIEW_START`)
export const exportTxtPreviewError = createAction(`${prefix}EXPORT_TXT_PREVIEW_ERROR`)

export const exportXmlPreviewSuccess = createAction(`${prefix}EXPORT_XML_PREVIEW_SUCCESS`)
export const exportXmlPreviewStart = createAction(`${prefix}EXPORT_XML_PREVIEW_START`)
export const exportXmlPreviewError = createAction(`${prefix}EXPORT_XML_PREVIEW_ERROR`)

export const exportJsonPreviewSuccess = createAction(`${prefix}EXPORT_JSON_PREVIEW_SUCCESS`)
export const exportJsonPreviewStart = createAction(`${prefix}EXPORT_JSON_PREVIEW_START`)
export const exportJsonPreviewError = createAction(`${prefix}EXPORT_JSON_PREVIEW_ERROR`)

export const uploadHtmlHeaderLogoSuccess = createAction(`${prefix}UPLOAD_HTML_HEADER_LOGO_SUCCESS`)
export const uploadHtmlHeaderLogoStart = createAction(`${prefix}UPLOAD_HTML_HEADER_LOGO_START`)
export const uploadHtmlHeaderLogoError = createAction(`${prefix}UPLOAD_HTML_HEADER_LOGO_ERROR`)

export const setUploadedHeaderLogoUrl = createAction(`${prefix}SET_UPLOADED_HEADER_LOGO_URL`)
export const resetUploadedHeaderLogoUrl = createAction(`${prefix}RESET_UPLOADED_HEADER_LOGO_URL`)

export const uploadHtmlAdvertisingBannerSuccess = createAction(`${prefix}UPLOAD_HEADER_ADVERTISING_BANNER_SUCCESS`)
export const uploadHtmlAdvertisingBannerStart = createAction(`${prefix}UPLOAD_HEADER_ADVERTISING_BANNER_START`)
export const uploadHtmlAdvertisingBannerError = createAction(`${prefix}UPLOAD_HEADER_ADVERTISING_BANNER_ERROR`)

export const setUploadedAdvertisingBannerUrl = createAction(`${prefix}SET_UPLOADED_ADVERTISING_BANNER_URL`)
export const resetUploadedAdvertisingBannerUrl = createAction(`${prefix}RESET_UPLOADED_ADVERTISING_BANNER_URL`)

export const resetExportUiState = createAction(`${prefix}RESET_EXPORT_UI_STATE`)

export const hideDocumentDownloadDialog = createAction(`${prefix}HIDE_DOCUMENT_DOWNLOAD_DIALOG`)
export const hideExportDialog = createAction(`${prefix}HIDE_EXPORT_DIALOG`)
export const hideSendMailDialog = createAction(`${prefix}HIDE_SEND_MAIL_DIALOG`)
export const setExportConfig = createAction(`${prefix}SET_EXPORT_CONFIG`)
export const resetExportConfig = createAction(`${prefix}RESET_EXPORT_CONFIG`)
export const sendMail = createAction(`${prefix}SEND_MAIL`)
export const sendMailSuccess = createAction(`${prefix}SEND_MAIL_SUCCESS`)
export const sendMailError = createAction(`${prefix}SEND_MAIL_ERROR`)
export const showExportDialog = createAction(`${prefix}SHOW_EXPORT_DIALOG`)
export const showSendMailDialog = createAction(`${prefix}SHOW_SEND_MAIL_DIALOG`)
export const updateExportSettings = createAction(`${prefix}UPDATE_EXPORT_SETTINGS`)
export const updateExportMediaReviewHtmlConfig = createAction(`${prefix}UPDATE_EXPORT_MEDIA_REVIEW_HTML_CONFIG`)
export const updateExportMediaReviewPdfConfig = createAction(`${prefix}UPDATE_EXPORT_MEDIA_REVIEW_PDF_CONFIG`)
export const updateExportDispatchConfig = createAction(`${prefix}UPDATE_EXPORT_DISPATCH_CONFIG`)
export const updateExportPdfDispatchConfig = createAction(`${prefix}UPDATE_EXPORT_PDF_DISPATCH_CONFIG`)
export const updateExportMediaReviewPdfCoverPageElement = createAction(`${prefix}UPDATE_EXPORT_PDF_MEDIA_REVIEW_PDF_COVER_PAGE_ELEMENT`)
export const updateExportMediaReviewPdfEndPageElement = createAction(`${prefix}UPDATE_EXPORT_PDF_MEDIA_REVIEW_PDF_END_PAGE_ELEMENT`)
export const updateTranslationOptions = createAction(`${prefix}UPDATE_TRANSLATION_OPTIONS`)
export const validateFilenameInExportSettings = createAction(`${prefix}VALIDATE_FILENAME_IN_EXPORT_SETTINGS`)
export const setValueInSettings = createAction(`${prefix}SET_VALUE_IN_SETTINGS`)
export const setExportFilename = createAction(`${prefix}SET_EXPORT_FILENAME`)

export const exportProgressIncreaseStep = createAction(`${prefix}EXPORT_PROGRESS_INCREASE_STEP`)
export const exportProgressReset = createAction(`${prefix}EXPORT_PROGRESS_RESET`)
export const exportProgressStart = createAction(`${prefix}EXPORT_PROGRESS_START`)
export const exportProgressFinish = createAction(`${prefix}EXPORT_PROGRESS_FINISH`)
export const exportProgressStop = createAction(`${prefix}EXPORT_PROGRESS_STOP`)

export const validateSpfSuccess = createAction(`${prefix}VALIDATE_SPF_SUCCESS`)
export const validateSpfStart = createAction(`${prefix}VALIDATE_SPF_START`)
export const validateSpfStarted = createAction(`${prefix}VALIDATE_SPF_STARTED`)
export const validateSpfError = createAction(`${prefix}VALIDATE_SPF_ERROR`)

export const changeStartEndDate = createAction(`${prefix}CHANGE_START_END_DATE`)

export const setLastExport = createAction(`${prefix}SET_LAST_EXPORT`)

export const fetchDispatchLogStart = createAction(`${prefix}FETCH_DISPATCH_LOG_START`)
export const fetchDispatchLogSuccess = createAction(`${prefix}FETCH_DISPATCH_LOG_SUCCESS`)
export const fetchDispatchLogError = createAction(`${prefix}FETCH_DISPATCH_LOG_ERROR`)

export const createDispatchStart = createAction(`${prefix}CREATE_DISPATCH_START`)
export const createDispatchSuccess = createAction(`${prefix}CREATE_DISPATCH_SUCCESS`)
export const createDispatchError = createAction(`${prefix}CREATE_DISPATCH_ERROR`)

export const cancelDispatchStart = createAction(`${prefix}CANCEL_DISPATCH_START`)
export const cancelDispatchSuccess = createAction(`${prefix}CANCEL_DISPATCH_SUCCESS`)
export const cancelDispatchError = createAction(`${prefix}CANCEL_DISPATCH_ERROR`)
