import { connect } from 'react-redux'

import CampaignName from 'components/content_desk_new/campaigns/campaign/campaign_name'

const mapStateToProps = (_state, ownProps) => ({
  campaign: ownProps.campaign,
  maxLength: ownProps.maxLength
})

export default connect(
  mapStateToProps,
  {
  }
)(CampaignName)

