import { combineReducers } from 'redux-immutable'

import data from './data'
import ui from './ui'
import filter from './filter'

export default combineReducers({
  data,
  ui,
  filter
})
