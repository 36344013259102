import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = Set([])

export default handleActions({
  [Actions.selectNews]: (state, { payload: newsIds }) => {
    let newNewsIds = fromJS([newsIds]).flatten().toSet()
    const intersection = state.intersect(newNewsIds)
    newNewsIds = newNewsIds.subtract(intersection)

    return state.subtract(intersection).union(newNewsIds)
  },

  [Actions.resetNewsSelection]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)
