import React from 'react'
import PropTypes from 'prop-types'
import { Button, IconButton } from 'containers/themed'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  button: {
    marginLeft: 15
  },
  iconButton: {
    [theme.breakpoints.only('sm')]: {
      padding: 8
    }
  }
}))

export default function FilterButton({ active, color, device, onClick }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const activeColor = 'secondary'
  const renderButton = () => {
    let comp = (
      <div>

        <Button
          className={classes.button}
          variant="contained"
          color={active ? activeColor : color}
          onClick={onClick}
          startIcon={<FilterListIcon />}
        >
          {i18n.get('filter')}
        </Button>
      </div>
    )

    if (device.get('lt-md')) {
      comp = (
        <IconButton
          classes={{ root: classes.iconButton }}
          color={active ? activeColor : 'inherit'}
          onClick={onClick}
          size="large"
        >
          <FilterListIcon />
        </IconButton>
      )
    }

    return comp
  }

  return (
    <>
      {renderButton()}
    </>
  )
}
FilterButton.defaultProps = {
  color: 'inherit'
}

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  color: PropTypes.string,
  device: PropTypes.instanceOf(Map)
}
