import { connect } from 'react-redux'

import Sortbar from 'components/shopping_cart/sortbar'
import { sortBy, sortByUngrouped } from 'actions/shopping_cart'
import { getI18n } from 'selectors'

const mapStateToProps = (state, { tab }) => ({
  i18n: getI18n(state),
  tab
})

export default connect(
  mapStateToProps,
  {
    onSortBy: sortBy,
    onUngroupedSortBy: sortByUngrouped
  }
)(Sortbar)
