import decamelize from 'decamelize'
import { fromJS, Set } from 'immutable'

import { ChartDataSources } from 'static/constants'
import { getChartConfigs } from '../../calculated'
import { getViewConfigModuleName } from '../view_config'

import { getI18n } from '../../i18n'

const popularFirstLevels = Set(['mediaReviewCodes', 'channels', 'customTags'])
const specialConfigs = fromJS([
  'isNumber',
  'isTimeline',
  'isSavedSearchesTimeline',
  'isNewsFeed',
  'isTopInfluencers',
  'isTopPublications',
  'isPageIdentities',
  'isPageIdentitiesTimeline',
  'isImageFeed'
])

const emptyList = fromJS([])
const emptyMap = fromJS({})

const filterByModuleNameAndDataSource = (chart, entities, state) => {
  const moduleName = chart.getIn(['savedSearch', 'moduleName']) || getViewConfigModuleName(state)
  const dataSource = chart.get('dataSource', ChartDataSources.PRESSRELATIONS_NEWS)

  return entities
    .filter(entity => {
      if (!moduleName || !entity.get('moduleNames')) {
        return true
      }

      return entity.get('moduleNames').includes(moduleName)
    })
    .filter(entity => {
      if (!entity.get('dataSources')) {
        return true
      }

      return entity.get('dataSources').includes(dataSource)
    })
}

const getLabel = (state, label) => getI18n(state).get(decamelize(label))

export const getChartDialogNumberFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isNumber')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogOtherFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => specialConfigs.every(key => !value.get(key))),
  state
)
  .keySeq()
  .toList()
  .filterNot(value => popularFirstLevels.has(value))
  .sortBy(item => getLabel(state, item))

export const getChartDialogPopularFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => specialConfigs.every(key => !value.get(key))),
  state
)
  .keySeq()
  .toList()
  .filter(value => popularFirstLevels.has(value))
  .sortBy(item => getLabel(state, item))

export const getChartDialogTimelineFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isTimeline')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogSavedSearchesTimelineFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isSavedSearchesTimeline')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogNewsFeedFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isNewsFeed')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogTopInfluencersFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isTopInfluencers')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogTopPublicationsFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isTopPublications')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogPageIdentitiesTimelineFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isPageIdentitiesTimeline')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogPageIdentitiesFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isPageIdentities')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogImageFeedFirstLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).filter(value => value.get('isImageFeed')),
  state
)
  .keySeq()
  .toList()
  .sortBy(item => getLabel(state, item))

export const getChartDialogChartTypes = (state, chart) => (
  getChartConfigs(state).getIn([chart.get('firstLevel'), 'chartTypes'], emptyMap).keySeq().toList()
    .sortBy(item => getLabel(state, `chart_type_${item}`))
)

export const getChartDialogSecondLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).getIn([chart.get('firstLevel'), 'chartTypes', chart.get('type'), 'secondLevels'], emptyList),
  state
)
  .map(item => item.get('name'))
  .sortBy(item => getLabel(state, item))

export const getChartDialogThirdLevels = (state, chart) => filterByModuleNameAndDataSource(
  chart,
  getChartConfigs(state).getIn([chart.get('firstLevel'), 'chartTypes', chart.get('type'), 'thirdLevels'], emptyList),
  state
)
  .map(item => item.get('name'))
  .sortBy(item => getLabel(state, item))
