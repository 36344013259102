import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { listMove } from 'utils/immutable'
import { multiDragAwareReorder } from 'utils/multidrag'

import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  summary: null,
  summaryContainers: [],
  groupedNews: [],
  totalCount: 0,
  count: 0,
  loadedFullTexts: [],
  reactions: {}
})

const removeUserReactionFromNewsAndCode = (state, codeId, newsId, userId) => state.updateIn(
  ['reactions', `${codeId || ''}||${newsId}`],
  reactions => (!reactions ? reactions : reactions.map(reaction => reaction.filter(user => user.get('id') !== userId)))
)

const addUserReactionToNewsAndCode = (state, codeId, newsId, reactionTypeId, user) => state.updateIn(
  ['reactions', `${codeId || ''}||${newsId}`, reactionTypeId],
  reactions => {
    if (!reactions) {
      return fromJS([user])
    }

    return reactions.push(fromJS(user))
  }
)

const cleanUpReactions = state => (
  state.update(
    'reactions',
    reactions => (
      reactions
        .map(reaction => reaction.filter(r => !r.isEmpty()))
        .filter(r => !r.isEmpty())
    )
  )
)

const moveCode = (state, oldIndex, newIndex) => (
  state.update('groupedNews', groupedNews => listMove(groupedNews, oldIndex, newIndex))
)

const moveNews = (state, codeId, oldIndexes, newIndex) => {
  const groupIndex = state.get('groupedNews').findIndex(gn => gn.getIn(['code', 'id']) === codeId)
  const newNews = listMove(state.getIn(['groupedNews', groupIndex, 'news']), oldIndexes, newIndex)

  return state.setIn(['groupedNews', groupIndex, 'news'], newNews)
}

const removeEmptyCodes = groupedNews => groupedNews.filter(g => !g.get('news').isEmpty())

export default handleActions({
  [MediaReviewsActions.loadFullTextSuccess]: (state, { payload }) => {
    const newsId = payload.get('newsId')
    const text = payload.get('text')
    const index = state.get('loadedFullTexts').findIndex(t => t.get('newsId') === newsId)

    if (index >= 0) {
      return state
    }

    const result = { newsId, text }

    return state.mergeIn(['loadedFullTexts'], fromJS([result]))
  },
  [MediaReviewsActions.fetchNewsForMediaReviewStart]: () => initialState,
  [MediaReviewsActions.fetchNewsForMediaReviewSuccess]: (
    state,
    { payload: { total, count, reactions, data, summary, summaryContainers } }
  ) => {
    const groupedNews = fromJS(data).map(group => group.update('news', news => news.map(n => n.get('id'))))

    return state.merge({
      groupedNews,
      summary,
      summaryContainers,
      totalCount: total,
      count,
      reactions: fromJS(reactions)
    })
  },

  [MediaReviewsActions.moveCodeInMediaReviewStart]: (state, { payload: { oldIndex, newIndex } }) => moveCode(state, oldIndex, newIndex),
  [MediaReviewsActions.moveCodeInMediaReviewError]: (state, { payload: { oldIndex, newIndex } }) => moveCode(state, newIndex, oldIndex),

  [MediaReviewsActions.moveNewsInMediaReviewStart]:
  (state, { payload: { codeId, oldIndex, newIndex, selectedNews } }) => {
    const selectedDraggableIds = selectedNews.filter(sn => sn.get('codeId') === codeId).map(sn => sn.get('newsId'))
    const newNews = multiDragAwareReorder({
      draggableIds: state.get('groupedNews').find(gn => gn.getIn(['code', 'id']) === codeId).get('news'),
      selectedDraggableIds,
      sourceIndex: oldIndex,
      destinationIndex: newIndex
    })

    return state.update('groupedNews', gn => gn.map(g => (g.getIn(['code', 'id']) === codeId ? g.set('news', newNews) : g)))
  },
  // moveNews(state, codeId, oldIndexes, newIndex),
  [MediaReviewsActions.moveNewsInMediaReviewError]:
    (state, { payload: { codeId, oldIndex, newIndex } }) => moveNews(state, codeId, newIndex, oldIndex),

  [MediaReviewsActions.removeNewsFromMediaReviewSuccess]: (state, { payload: { newsId } }) => (
    state.update('groupedNews', gN => gN.map(g => g.update('news', news => (news.filter(id => id !== newsId)))))
      .update('groupedNews', removeEmptyCodes)
  ),

  [MediaReviewsActions.regroupNews]: (state, { payload: news }) => {
    const newsCodeIds = news.get('codes').map(c => c.get('id')).toSet()

    let groupedNews = state.get('groupedNews').map(g => {
      if (!newsCodeIds.has(g.getIn(['code', 'id']))) {
        return g.update('news', n => n.filter(id => id !== news.get('id')))
      }

      if (!g.get('news').includes(news.get('id'))) {
        return g.update('news', n => n.push(news.get('id')))
      }

      return g
    })

    const stateCodeIds = state.get('groupedNews').map(g => g.getIn(['code', 'id'])).toSet()
    const newCodes = news.get('codes').filter(c => !stateCodeIds.has(c.get('id')))

    groupedNews = newCodes.reduce((acc, value) => acc.push(fromJS({
      news: [news.get('id')],
      code: value
    })), groupedNews)

    return state.set('groupedNews', removeEmptyCodes(groupedNews))
  },
  [MediaReviewsActions.setGroupedNews]: (state, { payload }) => state.set('groupedNews', payload),
  [MediaReviewsActions.recalculateMediaReviewStats]: (state, { payload: { news } }) => {
    const count = news.size
    const totalCount = news.flatMap(n => n.get('clusteredNews')).size + count

    return state.merge({
      count,
      totalCount
    })
  },

  [MediaReviewsActions.replaceMediaReviewReactionSuccess]: (state, { payload }) => {
    let newState = removeUserReactionFromNewsAndCode(state, payload.codeId, payload.newsId, payload.user.id)

    if (payload.id) {
      newState = addUserReactionToNewsAndCode(newState, payload.codeId, payload.newsId, payload.reactionType.id.toString(), payload.user)
    }

    return cleanUpReactions(newState)
  },

  [MediaReviewsActions.fetchMediaReviewReactionsSuccess]: (state, { payload }) => state.set('reactions', fromJS(payload)),

  [AppActions.resetState]: () => initialState
}, initialState)
