import React from 'react'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import {
  Typography,
  Tooltip
} from '@mui/material'

const useStyles = makeStyles()({
  socialMediaContainer: {
    display: 'flex',
    gap: 10
  },
  blurredContainer: {
    filter: 'blur(6px)',
    WebkitFilter: 'blur(6px)',
    userSelect: 'none'
  }
})
const BlurredSocialHandles = () => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <div className={classes.socialMediaContainer}>
      <Typography>
        {`${i18n.get('social_media')}: `}
      </Typography>
      <Tooltip
        placement="right-start"
        arrow
        title={i18n.get('blurred_social_media_handles')}
      >
        <div className={classes.blurredContainer}>
          Social media handles hidden!
        </div>
      </Tooltip>
    </div>
  )
}
export default BlurredSocialHandles
