import { connect } from 'react-redux'

import RemoveDialog from 'components/dialogs/confirm_dialog'

import { deleteMailingListStart, hideRemoveMailingListDialog } from 'actions/mailing_lists'
import { getI18n, getShowRemoveMailingListDialog, getSelectedMailingList } from 'selectors'

const mapStateToProps = state => (
  {
    i18n: getI18n(state),
    title: getSelectedMailingList(state).get('name'),
    payload: getSelectedMailingList(state).get('id'),
    active: getShowRemoveMailingListDialog(state),
    text: getI18n(state).get('delete_mailing_list_text')
  }
)

export default connect(
  mapStateToProps,
  {
    onSubmit: deleteMailingListStart,
    onHide: hideRemoveMailingListDialog
  }
)(RemoveDialog)
