import React, { useState } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { TableCell, TableRow } from '@mui/material'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  tableCell: {
    '&:hover': {
      backgroundColor: theme.isDark ? '#383838' : '#e0e0e0',
      cursor: 'pointer'
    },
    borderRadius: '5px 5px 0px 0px'
  },
  publicationCell: {
    borderRadius: '5px 5px 0px 0px'
  },
  selected: {
    backgroundColor: theme.isDark ? '#383838' : '#e0e0e0',
    borderRadius: '5px 5px 0px 0px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  arrowColumn: {
    width: '50px',
    paddingTop: 0,
    paddingBottom: 0
  }
}))

const defaultSortingDirections = {
  first_name: 'desc',
  last_name: 'desc',
  email: 'desc',
  country: 'desc',
  publication: 'desc'
}

const ContactsSortbar = ({ onSortBy, onSelectAll }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [sortingDirections, setSortingDirections] = useState(
    defaultSortingDirections
  )
  const [selectedField, setSelectedField] = useState(null)

  const handleSortingClick = sortBy => {
    const sortOrder = sortingDirections[sortBy] !== 'desc' ? 'desc' : 'asc'

    setSortingDirections({ ...defaultSortingDirections, [sortBy]: sortOrder })
    setSelectedField(sortBy)
    onSortBy({ sortBy, sortOrder })
  }

  const sortTableCellIcon = () => {
    if (sortingDirections[selectedField] === 'desc') {
      return (
        <IconButton>
          <ArrowDownwardIcon
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    if (sortingDirections[selectedField] === 'asc') {
      return (
        <IconButton>
          <ArrowUpwardIcon
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    return (
      <IconButton>
        <SwapVertIcon
          onClick={() => handleSortingClick('first_name')}
        />
      </IconButton>
    )
  }

  return (
    <TableRow>
      <TableCell className={classes.arrowColumn}>{sortTableCellIcon()}</TableCell>
      <TableCell
        className={selectedField === 'first_name' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('first_name')}
      >
        {i18n.get('first_name')}
      </TableCell>
      <TableCell
        className={selectedField === 'last_name' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('last_name')}
      >
        {i18n.get('last_name')}
      </TableCell>
      <TableCell
        className={selectedField === 'email' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('mail')}
      >
        {i18n.get('email')}
      </TableCell>
      <TableCell
        className={selectedField === 'country' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('country_id')}
      >
        {i18n.get('country')}
      </TableCell>
      <TableCell>
        {i18n.get('outlet')}
      </TableCell>
      <TableCell
        className={selectedField === 'source' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('supplier')}
      >
        {i18n.get('source')}
      </TableCell>
      <TableCell>
        {i18n.get('distribution_lists')}
      </TableCell>
      <TableCell />
      <TableCell>
        <IconButton
          size="small"
          onClick={() => onSelectAll()}
          title={i18n.get('select_all')}
        >
          <PlaylistAddIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

ContactsSortbar.propTypes = {
  onSortBy: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired
}

export default ContactsSortbar
