import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as ChartsActions from 'actions/charts'

export const initialState = fromJS({
  charts: {},
  layouts: {}
})

export default handleActions({
  [ChartsActions.setPrevious]: (state, { payload: { charts, layouts } }) => state.set('charts', charts).set('layouts', layouts),
  [AppActions.resetState]: () => initialState
}, initialState)
