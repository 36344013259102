import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import HelpIcon from '@mui/icons-material/Help'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { makeStyles } from 'tss-react/mui'

import { SearchFields, Capabilities } from 'static/constants'

import { mapToOptions } from 'utils/autocomplete'
import { RaisedPrimaryButton, Button, Autocomplete, IconButton } from 'containers/themed'
import CapSafe from 'containers/CapSafe'
import ActionBar from 'components/search_form/action_bar'

import { Typography, Grid, Checkbox, FormControlLabel, FormGroup, Switch, Divider } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  buttonContainer: {
    backgroundColor: theme.nrx.paper.paperOnBackgroundColor,
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      width: 750
    },
    '& > div:last-child': {
      '& > *': {
        marginRight: 10
      },
      '& > *:last-child': {
        marginRight: 0
      }
    },
    '& > div:first-of-type': {
      marginRight: 0
    }
  },
  outlinksTitle: {
    marginBottom: 10
  },
  spacer: {
    flex: 0.9,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  searchForm: {
    overflow: 'visible',
    padding: 20,
    [theme.breakpoints.up('lg')]: {
      height: '90%',
      overflowY: 'auto'
    }
  },
  tonality: {
    padding: '0 12px'
  },
  divider: {
    marginBottom: 10
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:last-child': {
      marginRight: -5
    }
  }
}))

const mapToEntity = (entities, values) => (values || List([]))
  .map(({ value }) => {
    const found = entities.find(entity => entity.get('id') === value)

    if (found) {
      return found.toJS()
    }

    return null
  })
  .filter(e => e)

const Row = props => (
  <Grid
    container
    direction="row"
    spacing={1}
    {...props}
  />
)

const Col = props => (
  <Grid
    item
    xl={6}
    lg={6}
    md={4}
    sm={6}
    xs={12}
    {...props}
  />
)

const CustomTagsCol = CapSafe(Col, Capabilities.HAS_CUSTOM_TAGS)
const NewsguardCol = CapSafe(Col, Capabilities.HAS_NEWSGUARD)
const CampaignsCol = CapSafe(Col, Capabilities.HAS_CONTENT_DESK_MODULE)

const SearchForm = props => {
  const { classes } = useStyles()
  const {
    mediaReviewCodes,
    countries,
    languages,
    mediaTypes,
    channels,
    filterGroups,
    distributionAreas,
    customTags,
    customTagGroups,
    newsguardRanks,
    newsguardOrientations,
    identitySets,
    mediaReviewTypes,
    ccdCampaigns,
    interfaces,
    suppliers,
    hasTonality,
    allowedFilterFields,
    i18n,
    device,
    toggleHelpDialog,
    uiApplyFilters,
    uiResetFilters,
    setSelectedFilters,
    onClose
  } = props

  const [selectedMediaReviewCodes, setSelectedMediaReviewCodes] = useState(mapToOptions(props.selectedMediaReviewCodes))
  const [selectedMediaTypes, setSelectedMediaTypes] = useState(mapToOptions(props.selectedMediaTypes))
  const [selectedLanguages, setSelectedLanguages] = useState(mapToOptions(props.selectedLanguages))
  const [selectedCountries, setSelectedCountries] = useState(mapToOptions(props.selectedCountries))
  const [selectedChannels, setSelectedChannels] = useState(mapToOptions(props.selectedChannels))
  const [selectedFilterGroups, setSelectedFilterGroups] = useState(mapToOptions(props.selectedFilterGroups))
  const [selectedDistributionAreas, setSelectedDistributionAreas] = useState(mapToOptions(props.selectedDistributionAreas))
  const [selectedCustomTags, setSelectedCustomTags] = useState(mapToOptions(props.selectedCustomTags))
  const [selectedCustomTagGroups, setSelectedCustomTagGroups] = useState(mapToOptions(props.selectedCustomTagGroups))
  const [selectedNewsguardRanks, setSelectedNewsguardRanks] = useState(mapToOptions(props.selectedNewsguardRanks))
  const [selectedNewsguardOrientations, setSelectedNewsguardOrientations] = useState(mapToOptions(props.selectedNewsguardOrientations))
  const [selectedIdentitySets, setSelectedIdentitySets] = useState(mapToOptions(props.selectedIdentitySets))
  const [selectedOutlinkDomains, setSelectedOutlinkDomains] = useState(mapToOptions(props.selectedOutlinkDomains))
  const [selectedOutlinkUrls, setSelectedOutlinkUrls] = useState(mapToOptions(props.selectedOutlinkUrls))
  const [selectedMediaReviewTypes, setSelectedMediaReviewTypes] = useState(mapToOptions(props.selectedMediaReviewTypes))
  const [selectedCcdCampaigns, setSelectedCcdCampaigns] = useState(mapToOptions(props.selectedCcdCampaigns))
  const [selectedInterfaces, setSelectedInterfaces] = useState(mapToOptions(props.selectedInterfaces))
  const [selectedSuppliers, setSelectedSuppliers] = useState(mapToOptions(props.selectedSuppliers))
  const [negative, setNegative] = useState(props.negative)
  const [neutral, setNeutral] = useState(props.neutral)
  const [positive, setPositive] = useState(props.positive)
  const [unknown, setUnknown] = useState(props.unknown)
  const [booleans, setBooleans] = useState(props.booleans)
  const [active, setActive] = useState(props.active)
  const [previousActive, setPreviousActive] = useState(null)

  if (active !== previousActive) {
    setSelectedMediaReviewCodes(mapToOptions(props.selectedMediaReviewCodes))
    setSelectedMediaTypes(mapToOptions(props.selectedMediaTypes))
    setSelectedLanguages(mapToOptions(props.selectedLanguages))
    setSelectedCountries(mapToOptions(props.selectedCountries))
    setSelectedChannels(mapToOptions(props.selectedChannels))
    setSelectedOutlinkDomains(mapToOptions(props.selectedOutlinkDomains))
    setSelectedOutlinkUrls(mapToOptions(props.selectedOutlinkUrls))
    setSelectedFilterGroups(mapToOptions(props.selectedFilterGroups))
    setSelectedDistributionAreas(mapToOptions(props.selectedDistributionAreas))
    setSelectedCustomTags(mapToOptions(props.selectedCustomTags))
    setSelectedCustomTagGroups(mapToOptions(props.selectedCustomTagGroups))
    setSelectedNewsguardRanks(mapToOptions(props.selectedNewsguardRanks))
    setSelectedNewsguardOrientations(mapToOptions(props.selectedNewsguardOrientations))
    setSelectedIdentitySets(mapToOptions(props.selectedIdentitySets))
    setSelectedMediaReviewTypes(mapToOptions(props.selectedMediaReviewTypes))
    setSelectedCcdCampaigns(mapToOptions(props.selectedCcdCampaigns))
    setNegative(props.negative)
    setNeutral(props.neutral)
    setPositive(props.positive)
    setUnknown(props.unknown)
    setBooleans(props.booleans)
    setPreviousActive(active)
    setActive(props.active)
  }

  const handleBooleans = newBooleans => {
    const overrides = {}

    if (newBooleans.get('withOutlinksOnly') && !booleans.get('withOutlinksOnly')) {
      overrides.withoutOutlinksOnly = false
    }

    if (newBooleans.get('withoutOutlinksOnly') && !booleans.get('withoutOutlinksOnly')) {
      overrides.withOutlinksOnly = false
    }

    setBooleans(newBooleans.merge(overrides))
  }

  const handleApply = () => {
    const oDomains = selectedOutlinkDomains.map(o => ({
      id: o.value,
      name: o.value
    }))
    const oUrls = selectedOutlinkUrls.map(o => ({
      id: o.value,
      name: o.value
    }))

    setSelectedFilters({
      channels: mapToEntity(channels, selectedChannels),
      outlinkDomains: booleans.get('withOutlinksOnly') ? oDomains : [],
      outlinkUrls: booleans.get('withOutlinksOnly') ? oUrls : [],
      filterGroups: mapToEntity(filterGroups, selectedFilterGroups),
      distributionAreas: mapToEntity(distributionAreas, selectedDistributionAreas),
      customTags: mapToEntity(customTags, selectedCustomTags),
      customTagGroups: mapToEntity(customTagGroups, selectedCustomTagGroups),
      newsguardRanks: mapToEntity(newsguardRanks, selectedNewsguardRanks),
      newsguardOrientations: mapToEntity(newsguardOrientations, selectedNewsguardOrientations),
      identitySets: mapToEntity(identitySets, selectedIdentitySets),
      countries: mapToEntity(countries, selectedCountries),
      languages: mapToEntity(languages, selectedLanguages),
      mediaReviewCodes: mapToEntity(mediaReviewCodes, selectedMediaReviewCodes),
      mediaTypes: mapToEntity(mediaTypes, selectedMediaTypes),
      mediaReviewTypes: mapToEntity(mediaReviewTypes, selectedMediaReviewTypes),
      interfaces: mapToEntity(interfaces, selectedInterfaces),
      suppliers: mapToEntity(suppliers, selectedSuppliers),
      similarity: {
        ccdCampaigns: mapToEntity(ccdCampaigns, selectedCcdCampaigns)
      },
      tonalities: {
        negative,
        neutral,
        positive,
        unknown
      },
      booleans
    })

    uiApplyFilters()
  }

  const handleCreateBacklink = inputValue => {
    let domain

    try {
      const url = new URL(inputValue)

      domain = url.hostname.replace('www.', '')
    } catch (e) {
      domain = inputValue
    }

    setSelectedOutlinkDomains(selectedOutlinkDomains.concat({
      value: domain,
      label: domain
    }))
  }

  const renderFilter = (comp, ...names) => {
    if (allowedFilterFields && !names.some(name => allowedFilterFields.includes(name))) {
      return null
    }

    return comp
  }

  const renderAutocomplete = (
    labelKey,
    onChange,
    source,
    value,
    name,
    helperText,
    creatable,
    allowEmptySource,
    onCreateOption,
    disableOptionsSelect
  ) => {
    if (!source.size && !allowEmptySource) {
      return null
    }

    return renderFilter((
      <>
        <Autocomplete
          key={labelKey}
          variant="outlined"
          isMulti
          isClearable
          creatable={creatable}
          onChange={onChange}
          options={mapToOptions(source)}
          value={value}
          placeholder=""
          label={i18n.get(labelKey)}
          formatCreateLabel={inputValue => `${i18n.get('add')}: ${inputValue}`}
          onCreateOption={onCreateOption}
          disableOptionsSelect={disableOptionsSelect}
          textFieldProps={{
            helperText
          }}
        />
        <br />
      </>
    ), name)
  }

  const renderCol = (children, Comp = Col) => {
    if (!children) {
      return null
    }

    return <Comp>{children}</Comp>
  }

  const renderTonalitiesFilter = () => {
    if (!hasTonality) {
      return null
    }

    return renderFilter((
      <Col>
        <Typography variant="subtitle1">
          {i18n.get('tonality')}:
        </Typography>
        <FormGroup>
          <FormControlLabel
            label={i18n.get('negative')}
            control={(
              <Checkbox
                checked={negative}
                onChange={e => setNegative(e.target.checked)}
                classes={{ root: classes.tonality }}
              />
            )}
          />
          <FormControlLabel
            label={i18n.get('neutral')}
            control={(
              <Checkbox
                checked={neutral}
                onChange={e => setNeutral(e.target.checked)}
                classes={{ root: classes.tonality }}
              />
            )}
          />
          <FormControlLabel
            label={i18n.get('positive')}
            control={(
              <Checkbox
                checked={positive}
                onChange={e => setPositive(e.target.checked)}
                classes={{ root: classes.tonality }}
              />
            )}
          />
          <FormControlLabel
            label={i18n.get('unknown')}
            control={(
              <Checkbox
                checked={unknown}
                onChange={e => setUnknown(e.target.checked)}
                classes={{ root: classes.tonality }}
              />
            )}
          />
        </FormGroup>
      </Col>
    ), SearchFields.TONALITIES)
  }

  const renderArticleOptions = () => {
    const hasFacebook = channels.some(c => c.get('id') === 6)
    const hasTwitter = channels.some(c => c.get('id') === 7)

    return renderFilter((
      <Col>
        <Typography
          variant="subtitle1"
        >
          {i18n.get('article_options')}:
        </Typography>

        <FormGroup>
          {renderFilter((
            <FormControlLabel
              label={i18n.get('summarized_only')}
              control={(
                <Switch
                  checked={booleans.get('summarizedOnly')}
                  onChange={e => handleBooleans(booleans.set('summarizedOnly', e.target.checked))}
                />
              )}
            />
          ), SearchFields.SUMMARIZED_ONLY)}

          {renderFilter((
            <FormControlLabel
              label={i18n.get('hide_clusters')}
              control={(
                <Switch
                  checked={!booleans.get('globalClusters')}
                  onChange={e => handleBooleans(booleans.set('globalClusters', !e.target.checked))}
                />
              )}
            />
          ), SearchFields.GLOBAL_CLUSTERS)}

          {hasFacebook && renderFilter((
            <FormControlLabel
              label={i18n.get('exclude_visitor_posts')}
              control={(
                <Switch
                  checked={booleans.get('excludeVisitorPosts')}
                  onChange={e => handleBooleans(booleans.set('excludeVisitorPosts', e.target.checked))}
                />
              )}
            />
          ), SearchFields.EXCLUDE_VISITOR_POSTS)}

          {renderFilter((
            <FormControlLabel
              label={i18n.get('exclude_comments')}
              control={(
                <Switch
                  checked={booleans.get('excludeComments')}
                  onChange={e => handleBooleans(booleans.set('excludeComments', e.target.checked))}
                />
              )}
            />
          ), SearchFields.EXCLUDE_COMMENTS)}

          {hasTwitter && renderFilter((
            <FormControlLabel
              label={i18n.get('exclude_retweets')}
              control={(
                <Switch
                  checked={booleans.get('excludeRetweets')}
                  onChange={e => handleBooleans(booleans.set('excludeRetweets', e.target.checked))}
                />
              )}
            />
          ), SearchFields.EXCLUDE_RETWEETS)}
        </FormGroup>
      </Col>
    ), SearchFields.BOOLEANS,
    SearchFields.GLOBAL_CLUSTERS,
    SearchFields.EXCLUDE_VISITOR_POSTS,
    SearchFields.EXCLUDE_COMMENTS)
  }

  const channelsWithCountry = channels.filter(c => c.get('hasCountry')).map(c => c.get('name')).join(', ')

  return (
    <div className={classes.searchForm}>
      <div className={classes.header}>
        <Typography variant="h5">
          {i18n.get('advanced_search')}
        </Typography>
        <IconButton
          onClick={() => onClose()}
          title={i18n.get('close')}
          size="large"
        >
          {device.get('lt-lg') ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>
      <Divider classes={{ root: classes.divider }} />

      <Row>
        {renderCol(
          renderAutocomplete(
            'select_topics',
            value => setSelectedMediaReviewCodes(value),
            mediaReviewCodes,
            selectedMediaReviewCodes,
            SearchFields.MEDIA_REVIEW_CODES
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_custom_tags',
            value => setSelectedCustomTags(value),
            customTags,
            selectedCustomTags,
            SearchFields.CUSTOM_TAGS
          ),
          CustomTagsCol
        )}
        {renderCol(
          renderAutocomplete(
            'select_channels',
            value => setSelectedChannels(value),
            channels,
            selectedChannels,
            SearchFields.CHANNELS
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_custom_tag_groups',
            value => setSelectedCustomTagGroups(value),
            customTagGroups,
            selectedCustomTagGroups,
            SearchFields.CUSTOM_TAG_GROUPS
          ),
          CustomTagsCol
        )}
        {renderCol(
          renderAutocomplete(
            'select_languages',
            value => setSelectedLanguages(value),
            languages,
            selectedLanguages,
            SearchFields.LANGUAGES
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_countries',
            value => setSelectedCountries(value),
            countries,
            selectedCountries,
            SearchFields.COUNTRIES,
            (selectedCountries && selectedCountries.length && channelsWithCountry
              ? i18n.get('for_channels_only', { channels: channelsWithCountry }) : undefined
            )
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_media_types',
            value => setSelectedMediaTypes(value),
            mediaTypes,
            selectedMediaTypes,
            SearchFields.MEDIA_TYPES
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_filter_groups',
            value => setSelectedFilterGroups(value),
            filterGroups,
            selectedFilterGroups,
            SearchFields.FILTER_GROUPS
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_distribution_areas',
            value => setSelectedDistributionAreas(value),
            distributionAreas,
            selectedDistributionAreas,
            SearchFields.DISTRIBUTION_AREAS
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_identity_sets',
            value => setSelectedIdentitySets(value),
            identitySets,
            selectedIdentitySets,
            SearchFields.IDENTITY_SETS
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_newsguard_ranks',
            value => setSelectedNewsguardRanks(value),
            newsguardRanks,
            selectedNewsguardRanks,
            SearchFields.NEWSGUARD_RANKS
          ),
          NewsguardCol
        )}
        {renderCol(
          renderAutocomplete(
            'select_newsguard_orientations',
            value => setSelectedNewsguardOrientations(value),
            newsguardOrientations,
            selectedNewsguardOrientations,
            SearchFields.NEWSGUARD_ORIENTATIONS
          ),
          NewsguardCol
        )}
        {renderCol(
          renderAutocomplete(
            'select_media_review_types',
            value => setSelectedMediaReviewTypes(value),
            mediaReviewTypes,
            selectedMediaReviewTypes,
            SearchFields.MEDIA_REVIEW_TYPES
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_ccd_campaigns',
            value => setSelectedCcdCampaigns(value),
            ccdCampaigns,
            selectedCcdCampaigns,
            SearchFields.SIMILARITY_CCD_CAMPAIGNS
          ),
          CampaignsCol
        )}
        {renderCol(
          renderAutocomplete(
            'select_interfaces',
            value => setSelectedInterfaces(value),
            interfaces,
            selectedInterfaces,
            SearchFields.INTERFACES
          )
        )}
        {renderCol(
          renderAutocomplete(
            'select_suppliers',
            value => setSelectedSuppliers(value),
            suppliers,
            selectedSuppliers,
            SearchFields.SUPPLIERS
          )
        )}
      </Row>

      <Row>
        {renderTonalitiesFilter()}
        {renderArticleOptions()}
      </Row>

      <>
        <Typography variant="subtitle1">
          {i18n.get('outlinks')}
        </Typography>

        <Divider classes={{ root: classes.outlinksTitle }} />

        <Row>
          {renderCol(
            <>
              {renderFilter((
                <FormControlLabel
                  label={i18n.get('with_outlinks_only')}
                  control={(
                    <Switch
                      checked={booleans.get('withOutlinksOnly')}
                      onChange={e => handleBooleans(booleans.set('withOutlinksOnly', e.target.checked))}
                    />
                  )}
                />
              ), SearchFields.WITH_OUTLINKS_ONLY)}

              {renderFilter((
                <FormControlLabel
                  label={i18n.get('without_outlinks_only')}
                  control={(
                    <Switch
                      checked={booleans.get('withoutOutlinksOnly')}
                      onChange={e => handleBooleans(booleans.set('withoutOutlinksOnly', e.target.checked))}
                    />
                  )}
                />
              ), SearchFields.WITHOUT_OUTLINKS_ONLY)}
            </>
          )}

          {booleans.get('withOutlinksOnly') && renderCol(
            <>
              {renderAutocomplete(
                'select_outlink_domains',
                value => setSelectedOutlinkDomains(value || []),
                fromJS([]),
                selectedOutlinkDomains,
                SearchFields.OUTLINK_DOMAINS,
                i18n.get('domains_tooltip'),
                true,
                true,
                handleCreateBacklink,
                true
              )}
              {renderAutocomplete(
                'select_outlinks',
                value => setSelectedOutlinkUrls(value || []),
                fromJS([]),
                selectedOutlinkUrls,
                SearchFields.OUTLINK_URLS,
                i18n.get('outlinks_tooltip'),
                true,
                true,
                undefined,
                true
              )}
            </>
          )}
        </Row>
      </>

      {device.get('lt-lg') && (
        <>
          <br />
          <Divider />
          <br />
        </>
      )}

      <ActionBar className={classes.buttonContainer}>
        {device.get('lt-sm') && (
          <IconButton
            onClick={() => toggleHelpDialog()}
            size="large"
          >
            <HelpIcon />
          </IconButton>
        )}

        {device.get('gt-xs') && (
          <Button
            onClick={() => toggleHelpDialog()}
            startIcon={<HelpIcon />}
          >
            {i18n.get('help')}
          </Button>
        )}

        <div className={classes.spacer} />

        <div>
          {device.get('lt-sm') && (
            <IconButton
              onClick={() => onClose()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          )}

          {device.get('gt-xs') && (
            <Button onClick={() => onClose()}>
              {i18n.get('close')}
            </Button>
          )}

          {device.get('lt-sm') && (
            <IconButton
              color="secondary"
              onClick={() => uiResetFilters()}
              size="large"
            >
              <SettingsBackupRestoreIcon />
            </IconButton>
          )}

          {device.get('gt-xs') && (
            <Button
              color="secondary"
              onClick={() => uiResetFilters()}
            >
              {i18n.get('reset_filters')}
            </Button>
          )}

          <RaisedPrimaryButton onClick={handleApply}>
            {i18n.get('apply_filters')}
          </RaisedPrimaryButton>
        </div>
      </ActionBar>
    </div>
  )
}

export default React.memo(SearchForm, (prevProps, nextProps) => (nextProps.active))

SearchForm.propTypes = {
  active: PropTypes.bool.isRequired,

  mediaReviewCodes: PropTypes.instanceOf(List).isRequired,
  selectedMediaReviewCodes: PropTypes.instanceOf(List).isRequired,

  countries: PropTypes.instanceOf(List).isRequired,
  selectedCountries: PropTypes.instanceOf(List).isRequired,

  languages: PropTypes.instanceOf(List).isRequired,
  selectedLanguages: PropTypes.instanceOf(List).isRequired,

  mediaTypes: PropTypes.instanceOf(List).isRequired,
  selectedMediaTypes: PropTypes.instanceOf(List).isRequired,

  channels: PropTypes.instanceOf(List).isRequired,
  selectedChannels: PropTypes.instanceOf(List).isRequired,

  filterGroups: PropTypes.instanceOf(List).isRequired,
  selectedFilterGroups: PropTypes.instanceOf(List).isRequired,

  distributionAreas: PropTypes.instanceOf(List).isRequired,
  selectedDistributionAreas: PropTypes.instanceOf(List).isRequired,

  customTags: PropTypes.instanceOf(List).isRequired,
  selectedCustomTags: PropTypes.instanceOf(List).isRequired,

  customTagGroups: PropTypes.instanceOf(List).isRequired,
  selectedCustomTagGroups: PropTypes.instanceOf(List).isRequired,

  identitySets: PropTypes.instanceOf(List).isRequired,
  selectedIdentitySets: PropTypes.instanceOf(List).isRequired,

  selectedOutlinkDomains: PropTypes.instanceOf(List).isRequired,
  selectedOutlinkUrls: PropTypes.instanceOf(List).isRequired,

  mediaReviewTypes: PropTypes.instanceOf(List).isRequired,
  selectedMediaReviewTypes: PropTypes.instanceOf(List).isRequired,

  ccdCampaigns: PropTypes.instanceOf(List).isRequired,
  selectedCcdCampaigns: PropTypes.instanceOf(List).isRequired,

  interfaces: PropTypes.instanceOf(List).isRequired,
  selectedInterfaces: PropTypes.instanceOf(List).isRequired,

  suppliers: PropTypes.instanceOf(List).isRequired,
  selectedSuppliers: PropTypes.instanceOf(List).isRequired,

  newsguardRanks: PropTypes.instanceOf(List).isRequired,
  selectedNewsguardRanks: PropTypes.instanceOf(List).isRequired,
  newsguardOrientations: PropTypes.instanceOf(List).isRequired,
  selectedNewsguardOrientations: PropTypes.instanceOf(List).isRequired,

  hasTonality: PropTypes.bool.isRequired,

  booleans: PropTypes.instanceOf(Map).isRequired,

  negative: PropTypes.bool.isRequired,
  neutral: PropTypes.bool.isRequired,
  positive: PropTypes.bool.isRequired,
  unknown: PropTypes.bool.isRequired,

  allowedFilterFields: PropTypes.instanceOf(List),

  i18n: PropTypes.object.isRequired,
  device: PropTypes.instanceOf(Map).isRequired,

  toggleHelpDialog: PropTypes.func.isRequired,
  uiApplyFilters: PropTypes.func.isRequired,
  uiResetFilters: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
