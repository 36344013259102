import { createImmutableSelector } from 'utils/reselect'
import { getStaticAnalysisCodesMap } from '../statics'

export const getNewsAnalysis = state => state.news.get('analysis')
export const getNewsAnalysisData = state => getNewsAnalysis(state).get('data')

const mapToCode = codes => code => (
  code
    .merge(codes.get(code.get('id').toString()))
    .update('subcodes', subcodes => subcodes.map(mapToCode(codes)))
)

const groupedAnalysisCodesSelector = state => getNewsAnalysisData(state).get('groupedAnalysisCodes')
const groupedMediaReviewCodesSelector = state => getNewsAnalysisData(state).get('groupedMediaReviewCodes')

export const getGroupedAnalysisCodes = createImmutableSelector(
  state => state.news.getIn(['filter', 'analysis']),
  getStaticAnalysisCodesMap,
  groupedAnalysisCodesSelector,
  groupedMediaReviewCodesSelector,
  (analysis, codes, analysisGroups, mediaReviewGroups) => {
    if (analysis.get('useMediaReviewCodes')) {
      return mediaReviewGroups.map(group => group.update('nodes', nodes => nodes.map(mapToCode(codes))))
    }

    return analysisGroups.map(group => group.update('nodes', nodes => nodes.map(mapToCode(codes))))
  }
)

export const getNewsAnalysisUi = state => getNewsAnalysis(state).get('ui')
export const getNewsAnalysisExpandedNodes = state => getNewsAnalysisUi(state).get('expandedNodes')
export const getNewsAnalysisExpandedNodesByType = (state, type) => getNewsAnalysisExpandedNodes(state).get(type)
export const getNewsAnalysisExpandedPanels = state => getNewsAnalysisUi(state).get('expandedPanels')
export const getNewsAnalysisExpandedPanelsByType = (state, type) => getNewsAnalysisExpandedPanels(state).get(type)
export const getNewsAnalysisShowPushChartToDashboardDialog = state => getNewsAnalysisUi(state).get('showPushChartToDashboardDialog')
export const getNewsAnalysisChartPushRunning = state => getNewsAnalysisUi(state).get('chartPushRunning')

const chartSelector = state => getNewsAnalysis(state).get('chart')

export const getNewsAnalysisChart = createImmutableSelector(
  chartSelector,
  state => state.news.getIn(['filter', 'analysis']),
  (chart, analysis) => {
    const codeIds = analysis.get('groupedCodes').map(c => c.get('id')).toSet()

    if (chart.get('firstLevel') === 'analysisCodes' && chart.get('secondLevel') === 'analysisCodes' && codeIds.isEmpty()) {
      return chart.delete('secondLevel')
    }

    return chart
  }
)

export const getNewsAnalysisMetrics = state => getNewsAnalysis(state).get('metrics')
