import { connect } from 'react-redux'
import { fromJS } from 'immutable'

import DistributionListsFilter
  from 'components/contact_management/distribution_lists/distribution_lists_item/distribution_lists_filter/DistributionListsFilter'

import { addDistributionListContactFilter, removeDistributionListContactFilter } from 'actions/contact_management'

import { getCmDistributionListContactsSearchQueries } from 'selectors'

const mapStateToProps = (state, { distObject, isDistListsLocked }) => ({
  distObject,
  isDistListsLocked,
  isActiveFilter: (getCmDistributionListContactsSearchQueries(state).first() || fromJS({})).get('value') === distObject.get('id')
})

export default connect(mapStateToProps, {
  onClick: addDistributionListContactFilter,
  onRemove: removeDistributionListContactFilter
})(DistributionListsFilter)
