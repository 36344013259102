import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { red } from '@mui/material/colors'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Grid,
  FormControlLabel,
  LinearProgress,
  Box,
  Tooltip,
  InputAdornment,
  Autocomplete,
  TextField
} from '@mui/material'

import SaveIcon from '@mui/icons-material/Save'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import RefreshIcon from '@mui/icons-material/Refresh'
import InfoIcon from '@mui/icons-material/Info'

import ContentContainer from 'components/content_container'

import AppBar from 'containers/AppBar'
import AllowedDomains from 'containers/administration/content_desk_settings/AllowedDomains'
import { Button, Input, IconButton } from 'containers/themed'

import { Capabilities, UploadedMediaFilesSizeLimit } from 'static/constants'
import useI18n from 'hooks/useI18n'
import { validEmail } from 'utils/regex'
import { formatBytes } from 'utils/number'

const useStyles = makeStyles()(theme => ({
  content: {
    paddingTop: 10
  },
  panelDetails: {
    flexDirection: 'column'
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '85%',
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '65%'
    }
  }
}))

const mapDomainsToOptions = domains => domains.map(d => {
  const senderDomains = d.get('senderDomains')

  return senderDomains.map(sd => ({
    value: { name: sd, domain: d.get('name'), region: d.get('region') },
    label: '@'.concat(sd).concat(sd !== d.get('name') ? ` (${d.get('name')})` : '')
  }))
}).flatten().toJS()

export default function ContentDeskSettings({
  capabilities,
  config,
  requestRunning,
  uploadedMediaFilesTotalSize,
  onChange,
  onUpdate,
  onConfigRefresh
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const senderEmail = config.get('senderEmail') || ''
  const senderEmailLocal = senderEmail.split('@')[0]
  const senderEmailDomain = senderEmail.split('@')[1]
  const senderDomain = config.get('domain') || ''
  const senderName = config.get('senderName') || ''
  const domainRegion = config.get('domainRegion') || ''
  const allowedDomains = config.get('ccdAllowedDomains') || List()

  const domainsOptions = mapDomainsToOptions(allowedDomains)

  const emailIsValid = validEmail(senderEmail)

  const loaderPercentage = (usage, limit) => {
    const percentage = Math.round((100 * usage) / limit)

    return { percentage: percentage > 100 ? 100 : percentage, isMax: percentage >= 100 }
  }

  const ccdPlanName = config.getIn(['ccdPlan', 'name'])
  const emailsSendLimit = config.getIn(['ccdPlan', 'emailsSendLimit'], 0)
  const emailsSendUsage = config.getIn(['ccdPlanUsage', 'emailsSendUsage'], 0)
  const distributionListsLimit = config.getIn(['ccdPlan', 'distributionListsLimit'], 0)
  const distributionListsUsage = config.getIn(['ccdPlanUsage', 'distributionListsUsage'], 0)
  const contactsPerDistListLimit = config.getIn(['ccdPlan', 'contactsPerDistListLimit'], 0)
  const journalistsImportLimit = config.getIn(['ccdPlan', 'journalistsImportLimit'], 0)
  const journalistsImportUsage = config.getIn(['ccdPlanUsage', 'journalistsImportUsage'], 0)
  const journalistsViewLimit = config.getIn(['ccdPlan', 'journalistsViewLimit'], 0)
  const journalistsViewUsage = config.getIn(['ccdPlanUsage', 'journalistsViewUsage'], 0)

  const senderEmailDomainValue = domainsOptions.find(option => {
    const { name, domain } = option.value

    return name === senderEmailDomain && domain === senderDomain
  }) || null

  const accordionDetails = [
    {
      title: 'uploads_storage_space',
      usage: uploadedMediaFilesTotalSize,
      limit: UploadedMediaFilesSizeLimit
    },
    {
      title: 'sent_emails',
      usage: emailsSendUsage,
      limit: emailsSendLimit
    },
    {
      title: 'distribution_lists',
      usage: distributionListsUsage,
      limit: distributionListsLimit
    },
    {
      title: 'imported_journalists',
      usage: journalistsImportUsage,
      limit: journalistsImportLimit
    },
    {
      title: 'viewed_journalists',
      usage: journalistsViewUsage,
      limit: journalistsViewLimit
    },
    {
      title: 'contacts_per_dist_list',
      limit: contactsPerDistListLimit
    }
  ]

  const handleMailChange = value => {
    onChange({ senderEmail: value.concat('@').concat(senderEmailDomain) })
  }

  const handleSenderEmailDomainChange = value => {
    const { name, domain, region } = value
    const domainId = value.id

    onChange({
      ccdAllowedDomainId: domainId,
      senderEmail: senderEmailLocal.concat('@').concat(name),
      domain,
      domainRegion: region
    })
    onUpdate()
  }

  const handleNameChange = value => onChange({ senderName: value })

  const allValid = (emailIsValid && domainRegion !== '')
  const saveDisabled = requestRunning || !allValid

  const handleCDConfigRefresh = () => onConfigRefresh()

  const renderUpdateButton = () => (
    <Button
      startIcon={<SaveIcon />}
      variant="contained"
      color="primary"
      loading={requestRunning}
      disabled={saveDisabled}
      onClick={() => onUpdate()}
    >
      {i18n.get('update')}
    </Button>
  )

  const renderSenderActions = () => (
    <AccordionActions>
      {renderUpdateButton()}
    </AccordionActions>
  )

  const renderSenderMailGridItem = () => (
    <Grid
      item
      xs={12}
      md={12}
    >
      <Input
        fullWidth
        type="text"
        value={senderEmailLocal}
        error={!emailIsValid && senderEmailLocal !== ''}
        label={i18n.get('sender_email')}
        onChange={event => handleMailChange(event.target.value)}
        disabled={requestRunning}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{ width: '100%' }}
            >
              <Autocomplete
                sx={{ width: '100%', '& legend': { width: '0px' } }}
                disablePortal
                disableClearable
                size="small"
                options={domainsOptions}
                value={senderEmailDomainValue}
                onChange={(_event, option) => handleSenderEmailDomainChange(option.value)}
                disabled={requestRunning}
                renderInput={params => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: false }}
                    label={senderEmailDomainValue ? '' : i18n.get('domain')}
                  />
                )}
              />
              <Tooltip
                title={(
                  <Box dangerouslySetInnerHTML={{
                    __html: i18n.get('ccd_config_sender_email_tooltip', { email: 'customerservice@pressrelations.de' })
                  }}
                  />
                )}
                placement="top-end"
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [-20, 10]
                      }
                    }
                  ]
                }}
              >
                <InfoIcon sx={{ marginLeft: '10px' }} />
              </Tooltip>
            </InputAdornment>
          )
        }}
      />
    </Grid>
  )
  const renderSenderNameGridItem = () => (
    <Grid
      item
      xs={12}
      md={12}
    >
      <Input
        fullWidth
        type="text"
        value={senderName}
        label={i18n.get('sender_name')}
        onChange={event => handleNameChange(event.target.value)}
        disabled={requestRunning}
      />
    </Grid>
  )

  const renderDetail = (detail, loader, index) => {
    let usageLimitDisplay

    if (detail.usage !== undefined) {
      if (detail.title === 'uploads_storage_space') {
        usageLimitDisplay = `${formatBytes(detail.usage)} / ${formatBytes(detail.limit)}`
      } else {
        usageLimitDisplay = `${detail.usage} / ${detail.limit}`
      }
    } else {
      usageLimitDisplay = detail.limit
    }

    return (
      <Grid
        key={index}
        item
        xs={12}
        md={6}
      >
        <Typography>{i18n.get(detail.title)}</Typography>
        <Typography>{usageLimitDisplay}</Typography>
        {loader && (
          <LinearProgress
            variant="determinate"
            value={loader.percentage}
            sx={{
              '& .MuiLinearProgress-bar': {
                backgroundColor: loader.isMax && red[500]
              }
            }}
          />
        )}
      </Grid>
    )
  }

  return (
    <>
      <AppBar title={i18n.get('content_desk_settings')} />

      <ContentContainer className={classes.content}>
        <div className={classes.container}>
          <Accordion
            defaultExpanded
            disabled={!ccdPlanName}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                '& .MuiAccordionSummary-content': {
                  display: 'flex',
                  alignItems: 'center'
                }
              }}
            >
              <Typography sx={{ flexGrow: 1 }}>{`${i18n.get('usage_and_limits')} / ${i18n.get('current_plan')} - ${ccdPlanName || 'none'}`}
              </Typography>
              <Box onClick={e => e.stopPropagation()}>
                <FormControlLabel
                  control={
                    (
                      <IconButton
                        color="primary"
                        onClick={handleCDConfigRefresh}
                        title={i18n.get('refresh')}
                        disabled={requestRunning}
                      >
                        <RefreshIcon />
                      </IconButton>
                    )
                  }
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <Grid
                container
                spacing={2}
              >
                {ccdPlanName && (
                  accordionDetails.map((detail, index) => {
                    let loader

                    if (detail.usage !== undefined && detail.usage !== null && detail.limit) {
                      loader = loaderPercentage(detail.usage, detail.limit)
                    }

                    return renderDetail(detail, loader, index)
                  })
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('general')}</Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <Grid
                container
                spacing={2}
              >
                {renderSenderNameGridItem()}
                {renderSenderMailGridItem()}
              </Grid>
            </AccordionDetails>
            {renderSenderActions()}
          </Accordion>
          {capabilities.get(Capabilities.HAS_CONTENT_DESK_ALLOWED_DOMAINS_SETTINGS) && (
            <AllowedDomains />
          )}
        </div>
      </ContentContainer>
    </>
  )
}

ContentDeskSettings.propTypes = {
  capabilities: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.object.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number,

  onUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfigRefresh: PropTypes.func.isRequired
}
