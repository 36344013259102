import { connect } from 'react-redux'

import EditDialog from 'components/administration/mailing_lists/edit_dialog'
import { getI18n, getShowMailingListDialog, getSelectedMailingList, getSaveMailingListRunning } from 'selectors'
import { hideMailingListDialog, saveMailingListStart, updateSelectedMailingList } from 'actions/mailing_lists'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  active: getShowMailingListDialog(state),
  mailingList: getSelectedMailingList(state),
  running: getSaveMailingListRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onHide: hideMailingListDialog,
    onSubmit: saveMailingListStart,
    onChange: updateSelectedMailingList
  }
)(EditDialog)
