import { useEffect } from 'react'

import {
  $createTextNode, $getRoot
} from 'lexical'

import { mergeRegister } from '@lexical/utils'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ExtendedLinkNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedLinkNode'

const EmptyLinkPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => mergeRegister(
    editor.registerNodeTransform(ExtendedLinkNode, linkNode => {
      if (linkNode) {
        const text = linkNode.getTextContent()

        if (text && (/^\s*$/g.test(text))) {
          const textNode = $createTextNode(text)
          linkNode.replace(textNode)
          $getRoot().selectStart()
        }
      }
    })
  ), [editor])

  return null
}

export default EmptyLinkPlugin
