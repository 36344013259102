/* istanbul ignore file */
import { put, takeEvery, all, call, select, delay } from 'redux-saga/effects'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'
import { DarknetCharts, addTypeToDomains } from 'utils/darknet'
import { Networks } from 'static/constants'
import moment from 'moment-timezone'

export function* scoresRequestStart({ payload: demoRequest }) {
  const ids = yield select(Selectors.getDarkowlScoreQueriesById)
  const dateFrom = yield select(Selectors.getSelectedDateFrom)
  const dateTo = yield select(Selectors.getSelectedDateTo)

  const formattedDateFrom = moment(dateFrom).format('x')
  const formattedDateTo = moment(dateTo).format('x')

  try {
    let scores

    if (demoRequest) {
      scores = yield call(Api.getDarknetDemoScoreResults)
    } else {
      scores = yield call(Api.getDarknetScoreResults, ids.toJS(), formattedDateFrom, formattedDateTo)
    }

    yield put(Actions.scoresRequestSuccess({ scores, demoRequest }))
  } catch (err) {
    yield put(Actions.scoresRequestError(err))
  }
}

export function* chartDrilldown({ payload: { data, chartId } }) {
  const i18n = yield select(Selectors.getI18n)
  const name = i18n.get(data.name)
  const value = data.keys || data.id

  if ([DarknetCharts.HACKISHNESS_NETWORKS, DarknetCharts.NETWORKS].indexOf(chartId) > -1) {
    yield put(Actions.addSelectedFilter({
      filterType: 'networks',
      value,
      name
    }))

    yield put(Actions.searchRequestStart())
  }

  if ([
    DarknetCharts.MENTIONS_TIMELINE,
    DarknetCharts.HACKISHNESS_TIMELINE,
    DarknetCharts.NETWORKS_TIMELINE
  ].indexOf(chartId) > -1) {
    const { from, to } = data.filterData.range
    const mfrom = moment(from)
    const mto = moment(to)
    const fromIsOlder = (mfrom.diff(mto) < 0)
    yield put(Actions.uiApplyDateFilter({
      dateFrom: fromIsOlder ? mfrom : mto.subtract(1, 'days'),
      dateTo: fromIsOlder ? mto : mfrom.add(1, 'days'),
      dateRange: ''
    }))
  }

  if ([DarknetCharts.LANGUAGES].indexOf(chartId) > -1) {
    if (data.pairs) {
      yield all(data.pairs.map(v => (
        put(Actions.addSelectedFilter({ filterType: 'languages', value: v.key, name: v.name }))
      )))
    } else {
      yield put(Actions.addSelectedFilter({ filterType: 'languages', value, name }))
    }

    yield put(Actions.searchRequestStart())
  }
}

export function* searchResultDrilldown({ payload }) {
  yield put(Actions.addSelectedFilter(payload))

  yield put(Actions.searchRequestStart())
}

export function* saveSearchQueryRequestStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getDarknetSearchQueryFormData)
    const networks = formData.get('networks')

    const requestBody = {
      name: formData.get('name'),
      query: formData.get('searchQuery'),
      emails: formData.get('emails'),
      emailDomains: formData.get('emailDomains'),
      networks: Object.keys(networks).filter(key => networks[key] === true),
      hackMin: formData.get('minHackishness') / 100
    }

    let result

    if (formData.get('id')) {
      result = yield call(Api.updateDarknetSearchQuery, formData.get('id'), requestBody)
    } else {
      result = yield call(Api.createDarknetSearchQuery, requestBody)
    }

    yield put(Actions.fetchAllQueriesStart())

    yield put(Actions.saveSearchQueryRequestSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get(result.message),
      success: true
    }))
  } catch (error) {
    yield put(Actions.saveSearchQueryRequestError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* openSearchQueryDialog({ payload }) {
  yield put(Actions.resetSearchQueryFormData())

  if (payload) {
    const emails = payload.get('filters').filter(e => e.get('type') === 'emails').map(e => e.get('value'))
    const emailDomains = payload.get('filters').filter(e => e.get('type') === 'email_domains').map(e => e.get('value'))
    const networks = Object.assign(
      {},
      ...Networks.map(el => ({
        [el.id]: payload
          .get('filters')
          .filter(e => e.get('type') === 'networks')
          .map(e => e.get('value'))
          .includes(el.id)
      }))
    )

    const data = {
      id: payload.get('id'),
      name: payload.get('name'),
      emails,
      emailDomains,
      networks,
      minHackishness: parseFloat(payload.get('hackMin')) * 100,
      searchQuery: payload.get('query')
    }

    yield put(Actions.updateSearchQueryFormData(data))
  }
}

export function* saveScoreQueryRequestStart() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getDarknetScoreQueryFormData)
    const requestBody = {
      name: formData.get('name'),
      domains: addTypeToDomains(formData.get('webDomains'), 'web').concat(addTypeToDomains(formData.get('emailDomains'), 'email'))
    }

    let result

    if (formData.get('id')) {
      result = yield call(Api.updateDarknetScoreQuery, formData.get('id'), requestBody)
    } else {
      result = yield call(Api.createDarknetScoreQuery, requestBody)
    }

    yield put(Actions.fetchAllQueriesStart())

    yield put(Actions.saveScoreQueryRequestSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get(result.message),
      success: true
    }))
  } catch (error) {
    yield put(Actions.saveScoreQueryRequestError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* openScoreQueryDialog({ payload }) {
  yield put(Actions.resetScoreQueryFormData())

  if (payload) {
    const webDomains = payload.get('domains').filter(domain => domain.get('type') === 'web').map(domain => domain.get('domain'))
    const emailDomains = payload.get('domains').filter(domain => domain.get('type') === 'email').map(domain => domain.get('domain'))
    const data = {
      id: payload.get('id'),
      name: payload.get('name'),
      webDomains,
      emailDomains
    }

    try {
      yield put(Actions.updateScoreQueryFormData(data))
    } catch (error) {
      yield put(Actions.saveScoreQueryRequestError(error))
    }
  }
}

export function* fetchAllQueriesStart() {
  try {
    const result = yield call(Api.fetchAllDarknetQueries)
    yield put(Actions.fetchAllQueriesSuccess(result))
  } catch (error) {
    yield put(Actions.fetchAllQueriesError(error))
  }
}

export function* deleteQueryRequestStart({ payload: { type, id } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    let result

    if (type === 'search') {
      result = yield call(Api.deleteDarknetSearchQuery, id)
    } else if (type === 'score') {
      result = yield call(Api.deleteDarknetScoreQuery, id)
    }

    yield put(Actions.fetchAllQueriesStart())
    yield put(Actions.deleteQueryRequestSuccess())
    yield put(AppActions.showAppMessage({
      text: i18n.get(result.message),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deleteQueryRequestError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('delete_query_error'),
      success: false
    }))
  }
}

export function* deactivateQueryRequestStart({ payload: { type, id } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.deactivateDarknetQuery, id, { type })

    yield put(Actions.fetchAllQueriesStart())
    yield put(Actions.deactivateQueryRequestSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get(result.message),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deactivateQueryRequestError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('deactivate_query_error'),
      success: false
    }))
  }
}

export function* activateQueryRequestStart({ payload: { type, id } }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const result = yield call(Api.activateDarknetQuery, id, { type })

    yield put(Actions.fetchAllQueriesStart())
    yield put(Actions.activateQueryRequestSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get(result.message),
      success: true
    }))
  } catch (error) {
    yield put(Actions.activateQueryRequestError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('activate_query_error'),
      success: false
    }))
  }
}

export function* testSearchQueryStart() {
  const i18n = yield select(Selectors.getI18n)
  try {
    const formData = yield select(Selectors.getDarknetSearchQueryFormData)
    const networks = formData.get('networks')

    const requestBody = {
      query: formData.get('searchQuery'),
      emails: formData.get('emails'),
      emailDomains: formData.get('emailDomains'),
      networks: Object.keys(networks).filter(key => networks[key] === true),
      hackMin: formData.get('minHackishness') / 100
    }

    const result = yield call(Api.testSearchQuery, requestBody)
    yield put(Actions.setSearchQueryFormTestResult({
      success: result.success,
      message: `${i18n.get('darknet_search_query_test_message')}: ${result.averageDailyHits.lastSevenDays}`
    }))
    yield delay(7000)
    yield put(Actions.testSearchQuerySuccess(result))
  } catch (error) {
    yield put(Actions.testSearchQueryError(error))
    yield put(AppActions.showAppMessage({
      text: i18n.get('test_search_query_error'),
      success: false
    }))
  }
}

export function* loadMoreDarknetEntriesStart() {
  const body = yield select(Selectors.getDarknetSearchBody)
  const searchResults = yield (select(Selectors.getDarknetResults))
  body.from = searchResults.size

  try {
    const result = yield call(Api.searchDarknetEntries, body)
    yield put(Actions.loadMoreDarknetEntriesSuccess(result))
  } catch (err) {
    yield put(Actions.loadMoreDarknetEntriesError(err))
  }
}

export function* watchScoresRequestStart() {
  yield takeEvery(Actions.scoresRequestStart, scoresRequestStart)
}

export function* watchChartDrilldown() {
  yield takeEvery(Actions.chartDrilldown, chartDrilldown)
}

export function* watchSearchResultDrilldown() {
  yield takeEvery(Actions.searchResultDrilldown, searchResultDrilldown)
}

export function* watchSaveSearchQueryRequestStart() {
  yield takeEvery(Actions.saveSearchQueryRequestStart, saveSearchQueryRequestStart)
}

export function* watchOpenSearchQueryDialog() {
  yield takeEvery(Actions.openSearchQueryDialog, openSearchQueryDialog)
}

export function* watchOpenScoreQueryDialog() {
  yield takeEvery(Actions.openScoreQueryDialog, openScoreQueryDialog)
}

export function* watchSaveScoreQueryRequestStart() {
  yield takeEvery(Actions.saveScoreQueryRequestStart, saveScoreQueryRequestStart)
}

export function* watchFetchAllQueriesStart() {
  yield takeEvery(Actions.fetchAllQueriesStart, fetchAllQueriesStart)
}

export function* watchDeleteQuery() {
  yield takeEvery(Actions.deleteQueryRequestStart, deleteQueryRequestStart)
}

export function* watchDeactivateQueryRequestStart() {
  yield takeEvery(Actions.deactivateQueryRequestStart, deactivateQueryRequestStart)
}

export function* watchActivateQueryRequestStart() {
  yield takeEvery(Actions.activateQueryRequestStart, activateQueryRequestStart)
}

export function* watchTestSearchQueryStart() {
  yield takeEvery(Actions.testSearchQueryStart, testSearchQueryStart)
}

export function* watchLoadMoreDarknetEntriesStart() {
  yield takeEvery(Actions.loadMoreDarknetEntriesStart, loadMoreDarknetEntriesStart)
}

export default function* mainSaga() {
  yield all([
    watchScoresRequestStart(),
    watchChartDrilldown(),
    watchSearchResultDrilldown(),
    watchSaveSearchQueryRequestStart(),
    watchSaveScoreQueryRequestStart(),
    watchFetchAllQueriesStart(),
    watchDeleteQuery(),
    watchDeactivateQueryRequestStart(),
    watchActivateQueryRequestStart(),
    watchOpenScoreQueryDialog(),
    watchOpenSearchQueryDialog(),
    watchTestSearchQueryStart(),
    watchLoadMoreDarknetEntriesStart()
  ])
}
