import React from 'react'
// import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import {
  Popover,
  Button
} from '@mui/material'

import Facets from 'containers/anewstip/results/Facets'
import FilterListIcon from '@mui/icons-material/FilterList'

const useStyles = makeStyles()(({
  filterButton: {
    marginRight: '15px'
  },
  filterMenu: {
    padding: 20,
    minWidth: 340
  }
}))

const FilterButton = () => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const filterMenuOpen = Boolean(anchorEl)

  const handleFilterClose = () => {
    setAnchorEl(null)
  }

  const renderFilterMenu = () => (
    <Popover
      open={filterMenuOpen}
      anchorEl={anchorEl}
      onClose={handleFilterClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <div className={classes.filterMenu}>
        <Facets />
      </div>
    </Popover>
  )

  const handleFiltersClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Button
        onClick={handleFiltersClick}
        className={classes.filterButton}
      >
        <FilterListIcon />
        <div className={classes.buttonIcon}>
          {i18n.get('filters')}
        </div>
      </Button>
      {renderFilterMenu()}
    </>
  )
}

export default FilterButton
