import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/identity_sets'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  showAddIdentityToIdentitySetDialog: false,
  showIdentitySetDialog: false,
  showDeleteIdentitySetDialog: false,
  loadingIdentitySetIds: Set([]),
  uploadedPhoto: null,
  savingIdentitySet: false
})

export default handleActions({
  [Actions.showIdentitySetDialog]: state => state.set('showIdentitySetDialog', true),
  [Actions.hideIdentitySetDialog]: state => state.set('showIdentitySetDialog', false),

  [Actions.showAddIdentityToIdentitySetDialog]: state => state.set('showAddIdentityToIdentitySetDialog', true),
  [Actions.hideAddIdentityToIdentitySetDialog]: state => state.set('showAddIdentityToIdentitySetDialog', false),

  [Actions.addIdentityToIdentitySetStart]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.add(identitySet.get('id')))
  ),
  [Actions.addIdentityToIdentitySetError]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.remove(identitySet.get('id')))
  ),
  [Actions.addIdentityToIdentitySetSuccess]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.remove(identitySet.get('id')))
  ),

  [Actions.removeIdentityFromIdentitySetStart]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.add(identitySet.get('id')))
  ),
  [Actions.removeIdentityFromIdentitySetError]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.remove(identitySet.get('id')))
  ),
  [Actions.removeIdentityFromIdentitySetSuccess]: (state, { payload: { identitySet } }) => (
    state.update('loadingIdentitySetIds', l => l.remove(identitySet.get('id')))
  ),

  [Actions.saveIdentitySetStart]: state => state.set('savingIdentitySet', true),
  [Actions.saveIdentitySetError]: state => state.set('savingIdentitySet', false),
  [Actions.saveIdentitySetSuccess]: state => state.set('savingIdentitySet', false),

  [Actions.setUploadedPhoto]: (state, { payload: data }) => state.set('uploadedPhoto', data),

  [Actions.showDeleteIdentitySetDialog]: state => state.set('showDeleteIdentitySetDialog', true),
  [Actions.hideDeleteIdentitySetDialog]: state => state.set('showDeleteIdentitySetDialog', false),

  [AppActions.resetState]: () => initialState
}, initialState)
