import React from 'react'
import PropTypes from 'prop-types'
import RefreshIcon from '@mui/icons-material/Refresh'

import { Button } from 'containers/themed'
import AppBarComp from 'containers/AppBar'

import useI18n from 'hooks/useI18n'

export default function AppBar({ onClick, loading }) {
  const i18n = useI18n()

  return (
    <AppBarComp
      title={i18n.get('dispatch_log')}
      actions={(
        <Button
          disabled={loading}
          startIcon={<RefreshIcon />}
          onClick={onClick}
          color="inherit"
        >
          {i18n.get('refresh')}
        </Button>
      )}
    />
  )
}

AppBar.propTypes = {
  loading: PropTypes.bool.isRequired,

  onClick: PropTypes.func.isRequired
}
