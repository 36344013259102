import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'
import {
  Typography
} from '@mui/material'

import LockedContentTooltip from 'components/themax/hidden_content/LockedContentTooltip'

const useStyles = makeStyles()({
  blurredSection: {
    display: 'flex',
    alignItems: 'center'
  },
  blurredContent: {
    filter: 'blur(4px)',
    pointerEvents: 'none',
    userSelect: 'none'
  }
})

const BlurredSection = ({ showLockIcon, size }) => {
  const { classes } = useStyles()

  let hiddenText = 'hidden'

  if (size === 'sm') {
    hiddenText = 'hidden small'
  }

  if (size === 'md') {
    hiddenText = 'hidden medium long text'
  }

  if (size === 'lg') {
    hiddenText = 'hidden large long text with more content. hidden large long text with more content. '
    hiddenText += 'hidden large long text with more content. hidden large long text with more content. '
    hiddenText += 'hidden large long text with more content. hidden large long text with more content. '
    hiddenText += 'hidden large long text with more content. hidden large long text with more content. '
    hiddenText += 'hidden large long text with more content. hidden large long text with more content. '
  }

  return (
    <div className={classes.blurredSection}>
      <Typography
        className={classes.blurredContent}
        variant="body2"
        color="text.secondary"
      >
        {hiddenText}
      </Typography>
      {showLockIcon === true && <LockedContentTooltip />}
    </div>
  )
}

BlurredSection.defaultProps = {
  showLockIcon: true,
  size: 'xs'
}

BlurredSection.propTypes = {
  showLockIcon: PropTypes.bool,
  size: PropTypes.string
}

export default BlurredSection
