import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'

import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { Paper, Tooltip, CircularProgress } from '@mui/material'

import LockIcon from '@mui/icons-material/Lock'

import { Button } from 'containers/themed'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  switchActionsContainer: {
    display: 'flex',
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    marginBottom: '10px'
  },
  switchActions: {
    display: 'flex',
    width: '100%',
    height: '64px !important'
  },
  switchAction: {
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,
    '& > button': {
      minWidth: '100%',
      minHeight: '100%',

      [theme.breakpoints.only('xs')]: {
        fontSize: '7pt'
      }
    }
  },
  switchActionContent: {
    paddingTop: '4px'
  },
  activeBorder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    animation: `${keyframes`
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
        `} 225ms;`
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28
  }
}))

export default function Navigation({
  hasPaidVersion,
  view,
  plansCount,
  publicationsCount,
  searchRequestRunning,
  onChange
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  const renderNavigationAction = (buttonView, label, isDisabled = false, Comp = ButtonContainer) => {
    const title = null

    let active

    if (view === buttonView) {
      active = (
        <div
          className={classes.activeBorder}
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    const tabButton = (props, children) => (
      <Button
        onClick={() => onChange(buttonView)}
        disabled={isDisabled}
        {...props}
      >
        <div
          className={classes.switchActionContent}
          style={active && { color: colors.get('primary') }}
        >
          {label}
        </div>
        {children}
      </Button>
    )

    const renderCalendarDisabled = () => (
      <Tooltip
        title={i18n.get('themax_paid_version_required')}
        placement="top"
        arrow
      >
        {tabButton(
          {
            sx: {
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              gap: '10px',
              '&.Mui-disabled': {
                pointerEvents: 'auto',
                cursor: 'not-allowed'
              }
            },
            component: 'div',
            onClick: null
          },
          <LockIcon sx={{ width: '18px', height: '18px' }} />
        )}
      </Tooltip>
    )

    const renderTabLoading = () => (
      <Button
        onClick={() => onChange(buttonView)}
        disabled
      >
        <div className={classes.iconContainer}>
          <CircularProgress
            variant="indeterminate"
            size={16}
          />
        </div>
      </Button>
    )

    const renderTabButton = () => {
      if (searchRequestRunning) {
        return renderTabLoading()
      }

      if (isDisabled && buttonView === 'calendar') {
        return renderCalendarDisabled()
      }

      return tabButton()
    }

    return (
      <Comp
        key={buttonView}
        className={classes.switchAction}
        title={title}
      >
        {renderTabButton()}
        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      'editorial_plans',
      `${i18n.get('editorial_plans')} (${plansCount})`
    )
  )

  buttons.push(
    renderNavigationAction(
      'media_profiles',
      `${i18n.get('media_profiles')} (${publicationsCount})`
    )
  )

  buttons.push(
    renderNavigationAction(
      'calendar',
      `${i18n.get('calendar')}`,
      !hasPaidVersion
    )
  )

  if (buttons.length <= 1) {
    return null
  }

  return (
    <>
      <Paper className={classes.switchActionsContainer}>
        <div className={classes.switchActions}>
          {buttons}
        </div>
      </Paper>
    </>
  )
}

Navigation.propTypes = {
  hasPaidVersion: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  plansCount: PropTypes.number.isRequired,
  publicationsCount: PropTypes.number.isRequired,
  searchRequestRunning: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired
}
