import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { blue } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  headline: {
    a: {
      transition: 'color 225ms',
      color: blue[theme.isDark ? 200 : 900],
      textDecoration: 'none',

      '&:hover': {
        color: blue[theme.isDark ? 300 : 700]
      }
    }
  }
}))

export default function Headline({ news, useSummaryHeadline }) {
  const target = news.get('url')
  const { classes } = useStyles()

  let headline = useSummaryHeadline ? news.get('summaryHeadline') : news.get('headline')
  headline = (headline || news.get('headline')).slice(0, 100)

  if (target) {
    return (
      <div className={classes.headline}>
        <a
          href={target}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{
            __html: headline
          }}
        />
      </div>
    )
  }

  return (
    <div
      className={classes.headline}
      dangerouslySetInnerHTML={{
        __html: headline
      }}
    />
  )
}

Headline.propTypes = {
  news: PropTypes.object.isRequired,
  useSummaryHeadline: PropTypes.bool
}
