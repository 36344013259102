import { combineReducers } from 'redux-immutable'

import ui from './ui'
import settings from './settings'
import progress from './progress'
import lastExport from './last_export'
import dispatchLog from './dispatch_log'

export default combineReducers({
  ui,
  settings,
  progress,
  lastExport,
  dispatchLog
})
