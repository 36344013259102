import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { FormControlLabel, Switch } from '@mui/material'
import { styled } from '@mui/material/styles'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  iconWrapper: {
    backgroundColor: theme.palette.primary.main,
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  }

}))

const MaterialUISwitch = styled(Switch)(() => ({
  width: 62,
  height: 32,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(25px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#8796A5'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#003892',
    width: 40,
    height: 40
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#8796A5',
    borderRadius: 20 / 2
  }
}))

const ThemedSwitch = ({ LeftIcon, RightIcon, iconColor, label, checked, disabled, handleChange }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <FormControlLabel
      control={(
        <MaterialUISwitch
          disabled={disabled}
          checked={checked}
          onChange={handleChange}
          icon={(
            <div
              className={classes.iconWrapper}
            >
              <LeftIcon
                sx={{
                  color: iconColor
                }}
              />
            </div>
          )}
          checkedIcon={(
            <div className={classes.iconWrapper}>
              <RightIcon
                sx={{
                  color: iconColor
                }}
              />
            </div>
          )}
          sx={{
            m: 1
          }}
        />
      )}
      label={i18n.get(label)}
    />
  )
}

ThemedSwitch.propTypes = {
  LeftIcon: PropTypes.any,
  RightIcon: PropTypes.any,
  iconColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,

  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default ThemedSwitch
