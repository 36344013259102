import { connect } from 'react-redux'

import ActiveFilterList from 'components/active_filter_list'
import {
  getActiveThemaxFilters,
  getActiveThemaxFiltersCollapsed,
  getActiveSelectedThemaxBooleans,
  getActiveThemaxNumberFilters
} from 'selectors'

import { toggleActiveFilters } from 'actions/themax'

const mapStateToProps = state => ({
  activeFilters: getActiveThemaxFilters(state),
  activeBooleanFilters: getActiveSelectedThemaxBooleans(state),
  filterCount: getActiveThemaxFilters(state).size,
  collapsed: getActiveThemaxFiltersCollapsed(state),
  activeNumberFilters: getActiveThemaxNumberFilters(state),
  activeFilterType: 'themax'
})

export default connect(
  mapStateToProps,
  {
    onCollapseClick: toggleActiveFilters
  }
)(ActiveFilterList)
