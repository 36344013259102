import React from 'react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

import { handleProps } from '../utils'

export default function InlineDatePicker(props) {
  const newProps = handleProps(props)

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateLibInstance={moment}
    >
      <DesktopDatePicker
        autoOk
        {...newProps}
      />
    </LocalizationProvider>
  )
}
