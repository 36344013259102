import React, { useState, useEffect } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { Badge, Menu, FormControl, ToggleButton, ToggleButtonGroup, Slider, Box, Typography } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SearchIcon from '@mui/icons-material/Search'
import FilterListIcon from '@mui/icons-material/FilterList'

import { InlineDateTimePicker, Button, IconButton, Select, Input } from 'containers/themed'

const useStyles = makeStyles()({
  filtersContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  newFilterAndOperatorActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  applyAndRemoveActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  filterRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
    marginBottom: '6px',
    padding: '5px'
  },
  operatorToggleForm: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
  },
  operatorToggle: {
    width: '100px',
    '& button': {
      width: '100%'
    }
  },
  actionButton: {
    width: '140px'
  },
  buttonIcon: {
    marginLeft: '10px'
  },
  invalidFilter: {
    backgroundColor: 'rgba(255, 0, 0, 0.038)',
    borderRadius: '6px'
  },
  input: {
    width: '200px',
    minWidth: '200px',
    maxWidth: '200px'
  },
  slider: {
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }
})

// Example field options for multi filters:
//
// fieldOptions = [
//   {
//     field: 'field_1',
//     name: 'field_1',
//     type: 'text'
//   },
//   {
//     field: 'field_2',
//     name: 'field_2',
//     type: 'number'
//   },
//   {
//     field: 'field_3',
//     name: 'field_3',
//     type: 'enum',
//     values: [
//       { value: 'value_1', label: 'value_1_label' },
//       { value: 'value_2', label: 'value_2_label' }
//     ]
//   },
//   {
//     field: 'field_4',
//     name: 'field_4',
//     type: 'boolean'
//   },
//   {
//     field: 'field_5',
//     name: 'field_5',
//     type: 'date'
//   },
//   {
//     field: ['field_6', 'filed_6_1'], // Nested (association) field
//     name: 'field_6 field_6_1',
//     label: 'field_6_1_label',
//     type: 'text'
//   },
//   {
//     field: 'field_7',
//     name: 'field_7',
//     type: 'percentage'
//   }
// ]

function MultiFilters({ currentFilters, fieldOptions, loading, position, locale, onApply }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const comparisonOptions = [
    {
      type: 'text',
      values: [
        'equals',
        'not_equals',
        'contains',
        'not_contains',
        'starts_with',
        'not_starts_with',
        'ends_with',
        'not_ends_with',
        'empty',
        'not_empty'
      ]
    },
    {
      type: 'number',
      values: [
        'equals',
        'not_equals',
        'greater_than',
        'greater_than_or_equals',
        'less_than',
        'less_than_or_equals'
      ]
    },
    {
      type: 'percentage',
      values: [
        'equals',
        'not_equals',
        'greater_than',
        'greater_than_or_equals',
        'less_than',
        'less_than_or_equals'
      ]
    },
    {
      type: 'enum',
      values: [
        'equals',
        'not_equals',
        'empty',
        'not_empty'
      ]
    },
    {
      type: 'date',
      values: [
        'date_equals',
        'date_not_equals',
        'date_greater_than',
        'date_greater_than_or_equals',
        'date_less_than',
        'date_less_than_or_equals',
        'empty',
        'not_empty'
      ]
    }
  ]

  const getPosition = p => {
    switch (p) {
      case 'top':
        return {
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
      case 'top-start':
        return {
          anchorOrigin: { vertical: 'top', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
      case 'top-end':
        return {
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' }
        }
      case 'bottom':
        return {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
      case 'bottom-start':
        return {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
      case 'bottom-end':
        return {
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' }
        }
      default:
        return {
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' }
        }
    }
  }

  const { anchorOrigin, transformOrigin } = getPosition(position)

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const [index, setIndex] = useState(0)
  const [operator, setOperator] = useState('and')

  const initialFilter = { index, field: '', name: '', comparison: '', value: '' }

  const [filters, setFilters] = useState([initialFilter])

  useEffect(() => {
    if (currentFilters) {
      setOperator(currentFilters.operator)

      if (currentFilters.conditions.length > 0) {
        setIndex(currentFilters.conditions[currentFilters.conditions.length - 1].index)
        setFilters(currentFilters.conditions)
      } else {
        setIndex(0)
        setFilters([initialFilter])
      }
    }
  }, [currentFilters])

  const validFilter = filter => filter.field !== ''
    && filter.comparison !== ''
    && (filter.value !== '' || ['empty', 'not_empty'].includes(filter.comparison))

  const validFilters = filters.filter(validFilter)

  const handleApplyFilters = () => {
    onApply(operator, validFilters)
  }

  const handleAddFilter = () => {
    setIndex(index + 1)
    setFilters(prevFilters => [...prevFilters, { index: index + 1, field: '', name: '', comparison: '', value: '' }])
  }

  const handleRemoveFilter = i => {
    if (filters.length === 1) {
      setFilters([initialFilter])
    } else {
      setFilters(prevFilters => prevFilters.filter(filter => filter.index !== i))
    }
  }

  const handleRemoveAllFilters = () => {
    setFilters([initialFilter])
  }

  const handleFiltersClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleFiltersClose = () => {
    setAnchorEl(null)
  }

  const matchingOption = (firstValue, secondValue) => firstValue === secondValue

  const handleFilterFieldChange = (option, i) => {
    if (option.value) {
      setFilters(prevFilters => prevFilters.map(filter => {
        if (filter.index === i) {
          const fieldOption = fieldOptions.find(field => matchingOption(field.name, option.value))
          const isBoolean = fieldOption.type === 'boolean'

          let value = ''

          if (fieldOption.type === 'percentage') {
            value = 0
          }

          return {
            ...filter,
            field: fieldOption.field,
            name: fieldOption.name,
            comparison: isBoolean ? 'is' : '',
            value
          }
        }

        return filter
      }))
    }
  }

  const handleComparisonChange = (option, i) => {
    if (option.value) {
      setFilters(prevFilters => prevFilters.map(filter => {
        if (filter.index === i) {
          return {
            ...filter,
            comparison: option.value,
            value: option.value === 'empty' || option.value === 'not_empty' ? '' : filter.value
          }
        }

        return filter
      }))
    }
  }

  const handleTextValueChange = (e, i) => {
    const { value } = e.target

    setFilters(prevFilters => prevFilters.map(filter => {
      if (filter.index === i) {
        return { ...filter, value }
      }

      return filter
    }))
  }

  const handleNumberValueChange = (value, i) => {
    setFilters(prevFilters => prevFilters.map(filter => {
      if (filter.index === i) {
        return { ...filter, value }
      }

      return filter
    }))
  }

  const handleEnumValueChange = (option, i) => {
    if (option.value) {
      setFilters(prevFilters => prevFilters.map(filter => {
        if (filter.index === i) {
          return { ...filter, value: option.value }
        }

        return filter
      }))
    }
  }

  const handleDateValueChange = (value, i) => {
    if (value) {
      setFilters(prevFilters => prevFilters.map(filter => {
        if (filter.index === i) {
          return { ...filter, value }
        }

        return filter
      }))
    }
  }

  const renderValueInput = (filter, i) => {
    if (filter.comparison === 'empty' || filter.comparison === 'not_empty') {
      return null
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'text') {
      return (
        <Input
          className={classes.input}
          variant="outlined"
          size="small"
          type="text"
          value={filter.value}
          onChange={e => handleTextValueChange(e, i)}
          onKeyDown={e => e.stopPropagation()}
          label={i18n.get('value')}
          disabled={loading}
        />
      )
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'number') {
      return (
        <Input
          className={classes.input}
          variant="outlined"
          size="small"
          type="number"
          value={filter.value}
          onChange={({ target: { value } }) => handleNumberValueChange(parseInt(value, 10), i)}
          onKeyDown={e => e.stopPropagation()}
          label={i18n.get('value')}
          disabled={loading}
        />
      )
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'percentage') {
      return (
        <Box className={classes.slider}>
          <Slider
            value={filter.value}
            onChange={({ target: { value } }) => handleNumberValueChange(value, i)}
            min={0}
            max={100}
            step={1}
            valueLabelDisplay="off"
            valueLabelFormat={value => `${value}%`}
            disabled={loading}
          />
          <Typography>
            {filter.value}%
          </Typography>
        </Box>
      )
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'enum') {
      return (
        <Box className={classes.input}>
          <Select
            variant="outlined"
            size="small"
            value={filter.value}
            onChange={value => handleEnumValueChange(value, i)}
            onKeyDown={e => e.stopPropagation()}
            options={fieldOptions
              .find(field => matchingOption(field.name, filter.name)).values
              .map(({ value, label }) => ({ value, label: i18n.get(label) }))}
            label={i18n.get('value')}
            disabled={loading}
          />
        </Box>
      )
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'boolean') {
      return (
        <Box className={classes.input}>
          <Select
            variant="outlined"
            size="small"
            value={filter.value}
            onChange={value => handleEnumValueChange(value, i)}
            onKeyDown={e => e.stopPropagation()}
            options={[{ value: 'yes', label: i18n.get('yes') }, { value: 'no', label: i18n.get('no') }]}
            label={i18n.get('value')}
            disabled={loading}
          />
        </Box>
      )
    }

    if (filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type === 'date') {
      return (
        <Box className={classes.input}>
          <InlineDateTimePicker
            inputProps={{
              size: 'small'
            }}
            ampm={locale === 'en-us'}
            label={i18n.get('value')}
            value={filter.value}
            onChange={value => handleDateValueChange(value, i)}
            onKeyDown={e => e.stopPropagation()}
            disabled={loading}
          />
        </Box>
      )
    }

    return null
  }

  const filterRow = filter => {
    const invalidFilterClass = validFilter(filter) ? '' : classes.invalidFilter

    return (
      <Box
        key={filter.index}
        className={`${classes.filterRow} ${invalidFilterClass}`}
      >
        <IconButton
          color="primary"
          size="small"
          onClick={() => handleRemoveFilter(filter.index)}
          title={i18n.get('remove')}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
        <Box className={classes.input}>
          <Select
            variant="outlined"
            size="small"
            onChange={value => handleFilterFieldChange(value, filter.index)}
            value={filter.name}
            options={fieldOptions.map(option => ({ value: option.name, label: i18n.get(option.label || option.name) }))}
            label={i18n.get('field')}
            disabled={loading}
          />
        </Box>
        {
          filter.field && fieldOptions.find(field => matchingOption(field.name, filter.name)).type !== 'boolean' && (
            <Box className={classes.input}>
              <Select
                variant="outlined"
                size="small"
                onChange={value => handleComparisonChange(value, filter.index)}
                value={filter.comparison}
                options={filter.field ? comparisonOptions
                  .find(option => option.type === fieldOptions.find(field => matchingOption(field.name, filter.name)).type).values
                  .map(value => ({ value, label: i18n.get(value) })) : []}
                label={i18n.get('comparison')}
                disabled={loading}
              />
            </Box>
          )
        }
        {renderValueInput(filter, filter.index)}
      </Box>
    )
  }

  return (
    <Box>
      <Button
        onClick={handleFiltersClick}
      >
        <Badge
          badgeContent={validFilters.length}
          color="primary"
        >
          <FilterListIcon />
        </Badge>
        <Box className={classes.buttonIcon}>
          {i18n.get('filters')}
        </Box>
      </Button>
      <Menu
        MenuListProps={{
          style: { width: '720px' }
        }}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={open}
        onClose={handleFiltersClose}
      >
        <Box className={classes.filtersContainer}>
          <Box className={classes.newFilterAndOperatorActions}>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleRemoveAllFilters}
              disabled={loading}
            >
              {i18n.get('remove_all')}
              <DeleteForeverIcon className={classes.buttonIcon} />
            </Button>
            <FormControl
              className={classes.operatorToggleForm}
              disabled={loading}
            >
              {i18n.get('operator')}:
              <ToggleButtonGroup
                className={classes.operatorToggle}
                size="small"
                color="primary"
                value={operator}
                exclusive
                onChange={(_e, value) => value && setOperator(value)}
              >
                <ToggleButton value="and">{i18n.get('and')}</ToggleButton>
                <ToggleButton value="or">{i18n.get('or')}</ToggleButton>
              </ToggleButtonGroup>
            </FormControl>
          </Box>
          {filters.map(filterRow)}
          <Box className={classes.applyAndRemoveActions}>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleAddFilter}
              disabled={loading}
            >
              {i18n.get('add_filter')}
              <AddOutlinedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleApplyFilters}
              loading={loading}
              disabled={loading}
            >
              {i18n.get('apply_filters')}
              <SearchIcon className={classes.buttonIcon} />
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  )
}

MultiFilters.propTypes = {
  currentFilters: PropTypes.object,
  fieldOptions: PropTypes.array,
  loading: PropTypes.bool,
  position: PropTypes.string,
  locale: PropTypes.string,

  onApply: PropTypes.func
}

export default MultiFilters
