import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  active: false,
  loadingNews: false,
  shoppingSuccess: false
})

export default handleActions({
  [NewsActions.showShoppingNewsDialog]: state => state.set('active', true),
  [NewsActions.hideShoppingNewsDialog]: state => state.set('active', false).set('shoppingSuccess', false),
  [NewsActions.submitShoppingSuccess]: state => state.set('shoppingSuccess', true),

  [AppActions.resetState]: () => initialState
}, initialState)
