import { connect } from 'react-redux'

import Actions from 'components/shopping_cart/app_bar/actions'
import { showEditNewsDialog } from 'actions/news'
import {
  showCustomTagDialog,
  showRemoveCustomTagDialog,
  resetShoppingCart
} from 'actions/shopping_cart'
import { showAiAnalysisDialog } from 'actions/ai'
import {
  getShoppingCartIsStructuredExport,
  getAllowedNewsViews,
  getCapabilities,
  getViewConfigCapabilities
} from 'selectors'

const mapStateToProps = state => ({
  customTagMode: getShoppingCartIsStructuredExport(state),
  allowedViews: getAllowedNewsViews(state),
  capabilities: getCapabilities(state),
  viewConfigCaps: getViewConfigCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onCustomTagClick: showCustomTagDialog,
    onRemoveCustomTagClick: showRemoveCustomTagDialog,
    onReset: resetShoppingCart,
    onNewNewsClick: showEditNewsDialog,
    onAiAnalysisClick: showAiAnalysisDialog
  }
)(Actions)
