import { connect } from 'react-redux'

import TemplatesEditorVariables from 'components/email_templates_library/templates_editor_variables'

import {
  getUserLocale
} from 'selectors'

const mapStateToProps = (state, ownProps) => ({
  userLocale: getUserLocale(state),
  raisedBtn: ownProps.raisedBtn
})

export default connect(
  mapStateToProps,
  {
  }
)(TemplatesEditorVariables)
