import { handleActions } from 'redux-actions'
import { fromJS, Set, List } from 'immutable'

import * as Actions from 'actions/custom_tags'
import * as ShoppingCartActions from 'actions/shopping_cart'
import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

const createNewCustomTag = () => fromJS({
  name: '',
  description: '',
  queries: Set([]),
  newsCount: 0,
  percolationEnabled: false,
  customTagGroupIds: List([])
})

export const initialState = fromJS({
  loading: false,
  showCustomTagDialog: false,
  showRemoveCustomTagDialog: false,
  showEmptyCustomTagDialog: false,
  uploadedPhoto: null,
  selectedCustomTag: createNewCustomTag(),
  lastUsedCustomTagIds: List([]),
  afterStructuredFormatExportAction: 'none',
  sortBy: 'name',
  groupedAssignableCustomTags: false,
  filteredGroupId: null,
  loadingCustomTagIds: Set([]),
  viewType: 'list',
  customTagsTab: 'all'
})

export default handleActions({
  [Actions.setSelectedCustomTag]: (state, { payload: customTag }) => state.set('selectedCustomTag', customTag),
  [Actions.hideAssignCustomTagsToNewsDialog]: state => state
    .set('selectedCustomTag', initialState.get('selectedCustomTag'))
    .set('showCustomTagDialog', false),
  [Actions.setUploadedPhoto]: (state, { payload: data }) => state.set('uploadedPhoto', data),

  [Actions.showCustomTagDialog]: (state, { payload }) => {
    let selectedCustomTag = createNewCustomTag()

    if (payload) {
      selectedCustomTag = payload.update('queries', queries => Set(queries))
    }

    return state.merge({
      showCustomTagDialog: true,
      uploadedPhoto: null,
      selectedCustomTag
    })
  },
  [Actions.hideCustomTagDialog]: state => state.set('showCustomTagDialog', false),
  [ShoppingCartActions.hideCustomTagDialog]: state => state.set('showCustomTagDialog', false),
  [MediaReviewsActions.hideCustomTagDialog]: state => state.set('showCustomTagDialog', false),

  [Actions.openCustomTagInShoppingCart]: state => state.set('loading', true),
  [ShoppingCartActions.fetchClusterForShoppingCartSuccess]: state => state.set('loading', false),

  [Actions.updateAfterStructuredFormatExportAction]: (state, { payload }) => state.merge({
    afterStructuredFormatExportAction: payload
  }),
  [Actions.hideEmptyCustomTagDialog]: state => state.set('showEmptyCustomTagDialog', false),
  [Actions.sortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [Actions.showRemoveCustomTagDialog]: (state, { payload: customTag }) => state.merge({
    showRemoveCustomTagDialog: true,
    selectedCustomTag: customTag
  }),

  [Actions.showEmptyCustomTagDialog]: (state, { payload: customTag }) => state.merge({
    showEmptyCustomTagDialog: true,
    selectedCustomTag: customTag
  }),
  [Actions.hideRemoveCustomTagDialog]: state => state.set('showRemoveCustomTagDialog', false),

  [Actions.setFilteredGroupId]: (state, { payload: groupId }) => state.update('filteredGroupId', id => (id === groupId ? null : groupId)),
  [Actions.resetFilteredGroupId]: state => state.set('filteredGroupId', null),

  [Actions.setCustomTagsViewType]: (state, { payload }) => (
    state
      .set('sortBy', 'name')
      .set('filteredGroupId', null)
      .set('viewType', payload)
  ),
  [Actions.changeCustomTagsTab]: (state, { payload }) => state.set('customTagsTab', payload),

  [Actions.toggleGroupedAssignableCustomTags]: state => state.update('groupedAssignableCustomTags', v => !v),

  [Actions.assignCustomTagToNewsSuccess]: (state, { payload: { customTag } }) => (
    state
      .update('lastUsedCustomTagIds', l => (l || List([])).unshift(customTag.get('id')))
      .update('loadingCustomTagIds', l => (l || Set([])).remove(customTag.get('id')))
      .set('selectedCustomTag', initialState.get('selectedCustomTag'))
  ),
  [Actions.assignCustomTagToNewsStart]: (state, { payload: { customTag } }) => (
    state.update('loadingCustomTagIds', l => l.add(customTag.get('id')))
  ),
  [Actions.assignCustomTagToNewsError]: (state, { payload: { customTag } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTag.get('id')))
  ),

  [Actions.removeCustomTagFromNewsStart]: (state, { payload: { customTag } }) => (
    state.update('loadingCustomTagIds', l => l.add(customTag.get('id')))
  ),
  [Actions.removeCustomTagFromNewsError]: (state, { payload: { customTag } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTag.get('id')))
  ),
  [Actions.removeCustomTagFromNewsSuccess]: (state, { payload: { customTag } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTag.get('id')))
  ),

  [Actions.assignCustomTagToGroupSuccess]: (state, { payload: { customTagId } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTagId))
  ),
  [Actions.assignCustomTagToGroupStart]: (state, { payload: customTagId }) => (
    state.update('loadingCustomTagIds', l => l.add(customTagId))
  ),
  [Actions.assignCustomTagToGroupError]: (state, { payload: { customTagId } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTagId))
  ),

  [Actions.removeCustomTagFromGroupStart]: (state, { payload: customTagId }) => (
    state.update('loadingCustomTagIds', l => l.add(customTagId))
  ),
  [Actions.removeCustomTagFromGroupError]: (state, { payload: { customTagId } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTagId))
  ),
  [Actions.removeCustomTagFromGroupSuccess]: (state, { payload: { customTagId } }) => (
    state.update('loadingCustomTagIds', l => l.remove(customTagId))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
