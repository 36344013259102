import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { List, Map, fromJS } from 'immutable'
import { Alert, ImageList, ImageListItem, ImageListItemBar, Box, Grid, IconButton, Tooltip } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

import useI18n from 'hooks/useI18n'
import TransitionComponent from 'components/transition_component'
import { Select, Button } from 'containers/themed'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, grey } from '@mui/material/colors'

import { listUniqueById } from 'utils/immutable'
import { Document, Page } from 'utils/pdf'
import { formatBytes } from 'utils/number'

import { linkedInPost as initialLinkedInPost } from 'static/linked_in_post_config'

const useStyles = makeStyles()(theme => ({
  video: {
    width: '100%',
    height: 260
  },
  galleryControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1em'
  },
  imageListBox: {
    overflowY: 'scroll',
    height: 460
  },
  lastUploaded: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '1em'
    }
  },
  document: {
    marginRight: '0.5em',
    marginBottom: '0.5em'
  },
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '281px !important'
  },
  pdfPaging: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '25px',
    marginTop: '2px'
  }
}))

const MediaGallery = ({
  contentFormData,
  uploadedMediaFiles,
  onChange,
  loadMoreDisabled,
  requestRunning,
  fetchMoreMediaFilesStart,
  onImageDelete
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const mediaTypeOptions = [
    { value: 'image', label: i18n.get('image') },
    { value: 'video', label: i18n.get('video') },
    { value: 'document', label: i18n.get('document') }
  ]

  const currentLinkedInPost = contentFormData.get('linkedInPost') || fromJS(initialLinkedInPost)
  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')
  const currentSelectedMediaFileIds = currentSelectedMediaFiles.map(f => f.get('id'))
  const linkedInContentType = currentLinkedInPost.get('type')

  const [uploadedImages, setUploadedImages] = useState(uploadedMediaFiles.filter(f => f.get('mimeType').startsWith('image')))
  const [uploadedVideos, setUploadedVideos] = useState(uploadedMediaFiles.filter(f => f.get('mimeType').startsWith('video')))
  const [uploadedDocuments, setUploadedDocuments] = useState(uploadedMediaFiles.filter(f => f.get('mimeType') === 'application/pdf'))
  const [mediaType, setMediaType] = useState('image')
  const [multiImageValid, setMultiImageValid] = useState(true)
  const [hoveredImageId, setHoveredImageId] = useState(null)
  const [totalPages, setTotalPages] = useState({})
  const [pageNumbers, setPageNumbers] = useState({})

  const onDocumentLoadSuccess = (documentId, { numPages }) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [documentId]: 1
    }))
    setTotalPages(prevTotalPages => ({
      ...prevTotalPages,
      [documentId]: numPages
    }))
  }

  const changePage = (documentId, offset) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [documentId]: prevPageNumbers[documentId] + offset
    }))
  }
  const previousPage = (documentId, e) => {
    e.stopPropagation()
    changePage(documentId, -1)
  }
  const nextPage = (documentId, e) => {
    e.stopPropagation()
    changePage(documentId, 1)
  }

  const handleDeleteImage = id => {
    onImageDelete({ id })
  }

  useEffect(() => {
    const allUploadedImageFiles = uploadedMediaFiles
      .filter(f => f.get('mimeType').startsWith('image'))
      .concat(contentFormData.get('ccdMediaFiles').filter(f => f.get('mimeType').startsWith('image')))
      .reduce((acc, current) => {
        if (!acc.find(item => item.get('id') === current.get('id'))) {
          return acc.push(current)
        }

        return acc
      }, fromJS([]))

    const allUploadedVideoFiles = uploadedMediaFiles
      .filter(f => f.get('mimeType').startsWith('video'))
      .concat(contentFormData.get('ccdMediaFiles').filter(f => f.get('mimeType').startsWith('video')))
      .reduce((acc, current) => {
        if (!acc.find(item => item.get('id') === current.get('id'))) {
          return acc.push(current)
        }

        return acc
      }, fromJS([]))

    const allUploadedDocumentFiles = uploadedMediaFiles
      .filter(f => f.get('mimeType') === 'application/pdf')
      .concat(uploadedDocuments)
      .concat(contentFormData.get('ccdMediaFiles').filter(f => f.get('mimeType') === 'application/pdf'))
      .reduce((acc, current) => {
        if (!acc.find(item => item.get('id') === current.get('id'))) {
          return acc.push(current)
        }

        return acc
      }, fromJS([]))

    if (linkedInContentType === 'multi_image') {
      if (currentSelectedMediaFiles.size <= 9) {
        setMultiImageValid(true)
      } else {
        setMultiImageValid(false)
      }
    }

    setUploadedImages(allUploadedImageFiles)
    setUploadedVideos(allUploadedVideoFiles)
    setUploadedDocuments(allUploadedDocumentFiles)
  }, [uploadedMediaFiles, contentFormData])

  useEffect(() => {
    if (currentSelectedMediaFiles.find(f => f.get('mimeType').startsWith('video'))) {
      return setMediaType('video')
    }

    if (currentSelectedMediaFiles.find(f => f.get('mimeType') === 'application/pdf')) {
      return setMediaType('document')
    }

    return setMediaType('image')
  }, [])

  const toggleImageSelect = selectedMediaFile => {
    let mediaContent = Map({})
    let newSelected = List()
    let linkedInImages = List()

    if (currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))) {
      newSelected = currentSelectedMediaFiles.filter(selected => selected.get('id') !== selectedMediaFile.get('id'))
    } else {
      newSelected = currentSelectedMediaFiles.unshift(fromJS(selectedMediaFile))
    }

    linkedInImages = newSelected.map(selected => (fromJS({
      altText: selected.get('filename'),
      id: String(selected.get('id'))
    })))
    linkedInImages = listUniqueById(linkedInImages)

    if (newSelected.size > 1) {
      mediaContent = fromJS({
        multiImage: {
          images: linkedInImages
        }
      })

      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'multi_image') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }

    if (newSelected.size === 1) {
      mediaContent = fromJS({
        media: {
          altText: selectedMediaFile.get('filename'),
          id: String(selectedMediaFile.get('id'))
        }
      })

      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'image') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }

    if (!newSelected.size) {
      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'text_only') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }

    if (currentSelectedMediaFiles.size <= 9) {
      setMultiImageValid(true)
    } else {
      setMultiImageValid(false)
    }
  }

  const toggleVideoSelect = selectedMediaFile => {
    let newSelected = List()
    let mediaContent = Map({})

    if (!currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))) {
      newSelected = newSelected.push(selectedMediaFile)

      mediaContent = fromJS({
        media: {
          title: currentLinkedInPost.getIn(['content', 'media', 'title'], ''),
          id: String(selectedMediaFile.get('id'))
        }
      })

      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'video') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    } else {
      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'text_only') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }
  }

  const toggleDocumentSelect = selectedMediaFile => {
    let newSelected = List()
    let mediaContent = Map({})

    if (!currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))) {
      newSelected = newSelected.push(selectedMediaFile)

      mediaContent = fromJS({
        media: {
          title: currentLinkedInPost.getIn(['content', 'media', 'title'], ''),
          id: String(selectedMediaFile.get('id'))
        }
      })

      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'document') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    } else {
      onChange({ key: 'linkedInPost', value: currentLinkedInPost.set('content', mediaContent).set('type', 'text_only') })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }
  }

  const renderMultiImageValidInfo = () => {
    if (mediaType !== 'image') {
      return null
    }

    if (multiImageValid === true) {
      return null
    }

    return (
      <Alert severity="warning">
        {i18n.get('linked_in_multi_image_info')}
      </Alert>
    )
  }

  const renderImageGallery = () => {
    if (mediaType !== 'image') {
      return null
    }

    if (!uploadedImages.size && !contentFormData.get('ccdMediaFiles').size) {
      return null
    }

    return (
      <Box
        className={classes.imageListBox}
      >
        <ImageList
          cols={3}
          gap={8}
          sx={{ marginTop: 0 }}
        >
          {uploadedImages.map(item => (
            <ImageListItem
              key={item.get('id')}
              onMouseEnter={() => setHoveredImageId(item.get('id'))}
              onMouseLeave={() => setHoveredImageId(null)}
            >
              {(hoveredImageId === item.get('id')) && (
                <ImageListItemBar
                  position="bottom"
                  title={(
                    <Tooltip title={item.get('filename')}>
                      <span>{item.get('filename')}</span>
                    </Tooltip>
                  )}
                  subtitle={formatBytes(item.get('size'))}
                  actionIcon={(
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => handleDeleteImage(item.get('id'))}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  actionPosition="right"
                />
              )}
              {currentSelectedMediaFileIds.includes(item.get('id'))
                && (
                  <ImageListItemBar
                    sx={{ background: 'transparent' }}
                    position="top"
                    actionPosition="left"
                    actionIcon={<CheckCircleRoundedIcon sx={{ color: green[300] }} />}
                  />
                )}
              {/* eslint-disable-next-line */}
              <img
                onClick={_e => toggleImageSelect(item)}
                src={`${item.get('url')}`}
                srcSet={`${item.get('url')}`}
                alt={item.get('id')}
                loading="lazy"
                style={{ objectFit: 'contain' }}
              />
            </ImageListItem>
          ))}
          <ImageListItem
            key="loadMoreButton"
            className={classes.loadMoreButton}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loadMoreDisabled}
              onClick={fetchMoreMediaFilesStart}
              loading={requestRunning}
            >{i18n.get('load_more')}
            </Button>
          </ImageListItem>
        </ImageList>
      </Box>
    )
  }

  const getVideoSelectedColor = item => {
    if (currentSelectedMediaFileIds.includes(item.get('id'))) {
      return green[300]
    }

    return grey[400]
  }

  const renderVideoValidInfo = () => {
    if (mediaType !== 'video') {
      return null
    }

    if (currentSelectedMediaFileIds.size === 1) {
      return null
    }

    return (
      <Alert severity="warning">
        {i18n.get('linked_in_video_info')}
      </Alert>
    )
  }

  const renderVideoGallery = () => {
    if (mediaType !== 'video') {
      return null
    }

    if (!uploadedVideos.size && !contentFormData.get('ccdMediaFiles').size) {
      return null
    }

    return (
      <>
        <ImageList
          sx={{ width: '100%' }}
          cols={2}
          rowHeight={300}
          gap={3}
        >
          {uploadedVideos.map(item => {
            if (currentSelectedMediaFiles.size && !currentSelectedMediaFileIds.includes(item.get('id'))) {
              return null
            }

            return (
              <ImageListItem
                key={item.get('id')}
              >
                <video
                  className={classes.video}
                  controls
                  preload="none"
                >
                  <source
                    src={`${item.get('url')}`}
                    type="video/mp4"
                  />
                </video>
                <ImageListItemBar
                  onClick={_e => toggleVideoSelect(item)}
                  subtitle={i18n.get('click_here_to_select')}
                  position="below"
                  actionPosition="left"
                  actionIcon={<CheckCircleRoundedIcon sx={{ color: getVideoSelectedColor(item) }} />}
                />
              </ImageListItem>
            )
          })}
        </ImageList>
      </>
    )
  }

  const renderDocumentGallery = () => {
    if (mediaType !== 'document') {
      return null
    }

    if (!uploadedDocuments.size && !contentFormData.get('ccdMediaFiles').size) {
      return null
    }

    return (
      <Box
        className={classes.imageListBox}
      >
        <ImageList
          sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
          gap={3}
        >
          {uploadedDocuments.map(item => {
            const documentId = item.get('id')

            return (
              <ImageListItem
                className={classes.document}
                key={documentId}
                onClick={_e => toggleDocumentSelect(item)}
              >
                <Document
                  file={item.get('url')}
                  onLoadSuccess={props => onDocumentLoadSuccess(documentId, props)}
                >
                  <Page
                    pageNumber={pageNumbers[documentId] || 1}
                    renderAnnotationLayer={false}
                    scale={0.3}
                    height={900}
                  />
                </Document>
                <div className={classes.pdfPaging}>
                  <p>
                    {i18n.get('page')} {pageNumbers[documentId]
                      || (totalPages[documentId] ? 1 : '--')} {i18n.get('of')} {totalPages[documentId] || '--'}
                  </p>
                  {(totalPages[documentId] !== 1) && (
                    <div>
                      <IconButton
                        disabled={pageNumbers[documentId] <= 1}
                        onClick={e => previousPage(documentId, e)}
                        title={i18n.get('previous')}
                        size="small"
                      >
                        <NavigateBeforeIcon />
                      </IconButton>
                      <IconButton
                        disabled={pageNumbers[documentId] >= totalPages[documentId]}
                        onClick={e => nextPage(documentId, e)}
                        size="small"
                      >
                        <NavigateNextIcon />
                      </IconButton>
                    </div>
                  )}
                </div>
                {currentSelectedMediaFileIds.includes(documentId)
                  && (
                    <ImageListItemBar
                      sx={{ background: 'transparent' }}
                      position="top"
                      actionPosition="left"
                      actionIcon={<CheckCircleRoundedIcon color="primary" />}
                    />
                  )}
              </ImageListItem>
            )
          })}
        </ImageList>
      </Box>
    )
  }

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.galleryControls}
      >
        <Grid
          className={classes.lastUploaded}
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >{i18n.get('last_uploaded')}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
        >
          <Select
            size="small"
            variant="outlined"
            disabled={currentSelectedMediaFiles.size >= 1}
            onChange={({ value: v }) => setMediaType(v)}
            options={mediaTypeOptions}
            value={mediaType}
            label={i18n.get('media_type')}
          />
        </Grid>
      </Grid>
      <TransitionComponent type="slideFadeDown">
        {renderMultiImageValidInfo()}
      </TransitionComponent>
      <TransitionComponent type="slideFadeDown">
        {renderVideoValidInfo()}
      </TransitionComponent>
      {renderImageGallery()}
      {renderVideoGallery()}
      {renderDocumentGallery()}
    </>
  )
}

MediaGallery.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  uploadedMediaFiles: PropTypes.instanceOf(List).isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  fetchMoreMediaFilesStart: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired
}

export default MediaGallery
