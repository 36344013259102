import { connect } from 'react-redux'

import Content from 'components/content_desk_new/contents/content'

const mapStateToProps = (_state, ownProps) => ({
  content: ownProps.content
})

export default connect(
  mapStateToProps,
  {}
)(Content)
