import { connect } from 'react-redux'

import ContentsCampaignsTabs from 'components/content_desk_new/contents_campaigns_tabs'

import {
  changeContentsCampaignsTabView
} from 'actions/content_desk'

import {
  getCDeskSearchCampaignsRunning,
  getCDeskSearchContentsRunning,
  getCDeskContentsCampaignsTabView
} from 'selectors'

const mapStateToProps = state => ({
  campaignsLoading: getCDeskSearchCampaignsRunning(state),
  contentsLoading: getCDeskSearchContentsRunning(state),
  view: getCDeskContentsCampaignsTabView(state)
})

export default connect(
  mapStateToProps,
  {
    changeContentsCampaignsTabView
  }
)(ContentsCampaignsTabs)
