import { connect } from 'react-redux'

import ContentStatus from 'components/content_desk_new/contents/content/content_status'

const mapStateToProps = (_state, ownProps) => ({
  content: ownProps.content,
  mode: ownProps.mode
})

export default connect(
  mapStateToProps,
  {
  }
)(ContentStatus)

