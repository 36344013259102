import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'

import Card from 'components/card'

import { CardContent, CardHeader } from '@mui/material'
import ChannelBar from './channel_bar'

const useStyles = makeStyles()({
  channelBar: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%'
  },
  cardText: {
    fontSize: 18,
    paddingTop: 0
  },
  cardTitle: {
    fontSize: 14,
    paddingBottom: '0 !important'
  }
})

export default function MediaReview({ i18n, mediaReview, showChannels, className }) {
  const { classes } = useStyles()
  const startDate = moment(mediaReview.get('startDate'))
  const endDate = moment(mediaReview.get('endDate'))

  let date = `${startDate.format('L')} - ${endDate.format('L')}`

  if (startDate.isSame(endDate, 'day')) {
    date = startDate.format('L')
  }

  let channels = null

  if (showChannels) {
    channels = (
      <div className={classes.channelBar}>
        <ChannelBar mediaReview={mediaReview} />
      </div>
    )
  }

  return (
    <Card className={className}>
      <CardHeader
        classes={{ root: classes.cardTitle }}
        title={mediaReview.get('name')}
      />

      <CardHeader
        classes={{ root: classes.cardTitle }}
        subheader={i18n.get('date')}
      />

      <CardContent classes={{ root: classes.cardText }}>
        {date}
      </CardContent>

      <CardHeader
        classes={{ root: classes.cardTitle }}
        subheader={i18n.get('hits')}
      />
      <CardContent classes={{ root: classes.cardText }}>
        {mediaReview.get('newsCount')}
      </CardContent>

      {channels}
    </Card>
  )
}

MediaReview.defaultProps = {
  showChannels: true
}

MediaReview.propTypes = {
  i18n: PropTypes.object.isRequired,
  mediaReview: PropTypes.instanceOf(Map).isRequired,
  showChannels: PropTypes.bool,
  className: PropTypes.string
}
