import { connect } from 'react-redux'

import ScheduleDialog from 'components/content_desk_new/contents/content_edit_dialog/schedule_dialog'

import {
  setContentFormData,
  closeScheduleDialog,
  saveContentStart
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskContentIsPublishable,
  getCDeskShowScheduleDialog,
  getUserLocale
} from 'selectors'

const mapStateToProps = state => ({
  content: getCDeskContentFormData(state),
  isPublishable: getCDeskContentIsPublishable(state),
  locale: getUserLocale(state),
  open: getCDeskShowScheduleDialog(state)
})

export default connect(
  mapStateToProps,
  {
    closeScheduleDialog,
    onChange: setContentFormData,
    onSave: saveContentStart
  }
)(ScheduleDialog)
