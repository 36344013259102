import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  show: false,
  running: false,
  selectedChannelId: null
})

export default handleActions({
  [Actions.showInfluencerDetails]: state => state.set('show', true),
  [Actions.hideInfluencerDetails]: state => state.merge({
    selectedChannelId: null,
    show: false
  }),

  [Actions.fetchInfluencerRequestStart]: state => state.set('running', true),
  [Actions.fetchInfluencerRequestSuccess]: state => state.set('running', false),
  [Actions.fetchInfluencerRequestError]: state => state.set('running', false),

  [Actions.selectChannel]: (state, { payload: channelId }) => {
    if (channelId === state.get('selectedChannelId')) {
      return state.set('selectedChannelId', null)
    }

    return state.set('selectedChannelId', channelId)
  },

  [AppActions.resetState]: () => initialState
}, initialState)
