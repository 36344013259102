import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import {
  Chip
} from '@mui/material'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  list: {
    flexWrap: 'wrap',
    gap: 5,
    minHeight: 50,
    marginBottom: 15
  }
})

const DistributionLists = ({
  recipients,
  setContentFormData,
  selectedDistributionLists,
  hideOnEmpty
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const displayDistributionLists = 10
  const [showAll, setShowAll] = useState(false)
  const displayedDistributionList = showAll ? selectedDistributionLists : selectedDistributionLists.slice(0, displayDistributionLists)

  const hide = (hideOnEmpty && selectedDistributionLists.size === 0)

  const handleDeleteList = distributionList => {
    const newRecipients = recipients.filter(
      r => !r.get('distributionLists').some(dl => dl.get('id') === distributionList.get('id'))
    )

    setContentFormData({
      key: 'recipients',
      value: newRecipients
    })
  }

  const renderOtherDistributionLists = () => {
    if (selectedDistributionLists.size <= displayDistributionLists) {
      return null
    }

    if (showAll) {
      return (
        <div
          className={classes.distributionLists}
          key="other_distribution_lists"
        >
          <Chip
            color="primary"
            variant="filled"
            label={i18n.get('show_less')}
            onClick={() => setShowAll(false)}
          />
        </div>
      )
    }

    return (
      <div
        className={classes.distributionLists}
        key="other_distribution_lists"
      >
        <Chip
          color="primary"
          variant="filled"
          label={`+${selectedDistributionLists.size - displayDistributionLists} ${i18n.get('show_more')}`}
          onClick={() => setShowAll(true)}
        />
      </div>
    )
  }

  return (
    <div
      className={classes.list}
      style={{ display: hide ? 'none' : 'flex' }}
    >
      {displayedDistributionList.map(
        dlist => (
          <div key={`${dlist.get('id')}`}>
            <Chip
              color="primary"
              variant={dlist.get('partiallySelected') ? 'outlined' : 'filled'}
              label={`${dlist.get('name')} (${dlist.get('recipients').size}/${dlist.get('contacts').size})`}
              onDelete={() => handleDeleteList(dlist)}
            />
          </div>
        )
      )}
      {renderOtherDistributionLists()}
    </div>
  )
}

DistributionLists.defaultProps = {
  hideOnEmpty: false
}

DistributionLists.propTypes = {
  selectedDistributionLists: PropTypes.instanceOf(List).isRequired,
  recipients: PropTypes.instanceOf(List).isRequired,
  setContentFormData: PropTypes.func.isRequired,
  hideOnEmpty: PropTypes.bool
}

export default DistributionLists
