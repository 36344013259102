import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Divider,
  Box,
  LinearProgress
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'

import {
  RaisedPrimaryButton,
  Button,
  IconButton
} from 'containers/themed'

import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()(() => ({
  autocomplete: {
    marginTop: '1em'
  },
  newList: {
    marginTop: '1em'
  }
}))

export default function DistributionListCreateDialog({
  contacts,
  open,
  lists,
  title,
  loading,
  searchRequestRunning,
  onClose,
  onSelectOption,
  onSubmit,
  onFormUpdate,
  onRemove,
  searchDistributionListStart
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [createListDisabled, setCreateListDisabled] = useState(false)
  const [selected, setSelected] = useState(null)
  const [newName, setNewName] = useState(null)
  const [timerID, setTimerID] = useState(null)

  useEffect(() => {
    searchDistributionListStart({ filter: [] })
  }, [open])

  const options = lists ? mapToOptions(lists) : []

  const handleAutocompleteChange = (_event, option) => {
    setSelected(option)

    if (option) {
      setCreateListDisabled(true)
      onSelectOption(option.value)
    } else {
      setCreateListDisabled(false)
      onSelectOption(null)
    }
  }

  const handleFormDataUpdate = e => {
    const { value, name } = e.target

    if (name === 'name') {
      setNewName(value)
      setSelected(null)
    }

    onFormUpdate({ key: name, value })
  }

  const handleSave = () => {
    setSelected(null)
    setCreateListDisabled(false)
    onSubmit()
  }

  const handleClose = () => {
    setCreateListDisabled(false)
    setSelected(null)
    onClose()
  }

  const handleSuggestList = (_event, v) => {
    if (v.length >= 2) {
      clearTimeout(timerID)
      const id = setTimeout(() => searchDistributionListStart({ filter: [{ field: 'name', value: v }] }), 300)
      setTimerID(id)
    }

    if (v === '' && v.length < 2) {
      searchDistributionListStart({ filter: [] })
    }
  }

  const renderContact = contact => (
    <TableRow key={`contacts__row__${contact.get('id')}`}>
      <TableCell>
        {contacts.size > 1 && (
          <IconButton
            className={classes.icon}
            size="small"
            onClick={() => onRemove(contact)}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </TableCell>
      <TableCell>{contact.get('firstName')}</TableCell>
      <TableCell>{contact.get('lastName')}</TableCell>
      <TableCell>{contact.get('mail')}</TableCell>
      <TableCell>{contact.getIn(['country', 'name'])}</TableCell>
      <TableCell>{contact.get('externalPublication')}</TableCell>
    </TableRow>
  )

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {contacts.size ? i18n.get('add_edit_list') : i18n.get('create_distribution_list')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: t => t.spacing(3) }}
      >
        {contacts.size
          ? (
            <>
              <TableContainer sx={{ backgroundColor: t => t.palette.grey[200] }}>
                <Table>
                  <TableBody>
                    {contacts.map(renderContact)}
                  </TableBody>
                </Table>
              </TableContainer>

              <Divider style={{ margin: '1em -24px' }} />

              <Typography variant="body2">
                {i18n.get('add_to_existing_distribution_list')}
              </Typography>

              <Box
                component="form"
              >
                {searchRequestRunning && (
                  <LinearProgress
                    sx={{ borderRadius: '5px', marginTop: '5px' }}
                    color="primary"
                  />
                )}

                <Autocomplete
                  className={classes.autocomplete}
                  key="distributionList"
                  variant="outlined"
                  disabled={title !== ''}
                  getOptionLabel={option => option.label}
                  onChange={handleAutocompleteChange}
                  options={options}
                  value={selected}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onInputChange={handleSuggestList}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={i18n.get('search')}
                      placeholder=""
                    />
                  )}
                />
              </Box>

              <Divider style={{ margin: '1em -24px' }} />

              <Typography variant="body2">{i18n.get('create_a_new_list')}</Typography>
            </>
          ) : null}

        <TextField
          className={classes.newList}
          fullWidth
          label={i18n.get('name')}
          name="name"
          onChange={handleFormDataUpdate}
          disabled={createListDisabled}
          inputProps={{ maxLength: 50 }}
        />
        {!contacts.size && (
          <TextField
            multiline
            minRows={4}
            fullWidth
            label={i18n.get('description')}
            name="description"
            onChange={handleFormDataUpdate}
            margin="normal"
            size="small"
            inputProps={{ maxLength: 1000 }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ padding: t => t.spacing(2) }}>
        <Button
          disabled={loading}
          onClick={handleClose}
        >
          {i18n.get('cancel')}
        </Button>
        <RaisedPrimaryButton
          disabled={loading || (!selected && !newName)}
          loading={loading}
          onClick={handleSave}
        >
          {i18n.get('save')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

DistributionListCreateDialog.propTypes = {
  contacts: PropTypes.instanceOf(ImmutableList).isRequired,
  lists: PropTypes.instanceOf(ImmutableList).isRequired,
  title: PropTypes.string.isRequired,

  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  searchRequestRunning: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  searchDistributionListStart: PropTypes.func.isRequired
}
