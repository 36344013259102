/* global DOMParser */
import { useEffect } from 'react'

import {
  $getSelection,
  $isRangeSelection,
  $createTextNode,
  $createLineBreakNode,
  KEY_ENTER_COMMAND,
  PASTE_COMMAND,
  COMMAND_PRIORITY_LOW
} from 'lexical'

import { $generateNodesFromDOM } from '@lexical/html'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

const ContactTextPlugin = () => {
  const [editor] = useLexicalComposerContext()

  const handleEnterCommand = () => editor.registerCommand(
    KEY_ENTER_COMMAND,
    event => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        event.preventDefault()
        editor.update(() => {
          selection.insertNodes([$createLineBreakNode()])
        })
      }

      return true
    },
    COMMAND_PRIORITY_LOW
  )

  const handlePasteCommand = () => editor.registerCommand(
    PASTE_COMMAND,
    event => {
      const { clipboardData } = event
      const html = clipboardData.getData('text/html')

      if (html) {
        event.preventDefault()
        event.stopPropagation()

        const parser = new DOMParser()
        const dom = parser.parseFromString(html, 'text/html')

        const lexicalNodes = $generateNodesFromDOM(editor, dom)

        editor.update(() => {
          const processedNodes = []

          lexicalNodes.forEach((node, index) => {
            if (node.getType() === 'extended-paragraph' || node.getType() === 'extended-list') {
              const childNodes = node.getChildren()
              childNodes.forEach(child => {
                if (node.getType() === 'extended-paragraph') {
                  processedNodes.push(child)
                } else if (node.getType() === 'extended-list') {
                  child.getChildren().forEach(listItemChild => {
                    let textNode = $createTextNode(listItemChild.getTextContent())

                    if (listItemChild.hasFormat('bold')) {
                      textNode = textNode.toggleFormat('bold')
                    }

                    if (listItemChild.hasFormat('italic')) {
                      textNode = textNode.toggleFormat('italic')
                    }

                    if (listItemChild.hasFormat('underline')) {
                      textNode = textNode.toggleFormat('underline')
                    }

                    processedNodes.push(textNode)
                  })

                  if (child.getNextSibling()) {
                    processedNodes.push($createLineBreakNode())
                  }
                }
              })

              if (index < lexicalNodes.length - 1) {
                processedNodes.push($createLineBreakNode())
              }
            } else {
              processedNodes.push(node)
            }
          })

          const selection = $getSelection()

          if (selection) {
            selection.insertNodes(processedNodes)
          }
        })

        return true
      }

      return false
    },
    COMMAND_PRIORITY_LOW
  )

  useEffect(() => {
    handleEnterCommand()
    handlePasteCommand()
  }, [editor])

  return null
}

export default ContactTextPlugin
