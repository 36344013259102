import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/publications'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  from: 0,
  sortBy: 'reach',
  running: false,
  isFormActive: false,
  selectedIds: Set([])
})

export default handleActions({
  [Actions.publicationsRequestSuccess]: state => state.update('from', from => from + 30).merge({
    running: false,
    selectedIds: Set([])
  }),
  [Actions.publicationsRequestError]: state => state.set('running', false),
  [Actions.publicationsRequestStart]: state => state.merge({
    from: 0,
    running: true
  }),
  [Actions.morePublicationsRequestSuccess]: state => state.update('from', from => from + 30),

  [Actions.setSortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [Actions.selectPublication]: (state, { payload: publication }) => {
    if (state.get('selectedIds').has(publication.get('id'))) {
      return state.update('selectedIds', selectedIds => selectedIds.delete(publication.get('id')))
    }

    return state.update('selectedIds', selectedIds => selectedIds.add(publication.get('id')))
  },
  [Actions.resetPublicationSelection]: state => state.set('selectedIds', Set([])),
  [Actions.createPublicationSuccess]: state => state.set('isFormActive', false),
  [Actions.createPublicationError]: state => state.set('isFormActive', false),
  [Actions.openPublicationForm]: state => state.set('isFormActive', true),
  [Actions.hidePublicationForm]: state => state.set('isFormActive', false),

  [AppActions.resetState]: () => initialState
}, initialState)
