import { createAction } from 'redux-actions'

let prefix = 'INFLUENCERS/'

export const influencersRequestError = createAction(`${prefix}INFLUENCERS_REQUEST_ERROR`)
export const influencersRequestStart = createAction(`${prefix}INFLUENCERS_REQUEST_START`)
export const influencersRequestSuccess = createAction(`${prefix}INFLUENCERS_REQUEST_SUCCESS`)
export const moreInfluencersRequestError = createAction(`${prefix}MORE_INFLUENCERS_REQUEST_ERROR`)
export const moreInfluencersRequestStart = createAction(`${prefix}MORE_INFLUENCERS_REQUEST_START`)
export const moreInfluencersRequestSuccess = createAction(`${prefix}MORE_INFLUENCERS_REQUEST_SUCCESS`)
export const searchForInfluencer = createAction(`${prefix}SEARCH_FOR_INFLUENCER`)
export const searchForSelectedInfluencers = createAction(`${prefix}SEARCH_FOR_SELECTED_INFLUENCERS`)

export const setSortBy = createAction(`${prefix}SET_SORT_BY`)

export const selectInfluencer = createAction(`${prefix}SELECT_INFLUENCER`)
export const resetInfluencerSelection = createAction(`${prefix}RESET_INFLUENCER_SELECTION`)

export const openAddInfluencerToContactsDialog = createAction(`${prefix}OPEN_ADD_INFLUENCER_TO_CONTACTS_DIALOG`)
export const closeAddInfluencerToContactsDialog = createAction(`${prefix}CLOSE_ADD_INFLUENCER_TO_CONTACTS_DIALOG`)
export const addInfluencerToContactsStart = createAction(`${prefix}ADD_INFLUENCER_TO_CONTACTS_START`)
export const addInfluencerToContactsSuccess = createAction(`${prefix}ADD_INFLUENCER_TO_CONTACTS_SUCCESS`)
export const addInfluencerToContactsError = createAction(`${prefix}ADD_INFLUENCER_TO_CONTACTS_ERROR`)
export const updateContactData = createAction(`${prefix}UPDATE_CONTACT_DATA`)
export const setDistributionListCreateDialogOpen = createAction(`${prefix}SET_DISTRIBUTION_LIST_CREATE_DIALOG_OPEN`)
export const setImportContactsForDistributionLists = createAction(`${prefix}SET_IMPORT_CONTACTS_FOR_DISTRIBUTION_LISTS`)
export const searchContactsStart = createAction(`${prefix}SEARCH_CONTACTS_START`)
export const searchContactsSuccess = createAction(`${prefix}SEARCH_CONTACTS_SUCCESS`)
export const searchContactsError = createAction(`${prefix}SEARCH_CONTACTS_ERROR`)

prefix = `${prefix}/DETAIL/`

export const showInfluencerDetails = createAction(`${prefix}SHOW_INFLUENCER_DETAILS`)
export const hideInfluencerDetails = createAction(`${prefix}HIDE_INFLUENCER_DETAILS`)

export const fetchInfluencerRequestError = createAction(`${prefix}FETCH_INFLUENCER_REQUEST_ERROR`)
export const fetchInfluencerRequestStart = createAction(`${prefix}FETCH_INFLUENCER_REQUEST_START`)
export const fetchInfluencerRequestSuccess = createAction(`${prefix}FETCH_INFLUENCER_REQUEST_SUCCESS`)

export const selectChannel = createAction(`${prefix}SELECT_CHANNEL`)
export const highlightReachChart = createAction(`${prefix}HIGHLIGHT_REACH_CHART`)
