import { connect } from 'react-redux'

import ContentDeskSettings from 'components/administration/content_desk_settings'

import {
  updateCDeskConfigRequestStart as onUpdate,
  updateCDeskConfig as onChange,
  refreshContentDeskPlanUsageAndLimits as onConfigRefresh
} from 'actions/config'

import {
  getCDeskConfig,
  getCDeskUpdateRequestRunning,
  getCapabilities,
  getCDeskUploadedMediaFilesTotalSize
} from 'selectors'

const mapStateToProps = state => ({
  capabilities: getCapabilities(state),
  config: getCDeskConfig(state),
  requestRunning: getCDeskUpdateRequestRunning(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state)
})

export default connect(
  mapStateToProps,
  {
    onChange,
    onUpdate,
    onConfigRefresh
  }
)(ContentDeskSettings)
