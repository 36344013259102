import { connect } from 'react-redux'

import Toolbar from 'components/content_desk_new/contents/content_edit_dialog/toolbar'
import {
  saveContentStart as onSave,
  openTestMailDialog as onTestMail,
  openScheduleDialog,
  setContentFormData,
  setContentIsPublishable,
  changeContentEditTabView
} from 'actions/content_desk'

import {
  getCDeskContentIsLoading,
  getCDeskContentFormData,
  getCDeskContentIsPublishable,
  getCDeskContentEditTabView
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  isLoading: getCDeskContentIsLoading(state),
  isPublishable: getCDeskContentIsPublishable(state),
  view: getCDeskContentEditTabView(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    onSave,
    onTestMail,
    openScheduleDialog,
    setContentIsPublishable,
    onNavigationChange: changeContentEditTabView
  }
)(Toolbar)
