import React from 'react'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { getChannelColor } from 'utils/channel'

const useStyles = makeStyles()({
  bar: {
    display: 'flex',
    width: '100%'
  },
  barContent: {
    width: '100%',
    height: 20
  },
  barText: {
    fontSize: 14,
    marginTop: 10
  },
  barTextNumber: {
    fontWeight: 500
  }
})

export default function ChannelBar({ mediaReview }) {
  const { classes } = useStyles()
  const channels = mediaReview.getIn(['stats', 'channels'])
  const totalCount = channels.reduce((acc, channel) => acc + channel.get('count'), 0)

  const channelPercentages = channels.reduce(
    (acc, value) => acc.set(value.get('id'), fromJS({
      percent: (value.get('count', 0) / totalCount) * 100,
      data: value
    })),
    fromJS({})
  )

  return (
    <div className={classes.bar}>
      {channelPercentages.keySeq().map(key => {
        const channel = channelPercentages.getIn([key, 'data'])
        const percent = channelPercentages.getIn([key, 'percent'])

        return (
          <div
            style={{ width: `${percent}%` }}
            key={key}
            title={`${channel.get('name')} (${channel.get('count')})`}
          >
            <div
              className={classes.barContent}
              style={{
                backgroundColor: getChannelColor(key)
              }}
            />

            {percent > 2 && channel.get('name') && (
              <div className={classes.barText}>
                <span className={classes.barTextNumber}>
                  {channel.get('count')}&nbsp;
                </span>
                {percent > 10 && channel.get('name')}
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

ChannelBar.propTypes = {
  mediaReview: PropTypes.instanceOf(Map).isRequired
}
