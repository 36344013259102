import { connect } from 'react-redux'

import Sortbar from 'components/media_reviews/media_review_detail/sortbar'
import { sortBy } from 'actions/media_reviews'
import { getI18n } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state)
})

export default connect(
  mapStateToProps,
  {
    onSortBy: sortBy
  }
)(Sortbar)
