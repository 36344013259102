import { connect } from 'react-redux'

import MediaProfilesItem from 'components/themax/media_profiles/media_profiles_item/'
import { getHasThemaxPaidVersion } from 'selectors'

import {
  openDetailedViewDialog,
  setSelectedEditorialPlan,
  themaxFetchRelatedEditorialPlansStart as fetchRelatedEditorialPlans
} from 'actions/themax'

const mapStateToProps = (state, ownProps) => ({
  mediaProfile: ownProps.mediaProfile,
  hasPaidVersion: getHasThemaxPaidVersion(state)
})

export default connect(
  mapStateToProps,
  {
    openDetailedViewDialog,
    setSelectedEditorialPlan,
    fetchRelatedEditorialPlans
  }
)(MediaProfilesItem)
