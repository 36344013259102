import { connect } from 'react-redux'

import ScoreQueryDialog from 'components/darknet/query_manager/score_query_dialog'
import { getDarknetScoreQueryFormData } from 'selectors'

import {
  saveScoreQueryRequestStart as onSave,
  updateScoreQueryFormData as onUpdate
} from 'actions/darknet'

const mapStateToProps = state => ({
  data: getDarknetScoreQueryFormData(state)
})

export default connect(
  mapStateToProps,
  {
    onSave,
    onUpdate
  }
)(ScoreQueryDialog)
