import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded'
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded'
import ImageRoundedIcon from '@mui/icons-material/ImageRounded'
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded'
import ReportOffRoundedIcon from '@mui/icons-material/ReportOffRounded'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  contentContainer: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  closeButton: {
    color: theme.palette.common.grey
  },
  title: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  content: {
    padding: 40
  },
  itemTitle: {
    fontWeight: 500
  }
}))

const OtsGuidelinesDialog = ({
  open,
  onClose
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Box className={classes.title}>
            <Typography
              variant="h6"
            >
              {i18n.get('press_release_guidelines')}
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Box>
            {i18n.get('ots_guidelines_note')}
          </Box>
          <Box className={classes.guidelinesItem}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <LightbulbRoundedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('general_requirements')}
                  secondary={i18n.get('ots_general_requirements')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ContactEmergencyRoundedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('contact_information')}
                  secondary={i18n.get('ots_contact_information')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ImageRoundedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('your_image_file')}
                  secondary={i18n.get('ots_image_file')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Icon
                    color="primary"
                    className="fas fa-book-section"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('legal_compliance')}
                  secondary={i18n.get('ots_legal_compliance')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <HealthAndSafetyRoundedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('pharmaceutical_and_healthcare_poducts')}
                  secondary={i18n.get('ots_pharmaceutical_and_healthcare_poducts')}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ReportOffRoundedIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={i18n.get('content_restrictions')}
                  secondary={i18n.get('ots_content_restrictions')}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  )
}

OtsGuidelinesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default OtsGuidelinesDialog
