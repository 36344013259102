import Cookies from 'js-cookie'
import moment from 'moment-timezone'
import { CookieNames } from 'static/constants'

import store from '../store'
import { getCookieOption } from '../selectors'

export function saveCookie(name, value, expires, allowSavingWithoutPermission = false) {
  if (allowSavingWithoutPermission || getCookieOption(store.getState())) {
    Cookies.set(name, value, { path: '/', expires: expires || moment().add(365, 'days').toDate() })
  }
}

export function deleteCookie(name) {
  Cookies.remove(name, { path: '/' })
}

export function readCookie(name) {
  return Cookies.get(name)
}

export function deleteAllCookies() {
  deleteCookie(CookieNames.NEWSRADAR)
  deleteCookie(CookieNames.SESSION)
  deleteCookie(CookieNames.ACCESS_TOKEN)
}

export function deleteSessionCookie() {
  deleteCookie(CookieNames.SESSION)
  deleteCookie(CookieNames.ACCESS_TOKEN)
}
