import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { IconButton } from 'containers/themed'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'

import NotificationsButton from 'containers/notifications/NotificationsButton'

const useStyles = makeStyles()({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionIcon: {
    color: '#ffffff'
  }
})

const Actions = ({ openCalendarDialog, isSubmodule }) => {
  const i18n = useI18n()
  const device = useDevice()
  const { classes } = useStyles()
  const actions = []

  if (!device.get('mobile')) {
    if (isSubmodule) {
      actions.push(
        <IconButton
          size="small"
          title={i18n.get('calendar')}
          className={classes.actionIcon}
          onClick={() => openCalendarDialog()}
        >
          <CalendarMonthIcon />
        </IconButton>
      )
    }
  }

  actions.push(
    <NotificationsButton />
  )

  return (
    <StaggeredList>
      <div className={classes.actionsWrapper}>
        {actions.map((action, index) => (
          <StaggeredListItem
            key={index}
            delay={index * 50}
          >
            {action}
          </StaggeredListItem>
        ))}
      </div>
    </StaggeredList>
  )
}

Actions.propTypes = {
  isSubmodule: PropTypes.bool.isRequired,

  openCalendarDialog: PropTypes.func.isRequired
}

export default Actions
