import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, Map, List } from 'immutable'
import useI18n from 'hooks/useI18n'

import {
  TextField,
  Grid,
  CircularProgress,
  Autocomplete as MuiAutocomplete,
  InputAdornment,
  Tooltip
} from '@mui/material'

import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { green, red } from '@mui/material/colors'

import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@mui/icons-material/Info'

import { Autocomplete, IconButton, Input } from 'containers/themed'

import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()({
  titleGridContainer: {
    marginBottom: '18px'
  },
  campaignAutocomplete: {
    width: '93%'
  },
  campaignAutocompleteContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh'
  },
  bottomActionsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0em 2em 0em 2em',
    marginTop: '2em',
    marginBottom: '0.5em'
  },
  picker: {
    '& > div': {
      width: '100%'
    }
  },
  subjectLengthInfo: {
    marginTop: '0.5em',
    textAlign: 'right'
  },
  validSubjectLength: {
    color: green[500]
  },
  invalidSubjectLength: {
    fontWeight: 'bold',
    color: red[500]
  }
})

export const GridBreak = styled('div')({ width: '100%' })

const mapDomainsToOptions = domains => domains.map(d => ({
  value: d.get('name'),
  label: '@'.concat(d.get('name'))
})).toJS()

export default function ContentForm({
  campaignOptionsLoading,
  data,
  config,
  defaultDomains,
  campaigns,
  types,
  emailEditorLoading,
  onChange,
  openCampaignForm,
  resetSocialMediaPostsAndUploads
}) {
  if (!data.get('ccdCampaign')) {
    return null
  }

  const { classes } = useStyles()
  const i18n = useI18n()

  const title = data.get('title')
  const preHeader = data.get('preHeader')

  const [campaign, setCampaign] = useState(data.get('ccdCampaign').toJS())
  const [type, setType] = useState(data.get('type', types.first()).toJS())
  const [subject, setSubject] = useState(data.get('subject') || '')
  const [senderEmailLocal, setSenderEmailLocal] = useState((data.get('senderMail') || '').split('@')[0])
  const [senderEmailDomain, setSenderEmailDomain] = useState((data.get('senderMail') || '').split('@')[1])
  const [senderMail, setSenderMail] = useState(data.get('senderMail'))
  const [senderName, setSenderName] = useState(data.get('senderName'))

  const typeIsMail = type.value === 1
  const activeCampaigns = campaigns.filter(c => c.get('active') === true)

  const typeOptions = mapToOptions(types)
  const campaignOptions = mapToOptions(activeCampaigns)

  const domainsOptions = defaultDomains.toJS()
    .map(o => ({ value: o.domain, label: '@'.concat(o.domain) }))
    .concat(mapDomainsToOptions(config.get('ccdAllowedDomains') || List()))

  useEffect(() => {
    onChange({
      subject,
      senderMail,
      senderName,
      title,
      ccdCampaign: fromJS(campaign),
      type: fromJS(type),
      preHeader
    })
  }, [campaign, type, subject, senderMail, senderName])

  const handleSubjectChange = e => setSubject(e.target.value)
  const handlePreHeaderChange = e => onChange({ preHeader: e.target.value })
  const handleNameChange = e => setSenderName(e.target.value)
  const handleTitleChange = e => onChange({ title: e.target.value })
  const handleCampaignChange = value => setCampaign(value)
  const handleTypeChange = value => {
    resetSocialMediaPostsAndUploads()
    setType(value)
  }

  const handleMailChange = value => {
    setSenderEmailLocal(value)
    setSenderMail(value.concat('@').concat(senderEmailDomain))
  }

  const handleSenderEmailDomainChange = value => {
    setSenderEmailDomain(value)
    setSenderMail(senderEmailLocal.concat('@').concat(value))
  }

  const maxSubjectLength = 255
  const subjectLength = subject.length
  let subjectLengthClasses = `${classes.subjectLengthInfo} ${classes.validSubjectLength}`

  if (subjectLength > maxSubjectLength) {
    subjectLengthClasses = `${classes.subjectLengthInfo} ${classes.invalidSubjectLength}`
  }

  const renderSubjectGridItem = () => {
    if (!typeIsMail) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        md={6}
      >
        <TextField
          fullWidth
          label={i18n.get('email_subject')}
          name="subject"
          value={subject || ''}
          onChange={handleSubjectChange}
          required
        />
        <div className={subjectLengthClasses}>
          {subjectLength}/{maxSubjectLength}
        </div>
      </Grid>
    )
  }

  const renderPreHeaderGridItem = () => {
    if (!typeIsMail) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        md={6}
      >
        <TextField
          fullWidth
          label={i18n.get('email_pre_header')}
          name="preHeader"
          value={preHeader || ''}
          onChange={handlePreHeaderChange}
          disabled={emailEditorLoading}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {emailEditorLoading ? (
                  <div className={classes.circularLoader}>
                    <CircularProgress
                      variant="indeterminate"
                      size={20}
                    />
                  </div>
                ) : (
                  <Tooltip
                    title={i18n.get('email_pre_header_info')}
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 10]
                          }
                        }
                      ]
                    }}
                  >
                    <InfoIcon />
                  </Tooltip>
                )}
              </InputAdornment>
            )
          }}
        />
      </Grid>
    )
  }

  const renderMailGridItem = () => {
    if (!typeIsMail) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        md={6}
      >
        <Input
          fullWidth
          type="text"
          value={senderEmailLocal}
          label={i18n.get('sender_email')}
          onChange={event => handleMailChange(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ width: '100%' }}
              >
                <MuiAutocomplete
                  disablePortal
                  disableClearable
                  size="small"
                  options={domainsOptions}
                  value={domainsOptions.find(option => option.value === senderEmailDomain) || domainsOptions[0]}
                  onChange={(_event, option) => handleSenderEmailDomainChange(option.value)}
                  sx={{ width: '100%' }}
                  renderInput={params => (
                    <TextField {...params} />
                  )}
                />
                <Tooltip
                  title={i18n.get('ccd_config_sender_email_tooltip')}
                  placement="top-end"
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 10]
                        }
                      }
                    ]
                  }}
                >
                  <InfoIcon sx={{ marginLeft: '10px' }} />
                </Tooltip>
              </InputAdornment>
            )
          }}
        />
      </Grid>
    )
  }

  const renderNameGridItem = () => {
    if (!typeIsMail) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        md={6}
      >
        <TextField
          fullWidth
          label={i18n.get('sender_name')}
          name="senderName"
          value={senderName || ''}
          onChange={handleNameChange}
        />
      </Grid>
    )
  }

  const renderTitleGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <TextField
        fullWidth
        required
        label={i18n.get('internal_title')}
        name="title"
        value={title}
        onChange={handleTitleChange}
      />
    </Grid>
  )

  const renderCampaignGridItem = () => (
    <Grid
      item
      xs={12}
      lg={6}
    >
      <div className={classes.campaignAutocompleteContainer}>
        <Autocomplete
          className={classes.campaignAutocomplete}
          onChange={value => handleCampaignChange(value)}
          options={campaignOptions}
          value={campaign}
          placeholder=""
          label={i18n.get('choose_campaign')}
        />
        <IconButton
          color="secondary"
          size="large"
          onClick={() => openCampaignForm()}
          title={i18n.get('new_campaign')}
        >
          <AddIcon />
        </IconButton>
      </div>
    </Grid>
  )

  const renderTypeGridItem = () => (
    <Grid
      item
      xs={12}
      md={6}
    >
      <Autocomplete
        required
        isClearable
        onChange={value => handleTypeChange(value)}
        options={typeOptions}
        value={type}
        placeholder=""
        label={i18n.get('channel')}
      />
    </Grid>
  )

  if (campaignOptionsLoading) {
    return (
      <div className={classes.circularLoader}>
        <CircularProgress
          variant="indeterminate"
          size={70}
        />
      </div>
    )
  }

  return (
    <>
      <Grid
        container
        spacing={3}
        mb={4}
      >

        {renderCampaignGridItem()}
        {renderTypeGridItem()}
        {renderTitleGridItem()}
        <GridBreak />
        {renderNameGridItem()}
        {renderMailGridItem()}
        {renderSubjectGridItem()}
        {renderPreHeaderGridItem()}
      </Grid>
    </>
  )
}

ContentForm.propTypes = {
  campaignOptionsLoading: PropTypes.bool.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  defaultDomains: PropTypes.instanceOf(List).isRequired,
  campaigns: PropTypes.instanceOf(List).isRequired,
  types: PropTypes.instanceOf(List).isRequired,
  emailEditorLoading: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired,
  resetSocialMediaPostsAndUploads: PropTypes.func.isRequired
}
