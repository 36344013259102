import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment'
import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogContent, DialogTitle, DialogActions, TextField, Grid } from '@mui/material'
import { Button, InlineDatePicker, Select } from 'containers/themed'

const useStyles = makeStyles()({
  dialogActions: {
    padding: '25px'
  }
})

const ClosedCampaignDialog = ({
  open,
  campaign,
  requestRunning,
  updateCampaignFormData,
  closeClosedCampaignDialog,
  resetCampaignForm,
  saveCampaign
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [isStatusDisabled, setIsStatusDisabled] = useState(false)
  const [status, setStatus] = useState(campaign.get('active'))
  const [endDate, setEndDate] = useState(campaign.get('endDate'))

  const statusOptions = [
    { value: true, label: i18n.get('active') },
    { value: false, label: i18n.get('closed') }
  ]

  useEffect(() => {
    if (endDate && moment(endDate).isBefore(moment())) {
      setIsStatusDisabled(true)
    } else {
      setIsStatusDisabled(false)
    }
  }, [endDate])

  useEffect(() => {
    if (open) {
      setStatus(campaign.get('active'))
      setEndDate(campaign.get('endDate'))
    }
  }, [open, campaign])

  const handleDateChange = value => {
    if (moment(value).isBefore(moment())) {
      setStatus(false)
      updateCampaignFormData({ active: false })
      setIsStatusDisabled(true)
    } else if (moment(value).isAfter(moment())) {
      setStatus(true)
      updateCampaignFormData({ active: true })
      setIsStatusDisabled(false)
    }

    setEndDate(value)
    updateCampaignFormData({ endDate: value })
  }

  const handleStatusChange = option => {
    setStatus(option.value)
    updateCampaignFormData({ active: option.value })
  }

  const handleClose = () => {
    closeClosedCampaignDialog()
    resetCampaignForm()
  }

  const handleSave = () => {
    if ((!campaign.get('endDate'))) {
      return enqueueSnackbar(
        `${i18n.get('end_date')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    saveCampaign()
    closeSnackbar()

    return resetCampaignForm()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {i18n.get('closed_campaign_selected')}
      </DialogTitle>
      <DialogContent>
        {i18n.get('closed_campaign_selected_info')}
        <Grid
          container
          spacing={2}
          mt={2}
        >
          <Grid
            item
            xs={6}
          >
            <Select
              size="small"
              disabled={isStatusDisabled || requestRunning}
              variant="outlined"
              onChange={handleStatusChange}
              options={statusOptions}
              value={(statusOptions.find(o => o.value === status)).value}
              label={i18n.get('status')}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <InlineDatePicker
              disabled={requestRunning}
              inputProps={{ size: 'small' }}
              label={i18n.get('end_date')}
              value={moment(endDate)}
              onChange={handleDateChange}
              minDate={moment(campaign.get('startDate'))}
              renderInput={params => (
                <TextField
                  {...params}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          onClick={handleClose}
          disabled={requestRunning}
        >
          {i18n.get('close')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          saving={requestRunning}
        >
          {i18n.get('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ClosedCampaignDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  campaign: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired,

  updateCampaignFormData: PropTypes.func.isRequired,
  closeClosedCampaignDialog: PropTypes.func.isRequired,
  resetCampaignForm: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired
}

export default ClosedCampaignDialog
