import { connect } from 'react-redux'

import TagSortbar from 'components/contact_management/tags_dialog/tag_sortbar/TagSortbar'
import { tagsSortBy } from 'actions/contact_management'

const mapStateToProps = () => ({
})

export default connect(
  mapStateToProps,
  {
    onSortBy: tagsSortBy
  }
)(TagSortbar)
