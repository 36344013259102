import { connect } from 'react-redux'

import LinkedInEditor from 'components/content_desk/content_edit_dialog/content_editor/linked_in_editor'
import {
  setContentFormData,
  uploadLinkedInMediaFileStart,
  toggleEditorAiMode,
  setAiSelectedText,
  linkedInCompanySearchStart,
  buildSocialMediaLinkStart
} from 'actions/content_desk'

import {
  toggleUnsplashComponent as toggleUnsplash
} from 'actions/unsplash'

import { navigate } from 'actions/navigation'

import {
  getCDeskContentFormData,
  getStaticLinkedInAnalyticsOrganizations,
  getLinkedInPermissions,
  getLinkedInProfile,
  getCDeskMediaFileUploading,
  getCDeskEditorAiMode,
  getShowUnsplashComponent,
  getCDeskUploadedMediaFilesTotalSize,
  getSocialMediaLinkLoading,
  getSocialMediaLinkBuildFailed,
  getCDeskLinkedInCompanySearchResults
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  companySearchResults: getCDeskLinkedInCompanySearchResults(state),
  organizations: getStaticLinkedInAnalyticsOrganizations(state),
  aiMode: getCDeskEditorAiMode(state),
  permissions: getLinkedInPermissions(state) || '',
  profile: getLinkedInProfile(state),
  mediaFileUploading: getCDeskMediaFileUploading(state),
  unsplashIsVisible: getShowUnsplashComponent(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state),
  linkLoading: getSocialMediaLinkLoading(state),
  socialMediaLinkBuildFailed: getSocialMediaLinkBuildFailed(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    onUpload: uploadLinkedInMediaFileStart,
    onSelect: setAiSelectedText,
    toggleEditorAiMode,
    toggleUnsplash,
    navigate,
    companySearchStart: linkedInCompanySearchStart,
    buildSocialMediaLink: buildSocialMediaLinkStart
  }
)(LinkedInEditor)
