import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/mailing_lists'
import * as AppActions from 'actions/app'

export const initialState = fromJS({})

const addMailingListsToState = (state, mailingLists) => {
  const newState = fromJS({}).withMutations(ctx => {
    mailingLists.forEach(
      mailingList => ctx.set(mailingList.get('id').toString(), mailingList)
    )
  })

  return newState
}

export default handleActions({
  [Actions.setMailingLists]: (state, { payload: mailingLists }) => addMailingListsToState(state, fromJS(mailingLists)),
  [Actions.saveMailingListSuccess]: (state, { payload: mailingList }) => state.set(mailingList.id.toString(), fromJS(mailingList)),
  [Actions.deleteMailingListSuccess]: (state, { payload: mailingListId }) => state.delete(mailingListId.toString()),
  [AppActions.resetState]: () => initialState
}, initialState)
