/* eslint-disable max-len, react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'containers/themed'
import { makeStyles } from 'tss-react/mui'
import { red } from '@mui/material/colors'

const useStyles = makeStyles()({
  helpText: {
    '& > h5': {
      marginBottom: 10
    },
    '& > pre': {
      fontSize: '90%',
      fontFamily: '"Courier 10 Pitch", Courier, monospace',
      whiteSpace: 'pre-wrap',
      height: '1%',
      width: 'auto',
      display: 'block',
      clear: 'both',
      color: '#555555',
      padding: 10,
      margin: '10px 20px',
      background: '#f4f4f4',
      border: 'solid 1px #e1e1e1',

      '& > code': {
        fontSize: '90%',
        height: '1%',
        fontFamily:
          'Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace',
        display: 'inline',
        color: '#555555',
        padding: 10,
        background: '#f4f4f4'
      }
    },
    '& > operator': {
      color: red[600]
    }
  }
})

const headlineLabel = resultType => {
  if (resultType === 'darknet') {
    return 'title'
  }

  return 'headline'
}

const entryLabel = (resultType, languageId) => {
  if (resultType === 'darknet') {
    if (languageId === 1) {
      return 'Einträge'
    }

    return 'entries'
  }

  if (languageId === 1) {
    return 'News'
  }

  return 'news'
}
export default function HelpDialog({
  i18n,
  showHelpDialog,
  languageId,
  resultType,
  toggleHelpDialog
}) {
  const { classes } = useStyles()

  const renderHelpText = () => {
    if (languageId === 1) {
      return (
        <div className={classes.helpText}>
          <div>Die Liste zeigt Ihnen, welche Operatoren Sie in der Suche verwenden können. Groß- und Kleinschreibung hat keinen Einfluß auf die Suchergebnisse. Kombinationen sind möglich. Mit Klammern () können außerdem Formeln gebildet werden.</div>
          <br />
          <h5>Verknüpfungen:</h5>
          <pre><code>Wald AND Baum</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} in denen beide Begriffe vorkommen. Entspricht der Suche <i>+Wald +Baum</i></div>
          <pre><code>Wald OR Baum</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} in denen mindestens einer der Begriffe vorkommen. Entspricht der Suche <i>Wald Baum</i></div>
          <pre><code>Wald NOT Baum</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} in denen Wald, aber nicht Baum vorkommt. Entspricht der Suche <i>+Wald -Baum</i></div>
          <br />
          <h5>Phrase:</h5>
          <pre><code>"Wald und Baum"</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} mit der Phrase in entsprechender Reiheinfolge der Begriffe.</div>
          <br />
          <h5>Wildcards:</h5>
          <pre><code>Wald*</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} mit Wörtern wie Waldmeister, Waldschrat etc.</div>
          <pre><code>W??d</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} mit Wörtern wie Wald, wird, wild etc.</div>
          <br />
          <h5>Nearby:</h5>
          <pre><code>"Wald Baum"~3</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} in denen die Worte Wald und Baum maximal drei Worte auseinander stehen. Die Zahl ist beliebig wählbar.</div>
          <br />
          <h5>Suche in der Überschrift:</h5>
          <pre><code>{headlineLabel(resultType)}:wald</code></pre>
          <div>Suchergebnisse: {entryLabel(resultType, languageId)} in deren Überschrift Wald vorkommt.</div>
        </div>
      )
    }

    if (languageId === 5) {
      return (
        <div className={classes.helpText}>
          <div>При осуществлении поиска можно использовать следующие операторы. На результаты поиска не влияют заглавные или строчные буквы. Поисковые запросы также могут быть сформированы с помощью скобок ().</div>
          <br />
          <h5>Операторы поиска:</h5>
          <pre><code>лес AND дерево</code></pre>
          <div>Результаты поиска: статьи, в которых встречаются оба ключевых слова. Также соответствует поиску <i>+лес +дерево</i></div>
          <pre><code>лес OR дерево</code></pre>
          <div>Результаты поиска: статьи, в которых встречается хотя бы одно ключевое слово. Также соответствует поиску <i>лес дерево</i></div>
          <pre><code>лес NOT дерево</code></pre>
          <div>Результаты поиска: статьи, в которых встречается ключевое слово лес, но не дерево. Также соответствует поиску <i>+лес -дерево</i></div>
          <br />
          <h5>Фразы</h5>
          <pre><code>"сосновый лес"</code></pre>
          <div>Результаты поиска: статьи, в которых слова, поставленные в кавычки, идут подряд и согласно указанному порядку.</div>
          <br />
          <h5>Символы:</h5>
          <pre><code>дерев*</code></pre>
          <div>Результаты поиска: статьи, в которых встречаются любые ключевые слова, начинающиеся на дерев-, например, деревья, деревянный.</div>
          <pre><code>д??в*</code></pre>
          <div>Результаты поиска: статьи, в которых встречается слова, начинающиеся и заканчивающиеся определенным образом.</div>
          <br />
          <h5>Рядом:</h5>
          <pre><code>"сосновый лес"~3</code></pre>
          <div>Результаты поиска: статьи, в которых слова сосновый лес разделены максимум 3 другими словами.</div>
        </div>
      )
    }

    return (
      <div className={classes.helpText}>
        <div>This list shows the operators you can use for your query. The search results are not affected by upper and lower case letters. Combinations  are possible. Formulas can also be formed using brackets ().</div>
        <br />
        <h5>Connectors:</h5>
        <pre><code>forest AND tree</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} in which both terms occur. Corresponds to search <i>+Forest +Tree</i></div>
        <pre><code>forest OR tree</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} in which at least one of the terms occurs. Corresponds to the search <i>forest tree</i></div>
        <pre><code>forest NOT tree</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} in which forest but not tree occurs. Corresponds to search <i>+Forest -Tree</i></div>
        <br />
        <h5>Phrase:</h5>
        <pre><code>"forest and tree"</code></pre>
        <div>Search  results: {entryLabel(resultType, languageId)} with the phrase in the corresponding order of the terms.</div>
        <br />
        <h5>Wildcards:</h5>
        <pre><code>wood*</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} with words like woodstock, woodpecker etc.</div>
        <pre><code>w??d</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} with words like wood, wild, word etc.</div>
        <br />
        <h5>Nearby:</h5>
        <pre><code>"Forest tree"~3</code></pre>
        <div>Search results: {entryLabel(resultType, languageId)} in which the words forest and tree are separated by a maximum of three words. The limit is freely selectable.</div>
        <h5>Search in {headlineLabel(resultType)}:</h5>
        <pre><code>{headlineLabel(resultType)}:forest</code></pre>
        <div>Search result: {entryLabel(resultType, languageId)} whose headline contains forest.</div>
      </div>
    )
  }

  return (
    <Dialog
      open={showHelpDialog}
      onClose={toggleHelpDialog}
      title={i18n.get('help')}
    >
      <br />
      <br />
      {renderHelpText()}
    </Dialog>
  )
}

HelpDialog.propTypes = {
  languageId: PropTypes.number.isRequired,
  toggleHelpDialog: PropTypes.func.isRequired,
  resultType: PropTypes.string,
  showHelpDialog: PropTypes.bool.isRequired,

  i18n: PropTypes.object.isRequired
}

HelpDialog.defaultProps = {
  resultType: 'default'
}
