import React from 'react'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Tooltip
} from '@mui/material'
import { grey } from '@mui/material/colors'
import LockIcon from '@mui/icons-material/Lock'

const useStyles = makeStyles()({
  lockIcon: {
    fontSize: '16px',
    color: grey[500],
    marginLeft: '5px'
  }
})

const LockedContentTooltip = () => {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Tooltip
      title={i18n.get('themax_paid_version_required')}
      arrow
    >
      <LockIcon
        className={classes.lockIcon}
      />
    </Tooltip>
  )
}

export default LockedContentTooltip
