import React, { useState } from 'react'
import PropTypes from 'prop-types'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from 'tss-react/mui'
import { InputAdornment, IconButton, LinearProgress } from '@mui/material'
import { red, green } from '@mui/material/colors'

import { Input } from 'containers/themed'
import TransitionComponent from 'components/transition_component'

const useStyles = makeStyles()({
  error: {
    color: red[500]
  },
  success: {
    color: green[500]
  },
  passwordPart: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10
  },
  container: {
    position: 'relative'
  },
  'container input': {
    height: 32
  }
})

export default function PasswordInput(props) {
  const { classes } = useStyles()
  const [state, setState] = useState({
    type: 'password',
    strength: 0,
    color: 'secondary',
    label: props.i18n.get('password_weak'),
    hasNumber: false,
    hasChar: false,
    hasCapitalizedChar: false,
    has8Chars: false
  })

  const handleEyeClick = () => {
    const { type } = state
    setState(prevState => ({ ...prevState, type: (type === 'password' ? 'text' : 'password') }))
  }

  const handleChange = value => {
    const { i18n, onChange } = props

    const newState = {
      strength: 0,
      hasNumber: false,
      hasChar: false,
      hasCapitalizedChar: false,
      has8Chars: false
    }

    if (value.match(/\d/)) {
      newState.strength += 25
      newState.hasNumber = true
    }

    if (value.match(/[a-z]/)) {
      newState.strength += 25
      newState.hasChar = true
    }

    if (value.match(/[A-Z]/)) {
      newState.strength += 25
      newState.hasCapitalizedChar = true
    }

    if (value.length >= 8) {
      newState.strength += 25
      newState.has8Chars = true
    }

    newState.label = i18n.get('password_very_strong')
    newState.color = 'primary'

    if (newState.strength <= 25) {
      newState.label = i18n.get('password_weak')
      newState.color = 'secondary'
    }

    if (newState.strength === 50) {
      newState.label = i18n.get('password_medium')
      newState.color = 'secondary'
    }

    if (newState.strength === 75) {
      newState.label = i18n.get('password_strong')
      newState.color = 'secondary'
    }

    setState(prevState => ({ ...prevState, ...newState }))

    onChange(value)
  }

  const passwordPartClass = flag => {
    if (flag) {
      return `${classes.passwordPart} ${classes.success}`
    }

    return `${classes.passwordPart} ${classes.error}`
  }

  const passwordPartIcon = flag => {
    if (flag) {
      return (
        <CheckIcon
          classes={{ root: classes.success }}
          value="check"
        />
      )
    }

    return (
      <CloseIcon
        classes={{ root: classes.error }}
        value="close"
      />
    )
  }

  const renderStrength = () => {
    const { i18n, value, validateStrength } = props
    const { label, color, strength, hasNumber, hasChar, hasCapitalizedChar, has8Chars } = state

    if (!value || validateStrength !== true) {
      return null
    }

    return (
      <>
        <br />
        {i18n.get('password_strength')}: {label}
        <LinearProgress
          color={color}
          variant="determinate"
          value={strength}
        />
        <br /><br />
        {i18n.get('password_contains')}:
        <ul>
          <li className={passwordPartClass(hasNumber)}>
            {passwordPartIcon(hasNumber)}
            <span>{i18n.get('password_has_number')}</span>
          </li>
          <li className={passwordPartClass(hasChar)}>
            {passwordPartIcon(hasChar)}
            <span>{i18n.get('password_has_char')}</span>
          </li>
          <li className={passwordPartClass(hasCapitalizedChar)}>
            {passwordPartIcon(hasCapitalizedChar)}
            <span>{i18n.get('password_has_capitalized_char')}</span>
          </li>
          <li className={passwordPartClass(has8Chars)}>
            {passwordPartIcon(has8Chars)}
            <span>{i18n.get('password_has_8_chars')}</span>
          </li>
        </ul>
      </>
    )
  }

  const { type } = state
  const newProps = { ...props }
  delete newProps.i18n
  delete newProps.onChange
  delete newProps.validateStrength
  delete newProps.dispatch

  return (
    <div className={classes.container}>
      <Input
        type={type}
        onChange={event => handleChange(event.target.value)}
        onPaste={() => null}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleEyeClick}
                size="large"
              >
                {type === 'text' ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          )
        }}
        {...newProps}
      />

      <TransitionComponent type="fade">
        {renderStrength()}
      </TransitionComponent>
    </div>
  )
}

PasswordInput.propTypes = {
  i18n: PropTypes.object.isRequired,
  value: PropTypes.string,
  validateStrength: PropTypes.bool,

  onChange: PropTypes.func.isRequired
}
