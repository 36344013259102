import { connect } from 'react-redux'

import NrxReactions from 'components/nrx_reactions'
import { getStaticReactionTypes, getUser } from 'selectors'

const mapStateToProps = state => ({
  reactionTypes: getStaticReactionTypes(state),
  user: getUser(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(NrxReactions)
