import { connect } from 'react-redux'
import { getDefaultDomains, getDefaultDomainByTld } from 'utils/mailgun'
import { getTopLevelDomain } from 'utils/regex'

import AllowedDomains from 'components/administration/content_desk_settings/allowed_domains'

import {
  createAllowedDomainStart as onCreate,
  createDefaultAllowedDomainsStart as onCreateDefault,
  updateAllowedDomainStart as onUpdate,
  deleteAllowedDomainStart as onDelete,
  searchAllowedDomainsStart as onSearch,
  refreshStatusAllowedDomainStart as onRefresh,
  setAllowedDomainsQuickSearchQuery as onQueryChange,
  setNewAllowedDomain as onNewDomainChange,
  setNewAllowedDomainRegion as onNewDomainRegionChange,
  setNewAllowedDomainSenderDomain as onNewSenderDomainChange,
  toggleAllowedDomainsDeleteMode as toggleDeleteMode,
  setAllowedDomainsPage as setPage,
  setAllowedDomainFormData as setFormData,
  toggleAllowedDomainsInfoDialog as openInfoDialog,
  openAllowedDomainsStatusDialog as openStatusDialog,
  fetchAllowedDomainStatusStart as fetchStatus
} from 'actions/administration'

import {

  updateCDeskConfigRequestStart as onConfigUpdate,
  updateCDeskConfig as onConfigChange
} from 'actions/config'

import {
  getCDeskConfig,
  getAllowedDomains,
  getAllowedDomainsPage,
  getAllowedDomainsRequestRunning,
  getAllowedDomainsQuickSearchQuery,
  getAllowedDomainsNewAllowedDomain,
  getAllowedDomainsNewAllowedDomainRegion,
  getAllowedDomainsNewAllowedSenderDomain,
  getAllowedDomainsDeleteMode,
  getAllowedDomainFormData,
  getUser,
  getDefaultAllowedDomainIds
} from 'selectors'

const getDefaultDomain = user => {
  const tld = getTopLevelDomain(user.get('email'))

  return getDefaultDomainByTld(tld)
}

const mapStateToProps = state => ({
  config: getCDeskConfig(state),
  domains: getAllowedDomains(state).get('items'),
  defaultDomains: getDefaultDomains(),
  formData: getAllowedDomainFormData(state),
  query: getAllowedDomainsQuickSearchQuery(state),
  newDomain: getAllowedDomainsNewAllowedDomain(state),
  newDomainRegion: getAllowedDomainsNewAllowedDomainRegion(state),
  newSenderDomain: getAllowedDomainsNewAllowedSenderDomain(state),
  deleteMode: getAllowedDomainsDeleteMode(state),
  total: getAllowedDomains(state).get('total'),
  page: getAllowedDomainsPage(state),
  loading: getAllowedDomainsRequestRunning(state),
  defaultRegionByTld: getDefaultDomain(getUser(state)).get('domainRegion'),
  defaultDomainIds: getDefaultAllowedDomainIds(state)
})

export default connect(
  mapStateToProps,
  {
    onCreate,
    onCreateDefault,
    onUpdate,
    onDelete,
    onSearch,
    onRefresh,
    onQueryChange,
    onNewDomainChange,
    onNewDomainRegionChange,
    onNewSenderDomainChange,
    toggleDeleteMode,
    setPage,
    setFormData,
    openInfoDialog,
    openStatusDialog,
    fetchStatus,
    onConfigUpdate,
    onConfigChange
  }
)(AllowedDomains)
