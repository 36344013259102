import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import camelcase from 'camelcase'

import { makeStyles } from 'tss-react/mui'

import {
  Box,
  CircularProgress
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import MobileContent from 'containers/content_desk_new/contents/mobile_contents/MobileContent'
import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    position: 'relative',
    width: '100%',
    padding: 10
  },
  loadingContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 30
  },
  sortIconActive: {
    fontSize: '0.8rem !important'
  },
  sortIconInActive: {
    fontSize: '0.8rem',
    opacity: 0.5
  }
})

const MobileContents = ({
  contents,
  loading,
  showCampaigns,
  sortBy,
  sortOrder,

  onSortBy,
  setPage
}) => {
  const i18n = useI18n()
  const device = useDevice()
  const { classes } = useStyles()
  const [sortedContents, setSortedContents] = useState(contents.get('items'))
  const [currentSortBy, setCurrentSortBy] = useState(sortBy)
  const [currentSortOrder, setCurrentSortOrder] = useState(sortOrder)

  useEffect(() => {
    setSortedContents(contents.get('items'))
  }, [contents])

  let handleSortingClick = field => {
    let newSortOrder = ''
    let newSortField = camelcase(field)

    if (field === currentSortBy) {
      if (currentSortOrder === '') {
        newSortOrder = 'asc'
      }

      if (currentSortOrder === 'asc') {
        newSortOrder = 'desc'
      }

      if (currentSortOrder === 'desc') {
        newSortOrder = ''
        newSortField = ''
      }
    } else {
      newSortOrder = 'asc'
    }

    if (newSortField === '') {
      setSortedContents(contents.get('items'))
      setCurrentSortBy('')
      setCurrentSortOrder('')

      return
    }

    const sorted = sortedContents.sort((a, b) => {
      if (a.get(newSortField) < b.get(newSortField)) {
        return newSortOrder === 'asc' ? -1 : 1
      }

      if (a.get(newSortField) > b.get(newSortField)) {
        return newSortOrder === 'asc' ? 1 : -1
      }

      return 0
    })

    setSortedContents(sorted)
    setCurrentSortBy(field)
    setCurrentSortOrder(newSortOrder)
  }

  if (setPage && onSortBy) {
    handleSortingClick = field => {
      let newSortOrder = ''
      let newSortField = field

      if (field === currentSortBy) {
        if (currentSortOrder === '') {
          newSortOrder = 'asc'
        }

        if (currentSortOrder === 'asc') {
          newSortOrder = 'desc'
        }

        if (currentSortOrder === 'desc') {
          newSortOrder = ''
          newSortField = ''
        }
      } else {
        newSortOrder = 'asc'
      }

      setPage({ key: 'contentPage', value: 1 })
      onSortBy({ type: 'contents', sortBy: newSortField, sortOrder: newSortOrder })
      setCurrentSortBy(field)
      setCurrentSortOrder(newSortOrder)
    }
  }

  const renderSortIcon = field => {
    if (sortedContents.size === 1) {
      return null
    }

    let title = i18n.get('sort')
    let icon = <SwapVertIcon />
    let iconClass = classes.sortIconInActive

    if (field === currentSortBy) {
      if (currentSortOrder === 'asc') {
        iconClass = classes.sortIconActive
        icon = <ArrowUpwardIcon />
        title = i18n.get('ascending')
      }

      if (currentSortOrder === 'desc') {
        iconClass = classes.sortIconActive
        icon = <ArrowDownwardIcon />
        title = i18n.get('descending')
      }
    }

    return (
      <IconButton
        title={title}
        icon={icon}
        size="small"
        className={iconClass}
        onClick={() => handleSortingClick(field)}
      />
    )
  }

  const renderContents = () => sortedContents.map((el, index) => (
    <MobileContent
      content={el}
      key={index}
      showCampaigns={showCampaigns}
    />
  ))

  return (
    <Box className={classes.container}>
      {loading && (
        <Box className={classes.loadingContainer}>
          <CircularProgress
            color="primary"
            size={26}
          />
        </Box>
      )}
      <Box className={classes.header}>
        <Box>
          {i18n.get('name')} {renderSortIcon('title')}
        </Box>
        {device.get('sm') && (
          <Box>
            {i18n.get('sending_status')} {renderSortIcon('scheduled_for')}
          </Box>
        )}
      </Box>
      {renderContents()}
    </Box>
  )
}

MobileContents.defaultProps = {
  showCampaigns: true,
  loading: false,
  sortBy: null,
  sortOrder: 'asc'
}

MobileContents.propTypes = {
  contents: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool,
  showCampaigns: PropTypes.bool,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,

  onSortBy: PropTypes.func,
  setPage: PropTypes.func
}

export default MobileContents
