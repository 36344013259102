import { createAction } from 'redux-actions'

const defaultPrefix = 'SAVED_SEARCHES/'
let prefix = defaultPrefix

export const setSavedSearches = createAction(`${prefix}SET_SAVED_SEARCHES`)
export const updateSelectedSavedSearch = createAction(`${prefix}UPDATE_SELECTED_SAVED_SEARCH`)

export const saveSavedSearchStart = createAction(`${prefix}SAVE_SAVED_SEARCH_START`)
export const saveSavedSearchSuccess = createAction(`${prefix}SAVE_SAVED_SEARCH_SUCCESS`)
export const saveSavedSearchError = createAction(`${prefix}SAVE_SAVED_SEARCH_ERROR`)

export const checkAffectedDashboards = createAction(`${prefix}CHECK_AFFECTED_DASHBOARDS`)
export const setAffectedDashboards = createAction(`${prefix}SET_AFFECTED_DASHBOARDS`)
export const showAffectedDashboardsDialog = createAction(`${prefix}SHOW_AFFECTED_DASHBOARDS_DIALOG`)
export const hideAffectedDashboardsDialog = createAction(`${prefix}HIDE_AFFECTED_DASHBOARDS_DIALOG`)

export const deleteSavedSearchStart = createAction(`${prefix}DELETE_SAVED_SEARCH_START`)
export const deleteSavedSearchSuccess = createAction(`${prefix}DELETE_SAVED_SEARCH_SUCCESS`)
export const deleteSavedSearchError = createAction(`${prefix}DELETE_SAVED_SEARCH_ERROR`)

export const resolveSavedSearchStart = createAction(`${prefix}RESOLVE_SAVED_SEARCH_START`)
export const resolveSavedSearchSuccess = createAction(`${prefix}RESOLVE_SAVED_SEARCH_SUCCESS`)
export const resolveSavedSearchError = createAction(`${prefix}RESOLVE_SAVED_SEARCH_ERROR`)

export const replaceSavedSearchStart = createAction(`${prefix}REPLACE_SAVED_SEARCH_START`)
export const replaceSavedSearchSuccess = createAction(`${prefix}REPLACE_SAVED_SEARCH_SUCCESS`)
export const replaceSavedSearchError = createAction(`${prefix}REPLACE_SAVED_SEARCH_ERROR`)

export const fetchSavedSearchesTimelinesStart = createAction(`${prefix}FETCH_SAVED_SEARCHES_TIMELINES_START`)
export const fetchSavedSearchesTimelinesSuccess = createAction(`${prefix}FETCH_SAVED_SEARCHES_TIMELINES_SUCCESS`)
export const fetchSavedSearchesTimelinesError = createAction(`${prefix}FETCH_SAVED_SEARCHES_TIMELINES_ERROR`)

export const fetchSavedSearchDispatchesStart = createAction(`${prefix}FETCH_SAVED_SEARCH_DISPATCHES_START`)
export const fetchSavedSearchDispatchesSuccess = createAction(`${prefix}FETCH_SAVED_SEARCH_DISPATCHES_SUCCESS`)
export const fetchSavedSearchDispatchesError = createAction(`${prefix}FETCH_SAVED_SEARCH_DISPATCHES_ERROR`)

export const showEditSavedSearchDialog = createAction(`${prefix}SHOW_EDIT_SAVED_SEARCH_DIALOG`)
export const hideEditSavedSearchDialog = createAction(`${prefix}HIDE_EDIT_SAVED_SEARCH_DIALOG`)

export const showRemoveSavedSearchDialog = createAction(`${prefix}SHOW_REMOVE_SAVED_SEARCH_DIALOG`)
export const hideRemoveSavedSearchDialog = createAction(`${prefix}HIDE_REMOVE_SAVED_SEARCH_DIALOG`)

export const showUpdateSavedSearchDialog = createAction(`${prefix}SHOW_UPDATE_SAVED_SEARCH_DIALOG`)
export const hideUpdateSavedSearchDialog = createAction(`${prefix}HIDE_UPDATE_SAVED_SEARCH_DIALOG`)

export const showSavedSearchDispatchesDialog = createAction(`${prefix}SHOW_SAVED_SEARCH_DISPATCH_DIALOG`)
export const hideSavedSearchDispatchesDialog = createAction(`${prefix}HIDE_SAVED_SEARCH_DISPATCH_DIALOG`)

export const toggleFavorite = createAction(`${prefix}TOGGLE_FAVORITE`)

export const loadInitialSearch = createAction(`${prefix}LOAD_INITIAL_SEARCH`)
export const setInitialSearchLoaded = createAction(`${prefix}SET_INITIAL_SEARCH_LOADED`)

export const executeSavedSearch = createAction(`${prefix}EXECUTE_SAVED_SEARCH`)
export const setExecutedSavedSearch = createAction(`${prefix}SET_EXECUTED_SAVED_SEARCH`)

export const setUploadedPhoto = createAction(`${prefix}SET_UPLOADED_PHOTO`)

export const setViewType = createAction(`${prefix}SET_VIEW_TYPE`)
export const setTab = createAction(`${prefix}SET_TAB`)

prefix = `${defaultPrefix}FEEDS/`

export const saveSavedSearchFeedStart = createAction(`${prefix}SAVE_SAVED_SEARCH_FEED_START`)
export const saveSavedSearchFeedSuccess = createAction(`${prefix}SAVE_SAVED_SEARCH_FEED_SUCCESS`)
export const saveSavedSearchFeedError = createAction(`${prefix}SAVE_SAVED_SEARCH_FEED_ERROR`)

export const deleteSavedSearchFeedStart = createAction(`${prefix}DELETE_SAVED_SEARCH_FEED_START`)
export const deleteSavedSearchFeedSuccess = createAction(`${prefix}DELETE_SAVED_SEARCH_FEED_SUCCESS`)
export const deleteSavedSearchFeedError = createAction(`${prefix}DELETE_SAVED_SEARCH_FEED_ERROR`)

export const expandSavedSearchFeed = createAction(`${prefix}EXPAND_SAVED_SEARCH_FEED`)
