import { createAction } from 'redux-actions'

const prefix = 'SAVED_DASHBOARD/'

export const saveSavedDashboardStart = createAction(`${prefix}SAVE_SAVED_DASHBOARD_START`)
export const saveSavedDashboardSuccess = createAction(`${prefix}SAVE_SAVED_DASHBOARD_SUCCESS`)
export const saveSavedDashboardError = createAction(`${prefix}SAVE_SAVED_DASHBOARD_ERROR`)

export const checkUrlNameStart = createAction(`${prefix}CHECK_URL_NAME_START`)
export const checkUrlNameSuccess = createAction(`${prefix}CHECK_URL_NAME_SUCCESS`)
export const checkUrlNameError = createAction(`${prefix}CHECK_URL_NAME_ERROR`)

export const fetchSavedDashboardStart = createAction(`${prefix}FETCH_SAVED_DASHBOARD_START`)
export const fetchSavedDashboardSuccess = createAction(`${prefix}FETCH_SAVED_DASHBOARD_SUCCESS`)
export const fetchSavedDashboardError = createAction(`${prefix}FETCH_SAVED_DASHBOARD_ERROR`)

export const fetchSavedDashboardInfoStart = createAction(`${prefix}FETCH_SAVED_DASHBOARD_INFO_START`)
export const fetchSavedDashboardInfoSuccess = createAction(`${prefix}FETCH_SAVED_DASHBOARD_INFO_SUCCESS`)
export const fetchSavedDashboardInfoError = createAction(`${prefix}FETCH_SAVED_DASHBOARD_INFO_ERROR`)

export const deleteSavedDashboardStart = createAction(`${prefix}DELETE_SAVED_DASHBOARD_START`)
export const deleteSavedDashboardSuccess = createAction(`${prefix}DELETE_SAVED_DASHBOARD_SUCCESS`)
export const deleteSavedDashboardError = createAction(`${prefix}DELETE_SAVED_DASHBOARD_ERROR`)

export const showSavedDashboardDialog = createAction(`${prefix}SHOW_SAVED_DASHBOARD_DIALOG`)
export const hideSavedDashboardDialog = createAction(`${prefix}HIDE_SAVED_DASHBOARD_DIALOG`)
export const startAutoReload = createAction(`${prefix}START_AUTO_RELOAD`)
export const setSavedDashboardValue = createAction(`${prefix}SET_SAVED_DASHBOARD_VALUE`)
