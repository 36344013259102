import { connect } from 'react-redux'

import TemplatesWrapper from 'components/email_templates_library/templates_wrapper'

import {
  fetchTemplatesStart as onMount,
  resetTemplatesModule as onUnmount,
  togglePublicTemplates,
  fetchMoreTemplatesStart as onLoadMore
} from 'actions/email_templates_library'

import {
  getETLibraryTemplates,
  getETLibraryFetchRequestRunning,
  getETLibraryShowPublic,
  getETLibraryDeleteRequestRunning,
  getCapabilities,
  getETLibraryReadMode
} from 'selectors'

const mapStateToProps = (state, { loadTemplate }) => ({
  loadTemplate,
  hasMore: getETLibraryTemplates(state).get('hasMore'),
  loading: getETLibraryFetchRequestRunning(state) || getETLibraryDeleteRequestRunning(state),
  isPublicOnly: getETLibraryShowPublic(state),
  capabilities: getCapabilities(state),
  readMode: getETLibraryReadMode(state)
})

export default connect(
  mapStateToProps,
  {
    onMount,
    onUnmount,
    togglePublicTemplates,
    onLoadMore
  }
)(TemplatesWrapper)
