import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { ListItem, ListItemText, Checkbox, Card } from '@mui/material'

import ShoppingCartGroupedNewsSublist from 'containers/shopping_cart/GroupedNewsSublist'
import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  itemContent: {
    width: '100%'
  },
  group: {
    paddingLeft: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.nrx.paper.paperOnBackgroundColor
  },
  groupHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  listItem: {
    padding: 0,
    alignItems: 'flex-start',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  listItemText: {
    margin: 0
  },
  name: {
    position: 'relative'
  },
  spacer: {
    flex: 1
  },
  action: {
    padding: '2px'
  },
  dragAction: {
    padding: '2px',
    color: 'rgba(0, 0, 0, 0.54) !important'
  },
  card: {
    margin: '5px 0'
  }
}))

export default function GroupedNewsListItem({
  code,
  onToggle,
  selected,
  onSelect,
  selectedCount,
  indeterminate,
  newsClusterIds
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Card classes={{ root: classes.card }}>
      <ListItem classes={{ root: classes.listItem }}>
        <ListItemText classes={{ root: classes.listItemText }}>
          <div className={classes.itemContent}>
            <div className={classes.group}>
              <span className={classes.groupHeader}>
                <Checkbox
                  onChange={() => onSelect({
                    codeId: code.get('id'),
                    newsIds: newsClusterIds,
                    omitIntersection: !selected
                  })}
                  checked={selected}
                  indeterminate={indeterminate}
                />

                {code.get('name') ? `${i18n.get('topic')}: ${code.get('name')}` : i18n.get('ungrouped')}

                {selectedCount > 0 && (
                  <>
                    &nbsp; <b>({selectedCount})</b>
                  </>
                )}
              </span>

              <span className={classes.spacer} />

              <IconButton
                classes={{ root: classes.action }}
                title={i18n.get(code.get('showNews') ? 'collapse' : 'expand')}
                onClick={() => onToggle(code.get('id'))}
                size="large"
              >
                {code.get('showNews') ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            {code.get('showNews') ? (
              <ShoppingCartGroupedNewsSublist
                codeId={code.get('id')}
                news={code.get('news')}
              />
            ) : null}
          </div>
        </ListItemText>
      </ListItem>
    </Card>
  )
}

GroupedNewsListItem.propTypes = {
  code: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  newsClusterIds: PropTypes.instanceOf(List).isRequired,

  onSelect: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired
}
