import { connect } from 'react-redux'

import DistributionListCreateDialog from 'components/contact_management/distribution_list_create_dialog'

import {
  getAnewstipDistributionListContactDialogOpen,
  getCmDistributionListsSearch,
  getCmListData,
  getAnewstipImporContactsForDistributionLists,
  getCmRequestRunning,
  getCmDistributionListSearchRunning
} from 'selectors'

import {
  hideDistributionListCreateDialog as onClose
} from 'actions/anewstip'

import {
  setDistributionList as onSelectOption,
  addContactsToDistributionListStart,
  updateDistributionListFormData as onFormUpdate,
  selectContact as onRemove,
  searchDistributionListStart
} from 'actions/contact_management'

const mapStateToProps = state => ({
  contacts: getAnewstipImporContactsForDistributionLists(state),
  open: getAnewstipDistributionListContactDialogOpen(state),
  lists: getCmDistributionListsSearch(state),
  title: getCmListData(state).get('name'),
  loading: getCmRequestRunning(state),
  searchRequestRunning: getCmDistributionListSearchRunning(state)
})

export default connect(mapStateToProps, {
  onClose,
  onSelectOption,
  onSubmit: () => addContactsToDistributionListStart({ source: 'anewstip' }),
  onFormUpdate,
  onRemove,
  searchDistributionListStart
})(DistributionListCreateDialog)
