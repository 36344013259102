import { createAction } from 'redux-actions'

const defaultPrefix = 'NEWS/ANALYSIS/'
let prefix = defaultPrefix

prefix = `${defaultPrefix}DATA/`
export const applyAnalysisFilter = createAction(`${prefix}APPLY_ANALYSIS_FILTER`)
export const setAnalysisFilter = createAction(`${prefix}SET_ANALYSIS_FILTER`)
export const toggleStatementTonality = createAction(`${prefix}TOGGLE_STATEMENT_TONALITY`)
export const pushChartToDashboardStart = createAction(`${prefix}PUSH_CHART_TO_DASHBOARD_START`)
export const pushChartToDashboardError = createAction(`${prefix}PUSH_CHART_TO_DASHBOARD_ERROR`)
export const pushChartToDashboardSuccess = createAction(`${prefix}PUSH_CHART_TO_DASHBOARD_SUCCESS`)

prefix = `${defaultPrefix}UI/`
export const setExpandedNodes = createAction(`${prefix}SET_EXPANDED_NODES`)
export const addExpandedNodes = createAction(`${prefix}ADD_EXPANDED_NODES`)
export const removeExpandedNodes = createAction(`${prefix}REMOVE_EXPANDED_NODES`)
export const toggleExpandedPanel = createAction(`${prefix}TOGGLE_EXPANDED_PANEL`)
export const toggleUseMediaReviewCodes = createAction(`${prefix}TOGGLE_USE_MEDIA_REVIEW_CODES`)
export const showPushChartToDashboardDialog = createAction(`${prefix}SHOW_PUSH_CHART_TO_DASHBOARD_DIALOG`)
export const hidePushChartToDashboardDialog = createAction(`${prefix}HIDE_PUSH_CHART_TO_DASHBOARD_DIALOG`)

prefix = `${defaultPrefix}CHART/`
export const fetchChartDataStart = createAction(`${prefix}FETCH_CHART_DATA_START`)
export const fetchChartDataError = createAction(`${prefix}FETCH_CHART_DATA_ERROR`)
export const fetchChartDataSuccess = createAction(`${prefix}FETCH_CHART_DATA_SUCCESS`)
export const setChartFields = createAction(`${prefix}SET_CHART_FIELDS`)
export const setChartOpt = createAction(`${prefix}SET_CHART_OPT`)
export const drilldown = createAction(`${prefix}DRILLDOWN`)

prefix = `${defaultPrefix}METRICS/`
export const fetchMetricsChartsData = createAction(`${prefix}FETCH_METRICS_CHART_DATA`)
export const setMetricsChartLoading = createAction(`${prefix}SET_METRICS_CHART_LOADING`)
export const setMetricsChartData = createAction(`${prefix}SET_METRICS_CHART_DATA`)
