import { compose, createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createReduxHistoryContext } from 'redux-first-history'

import { logException } from 'utils/error'
import rootReducer from 'reducers/index'
import rootSaga from 'sagas'
import { trackExceptions, trackActions, loadingIndicator } from 'utils/middlewares'

import * as GA from 'utils/ga'
import history from './configureHistory'
import { getCookieOption } from '../selectors'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history
})

const sagaMiddleware = createSagaMiddleware({
  onError: logException
})

let gaInitialized = false

export default function configureStore(initialState = {}) {
  const enhancer = compose(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware,
      trackExceptions,
      trackActions,
      loadingIndicator
    )
  )

  const store = createStore(
    rootReducer(routerReducer),
    initialState,
    enhancer
  )

  store.subscribe(() => {
    if (!gaInitialized) {
      const cookieOptions = getCookieOption(store.getState())
      const accepted = (cookieOptions === 'accept_all' || cookieOptions === 'true')

      if (accepted) {
        GA.init()
        gaInitialized = true
      }
    }
  })

  sagaMiddleware.run(rootSaga)

  return {
    store,
    history: createReduxHistory(store)
  }
}
