import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'

import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  switchActions: {
    display: 'flex',
    height: '45px !important'
  },
  switchAction: {
    position: 'relative',
    flexBasis: 0,
    '& > button': {
      minHeight: '100%',
      [theme.breakpoints.only('xs')]: {
        fontSize: 10
      }
    }
  },
  switchActionContent: {
    paddingTop: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
    whiteSpace: 'nowrap',
    opacity: 0.7
  },
  iconContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: 25,
    marginRight: 5
  }
}))

export default function ContentsCampaignsTab({
  campaignsLoading,
  contentsLoading,
  view,
  changeContentsCampaignsTabView
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  const handleTabChange = buttonView => {
    if (view === buttonView) {
      return
    }

    changeContentsCampaignsTabView(buttonView)
  }

  const renderNavigationAction = (buttonView, label, loading, isDisabled = false, Comp = ButtonContainer) => {
    let active

    if (view === buttonView) {
      active = (
        <div
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    const tabButton = () => (
      <Button
        disableRipple
        onClick={() => handleTabChange(buttonView)}
        disabled={isDisabled}
        loading={loading}
      >
        <div
          className={classes.switchActionContent}
          style={active && { color: colors.get('primary'), opacity: 1 }}
        >
          {label}
        </div>
      </Button>
    )

    return (
      <Comp
        key={buttonView}
        className={classes.switchAction}
      >
        {tabButton()}
        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      'contents',
      `${i18n.get('contents')}`,
      contentsLoading
    )
  )

  buttons.push(
    renderNavigationAction(
      'campaigns',
      `${i18n.get('campaigns')}`,
      campaignsLoading
    )
  )

  return (
    <>
      <div className={classes.switchActions}>
        {buttons}
      </div>
    </>
  )
}

ContentsCampaignsTab.propTypes = {
  campaignsLoading: PropTypes.bool.isRequired,
  contentsLoading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,

  changeContentsCampaignsTabView: PropTypes.func.isRequired
}
