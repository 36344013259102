import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'

import { getCapabilities, getViewConfigCapabilities } from 'selectors'

const CapSafeComp = (Comp, props, capNames, fallBackComp = null) => {
  const allowed = capNames.some(val => {
    if (val === undefined) {
      return false
    }

    if (typeof val === 'string') {
      return props.caps.get(val)
    }

    return val.every(capName => props.caps.get(capName))
  })

  if (!allowed) {
    return fallBackComp || props.fallBackComp || null
  }

  const compProps = { ...props }
  delete compProps.caps
  delete compProps.viewConfigCaps
  delete compProps.dispatch
  delete compProps.fallBackComp

  return <Comp {...compProps} />
}

CapSafeComp.defaultProps = {
  caps: Map({})
}

CapSafeComp.propTypes = {
  caps: PropTypes.instanceOf(Map),
  fallBackComp: PropTypes.any
}

export default function CapSafe(Comp, ...capNames) {
  const WrapperComponent = props => CapSafeComp(Comp, props, capNames)
  WrapperComponent.displayName = 'CapSafe'

  WrapperComponent.defaultProps = {
    caps: Map({})
  }

  WrapperComponent.propTypes = {
    caps: PropTypes.instanceOf(Map)
  }

  const mapStateToProps = state => ({
    caps: getCapabilities(state)
  })

  return connect(mapStateToProps)(WrapperComponent)
}

export function ViewConfigCapSafe(Comp, key, fallBackComp) {
  const WrapperComponent = props => {
    const capNames = props.viewConfigCaps.get(key).toJS()

    return CapSafeComp(Comp, props, capNames, fallBackComp)
  }

  WrapperComponent.displayName = 'ViewConfigCapSafe'

  WrapperComponent.defaultProps = {
    viewConfigCaps: Map({}),
    caps: Map({})
  }

  WrapperComponent.propTypes = {
    viewConfigCaps: PropTypes.instanceOf(Map),
    caps: PropTypes.instanceOf(Map)
  }

  const mapStateToProps = state => ({
    viewConfigCaps: getViewConfigCapabilities(state),
    caps: getCapabilities(state)
  })

  return connect(mapStateToProps)(WrapperComponent)
}
