import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { getWhitelabelProgressLetters } from 'selectors'

import { CircularProgress } from '@mui/material'

const useStyles = makeStyles()({
  progressBar: {
    position: 'relative',
    width: 80,
    height: 80
  },
  progressBarItem: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loading: {
    position: 'fixed',
    zIndex: 300,
    top: 0,
    width: '100%'
  },
  nrx0: {
    animation: `${keyframes`
            0% {
              transform: scale(1) translate(-10px) rotate(-10deg);
            },
            10% {
              transform: scale(1) translate(-10px) rotate(-20deg);
            },
            20% {
              transform: scale(1) translate(-10px) rotate(0deg);
            },
            50% {
              transform: scale(0.5) translate(10px);
            },
            60% {
              transform: scale(1) translate(10px);
            },
            80% {
              transform: scale(1.5) translate(0);
            },
            100% {
              transform: scale(1) translate(-10px);
            }
          `} 2s linear infinite;`
  },
  nrx1: {
    animation: `${keyframes`
            0% {
              transform: translate(0, 0) rotate(0);
            }
            10% {
              transform: translate(0, -10px) rotate(-40deg);
            }
            15% {
              transform: translate(0, -5px) rotate(-20deg);
            }
            20% {
              transform: translate(0, 0);
            }
          `} 2s linear infinite;`
  },
  nrx2: {
    animation: `${keyframes`
            0% {
              transform: scale(1) translate(10px) rotate(10deg);
            }
            10% {
              transform: scale(1) translate(10px) rotate(20deg);
            }
            20% {
              transform: scale(1) translate(10px) rotate(0);
            }
            50% {
              transform: scale(1.5) translate(-10px);
            }
            60% {
              transform: scale(1) translate(-10px);
            }
            80% {
              transform: scale(0.5) translate(0);
            }
            100% {
              transform: scale(1) translate(10px);
            }
          `} 2s linear infinite;`
  }
})

export default function NrxProgressBar() {
  const { classes } = useStyles()
  const letters = useSelector(getWhitelabelProgressLetters)

  return (
    <div className={classes.progressBar}>
      <div className={classes.progressBarItem}>
        <CircularProgress
          variant="indeterminate"
          size={80}
        />
      </div>

      {letters.map((l, i) => {
        const className = classes[`nrx${i}`]

        return (
          <span
            key={i}
            className={`${classes.progressBarItem} ${className}`}
          >
            {l}
          </span>
        )
      })}
    </div>
  )
}
