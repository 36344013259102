
import { connect } from 'react-redux'

import Facebook from 'components/content_desk/contents/content_view/statistics_data/facebook'

import { getCDeskStatisticsRequestRunning, getCDeskSelectedContent } from 'selectors'

const mapStateToProps = (state, { data }) => ({
  data,
  requestRunning: getCDeskStatisticsRequestRunning(state),
  contentType: getCDeskSelectedContent(state).getIn(['facebookPost', 'type'])
})

export default connect(
  mapStateToProps,
  {
  }
)(Facebook)
