
import { connect } from 'react-redux'

import LinkedIn from 'components/content_desk/contents/content_view/statistics_data/linked_in'

import { getCDeskStatisticsRequestRunning } from 'selectors'

const mapStateToProps = (state, { data }) => ({
  data,
  requestRunning: getCDeskStatisticsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(LinkedIn)
