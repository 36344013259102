export const getMailingLists = state => state.mailingLists
export const getMailingListsDialog = state => getMailingLists(state).get('dialogs')

// UI
export const getShowRemoveMailingListDialog = state => getMailingListsDialog(state).getIn(['ui', 'removeActive'])
export const getShowMailingListDialog = state => getMailingListsDialog(state).getIn(['ui', 'active'])
export const getSaveMailingListRunning = state => getMailingListsDialog(state).getIn(['ui', 'running'])

// DATA
export const getSelectedMailingList = state => getMailingListsDialog(state).getIn(['data', 'selectedMailingList'])
