import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  formData: {
    id: null,
    name: '',
    webDomains: [],
    emailDomains: []
  }
})

export default handleActions({
  [Actions.updateScoreQueryFormData]: (state, { payload }) => (
    state.update('formData', oldValue => oldValue.merge(payload))
  ),
  [Actions.resetScoreQueryFormData]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
