import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import camelcase from 'camelcase'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  TableCell,
  TableRow,
  Typography
} from '@mui/material'
import { indigo, green, purple, grey, orange, red } from '@mui/material/colors'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import ContentTitle from 'containers/content_desk_new/contents/content/ContentTitle'
import ContentApprovalStatusSelect from 'containers/content_desk_new/contents/content/ContentApprovalStatusSelect'
import ContentStatus from 'containers/content_desk_new/contents/content/ContentStatus'
import ContentActions from 'containers/content_desk_new/contents/content/ContentActions'

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 20,
    margin: '-2px'
  },
  draft: {
    borderLeft: '5px solid',
    borderColor: grey[500]
  },
  declined: {
    borderLeft: '5px solid',
    borderColor: red[700]
  },
  distributed: {
    borderLeft: '5px solid',
    borderColor: indigo[500]
  },
  review: {
    borderLeft: '5px solid',
    borderColor: orange[500]
  },
  reviewPending: {
    borderLeft: '5px solid',
    borderColor: orange[500]
  },
  revisionsNeeded: {
    borderLeft: '5px solid',
    borderColor: purple[500]
  },
  approved: {
    borderLeft: '5px solid',
    borderColor: green[500]
  }
}))

function ColumnFooter({ text }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: theme => !theme.isDark && '#757575' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const Content = ({
  content,
  showCampaigns
}) => {
  const i18n = useI18n()
  const device = useDevice()
  const { classes } = useStyles()
  const editor = `${content.getIn(['lastEditor', 'firstName'])} ${content.getIn(['lastEditor', 'lastName'])}`
  const lastEditedDate = moment(content.get('updatedAt')).format('lll')
  const status = camelcase(content.get('approvalStatus'))
  const statusClass = classes[status]
  const otsStatus = content.getIn(['otsPressRelease', 'status'])

  let otsStatusClass

  if (otsStatus) {
    otsStatusClass = classes[otsStatus.toLowerCase()]
  }

  let maxLength = 16

  if (device.get('xl')) {
    maxLength = 32
  } else if (device.get('lg')) {
    maxLength = 24
  }

  const renderCampaigName = () => {
    if (showCampaigns) {
      let campaignName = content.getIn(['ccdCampaign', 'name']) || ''

      if (campaignName.toLowerCase() === 'no campaign') {
        campaignName = i18n.get('no_campaign')
      }

      return (
        <TableCell>
          {campaignName}
        </TableCell>
      )
    }

    return null
  }

  return (
    <TableRow className={otsStatus ? otsStatusClass : statusClass}>
      <TableCell>
        <ContentTitle
          content={content}
          maxLength={maxLength}
        />
      </TableCell>
      <TableCell>
        {editor}
        <ColumnFooter text={lastEditedDate} />
      </TableCell>
      {renderCampaigName()}
      <TableCell>
        <ContentApprovalStatusSelect
          content={content}
        />
      </TableCell>
      <TableCell>
        <ContentStatus
          content={content}
        />
      </TableCell>
      <TableCell>
        <ContentActions
          content={content}
        />
      </TableCell>
    </TableRow>
  )
}

Content.defaultProps = {
  showCampaigns: true
}

Content.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  showCampaigns: PropTypes.bool
}

ColumnFooter.propTypes = {
  text: PropTypes.string
}

export default Content
