import { connect } from 'react-redux'

import UpdateDialog from 'components/dialogs/confirm_dialog'

import {
  getI18n,
  getExecutedSavedSearch,
  getShowUpdateSavedSearchDialog
} from 'selectors'

import {
  saveThemaxSavedSearchStart
} from 'actions/themax'

import {
  hideUpdateSavedSearchDialog
} from 'actions/saved_searches'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  title: getI18n(state).get('update_saved_search_title'),
  payload: getExecutedSavedSearch(state),
  active: getShowUpdateSavedSearchDialog(state),
  text: getI18n(state).get('update_saved_search_text'),
  actionText: getI18n(state).get('save'),
  autoHide: true
})

export default connect(
  mapStateToProps,
  {
    onSubmit: saveThemaxSavedSearchStart,
    onHide: hideUpdateSavedSearchDialog
  }
)(UpdateDialog)
