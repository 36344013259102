import { createAction } from 'redux-actions'
import createLoadingAction from 'utils/createLoadingAction'

const defaultPrefix = 'CUSTOM_TAGS/'
let prefix = 'CUSTOM_TAGS/'

// DATA
export const setCustomTags = createAction(`${prefix}SET_CUSTOM_TAGS`)
export const resetCustomTagNewNewsCount = createAction(`${prefix}RESET_CUSTOM_TAGS_NEW_NEWS_COUNT`)

export const saveCustomTagStart = createLoadingAction(`${prefix}SAVE_CUSTOM_TAG_START`, true)
export const saveCustomTagSuccess = createLoadingAction(`${prefix}SAVE_CUSTOM_TAG_SUCCESS`, false)
export const saveCustomTagError = createLoadingAction(`${prefix}SAVE_CUSTOM_TAG_ERROR`, false)

export const deleteCustomTagStart = createAction(`${prefix}DELETE_CUSTOM_TAG_START`)
export const deleteCustomTagSuccess = createAction(`${prefix}DELETE_CUSTOM_TAG_SUCCESS`)
export const deleteCustomTagError = createAction(`${prefix}DELETE_CUSTOM_TAG_ERROR`)

export const emptyCustomTagStart = createAction(`${prefix}EMPTY_CUSTOM_TAG_START`)
export const emptyCustomTagSuccess = createAction(`${prefix}EMPTY_CUSTOM_TAG_SUCCESS`)
export const emptyCustomTagError = createAction(`${prefix}EMPTY_CUSTOM_TAG_ERROR`)

export const assignCustomTagToNewsStart = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_NEWS_START`, true)
export const assignCustomTagToNewsSuccess = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_NEWS_SUCCESS`, false)
export const assignCustomTagToNewsError = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_NEWS_ERROR`, false)

export const removeCustomTagFromNewsStart = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_NEWS_START`, true)
export const removeCustomTagFromNewsSuccess = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_NEWS_SUCCESS`, false)
export const removeCustomTagFromNewsError = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_NEWS_ERROR`, false)

export const assignCustomTagToGroupStart = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_GROUP_START`, true)
export const assignCustomTagToGroupSuccess = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_GROUP_SUCCESS`, false)
export const assignCustomTagToGroupError = createLoadingAction(`${prefix}ASSIGN_CUSTOM_TAG_TO_GROUP_ERROR`, false)

export const removeCustomTagFromGroupStart = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_GROUP_START`, true)
export const removeCustomTagFromGroupSuccess = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_GROUP_SUCCESS`, false)
export const removeCustomTagFromGroupError = createLoadingAction(`${prefix}REMOVE_CUSTOM_TAG_FROM_GROUP_ERROR`, false)

export const fetchNewsForCustomTagStart = createLoadingAction(`${prefix}FETCH_NEWS_FOR_CUSTOM_TAG_START`, true)
export const fetchNewsForCustomTagSuccess = createLoadingAction(`${prefix}FETCH_NEWS_FOR_CUSTOM_TAG_SUCCESS`, false)
export const fetchNewsForCustomTagError = createLoadingAction(`${prefix}FETCH_NEWS_FOR_CUSTOM_TAG_ERROR`, false)

export const afterStructuredFormatExportActionStart = createAction(`${prefix}AFTER_STRUCTURED_FORMAT_EXPORT_ACTION_START`)
export const afterStructuredFormatExportActionSuccess = createAction(`${prefix}AFTER_STRUCTURED_FORMAT_EXPORT_ACTION_SUCCESS`)
export const afterStructuredFormatExportActionError = createAction(`${prefix}AFTER_STRUCTURED_FORMAT_EXPORT_ACTION_ERROR`)

// UI
prefix = `${defaultPrefix}UI/`
export const updateAfterStructuredFormatExportAction = createAction(`${prefix}UPDATE_AFTER_STRUCTURED_FORMAT_EXPORT_ACTION`)
export const setSelectedCustomTag = createAction(`${prefix}SET_SELECTED_CUSTOM_TAG`)
export const showCustomTagDialog = createAction(`${prefix}SHOW_CUSTOM_TAG_DIALOG`)
export const hideCustomTagDialog = createAction(`${prefix}HIDE_CUSTOM_TAG_DIALOG`)
export const showEmptyCustomTagDialog = createAction(`${prefix}SHOW_EMPTY_CUSTOM_TAG_DIALOG`)
export const hideEmptyCustomTagDialog = createAction(`${prefix}HIDE_EMPTY_CUSTOM_TAG_DIALOG`)
export const showAssignCustomTagsToNewsDialog = createAction(`${prefix}SHOW_ASSIGN_CUSTOM_TAGS_TO_NEWS_DIALOG`)
export const hideAssignCustomTagsToNewsDialog = createAction(`${prefix}HIDE_ASSIGN_CUSTOM_TAGS_TO_NEWS_DIALOG`)
export const showAssignCustomTagsToGroupDialog = createAction(`${prefix}SHOW_ASSIGN_CUSTOM_TAGS_TO_GROUP_DIALOG`)
export const hideAssignCustomTagsToGroupDialog = createAction(`${prefix}HIDE_ASSIGN_CUSTOM_TAGS_TO_GROUP_DIALOG`)
export const uploadPhotoStart = createAction(`${prefix}UPLOAD_PHOTO_START`)
export const uploadPhotoSuccess = createAction(`${prefix}UPLOAD_PHOTO_SUCCESS`)
export const uploadPhotoError = createAction(`${prefix}UPLOAD_PHOTO_ERROR`)
export const setUploadedPhoto = createAction(`${prefix}SET_UPLOADED_PHOTO`)
export const filterBySelectedCustomTag = createAction(`${prefix}FILTER_BY_SELECTED_CUSTOM_TAG`)
export const filterBySelectedCustomTagGroup = createAction(`${prefix}FILTER_BY_SELECTED_CUSTOM_TAG_GROUP`)
export const openCustomTagInShoppingCart = createAction(`${prefix}OPEN_CUSTOM_TAG_IN_SHOPPING_CART`)
export const sortBy = createAction(`${prefix}SORT_BY`)
export const showRemoveCustomTagDialog = createAction(`${prefix}SHOW_REMOVE_CUSTOM_TAG_DIALOG`)
export const hideRemoveCustomTagDialog = createAction(`${prefix}HIDE_REMOVE_CUSTOM_TAG_DIALOG`)
export const setCustomTagsViewType = createAction(`${prefix}SET_CUSTOM_TAGS_VIEW_TYPE`)
export const changeCustomTagsTab = createAction(`${prefix}CHANGE_CUSTOM_TAGS_TAB`)

// GROUPS
prefix = `${defaultPrefix}GROUPS/`
export const setFilteredGroupId = createAction(`${prefix}SET_FILTERED_GROUP_ID`)
export const resetFilteredGroupId = createAction(`${prefix}RESET_FILTERED_GROUP_ID`)

export const showGroupDialog = createAction(`${prefix}SHOW_GROUP_DIALOG`)
export const hideGroupDialog = createAction(`${prefix}HIDE_GROUP_DIALOG`)

export const showDeleteGroupDialog = createAction(`${prefix}SHOW_DELETE_GROUP_DIALOG`)
export const hideDeleteGroupDialog = createAction(`${prefix}HIDE_DELETE_GROUP_DIALOG`)

export const setSelectedGroup = createAction(`${prefix}SET_SELECTED_GROUP`)

export const saveGroupStart = createAction(`${prefix}SAVE_GROUP_START`)
export const saveGroupSuccess = createAction(`${prefix}SAVE_GROUP_SUCCESS`)
export const saveGroupError = createAction(`${prefix}SAVE_GROUP_ERROR`)

export const deleteGroupStart = createAction(`${prefix}DELETE_GROUP_START`)
export const deleteGroupSuccess = createAction(`${prefix}DELETE_GROUP_SUCCESS`)
export const deleteGroupError = createAction(`${prefix}DELETE_GROUP_ERROR`)

export const toggleGroupedAssignableCustomTags = createAction(`${prefix}TOGGLE_GROUPED_ASSIGNABLE_CUSTOM_TAGS`)

export const runCustomTagQueries = createAction(`${prefix}RUN_CUSTOM_TAG_QUERIES`)
