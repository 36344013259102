import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { makeStyles } from 'tss-react/mui'
import { grey } from '@mui/material/colors'
import {
  Box,
  Paper,
  Typography
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

/* Caution: these components are still in the old directory */
import CalendarDialog from 'containers/content_desk_new/CalendarDialog'
import OldContentEditDialog from 'containers/content_desk/ContentEditDialog'
import DeleteDialog from 'containers/content_desk/DeleteDialog'

import CampaignForm from 'containers/content_desk_new/campaigns/CampaignForm'
import ContentNewDialog from 'containers/content_desk_new/ContentNewDialog'
import Campaigns from 'containers/content_desk_new/campaigns/Campaigns'
import MobileCampaigns from 'containers/content_desk_new/campaigns/mobile_campaigns/MobileCampaigns'
import ContentEditDialog from 'containers/content_desk_new/contents/content_edit_dialog/ContentEditDialog'
import ContentsEditBlockDialog from 'containers/content_desk_new/contents/ContentsEditBlockDialog'
import ContactDetailsDialog from 'containers/contact_management/ContactDetailsDialog'
import ContactEditDialog from 'containers/contact_management/ContactEditDialog'
import ContentNotesDialog from 'containers/content_desk_new/contents/ContentNotesDialog'
import Contents from 'containers/content_desk_new/contents/Contents'
import MobileContents from 'containers/content_desk_new/contents/mobile_contents/MobileContents'
import CampaignsFilters from 'containers/content_desk_new/campaigns/CampaignsFilters'
import ContentsFilters from 'containers/content_desk_new/contents/ContentsFilters'
import ContentsCampaignsTabs from 'containers/content_desk_new/ContentsCampaignsTabs'
import ContentView from 'containers/content_desk_new/contents/content_view/ContentView'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  contentsAndCampaignsContainer: {
    borderRadius: 20,
    minHeight: 400,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200],
    marginBottom: 20
  },
  seeAllButtonMobile: {
    fontSize: 10
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 0 0'
  },
  circularProgress: {
    textAlign: 'center'
  }
})

export default function ContensCampaignsOverview({
  view
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const renderSeeAllButton = () => (
    <Link to="/app/content_desk/content_management/contents_and_campaigns">
      <Button
        variant="outlined"
        color="primary"
        size={device.get('mobile') ? 'small' : 'default'}
        className={device.get('mobile') ? classes.seeAllButtonMobile : null}
      >
        {i18n.get('see_all')}
      </Button>
    </Link>
  )

  const renderContentsFilters = () => {
    if (device.get('mobile')) {
      return null
    }

    if (view === 'contents') {
      return <ContentsFilters />
    }

    if (view === 'campaigns') {
      return <CampaignsFilters />
    }

    return null
  }

  const renderResultsTable = () => {
    if (view === 'contents') {
      if (device.get('mobile')) {
        return <MobileContents />
      }

      return <Contents />
    }

    if (view === 'campaigns') {
      if (device.get('mobile')) {
        return <MobileCampaigns />
      }

      return <Campaigns />
    }

    return null
  }

  return (
    <Paper className={classes.contentsAndCampaignsContainer}>
      <Box className={classes.titleContainer}>
        <Typography
          variant="h5"
        >
          {i18n.get('contents_and_campaigns')}
        </Typography>
        {renderSeeAllButton()}
      </Box>
      <Box className={classes.navigationContainer}>
        <ContentsCampaignsTabs />
        {renderContentsFilters()}
      </Box>
      {renderResultsTable()}

      <CampaignForm />
      <ContentNewDialog />
      <ContentEditDialog />
      <OldContentEditDialog />
      <ContactDetailsDialog />
      <ContactEditDialog />
      <ContentNotesDialog />
      <CalendarDialog />
      <ContentsEditBlockDialog />
      <ContentView />

      <DeleteDialog entity="campaign" />
      <DeleteDialog entity="content" />
    </Paper>
  )
}

ContensCampaignsOverview.propTypes = {
  view: PropTypes.string.isRequired
}
