import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/unsplash'
import * as CDActions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  showUnsplashComponent: false,
  requestRunning: false,
  downloadRequestRunning: false,
  selectedImageId: null,
  isRandomSearch: true
})

export default handleActions({
  [Actions.toggleUnsplashComponent]: state => state.set('showUnsplashComponent', !state.get('showUnsplashComponent')),
  [Actions.fetchRandomUnsplashPhotosStart]: state => state.set('requestRunning', true).set('isRandomSearch', true),
  [Actions.searchUnsplashPhotosStart]: state => state.set('requestRunning', true).set('isRandomSearch', false),
  [combineActions(Actions.searchUnsplashPhotosSuccess, Actions.searchUnsplashPhotosError)]: state => state.set('requestRunning', false),
  [combineActions(
    CDActions.closeNewContentDialog,
    CDActions.closeEditContentDialog,
    CDActions.closeOldEditContentDialog
  )]: () => initialState,
  [Actions.addImageToLibraryStart]: (state, { payload: { id } }) => state.set('downloadRequestRunning', true).set('selectedImageId', id),
  [combineActions(Actions.addImageToLibrarySuccess, Actions.addImageToLibraryError)]: state => state
    .set('downloadRequestRunning', false)
    .set('selectedImageId', null),
  [AppActions.resetState]: () => initialState
}, initialState)
