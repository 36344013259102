import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import useSavingSuccess from 'hooks/useSavingSuccess'
import { Button, CircularProgress } from '@mui/material'
import { alpha } from '@mui/material/styles'
import { green } from '@mui/material/colors'

const useStyles = makeStyles()(theme => ({
  loading: {
    position: 'relative'
  },
  indicator: {
    position: 'absolute',
    color: theme.palette.grey[500],
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      left: '47.5%'
    }
  },
  success: {
    position: 'absolute',
    color: green[500],
    opacity: 0,
    animation: `${keyframes`
          0% {
            opacity: 0;
            transform: scale(1);
          }
          25% {
            opacity: 1;
            transform: scale(2);
          }
          50% {
            opacity: 1;
            transform: scale(1);
          }
          100% {
            opacity: 0;
            transform: scale(1);
          }
          `} 2s;`,
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      left: '47.5%'
    }
  },
  defaultContained: {
    color: theme.palette.getContrastText(theme.palette.grey[300]),
    backgroundColor: theme.palette.grey[300],

    '&:hover': {
      boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      backgroundColor: '#d5d5d5'
    }
  },
  defaultOutlined: {
    color: theme.palette.text.primary,
    borderColor:
    theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.23)'
      : 'rgba(255, 255, 255, 0.23)',

    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.action.disabledBackground}`
    },

    '&:hover': {
      borderColor:
      theme.palette.mode === 'light'
        ? 'rgba(0, 0, 0, 0.23)'
        : 'rgba(255, 255, 255, 0.23)',
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      )
    }
  },
  defaultText: {
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: alpha(
        theme.palette.text.primary,
        theme.palette.action.hoverOpacity
      )
    }
  }
}))

const MuiButton = ({ loading, saving, children, innerRef, ...props }) => {
  const { classes, cx } = useStyles()
  const showSuccess = useSavingSuccess(saving)

  const buttonProps = { ...props }

  if (buttonProps.color === 'default' || !buttonProps.color) {
    buttonProps.color = 'primary'
    buttonProps.classes = buttonProps.classes || {}

    if (!buttonProps.variant || buttonProps.variant === 'text') {
      buttonProps.classes = { ...buttonProps.classes, root: cx(classes.defaultText, buttonProps.classes.root) }
    } else if (buttonProps.variant === 'contained') {
      buttonProps.classes = { ...buttonProps.classes, root: cx(classes.defaultContained, buttonProps.classes.root) }
    } else if (buttonProps.variant === 'outlined') {
      buttonProps.classes = { ...buttonProps.classes, root: cx(classes.defaultOutlined, buttonProps.classes.root) }
    }
  }

  if (loading || saving || showSuccess) {
    buttonProps.disabled = true
    buttonProps.className = cx(buttonProps.className, classes.loading)
  }

  return (
    <Button
      ref={innerRef}
      {...buttonProps}
    >
      {children}

      {loading || saving ? (
        <CircularProgress
          className={classes.indicator}
          variant="indeterminate"
          size={24}
        />
      ) : null}

      {showSuccess && <CheckIcon classes={{ root: classes.success }} />}
    </Button>
  )
}

function ButtonWrapper(props, ref) {
  return (
    <MuiButton
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(ButtonWrapper)

MuiButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  innerRef: PropTypes.any
}
