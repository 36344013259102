const arrayMoveMutate = (array, from, to) => {
  array.splice((to < 0 ? array.length + to : to), 0, array.splice(from, 1)[0])
}

export default function arrayMove(array, from, to) {
  const a = array.slice()
  arrayMoveMutate(a, from, to)

  return a
}
