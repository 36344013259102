
import { connect } from 'react-redux'

import Email from 'components/content_desk/contents/content_view/statistics_data/email'

import { getCDeskStatisticsRequestRunning } from 'selectors'

const mapStateToProps = (state, { data }) => ({
  data,
  requestRunning: getCDeskStatisticsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(Email)
