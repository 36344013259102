import { connect } from 'react-redux'

import ChooseContent from 'components/content_desk_new/contents/content_edit_dialog/choose_content'

import {
  getCDeskContentDropdownOptions,
  getCDeskContentFormData
} from 'selectors'

import {
  fetchContentDropdownOptionsStart,
  changeContentEditTabView,
  updateContent
} from 'actions/content_desk'

const mapStateToProps = state => ({
  contents: getCDeskContentDropdownOptions(state),
  contentFormData: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    fetchContents: fetchContentDropdownOptionsStart,
    onNavigationChange: changeContentEditTabView,
    updateContent
  }
)(ChooseContent)
