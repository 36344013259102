import { connect } from 'react-redux'

import ContactDeleteDialog from 'components/contact_management/contact_delete_dialog/ContactDeleteDialog'

import {
  deleteContactsStart as onDelete,
  closeContactDeleteDialog as onClose
} from 'actions/contact_management'

import {
  getCmContactDeleteDialogOpen,
  getCmContactsToDelete,
  getCmRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  open: getCmContactDeleteDialogOpen(state),
  contacts: getCmContactsToDelete(state),
  loading: getCmRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onClose,
    onDelete
  }
)(ContactDeleteDialog)
