import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import useColors from 'hooks/useColors'

import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { Paper, Divider } from '@mui/material'

import { Button } from 'containers/themed'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  switchActionsContainer: {
    display: 'flex',
    margin: '3em 2em 0em 1em',
    [theme.breakpoints.only('xs')]: {
      margin: '3em 0 1.5em 0'
    }
  },
  switchActions: {
    display: 'flex',
    width: '100%',
    height: '64px !important'
  },
  switchAction: {
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,

    '& > button': {
      minWidth: '100%',
      minHeight: '100%',

      [theme.breakpoints.only('xs')]: {
        fontSize: '7pt'
      }
    }
  },
  switchActionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  activeBorder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    animation: `${keyframes`
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
          `} 300ms;`
  },
  divider: {
    marginBottom: '1.5em'
  }
}))

export default function Navigation({
  view,
  content,
  onChange
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  const renderNavigationAction = (buttonView, label, isDisabled = false, Comp = ButtonContainer) => {
    const title = null

    let active

    if (view === buttonView) {
      active = (
        <div
          className={classes.activeBorder}
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    return (
      <Comp
        key={buttonView}
        className={classes.switchAction}
        title={title}
      >
        <Button
          onClick={() => onChange(buttonView)}
          disabled={isDisabled}
        >
          <div className={classes.switchActionContent}>
            {label}
          </div>
        </Button>

        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      'create_content',
      `${i18n.get('setting')}`
    )
  )

  buttons.push(
    renderNavigationAction(
      'content_editor',
      `${i18n.get('content_editor')}`,
      content.get('type') === null
    )
  )

  buttons.push(
    renderNavigationAction(
      'attachments',
      `${i18n.get('attachments')}`,
      content.getIn(['type', 'id']) !== 'email'
    )
  )

  buttons.push(
    renderNavigationAction(
      'select_recipients',
      `${i18n.get('recipients')}`,
      content.getIn(['type', 'id']) !== 'email'
    )
  )

  buttons.push(
    renderNavigationAction(
      'distribute',
      i18n.get('overview')
    )
  )

  if (buttons.length <= 1) {
    return null
  }

  return (
    <>
      <Paper
        elevation={0}
        className={classes.switchActionsContainer}
      >
        <div className={classes.switchActions}>
          {buttons}
        </div>
      </Paper>
      <Divider className={classes.divider} />
    </>
  )
}

Navigation.propTypes = {
  view: PropTypes.string.isRequired,
  content: PropTypes.instanceOf(Map).isRequired,

  onChange: PropTypes.func.isRequired
}
