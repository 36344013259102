import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List as iList } from 'immutable'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { makeStyles } from 'tss-react/mui'

import { Input, Button } from 'containers/themed'
import NrxProgressBar from 'components/nrx_progress_bar'

import useI18n from 'hooks/useI18n'
import usePrevious from 'hooks/usePrevious'

import { List, ListItem, Divider, Popover } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  list: {
    maxHeight: '50vh',
    overflowY: 'auto'
  },
  inputContainer: {
    padding: 10
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  loading: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  }
}))

export default function FilterableMenu({ items, onItemClick, onMainClick, handle, loading }) {
  const [value, setValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const { classes } = useStyles()
  const i18n = useI18n()
  const previousLoading = usePrevious(loading)

  useEffect(() => {
    if (previousLoading && !loading && anchorEl) {
      setAnchorEl(null)
      setValue('')
    }
  }, [loading, previousLoading, anchorEl, setAnchorEl])

  const newHandle = React.cloneElement(handle, {
    onClick: event => setAnchorEl(event.currentTarget)
  })

  const handleItemClick = item => {
    if (loading === undefined) {
      setAnchorEl(null)
    }

    return onItemClick && onItemClick(item)
  }
  const handleValueChange = event => setValue(event.target.value)

  const highlightName = name => {
    if (value) {
      return name.replace(new RegExp(`(${value})`, 'gi'), `<span class="${classes.highlight}">$1</span>`)
    }

    return name
  }

  const filteredItems = items.filter(item => item.get('name').toLowerCase().indexOf(value.toLowerCase()) !== -1)

  return (
    <>
      {newHandle}

      <Popover
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        {loading && (
          <div className={classes.loading}>
            <NrxProgressBar />
          </div>
        )}

        {!items.isEmpty() && (
          <>
            <div className={classes.inputContainer}>
              <Input
                autoFocus
                value={value}
                onChange={handleValueChange}
                variant="outlined"
                label={i18n.get('search')}
                disabled={loading}
              />
            </div>

            <Divider />

            <List classes={{ root: classes.list }}>
              {filteredItems.map(item => (
                <ListItem
                  key={item.get('id')}
                  button
                  onClick={() => handleItemClick(item)}
                  disabled={loading}
                >
                  <span dangerouslySetInnerHTML={{ __html: highlightName(item.get('name')) }} />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {items.isEmpty() && (
          <List>
            <ListItem
              button
              disabled
            >
              {i18n.get('no_items_yet')}
            </ListItem>
          </List>
        )}

        {onMainClick && (
          <>
            <Divider />

            <div className={classes.buttonContainer}>
              <Button
                endIcon={<ChevronRightIcon />}
                onClick={onMainClick}
                disabled={loading}
              >
                {i18n.get('overview')}
              </Button>
            </div>
          </>
        )}
      </Popover>
    </>
  )
}

FilterableMenu.propTypes = {
  items: PropTypes.instanceOf(iList).isRequired,
  handle: PropTypes.node.isRequired,
  loading: PropTypes.bool,

  onItemClick: PropTypes.func,
  onMainClick: PropTypes.func
}
