import { connect } from 'react-redux'

import DeeplButton from 'components/administration/external_accounts/deepl_button/DeeplButton'

import {
  getApiKeyRequestRunning
} from 'selectors'

import {
  submitApiKeyStart
} from 'actions/api_keys'

const mapStateToProps = state => ({
  loading: getApiKeyRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onSubmit: submitApiKeyStart
  }
)(DeeplButton)
