import { connect } from 'react-redux'

import TagDeleteDialog from 'components/contact_management/tags_dialog/tag_delete_dialog'

import { getCmTagDeleteDialogOpen, getCmRequestRunning } from 'selectors/contact_management'

import {
  deleteTagStart as onConfirm,
  closeTagDeleteDialog as onCancel
} from 'actions/contact_management'

const mapStateToProps = (state, ownProps) => ({
  open: getCmTagDeleteDialogOpen(state),
  running: getCmRequestRunning(state),
  tag: ownProps.tag
})

export default connect(mapStateToProps, {
  onCancel,
  onConfirm
})(TagDeleteDialog)
