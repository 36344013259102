/* global window, document */
import { call, put, takeEvery, select, all } from 'redux-saga/effects'

import * as Actions from 'actions/environment'
import * as Selectors from 'selectors'
import { whitelabelRequestSuccess } from 'actions/whitelabel'
import { setCookieOption } from 'actions/user'
import { updateColorInMeta } from 'utils/dom'
import getStage from 'api/stage'
import getWhiteLabelConfig from 'api/whitelabel'

export function* initEnvironment() {
  try {
    const config = yield call(getWhiteLabelConfig, window.location.hostname)
    yield put(whitelabelRequestSuccess(config))

    if (config.defaultCookieOption) {
      yield put(setCookieOption({ cookieOption: config.defaultCookieOption }))
    }
  } catch (e) { // eslint-disable-line
  }

  yield put(Actions.changeWidthAndHeight({
    width: window.innerWidth,
    height: window.innerHeight
  }))

  try {
    const { stage } = yield call(getStage)
    yield put(Actions.stageRequestSuccess(stage))
  } catch (e) { // eslint-disable-line
    yield put(Actions.stageRequestSuccess('staging'))
  }
}

export function* whitelabel() {
  const favicon = yield select(Selectors.getWhitelabelFavicon)
  const title = yield select(Selectors.getWhitelabelTitle)
  const colors = yield select(Selectors.getWhitelabelColors)

  if (title) {
    document.title = title
  }

  if (favicon) {
    const faviconLink = document.getElementById('favicon-link')
    faviconLink.setAttribute('href', favicon)
  }

  yield call(updateColorInMeta, colors.get('primary'))
}

export function* watchInitEnvironment() {
  yield takeEvery(Actions.initEnvironment, initEnvironment)
}

export function* watchWhitelabel() {
  yield takeEvery(whitelabelRequestSuccess, whitelabel)
}

export default function* environmentSaga() {
  yield all([
    watchInitEnvironment(),
    watchWhitelabel()
  ])
}
