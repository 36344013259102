import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/darknet'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* searchRequestStart() {
  const body = yield select(Selectors.getDarknetSearchBody)

  try {
    const result = yield call(Api.searchDarknetEntries, body)
    yield put(Actions.searchRequestSuccess(result))
  } catch (err) {
    yield put(Actions.searchRequestError(err))
  }
}

export function* onSortByChange({ payload }) {
  yield put(Actions.uiOnSortByChange(payload))
  yield put(Actions.searchRequestStart())
}

export function* onSortDirectionChange({ payload }) {
  yield put(Actions.uiOnSortDirectionChange(payload))
  yield put(Actions.searchRequestStart())
}

export function* watchSearchRequestStart() {
  yield takeEvery(Actions.searchRequestStart, searchRequestStart)
}

export function* watchOnSortByChange() {
  yield takeEvery(Actions.onSortByChange, onSortByChange)
}

export function* watchOnSortDirectionChange() {
  yield takeEvery(Actions.onSortDirectionChange, onSortDirectionChange)
}

export default function* searchSaga() {
  yield all([
    watchOnSortByChange(),
    watchOnSortDirectionChange(),
    watchSearchRequestStart()
  ])
}
