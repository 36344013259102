import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { Typography } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  container: {
    marginLeft: '2em'
  },
  keyFigure: {
    fontSize: 'smaller',
    color: theme.palette.text.secondary
  },
  value: {
  }
}))

const usePercent = value => `${Math.round(value * 100)}%`

export default function AverageStatistics({ avgHackishness }) {
  if (!avgHackishness) {
    return null
  }

  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <div className={classes.container}>
      <Typography className={classes.keyFigure}>
        {i18n.get('hackishness')}
      </Typography>
      <Typography className={classes.value}>
        {usePercent(avgHackishness)}
      </Typography>
    </div>
  )
}

AverageStatistics.propTypes = {
  avgHackishness: PropTypes.number
}
