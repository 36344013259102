import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as Actions from 'actions/news'

import * as Selectors from 'selectors'
import { fetchChartData } from 'sagas/charts'

export function* fetchFilterTimeline({ payload: { dateFrom, dateTo } }) {
  const chart = yield select(Selectors.getFilterTimeline)

  const searchBody = yield select(Selectors.getSearchBody, dateFrom, dateTo)

  const { timeline: result } = yield call(fetchChartData, chart, searchBody)

  yield put(Actions.fetchFilterTimelineSuccess(result))
}

export function* watchFetchFilterTimeline() {
  yield takeLatest(Actions.fetchFilterTimelineStart, fetchFilterTimeline)
}

export default function* newsSaga() {
  yield all([
    watchFetchFilterTimeline()
  ])
}
