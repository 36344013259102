import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  journalists: {
    entities: [],
    paginator: {
      totalCount: 0,
      pageSize: 10,
      currentPage: 1
    }
  },
  outlets: {
    entities: [],
    paginator: {
      totalCount: 0,
      pageSize: 10,
      currentPage: 1
    }
  },
  articles: {
    entities: [],
    cursor: '',
    previousCursor: '',
    pageSize: 10
  }
})

export default handleActions({
  [Actions.setDrillDownResult]: (state, { payload: { entity, value } }) => state.set(entity, fromJS(value)),
  [combineActions(
    Actions.closeDrillDownDialog,
    Actions.clearModuleData
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
