import { connect } from 'react-redux'

import FacebookEditor from 'components/content_desk/content_edit_dialog/content_editor/facebook_editor'

import {
  setContentFormData,
  uploadFacebookMediaFileStart,
  toggleEditorAiMode,
  setAiSelectedText,
  buildSocialMediaLinkStart
} from 'actions/content_desk'

import {
  toggleUnsplashComponent as toggleUnsplash
} from 'actions/unsplash'

import { navigate } from 'actions/navigation'

import {
  getCDeskContentFormData,
  getStaticFacebookAnalyticsPages,
  getCDeskMediaFileUploading,
  getCDeskEditorAiMode,
  getShowUnsplashComponent,
  getFacebookAccountConnected,
  getCDeskUploadedMediaFilesTotalSize,
  getSocialMediaLinkLoading,
  getSocialMediaLinkBuildFailed
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  pages: getStaticFacebookAnalyticsPages(state),
  mediaFileUploading: getCDeskMediaFileUploading(state),
  aiMode: getCDeskEditorAiMode(state),
  unsplashIsVisible: getShowUnsplashComponent(state),
  facebookAccountConnected: getFacebookAccountConnected(state),
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state),
  linkLoading: getSocialMediaLinkLoading(state),
  socialMediaLinkBuildFailed: getSocialMediaLinkBuildFailed(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    onUpload: uploadFacebookMediaFileStart,
    onSelect: setAiSelectedText,
    toggleEditorAiMode,
    toggleUnsplash,
    navigate,
    buildSocialMediaLink: buildSocialMediaLinkStart
  }
)(FacebookEditor)
