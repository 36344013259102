import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/charts'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  authorBirthDateRanges: [],
  authorCities: [],
  authorCountries: [],
  authorSexes: [],
  ave: {},
  buzz: {},
  channels: [],
  circulation: {},
  countries: [],
  customTags: [],
  emojis: [],
  entities: [],
  hashtags: [],
  interactions: {},
  languages: [],
  mediaReviewCodes: [],
  mediaTopics: [],
  mediaTypes: [],
  reach: {},
  sentiment: {},
  sentiments: [],
  timeline: [],
  tonalities: [],
  tonality: {},
  visits: {}
})

export default handleActions({
  [Actions.aggregationsRequestSuccess]: (state, { payload: aggregations }) => (
    aggregations ? initialState.merge(fromJS((aggregations))) : initialState
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
