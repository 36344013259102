import { connect } from 'react-redux'

import ExternalAccounts from 'components/administration/external_accounts'

import {
  getGoogleAnalyticsEnabled,
  getYoutubeAnalyticsEnabled,
  getGoogleAnalyticsClientId,
  getFacebookAccountConnected,
  getFacebookAnalyticsClientId,
  getLinkedInClientId,
  getLinkedInAnalyticsEnabled,
  getDeeplApiKey,
  getStaticExternalAccounts,
  getBaseUrl,
  getCapabilities
} from 'selectors'

import {
  authenticateGoogleAnalyticsStart,
  authenticateYoutubeAnalyticsStart,
  authenticateFacebookStart,
  authenticateLinkedInStart,
  revokeGoogleAnalyticsStart,
  revokeYoutubeAnalyticsStart,
  revokeLinkedInStart,
  revokeTwitterStart,
  revokeFacebookStart
} from 'actions/auth'

const mapStateToProps = state => ({
  googleAnalyticsEnabled: getGoogleAnalyticsEnabled(state),
  youtubeAnalyticsEnabled: getYoutubeAnalyticsEnabled(state),
  googleAnalyticsClientId: getGoogleAnalyticsClientId(state),
  facebookAccountConnected: getFacebookAccountConnected(state),
  facebookAnalyticsClientId: getFacebookAnalyticsClientId(state),
  linkedInAnalyticsEnabled: getLinkedInAnalyticsEnabled(state),
  linkedInClientId: getLinkedInClientId(state),
  deeplApiKey: getDeeplApiKey(state),
  externalAccounts: getStaticExternalAccounts(state),
  baseUrl: getBaseUrl(state),
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onGoogleAnalyticsSubmit: authenticateGoogleAnalyticsStart,
    onGoogleAnalyticsRevoke: revokeGoogleAnalyticsStart,
    onYoutubeAnalyticsSubmit: authenticateYoutubeAnalyticsStart,
    onYoutubeAnalyticsRevoke: revokeYoutubeAnalyticsStart,
    onFacebookSubmit: authenticateFacebookStart,
    onFacebookRevoke: revokeFacebookStart,
    onLinkedInSubmit: authenticateLinkedInStart,
    onLinkedInRevoke: revokeLinkedInStart,
    onTwitterRevoke: revokeTwitterStart
  }
)(ExternalAccounts)
