import { connect } from 'react-redux'

import { Capabilities } from 'static/constants'

import KpiStatistics from 'components/content_desk_new/kpi_statistics/'

import {
} from 'actions/content_desk'

import {
  getCDeskKpiStatistics,
  getCDeskContentTabView,
  getCDeskKpiStatisticsFilters,
  getCDeskLoadingKpiStatistics,
  hasCapability
} from 'selectors'

const mapStateToProps = state => ({
  hasSocialMedia: (
    !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_LINKED_IN)
    || !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_FACEBOOK)
  ),
  kpiStatistics: getCDeskKpiStatistics(state),
  view: getCDeskContentTabView(state),
  timePeriod: getCDeskKpiStatisticsFilters(state).get('dateRange'),
  loadingKpiStatistics: getCDeskLoadingKpiStatistics(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(KpiStatistics)
