import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Paper,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material'

import TemplatesList from 'containers/email_templates_library/TemplatesList'
import { RaisedPrimaryButton } from 'containers/themed'
import ContentContainer from 'components/content_container/'

import { Capabilities } from 'static/constants'

const useStyles = makeStyles()((_theme, { readMode }) => ({
  container: {
    paddingTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    height: readMode ? '90%' : '95%',
    minHeight: '90%',
    overflow: 'auto'
  },
  paper: {
    height: '88vh'
  },
  circularLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '65vh'
  },
  loadMoreButtonContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    justifyContent: 'center',
    display: 'flex',
    width: '100%',
    overflow: 'hidden'
  }
}))

const TemplatesWrapper = ({
  loadTemplate,
  hasMore,
  loading,
  isPublicOnly,
  capabilities,
  readMode,
  onMount,
  onUnmount,
  togglePublicTemplates,
  onLoadMore
}) => {
  const { classes } = useStyles({ readMode })
  const i18n = useI18n()
  const hasPublicReadRights = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_READ_RIGHTS)

  useEffect(() => {
    onMount({ shared: isPublicOnly })

    return () => {
      onUnmount()
    }
  }, [])

  return (
    <Paper
      className={classes.paper}
      elevation={0}
    >
      <ContentContainer className={classes.container}>
        {(readMode && hasPublicReadRights) && (
          <FormControlLabel
            sx={{ marginLeft: '40px', marginBottom: '25px' }}
            control={(
              <Switch
                checked={isPublicOnly}
                onClick={() => togglePublicTemplates()}
                disabled={loading}
              />
            )}
            label={isPublicOnly ? i18n.get('show_my_templates') : i18n.get('show_public_templates')}
          />
        )}
        {loading ? (
          <div className={classes.circularLoader}>
            <CircularProgress
              variant="indeterminate"
              size={70}
            />
          </div>
        ) : <TemplatesList loadTemplate={loadTemplate} />}

      </ContentContainer>
      <div className={classes.loadMoreButtonContainer}>
        <RaisedPrimaryButton
          variant="contained"
          onClick={onLoadMore}
          disabled={!hasMore || loading}
        >
          {i18n.get('load_more')}
        </RaisedPrimaryButton>
      </div>
    </Paper>
  )
}

TemplatesWrapper.propTypes = {
  loadTemplate: PropTypes.func,
  hasMore: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isPublicOnly: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(ImmutableMap).isRequired,
  readMode: PropTypes.bool.isRequired,

  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  togglePublicTemplates: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired
}

export default TemplatesWrapper
