import { put, select, call, takeLatest, all } from 'redux-saga/effects'
import { fromJS } from 'immutable'

import * as Actions from 'actions/ai'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'
import * as AppActions from 'actions/app'

export function* aiAnalysis({ payload }) {
  try {
    let news = payload.news || fromJS([])

    const locale = yield select(Selectors.getAiSettingsLocale)

    news = news.map(n => ({
      id: n.get('id'),
      snippet: n.get('snippet'),
      headline: n.get('headline'),
      article_date: n.get('articleDate'),
      publication: {
        channel: {
          name: n.getIn(['publication', 'channel', 'name'])
        },
        name: n.getIn(['publication', 'name'])
      },
      key_figures: {
        reach: n.getIn(['keyFigures', 'reach']),
        interactions: n.getIn(['keyFigures', 'interactions'])
      }
    })).toJS()

    const body = { ...(payload || {}), locale, news }

    const result = yield call(Api.getNewsAiAnalysis, body)

    yield put(Actions.aiAnalysisSuccess(result))
  } catch (error) {
    yield put(Actions.aiAnalysisError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchAiAnalysis() {
  yield takeLatest(Actions.aiAnalysisStart, aiAnalysis)
}

export default function* shoppingCartSaga() {
  yield all([
    watchAiAnalysis()
  ])
}
