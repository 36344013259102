import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'

import NotificationsButton from 'containers/notifications/NotificationsButton'

const useStyles = makeStyles()({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  actionIcon: {
    color: '#ffffff'
  }
})

const Actions = () => {
  const { classes } = useStyles()
  const actions = []

  actions.push(
    <NotificationsButton />
  )

  return (
    <StaggeredList>
      <div className={classes.actionsWrapper}>
        {actions.map((action, index) => (
          <StaggeredListItem
            key={index}
            delay={index * 50}
          >
            {action}
          </StaggeredListItem>
        ))}
      </div>
    </StaggeredList>
  )
}

export default Actions
