import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { selectViewConfigPreset } from 'actions/news'
import { getGroupingSettingId } from 'selectors'

export default function NewsView(Comp, presetName, options = {}) {
  const WrapperComponent = ({ groupingSettingId, selectPreset, ...props }) => {
    useEffect(() => {
      let selectedPresetName = presetName

      if (groupingSettingId && presetName.startsWith('earned')) {
        switch (groupingSettingId) {
          case 1:
            selectedPresetName = 'earned'
            break
          case 2:
            selectedPresetName = 'earnedByCode'
            break
          case 3:
            selectedPresetName = 'earnedByCustomTag'
            break
          default:
            break
        }
      } else if (groupingSettingId === 4 && presetName === 'searchPool') {
        selectedPresetName = 'searchPoolByNewsguardRank'
      }

      selectPreset({
        name: selectedPresetName,
        ...options
      })
    }, [])

    return <Comp {...props} />
  }

  WrapperComponent.displayName = `NewsView(${Comp.name || Comp.displayName}[${presetName}])`

  WrapperComponent.propTypes = {
    groupingSettingId: PropTypes.number,
    selectPreset: PropTypes.func.isRequired
  }

  const mapStateToProps = state => ({
    groupingSettingId: getGroupingSettingId(state)
  })

  return connect(
    mapStateToProps,
    {
      selectPreset: selectViewConfigPreset
    }
  )(WrapperComponent)
}
