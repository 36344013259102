import { call, put, select, takeEvery, takeLatest, take, all } from 'redux-saga/effects'
import { fromJS } from 'immutable'

import { SearchFields } from 'static/constants'

import * as Actions from 'actions/publications'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { genericErrorMessage, exception } from 'actions/app'
import { uiAddFilters } from 'actions/ui'
import { setNewsView, newsRequestSuccess } from 'actions/news'
import { retriable } from 'utils/sagas'

export function* fetchPublicationsTry() {
  const body = yield select(Selectors.getPublicationsSearchBody)

  const result = yield call(Api.searchPublications, body)

  yield put(Actions.publicationsRequestSuccess(result))
}

export function* createPublication({ payload: body }) {
  try {
    const result = yield call(Api.createPublication, body)

    yield put(Actions.createPublicationSuccess(result.publication))
  } catch (error) {
    yield put(Actions.createPublicationError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* fetchPublicationsFail(error) {
  yield put(Actions.publicationsRequestError(error))
  yield put(exception(error))
}

export function* fetchPublications() {
  yield call(retriable, fetchPublicationsTry, fetchPublicationsFail)
}

export function* fetchMorePublicationsTry() {
  const from = yield select(Selectors.getPublicationsFrom)

  if (from) {
    const searchBody = yield select(Selectors.getPublicationsSearchBody)
    const body = { ...searchBody, from }
    const result = yield call(Api.searchPublications, body)
    yield put(Actions.morePublicationsRequestSuccess(result))
  }
}

export function* fetchMorePublicationsFail(error) {
  yield put(Actions.morePublicationsRequestError(error))
  yield put(exception(error))
}

export function* fetchMorePublications() {
  yield call(retriable, fetchMorePublicationsTry, fetchMorePublicationsFail)
}

export function* searchForPublication({ payload: { publication, targetView } }) {
  yield put(uiAddFilters([{
    filter: publication,
    field: SearchFields.PUBLICATIONS
  }]))
  yield take(newsRequestSuccess)
  yield put(setNewsView(targetView))
}

export function* searchForSelectedPublications({ payload: { targetView } }) {
  const selectedPublicationIds = yield select(Selectors.getSelectedPublicationIds)
  const allPublication = yield select(Selectors.getPublications)
  const selectedPublication = selectedPublicationIds
    .map(id => allPublication.find(publication => publication.get('id') === id))
    .toJS()
    .map(filter => ({ filter: fromJS(filter), field: SearchFields.PUBLICATIONS }))

  yield put(uiAddFilters(selectedPublication))
  yield take(newsRequestSuccess)
  yield put(setNewsView(targetView))
}

export function* setSortBy() {
  yield put(Actions.publicationsRequestStart())
}

export function* watchFetchPublications() {
  yield takeLatest(Actions.publicationsRequestStart, fetchPublications)
}

export function* watchFetchMorePublications() {
  yield takeEvery(Actions.morePublicationsRequestStart, fetchMorePublications)
}

export function* watchSetSortBy() {
  yield takeEvery(Actions.setSortBy, setSortBy)
}

export function* watchSearchForPublication() {
  yield takeLatest(Actions.searchForPublication, searchForPublication)
}

export function* watchSearchForSelectedPublications() {
  yield takeLatest(Actions.searchForSelectedPublications, searchForSelectedPublications)
}

export function* watchCreatePublication() {
  yield takeEvery(Actions.createPublicationStart, createPublication)
}

export default function* saga() {
  yield all([
    watchFetchPublications(),
    watchFetchMorePublications(),
    watchSetSortBy(),
    watchCreatePublication(),
    watchSearchForPublication(),
    watchSearchForSelectedPublications()
  ])
}
