import { all } from 'redux-saga/effects'
import searchSaga from './search'
import mainSaga from './main'

export default function* unsplashSaga() {
  yield all([
    searchSaga(),
    mainSaga()
  ])
}
