import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
  Typography,
  CircularProgress,
  Box,
  Slider
} from '@mui/material'
import { grey } from '@mui/material/colors'

import InfoIcon from '@mui/icons-material/Info'

import { formatNumber, separate } from 'utils/number'

import { Popover } from 'containers/themed'
import Email from 'containers/content_desk_new/contents/content_view/statistics_data/Email'
import LinkedIn from 'containers/content_desk_new/contents/content_view/statistics_data/LinkedIn'
import Facebook from 'containers/content_desk_new/contents/content_view/statistics_data/Facebook'
import OtsPressRelease from 'containers/content_desk_new/contents/content_view/statistics_data/OtsPressRelease'
import News from 'containers/content_desk_new/contents/content_view/statistics_data/News'

const useStyles = makeStyles()(theme => ({
  card: {
    borderRadius: 20,
    minHeight: 147,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200]
  },
  cardContent: {
    minHeight: 150,
    marginTop: 18,
    display: 'flex'
  },
  kpiWrapper: {
    height: '100%'
  },
  engagement: {
    fontSize: '30pt',
    fontWeight: '700',
    color: !theme.isDark && '#404040'
  },
  engagementLabel: {
    fontSize: '12pt',
    color: !theme.isDark && '#757575'
  },
  rootWrapper: {
    marginTop: '0.5em'
  },
  divider: {
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  },
  popover: {
    width: 300,
    maxHeight: 500
  },
  headlineWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: '1em'
  },
  infoIcon: {
    color: grey[600],
    alignSelf: 'flex-start'
  }
}))

function valueLabelFormat(value) {
  return `${value}%`
}

const StatisticsData = ({ data, type, requestRunning, showEarnedStats, onAccuracyChange }) => {
  const { classes } = useStyles()
  const [accuracy, setAccuracy] = useState([30])
  const i18n = useI18n()

  const loadOrShowData = value => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return value
  }

  const renderStatistics = () => {
    if (type === 'email') {
      return <Email data={data} />
    }

    if (type === 'linkedin') {
      return <LinkedIn data={data} />
    }

    if (type === 'facebook') {
      return <Facebook data={data} />
    }

    if (type === 'ots_press_release') {
      return <OtsPressRelease data={data} />
    }

    return null
  }

  const handleAccuracyChange = e => setAccuracy(e.target.value)

  return (
    <div className={classes.rootWrapper}>
      {renderStatistics()}
      {showEarnedStats && (
        <>
          <div className={classes.headlineWrapper}>
            <Typography
              className={classes.headline}
              variant="h6"
            >
              {i18n.get('earned')}
            </Typography>
            <Tooltip
              title={i18n.get('earned_media_accuracy_info')}
              arrow
              placement="top"
            >
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
            <Box sx={{ width: 250 }}>
              <Slider
                value={accuracy}
                valueLabelFormat={valueLabelFormat}
                valueLabelDisplay="auto"
                onChange={handleAccuracyChange}
                onMouseUp={() => onAccuracyChange({ earnedMediaAccuracy: `${accuracy[0]}%` })}
                step={10}
                min={20}
                max={80}
                marks
              />
            </Box>
          </div>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
            >
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.kpiWrapper}>
                    <div className={classes.engagementLabel}>{i18n.get('buzz')}</div>
                    <div
                      className={classes.engagement}
                      title={separate(data.getIn(['earned', 'stats', 'total']))}
                    >
                      {loadOrShowData(formatNumber(data.getIn(['earned', 'stats', 'total'])))}
                    </div>
                    <Popover
                      classes={{
                        paper: classes.popover
                      }}
                      handle={(
                        <Chip
                          size="small"
                          color="success"
                          variant="outlined"
                          label={i18n.get('view_details')}
                        />
                      )}
                    >
                      <div>
                        <News
                          news={data.getIn(['earned', 'news'])}
                          total={data.getIn(['earned', 'stats', 'total'])}
                        />
                      </div>
                    </Popover>

                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
            >
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.kpiWrapper}>
                    <div className={classes.engagementLabel}>
                      {i18n.get('reach')}
                    </div>
                    <div
                      className={classes.engagement}
                      title={separate(data.getIn(['earned', 'stats', 'reach']))}
                    >
                      {loadOrShowData(formatNumber(data.getIn(['earned', 'stats', 'reach'])))}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  )
}

StatisticsData.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  type: PropTypes.string.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  showEarnedStats: PropTypes.bool.isRequired,

  onAccuracyChange: PropTypes.func.isRequired
}

export default StatisticsData
