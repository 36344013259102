import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { Typography, Grid, MenuItem, Paper } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { IconButton, Menu } from 'containers/themed'
import InfoSummary from 'containers/darknet/InfoSummary'

import useI18n from 'hooks/useI18n'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FulltextDialog from './fulltext_dialog'
import SourceDialog from './source_dialog'

const InfoSummaryGrid = props => (
  <Grid
    item
    xl={4}
    lg={4}
    md={12}
    sm={12}
    xs={12}
    {...props}
  />
)

const TitleGrid = props => (
  <Grid
    item
    xl={4}
    lg={4}
    md={12}
    sm={12}
    xs={12}
    {...props}
  />
)

const SnippetGrid = props => (
  <Grid
    item
    xl={4}
    lg={4}
    md={12}
    sm={12}
    xs={12}
    {...props}
  />
)

const useStyles = makeStyles()(theme => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginRight: 15
  },
  entryContainer: {
    '&:not(:last-child)': {
      margin: '0 0 1rem 0'
    },
    position: 'relative'
  },
  entry: {
    padding: '1em 2em 1em 1em',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  },
  textWrapper: {
    padding: '0 1em 0 0',
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    fontSize: 'inherit',
    lineHeight: '1.25',
    '& .clip': {
      color: theme.nrx.colors.highlighting
    },
    [theme.breakpoints.down('lg')]: {
      padding: '2em 1em 0 0'
    }
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: 0
  }
}))

export default function Entry({ entry, query, location }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const { title, snippet, body, network } = entry.toJS()

  const [showFulltext, setShowFulltext] = useState(false)
  const [showSource, setShowSource] = useState(false)

  const handleFulltextClick = () => setShowFulltext(true)
  const handleSourceClick = () => setShowSource(true)

  const handleClose = () => {
    setShowFulltext(false)
    setShowSource(false)
  }

  return (
    <Paper className={classes.entryContainer}>
      <Grid
        className={classes.entry}
        container
        // alignItems="center"
        justifyContent="space-between"
      >
        <InfoSummaryGrid>
          <InfoSummary
            entry={entry}
            query={query}
          />
        </InfoSummaryGrid>

        <TitleGrid>
          <Typography
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{
              __html: (title ? title.trim() : '')
            }}
          />
        </TitleGrid>

        <SnippetGrid>
          <Typography
            className={classes.textWrapper}
            dangerouslySetInnerHTML={{
              __html: (snippet ? snippet.trim() : '')
            }}
          />
        </SnippetGrid>
      </Grid>

      <div className={classes.menuContainer}>
        <Menu
          handle={
            (
              <IconButton
                color="inherit"
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          <MenuItem onClick={() => handleSourceClick()}>
            {i18n.get('show_source')}
          </MenuItem>
          <MenuItem onClick={() => handleFulltextClick()}>
            {i18n.get('show_fulltext')}
          </MenuItem>
        </Menu>
      </div>

      <FulltextDialog
        text={body}
        open={showFulltext}
        onClose={handleClose}
      />
      <SourceDialog
        source={location}
        open={showSource}
        network={network}
        onClose={handleClose}
      />
    </Paper>
  )
}

Entry.propTypes = {
  entry: PropTypes.instanceOf(Map),
  query: PropTypes.instanceOf(Map),
  location: PropTypes.string
}
