/* eslint-disable */
import { ListNode } from '@lexical/list'

export class ExtendedListNode extends ListNode {
    static getType() {
        return 'extended-list'
    }

    static clone(node) {
        return new ExtendedListNode(node.__listType, node.__start, node.__key)
    }

    exportDOM(editor) {
        const element = super.createDOM(editor._config, editor)

        // Remove additional class and dir attribute on exported html.
        element.removeAttribute('dir')
        element.removeAttribute('class')

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        return ListNode.importJSON(serializedNode)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'extended-list',
            version: 1
        }
    }
}