import { connect } from 'react-redux'

import CampaignForm from 'components/content_desk_new/campaigns/campaign_form/CampaignForm'

import {
  getCDeskCampaignFormData,
  getCDeskShowNewCampaignDialog,
  getCDeskCampaignDialogEditMode,
  getCDeskAssignees,
  getCDeskCampaignNameValid
} from 'selectors'
import {
  updateCampaignFormData as onUpdate,
  closeCampaignForm as onClose,
  saveCampaignStart as onSave,
  resetCampaignForm as resetForm
} from 'actions/content_desk'

const mapStateToProps = state => ({
  data: getCDeskCampaignFormData(state),
  open: getCDeskShowNewCampaignDialog(state),
  isInEditMode: getCDeskCampaignDialogEditMode(state),
  campaignNameValid: getCDeskCampaignNameValid(state),
  assignees: getCDeskAssignees(state)
})

export default connect(
  mapStateToProps,
  {
    onUpdate,
    onClose,
    onSave,
    resetForm
  }
)(CampaignForm)
