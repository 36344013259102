import { connect } from 'react-redux'

import PdfMedia from 'components/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/pdf_media'

const mapStateToProps = (_state, { mediaFile, width, height }) => ({
  mediaFile,
  width,
  height
})

export default connect(
  mapStateToProps,
  {}
)(PdfMedia)
