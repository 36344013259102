import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  Card,
  Box
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { rgbaColorFromHex } from 'utils/color'

import { Button } from 'containers/themed'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  textContainer: {
    width: '60%',
    padding: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  container: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  unlockIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`,

    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  orderIconContainer: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40%',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`,

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  successImageContainer: {
    textAlign: 'center'
  },
  orderConditions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',

    '& a': {
      color: theme.palette.secondary.main
    }
  },
  termsAndConditionsText: {
    marginTop: 10
  }
}))

export default function OrderForm({
  card,
  paidFeature,
  ordering,
  status,
  onOrderClick,
  onTraceClick,
  termsAndConditionsLink,
  privacyLink,
  benefits,
  logo,
  unlockTitle,
  unlockSubtitle,
  unlockText,
  subscriptionInfo
}) {
  const [showOrder, setShowOrder] = useState(false)
  const [orderCondition1, setOrderCondition1] = useState(false)
  const [orderCondition2, setOrderCondition2] = useState(false)
  const { classes } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    onTraceClick({
      measurement: 'paid_feature_order_form_open_click',
      tags: {
        paid_feature_id: paidFeature.get('paidFeatureId'),
        paid_feature_name: paidFeature.get('id')
      }
    })
  }, [onTraceClick])

  const isPending = status === 'pending'

  const Comp = card ? Card : Box

  if (!showOrder && !isPending) {
    return (
      <Comp className={classes.container}>
        <div className={classes.textContainer}>
          <Typography
            variant="h4"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: unlockTitle
            }}
          />
          <Typography
            variant="body1"
            color="text.secondary"
            dangerouslySetInnerHTML={{
              __html: unlockText
            }}
          />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onTraceClick({
                measurement: 'paid_feature_order_form_unlock_click',
                tags: {
                  paid_feature_id: paidFeature.get('paidFeatureId'),
                  paid_feature_name: paidFeature.get('id')
                }
              })

              setShowOrder(true)
            }}
          >
            {i18n.get('unlock')}
          </Button>
        </div>
        <div className={classes.unlockIconContainer}>
          <img
            src={logo}
            alt="feature"
            width="50%"
          />
        </div>
      </Comp>
    )
  }

  return (
    <Comp className={classes.container}>
      <div className={classes.textContainer}>
        {!isPending ? (
          <>
            <Typography
              variant="h4"
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: unlockTitle
              }}
            />
            <Typography
              variant="h5"
              dangerouslySetInnerHTML={{
                __html: unlockSubtitle
              }}
            />
            <List>
              {benefits.map(benefit => (
                <ListItem key={benefit}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>
                  <Typography color="text.secondary">
                    {benefit}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <div className={classes.successImageContainer}>
              <img
                src="https://static.pressrelations.de/pics/groot/themax/success_flag.png"
                alt="newspaper"
                width="100"
              />
            </div>
            <Typography
              variant="h5"
              gutterBottom
            >
              {i18n.get('paid_feature_order_success_title')}
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              className={classes.successMessage}
              dangerouslySetInnerHTML={{
                __html: i18n.get('paid_feature_order_success_message', { email: paidFeature.getIn(['config', 'supportMail']) })
              }}
            />
          </>
        )}
      </div>
      <div className={classes.orderIconContainer}>
        <Typography variant="h4">
          {paidFeature.getIn(['config', 'price'])} {paidFeature.getIn(['config', 'currency'])}
        </Typography>
        {paidFeature.getIn(['config', 'vat']) > 0 && (
          <Typography
            variant="body1"
            color="text.secondary"
          >
            + {paidFeature.getIn(['config', 'vat'])}% {i18n.get('vat')}
          </Typography>
        )}
        <Typography variant="h6">
          {subscriptionInfo}
        </Typography>

        <div className={classes.orderConditions}>
          <Checkbox
            checked={orderCondition1}
            onChange={() => setOrderCondition1(!orderCondition1)}
            disabled={isPending}
          />
          <div
            className={classes.termsAndConditionsText}
            dangerouslySetInnerHTML={{
              __html: i18n.get('paid_feature_order_terms_and_conditions_1', { product: paidFeature.get('name') })
            }}
          />
        </div>
        <div className={classes.orderConditions}>
          <Checkbox
            checked={orderCondition2}
            onChange={() => setOrderCondition2(!orderCondition2)}
            disabled={isPending}
          />
          <div
            className={classes.termsAndConditionsText}
            dangerouslySetInnerHTML={{
              __html: i18n.get('paid_feature_order_terms_and_conditions_2', { terms: termsAndConditionsLink, privacy: privacyLink })
            }}
          />
        </div>

        <br />

        <Button
          variant="contained"
          color="primary"
          onClick={() => onOrderClick(paidFeature.get('paidFeatureId'))}
          disabled={isPending || !(orderCondition1 && orderCondition2)}
          saving={ordering}
        >
          {i18n.get('order_now')}
        </Button>
      </div>
    </Comp>
  )
}

OrderForm.defaultProps = {
  logo: 'https://static.pressrelations.de/pics/groot/paid_features/ai_summaries.png'
}

OrderForm.propTypes = {
  paidFeature: PropTypes.instanceOf(Map).isRequired,
  ordering: PropTypes.bool.isRequired,
  status: PropTypes.string,
  termsAndConditionsLink: PropTypes.string,
  privacyLink: PropTypes.string,
  card: PropTypes.bool,
  benefits: PropTypes.array.isRequired,
  logo: PropTypes.string,
  unlockTitle: PropTypes.string,
  unlockSubtitle: PropTypes.string,
  unlockText: PropTypes.string,
  subscriptionInfo: PropTypes.string,

  onOrderClick: PropTypes.func.isRequired,
  onTraceClick: PropTypes.func.isRequired
}
