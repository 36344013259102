import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { makeStyles } from 'tss-react/mui'

import { Input } from 'containers/themed'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Switch,
  FormControlLabel
} from '@mui/material'
import CustomTag from './custom_tag'

const useStyles = makeStyles()({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  customTags: {
    width: '100%'
  },
  searchResultsHint: {
    marginTop: 15,
    textAlign: 'center'
  }
})

export default function FilterableCustomTagList({
  assignedLabel,
  assignLabel,
  customTagGroups,
  customTags,
  grouped,
  i18n,
  isAssigned,
  isSaving,
  onAddClick,
  onGroupedToggle,
  onRemoveClick
}) {
  const { classes } = useStyles()
  const maxItems = 200
  const [filterValue, setFilterValue] = useState('')
  const filterCustomTags = values => values.filter(c => c.get('name').toLowerCase().includes(filterValue.toLowerCase()))
  const filteredCustomTags = filterCustomTags(customTags)
  const onFilterChange = e => setFilterValue(e.target.value)

  return (
    <>
      <Input
        label={i18n.get('search_for_pin_board')}
        value={filterValue}
        onChange={onFilterChange}
      />

      <br />
      <br />

      <div className={classes.header}>
        <p>{i18n.get('all_pin_boards')}</p>

        {!customTagGroups.isEmpty() && (
          <FormControlLabel
            control={(
              <Switch
                checked={grouped}
                onChange={() => onGroupedToggle()}
                color="primary"
              />
            )}
            label={i18n.get('show_groups')}
          />
        )}
      </div>

      {grouped ? (
        <>
          {customTagGroups.map(customTagGroup => {
            const groupCustomTags = filterCustomTags(customTagGroup.get('customTagsCustomTagGroups').map(c => c.get('customTag')))

            if (groupCustomTags.isEmpty()) {
              return null
            }

            return (
              <Accordion
                key={customTagGroup.get('id')}
                defaultExpanded={customTags.size < 100 || (customTagGroup.get('id') === customTagGroups.first().get('id'))}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{customTagGroup.get('name')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.customTags}>
                    {filterCustomTags(customTagGroup
                      .get('customTagsCustomTagGroups')
                      .map(c => c.get('customTag')))
                      .map(customTag => (
                        <CustomTag
                          key={customTag.get('id')}
                          assignedLabel={assignedLabel}
                          assignLabel={assignLabel}
                          customTag={customTag}
                          onAddClick={onAddClick}
                          onRemoveClick={onRemoveClick}
                          isAssigned={isAssigned(customTag)}
                          isSaving={isSaving && isSaving(customTag)}
                        />
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </>
      ) : (
        <>
          {filteredCustomTags.take(maxItems).map(customTag => (
            <CustomTag
              key={customTag.get('id')}
              assignedLabel={assignedLabel}
              assignLabel={assignLabel}
              customTag={customTag}
              onAddClick={onAddClick}
              onRemoveClick={onRemoveClick}
              isAssigned={isAssigned(customTag)}
              isSaving={isSaving && isSaving(customTag)}
            />
          ))}
          {(maxItems < filteredCustomTags.size) && (
            <div className={classes.searchResultsHint}>
              {i18n.get('custom_tags_search_results_hint', { count: filteredCustomTags.size })}
            </div>
          )}
        </>
      )}
    </>
  )
}

FilterableCustomTagList.propTypes = {
  customTags: PropTypes.instanceOf(List).isRequired,
  customTagGroups: PropTypes.instanceOf(List).isRequired,
  i18n: PropTypes.object.isRequired,
  assignedLabel: PropTypes.string,
  assignLabel: PropTypes.string,
  grouped: PropTypes.bool,

  onAddClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  isAssigned: PropTypes.func.isRequired,
  isSaving: PropTypes.func,
  onGroupedToggle: PropTypes.func.isRequired
}
