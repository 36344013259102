import { connect } from 'react-redux'

import BasicSettings from 'components/personal_settings/basic_settings'
import { getI18n, getUser, getPreviewUrl, getUserImage } from 'selectors'
import { updateUserRequestStart, setUser } from 'actions/user'
import { uiSetPreviewUrl, uiSetUserImage } from 'actions/personal_settings'

const mapStateToProps = state => ({
  user: getUser(state),
  i18n: getI18n(state),
  previewUrl: getPreviewUrl(state),
  uploadedPhoto: getUserImage(state)
})

export default connect(
  mapStateToProps,
  {
    onSave: updateUserRequestStart,
    onChange: setUser,
    onPreviewUrlChange: uiSetPreviewUrl,
    onImageUpload: uiSetUserImage
  }
)(BasicSettings)
