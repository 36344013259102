import { connect } from 'react-redux'

import AppBar from 'components/anewstip/app_bar'

const mapStateToProps = _state => ({
})

export default connect(
  mapStateToProps,
  {
  }
)(AppBar)
