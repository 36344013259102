import React from 'react'
import PropTypes from 'prop-types'
import { fromJS } from 'immutable'
import { Dialog, RaisedPrimaryButton, Button, Input } from 'containers/themed'

import MailAutocomplete from 'containers/MailAutocomplete'
import { CardContent } from '@mui/material'

export default function EditDialog({
  active,
  i18n,
  mailingList,
  running,
  onHide,
  onSubmit,
  onChange
}) {
  const handleNameChange = value => {
    onChange(mailingList.set('name', value))
  }

  const handleRecipientsChange = value => {
    onChange(mailingList.set('recipients', fromJS(value)))
  }

  const handleSave = () => {
    onSubmit(mailingList)
  }

  const isValid = () => {
    const namePresent = mailingList.get('name').length > 0
    const recipientsPresent = mailingList.get('recipients').size > 0

    return namePresent && recipientsPresent
  }

  const renderActions = () => {
    const actions = [
      <Button
        key="cancel"
        onClick={onHide}
      >
        {i18n.get('cancel')}
      </Button>,
      <RaisedPrimaryButton
        key="save"
        loading={running}
        onClick={() => handleSave()}
        disabled={!isValid() || running}
      >
        {i18n.get('save')}
      </RaisedPrimaryButton>
    ]

    return actions
  }

  const renderBody = () => {
    const recipients = mailingList.get('recipients').toJS()

    return (
      <CardContent>
        <Input
          disabled={running}
          type="text"
          label={i18n.get('name')}
          value={mailingList.get('name')}
          onChange={e => handleNameChange(e.target.value)}
        />
        <br />
        <br />
        <MailAutocomplete
          disabled={running}
          onChange={value => handleRecipientsChange(value)}
          value={recipients}
        />
      </CardContent>
    )
  }

  let title = i18n.get('create_mailing_list')

  if (mailingList.get('id')) {
    title = i18n.get('edit_mailing_list')
  }

  return (
    <Dialog
      open={active}
      title={title}
      actions={renderActions()}
      onClose={onHide}
    >
      {renderBody()}
    </Dialog>
  )
}

EditDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  mailingList: PropTypes.object.isRequired,
  running: PropTypes.bool.isRequired,

  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}
