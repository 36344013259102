import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/publications'
import * as AppActions from 'actions/app'
import * as NewsActions from 'actions/news'

export const initialState = fromJS({
  total: 0,
  publications: [],
  newPublication: null
})

const mapPublications = publications => fromJS(publications)

export default handleActions({
  [Actions.publicationsRequestSuccess]: (state, { payload: { publications, total } }) => state.merge({
    publications: mapPublications(publications),
    total
  }),
  [Actions.morePublicationsRequestSuccess]: (state, { payload: { publications } }) => (
    state.update('publications', old => old.concat(mapPublications(publications)))
  ),
  [Actions.createPublicationSuccess]: (state, { payload: publication }) => state.set('newPublication', fromJS(publication)),
  [Actions.openPublicationForm]: state => state.set('newPublication', null),
  [NewsActions.hideEditNewsDialog]: state => state.set('newPublication', null),
  [Actions.resetCreatedPublication]: state => state.set('newPublication', null),
  [AppActions.resetState]: () => initialState
}, initialState)
