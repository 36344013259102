/* eslint-disable */
import { useEffect } from 'react'

import {
  $createTextNode,
  $isElementNode,
  $isLineBreakNode,
  $isTextNode
} from 'lexical'

import {
  $createAutoLinkNode,
  $isAutoLinkNode,
  $isLinkNode,
  AutoLinkNode
} from '@lexical/link'

import { mergeRegister } from '@lexical/utils'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { ExtendedTextNode } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/nodes/ExtendedTextNode'

const URL_MATCHER = /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
const EMAIL_MATCHER = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

const MATCHERS = [
  text => {
    const match = URL_MATCHER.exec(text)

    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: match[0]
      }
    )
  },
  text => {
    const match = EMAIL_MATCHER.exec(text)

    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`
      }
    )
  }
]

export const createLinkMatcherWithRegExp = (regExp, urlTransformer) => {
  return text => {
    const match = regExp.exec(text)

    if (match === null) {
      return null
    }

    return {
      index: match.index,
      length: match[0].length,
      text: match[0],
      url: urlTransformer(text)
    }
  }
}

const findFirstMatch = (text, matchers) => {
  for (let i = 0; i < matchers.length; i++) {
    const match = matchers[i](text)

    if (match) {
      return match
    }
  }

  return null
}

const PUNCTUATION_OR_SPACE = /[.,;\s]/

const isSeparator = (char) => PUNCTUATION_OR_SPACE.test(char)

const endsWithSeparator = (textContent) => isSeparator(textContent[textContent.length - 1])

const startsWithSeparator = (textContent) => isSeparator(textContent[0])

const isPreviousNodeValid = (node) => {
  let previousNode = node.getPreviousSibling()

  if ($isElementNode(previousNode)) {
    previousNode = previousNode.getLastDescendant()
  }

  return (
    previousNode === null
    || $isLineBreakNode(previousNode)
    || ($isTextNode(previousNode)
      && endsWithSeparator(previousNode.getTextContent()))
  )
}

const isNextNodeValid = (node) => {
  let nextNode = node.getNextSibling()

  if ($isElementNode(nextNode)) {
    nextNode = nextNode.getFirstDescendant()
  }

  return (
    nextNode === null
    || $isLineBreakNode(nextNode)
    || ($isTextNode(nextNode) && startsWithSeparator(nextNode.getTextContent()))
  )
}

const isContentAroundIsValid = (matchStart, matchEnd, text, node) => {
  const contentBeforeIsValid = matchStart > 0
    ? isSeparator(text[matchStart - 1])
    : isPreviousNodeValid(node)

  if (!contentBeforeIsValid) {
    return false
  }

  const contentAfterIsValid = matchEnd < text.length
    ? isSeparator(text[matchEnd])
    : isNextNodeValid(node)

  return contentAfterIsValid
}

const handleLinkCreation = (node, matchers) => {
  const nodeText = node.getTextContent()
  let text = nodeText
  let invalidMatchEnd = 0
  let remainingTextNode = node
  let match

  while ((match = findFirstMatch(text, matchers)) && match !== null) {
    const matchStart = match.index
    const matchLength = match.length
    const matchEnd = matchStart + matchLength
    const isValid = isContentAroundIsValid(
      invalidMatchEnd + matchStart,
      invalidMatchEnd + matchEnd,
      nodeText,
      node
    )

    if (isValid) {
      let linkTextNode

      if (invalidMatchEnd + matchStart === 0) {
        [linkTextNode, remainingTextNode] = remainingTextNode.splitText(
          invalidMatchEnd + matchLength
        )
      } else {
        [, linkTextNode, remainingTextNode] = remainingTextNode.splitText(
          invalidMatchEnd + matchStart,
          invalidMatchEnd + matchStart + matchLength
        )
      }

      const linkNode = $createAutoLinkNode(match.url, match.attributes)
      const textNode = $createTextNode(match.text)
      textNode.setFormat(linkTextNode.getFormat())
      textNode.setDetail(linkTextNode.getDetail())
      linkNode.append(textNode)
      linkTextNode.replace(linkNode)
      invalidMatchEnd = 0
    } else {
      invalidMatchEnd += matchEnd
    }

    text = text.substring(matchEnd)
  }
}

const handleLinkEdit = (linkNode, matchers) => {
  // Check children are simple text
  const children = linkNode.getChildren()
  const childrenLength = children.length
  for (let i = 0; i < childrenLength; i++) {
    const child = children[i]

    if (!$isTextNode(child) || !child.isSimpleText()) {
      replaceWithChildren(linkNode)

      return
    }
  }

  // Check text content fully matches
  const text = linkNode.getTextContent()
  const match = findFirstMatch(text, matchers)

  if (match === null || match.text !== text) {
    replaceWithChildren(linkNode)

    return
  }

  // Check neighbors
  if (!isPreviousNodeValid(linkNode) || !isNextNodeValid(linkNode)) {
    replaceWithChildren(linkNode)

    return
  }

  const url = linkNode.getURL()

  if (url !== match.url) {
    linkNode.setURL(match.url)
  }

  if (match.attributes) {
    const rel = linkNode.getRel()

    if (rel !== match.attributes.rel) {
      linkNode.setRel(match.attributes.rel || null)
    }

    const target = linkNode.getTarget()

    if (target !== match.attributes.target) {
      linkNode.setTarget(match.attributes.target || null)
    }
  }
}

// Bad neighbours are edits in neighbor nodes that make AutoLinks incompatible.
// Given the creation preconditions, these can only be simple text nodes.
const handleBadNeighbors = (textNode, matchers) => {
  const previousSibling = textNode.getPreviousSibling()
  const nextSibling = textNode.getNextSibling()
  const text = textNode.getTextContent()

  if ($isAutoLinkNode(previousSibling) && !startsWithSeparator(text)) {
    previousSibling.append(textNode)
    handleLinkEdit(previousSibling, matchers)
  }

  if ($isAutoLinkNode(nextSibling) && !endsWithSeparator(text)) {
    replaceWithChildren(nextSibling)
    handleLinkEdit(nextSibling, matchers)
  }
}

const replaceWithChildren = (node) => {
  const children = node.getChildren()
  const childrenLength = children.length

  for (let j = childrenLength - 1; j >= 0; j--) {
    node.insertAfter(children[j])
  }

  node.remove()

  return children.map(child => child.getLatest())
}

const useAutoLink = (editor, matchers) => {
  useEffect(() => {
    if (!editor.hasNodes([AutoLinkNode])) {
      throw new Error('LexicalAutoLinkPlugin: AutoLinkNode not registered on editor')
    }

    return mergeRegister(
      editor.registerNodeTransform(ExtendedTextNode, textNode => {
        const parent = textNode.getParentOrThrow()
        const previous = textNode.getPreviousSibling()

        if ($isAutoLinkNode(parent)) {
          handleLinkEdit(parent, matchers)
        } else if (!$isLinkNode(parent)) {
          if (textNode.isSimpleText() && (startsWithSeparator(textNode.getTextContent()) || !$isAutoLinkNode(previous))) {
            handleLinkCreation(textNode, matchers)
          }
          handleBadNeighbors(textNode, matchers)
        }
      })
    )
  }, [editor, matchers])
}

const AutoLinkPlugin = () => {
  const [editor] = useLexicalComposerContext()

  useAutoLink(editor, MATCHERS)

  return null
}

export default AutoLinkPlugin
