import { connect } from 'react-redux'

import StorageWarning from 'components/content_desk_new/contents/content_edit_dialog/attachments/storage_warning/StorageWarning'

import {
} from 'actions/content_desk'

import {
  getCDeskUploadedMediaFilesTotalSize
} from 'selectors'

const mapStateToProps = state => ({
  uploadedMediaFilesTotalSize: getCDeskUploadedMediaFilesTotalSize(state)
})

export default connect(
  mapStateToProps,
  {}
)(StorageWarning)
