import { handleActions } from 'redux-actions'

import * as Actions from 'actions/news'
import * as ConfigActions from 'actions/config'
import * as AppActions from 'actions/app'

export const initialState = ''

const sortingOptions = {
  1: '',
  2: 'reach',
  3: 'interactions'
}

export default handleActions({
  [Actions.setSortBy]: (state, action) => action.payload,

  [ConfigActions.setConfig]: (state, { payload: { defaultSortingId } }) => sortingOptions[`${defaultSortingId}`] || '',

  [AppActions.resetState]: () => initialState
}, initialState)
