import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'

import ScoreResultTimelineChart from 'containers/darknet/external_charts/ScoreResultTimelineChart'

export default function ExternalChart({ onMount }) {
  useEffect(() => {
    onMount(true)
  }, [])

  return (
    <ScoreResultTimelineChart />
  )
}

ExternalChart.propTypes = {
  onMount: PropTypes.func.isRequired
}
