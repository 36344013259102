import {
  put,
  takeEvery,
  call,
  all,
  take,
  select
} from 'redux-saga/effects'

import { fromJS } from 'immutable'

import * as Api from 'api/bff'
import * as Actions from 'actions/themax'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'
import * as SavedSearchesActions from 'actions/saved_searches'
import * as NavigationActions from 'actions/navigation'
import { initialState as themaxFilters } from 'reducers/themax/filter'
import { resetState as savedSearchesData } from 'reducers/saved_searches/dialogs/data'

import { isSavedSearchOutdated } from 'utils/themax'

export function* saveThemaxSavedSearch({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const filters = yield select(Selectors.getThemaxFilters)

    let text
    let result
    const savedSearch = payload
    const id = savedSearch.get('id')

    const requestBody = {
      name_de: savedSearch.get('nameDe'),
      name_en: savedSearch.get('nameEn'),
      name_fr: savedSearch.get('nameFr'),
      name_ru: savedSearch.get('nameRu'),
      name_zh: savedSearch.get('nameZh'),
      name_ja: savedSearch.get('nameJa'),
      description: savedSearch.get('description'),
      filters: {
        booleans: filters.get('booleans'),
        reachFilter: filters.get('reachFilter'),
        pageAveFilter: filters.get('pageAveFilter'),
        topicPlanQueries: filters.get('topicPlanQueries'),
        publicationQueries: filters.get('publicationQueries'),
        mediaTypes: filters.get('mediaTypes')
      }
    }

    if (id) {
      result = yield call(Api.updateThemaxSavedSearch, id, requestBody)
      text = i18n.get('saved_search_updated')
    } else {
      result = yield call(Api.createThemaxSavedSearch, requestBody)
      text = i18n.get('saved_search_created')
    }

    yield put(SavedSearchesActions.setExecutedSavedSearch(fromJS(result)))
    yield put(Actions.saveThemaxSavedSearchSuccess(result))
    yield put(AppActions.showAppMessage({
      text,
      success: true
    }))
  } catch (error) {
    yield put(Actions.saveThemaxSavedSearchError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* deleteThemaxSavedSearch({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const savedSearch = payload
    const id = savedSearch.get('id')

    const result = yield call(Api.deleteThemaxSavedSearch, id)

    yield put(Actions.deleteThemaxSavedSearchSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get('saved_search_deleted'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.deleteThemaxSavedSearchError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* searchThemaxSavedSearches() {
  try {
    const result = yield call(Api.searchThemaxSavedSearches, {})

    yield put(Actions.searchThemaxSavedSearchesSuccess(result))
  } catch (error) {
    yield put(Actions.searchThemaxSavedSearchesError(error))

    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* resetThemaxSavedSearches() {
  yield put(SavedSearchesActions.setExecutedSavedSearch(null))
  yield put(Actions.resetThemaxFilters())
  yield put(Actions.themaxSearchRequestStart())
}

export function* executeThemaxSavedSearch({ payload }) {
  try {
    const id = payload.get('id')
    const result = yield call(Api.fetchThemaxSavedSearch, id)

    if (isSavedSearchOutdated(themaxFilters.toJS(), result.filters)) {
      yield put(SavedSearchesActions.setExecutedSavedSearch(payload.set('outdated', true)))
      yield put(Actions.resetThemaxFilters())
    } else {
      yield put(SavedSearchesActions.setExecutedSavedSearch(payload))
      yield put(Actions.setThemaxSavedSearchFilters(result))
      yield put(Actions.themaxSearchRequestStart())
    }

    const isSavedSearchesOverview = yield select(Selectors.isSavedSearchesOverview)

    if (isSavedSearchesOverview) {
      yield put(NavigationActions.back())
    }
  } catch (error) {
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* favoriteThemaxSavedSearch({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const savedSearch = payload
    const id = savedSearch.get('id')

    const result = yield call(Api.favoriteThemaxSavedSearch, id)

    yield put(Actions.favoriteThemaxSavedSearchSuccess(result))
    yield put(AppActions.showAppMessage({
      text: i18n.get('saved_search_favorited'),
      success: true
    }))
  } catch (error) {
    yield put(Actions.favoriteThemaxSavedSearchError(error))
    yield put(AppActions.showAppMessage({
      success: false
    }))
  }
}

export function* initializeThemaxSavedSearches() {
  yield put(SavedSearchesActions.setExecutedSavedSearch(null))
  yield put(SavedSearchesActions.updateSelectedSavedSearch(savedSearchesData.get('selectedSavedSearch')))
  yield put(Actions.searchThemaxSavedSearchesStart())
  yield take(Actions.searchThemaxSavedSearchesSuccess)

  const savedSearches = yield select(Selectors.getThemaxSavedSearches)

  const favoriteSavedSearch = savedSearches.get('items').find(ss => ss.get('favorite') === true)

  if (favoriteSavedSearch) {
    yield put(Actions.executeThemaxSavedSearch(favoriteSavedSearch))
  }
}

export function* watchInitializeThemaxSavedSearches() {
  yield takeEvery(Actions.initializeThemaxSavedSearches, initializeThemaxSavedSearches)
}

export function* watchSaveThemaxSavedSearch() {
  yield takeEvery(Actions.saveThemaxSavedSearchStart, saveThemaxSavedSearch)
}

export function* watchSearchThemaxSavedSearches() {
  yield takeEvery(Actions.searchThemaxSavedSearchesStart, searchThemaxSavedSearches)
}

export function* watchDeleteThemaxSavedSearch() {
  yield takeEvery(Actions.deleteThemaxSavedSearchStart, deleteThemaxSavedSearch)
}

export function* watchExecuteThemaxSavedSearch() {
  yield takeEvery(Actions.executeThemaxSavedSearch, executeThemaxSavedSearch)
}

export function* watchResetThemaxSavedSearches() {
  yield takeEvery(Actions.resetThemaxSavedSearches, resetThemaxSavedSearches)
}

export function* watchFavoriteThemaxSavedSearch() {
  yield takeEvery(Actions.favoriteThemaxSavedSearchStart, favoriteThemaxSavedSearch)
}

export default function* savedSearchesSaga() {
  yield all([
    watchInitializeThemaxSavedSearches(),
    watchSaveThemaxSavedSearch(),
    watchSearchThemaxSavedSearches(),
    watchDeleteThemaxSavedSearch(),
    watchExecuteThemaxSavedSearch(),
    watchResetThemaxSavedSearches(),
    watchFavoriteThemaxSavedSearch()
  ])
}
