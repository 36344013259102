import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import statistics from './statistics'
import posts from './posts'

export default combineReducers({
  data,
  posts,
  statistics,
  ui
})
