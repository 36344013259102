import { connect } from 'react-redux'

import ShoppingCartGroupedNewsList from 'components/shopping_cart/grouped_news_list'
import { moveCodeInShoppingCart, expandAllNewsLists, collapseAllNewsLists, sortBy } from 'actions/shopping_cart'
import { getShoppingCartCodes, isAllExpanded } from 'selectors'

const mapStateToProps = state => ({
  codes: getShoppingCartCodes(state),
  allExpanded: isAllExpanded(state)
})

export default connect(
  mapStateToProps,
  {
    onSortEnd: moveCodeInShoppingCart,
    onExpand: expandAllNewsLists,
    onCollapse: collapseAllNewsLists,
    onSortBy: sortBy
  }
)(ShoppingCartGroupedNewsList)
