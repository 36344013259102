import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  contentContainer: {
    width: '100%',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  closeButton: {
    color: theme.palette.common.grey
  },
  title: {
    display: 'flex',
    gap: 10,
    alignItems: 'center'
  },
  content: {
    padding: 40
  },
  message: {
    '& a': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      textDecoration: 'underline'
    }
  }
}))

const OtsErrorDialog = ({
  open,
  onClose
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Box className={classes.title}>
            <ErrorOutlineIcon
              color="error"
            />
            <Typography
              variant="h6"
            >
              {i18n.get('create_ots_content_error_title')}
            </Typography>
          </Box>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box>
          <Typography
            variant="body1"
            color="text.secondary"
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: i18n.get('create_ots_content_error_message')
            }}
          />
        </Box>
      </Paper>
    </Dialog>
  )
}

OtsErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired
}

export default OtsErrorDialog
