import { fromJS } from 'immutable'

export const multiSelect = (selectedNews, newsByCodeId, payload, ids) => {
  const lastSelectedNews = selectedNews.last().get('newsId')
  const codeId = payload.first().get('codeId')
  const newsIds = ids || newsByCodeId.map(n => n.get('id'))
  const indexOfLast = newsIds.indexOf(lastSelectedNews)
  const indexOfNew = newsIds.indexOf(payload.first().get('newsId'))

  if (indexOfNew !== indexOfLast) {
    const isSelectingForwards = indexOfNew > indexOfLast
    const start = isSelectingForwards ? indexOfLast : indexOfNew
    const end = isSelectingForwards ? indexOfNew : indexOfLast

    const inBetween = newsIds.slice(start, end + 1)
    const inBetweenWithCodeId = inBetween.map(newsId => (fromJS({
      newsId,
      codeId
    })))

    return inBetweenWithCodeId
  }

  return fromJS([{}])
}
