/* global window */
import { fromJS } from 'immutable'

import { getStage } from './environment'

const { protocol, host, pathname } = window.location

export const getWhiteLabelConfig = state => state.whitelabel
export const getWhitelabelColors = state => getWhiteLabelConfig(state).get('colors')
export const getLogo = state => getWhiteLabelConfig(state).get('logo')
export const getEmailLogo = state => getWhiteLabelConfig(state).get('emailLogo') || getLogo(state)
export const getWhitelabelEmailAddress = state => getWhiteLabelConfig(state).get('emailAddress')
export const getWhitelabelFavicon = state => getWhiteLabelConfig(state).get('favicon')
export const getWhitelabelTitle = state => getWhiteLabelConfig(state).get('title')
export const getWhitelabelEmotionals = state => getWhiteLabelConfig(state).get('emotionals') || fromJS([])
export const getHasMyNews = state => getWhiteLabelConfig(state).get('hasMyNews')
export const getLoginTypes = state => getWhiteLabelConfig(state).get('loginTypes')
export const getAzureSsoConfig = state => getWhiteLabelConfig(state).getIn(['sso', 'azure'])
export const getBaseUrl = () => `${protocol}//${host}${pathname}`.replace(/\/$/, '')
export const getBffUrl = state => {
  const url = getWhiteLabelConfig(state).getIn(['bffUrl', getStage(state)])

  if (url === '{DEFAULT}') {
    return `${getBaseUrl()}/bff`
  }

  return url
}

export const getWhitelabelLoginLinks = state => getWhiteLabelConfig(state).get('loginLinks')
export const getWhitelabelLegalNoticeLink = state => getWhitelabelLoginLinks(state).get('legalNotice')
export const getWhitelabelPrivacyLink = state => getWhitelabelLoginLinks(state).get('privacy')
export const getWhitelabelTermsAndConditionsLink = state => getWhitelabelLoginLinks(state).get('termsAndConditions')
export const getWhitelabelHomepageName = state => getWhitelabelLoginLinks(state).getIn(['homepage', 'name'])
export const getWhitelabelHomepageLink = state => getWhitelabelLoginLinks(state).getIn(['homepage', 'link'])
export const getWhitelabelProgressLetters = state => getWhiteLabelConfig(state).get('progressLetters')
export const getWhitelabelTheme = state => getWhiteLabelConfig(state).get('theme')
export const isPlotlights = state => getWhitelabelTheme(state) === 'plotlights'
