import { connect } from 'react-redux'

import FilterableMenu from 'components/filterable_menu'

import { getSavedSearches, getSavedSearchResolving } from 'selectors'

import { executeSavedSearch } from 'actions/saved_searches'
import { navigate } from 'actions/navigation'

const mapStateToProps = state => ({
  items: getSavedSearches(state),
  loading: getSavedSearchResolving(state)
})

const mapDispatchToProps = dispatch => ({
  onItemClick: item => dispatch(executeSavedSearch(item)),
  onMainClick: () => dispatch(navigate('saved_searches'))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterableMenu)
