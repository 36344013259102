import { handleActions } from 'redux-actions'
import { Map as ImmutableMap, List as ImmutableList, Set as ImmutableSet } from 'immutable'

import * as Actions from 'actions/ust'

export const initialState = ImmutableMap({
  tags: ImmutableSet(),
  events: ImmutableList()
})

export default handleActions({
  [Actions.ustTagSuccess]: (state, { payload }) => state
    .update('tags', set => set.add({ tag: payload.tag, created: payload.created })),
  [Actions.ustEventSuccess]: (state, { payload }) => state
    .update('events', list => list.push({ event: payload.event, created: payload.created }))
}, initialState)
