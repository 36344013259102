import { createAction } from 'redux-actions'

const prefix = 'MAILING_LISTS/'

export const setMailingLists = createAction(`${prefix}SET_MAILING_LISTS`)
export const updateSelectedMailingList = createAction(`${prefix}UPDATE_SELECTED_MAILING_LIST`)

export const saveMailingListStart = createAction(`${prefix}SAVE_MAILING_LIST_START`)
export const saveMailingListSuccess = createAction(`${prefix}SAVE_MAILING_LIST_SUCCESS`)
export const saveMailingListError = createAction(`${prefix}SAVE_MAILING_LIST_ERROR`)

export const deleteMailingListStart = createAction(`${prefix}DELETE_MAILING_LIST_START`)
export const deleteMailingListSuccess = createAction(`${prefix}DELETE_MAILING_LIST_SUCCESS`)
export const deleteMailingListError = createAction(`${prefix}DELETE_MAILING_LIST_ERROR`)

export const showMailingListDialog = createAction(`${prefix}SHOW_MAILING_LIST_DIALOG`)
export const hideMailingListDialog = createAction(`${prefix}HIDE_MAILING_LIST_DIALOG`)

export const showRemoveMailingListDialog = createAction(`${prefix}SHOW_REMOVE_MAILING_LIST_DIALOG`)
export const hideRemoveMailingListDialog = createAction(`${prefix}HIDE_REMOVE_MAILING_LIST_DIALOG`)
