import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

const calculateStats = entities => (
  entities
    .reduce(
      (acc, entity) => acc.update(entity.get('id'), entity, e => e.update('count', 0, count => count + 1)),
      fromJS({})
    )
    .toList()
    .sortBy(e => -e.get('count'))
)

export default handleActions({
  [MediaReviewsActions.setMediaReviews]: (state, { payload: data }) => fromJS(data),
  [MediaReviewsActions.filterMediaReviewsSuccess]: (state, { payload: data }) => fromJS(data),
  [MediaReviewsActions.recalculateMediaReviewStats]: (state, { payload: { mediaReviewId, news } }) => {
    const index = state.findIndex(m => m.get('id') === mediaReviewId)

    if (index !== -1) {
      const newsCount = news.flatMap(n => n.get('clusteredNews')).size + news.size

      const codes = news.flatMap(n => n.get('codes'))
      const codeStats = calculateStats(codes)
      const channels = news.flatMap(n => {
        const channel = n.getIn(['publication', 'channel'])

        const result = []
        for (let i = 0; i <= n.get('clusteredNews').size; i += 1) {
          result.push(channel)
        }

        return fromJS(result)
      })
      const channelStats = calculateStats(channels)

      return state.update(index, mediaReview => mediaReview.merge(fromJS({
        newsCount,
        stats: {
          codes: codeStats,
          channels: channelStats
        }
      })))
    }

    return state
  },
  [AppActions.resetState]: () => initialState
}, initialState)
