/* global window */

const IP_FILTER = [
  '192.168',
  '84.133.192.205',
  '91.26.142.106',
  '217.113.43',
  '92.247.50.185',
  '78.83.69.79',
  '109.199.129.41',
  '24.134.122.173',
  '84.241.96.98'
]

export const checkIp = () => {
  if (process.env.NODE_ENV !== 'production') {
    return true
  }

  const ip = window.ust_myIP
  const allowed = IP_FILTER.some(filter => ip.startsWith(filter))

  return allowed
}

export const disableRecording = () => {
  window.UST.settings.enableSessionRecordings = false
  window.UST.fullRec = false
  window.UST.nativeSessionRecording = false
  console.log('Recording disabled')  // eslint-disable-line
}

export const enableRecording = () => {
  window.UST.settings.enableSessionRecordings = true
  window.UST.fullRec = true
  window.UST.nativeSessionRecording = true
  console.log('Recording enabled')  // eslint-disable-line
}
