import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { IconButton } from 'containers/themed'
import YoutubeSearchedFor from '@mui/icons-material/YoutubeSearchedFor'
import AddCircle from '@mui/icons-material/AddCircle'

const AutocompleteAsync = props => {
  const [options, setOptions] = React.useState([])
  const loading = options.length === 0

  React.useEffect(() => {
    if (!loading) {
      return undefined
    }

    return () => {
    }
  }, [loading])

  React.useEffect(() => {
    if (props.options.length > 0) {
      setOptions(props.options)
    }
  }, [props])

  return (
    <Autocomplete
      {...props}
      isOptionEqualToValue={(option, value) => option.title === value}
      getOptionLabel={option => option.title}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {props.loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {/* {params.InputProps.endAdornment} */}
                <IconButton>
                  <YoutubeSearchedFor />
                </IconButton>
                <IconButton>
                  <AddCircle />
                </IconButton>
              </>
            )
          }}
        />
      )}
    />
  )
}

AutocompleteAsync.propTypes = {
  loading: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired
}

export default AutocompleteAsync
