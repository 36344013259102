import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import GoogleAuthorize from 'react-google-authorize'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2'
import { makeStyles } from 'tss-react/mui'

import AppBar from 'containers/AppBar'
import ContentContainer from 'components/content_container'

import { Capabilities } from 'static/constants'

import useI18n from 'hooks/useI18n'
import DeeplButton from 'containers/administration/external_accounts/DeeplButton'
import DeeplApiKeyCard from 'containers/administration/external_accounts/DeeplApiKeyCard'
import JournalistSearch from 'containers/administration/external_accounts/AnewstipApiKey'
import { Icon, Card, CardContent, CardHeader, Grid } from '@mui/material'
import LoginButton from './login_button'
import ProfileInfo from './profile_info'
import Description from './description'

const useStyles = makeStyles()(theme => ({
  buttonCardHeader: {
    paddingBottom: 0
  },
  container: {
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    margin: '0 auto',
    width: '75%',

    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  apiContainer: {
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    margin: '0 auto',
    marginTop: '1em',
    width: '75%',

    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  facebook: {
    color: '#4267b2'
  },
  linkedIn: {
    color: '#2867b2'
  },
  twitter: {
    color: '#000000'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))

const googleScopes = [
  'profile',
  'email',
  'https://www.googleapis.com/auth/analytics.readonly'
].join(' ')

const youtubeScopes = [
  'profile',
  'email',
  'https://www.googleapis.com/auth/yt-analytics.readonly',
  'https://www.googleapis.com/auth/yt-analytics-monetary.readonly'
].join(' ')

const linkedInAnalyticsScopes = [
  'r_basicprofile',
  'rw_organization_admin',
  'r_organization_social'
].join(' ')

const linkedInContentDeskScopes = `${[
  'w_organization_social',
  'w_member_social'
].join(' ')} ${linkedInAnalyticsScopes}`

export default function ExternalAccounts({
  facebookAccountConnected,
  facebookAnalyticsClientId,
  googleAnalyticsClientId,
  googleAnalyticsEnabled,
  youtubeAnalyticsEnabled,
  onFacebookSubmit,
  onFacebookRevoke,
  onGoogleAnalyticsSubmit,
  onGoogleAnalyticsRevoke,
  onYoutubeAnalyticsSubmit,
  onYoutubeAnalyticsRevoke,
  onLinkedInSubmit,
  onLinkedInRevoke,
  onTwitterRevoke,
  linkedInAnalyticsEnabled,
  linkedInClientId,
  deeplApiKey,
  externalAccounts,
  baseUrl,
  capabilities
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const facebookScopes = [
    'pages_show_list',
    'read_insights',
    'instagram_basic',
    'instagram_manage_insights',
    'pages_read_engagement',
    'pages_manage_posts',
    'business_management'
  ]

  const facebookAccount = externalAccounts.getIn([1, 0])
  const googleAnalyticsAccount = externalAccounts.getIn([2, 0])
  const youtubeAnalyticsAccount = externalAccounts.getIn([3, 0])
  const linkedinAnalyticsAccount = externalAccounts.getIn([4, 0])
  // as soon as it is settled that twitter sucks and we won't integrate external accounts,
  // this and bff code can be deleted
  const twitterAnalyticsAccount = externalAccounts.getIn([5, 0])

  const linkedInButton = scope => (
    <LinkedIn
      clientId={linkedInClientId}
      onSuccess={code => onLinkedInSubmit({ code })}
      onFailure={() => onLinkedInSubmit()}
      redirectUri={`${baseUrl}/linked_in`}
      className={classes.googleButton}
      scope={scope}
    >
      {({ linkedInLogin }) => (
        <LoginButton
          onClick={linkedInLogin}
          disabled={linkedInAnalyticsEnabled}
          icon={(
            <Icon
              className={`fab fa-linkedin ${classes.linkedIn}`}
            />
          )}
          text="Sign in with LinkedIn"
        />
      )}
    </LinkedIn>
  )

  return (
    <>
      <AppBar title={i18n.get('external_accounts')} />
      <ContentContainer>
        <Card classes={{ root: classes.container }}>
          <CardHeader
            title={i18n.get('your_external_accounts')}
            subtitle={i18n.get('external_accounts_hint')}
          />

          <CardContent>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              {Boolean(googleAnalyticsClientId) && (
                <Grid item>
                  <Card>
                    <CardHeader
                      classes={{ root: classes.buttonCardHeader }}
                      title={(
                        <div className={classes.title}>
                          <span>Google Analytics</span>

                          <Description
                            ownedChannelAnalytics={i18n.get(
                              'external_accounts_owned_channel_analytics_google_analytics'
                            )}
                          />
                        </div>
                      )}
                    />

                    {!googleAnalyticsEnabled && (
                      <CardContent>
                        <GoogleAuthorize
                          clientId={googleAnalyticsClientId}
                          onSuccess={onGoogleAnalyticsSubmit}
                          onFailure={onGoogleAnalyticsSubmit}
                          accessType="offline"
                          responseType="code"
                          prompt="consent"
                          cookiePolicy="none"
                          scope={googleScopes}
                          disabled={googleAnalyticsEnabled}
                          render={renderProps => (
                            <LoginButton
                              disabled={googleAnalyticsEnabled}
                              onClick={renderProps.onClick}
                            />
                          )}
                        />
                      </CardContent>
                    )}

                    {googleAnalyticsEnabled && (
                      <ProfileInfo
                        onRemove={onGoogleAnalyticsRevoke}
                        profile={googleAnalyticsAccount}
                      />
                    )}
                  </Card>
                </Grid>
              )}

              {Boolean(googleAnalyticsClientId) && (
                <Grid item>
                  <Card>
                    <CardHeader
                      classes={{ root: classes.buttonCardHeader }}
                      title={(
                        <div className={classes.title}>
                          <span>YouTube</span>

                          <Description
                            ownedChannelAnalytics={i18n.get(
                              'external_accounts_owned_channel_analytics_youtube'
                            )}
                          />
                        </div>
                      )}
                    />

                    {!youtubeAnalyticsEnabled && (
                      <CardContent>
                        <GoogleAuthorize
                          clientId={googleAnalyticsClientId}
                          onSuccess={onYoutubeAnalyticsSubmit}
                          onFailure={onYoutubeAnalyticsSubmit}
                          accessType="offline"
                          responseType="code"
                          prompt="consent"
                          cookiePolicy="none"
                          scope={youtubeScopes}
                          disabled={youtubeAnalyticsEnabled}
                          render={renderProps => (
                            <LoginButton
                              disabled={youtubeAnalyticsEnabled}
                              onClick={renderProps.onClick}
                            />
                          )}
                        />
                      </CardContent>
                    )}

                    {youtubeAnalyticsEnabled && (
                      <ProfileInfo
                        onRemove={onYoutubeAnalyticsRevoke}
                        profile={youtubeAnalyticsAccount}
                      />
                    )}
                  </Card>
                </Grid>
              )}

              {Boolean(linkedInClientId) && (
                <Grid item>
                  <Card>
                    <CardHeader
                      classes={{ root: classes.buttonCardHeader }}
                      title={(
                        <div className={classes.title}>
                          <span>LinkedIn</span>

                          <Description
                            ownedChannelAnalytics={i18n.get(
                              'external_accounts_owned_channel_analytics_linkedin'
                            )}
                          />
                        </div>
                      )}
                    />

                    {!linkedInAnalyticsEnabled && (
                      <CardContent>
                        {capabilities.get(Capabilities.HAS_CONTENT_DESK_LINKED_IN)
                          ? linkedInButton(linkedInContentDeskScopes) : linkedInButton(linkedInAnalyticsScopes)}
                      </CardContent>
                    )}

                    {linkedInAnalyticsEnabled && (
                      <ProfileInfo
                        onRemove={onLinkedInRevoke}
                        profile={linkedinAnalyticsAccount}
                      />
                    )}
                  </Card>
                </Grid>
              )}

              {Boolean(facebookAnalyticsClientId) && (
                <Grid item>
                  <Card>
                    <CardHeader
                      classes={{ root: classes.buttonCardHeader }}
                      title={(
                        <div className={classes.title}>
                          <span>Facebook / Instagram</span>

                          <Description
                            ownedChannelAnalytics={i18n.get(
                              'external_accounts_owned_channel_analytics_facebook'
                            )}
                            socialListening={i18n.get(
                              'external_accounts_social_listening_facebook'
                            )}
                          />
                        </div>
                      )}
                    />

                    {!facebookAccountConnected && (
                      <CardContent>
                        <FacebookLogin
                          appId={facebookAnalyticsClientId}
                          scope={facebookScopes.join()}
                          callback={onFacebookSubmit}
                          version="19.0"
                          responseType="code"
                          render={({ onClick }) => (
                            <LoginButton
                              type="button"
                              onClick={onClick}
                              variant="contained"
                              classes={{ root: classes.button }}
                              disabled={facebookAccountConnected}
                              icon={(
                                <Icon
                                  className={`fab fa-facebook-f ${classes.facebook}`}
                                />
                              )}
                              text="Sign in with Facebook"
                            />
                          )}
                        />
                      </CardContent>
                    )}

                    {facebookAccountConnected && (
                      <ProfileInfo
                        onRemove={onFacebookRevoke}
                        profile={facebookAccount}
                      />
                    )}
                  </Card>
                </Grid>
              )}

              {Boolean(twitterAnalyticsAccount) && (

                <Grid item>
                  <Card>
                    <CardHeader
                      classes={{ root: classes.buttonCardHeader }}
                      title={(
                        <div className={classes.title}>
                          <span>X / Twitter</span>

                          <Description
                            ownedChannelAnalytics={i18n.get(
                              'external_accounts_owned_channel_analytics_twitter'
                            )}
                          />
                        </div>
                      )}
                    />
                    <ProfileInfo
                      onRemove={onTwitterRevoke}
                      profile={twitterAnalyticsAccount}
                    />
                  </Card>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
        <Card classes={{ root: classes.apiContainer }}>
          <CardHeader title={i18n.get('your_api_keys')} />

          <CardContent>
            <Grid
              container
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Card>
                  <CardHeader
                    classes={{ root: classes.buttonCardHeader }}
                    title={(
                      <div className={classes.title}>
                        <span>DeepL</span>

                        <Description />
                      </div>
                    )}
                  />

                  <CardContent>
                    {!deeplApiKey && <DeeplButton />}
                    {deeplApiKey && <DeeplApiKeyCard />}
                  </CardContent>

                </Card>
              </Grid>
            </Grid>
          </CardContent>
          {capabilities.get(Capabilities.HAS_CONTACTS_MANAGEMENT_ANEWSTIP_API_KEY_EDITING_FEATURE) && <JournalistSearch />}
        </Card>
      </ContentContainer>
    </>
  )
}

ExternalAccounts.propTypes = {
  googleAnalyticsEnabled: PropTypes.bool.isRequired,
  youtubeAnalyticsEnabled: PropTypes.bool.isRequired,
  googleAnalyticsClientId: PropTypes.string,
  facebookAccountConnected: PropTypes.bool.isRequired,
  facebookAnalyticsClientId: PropTypes.string,
  linkedInAnalyticsEnabled: PropTypes.bool.isRequired,
  linkedInClientId: PropTypes.string,
  deeplApiKey: PropTypes.object,
  externalAccounts: PropTypes.instanceOf(Map).isRequired,
  baseUrl: PropTypes.string.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,

  onGoogleAnalyticsSubmit: PropTypes.func.isRequired,
  onGoogleAnalyticsRevoke: PropTypes.func.isRequired,
  onYoutubeAnalyticsSubmit: PropTypes.func.isRequired,
  onYoutubeAnalyticsRevoke: PropTypes.func.isRequired,
  onFacebookSubmit: PropTypes.func.isRequired,
  onFacebookRevoke: PropTypes.func.isRequired,
  onLinkedInSubmit: PropTypes.func.isRequired,
  onLinkedInRevoke: PropTypes.func.isRequired,
  onTwitterRevoke: PropTypes.func.isRequired
}
