import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { listChunk } from 'utils/immutable'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import { List as ImmutableList, Set as ImmutableSet } from 'immutable'

import { Button, Input } from 'containers/themed'
import CheckboxCard from 'components/checkbox_card'
import { StaggeredListItem } from 'components/staggered_list'
import { Typography, Box, Grid } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  topicGroups: {
    marginTop: 30,
    display: 'flex',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0
    }
  },
  topics: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      paddingRight: 0
    },
    '&:last-child': {
      paddingRight: 0
    }
  },
  topic: {
    marginBottom: 20,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 0
    }
  },
  selectButton: {
    marginTop: 20,
    [theme.breakpoints.only('xs')]: {
      marginBottom: 20
    }
  },
  header: {
    marginBottom: 10,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 25,
      marginRight: 25
    }
  }
}))

export default function Topics({
  topics,
  selectedTopicIds,
  selectedEditionIds,
  selectedCodeFilters,
  onSelect,
  onSelectAll,
  onDeselectAll
}) {
  const { classes } = useStyles()
  const [filter, setFilter] = useState('')
  const i18n = useI18n()
  const device = useDevice()

  const byCustomFilter = topic => {
    if (filter.length <= 2) {
      return true
    }

    return topic.get('name').toLowerCase().includes(filter.toLowerCase())
  }

  const byCodeFilter = topic => {
    if (selectedCodeFilters.isEmpty()) {
      return true
    }

    return selectedCodeFilters.includes(topic.get('id'))
  }

  const filteredTopics = topics
    .filter(topic => !topic.get('topnews'))
    .filter(byCustomFilter)
    .filter(byCodeFilter)
    .sortBy(topic => topic.get('name').toLowerCase())

  const filteredTopTopics = topics
    .filter(byCodeFilter)
    .filter(topic => topic.get('topnews'))
    .sortBy(topic => topic.get('name').toLowerCase())

  const getGroupSize = () => {
    if (device.get('sm')) {
      return 2
    }

    if (device.get('xs')) {
      return 1
    }

    return 3
  }

  const getTopics = () => listChunk(filteredTopics, getGroupSize(), true)
  const getTopTopics = () => listChunk(filteredTopTopics, getGroupSize(), true)

  const allSelected = (filteredTopics.push(filteredTopTopics)).size === selectedTopicIds.size
  const selectableTopics = topics.filter(topic => !topic.get('topnews')).filter(byCodeFilter)

  const handleFilterChange = value => setFilter(value)
  const handleSelectAll = () => {
    if (allSelected) {
      onDeselectAll()
    } else {
      setFilter('')
      onSelectAll()
    }
  }

  const renderTopic = (topic, delay, top) => (
    <StaggeredListItem
      key={topic.get('id')}
      delay={delay}
      className={classes.topics}
    >
      {top && (
      <CheckboxCard
        className={classes.topic}
        label={topic.get('name')}
        checked
        disabled
      />
      )}
      {!top && (
      <CheckboxCard
        accent
        disabled={selectedEditionIds.isEmpty()}
        className={classes.topic}
        label={topic.get('name')}
        checked={selectedTopicIds.has(topic.get('id'))}
        onChange={selected => (!selectedEditionIds.isEmpty() && onSelect({ topic, selected }))}
      />
      )}
    </StaggeredListItem>
  )

  const renderGroup = (group, gIndex, xs, top) => (
    <Grid
      item
      xs={xs}
      key={gIndex}
    >
      {group.map((topic, tIndex) => {
        const delay = (gIndex + tIndex + 1) * 50

        return renderTopic(topic, delay, top)
      })}
    </Grid>
  )

  const renderGroups = (groups, top) => {
    const xs = parseInt((12 / groups.size), 10)

    return (
      <Grid
        container
        spacing={2}
      >
        {groups.map((group, groupIndex) => renderGroup(group, groupIndex, xs, top))}
      </Grid>
    )
  }

  const renderTopics = top => {
    const groups = top ? getTopTopics() : getTopics()

    if (groups.isEmpty()) {
      return null
    }

    return (
      <Box className={classes.topicGroups}>
        {renderGroups(groups, top)}
      </Box>
    )
  }

  const renderSelectButton = () => (
    <Button
      className={classes.selectButton}
      color="primary"
      variant="contained"
      disabled={selectedEditionIds.isEmpty()}
      onClick={handleSelectAll}
    >
      {allSelected ? i18n.get('deselect_all') : i18n.get('select_all')}
    </Button>
  )

  const renderFilter = () => (
    <Input
      value={filter}
      label={i18n.get('filter')}
      onChange={event => handleFilterChange(event.target.value)}
      disabled={allSelected}
    />
  )

  if (selectedEditionIds.isEmpty() || selectableTopics.isEmpty()) {
    return null
  }

  return (
    <Box
      my={3}
      py={3}
    >
      <div className={classes.header}>
        <Typography
          variant="h5"
          gutterBottom
        >
          {i18n.get('select_your_topics_subtitle')}
        </Typography>
        {renderFilter()}
        {renderSelectButton()}
      </div>
      {renderTopics(true)}
      {renderTopics(false)}
    </Box>
  )
}

Topics.propTypes = {
  topics: PropTypes.instanceOf(ImmutableList).isRequired,
  selectedTopicIds: PropTypes.object.isRequired,
  selectedEditionIds: PropTypes.instanceOf(ImmutableSet).isRequired,
  selectedCodeFilters: PropTypes.instanceOf(ImmutableList).isRequired,

  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  onDeselectAll: PropTypes.func.isRequired
}
