import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  filter: [],
  size: 30,
  filterRequestRunning: false
})

export default handleActions({
  [Actions.setMediaReviewFilter]: (state, { payload }) => state.set('filter', fromJS(payload)),
  [Actions.setMediaReviewFilterSize]: (state, { payload }) => state.set('size', fromJS(payload)),
  [Actions.filterMediaReviewsStart]: state => state.set('filterRequestRunning', true),
  [combineActions(
    Actions.filterMediaReviewsSuccess,
    Actions.filterMediaReviewsError
  )]: state => state.set('filterRequestRunning', false),

  [AppActions.resetState]: () => initialState
}, initialState)
