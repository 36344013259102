import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'
import camelcaseKeysDeep from 'camelcase-keys-deep'

import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'

import { linkedInPost } from 'static/linked_in_post_config'
import { facebookPost } from 'static/facebook_post_config'

const updateOrAddCampaignName = (allNames, campaign) => {
  if (allNames.some(e => e.get('id') === campaign.get('id'))) {
    return allNames.map(e => ((e.get('id') === campaign.get('id')) ? (
      e.set('name', campaign.get('name'))) : e))
  }

  return allNames.push(fromJS({ id: campaign.get('id'), name: campaign.get('name') }))
}

export const initialState = fromJS({
  ccdCampaigns: {
    total: null,
    items: [],
    allNames: []
  },
  ccdContents: {
    total: null,
    items: []
  },
  ccdStatistics: {
    total: null,
    events: [],
    earned: {
      news: [],
      stats: {
        total: 0,
        reach: 0
      }
    }
  },
  ccdKpiStatistics: {
    email: {},
    socialMedia: {}
  },
  uploadedMediaFiles: [],
  uploadedMediaFilesTotal: null,
  uploadedMediaFilesTotalSize: 0,
  ccdAssignees: [],
  contentFormData: {
    id: null,
    title: '',
    subject: '',
    preHeader: '',
    body: '',
    linkedInPost,
    facebookPost,
    type: null,
    plannedFor: null,
    scheduledFor: null,
    ccdCampaign: null,
    recipients: [],
    editorDesign: null,
    senderMail: '',
    senderName: '',
    domain: '',
    domainRegion: '',
    ccdMediaFiles: [],
    isDirty: false,
    lockedAt: null,
    lockedBy: null,
    approvalStatus: 'draft',
    status: null,
    notes: [],
    otsContent: null
  },
  campaignFormData: {
    assignedUserId: null,
    id: null,
    name: '',
    startDate: '',
    endDate: '',
    summary: '',
    active: true
  },
  selectedContent: {},
  campaignDropdownOptions: [],
  contentDropdownOptions: [],
  contactDropdownOptions: [],
  distributionListDropdownOptions: [],
  distributionListSearchQuery: '',
  aiSelectedText: '',
  aiRequestResults: []
})

const updateNestedContentsCampaigns = (state, contentId, campaignId, updated) => {
  let newState = state

  if (newState.getIn(['ccdContents', 'items']).filter(c => c.get('id') === contentId).size) {
    newState = newState
      .updateIn(['ccdContents', 'items'], contents => (
        contents.map(content => {
          if (content.get('id') === contentId) {
            return updated
          }

          return content
        })
      ))
  } else {
    newState = newState
      .setIn(['ccdContents', 'items'], state.getIn(['ccdContents', 'items']).unshift(updated))
      .setIn(['ccdContents', 'total'], state.getIn(['ccdContents', 'total']) + 1)
  }

  if (newState.getIn(['ccdCampaigns', 'items']).filter(c => c.get('id') === campaignId).size) {
    newState = newState.updateIn(['ccdCampaigns', 'items'], campaigns => (
      campaigns.map(campaign => {
        if (campaign.get('id') === campaignId) {
          if (campaign.get('ccdContents').filter(c => c.get('id') === contentId).size) {
            return campaign.update('ccdContents', contents => (
              contents.map(content => {
                if (content.get('id') === contentId) {
                  return updated
                }

                return content
              })
            ))
          }

          return campaign.merge({
            ccdContentsCount: campaign.get('ccdContentsCount') + 1,
            ccdContents: campaign.get('ccdContents').unshift(updated)
          })
        }

        return campaign
      })
    ))
  }

  return newState
}

export default handleActions({
  [Actions.fetchCampaignDropdownOptionsSuccess]: (state, { payload: { ccdCampaigns } }) => (
    state.set('campaignDropdownOptions', fromJS(ccdCampaigns))),
  [Actions.fetchContentDropdownOptionsSuccess]: (state, { payload: { ccdContents } }) => (
    state.set('contentDropdownOptions', fromJS(ccdContents))),
  [Actions.fetchContactDropdownOptionsSuccess]: (state, { payload: { contacts } }) => (
    state.set('contactDropdownOptions', fromJS(contacts))),
  [Actions.fetchDistributionListDropdownOptionsSuccess]: (state, { payload: { distributionLists } }) => (
    state.set('distributionListDropdownOptions', fromJS(distributionLists))),
  [Actions.deleteContentSuccess]: (state, { payload }) => {
    const oldContent = state.getIn(['ccdContents', 'items']).find(content => content.get('id') === payload.id)

    let newContentsTotal = state.getIn(['ccdContents', 'total'])

    if (oldContent) {
      const oldContentsTotal = state.getIn(['ccdContents', 'total'])
      newContentsTotal = oldContentsTotal ? (oldContentsTotal - 1) : oldContentsTotal
    }

    const newContents = state.getIn(['ccdContents', 'items']).filter(content => content.get('id') !== payload.id)

    const newCampaigns = state
      .getIn(['ccdCampaigns', 'items'])
      .map(campaign => {
        const newInnerContents = campaign
          .get('ccdContents')
          .filter(content => content.get('id') !== payload.id)
        const oldInnerContents = campaign
          .get('ccdContents')
          .find(content => content.get('id') === payload.id)

        let newInnerContentsCount = campaign.get('ccdContentsCount')

        if (oldInnerContents) {
          newInnerContentsCount = newInnerContentsCount === 0 ? 0 : campaign.get('ccdContentsCount') - 1
        }

        return campaign
          .set('ccdContents', newInnerContents)
          .set('ccdContentsCount', newInnerContentsCount)
      })

    return state
      .setIn(['ccdCampaigns', 'items'], newCampaigns)
      .setIn(['ccdContents', 'items'], newContents)
      .setIn(['ccdContents', 'total'], newContentsTotal)
  },
  [Actions.deleteCampaignSuccess]: (state, { payload }) => {
    const oldCampaign = state.getIn(['ccdCampaigns', 'items']).find(campaign => campaign.get('id') === payload.id)

    let newCampaignsTotal = state.getIn(['ccdCampaigns', 'total'])
    let newContentsTotal = state.getIn(['ccdContents', 'total'])

    if (oldCampaign) {
      const oldCampaignsTotal = state.getIn(['ccdCampaigns', 'total'])
      newCampaignsTotal = oldCampaignsTotal ? (oldCampaignsTotal - 1) : oldCampaignsTotal
      newContentsTotal = newContentsTotal ? newContentsTotal - oldCampaign.get('ccdContentsCount') : newContentsTotal
    }

    const newCampaigns = state.getIn(['ccdCampaigns', 'items']).filter(campaign => campaign.get('id') !== payload.id)
    const newContents = state.getIn(['ccdContents', 'items']).filter(content => content.get('ccdCampaign').get('id') !== payload.id)

    return state
      .setIn(['ccdCampaigns', 'items'], newCampaigns)
      .setIn(['ccdCampaigns', 'total'], newCampaignsTotal)
      .updateIn(['ccdCampaigns', 'allNames'], allNames => allNames.filter(e => e.get('id') !== payload.id))
      .setIn(['ccdContents', 'items'], newContents)
      .setIn(['ccdContents', 'total'], newContentsTotal)
  },
  [Actions.setAssignees]: (state, { payload }) => state.merge({ ccdAssignees: fromJS(payload) }),

  [Actions.setCampaigns]: (state, { payload: { total, ccdCampaigns, allNames } }) => state
    .setIn(['ccdCampaigns', 'items'], fromJS(ccdCampaigns))
    .setIn(['ccdCampaigns', 'total'], total)
    .setIn(['ccdCampaigns', 'allNames'], fromJS(allNames)),
  [Actions.resetCampaigns]: state => state.set('ccdCampaigns', initialState.get('ccdCampaigns')),

  // contentFormData
  [Actions.setContentFormData]: (state, { payload: { key, value } }) => (
    state
      .setIn(['contentFormData', 'isDirty'], true)
      .setIn(['contentFormData', key], value)
  ),
  [Actions.removeRecipient]: (state, { payload }) => (
    state.updateIn(['contentFormData', 'recipients'], recipients => (
      recipients.filter(recipient => recipient.get('id') !== payload)
    ))
  ),
  [Actions.updateContent]: (state, { payload }) => (
    state
      .setIn(['contentFormData', 'isDirty'], true)
      .update('contentFormData', oldValue => oldValue.merge(payload))
  ),
  [Actions.openNewContentDialog]: (state, { payload }) => (
    state.set('contentFormData', initialState.get('contentFormData').merge(payload || {}))
  ),
  [Actions.openEditContentDialog]: (state, { payload }) => (
    state
      .update('contentFormData', oldValue => oldValue.merge(payload))
      .update('selectedContent', oldValue => oldValue.merge(payload))
  ),
  [Actions.openOldEditContentDialog]: (state, { payload }) => (
    state
      .update('contentFormData', oldValue => oldValue.merge(payload))
      .update('selectedContent', oldValue => oldValue.merge(payload))
  ),
  [Actions.closeEditContentDialog]: state => (
    state
      .set('contentFormData', initialState.get('contentFormData'))
      .set('selectedContent', initialState.get('selectedContent'))
      .set('aiSelectedText', initialState.get('aiSelectedText'))
      .set('aiRequestResults', initialState.get('aiRequestResults'))
  ),
  [Actions.closeOldEditContentDialog]: state => (
    state
      .set('contentFormData', initialState.get('contentFormData'))
      .set('selectedContent', initialState.get('selectedContent'))
      .set('aiSelectedText', initialState.get('aiSelectedText'))
      .set('aiRequestResults', initialState.get('aiRequestResults'))
  ),
  [Actions.cloneContentsFormData]: (state, { payload }) => (
    state.update('contentFormData', oldValue => (oldValue.merge(payload)))
  ),

  [Actions.openContentNotesDialog]: (state, { payload }) => state.update('contentFormData', oldValue => (oldValue.merge(payload))),
  [Actions.closeContentNotesDialog]: state => state.set('contentFormData', initialState.get('contentFormData')),

  // selectedContent
  [combineActions(
    Actions.lockContentSuccess,
    Actions.unlockContentSuccess
  )]: (state, { payload }) => state.set('selectedContent', payload),
  [Actions.closeViewContentDialog]: state => state.set('selectedContent', initialState.get('selectedContent')),

  // ccdCampaigns
  [combineActions(
    Actions.saveCampaignSuccess,
    Actions.saveCampaignFormlessSuccess
  )]: (state, { payload }) => {
    const campaignId = payload.id
    const updated = fromJS(payload)
    const allNames = updateOrAddCampaignName(state.getIn(['ccdCampaigns', 'allNames']), updated)

    if (state.getIn(['ccdCampaigns', 'items']).filter(c => c.get('id') === campaignId).size) {
      return state.updateIn(['ccdCampaigns', 'items'], campaigns => (
        campaigns.map(campaign => {
          if (campaign.get('id') === campaignId) {
            return updated
          }

          return campaign
        })
      )).setIn(['ccdCampaigns', 'allNames'], allNames)
    }

    return state
      .setIn(['ccdCampaigns', 'items'], state.getIn(['ccdCampaigns', 'items']).unshift(updated))
      .setIn(['ccdCampaigns', 'total'], state.getIn(['ccdCampaigns', 'total']) + 1)
      .setIn(['ccdCampaigns', 'allNames'], allNames)
  },

  // ccdContents
  [Actions.setContents]: (state, { payload: { total, ccdContents } }) => state
    .setIn(['ccdContents', 'items'], fromJS(ccdContents))
    .setIn(['ccdContents', 'total'], total),
  [Actions.setUpdatedContents]: (state, { payload }) => {
    let newState = state

    payload.forEach(p => {
      const contentId = p.id
      const updated = fromJS(p)

      if (newState.getIn(['ccdContents', 'items']).filter(c => c.get('id') === contentId).size) {
        newState = newState
          .updateIn(['ccdContents', 'items'], contents => (
            contents.map(content => {
              if (content.get('id') === contentId) {
                return updated
              }

              return content
            })
          ))
      }
    })

    return newState
  },
  [Actions.setUpdatedCampaignsContents]: (state, { payload }) => {
    let newState = state

    payload.forEach(p => {
      const contentId = p.id
      const updated = fromJS(p)

      newState = newState.updateIn(['ccdCampaigns', 'items'], campaigns => (
        campaigns.map(campaign => (
          campaign.updateIn(['ccdContents'], contents => (
            contents.map(content => (
              content.get('id') === contentId ? updated : content
            ))
          ))
        ))
      ))
    })

    return newState
  },
  [combineActions(
    Actions.saveContentSuccess,
    Actions.saveDeletedExternallyForContentSuccess,
    Actions.saveContentFormlessSuccess
  )]: (state, { payload }) => {
    const contentId = payload.id
    const campaignId = payload.ccdCampaign.id
    const updated = fromJS(payload)
    const newState = updateNestedContentsCampaigns(state, contentId, campaignId, updated)

    return newState
      .setIn(['contentFormData', 'id'], contentId)
      .setIn(['contentFormData', 'isDirty'], false)
      .setIn(['contentFormData', 'title'], updated.get('title') || newState.getIn(['contentFormData', 'title']))
      .setIn(['contentFormData', 'approvalStatus'], updated.get('approvalStatus') || newState.getIn(['contentFormData', 'approvalStatus']))
      .set('selectedContent', updated)
  },
  [combineActions(
    Actions.createOtsDraftSuccess,
    Actions.getContentSuccess
  )]: (state, { payload }) => {
    const contentId = payload.id
    const campaignId = payload.ccdCampaign.id
    const updated = fromJS(payload)
    const newState = updateNestedContentsCampaigns(state, contentId, campaignId, updated)

    return newState.set('selectedContent', updated)
  },
  [Actions.resetContents]: state => state.set('ccdContents', initialState.get('ccdContents')),

  // campaignFormData
  [Actions.updateCampaignFormData]: (state, { payload }) => (
    state.update('campaignFormData', oldValue => (oldValue.merge(payload)))
  ),
  [Actions.openCampaignForm]: (state, { payload }) => (
    payload ? state.set('campaignFormData', initialState.get('campaignFormData').merge(payload)) : state
  ),
  [Actions.resetCampaignForm]: state => state.set('campaignFormData', initialState.get('campaignFormData')),

  // ccdStatistics
  [Actions.fetchStatisticsSuccess]: (state, { payload: { total, events, earned, owned } }) => (
    state
      .setIn(['ccdStatistics', 'events'], fromJS(events || []))
      .setIn(['ccdStatistics', 'total'], total)
      .setIn(['ccdStatistics', 'earned'], fromJS(earned))
      .setIn(['ccdStatistics', 'owned'], fromJS(owned || {}))
  ),
  [Actions.resetEvents]: state => state.set('ccdStatistics', initialState.get('ccdStatistics')),
  [Actions.fetchMediaFilesSuccess]: (state, { payload: { mediaFiles, total } }) => (
    state.set('uploadedMediaFiles', fromJS(mediaFiles)).set('uploadedMediaFilesTotal', total)
  ),
  [Actions.appendMediaFiles]: (state, { payload: { mediaFiles, total } }) => (
    state.set('uploadedMediaFiles', state.get('uploadedMediaFiles').concat(fromJS(mediaFiles))).set('uploadedMediaFilesTotal', total)
  ),
  [combineActions(
    Actions.uploadLinkedInMediaFileSuccess,
    Actions.uploadFacebookMediaFileSuccess,
    Actions.uploadEditorImageSuccess
  )]: (state, { payload }) => {
    const files = state.get('uploadedMediaFiles').unshift(fromJS(camelcaseKeysDeep(payload)))
    const uniqueFiles = files.groupBy(file => file.get('id')).map(group => group.first()).toList()

    return state.set('uploadedMediaFiles', uniqueFiles)
  },
  [Actions.deleteMediaFileSuccess]: (state, { payload: { ccdMediaFile, total } }) => {
    const updatedMediaFiles = state.get('uploadedMediaFiles').filter(mediaFile => mediaFile.get('id') !== ccdMediaFile.id)

    return state
      .set('uploadedMediaFiles', updatedMediaFiles)
      .set('uploadedMediaFilesTotal', total)
  },
  [Actions.editorAiRequestSuccess]: (state, { payload }) => (
    state.set('aiRequestResults', state.get('aiRequestResults').unshift(fromJS(payload)))
  ),
  [Actions.setAiSelectedText]: (state, { payload }) => (
    state.set('aiSelectedText', payload)
  ),
  [Actions.resetSocialMediaPostsAndUploads]: state => (
    state
      .setIn(['contentFormData', 'ccdMediaFiles'], fromJS([]))
      .setIn(['contentFormData', 'linkedInPost'], null)
      .setIn(['contentFormData', 'facebookPost'], null)
  ),
  [Actions.setUploadedMediaFilesTotalSize]: (state, { payload }) => state.set('uploadedMediaFilesTotalSize', payload),
  [Actions.buildSocialMediaLinkLinkedInSuccess]: (state, { payload }) => {
    const { title, url, description, id, host, imageUrl } = payload
    const content = fromJS({
      article: {
        title: title || '',
        source: url,
        description: description || '',
        id,
        host,
        imageUrl: imageUrl || ''
      }
    })

    return state
      .setIn(['contentFormData', 'linkedInPost', 'type'], 'link')
      .setIn(['contentFormData', 'linkedInPost', 'content'], content)
  },
  [Actions.buildSocialMediaLinkFacebookSuccess]: (state, { payload }) => {
    const { title, url, description, host, image } = payload

    const linkMetadata = fromJS(
      {
        title,
        source: url,
        description,
        host,
        imageUrl: image
      }
    )

    return state
      .setIn(['contentFormData', 'facebookPost', 'link'], url)
      .setIn(['contentFormData', 'facebookPost', 'linkMetadata'], linkMetadata)
  },
  [Actions.buildSocialMediaLinkLinkedInError]: (state, { payload }) => {
    const rawUrl = payload.url
    const url = new URL(rawUrl)

    const content = fromJS({
      article: {
        title: url.hostname,
        source: rawUrl,
        host: url.hostname
      }
    })

    return state
      .setIn(['contentFormData', 'linkedInPost', 'type'], 'link')
      .setIn(['contentFormData', 'linkedInPost', 'content'], content)
  },
  [Actions.buildSocialMediaLinkFacebookError]: (state, { payload }) => {
    const rawUrl = payload.url
    const url = new URL(rawUrl)

    const linkMetadata = fromJS(
      {
        title: url.hostname,
        source: rawUrl,
        host: url.hostname
      }
    )

    return state
      .setIn(['contentFormData', 'facebookPost', 'link'], rawUrl)
      .setIn(['contentFormData', 'facebookPost', 'linkMetadata'], linkMetadata)
  },
  [Actions.fetchKpiStatisticsSuccess]: (state, { payload }) => (
    state.set('ccdKpiStatistics', fromJS(payload))
  ),
  [Actions.setDistributionListSearchQuery]: (state, { payload }) => (
    state.set('distributionListSearchQuery', payload)
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
