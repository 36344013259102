import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  filtersHistory: [],
  lastIndex: 0,
  currentIndex: 0
})

export default handleActions({
  [Actions.addDrillDownFiltersToHistory]: (state, { payload: filters }) => {
    const history = state.get('filtersHistory')
    const lastIndex = state.get('lastIndex')

    const newIndex = lastIndex + 1
    const newHistory = history.push(fromJS({ filters, index: newIndex }))

    return state.set('filtersHistory', newHistory).set('currentIndex', newIndex).set('lastIndex', newIndex)
  },
  [Actions.setDrillDownHistoryIndex]: (state, { payload: index }) => state.set('currentIndex', index),
  [combineActions(
    Actions.closeDrillDownDialog,
    Actions.clearModuleData
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
