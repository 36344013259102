import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { List, Map, fromJS } from 'immutable'
import useI18n from 'hooks/useI18n'

import { ImageList, ImageListItem, ImageListItemBar, Box, IconButton, Tooltip } from '@mui/material'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import TheatersIcon from '@mui/icons-material/Theaters'
import DeleteIcon from '@mui/icons-material/Delete'
import { green, grey } from '@mui/material/colors'

import Switch from 'components/themed/switch'
import { Button } from 'containers/themed'

import { facebookPost as initialFacebookPost } from 'static/facebook_post_config'

import { formatBytes } from 'utils/number'

const useStyles = makeStyles()({
  video: {
    width: '100%',
    maxHeight: 235
  },
  galleryControls: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageListBox: {
    overflowY: 'scroll',
    height: 460
  },
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '281px !important'
  }
})

const MediaGallery = ({
  contentType,
  contentFormData,
  uploadedMediaFiles,
  loadMoreDisabled,
  requestRunning,
  onChange,
  fetchMoreMediaFilesStart,
  onImageDelete
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const currentFacebookPost = contentFormData.get('facebookPost') || fromJS(initialFacebookPost)
  const currentAttachments = currentFacebookPost.get('mediaAttachments')
  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')
  const currentSelectedMediaFileIds = currentSelectedMediaFiles.map(f => f.get('id'))

  const [uploadedImages, setUploadedImages] = useState(uploadedMediaFiles.filter(f => f.get('mimeType').startsWith('image')))
  const [uploadedVideos, setUploadedVideos] = useState(uploadedMediaFiles.filter(f => f.get('mimeType').startsWith('video')))
  const [mediaType, setMediaType] = useState(contentType)
  const [hoveredImageId, setHoveredImageId] = useState(null)

  const handleDeleteImage = id => {
    onImageDelete({ id })
  }

  useEffect(() => {
    const allUploadedImageFiles = uploadedMediaFiles
      .filter(f => f.get('mimeType').startsWith('image'))
      .concat(contentFormData.get('ccdMediaFiles').filter(f => f.get('mimeType').startsWith('image')))
      .reduce((acc, current) => {
        if (!acc.find(item => item.get('id') === current.get('id'))) {
          return acc.push(current)
        }

        return acc
      }, fromJS([]))

    const allUploadedVideoFiles = uploadedMediaFiles
      .filter(f => f.get('mimeType').startsWith('video'))
      .concat(contentFormData.get('ccdMediaFiles').filter(f => f.get('mimeType').startsWith('video')))
      .reduce((acc, current) => {
        if (!acc.find(item => item.get('id') === current.get('id'))) {
          return acc.push(current)
        }

        return acc
      }, fromJS([]))

    setUploadedImages(allUploadedImageFiles)
    setUploadedVideos(allUploadedVideoFiles)
  }, [uploadedMediaFiles, contentFormData])

  const toggleImageSelect = selectedMediaFile => {
    let newSelected = List()
    let facebookImages = List()

    if (mediaType === 'image' || mediaType === 'text') {
      if (currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))) {
        newSelected = currentSelectedMediaFiles.filter(selected => selected.get('id') !== selectedMediaFile.get('id'))
      } else {
        newSelected = currentSelectedMediaFiles.unshift(fromJS(selectedMediaFile))
      }

      facebookImages = newSelected.map(selected => (fromJS({
        name: selected.get('filename'),
        url: selected.get('url'),
        id: selected.get('id'),
        message: ''
      })))

      onChange({
        key: 'facebookPost',
        value: currentFacebookPost.set('mediaAttachments', facebookImages).set('type', 'image')
      })
      onChange({ key: 'ccdMediaFiles', value: newSelected })
    }
  }

  const toggleVideoSelect = selectedMediaFile => {
    let newSelected = List()
    let facebookVideos = List()

    if (!currentSelectedMediaFileIds.includes(selectedMediaFile.get('id'))) {
      newSelected = newSelected.push(selectedMediaFile)
    }

    facebookVideos = newSelected.map(selected => (fromJS({
      name: selected.get('filename'),
      url: selected.get('url'),
      id: selected.get('id'),
      message: ''
    })))

    onChange({ key: 'facebookPost', value: currentFacebookPost.set('mediaAttachments', facebookVideos).set('type', 'video') })
    onChange({ key: 'ccdMediaFiles', value: newSelected })
  }

  const renderImageGallery = () => {
    if (mediaType !== 'image' && mediaType !== 'text') {
      return null
    }

    if (!uploadedImages.size && !contentFormData.get('ccdMediaFiles').size) {
      return null
    }

    return (
      <Box
        className={classes.imageListBox}
      >
        <ImageList
          cols={3}
          gap={8}
          sx={{ marginTop: 0 }}
        >
          {uploadedImages.map(item => (
            <ImageListItem
              key={item.get('id')}
              onMouseEnter={() => setHoveredImageId(item.get('id'))}
              onMouseLeave={() => setHoveredImageId(null)}
            >
              {(hoveredImageId === item.get('id')) && (
                <ImageListItemBar
                  position="bottom"
                  title={(
                    <Tooltip title={item.get('filename')}>
                      <span>{item.get('filename')}</span>
                    </Tooltip>
                  )}
                  subtitle={formatBytes(item.get('size'))}
                  actionIcon={(
                    <IconButton
                      sx={{ color: 'red' }}
                      onClick={() => handleDeleteImage(item.get('id'))}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  actionPosition="right"
                />
              )}
              {currentSelectedMediaFileIds.includes(item.get('id'))
                && (
                  <ImageListItemBar
                    sx={{ background: 'transparent' }}
                    position="top"
                    actionPosition="left"
                    actionIcon={<CheckCircleRoundedIcon sx={{ color: green[300] }} />}
                  />
                )}
              {/* eslint-disable-next-line */}
              <img
                onClick={_e => toggleImageSelect(item)}
                src={`${item.get('url')}?w=199&h=199&fit=crop&auto=format`}
                srcSet={`${item.get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                alt={item.get('id')}
                loading="lazy"
                style={{ objectFit: 'contain' }}
              />
            </ImageListItem>
          ))}
          <ImageListItem
            key="loadMoreButton"
            className={classes.loadMoreButton}
          >
            <Button
              variant="contained"
              color="primary"
              disabled={loadMoreDisabled}
              onClick={fetchMoreMediaFilesStart}
              loading={requestRunning}
            >{i18n.get('load_more')}
            </Button>
          </ImageListItem>
        </ImageList>
      </Box>
    )
  }

  const getVideoSelectedColor = item => {
    if (currentSelectedMediaFileIds.includes(item.get('id'))) {
      return green[300]
    }

    return grey[400]
  }

  const renderVideoGallery = () => {
    if (mediaType !== 'video') {
      return null
    }

    if (!uploadedVideos.size && !contentFormData.get('ccdMediaFiles').size) {
      return null
    }

    return (
      <Box
        className={classes.imageListBox}
      >
        <ImageList
          cols={2}
          gap={8}
          sx={{ marginTop: 0 }}
        >
          {uploadedVideos.map((item, index) => (
            <ImageListItem
              key={index}
            >
              <video
                className={classes.video}
                controls
                preload="metadata"
              >
                <source
                  src={`${item.get('url')}#t=0.5`}
                />
              </video>
              <ImageListItemBar
                onClick={_e => toggleVideoSelect(item)}
                subtitle={i18n.get('click_here_to_select')}
                position="below"
                actionPosition="left"
                actionIcon={<CheckCircleRoundedIcon sx={{ color: getVideoSelectedColor(item) }} />}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    )
  }

  return (
    <>
      <div className={classes.galleryControls}>
        <div>{i18n.get('last_uploaded')}</div>
        <Switch
          LeftIcon={CameraAltIcon}
          RightIcon={TheatersIcon}
          iconColor="#fffff"
          label="media_type"
          disabled={currentAttachments.size >= 1}
          checked={mediaType === 'video'}
          handleChange={() => setMediaType((mediaType === 'text' || mediaType === 'image') ? 'video' : 'image')}
        />
      </div>
      {renderImageGallery()}
      {renderVideoGallery()}
    </>
  )
}

MediaGallery.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  uploadedMediaFiles: PropTypes.instanceOf(List).isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  fetchMoreMediaFilesStart: PropTypes.func.isRequired,
  onImageDelete: PropTypes.func.isRequired
}

export default MediaGallery
