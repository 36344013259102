import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlack } from '@fortawesome/free-brands-svg-icons/faSlack'
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  webhookOption: {
    display: 'flex',
    alignItems: 'center'
  },
  platformIcon: {
    marginRight: 5
  },
  teams: {
    width: 26
  }
})

export default function WebhookOption({ url }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  let icon = <FontAwesomeIcon icon={faCircleExclamation} />
  let title = i18n.get('webhook_not_supported')

  if (url.includes('webhook.office.com')) {
    icon = <FontAwesomeIcon icon={faMicrosoft} />
    title = 'Microsoft Teams'
  }

  if (url.includes('hooks.slack.com')) {
    icon = <FontAwesomeIcon icon={faSlack} />
    title = 'Slack'
  }

  const shortUrl = `${url.slice(0, 25)}...`
  title = `[${title}] ${url}`

  return (
    <div
      title={title}
      className={classes.webhookOption}
    >
      <span className={classes.platformIcon}>
        {icon}
      </span>
      <span>
        {shortUrl}
      </span>
    </div>
  )
}

WebhookOption.propTypes = {
  url: PropTypes.string.isRequired
}
