import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import MultiFilters from 'components/multi_filters'

const useStyles = makeStyles()({
  container: {
    marginBottom: '1em'
  }
})

function CampaignsFilters({
  currentFilters,
  loading,
  locale,
  searchCampaigns,
  setCampaignFilters,
  setPage
}) {
  const { classes } = useStyles()

  const fieldOptions = [
    {
      field: 'name',
      name: 'name',
      label: 'name',
      type: 'text'
    },
    {
      field: 'start_date',
      name: 'start_date',
      type: 'date'
    },
    {
      field: 'end_date',
      name: 'end_date',
      type: 'date'
    },
    {
      field: 'days_left',
      name: 'days_left',
      label: 'days_left',
      type: 'number'
    },
    {
      field: 'progress',
      name: 'progress',
      label: 'progress',
      type: 'percentage'
    },
    {
      field: 'assignee_full_name',
      name: 'assignee_full_name',
      label: 'assigned_user',
      type: 'text'
    }
  ]

  const handleOnApply = (operator, conditions) => {
    setPage({ key: 'campaignPage', value: 1 })
    setCampaignFilters({ operator, conditions })
    searchCampaigns()
  }

  return (
    <div className={classes.container}>
      <MultiFilters
        currentFilters={currentFilters.toJS()}
        fieldOptions={fieldOptions}
        loading={loading}
        locale={locale}
        onApply={handleOnApply}
        position="bottom-end"
      />
    </div>
  )
}

CampaignsFilters.propTypes = {
  currentFilters: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,

  searchCampaigns: PropTypes.func.isRequired,
  setCampaignFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

export default CampaignsFilters
