import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'

import {
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Collapse,
  Box,
  Typography,
  Tooltip
} from '@mui/material'

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { red } from '@mui/material/colors'

import { capitalize } from 'utils/string'

const CollapsableRow = ({ recipient, isMobile }) => {
  const i18n = useI18n()
  const [open, setOpen] = useState(false)

  const renderBody = () => (
    <TableBody>
      {recipient.events
        .sort((c, n) => {
          const a = new Date(c.createdAt)
          const b = new Date(n.createdAt)

          return a - b
        })
        .map((event, index) => (
          <TableRow key={index}>
            <TableCell
              component="th"
              scope="row"
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {capitalize(event.type)}
                {event.type === 'failed'
                  && (
                    <Tooltip
                      title={`
                      ${i18n.get('status')} ${i18n.get('message')}:
                      ${event.deliveryStatusMessage || i18n.get('no_delivery_status_message')}
                      `}
                      placement="right"
                      arrow
                    >
                      <InfoIcon
                        sx={{ color: red[500] }}
                      />
                    </Tooltip>
                  )}
              </Box>
            </TableCell>
            <TableCell>{moment(event.createdAt).format('D MMM, YYYY H:mm:ss')}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  )

  return (
    <>
      <TableRow>
        <TableCell>
          {isMobile ? (`${recipient.firstName} ${recipient.lastName}`) : recipient.firstName}
        </TableCell>
        {!isMobile && (
        <TableCell>
          {recipient.lastName}
        </TableCell>
        )}
        {!isMobile && (
          <TableCell>
            {recipient.email}
          </TableCell>
        )}
        <TableCell
          align="right"
          sx={{ padding: isMobile ? 0 : '16px' }}
        >
          <IconButton
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      {isMobile && (
        <TableRow>
          <TableCell>
            {recipient.email}
          </TableCell>
          <TableCell />
        </TableRow>
      )}
      <TableRow sx={{
        backgroundColor: t => !t.isDark && '#F0F0F0'
      }}
      >
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
              >
                {i18n.get('events')}
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{i18n.get('type')}</TableCell>
                    <TableCell>{i18n.get('occurred_at')}</TableCell>
                  </TableRow>
                </TableHead>
                {renderBody()}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

CollapsableRow.propTypes = {
  recipient: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
}

export default CollapsableRow
