import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Actions from 'components/saved_searches/actions'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'

import ContentContainer from 'components/content_container'
import AppBar from 'containers/AppBar'

import Topics from 'containers/subscriptions/Topics'
import Editions from 'containers/subscriptions/Editions'
import { Typography } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  introduction: {
    marginTop: 10,
    maxWidth: 1000,
    fontWeight: 400,
    fontSize: '16px',
    [theme.breakpoints.only('xs')]: {
      margin: '0 30px'
    }
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 76,
      paddingRight: 76
    }
  },
  greeting: {
    [theme.breakpoints.only('xs')]: {
      margin: 30
    }
  }
}))

const Intro = ({ user }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <>
      <Typography
        variant="h4"
        className={classes.greeting}
      >
        {i18n.get('hello')} {user.get('firstName')} {user.get('lastName')},
      </Typography>
      <br />
      <Typography
        variant="h6"
        className={classes.introduction}
        dangerouslySetInnerHTML={{
          __html: i18n.get('my_news_introduction')
        }}
      />
    </>
  )
}

export default function Subscriptions({ user }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <>
      <AppBar
        title={i18n.get('my_news')}
        actions={<Actions />}
      />
      <ContentContainer className={classes.container}>
        <Intro user={user} />
        <Editions />
        <Topics />
      </ContentContainer>
    </>
  )
}

Subscriptions.propTypes = {
  user: PropTypes.instanceOf(ImmutableMap).isRequired
}
Intro.propTypes = {
  user: PropTypes.instanceOf(ImmutableMap).isRequired
}
