import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import camelcase from 'camelcase'

import { makeStyles } from 'tss-react/mui'

import {
  CircularProgress,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import Content from 'containers/content_desk_new/contents/content/Content'
import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  tableContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '20px'
  },
  lastEditedLabel: {
    minWidth: 200
  },
  loadingContainer: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  sortIconActive: {
    fontSize: '0.6rem !important'
  },
  sortIconInActive: {
    fontSize: '0.6rem !important',
    opacity: 0.5
  }
})

const Contents = ({
  contents,
  loading,
  showCampaigns,
  sortBy,
  sortOrder,

  onSortBy,
  setPage
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [sortedContents, setSortedContents] = useState(contents.get('items'))
  const [currentSortBy, setCurrentSortBy] = useState(sortBy)
  const [currentSortOrder, setCurrentSortOrder] = useState(sortOrder)

  useEffect(() => {
    setSortedContents(contents.get('items'))
  }, [contents])

  let handleSortingClick = field => {
    let newSortOrder = ''
    let newSortField = camelcase(field)

    if (field === currentSortBy) {
      if (currentSortOrder === '') {
        newSortOrder = 'asc'
      }

      if (currentSortOrder === 'asc') {
        newSortOrder = 'desc'
      }

      if (currentSortOrder === 'desc') {
        newSortOrder = ''
        newSortField = ''
      }
    } else {
      newSortOrder = 'asc'
    }

    if (newSortField === '') {
      setSortedContents(contents.get('items'))
      setCurrentSortBy('')
      setCurrentSortOrder('')

      return
    }

    const sorted = sortedContents.sort((a, b) => {
      if (a.get(newSortField) < b.get(newSortField)) {
        return newSortOrder === 'asc' ? -1 : 1
      }

      if (a.get(newSortField) > b.get(newSortField)) {
        return newSortOrder === 'asc' ? 1 : -1
      }

      return 0
    })

    setSortedContents(sorted)
    setCurrentSortBy(field)
    setCurrentSortOrder(newSortOrder)
  }

  if (setPage && onSortBy) {
    handleSortingClick = field => {
      let newSortOrder = ''
      let newSortField = field

      if (field === currentSortBy) {
        if (currentSortOrder === '') {
          newSortOrder = 'asc'
        }

        if (currentSortOrder === 'asc') {
          newSortOrder = 'desc'
        }

        if (currentSortOrder === 'desc') {
          newSortOrder = ''
          newSortField = ''
        }
      } else {
        newSortOrder = 'asc'
      }

      setPage({ key: 'contentPage', value: 1 })
      onSortBy({ type: 'contents', sortBy: newSortField, sortOrder: newSortOrder })
      setCurrentSortBy(field)
      setCurrentSortOrder(newSortOrder)
    }
  }

  const renderSortIcon = field => {
    if (sortedContents.size === 1) {
      return null
    }

    let title = i18n.get('sort')
    let icon = <SwapVertIcon />
    let iconClass = classes.sortIconInActive

    if (field === currentSortBy) {
      if (currentSortOrder === 'asc') {
        iconClass = classes.sortIconActive
        icon = <ArrowUpwardIcon />
        title = i18n.get('ascending')
      }

      if (currentSortOrder === 'desc') {
        iconClass = classes.sortIconActive
        icon = <ArrowDownwardIcon />
        title = i18n.get('descending')
      }
    }

    return (
      <IconButton
        title={title}
        icon={icon}
        size="small"
        className={iconClass}
        onClick={() => handleSortingClick(field)}
      />
    )
  }

  const renderContents = () => sortedContents.map((el, index) => (
    <Content
      content={el}
      key={index}
      showCampaigns={showCampaigns}
    />
  ))

  return (
    <div className={classes.tableContainer}>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress
            color="primary"
            size={26}
          />
        </div>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {i18n.get('name')} {renderSortIcon('title')}
            </TableCell>
            <TableCell className={classes.lastEditedLabel}>
              {i18n.get('last_edited')} {renderSortIcon('updated_at')}
            </TableCell>
            {showCampaigns && (
              <TableCell>
                {i18n.get('campaign')}
              </TableCell>
            )}
            <TableCell>
              {i18n.get('editing_status')}
            </TableCell>
            <TableCell>
              {i18n.get('sending_status')} {renderSortIcon('scheduled_for')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {renderContents()}
        </TableBody>
      </Table>
    </div>
  )
}

Contents.defaultProps = {
  showCampaigns: true,
  loading: false,
  sortBy: null,
  sortOrder: 'asc'
}

Contents.propTypes = {
  contents: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool,
  showCampaigns: PropTypes.bool,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,

  onSortBy: PropTypes.func,
  setPage: PropTypes.func
}

export default Contents
