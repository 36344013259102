import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/charts'
import * as AppActions from 'actions/app'

/*
lg >= 1280px
md >= 960px
sm >= 600px
*/
export const initialState = fromJS({
  charts: {
    lg: [
      { type: 'rectangle', firstLevel: 'reach' },
      { type: 'rectangle', firstLevel: 'buzz' },
      { type: 'rectangle', firstLevel: 'visits' },
      { type: 'area', firstLevel: 'timeline' },
      { type: 'bar', firstLevel: 'languages' },
      { type: 'worldMap', firstLevel: 'countries' },
      { type: 'tagCloud', firstLevel: 'hashtags' },
      { type: 'bar', firstLevel: 'channels' },
      { type: 'bar', firstLevel: 'mediaTypes' },
      { type: 'tagCloud', firstLevel: 'emojis' }
    ],
    md: [
      { type: 'rectangle', firstLevel: 'reach' },
      { type: 'rectangle', firstLevel: 'buzz' },
      { type: 'rectangle', firstLevel: 'visits' },
      { type: 'area', firstLevel: 'timeline' },
      { type: 'worldMap', firstLevel: 'countries' },
      { type: 'bar', firstLevel: 'languages' },
      { type: 'bar', firstLevel: 'mediaTypes' },
      { type: 'bar', firstLevel: 'channels' },
      { type: 'tagCloud', firstLevel: 'hashtags' },
      { type: 'tagCloud', firstLevel: 'emojis' }
    ],
    sm: [
      { type: 'rectangle', firstLevel: 'reach' },
      { type: 'rectangle', firstLevel: 'buzz' },
      { type: 'rectangle', firstLevel: 'visits' },
      { type: 'area', firstLevel: 'timeline' },
      { type: 'worldMap', firstLevel: 'countries' },
      { type: 'bar', firstLevel: 'languages' },
      { type: 'bar', firstLevel: 'mediaTypes' },
      { type: 'bar', firstLevel: 'channels' },
      { type: 'tagCloud', firstLevel: 'hashtags' },
      { type: 'tagCloud', firstLevel: 'emojis' }
    ],
    xs: [
      { type: 'rectangle', firstLevel: 'reach' },
      { type: 'rectangle', firstLevel: 'buzz' },
      { type: 'rectangle', firstLevel: 'visits' },
      { type: 'area', firstLevel: 'timeline' },
      { type: 'worldMap', firstLevel: 'countries' },
      { type: 'bar', firstLevel: 'languages' },
      { type: 'bar', firstLevel: 'mediaTypes' },
      { type: 'bar', firstLevel: 'channels' },
      { type: 'tagCloud', firstLevel: 'hashtags' },
      { type: 'tagCloud', firstLevel: 'emojis' }
    ]
  },
  layouts: {
    lg: [
      { y: 0, x: 0, w: 4, h: 2 },
      { y: 2, x: 0, w: 2, h: 2 },
      { y: 2, x: 2, w: 2, h: 2 },
      { y: 0, x: 4, w: 8, h: 4 },
      { y: 4, x: 0, w: 4, h: 4 },
      { y: 4, x: 4, w: 4, h: 4 },
      { y: 4, x: 8, w: 4, h: 4 },
      { y: 8, x: 0, w: 4, h: 4 },
      { y: 8, x: 4, w: 4, h: 4 },
      { y: 8, x: 8, w: 4, h: 4 }
    ],
    md: [
      { y: 0, x: 0, w: 4, h: 1 },
      { y: 0, x: 4, w: 4, h: 1 },
      { y: 0, x: 8, w: 4, h: 1 },
      { y: 1, x: 0, w: 12, h: 3 },
      { y: 4, x: 0, w: 6, h: 3 },
      { y: 4, x: 6, w: 6, h: 3 },
      { y: 7, x: 0, w: 6, h: 3 },
      { y: 7, x: 6, w: 6, h: 3 },
      { y: 10, x: 0, w: 6, h: 3 },
      { y: 10, x: 6, w: 6, h: 3 }
    ],
    sm: [
      { y: 0, x: 0, w: 12, h: 1 },
      { y: 1, x: 0, w: 6, h: 1 },
      { y: 1, x: 6, w: 6, h: 1 },
      { y: 2, x: 0, w: 12, h: 3 },
      { y: 5, x: 0, w: 12, h: 3 },
      { y: 8, x: 0, w: 12, h: 3 },
      { y: 11, x: 0, w: 12, h: 3 },
      { y: 14, x: 0, w: 12, h: 3 },
      { y: 17, x: 0, w: 12, h: 3 },
      { y: 20, x: 0, w: 12, h: 3 }
    ],
    xs: [
      { y: 0, x: 0, w: 12, h: 1 },
      { y: 1, x: 0, w: 12, h: 1 },
      { y: 2, x: 0, w: 12, h: 1 },
      { y: 3, x: 0, w: 12, h: 3 },
      { y: 6, x: 0, w: 12, h: 3 },
      { y: 9, x: 0, w: 12, h: 3 },
      { y: 12, x: 0, w: 12, h: 3 },
      { y: 15, x: 0, w: 12, h: 3 },
      { y: 18, x: 0, w: 12, h: 3 },
      { y: 21, x: 0, w: 12, h: 3 }
    ]
  }
})

const updateFieldInCharts = (state, field, value) => state.update(
  'charts',
  charts => charts.reduce((acc, values, key) => acc.set(key, values.map(c => c.set(field, value))), fromJS({}))
)

export default handleActions({
  [Actions.aggregationsRequestStart]: state => updateFieldInCharts(state, 'loading', true),
  [Actions.aggregationsRequestError]: state => updateFieldInCharts(state, 'loading', false),
  [Actions.aggregationsRequestSuccess]: state => updateFieldInCharts(state, 'loading', false),

  [Actions.setAggregationValue]: (state, { payload: value }) => (value ? updateFieldInCharts(state, 'thirdLevel', value) : initialState),
  [Actions.setStaticChartData]: (state, { payload: { index, breakpoint, data } }) => {
    const chart = state.getIn(['charts', breakpoint, index])

    if (chart) {
      return state.setIn(['charts', breakpoint, index], chart.merge({
        data: fromJS(data),
        loading: false
      }))
    }

    return state
  },

  [AppActions.resetState]: () => initialState
}, initialState)
