import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_charts'
import * as AppActions from 'actions/app'

export const initialState = fromJS({})

export default handleActions({
  [Actions.fetchSavedChartSuccess]: (state, { payload: savedChart }) => fromJS(savedChart),
  [Actions.fetchSavedChartError]: () => fromJS({ chart: { chartError: { message: 'not found' } } }),

  [Actions.createSavedChartSuccess]: (state, { payload: savedChart }) => fromJS(savedChart),

  [combineActions(
    AppActions.resetState,
    Actions.fetchSavedChartStart,
    Actions.createSavedChartStart
  )]: () => initialState
}, initialState)
