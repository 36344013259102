import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/contact_management'
import * as AppActions from 'actions/app'

import { ContactManagementSearchFields } from 'static/constants'

const cmSearchFieldsMapping = {
  [ContactManagementSearchFields.CONTACT_NAME]: {
    stateKeyName: 'contactSearchQueries'
  },
  [ContactManagementSearchFields.CONTACT_TAG]: {
    stateKeyName: 'contactSearchQueries'
  },
  [ContactManagementSearchFields.DISTRIBUTION_LIST_NAME]: {
    stateKeyName: 'distributionListSearchQueries'
  },
  [ContactManagementSearchFields.DISTRIBUTION_LIST_CONTACTS]: {
    stateKeyName: 'distributionListContactsSearchQueries'
  }
}

export const initialState = fromJS({
  distributionListPage: 1,
  distributionListLimit: 10,
  contactPage: 1,
  contactLimit: 10,
  showUnsubscribed: false,
  tagsPage: 1,
  tagsLimit: 100,
  contactSearchQueries: [],
  distributionListSearchQueries: [],
  distributionListContactsSearchQueries: []
})

export default handleActions({
  [Actions.addSearchQuery]: (state, { payload: { query, selectedQueryType } }) => (
    state.update(cmSearchFieldsMapping[selectedQueryType].stateKeyName, queries => queries.push(fromJS({
      label: query,
      value: query,
      field: selectedQueryType
    })))),
  [Actions.addDistributionListContactsSearchQuery]: (state, { payload: { query, selectedQueryType } }) => (
    state.set(cmSearchFieldsMapping[selectedQueryType].stateKeyName, fromJS([{
      label: query.label,
      value: query.value,
      field: selectedQueryType
    }]))),
  [Actions.removeFilter]: (state, { payload: { field, index } }) => (
    state.update(cmSearchFieldsMapping[field].stateKeyName, list => list.delete(index))
  ),
  [Actions.removeDistributionListContactFilter]: state => state
    .set('distributionListContactsSearchQueries', initialState.get('distributionListContactsSearchQueries')),
  [Actions.updateFilter]: (state, { payload: { filter, index } }) => {
    const { stateKeyName } = cmSearchFieldsMapping[filter.get('field')]

    return state.setIn([stateKeyName, index], filter.set('updated', true))
  },
  [Actions.setContactsPage]: (state, { payload: page }) => state.set('contactPage', page),
  [Actions.setDistributionListsPage]: (state, { payload: page }) => state.set('distributionListPage', page),
  [Actions.toggleContactsShowUnsubscribed]: state => {
    let newState = state.set('showUnsubscribed', !state.get('showUnsubscribed'))

    if (newState.get('showUnsubscribed')) {
      newState = newState.set('contactPage', 1)
    }

    return newState
  },
  [Actions.resetFilters]: state => (
    state.merge(initialState)
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
