import { createAction } from 'redux-actions'

const defaultPrefix = 'ANEWSTIP/'
let prefix = defaultPrefix

export const clearModuleData = createAction(`${prefix}CLEAR_MODULE_DATA`)

// STATICS
prefix = `${defaultPrefix}STATICS/`

export const fetchStaticsStart = createAction(`${prefix}FETCH_STATICS_START`)
export const fetchStaticsSuccess = createAction(`${prefix}FETCH_STATICS_SUCCESS`)
export const fetchStaticsError = createAction(`${prefix}FETCH_STATICS_ERROR`)

export const searchSuggestStart = createAction(`${prefix}SEARCH_SUGGEST_START`)
export const searchSuggestSuccess = createAction(`${prefix}SEARCH_SUGGEST_SUCCESS`)
export const searchSuggestError = createAction(`${prefix}SEARCH_SUGGEST_ERROR`)

export const setStatics = createAction(`${prefix}SET_STATICS`)
export const setSuggestions = createAction(`${prefix}SET_SUGGESTIONS`)

// SEARCH
prefix = `${defaultPrefix}SEARCH/`

export const setSearchForm = createAction(`${prefix}SET_SEARCH_FORM`)

export const combinedSearchStart = createAction(`${prefix}COMBINED_SEARCH_START`)
export const combinedSearchSuccess = createAction(`${prefix}COMBINED_SEARCH_SUCCESS`)
export const combinedSearchError = createAction(`${prefix}COMBINED_SEARCH_ERROR`)

export const searchJournalistsStart = createAction(`${prefix}SEARCH_JOURNALISTS_START`)
export const searchJournalistsSuccess = createAction(`${prefix}SEARCH_JOURNALISTS_SUCCESS`)
export const searchJournalistsError = createAction(`${prefix}SEARCH_JOURNALISTS_ERROR`)

export const searchOutletsStart = createAction(`${prefix}SEARCH_OUTLETS_START`)
export const searchOutletsSuccess = createAction(`${prefix}SEARCH_OUTLETS_SUCCESS`)
export const searchOutletsError = createAction(`${prefix}SEARCH_OUTLETS_ERROR`)

export const searchArticlesStart = createAction(`${prefix}SEARCH_ARTICLES_START`)
export const searchArticlesSuccess = createAction(`${prefix}SEARCH_ARTICLES_SUCCESS`)
export const searchArticlesError = createAction(`${prefix}SEARCH_ARTICLES_ERROR`)

// FILTERS
prefix = `${defaultPrefix}FILTERS/`

export const setFiltersForm = createAction(`${prefix}SET_FILTERS_FORM`)
export const resetEntityFilters = createAction(`${prefix}RESET_ENTITY_FILTERS`)
export const resetPaginationFilter = createAction(`${prefix}RESET_PAGINATION_FILTER`)

// SELECTED
prefix = `${defaultPrefix}SELECTED/`

export const setSelectedResults = createAction(`${prefix}SET_SELECTED_RESULTS`)

// UI
prefix = `${defaultPrefix}UI/`

export const switchSearchType = createAction(`${prefix}SWITCH_SEARCH_TYPE`)
export const switchResultsTab = createAction(`${prefix}SWITCH_RESULTS_TAB`)
export const openDrillDownDialog = createAction(`${prefix}OPEN_DRILL_DOWN_DIALOG`)
export const closeDrillDownDialog = createAction(`${prefix}CLOSE_DRILL_DOWN_DIALOG`)
export const setDrillDownEntity = createAction(`${prefix}SET_DRILL_DOWN_ENTITY`)
export const switchDrillDownTab = createAction(`${prefix}SWITCH_DRILL_DOWN_TAB`)

export const showDuplicateContactsDialog = createAction(`${prefix}SHOW_DUPLICATE_CONTACTS_DIALOG`)
export const hideDuplicateContactsDialog = createAction(`${prefix}HIDE_DUPLICATE_CONTACTS_DIALOG`)

export const lockNavigation = createAction(`${prefix}LOCK_NAVIGATION`)
export const setLimitedFunctionality = createAction(`${prefix}SET_LIMITED_FUNCTIONALITY`)

export const showDistributionListCreateDialog = createAction(`${prefix}SHOW_DISTRIBUTION_LIST_CREATE_DIALOG`)
export const hideDistributionListCreateDialog = createAction(`${prefix}HIDE_DISTRIBUTION_LIST_CREATE_DIALOG`)

export const setImportWithDistributionLists = createAction(`${prefix}SET_IMPORT_WITH_DISTRIBUTION_LISTS`)
export const setImportContactsForDistributionLists = createAction(`${prefix}SET_IMPORT_CONTACTS_FOR_DISTRIBUTION_LISTS`)

// DATA
prefix = `${defaultPrefix}DATA/`

export const setEntity = createAction(`${prefix}SET_ENTITY`)

// IMPORT
prefix = `${defaultPrefix}IMPORT/`

export const importStart = createAction(`${prefix}IMPORT_START`)
export const importSuccess = createAction(`${prefix}IMPORT_SUCCESS`)
export const importError = createAction(`${prefix}IMPORT_ERROR`)

export const addJournalistsToDistributionList = createAction(`${prefix}ADD_JOURNALIST_TO_DISTRIBUTION_LIST`)

export const keepContact = createAction(`${prefix}KEEP_CONTACT`)
export const removeContactFromDuplicateList = createAction(`${prefix}REMOVE_CONTACT_FROM_DUPLICATE_LIST`)

export const replaceContactStart = createAction(`${prefix}REPLACE_CONTACT_START`)
export const replaceContactSuccess = createAction(`${prefix}REPLACE_CONTACT_SUCCESS`)
export const replaceContactError = createAction(`${prefix}REPLACE_CONTACT_ERROR`)

// DRILL DOWN FILTERS
prefix = `${defaultPrefix}DRILL_DOWN_FILTERS/`

export const setDrillDownFiltersState = createAction(`${prefix}SET_DRILL_DOWN_FILTERS_STATE`)
export const setDrillDownFiltersForm = createAction(`${prefix}SET_DRILL_DOWN_FILTERS_FORM`)
export const toggleDrillDownTopic = createAction(`${prefix}TOGGLE_DRILL_DOWN_TOPIC`)

// DRILL DOWN DATA
prefix = `${defaultPrefix}DRILL_DOWN_DATA/`

export const drillDownRequestStart = createAction(`${prefix}DRILL_DOWN_REQUEST_START`)
export const drillDownRequestSuccess = createAction(`${prefix}DRILL_DOWN_REQUEST_SUCCESS`)
export const drillDownRequestError = createAction(`${prefix}DRILL_DOWN_REQUEST_ERROR`)

export const searchDrillDownJournalistsStart = createAction(`${prefix}SEARCH_DRILL_DOWN_JOURNALISTS_START`)
export const searchDrillDownJournalistsSuccess = createAction(`${prefix}SEARCH_DRILL_DOWN_JOURNALISTS_SUCCESS`)
export const searchDrillDownJournalistsError = createAction(`${prefix}SEARCH_DRILL_DOWN_JOURNALISTS_ERROR`)

export const searchDrillDownOutletsStart = createAction(`${prefix}SEARCH_DRILL_DOWN_OUTLETS_START`)
export const searchDrillDownOutletsSuccess = createAction(`${prefix}SEARCH_DRILL_DOWN_OUTLETS_SUCCESS`)
export const searchDrillDownOutletsError = createAction(`${prefix}SEARCH_DRILL_DOWN_OUTLETS_ERROR`)

export const searchDrillDownArticlesStart = createAction(`${prefix}SEARCH_DRILL_DOWN_ARTICLES_START`)
export const searchDrillDownArticlesSuccess = createAction(`${prefix}SEARCH_DRILL_DOWN_ARTICLES_SUCCESS`)
export const searchDrillDownArticlesError = createAction(`${prefix}SEARCH_DRILL_DOWN_ARTICLES_ERROR`)

export const setDrillDownResult = createAction(`${prefix}SET_DRILL_DOWN_RESULT`)

// DRILL DOWN DATA
prefix = `${defaultPrefix}DRILL_DOWN_HISTORY/`

export const addDrillDownFiltersToHistory = createAction(`${prefix}ADD_DRILL_DOWN_FILTERS_TO_HISTORY`)

export const drillDownHistorySearch = createAction(`${prefix}DRILL_DOWN_HISTORY_SEARCh`)

export const setDrillDownHistoryIndex = createAction(`${prefix}SET_DRILL_DOWN_HISTORY_INDEX`)

