import React from 'react'
import PropTypes from 'prop-types'
import { CSS } from '@dnd-kit/utilities'
import { useSortable } from '@dnd-kit/sortable'

export default function SortableItem({ id, children, disabled }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id, disabled })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  )
}

SortableItem.defaultProps = {
  disabled: false
}

SortableItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool
}
