import { connect } from 'react-redux'

import DuplicateContactItem from 'components/anewstip/duplicate_contacts_dialog/duplicate_contact_item/DuplicateContactItem'

import { getAnewstipReplaceRequestRunning } from 'selectors'

import { replaceContactStart, keepContact } from 'actions/anewstip'

const mapStateToProps = (state, ownProps) => ({
  data: ownProps.data,
  requestRunning: getAnewstipReplaceRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onReplace: replaceContactStart,
    onKeep: keepContact
  }
)(DuplicateContactItem)
