import React from 'react'
import PropTypes from 'prop-types'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import MenuIcon from '@mui/icons-material/Menu'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { IconButton } from 'containers/themed'

import { AppBar as MuiAppBar, Toolbar, Fade } from '@mui/material'

const useStyles = makeStyles()({
  appBar: {
    zIndex: '200 !important'
  },
  noShadow: {
    boxShadow: 'none !important'
  },
  fadeIn: {
    animation: `${keyframes`
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          `} 500ms;`
  },
  title: {
    fontSize: '15pt',
    marginLeft: 24,
    marginRight: 24
  },
  spacer: {
    flex: 1
  },
  messageBar: {
    display: 'flex',
    justifyContent: 'center'
  },
  hiddenAppBar: {
    visibility: 'hidden'
  },
  button: {
    marginLeft: -12,
    marginRight: -12
  }
})

export default function AppBar({
  isBackButtonPage,
  onNavigationToggle,
  message,
  back,
  onBackClick,
  color,
  title,
  actions,
  children
}) {
  const { classes } = useStyles()

  const renderBurger = () => {
    if (message) {
      return null
    }

    if (isBackButtonPage) {
      return (
        <IconButton
          className={`${classes.fadeIn} ${classes.button}`}
          color="inherit"
          onClick={() => (onBackClick ? onBackClick() : back())}
          size="large"
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      )
    }

    return (
      <IconButton
        className={`${classes.fadeIn} ${classes.button}`}
        color="inherit"
        onClick={() => onNavigationToggle()}
        size="large"
      >
        <MenuIcon />
      </IconButton>
    )
  }

  const renderContentBar = () => {
    if (message) {
      return (
        <MuiAppBar color="secondary">
          <Toolbar className={classes.messageBar}>
            <Fade in>
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </Fade>
          </Toolbar>
        </MuiAppBar>
      )
    }

    let titleComp = null

    if (title) {
      titleComp = (<div className={`${classes.title} ${classes.fadeIn}`}>{title}</div>)
    }

    return (
      <MuiAppBar
        enableColorOnDark
        className={classes.appBar}
        color={color}
      >
        <Toolbar>
          {renderBurger()}
          {titleComp}
          {children || <div className={classes.spacer} />}
          <div className={classes.spacer} />
          {actions}
        </Toolbar>
      </MuiAppBar>
    )
  }

  return (
    <>
      {renderContentBar()}

      <MuiAppBar
        position="static"
        className={classes.hiddenAppBar}
        key="hidden"
      >
        <Toolbar />
      </MuiAppBar>
    </>
  )
}

AppBar.propTypes = {
  children: PropTypes.any,
  isBackButtonPage: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  color: PropTypes.string,
  message: PropTypes.any,
  actions: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),

  onNavigationToggle: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  onBackClick: PropTypes.func
}
