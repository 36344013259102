import { connect } from 'react-redux'

import FilterTimeline from 'components/date_form/filter_timeline'
import { drilldown } from 'actions/charts'
import { getFilterTimeline, getShowFilterTimeline } from 'selectors'
import { fetchFilterTimelineStart } from 'actions/news'

const mapStateToProps = state => ({
  chart: getFilterTimeline(state),
  hidden: !getShowFilterTimeline(state)
})

export default connect(
  mapStateToProps,
  {
    onClick: drilldown,
    onDateChange: fetchFilterTimelineStart
  }
)(FilterTimeline)
