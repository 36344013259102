import { combineReducers } from 'redux-immutable'

import ui from './ui'
import statics from './statics'
import search from './search'
import filters from './filters'
import selected from './selected'
import data from './data'
import imports from './imports'
import drillDownData from './drill_down_data'
import drillDownFilters from './drill_down_filters'
import drillDownHistory from './drill_down_history'

export default combineReducers({
  ui,
  statics,
  search,
  filters,
  selected,
  data,
  imports,
  drillDownData,
  drillDownFilters,
  drillDownHistory
})
