import masterConfig from './master_config'

const theme = masterConfig.get('theme').toJS()

export const ChartBackgroundColors = {
  DARKMODE: '#424242',
  PLOTLIGHTS: '#1B465C',
  DEFAULT: '#FFFFFF'
}

export default [
  {
    name: 'Default',
    labelKey: 'default_theme',
    colors: {
      light: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#2B7B87',
          color02: '#06A69B',
          color03: '#F95701',
          color04: '#616161',
          color05: '#C8C8C8',
          color06: '#80CBC4',
          color07: '#1EF0E2',
          color08: '#9B9B9B',
          color09: '#FF8F54',
          color10: '#4A4A4A'
        }
      },
      dark: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#2B7B87',
          color02: '#06A69B',
          color03: '#F95701',
          color04: '#616161',
          color05: '#C8C8C8',
          color06: '#80CBC4',
          color07: '#1EF0E2',
          color08: '#9B9B9B',
          color09: '#FF8F54',
          color10: '#4A4A4A'
        }
      }
    }
  },
  {
    name: 'Theme 2',
    labelKey: 'theme',
    colors: {
      light: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#4A90E2',
          color02: '#0254B5',
          color03: '#002755',
          color04: '#89BCF7',
          color05: '#FFC900',
          color06: '#F8E71C',
          color07: '#E2E0CE',
          color08: '#9B9B9B',
          color09: '#00577D',
          color10: '#4A4A4A'
        }
      },
      dark: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#4A90E2',
          color02: '#0254B5',
          color03: '#002755',
          color04: '#89BCF7',
          color05: '#FFC900',
          color06: '#F8E71C',
          color07: '#E2E0CE',
          color08: '#9B9B9B',
          color09: '#00577D',
          color10: '#4A4A4A'
        }
      }
    }
  },
  {
    name: 'Theme 3',
    labelKey: 'theme',
    colors: {
      light: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#4A90E2',
          color02: '#A3D000',
          color03: '#FFDB00',
          color04: '#F7A700',
          color05: '#D2000B',
          color06: '#FF005E',
          color07: '#FF7FB1',
          color08: '#9100FF',
          color09: '#1F00FF',
          color10: '#647E00'
        }
      },
      dark: {
        primary: theme.nrx.colors.primary,
        accent: theme.nrx.colors.accent,
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#4A90E2',
          color02: '#A3D000',
          color03: '#FFDB00',
          color04: '#F7A700',
          color05: '#D2000B',
          color06: '#FF005E',
          color07: '#FF7FB1',
          color08: '#9100FF',
          color09: '#1F00FF',
          color10: '#647E00'
        }
      }
    }
  },
  {
    name: 'Theme 4',
    labelKey: 'theme',
    colors: {
      light: {
        primary: '#68BAB4',
        accent: '#FF8F54',
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#68BAB4',
          color02: '#028981',
          color03: '#DF5D61',
          color04: '#616161',
          color05: '#C8C8C8',
          color06: '#80CBC4',
          color07: '#D0C8A2',
          color08: '#D38A5B',
          color09: '#FF8F54',
          color10: '#4A4A4A'
        }
      },
      dark: {
        primary: '#68BAB4',
        accent: '#FF8F54',
        highlighting: theme.nrx.colors.highlighting,
        highlightingBackground: theme.nrx.colors.highlightingBackground,
        charts: {
          color01: '#68BAB4',
          color02: '#028981',
          color03: '#DF5D61',
          color04: '#616161',
          color05: '#C8C8C8',
          color06: '#80CBC4',
          color07: '#D0C8A2',
          color08: '#D38A5B',
          color09: '#FF8F54',
          color10: '#4A4A4A'
        }
      }
    }
  },
  {
    name: 'Theme 5',
    labelKey: 'plotlights',
    theme: 'plotlights',
    colors: {
      dark: {
        primary: theme.plotlights.colors.primary,
        accent: theme.plotlights.colors.accent,
        highlighting: theme.plotlights.colors.highlighting,
        highlightingBackground: theme.plotlights.colors.highlightingBackground,
        charts: {
          color01: '#FF6E40',
          color02: '#BF7DFF',
          color03: '#07E89C',
          color04: '#FFE100',
          color05: '#5CB3FF',
          color06: '#FFA3C7',
          color07: '#8BFB19',
          color08: '#FFB764',
          color09: '#19F5FB',
          color10: '#EC008C'
        }
      },
      light: {
        primary: theme.plotlights.colors.primary,
        accent: theme.plotlights.colors.accent,
        highlighting: '#FF6E40',
        highlightingBackground: theme.plotlights.colors.highlightingBackground,
        charts: {
          color01: '#FF6E40',
          color02: '#9F41F7',
          color03: '#3FC985',
          color04: '#F6C703',
          color05: '#5CB4FF',
          color06: '#F67FB3',
          color07: '#403EFF',
          color08: '#65C800',
          color09: '#359B94',
          color10: '#EC008C'
        }
      }
    }
  }
]
