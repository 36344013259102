import { connect } from 'react-redux'

import DeeplApiKeyCard from 'components/administration/external_accounts/deepl_button/deepl_api_key_card/DeeplApiKeyCard'

import {
  getDeeplApiKey,
  getApiKeyRequestRunning
} from 'selectors'

import {
  deleteApiKeyStart
} from 'actions/api_keys'

const mapStateToProps = state => ({
  deeplApiKey: getDeeplApiKey(state),
  loading: getApiKeyRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onDelete: deleteApiKeyStart
  }
)(DeeplApiKeyCard)
