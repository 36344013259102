import React from 'react'
import PropTypes from 'prop-types'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { makeStyles } from 'tss-react/mui'
import { List, Map } from 'immutable'

import { createLoadable } from 'utils/loadable'

import CapSafe, { ViewConfigCapSafe } from 'containers/CapSafe'
import { Capabilities, NewsPageViews } from 'static/constants'

import { Button, IconButton, Menu } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { MenuItem, Divider } from '@mui/material'

const useStyles = makeStyles()({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      marginRight: 5,
      '&:last-child': {
        marginRight: 0
      }
    }
  }
})

const ExportMenu = createLoadable(() => (
  import(/* webpackChunkName: "ShoppingCartExportMenu" */ 'containers/ExportMenu')
))

const ExportButton = ViewConfigCapSafe(ExportMenu, 'export')
const AiAnalysis = CapSafe(Button, Capabilities.HAS_AI_SUMMARY_FEATURE)

export default function Actions({
  onCustomTagClick,
  onRemoveCustomTagClick,
  onReset,
  onNewNewsClick,
  onAiAnalysisClick,
  customTagMode,
  allowedViews,
  capabilities,
  viewConfigCaps
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (customTagMode) {
    return (
      <div className={classes.actions}>
        <ExportButton />
      </div>
    )
  }

  const menuActions = []

  if (allowedViews.includes(NewsPageViews.PIN_BOARDS) && capabilities.get(Capabilities.HAS_CUSTOM_TAGS)) {
    menuActions.push(
      <MenuItem
        key="pin"
        onClick={() => onCustomTagClick()}
      >
        {i18n.get('pin')}
      </MenuItem>,
      <MenuItem
        key="unpin"
        onClick={() => onRemoveCustomTagClick()}
      >
        {i18n.get('unpin')}
      </MenuItem>
    )
  }

  if (viewConfigCaps.get('editing').some(c => capabilities.get(c))) {
    if (menuActions.length > 0) {
      menuActions.push(
        <Divider key="divider" />
      )
    }

    menuActions.push(
      <MenuItem
        key="new_news"
        onClick={() => onNewNewsClick()}
      >
        {i18n.get('new_news')}
      </MenuItem>
    )
  }

  return (
    <div className={classes.actions}>
      <Button
        startIcon={<ClearAllIcon />}
        color="inherit"
        onClick={() => onReset({
          navigateBack: true,
          resetCustomTag: true
        })}
      >
        {i18n.get('empty_shopping_cart')}
      </Button>

      <AiAnalysis
        startIcon={<AutoAwesomeIcon />}
        color="inherit"
        onClick={() => onAiAnalysisClick()}
      >
        {i18n.get('ai_analysis')}
      </AiAnalysis>

      <div>
        <ExportButton icon={null} />
      </div>

      {menuActions.length > 0 && (
        <Menu
          handle={
            (
              <IconButton
                color="inherit"
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {menuActions}
        </Menu>
      )}
    </div>
  )
}

Actions.propTypes = {
  customTagMode: PropTypes.bool.isRequired,
  allowedViews: PropTypes.instanceOf(List).isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  viewConfigCaps: PropTypes.instanceOf(Map).isRequired,

  onCustomTagClick: PropTypes.func.isRequired,
  onRemoveCustomTagClick: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onNewNewsClick: PropTypes.func.isRequired,
  onAiAnalysisClick: PropTypes.func.isRequired
}
