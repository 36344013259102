/* global window */

import screenfull from 'screenfull'

export const toggleFullscreen = () => {
  if (screenfull.isEnabled) {
    if (screenfull.isFullscreen) {
      screenfull.exit()
    } else {
      try {
        screenfull.request()
      } catch (_e) {
        console.warn('Couldn\'t start fullscreen') // eslint-disable-line
      }
    }
  }
}

export const startFullscreen = () => {
  if (screenfull.isEnabled) {
    if (!screenfull.isFullscreen) {
      try {
        screenfull.request()
      } catch (_e) {
        console.warn('Couldn\'t start fullscreen') // eslint-disable-line
      }
    }
  }
}

export const stopFullscreen = () => {
  if (screenfull.isEnabled) {
    if (screenfull.isFullscreen) {
      screenfull.exit()
    }
  }
}

export const isFullscreen = () => (
  window.fullScreen || (window.innerWidth === window.screen.width && window.innerHeight === window.screen.height)
)

export const redirect = url => {
  window.location.href = url
}

export const reload = () => {
  window.location.reload()
}
