import { connect } from 'react-redux'

import AnewstipApiKey from 'components/administration/external_accounts/journalist_search'

import {
  getApiKeyRequestRunning,
  getAnewstipApiKey
} from 'selectors'

import {
  submitApiKeyStart as onSubmit,
  deleteApiKeyStart as onDelete
} from 'actions/api_keys'

const mapStateToProps = state => ({
  loading: getApiKeyRequestRunning(state),
  anewstipApiKey: getAnewstipApiKey(state)
})

export default connect(
  mapStateToProps,
  {
    onSubmit,
    onDelete
  }
)(AnewstipApiKey)
