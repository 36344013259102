import { connect } from 'react-redux'
import { List } from 'immutable'

import ActiveFilterList from 'components/active_filter_list'
import {
  getCDeskActiveFilters, getCDeskActiveFiltersCollapsed
} from 'selectors'
import { toggleActiveFilters } from 'actions/content_desk'

const mapStateToProps = state => ({
  activeFilters: getCDeskActiveFilters(state),
  activeBooleanFilters: List(),
  filterCount: getCDeskActiveFilters(state).size,
  collapsed: getCDeskActiveFiltersCollapsed(state),
  activeFilterType: 'contentDesk'
})

export default connect(
  mapStateToProps,
  {
    onCollapseClick: toggleActiveFilters
  }
)(ActiveFilterList)
