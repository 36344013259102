import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'
import { useTheme } from '@mui/material/styles'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Box
} from '@mui/material'
import { DownloadOutlined } from '@mui/icons-material'

import LockedContentTooltip from 'components/themax/hidden_content/LockedContentTooltip'
import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  exportButton: {
    minWidth: 120
  }
})

export default function ExcelDownloadButton({
  excelExportRequestRunning,
  onClick,
  hasPaidVersion,
  plansCount,
  view
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [warnDialogOpen, setWarnDialogOpen] = useState(false)

  if (view !== 'editorial_plans') {
    return null
  }

  const handleDialogConfirm = () => {
    onClick()
    setWarnDialogOpen(false)
  }

  const renderWarnDialog = () => (
    <Dialog
      open={warnDialogOpen}
      onClose={() => setWarnDialogOpen(false)}
    >
      <DialogTitle>{i18n.get('export_excel')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {i18n.get('themax_export_excel_warning', { n: plansCount })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          onClick={() => setWarnDialogOpen(false)}
          color="secondary"
        >
          {i18n.get('cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={() => handleDialogConfirm()}
          color="primary"
          autoFocus
        >
          {i18n.get('next')}
        </Button>
      </DialogActions>
    </Dialog>
  )

  const renderDownloadButton = () => {
    let comp = (
      <>
        <Button
          color="primary"
          variant="contained"
          onClick={plansCount > 500 ? () => setWarnDialogOpen(true) : onClick}
          size="small"
          className={classes.exportButton}
          disabled={excelExportRequestRunning || !hasPaidVersion}
        >
          {excelExportRequestRunning ? (
            <CircularProgress
              size={20}
              color="inherit"
            />
          ) : i18n.get('export_excel')}
        </Button>
        {!hasPaidVersion && <LockedContentTooltip />}
      </>
    )

    if (isMobile) {
      comp = (
        <>
          <IconButton
            disabled={excelExportRequestRunning || !hasPaidVersion}
            onClick={plansCount > 500 ? () => setWarnDialogOpen(true) : onClick}
          >
            <DownloadOutlined />
          </IconButton>
          {!hasPaidVersion && <LockedContentTooltip />}
        </>
      )
    }

    return (
      <Box className={classes.buttonWrapper}>
        {comp}
      </Box>
    )
  }

  return (
    <div>
      {renderDownloadButton()}
      {renderWarnDialog()}
    </div>
  )
}

ExcelDownloadButton.propTypes = {
  excelExportRequestRunning: PropTypes.bool.isRequired,
  hasPaidVersion: PropTypes.bool.isRequired,
  plansCount: PropTypes.number.isRequired,
  view: PropTypes.string.isRequired,

  onClick: PropTypes.func.isRequired
}
