/* global window, document */
export const scrollToTop = () => window.scrollTo(0, 0)

export const scrollIntoView = (id, behavior = 'smooth', block = 'start') => {
  const el = document.getElementById(id)

  if (el) {
    el.scrollIntoView({ behavior, block })
  }
}

const easeInOutQuad = (t, b, c, d) => {
  let newT = t / (d / 2)

  if (newT < 1) {
    return (c / 2) * newT * newT + b // eslint-disable-line
  }

  newT -= 1
  return -c / 2 * (newT * (newT - 2) - 1) + b // eslint-disable-line
}

export const scrollTo = (element, to, duration, direction = 'y') => {
  const newElement = element
  const start = direction === 'y' ? newElement.scrollTop : newElement.scrollLeft
  const change = to - start
  let currentTime = 0
  const increment = 20

  const animateScroll = () => {
    currentTime += increment
    const val = easeInOutQuad(currentTime, start, change, duration)

    if (direction === 'y') {
      newElement.scrollTop = val
    } else {
      newElement.scrollLeft = val
    }

    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

export const scrollElementToTop = elementId => {
  const element = document.getElementById(elementId)

  if (element) {
    element.scrollTop = 0
  }
}

export const scrollElementToLeft = elementId => {
  const element = document.getElementById(elementId)
  element.scrollLeft = 0
}

export const scrollElement = (elementId, offset) => {
  const element = document.getElementById(elementId)

  if (element) {
    element.scrollTop += offset

    return [element.scrollTop, element.scrollHeight, element.offsetHeight]
  }

  return []
}

export const scrollElementX = (elementId, offset) => {
  const element = document.getElementById(elementId)

  if (element) {
    element.scrollLeft += offset

    return [element.scrollLeft, element.scrollWidth, element.offsetWidth]
  }

  return []
}

export const scrollInsideContainer = (containerId, elementId, offset = 0) => {
  const container = document.getElementById(containerId)
  const element = document.getElementById(elementId)
  const topPos = element.offsetTop + offset
  scrollTo(container, topPos, 200)
}

export const scrollToTopInsideContainer = containerId => {
  const container = document.getElementById(containerId)
  scrollTo(container, 0, 200)
}
