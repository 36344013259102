import { connect } from 'react-redux'

import Sorting from 'components/themax/sorting'
import { getThemaxPublicationsSortBy, getThemaxPublicationsSortOrder } from 'selectors'
import { updateThemaxSort } from 'actions/themax'

const mapStateToProps = state => ({
  sortBy: getThemaxPublicationsSortBy(state),
  sortOrder: getThemaxPublicationsSortOrder(state),
  sortType: 'publicationsSorting'
})

export default connect(
  mapStateToProps,
  {
    onSortChange: updateThemaxSort
  }
)(Sorting)
