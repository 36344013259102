import { connect } from 'react-redux'

import TemplatesPickerDialog from 'components/email_templates_library/templates_picker_dialog'

import {
  saveContentStart
} from 'actions/content_desk'

import {
  closePickerDialog,
  setReadMode as setTemplatesReadModeOnly
} from 'actions/email_templates_library'

import {
  getETLibraryShowPickerDialog
} from 'selectors'

const mapStateToProps = (state, { loadTemplate }) => ({
  loadTemplate,
  isOpen: getETLibraryShowPickerDialog(state)
})

export default connect(
  mapStateToProps,
  {
    closePickerDialog,
    setTemplatesReadModeOnly,
    saveCcdContent: saveContentStart
  }
)(TemplatesPickerDialog)
