import { handleActions } from 'redux-actions'
import { Set } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = Set([])

export default handleActions({
  [NewsActions.deleteNewsSuccess]: (state, { payload: newsId }) => state.concat(newsId),
  [AppActions.resetState]: () => initialState
}, initialState)
