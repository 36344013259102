/* global document */
import { useEffect, useState } from 'react'
import { fromJS } from 'immutable'

const updateBodyBackgroundColor = backgroundColor => {
  document.body.style.backgroundColor = backgroundColor
}

const updateBodyBackgroundImage = backgroundImageUrl => {
  document.body.style.background = `url(${backgroundImageUrl}) no-repeat center center fixed`
  document.body.style.backgroundSize = 'cover'
}

const resetBodyBackgroundImage = () => {
  document.body.style.removeProperty('background')
  document.body.style.removeProperty('background-size')
}

export default function useDashboard(config) {
  const [defaultBackgroundColor] = useState(document.body.style.backgroundColor)

  const backgroundColor = (config || fromJS({})).getIn(['colors', 'background'])
  const backgroundImageUrl = (config || fromJS({})).get('backgroundImageUrl')

  useEffect(() => {
    if (backgroundColor) {
      updateBodyBackgroundColor(backgroundColor)
    }

    if (backgroundImageUrl) {
      updateBodyBackgroundImage(backgroundImageUrl)
    }

    return () => {
      updateBodyBackgroundColor(defaultBackgroundColor)
      resetBodyBackgroundImage()
    }
  }, [backgroundColor, backgroundImageUrl])
}
