import React from 'react'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import { Breadcrumbs, CircularProgress, Tab, Typography, Divider, Chip, Stack } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'

import CheckIcon from '@mui/icons-material/Check'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { StaggeredListItem } from 'components/staggered_list'

import Journalist from 'containers/anewstip/results/Journalist'
import Outlet from 'containers/anewstip/results/Outlet'
import Article from 'containers/anewstip/results/Article'
import PrArticle from 'components/anewstip/results/pr_article'
import Pagination from 'containers/anewstip/results/Pagination'
import { Dialog } from 'containers/themed'

const useStyles = makeStyles()({
  content: {
    minHeight: 700,
    maxHeight: 700
  },
  tabs: {
    borderBottom: '1px solid gray'
  },
  resultsInnerContainer: {
    width: '100%',
    paddingBottom: '20px'
  },
  resultWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '20px'
  },
  circularProgress: {
    height: '52vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  topicChips: {
    padding: 2,
    marginBottom: '15px'
  },
  drillDownHistoryFilter: {
    cursor: 'pointer'
  },
  drillDownNavigation: {
    marginBottom: '10px'
  }
})

const DrillDownDialog = ({
  data,
  open,
  activeTab,
  drillDownEntity,
  journalistsDrillDownRequestRunning,
  outletsDrillDownRequestRunning,
  articlesDrillDownRequestRunning,
  topics,
  importResult,
  importRequestRunning,
  drillDownHistory,
  isLimitedFunctionality,
  isDistListsLocked,
  onClose,
  switchTab,
  toggleTopic,
  onDrillDownJournalists,
  onDrillDownOutlets,
  onAddClick,
  onAddToDistributionListClick,
  onDrillDownHistory
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const entities = data.getIn([activeTab, 'entities'])
  const hideBtn = ent => activeTab === 'outlets' || (activeTab === 'articles' && ent.get('contactType') === 'outlet')
  const onlyOneTopicActive = topics.filter(topic => topic.get('active') === true).size === 1

  const currentDrillDownIndex = drillDownHistory.get('currentIndex')
  const drillDownHistoryFilters = drillDownHistory.get('filtersHistory')

  const handleClose = () => onClose()

  const handleTabChange = (_event, entity) => {
    switchTab(entity)
  }

  const handleToggleTopic = name => {
    toggleTopic({ name })

    if (activeTab === 'journalists') {
      onDrillDownJournalists(drillDownEntity)
    }

    if (activeTab === 'outlets') {
      onDrillDownOutlets()
    }
  }

  const handleDrillDownHistory = index => {
    onDrillDownHistory({ index, entity: drillDownEntity })
  }

  const renderJournalistsTab = () => (
    <Tab
      disabled={isLimitedFunctionality}
      label={`${i18n.get(drillDownEntity === 'journalist' ? 'similar' : 'related')} ${i18n.get('journalists')}`}
      value="journalists"
    />
  )

  const renderOutletsTab = () => (
    <Tab
      label={`${i18n.get('similar')} ${i18n.get('outlets')}`}
      value="outlets"
    />
  )

  const renderArticlesTab = () => (
    <Tab
      label={`${i18n.get('related')} ${i18n.get('articles')}`}
      value="articles"
    />
  )

  const renderDrillDownHistory = () => {
    if (drillDownHistoryFilters.size < 2) {
      return null
    }

    return (
      <div className={classes.drillDownNavigation}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          {drillDownHistoryFilters.map(filtersHistory => (
            <Typography
              className={classes.drillDownHistoryFilter}
              key={filtersHistory.get('index')}
              color={currentDrillDownIndex === filtersHistory.get('index') && 'black'}
              onClick={() => handleDrillDownHistory(filtersHistory.get('index'))}
            >
              {filtersHistory.getIn(['filters', 'name'])}
            </Typography>
          ))}
        </Breadcrumbs>
      </div>
    )
  }

  const renderTopicChips = () => (
    <div className={classes.topicChips}>
      <Stack
        direction="row"
        spacing={0.5}
        flexWrap="wrap"
        rowGap="5px"
      >
        {topics.map((topic, index) => (
          topic.get('active')
            ? (
              <Chip
                key={index}
                label={topic.get('name')}
                icon={<CheckIcon />}
                color="primary"
                size="small"
                onClick={() => handleToggleTopic(topic.get('name'))}
                disabled={onlyOneTopicActive}
              />
            ) : (
              <Chip
                key={index}
                label={topic.get('name')}
                onClick={() => handleToggleTopic(topic.get('name'))}
                size="small"
                variant="outlined"
              />
            )
        ))}
      </Stack>
    </div>
  )

  const renderResults = () => (
    (activeTab === 'journalists' && journalistsDrillDownRequestRunning)
      || (activeTab === 'outlets' && outletsDrillDownRequestRunning)
      || (activeTab === 'articles' && articlesDrillDownRequestRunning) ? (
        <div className={classes.circularProgress}>
          <CircularProgress
            size={60}
          />
        </div>
      ) : (
        <>
          {(
            (drillDownEntity === 'journalist' && activeTab === 'journalists')
          || (drillDownEntity === 'outlet' && activeTab === 'outlets')
          )
          && renderDrillDownHistory()}
          {
          (
            (drillDownEntity === 'journalist' && activeTab === 'journalists')
            || (drillDownEntity === 'outlet' && activeTab === 'outlets')
          )
          && renderTopicChips()
        }
          {
          entities.map((entity, index) => (
            <div
              className={classes.resultsInnerContainer}
              key={index}
            >
              <StaggeredListItem delay={index * 50}>
                <Divider />
                <div className={classes.resultWrapper} />
                {activeTab === 'journalists' && (
                  <Journalist
                    journalist={entity}
                    importResult={importResult}
                    requestRunning={importRequestRunning}
                    isDistListsLocked={isDistListsLocked}
                    onAddClick={onAddClick}
                    onAddToDistributionListClick={onAddToDistributionListClick}
                    drillDownEntity={drillDownEntity}
                    isLimitedFunctionality={isLimitedFunctionality}
                    drillDown
                  />
                )}
                {(activeTab === 'outlets' && drillDownEntity === 'outlet') && (
                  <Outlet
                    outlet={entity}
                    drillDownEntity
                  />
                )}
                {(activeTab === 'articles' && drillDownEntity === 'journalist') && (
                  (entity.get('authors') ? (
                    <PrArticle article={entity} />
                  ) : (
                    <Article
                      article={entity}
                      hideButton={hideBtn(entity)}
                      drillDown
                      isDistListsLocked={isDistListsLocked}
                      onAddToDistributionListClick={onAddToDistributionListClick}
                    />
                  ))
                )}
              </StaggeredListItem>
            </div>
          ))
        }
          <Pagination
            drillDown
            resultsTab={activeTab}
            results={data.get(activeTab)}
          />
        </>
      ))

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      onClose={handleClose}
      fullWidth
      title={' '}
      dialogContentClasses={{ root: classes.content }}
      disableBackClick
    >
      <TabContext value={activeTab}>
        <div className={classes.tabs}>
          <TabList
            centered
            onChange={handleTabChange}
            variant="fullWidth"
          >
            {drillDownEntity === 'outlet' && renderOutletsTab()}
            {renderJournalistsTab()}
            {drillDownEntity === 'journalist' && renderArticlesTab()}
          </TabList>
        </div>
        <TabPanel
          value={activeTab}
        >
          {renderResults()}
        </TabPanel>
      </TabContext>
    </Dialog>
  )
}

DrillDownDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  drillDownEntity: PropTypes.string,
  journalistsDrillDownRequestRunning: PropTypes.bool.isRequired,
  outletsDrillDownRequestRunning: PropTypes.bool.isRequired,
  articlesDrillDownRequestRunning: PropTypes.bool.isRequired,
  topics: PropTypes.instanceOf(List).isRequired,
  importResult: PropTypes.instanceOf(List).isRequired,
  importRequestRunning: PropTypes.bool.isRequired,
  drillDownHistory: PropTypes.instanceOf(Map).isRequired,
  isLimitedFunctionality: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  switchTab: PropTypes.func.isRequired,
  toggleTopic: PropTypes.func.isRequired,
  onDrillDownJournalists: PropTypes.func.isRequired,
  onDrillDownOutlets: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onAddToDistributionListClick: PropTypes.func.isRequired,
  onDrillDownHistory: PropTypes.func.isRequired
}

export default DrillDownDialog
