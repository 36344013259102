import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography
} from '@mui/material'

import { green, amber, blue } from '@mui/material/colors'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import WarningIcon from '@mui/icons-material/Warning'
import InfoIcon from '@mui/icons-material/Info'

import { Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px'
  },
  failedContactsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    marginTop: '1.5em'
  },
  excelButton: {
    width: '100px',
    marginLeft: 50
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    color: !theme.isDark && '#404040'
  },
  successRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  failRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px'
  },
  contact: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-0.5em',
    marginBottom: '-0.5em'
  },
  checkIcon: {
    color: green[400]
  },
  warningIcon: {
    color: amber[400]
  },
  infoIcon: {
    color: blue[400]

  },
  divider: {
    margin: '1.5em -1.5em 1.5em -1.5em'
  }
}))

const UploadResultDialog = ({
  open,
  data,
  requestRunning,
  createDistributionList,
  addToExistingDistributionList,
  onClose,
  onExcelDownload
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const totalUploaded = data.get('total_uploaded')
  const totalFailed = data.get('total_failed')

  return (
    <Dialog
      open={open}
      fullWidth
    >
      <DialogTitle className={classes.title}>
        {i18n.get('import_completed')}
        {totalFailed > 0 && (
          <Button
            className={classes.excelButton}
            variant="contained"
            loading={requestRunning}
            color="primary"
            onClick={() => onExcelDownload({ contacts: data.get('failed'), sheetName: 'Failed Contacts' })}
          >
            {i18n.get('excel')}
          </Button>
        )}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {totalUploaded > 0 && (
          <>
            <div className={classes.successRow}>
              <CheckCircleIcon className={classes.checkIcon} />
              <Typography variant="subtitle1">
                {`${totalUploaded} ${totalUploaded === 1
                  ? i18n.get('contact')
                  : i18n.get('contacts')
                } ${i18n.get('successfully_uploaded')}`}
              </Typography>
            </div>
          </>
        )}
        {totalFailed > 0 && (
          <>
            {totalUploaded > 0 && <Divider className={classes.divider} />}
            <div className={classes.failRow}>
              <WarningIcon className={classes.warningIcon} />
              <Typography variant="subtitle1">
                {`${totalFailed} ${totalFailed === 1
                  ? `${i18n.get('contact')} ${i18n.get('has_not_been_imported')}`
                  : `${i18n.get('contacts')} ${i18n.get('have_not_been_imported')}`
                }, ${i18n.get('see_information_below').toLowerCase()}.`}
              </Typography>
            </div>
            {(createDistributionList || addToExistingDistributionList)
              && (
                <div className={classes.infoRow}>
                  <InfoIcon className={classes.infoIcon} />
                  <Typography variant="subtitle1">
                    {`${i18n.get('ccd_contacts_import_already_existing_info')}.`}
                  </Typography>
                </div>
              )}
            <div className={classes.failedContactsContainer}>
              {data.get('failed').map((c, index) => (
                <div
                  className={classes.contact}
                  key={index}
                >
                  {`${c.get('first_name')} ${c.get('last_name')} (${c.get(
                    'mail'
                  )}) `}
                  <Typography
                    variant="subtitle2"
                  >
                    {c.get('error')}
                  </Typography>
                </div>
              ))}
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '20px 24px' }}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          {i18n.get('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadResultDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  createDistributionList: PropTypes.bool.isRequired,
  addToExistingDistributionList: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onExcelDownload: PropTypes.func.isRequired
}

export default UploadResultDialog
