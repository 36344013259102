/* global window */
import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { ChartGridRowHeight } from 'static/constants'
import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'
import * as EnvironmentActions from 'actions/environment'

const dashboardMargin = 40
const gridPadding = 10

const calculateRowHeight = state => {
  const height = window.screen.height || 1030
  let rowHeight = ChartGridRowHeight

  const innerHeight = height - (dashboardMargin * 2) + gridPadding
  const maxRowCount = Math.floor(innerHeight / rowHeight)
  rowHeight = innerHeight / maxRowCount
  const gridHeight = innerHeight + 2

  if (state.get('autoRefresh') && height) {
    return { rowHeight, gridHeight, maxRowCount }
  }

  return { rowHeight: ChartGridRowHeight, gridHeight: undefined, maxRowCount }
}

export const initialState = fromJS({
  editing: false,
  saving: false,
  deleting: false,
  executing: false,
  showChartDialog: false,
  showEditDialog: false,
  showMultiChartsDialog: false,
  showDeleteDialog: false,
  chartLimitReached: false,
  powerpointExporting: false,
  imageDownloading: false,
  pdfDownloading: false,
  autoRefresh: false,
  gridHeight: undefined,
  rowHeight: ChartGridRowHeight,
  maxRowCount: 0
})

export default handleActions({
  [Actions.toggleEditing]: state => state.set('editing', !state.get('editing')),

  [Actions.hideChartDialog]: state => state.set('showChartDialog', false),
  [Actions.showChartDialog]: state => state.set('showChartDialog', true),

  [Actions.hideMultiChartsDialog]: state => state.set('showMultiChartsDialog', false),
  [Actions.showMultiChartsDialog]: state => state.set('showMultiChartsDialog', true),

  [Actions.hideEditDialog]: state => state.set('showEditDialog', false),
  [Actions.showEditDialog]: state => state.set('showEditDialog', true),

  [Actions.hideDeleteDialog]: state => state.set('showDeleteDialog', false),
  [Actions.showDeleteDialog]: state => state.set('showDeleteDialog', true),

  [Actions.fetchDashboard]: state => state.set('editing', false),

  [Actions.saveDashboardStart]: state => state.set('saving', true),
  [Actions.saveDashboardError]: state => state.set('saving', false),
  [Actions.saveDashboardSuccess]: state => state.set('saving', false),

  [Actions.exportDashboardAsPowerpointStart]: state => state.set('powerpointExporting', true),
  [Actions.exportDashboardAsPowerpointError]: state => state.set('powerpointExporting', false),
  [Actions.exportDashboardAsPowerpointSuccess]: state => state.set('powerpointExporting', false),

  [Actions.downloadImageStart]: state => state.set('imageDownloading', true),
  [Actions.downloadImageError]: state => state.set('imageDownloading', false),
  [Actions.downloadImageSuccess]: state => state.set('imageDownloading', false),

  [Actions.downloadPdfStart]: state => state.set('pdfDownloading', true),
  [Actions.downloadPdfError]: state => state.set('pdfDownloading', false),
  [Actions.downloadPdfSuccess]: state => state.set('pdfDownloading', false),

  [Actions.deleteDashboardStart]: state => state.set('deleting', true),
  [Actions.deleteDashboardError]: state => state.set('deleting', false),
  [Actions.deleteDashboardSuccess]: state => state.set('deleting', false),

  [Actions.executeSavedSearch]: state => state.set('executing', true),
  [Actions.executedSavedSearch]: state => state.set('executing', false),

  [Actions.setChartLimitReached]: (state, { payload }) => state.set('chartLimitReached', payload),

  [Actions.toggleAutoRefresh]: state => {
    const newState = state
      .set('rowHeight', ChartGridRowHeight)
      .update('autoRefresh', v => !v)

    return newState.merge(calculateRowHeight(newState))
  },

  [EnvironmentActions.changeWidthAndHeight]: state => state.merge(calculateRowHeight(state)),

  [AppActions.resetState]: () => initialState
}, initialState)
