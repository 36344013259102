import React from 'react'

import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/material'

import Tabs from 'containers/content_desk_new/content_header/Tabs'
import KpiDateFilter from 'containers/content_desk_new/content_header/KpiDateFilter'

const useStyles = makeStyles()(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      gap: '20px'
    }
  }
}))

export default function ContentHeader() {
  const { classes } = useStyles()

  return (
    <Box className={classes.header}>
      <Tabs />
      <KpiDateFilter />
    </Box>
  )
}
