import { fromJS } from 'immutable'
import decamelize from 'decamelize'
import { ChartDataSources, ChartTypes } from './constants'

const facebookMetrics = [
  'pageViewsTotal',
  'pageFans',
  'pagePostsImpressionsOrganicUnique',
  'pagePostsImpressionsPaidUnique',
  'pagePostEngagements'
]

const instagramMetrics = [
  'impressions',
  'reach',
  'profileViews'
]

const buildMetrics = (metrics, instagram) => fromJS(metrics.map(metric => ({
  value: `${decamelize(metric)}_total`,
  instagram,
  chart: {
    firstLevel: metric,
    secondLevel: null,
    thirdLevel: null,
    dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
    type: ChartTypes.RECTANGLE
  }
})))

const kpis = buildMetrics(facebookMetrics, false).concat(buildMetrics(instagramMetrics, true))

const config = fromJS([
  {
    value: 'page_views',
    instagram: false,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'pageViewsTotal',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'page_fans',
    instagram: false,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'pageFans',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'page_posts_impressions',
    instagram: false,
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'impressionTypes',
      thirdLevel: 'pagePostsImpressions',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'page_post_engagements',
    instagram: false,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'pagePostEngagements',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'page_video_views_by_paid_non_paid',
    instagram: false,
    chart: {
      firstLevel: 'timeline',
      secondLevel: 'videoViewTypes',
      thirdLevel: 'pageVideoViewsByPaidNonPaid',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'social_media_analytics_feed',
    instagram: false,
    chart: {
      firstLevel: 'socialMediaAnalyticsFeed',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED
    }
  },
  {
    value: 'social_media_analytics_feed',
    instagram: true,
    chart: {
      firstLevel: 'socialMediaAnalyticsFeed',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED
    }
  },
  {
    value: 'social_media_analytics_stories_feed',
    instagram: true,
    chart: {
      firstLevel: 'socialMediaAnalyticsStoriesFeed',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.SOCIAL_MEDIA_ANALYTICS_STORIES_FEED
    }
  },
  {
    value: 'impressions',
    instagram: true,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'impressions',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'reach',
    instagram: true,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'reach',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'profile_views',
    instagram: true,
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'profileViews',
      dataSource: ChartDataSources.FACEBOOK_ANALYTICS,
      type: ChartTypes.AREA
    }
  }
]).concat(kpis)

export default config

export const findFirstChart = (instagram = false) => {
  const found = config.find(c => c.get('instagram') === instagram)

  if (found) {
    return found.get('value')
  }

  return null
}

export const findValueByChart = chart => {
  const found = config.find(c => fromJS({
    firstLevel: chart.get('firstLevel'),
    secondLevel: chart.get('secondLevel'),
    thirdLevel: chart.get('thirdLevel'),
    dataSource: chart.get('dataSource'),
    type: chart.get('type')
  }).equals(c.get('chart')))

  if (found) {
    return found.get('value')
  }

  return null
}
