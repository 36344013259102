import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  }
}))

const TemplateConfirmDialog = ({
  open,
  editorDesign,
  closeConfirmDialog,
  onChange,
  onNavigationChange,
  forceLoadDesign
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleCloseDialog = () => closeConfirmDialog()

  const handleChangeTemplate = () => {
    onChange({ editorDesign })
    handleCloseDialog()
    forceLoadDesign()
    onNavigationChange('editor')
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('template_change_dialog_title')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseDialog}
        >
          {i18n.get('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleChangeTemplate}
        >
          {i18n.get('overwrite')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TemplateConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  editorDesign: PropTypes.instanceOf(Map),

  closeConfirmDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  forceLoadDesign: PropTypes.func.isRequired
}

export default TemplateConfirmDialog
