import React, { Fragment, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { createLoadable } from 'utils/loadable'
import { Icon, Tabs as MuiTabs, Tab, Paper } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

import Actions from 'components/saved_searches/actions'

import AppBar from 'containers/AppBar'
import RestrictionHint from 'containers/RestrictionHint'
import { ViewConfigCapSafe } from 'containers/CapSafe'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

const Tabs = ViewConfigCapSafe(Fragment, 'savedSearchesFeeds')

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: 30
  },
  timeline: {
    height: 300,
    marginBottom: theme.spacing(2)
  },
  customTagsViewIcon: {
    fontSize: '30px !important',
    cursor: 'pointer'
  },
  customTagsView: {
    [theme.breakpoints.up('sm')]: {
      marginRight: 10,
      paddingRight: 10
    }
  },
  tabs: {
    marginBottom: theme.spacing(2)
  }
}))

const ContentContainer = createLoadable(() => (
  import(/* webpackChunkName: "SavedSearchesContentContainer" */ 'components/content_container')
))

const EditDialog = ({ loadThrough }) => {
  const Comp = useMemo(() => createLoadable(() => (
    import(/* webpackChunkName: "SavedSearchesEditDialog" */ `containers/${loadThrough}/EditDialog`)
  )), [loadThrough])

  return <Comp />
}

EditDialog.propTypes = {
  loadThrough: PropTypes.string.isRequired
}

const RemoveDialog = ({ loadThrough }) => {
  const Comp = useMemo(() => createLoadable(() => (
    import(/* webpackChunkName: "SavedSearchesRemoveDialog" */ `containers/${loadThrough}/RemoveDialog`)
  )), [loadThrough])

  return <Comp />
}

RemoveDialog.propTypes = {
  loadThrough: PropTypes.string.isRequired
}

const AffectedDashboardsDialog = createLoadable(() => (
  import(/* webpackChunkName: "SavedSearchesAffectedDashboardsDialog" */ 'containers/saved_searches/AffectedDashboardsDialog')
))

const Timeline = createLoadable(() => (
  import(/* webpackChunkName: "SavedSearchesTimeline" */ 'containers/saved_searches/Timeline')
))

const CardView = ({ loadThrough }) => {
  const Comp = useMemo(() => createLoadable(() => (
    import(/* webpackChunkName: "SavedSearchesCardView" */ `containers/${loadThrough}/card_view/CardView`)
  )), [loadThrough])

  return <Comp />
}

CardView.propTypes = {
  loadThrough: PropTypes.string.isRequired
}

const ListView = ({ loadThrough }) => {
  const Comp = useMemo(() => createLoadable(() => (
    import(/* webpackChunkName: "SavedSearchesListView" */ `containers/${loadThrough}/list_view/ListView`)
  )), [loadThrough])

  return <Comp />
}

ListView.propTypes = {
  loadThrough: PropTypes.string.isRequired
}

const DispatchesDialog = createLoadable(() => (
  import(/* webpackChunkName: "SavedSearchesDispatchesDialog" */ 'containers/saved_searches/DispatchesDialog')
))

const Feeds = createLoadable(() => (
  import(/* webpackChunkName: "SavedSearchesFeeds" */ 'containers/saved_searches/feeds/Feeds')
))

export default function SavedSearches({
  loadThrough,
  hasSavedSearches,
  onMount,
  onTabChange,
  onViewChange,
  showRestrictionHint,
  tab,
  viewType,
  showFeed,
  showTimeline
}) {
  const { classes } = useStyles()
  const device = useDevice()
  const i18n = useI18n()

  useEffect(() => {
    onMount()
  }, [onMount])

  const renderViewSwitch = () => {
    if (device.get('xs')) {
      return null
    }

    let listIconColor = 'disabled'
    let columnIconColor = 'secondary'

    if (viewType === 'list') {
      listIconColor = 'secondary'
      columnIconColor = 'disabled'
    }

    return (
      <div className={classes.customTagsView}>
        <Icon
          className={classes.customTagsViewIcon}
          onClick={() => onViewChange('list')}
          color={listIconColor}
        >
          list
        </Icon>
        <Icon
          className={classes.customTagsViewIcon}
          onClick={() => onViewChange('card')}
          color={columnIconColor}
        >
          view_column
        </Icon>
      </div>
    )
  }

  const renderSavedSearches = () => (
    <>
      {showTimeline && (
        <div className={classes.timeline}>
          <Timeline />
        </div>
      )}

      {showRestrictionHint && <RestrictionHint />}
      {renderViewSwitch()}
      {viewType === 'list' && <ListView loadThrough={loadThrough} />}
      {viewType === 'card' && <CardView loadThrough={loadThrough} />}
    </>
  )

  return (
    <div>
      <AppBar
        title={i18n.get('saved_searches')}
        actions={<Actions />}
      />

      <ContentContainer>
        <Tabs fallBackComp={renderSavedSearches()}>
          {hasSavedSearches && showFeed && (
            <MuiTabs
              component={Paper}
              value={tab}
              onChange={(_event, tabValue) => onTabChange(tabValue)}
              variant="fullWidth"
              indicatorColor="primary"
              classes={{ root: classes.tabs }}
            >
              <Tab
                label={i18n.get('saved_searches')}
                value="saved_searches"
              />
              <Tab
                label={i18n.get('saved_search_feeds')}
                value="feeds"
              />
            </MuiTabs>
          )}

          {(!hasSavedSearches || tab === 'saved_searches') && renderSavedSearches()}
          {hasSavedSearches && tab === 'feeds' && <Feeds />}
        </Tabs>
      </ContentContainer>

      <EditDialog loadThrough={loadThrough} />
      <RemoveDialog loadThrough={loadThrough} />
      <AffectedDashboardsDialog />
      <DispatchesDialog />
    </div>
  )
}

SavedSearches.defaultProps = {
  loadThrough: 'saved_searches',
  showTimeline: true,
  showFeed: true
}

SavedSearches.propTypes = {
  loadThrough: PropTypes.string,
  showRestrictionHint: PropTypes.bool.isRequired,
  hasSavedSearches: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  showTimeline: PropTypes.bool,
  showFeed: PropTypes.bool,

  onMount: PropTypes.func.isRequired,
  onViewChange: PropTypes.func.isRequired,
  onTabChange: PropTypes.func.isRequired
}
