import { connect } from 'react-redux'

import CampaignsPagination from 'components/content_desk_new/contents_campaigns_show_all/contents_campaigns_pagination'
import {
  searchCampaignsStart,
  setLimit,
  setPage
} from 'actions/content_desk'
import {
  getCDeskCampaigns,
  getCDeskCampaignLimit,
  getCDeskCampaignPage
} from 'selectors'

const mapStateToProps = state => ({
  pageName: 'campaignPage',
  page: getCDeskCampaignPage(state),
  pageSize: getCDeskCampaignLimit(state),
  total: getCDeskCampaigns(state).get('total') || 0
})

export default connect(
  mapStateToProps,
  {
    search: searchCampaignsStart,
    setPage,
    setPageSize: setLimit
  }
)(CampaignsPagination)
