import { connect } from 'react-redux'

import RecipientsSelection from 'components/content_desk_new/contents/content_edit_dialog/recipients_selection'

import {
  getCDeskContentFormDataRecipients,
  getCDeskContactDropdownOptions,
  getCDeskContactDropdownOptionsLoading,
  getCDeskContentFormFilteredDistributionLists,
  getCDeskDistributionListDropdownOptionsLoading,
  getCDeskIsDistributionListsLocked,
  getCDeskContentFormSelectedDistributionLists,
  getCDeskDistributionListSearchQuery
} from 'selectors'

import {
  fetchContactDropdownOptionsStart,
  fetchDistributionListDropdownOptionsStart,
  setContentFormData,
  setDistributionListSearchQuery
} from 'actions/content_desk'

const mapStateToProps = state => ({
  recipients: getCDeskContentFormDataRecipients(state),
  distributionListSearchQuery: getCDeskDistributionListSearchQuery(state),
  selectedDistributionLists: getCDeskContentFormSelectedDistributionLists(state),
  contactDropdownOptions: getCDeskContactDropdownOptions(state),
  contactDropdownOptionsLoading: getCDeskContactDropdownOptionsLoading(state),
  distributionListDropdownOptions: getCDeskContentFormFilteredDistributionLists(state),
  distributionListDropdownOptionsLoading: getCDeskDistributionListDropdownOptionsLoading(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(
  mapStateToProps,
  {
    fetchContactDropdownOptionsStart,
    fetchDistributionListDropdownOptionsStart,
    setContentFormData,
    setDistributionListSearchQuery
  }
)(RecipientsSelection)
