import camelcase from 'camelcase'
import { getViewConfigModuleName } from './news/view_config'
import { getSavedSearchById } from './saved_searches'
import { getWhitelabelEmailAddress } from './whitelabel'

export const getConfig = state => state.config
export const getColors = state => getConfig(state).get('colors')
export const getThemes = state => getConfig(state).get('themes')
export const getGroupingSettingId = state => getConfig(state).get('grootGroupingSettingId')
export const getRandomThemes = state => getConfig(state).get('randomThemes')
export const getCurrency = state => getConfig(state).get('currency')
export const getConfigSenderMail = state => (
  getConfig(state).get('senderMail') || getWhitelabelEmailAddress(state) || 'customerservice@pressrelations.de'
)
export const getPdfCoverTitle = state => getConfig(state).get('pdfCoverTitle')
export const getExportLimit = state => getConfig(state).get('exportLimit')
export const getXlsxExportLimit = state => getConfig(state).get('xlsxExportLimit')
export const getBackwardsNewsHtmlLimit = state => getConfig(state).get('backwardsNewsHtmlLimit')
export const getBackwardsNewsXlsxLimit = state => getConfig(state).get('backwardsNewsXlsxLimit')
export const getSavedSearchFeedLimit = state => getConfig(state).get('savedSearchFeedLimit')
export const getConfigDefaultDateType = state => getConfig(state).get('defaultDateType')
export const getInitialRoute = state => getConfig(state).get('initialRoute')
export const getGoogleAnalyticsEnabled = state => getConfig(state).get('googleAnalyticsEnabled')
export const getYoutubeAnalyticsEnabled = state => getConfig(state).get('youtubeAnalyticsEnabled')
export const getFacebookAnalyticsEnabled = state => getConfig(state).get('facebookAnalyticsEnabled')
export const getFacebookAccountConnected = state => getConfig(state).get('facebookAccountConnected')
export const getTwitterAnalyticsEnabled = state => getConfig(state).get('twitterAnalyticsEnabled')
export const getIsSuper = state => getConfig(state).get('isSuper')
export const getLinkedInAnalyticsEnabled = state => getConfig(state).get('linkedInAnalyticsEnabled')
export const getLinkedInPermissions = state => getConfig(state).get('linkedInPermissions')
export const getLinkedInProfile = state => getConfig(state).get('linkedInProfile')
export const getApiKeyRequestRunning = state => getConfig(state).get('apiKeyRequestRunning')
export const getNewsPoolAllowedSavedSearchesCount = state => getConfig(state).getIn(['newsPoolConfig', 'savedSearchCount'])
export const getNewsPoolTimeLimit = state => getConfig(state).getIn(['newsPoolConfig', 'timeLimit'])
export const getNewsPoolTimeUnit = state => getConfig(state).getIn(['newsPoolConfig', 'timeUnit'])
export const getNewsPoolAllowedChannels = state => getConfig(state).getIn(['newsPoolConfig', 'channels'])
export const getNewsAllowedCodeIds = state => getConfig(state).getIn(['newsConfig', 'allowedCodeIds'])
export const getNewsAllowedChannelIds = state => getConfig(state).getIn(['newsConfig', 'allowedChannelIds'])
export const getNewsAllowedCountryIds = state => getConfig(state).getIn(['newsConfig', 'allowedCountryIds'])
export const getDashboardAllowedDashboardsCount = state => getConfig(state).getIn(['dashboardConfig', 'savedDashboardsCount'])
export const getDashboardAllowedChartsCount = state => getConfig(state).getIn(['dashboardConfig', 'savedChartsCount'])
export const getGoogleAnalyticsClientId = state => getConfig(state).get('googleAnalyticsClientId')
export const getFacebookAnalyticsClientId = state => getConfig(state).get('facebookAnalyticsClientId')
export const getLinkedInClientId = state => getConfig(state).get('linkedInClientId')
export const getDeeplApiKey = state => getConfig(state).get('deeplApiKey')
export const getConfigSeparateCoding = state => getConfig(state).get('separateCoding')
export const getEvaluationSteps = state => getConfig(state).get('evaluationSteps')
export const getStatementEvaluationSteps = state => getConfig(state).get('statementEvaluationSteps')
export const getStatementBasedCoding = state => getConfig(state).get('statementBasedCoding')
export const getFirstConfigLoaded = state => getConfig(state).get('firstConfigLoaded')
export const getStartedWithDeepLink = state => getConfig(state).get('startedWithDeepLink')
export const getStartedWithDeepLinkSet = state => getConfig(state).get('startedWithDeepLinkSet')
export const getDarkowlSearchQueries = state => getConfig(state).get('darkowlSearchQueries')
export const getDarkowlScoreQueries = state => getConfig(state).get('darkowlScoreQueries')
export const getDarkowlScoreQueriesById = state => getDarkowlScoreQueries(state).map(q => q.get('id'))
export const getAnewstipApiKey = state => getConfig(state).get('anewstipApiKey')
export const getPaidFeatures = state => getConfig(state).get('paidFeatures') || {}
export const getThemaxPaidFeatureStatus = state => getPaidFeatures(state).get('themax')
export const getHasThemaxPaidVersion = state => getThemaxPaidFeatureStatus(state) === 'completed'
export const getAiSummariesPaidFeatureStatus = state => getPaidFeatures(state).get('aiSummaries')
export const getHasAiSummariesPaidVersion = state => getAiSummariesPaidFeatureStatus(state) === 'completed'
export const getConfigOrderRequestRunning = state => getConfig(state).get('orderRequestRunning')
export const getHasAiPowerpointAnalysisTestVersion = () => false

export const getUserConfig = state => getConfig(state).get('userConfig')
export const getCDeskConfig = state => getConfig(state).get('contentDeskConfig')
export const getCDeskUpdateRequestRunning = state => getConfig(state).get('updateConfigRequestStarted')
export const getCDeskPlan = state => getCDeskConfig(state).get('ccdPlan')
export const getCDeskPlanUsage = state => getCDeskConfig(state).get('ccdPlanUsage')
export const getCDeskIsDistributionListsLocked = state => getCDeskConfig(state).get('distListsLocked')

export const getFavoritedSavedSearchId = state => {
  const moduleName = camelcase(getViewConfigModuleName(state))

  return getConfig(state).getIn(['userConfig', `${moduleName}SavedSearchId`])
}
export const getFavoritedSavedSearch = state => {
  const savedSearchId = getFavoritedSavedSearchId(state)

  return getSavedSearchById(state, savedSearchId)
}

export const getFavoritedDashboardId = state => getUserConfig(state).get('dashboardId')
