import { put, takeEvery, all, call, select } from 'redux-saga/effects'
import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import * as Api from 'api/contacts_management'

export function* importStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  let body

  try {
    if (payload && payload.id) {
      body = {
        ids: [payload.id]

      }
    } else {
      const selected = yield select(Selectors.getAnewstipSelectedData)
      body = {
        ids: selected.get('journalists')
      }
    }

    const result = yield call(Api.importAnewstipContacts, body)

    const withDistributionList = yield select(Selectors.getAnewstipImportWithDistributionLists)

    if (withDistributionList) {
      yield put(Actions.setImportContactsForDistributionLists(result))
    }

    if (result.some(el => el.existingContact)) {
      yield put(Actions.importSuccess(result))
      yield put(Actions.showDuplicateContactsDialog())
    } else {
      yield put(Actions.importSuccess(result))

      if (withDistributionList) {
        yield put(Actions.showDistributionListCreateDialog())
      }

      yield put(AppActions.showAppSnackbarMessage({
        text: i18n.get('contact_added'),
        variant: 'success'
      }))
    }
  } catch (error) {
    yield put(Actions.importError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* replaceContactStart({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const existingContact = payload.get('existingContact')
    const journalist = payload.get('journalist')
    const id = existingContact.get('id')
    const supplier = existingContact.get('supplier')

    const body = {
      id,
      supplier,
      contact: {
        first_name: journalist.get('firstName'),
        last_name: journalist.get('lastName'),
        mail: journalist.get('mail'),
        title: journalist.get('title'),
        job_title: journalist.get('jobTitle'),
        dossier: journalist.get('dossier'),
        picture: journalist.get('picture'),
        address: journalist.get('address'),
        phone: journalist.get('phone'),
        fax: journalist.get('fax'),
        website: journalist.get('website'),
        country_id: journalist.get('countryId'),
        state_id: journalist.get('stateId'),
        supplier: journalist.get('supplier'),
        external_id: journalist.get('externalId'),
        linkedin_url: journalist.get('linkedinUrl'),
        twitter_url: journalist.get('twitterUrl'),
        facebook_url: journalist.get('facebookUrl'),
        tiktok_url: journalist.get('tiktokUrl'),
        instagram_url: journalist.get('instagramUrl'),
        threads_url: journalist.get('threadsUrl'),
        youtube_url: journalist.get('youtubeUrl'),
        other_url: journalist.get('otherUrl'),
        contacts_languages: journalist.get('contactsLanguages'),
        contacts_media_types: journalist.get('contactsMediaTypes')
      },
      info: {
        outlet: journalist.get('outlet'),
        topic: journalist.get('topic')
      }
    }

    const replaceContactResult = yield call(Api.replaceContact, body)

    yield put(Actions.replaceContactSuccess({ existingContact, replaceContactResult }))
    yield put(Actions.importSuccess(replaceContactResult))

    const withDistributionList = yield select(Selectors.getAnewstipImportWithDistributionLists)

    if (withDistributionList) {
      const importResult = yield select(Selectors.getAnewstipImportResult)
      const duplicates = importResult.filter(el => el.get('existingContact'))

      if (duplicates.size < 2) {
        yield put(Actions.hideDuplicateContactsDialog())
        yield put(Actions.showDistributionListCreateDialog())
      }
    }

    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('contact_added'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.replaceContactError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: error.response.message,
      variant: 'error'
    }))
  }
}

export function* keepContact({ payload }) {
  const importResult = yield select(Selectors.getAnewstipImportResult)
  const duplicates = importResult.filter(el => el.get('existingContact'))

  const withDistributionList = yield select(Selectors.getAnewstipImportWithDistributionLists)

  if (withDistributionList && duplicates.size < 2) {
    yield put(Actions.hideDuplicateContactsDialog())
    yield put(Actions.showDistributionListCreateDialog())
  }

  yield put(Actions.removeContactFromDuplicateList(payload))
}

export function* watchImportStart() {
  yield takeEvery(Actions.importStart, importStart)
}

export function* watchReplaceContactStart() {
  yield takeEvery(Actions.replaceContactStart, replaceContactStart)
}

export function* watchAddJournalistToDistributionListStart() {
  yield takeEvery(Actions.addJournalistsToDistributionList, importStart)
}

export function* watchKeepContact() {
  yield takeEvery(Actions.keepContact, keepContact)
}

export default function* importSaga() {
  yield all([
    watchImportStart(),
    watchAddJournalistToDistributionListStart(),
    watchKeepContact(),
    watchReplaceContactStart()
  ])
}
