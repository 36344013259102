import { connect } from 'react-redux'

import ManagementSummary from 'components/media_reviews/media_review_detail/management_summary/ManagementSummary'

import {
  getMediaReviewDetailData
} from 'selectors'

const mapStateToProps = state => ({
  mediaReview: getMediaReviewDetailData(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(ManagementSummary)
