import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Fade } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Button } from 'containers/themed'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  content: {
    display: 'flex',
    alignItems: 'center'
  },
  defaultColor: {
    backgroundColor: theme.palette.background.default
  },
  textWrapper: {
    marginTop: 'auto',
    fontSize: 'inherit',
    lineHeight: '1.25',
    '& .clip': {
      color: theme.nrx.colors.highlighting
    }
  }
}))

const TransitionComponent = React.forwardRef((props, ref) => (
  <Fade
    ref={ref}
    {...props}
  />
))

TransitionComponent.displayName = 'Transition'

export default function FulltextDialog({ open, text, onClose }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={TransitionComponent}
      keepMounted
    >
      <DialogTitle>{i18n.get('text')}</DialogTitle>

      <DialogContent className={`${classes.content} ${classes.defaultColor}`}>
        <Typography
          className={classes.textWrapper}
          dangerouslySetInnerHTML={{
            __html: (text || '').replace(/\n/g, '<br />')
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
          color="inherit"
        >{i18n.get('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FulltextDialog.defaultProps = {
  open: false,
  text: ''
}

FulltextDialog.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,

  onClose: PropTypes.func.isRequired
}
