import { put, takeEvery, all, call, select, take } from 'redux-saga/effects'

import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as Actions from 'actions/administration'
import * as ConfigActions from 'actions/config'
import * as AppActions from 'actions/app'

import { getDefaultDomains } from 'utils/mailgun'

export function* createAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const name = yield select(Selectors.getAllowedDomainsNewAllowedDomain)
    const region = yield select(Selectors.getAllowedDomainsNewAllowedDomainRegion)

    const requestBody = {
      name,
      region
    }

    const result = yield call(Api.createAllowedDomain, requestBody)

    yield put(Actions.createAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())
    yield take(Actions.searchAllowedDomainsSuccess)

    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('ccd_allowed_domain_created'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.createAllowedDomainError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* createDefaultAllowedDomains() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const defaultDomains = getDefaultDomains()

    for (let i = 0; i < defaultDomains.size; i += 1) {
      const name = defaultDomains.get(i).get('domain')
      const region = defaultDomains.get(i).get('domainRegion')
      yield call(Api.createAllowedDomain, { name, region })
    }

    yield put(Actions.createDefaultAllowedDomainsSuccess())
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())
    yield take(Actions.searchAllowedDomainsSuccess)
  } catch (error) {
    yield put(Actions.createDefaultAllowedDomainsError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* updateAllowedDomain({ payload }) {
  const i18n = yield select(Selectors.getI18n)

  try {
    const { updateType } = payload
    const formData = yield select(Selectors.getAllowedDomainFormData)

    let requestBody
    let result

    if (updateType === 'openTracking') {
      requestBody = {
        name: formData.get('name'),
        region: formData.get('region'),
        active: !formData.get('openTracking')
      }

      result = yield call(Api.updateAllowedDomainOpenTracking, formData.get('id'), requestBody)
    }

    if (updateType === 'clickTracking') {
      requestBody = {
        name: formData.get('name'),
        region: formData.get('region'),
        active: !formData.get('clickTracking')
      }

      result = yield call(Api.updateAllowedDomainClickTracking, formData.get('id'), requestBody)
    }

    if (updateType === 'senderDomain') {
      requestBody = {
        sender_domains: formData.get('senderDomains')
      }

      result = yield call(Api.updateAllowedDomainSenderDomains, formData.get('id'), requestBody)
    }

    yield put(Actions.updateAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())
    yield take(Actions.searchAllowedDomainsSuccess)

    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('ccd_allowed_domain_updated'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.updateAllowedDomainError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* deleteAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getAllowedDomainFormData)

    const result = yield call(Api.deleteAllowedDomain, formData.get('id'))

    yield put(Actions.deleteAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())
    yield take(Actions.searchAllowedDomainsSuccess)

    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('ccd_allowed_domain_deleted'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.deleteAllowedDomainError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* refreshStatusAllowedDomain() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getAllowedDomainFormData)

    const requestBody = {
      id: formData.get('id'),
      name: formData.get('name'),
      region: formData.get('region')
    }

    const result = yield call(Api.refreshStatusAllowedDomain, requestBody)

    yield put(Actions.refreshStatusAllowedDomainSuccess(result))
    yield put(ConfigActions.fetchCDeskConfigRequestStart())
    yield put(Actions.searchAllowedDomainsStart())
    yield take(Actions.searchAllowedDomainsSuccess)

    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('ccd_allowed_domain_status_refreshed'),
      variant: 'success'
    }))
  } catch (error) {
    yield put(Actions.refreshStatusAllowedDomainError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* fetchAllowedDomainStatus() {
  const i18n = yield select(Selectors.getI18n)

  try {
    const formData = yield select(Selectors.getAllowedDomainFormData)

    const requestBody = {
      name: formData.get('name'),
      region: formData.get('region')
    }

    const result = yield call(Api.fetchAllowedDomainStatus, requestBody)

    yield put(Actions.fetchAllowedDomainStatusSuccess(result))
  } catch (error) {
    yield put(Actions.fetchAllowedDomainStatusError(error))
    yield put(AppActions.showAppSnackbarMessage({
      text: i18n.get('failed'),
      variant: 'error'
    }))
  }
}

export function* watchCreateAllowedDomainStart() {
  yield takeEvery(Actions.createAllowedDomainStart, createAllowedDomain)
}

export function* watchCreateDefaultAllowedDomainsStart() {
  yield takeEvery(Actions.createDefaultAllowedDomainsStart, createDefaultAllowedDomains)
}

export function* watchUpdateAllowedDomainStart() {
  yield takeEvery(Actions.updateAllowedDomainStart, updateAllowedDomain)
}

export function* watchDeleteAllowedDomainStart() {
  yield takeEvery(Actions.deleteAllowedDomainStart, deleteAllowedDomain)
}

export function* watchRefreshStatusAllowedDomainStart() {
  yield takeEvery(Actions.refreshStatusAllowedDomainStart, refreshStatusAllowedDomain)
}

export function* watchFetchAllowedDomainStatusStart() {
  yield takeEvery(Actions.fetchAllowedDomainStatusStart, fetchAllowedDomainStatus)
}

export default function* mainSaga() {
  yield all([
    watchCreateAllowedDomainStart(),
    watchCreateDefaultAllowedDomainsStart(),
    watchUpdateAllowedDomainStart(),
    watchDeleteAllowedDomainStart(),
    watchRefreshStatusAllowedDomainStart(),
    watchFetchAllowedDomainStatusStart()
  ])
}
