import { connect } from 'react-redux'

import MobileContent from 'components/content_desk_new/contents/mobile_contents/mobile_content'

import {
  lockContentStart as onEditClick,
  openViewContentDialog as onViewClick
} from 'actions/content_desk'

const mapStateToProps = (_state, ownProps) => ({
  content: ownProps.content
})

export default connect(
  mapStateToProps,
  {
    onEditClick,
    onViewClick
  }
)(MobileContent)
