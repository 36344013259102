import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

export default handleActions({
  [NewsActions.newsRequestSuccess]: (state, { payload: { groups } }) => fromJS(groups.map(({ id }) => id)),
  [NewsActions.updateGroups]: (state, { payload: { updatedGroups } }) => {
    const groupsIds = updatedGroups.keySeq()

    if (state.has(-1)) {
      return state.insert(-1, groupsIds).flatten()
    }

    return state.insert(state.size, groupsIds).flatten()
  },
  [NewsActions.resetNews]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
