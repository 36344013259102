/* eslint-disable max-len, react/no-unescaped-entities */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Autocomplete,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
  TextField
} from '@mui/material'

import BackupIcon from '@mui/icons-material/Backup'
import InfoIcon from '@mui/icons-material/Info'

import { BrowseButton, Button } from 'containers/themed'
import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px'
  },
  browseButton: {
    marginLeft: '0.5em'
  },
  actions: {
    marginBottom: '0.5em',
    marginRight: '0.5em'
  },
  content: {
    padding: '20px',
    fontSize: '11pt',
    fontWeight: '400',
    color: !theme.isDark && '#757575',
    marginLeft: '0.2em'
  },
  title: {
    fontSize: '1.5rem',
    color: !theme.isDark && '#404040'
  },
  hint: {
    fontSize: '10pt',
    color: !theme.isDark && '#757575'
  },
  circularProgress: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '-0.5em'
  },
  newList: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  autocomplete: {
    marginTop: '1em'
  }
}))

const UploadContacts = ({
  open,
  loading,
  autoCreateDistributionList,
  autoAddToExistingList,
  newListName,
  lists,
  user,
  onClose,
  onFileUpload,
  onHelpClick,
  onNewListCheckboxClick,
  onAddToListCheckboxClick,
  onChangeFormData,
  onSelectList
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const options = mapToOptions(lists)
  const [selectedList, setSelectedList] = useState(null)

  const isFormValid = () => {
    if (autoCreateDistributionList && newListName === '') {
      return false
    }

    if (autoAddToExistingList && !selectedList) {
      return false
    }

    return true
  }

  const handleClose = () => {
    setSelectedList(null)
    onClose()
  }

  const handleFileUpload = event => {
    onFileUpload(event.target.files[0])
  }

  const handleNewListCheckboxChange = () => onNewListCheckboxClick(!autoCreateDistributionList)
  const handleAddToListCheckboxChange = () => onAddToListCheckboxClick(!autoAddToExistingList)

  const handleNameChange = e => {
    onChangeFormData({ key: 'name', value: e.target.value })
  }

  const handleAutocompleteChange = (_event, option) => {
    if (option) {
      setSelectedList(option)
      onSelectList(option.value)
    } else {
      setSelectedList(null)
      onSelectList(null)
    }
  }

  const userLanguage = i18n.get(`language_${user.get('languageShort')}`)

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.title}>
          {i18n.get('import')} {i18n.get('contacts')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div
          dangerouslySetInnerHTML={{
            __html: i18n.get('import_contacts_description')
          }}
        />
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={loading}
                checked={autoCreateDistributionList}
                onChange={handleNewListCheckboxChange}
              />
            )}
            label={i18n.get('create_distribution_list')}
          />
          {autoCreateDistributionList
            && (
              <TextField
                className={classes.newList}
                fullWidth
                required
                label={i18n.get('name')}
                onChange={handleNameChange}
                inputProps={{ maxLength: 50 }}
              />
            )}
        </div>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={loading}
                checked={autoAddToExistingList}
                onChange={handleAddToListCheckboxChange}
              />
            )}
            label={i18n.get('add_to_existing_distribution_list')}
          />
          {autoAddToExistingList
            && (
              <Autocomplete
                className={classes.autocomplete}
                key="distributionList"
                variant="outlined"
                getOptionLabel={option => option.label}
                required
                onChange={handleAutocompleteChange}
                options={options}
                value={selectedList}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={i18n.get('select_list')}
                    placeholder=""
                    required
                  />
                )}
              />
            )}
        </div>
        <div>
          <br />
          <Typography className={classes.hint}>
            {i18n.get('upload_contacts_language_hint_1')}: <b>{userLanguage}</b> <br />
            {i18n.get('upload_contacts_language_hint_2')}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.button}
          startIcon={<InfoIcon />}
          disabled={loading}
          onClick={onHelpClick}
          variant="contained"
        >
          {i18n.get('help')}
        </Button>
        <Button
          className={classes.button}
          key="cancel"
          disabled={loading}
          onClick={handleClose}
        >
          {i18n.get('cancel')}
        </Button>
        {loading ? (
          <div className={classes.circularProgress}>
            <CircularProgress />
          </div>
        ) : (
          <BrowseButton
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.browseButton}
            disabled={!isFormValid()}
            onChange={handleFileUpload}
            startIcon={<BackupIcon />}
            variant="contained"
            color="primary"
          >
            {i18n.get('upload')}
          </BrowseButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

UploadContacts.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  autoCreateDistributionList: PropTypes.bool.isRequired,
  autoAddToExistingList: PropTypes.bool.isRequired,
  newListName: PropTypes.string.isRequired,
  lists: PropTypes.instanceOf(ImmutableList).isRequired,
  user: PropTypes.instanceOf(ImmutableMap).isRequired,

  onClose: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onHelpClick: PropTypes.func.isRequired,
  onNewListCheckboxClick: PropTypes.func.isRequired,
  onAddToListCheckboxClick: PropTypes.func.isRequired,
  onChangeFormData: PropTypes.func.isRequired,
  onSelectList: PropTypes.func.isRequired
}

export default UploadContacts
