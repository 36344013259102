import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { rgbaColorFromHex } from 'utils/color'
import { IconButton, Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  mobileContentContainer: {
    padding: '0px 20px 20px 20px'
  },
  contentContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: theme.palette.common.black
  },
  unlockButton: {
    marginTop: 20
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftContainer: {
    padding: '60px 50px 50px 50px',
    flex: '1 1 60%'
  },
  rightContainer: {
    textAlign: 'center',
    flex: '1 1 40%',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`
  },
  mobileImageContainer: {
    textAlign: 'center',
    marginTop: 20,
    padding: '20px 10px 20px 10px',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%'
  }
}))

const WelcomeDialog = ({
  open,
  status,
  onClose,
  onUnlockClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const handleClose = () => {
    onClose()
  }

  const handleUnlockClick = () => {
    onUnlockClick()
    onClose()
  }

  const themaxWelcomeMessage = (
    <>
      <Typography
        variant="h5"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: i18n.get('themax_welcome_title')
        }}
      />
      <Typography
        variant="body1"
        color="text.secondary"
        dangerouslySetInnerHTML={{
          __html: i18n.get('themax_welcome_text')
        }}
      />
    </>
  )

  const unlockButton = (
    <Button
      color="primary"
      variant="contained"
      className={classes.unlockButton}
      onClick={handleUnlockClick}
      disabled={status === 'pending'}
    >
      {i18n.get('unlock')}
    </Button>
  )

  const renderContent = () => {
    if (device.get('mobile')) {
      return (
        <Box className={classes.mobileContentContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {themaxWelcomeMessage}
          <Box className={classes.mobileImageContainer}>
            <img
              src="https://static.pressrelations.de/pics/groot/themax/newspaper.png"
              alt="newspaper"
              width="200"
            />
            {unlockButton}
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Box className={classes.leftContainer}>
          {themaxWelcomeMessage}
          {unlockButton}
        </Box>
        <Box className={classes.rightContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.imageContainer}>
            <img
              src="https://static.pressrelations.de/pics/groot/themax/newspaper.png"
              alt="newspaper"
              width="200"
            />
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.content}>
          {renderContent()}
        </Box>
      </Paper>
    </Dialog>
  )
}

WelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  status: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onUnlockClick: PropTypes.func.isRequired
}

export default WelcomeDialog
