import { connect } from 'react-redux'

import Subscriptions from 'components/subscriptions'
import { getUser } from 'selectors'
import { navigate } from 'actions/navigation'

const mapStateToProps = state => ({
  user: getUser(state)
})

export default connect(
  mapStateToProps,
  {
    navigate
  }
)(Subscriptions)
