import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import Chart from 'containers/charts/Chart'
import useI18n from 'hooks/useI18n'

import { Card, Typography } from '@mui/material'

const useStyles = makeStyles()({
  title: {
    margin: '10px 0 0 10px'
  },
  chart: {
    width: '100%',
    height: 150
  }
})

export default function FilterTimeline({
  chart,
  active,
  onClick,
  dateFrom,
  dateTo,
  onDateChange,
  hidden
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    if (active) {
      onDateChange({
        dateFrom,
        dateTo
      })
    }
  }, [dateFrom, dateTo, onDateChange, active])

  if (hidden) {
    return null
  }

  return (
    <Card>
      <Typography
        classes={{ root: classes.title }}
        variant="caption"
        color="textSecondary"
      >
        {i18n.get('filter_timeline_hint')}
      </Typography>

      <div className={classes.chart}>
        <Chart
          chart={chart}
          data={chart.get('data')}
          onClick={(d, secondFilter) => onClick({ chart, d, secondFilter })}
        />
      </div>
    </Card>
  )
}

FilterTimeline.propTypes = {
  chart: PropTypes.instanceOf(Map).isRequired,
  dateFrom: PropTypes.object,
  dateTo: PropTypes.object,
  active: PropTypes.bool,
  hidden: PropTypes.bool,

  onClick: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired
}
