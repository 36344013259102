import { connect } from 'react-redux'

import { NewsPageModules } from 'static/constants'

import CustomTags from 'components/custom_tags'
import { getI18n, getCustomtagsToShoppingCartLoading, getViewConfigModuleName, getCustomTagsViewType } from 'selectors'
import { setCustomTagsViewType } from 'actions/custom_tags'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  loading: getCustomtagsToShoppingCartLoading(state),
  showRestrictionHint: getViewConfigModuleName(state) === NewsPageModules.NEWS,
  showGroups: getViewConfigModuleName(state) !== NewsPageModules.NEWS_POOL,
  viewType: getCustomTagsViewType(state)
})

export default connect(
  mapStateToProps,
  {
    onViewChange: setCustomTagsViewType
  }
)(CustomTags)
