import { connect } from 'react-redux'

import MediaActions from 'components/content_desk_new/contents/content_edit_dialog/attachments/files_gallery/media_actions'

import {
  openAttachmentsDeleteDialog as openDeleteDialog,
  openAttachmentsImagePreviewDialog as openImagePreviewDialog
} from 'actions/content_desk'

const mapStateToProps = (_state, { mediaFile, isPdf }) => ({
  mediaFile,
  isPdf
})

export default connect(
  mapStateToProps,
  {
    openDeleteDialog,
    openImagePreviewDialog
  }
)(MediaActions)
