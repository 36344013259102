import React from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import tinycolor from 'tinycolor2'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import { Menu } from 'containers/themed'
import { Chip } from '@mui/material'

const useStyles = makeStyles()({
  chip: {
    backgroundColor: '#ffffff',
    marginBottom: 5,
    marginRight: 10
  },
  deleteIcon: {
    color: '#ffffff'
  },
  deleteIconLight: {
    color: '#616161 !important'
  },
  menu: {
    padding: 0
  }
})

export default function ColorPicker({ onChange, onReset, value, defaultValue, useRgb, handle }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleColorChange = ({ hex, rgb: { r, g, b, a } }) => {
    if (useRgb && a < 1) {
      onChange(`rgba(${r}, ${g}, ${b}, ${a})`)
    } else {
      onChange(hex)
    }
  }

  let handleColorReset

  if (onReset) {
    handleColorReset = () => {
      onReset()
    }
  }

  const realValue = value || defaultValue
  const isLight = tinycolor(realValue).getBrightness() > 152

  return (
    <Menu
      classes={{ list: classes.menu }}
      handle={handle || (
        <Chip
          classes={{
            root: classes.chip,
            deleteIcon: isLight ? classes.deleteIconLight : classes.deleteIcon
          }}
          style={{
            backgroundColor: realValue,
            color: isLight ? '#616161' : '#FFFFFF'
          }}
          onDelete={handleColorReset}
          label={value || i18n.get('default')}
          variant="outlined"
        />
      )}
    >
      <div className={classes.picker}>
        <SketchPicker
          width={170}
          color={realValue}
          onChangeComplete={handleColorChange}
        />
      </div>
    </Menu>
  )
}

ColorPicker.defaultProps = {
  useRgb: true
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  useRgb: PropTypes.bool,
  handle: PropTypes.any,

  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func
}
