import { connect } from 'react-redux'

import DispatchLog from 'components/administration/dispatch_log'

import { cancelDispatchStart, fetchDispatchLogStart } from 'actions/export'

import { getDispatchLogData, getDispatchLogLoading } from 'selectors'

const mapStateToProps = state => ({
  dispatchLog: getDispatchLogData(state),
  loading: getDispatchLogLoading(state)
})

export default connect(
  mapStateToProps,
  {
    onCancelClick: cancelDispatchStart,
    onMount: fetchDispatchLogStart
  }
)(DispatchLog)
