import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'
import * as NewsActions from 'actions/news'

const newsFeedPageSize = 10

const updateFlagsInGroup = (state, groupId, loading) => (
  state.update('groups', groups => groups.map(group => {
    if (group.get('id') === groupId) {
      return group.merge({ loading })
    }

    return group
  }))
)

const updateNewsInAllGroups = (state, newNews) => state
  .update('groups', groups => groups.map(g => g
    .update('news', news => news.map(n => {
      if (n.get('id') === newNews.get('id')) {
        return newNews
      }

      return n
    }))))

const updateNewsInGroup = (state, groupId, newNews) => state
  .update('groups', groups => groups.map(g => {
    if (g.get('id') === groupId) {
      return g
        .update('news', news => news.map(n => {
          if (n.get('id') === newNews.get('id')) {
            return newNews
          }

          return n
        }))
    }

    return g
  }))

export const initialState = fromJS({
  groups: [],
  aiAnalysis: null
})

export default handleActions({
  [Actions.showDrilldownDialog]: () => initialState,

  [Actions.loadDrilldownNewsFeedNewsSuccess]: (state, { payload: { groups } }) => {
    const newGroups = fromJS(groups)
    let newState = state.set('groups', newGroups)

    newState = newGroups.reduce(
      (acc, group) => (
        updateFlagsInGroup(acc, group.get('id'), false)
      ),
      newState
    )

    return newState
  },

  [NewsActions.updateNews]: (state, { payload: news }) => {
    let newState = state

    newState = news.reduce((acc, n) => updateNewsInAllGroups(acc, n), newState)

    return newState
  },

  [Actions.loadMoreDrilldownNewsFeedNewsStart]: (state, { payload: { group } }) => (
    updateFlagsInGroup(state, group.get('id'), true)
  ),

  [Actions.loadMoreDrilldownNewsFeedNewsError]: (state, { payload: { group } }) => (
    updateFlagsInGroup(state, group.get('id'), false)
  ),

  [Actions.loadMoreDrilldownNewsFeedNewsSuccess]: (state, { payload: { group, result } }) => {
    const groupId = group.get('id')
    const newNews = fromJS(result.groups.filter(g => g.id === groupId)).getIn([0, 'news'])

    let newState = state

    if (newNews) {
      newState = newState
        .update('groups', groups => groups.map(g => {
          if (g.get('id') === groupId) {
            return g
              .update('news', news => news.concat(newNews))
              .update('from', newsFeedPageSize, from => from + newsFeedPageSize)
          }

          return g
        }))

      newState = updateFlagsInGroup(newState, groupId, false)
    } else {
      newState = updateFlagsInGroup(newState, groupId, false)
    }

    return newState
  },
  [Actions.fetchDrilldownNewsFeedClusterStatsSuccess]: (state, { payload: { group, newNews } }) => {
    let newState = state

    if (group && newNews) {
      const groupId = group.get('id')

      newState = updateNewsInGroup(state, groupId, newNews)
    }

    return newState
  },

  [Actions.loadDrilldownNewsFeedAiAnalysisStart]: state => state.set('aiAnalysis', null),
  [Actions.loadDrilldownNewsFeedAiAnalysisSuccess]: (state, { payload: { analysis } }) => state.set('aiAnalysis', fromJS(analysis)),

  [AppActions.resetState]: () => initialState
}, initialState)
