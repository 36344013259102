import React, { isValidElement } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { FormControlLabel, Checkbox, CardContent, Card } from '@mui/material'

const useStyles = makeStyles()({
  checked: {
    fontWeight: 500
  },
  root: {
    paddingLeft: 10
  },
  card: {
    cursor: 'pointer',
    width: '100%'
  },
  cardText: {
    paddingBottom: '14px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  icon: {
  }
})

export default function CheckboxCard({ label, className, disabled, checked, onChange, accent, icon }) {
  const { classes } = useStyles()

  const renderLabel = () => {
    if (!checked) {
      return <div dangerouslySetInnerHTML={{ __html: label }} />
    }

    return (
      <div
        className={classes.checked}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    )
  }

  const renderIcon = () => {
    if (!isValidElement(icon)) {
      return null
    }

    return icon
  }

  return (
    <Card
      className={`${classes.root} ${className} ${classes.card}`}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()

        if (!disabled) {
          onChange(!checked)
        }
      }}
    >
      <CardContent classes={{ root: classes.cardText }}>
        <FormControlLabel
          label={renderLabel(label, checked)}
          control={(
            <Checkbox
              color={accent ? 'secondary' : 'primary'}
              disabled={disabled}
              checked={checked}
              onChange={e => onChange(e.target.checked)}
            />
          )}
        />
        {renderIcon()}
      </CardContent>
    </Card>
  )
}

CheckboxCard.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  accent: PropTypes.bool,
  checked: PropTypes.bool,
  icon: PropTypes.any,
  className: PropTypes.string,
  onChange: PropTypes.func
}
