import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { List } from 'immutable'
import moment from 'moment-timezone'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
  Switch
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Intervals } from 'static/constants'
import { capitalize } from 'utils/string'

import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'

import useI18n from 'hooks/useI18n'
import ConfirmationDialog from '../confirmation_dialog/ConfirmationDialog'

const useStyles = makeStyles()(theme => ({
  table: {
    minWidth: 650
  },
  tableNameColumn: {
    minWidth: 100
  },
  activatedColumn: {
    padding: '4px 16px 4px 16px'
  },
  deactivatedColumn: {
    padding: '4px 16px 4px 16px',
    color: '#888888'
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(5)
  },
  tableContainer: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: 'relative',
    overflow: 'hidden'
  },
  tableActionColumn: {
    width: 90,
    padding: '4px 16px 4px 16px'
  },
  actionsDivider: {
    backgroundColor: 'rgba(81, 81, 81, 1)',
    float: 'left',
    height: 45
  },
  actionGroup: {
    display: 'flex'
  },
  activationSwitch: {
    marginTop: 5
  },
  confirmationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  closeButton: {
    marginRight: 10
  },
  searchRow: {
    borderLeft: '20px solid #3498A4'
  },
  scoreRow: {
    borderLeft: '20px solid #EB711F'
  },
  deactivatedRow: {
    borderLeft: '20px solid #445C68'
  },
  overlay: {
    zIndex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.2)'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '90%'
  }
}))

function mapIntervals(timeInMinutes) {
  const interval = Object.keys(Intervals).find(key => Intervals[key] === timeInMinutes)

  if (interval === undefined) {
    return `${timeInMinutes / 60} hours`
  }

  return interval
}

export default function QueriesTable({
  queries,
  requestRunning,

  onScoreQueryDialogOpen,
  onSearchQueryDialogOpen,
  onDelete,
  onDeactivate,
  onActivate
}) {
  const [confirmationDialog, setConfirmationDialog] = useState({ row: null, isOpen: false })
  const { classes, cx } = useStyles()
  const i18n = useI18n()

  const toggleConfirmationDialog = (row = null) => {
    setConfirmationDialog({ row, isOpen: !confirmationDialog.isOpen })
  }

  const handleDelete = () => {
    onDelete({ type: confirmationDialog.row.get('type'), id: confirmationDialog.row.get('id') })
    toggleConfirmationDialog()
  }

  const handleDeactivate = row => {
    onDeactivate({ type: row.get('type'), id: row.get('id') })
  }

  const handleActivate = row => {
    onActivate({ type: row.get('type'), id: row.get('id') })
  }

  const handleEdit = row => {
    if (row.get('type') === 'score') {
      onScoreQueryDialogOpen(row)
    }

    if (row.get('type') === 'search') {
      onSearchQueryDialogOpen(row)
    }
  }

  function renderActionButtons(row) {
    const activated = !row.get('deactivatedAt')

    return (
      <div className={classes.actionGroup}>
        <IconButton
          aria-label="delete"
          onClick={() => toggleConfirmationDialog(row)}
          size="large"
        >
          <DeleteOutlinedIcon />
        </IconButton>
        <IconButton
          aria-label="edit"
          onClick={() => handleEdit(row)}
          size="large"
        >
          <EditOutlinedIcon />
        </IconButton>
        <Switch
          className={classes.activationSwitch}
          title={i18n.get(activated ? 'Deactivate' : 'Activate')}
          checked={activated}
          onChange={() => (activated ? handleDeactivate(row) : handleActivate(row))}
          color="primary"
        />
      </div>
    )
  }

  const formatDomains = query => query.get('domains')
    .reduce((acc, obj) => {
      const domainType = obj.get('type')

      return {
        ...acc, [domainType]: (acc[domainType] || []).concat(obj.get('domain'))
      }
    }, {})

  const stringifyDomains = domains => Object.entries(domains).map(([key, value], index) => (
    <div key={index}>
      <div>
        {key === 'web' && `Web domains: ${value.join(', ')}`}
      </div>
      <div>
        {key === 'email' && `Email domains: ${value.join(', ')}`}
      </div>
    </div>
  ))

  const cellClass = el => (el.get('deactivatedAt') ? classes.deactivatedColumn : classes.activatedColumn)
  const rowTypeClass = el => {
    if (el.get('deactivatedAt')) {
      return classes.deactivatedRow
    }

    return el.get('type') === 'score' ? classes.scoreRow : classes.searchRow
  }

  return (
    <>
      <div className={classes.tableWrapper}>
        <TableContainer
          component={Paper}
          className={classes.tableContainer}
        >
          {requestRunning && (
          <div className={classes.overlay}>
            <div className={classes.progressBar}>
              <NrxProgressBar />
            </div>
          </div>
          )}
          <Table
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableNameColumn}>{i18n.get('name')}</TableCell>
                <TableCell>{i18n.get('type')}</TableCell>
                <TableCell>{i18n.get('search_phrase')}</TableCell>
                <TableCell align="left">{i18n.get('requests')}
                </TableCell>
                <TableCell align="left">{i18n.get('interval')}
                </TableCell>
                <TableCell align="left">{i18n.get('last_run')}
                </TableCell>
                <TableCell align="left">{i18n.get('next_run')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {queries.map((el, index) => (
                <TableRow key={`${el.get('id')}-${index}`}>
                  <TableCell
                    className={cx(cellClass(el), rowTypeClass(el))}
                  >
                    {el.get('name')}
                  </TableCell>
                  <TableCell className={cellClass(el)}>
                    {el.get('type') === 'search' ? i18n.get('exposure') : i18n.get('risk_assessment')}
                  </TableCell>
                  <TableCell className={cellClass(el)}>
                    {el.get('query') || stringifyDomains(formatDomains(el))}
                  </TableCell>
                  <TableCell
                    className={cellClass(el)}
                    align="left"
                  >
                    {el.get('successfulRunsCount')}
                  </TableCell>
                  <TableCell
                    className={cellClass(el)}
                    align="left"
                  >
                    {capitalize(i18n.get(mapIntervals(el.get('crawlingInterval'))))}
                  </TableCell>
                  <TableCell
                    className={cellClass(el)}
                    align="left"
                  >
                    {el.get('lastCrawledAt') ? moment(el.get('lastCrawledAt')).calendar() : 'N/A'}
                  </TableCell>
                  <TableCell
                    className={cellClass(el)}
                    align="left"
                  >
                    {
                    el.get('lastCrawledAt')
                      ? moment(el.get('lastCrawledAt'))
                        .add(el.get('crawlingInterval'), 'minutes')
                        .calendar()
                      : 'N/A'
                  }
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableActionColumn}
                  >
                    <Divider
                      orientation="vertical"
                      className={classes.actionsDivider}
                      flexItem
                      component="hr"
                    />
                    {renderActionButtons(el)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <ConfirmationDialog
        open={confirmationDialog.isOpen}
        onClose={() => toggleConfirmationDialog()}
        onConfirm={handleDelete}
      />
    </>
  )
}

QueriesTable.propTypes = {
  queries: PropTypes.instanceOf(List).isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onScoreQueryDialogOpen: PropTypes.func.isRequired,
  onSearchQueryDialogOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired
}
