import { connect } from 'react-redux'

import Reactions from 'containers/NrxReactions'

import { getMediaReviewReactions } from 'selectors'

const mapStateToProps = (state, { codeId, news }) => ({
  codeId,
  reactions: getMediaReviewReactions(state).get(`${codeId || ''}||${news.get('id')}`)
})

export default connect(
  mapStateToProps
)(Reactions)
