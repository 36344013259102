import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(8),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  }
}))

export default function InfoDialog({
  open,
  onClose
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('ccd_allowed_domain_info_title')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box dangerouslySetInnerHTML={{ __html: i18n.get('ccd_allowed_domain_info_text') }} />
      </DialogContent>
    </Dialog>
  )
}

InfoDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired
}
