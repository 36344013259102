import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Popover } from 'containers/themed'

import ReactionSelect from './reaction_select'
import ReactionSelectTrigger from './reaction_select_trigger'

const useStyles = makeStyles()({
  reactPopover: {
    overflow: 'visible',
    padding: 10
  }
})

export default function ReplaceReaction({
  reactions,
  reactionTypes,
  onReactionClick,
  user
}) {
  const { classes } = useStyles()

  const myReactionTypeId = reactions.findKey(r => r.find(u => u.get('id') === user.get('id')))
  const myReactionType = reactionTypes.find(rt => rt.get('id').toString() === myReactionTypeId)

  return (
    <Popover
      disableScrollLock
      classes={{ paper: classes.reactPopover }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      handle={(
        <div>
          <ReactionSelectTrigger reactionType={myReactionType} />
        </div>
      )}
    >
      <ReactionSelect
        reactionTypes={reactionTypes}
        onClick={onReactionClick}
        selectedId={myReactionTypeId}
      />
    </Popover>
  )
}

ReplaceReaction.propTypes = {
  reactions: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(Map).isRequired,
  reactionTypes: PropTypes.instanceOf(List).isRequired,

  onReactionClick: PropTypes.func
}
