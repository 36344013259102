import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_charts'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'

export const initialState = fromJS([])

const setLoading = (state, id, loading) => state.map(savedChart => {
  if (savedChart.get('id') === id || id === null) {
    return savedChart.setIn(['chart', 'loading'], loading)
  }

  return savedChart
})

export default handleActions({
  [Actions.updateSavedChartSuccess]: (state, { payload: result }) => state.map(savedChart => {
    if (savedChart.get('id') === result.id) {
      return fromJS(result)
    }

    return savedChart
  }),

  [Actions.fetchSavedChartStart]: (state, { payload: { id } }) => setLoading(state, id, true),
  [Actions.fetchSavedChartSuccess]: state => setLoading(state, null, false),
  [Actions.fetchSavedChartError]: state => setLoading(state, null, false),

  [Actions.createSavedChartSuccess]: (state, { payload: result }) => state.push(fromJS(result)),

  [combineActions(
    Actions.deleteSavedChartSuccess,
    Actions.discardSavedChartSuccess
  )]: (state, { payload: id }) => state.filter(s => s.get('id') !== id),

  [ConfigActions.configRequestSuccess]: (state, { payload: { savedCharts } }) => fromJS(savedCharts),
  [AppActions.resetState]: () => initialState
}, initialState)
