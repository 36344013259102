/* globals window */
import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import { Box } from '@mui/material'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '90%',
    gap: '10px'
  },
  button: {
    borderWidth: '2px',
    '&:hover': {
      borderWidth: '2px'
    }
  }
})

const MediaActions = ({
  deleteDisabled,
  mediaFile,
  isPdf,
  openDeleteDialog,
  openImagePreviewDialog
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handlePdfPreview = e => {
    e.preventDefault()
    e.stopPropagation()
    window.open(mediaFile.get('url'), '_blank', 'noopener,noreferrer')
  }

  const handleImagePreviewDialog = e => {
    e.preventDefault()
    e.stopPropagation()
    openImagePreviewDialog(mediaFile.get('url'))
  }

  const handleDeleteDialog = e => {
    e.preventDefault()
    e.stopPropagation()
    openDeleteDialog(mediaFile.get('id'))
  }

  return (
    <Box className={classes.container}>
      <Button
        className={classes.button}
        fullWidth
        variant="outlined"
        color="primary"
        onClick={isPdf ? handlePdfPreview : handleImagePreviewDialog}
      >
        {i18n.get('preview')}
      </Button>
      {!deleteDisabled && (
        <Button
          className={classes.button}
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleDeleteDialog}
        >
          {i18n.get('delete')}
        </Button>
      )}
    </Box>
  )
}

MediaActions.propTypes = {
  deleteDisabled: PropTypes.bool,
  mediaFile: PropTypes.instanceOf(Map).isRequired,
  isPdf: PropTypes.bool.isRequired,

  openDeleteDialog: PropTypes.func.isRequired,
  openImagePreviewDialog: PropTypes.func.isRequired
}

export default MediaActions
