import { connect } from 'react-redux'

import AppBar from 'components/themax/app_bar'

import {
  getThemaxShowAdvancedSearch,
  getThemaxShowDateForm
} from 'selectors'

import {
  toggleAdvancedSearch,
  toggleDateForm
} from 'actions/themax'

const mapStateToProps = state => ({
  showAdvancedSearch: getThemaxShowAdvancedSearch(state),
  showDateForm: getThemaxShowDateForm(state)
})

export default connect(
  mapStateToProps,
  {
    toggleAdvancedSearch,
    toggleDateForm
  }
)(AppBar)
