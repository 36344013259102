import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

import { RaisedPrimaryButton, IconButton } from 'containers/themed'

import DistributionListTable from 'containers/contact_management/DistributionListTable'

const DistributionListViewDialog = ({ contact, open, onClose }) => {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const handleClose = () => onClose()

  if (!contact.get('distributionLists') || contact.get('distributionLists').isEmpty()) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(3),
          color: t => t.palette.grey[800]
        }}
      >
        {`${contact.get('firstName') || ''} ${contact.get('lastName')} | ${i18n.get('distribution_lists')}`}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: 0 }}
      >
        <DistributionListTable />
      </DialogContent>

      <DialogActions>
        <Box
          m={2}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <RaisedPrimaryButton onClick={handleClose}>
            {i18n.get('close')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DistributionListViewDialog.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired
}

export default DistributionListViewDialog
