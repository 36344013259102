import React, { useState, useEffect, useRef } from 'react'
import { fromJS, List } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import {
  Grid,
  TextField,
  Autocomplete,
  CircularProgress,
  Chip,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
  Alert
} from '@mui/material'
import { red } from '@mui/material/colors'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

import { listUniqueByKeyName } from 'utils/immutable'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  recipientList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
    marginBottom: '1em'
  },
  row1: {
    opacity: 1
  },
  row2: {
    opacity: 0.7
  },
  row3: {
    opacity: 0.4,
    WebkitMaskImage: 'linear-gradient(to bottom, white 30%, transparent 100%)',
    maskImage: 'linear-gradient(to bottom, white 30%, transparent 100%)'
  },
  switchButton: {
    textAlign: 'right'
  },
  switchButtonHint: {
    fontSize: '10pt',
    color: '#7C7C7C',
    marginTop: '8px'
  },
  bottomActionsContainer: {
    marginTop: '2em',
    padding: '0em 0em 0em 2em',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  nextButton: {
    marginLeft: '1em'
  },
  recipientsActions: {
    display: 'flex',
    gap: 20,
    marginBottom: '1em'
  }
})

const SelectRecipientsForm = ({
  recipients,
  contactDropdownOptions,
  contactDropdownOptionsLoading,
  distributionListDropdownOptions,
  distributionListDropdownOptionsLoading,
  isDistListsLocked,
  fetchContactDropdownOptionsStart,
  fetchDistributionListDropdownOptionsStart,
  setContentFormData,
  saveContentStart
}) => {
  const recipientsRef = useRef(null)
  const collapsedSize = 110
  const [contactOpen, setContactOpen] = useState(false)
  const [contactOptions, setContactOptions] = useState(contactDropdownOptions.filter(contact => !contact.get('unsubscribed')).toJS())
  const [contactValue, setContactValue] = useState(null)
  const [contactInputValue, setContactInputValue] = useState('')
  const [dlOpen, setDlOpen] = useState(false)
  const [dlOptions, setDlOptions] = useState(distributionListDropdownOptions.toJS())
  const [dlValue, setDlValue] = useState(null)
  const [dlInputValue, setDlInputValue] = useState('')
  const [showRecipients, setShowRecipients] = useState(false)
  const [recipientsHeight, setRecipientsHeight] = useState(0)
  const { classes } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    fetchDistributionListDropdownOptionsStart({ searchString: dlInputValue })
  }, [dlInputValue])

  useEffect(() => {
    fetchContactDropdownOptionsStart({ searchString: contactInputValue })
  }, [contactInputValue])

  useEffect(() => {
    setContactOptions(contactDropdownOptions.filter(contact => !contact.get('unsubscribed')).toJS())
    setDlOptions(distributionListDropdownOptions.toJS())
  }, [distributionListDropdownOptions, contactDropdownOptions])

  useEffect(() => {
    setRecipientsHeight(recipientsRef.current.clientHeight)
  })

  const handleShowAllRecipients = () => setShowRecipients(prevState => !prevState)

  const handleClearAll = () => setContentFormData({ key: 'recipients', value: List() })

  const handleDelete = currentRecipient => {
    const newRecipients = recipients.filter(
      r => r.get('id') !== currentRecipient.get('id') && r.get('contactId') !== currentRecipient.get('contactId')
    )

    setContentFormData({
      key: 'recipients',
      value: newRecipients
    })
  }

  const dlHandleOnChange = (_event, value) => {
    if (value) {
      const selectedRecipients = value.contacts.map(c => {
        const contact = c
        contact.contactId = contact.id
        contact.distributionListId = value.id
        contact.new = true
        contact.distributionListName = value.name
        delete contact.createdAt

        return contact
      })
      const newRecipients = [...selectedRecipients, ...recipients.toJS()]
      const uniqueRecipients = listUniqueByKeyName(fromJS(newRecipients), 'contactId')

      setContentFormData({ key: 'recipients', value: uniqueRecipients })
    }

    setDlValue(null)
    setDlInputValue('')
  }

  const contactHandleOnChange = (_event, value) => {
    if (value) {
      const contact = value
      contact.contactId = value.id
      contact.new = true

      const newRecipients = [contact, ...recipients.toJS()]
      const uniqueRecipients = listUniqueByKeyName(fromJS(newRecipients), 'contactId')

      setContentFormData({ key: 'recipients', value: uniqueRecipients })
    }

    setContactValue(null)
    setContactInputValue('')
  }

  const onSwitchClick = (_event, _value) => {
    saveContentStart({ switchToContactManagement: true })
  }

  const renderDlAutocomplete = () => (
    <Autocomplete
      disabled={isDistListsLocked}
      clearOnEscape
      disableCloseOnSelect
      value={dlValue}
      inputValue={dlInputValue}
      open={dlOpen}
      onOpen={() => {
        setDlOpen(true)
      }}
      onClose={() => {
        setDlOpen(false)
      }}
      filterOptions={x => x}
      getOptionLabel={option => option.name}
      options={dlOptions}
      loading={distributionListDropdownOptionsLoading}
      onChange={dlHandleOnChange}
      onInputChange={(_event, newInputValue) => {
        setDlInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.get('search_for_distribution_list')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {distributionListDropdownOptionsLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )

  const renderContactAutocomplete = () => (
    <Autocomplete
      clearOnEscape
      disableCloseOnSelect
      value={contactValue}
      inputValue={contactInputValue}
      open={contactOpen}
      onOpen={() => {
        setContactOpen(true)
      }}
      onClose={() => {
        setContactOpen(false)
      }}
      filterOptions={x => x}
      getOptionLabel={option => `${option.firstName || ''} ${option.lastName} [${option.mail}]`}
      options={contactOptions}
      loading={contactDropdownOptionsLoading}
      onChange={contactHandleOnChange}
      onInputChange={(_event, newInputValue) => {
        setContactInputValue(newInputValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={i18n.get('search_for_contacts')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {contactDropdownOptionsLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )

  const renderRecipients = () => recipients.map(
    currentRecipient => (
      <div
        className={classes.recipient}
        key={`${currentRecipient.get('id')}_${currentRecipient.get('contactId')}`}
      >
        <Tooltip
          title={currentRecipient.get('distributionListName')
            ? `${i18n.get('distribution_list')}: ${currentRecipient.get('distributionListName')}`
            : `${i18n.get('added_manually')}`}
          placement="right-start"
          arrow
        >
          <Chip
            color={currentRecipient.get('new') ? 'primary' : 'default'}
            variant={currentRecipient.get('new') ? 'outlined' : 'filled'}
            label={currentRecipient.get('mail')}
            onDelete={() => handleDelete(currentRecipient)}
          />
        </Tooltip>
      </div>
    )
  )

  useEffect(() => {
    const recipientsNode = recipientsRef.current
    let lastTop = -1
    let totalRows = 0

    Array.from(recipientsNode.children).forEach(child => {
      if (child.offsetTop !== lastTop) {
        totalRows += 1
        lastTop = child.offsetTop
      }

      // eslint-disable-next-line no-param-reassign
      child.className = ''
    })

    if (!showRecipients && totalRows >= 3) {
      lastTop = -1
      let row = 0
      Array.from(recipientsNode.children).forEach(child => {
        if (child.offsetTop !== lastTop) {
          row += 1
          lastTop = child.offsetTop
        }

        // eslint-disable-next-line no-param-reassign
        child.className = classes[`row${row}`]
      })
    }
  }, [recipients, showRecipients])

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
        >
          {renderDlAutocomplete()}
          {isDistListsLocked && (
            <Alert
              variant="outlined"
              sx={{ border: 'none' }}
              severity="error"
            >
              {i18n.get('distribution_lists_limit_reached')}
            </Alert>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
        >
          {renderContactAutocomplete()}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onSwitchClick}
          >
            {i18n.get('contactManagement')}
          </Button>
          <div className={classes.switchButtonHint}>{i18n.get('ccd_switch_button_hint')}</div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <div className={classes.recipientsActions}>
            {recipients.size > 2 && (
              <Button
                variant="outlined"
                onClick={handleClearAll}
                startIcon={<DeleteForeverIcon sx={{ color: red[500] }} />}
              >

                {i18n.get('clear_all')}
              </Button>
            )}
            {recipientsHeight > 70 && (
              <FormControlLabel
                control={(
                  <Switch
                    color="primary"
                    checked={showRecipients}
                    onChange={handleShowAllRecipients}
                  />
                )}
                label={i18n.get('show_all')}
              />
            )}
          </div>
          <Collapse
            in={showRecipients}
            collapsedSize={collapsedSize}
            timeout={0}
          >
            <div
              className={classes.recipientList}
              ref={recipientsRef}
            >
              {renderRecipients()}
            </div>
          </Collapse>
        </Grid>
      </Grid>
    </>
  )
}

SelectRecipientsForm.propTypes = {
  recipients: PropTypes.instanceOf(List).isRequired,
  contactDropdownOptions: PropTypes.instanceOf(List).isRequired,
  contactDropdownOptionsLoading: PropTypes.bool.isRequired,
  distributionListDropdownOptions: PropTypes.instanceOf(List).isRequired,
  distributionListDropdownOptionsLoading: PropTypes.bool.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,

  fetchContactDropdownOptionsStart: PropTypes.func.isRequired,
  fetchDistributionListDropdownOptionsStart: PropTypes.func.isRequired,
  setContentFormData: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired
}

export default SelectRecipientsForm
