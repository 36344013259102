export const canvasToPdf = (canvas, dataUrl, filename) => {
  import(/* webpackChunkName: "jspdf" */ 'jspdf').then(({ jsPDF }) => {
    const pdf = new jsPDF({
      unit: 'px',
      hotfixes: ['px_scaling'],
      orientation: canvas.width > canvas.height ? 'landscape' : 'portrait',
      format: [canvas.width, canvas.height]
    })

    pdf.addImage(dataUrl, 'JPEG', 0, 0)
    pdf.save(filename)
  })
}

export const htmlToPdf = (element, filename) => {
  import(/* webpackChunkName: "jspdf" */ 'jspdf').then(({ jsPDF }) => {
    const pdf = new jsPDF({
      unit: 'px',
      hotfixes: ['px_scaling'],
      format: 'a4'
    })

    const padding = 20
    const width = 793

    pdf.html(
      element,
      {
        width: width - padding * 2,
        windowWidth: width - padding * 2,
        x: padding,
        y: padding,
        callback: doc => {
          doc.save(filename)
        }
      }
    )
  })
}
