import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalizeIt } from 'utils/normalizr'

import * as Actions from 'actions/identity_sets'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  entities: {},
  result: []
})

export default handleActions({
  [Actions.setIdentitySets]: (state, { payload: identitySets }) => (
    state.merge(fromJS(normalizeIt({ identitySets }, 'identitySet')))
  ),

  [Actions.saveIdentitySetSuccess]: (state, { payload: { identitySet } }) => (
    state
      .update('entities', entities => entities.set(identitySet.id.toString(), fromJS(identitySet)))
      .update('result', result => (result.includes(identitySet.id) ? result : result.push(identitySet.id)))
  ),

  [Actions.deleteIdentitySetSuccess]: (state, { payload: id }) => (
    state
      .update('entities', entities => entities.delete(id.toString()))
      .update('result', result => result.filter(i => i !== id))
  ),

  [Actions.removeIdentityFromIdentitySetSuccess]: (state, { payload: { identitySet, identityId } }) => {
    const entity = state.getIn(['entities', identitySet.get('id').toString()])

    if (entity) {
      return state.setIn(
        ['entities', identitySet.get('id').toString()],
        entity.update('identitySetsIdentities', is => is.filter(i => i.get('identityId') !== identityId))
      )
    }

    return state
  },

  [Actions.addIdentityToIdentitySetSuccess]: (state, { payload: { identitySetsIdentities } }) => {
    const { identitySetId } = identitySetsIdentities
    const entity = state.getIn(['entities', identitySetId.toString()])

    if (entity) {
      return state.setIn(
        ['entities', identitySetId.toString()],
        entity.update('identitySetsIdentities', is => is.push(fromJS(identitySetsIdentities)))
      )
    }

    return state
  },

  [AppActions.resetState]: () => initialState
}, initialState)
