import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import Card from 'components/card'
import DummyCardIcon from 'components/dummy_card_icon'

import { CardActionArea, CardHeader } from '@mui/material'

const useStyles = makeStyles()({
  icon: {
    height: 200
  }
})

export default function NewItemCard({ onClick, label, iconClassName }) {
  const { classes, cx } = useStyles()

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <div className={cx(classes.icon, iconClassName)}>
          <DummyCardIcon icon="add" />
        </div>
      </CardActionArea>

      <CardHeader title={label} />
    </Card>
  )
}

NewItemCard.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.string
}
