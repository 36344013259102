import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { NewsPageViews } from 'static/constants'

import * as ConfigActions from 'actions/config'
import * as NewsActions from 'actions/news'
import * as FilterActions from 'actions/filter'
import * as AppActions from 'actions/app'

const queryTypeMapping = {
  post: 'news',
  profile: 'author'
}

const queryTypeLabelMapping = {
  news: 'search_for_news',
  author: 'search_for_authors',
  profile: 'search_for_profiles',
  publication: 'search_for_publications',
  post: 'search_for_posts'
}

const newsViewMapping = {
  [NewsPageViews.INFLUENCERS]: {
    selectedQueryType: 'author',
    searchBarLabel: 'search_for_authors'
  },
  [NewsPageViews.PROFILES]: {
    selectedQueryType: 'author',
    searchBarLabel: 'search_for_profiles'
  },
  [NewsPageViews.POSTS]: {
    selectedQueryType: 'news',
    searchBarLabel: 'search_for_posts'
  },
  [NewsPageViews.STATISTICS]: {
    selectedQueryType: 'news',
    searchBarLabel: 'search_for_posts'
  },
  [NewsPageViews.PUBLICATIONS]: {
    selectedQueryType: 'publication',
    searchBarLabel: 'search_for_publications'
  },
  default: {
    selectedQueryType: 'news',
    searchBarLabel: 'search_for_news'
  }
}

export const initialState = fromJS({
  currentIndex: 0,
  showAdvancedSearch: false,
  showDateForm: false,
  displayRemoveDialog: false,
  deletingNews: false,
  selectedNewsToRemove: {
    id: null,
    headline: null
  },
  showHelpDialog: false,
  selectedQueryType: 'news',
  searchBarLabel: 'search_for_news',
  newsRequestIsRunning: false,
  newsView: 'news',
  previousNewsView: 'news',
  activeFiltersCollapsed: false,
  lastSeenNewsId: 0,
  showNewsList: false,
  selectedNewsIdToShowInDrawer: 0,
  selectedNewsIdForAiAnalysis: 0,
  newsRequestBlocked: false,
  lastNewsIdClickedOnMorePublications: 0,
  youtubePlayerActiveNewsId: null,
  tiktokPlayerActiveNewsId: null,
  translateRunningRequestForNewsId: null
})

export default handleActions({
  [NewsActions.currentIndexChange]: (state, { payload: index }) => state.set('currentIndex', index),
  [NewsActions.toggleAdvancedSearch]: state => {
    const show = state.get('showAdvancedSearch')
    let result = state.set('showAdvancedSearch', !show)

    if (show === false) {
      result = result.set('showDateForm', false)
      result = result.set('showHelpDialog', false)
    }

    return result
  },
  [NewsActions.showNewsPageRemoveNewsDialog]: (state, { payload: news }) => (
    state.merge({ displayRemoveDialog: true, selectedNewsToRemove: news })
  ),
  [NewsActions.hideNewsPageRemoveNewsDialog]: state => state.set('displayRemoveDialog', false),

  [NewsActions.deleteNewsStart]: state => state.set('deletingNews', true),
  [NewsActions.deleteNewsError]: state => state.set('deletingNews', false),
  [NewsActions.deleteNewsSuccess]: state => state.merge({ deletingNews: false, displayRemoveDialog: false }),

  [NewsActions.toggleHelpDialog]: state => state.set('showHelpDialog', !state.get('showHelpDialog')),
  [NewsActions.toggleDateForm]: (state, { payload }) => {
    let show = !state.get('showDateForm')

    if (payload !== undefined) {
      show = payload === true
    }

    let result = state.set('showDateForm', show)

    if (show) {
      result = result.set('showAdvancedSearch', false)
      result = result.set('showHelpDialog', false)
    }

    return result
  },
  [NewsActions.setLastNewsIdClickedOnMorePublications]: (state, { payload: id }) => {
    if (id === state.get('lastNewsIdClickedOnMorePublications')) {
      return state.set('lastNewsIdClickedOnMorePublications', 0)
    }

    return state.set('lastNewsIdClickedOnMorePublications', id)
  },
  [FilterActions.setSelectedQueryType]: (state, { payload: queryType }) => {
    const newState = state.set('selectedQueryType', queryTypeMapping[queryType] || queryType)

    if (queryTypeLabelMapping[queryType]) {
      return newState.set('searchBarLabel', queryTypeLabelMapping[queryType])
    }

    return state
  },
  [NewsActions.newsRequestStart]: state => {
    if (state.get('newsRequestBlocked')) {
      return state
    }

    return state.set('newsRequestIsRunning', true)
  },
  [NewsActions.setNewsRequestIsRunning]: (state, { payload: running }) => state.set('newsRequestIsRunning', running),
  [NewsActions.invalidSearch]: state => state.set('newsRequestIsRunning', false),
  [NewsActions.setNewsView]: (state, { payload: view }) => {
    let newsView = view

    if (newsView === NewsPageViews.CHARTS && state.get('newsView') === NewsPageViews.CHARTS) {
      newsView = state.get('previousNewsView')
    }

    const newState = state.merge({
      previousNewsView: state.get('newsView', initialState.get('newsView')),
      newsView,
      youtubePlayerActiveNewsId: null,
      tiktokPlayerActiveNewsId: null
    })

    return newState.merge(newsViewMapping[newsView] || newsViewMapping.default)
  },
  [combineActions(NewsActions.newsRequestError, NewsActions.newsRequestSuccess)]: state => state.set('newsRequestIsRunning', false),
  [NewsActions.resetNews]: state => initialState.merge({
    newsView: state.get('newsView'),
    previousNewsView: state.get('previousNewsView'),
    lastSeenNewsId: state.get('lastSeenNewsId'),
    showNewsList: state.get('showNewsList'),
    newsRequestBlocked: state.get('newsRequestBlocked')
  }),

  [NewsActions.toggleActiveFilters]: state => state.update('activeFiltersCollapsed', v => !v),

  [combineActions(
    FilterActions.addNewsQuery,
    FilterActions.setSelectedFilters,
    FilterActions.addFilters,
    FilterActions.setFilter
  )]: state => state.set('activeFiltersCollapsed', false),

  [NewsActions.setLastSeenNewsId]: (state, { payload: id }) => state.set('lastSeenNewsId', id),

  [NewsActions.setShowNewsList]: (state, { payload: showNewsList }) => state.set('showNewsList', showNewsList),
  [NewsActions.setSelectedNewsIdToShowInDrawer]: (state, { payload: id }) => state.set('selectedNewsIdToShowInDrawer', id || 0),

  [NewsActions.blockNewsRequest]: state => state.set('newsRequestBlocked', true),
  [NewsActions.unblockNewsRequest]: state => state.set('newsRequestBlocked', false),

  [NewsActions.setYoutubePlayerActiveNewsId]: (state, { payload }) => state.set('youtubePlayerActiveNewsId', payload),
  [NewsActions.setTiktokPlayerActiveNewsId]: (state, { payload }) => state.set('tiktokPlayerActiveNewsId', payload),

  [NewsActions.translateRequestStart]: (state, { payload: { news } }) => state.set('translateRunningRequestForNewsId', news.get('id')),
  [NewsActions.translateRequestSuccess]: state => state.set('translateRunningRequestForNewsId', null),

  [NewsActions.showAiAnalysisDialog]: (state, { payload: newsId }) => state.set('selectedNewsIdForAiAnalysis', newsId),

  [ConfigActions.setConfig]: (state, { payload: config }) => {
    if (config.defaultNewsPageView === 'list_view') {
      return state.set('showNewsList', true)
    }

    return state
  },

  [AppActions.resetState]: () => initialState
}, initialState)
