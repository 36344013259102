import { connect } from 'react-redux'

import { Capabilities } from 'static/constants'

import Tabs from 'components/content_desk_new/content_header/tabs'

import {
  changeContentTabView,
  resetFilters,
  search,
  setContentFilters,
  setKpiStatisticsFilters,
  fetchKpiStatisticsStart
} from 'actions/content_desk'

import {
  getCDeskContentTabView,
  getCDeskRequestRunning,
  hasCapability
} from 'selectors'

const mapStateToProps = state => ({
  hasLinkedIn: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_LINKED_IN),
  hasFacebook: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_FACEBOOK),
  hasOtsPr: !!hasCapability(state, Capabilities.HAS_CONTENT_DESK_OTS_PR),
  requestRunning: getCDeskRequestRunning(state),
  view: getCDeskContentTabView(state)
})

export default connect(
  mapStateToProps,
  {
    changeContentTabView,
    fetchKpiStatistics: fetchKpiStatisticsStart,
    resetFilters,
    setContentFilters,
    search,
    setKpiFilters: setKpiStatisticsFilters
  }
)(Tabs)
