import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import CancelIcon from '@mui/icons-material/Cancel'
import classnames from 'classnames'
import { emphasize, useTheme } from '@mui/material/styles'
import { Typography, TextField, Paper, Chip, MenuItem } from '@mui/material'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
    height: 250
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 200
  },
  chip: {
    margin: theme.spacing(0.5, 0.25)
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 300,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing(2)
  },
  outlinedValueContainer: {
    minHeight: 51,
    paddingTop: 5,
    paddingLeft: 5
  },
  outlinedValueContainerSingle: {
    paddingLeft: 14
  }
}))

const NoOptionsMessage = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
)

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.any
}

const InputComp = ({ innerRef, inputRef: _inputRef, ...props }) => (
  <div
    ref={innerRef}
    {...props}
  />
)

InputComp.propTypes = {
  innerRef: PropTypes.any,
  inputRef: PropTypes.any
}

const InputCompWrapper = (props, ref) => (
  <InputComp
    innerRef={ref}
    {...props}
  />
)

const InputComponent = forwardRef(InputCompWrapper)

const Control = props => {
  const textFieldProps = { ...props.selectProps.textFieldProps }
  const InputLabelProps = { ...textFieldProps.InputLabelProps }
  InputLabelProps.shrink = InputLabelProps.shrink === undefined ? props.hasValue || undefined : InputLabelProps.shrink
  textFieldProps.label = textFieldProps.label || props.selectProps.label
  textFieldProps.required = textFieldProps.required || props.selectProps.required
  textFieldProps.InputLabelProps = InputLabelProps

  return (
    <TextField
      fullWidth
      variant={props.selectProps.variant}
      disabled={props.selectProps.isDisabled}
      InputProps={{
        sx: props.selectProps.sx,
        inputComponent: InputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      {...textFieldProps}
    />
  )
}

Control.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  innerRef: PropTypes.any,
  children: PropTypes.any,
  hasValue: PropTypes.bool
}

const Option = props => {
  const option = props.options.find(o => o.value === props.value)

  let style = {
    fontWeight: props.isSelected ? 500 : 400
  }

  if (option && option.style) {
    style = { ...style, ...option.style }
  }

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      title={option && option.title ? option.title : undefined}
      component="div"
      style={style}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.any,
  isSelected: PropTypes.bool,
  isFocused: PropTypes.bool,
  children: PropTypes.any,
  options: PropTypes.array,
  value: PropTypes.any
}

const Placeholder = props => (
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
)

Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.any
}

const SingleValue = props => (
  <Typography
    className={props.selectProps.classes.singleValue}
    title={props.data && props.data.title ? props.data.title : undefined}
    {...props.innerProps}
  >
    {props.children}
  </Typography>
)

SingleValue.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  data: PropTypes.object,
  children: PropTypes.any
}

const ValueContainer = props => {
  const { classes } = props.selectProps

  const variant = props.selectProps.variant || 'outlined'

  return (
    <div className={classnames(
      classes.valueContainer,
      variant === 'outlined' && classes.outlinedValueContainer,
      variant === 'outlined' && !props.selectProps.isMulti && classes.outlinedValueContainerSingle
    )}
    >
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  selectProps: PropTypes.object,
  children: PropTypes.any
}

const MultiValue = props => {
  if (props.selectProps.hideValues) {
    return <div />
  }

  const children = props.children || ''
  let label = children.slice(0, 30)

  if (label.length < children.length) {
    label = `${label}...`
  }

  if (props.selectProps.renderValue) {
    return props.selectProps.renderValue(props.data)
  }

  return (
    <Chip
      disabled={props.selectProps.isDisabled}
      tabIndex={-1}
      title={props.children}
      label={label}
      className={classnames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  selectProps: PropTypes.object,
  data: PropTypes.object,
  removeProps: PropTypes.object,
  isFocused: PropTypes.bool,
  children: PropTypes.any,
  hideValues: PropTypes.bool
}

const Menu = props => (
  <Paper
    square
    className={props.selectProps.classes.paper}
    {...props.innerProps}
  >
    {props.children}
  </Paper>
)

Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.any
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
}

const noOptionsMessage = () => '-'

const MuiAutocomplete = ({ creatable, disableOptionsSelect, ...props }) => {
  const { classes } = useStyles()
  const theme = useTheme()

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    }),
    indicatorsContainer: base => ({
      ...base,
      cursor: 'pointer'
    })
  }

  let Comp = Select

  if (creatable) {
    Comp = CreatableSelect
  }

  let newComponents = components

  if (disableOptionsSelect) {
    newComponents = {
      ...components,
      Menu: () => null,
      DropdownIndicator: () => null,
      IndicatorsContainer: () => null
    }
  }

  return (
    <Comp
      closeMenuOnSelect={!props.isMulti}
      {...props}
      classes={classes}
      styles={selectStyles}
      components={newComponents}
      noOptionsMessage={noOptionsMessage}
    />
  )
}

MuiAutocomplete.propTypes = {
  isMulti: PropTypes.bool,
  creatable: PropTypes.bool,
  disableOptionsSelect: PropTypes.bool
}

export default MuiAutocomplete
