import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'components/darknet/charts/chart'
import GridItem from 'components/darknet/grid_item/GridItem'

export default function ScoreResultTimelineChart({ charts, size }) {
  return (
    charts.map((chart, index) => (
      <GridItem
        size={size}
        key={index}
      >
        <Chart
          chart={chart}
        />
      </GridItem>
    ))
  )
}

ScoreResultTimelineChart.defaultProps = {
  size: 6
}

ScoreResultTimelineChart.propTypes = {
  charts: PropTypes.any,
  size: PropTypes.number
}
