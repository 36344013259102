import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, List } from 'immutable'
import { Chip, Box, Divider, Typography } from '@mui/material'

function MediaTypeChip({ label }) {
  return (
    <Box
      mr={1}
      my={1}
      display="inline-block"
    >
      <Chip
        px={2}
        size="small"
        label={label}
      />
    </Box>
  )
}

export default function ContactMediaTypes({ contact, mediaTypes }) {
  const types = contact.get('mediaTypes')
  const i18n = useI18n()

  if (!types || types.isEmpty()) {
    return null
  }

  const ids = types.map(t => t.get('id'))
  const typesWithNames = mediaTypes.filter(mt => ids.includes(mt.get('id')))

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Typography
        variant="subtitle2"
        pb={2}
        sx={{ fontSize: '90%' }}
      >{i18n.get('media_type')}
      </Typography>
      <Box>
        {typesWithNames.map(type => (
          <MediaTypeChip
            key={`type__${type.get('id')}`}
            label={type.get('name')}
          />
        ))}
      </Box>
    </>
  )
}

ContactMediaTypes.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  mediaTypes: PropTypes.instanceOf(List).isRequired
}

MediaTypeChip.propTypes = {
  label: PropTypes.string.isRequired
}
