import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/page_identities'
import * as IdentitySetActions from 'actions/identity_sets'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  total: 0,
  pageIdentities: [],
  inactivePageIdentities: []
})

export default handleActions({
  [Actions.pageIdentitiesRequestSuccess]: (state, { payload }) => {
    const { pageIdentities, inactivePageIdentities, total } = payload

    return state.merge({
      pageIdentities: fromJS(pageIdentities),
      inactivePageIdentities: fromJS(inactivePageIdentities),
      total
    })
  },

  [IdentitySetActions.addIdentityToIdentitySetSuccess]: (state, { payload: { identitySet, identityId } }) => (
    state.update('pageIdentities', pageIdentities => pageIdentities.map(pageIdentity => {
      if (pageIdentity.getIn(['pageIdentity', 'id']) === identityId) {
        return pageIdentity
          .updateIn(['pageIdentity', 'identitySetIds'], ids => ids.push(identitySet.get('id')))
      }

      return pageIdentity
    }))
  ),

  [IdentitySetActions.removeIdentityFromIdentitySetSuccess]: (state, { payload: { identitySet, identityId } }) => (
    state.update('pageIdentities', pageIdentities => pageIdentities.map(pageIdentity => {
      if (pageIdentity.getIn(['pageIdentity', 'id']) === identityId) {
        return pageIdentity
          .updateIn(['pageIdentity', 'identitySetIds'], ids => ids.filter(id => id !== identitySet.get('id')))
      }

      return pageIdentity
    }))
  ),

  [IdentitySetActions.deleteIdentitySetSuccess]: (state, { payload: identitySetId }) => (
    state.update('pageIdentities', pageIdentities => pageIdentities.map(pageIdentity => (
      pageIdentity
        .updateIn(['pageIdentity', 'identitySetIds'], ids => ids.filter(id => id !== identitySetId))
    )))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
