import React from 'react'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import NewsListItem from 'containers/shopping_cart/NewsListItem'

import Toolbar from 'containers/shopping_cart/Toolbar'
import { UNGROUPED } from 'components/shopping_cart/enum'
import MultisortNewsList from 'components/sortable/multisort_news_list'

import { List } from '@mui/material'

export default function NewsList({
  news,
  onSortEnd,
  onReset,
  selectedNews
}) {
  return (
    <div>
      <Toolbar tab={UNGROUPED} />

      <List>
        <MultisortNewsList
          news={news}
          onSortEnd={onSortEnd}
          onReset={onReset}
          selectedNews={selectedNews}
          NewsComponent={NewsListItem}
        />
      </List>
    </div>
  )
}

NewsList.propTypes = {
  news: PropTypes.object.isRequired,
  selectedNews: PropTypes.instanceOf(Set).isRequired,

  onSortEnd: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}
