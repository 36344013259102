import { connect } from 'react-redux'

import MiscSettings from 'components/personal_settings/misc_settings'
import { setDarkMode, setField, updateUserRequestStart } from 'actions/user'

import { getUser } from 'selectors'

const mapStateToProps = state => ({
  config: getUser(state)
})

export default connect(
  mapStateToProps,
  {
    onDarkModeChange: setDarkMode,
    onChange: setField,
    onSave: updateUserRequestStart
  }
)(MiscSettings)
