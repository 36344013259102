import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import camelcase from 'camelcase'

import { makeStyles } from 'tss-react/mui'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import { green, purple, grey, orange } from '@mui/material/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import VisibilityIcon from '@mui/icons-material/Visibility'
import NotesIcon from '@mui/icons-material/Notes'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { IconButton } from 'containers/themed'

import ContentTitle from 'containers/content_desk_new/contents/content/ContentTitle'
import ContentApprovalStatusSelect from 'containers/content_desk_new/contents/content/ContentApprovalStatusSelect'
import ContentStatus from 'containers/content_desk_new/contents/content/ContentStatus'

const useStyles = makeStyles()(() => ({
  summaryHeader: {
    width: '95%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  icon: {
    fontSize: 20,
    margin: '-2px'
  },
  draft: {
    borderLeft: '5px solid',
    borderColor: grey[500]
  },
  reviewPending: {
    borderLeft: '5px solid',
    borderColor: purple[500]
  },
  revisionsNeeded: {
    borderLeft: '5px solid',
    borderColor: orange[500]
  },
  approved: {
    borderLeft: '5px solid',
    borderColor: green[500]
  },
  detailsContainer: {
    marginTop: 10,
    fontSize: 12
  },
  label: {
    fontWeight: 600
  }
}))

function ColumnFooter({ text }) {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{ color: theme => !theme.isDark && '#757575', fontSize: 12 }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const MobileContent = ({
  content,
  showCampaigns,
  onEditClick,
  onViewClick
}) => {
  const i18n = useI18n()
  const device = useDevice()
  const { classes } = useStyles()
  const editor = `${content.getIn(['lastEditor', 'firstName'])} ${content.getIn(['lastEditor', 'lastName'])}`
  const lastEditedDate = moment(content.get('updatedAt')).format('lll')
  const status = camelcase(content.get('approvalStatus'))
  const statusClass = classes[status]
  let maxLength = 24

  if (device.get('sm') || device.get('gt-sm')) {
    maxLength = 50
  }

  const handleNotes = () => onEditClick({ id: content.get('id'), openContentNotesDialog: true })

  return (
    <Accordion className={statusClass}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box className={device.get('sm') ? classes.summaryHeader : null}>
          <ContentTitle
            content={content}
            maxLength={maxLength}
          />
          {device.get('sm') && (
            <ContentStatus content={content} />
          )}
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails className={classes.detailsContainer}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('last_edited')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            {editor}
            <ColumnFooter text={lastEditedDate} />
          </Grid>
          {showCampaigns && (
            <>
              <Grid
                item
                xs={6}
                className={classes.label}
              >
                {i18n.get('campaign')}:
              </Grid>
              <Grid
                item
                xs={6}
              >
                {content.getIn(['ccdCampaign', 'name'])}
              </Grid>
            </>
          )}
          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('editing_status')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            <ContentApprovalStatusSelect content={content} />
          </Grid>
          <Grid
            item
            xs={6}
            className={classes.label}
          >
            {i18n.get('sending_status')}:
          </Grid>
          <Grid
            item
            xs={6}
          >
            <ContentStatus
              mode="short"
              content={content}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={6}
              className={classes.label}
            >
              {i18n.get('actions')}:
            </Grid>
            <Grid
              item
              xs={6}
            >
              <IconButton onClick={() => onViewClick(content)}>
                <VisibilityIcon />
              </IconButton>
              <IconButton onClick={handleNotes}>
                <NotesIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

MobileContent.defaultProps = {
  showCampaigns: true
}

MobileContent.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  showCampaigns: PropTypes.bool,
  onEditClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired
}

ColumnFooter.propTypes = {
  text: PropTypes.string
}

export default MobileContent
