import {
  put,
  takeEvery,
  all,
  call,
  take,
  select
} from 'redux-saga/effects'

import moment from 'moment'

import * as Actions from 'actions/themax'
import * as Selectors from 'selectors'
import * as Api from 'api/bff'

export function* themaxSearchEditorialPlans() {
  try {
    const themaxFilters = yield select(Selectors.getThemaxFilters)
    const timezone = yield select(Selectors.getTimezone)
    const page = themaxFilters.get('editorialPlansPage')
    const publicationFilter = yield select(Selectors.getThemaxPublicationsFilter)
    const editorialPlanFilter = yield select(Selectors.getThemaxEditorialPlansFilter)

    const body = {
      publications: publicationFilter,
      editorial_plans: editorialPlanFilter,
      limit: themaxFilters.get('limit'),
      sort_by: themaxFilters.getIn(['editorialPlansSorting', 'sortBy']),
      sort_order: themaxFilters.getIn(['editorialPlansSorting', 'sortOrder']),
      page,
      timezone
    }
    const response = yield call(Api.searchThemaxEditorialPlans, body)
    yield put(Actions.themaxSearchEditorialPlansSuccess({ response, page }))
    yield put(Actions.themaxSearchRequestSuccess())
  } catch (error) {
    yield put(Actions.themaxSearchRequestError(error))
  }
}

export function* themaxFetchRelatedEditorialPlans() {
  try {
    const selectedEditorialPlan = yield select(Selectors.getSelectedEditorialPlan)
    const themaxFilters = yield select(Selectors.getThemaxFilters)
    const timezone = yield select(Selectors.getTimezone)
    const page = themaxFilters.get('relatedEditorialPlansPage')

    const body = {
      publications: {
        ids: [selectedEditorialPlan.getIn(['publication', 'id'])]
      },
      editorial_plans: {
        editorial_deadline_from: moment().subtract(2, 'years').toDate()
      },
      limit: themaxFilters.get('limit'),
      sort_by: 'editorial_deadline',
      sort_order: 'desc',
      page,
      timezone
    }
    const response = yield call(Api.searchThemaxEditorialPlans, body)
    yield put(Actions.themaxFetchRelatedEditorialPlansSuccess({ response, page }))
  } catch (error) {
    yield put(Actions.themaxFetchRelatedEditorialPlansError(error))
  }
}

export function* themaxSearchPublications() {
  try {
    const themaxFilters = yield select(Selectors.getThemaxFilters)
    const timezone = yield select(Selectors.getTimezone)
    const page = themaxFilters.get('publicationsPage')
    const publicationFilter = yield select(Selectors.getThemaxPublicationsFilter)
    const editorialPlanFilter = yield select(Selectors.getThemaxEditorialPlansFilter)

    const body = {
      publications: publicationFilter,
      editorial_plans: editorialPlanFilter,
      limit: themaxFilters.get('limit'),
      sort_by: themaxFilters.getIn(['publicationsSorting', 'sortBy']),
      sort_order: themaxFilters.getIn(['publicationsSorting', 'sortOrder']),
      page,
      timezone
    }

    const response = yield call(Api.searchThemaxPublications, body)
    take(Actions.themaxSearchRequestSuccess)
    yield put(Actions.themaxSearchPublicationsSuccess({ response, page }))
    take(Actions.themaxSearchEditorialPlansSuccess)
    yield put(Actions.themaxSearchRequestSuccess())
  } catch (error) {
    yield put(Actions.themaxSearchRequestError(error))
  }
}

export function* themaxSearchRequestStart() {
  yield put(Actions.setThemaxPage({ pageValue: 1, pageType: 'editorialPlansPage' }))
  yield put(Actions.setThemaxPage({ pageValue: 1, pageType: 'publicationsPage' }))
  yield put(Actions.themaxSearchEditorialPlans())
  yield put(Actions.themaxSearchPublications())
}

export function* fetchMoreEditorialPlans() {
  const themaxFilters = yield select(Selectors.getThemaxFilters)
  const page = themaxFilters.get('editorialPlansPage') + 1
  yield put(Actions.setThemaxPage({ pageValue: page, pageType: 'editorialPlansPage' }))
  yield put(Actions.themaxSearchEditorialPlans())
}

export function* fetchMoreRelatedEditorialPlans() {
  const themaxFilters = yield select(Selectors.getThemaxFilters)
  const page = themaxFilters.get('relatedEditorialPlansPage') + 1
  yield put(Actions.setThemaxPage({ pageValue: page, pageType: 'relatedEditorialPlansPage' }))
  yield put(Actions.themaxFetchRelatedEditorialPlansStart())
}

export function* fetchMorePublications() {
  const themaxFilters = yield select(Selectors.getThemaxFilters)
  const page = themaxFilters.get('publicationsPage') + 1
  yield put(Actions.setThemaxPage({ pageValue: page, pageType: 'publicationsPage' }))
  yield put(Actions.themaxSearchPublications())
}

export function* updateThemaxSort({ payload }) {
  yield put(Actions.setThemaxSort(payload))
  yield put(Actions.themaxSearchRequestStart())
}

export function* watchThemaxSearchRequestStart() {
  yield takeEvery(Actions.themaxSearchRequestStart, themaxSearchRequestStart)
}

export function* watchThemaxSearchEditorialPlans() {
  yield takeEvery(Actions.themaxSearchEditorialPlans, themaxSearchEditorialPlans)
}

export function* watchThemaxFetchRelatedEditorialPlans() {
  yield takeEvery(Actions.themaxFetchRelatedEditorialPlansStart, themaxFetchRelatedEditorialPlans)
}

export function* watchThemaxSearchPublications() {
  yield takeEvery(Actions.themaxSearchPublications, themaxSearchPublications)
}

export function* watchThemaxFetchMoreEditorialPlansStart() {
  yield takeEvery(Actions.themaxFetchMoreEditorialPlansStart, fetchMoreEditorialPlans)
}

export function* watchThemaxFetchMoreRelatedEditorialPlansStart() {
  yield takeEvery(Actions.themaxFetchMoreRelatedEditorialPlansStart, fetchMoreRelatedEditorialPlans)
}

export function* watchThemaxFetchMorePublicationsStart() {
  yield takeEvery(Actions.themaxFetchMorePublicationsStart, fetchMorePublications)
}

export function* watchUpdateThemaxSort() {
  yield takeEvery(Actions.updateThemaxSort, updateThemaxSort)
}

export default function* searchSaga() {
  yield all([
    watchThemaxSearchRequestStart(),
    watchThemaxSearchPublications(),
    watchThemaxSearchEditorialPlans(),
    watchThemaxFetchRelatedEditorialPlans(),
    watchThemaxFetchMoreEditorialPlansStart(),
    watchThemaxFetchMoreRelatedEditorialPlansStart(),
    watchThemaxFetchMorePublicationsStart(),
    watchUpdateThemaxSort()
  ])
}
