import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  }
}))

const DeleteFileDialog = ({
  open,
  contentFormData,
  mediaFileId,
  closeDeleteDialog,
  onChange,
  onMediaDelete
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')

  const handleCloseDeleteDialog = () => closeDeleteDialog()

  const handleDeleteMedia = () => {
    onMediaDelete({ id: mediaFileId })
    onChange({
      key: 'ccdMediaFiles',
      value: currentSelectedMediaFiles.filter(selected => selected.get('id') !== mediaFileId)
    })
    handleCloseDeleteDialog()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDeleteDialog}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('are_you_sure')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleCloseDeleteDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {i18n.get('delete_file_text')}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseDeleteDialog}
        >
          {i18n.get('close')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteMedia}
        >
          {i18n.get('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteFileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  mediaFileId: PropTypes.number,

  closeDeleteDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onMediaDelete: PropTypes.func.isRequired
}

export default DeleteFileDialog
