/* global FormData */
import { getBffUrl as bffUrlSelector } from 'selectors'
import store from '../store'

import * as Api from '../utils/api'

const getBffUrl = () => bffUrlSelector(store.getState())

export const fetchContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/search`,
  body
)

export const searchContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/search/global`,
  body
)

export const createContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts`,
  body
)

export const updateContacts = (id, body) => Api.patch(
  `${getBffUrl()}/contacts_management/contacts/${id}`,
  body
)

export const deleteContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/contacts/delete`,
  body
)

export const fetchDistributionLists = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/search`,
  body
)

export const createDistributionList = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists`,
  body
)

export const updateDistributionList = (id, body) => Api.patch(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}`,
  body
)

export const deleteDistributionList = id => Api.destroy(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}`
)

export const addContactsToDistributionList = (id, body) => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}/add_contacts`, body
)

export const removeContactFromList = (id, contactId) => Api.destroy(
  `${getBffUrl()}/contacts_management/distribution_lists/${id}/remove_contact/${contactId}`
)

export const uploadContacts = data => {
  const formData = new FormData()
  data.forEach(({ key, value }) => formData.append(key, value))

  return Api.form(
    `${getBffUrl()}/contacts_management/contacts/upload_contacts`, formData
  )
}

export const downloadContactsExcel = data => (
  Api.post(
    `${getBffUrl()}/contacts_management/contacts/excel`, data, 'blob'
  )
)

export const downloadDistributionList = data => (
  Api.post(
    `${getBffUrl()}/contacts_management/distribution_lists/excel`, data, 'blob'
  )
)

export const createContactTag = body => Api.post(
  `${getBffUrl()}/contacts_management/tags`,
  body
)

export const renameContactTag = (id, name) => Api.patch(
  `${getBffUrl()}/contacts_management/tags/${id}`,
  { name }
)

export const deleteContactTag = id => Api.destroy(
  `${getBffUrl()}/contacts_management/tags/${id}`
)

export const addTagToContact = (id, body) => Api.post(
  `${getBffUrl()}/contacts_management/contacts/${id}/add_tag`,
  body
)

export const removeTagFromContact = (id, tagId) => Api.destroy(
  `${getBffUrl()}/contacts_management/contacts/${id}/remove_tag/${tagId}`
)

export const searchTags = body => Api.post(
  `${getBffUrl()}/contacts_management/tags/search`,
  body
)

export const searchAnewstipJournalists = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/journalists`, body
)

export const searchAnewstipOutlets = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/outlets`, body
)

export const searchAnewstipArticles = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/search/articles`, body
)

export const fetchAnewstipStatics = () => Api.get(
  `${getBffUrl()}/contacts_management/anewstip/statics`
)

export const searchAnewstipSuggest = body => Api.post(
  `${getBffUrl()}/contacts_management/anewstip/suggest`, body
)

export const importAnewstipContacts = body => Api.post(
  `${getBffUrl()}/contacts_management/import/anewstip`, body
)

export const replaceContact = body => Api.post(
  `${getBffUrl()}/contacts_management/import/anewstip/replace`, body
)

export const mergeDistributionLists = body => Api.post(
  `${getBffUrl()}/contacts_management/distribution_lists/merge`, body
)
