import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'

export const Item = ({ id, items, Component, ComponentProps, ...props }, ref) => {
  const item = items.find(i => `${i.get('id')}` === id)

  return (
    <div
      {...props}
      ref={ref}
    >
      {!!item && (
        <Component
          item={item}
          {...ComponentProps}
        />
      )}
    </div>
  )
}

export default forwardRef(Item)

Item.propTypes = {
  id: PropTypes.string.isRequired,
  items: PropTypes.instanceOf(List).isRequired,
  Component: PropTypes.any.isRequired,
  ComponentProps: PropTypes.object
}
