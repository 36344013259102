import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  journalists: []
})

export default handleActions({
  [Actions.setSelectedResults]: (state, { payload: { key, value } }) => state.set(key, value),
  [combineActions(
    Actions.searchJournalistsStart,
    Actions.clearModuleData
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
