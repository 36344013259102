import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'components/darknet/charts/chart'
import { addStylesToChart } from 'utils/darknet'
import GridItem from 'components/darknet/grid_item/GridItem'
import { useTheme } from '@mui/material/styles'

const opts = theme => ({
  backgroundColor: theme.palette.background.paper
})

export default function AvgHackishnessChart({ chart, size }) {
  const theme = useTheme()
  const chartWithStyles = addStylesToChart(chart, {}, opts(theme))

  return (
    <GridItem size={size}>
      <Chart
        chart={chartWithStyles}
      />
    </GridItem>
  )
}

AvgHackishnessChart.defaultProps = {
  size: 2
}

AvgHackishnessChart.propTypes = {
  chart: PropTypes.any,
  size: PropTypes.number
}
