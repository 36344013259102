import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

import {
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery
} from '@mui/material'

const useStyles = makeStyles()(theme => ({
  container: {
    height: '100%',
    backgroundColor: theme.nrx.paper.backgroundPaperColor,
    border: '1px solid #D1D5DC',
    borderRight: 'none',
    borderRadius: '5px 0px 0px 5px'
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    padding: '20px 20px 10px 20px'
  },
  eventOptions: {
    paddingLeft: '30px'
  },
  toggleGroup: {
    width: '100%',
    marginBottom: '20px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '15px',
      paddingRight: '15px',
      marginBottom: '0px'
    }
  },
  toggleButton: {
    width: '50%'
  }
}))

const Sidebar = ({ filters, setFilters, applyFilters, setSorting }) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))

  const bookmarkedOnly = filters.getIn(['booleans', 'bookmarkedOnly'])
  const calendarOption = bookmarkedOnly ? 'bookmarked_editorial_plans' : 'top_editorial_plans'

  const handleOptionChange = value => {
    if (value === 'top_editorial_plans') {
      setSorting({ sortBy: 'reach', sortOrder: 'desc', sortType: 'editorialPlansSorting' })
      setFilters({
        booleans: filters.setIn(['booleans', 'bookmarkedOnly'], false).get('booleans'),
        reachFilter: filters.get('reachFilter'),
        pageAveFilter: filters.get('pageAveFilter')
      })
    }

    if (value === 'bookmarked_editorial_plans') {
      setSorting({ sortBy: 'editorial_deadline', sortOrder: 'asc', sortType: 'editorialPlansSorting' })
      setFilters({
        booleans: filters.setIn(['booleans', 'bookmarkedOnly'], true).get('booleans'),
        reachFilter: filters.get('reachFilter'),
        pageAveFilter: filters.get('pageAveFilter')
      })
    }

    applyFilters()
  }

  return (
    <>
      {isMedium && (
        <ToggleButtonGroup
          className={classes.toggleGroup}
          color="primary"
          value={calendarOption}
          exclusive
          onChange={e => handleOptionChange(e.target.value)}
        >
          <ToggleButton
            className={classes.toggleButton}
            value="top_editorial_plans"
          >
            {`${i18n.get('themax_top_by_reach', { n: 100 })}`}
          </ToggleButton>
          <ToggleButton
            className={classes.toggleButton}
            value="bookmarked_editorial_plans"
          >
            {i18n.get('bookmarked')}
          </ToggleButton>
        </ToggleButtonGroup>
      )}
      {!isMedium && (
        <Box className={classes.container}>
          <Box className={classes.title}>
            <Typography>{i18n.get('events')}</Typography>
          </Box>
          <RadioGroup
            className={classes.eventOptions}
            name="event_options"
            value={calendarOption}
            onChange={e => handleOptionChange(e.target.value)}
          >
            <FormControlLabel
              label={`${i18n.get('themax_top_by_reach', { n: 100 })}`}
              value="top_editorial_plans"
              control={<Radio size="small" />}
            />
            <FormControlLabel
              label={i18n.get('bookmarked')}
              value="bookmarked_editorial_plans"
              control={<Radio size="small" />}
            />
          </RadioGroup>
        </Box>
      )}
    </>
  )
}

export default Sidebar

Sidebar.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,

  setFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired
}
