import moment from 'moment'
import { fromJS } from 'immutable'
import {
  darknetChartsConfigs,
  DarknetCharts,
  docCountSum,
  sortByDocCount,
  oneDayRange
} from 'utils/darknet'
import { capitalize } from 'utils/string'

const round3 = number => Math.round(number * 1e3) / 1e3
const defaultIterator = n => (n.pairs ? {
  name: n.name ? capitalize(n.name) : n.key,
  id: n.key,
  pairs: n.pairs,
  count: n.docCount,
  stats: { buzz: n.docCount }
} : {
  name: n.name ? capitalize(n.name) : n.key,
  id: n.key,
  count: n.docCount,
  stats: { buzz: n.docCount }
})

const defaultTimelineElement = d => ({
  range: oneDayRange(d.keyAsString),
  name: moment(d.keyAsString).format('DD. MMM'),
  interval: 'day',
  id: d.key,
  count: d.docCount
})

const defaultRectangleData = d => ({
  current: d.current,
  previous: d.previous
})

const formatNetworksChartData = data => data.map(defaultIterator)
const formatLanguagesChartData = data => {
  const sum = docCountSum(data)
  const byLowerBound = d => d.docCount > ((0.33 / 100) * sum)
  const filteredData = data.filter(byLowerBound)

  const sortedData = sortByDocCount(filteredData)

  let resultData = sortedData

  if (sortedData.length > 6) {
    const visibleData = sortedData.slice(0, 6)
    const hiddenData = sortedData.slice(6)

    const docCount = docCountSum(hiddenData)
    visibleData.push({
      key: 'language_other',
      pairs: hiddenData.map(hd => ({
        name: capitalize(hd.name),
        key: hd.key
      })),
      docCount
    })

    resultData = visibleData
  }

  return resultData.map(defaultIterator)
}

const formatAvgHackishnessNetworksChartData = data => (
  data.map(d => ({
    name: d.key,
    id: d.key,
    count: d.hackishness.value,
    stats: {
      buzz: Math.round(round3(d.hackishness.value) * 100)
    }
  }))
)

const formatTotalChartData = data => defaultRectangleData(data)

const formatMentionsTimelineChartData = data => data.map(d => ({
  ...defaultTimelineElement(d),
  stats: {
    mentions: [
      {
        count: d.docCount,
        name: 'mentions',
        id: 'mentions',
        stats: {
          buzz: d.docCount
        }
      }
    ],
    buzz: d.docCount
  }
}))

const formatNetworksTimelineChartData = data => (
  data.map(d => ({
    ...defaultTimelineElement(d),
    stats: {
      buzz: d.docCount,
      networks: d.networks.buckets.map(n => ({
        count: n.docCount,
        name: n.key,
        id: n.key,
        stats: {
          buzz: n.docCount
        }
      }))
    }
  }))
)

const formatSearchQueriesTimelineChartData = data => (
  data.map(d => ({
    ...defaultTimelineElement(d),
    stats: {
      buzz: d.docCount,
      searchQueries: d.searchQueries.buckets.map(n => ({
        count: n.docCount,
        name: n.name,
        id: n.key,
        stats: {
          buzz: n.docCount
        }
      }))
    }
  }))
)

const formatAvgHackishnessChartData = data => defaultRectangleData({
  current: Math.round(data.current * 100),
  previous: Math.round(data.previous * 100)
})

const formatAvgHackishnessTimelineChartData = data => (
  data.map(d => ({
    ...defaultTimelineElement(d),
    count: round3(d.hackishness.value),
    stats: {
      buzz: round3(d.hackishness.value) * 100,
      hackishness: [{
        name: 'hackishness',
        count: round3(d.hackishness.value),
        id: 'hackishness',
        stats: {
          buzz: round3(d.hackishness.value) * 100
        }
      }]
    }
  }))
)

const formatScoreResultTimelineChartData = data => (
  data.map(d => ({
    ...defaultTimelineElement({ ...d[0], key: d[0].date, keyAsString: d[0].date }),
    count: round3(d[0].score),
    stats: {
      buzz: round3(d[0].score),
      queries: d.map(q => ({
        name: q.scoreQueryName,
        id: q.scoreQueryName,
        count: round3(q.score),
        stats: {
          buzz: round3(q.score)
        }
      }))
    }
  }))
)

export const getChartData = (chartId, rawData) => {
  let data

  switch (chartId) {
    case DarknetCharts.LANGUAGES: data = formatLanguagesChartData(rawData); break
    case DarknetCharts.NETWORKS: data = formatNetworksChartData(rawData); break
    case DarknetCharts.HACKISHNESS_NETWORKS: data = formatAvgHackishnessNetworksChartData(rawData); break
    case DarknetCharts.MENTIONS_TOTAL: data = formatTotalChartData(rawData); break
    case DarknetCharts.MENTIONS_TIMELINE: data = formatMentionsTimelineChartData(rawData); break
    case DarknetCharts.NETWORKS_TIMELINE: data = formatNetworksTimelineChartData(rawData); break
    case DarknetCharts.HACKISHNESS_TOTAL: data = formatAvgHackishnessChartData(rawData); break
    case DarknetCharts.HACKISHNESS_TIMELINE: data = formatAvgHackishnessTimelineChartData(rawData); break
    case DarknetCharts.SCORE_RESULT_TIMELINE: data = formatScoreResultTimelineChartData(rawData); break
    case DarknetCharts.SEARCH_QUERIES_TIMELINE: data = formatSearchQueriesTimelineChartData(rawData); break
    case DarknetCharts.EXTERNAL_SCORE_CHART: data = formatScoreResultTimelineChartData(rawData); break
    default: data = null
  }

  return darknetChartsConfigs.get(chartId).merge(fromJS({ data }))
}
