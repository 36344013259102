import { connect } from 'react-redux'

import SaveTemplateDialog from
  'components/content_desk_new/contents/content_edit_dialog/editor/email_editor/save_template_dialog/SaveTemplateDialog'

import {
  closeSaveTemplateDialog
} from 'actions/content_desk'

import {
  setTemplateFormData as onChange,
  saveTemplateStart as onSave
} from 'actions/email_templates_library'

import {
  getCDeskShowSaveTemplateDialog,
  getETLibrarySaveRequestRunning,
  getCapabilities
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowSaveTemplateDialog(state),
  loading: getETLibrarySaveRequestRunning(state),
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeSaveTemplateDialog,
    onChange,
    onSave
  }
)(SaveTemplateDialog)
