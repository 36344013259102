/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import AppBar from 'containers/AppBar'
import SearchBar from 'containers/darknet/SearchBar'
import Sorting from 'containers/darknet/Sorting'
import Actions from 'containers/darknet/DashboardActions'
import SearchResults from 'containers/darknet/SearchResults'
import ActiveFilterList from 'containers/darknet/ActiveFilterList'
import Navigation from 'containers/darknet/Navigation'
import DateDrawer from 'containers/darknet/DateDrawer'
import FilterDrawer from 'containers/darknet/FilterDrawer'
import DateFilterButton from 'containers/news_page/DateFilterButton'
import FilterButton from 'containers/news_page/FilterButton'
import StaticCharts from 'containers/darknet/StaticCharts'
import HelpDialog from 'containers/HelpDialog'

import TransitionComponent from 'components/transition_component'
import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  searchBar: {
    transition: 'width 150ms',
    width: '25%',
    [theme.breakpoints.only('md')]: {
      marginLeft: '1em',
      width: '25%'
    },
    [theme.breakpoints.down('md')]: {
      width: '45%',
      marginLeft: '1em'
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1em'
    }
  },
  root: {
    width: '100%'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexBasis: '100%',
    height: '80vh'
  },
  activeFilters: {
    marginTop: 15,
    marginLeft: 15
  },
  dateButton: {
    marginLeft: 10
  }
}))

export default function Dashboard({
  requestRunning,
  view,
  showAdvancedSearch,
  showDateForm,

  onMountSearch,
  onMountScores,
  toggleDateForm,
  toggleAdvancedSearch
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const device = useDevice()
  const [barStyle, setBarStyle] = useState(null)

  useEffect(() => {
    onMountSearch()
    onMountScores()
  }, [])

  const handleFocus = () => {
    let width = '100%'

    if (device.get('gt-md')) {
      width = '50%'
    }

    if (device.get('md')) {
      width = '40%'
    }

    setBarStyle({ width })
  }

  const handleBlur = () => {
    setTimeout(() => setBarStyle(null), 500)
  }

  let title

  if (device.get('gt-md')) {
    title = i18n.get('darknet_dashboard')
  }

  return (
    <>
      <TransitionComponent type="slideFadeDown">
        <AppBar
          title={title}
          actions={<Actions />}
        >
          <div
            style={barStyle}
            className={classes.searchBar}
          >
            <SearchBar
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </div>
          <FilterButton
            onClick={() => toggleAdvancedSearch()}
            color="secondary"
            active={showAdvancedSearch}
          />
          <div className={classes.dateButton}>
            <DateFilterButton
              onClick={() => toggleDateForm()}
              active={showDateForm}
            />
          </div>
        </AppBar>
        <FilterDrawer />
        <DateDrawer />
        <HelpDialog
          resultType="darknet"
        />
      </TransitionComponent>

      <div className={classes.activeFilters}>
        <ActiveFilterList />
      </div>

      <Navigation />

      {requestRunning ? (
        <div className={classes.progressBar}>
          <NrxProgressBar />
        </div>
      ) : (
        <>
          {view === 'statistics' && <StaticCharts />}
          {view === 'search_results' && (
            <>
              <Sorting />
              <SearchResults />
            </>
          )}
        </>
      )}
    </>
  )
}

Dashboard.propTypes = {
  requestRunning: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  showAdvancedSearch: PropTypes.bool.isRequired,
  showDateForm: PropTypes.bool.isRequired,

  onMountSearch: PropTypes.func.isRequired,
  onMountScores: PropTypes.func.isRequired,
  toggleDateForm: PropTypes.func.isRequired,
  toggleAdvancedSearch: PropTypes.func.isRequired
}
