import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

import { equals as stringEquals } from 'utils/string'
import SortableItem from '../sortable_item'
import Item from './item'

export default function SimpleSortableList({
  items,
  Component,
  ComponentProps,
  disabled,
  onSortEnd
}) {
  const [activeId, setActiveId] = useState(null)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  )

  const onDragStart = event => {
    const { active } = event

    setActiveId(active.id)
  }

  const onDragEnd = event => {
    const { active, over } = event

    if ((active && over) && active.id !== over.id) {
      const oldIndex = items.findIndex(i => stringEquals(i.get('id'), active.id))
      const newIndex = items.findIndex(i => stringEquals(i.get('id'), over.id))

      onSortEnd({ oldIndex, newIndex })
    }

    setActiveId(null)
  }

  const onDragCancel = () => {
    setActiveId(null)
  }

  const ids = items.map(i => `${i.get('id')}`).toJS()

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext
        items={ids}
        strategy={verticalListSortingStrategy}
      >
        {items.map(i => (
          <SortableItem
            key={`${i.get('id')}`}
            id={`${i.get('id')}`}
            disabled={disabled}
          >
            <div style={activeId && activeId === `${i.get('id')}` ? { visibility: 'hidden' } : null}>
              <Component
                item={i}
                {...ComponentProps}
              />
            </div>
          </SortableItem>
        ))}
      </SortableContext>

      <DragOverlay>
        {activeId ? (
          <Item
            id={activeId}
            Component={Component}
            ComponentProps={ComponentProps}
            items={items}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

SimpleSortableList.defaultProps = {
  ComponentProps: {},
  disabled: false
}

SimpleSortableList.propTypes = {
  items: PropTypes.instanceOf(List).isRequired,
  Component: PropTypes.any.isRequired,
  ComponentProps: PropTypes.object,
  disabled: PropTypes.bool,

  onSortEnd: PropTypes.func.isRequired
}
