import { fromJS } from 'immutable'

const EU_DOMAIN = 'content-desk.eu'
const US_DOMAIN = 'content-desk.com'
const EU = 'eu'
const US = 'us'

const DOMAIN_REGION_OPTIONS = [
  { value: 'us', label: 'US' },
  { value: 'eu', label: 'EU' }
]

const DEFAULT_DOMAINS = {
  us: US_DOMAIN,
  eu: EU_DOMAIN
}

const getRegionByTld = tld => (tld === 'com' ? US : EU)

export const getDomainRegionOptions = () => DOMAIN_REGION_OPTIONS
export const getDomainByRegion = region => DEFAULT_DOMAINS[region]
export const getDefaultDomains = () => fromJS([
  { domain: US_DOMAIN, domainRegion: US },
  { domain: EU_DOMAIN, domainRegion: EU }
])
export const getDefaultDomainByTld = tld => {
  const region = getRegionByTld(tld)

  return fromJS({
    domain: DEFAULT_DOMAINS[region],
    domainRegion: region
  })
}

