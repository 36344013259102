import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'

import AppBar from 'containers/AppBar'
import ContentContainer from 'components/content_container'
import SavedChart from 'containers/administration/saved_charts/SavedChart'

import useI18n from 'hooks/useI18n'

export default function SavedCharts({ savedCharts, onExpand }) {
  const [expandedId, setExpandedId] = useState(null)
  const i18n = useI18n()

  const handleExpand = id => {
    if (id === expandedId) {
      setExpandedId(null)
    } else {
      setExpandedId(id)
      onExpand({ id })
    }
  }

  return (
    <>
      <AppBar title={i18n.get('saved_charts')} />

      <ContentContainer>
        {savedCharts.map(savedChart => (
          <SavedChart
            key={savedChart.get('id')}
            expanded={savedChart.get('id') === expandedId}
            onExpand={handleExpand}
            savedChart={savedChart}
          />
        ))}
      </ContentContainer>
    </>
  )
}

SavedCharts.propTypes = {
  savedCharts: PropTypes.instanceOf(List).isRequired,

  onExpand: PropTypes.func.isRequired
}
