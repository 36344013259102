import { connect } from 'react-redux'

import ShoppingCartGroupedNewsListItem from 'components/shopping_cart/grouped_news_list_item'

import { toggleNewsList, selectNews } from 'actions/shopping_cart'
import {
  isShoppingCartGroupedNewsListSelected,
  getShoppingCartSelectedNews,
  getNewsClusterIdsOfCodeInShoppingCart
} from 'selectors'

const mapStateToProps = (state, { code }) => {
  const selectedNews = getShoppingCartSelectedNews(state)
  const selectedCount = selectedNews.filter(n => parseInt(n.split('_')[0], 10) === code.get('id')).size
  const newsClusterIds = getNewsClusterIdsOfCodeInShoppingCart(state, code)

  return ({
    selected: isShoppingCartGroupedNewsListSelected(state, code.get('id')) || selectedNews.isEmpty(),
    selectedCount: selectedNews.filter(n => parseInt(n.split('_')[0], 10) === code.get('id')).size,
    indeterminate: selectedCount > 0 && selectedCount !== newsClusterIds.size,
    newsClusterIds
  })
}

export default connect(
  mapStateToProps,
  {
    onSelect: selectNews,
    onToggle: toggleNewsList
  }
)(ShoppingCartGroupedNewsListItem)
