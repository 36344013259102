import { connect } from 'react-redux'

import NewsPage from 'components/news_page'
import {
  getI18n,
  getNewsView,
  getDevice,
  getAllowedNewsViews,
  isValidSearch,
  hasFilters
} from 'selectors'
import { invalidSearch } from 'actions/news'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  view: getNewsView(state),
  device: getDevice(state),
  allowedViews: getAllowedNewsViews(state),
  isValidSearch: isValidSearch(state),
  hasFilters: hasFilters(state)
})

export default connect(
  mapStateToProps,
  {
    onInvalidSearch: invalidSearch
  }
)(NewsPage)
