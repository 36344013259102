import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'
import { sortCharts, copyFromDisplaySize } from 'utils/charts'
import { isPressrelationsNewsChart } from 'utils/chart'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

export const initialState = fromJS({})

const newsFeedPageSize = 10

const updateFlagsInNewsFeedData = (chart, group, loading) => {
  if (group) {
    return chart.updateIn(['data', 'groups'], groups => groups.map(g => {
      if (g.get('id') === group.get('id')) {
        return g.set('loading', loading)
      }

      return g
    }))
  }

  return chart.setIn(['data', 'loading'], loading)
}

export default handleActions({
  [Actions.setSelectedDashboard]: (state, { payload: dashboard }) => {
    const newDashboard = sortCharts(fromJS(dashboard))

    return newDashboard
  },

  [Actions.setChartData]: (state, { payload: { index, breakpoint, data, chartError } }) => {
    const chart = state.getIn(['charts', breakpoint, index])

    if (chart) {
      return state.setIn(['charts', breakpoint, index], chart.merge({
        data: fromJS(data),
        loading: false,
        chartError
      }))
    }

    return state
  },

  [Actions.setChartLoading]: (state, { payload: { index, breakpoint, loading } }) => {
    const realIndex = index === null ? state.getIn(['charts', breakpoint]).size - 1 : index

    const chart = state.getIn(['charts', breakpoint, realIndex])

    if (chart) {
      return state.setIn(['charts', breakpoint, realIndex], chart.set('loading', loading !== undefined ? loading : true))
    }

    return state
  },

  [Actions.setChartOpt]: (state, { payload: { index, breakpoint, key, value } }) => {
    const realIndex = index === null ? state.getIn(['charts', breakpoint]).size - 1 : index
    const chart = state.getIn(['charts', breakpoint, realIndex])

    if (chart) {
      return state.setIn(['charts', breakpoint, realIndex], chart.update('opts', opts => (opts || Map({})).set(key, value)))
    }

    return state
  },

  [Actions.setChart]: (state, { payload: { chart, index, breakpoint } }) => {
    if (index === null) {
      const newLayouts = state.getIn(['layouts', breakpoint]).map(layout => layout.update('y', y => y + 4))

      return state.updateIn(['charts', breakpoint], charts => charts.push(chart))
        .setIn(['layouts', breakpoint], newLayouts.push(fromJS({ w: 12, h: 4, x: 0, y: 0 })))
    }

    return state.setIn(['charts', breakpoint, index], chart)
  },

  [Actions.loadMoreNewsFeedNewsStart]: (state, { payload: { index, breakpoint, group } }) => (
    state.updateIn(['charts', breakpoint, index], chart => updateFlagsInNewsFeedData(chart, group, true))
  ),

  [Actions.loadMoreNewsFeedNewsError]: (state, { payload: { index, breakpoint, group } }) => (
    state.updateIn(['charts', breakpoint, index], chart => updateFlagsInNewsFeedData(chart, group, false))
  ),

  [Actions.loadMoreNewsFeedNewsSuccess]: (state, { payload: { index, breakpoint, group, result } }) => {
    let newChart = state.getIn(['charts', breakpoint, index])

    if (group) {
      const groupId = group.get('id')
      const newNews = fromJS(result.groups).getIn([0, 'news'])

      if (newNews) {
        newChart = newChart
          .updateIn(['data', 'groups'], groups => groups.map(g => {
            if (g.get('id') === groupId) {
              return g
                .update('news', news => news.concat(newNews))
                .update('from', newsFeedPageSize, from => from + newsFeedPageSize)
            }

            return g
          }))
      }
    } else {
      const newNews = fromJS(result.news)

      if (newNews) {
        newChart = newChart.update('data', data => (
          data
            .update('news', news => news.concat(newNews))
            .update('from', newsFeedPageSize, from => from + newsFeedPageSize)
        ))
      }
    }

    return state.setIn(['charts', breakpoint, index], updateFlagsInNewsFeedData(newChart, group, false))
  },

  [Actions.fetchNewsFeedClusterStatsSuccess]: (state, { payload: { index, breakpoint, group, newNews } }) => {
    let newChart = state.getIn(['charts', breakpoint, index])

    if (group) {
      const groupId = group.get('id')

      newChart = newChart
        .updateIn(['data', 'groups'], groups => groups.map(g => {
          if (g.get('id') === groupId) {
            return g
              .update('news', news => news.map(n => (n.get('id') === newNews.get('id') ? newNews : n)))
          }

          return g
        }))
    } else {
      newChart = newChart.update('data', data => (
        data
          .update('news', news => news.map(n => (n.get('id') === newNews.get('id') ? newNews : n)))
      ))
    }

    return state.setIn(['charts', breakpoint, index], newChart)
  },

  [Actions.loadMoreSocialMediaAnalyticsFeedPostsStart]: (state, { payload: { index, breakpoint } }) => (
    state.updateIn(['charts', breakpoint, index], chart => chart.setIn(['data', 'loading'], true))
  ),

  [Actions.loadMoreSocialMediaAnalyticsFeedPostsError]: (state, { payload: { index, breakpoint } }) => (
    state.updateIn(['charts', breakpoint, index], chart => chart.setIn(['data', 'loading'], false))
  ),

  [Actions.loadMoreSocialMediaAnalyticsFeedPostsSuccess]: (state, { payload: { index, breakpoint, result } }) => {
    const newChart = state
      .getIn(['charts', breakpoint, index])
      .updateIn(['data', 'posts'], posts => posts.concat(fromJS(result.posts)))
      .setIn(['data', 'paging'], fromJS(result.paging))
      .setIn(['data', 'loading'], false)

    return state.setIn(['charts', breakpoint, index], newChart)
  },

  [Actions.removeChart]: (state, { payload: { breakpoint, index } }) => (
    state
      .deleteIn(['charts', breakpoint, index])
      .deleteIn(['layouts', breakpoint, index])
  ),

  [Actions.setLayouts]: (state, { payload: { breakpoint, layout } }) => {
    const newLayouts = fromJS(layout).map(s => fromJS({
      x: s.get('x'),
      y: s.get('y'),
      w: s.get('w'),
      h: s.get('h')
    }))

    return state.setIn(['layouts', breakpoint], newLayouts)
  },

  [Actions.deleteDashboardSuccess]: () => initialState,

  [Actions.copyFromDisplaySize]: (state, { payload: { from, to } }) => copyFromDisplaySize(state, from, to),

  [Actions.updateDateRangeOfSelectedDashboard]: (state, { payload: dateRange }) => (
    state
      .update(
        'charts',
        charts => charts.reduce(
          (acc, values, breakpoint) => acc.set(breakpoint, values.map(chart => chart.merge({
            dateRange,
            dateInterval: null
          }))), fromJS({})
        )
      )
  ),

  [Actions.updateKpisOfSelectedDashboard]: (state, { payload: kpi }) => (
    state
      .update(
        'charts',
        charts => charts.reduce(
          (acc, values, breakpoint) => acc.set(breakpoint, values.map(chart => {
            if (isPressrelationsNewsChart(chart)) {
              if (chart.get('type') === 'rectangle') {
                return chart.set('firstLevel', kpi)
              }

              return chart.set('thirdLevel', kpi)
            }

            return chart
          })), fromJS({})
        )
      )
  ),

  [Actions.updateDatesOfSelectedDashboard]: (state, { payload: { dateFrom, dateTo } }) => (
    state
      .update(
        'charts',
        charts => charts.reduce(
          (acc, values, breakpoint) => acc.set(breakpoint, values.map(chart => chart.merge({
            dateRange: null,
            dateInterval: null,
            dateFrom,
            dateTo
          }))),
          fromJS({})
        )
      )
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
