import { connect } from 'react-redux'

import Results from 'components/anewstip/results'

import {
  switchResultsTab
} from 'actions/anewstip'

import {
  getAnewstipJournalists,
  getAnewstipOutlets,
  getAnewstipArticles,
  getResultsTab,
  getIsNavigationLocked,
  getJournalistsSearchRequestRunning,
  getOutletsSearchRequestRunning,
  getArticlesSearchRequestRunning
} from 'selectors/anewstip'

const mapStateToProps = state => ({
  journalists: getAnewstipJournalists(state),
  outlets: getAnewstipOutlets(state),
  articles: getAnewstipArticles(state),
  resultsTab: getResultsTab(state),
  isNavigationLocked: getIsNavigationLocked(state),
  journalistsSearchRequestRunning: getJournalistsSearchRequestRunning(state),
  outletsSearchRequestRunning: getOutletsSearchRequestRunning(state),
  articlesSearchRequestRunning: getArticlesSearchRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    switchResultsTab
  }
)(Results)
