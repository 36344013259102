import { connect } from 'react-redux'

import Facets from 'components/anewstip/results/facets'

import {
  getAnewstipFiltersData,
  getResultsTab,
  getAnewstipData
} from 'selectors/anewstip'

import {
  setFiltersForm as onFilterChange,
  searchJournalistsStart as onSearchJournalists,
  searchOutletsStart as onSearchOutlets,
  searchArticlesStart as onSearchArticles,
  resetPaginationFilter
} from 'actions/anewstip'

const mapStateToProps = state => ({
  filtersFormData: getAnewstipFiltersData(state),
  resultsTab: getResultsTab(state),
  data: getAnewstipData(state)
})

export default connect(
  mapStateToProps,
  {
    onFilterChange,
    onSearchJournalists,
    onSearchOutlets,
    onSearchArticles,
    resetPaginationFilter
  }
)(Facets)
