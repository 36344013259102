import React, { Fragment } from 'react'
import { List as iList, Map } from 'immutable'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import { Capabilities } from 'static/constants'
import { formatNumber, separate } from 'utils/number'

import ChannelIcon from 'components/channel_icon'
import { Button } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

import useI18n from 'hooks/useI18n'

import { Link, Divider, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material'

const useStyles = makeStyles()({
  item: {
    paddingBottom: 0,
    paddingTop: 0
  },
  switchButton: {
    padding: 10,
    width: '100%'
  }
})

const SwitchButton = props => {
  const { classes } = useStyles()

  return (
    <div className={classes.switchButton}>
      <Button {...props} />
    </div>
  )
}

const SwitchToSearchPoolButton = CapSafe(SwitchButton, Capabilities.HAS_NEWS_POOL_MODULE)

export default function News({
  news,
  content,
  total,
  onSwitchToSearchPoolClick
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <>
      <SwitchToSearchPoolButton
        variant="contained"
        color="primary"
        onClick={() => onSwitchToSearchPoolClick({
          field: 'ccdContents',
          value: content
        })}
      >
        {i18n.get('switch_to_news_pool_module')}
      </SwitchToSearchPoolButton>

      <List
        dense
        disablePadding
      >
        {news.map(n => (
          <Fragment key={n.get('id')}>
            <ListItem
              classes={{
                root: classes.item
              }}
            >
              <ListItemAvatar>
                <ChannelIcon
                  id={n.getIn(['publication', 'channel', 'id'])}
                />
              </ListItemAvatar>

              <ListItemText
                primary={(
                  <Link
                    href={n.get('url')}
                    target="_blank"
                    rel="noreferrer"
                    underline="hover"
                    key={n.get('id')}
                    title={n.get('headline')}
                  >
                    {n.getIn(['publication', 'name'])}
                  </Link>
                )}
                secondary={(
                  <>
                    <span>{i18n.get('reach')}:&nbsp;</span>

                    <span title={separate(n.getIn(['keyFigures', 'reach']) || 0)}>
                      {formatNumber(n.getIn(['keyFigures', 'reach']) || 0)}
                    </span>

                    <br />

                    <span>{i18n.get('similarity')}:&nbsp;</span>

                    <span>
                      {n.getIn(['keyFigures', 'similarity'])}%
                    </span>
                  </>
                )}
              />
            </ListItem>
            <Divider />
          </Fragment>
        ))}

        {news.size < total && (
          <ListItem>
            <ListItemAvatar />
            <ListItemText
              primary={`+${total - news.size} ${i18n.get('more')}`}
            />
          </ListItem>
        )}
      </List>

    </>
  )
}

News.propTypes = {
  news: PropTypes.instanceOf(iList).isRequired,
  content: PropTypes.instanceOf(Map).isRequired,
  total: PropTypes.number.isRequired,

  onSwitchToSearchPoolClick: PropTypes.func.isRequired
}
