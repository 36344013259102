import React, { useEffect } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import {
  CircularProgress,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  TextField,
  InputAdornment,
  Tooltip
} from '@mui/material'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import SearchIcon from '@mui/icons-material/Search'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  circularLoader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '460px'
  },
  searchField: {
    marginBottom: '15px'
  },
  imageListBox: {
    overflowY: 'scroll',
    height: 460
  },
  loadMoreButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px !important'
  },
  hotlink: {
    textDecoration: 'underline',
    color: 'white'
  },
  subtitleWrapper: {
    fontSize: '9pt'
  }
})

const Unsplash = ({
  photosData,
  requestRunning,
  downloadRequestRunning,
  formData,
  isRandomSearch,
  loadMoreDisabled,
  selectedImageId,
  startUnsplashSearch,
  fetchRandomPhotos,
  onChange,
  resetUnsplashPhotos,
  addToLibrary
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleLoadMore = () => {
    if (isRandomSearch) {
      return fetchRandomPhotos()
    }

    return startUnsplashSearch()
  }

  const handleMainSearch = () => {
    resetUnsplashPhotos()

    return startUnsplashSearch()
  }

  const handleChange = e => onChange({ name: e.target.name, value: e.target.value })

  const handleOnEnter = e => {
    if (e.code === 'Enter') {
      handleMainSearch()
    }
  }

  useEffect(() => {
    if (photosData.get('items').size === 0) {
      fetchRandomPhotos()
    }
  }, [])

  const renderLoader = () => (
    <div className={classes.circularLoader}>
      <CircularProgress
        size={50}
      />
    </div>
  )

  const renderSubtitle = item => (
    <div className={classes.subtitleWrapper}>
      Photo by&nbsp;
      <a
        className={classes.hotlink}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://unsplash.com/@${item.getIn(['user', 'username'])}?utm_source=NewsRadar&utm_medium=referral`}
      >
        {`${item.getIn(['user', 'first_name'])} ${item.getIn(['user', 'last_name']) || ''}`}
      </a>&nbsp;on&nbsp;
      <a
        className={classes.hotlink}
        target="_blank"
        rel="noopener noreferrer"
        href="https://unsplash.com/?utm_source=NewsRadar&utm_medium=referral"
      >Unsplash
      </a>
    </div>
  )

  return (
    <>
      <TextField
        fullWidth
        placeholder={`${i18n.get('image')} ${i18n.get('search')}...`}
        name="query"
        value={formData.get('query') || ''}
        onChange={handleChange}
        size="small"
        className={classes.searchField}
        onKeyDown={handleOnEnter}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={handleMainSearch}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {(requestRunning && photosData.get('items').size === 0) ? renderLoader() : (
        <Box
          className={classes.imageListBox}
        >
          <ImageList
            cols={3}
            gap={8}
            sx={{ marginTop: 0 }}
          >
            {photosData.get('items').map(item => (
              <ImageListItem key={item.get('id')}>
                <img
                  src={`${item.getIn(['urls', 'thumb'])}`}
                  srcSet={`${item.getIn(['urls', 'thumb'])}`}
                  alt={item.get('description')}
                  loading="lazy"
                />
                <ImageListItemBar
                  onClick={() => null}
                  sx={{
                    '& .MuiImageListItemBar-title': { fontSize: '9pt' }
                  }}
                  title={item.get('description') || item.get('alt_description')}
                  subtitle={renderSubtitle(item)}
                  actionIcon={(
                    <Tooltip
                      title={i18n.get('add_to_library')}
                      arrow
                    >
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        onClick={() => addToLibrary({
                          url: item.getIn(['links', 'download_location']),
                          filename: item.get('slug'),
                          id: item.get('id')
                        })}
                        disabled={downloadRequestRunning}
                      >
                        {(downloadRequestRunning && selectedImageId === item.get('id')) ? (
                          <CircularProgress
                            size={20}
                          />
                        )
                          : <CloudDownloadIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                />
              </ImageListItem>
            ))}
            <ImageListItem
              key="loadMoreButton"
              className={classes.loadMoreButton}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={loadMoreDisabled}
                onClick={handleLoadMore}
                loading={requestRunning}
              >{i18n.get('load_more')}
              </Button>
            </ImageListItem>
          </ImageList>
        </Box>
      )}
    </>
  )
}

Unsplash.propTypes = {
  photosData: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired,
  formData: PropTypes.instanceOf(Map).isRequired,
  isRandomSearch: PropTypes.bool.isRequired,
  loadMoreDisabled: PropTypes.bool.isRequired,
  downloadRequestRunning: PropTypes.bool.isRequired,
  selectedImageId: PropTypes.string,

  startUnsplashSearch: PropTypes.func.isRequired,
  fetchRandomPhotos: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  resetUnsplashPhotos: PropTypes.func.isRequired,
  addToLibrary: PropTypes.func.isRequired
}

export default Unsplash
