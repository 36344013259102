/* eslint-disable */
import React from 'react'
import useI18n from 'hooks/useI18n'

import {
    $getSelection,
    $isRangeSelection,
    $createParagraphNode
} from 'lexical'

import {
    INSERT_UNORDERED_LIST_COMMAND,
    INSERT_ORDERED_LIST_COMMAND,
    REMOVE_LIST_COMMAND
} from '@lexical/list'

import {
    $setBlocksType
} from '@lexical/selection'

import { DropDown, DropDownItem } from 'components/content_desk_new/contents/content_edit_dialog/ots_editor/rte/plugins/ui/dropdown'

const blockTypeToBlockName = {
    paragraph: 'paragraph',
    ul: 'unordered_list',
    ol: 'ordered_list'
}

const TextFormat = ({
    editor,
    toolbarTrackerRef,
    isEditable,
    blockType
}) => {
    const i18n = useI18n()

    const capitalizeEach = string => {
        const strArray = string.split(' ')

        for (let i = 0; i < strArray.length; i += 1) {
            strArray[i] = strArray[i][0].toUpperCase() + strArray[i].slice(1)
        }

        return strArray.join(' ')
    }

    let adjustedBlockType = blockType

    if (blockType === 'root') {
        adjustedBlockType = 'paragraph'
    }

    if (blockType === 'extended-paragraph') {
        adjustedBlockType = 'paragraph'
    }

    const formatParagraph = () => {
        if (adjustedBlockType !== 'paragraph') {
            editor.update(() => {
                const selection = $getSelection()

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createParagraphNode())
                }
            })
        }
    }

    const formatUnorderedList = () => {
        if (adjustedBlockType !== 'ul') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    const formatOrderedList = () => {
        if (adjustedBlockType !== 'ol') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
        }
    }

    let adjustedIconName = adjustedBlockType

    if (blockType === 'ul') {
        adjustedIconName = 'list-ul'
    }

    if (blockType === 'ol') {
        adjustedIconName = 'list-ol'
    }

    return (
        <DropDown
            toolbarTrackerRef={toolbarTrackerRef}
            disabled={!isEditable}
            buttonLabel={capitalizeEach(i18n.get(blockTypeToBlockName[adjustedBlockType]))}
            buttonIconClassName={`toolbar-icon fal fa-${adjustedIconName}`}
            buttonClassName="toolbar-item spaced text-format"
            title={i18n.get('text_format')}
        >
            <DropDownItem
                onClick={formatParagraph}
                className="item"
            >
                <i className="toolbar-icon fal fa-paragraph"></i>
                <span className="text">{i18n.get('paragraph')}</span>
            </DropDownItem>
            <DropDownItem
                onClick={formatOrderedList}
                className="item"
            >
                <i className="toolbar-icon fal fa-list-ol"></i>
                <span className="text">{capitalizeEach(i18n.get('ordered_list'))}</span>
            </DropDownItem>
            <DropDownItem
                onClick={formatUnorderedList}
                className="item"
            >
                <i className="toolbar-icon fal fa-list-ul"></i>
                <span className="text">{capitalizeEach(i18n.get('unordered_list'))}</span>
            </DropDownItem>
        </DropDown>
    )
}

export default TextFormat
