import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as DashboardActions from 'actions/dashboard'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  news: {},
  references: {}
})

const addNewsToState = (state, news) => {
  const newNews = fromJS({}).withMutations(ctx => {
    news.forEach(n => ctx.set(n.get('id').toString(), n))
  })

  const references = news.reduce(
    (acc, n) => acc.update(n.get('id').toString(), 0, count => count + 1), state.get('references')
  )

  return state.update('news', n => n.merge(newNews))
    .update('references', r => r.merge(references))
}

const collectGarbage = state => {
  const unreferencedNewsIds = state.get('references').filter(v => v <= 0).keySeq().toList()
  const news = state.get('news').filter((v, k) => !unreferencedNewsIds.includes(k))
  const references = state.get('references').filter((v, k) => !unreferencedNewsIds.includes(k))

  return state.merge({
    news,
    references
  })
}

export default handleActions({
  [NewsActions.addNews]: (state, { payload: news }) => addNewsToState(state, fromJS(news)),
  [NewsActions.updateNews]: (state, { payload: news }) => news.reduce((acc, n) => acc.setIn(['news', n.get('id').toString()], n), state),
  [NewsActions.translateRequestSuccess]: (state, { payload: { translations, newsId, targetLang } }) => (
    state.setIn(['news', newsId.toString(), 'translations'], fromJS(translations).set('language', targetLang))
  ),
  [NewsActions.removeTranslations]: (state, { payload: newsId }) => state.deleteIn(['news', newsId.toString(), 'translations']),
  [NewsActions.decrementReferences]: (state, { payload: newsIds }) => (
    collectGarbage(
      newsIds.reduce((acc, id) => acc.updateIn(['references', id.toString()], 0, count => count - 1), state)
    )
  ),

  [DashboardActions.loadDrilldownNewsFeedNewsSuccess]: (state, { payload: { groups } }) => {
    const news = fromJS(groups.flatMap(g => g.news))

    return addNewsToState(state, news)
  },

  [AppActions.resetState]: () => initialState
}, initialState)
