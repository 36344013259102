import React, { useState } from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import {
  Alert,
  AlertTitle,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Breadcrumbs,
  Link,
  Slide
} from '@mui/material'
import { grey, red } from '@mui/material/colors'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'

import { OtsStatuses } from 'static/constants'

import ContentPreview from 'containers/content_desk_new/contents/ContentPreview'
import StatisticsData from 'containers/content_desk_new/contents/content_view/StatisticsData'
import RecipientsTable from 'containers/content_desk_new/contents/content_view/RecipientsTable'
import ContentStatus from 'containers/content_desk_new/contents/content/ContentStatus'

const useStyles = makeStyles()(theme => ({
  headlineDivider: {
    margin: '1em 0em 1em 0em'
  },
  dialog: {
    padding: '1.5em 1.5em 1.5em 1.5em'
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginTop: '1em'
  },
  title: {
    fontSize: '14pt',
    fontWeight: 500,
    color: !theme.isDark && '#404040',
    marginBottom: '0.5em',
    overflowWrap: 'break-word'
  },
  closeIcon: {
    position: 'absolute',
    right: 15,
    top: 15
  },
  editButton: {
    marginTop: '1em',
    textAlign: 'right'
  },
  contentContainer: {
    fontSize: '10pt',
    color: !theme.isDark && grey[600]
  },
  label: {
    fontWeight: 500
  },
  chip: {
    width: '160px',
    padding: '0px 3px 0px 3px'
  },
  dialogTitle: {
    marginTop: 5,
    paddingLeft: '1.1em'
  },
  breadcrumbLink: {
    fontSize: 23,
    color: !theme.isDark ? '#404040' : '#ffffff'
  },
  deleted: {
    color: red[500]
  },
  recipientsChipParagraph: {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  otsHotline: {
    margin: '1em 0em'
  },
  otsHotlineTitle: {
    fontSize: '0.8em'
  },
  price: {
    fontSize: 26,
    fontWeight: 500
  },
  excludeVat: {
    fontSize: 14,
    color: !theme.isDark ? '#404040' : '#ffffff',
    verticalAlign: 'middle'
  },
  phoneIcon: {
    verticalAlign: 'bottom'
  }
}))

const Transition = React.forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
))
Transition.displayName = 'Transition'

const ContentView = ({ open, content, requestRunning, onEditClick, statisticsData, resetEvents, onClose }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [recipientsView, setRecipientsView] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const otsStatus = content.getIn(['otsPressRelease', 'status'])
  const otsPrice = content.getIn(['otsPressRelease', 'price'])
  const showEditButton = content.get('status') !== 'released' && isDesktop && !otsStatus
  const showStatistics = content.get('status') === 'released' || otsStatus === OtsStatuses.DISTRIBUTED

  if (!content || content.isEmpty() || !content.get('id')) {
    return null
  }

  const lastEditedDate = moment(content.get('updatedAt')).format('lll')

  const handleEdit = () => {
    if (content.get('type') === 'email' || content.get('type') === 'ots_press_release') {
      onEditClick({ id: content.get('id'), openEditContentDialog: true })
    } else {
      onEditClick({ id: content.get('id'), openOldEditContentDialog: true })
    }

    onClose()
  }

  const combinedRecipientData = content
    .get('recipients')
    .map(recipient => {
      const match = statisticsData.get('events').find(event => event.get('email').toLowerCase()
        .includes(recipient.get('mail').toLowerCase()))

      let events

      if (match) {
        events = match.get('events').toJS()
      } else {
        events = []
      }

      return ({
        firstName: recipient.get('firstName'),
        lastName: recipient.get('lastName'),
        email: recipient.get('mail'),
        events
      })
    })

  const handleClose = () => {
    setTimeout(() => {
      resetEvents()
      setRecipientsView(false)
    }, 400)

    onClose()
  }

  const renderCampaignName = campaign => {
    switch (campaign) {
      case 'No Campaign':
        return (
          <>
            <span className={classes.label}>{i18n.get('campaign')}:</span> {i18n.get('no_campaign')}
          </>
        )
      default:
        return (
          <>
            <span className={classes.label}>{i18n.get('campaign')}:</span> {campaign}
          </>
        )
    }
  }

  const renderRecipients = () => {
    if (content.get('type') !== 'email') {
      return null
    }

    return (
      <>
        <div className={classes.title}>{i18n.get('recipients')}</div>
        <Button
          variant="text"
          color="primary"
          disabled={requestRunning}
          onClick={() => (content.get('recipients').size !== 0 ? setRecipientsView(true) : null)}
        >
          {i18n.get('see_all_recipients')} ({content.get('recipients').size})
        </Button>
      </>
    )
  }

  const renderEmailInfos = () => {
    if (content.get('type') === 'email') {
      const preheaderText = content.getIn(['editorDesign', 'body', 'values', 'preheaderText'])

      return (
        <>
          <div>
            <span className={classes.label}>{i18n.get('subject')}:</span> {content.get('subject')}
          </div>
          {preheaderText && (
            <div>
              <span className={classes.label}>{i18n.get('email_pre_header')}:</span> {preheaderText}
            </div>
          )}
        </>
      )
    }

    return null
  }

  const renderOtsHotline = () => {
    if (otsStatus) {
      return (
        <Alert
          variant="outlined"
          severity="warning"
          className={classes.otsHotline}
        >
          <AlertTitle className={classes.otsHotlineTitle}>
            {i18n.get('ots_need_help')}
          </AlertTitle>
          <LocalPhoneIcon className={classes.phoneIcon} /> {i18n.get('ots_hotline')}
        </Alert>
      )
    }

    return null
  }

  const renderOtsPrice = () => {
    if (otsStatus) {
      return (
        <>
          <Divider className={classes.headlineDivider} />
          <div>
            <Typography
              variant="h5"
              gutterBottom
            >
              {i18n.get('total_price')}
            </Typography>
            <div className={classes.price}>
              {otsPrice} € <span className={classes.excludeVat}>{i18n.get('price_exclude_vat')}</span>
            </div>
          </div>
        </>
      )
    }

    return null
  }

  const renderMainView = () => (
    <>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Grid
          item
          md={4}
        >
          <div className={classes.title}>{i18n.get('info')}</div>
          <div className={classes.contentContainer}>
            <div>
              <span className={classes.label}>{i18n.get('name')}:</span> {content.get('title')}
            </div>
            <div>
              {renderCampaignName(content.getIn(['ccdCampaign', 'name']))}
            </div>
            {renderEmailInfos()}
          </div>
        </Grid>
        <Grid item>
          <div className={classes.title}>
            {i18n.get('last_edited')}
          </div>
          <div className={classes.contentContainer}>
            <div>
              <span className={classes.label}>{i18n.get('by')}: </span>
              {`${content.getIn(['lastEditor', 'firstName'])} ${content.getIn(['lastEditor', 'lastName'])}`}
            </div>
            <div>
              <span className={classes.label}>{i18n.get('date')}:</span> {lastEditedDate }
            </div>
          </div>
        </Grid>
        <Grid item>
          {renderRecipients()}
        </Grid>
        <Grid item>
          <div className={classes.title}>{i18n.get('sending_status')}</div>
          <div className={classes.contentContainer}>
            <ContentStatus content={content} />
            {renderOtsHotline()}
          </div>
        </Grid>
      </Grid>
      {renderOtsPrice()}
      {showStatistics && (
        <StatisticsData
          data={statisticsData}
          type={content.get('type')}
          requestRunning={requestRunning}
        />
      )}
      {showEditButton && (
        <div className={classes.editButton}>
          <Button
            variant="outlined"
            color="primary"
            endIcon={<EditIcon />}
            onClick={handleEdit}
          >
            {i18n.get('edit')}
          </Button>
        </div>
      )}
      <Divider className={classes.headlineDivider} />
      <Typography
        className={classes.headline}
        variant="h6"
      >
        {i18n.get('content')}
      </Typography>
      <ContentPreview />
    </>
  )

  const renderBreadcrumbs = () => (
    <Breadcrumbs>
      <Link
        underline={recipientsView ? 'hover' : 'none'}
        className={classes.breadcrumbLink}
        style={{ cursor: recipientsView && 'pointer', display: 'flex', alignItems: 'center', gap: 5 }}
        onClick={() => setRecipientsView(false)}
      >
        {recipientsView && <ArrowBackIcon sx={{ mb: '3px' }} />}
        {i18n.get('details')}
      </Link>
      {recipientsView && (
        <Link
          underline="none"
          className={classes.breadcrumbLink}
        >
          {i18n.get('recipients')}
        </Link>
      )}
    </Breadcrumbs>
  )

  return (
    <div>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        maxWidth="lg"
        fullWidth
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            [theme.breakpoints.up('sm')]: {
              minHeight: '95%',
              maxHeight: '95%'
            }
          }
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          {renderBreadcrumbs()}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {!recipientsView
            ? renderMainView()
            : <RecipientsTable data={combinedRecipientData} />}
        </DialogContent>
      </Dialog>
    </div>
  )
}

ContentView.propTypes = {
  content: PropTypes.object.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  statisticsData: PropTypes.instanceOf(Map).isRequired,
  resetEvents: PropTypes.func.isRequired
}

export default ContentView
