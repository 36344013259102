import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogContent } from '@mui/material'

const useStyles = makeStyles()({
  dialogPaper: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '0',
    boxShadow: 'none'
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    padding: 0
  },
  imagePreview: {
    maxWidth: '100%',
    maxHeight: '80vh'
  }
})

const ImagePreviewDialog = ({
  open,
  imageSrc,
  closeImagePreviewDialog
}) => {
  const { classes } = useStyles()

  const handleCloseImagePreview = e => {
    e.preventDefault()
    e.stopPropagation()
    closeImagePreviewDialog()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseImagePreview}
      PaperProps={{
        className: classes.dialogPaper
      }}
    >
      <DialogContent
        className={classes.dialogContent}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <img
          className={classes.imagePreview}
          src={imageSrc}
          alt=""
        />
      </DialogContent>
    </Dialog>
  )
}

ImagePreviewDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  imageSrc: PropTypes.string,

  closeImagePreviewDialog: PropTypes.func.isRequired
}

export default ImagePreviewDialog
