import { connect } from 'react-redux'

import QueriesTable from 'components/darknet/query_manager/queries_table'

import {
  openScoreQueryDialog as onScoreQueryDialogOpen,
  openSearchQueryDialog as onSearchQueryDialogOpen,
  deleteQueryRequestStart,
  deactivateQueryRequestStart,
  activateQueryRequestStart
} from 'actions/darknet'

import {
  getDarknetQueries,
  getDarknetRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  requestRunning: getDarknetRequestRunning(state),
  queries: getDarknetQueries(state)
})

export default connect(
  mapStateToProps,
  {
    onScoreQueryDialogOpen,
    onSearchQueryDialogOpen,
    onDelete: deleteQueryRequestStart,
    onDeactivate: deactivateQueryRequestStart,
    onActivate: activateQueryRequestStart
  }
)(QueriesTable)
