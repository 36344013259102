import { connect } from 'react-redux'

import ShoppingCartNewsList from 'components/shopping_cart/news_list'

import { moveNewsInShoppingCart, resetSortingSelection } from 'actions/shopping_cart'

import { getShoppingCartNews, getShoppingCartSelectedNewsForSorting } from 'selectors'

const mapStateToProps = state => ({
  news: getShoppingCartNews(state),
  selectedNews: getShoppingCartSelectedNewsForSorting(state)
})

export default connect(
  mapStateToProps,
  {
    onSortEnd: moveNewsInShoppingCart,
    onReset: resetSortingSelection
  }
)(ShoppingCartNewsList)
