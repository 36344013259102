/* global document */

const updateMetaElement = (selector, value) => {
  const element = document.querySelector(selector)

  if (element) {
    element.content = value
  }
}

// For nice effects in mobile chrome
export const updateColorInMeta = value => updateMetaElement('meta[name="theme-color"]', value)
export const updateTitleInMeta = value => updateMetaElement('meta[property="og:title"]', value)
export const updateDescriptionInMeta = value => updateMetaElement('meta[property="og:description"]', value)

export const getBodyBackgroundColor = () => document.body.style.backgroundColor
