import React from 'react'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { getChannelColor } from 'utils/channel'

const useStyles = makeStyles()({
  bar: {
    display: 'flex',
    width: '100%',
    height: 10
  }
})

export default function ChannelBar({ mediaReview }) {
  const { classes } = useStyles()
  const channels = mediaReview.getIn(['stats', 'channels'])
  const newsCount = mediaReview.get('newsCount')

  const channelPercentages = channels.reduce(
    (acc, value) => acc.set(value.get('id'), (value.get('count', 0) / newsCount) * 100),
    fromJS({})
  )

  return (
    <div className={classes.bar}>
      {channelPercentages.keySeq().map(key => (
        <div
          key={key}
          style={{
            width: `${channelPercentages.get(key)}%`,
            backgroundColor: getChannelColor(key)
          }}
        />
      ))}
    </div>
  )
}

ChannelBar.propTypes = {
  mediaReview: PropTypes.instanceOf(Map).isRequired
}
