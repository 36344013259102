import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import ShortCampaign from 'containers/content_desk_new/campaigns/short_campaigns/ShortCampaign'
import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  sortIconActive: {
    fontSize: '0.6rem !important'
  },
  sortIconInActive: {
    fontSize: '0.6rem !important',
    opacity: 0.5
  }
})

const ShortCampaigns = ({
  campaigns,
  campaignsSorting,
  sortCampaigns
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  if (campaigns.isEmpty()) {
    return null
  }

  const renderSortIcon = field => {
    const sorting = campaignsSorting
    const sortFunc = sortCampaigns
    let title = i18n.get('sort')
    let icon = <SwapVertIcon />
    let iconClass = classes.sortIconInActive

    if (field === sorting.get('field')) {
      if (sorting.get('order') === 'asc') {
        iconClass = classes.sortIconActive
        icon = <ArrowUpwardIcon />
        title = i18n.get('ascending')
      }

      if (sorting.get('order') === 'desc') {
        iconClass = classes.sortIconActive
        icon = <ArrowDownwardIcon />
        title = i18n.get('descending')
      }

      return (
        <IconButton
          title={title}
          icon={icon}
          size="small"
          className={iconClass}
          onClick={() => sortFunc({
            field,
            order: (sorting.get('order') === 'asc') ? 'desc' : 'asc'
          })}
        />
      )
    }

    return (
      <IconButton
        title={title}
        icon={icon}
        size="small"
        className={iconClass}
        onClick={() => sortFunc({
          field,
          order: (field === 'name') ? 'asc' : 'desc'
        })}
      />
    )
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{i18n.get('name')} {renderSortIcon('name')}</TableCell>
          <TableCell>{i18n.get('duration')} {renderSortIcon('startDate')}</TableCell>
          <TableCell>{i18n.get('contents')}</TableCell>
          <TableCell>{i18n.get('status')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {campaigns
          .map((el, index) => (
            <ShortCampaign
              campaign={el}
              key={index}
            />
          ))}
      </TableBody>
    </Table>
  )
}

ShortCampaigns.propTypes = {
  campaigns: PropTypes.instanceOf(List).isRequired,
  campaignsSorting: PropTypes.instanceOf(Map).isRequired,

  sortCampaigns: PropTypes.func.isRequired
}

export default ShortCampaigns
