import { connect } from 'react-redux'

import PasswordInput from 'components/password_input'

import { getI18n } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state)
})

export default connect(mapStateToProps)(PasswordInput)
