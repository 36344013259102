/* global window, document, DOMParser */
import { stripHtml } from 'string-strip-html'

export const stripDossier = dossier => {
  const match = dossier.match(/(\s+)$/s)
  const endingWhitespaceOrNewline = match ? match[0] : ''

  let stripped = stripHtml(dossier, {
    skipHtmlDecoding: true,
    dumpLinkHrefsNearby: {
      enabled: true,
      wrapHeads: '(',
      wrapTails: ')'
    }
  }).result

  stripped += endingWhitespaceOrNewline

  return stripped
}

// Keeps styles to copy into Word etc
export const copyElementContentToClipboard = elementId => {
  const content = document.getElementById(elementId)
  const range = document.createRange()
  range.selectNode(content)
  window.getSelection().removeAllRanges()
  window.getSelection().addRange(range)

  let successful = false
  try {
    successful = document.execCommand('copy')
  } catch (err) {
    console.error('Failed to copy content', err) // eslint-disable-line no-console
  }

  window.getSelection().removeAllRanges()

  return successful
}

export const removeHtmlTags = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  return doc.body.textContent || ''
}
