import { connect } from 'react-redux'

import TestMailDialog from 'components/content_desk_new/contents/content_edit_dialog/test_mail_dialog'

import {
  closeTestMailDialog as onClose,
  dispatchContentRequestStart as onDispatch
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getCDeskShowTestMailDialog,
  getCmRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  sender: getCDeskContentFormData(state).get('senderMail', ''),
  requestRunning: getCmRequestRunning(state),
  isOpen: getCDeskShowTestMailDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onClose,
    onDispatch
  }
)(TestMailDialog)
