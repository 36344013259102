import { connect } from 'react-redux'

import TagRenameDialog from 'components/contact_management/tags_dialog/tag_rename_dialog'

import { getCmTagRenameDialogOpen, getCmRequestRunning } from 'selectors/contact_management'

import {
  renameTagStart as onSave,
  closeTagRenameDialog as onCancel
} from 'actions/contact_management'

const mapStateToProps = (state, ownProps) => ({
  open: getCmTagRenameDialogOpen(state),
  running: getCmRequestRunning(state),
  tag: ownProps.tag
})

export default connect(mapStateToProps, {
  onCancel,
  onSave
})(TagRenameDialog)
