import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { IconButton } from 'containers/themed'

import RemoveIcon from '@mui/icons-material/Remove'

import { TableRow, TableCell } from '@mui/material'

export default function ContactsListItem({ contact, onRemove }) {
  const [removed, setRemoved] = useState(false)

  const handleClick = () => {
    setRemoved(true)
    onRemove(contact)
  }

  if (removed) {
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <IconButton
          size="small"
          onClick={handleClick}
        >
          <RemoveIcon />
        </IconButton>
      </TableCell>
      <TableCell>{contact.get('firstName')}</TableCell>
      <TableCell>{contact.get('lastName')}</TableCell>
      <TableCell>{contact.get('mail')}</TableCell>
      <TableCell>{contact.get('country')}</TableCell>
      <TableCell>{contact.get('externalPublication')}</TableCell>
    </TableRow>
  )
}

ContactsListItem.propTypes = {
  contact: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
}
