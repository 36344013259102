export const valueLabelFormat = (value, marks) => marks.findIndex(ms => ms.value === value) + 1

export const descale = (scaledValue, marks) => {
  const markIndex = marks.findIndex(
    mark => mark.scaledValue >= scaledValue
  )
  const mark = marks[markIndex]

  if (mark.scaledValue === scaledValue) {
    return mark.value
  }

  if (markIndex === 0) {
    return 0
  }

  const m = (mark.scaledValue - marks[markIndex - 1].scaledValue)
    / (mark.value - marks[markIndex - 1].value || 1)
  const dX = scaledValue - marks[markIndex - 1].scaledValue

  return dX / m + marks[markIndex - 1].value
}

export const scale = (value, marks) => {
  const markIndex = marks.findIndex(mark => mark.value >= value)
  const mark = marks[markIndex]

  if (mark.value === value) {
    return mark.scaledValue
  }

  const m = (mark.scaledValue - marks[markIndex - 1].scaledValue)
    / (mark.value - marks[markIndex - 1].value || 1)
  const dX = value - marks[markIndex - 1].value

  return m * dX + marks[markIndex - 1].scaledValue
}
