/* eslint-disable */
import { ListItemNode } from '@lexical/list'

export class ExtendedListItemNode extends ListItemNode {
    static getType() {
        return 'extended-listitem'
    }

    static clone(node) {
        return new ExtendedListItemNode(node.__value, node.__checked, node.__key)
    }

    exportDOM(editor) {
        const element = super.createDOM(editor._config, editor)

        // Remove additional class, dir and value attribute on exported html.
        element.removeAttribute('dir')
        element.removeAttribute('class')
        element.removeAttribute('value')

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        return ListItemNode.importJSON(serializedNode)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'extended-listitem',
            version: 1
        }
    }
}