import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import { DateRanges } from 'static/constants'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

export const initialState = fromJS({
  dashboards: [],
  allowedDateRanges: DateRanges
})

const sort = dashboards => dashboards.sortBy(d => d.get('name').toLowerCase())
const parseSavedSearchIds = charts => charts.reduce((acc, c) => acc.add(c.map(d => (d.get('savedSearchId')))), Set())

export default handleActions({
  [Actions.setDashboards]: (state, { payload: dashboards }) => state.set('dashboards', sort(fromJS(dashboards))),

  [Actions.saveDashboardSuccess]: (state, { payload }) => {
    const dashboard = fromJS(payload)
    const dashboards = state.get('dashboards')
    const found = dashboards.find(d => d.get('id') === dashboard.get('id'))

    let savedSearchIds

    if (dashboard.get('charts')) {
      savedSearchIds = parseSavedSearchIds(dashboard.get('charts')).flatten().toList()
    }

    if (found) {
      return state.set('dashboards', sort(dashboards.map(d => {
        if (d.get('id') === dashboard.get('id')) {
          return dashboard.set('savedSearchIds', savedSearchIds)
        }

        return d
      })))
    }

    return state.update('dashboards', d => sort(d.push(dashboard.set('savedSearchIds', savedSearchIds))))
  },

  [Actions.deleteDashboardSuccess]: (state, { payload: id }) => (
    state.update('dashboards', dashboards => dashboards.filter(d => d.get('id') !== id))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
