import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { makeStyles } from 'tss-react/mui'
import CheckboxCard from 'components/checkbox_card'
import useI18n from 'hooks/useI18n'

import { StaggeredListItem } from 'components/staggered_list'
import { Typography, Box, Grid } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  timeIcon: {
    fontSize: '24pt',
    color: theme.palette.grey[500],
    marginRight: 10,
    marginLeft: 10,
    [theme.breakpoints.only('xs')]: {
      fontSize: '18pt'
    }
  },
  checkBoxCard: {
    marginBottom: 10,
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  },
  bold: {
    fontWeight: 500
  },
  schedule: {
    paddingLeft: 25
  },
  editions: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      marginBottom: 10
    }
  },
  selection: {
    [theme.breakpoints.only('xs')]: {
      marginLeft: 25,
      marginRight: 25
    }
  },
  header: {
    marginBottom: 10,
    [theme.breakpoints.only('xs')]: {
      marginLeft: 25,
      marginRight: 25
    }
  }
}))

const Header = ({ title }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.header}>
      <Typography
        variant="h5"
        gutterBottom
      >
        {title}
      </Typography>
    </div>
  )
}

const formatTime = time => {
  if (!time) {
    return 'X'
  }

  let [hours, minutes] = (time || '').split(':')
  hours = parseInt(hours, 10)
  minutes = parseInt(minutes, 10)
  const date = moment().set({ hours, minutes })

  return date.format('LT')
}

export default function Editions({ editions, selectedEditionIds, onSelect }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const selectedEditions = editions.filter(edition => selectedEditionIds.has(edition.get('id')))

  const renderEdition = (edition, index) => {
    const timeSet = Boolean(edition.get('dispatchTime'))
    const icon = timeSet ? <AccessTimeIcon classes={{ root: classes.timeIcon }} /> : undefined

    return (
      <StaggeredListItem
        key={edition.get('id')}
        delay={index * 50}
        sx={{ width: '100%' }}
      >
        <Box>
          <CheckboxCard
            accent
            label={edition.get('name')}
            className={classes.checkBoxCard}
            checked={selectedEditionIds.has(edition.get('id'))}
            onChange={selected => onSelect({ edition, selected })}
            icon={icon}
          />
          {timeSet && (
          <Typography
            variant="body2"
            gutterBottom
            className={classes.schedule}
          >{formatTime(edition.get('dispatchTime'))} {edition.get('timezone')}
          </Typography>
          )}
          {!timeSet && (
          <Typography
            variant="body2"
            gutterBottom
            className={classes.schedule}
          >{i18n.get('no_time_set')}
          </Typography>
          )}
        </Box>
      </StaggeredListItem>
    )
  }

  const renderEditions = () => (
    editions.valueSeq().map((edition, index) => renderEdition(edition, index))
  )

  const renderSelectedEdition = edition => (
    `
        <span class="${classes.bold}">${edition.get('name')}</span>
        ${i18n.get('at')}
        <span class="${classes.bold}">${formatTime(edition.get('dispatchTime'))} CET</span>
      `
  )

  const renderSelectedEditions = () => {
    if (selectedEditions.isEmpty()) {
      return i18n.get('no_edition_selected')
    }

    const editionNames = selectedEditions.map(renderSelectedEdition)
    const separator = selectedEditions.size <= 2 ? ` ${i18n.get('and')} ` : ','
    const content = {
      __html: i18n.get(
        'your_selection_description',
        {
          editions: editionNames.join(separator)
        }
      )
    }

    return (
      <span dangerouslySetInnerHTML={content} />
    )
  }

  return (
    <Box
      my={3}
      py={3}
    >
      <Header title={i18n.get('select_your_editions')} />
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          sm={12}
          className={classes.editions}
        >
          {renderEditions()}
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          className={classes.selection}
        >
          <span className={classes.bold}>{i18n.get('your_selection')}</span>
          <p>{renderSelectedEditions()}</p>
        </Grid>
      </Grid>
    </Box>
  )
}

Editions.propTypes = {
  editions: PropTypes.object.isRequired,
  selectedEditionIds: PropTypes.object.isRequired,

  onSelect: PropTypes.func.isRequired
}

Header.propTypes = {
  title: PropTypes.string.isRequired
}
