import moment from 'moment-timezone'
import { Set } from 'immutable'

const halfYearFromDate = date => {
  let from
  let to

  if ((date.get('month') + 1) < 7) {
    from = date.startOf('year')
    to = moment(from).add(5, 'months').endOf('month').toDate()
    from = from.toDate()
  } else {
    to = date.endOf('year')
    from = moment(to).subtract(5, 'months').startOf('month').toDate()
  }

  return { from, to }
}

export const rangeToDates = range => {
  let from
  let to

  switch (range) {
    case 'today': {
      from = moment().startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'yesterday': {
      from = moment().add(-1, 'days').startOf('day').toDate()
      to = moment().add(-1, 'days').endOf('day').toDate()
      break
    }
    case 'this_week': {
      from = moment().startOf('week').toDate()
      to = moment().endOf('week').toDate()
      break
    }
    case 'last_week': {
      from = moment().subtract('1', 'week').startOf('week').toDate()
      to = moment().subtract('1', 'week').endOf('week').toDate()
      break
    }
    case 'this_year': {
      from = moment().startOf('year').toDate()
      to = moment().endOf('year').toDate()
      break
    }
    case 'this_month': {
      from = moment().startOf('month').toDate()
      to = moment().endOf('month').toDate()
      break
    }
    case 'last_year': {
      from = moment().subtract('1', 'year').startOf('year').toDate()
      to = moment().subtract('1', 'year').endOf('year').toDate()
      break
    }
    case 'last_month': {
      from = moment().subtract('1', 'month').startOf('month').toDate()
      to = moment().subtract('1', 'month').endOf('month').toDate()
      break
    }
    case 'this_quarter': {
      from = moment().startOf('quarter').toDate()
      to = moment().endOf('quarter').toDate()
      break
    }
    case 'last_quarter': {
      from = moment().subtract('1', 'quarter').startOf('quarter').toDate()
      to = moment().subtract('1', 'quarter').endOf('quarter').toDate()
      break
    }
    case 'this_half_year': {
      const date = moment()
      const result = halfYearFromDate(date)
      from = result.from
      to = result.to

      break
    }
    case 'last_half_year': {
      const date = moment().subtract(2, 'quarters')
      const result = halfYearFromDate(date)
      from = result.from
      to = result.to

      break
    }
    case 'last_7_days': {
      from = moment().subtract('6', 'days').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'last_30_days': {
      from = moment().subtract('29', 'days').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'last_60_days': {
      from = moment().subtract('59', 'days').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'last_90_days': {
      from = moment().subtract('89', 'days').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'last_12_hours': {
      from = moment().subtract('12', 'hours').toDate()
      to = moment().toDate()
      break
    }
    case 'last_24_hours': {
      from = moment().subtract('24', 'hours').toDate()
      to = moment().toDate()
      break
    }
    case 'last_6_months': {
      from = moment().subtract('6', 'months').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'last_12_months': {
      from = moment().subtract('12', 'months').startOf('day').toDate()
      to = moment().endOf('day').toDate()
      break
    }
    case 'next_half_year': {
      const thisHalfYear = halfYearFromDate(moment())
      from = moment(thisHalfYear.from).add(6, 'months').toDate()
      to = moment(thisHalfYear.to).add(6, 'months').toDate()
      break
    }
    case 'next_month': {
      from = moment().startOf('month').add(1, 'month').toDate()
      to = moment().endOf('month').add(1, 'month').toDate()
      break
    }
    case 'next_quarter': {
      from = moment().startOf('quarter').add(1, 'quarter').toDate()
      to = moment().endOf('quarter').add(1, 'quarter').toDate()
      break
    }
    case 'next_year': {
      from = moment().startOf('year').add(1, 'year').toDate()
      to = moment().endOf('year').add(1, 'year').toDate()
      break
    }
    default: {
      from = null
      to = null
    }
  }

  return { from, to }
}

export const datesToRange = (from, to) => {
  let rangeResult
  const dateFrom = moment(from)
  const dateTo = moment(to)

  const now = moment()
  const yesterday = moment().add(-1, 'days')

  if (dateFrom.isSame(dateTo, 'day')) {
    if (dateFrom.isSame(now, 'day')) {
      return 'today'
    }

    if (dateFrom.isSame(yesterday, 'day')) {
      return 'yesterday'
    }
  }

  const startOfWeek = moment().startOf('week')
  const endOfWeek = moment().endOf('week')

  if (dateFrom.isSame(startOfWeek, 'minute') && dateTo.isSame(endOfWeek, 'minute')) {
    return 'this_week'
  }

  const startOfMonth = moment().startOf('month')
  const endOfMonth = moment().endOf('month')

  if (dateFrom.isSame(startOfMonth, 'minute') && dateTo.isSame(endOfMonth, 'minute')) {
    return 'this_month'
  }

  const startOfQuarter = moment().startOf('quarter')
  const endOfQuarter = moment().endOf('quarter')

  if (dateFrom.isSame(startOfQuarter, 'minute') && dateTo.isSame(endOfQuarter, 'minute')) {
    return 'this_quarter'
  }

  rangeResult = rangeToDates('this_half_year')

  if (dateFrom.isSame(rangeResult.from, 'minute') && dateTo.isSame(rangeResult.to, 'minute')) {
    return 'this_half_year'
  }

  const startOfYear = moment().startOf('year')
  const endOfYear = moment().endOf('year')

  if (dateFrom.isSame(startOfYear, 'minute') && dateTo.isSame(endOfYear, 'minute')) {
    return 'this_year'
  }

  const startOfLastWeek = moment().subtract('1', 'week').startOf('week')
  const endOfLastWeek = moment().subtract('1', 'week').endOf('week')

  if (dateFrom.isSame(startOfLastWeek, 'minute') && dateTo.isSame(endOfLastWeek, 'minute')) {
    return 'last_week'
  }

  const startOfLastMonth = moment().subtract('1', 'month').startOf('month')
  const endOfLastMonth = moment().subtract('1', 'month').endOf('month')

  if (dateFrom.isSame(startOfLastMonth, 'minute') && dateTo.isSame(endOfLastMonth, 'minute')) {
    return 'last_month'
  }

  const startOfLastQuarter = moment().subtract('1', 'quarter').startOf('quarter')
  const endOfLastQuarter = moment().subtract('1', 'quarter').endOf('quarter')

  if (dateFrom.isSame(startOfLastQuarter, 'minute') && dateTo.isSame(endOfLastQuarter, 'minute')) {
    return 'last_quarter'
  }

  rangeResult = rangeToDates('last_half_year')

  if (dateFrom.isSame(rangeResult.from, 'minute') && dateTo.isSame(rangeResult.to, 'minute')) {
    return 'last_half_year'
  }

  const startOfLastYear = moment().subtract('1', 'year').startOf('year')
  const endOfLastYear = moment().subtract('1', 'year').endOf('year')

  if (dateFrom.isSame(startOfLastYear, 'minute') && dateTo.isSame(endOfLastYear, 'minute')) {
    return 'last_year'
  }

  const before7Days = moment().subtract('6', 'days').startOf('day')

  if (dateFrom.isSame(before7Days, 'minute') && dateTo.isSame(now, 'day')) {
    return 'last_7_days'
  }

  const before30Days = moment().subtract('29', 'days').startOf('day')

  if (dateFrom.isSame(before30Days, 'minute') && dateTo.isSame(now, 'day')) {
    return 'last_30_days'
  }

  const before60Days = moment().subtract('59', 'days').startOf('day')

  if (dateFrom.isSame(before60Days, 'minute') && dateTo.isSame(now, 'day')) {
    return 'last_60_days'
  }

  const before90Days = moment().subtract('89', 'days').startOf('day')

  if (dateFrom.isSame(before90Days, 'minute') && dateTo.isSame(now, 'day')) {
    return 'last_90_days'
  }

  return null
}

const calenderWeek = date => {
  let cw = 'CW'

  if (moment.locale() === 'de') {
    cw = 'KW'
  }

  return `${cw} ${moment(date).format('WW')}`
}

export const formatDateByInterval = (date, interval) => {
  switch (interval) {
    case 'hour':
      return moment(date).format('LT')
    case 'week':
      return calenderWeek(date)
    case 'month':
      return moment(date).format('MMM YYYY')
    case 'year':
      return moment(date).format('YYYY')
    default:
      return moment(date).format('DD MMM')
  }
}

export const defaultAllowedDateIntervals = Set(['hour', 'day', 'week', 'month', 'quarter', 'year'])
export const restrictedDateIntervals = Set(['day', 'week', 'month', 'quarter', 'year'])
export const moreRestrictedDateIntervals = Set(['week', 'month', 'quarter', 'year'])
export const superRestrictedDateIntervals = Set(['month', 'quarter', 'year'])

export const calculateIntervals = (currentInterval, from, to, range) => {
  let dateInterval = currentInterval
  let allowedDateIntervals = defaultAllowedDateIntervals
  const dateFrom = moment(from)
  const dateTo = to ? moment(to) : moment()

  if (range && range.startsWith('relative_range')) {
    const parts = range.split('_')

    const count = parseInt(parts[2], 10)
    const unit = parts[3]

    if (unit === 'days') {
      allowedDateIntervals = superRestrictedDateIntervals

      if (count <= 366) {
        allowedDateIntervals = restrictedDateIntervals
      }

      if (count <= 10) {
        allowedDateIntervals = defaultAllowedDateIntervals
      }
    } else if (unit === 'months' && count <= 12) {
      allowedDateIntervals = restrictedDateIntervals
    } else if (unit === 'months' && count <= 24) {
      allowedDateIntervals = moreRestrictedDateIntervals
    } else {
      allowedDateIntervals = superRestrictedDateIntervals
    }
  } else if (!from) {
    allowedDateIntervals = superRestrictedDateIntervals
  } else if (dateTo.diff(dateFrom, 'month') > 24) {
    allowedDateIntervals = moreRestrictedDateIntervals
  } else if (dateTo.diff(dateFrom, 'days') > 7) {
    allowedDateIntervals = restrictedDateIntervals
  }

  if (dateInterval && !allowedDateIntervals.includes(dateInterval)) {
    dateInterval = allowedDateIntervals.first()
  }

  return {
    dateInterval,
    allowedDateIntervals
  }
}

const formatWithoutTime = 'll'
const formatWithTime = 'lll'

const isStart = from => {
  let dateFrom = from

  if (!dateFrom) {
    return false
  }

  dateFrom = moment(dateFrom)
  const dateFromStart = moment(dateFrom).startOf('day')

  return dateFrom.isSame(dateFromStart)
}

const isEnd = to => {
  let dateTo = to

  if (!dateTo) {
    return false
  }

  dateTo = moment(dateTo)
  const dateToEnd = moment(dateTo).endOf('day')

  return dateTo.isSame(dateToEnd)
}

export const getDateString = (dateRange, from, to, i18n, formatOverride) => {
  let dateFrom = from
  let dateTo = to

  const isStartEnd = isStart(dateFrom) && isEnd(dateTo)

  let format = formatWithoutTime

  if (!isStartEnd) {
    format = formatWithTime
  }

  if (formatOverride) {
    format = formatOverride
  }

  if (dateRange) {
    if (dateRange.startsWith('relative_range')) {
      const [count, unit] = dateRange.replace('relative_range_', '').split('_')

      return i18n.get('relative_date_range', { count, unit: i18n.get(unit) })
    }

    const dateRangeString = i18n.get(dateRange)

    if (isStartEnd || dateRange.match(/hours/)) {
      return dateRangeString
    }

    if (dateFrom && dateTo) {
      dateFrom = moment(dateFrom)
      dateTo = moment(dateTo)

      return `${dateRangeString} ${dateFrom.format('LT')} - ${dateTo.format('LT')}`
    }

    return dateRangeString
  }

  if (!dateFrom || !dateTo) {
    if (!dateFrom && dateTo) {
      if (isEnd(dateTo)) {
        format = formatWithoutTime
      }

      return `${i18n.get('till')} ${moment(dateTo).format(format)}`
    }

    if (dateFrom && !dateTo) {
      if (isStart(dateFrom)) {
        format = formatWithoutTime
      }

      return `${i18n.get('from')} ${moment(dateFrom).format(format)}`
    }

    return i18n.get('everything')
  }

  dateFrom = moment(dateFrom)
  dateTo = moment(dateTo)

  if (dateFrom.isSame(dateTo, 'day')) {
    if (isStart(dateFrom)) {
      return dateFrom.format(format)
    }

    return `${dateFrom.format(format)} - ${dateTo.format('LT')}`
  }

  return `${dateFrom.format(format)} - ${dateTo.format(format)}`
}

export const getDateQuickselects = type => {
  if (type === 'editorialDeadline') {
    return [[
      'this_month',
      'this_quarter',
      'this_half_year',
      'this_year'
    ],
    [
      'next_month',
      'next_quarter',
      'next_half_year',
      'next_year'
    ]]
  }

  return [[
    'today',
    'this_week',
    'this_month',
    'this_quarter',
    'this_half_year',
    'this_year',
    'last_7_days',
    'last_30_days',
    'last_6_months'
  ],
  [
    'yesterday',
    'last_week',
    'last_month',
    'last_quarter',
    'last_half_year',
    'last_year',
    'last_60_days',
    'last_90_days',
    'last_12_months'
  ]]
}

export const getLocale = () => (moment.locale() || 'de')
