import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import selectedIdentity from './selected_identity'
import selectedIdentitySet from './selected_identity_set'

export default combineReducers({
  ui,
  data,
  selectedIdentity,
  selectedIdentitySet
})
