import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Grid
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Button, RaisedPrimaryButton } from 'containers/themed'

import ContactCard from 'containers/anewstip/duplicate_contacts_dialog/ContactCard'

export default function DuplicateContactItem({ data, requestRunning, onReplace, onKeep }) {
  const i18n = useI18n()

  const existingContact = data.get('existingContact')
  const journalist = data.get('journalist')

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{journalist.get('firstName')} {journalist.get('lastName')} ({journalist.get('mail')})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography style={{ marginBottom: '0.5em' }}>{i18n.get('your_contact')}</Typography>
            <ContactCard data={existingContact} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
          >
            <Typography style={{ marginBottom: '0.5em' }}>{i18n.get('journalist_search')} {i18n.get('contact')}</Typography>
            <ContactCard data={journalist} />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Button
          disabled={requestRunning}
          onClick={() => onKeep(data)}
        >
          {i18n.get('keep')}
        </Button>
        <RaisedPrimaryButton
          loading={requestRunning}
          onClick={() => onReplace(data)}
        >
          {i18n.get('replace')}
        </RaisedPrimaryButton>
      </AccordionActions>
    </Accordion>
  )
}

DuplicateContactItem.propTypes = {
  data: PropTypes.instanceOf(ImmutableMap).isRequired,
  requestRunning: PropTypes.bool.isRequired,

  onReplace: PropTypes.func.isRequired,
  onKeep: PropTypes.func.isRequired
}
