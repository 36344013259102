import { connect } from 'react-redux'

import { SearchFields } from 'static/constants'

import FilterableMenu from 'components/filterable_menu'

import { getCustomTags } from 'selectors'

import { uiAddFilters } from 'actions/ui'
import { navigate } from 'actions/navigation'

const mapStateToProps = state => ({
  items: getCustomTags(state)
})

const mapDispatchToProps = dispatch => ({
  onItemClick: item => dispatch(uiAddFilters([
    {
      field: SearchFields.CUSTOM_TAGS,
      filter: item
    }
  ])),
  onMainClick: () => dispatch(navigate('pin_boards'))
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterableMenu)
