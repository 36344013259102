import { connect } from 'react-redux'

import Campaign from 'components/content_desk_new/campaigns/campaign'

const mapStateToProps = (_state, ownProps) => ({
  campaign: ownProps.campaign
})

export default connect(
  mapStateToProps,
  {
  }
)(Campaign)
