import React from 'react'
import useI18n from 'hooks/useI18n'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  titles: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  }
}))

export default function Titles() {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Grid
      container
      style={{ padding: '0 2em 1em 1em' }}
    >
      <Grid
        item
        lg={4}
        xs={12}
      />

      <Grid
        item
        lg={4}
        xs={12}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.titles}
        >
          {i18n.get('title')}
        </Typography>
      </Grid>

      <Grid
        item
        lg={4}
        xs={12}
      >
        <Typography
          variant="caption"
          color="textSecondary"
          className={classes.titles}
        >
          {i18n.get('snippet')}
        </Typography>
      </Grid>
    </Grid>
  )
}
