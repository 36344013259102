import { connect } from 'react-redux'

import DistributionLists from 'components/contact_management/distribution_lists/DistributionLists'

import {
  getCmDistributionLists,
  getCmDistributionListPage,
  getCmListsTotal,
  getCmDistributionListsSearchRunning,
  getCmDistributionListSearchQueries,
  getCDeskIsDistributionListsLocked,
  getCDeskPlan
} from 'selectors'

import { setDistributionListsPage } from 'actions/contact_management'

const mapStateToProps = state => ({
  distributionLists: getCmDistributionLists(state),
  page: getCmDistributionListPage(state),
  total: getCmListsTotal(state),
  loading: getCmDistributionListsSearchRunning(state),
  hasSearchQueries: getCmDistributionListSearchQueries(state).size > 0,
  isDistListsLocked: getCDeskIsDistributionListsLocked(state),
  distListsLimit: getCDeskPlan(state).get('distributionListsLimit')
})

export default connect(mapStateToProps, {
  onPageChange: setDistributionListsPage
})(DistributionLists)
