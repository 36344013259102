import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as ExportActions from 'actions/export'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

export default handleActions({
  [ExportActions.fetchDispatchLogSuccess]: (_state, { payload }) => fromJS(payload),
  [ExportActions.cancelDispatchSuccess]: (state, { payload: id }) => (
    state
      .updateIn([state.findIndex(d => d.get('id') === id), 'dispatchStatus'], () => 'CANCELLED')
      .updateIn([state.findIndex(d => d.get('id') === id), 'scheduledJob', 'state'], () => 'CANCELLED')
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
