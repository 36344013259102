import { connect } from 'react-redux'

import AiSummariesOrderForm from 'components/paid_features/ai_summaries_order_form'

import {
  getStaticPaidFeatureById,
  getAiSummariesPaidFeatureStatus
} from 'selectors'

const mapStateToProps = state => ({
  paidFeature: getStaticPaidFeatureById(state, 'ai_summaries'),
  status: getAiSummariesPaidFeatureStatus(state)
})

export default connect(mapStateToProps)(AiSummariesOrderForm)
