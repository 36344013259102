import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import detail from './detail'
import assignCustomTagsToNews from './assign_custom_tags_to_news'
import assignCustomTagsToGroup from './assign_custom_tags_to_group'
import groups from './groups'

export default combineReducers({
  ui,
  data,
  detail,
  assignCustomTagsToNews,
  assignCustomTagsToGroup,
  groups
})
