export const getUser = state => state.user
export const getUserId = state => getUser(state).get('id')
export const getSid = state => getUser(state).get('sid')
export const getUserFirstName = state => getUser(state).get('firstName')
export const getUserLastName = state => getUser(state).get('lastName')
export const getAccessToken = state => getUser(state).get('accessToken')
export const getCapabilities = state => getUser(state).get('capabilities')
export const getRegistrationException = state => getUser(state).get('registrationException')
export const getUserCreated = state => getUser(state).get('created')
export const getRequestRunning = state => getUser(state).get('requestRunning')
export const getUserLocale = state => getUser(state).get('languageShort')
export const getLocale = state => getUser(state).get('locale')
export const getIsAuthenticated = state => getUser(state).get('isAuthenticated')
export const getCookieOption = state => getUser(state).get('cookieOption')
export const getGlobalClusterDefault = state => getUser(state).get('globalClusterDefault')
export const isYouTubeReview = state => getUser(state).get('id') === 53042
export const hasCapability = (state, cap) => getCapabilities(state).get(cap)
export const getIsEmployee = state => getUser(state).get('isEmployee')

export const getPrefersDarkMode = state => getUser(state).get('prefersDarkMode')
export const getDarkMode = state => {
  const isAuthenticated = getIsAuthenticated(state)
  const darkMode = getUser(state).get('darkMode')

  return isAuthenticated && (darkMode === null ? getPrefersDarkMode(state) : darkMode)
}
