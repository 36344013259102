/* global window */
import { useEffect, useRef } from 'react'

/* eslint-disable no-param-reassign */
export default function useBeforeUnload(fn, unsavedChanges = false) {
  const eventListenerRef = useRef()

  /* istanbul ignore next */
  useEffect(() => {
    eventListenerRef.current = event => {
      const returnValue = fn?.(event)

      // Handle legacy `event.returnValue` property
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      if (typeof returnValue === 'string') {
        event.returnValue = returnValue
      }

      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
      if (event.defaultPrevented) {
        event.returnValue = ''
      }

      return event
    }
  }, [fn])

  useEffect(() => {
    const eventListener = e => eventListenerRef.current(e)

    if (unsavedChanges) {
      window.addEventListener('beforeunload', eventListener)
    }

    return () => {
      window.removeEventListener('beforeunload', eventListener)
    }
  }, [unsavedChanges])
}
