import { connect } from 'react-redux'

import Pagination from 'components/anewstip/results/pagination'

import {
  getAnewstipFiltersData,
  getAnewstipDrillDownFilters,
  getDrillDownEntity,
  getIsLimitedFunctionality
} from 'selectors/anewstip'

import {
  setFiltersForm as onResultsFilterChange,
  setDrillDownFiltersForm as onDrillDownFilterChange,
  searchJournalistsStart as onSearchJournalists,
  searchOutletsStart as onSearchOutlets,
  searchArticlesStart as onSearchArticles,
  searchDrillDownJournalistsStart as onDrillDownJournalists,
  searchDrillDownOutletsStart as onDrillDownOutlets,
  searchDrillDownArticlesStart as onDrillDownArticles
} from 'actions/anewstip'

const mapStateToProps = (state, ownProps) => ({
  resultsTab: ownProps.resultsTab,
  results: ownProps.results,
  resultsFilters: getAnewstipFiltersData(state),
  drillDownFilters: getAnewstipDrillDownFilters(state),
  drillDownEntity: getDrillDownEntity(state),
  isLimitedFunctionality: getIsLimitedFunctionality(state)
})

export default connect(
  mapStateToProps,
  {
    onResultsFilterChange,
    onDrillDownFilterChange,
    onSearchJournalists,
    onSearchOutlets,
    onSearchArticles,
    onDrillDownJournalists,
    onDrillDownOutlets,
    onDrillDownArticles
  }
)(Pagination)
