import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, List } from 'immutable'
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { makeStyles } from 'tss-react/mui'

import { createLoadable } from 'utils/loadable'
import ContentContainer from 'components/content_container'
import { FlexList, FlexListItem } from 'components/flex_list'
import Preview from 'components/administration/settings/preview'

import AppBar from 'containers/AppBar'
import { Button, Select, Input } from 'containers/themed'
import ExportFilenameInput from 'components/export_filename_input'
import { Capabilities, DateTypes, NewsPageViewTypes, InitialRoutes } from 'static/constants'
import ColorPicker from 'components/color_picker'

import {
  Divider,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Grid
} from '@mui/material'

const useStyles = makeStyles()(theme => ({
  content: {
    paddingTop: 10
  },
  cardContent: {
    paddingBottom: 0
  },
  colorPickerHeader: {
    marginBottom: 10
  },
  editIcon: {
    color: 'white',
    marginRight: 5,
    height: '100%'
  },
  whiteEditIcon: {
    color: theme.palette.grey[700]
  },
  card: {
    marginBottom: 10
  },
  colorContainer: {
    cursor: 'pointer'
  },
  color: {
    borderRadius: 4,
    width: '100%',
    minWidth: 'auto',
    height: 30,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  whiteColor: {
    border: `1px solid ${theme.palette.grey[700]}`
  },
  chart: {
    height: 200
  },
  panelDetails: {
    flexDirection: 'column'
  },
  container: {
    [theme.breakpoints.up('md')]: {
      width: '75%',
      margin: '0 auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    }
  },
  chartColorButton: {
    [theme.breakpoints.up('sm')]: {
      float: 'right'
    }
  }
}))

const colorIndices = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

const Chart = createLoadable(() => import(/* webpackChunkName: "Chart" */ 'containers/charts/Chart'))

const listData = [3, 8, 6, 2, 1, 4, 5, 6, 1, 2, 10].map((count, index) => ({
  name: `data_${index + 1}`,
  count,
  stats: {
    buzz: count
  }
}))

const charts = fromJS([
  {
    chart: {
      type: 'area'
    },
    data: listData
  },
  {
    chart: {
      type: 'treemap'
    },
    data: listData
  },
  {
    chart: {
      type: 'bar'
    },
    data: listData
  },
  {
    chart: {
      type: 'pie'
    },
    data: listData
  },
  {
    chart: {
      firstLevel: 'data',
      type: 'rectangle'
    },
    data: {
      current: 3,
      previous: 4
    }
  }
])

export default function Settings({
  caps,
  config,
  i18n,
  isValidFilename,
  pdfDispatchConfigs,
  darkmode,
  isPlotlights,
  onChange,
  onSave,
  onThemeClick,
  reset,
  resetColors,
  themes,
  updateConfigRequestStarted,
  validateFilename
}) {
  const { classes, cx } = useStyles()
  const [themeName, setThemeName] = useState(null)
  const dateType = config.get('defaultDateType')
  const mode = darkmode ? 'dark' : 'light'

  const handleChange = (name, value) => onChange(config.remove('isValidFilename').set(name, value))

  const handleColorChange = (type, color) => {
    const newColors = config.get('colors').setIn(type, color)
    const newConfig = config.mergeIn(['colors'], newColors)
    onChange(newConfig)
  }

  const renderColorPicker = (colorKeys, useRgb = true) => {
    const newColor = config.getIn(['colors'].concat(colorKeys)) || '#FFFFFF'

    return (
      <ColorPicker
        handle={(
          <div className={classes.colorContainer}>
            <div
              className={cx(classes.color, newColor === '#FFFFFF' && classes.whiteColor)}
              style={{ backgroundColor: newColor }}
            >
              <EditIcon
                classes={{ root: cx(classes.editIcon, newColor === '#FFFFFF' && classes.whiteEditIcon) }}
              />
            </div>
          </div>
        )}
        value={newColor}
        defaultValue="#FFFFFF"
        useRgb={useRgb}
        onChange={color => {
          handleColorChange(colorKeys, color)
        }}
      />
    )
  }

  const renderPdfDispatchConfigSelect = () => {
    const pdfDispatchConfigOptions = pdfDispatchConfigs.map(dc => (
      { value: dc.get('id'), label: dc.get('name') }
    ))

    return (
      <>
        <br />
        <br />
        <Select
          variant="outlined"
          onChange={value => handleChange('defaultPdfDispatchConfigId', value.value)}
          options={pdfDispatchConfigOptions.toJS()}
          value={config.get('defaultPdfDispatchConfigId')}
          label={i18n.get('select_dispatch_config_for_single_article_pdf')}
        />
      </>
    )
  }

  const dateFilterSettings = [
    { value: 0, label: i18n.get('no_preferences') },
    { value: 1, label: i18n.get('today') },
    { value: 2, label: dateType === DateTypes.MEDIA_REVIEW ? i18n.get('last_mediareview') : i18n.get('newest_article_date') },
    { value: 3, label: i18n.get('this_week') },
    { value: 4, label: i18n.get('this_month') },
    { value: 5, label: i18n.get('this_quarter') },
    { value: 6, label: i18n.get('this_year') },
    { value: 7, label: i18n.get('last_7_days') },
    { value: 8, label: i18n.get('last_30_days') },
    { value: 9, label: i18n.get('last_60_days') },
    { value: 10, label: i18n.get('last_90_days') }
  ]

  const dateTypeFilterSettings = [
    { value: DateTypes.MEDIA_REVIEW, label: i18n.get('media_review_date') },
    { value: DateTypes.ARTICLE, label: i18n.get('article_date') }
  ]

  const groupingSetting = [
    { value: 1, label: i18n.get('channel') },
    { value: 2, label: i18n.get('media_review_codes') }
  ]

  const sortingSettings = [
    { value: 1, label: i18n.get('date') },
    { value: 2, label: i18n.get('reach') },
    { value: 3, label: i18n.get('interactions') }
  ]

  if (caps.get(Capabilities.HAS_CUSTOM_TAGS_MODULE)) {
    groupingSetting.push({ value: 3, label: i18n.get('pin_board') })
  }

  if (caps.get(Capabilities.HAS_NEWSGUARD)) {
    groupingSetting.push({ value: 4, label: i18n.get('newsguard_ranks') })
  }

  const newsPageViewSettings = [
    { value: NewsPageViewTypes.LISTVIEW, label: i18n.get('list_view') },
    { value: NewsPageViewTypes.CARDVIEW, label: i18n.get('card_view') }
  ]

  const initialRouteSettings = [
    { value: '', label: i18n.get('default') }
  ]

  if (caps.get('HAS_DASHBOARD_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.DASHBOARD, label: i18n.get('dashboard')
    })
  }

  if (caps.get('HAS_NEWS_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.NEWSBOARD, label: i18n.get('news_board')
    })
  }

  if (caps.get('HAS_PROFILE_MONITORING_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.PROFILE_MONITORING, label: i18n.get('profile_monitoring')
    })
  }

  if (caps.get('HAS_NEWS_POOL_MODULE')) {
    initialRouteSettings.push({
      value: isPlotlights ? InitialRoutes.MEDIA_MONITORING : InitialRoutes.SEARCH_POOL, label: i18n.get('search_pool')
    })
  }

  if (caps.get('HAS_MEDIA_REVIEW_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.MEDIA_REVIEWS, label: i18n.get('media_reviews')
    })
  }

  if (caps.get('HAS_CONTENT_DESK_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.CONTENT_DESK, label: i18n.get('content_desk')
    })
  }

  if (caps.get('HAS_CONTACTS_MANAGEMENT_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.CONTACT_DATABASE_MODULE, label: i18n.get('my_contacts')
    })
  }

  if (caps.get('HAS_CONTACTS_MANAGEMENT_ANEWSTIP')) {
    initialRouteSettings.push({
      value: InitialRoutes.ANEWSTIP, label: i18n.get('journalist_search')
    })
  }

  if (caps.get('HAS_THE_MAX_MODULE')) {
    initialRouteSettings.push({
      value: InitialRoutes.THEMAX, label: i18n.get('themax')
    })
  }

  const renderActions = () => (
    <AccordionActions>
      <Button
        startIcon={<SettingsBackupRestoreIcon />}
        disabled={updateConfigRequestStarted}
        onClick={() => reset()}
      >
        {i18n.get('reset_defaults')}
      </Button>
      <Button
        startIcon={<SaveIcon />}
        variant="contained"
        color="primary"
        loading={updateConfigRequestStarted}
        disabled={updateConfigRequestStarted || !isValidFilename}
        onClick={() => onSave()}
      >
        {i18n.get('save')}
      </Button>
    </AccordionActions>
  )

  return (
    <>
      <AppBar title={i18n.get('settings')} />

      <ContentContainer className={classes.content}>
        <div className={classes.container}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('general')}</Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('dateFilterSettingId', value.value)}
                    options={dateFilterSettings}
                    value={config.get('dateFilterSettingId')}
                    label={i18n.get('initial_mediareview_date')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('defaultDateType', value.value)}
                    options={dateTypeFilterSettings}
                    value={dateType}
                    label={i18n.get('initial_mediareview_date_type')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('grootGroupingSettingId', value.value)}
                    options={groupingSetting}
                    value={config.get('grootGroupingSettingId')}
                    label={i18n.get('initial_mediareview_theme')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('defaultSortingId', value.value)}
                    options={sortingSettings}
                    value={config.get('defaultSortingId')}
                    label={i18n.get('initial_sorting')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('defaultNewsPageView', value.value)}
                    options={newsPageViewSettings}
                    value={config.get('defaultNewsPageView')}
                    label={i18n.get('initial_news_page_view')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                >
                  <Select
                    variant="outlined"
                    onChange={value => handleChange('initialRoute', value.value)}
                    options={initialRouteSettings}
                    value={config.get('initialRoute') || ''}
                    label={i18n.get('initial_page')}
                    displayEmpty
                  />
                </Grid>
              </Grid>
            </AccordionDetails>

            {renderActions()}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('color_scheme')}</Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <div>
                {
                  themes.map(
                    (t, key) => (
                      <Button
                        key={key}
                        disabled={config.getIn(['colors']).equals(t.getIn(['colors', mode]))}
                        onClick={
                          () => {
                            setThemeName(t.get('name'))
                            onThemeClick({ name: t.get('name'), mode })
                          }
                        }
                      >
                        {i18n.get(t.get('labelKey'), { number: key }).toUpperCase()}
                      </Button>
                    )
                  )
                }

                {!config.get('selectedTheme') && (
                  <Button
                    disabled
                  >
                    {i18n.get('customized')}
                  </Button>
                )}

                <Button
                  onClick={() => resetColors({ name: (themeName || 'Default'), mode })}
                  classes={{ root: classes.chartColorButton }}
                >
                  {i18n.get('back_to_standard')}
                </Button>
              </div>

              <br />
              <Divider />
              <br />

              <FlexList columns={4}>
                <FlexListItem>
                  <p>{`${i18n.get('primary_color')}`}</p>
                  {renderColorPicker(['primary'], false)}
                </FlexListItem>
                <FlexListItem>
                  <p>{`${i18n.get('accent_color')}`}</p>
                  {renderColorPicker(['accent'], false)}
                </FlexListItem>
                <FlexListItem>
                  <p>{i18n.get('highlighting_color')}</p>
                  {renderColorPicker(['highlighting'])}
                </FlexListItem>
                <FlexListItem>
                  <p>{i18n.get('highlighting_background_color')}</p>
                  {renderColorPicker(['highlightingBackground'])}
                </FlexListItem>
              </FlexList>

              <br />
              <Divider />
              <br />

              <Typography>{i18n.get('chart_colors')}</Typography>

              <FlexList columns={10}>
                {colorIndices.map(i => (
                  <FlexListItem key={i}>
                    <p>{`${i18n.get('color')} ${i}`}</p>
                    {renderColorPicker(['charts', `color${i >= 10 ? '' : 0}${i}`])}
                  </FlexListItem>
                ))}
              </FlexList>

              <br />
              <Divider />
              <br />

              <Typography>
                {i18n.get('preview')}
              </Typography>

              <Preview
                i18n={i18n}
                config={config}
              />

              <FlexList columns={3}>
                {charts.map((chart, index) => (
                  <FlexListItem
                    key={index}
                    className={classes.chart}
                  >
                    <Chart
                      chart={chart.get('chart')}
                      data={chart.get('data')}
                    />
                  </FlexListItem>
                ))}
              </FlexList>
            </AccordionDetails>

            {renderActions()}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('news_layout')}</Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <FormControlLabel
                label={i18n.get('show_flags')}
                control={(
                  <Switch
                    checked={config.get('showFlags')}
                    onChange={e => handleChange('showFlags', e.target.checked)}
                  />
                )}
              />
            </AccordionDetails>

            {renderActions()}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('export_settings')}</Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <ExportFilenameInput
                icon="insert_drive_file"
                value={config.get('exportFilename')}
                onChange={value => handleChange('exportFilename', value)}
                validate={validateFilename}
                valid={isValidFilename}
              />
              <br />
              <Input
                type="text"
                icon="picture_as_pdf"
                value={config.get('pdfCoverTitle')}
                label={i18n.get('pdf_cover_title')}
                multiline
                rows={5}
                onChange={event => handleChange('pdfCoverTitle', event.target.value)}
              />
              {renderPdfDispatchConfigSelect()}
            </AccordionDetails>

            {renderActions()}
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{i18n.get('media_reviews')}</Typography>
            </AccordionSummary>

            <AccordionDetails classes={{ root: classes.panelDetails }}>
              <FormControlLabel
                label={i18n.get('expand_all_topics')}
                control={(
                  <Switch
                    checked={config.get('expandTopics')}
                    onChange={e => handleChange('expandTopics', e.target.checked)}
                  />
                )}
              />
              <FormControlLabel
                label={i18n.get('show_summaries')}
                control={(
                  <Switch
                    checked={config.get('showSummaries')}
                    onChange={e => handleChange('showSummaries', e.target.checked)}
                  />
                )}
              />
              <FormControlLabel
                label={i18n.get('show_snippets')}
                control={(
                  <Switch
                    checked={config.get('showSnippets')}
                    onChange={e => handleChange('showSnippets', e.target.checked)}
                  />
                )}
              />
            </AccordionDetails>

            {renderActions()}
          </Accordion>
        </div>
      </ContentContainer>
    </>
  )
}

Settings.propTypes = {
  config: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  themes: PropTypes.object.isRequired,
  caps: PropTypes.object.isRequired,
  updateConfigRequestStarted: PropTypes.bool.isRequired,
  darkmode: PropTypes.bool.isRequired,
  isValidFilename: PropTypes.bool.isRequired,
  pdfDispatchConfigs: PropTypes.instanceOf(List).isRequired,
  isPlotlights: PropTypes.bool.isRequired,

  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  resetColors: PropTypes.func.isRequired,
  validateFilename: PropTypes.func.isRequired,
  onThemeClick: PropTypes.func.isRequired
}
