import { connect } from 'react-redux'

import SaveTemplateDialog from
  'components/content_desk/content_edit_dialog/content_editor/email_editor/save_template_dialog/SaveTemplateDialog'

import { getCDeskShowSaveTemplateDialog, getETLibrarySaveRequestRunning, getCapabilities } from 'selectors'

import { closeSaveTemplateDialog } from 'actions/content_desk'

import {
  setTemplateFormData as onChange,
  saveTemplateStart as onSave
} from 'actions/email_templates_library'

const mapStateToProps = state => ({
  open: getCDeskShowSaveTemplateDialog(state),
  loading: getETLibrarySaveRequestRunning(state),
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeSaveTemplateDialog,
    onChange,
    onSave
  }
)(SaveTemplateDialog)
