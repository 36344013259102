import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import translations from 'static/translations.json'
import translationsPlotlights from 'static/translations_plotlights.json'

import * as UserActions from 'actions/user'
import * as SavedDashboardActions from 'actions/saved_dashboard'
import * as SavedChartsActions from 'actions/saved_charts'
import * as WhitelabelActions from 'actions/whitelabel'
import { getLocale } from 'utils/locale'

export const getTranslationsByLocale = (language, config = translations) => (
  Object.keys(config).reduce((acc, element) => ({ ...acc, [element]: config[element][language] }), {})
)

const locale = getLocale()

const englishTranslations = fromJS(getTranslationsByLocale('en', translations))
const englishTranslationsPlotlights = fromJS(getTranslationsByLocale('en', translationsPlotlights))

const buildI18n = (loc, isPlotlights) => {
  let newTranslations = fromJS(getTranslationsByLocale(loc, translations))
  let english = englishTranslations

  if (isPlotlights) {
    newTranslations = newTranslations.merge(fromJS(getTranslationsByLocale(loc, translationsPlotlights)))
    english = englishTranslations.merge(englishTranslationsPlotlights)
  }

  /* istanbul ignore next */
  return {
    get: (value, replacements = {}, forceEnglish = false) => {
      let translation = newTranslations.get(value) || english.get(value) || value || ''

      if (forceEnglish) {
        translation = english.get(value) || value || ''
      }

      return Object.keys(replacements)
        .reduce(
          (acc, key) => acc.replaceAll(`{${key}}`, replacements[key]),
          translation
        )
        .replace(/\{.*?\}/g, '')
    }
  }
}

export const initialState = {
  translations: buildI18n(locale),
  isPlotlights: false
}

export default handleActions({
  [UserActions.setUser]: (state, { payload: { languageShort } }) => (
    { ...state, translations: buildI18n(languageShort, state.isPlotlights) }
  ),

  [WhitelabelActions.whitelabelRequestSuccess]: (state, { payload }) => (
    { ...state, isPlotlights: payload.theme === 'plotlights' }
  ),

  [combineActions(
    SavedDashboardActions.fetchSavedDashboardSuccess,
    SavedChartsActions.fetchSavedChartSuccess
  )]: (state, { payload: { locale: loc } }) => (
    { ...state, translations: buildI18n(loc, state.isPlotlights) }
  )
}, initialState)
