import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import AssignedReactions from './assigned_reactions'
import ReplaceReaction from './replace_reaction'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center'
  }
})

export default function NrxReactions({
  indicatorClassName,
  reactions: reactionsFromProps,
  reactionTypes,
  onReactionClick,
  user
}) {
  const { classes } = useStyles()
  const reactions = reactionsFromProps || Map({})

  return (
    <div className={classes.container}>
      <AssignedReactions
        indicatorClassName={indicatorClassName}
        reactions={reactions}
        reactionTypes={reactionTypes}
      />

      <ReplaceReaction
        reactions={reactions}
        reactionTypes={reactionTypes}
        onReactionClick={onReactionClick}
        user={user}
      />
    </div>
  )
}

NrxReactions.propTypes = {
  indicatorClassName: PropTypes.string,
  reactions: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(Map).isRequired,
  reactionTypes: PropTypes.instanceOf(List).isRequired,

  onReactionClick: PropTypes.func
}
