import { connect } from 'react-redux'

import UploadResultDialog from 'components/contact_management/contacts/upload_contacts/upload_result_dialog/UploadResultDialog'

import {
  closeUploadResultDialog,
  downloadContactsExcelStart
} from 'actions/contact_management'

import {
  getCmUploadResultDialogOpen,
  getCmUploadResult,
  getCmRequestRunning,
  getCmAutoCreateDistributionList,
  getCmAutoAddToExistingList
} from 'selectors'

const mapStateToProps = state => ({
  open: getCmUploadResultDialogOpen(state),
  data: getCmUploadResult(state),
  requestRunning: getCmRequestRunning(state),
  createDistributionList: getCmAutoCreateDistributionList(state),
  addToExistingDistributionList: getCmAutoAddToExistingList(state)
})

export default connect(mapStateToProps, {
  onClose: closeUploadResultDialog,
  onExcelDownload: downloadContactsExcelStart
})(UploadResultDialog)
