import { connect } from 'react-redux'

import SelectedDistributionListsBar from 'components/contact_management/selected_distribution_lists_bar/SelectedDistributionListsBar'
import { clearSelectedLists, openDistributionListMergeDialog } from 'actions/contact_management'
import { getCmSelectedLists, getCmListData } from 'selectors'

const mapStateToProps = state => ({
  lists: getCmSelectedLists(state).get('lists'),
  contactsBarOpen: getCmListData(state).get('contacts').size > 0
})

export default connect(mapStateToProps, {
  onClick: openDistributionListMergeDialog,
  onClear: clearSelectedLists
})(SelectedDistributionListsBar)
