import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'
import { Box, Divider, Typography } from '@mui/material'
import TagChip from 'components/contact_management/tag_chip'

export default function ContactTags({ contact }) {
  const customTags = contact.get('customTags')
  const i18n = useI18n()

  if (!customTags || customTags.isEmpty()) {
    return null
  }

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Typography
        variant="subtitle2"
        pb={2}
        sx={{ fontSize: '90%' }}
      >{i18n.get('tags')}
      </Typography>
      <Box>
        {customTags.map(tag => (
          <TagChip
            key={`tag__${tag.get('id')}`}
            label={tag.get('name')}
          />
        ))}
      </Box>
    </>
  )
}

ContactTags.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired
}
