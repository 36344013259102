import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { IconButton } from 'containers/themed'

import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

const DistributionListsFilter = ({ distObject, isDistListsLocked, isActiveFilter, onClick, onRemove }) => {
  const i18n = useI18n()

  if (isActiveFilter) {
    return (
      <IconButton
        onClick={() => onRemove()}
        title={`${i18n.get('delete')} ${i18n.get('filter')}`}
      >
        <FilterAltOffIcon />
      </IconButton>
    )
  }

  return (
    <IconButton
      disabled={isDistListsLocked}
      onClick={() => onClick({ label: `${i18n.get('distribution_list')}: ${distObject.get('name')}`, value: distObject.get('id') })}
      title={`${i18n.get('filter')} ${i18n.get('contacts')}`}
    >
      <FilterAltIcon />
    </IconButton>
  )
}

DistributionListsFilter.propTypes = {
  distObject: PropTypes.object.isRequired,
  isDistListsLocked: PropTypes.bool.isRequired,
  isActiveFilter: PropTypes.bool.isRequired,

  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default DistributionListsFilter
