import { connect } from 'react-redux'

import WelcomeDialog from 'components/themax/welcome_dialog'
import { closeWelcomeDialog, openOrderDialog } from 'actions/themax'
import { getShowWelcomeDialog, getThemaxPaidFeatureStatus } from 'selectors'

const mapStateToProps = state => ({
  open: getShowWelcomeDialog(state),
  status: getThemaxPaidFeatureStatus(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeWelcomeDialog,
    onUnlockClick: openOrderDialog
  }
)(WelcomeDialog)
