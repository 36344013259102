import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap } from 'immutable'

import { Box, Divider } from '@mui/material'
import SmIcon from 'components/contact_management/contact_details_dialog/sm_icon'

export default function ContactChannels({ contact }) {
  const hasSmChannels = contact.get('facebookUrl')
    || contact.get('twitterUrl')
    || contact.get('tiktokUrl')
    || contact.get('linkedinUrl')
    || contact.get('threadsUrl')
    || contact.get('instagramUrl')
    || contact.get('youtubeUrl')
    || contact.get('otherUrl')

  if (!hasSmChannels) {
    return null
  }

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Box>
        <SmIcon
          channel="tiktok"
          url={contact.get('tiktokUrl')}
        />
        <SmIcon
          channel="facebook"
          url={contact.get('facebookUrl')}
        />
        <SmIcon
          channel="twitter"
          url={contact.get('twitterUrl')}
        />
        <SmIcon
          channel="linkedin"
          url={contact.get('linkedinUrl')}
        />
        <SmIcon
          channel="threads"
          url={contact.get('threadsUrl')}
        />
        <SmIcon
          channel="instagram"
          url={contact.get('instagramUrl')}
        />
        <SmIcon
          channel="youtube"
          url={contact.get('youtubeUrl')}
        />
        <SmIcon
          channel="other"
          url={contact.get('otherUrl')}
        />
      </Box>
    </>
  )
}

ContactChannels.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired
}
