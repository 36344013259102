import { call, takeEvery, all } from 'redux-saga/effects'

import * as Actions from 'actions/ga'
import * as GA from 'utils/ga'

export function* init() {
  yield call(GA.init)
}

export function* event({ payload: eventData }) {
  yield call(GA.event, eventData)
}

export function* pageView({ payload: page }) {
  const trackPage = page.replace(/reset_password.*/, 'reset_password')
  yield call(GA.pageView, trackPage)
}

export function* setOption({ payload: { key, value } }) {
  yield call(GA.setGaOption, key, value)
}

export function* watchInit() {
  yield takeEvery(Actions.init, init)
}

export function* watchEvent() {
  yield takeEvery(Actions.event, event)
}

export function* watchPageView() {
  yield takeEvery(Actions.pageView, pageView)
}

export function* watchSetOption() {
  yield takeEvery(Actions.setOption, setOption)
}

export default function* gaSaga() {
  yield all([
    watchInit(),
    watchEvent(),
    watchPageView(),
    watchSetOption()
  ])
}
