import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import Chart from 'containers/charts/Chart'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(() => ({
  chart: {
    height: 250,
    width: '100%'
  }
}))

export default function ChartExport({ chart, colors }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const layout = chart.get('layout', Map()).toJS()
  const chartOpts = chart.get('opts', Map()).toJS()
  const data = chart.get('data')
  const chartWithTranslations = chart.update('label', label => `${i18n.get(label)}`)

  return (
    <div
      className={classes.chart}
      style={layout}
    >
      <Chart
        chart={chartWithTranslations}
        data={data}
        chartOpts={chartOpts}
        card
        colors={colors}
      />
    </div>
  )
}

ChartExport.propTypes = {
  chart: PropTypes.instanceOf(Map),
  colors: PropTypes.instanceOf(Map)
}
