import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControl,
  FormHelperText
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { Map, List } from 'immutable'

import useI18n from 'hooks/useI18n'
import { validDomain } from 'utils/darknet'

import { makeStyles } from 'tss-react/mui'
import { Button, RaisedSecondaryButton } from 'containers/themed'

const MAIL_DOMAINS = 'emailDomains'
const WEB_DOMAINS = 'webDomains'

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    marginTop: theme.spacing(3)
  },
  chip: {
    backgroundColor: theme.palette.background.default
  },
  chips: {
    marginTop: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1)
    }
  },
  dialogTitle: {
    borderBottomStyle: 'solid',
    borderBottomColor: grey[400],
    borderBottomWidth: '1px',
    marginBottom: '1em'
  },
  dialogContent: {
    paddingTop: '20px !important'
  }
}))

export default function ScoreQueryDialog({ data, open, onClose, onSave, onUpdate, editing }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const [webDomainData, setWebDomainData] = useState('')
  const [emailDomainData, setEmailDomainData] = useState('')
  const [error, setError] = useState({ email: false, web: false })

  const fieldsAreEmpty = !data.get('name') || !data.get(WEB_DOMAINS).size || !data.get(MAIL_DOMAINS).size
  const disabled = error.email || error.web || fieldsAreEmpty
  const isValid = (value, context) => value !== '' && (!validDomain(value) || data.get(context).includes(value))

  const handleSubmit = () => {
    onSave()
    onClose()
  }

  const handleChange = e => {
    const { name, value } = e.target
    onUpdate({ [name]: value })
  }

  const handleWebDelete = chipToDelete => () => {
    onUpdate({ webDomains: data.get(WEB_DOMAINS).filter(domain => domain !== chipToDelete.label) })
  }

  const handleEmailDelete = chipToDelete => () => {
    onUpdate({ emailDomains: data.get(MAIL_DOMAINS).filter(domain => domain !== chipToDelete.label) })
  }

  const handleDomainClick = () => {
    if (isValid(webDomainData, WEB_DOMAINS)) {
      setError({ ...error, web: true })

      return
    }

    setError({ ...error, web: false })
    onUpdate({ webDomains: List([...data.get(WEB_DOMAINS), webDomainData]) })
    setWebDomainData('')
  }

  const handleMailClick = () => {
    if (isValid(emailDomainData, MAIL_DOMAINS)) {
      setError({ ...error, email: true })

      return
    }

    setError({ ...error, email: false })
    onUpdate({ emailDomains: List([...data.get(MAIL_DOMAINS), emailDomainData]) })
    setEmailDomainData('')
  }

  const handleMail = e => {
    if (e.key === 'Enter') {
      handleMailClick()
    }
  }
  const handleDomain = e => {
    if (e.key === 'Enter') {
      handleDomainClick()
    }
  }

  const renderChips = (chipsData, deleteFun) => {
    const formattedChips = chipsData.map((chip, index) => ({
      label: chip,
      key: index
    }))

    return (
      <div className={classes.chips}>
        {formattedChips.map(d => (
          <Chip
            key={d.key}
            label={d.label}
            onDelete={deleteFun(d)}
            className={classes.chip}
          />
        ))}
      </div>
    )
  }

  const renderForm = () => (
    <div className={classes.root}>
      <FormControl fullWidth>
        <TextField
          required
          label={i18n.get('name')}
          name="name"
          value={data.get('name')}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl
        fullWidth
        variant="outlined"
        className={classes.margin}
      >
        <InputLabel htmlFor="outlined-adornment-web-domains">{i18n.get('web_domains')} *</InputLabel>
        <OutlinedInput
          required
          id="outlined-adornment-web-domains"
          name="webDomains"
          placeholder="eg. plotlights.com, example.org"
          onKeyDown={handleDomain}
          onChange={e => setWebDomainData(e.target.value)}
          value={webDomainData}
          error={error.web}
          endAdornment={(
            <InputAdornment
              position="end"
              onClick={handleDomainClick}
              style={{ cursor: 'pointer' }}
            >Add
            </InputAdornment>
        )}
        />
        {error.web && (
          <FormHelperText error>
            {i18n.get('invalid_domain')}
          </FormHelperText>
        )}

        {renderChips(data.get(WEB_DOMAINS), handleWebDelete)}
      </FormControl>

      <FormControl
        fullWidth
        variant="outlined"
        className={classes.margin}
      >
        <InputLabel htmlFor="outlined-adornment-email-domains">{i18n.get('email_domains')} *</InputLabel>
        <OutlinedInput
          required
          id="outlined-adornment-email-domains"
          name="emailDomains"
          placeholder="eg. plotlights.com, example.org"
          value={emailDomainData}
          onKeyDown={handleMail}
          onChange={e => setEmailDomainData(e.target.value)}
          error={error.email}
          endAdornment={(
            <InputAdornment
              position="end"
              onClick={handleMailClick}
              style={{ cursor: 'pointer' }}
            >Add
            </InputAdornment>
        )}
        />
        {error.email && (
          <FormHelperText error>
            {i18n.get('invalid_domain')}
          </FormHelperText>
        )}
        {renderChips(data.get(MAIL_DOMAINS), handleEmailDelete)}
      </FormControl>
    </div>
  )

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>
        {editing ? i18n.get('edit') : i18n.get('create_query')}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {renderForm()}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose()}
        >
          {i18n.get('close')}
        </Button>

        <RaisedSecondaryButton
          disabled={disabled}
          onClick={handleSubmit}
        >
          {i18n.get('save')}
        </RaisedSecondaryButton>
      </DialogActions>
    </Dialog>
  )
}

ScoreQueryDialog.defaultProps = {
  editing: true
}

ScoreQueryDialog.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  editing: PropTypes.bool,
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
