import hexRgb from 'hex-rgb'

/* eslint-disable no-bitwise,no-plusplus */
const stringToHash = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash &= hash
  }

  return hash
}

// Converts the hash to a random HEX string
export const hexColorFromString = str => {
  if (str.length === 0) {
    return '#ffffff'
  }

  const hash = stringToHash(str)
  let color = '#'
  let value = 0

  for (let i = 0; i < 3; i++) {
    value = (hash >> (i * 8)) & 255
    color += (`00${value.toString(16)}`).substr(-2)
  }

  return color
}

const range = (hash, min, max) => {
  const diff = max - min
  const x = ((hash % diff) + diff) % diff

  return x + min
}

export const hslColorFromString = str => {
  const result = { h: 0, s: 100, l: 30 }

  if (str.length === 0) {
    return result
  }

  const hash = stringToHash(str)

  return {
    h: range(hash, 0, 360),
    s: 80,
    l: 50
  }
}
/* eslint-enable no-bitwise,no-plusplus */

export const rgbaColorFromHex = (hex, alpha) => {
  const rgbColor = hexRgb(hex)

  return `rgba(${rgbColor.red}, ${rgbColor.green}, ${rgbColor.blue}, ${alpha})`
}
