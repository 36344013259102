import { connect } from 'react-redux'

import EditorAiWorkspace from 'components/content_desk/editor_ai_workspace'

import { editorAiRequestStart } from 'actions/content_desk'

import { getCDeskEditorAiRequestRunning } from 'selectors'

const mapStateToProps = (state, { contentType }) => ({
  contentType,
  loading: getCDeskEditorAiRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onButtonClick: editorAiRequestStart
  }
)(EditorAiWorkspace)
