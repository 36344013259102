import isURL from 'validator/lib/isURL'
import { fromJS } from 'immutable'

import {
  isPressrelationsNewsChart,
  isGoogleAnalyticsChart,
  isMozChart,
  isYoutubeAnalyticsChart,
  isLinkedInAnalyticsChart,
  isFacebookAnalyticsChart,
  isTwitterAnalyticsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart,
  isSocialMediaAnalyticsFeed
} from 'utils/chart'

import { createImmutableSelector } from 'utils/reselect'
import { fillDashboard } from 'utils/charts'
import { NewsPageModules, GroupingTypes, DateTypes, StepTypes, SortingOptions } from 'static/constants'
import { getAllSavedSearches } from './saved_searches'
import { getDashboardAllowedDashboardsCount } from './config'
import { getUser } from './user'

// DATA
export const getDashboardsData = state => state.dashboard.get('data')
export const getDashboards = state => getDashboardsData(state).get('dashboards')
export const getDashboardAllowedDateRanges = state => getDashboardsData(state).get('allowedDateRanges')
export const getDashboardById = (state, id) => getDashboards(state).find(d => d.get('id') === id)

export const getDashboardLimitReached = state => getDashboards(state).size >= getDashboardAllowedDashboardsCount(state)

// UI
export const getDashboardsUI = state => state.dashboard.get('ui')
export const getDashboardIsEditing = state => getDashboardsUI(state).get('editing')
export const getDashboardAutoRefresh = state => getDashboardsUI(state).get('autoRefresh')
export const getDashboardRowHeight = state => getDashboardsUI(state).get('rowHeight')
export const getDashboardMaxRowCount = state => getDashboardsUI(state).get('maxRowCount')
export const getDashboardGridHeight = state => getDashboardsUI(state).get('gridHeight')
export const getDashboardShowChartDialog = state => getDashboardsUI(state).get('showChartDialog')
export const getDashboardShowMultiChartsDialog = state => getDashboardsUI(state).get('showMultiChartsDialog')
export const getDashboardShowEditDialog = state => getDashboardsUI(state).get('showEditDialog')
export const getDashboardShowDeleteDialog = state => getDashboardsUI(state).get('showDeleteDialog')
export const getDashboardSaving = state => getDashboardsUI(state).get('saving')
export const getDashboardDeleting = state => getDashboardsUI(state).get('deleting')
export const getDashboardExecuting = state => getDashboardsUI(state).get('executing')
export const getDashboardChartLimitReached = state => getDashboardsUI(state).get('chartLimitReached')
export const getDashboardPowerpointExporting = state => getDashboardsUI(state).get('powerpointExporting')
export const getDashboardImageDownloading = state => getDashboardsUI(state).get('imageDownloading')
export const getDashboardPdfDownloading = state => getDashboardsUI(state).get('pdfDownloading')

// SELECTED CHART
const getSelectedChartUi = state => state.dashboard.getIn(['selectedChart', 'ui'])
export const getDashboardSelectedChartIndex = state => getSelectedChartUi(state).get('index')
export const getDashboardSelectedChartDateMode = state => getSelectedChartUi(state).get('dateMode')
export const getDashboardSelectedChartStepType = state => getSelectedChartUi(state).get('stepType')
export const getDashboardSelectedChartAggregations = state => getSelectedChartUi(state).get('aggregations')
export const getDashboardSelectedChartAllowedDateIntervals = state => getSelectedChartUi(state).get('allowedDateIntervals')
export const getDashboardSelectedChartLoading = state => getSelectedChartUi(state).get('loading')
export const getDashboardSelectedChartGoogleAnalyticsChart = state => getSelectedChartUi(state).get('selectedGoogleAnalyticsChart')
export const getDashboardSelectedChartMozChart = state => getSelectedChartUi(state).get('selectedMozChart')
export const getDashboardSelectedChartLinkedInAnalyticsChart = state => getSelectedChartUi(state).get('selectedLinkedInAnalyticsChart')
export const getDashboardSelectedChartFacebookAnalyticsChart = state => getSelectedChartUi(state).get('selectedFacebookAnalyticsChart')
export const getDashboardSelectedChartTwitterAnalyticsChart = state => getSelectedChartUi(state).get('selectedTwitterAnalyticsChart')
export const getDashboardSelectedChartUploadedPhoto = state => getSelectedChartUi(state).get('uploadedPhoto')
export const getDashboardSelectedChartMultipleSavedSearchesMode = state => getSelectedChartUi(state).get('multipleSavedSearchesMode')

export const getSelectedChartData = state => state.dashboard.getIn(['selectedChart', 'data'])
export const getDashboardSelectedChartFirstLevel = state => getSelectedChartData(state).get('firstLevel')
export const getDashboardSelectedChartThirdLevel = state => getSelectedChartData(state).get('thirdLevel')

export const getDashboardSelectedChartGoogleAnalyticsViewId = state => (
  getSelectedChartData(state).getIn(['opts', 'googleAnalytics', 'viewId'])
)
export const getDashboardSelectedChartGoogleAnalyticsSegmentId = state => (
  getSelectedChartData(state).getIn(['opts', 'googleAnalytics', 'segmentId'])
)
export const getDashboardSelectedChartMozTargets = state => (
  getSelectedChartData(state).getIn(['opts', 'moz', 'targets']) || fromJS([])
)
export const getDashboardSelectedChartLinkedInAnalyticsOrganizationId = state => (
  getSelectedChartData(state).getIn(['opts', 'linkedInAnalytics', 'organizationId'])
)
export const getDashboardSelectedChartFacebookAnalyticsPageId = state => (
  getSelectedChartData(state).getIn(['opts', 'facebookAnalytics', 'pageId'])
)
export const getDashboardSelectedChartImageUrl = state => getSelectedChartData(state).getIn(['opts', 'imageUrl'])
export const getDashboardSelectedChartLabel = state => getSelectedChartData(state).get('label')
export const getDashboardSelectedChartTopLabel = state => getSelectedChartData(state).get('topLabel')
export const getDashboardSelectedChartSavedSearchModuleName = state => getSelectedChartData(state).getIn(['savedSearch', 'moduleName'])

export const getDashboardSelectedChart = createImmutableSelector(
  getDashboardSelectedChartAggregations,
  getSelectedChartData,
  (aggregations, chart) => chart.set('data', aggregations.get(chart.get('firstLevel'), null))
)

export const getDashboardHideSecondLevelsInDialog = createImmutableSelector(
  getDashboardSelectedChart,
  getDashboardSelectedChartMultipleSavedSearchesMode,
  (chart, isMulti) => chart.get('firstLevel') !== 'savedSearches' && isMulti
)

export const getDashboardSelectedChartSavedSearchId = state => getSelectedChartData(state).get('savedSearchId')
export const getDashboardSelectedChartAdditionalSavedSearchIds = state => (
  getSelectedChartData(state).get('additionalSavedSearchIds') || fromJS([])
)

export const getDashboardSelectedChartAvailableAdditonalSavedSearches = createImmutableSelector(
  getAllSavedSearches,
  getDashboardSelectedChartSavedSearchModuleName,
  getDashboardSelectedChartSavedSearchId,
  (savedSearches, moduleName, savedSearchId) => (
    savedSearches.filter(s => s.get('moduleName') === moduleName && s.get('id') !== savedSearchId)
  )
)

export const getDashboardSelectedChartGroupingType = state => {
  const chart = getSelectedChartData(state)

  return chart.get('groupingType', chart.getIn(['savedSearch', 'groupingType']))
}

export const getDashboardSelectedChartSortBy = state => {
  const chart = getSelectedChartData(state)

  return chart.get('sortBy', chart.getIn(['savedSearch', 'sortBy']))
}

export const getDashboardSelectedChartDateType = state => {
  const chart = getSelectedChartData(state)

  return chart.get('dateType', chart.getIn(['savedSearch', 'dateType'], DateTypes.MEDIA_REVIEW))
}

const groupingTypesByModuleName = moduleName => {
  if ([NewsPageModules.ANALYSIS, NewsPageModules.NEWS].indexOf(moduleName) !== -1) {
    return [
      GroupingTypes.CHANNEL,
      GroupingTypes.MEDIA_REVIEW_CODE,
      GroupingTypes.CUSTOM_TAG,
      GroupingTypes.UNGROUPED
    ]
  }

  if (moduleName === NewsPageModules.PROFILE_MONITORING) {
    return [
      GroupingTypes.PAGE_IDENTITY,
      GroupingTypes.UNGROUPED_PAGE_IDENTITY
    ]
  }

  return [
    GroupingTypes.CHANNEL,
    GroupingTypes.UNGROUPED
  ]
}

export const getDashboardSelectedChartGroupingOptions = createImmutableSelector(
  getSelectedChartData,
  getDashboardSelectedChartSavedSearchModuleName,
  (chart, moduleName) => {
    if (['topInfluencers', 'topPublications', 'imageFeed'].indexOf(chart.get('firstLevel')) !== -1) {
      return []
    }

    return groupingTypesByModuleName(moduleName)
  }
)

export const getDashboardSelectedChartDateRange = state => getSelectedChartData(state).get('dateRange')
export const getDashboardSelectedChartDateFrom = state => getSelectedChartData(state).get('dateFrom')
export const getDashboardSelectedChartDateTo = state => getSelectedChartData(state).get('dateTo')
export const getDashboardSelectedChartDateInterval = state => getSelectedChartData(state).get('dateInterval')
export const getDashboardSelectedChartGoogleAnalyticsViewSelected = state => (
  Boolean(getSelectedChartData(state).getIn(['opts', 'googleAnalytics', 'viewId']))
)
export const getDashboardSelectedChartLinkedInAnalyticsOrganizationSelected = state => (
  Boolean(getSelectedChartData(state).getIn(['opts', 'linkedInAnalytics', 'organizationId']))
)
export const getDashboardSelectedChartFacebookAnalyticsPageSelected = state => (
  Boolean(getSelectedChartData(state).getIn(['opts', 'facebookAnalytics', 'pageId']))
)

export const getDashboardDateSelected = createImmutableSelector(
  getSelectedChartData,
  getDashboardSelectedChartDateMode,
  (chart, dateMode) => {
    let isDateValid = false

    if (dateMode === 'date_range') {
      isDateValid = Boolean(chart.get('dateRange'))
    } else if (dateMode === 'relative') {
      isDateValid = Boolean(chart.get('dateRange') && chart.get('dateRange').startsWith('relative_range'))
    } else {
      isDateValid = Boolean(chart.get('dateFrom') && chart.get('dateTo'))
    }

    return isDateValid
  }
)

export const getDashboardPreviewLoadable = createImmutableSelector(
  getSelectedChartData,
  getDashboardDateSelected,
  getDashboardSelectedChartGoogleAnalyticsChart,
  getDashboardSelectedChartGoogleAnalyticsViewId,
  getDashboardSelectedChartMozChart,
  getDashboardSelectedChartMozTargets,
  getDashboardSelectedChartFacebookAnalyticsChart,
  getDashboardSelectedChartFacebookAnalyticsPageId,
  getDashboardSelectedChartTwitterAnalyticsChart,
  getDashboardSelectedChartLinkedInAnalyticsChart,
  getDashboardSelectedChartLinkedInAnalyticsOrganizationId,
  (chart, isDateValid, gaChart, viewId, mozChart, mozTargets, fbChart, pageId, twChart, liChart, organizationId) => {
    if (isPressrelationsNewsChart(chart)) {
      return Boolean(chart.get('savedSearchId')) && isDateValid
    }

    if (isGoogleAnalyticsChart(chart)) {
      return Boolean(gaChart && viewId) && isDateValid
    }

    if (isYoutubeAnalyticsChart(chart)) {
      return isDateValid
    }

    if (isMozChart(chart)) {
      return Boolean(mozChart) && isDateValid && !mozTargets.isEmpty()
    }

    if (isLinkedInAnalyticsChart(chart)) {
      if (isSocialMediaAnalyticsFeed(chart)) {
        return Boolean(organizationId)
      }

      return Boolean(liChart && organizationId) && isDateValid
    }

    if (isFacebookAnalyticsChart(chart)) {
      return Boolean(fbChart && pageId) && isDateValid
    }

    if (isTwitterAnalyticsChart(chart)) {
      return Boolean(twChart) && isDateValid
    }

    if (isTextChart(chart)) {
      return Boolean(chart.getIn(['opts', 'html']))
    }

    if (isExternalWidgetChart(chart)) {
      return Boolean(isURL((chart.getIn(['opts', 'customWidgetUrl']) || '').trim()) || chart.getIn(['opts', 'widgetContent']))
    }

    return false
  }
)

export const getDashboardChartDialogChartValid = createImmutableSelector(
  getSelectedChartData,
  getDashboardPreviewLoadable,
  getDashboardSelectedChartUploadedPhoto,
  (chart, loadable, uploadedPhoto) => {
    if (isImageChart(chart)) {
      const hasLabel = chart.get('label') || chart.get('topLabel')

      return Boolean(uploadedPhoto || hasLabel)
    }

    return loadable
  }
)

// SELECTED
export const getSelectedDashboard = state => state.dashboard.get('selected')

export const getSelectedDashboardFilled = createImmutableSelector(
  getSelectedDashboard,
  getDashboardIsEditing,
  (selectedDashboard, isEditing) => {
    if (isEditing) {
      return selectedDashboard
    }

    return fillDashboard(selectedDashboard)
  }
)
export const getSelectedDashboardCharts = state => getSelectedDashboard(state).get('charts')
export const getSelectedDashboardLayouts = state => getSelectedDashboard(state).get('layouts')
export const getSelectedDashboardChartsFilled = state => getSelectedDashboardFilled(state).get('charts')
export const getSelectedDashboardLayoutsFilled = state => getSelectedDashboardFilled(state).get('layouts')
export const getSelectedDashboardName = state => getSelectedDashboard(state).get('name')
export const getSelectedDashboardBackgroundColor = state => getSelectedDashboard(state).getIn(['config', 'colors', 'background'])
export const getSelectedDashboardBackgroundImageUrl = state => getSelectedDashboard(state).getIn(['config', 'backgroundImageUrl'])
export const getSelectedDashboardConfig = state => getSelectedDashboard(state).get('config')
export const getSelectedDashboardCardColor = state => getSelectedDashboard(state).getIn(['config', 'colors', 'card'])
export const getSelectedDashboardChartByIndexAndBreakpoint = (state, index, breakpoint) => (
  getSelectedDashboardCharts(state).getIn([breakpoint, index])
)

export const getSelectedDashboardBreakpoints = createImmutableSelector(
  getSelectedDashboardLayouts,
  layouts => layouts.reduce((acc, value, key) => (value.isEmpty() ? acc : acc.push(key)), fromJS([]))
)

// PREVIOUS
export const getPreviousDashboard = state => state.dashboard.get('previous')

// EDIT
export const getEditDashboard = state => state.dashboard.get('edit')

// DRILLDOWN
export const getDashboardDrilldown = state => state.dashboard.get('drilldown')
export const getDashboardDrilldownData = state => state.dashboard.getIn(['drilldown', 'data'])
export const getDashboardDrilldownDataGroups = createImmutableSelector(
  getDashboardDrilldownData,
  data => data.get('groups')
)
export const getDashboardDrilldownDataNewsById = (state, id) => {
  const news = getDashboardDrilldownData(state)
    .get('groups')
    .map(group => group.get('news').find(n => n.get('id') === id))
    .filter(n => n !== undefined)
    .first()

  return news
}

export const getDashboardDrilldownUi = state => state.dashboard.getIn(['drilldown', 'ui'])
export const getDashboardSelectedDrilldownChart = state => getDashboardDrilldownUi(state).get('chart')
export const getDashboardSelectedDrilldownFilterData = state => getDashboardDrilldownUi(state).get('filterData')
export const getDashboardSelectedDrilldownSecondFilter = state => getDashboardDrilldownUi(state).get('secondFilter')
export const getDashboardSelectedDrilldownSavedSearchId = state => getDashboardDrilldownUi(state).get('savedSearchId')
export const getDashboardSelectedDrilldownTitle = createImmutableSelector(
  getDashboardSelectedDrilldownFilterData,
  getDashboardSelectedDrilldownSecondFilter,
  (filterData, secondFilter) => [
    filterData ? filterData.get('name') : null,
    secondFilter ? secondFilter.get('name') : null
  ].filter(Boolean).join(' / ')
)

export const getDashboardSelectedDrilldownChartSavedSearch = state => {
  const savedSearches = getAllSavedSearches(state)
  const id = getDashboardSelectedDrilldownSavedSearchId(state)
  const found = savedSearches.find(s => s.get('id') === id)

  if (found) {
    return found
  }

  return getDashboardSelectedDrilldownChart(state).get('savedSearch')
}

export const getDashboardShowDrilldownDialog = state => getDashboardDrilldownUi(state).get('show')
export const getDashboardDrilldownLoading = state => getDashboardDrilldownUi(state).get('loading')
export const getDashboardDrilldownSearchParams = state => getDashboardDrilldownUi(state).get('searchParams')
export const getDashboardDrilldownDateFrom = state => getDashboardDrilldownUi(state).get('dateFrom')
export const getDashboardDrilldownDateTo = state => getDashboardDrilldownUi(state).get('dateTo')
export const getDashboardDrilldownDateRange = state => getDashboardDrilldownUi(state).get('dateRange')
export const getDashboardDrilldownDateType = state => getDashboardDrilldownUi(state).get('dateType')
export const getDashboardDrilldownGroupingType = state => getDashboardDrilldownUi(state).get('groupingType')
export const getDashboardDrilldownSortBy = state => getDashboardDrilldownUi(state).get('sortBy')

export const getDashboardDrilldownChartSavedSearchModuleName = state => (
  getDashboardDrilldownUi(state).getIn(['chart', 'savedSearch', 'moduleName'])
)

export const getDashboardDrilldownGroupingOptions = createImmutableSelector(
  getDashboardDrilldownChartSavedSearchModuleName,
  moduleName => groupingTypesByModuleName(moduleName).filter(g => g !== GroupingTypes.UNGROUPED)
)

export const getDashboardDrilldownSortingOptions = () => SortingOptions

export const getDashboardDrilldownChartShowSentiment = createImmutableSelector(
  getDashboardSelectedDrilldownChart,
  getDashboardDrilldownChartSavedSearchModuleName,
  (chart, moduleName) => (
    ['sentiment', 'sentiments'].some(s => chart.get('firstLevel') === s || chart.get('secondLevel') === s)
    || moduleName === NewsPageModules.NEWS_POOL
  )
)

export const getDashboardDrilldownChartShowStatementTonality = createImmutableSelector(
  getDashboardSelectedDrilldownChart,
  getDashboardDrilldownChartSavedSearchModuleName,
  (chart, moduleName) => (
    ['statementTonality', 'statementTonalities'].some(s => chart.get('firstLevel') === s || chart.get('secondLevel') === s)
    || moduleName === NewsPageModules.ANALYSIS
  )
)

export const getDashboardDrilldownAiAnalysisLoading = state => getDashboardDrilldownUi(state).get('aiAnalysisLoading')
export const getDashboardDrilldownAiAnalysis = state => getDashboardDrilldownData(state).get('aiAnalysis')

export const getDashboardChartDialogDefaultFormCategories = state => {
  const moduleName = getDashboardSelectedChartSavedSearchModuleName(state)
  const isMulti = getDashboardSelectedChartMultipleSavedSearchesMode(state)
  const userId = getUser(state).get('id')

  if (userId === 56558 || userId === 57869) {
    return [
      StepTypes.CHARTS,
      StepTypes.TIMELINE
    ]
  }

  if (moduleName === NewsPageModules.PROFILE_MONITORING) {
    if (isMulti) {
      return [
        StepTypes.CHARTS
      ]
    }

    return [
      StepTypes.NEWS_FEED,
      StepTypes.KPI,
      StepTypes.PAGE_IDENTITIES_TIMELINE,
      StepTypes.PAGE_IDENTITIES,
      StepTypes.IMAGE_FEED
    ]
  }

  if (isMulti) {
    return [
      StepTypes.CHARTS,
      StepTypes.SAVED_SEARCHES_TIMELINE
    ]
  }

  return [
    StepTypes.CHARTS,
    StepTypes.TIMELINE,
    StepTypes.KPI,
    StepTypes.NEWS_FEED,
    StepTypes.TOP_INFLUENCERS,
    StepTypes.TOP_PUBLICATIONS,
    StepTypes.IMAGE_FEED
  ]
}
