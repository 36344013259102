import { connect } from 'react-redux'
import { List } from 'immutable'

import ActiveFilterList from 'components/active_filter_list'
import {
  getCmActiveFilters, getCmFiltersCollapsed
} from 'selectors'
import { toggleActiveFilters } from 'actions/contact_management'

const mapStateToProps = state => ({
  activeFilters: getCmActiveFilters(state),
  activeBooleanFilters: List(),
  filterCount: getCmActiveFilters(state).size,
  collapsed: getCmFiltersCollapsed(state),
  activeFilterType: 'contactManagement'
})

export default connect(
  mapStateToProps,
  {
    onCollapseClick: toggleActiveFilters
  }
)(ActiveFilterList)
