import { createImmutableSelector } from 'utils/reselect'

import { Map } from 'immutable'
import { configPresets } from 'reducers/news/view_config'

export const getNewsViewConfig = state => state.news.get('viewConfig')
export const getNewsGroupingType = state => getNewsViewConfig(state).get('groupingType')
export const getNewsWidgetConfig = state => getNewsViewConfig(state).get('newsWidget')
export const getInfluencerWidgetConfig = state => getNewsViewConfig(state).get('influencerWidget')
export const getPublicationWidgetConfig = state => getNewsViewConfig(state).get('publicationWidget')
export const getAllowedNewsViews = state => getNewsViewConfig(state).get('allowedViews')
export const getAllowedKeyFigures = state => getNewsViewConfig(state).get('allowedKeyFigures')
export const getAllowedDateTypes = state => getNewsViewConfig(state).get('allowedDateTypes')
export const getAllowedFilterFields = state => getNewsViewConfig(state).get('allowedFilterFields')
export const getAllowedFilterFieldGroups = state => getNewsViewConfig(state).get('allowedFilterFieldGroups')
export const getViewConfigAppBarTitleKey = state => getNewsViewConfig(state).get('appBarTitleKey')
export const getRequiredFilters = state => getNewsViewConfig(state).get('requiredFilters')
export const getViewConfigCapabilities = state => getNewsViewConfig(state).get('capabilities')
export const isAllowedNewsView = (state, ...names) => names.some(name => getNewsViewConfig(state).get('allowedViews').indexOf(name) !== -1)
export const getViewConfigDefaultDateType = state => getNewsViewConfig(state).get('defaultDateType')
export const getViewConfigNewsGroupHeaderFilterField = state => getNewsViewConfig(state).getIn(['newsGroupWidget', 'headerFilterField'])
export const getShowAllKeyFiguresInNewsGroupHeader = state => (
  getNewsViewConfig(state).getIn(['newsGroupWidget', 'showAllKeyFiguresInHeader'])
)
export const getViewConfigNewsGroupIconMode = state => getNewsViewConfig(state).getIn(['newsGroupWidget', 'icon', 'mode'])
export const getViewConfigNewsGroupIcon = state => getNewsViewConfig(state).getIn(['newsGroupWidget', 'icon', 'icon'])
export const getViewConfigHasDateRestriction = state => getNewsViewConfig(state).get('hasDateRestriction')
export const getViewConfigHasSavedSearchesLimit = state => getNewsViewConfig(state).get('hasSavedSearchesLimit')
export const getViewConfigExportFormats = state => getNewsViewConfig(state).get('exportFormats')

export const getViewConfigNewsGroupPaging = state => getNewsViewConfig(state).getIn(['newsGroupWidget', 'paging'], Map())
export const getViewConfigNewsGroupPagingEntity = state => getViewConfigNewsGroupPaging(state).get('entity')
export const getViewConfigNewsGroupPagingField = state => getViewConfigNewsGroupPaging(state).get('field')

export const getViewConfigNewsPage = state => getNewsViewConfig(state).get('newsPage')

export const getViewConfigIndexType = state => getNewsViewConfig(state).get('indexType')
export const getViewConfigModuleName = state => getNewsViewConfig(state).get('moduleName')
export const getViewConfigSwitchableConfigs = createImmutableSelector(
  getViewConfigModuleName,
  moduleName => configPresets.keySeq().toList().filter(p => configPresets.getIn([p, 'moduleName']) === moduleName)
)

export const getLastSeenNewsIdCookieName = state => getNewsViewConfig(state).get('lastSeenNewsIdCookieName')

export const getViewConfigUseAggregationValue = state => getNewsViewConfig(state).get('useAggregationValue')
