import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { Capabilities } from 'static/constants'
import { Typography } from '@mui/material'
import decamelize from 'decamelize'

import { Dialog, Button, Input } from 'containers/themed'
import PhotoUpload from 'components/photo_upload'
import ColorPicker from 'components/color_picker'
import CapSafe from 'containers/CapSafe'
import AlertOptions from 'containers/saved_searches/edit_dialog/AlertOptions'
import MultiLanguageNameForm from 'components/multi_language_name_form'

import { createLoadable } from 'utils/loadable'

const ColorPickerContainer = CapSafe(props => <div {...props} />, Capabilities.HAS_CHARTS_MODULE, Capabilities.HAS_DASHBOARD_MODULE)

const useStyles = makeStyles()({
  preview: {
    width: '50%'
  },
  additionalLanguages: {
    marginTop: 10
  }
})

const SavedSearch = ({ loadThrough, ...props }) => {
  const Comp = useMemo(() => createLoadable(() => (
    import(/* webpackChunkName: "SavedSearchesCardViewSavedSearch" */ `containers/${loadThrough}/card_view/SavedSearch`)
  )), [loadThrough])

  return <Comp {...props} />
}

SavedSearch.propTypes = {
  loadThrough: PropTypes.string.isRequired
}

export default function EditDialog({
  active,
  i18n,
  savedSearch,
  savedSearches,
  allowedSavedSearchesCount,
  hasLimit,
  showChartOptions,
  showPhotoUpload,
  showAlertOptions,
  loadThrough,
  onHide,
  onSubmit,
  onChange,
  onPhotoChange
}) {
  const { classes } = useStyles()
  const [state, setState] = useState({ previewUrl: '' })

  const handlePreviewUrlChange = previewUrl => {
    setState({ ...state, previewUrl })
  }

  const handleChange = (field, value) => {
    onChange(savedSearch.set(field, value))
  }

  const hide = () => {
    setState({ ...state, previewUrl: '' })
    onHide()
  }

  const handleSave = () => {
    hide()
    onSubmit(savedSearch)
  }

  const nameExistsByKey = key => {
    const dup = savedSearches.find(s => (s.get(key).toLowerCase() !== '')
      && (s.get(key).toLowerCase() === savedSearch.get(key).toLowerCase()))

    return dup && dup.get('id') !== savedSearch.get('id')
  }

  const limitReached = () => hasLimit && !savedSearch.get('id') && allowedSavedSearchesCount <= savedSearches.size

  const isValid = () => {
    const namePresent = savedSearch.get('nameEn').length > 0

    const limit = limitReached()

    let recipientsPreset = true

    if (savedSearch.get('alertConfig') && savedSearch.getIn(['alertConfig', 'type']) !== 'none') {
      recipientsPreset = !savedSearch.getIn(['alertConfig', 'recipients']).isEmpty()
      recipientsPreset = recipientsPreset || !savedSearch.getIn(['alertConfig', 'mailingListIds']).isEmpty()
      recipientsPreset = recipientsPreset || !savedSearch.getIn(['alertConfig', 'recipientsSms']).isEmpty()
      recipientsPreset = recipientsPreset || !savedSearch.getIn(['alertConfig', 'webhooks']).isEmpty()
    }

    return namePresent && recipientsPreset && !limit
  }

  const renderPhoto = () => {
    const { previewUrl } = state

    return (
      <div className={classes.preview}>
        <SavedSearch
          loadThrough={loadThrough}
          savedSearch={savedSearch.set('photo', previewUrl || savedSearch.get('photo'))}
          showTitle={false}
        />
      </div>
    )
  }

  const renderActions = () => {
    const actions = [
      <Button
        key="cancel"
        onClick={() => hide()}
      >
        {i18n.get('cancel')}
      </Button>,
      <Button
        key="save"
        variant="contained"
        color="primary"
        onClick={() => handleSave()}
        disabled={!isValid()}
      >
        {i18n.get('save')}
      </Button>
    ]

    return actions
  }

  const renderNameInput = (key, required) => {
    let error = null

    if (limitReached()) {
      error = i18n.get('saved_search_limit_reached', { count: allowedSavedSearchesCount })
    } else if (nameExistsByKey(key)) {
      error = i18n.get('saved_search_exists')
    }

    return (
      <Input
        label={i18n.get(decamelize(key))}
        value={savedSearch.get(key)}
        onChange={e => handleChange(key, e.target.value)}
        error={!!error}
        helperText={error}
        variant="outlined"
        required={required}
      />
    )
  }

  const renderBody = () => {
    const { previewUrl } = state

    return (
      <>
        <br />

        <MultiLanguageNameForm renderNameInput={renderNameInput} />

        <br />
        <br />

        <Input
          label={i18n.get('description')}
          value={savedSearch.get('description') || ''}
          onChange={e => handleChange('description', e.target.value)}
          variant="outlined"
        />

        {showChartOptions && (
          <ColorPickerContainer>
            <br />
            <Typography>{i18n.get('color_in_charts')}:</Typography>
            <ColorPicker
              onChange={color => handleChange('color', color)}
              onReset={() => handleChange('color', null)}
              defaultValue="#FFFFFF"
              value={savedSearch.get('color')}
            />
          </ColorPickerContainer>
        )}

        {showPhotoUpload && (
          <>
            <br />
            <br />

            <PhotoUpload
              photoUrl={previewUrl}
              onFileChange={onPhotoChange}
              onFileUrlChange={handlePreviewUrlChange}
              onDelete={() => {
                handleChange('photo', null)
              }}
            />

            <br />
            <br />

            {renderPhoto()}
          </>
        )}

        {showAlertOptions && (
          <>
            <br />

            <AlertOptions />
          </>
        )}
      </>
    )
  }

  let title = i18n.get('create_saved_search')

  if (savedSearch.get('id')) {
    title = i18n.get('edit_saved_search')
  }

  return (
    <Dialog
      open={active}
      title={title}
      actions={renderActions()}
      onClose={() => hide()}
    >
      {renderBody()}
    </Dialog>
  )
}

EditDialog.defaultProps = {
  showChartOptions: true,
  showPhotoUpload: true,
  showAlertOptions: true,
  loadThrough: 'saved_searches'
}

EditDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  i18n: PropTypes.object.isRequired,
  savedSearch: PropTypes.instanceOf(Map).isRequired,
  savedSearches: PropTypes.instanceOf(List).isRequired,
  allowedSavedSearchesCount: PropTypes.number.isRequired,
  hasLimit: PropTypes.bool.isRequired,
  showChartOptions: PropTypes.bool,
  showPhotoUpload: PropTypes.bool,
  showAlertOptions: PropTypes.bool,
  loadThrough: PropTypes.string,

  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onPhotoChange: PropTypes.func.isRequired
}
