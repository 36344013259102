import React from 'react'

import PropTypes from 'prop-types'
import { Map, Set } from 'immutable'

import { Button } from 'containers/themed'

export default function RecipientStats({ onClick, data, openIds }) {
  const id = data.get('id')

  const toggleEmailOpen = () => {
    if (openIds.has(id)) {
      return onClick(openIds.delete(id))
    }

    return onClick(openIds.add(id))
  }

  return (
    <Button
      color={openIds.has(id) ? 'secondary' : 'primary'}
      onClick={() => toggleEmailOpen(id)}
    >
      {data.get('recipients').filter(r => r.get('succeeded')).size}/{data.get('recipients').size}
    </Button>
  )
}

RecipientStats.propTypes = {
  openIds: PropTypes.instanceOf(Set).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,

  onClick: PropTypes.func.isRequired
}
