import { connect } from 'react-redux'

import ClosedCampaignDialog from 'components/content_desk_new/contents/content_edit_dialog/settings/closed_campaign_dialog'

import {
  updateCampaignFormData,
  closeClosedCampaignDialog,
  resetCampaignForm,
  saveCampaignStart
} from 'actions/content_desk'

import {
  getCDeskShowClosedCampaignDialog,
  getCDeskCampaignFormData,
  getCDeskRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowClosedCampaignDialog(state),
  campaign: getCDeskCampaignFormData(state),
  requestRunning: getCDeskRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    updateCampaignFormData,
    closeClosedCampaignDialog,
    resetCampaignForm,
    saveCampaign: saveCampaignStart
  }
)(ClosedCampaignDialog)
