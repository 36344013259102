import { all } from 'redux-saga/effects'

import mainSaga from './main'
import filterSaga from './filter'
import searchSaga from './search'
import uiSaga from './ui'
import savedSearchesSaga from './saved_searches'

export default function* themaxSaga() {
  yield all([
    mainSaga(),
    filterSaga(),
    searchSaga(),
    uiSaga(),
    savedSearchesSaga()
  ])
}
