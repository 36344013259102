import { connect } from 'react-redux'
import { List } from 'immutable'

import CapSafe from 'containers/CapSafe'
import { Capabilities } from 'static/constants'

import CustomTagDialog from 'components/custom_tags/custom_tag_dialog'
import { getSelectedCustomTag, getShowCustomTagDialog, getCustomTagGroupsData, getViewConfigModuleName } from 'selectors'
import {
  setSelectedCustomTag,
  saveCustomTagStart,
  hideCustomTagDialog,
  setUploadedPhoto
} from 'actions/custom_tags'

const mapStateToProps = (state, { isInParentDialog, hideParentDialog }) => {
  const module = getViewConfigModuleName(state)
  let groups = getCustomTagGroupsData(state)
  groups = (module === 'news_pool') ? List() : groups

  const customTag = getSelectedCustomTag(state)
  const selectedCustomTagGroups = groups.filter(g => customTag.get('customTagGroupIds').includes(g.get('id')))

  return {
    active: getShowCustomTagDialog(state),
    customTag,
    groups,
    selectedCustomTagGroups,
    module,
    isInParentDialog,
    hideParentDialog
  }
}

const result = connect(
  mapStateToProps,
  {
    onHide: hideCustomTagDialog,
    onChange: setSelectedCustomTag,
    onSave: saveCustomTagStart,
    onPhotoChange: setUploadedPhoto
  }
)(CustomTagDialog)

export default CapSafe(result, Capabilities.HAS_CUSTOM_TAGS_MODULE)
