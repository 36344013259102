import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/email_templates_library'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  showEditorDialog: false,
  showSaveDialog: false,
  showPickerDialog: false,
  fetchRequestRunning: false,
  fetchReleasedRequestRunning: false,
  saveRequestRunning: false,
  deleteRequestRunning: false,
  showPublic: false,
  readMode: false
})

export default handleActions({
  [Actions.openEditorDialog]: state => state.set('showEditorDialog', true),
  [Actions.closeEditorDialog]: state => state.set('showEditorDialog', false),
  [Actions.openPickerDialog]: state => state.set('showPickerDialog', true),
  [Actions.closePickerDialog]: state => state.set('showPickerDialog', false),
  [Actions.openSaveDialog]: state => state.set('showSaveDialog', true),
  [Actions.closeSaveDialog]: state => state.set('showSaveDialog', false),
  [Actions.fetchTemplatesStart]: state => state.set('fetchRequestRunning', true),
  [combineActions(Actions.fetchTemplatesSuccess, Actions.fetchTemplatesError)]: state => (
    state.set('fetchRequestRunning', false)
  ),
  [Actions.saveTemplateStart]: state => state.set('saveRequestRunning', true),
  [combineActions(Actions.saveTemplateSuccess, Actions.saveTemplateError)]: state => (
    state.set('saveRequestRunning', false)
  ),
  [Actions.togglePublicTemplates]: state => state.set('showPublic', !state.get('showPublic')),
  [Actions.deleteTemplateStart]: state => state.set('deleteRequestRunning', true),
  [combineActions(Actions.deleteTemplateSuccess, Actions.deleteTemplateError)]: state => (
    state.set('deleteRequestRunning', false)
  ),
  [Actions.setReadMode]: (state, { payload }) => state.set('readMode', payload),
  [Actions.fetchReleasedTemplatesStart]: state => state.set('fetchReleasedRequestRunning', true),
  [combineActions(Actions.fetchReleasedTemplatesSuccess, Actions.fetchReleasedTemplatesError)]: state => (
    state.set('fetchReleasedRequestRunning', false)
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
