import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import Card from 'components/card'

import { CardContent, CardHeader, Divider } from '@mui/material'
import ChannelBar from './channel_bar'
import Codes from './codes'

const useStyles = makeStyles()({
  cardTitle: {
    paddingBottom: '0 !important'
  },
  cardText: {
    fontSize: 16,
    paddingBottom: 0
  },
  codes: {
    '& > div:first-of-type': {
      marginBottom: 10
    }
  }
})

export default function MediaReviewStats({ i18n, mediaReview, className }) {
  const { classes, cx } = useStyles()

  return (
    <Card className={className}>
      <CardHeader
        classes={{ root: classes.cardTitle }}
        title={i18n.get('media_review_codes')}
      />

      <CardContent classes={{ root: cx(classes.cardText, classes.codes) }}>
        <Codes mediaReview={mediaReview} />
        <Divider />
      </CardContent>

      <CardHeader
        classes={{ root: classes.cardTitle }}
        title={i18n.get('channels')}
      />

      <CardContent classes={{ root: classes.cardText }}>
        <ChannelBar mediaReview={mediaReview} />
      </CardContent>
    </Card>
  )
}

MediaReviewStats.propTypes = {
  i18n: PropTypes.object.isRequired,
  mediaReview: PropTypes.instanceOf(Map).isRequired,
  className: PropTypes.string
}
