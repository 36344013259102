// DATA
export const getPublicationsData = state => state.publications.get('data')
export const getPublications = state => getPublicationsData(state).get('publications')
export const getPublicationsTotal = state => getPublicationsData(state).get('total')
export const getNewPublication = state => getPublicationsData(state).get('newPublication')

// UI
export const getPublicationsUi = state => state.publications.get('ui')
export const getPublicationsFrom = state => getPublicationsUi(state).get('from')
export const getPublicationsSortBy = state => getPublicationsUi(state).get('sortBy')
export const getPublicationsRunning = state => getPublicationsUi(state).get('running')
export const getSelectedPublicationIds = state => getPublicationsUi(state).get('selectedIds')
export const isPublicationFormActive = state => getPublicationsUi(state).get('isFormActive')
export const getSelectedPublications = state => (
  getPublicationsUi(state)
    .get('selectedIds').map(
      id => getPublications(state).find(p => p.get('id') === id)
    )
    .filter(p => p)
    .toList()
)
