import { connect } from 'react-redux'

import PromptHistory from 'components/content_desk/prompt_history'

import { getCDeskAiRequestResults, getCDeskEditorAiRequestRunning } from 'selectors'

const mapStateToProps = state => ({
  entries: getCDeskAiRequestResults(state),
  loading: getCDeskEditorAiRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(PromptHistory)
