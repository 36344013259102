import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

export default function User({ data }) {
  return (
    <>
      {
        [
          data.getIn(['account', 'firstName']),
          data.getIn(['account', 'lastName'])
        ].filter(s => !!s).join(' ')
      }
    </>
  )
}

User.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired
}
