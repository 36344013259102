// ADMINISTRATION
export const getAdministration = state => state.administration
export const getAdministrationData = state => getAdministration(state).get('data')
export const getAdministrationUi = state => getAdministration(state).get('ui')
export const getAdministrationFilter = state => getAdministration(state).get('filter')

// CONTENT DESK SETTINGS ALLOWED DOMAINS DATA
export const getAllowedDomains = state => getAdministrationData(state).get('ccdAllowedDomains')
export const getAllowedDomainFormData = state => getAdministrationData(state).get('ccdAllowedDomainFormData')
export const getAllowedDomainStatus = state => getAdministrationData(state).get('ccdAllowedDomainStatus')

// CONTENT DESK SETTINGS ALLOWED DOMAINS UI
export const getAllowedDomainsUi = state => getAdministrationUi(state).get('ccdAllowedDomains')
export const getAllowedDomainsRequestRunning = state => getAllowedDomainsUi(state).get('requestRunning')
export const getAllowedDomainsQuickSearchQuery = state => getAllowedDomainsUi(state).get('quickSearchQuery')
export const getAllowedDomainsNewAllowedDomain = state => getAllowedDomainsUi(state).get('newAllowedDomain')
export const getAllowedDomainsNewAllowedDomainRegion = state => getAllowedDomainsUi(state).get('newAllowedDomainRegion')
export const getAllowedDomainsNewAllowedSenderDomain = state => getAllowedDomainsUi(state).get('newAllowedSenderDomain')
export const getAllowedDomainsDeleteMode = state => getAllowedDomainsUi(state).get('deleteMode')
export const getAllowedDomainsInfoDialogOpen = state => getAllowedDomainsUi(state).get('infoDialogOpen')
export const getAllowedDomainsStatusDialogOpen = state => getAllowedDomainsUi(state).get('statusDialogOpen')
export const getDefaultAllowedDomainIds = state => getAllowedDomainsUi(state).get('defaultAllowedDomainIds')

// CONTENT DESK SETTINGS ALLOWED DOMAINS FILTERS
export const getAllowedDomainsFilters = state => getAdministrationFilter(state).get('ccdAllowedDomains')
export const getAllowedDomainsPage = state => getAllowedDomainsFilters(state).get('page')
export const getAllowedDomainsLimit = state => getAllowedDomainsFilters(state).get('limit')

