import { combineReducers } from 'redux-immutable'

import data from './data'
import detail from './detail'
import filter from './filter'

export default combineReducers({
  data,
  detail,
  filter
})
