import { connect } from 'react-redux'

import DistributionListItem from 'components/contact_management/distribution_lists/distribution_lists_item/DistributionListItem'

import {
  openDistributionListEditDialog,
  selectDistributionList,
  downloadDistributionListStart
} from 'actions/contact_management'

import {
  getCmSelectedLists,
  getCmRequestRunning,
  getCDeskIsDistributionListsLocked
} from 'selectors'

const mapStateToProps = (state, { distObject }) => ({
  distObject,
  selected: getCmSelectedLists(state).get('lists').map(c => c.get('id')).includes(distObject.get('id')),
  loading: getCmRequestRunning(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(mapStateToProps, {
  onEdit: openDistributionListEditDialog,
  onSelect: selectDistributionList,
  onDownload: downloadDistributionListStart
})(DistributionListItem)
