import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(8),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  }
}))

const ClearAllConfirmDialog = ({
  open,
  closeDialog,
  onChange
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleCloseDialog = () => closeDialog()

  const handleClearAll = () => {
    onChange({ key: 'recipients', value: List() })
    closeDialog()
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('ccd_recipients_clear_all_confirm')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseDialog}
        >
          {i18n.get('close')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearAll}
        >
          {i18n.get('clear_all')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ClearAllConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  closeDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ClearAllConfirmDialog
