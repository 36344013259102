import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import useI18n from 'hooks/useI18n'

import { Typography, Paper } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  summaryPaper: {
    padding: 10,
    backgroundColor: theme.palette.grey[300]
  },
  summary: {
    textAlign: 'justify',
    columns: '100px 4',
    [theme.breakpoints.down('lg')]: {
      columns: '100px 3'
    },
    [theme.breakpoints.down('md')]: {
      columns: '100px 2'
    },
    [theme.breakpoints.only('xs')]: {
      columns: '100px 1'
    },
    p: {
      marginBlockEnd: 0,
      marginBlockStart: 0
    }
  }
}))

export default function Summary({ summary }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  if (!summary) {
    return null
  }

  return (
    <Paper
      classes={{ root: classes.summaryPaper }}
    >
      <Typography variant="h6">
        {i18n.get('summary')}
      </Typography>
      <div
        className={classes.summary}
        dangerouslySetInnerHTML={{
          __html: summary
        }}
      />
    </Paper>
  )
}

Summary.propTypes = {
  summary: PropTypes.string
}
