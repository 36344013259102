
export const linkedInPost = {
  author: '',
  commentary: 'Sample text...',
  visibility: 'PUBLIC',
  lifecycleState: 'PUBLISHED',
  isReshareDisabledByAuthor: false,
  distribution: {
    feedDistribution: 'MAIN_FEED',
    targetEntities: []
  },
  type: 'text_only'
}

export const linkedInRequiredPermissions = ['w_organization_social', 'r_organization_social', 'w_member_social']
