import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { v4 } from 'uuid'

import * as Actions from 'actions/custom_tags'
import * as AppActions from 'actions/app'

const createNewGroup = () => fromJS({
  name: '',
  customTagsCustomTagGroups: []
})

export const initialState = fromJS({
  showGroupDialog: false,
  showDeleteGroupDialog: false,
  saving: false,
  deleting: false,
  selectedGroup: createNewGroup(),
  headerId: v4()
})

export default handleActions({
  [Actions.setSelectedGroup]: (state, { payload: group }) => state.set('selectedGroup', group),
  [Actions.showGroupDialog]: (state, { payload }) => state.merge({
    showGroupDialog: true,
    selectedGroup: payload || createNewGroup()
  }),
  [Actions.hideGroupDialog]: state => state.set('showGroupDialog', false),
  [Actions.showDeleteGroupDialog]: (state, { payload }) => state.merge({
    showDeleteGroupDialog: true,
    selectedGroup: payload
  }),
  [Actions.hideDeleteGroupDialog]: state => state.set('showDeleteGroupDialog', false),

  [Actions.saveGroupStart]: state => state.set('saving', true),
  [Actions.saveGroupError]: state => state.set('saving', false),
  [Actions.saveGroupSuccess]: state => state.merge({
    saving: false,
    showGroupDialog: false
  }),

  [Actions.deleteGroupStart]: state => state.set('deleting', true),
  [Actions.deleteGroupError]: state => state.set('deleting', false),
  [Actions.deleteGroupSuccess]: state => state.merge({
    deleting: false,
    showDeleteGroupDialog: false
  }),

  [AppActions.resetState]: () => initialState
}, initialState)
