/* global window */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map, fromJS } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { ImageList, ImageListItemBar, ImageListItem, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { grey } from '@mui/material/colors'

import useI18n from 'hooks/useI18n'

import { IconButton } from 'containers/themed'
import { facebookPost as defaultFacebookPost } from 'static/facebook_post_config'

const useStyles = makeStyles()({
  postContainer: {
    display: 'block',
    backgroundColor: 'white',
    verticalAlign: 'baseline',
    color: 'rgba(0, 0, 0, 0.9)',
    direction: 'ltr',
    fontSize: '14px',
    minWidth: '555px',
    width: '555px',
    lineHeight: '20px',
    borderRadius: '0.5rem',
    border: '1px solid #ccc',
    padding: '6px',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word'
  },
  showMore: {
    float: 'right',
    color: 'rgb(0, 0, 0, 0.6)',
    cursor: 'pointer'
  },
  mediaGallery: {
    marginTop: '8px'
  },
  mediaImg: {
    maxHeight: 350,
    objectFit: 'cover'
  },
  transparentLayer: {
    height: '100%',
    textAlign: 'center'
  },
  video: {
    width: '100%'
  },
  linkContainer: {
    marginTop: '8px',
    position: 'relative',
    cursor: 'pointer'
  },
  linkImg: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },
  linkData: {
    backgroundColor: '#edf3f8',
    padding: '10px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px'
  },
  linkRemoveBtn: {
    position: 'absolute',
    top: 5,
    right: 5,
    opacity: 0.7,
    backgroundColor: grey[800],
    '&:hover': {
      backgroundColor: grey[900]
    }
  }
})

const Facebook = ({
  content,
  showContentDetailsView,
  editorDialogView,
  onChange
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const currentFacebookPost = content.get('facebookPost') || fromJS(defaultFacebookPost)
  const postText = currentFacebookPost.get('message') || ''

  const [showMore, setShowMore] = useState(false)

  let displayedText; let
    showMoreLink

  if (showMore) {
    displayedText = postText
  } else {
    displayedText = postText.substring(0, 129)
  }

  if (postText.length > 130) {
    showMoreLink = (
      <span
        className={classes.showMore}
        onClick={() => setShowMore(!showMore)}
      > ...{i18n.get('show_more').toLowerCase()}
      </span>
    )
  }

  const handleRemoveLink = e => {
    e.stopPropagation()
    onChange({ key: 'facebookPost', value: currentFacebookPost.set('link', '').set('linkMetadata', Map({})) })
  }

  const renderFacebookGallery = () => {
    const facebookPost = content.get('facebookPost') || currentFacebookPost
    const facebookPostType = facebookPost.get('type')
    const link = facebookPost.get('link')
    const images = facebookPost.get('mediaAttachments')
    const video = facebookPost.getIn(['mediaAttachments', 0])

    if (facebookPostType === 'text' && link.length !== 0) {
      const linkMetadata = currentFacebookPost.get('linkMetadata')
      const source = linkMetadata.get('source')
      const imageUrl = linkMetadata.get('imageUrl')

      return (
        <div
          onClick={() => window.open(source, '_blank', 'noopener,noreferrer')}
          className={classes.linkContainer}
        >
          {(!showContentDetailsView && editorDialogView !== 'distribute') && (
            <IconButton
              className={classes.linkRemoveBtn}
              onClick={handleRemoveLink}
              size="small"
            >
              <CloseIcon sx={{ color: 'white' }} />
            </IconButton>
          )}
          {imageUrl && (
            <img
              src={imageUrl}
              alt=""
              loading="lazy"
              className={classes.linkImg}
            />
          )}
          <div className={classes.linkData}>
            <Typography
              fontSize={13}
              color="#8b8f92"
            >{linkMetadata.get('host').toUpperCase()}
            </Typography>
            <Typography
              fontSize={15}
              fontWeight="bold"
            >{linkMetadata.get('title')}
            </Typography>
            <Typography
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              fontSize={13}
              color="#8b8f92"
            >{linkMetadata.get('description')}
            </Typography>
          </div>
        </div>
      )
    }

    if (facebookPost.get('type') === 'video' && video) {
      return (
        <div className={classes.mediaGallery}>
          <video
            className={classes.video}
            controls
            preload="metadata"
          >
            <source
              src={`${video.get('url')}#t=0.5`}
            />
          </video>
        </div>
      )
    }

    if (images.size === 1) {
      const image = images.first()

      return (
        <div className={classes.mediaGallery}>
          <img
            src={image.get('url')}
            alt={image.get('filename')}
            width="100%"
          />
        </div>
      )
    }

    if (images) {
      if (images.size === 2) {
        return (
          <ImageList
            sx={{ width: '100%' }}
            cols={2}
            gap={2}
          >
            <ImageListItem>
              <img
                src={`${images.get(0).get('url')}?w=199&h=199&fit=crop&auto=format`}
                srcSet={`${images.get(0).get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                alt={images.get(0).get('filename')}
                loading="lazy"
                className={classes.mediaImg}
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`${images.get(1).get('url')}?w=199&h=199&fit=crop&auto=format`}
                srcSet={`${images.get(1).get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                alt={images.get(1).get('filename')}
                loading="lazy"
                className={classes.mediaImg}
              />
            </ImageListItem>
          </ImageList>
        )
      }

      if (images.size > 4) {
        const restCounter = images.size - 5

        return (
          <>
            <ImageList
              sx={{ width: '100%' }}
              cols={2}
              gap={2}
            >
              {images.slice(0, 2).map(item => (
                <ImageListItem
                  key={item.get('id')}
                >
                  <img
                    src={`${item.get('url')}?w=199&h=199&fit=crop&auto=format`}
                    srcSet={`${item.get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.get('id')}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <ImageList
              sx={{ width: '100%' }}
              cols={3}
              gap={2}
            >
              {images.slice(2, 5).map(item => (
                <ImageListItem
                  key={item.get('id')}
                >
                  {item === images.get(4)
                    && (
                      <ImageListItemBar
                        className={classes.transparentLayer}
                        title={`+ ${restCounter}`}
                      />
                    )}
                  <img
                    src={`${item.get('url')}?w=199&h=199&fit=crop&auto=format`}
                    srcSet={`${item.get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.get('id')}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </>
        )
      }

      if (images.size > 2) {
        const columnCount = (images.size - 1) >= 3 ? 3 : (images.size - 1)

        return (
          <ImageList
            sx={{ width: '100%' }}
            cols={columnCount}
            gap={2}
          >
            {images.slice(0, 5).map(item => (
              <ImageListItem
                key={item.get('id')}
                cols={item === images.first() ? columnCount : 1}
              >
                <img
                  src={`${item.get('url')}?w=199&h=199&fit=crop&auto=format`}
                  srcSet={`${item.get('url')}?w=199&h=199&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.get('id')}
                  loading="lazy"
                  className={classes.mediaImg}
                />
              </ImageListItem>
            ))}
          </ImageList>
        )
      }
    }

    return null
  }

  return (
    <div className={classes.postContainer}>
      {displayedText}
      {!showMore && showMoreLink}
      {renderFacebookGallery()}
    </div>
  )
}

Facebook.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  showContentDetailsView: PropTypes.bool.isRequired,
  editorDialogView: PropTypes.string,

  onChange: PropTypes.func
}

export default Facebook
