import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import SaveIcon from '@mui/icons-material/Save'
import { CardContent, FormControlLabel, Switch, Typography } from '@mui/material'

import { getContentLanguageOptions } from 'utils/translation'

import { Select, Button } from 'containers/themed'

import useI18n from 'hooks/useI18n'

export default function SpecificSettings({
  user,
  userConfig,
  onSave,
  onChange
}) {
  const i18n = useI18n()

  const translateSearchResults = userConfig.get('translateSearchResults') || false
  const translateSearchResultsTargetLang = userConfig.get('translateSearchResultsTargetLang') || user.get('languageShort')
  const contentLanguageOptions = getContentLanguageOptions()

  return (
    <CardContent>
      <Typography
        variant="h5"
        gutterBottom
      >
        {i18n.get('newsradar_specific_user_settings')}
      </Typography>

      <FormControlLabel
        label={i18n.get('translate_search_results')}
        control={(
          <Switch
            checked={translateSearchResults}
            onChange={() => onChange({
              field: 'translateSearchResults',
              value: !translateSearchResults
            })}
          />
        )}
      />

      {translateSearchResults && (
        <>
          <br />
          <br />

          <Select
            displayEmpty
            variant="outlined"
            label={i18n.get('language')}
            options={contentLanguageOptions.toJS()}
            onChange={({ value }) => onChange({
              field: 'translateSearchResultsTargetLang',
              value
            })}
            value={translateSearchResultsTargetLang}
          />
        </>
      )}

      <br />
      <br />

      <Button
        color="primary"
        variant="contained"
        onClick={() => onSave()}
        startIcon={<SaveIcon />}
      >
        {i18n.get('save')}
      </Button>
    </CardContent>
  )
}

SpecificSettings.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  userConfig: PropTypes.instanceOf(Map).isRequired,

  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}
