import { connect } from 'react-redux'

import DistributionListMergeDialog from 'components/contact_management/distribution_list_merge_dialog/DistributionListMergeDialog'

import {
  getCmDistributionListMergeDialogOpen,
  getCmSelectedLists,
  getCmRequestRunning
} from 'selectors'

import {
  setMergedDistributionListData as onChange,
  closeDistributionListMergeDialog as onClose,
  mergeDistributionListsStart as onMerge
} from 'actions/contact_management'

const mapStateToProps = state => ({
  data: getCmSelectedLists(state),
  open: getCmDistributionListMergeDialogOpen(state),
  loading: getCmRequestRunning(state)
})

export default connect(mapStateToProps, {
  onChange,
  onClose,
  onMerge
})(DistributionListMergeDialog)
