import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { red, grey, green } from '@mui/material/colors'

import { formatNumber, separate } from 'utils/number'

import ChangeIcon from './change_icon'

const useStyles = makeStyles()(theme => ({
  keyFigure: {
    fontWeight: 400,
    fontSize: '11pt',
    color: theme.isDark ? undefined : grey[800],
    whiteSpace: 'nowrap'
  },
  keyFigureTitle: {
    fontWeight: 500,
    fontSize: '10pt',
    color: theme.isDark ? undefined : grey[500],
    marginBottom: 0
  },
  keyFigureTitleHorizontal: {
    marginBottom: '0',
    fontWeight: '400',
    color: theme.isDark ? undefined : grey[700],
    fontSize: '11pt'
  },
  numberHorizontal: {
    fontSize: '11pt',
    fontWeight: '400',
    color: theme.isDark ? undefined : grey[900],
    paddingLeft: '10px'
  },
  previous: {
    marginLeft: '5px',
    fontSize: '10pt'
  },
  negative: {
    color: red[700]
  },
  positive: {
    color: green[700]
  },
  compact: {
    display: 'flex'
  },
  horizontal: {
    display: 'flex',
    alignItems: 'flex-end'
  }
}))

export default function KeyFigure({
  className,
  compact,
  decimals,
  icon,
  name,
  previous,
  title,
  titleClassName,
  numberClassName,
  horizontalClassName,
  unit,
  value,
  variant
}) {
  const { classes, cx } = useStyles()

  let previousValue = null

  if (typeof value !== 'string' && previous !== undefined) {
    let cssClasses = `${classes.previous} ${classes.positive}`

    if ((value - previous) < 0) {
      cssClasses = `${classes.previous} ${classes.negative}`
    }

    const factor = Math.pow(10, decimals) // eslint-disable-line

    let diff = value - previous
    const formattedDiff = Math.round(Math.abs(diff) * factor) / factor

    if (diff % 1 !== 0) {
      diff = formattedDiff
    }

    previousValue = (
      <span className={cssClasses}>
        <ChangeIcon
          current={value}
          previous={previous}
        />
        <span title={diff}>
          {formatNumber(formattedDiff)}
        </span>
      </span>
    )
  }

  let cssClasses = classes.keyFigure

  if (compact) {
    cssClasses = `${cssClasses} ${classes.compact}`
  }

  return (
    <div
      className={`${cssClasses} ${className}`}
      title={title}
    >
      <div className={cx(variant === 'horizontal' && classes.horizontal, horizontalClassName)}>
        {icon ? (
          <div title={name}>
            {icon}
          </div>
        ) : (
          <div className={cx(
            classes.keyFigureTitle, variant === 'horizontal' ? classes.keyFigureTitleHorizontal : null, titleClassName
          )}
          >
            {name}
          </div>
        )}

        <span
          title={typeof value !== 'string' ? separate(value) : value}
          className={cx(variant === 'horizontal' && classes.numberHorizontal, numberClassName)}
        >
          {typeof number !== 'string' ? formatNumber(value) : value}{unit || ''}
        </span>

        {previousValue}
      </div>
    </div>
  )
}

KeyFigure.defaultProps = {
  className: '',
  unit: '',
  titleClassName: '',
  decimals: 4,
  compact: false,
  variant: 'vertical'
}

KeyFigure.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  unit: PropTypes.string,
  previous: PropTypes.number,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  numberClassName: PropTypes.string,
  horizontalClassName: PropTypes.string,
  title: PropTypes.string,
  decimals: PropTypes.number,
  compact: PropTypes.bool,
  variant: PropTypes.string
}
