import { connect } from 'react-redux'

import CapSafe from 'containers/CapSafe'
import { Capabilities } from 'static/constants'

import RemoveDialog from 'components/dialogs/confirm_dialog'
import {
  getI18n,
  getSelectedMediaReviewNewsItem,
  getShowRemoveNewsDialog,
  getRemoveMediaReviewNewsDeleting
} from 'selectors'

import { removeNewsFromMediaReviewStart, hideRemoveNewsDialog } from 'actions/media_reviews'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  payload: getSelectedMediaReviewNewsItem(state).get('id'),
  deleting: getRemoveMediaReviewNewsDeleting(state),
  active: getShowRemoveNewsDialog(state),
  text: getI18n(state).get('delete_news_from_media_review'),
  actionText: getI18n(state).get('remove')
})

const result = connect(
  mapStateToProps,
  {
    onSubmit: removeNewsFromMediaReviewStart,
    onHide: hideRemoveNewsDialog
  }
)(RemoveDialog)

export default CapSafe(result, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)
