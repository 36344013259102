import { combineReducers } from 'redux-immutable'

import settings from './settings'
import analysis from './analysis'
import ui from './ui'

export default combineReducers({
  settings,
  analysis,
  ui
})
