import { connect } from 'react-redux'

import Outlet from 'components/anewstip/results/outlet'

import {
  openDrillDownDialog,
  setDrillDownEntity,
  drillDownRequestStart as onDrillDownClick
} from 'actions/anewstip'

const mapStateToProps = (_state, { outlet }) => ({
  outlet
})

export default connect(
  mapStateToProps,
  {
    openDrillDownDialog,
    setDrillDownEntity,
    onDrillDownClick
  }
)(Outlet)
