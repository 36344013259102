import { connect } from 'react-redux'

import AppBar from 'components/shopping_cart/app_bar'

import {
  getShoppingCartNewsCount,
  getShoppingCartPinAllProgress,
  getShoppingCartCustomTag
} from 'selectors'

import { back } from 'actions/navigation'
import { resetShoppingCart } from 'actions/shopping_cart'

const mapStateToProps = state => ({
  count: getShoppingCartNewsCount(state),
  progress: getShoppingCartPinAllProgress(state),
  shoppingCartCustomTag: getShoppingCartCustomTag(state)
})

export default connect(
  mapStateToProps,
  {
    onCustomTagBackClick: back,
    onReset: resetShoppingCart
  }
)(AppBar)
