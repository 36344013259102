import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import decamelize from 'decamelize'

import * as Actions from 'actions/user'
import * as SurveyActions from 'actions/survey'
import * as AppActions from 'actions/app'
import { CookieNames } from 'static/constants'

import { readCookie } from 'utils/cookieHelper'
import { guessLocale } from 'utils/locale'

const sid = readCookie(CookieNames.SESSION) || ''
const accessToken = readCookie(CookieNames.ACCESS_TOKEN) || ''
const isAuthenticated = (accessToken !== '')
const registrationException = {
  statusCode: 200,
  status: '',
  message: '',
  exceptionCode: ''
}
const locale = guessLocale()
const cookieOptionReadFromCookie = readCookie(CookieNames.COOKIES_ACCEPTED)

const initialState = fromJS({
  sid,
  accessToken,
  isAuthenticated,
  locale,
  cookieOption: cookieOptionReadFromCookie,
  created: false,
  registrationException,
  requestRunning: false,
  updating: false,
  darkMode: false,
  preferSummaries: false,
  globalClusterDefault: true,
  disableNotificationAlerts: false,
  prefersDarkMode: false,
  loginTypeId: 1,
  showSurvey: false,
  preferMedia: true
})

const resetState = fromJS({
  sid: '',
  accessToken: '',
  created: false,
  isAuthenticated: false,
  locale,
  registrationException,
  requestRunning: false,
  updating: false
})

const reset = state => resetState.merge({
  capabilities: state.get('capabilities'),
  cookieOption: state.get('cookieOption'),
  darkMode: state.get('darkMode'),
  preferSummaries: state.get('preferSummaries'),
  globalClusterDefault: state.get('globalClusterDefault'),
  disableNotificationAlerts: state.get('disableNotificationAlerts'),
  prefersDarkMode: state.get('prefersDarkMode'),
  loginTypeId: state.get('loginTypeId'),
  showSurvey: state.get('showSurvey'),
  preferMedia: state.get('preferMedia')
})

export default handleActions({
  [Actions.setUser]: (state, { payload: user }) => {
    const newUser = fromJS(user)
      .update('capabilities', caps => caps.reduce(
        (acc, value, key) => acc.set(decamelize(key).toUpperCase(), value),
        fromJS({})
      ))
      .set('isAuthenticated', true)

    return state.merge(newUser)
  },

  [Actions.setCookieOption]: (state, { payload: { cookieOption } }) => state.set('cookieOption', cookieOption),

  [Actions.registerUserRequestSuccess]: (state, { payload: response }) => {
    if (response.statusCode === 201) {
      return state.set('created', true)
    }

    return state.merge({ registrationException: response, requestRunning: false })
  },
  [Actions.registerUserRequestError]: (state, { payload: response }) => state.set('registrationException', response),

  [Actions.setDarkMode]: (state, { payload }) => state.set('darkMode', payload),
  [Actions.setPrefersDarkMode]: (state, { payload }) => state.set('prefersDarkMode', payload),

  [Actions.setField]: (state, { payload: { field, value } }) => state.set(field, value),

  [Actions.reset]: reset,

  [SurveyActions.submitSurveySuccess]: state => state.set('showSurvey', false),

  [Actions.updateUserRequestStart]: state => state.set('updating', true),
  [Actions.updateUserRequestSuccess]: state => state.set('updating', false),
  [Actions.updateUserRequestError]: state => state.set('updating', false),

  [AppActions.loginRequestError]: reset,
  [AppActions.logoutRequestStart]: state => state.set('isAuthenticated', false),
  [AppActions.logoutRequestSuccess]: reset
}, initialState)
