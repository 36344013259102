import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Chip,
  Collapse,
  Paper,
  TableCell,
  TableRow
} from '@mui/material'

import { blue, blueGrey } from '@mui/material/colors'

import useDevice from 'hooks/useDevice'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { IconButton } from 'containers/themed'
import CampaignName from 'containers/content_desk_new/campaigns/campaign/CampaignName'
import CampaignStatus from 'containers/content_desk_new/campaigns/campaign/CampaignStatus'
import CampaignProgress from 'containers/content_desk_new/campaigns/campaign/CampaignProgress'
import CampaignActions from 'containers/content_desk_new/campaigns/campaign/CampaignActions'

import Contents from 'components/content_desk_new/contents/Contents'

const useStyles = makeStyles()({
  tableCell: {
    paddingTop: 8,
    paddingBottom: 8
  },
  active: {
    borderLeft: '5px solid',
    borderColor: blue[500]
  },
  inactive: {
    borderLeft: '5px solid',
    borderColor: blueGrey[500]
  }
})

const Campaign = ({
  campaign
}) => {
  const { classes } = useStyles()
  const device = useDevice()
  const [showContents, setShowContents] = useState(false)
  const contents = fromJS({
    total: campaign.get('ccdContentsCount'),
    items: campaign.get('ccdContents')
  })
  const campaignActive = campaign.get('active') && !campaign.get('defaultAssigned')

  let maxLength = 16

  if (device.get('xl')) {
    maxLength = 54
  } else if (device.get('lg')) {
    maxLength = 34
  }

  let assignedUserName = ''

  if (campaign.get('assignedUser')) {
    assignedUserName = `${campaign.getIn(['assignedUser', 'firstName'])} ${campaign.getIn(['assignedUser', 'lastName'])}`
  }

  return (
    <>
      <TableRow className={campaignActive ? classes.active : classes.inactive}>
        <TableCell className={classes.tableCell}>
          <CampaignName
            campaign={campaign}
            maxLength={maxLength}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <CampaignProgress campaign={campaign} />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Chip
            label={campaign.get('ccdContentsCount')}
            color="primary"
          />
        </TableCell>
        <TableCell>
          <CampaignStatus
            campaign={campaign}
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          {assignedUserName}
        </TableCell>
        <TableCell className={classes.tableCell}>
          <IconButton
            disabled={campaign.get('ccdContentsCount') === 0}
            onClick={() => setShowContents(!showContents)}
          >
            {showContents ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <CampaignActions campaign={campaign} />
        </TableCell>
      </TableRow>
      <TableRow
        sx={{ display: showContents ? 'table-row' : 'none', '& > *': { borderBottom: 'unset !important' } }}
        selected={showContents}
        className={campaignActive ? classes.active : classes.inactive}
      >
        <TableCell
          colSpan={6}
          className={classes.tableCell}
        >
          <Collapse
            in={showContents}
            unmountOnExit
          >
            <Paper elevation={3}>
              <Contents
                contents={contents}
                showCampaigns={false}
              />
            </Paper>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Campaign.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired
}

export default Campaign
