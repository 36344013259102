import { call, put, select, takeEvery, throttle, all } from 'redux-saga/effects'

import * as Actions from 'actions/subscriptions'
import * as Api from 'api/bff'
import { genericErrorMessage, showAppMessage, exception } from 'actions/app'
import {
  getSaveSubscriptionsBody,
  getAllowedStaticMediaReviewCodes,
  getStaticMediaReviewTopNewsCodeIds,
  getSelectedCodeFilters,
  getI18n
} from 'selectors'

export function* saveSubscriptions() {
  try {
    const body = yield select(getSaveSubscriptionsBody)

    yield call(Api.saveSubscriptions, body)

    yield put(Actions.saveSubscriptionsSuccess())
  } catch (error) {
    yield put(Actions.saveSubscriptionsError(error))
    yield put(genericErrorMessage())
    yield put(exception(error))
  }
}

export function* updateTopicSelection({ payload: { topic, selected } }) {
  yield put(Actions.saveSubscriptionsStart())
  const i18n = yield select(getI18n)

  if (selected) {
    yield put(showAppMessage({ text: `<b>${topic.get('name')}</b> ${i18n.get('added')}` }))
  } else {
    yield put(showAppMessage({ text: `<b>${topic.get('name')}</b> ${i18n.get('removed')}` }))
  }
}

export function* updateEditionSelection({ payload: { edition, selected } }) {
  yield put(Actions.saveSubscriptionsStart())
  const i18n = yield select(getI18n)

  if (selected) {
    yield put(showAppMessage({ text: `<b>${edition.get('name')}</b> ${i18n.get('added')}` }))
  } else {
    yield put(showAppMessage({ text: `<b>${edition.get('name')}</b> ${i18n.get('removed')}` }))
  }
}

export function* selectAllTopics() {
  const i18n = yield select(getI18n)
  const topics = yield select(getAllowedStaticMediaReviewCodes)
  const selectedCodeFilters = yield select(getSelectedCodeFilters)

  let filteredTopics = topics

  if (!selectedCodeFilters.isEmpty()) {
    filteredTopics = topics.filter(t => selectedCodeFilters.includes(t.get('id')) || t.get('topnews') === true)
  }

  const topicIds = filteredTopics.map(t => t.get('id'))
  yield put(Actions.setTopics(topicIds))
  yield put(Actions.saveSubscriptionsStart())
  yield put(showAppMessage({ text: i18n.get('all_topics_selected') }))
}

export function* deselectAllTopics() {
  const i18n = yield select(getI18n)
  const topicIds = yield select(getStaticMediaReviewTopNewsCodeIds)
  yield put(Actions.setTopics(topicIds))
  yield put(Actions.saveSubscriptionsStart())
  yield put(showAppMessage({ text: i18n.get('all_topics_deselected') }))
}

export function* watchSaveSubscriptions() {
  yield throttle(1000, Actions.saveSubscriptionsStart, saveSubscriptions)
}

export function* watchUpdateTopicSelection() {
  yield takeEvery(Actions.updateTopicSelection, updateTopicSelection)
}

export function* watchUpdateEditionSelection() {
  yield takeEvery(Actions.updateEditionSelection, updateEditionSelection)
}

export function* watchSelectAllTopics() {
  yield takeEvery(Actions.selectAllTopics, selectAllTopics)
}

export function* watchDeselectAllTopics() {
  yield takeEvery(Actions.deselectAllTopics, deselectAllTopics)
}

export default function* subscriptionsSaga() {
  yield all([
    watchSaveSubscriptions(),
    watchUpdateTopicSelection(),
    watchUpdateEditionSelection(),
    watchSelectAllTopics(),
    watchDeselectAllTopics()
  ])
}
