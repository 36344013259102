import { put, takeEvery, all, select } from 'redux-saga/effects'
import * as Selectors from 'selectors'
import * as Actions from 'actions/contact_management'

export function* uiEnteredQuery({ payload: query }) {
  const selectedQueryType = yield select(Selectors.getCmSelectedQueryType)

  if (query !== '') {
    const queries = query.split(',').map(q => q.trim()).filter(q => q)

    for (let i = 0; i < queries.length; i += 1) {
      yield put(Actions.addSearchQuery({
        query: queries[i],
        selectedQueryType
      }))
    }
  }

  yield put(Actions.fetchAll())
}

export function* addDistributionListContactFilter({ payload }) {
  yield put(Actions.addDistributionListContactsSearchQuery({
    query: payload,
    selectedQueryType: 'distribution_list_contacts'
  }))

  yield put(Actions.fetchAll())
}

export function* uiRemoveFilter({ payload: { index, field } }) {
  yield put(Actions.removeFilter({ index, field }))
  yield put(Actions.fetchAll())
}

export function* uiRemoveDistributionListContactFilter() {
  yield put(Actions.fetchAll())
}

export function* uiUpdateFilter({ payload }) {
  yield put(Actions.updateFilter(payload))
  yield put(Actions.fetchAll())
}

export function* watchUiEnteredQuery() {
  yield takeEvery(Actions.uiEnteredQuery, uiEnteredQuery)
}

export function* watchAddDistributionListContactFilter() {
  yield takeEvery(Actions.addDistributionListContactFilter, addDistributionListContactFilter)
}

export function* watchUiRemoveFilter() {
  yield takeEvery(Actions.uiRemoveFilter, uiRemoveFilter)
}

export function* watchUiRemoveDistributionListContactFilter() {
  yield takeEvery(Actions.removeDistributionListContactFilter, uiRemoveDistributionListContactFilter)
}

export function* watchUiUpdateFilter() {
  yield takeEvery(Actions.uiUpdateFilter, uiUpdateFilter)
}

export default function* filterSaga() {
  yield all([
    watchUiEnteredQuery(),
    watchAddDistributionListContactFilter(),
    watchUiRemoveFilter(),
    watchUiRemoveDistributionListContactFilter(),
    watchUiUpdateFilter()
  ])
}
