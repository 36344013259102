import { connect } from 'react-redux'

import NoDataMessage from 'components/charts/chart/no_data_message'
import { navigate } from 'actions/navigation'

export default connect(
  null,
  {
    navigate
  }
)(NoDataMessage)
