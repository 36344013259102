/* global window */
import React from 'react'
import PropTypes from 'prop-types'
import { Grow, useScrollTrigger } from '@mui/material/'

import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

export default function ScrollToTop(props) {
  const { classes } = useStyles()

  const trigger = useScrollTrigger({
    target: props.window ? props.window() : undefined,
    disableHysteresis: true,
    threshold: 1200
  })

  return (
    <Grow in={trigger}>
      <div
        onClick={() => window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })}
        role="presentation"
        className={classes.root}
      >
        {props.children}
      </div>
    </Grow>
  )
}

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func
}
