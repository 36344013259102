import { createAction } from 'redux-actions'

const defaultPrefix = 'THEMAX/'
let prefix = defaultPrefix

export const onMount = createAction(`${prefix}ON_MOUNT`)
export const onUnmount = createAction(`${prefix}ON_UNMOUNT`)

// DATA
prefix = `${defaultPrefix}DATA/`

export const setSelectedEditorialPlan = createAction(`${prefix}SET_SELECTED_EDITORIAL_PLAN`)

// UI
prefix = `${defaultPrefix}UI/`
export const setThemaxModuleInitialized = createAction(`${prefix}SET_THEMAX_MODULE_INITIALIZED`)

export const setSelectedQueryType = createAction(`${prefix}SET_SELECTED_QUERY_TYPE`)
export const changeTabView = createAction(`${prefix}CHANGE_TAB_VIEW`)
export const toggleAdvancedSearch = createAction(`${prefix}TOGGLE_ADVANCED_SEARCH`)
export const toggleDateForm = createAction(`${prefix}TOGGLE_DATE_FORM`)

export const uiRemoveFilter = createAction(`${prefix}REMOVE_FILTER`)
export const uiInvertFilter = createAction(`${prefix}INVERT_FILTER`)
export const uiUpdateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const toggleActiveFilters = createAction(`${prefix}TOGGLE_ACTIVE_FILTERS`)
export const uiEnteredQuery = createAction(`${prefix}ENTERED_QUERY`)

export const openDetailedViewDialog = createAction(`${prefix}OPEN_DETAILED_VIEW_DIALOG`)
export const closeDetailedViewDialog = createAction(`${prefix}CLOSE_DETAILED_VIEW_DIALOG`)

export const openWelcomeDialog = createAction(`${prefix}OPEN_WELCOME_DIALOG`)
export const closeWelcomeDialog = createAction(`${prefix}CLOSE_WELCOME_DIALOG`)

export const openOrderDialog = createAction(`${prefix}OPEN_ORDER_DIALOG`)
export const closeOrderDialog = createAction(`${prefix}CLOSE_ORDER_DIALOG`)

export const closeOrderSuccessDialog = createAction(`${prefix}CLOSE_ORDER_SUCCESS_DIALOG`)

// FILTER
prefix = `${defaultPrefix}FILTER/`
export const setSelectedThemaxFilters = createAction(`${prefix}SET_SELECTED_THEMAX_FILTERS`)
export const applyThemaxFilters = createAction(`${prefix}APPLY_THEMAX_FILTERS`)
export const resetThemaxFilters = createAction(`${prefix}RESET_THEMAX_FILTERS`)
export const removeFilter = createAction(`${prefix}REMOVE_FILTER`)
export const invertFilter = createAction(`${prefix}INVERT_FILTER`)
export const updateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const uiApplyDateFilter = createAction(`${prefix}UI_APPLY_DATE_FILTER`)
export const uiChooseQuickSelect = createAction(`${prefix}UI_CHOOSE_QUICK_SELECT`)
export const changeDate = createAction(`${prefix}CHANGE_DATE`)
export const addQuery = createAction(`${prefix}ADD_QUERY`)
export const setThemaxPage = createAction(`${prefix}SET_THEMAX_PAGE`)
export const setThemaxLimit = createAction(`${prefix}SET_THEMAX_LIMIT`)
export const setThemaxSort = createAction(`${prefix}SET_THEMAX_SORT`)
export const updateThemaxSort = createAction(`${prefix}UPDATE_THEMAX_SORT`)
export const setSelectedPublicationIds = createAction(`${prefix}SET_SELECTED_PUBLICATION_IDS`)

// SEARCH
prefix = `${defaultPrefix}SEARCH/`
export const themaxSearchRequestStart = createAction(`${prefix}THEMAX_SEARCH_REQUEST_START`)
export const themaxSearchRequestSuccess = createAction(`${prefix}THEMAX_SEARCH_REQUEST_SUCCESS`)
export const themaxSearchRequestError = createAction(`${prefix}THEMAX_SEARCH_REQUEST_ERROR`)

export const themaxSearchEditorialPlans = createAction(`${prefix}THEMAX_SEARCH_EDITORIAL_PLANS`)
export const themaxSearchEditorialPlansSuccess = createAction(`${prefix}THEMAX_SEARCH_EDITORIAL_PLANS_SUCCESS`)
export const themaxSearchEditorialPlansError = createAction(`${prefix}THEMAX_SEARCH_EDITORIAL_PLANS_ERROR`)

export const themaxFetchRelatedEditorialPlansStart = createAction(`${prefix}THEMAX_FETCH_RELATED_EDITORIAL_PLANS_START`)
export const themaxFetchRelatedEditorialPlansSuccess = createAction(`${prefix}THEMAX_FETCH_RELATED_EDITORIAL_PLANS_SUCCESS`)
export const themaxFetchRelatedEditorialPlansError = createAction(`${prefix}THEMAX_FETCH_RELATED_EDITORIAL_PLANS_ERROR`)

export const themaxSearchPublications = createAction(`${prefix}THEMAX_SEARCH_PUBLICATIONS`)
export const themaxSearchPublicationsSuccess = createAction(`${prefix}THEMAX_SEARCH_PUBLICATIONS_SUCCESS`)
export const themaxSearchPublicationsError = createAction(`${prefix}THEMAX_SEARCH_PUBLICATIONS_ERROR`)

export const themaxFetchMorePublicationsStart = createAction(`${prefix}THEMAX_FETCH_MORE_PUBLICATIONS`)
export const themaxFetchMorePublicationsSuccess = createAction(`${prefix}THEMAX_FETCH_MORE_PUBLICATIONS_SUCCESS`)
export const themaxFetchMorePublicationsError = createAction(`${prefix}THEMAX_FETCH_MORE_PUBLICATIONS_ERROR`)

export const themaxFetchMoreEditorialPlansStart = createAction(`${prefix}THEMAX_FETCH_MORE_EDITORIAL_PLANS_START`)
export const themaxFetchMoreEditorialPlansSuccess = createAction(`${prefix}THEMAX_FETCH_MORE_EDITORIAL_PLANS_SUCCESS`)
export const themaxFetchMoreEditorialPlansError = createAction(`${prefix}THEMAX_FETCH_MORE_EDITORIAL_PLANS_ERROR`)

export const themaxFetchMoreRelatedEditorialPlansStart = createAction(`${prefix}THEMAX_FETCH_MORE_RELATED_EDITORIAL_PLANS_START`)
export const themaxFetchMoreRelatedEditorialPlansSuccess = createAction(`${prefix}THEMAX_FETCH_MORE_RELATED_EDITORIAL_PLANS_SUCCESS`)
export const themaxFetchMoreRelatedEditorialPlansError = createAction(`${prefix}THEMAX_FETCH_MORE_RELATED_EDITORIAL_PLANS_ERROR`)

// BOOKMARKING
export const themaxSaveBookmarkStart = createAction(`${prefix}THEMAX_SAVE_BOOKMARK_START`)
export const themaxSaveBookmarkSuccess = createAction(`${prefix}THEMAX_SAVE_BOOKMARK_SUCCESS`)
export const themaxSaveBookmarkError = createAction(`${prefix}THEMAX_SAVE_BOOKMARK_ERROR`)

export const themaxDeleteBookmarkStart = createAction(`${prefix}THEMAX_DELETE_BOOKMARK_START`)
export const themaxDeleteBookmarkSuccess = createAction(`${prefix}THEMAX_DELETE_BOOKMARK_SUCCESS`)
export const themaxDeleteBookmarkError = createAction(`${prefix}THEMAX_DELETE_BOOKMARK_ERROR`)

// SAVED SEARCHES
prefix = `${defaultPrefix}SAVED_SEARCHES/`

export const saveThemaxSavedSearchStart = createAction(`${prefix}SAVE_THEMAX_SAVED_SEARCH_START`)
export const saveThemaxSavedSearchSuccess = createAction(`${prefix}SAVE_THEMAX_SAVED_SEARCH_SUCCESS`)
export const saveThemaxSavedSearchError = createAction(`${prefix}SAVE_THEMAX_SAVED_SEARCH_ERROR`)

export const deleteThemaxSavedSearchStart = createAction(`${prefix}DELETE_THEMAX_SAVED_SEARCH_START`)
export const deleteThemaxSavedSearchSuccess = createAction(`${prefix}DELETE_THEMAX_SAVED_SEARCH_SUCCESS`)
export const deleteThemaxSavedSearchError = createAction(`${prefix}DELETE_THEMAX_SAVED_SEARCH_ERROR`)

export const searchThemaxSavedSearchesStart = createAction(`${prefix}SEARCH_THEMAX_SAVED_SEARCHES_START`)
export const searchThemaxSavedSearchesSuccess = createAction(`${prefix}SEARCH_THEMAX_SAVED_SEARCHES_SUCCESS`)
export const searchThemaxSavedSearchesError = createAction(`${prefix}SEARCH_THEMAX_SAVED_SEARCHES_ERROR`)

export const fetchThemaxSavedSearchFiltersStart = createAction(`${prefix}FETCH_THEMAX_SAVED_SEARCH_FILTERS_START`)
export const fetchThemaxSavedSearchFiltersSuccess = createAction(`${prefix}FETCH_THEMAX_SAVED_SEARCH_FILTERS_SUCCESS`)
export const fetchThemaxSavedSearchFiltersError = createAction(`${prefix}FETCH_THEMAX_SAVED_SEARCH_FILTERS_ERROR`)

export const favoriteThemaxSavedSearchStart = createAction(`${prefix}FAVORITE_THEMAX_SAVED_SEARCH_START`)
export const favoriteThemaxSavedSearchSuccess = createAction(`${prefix}FAVORITE_THEMAX_SAVED_SEARCH_SUCCESS`)
export const favoriteThemaxSavedSearchError = createAction(`${prefix}FAVORITE_THEMAX_SAVED_SEARCH_ERROR`)

export const initializeThemaxSavedSearches = createAction(`${prefix}INITIALIZE_THEMAX_SAVED_SEARCHES`)
export const executeThemaxSavedSearch = createAction(`${prefix}EXECUTE_THEMAX_SAVED_SEARCH`)
export const resetThemaxSavedSearches = createAction(`${prefix}RESET_THEMAX_SAVED_SEARCHES`)
export const setThemaxSavedSearchFilters = createAction(`${prefix}SET_THEMAX_SAVED_SEARCH_FILTERS`)

// EXPORT
prefix = `${defaultPrefix}EXPORT/`
export const exportEditorialPlansStart = createAction(`${prefix}EXPORT_EDITORIAL_PLANS_START`)
export const exportEditorialPlansSuccess = createAction(`${prefix}EXPORT_EDITORIAL_PLANS_SUCCESS`)
export const exportEditorialPlansError = createAction(`${prefix}EXPORT_EDITORIAL_PLANS_ERROR`)
