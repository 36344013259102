import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as CustomTagActions from 'actions/custom_tags'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  show: false,
  selectedNewsId: 0
})

export default handleActions({
  [CustomTagActions.showAssignCustomTagsToNewsDialog]: (state, { payload: newsId }) => state.merge({
    show: true,
    selectedNewsId: newsId
  }),
  [CustomTagActions.hideAssignCustomTagsToNewsDialog]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)
