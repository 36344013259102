import { connect } from 'react-redux'

import SavedSearches from 'components/saved_searches'

import {
  searchThemaxSavedSearchesStart
} from 'actions/themax'

import {
  setViewType
} from 'actions/saved_searches'

import {
  getSavedSearchViewType,
  getThemaxSavedSearches as getSavedSearches
} from 'selectors'

const mapStateToProps = state => ({
  hasSavedSearches: !getSavedSearches(state).get('items').isEmpty(),
  showRestrictionHint: false,
  viewType: getSavedSearchViewType(state),
  tab: 'saved_searches',
  showTimeline: false,
  showFeed: false,
  loadThrough: 'themax/saved_searches'
})

export default connect(
  mapStateToProps,
  {
    onMount: searchThemaxSavedSearchesStart,
    onViewChange: setViewType,
    onTabChange: () => { }
  }
)(SavedSearches)
