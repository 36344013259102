import { connect } from 'react-redux'

import ActiveFilter from 'components/active_filter'
import { uiRemoveFilter, uiUpdateFilter } from 'actions/contact_management'
import { getI18n, getDevice } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  device: getDevice(state)
})

export default connect(
  mapStateToProps,
  {
    onRemove: uiRemoveFilter,
    onUpdate: uiUpdateFilter
  }
)(ActiveFilter)
