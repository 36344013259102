import { connect } from 'react-redux'

import StatisticsData from 'components/content_desk_new/contents/content_view/statistics_data'

import { fetchStatisticsStart } from 'actions/content_desk'
import { getCDeskSelectedContent } from 'selectors'

const mapStateToProps = (state, { data, type, requestRunning }) => {
  const plainText = getCDeskSelectedContent(state).get('plainText') || ''

  return {
    data,
    type,
    requestRunning,
    showEarnedStats: plainText.length >= 1200
  }
}

export default connect(
  mapStateToProps,
  {
    onAccuracyChange: fetchStatisticsStart
  }
)(StatisticsData)
