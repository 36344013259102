import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'
import { NewsPageSize, NewsPageMoreNewsSize } from 'static/constants'

export const initialState = fromJS({})

export default handleActions({
  [NewsActions.increasePaging]: (state, { payload: { groups, groupId } }) => {
    let ids = []

    if (groupId) {
      ids = [groupId]
    } else {
      ids = groups.map(({ id }) => id)
    }

    const updates = {}

    ids.forEach(id => {
      if (state.has(id.toString())) {
        const size = state.getIn([id.toString(), 'size'])

        Object.assign(updates, {
          [id]: {
            size,
            from: state.getIn([id.toString(), 'from']) + size
          }
        })
      } else {
        Object.assign(updates, {
          [id]: {
            size: NewsPageMoreNewsSize,
            from: NewsPageSize
          }
        })
      }
    })

    return state.merge(fromJS(updates))
  },
  [NewsActions.newsRequestStart]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
