import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/page_identities'
import * as AppActions from 'actions/app'

const defaultCharts = Set(['comments', 'shares', 'reactions'])

export const initialState = fromJS({
  selectedInteractionCharts: defaultCharts
})

export default handleActions({
  [Actions.toggleSelectedInteractionsChart]: (state, { payload: chart }) => state.update('selectedInteractionCharts', charts => {
    let newCharts = charts

    if (charts.has(chart)) {
      newCharts = newCharts.remove(chart)
    } else {
      newCharts = newCharts.add(chart)
    }

    if (newCharts.isEmpty()) {
      return defaultCharts
    }

    return newCharts
  }),

  [AppActions.resetState]: () => initialState
}, initialState)
