import { connect } from 'react-redux'

import EditorialPlans from 'components/themax/editorial_plans'
import { getThemaxData, getThemaxFetchMoreRequestRunning } from 'selectors'
import { themaxFetchMoreEditorialPlansStart } from 'actions/themax'

const mapStateToProps = state => ({
  editorialPlans: getThemaxData(state).getIn(['editorialPlans', 'items']),
  editorialPlansTotal: getThemaxData(state).getIn(['editorialPlans', 'total']),
  fetchMoreRequestRunning: getThemaxFetchMoreRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onScrollBottom: themaxFetchMoreEditorialPlansStart
  }
)(EditorialPlans)
