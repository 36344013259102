import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/export'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'

export const initialState = fromJS({
  attachSummaries: false,
  automaticTranslationEnabled: false,
  contentLanguage: undefined,
  templateLanguage: undefined,
  columns: ['group_key_figures'],
  coverId: null,
  dispatchConfig: null,
  pdfDispatchConfig: null,
  endDate: null,
  exportFilename: '',
  pdfCoverTitle: '',
  showArticleText: true,
  showCover: true,
  showEndPage: false,
  showTableOfContents: true,
  startDate: null
})

export default handleActions({
  [Actions.updateExportSettings]: (state, action) => {
    if (!action.payload.get('attachSummaries')) {
      return state.merge(action.payload.set('showArticleText', true))
    }

    return state.merge(action.payload)
  },
  [Actions.updateTranslationOptions]: (state, action) => state.merge(fromJS(action.payload)),
  [Actions.setValueInSettings]: (state, { payload: { name, value } }) => state.set(name, value),
  [Actions.setExportFilename]: (state, { payload: value }) => state.set('exportFilename', value),
  [Actions.updateExportMediaReviewHtmlConfig]: (state, action) => (
    state.setIn(['dispatchConfig', 'mediaReviewHtmlConfig'], action.payload)
  ),
  [Actions.updateExportDispatchConfig]: (state, action) => state.set('dispatchConfig', action.payload),

  [Actions.updateExportPdfDispatchConfig]: (state, action) => state.set('pdfDispatchConfig', fromJS(action.payload)),
  [Actions.updateExportMediaReviewPdfConfig]: (state, action) => (
    state.setIn(['pdfDispatchConfig', 'mediaReviewPdfConfig'], action.payload)
  ),
  [Actions.updateExportMediaReviewPdfCoverPageElement]: (state, { payload: element }) => (
    state.updateIn(
      ['pdfDispatchConfig', 'mediaReviewPdfConfig', 'mediaReviewPdfCoverPage', 'mediaReviewPdfCoverPageElements'],
      elements => elements.map(el => {
        if (el.get('id') === element.get('id')) {
          return element
        }

        return el
      })
    )
  ),

  [Actions.updateExportMediaReviewPdfEndPageElement]: (state, { payload: element }) => (
    state.updateIn(
      ['pdfDispatchConfig', 'mediaReviewPdfConfig', 'mediaReviewPdfEndPage', 'mediaReviewPdfEndPageElements'],
      elements => elements.map(el => {
        if (el.get('id') === element.get('id')) {
          return element
        }

        return el
      })
    )
  ),

  [Actions.setExportConfig]: (state, action) => state.merge(fromJS({
    pdfCoverTitle: action.payload.pdfCoverTitle || '',
    exportFilename: action.payload.exportFilename || '',
    coverId: (action.payload.pdfCovers.find(p => p.default) || { id: null }).id,
    columns: state.get('columns') || []
  })),

  [Actions.resetExportConfig]: state => state.merge(fromJS({
    columns: initialState.get('columns')
  })),

  [Actions.changeStartEndDate]: (state, { payload: { startDate, endDate } }) => state.merge({
    startDate,
    endDate
  }),

  [Actions.setUploadedHeaderLogoUrl]: (state, { payload: { url, filename } }) => (
    state.mergeIn(['dispatchConfig', 'mediaReviewHtmlConfig'], { headerLogo: url, headerLogoFilename: filename })
  ),
  [Actions.resetUploadedHeaderLogoUrl]: state => (
    state.mergeIn(['dispatchConfig', 'mediaReviewHtmlConfig'], { headerLogo: null, headerLogoFilename: null })
  ),

  [Actions.setUploadedAdvertisingBannerUrl]: (state, { payload: { url, filename } }) => (
    state.mergeIn(['dispatchConfig', 'mediaReviewHtmlConfig'], { advertisingBanner: url, advertisingBannerFilename: filename })
  ),
  [Actions.resetUploadedAdvertisingBannerUrl]: state => (
    state.mergeIn(['dispatchConfig', 'mediaReviewHtmlConfig'], { advertisingBanner: null, advertisingBannerFilename: null })
  ),

  [ConfigActions.setStatics]: (state, { payload: {
    htmlDispatchConfigs: [htmlDispatchConfig],
    pdfDispatchConfigs: [pdfDispatchConfig]
  } }) => (
    state.set('dispatchConfig', fromJS(htmlDispatchConfig))
      .set('pdfDispatchConfig', fromJS(pdfDispatchConfig))
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
