import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap } from 'immutable'
import useI18n from 'hooks/useI18n'

import { Grid, TextField, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import PhotoUpload from 'components/photo_upload'
import ContactImage from 'components/contact_management/contact_image'

const DEFAULT_IMAGE_URL = 'https://static.pressrelations.de/pics/groot/ccd/portrait_placeholder.png'

const useStyles = makeStyles()({
  caption: {
    fontSize: '10pt',
    color: '#7C7C7C',
    marginTop: '8px'
  }
})

export default function EditContactProfile({ contact, onChange, onUpload }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })

  const handlePictureDelete = () => {
    onUpload(null)
    onChange({ key: 'picture', value: DEFAULT_IMAGE_URL })
  }

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column-reverse', sm: 'column-reverse', md: 'row' }}
      justifyContent="space-between"
      sx={{
        '& .MuiTextField-root': { margin: 0 }
      }}
      gap={2}
    >
      <Grid
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('first_name')}
            name="firstName"
            value={contact.get('firstName') || ''}
            onChange={handleChange}
            size="small"
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            required
            label={i18n.get('last_name')}
            name="lastName"
            value={contact.get('lastName')}
            onChange={handleChange}
            size="small"
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('title')}
            name="title"
            value={contact.get('title') || ''}
            onChange={handleChange}
            size="small"
            inputProps={{ maxLength: 32 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('job_title')}
            name="jobTitle"
            value={contact.get('jobTitle') || ''}
            onChange={handleChange}
            size="small"
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            required
            label={i18n.get('email')}
            name="mail"
            value={contact.get('mail')}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
        >
          <TextField
            fullWidth
            label={i18n.get('website')}
            name="website"
            value={contact.get('website') || ''}
            onChange={handleChange}
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
        >
          <TextField
            fullWidth
            label={i18n.get('outlet')}
            name="externalPublication"
            value={contact.get('externalPublication') || ''}
            onChange={handleChange}
            size="small"
            inputProps={{ maxLength: 255 }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <ContactImage url={contact.get('picture')} />
        <PhotoUpload
          buttonProps={{
            variant: 'contained',
            color: 'primary',
            sx: { marginLeft: '50px' }
          }}
          photoUrl={contact.get('picture')}
          onFileChange={onUpload}
          onFileUrlChange={value => onChange({ key: 'picture', value })}
          maxSize={2000000}
          onDelete={handlePictureDelete}
        />
        <Box className={classes.caption}>{i18n.get('upload_file_limit', { n: 2 })}</Box>
      </Grid>
    </Box>
  )
}

EditContactProfile.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired
}
