import React, { useState } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { fromJS, Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import decamelize from 'decamelize'

import { MenuItem, Select, InputLabel, FormControl, Checkbox } from '@mui/material'

import { Cached } from '@mui/icons-material'

import { StaggeredListItem } from 'components/staggered_list'

import { Button } from 'containers/themed'

const useStyles = makeStyles()(({
  facetFilters: {
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '20px'
  },
  filterButtons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px'
  }
}))

const Facets = ({
  filtersFormData,
  resultsTab,
  data,
  onFilterChange,
  onSearchJournalists,
  onSearchOutlets,
  onSearchArticles,
  resetPaginationFilter
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [openedFacetValue, setOpenedFacetValue] = useState([])

  const categoryFilters = filtersFormData.get(resultsTab)
  const facetFilters = categoryFilters.get('facetFilters')
  const facets = data.getIn([resultsTab, 'facets'])

  const compareArrays = (a, b) => a.length === b.length && a.every((element, index) => element === b[index])

  const handleOnSearch = () => {
    if (resultsTab === 'journalists') {
      onSearchJournalists()
    }

    if (resultsTab === 'outlets') {
      onSearchOutlets()
    }

    if (resultsTab === 'articles') {
      onSearchArticles()
    }
  }

  const handleSelectChange = (event, facet) => {
    const { target: { value } } = event

    let newFacetsFilters

    if (value.length === 0) {
      newFacetsFilters = facetFilters.delete(facet)
    } else {
      newFacetsFilters = facetFilters.set(facet, fromJS(value))
    }

    onFilterChange({ key: resultsTab, value: categoryFilters.set('facetFilters', newFacetsFilters) })
  }

  const handleResetFacetFilters = () => {
    onFilterChange({ key: resultsTab, value: categoryFilters.set('facetFilters', fromJS({})) })
    handleOnSearch()
  }

  const handleApplyFacetFilters = (_event, currentFacetFilter) => {
    if (compareArrays(openedFacetValue, currentFacetFilter)) {
      return
    }

    resetPaginationFilter(resultsTab)
    handleOnSearch()
  }

  const handleOnFacetSelectOpen = (_event, currentFacetFilter) => {
    setOpenedFacetValue(currentFacetFilter)
  }

  const isChecked = (facet, name) => {
    const selectedFacets = facetFilters.get(facet)

    if (selectedFacets) {
      return selectedFacets.toJS().includes(name)
    }

    return false
  }

  const renderFacetOptions = facet => (
    facets.get(facet).map((f, index) => (
      <MenuItem
        key={index}
        value={f.get('name')}
      >
        <Checkbox
          checked={isChecked(facet, f.get('name'))}
        />
        {f.get('name')} ({f.get('count')})
      </MenuItem>
    ))
  )

  const renderFacetSelect = (facet, index) => {
    const facetFilter = facetFilters.get(facet)

    return (
      <StaggeredListItem
        key={index}
        delay={index * 50}
      >
        <FormControl
          size="medium"
          key={index}
          fullWidth
        >
          <InputLabel id="facet-select">{facet === 'title' ? i18n.get('job_title') : i18n.get(decamelize(facet))}</InputLabel>
          <Select
            labelId="facet-select"
            multiple
            value={facetFilter ? facetFilter.toJS() : []}
            renderValue={selected => selected.join(', ')}
            label={facet === 'title' ? i18n.get('job_title') : i18n.get(decamelize(facet))}
            onChange={event => handleSelectChange(event, facet)}
            onOpen={event => handleOnFacetSelectOpen(event, facetFilter ? facetFilter.toJS() : [])}
            onClose={event => handleApplyFacetFilters(event, facetFilter ? facetFilter.toJS() : [])}
          >
            {renderFacetOptions(facet)}
          </Select>
        </FormControl>
      </StaggeredListItem>
    )
  }

  if (!facets) {
    return (
      null
    )
  }

  return (
    <>
      <div className={classes.facetFilters}>
        {Object.keys(facets.toJS()).map((facet, index) => renderFacetSelect(facet, index))}
      </div>
      <StaggeredListItem delay={300}>
        <div className={classes.filterButtons}>
          <Button
            disabled={facetFilters.size === 0}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleResetFacetFilters}
            endIcon={<Cached />}
          >
            {i18n.get('reset')}
          </Button>
        </div>
      </StaggeredListItem>
    </>
  )
}

Facets.propTypes = {
  filtersFormData: PropTypes.instanceOf(Map).isRequired,
  resultsTab: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,

  onFilterChange: PropTypes.func.isRequired,
  onSearchJournalists: PropTypes.func.isRequired,
  onSearchOutlets: PropTypes.func.isRequired,
  onSearchArticles: PropTypes.func.isRequired,
  resetPaginationFilter: PropTypes.func.isRequired
}

export default Facets
