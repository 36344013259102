import { connect } from 'react-redux'

import NotificationsButton from 'components/notifications/button'

import { getNotifications, getUser } from 'selectors'
import { openDrawer } from 'actions/notifications'

const mapStateToProps = state => ({
  hasNotificationAlertsOn: !(getUser(state).get('disableNotificationAlerts')),
  hasNotifications: getNotifications(state).size > 0,
  unreadCount: getNotifications(state).filter(n => n.get('seenAt') == null).size
})

export default connect(
  mapStateToProps,
  {
    onOpen: openDrawer
  }
)(NotificationsButton)
