import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { $getSelection, $isRangeSelection, RootNode } from 'lexical'

import { trimTextContentFromAnchor } from '@lexical/selection'

import { $restoreEditorState } from '@lexical/utils'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

const MaxLengthPlugin = ({ maxLength }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    let lastRestoredEditorState = null

    return editor.registerNodeTransform(RootNode, rootNode => {
      const selection = $getSelection()

      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return
      }

      const prevEditorState = editor.getEditorState()
      const prevTextContentSize = prevEditorState.read(() => rootNode.getTextContentSize())
      const textContentSize = rootNode.getTextContentSize()

      if (prevTextContentSize !== textContentSize) {
        const delCount = textContentSize - maxLength
        const { anchor } = selection

        if (delCount > 0) {
          // Restore the old editor state instead if the last text content was already at the limit.
          if (
            prevTextContentSize === maxLength
            && lastRestoredEditorState !== prevEditorState
          ) {
            lastRestoredEditorState = prevEditorState
            $restoreEditorState(editor, prevEditorState)
          } else {
            trimTextContentFromAnchor(editor, anchor, delCount)
          }
        }
      }
    })
  }, [editor, maxLength])

  return null
}

MaxLengthPlugin.propTypes = {
  maxLength: PropTypes.number.isRequired
}

export default MaxLengthPlugin
