import { connect } from 'react-redux'
import { List } from 'immutable'

import {
  toggleAdvancedSearch,
  setSelectedThemaxFilters as setSelectedFilters,
  applyThemaxFilters as applyFilters,
  resetThemaxFilters as resetFilters
} from 'actions/themax'
import SearchForm from 'components/themax/search_form'
import {
  getAllStaticMediaTypes,
  getAllStaticMediaTopics,
  getThemaxCountries,
  getThemaxFilters,
  getThemaxShowAdvancedSearch,
  getI18n,
  getDevice,
  getHasThemaxPaidVersion
} from 'selectors'

const mapStateToProps = state => ({
  active: getThemaxShowAdvancedSearch(state),
  mediaTypes: getAllStaticMediaTypes(state).filter(mediaType => mediaType.get('mediaSourceIds', List()).includes(7)),
  mediaTopics: getAllStaticMediaTopics(state),
  countries: getThemaxCountries(state),
  reachFilter: getThemaxFilters(state).get('reachFilter'),
  pageAveFilter: getThemaxFilters(state).get('pageAveFilter'),
  selectedMediaTypes: getThemaxFilters(state).get('mediaTypes'),
  selectedMediaTopics: getThemaxFilters(state).get('mediaTopics'),
  selectedCountries: getThemaxFilters(state).get('countries'),
  hasPaidVersion: getHasThemaxPaidVersion(state),
  booleans: getThemaxFilters(state).get('booleans'),
  i18n: getI18n(state),
  device: getDevice(state)
})

export default connect(
  mapStateToProps,
  {
    setSelectedFilters,
    applyFilters,
    resetFilters,
    onClose: toggleAdvancedSearch
  }
)(SearchForm)
