import React from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { makeStyles } from 'tss-react/mui'
import { Map } from 'immutable'

import { createLoadable } from 'utils/loadable'

import { Capabilities } from 'static/constants'

import { IconButton, Menu, Button } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

import useI18n from 'hooks/useI18n'

import { MenuItem } from '@mui/material'

const useStyles = makeStyles()({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *': {
      marginRight: 5,
      '&:last-child': {
        marginRight: 0
      }
    }
  }
})

const ExportMenu = createLoadable(() => (
  import(/* webpackChunkName: "MediaReviewDetailExportMenu" */ 'containers/ExportMenu')
))

const ExportButton = CapSafe(ExportMenu, Capabilities.HAS_MEDIA_REVIEW_DISPATCH_FEATURE)
const AiAnalysis = CapSafe(Button, Capabilities.HAS_AI_SUMMARY_FEATURE)

export default function Actions({
  onCustomTagClick,
  onRemoveCustomTagClick,
  onAiAnalysisClick,
  capabilities
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const menuActions = []

  if (capabilities.get(Capabilities.HAS_CUSTOM_TAGS)) {
    menuActions.push(
      <MenuItem
        key="pin"
        onClick={() => onCustomTagClick()}
      >
        {i18n.get('pin')}
      </MenuItem>,
      <MenuItem
        key="unpin"
        onClick={() => onRemoveCustomTagClick()}
      >
        {i18n.get('unpin')}
      </MenuItem>
    )
  }

  return (
    <div className={classes.actions}>
      <AiAnalysis
        startIcon={<AutoAwesomeIcon />}
        color="inherit"
        onClick={() => onAiAnalysisClick()}
      >
        {i18n.get('ai_analysis')}
      </AiAnalysis>

      <div>
        <ExportButton />
      </div>

      {menuActions.length > 0 && (
        <Menu
          handle={
            (
              <IconButton
                color="inherit"
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
        >
          {menuActions}
        </Menu>
      )}
    </div>
  )
}

Actions.propTypes = {
  capabilities: PropTypes.instanceOf(Map).isRequired,

  onCustomTagClick: PropTypes.func.isRequired,
  onRemoveCustomTagClick: PropTypes.func.isRequired,
  onAiAnalysisClick: PropTypes.func.isRequired
}
