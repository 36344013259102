import { connect } from 'react-redux'

import QueryManager from 'components/darknet/query_manager'

import {
  getScoreQueryDialogOpen,
  getSearchQueryDialogOpen,
  getDarknetTestSearchQueryRunning,
  getUserLocale
} from 'selectors'
import {
  closeScoreQueryDialog as onScoreDialogClose,
  openScoreQueryDialog as onScoreDialogOpen,
  closeSearchQueryDialog as onSearchDialogClose,
  openSearchQueryDialog as onSearchDialogOpen
} from 'actions/darknet'

const mapStateToProps = state => ({
  scoreQueryDialogOpen: getScoreQueryDialogOpen(state),
  searchQueryDialogOpen: getSearchQueryDialogOpen(state),
  testRunning: getDarknetTestSearchQueryRunning(state),
  userLocale: getUserLocale(state)
})

export default connect(
  mapStateToProps,
  {
    onScoreDialogClose,
    onSearchDialogClose,
    onScoreDialogOpen,
    onSearchDialogOpen
  }
)(QueryManager)
