import { combineReducers } from 'redux'
import { fromJS } from 'immutable'

import app from './app'
import config from './config'
import customTags from './custom_tags'
import dashboard from './dashboard'
import device from './device'
import environment from './environment'
import i18n from './i18n'
import identitySets from './identity_sets'
import influencers from './influencers'
import mailingLists from './mailing_lists'
import mediaReviews from './media_reviews'
import news from './news'
import pageIdentities from './page_identities'
import publications from './publications'
import savedSearches from './saved_searches'
import statics from './statics'
import subscriptions from './subscriptions'
import user from './user'
import whitelabel from './whitelabel'
import savedDashboard from './saved_dashboard'
import savedCharts from './saved_charts'
import darknet from './darknet'
import personalSettings from './personal_settings'
import contentDesk from './content_desk'
import contactManagement from './contact_management'
import emailTemplatesLibrary from './email_templates_library'
import notifications from './notifications'
import unsplash from './unsplash'
import anewstip from './anewstip'
import ust from './ust'
import administration from './administration'
import themax from './themax'
import ai from './ai'

import { logoutRequestSuccess } from '../actions/app'

const appReducer = routerReducer => combineReducers({
  app,
  ai,
  config,
  customTags,
  dashboard,
  device,
  environment,
  i18n,
  identitySets,
  influencers,
  mailingLists,
  mediaReviews,
  news,
  pageIdentities,
  publications,
  router: routerReducer,
  savedSearches,
  savedDashboard,
  savedCharts,
  statics,
  subscriptions,
  user,
  whitelabel,
  darknet,
  personalSettings,
  contentDesk,
  contactManagement,
  notifications,
  unsplash,
  anewstip,
  emailTemplatesLibrary,
  ust,
  administration,
  themax
})

// Pattern inspired by http://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = routerReducer => (state, action) => {
  let newState = state

  if (action.type === logoutRequestSuccess().type) {
    // Keep width and height for login page
    newState = {
      environment: state.environment,
      whitelabel: state.whitelabel,
      i18n: state.i18n,
      app: fromJS({
        activeNewsradarId: state.app.get('activeNewsradarId')
      }),
      device: state.device
    }
  }

  return appReducer(routerReducer)(newState, action)
}

// This root reducer is used to create the store
export default rootReducer
