import { connect } from 'react-redux'

import Dashboard from 'components/dashboard'

import {
  getSelectedDashboard,
  getDashboards,
  getDashboardIsEditing,
  getDashboardAutoRefresh,
  getWidth,
  getHeight,
  getSelectedDashboardConfig,
  getSavedDashboardShowDialog,
  getDashboardShowEditDialog
} from 'selectors'
import { loadDashboard, stopAutoRefresh, toggleAutoRefresh, showEditDialog, startAutoRefresh } from 'actions/dashboard'

const mapStateToProps = state => {
  const selectedDashboard = getSelectedDashboard(state)

  return {
    dashboardId: selectedDashboard.get('id'),
    dashboardName: selectedDashboard.get('name'),
    config: getSelectedDashboardConfig(state),
    hasDashboards: !getDashboards(state).isEmpty(),
    isEditing: getDashboardIsEditing(state),
    autoRefresh: getDashboardAutoRefresh(state),
    width: getWidth(state),
    height: getHeight(state),
    hide: getSavedDashboardShowDialog(state) || getDashboardShowEditDialog(state)
  }
}

export default connect(
  mapStateToProps,
  {
    onDashboardChange: loadDashboard,
    onMount: startAutoRefresh,
    onUnmount: stopAutoRefresh,
    onNewClick: showEditDialog,
    onExitFullscreen: toggleAutoRefresh
  }
)(Dashboard)
