/* global window */
import React, { useState } from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'

import useInterval from 'hooks/useInterval'
import useI18n from 'hooks/useI18n'

import { Snackbar, SnackbarContent } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    fontSize: 50,
    marginRight: 10
  },
  snackbarContent: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    maxWidth: '100vw'
  }
}))

const maxSeconds = 60

const reload = () => {
  window.location.reload()
}

export default function ForceReload() {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [seconds, setSeconds] = useState(0)
  const diff = maxSeconds - seconds

  useInterval(() => {
    setSeconds(seconds + 1)
  }, 1000)

  if (seconds >= maxSeconds) {
    reload()
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open
    >
      <SnackbarContent
        classes={{
          root: classes.snackbarContent
        }}
        message={(
          <>
            <div className={classes.message}>
              <ErrorIcon classes={{ root: classes.icon }} />

              <div>
                <div>
                  {i18n.get('force_reload_title')}
                </div>
                <div>
                  {i18n.get('force_reload_body', { seconds: diff < 0 ? 0 : diff })}
                </div>
              </div>
            </div>
          </>
          )}
        action={(
          <Button
            variant="contained"
            onClick={reload}
          >
            {i18n.get('reload_now')}
          </Button>
          )}
      />
    </Snackbar>
  )
}
