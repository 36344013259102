import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  from: 0,
  sortBy: 'reach',
  running: false,
  selectedIds: Set([])
})

export default handleActions({
  [Actions.influencersRequestSuccess]: state => state.update('from', from => from + 20).merge({
    running: false,
    selectedIds: Set([])
  }),
  [Actions.influencersRequestError]: state => state.set('running', false),
  [Actions.influencersRequestStart]: state => state.merge({
    from: 0,
    running: true
  }),
  [Actions.moreInfluencersRequestSuccess]: state => state.update('from', from => from + 20),

  [Actions.setSortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [Actions.selectInfluencer]: (state, { payload: influencer }) => {
    if (state.get('selectedIds').has(influencer.get('id'))) {
      return state.update('selectedIds', selectedIds => selectedIds.delete(influencer.get('id')))
    }

    return state.update('selectedIds', selectedIds => selectedIds.add(influencer.get('id')))
  },
  [Actions.resetInfluencerSelection]: state => state.set('selectedIds', Set([])),

  [AppActions.resetState]: () => initialState
}, initialState)
