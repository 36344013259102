import { connect } from 'react-redux'

import CalendarDialog from 'components/content_desk_new/calendar_dialog'

import {
  getCDeskCalendarEvents,
  getCDeskShowCalendarDialog,
  getCDeskRequestRunning,
  getCDeskCalendarDate
} from 'selectors'

import {
  closeCalendarDialog,
  openNewContentDialog,
  onCalendarContentClick,
  fetchCalendarEventsStart,
  openCampaignForm,
  setCalendarDate
} from 'actions/content_desk'

const mapStateToProps = state => ({
  events: getCDeskCalendarEvents(state),
  open: getCDeskShowCalendarDialog(state),
  calendarDate: getCDeskCalendarDate(state),
  requestRunning: getCDeskRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeCalendarDialog,
    onContentClick: onCalendarContentClick,
    onCampaignClick: openCampaignForm,
    onNewContentClick: openNewContentDialog,
    onNewCampaignClick: openCampaignForm,
    fetchContents: fetchCalendarEventsStart,
    setCalendarDate
  }
)(CalendarDialog)
