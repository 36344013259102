import { call, put, takeEvery, all } from 'redux-saga/effects'

import * as Actions from 'actions/news'
import * as Api from 'api/bff'
import { genericErrorMessage, exception } from 'actions/app'

export function* submitShopping({ payload: { news, shoppingOption } }) {
  try {
    const response = yield call(Api.submitShopping, news.get('id'), shoppingOption)

    if (response.acknowledged) {
      const newNews = news.set('orderStatus', 'ordered')
      yield put(Actions.updateNews([newNews]))
      yield put(Actions.submitShoppingSuccess())
    }
  } catch (error) {
    yield put(Actions.submitShoppingError(error))
    yield put(exception(error))
    yield put(genericErrorMessage())
  }
}

export function* watchSubmitShopping() {
  yield takeEvery(Actions.submitShoppingStart, submitShopping)
}

export default function* newsSaga() {
  yield all([
    watchSubmitShopping()
  ])
}
