import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({})

export default handleActions({
  [NewsActions.newsRequestSuccess]: (_state, { payload: { groups } }) => fromJS({}).withMutations(ctx => {
    groups.forEach(group => (
      ctx.set(
        group.id,
        fromJS(group).update('news', news => news.map(n => n.get('id')))
      )
    ))
  }),
  [NewsActions.moreNewsRequestStart]: (state, { payload: id }) => {
    const group = state.get(id).set('loadingMore', true)

    return state.set(id, group)
  },
  [NewsActions.moreNewsRequestSuccess]: (state, { payload: { data: { groups }, id } }) => {
    const groupData = groups.filter(c => c.id === id)[0]

    if (!groupData) {
      if (state.get(id)) {
        return state.set(id, state.get(id).withMutations(
          ctx => ctx.merge({ loadingMore: false })
        ))
      }

      return state
    }

    if (state.get(id)) {
      const oldNewsIds = state.getIn([id, 'news'])
      const news = groupData.news.filter(n => !oldNewsIds.includes(n.id))

      return state.set(id, state.get(id).withMutations(
        ctx => ctx
          .set('news', state.getIn([id, 'news']).concat(fromJS(news.map(n => n.id))))
          .set('loadingMore', false)
      ))
    }

    return state
  },
  [NewsActions.updateGroups]: (state, { payload: { newGroupIds, updatedGroups, newsId } }) => {
    const filteredState = state.map(s => s
      .set('news', s.get('news').filter(n => n !== newsId)))

    return filteredState.map(s => {
      if (newGroupIds.includes(s.get('id'))) {
        return s.set('news', s.get('news').insert(0, newsId))
      }

      return s
    }).merge(updatedGroups)
  },
  [NewsActions.updateGroupStats]: (state, { payload: { groups } }) => groups.reduce((newState, group) => {
    if (newState.get(group.id)) {
      return newState.setIn([group.id, 'stats'], fromJS(group.stats))
    }

    return newState
  }, state),

  [NewsActions.resetNews]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
