import { connect } from 'react-redux'

import HelpDialog from 'components/help_dialog'
import { toggleHelpDialog } from 'actions/news'
import { getNewsUi, getUser, getI18n } from 'selectors'

const mapStateToProps = state => ({
  showHelpDialog: getNewsUi(state).get('showHelpDialog'),
  languageId: getUser(state).get('languageId'),
  i18n: getI18n(state)
})

export default connect(mapStateToProps, { toggleHelpDialog })(HelpDialog)
