import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { normalizeIt, sort } from 'utils/normalizr'

import * as Actions from 'actions/saved_searches'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  entities: {},
  result: [],
  sorted: []
})

export const addSorted = state => state.set('sorted', sort(
  state.get('result'),
  state.get('entities')
))

export default handleActions({
  [Actions.setSavedSearches]: (state, { payload: { feeds } }) => (
    addSorted(state.merge(fromJS(normalizeIt({ feeds }, 'feed', 'feeds'))))
  ),

  [Actions.saveSavedSearchFeedSuccess]: (state, { payload: savedSearch }) => addSorted(state
    .update('entities', entities => entities.set(savedSearch.id, fromJS(savedSearch)))
    .update('result', result => (result.includes(savedSearch.id) ? result : result.push(savedSearch.id)))),

  [Actions.deleteSavedSearchFeedSuccess]: (state, { payload: id }) => addSorted(state
    .update('entities', entities => entities.delete(id))
    .update('result', result => result.filter(i => i !== id))),

  [AppActions.resetState]: () => initialState
}, initialState)
