import { createAction } from 'redux-actions'
import createLoadingAction from 'utils/createLoadingAction'

const prefix = 'SAVED_CHARTS/'

export const fetchSavedChartStart = createAction(`${prefix}FETCH_SAVED_CHART_START`)
export const fetchSavedChartSuccess = createAction(`${prefix}FETCH_SAVED_CHART_SUCCESS`)
export const fetchSavedChartError = createAction(`${prefix}FETCH_SAVED_CHART_ERROR`)

export const createSavedChartStart = createLoadingAction(`${prefix}CREATE_SAVED_CHART_START`, true)
export const createSavedChartSuccess = createLoadingAction(`${prefix}CREATE_SAVED_CHART_SUCCESS`, false)
export const createSavedChartError = createLoadingAction(`${prefix}CREATE_SAVED_CHART_ERROR`, false)

export const deleteSavedChartStart = createAction(`${prefix}DELETE_SAVED_CHART_START`)
export const deleteSavedChartSuccess = createAction(`${prefix}DELETE_SAVED_CHART_SUCCESS`)
export const deleteSavedChartError = createAction(`${prefix}DELETE_SAVED_CHART_ERROR`)

export const discardSavedChartStart = createAction(`${prefix}DISCARD_SAVED_CHART_START`)
export const discardSavedChartSuccess = createAction(`${prefix}DISCARD_SAVED_CHART_SUCCESS`)
export const discardSavedChartError = createAction(`${prefix}DISCARD_SAVED_CHART_ERROR`)

export const updateSavedChartStart = createAction(`${prefix}UPDATE_SAVED_CHART_START`)
export const updateSavedChartSuccess = createAction(`${prefix}UPDATE_SAVED_CHART_SUCCESS`)
export const updateSavedChartError = createAction(`${prefix}UPDATE_SAVED_CHART_ERROR`)

export const goToAdministration = createAction(`${prefix}GO_TO_ADMINISTRATION`)

export const showDialog = createAction(`${prefix}SHOW_DIALOG`)
export const hideDialog = createAction(`${prefix}HIDE_DIALOG`)
