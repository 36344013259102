import { combineReducers } from 'redux-immutable'

import aggregations from './aggregations'
import analysis from './analysis'
import charts from './charts'
import count from './count'
import data from './data'
import deletedNewsIds from './deleted_news_ids'
import editNews from './edit_news'
import exportReducer from './export'
import filter from './filter'
import filterTimeline from './filter_timeline'
import groups from './groups'
import groupSortOrder from './group_sort_order'
import paging from './paging'
import selectedNewsIds from './selected_news_ids'
import shoppingCart from './shopping_cart'
import shoppingNews from './shopping_news'
import staticCharts from './static_charts'
import sortBy from './sort_by'
import sortDirection from './sort_direction'
import total from './total'
import ui from './ui'
import viewConfig from './view_config'

export default combineReducers({
  aggregations,
  analysis,
  charts,
  count,
  data,
  deletedNewsIds,
  editNews,
  export: exportReducer,
  filter,
  filterTimeline,
  groups,
  groupSortOrder,
  paging,
  selectedNewsIds,
  shoppingCart,
  shoppingNews,
  staticCharts,
  sortBy,
  sortDirection,
  total,
  ui,
  viewConfig
})
