import { connect } from 'react-redux'

import OrderForm from 'components/paid_features/order_form'

import {
  getConfigOrderRequestRunning,
  getWhitelabelTermsAndConditionsLink,
  getWhitelabelPrivacyLink
} from 'selectors'
import { orderPaidVersionStart } from 'actions/config'
import { clickTrace } from 'actions/app'

const mapStateToProps = state => ({
  ordering: getConfigOrderRequestRunning(state),
  termsAndConditionsLink: getWhitelabelTermsAndConditionsLink(state),
  privacyLink: getWhitelabelPrivacyLink(state)
})

export default connect(
  mapStateToProps,
  {
    onOrderClick: orderPaidVersionStart,
    onTraceClick: clickTrace
  }
)(OrderForm)
