import { connect } from 'react-redux'

import DateFilterButton from 'components/themax/date_filter_button'

import { getThemaxSelectedDate, getThemaxTabView } from 'selectors'

const mapStateToProps = state => ({
  selectedDate: getThemaxSelectedDate(state),
  view: getThemaxTabView(state)
})

export default connect(mapStateToProps, {})(DateFilterButton)
