import { connect } from 'react-redux'

import ContactEditDialog from 'components/contact_management/contact_edit_dialog'

import {
  getCmContactEditDialogOpen,
  getCmRequestRunning,
  getCmFormData,
  getCmTags
} from 'selectors/contact_management'

import {
  closeContactEditDialog as onClose,
  saveContactsStart as onSave,
  changeContactFormData as onChange,
  setUploadedPhoto as onUpload,
  changeTagStart as onTagChange,
  changeContactMediaTopics as onTopicChange,
  changeContactLanguages as onLanguageChange,
  changeContactMediaTypes as onMediaTypeChange,
  openContactDeleteDialog as onDelete
} from 'actions/contact_management'

import {
  getAllStaticCountries,
  getAllStaticMediaTopics,
  getAllStaticMediaTypes,
  getAllStaticLanguages,
  getStaticStates,
  getCmContactEditDialogMode
} from 'selectors'

const mapStateToProps = state => ({
  data: getCmFormData(state),
  open: getCmContactEditDialogOpen(state),
  isEditMode: getCmContactEditDialogMode(state),
  loading: getCmRequestRunning(state),
  countries: getAllStaticCountries(state),
  states: getStaticStates(state),
  tags: getCmTags(state),
  topics: getAllStaticMediaTopics(state),
  mediaTypes: getAllStaticMediaTypes(state),
  languages: getAllStaticLanguages(state)
})

export default connect(mapStateToProps, {
  onClose,
  onSave,
  onChange,
  onUpload,
  onTagChange,
  onTopicChange,
  onLanguageChange,
  onMediaTypeChange,
  onDelete
})(ContactEditDialog)
