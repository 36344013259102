import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_searches'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

export default handleActions({
  [Actions.fetchSavedSearchDispatchesStart]: () => initialState,
  [Actions.fetchSavedSearchDispatchesSuccess]: (state, { payload: dispatches }) => fromJS(dispatches),

  [AppActions.resetState]: () => initialState
}, initialState)
