/* eslint-disable */
import { LinkNode } from '@lexical/link'

export class ExtendedLinkNode extends LinkNode {
    static getType() {
        return 'extended-link'
    }

    static clone(node) {
        return new ExtendedLinkNode(node.__url, {}, node.__key)
    }

    exportDOM(editor) {
        const element = super.createDOM(editor._config, editor)

        // Remove additional class and dir attribute on exported html.
        element.removeAttribute('dir')
        element.removeAttribute('class')
        element.removeAttribute('target')
        element.removeAttribute('rel')

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        return LinkNode.importJSON(serializedNode)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'extended-link',
            version: 1
        }
    }
}