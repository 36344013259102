import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'
import * as CmActions from 'actions/contact_management'

export const initialState = fromJS({
  staticsRequestRunning: false,
  hasStatics: false,
  isSimpleSearch: true,
  searchRequestRunning: false,
  importRequestRunning: false,
  replaceRequestRunning: false,
  combinedSearchRequestRunning: false,
  journalistsSearchRequestRunning: false,
  outletsSearchRequestRunning: false,
  articlesSearchRequestRunning: false,
  isInitialSearch: true,
  resultsTab: 'journalists',
  duplicateContactsDialogOpen: false,
  distributionListCreateDialogOpen: false,
  drillDownEntity: null,
  drillDownTab: 'journalists',
  showDrillDownDialog: false,
  journalistsDrillDownRequestRunning: false,
  outletsDrillDownRequestRunning: false,
  articlesDrillDownRequestRunning: false,
  isNavigationLocked: false,
  isLimitedFunctionality: false,
  importWithDistributionLists: false
})

export default handleActions({
  [Actions.fetchStaticsStart]: state => state.set('staticsRequestRunning', true),
  [Actions.fetchStaticsSuccess]: state => state
    .set('staticsRequestRunning', false)
    .set('hasStatics', true),
  [Actions.fetchStaticsError]: state => state.set('staticsRequestRunning', false),
  [Actions.switchSearchType]: state => state.set('isSimpleSearch', !state.get('isSimpleSearch')),
  [combineActions(
    Actions.searchJournalistsStart,
    Actions.searchOutletsStart,
    Actions.searchArticlesStart
  )]: state => state.set('searchRequestRunning', true),
  [Actions.searchJournalistsStart]: state => state.set('journalistsSearchRequestRunning', true),
  [Actions.searchOutletsStart]: state => state.set('outletsSearchRequestRunning', true),
  [Actions.searchArticlesStart]: state => state.set('articlesSearchRequestRunning', true),
  [Actions.searchJournalistsSuccess]: state => state.set('isInitialSearch', false),
  [combineActions(
    Actions.searchJournalistsSuccess,
    Actions.searchJournalistsError
  )]: state => state
    .set('searchRequestRunning', false)
    .set('journalistsSearchRequestRunning', false),
  [combineActions(
    Actions.searchOutletsSuccess,
    Actions.searchOutletsError
  )]: state => state
    .set('searchRequestRunning', false)
    .set('outletsSearchRequestRunning', false),
  [combineActions(
    Actions.searchArticlesSuccess,
    Actions.searchArticlesError
  )]: state => state
    .set('searchRequestRunning', false)
    .set('articlesSearchRequestRunning', false),
  [Actions.combinedSearchStart]: state => state
    .set('combinedSearchRequestRunning', true)
    .set('journalistsSearchRequestRunning', true)
    .set('outletsSearchRequestRunning', true)
    .set('articlesSearchRequestRunning', true),
  [combineActions(
    Actions.combinedSearchSuccess,
    Actions.combinedSearchError
  )]: state => state.set('combinedSearchRequestRunning', false),
  [Actions.switchResultsTab]: (state, { payload }) => state.set('resultsTab', payload),
  [combineActions(
    Actions.importStart,
    Actions.addJournalistsToDistributionList
  )]: state => state.set('importRequestRunning', true),
  [combineActions(
    Actions.importSuccess,
    Actions.importError
  )]: state => state.set('importRequestRunning', false),
  [Actions.showDuplicateContactsDialog]: state => state.set('duplicateContactsDialogOpen', true),
  [combineActions(
    Actions.hideDuplicateContactsDialog,
    Actions.showDistributionListCreateDialog
  )]: state => state.set('duplicateContactsDialogOpen', false),
  [Actions.showDistributionListCreateDialog]: state => state.set('distributionListCreateDialogOpen', true),
  [Actions.hideDistributionListCreateDialog]: state => state.set('distributionListCreateDialogOpen', false),
  [Actions.replaceContactStart]: state => state.set('replaceRequestRunning', true),
  [combineActions(
    Actions.replaceContactSuccess,
    Actions.replaceContactError
  )]: state => state.set('replaceRequestRunning', false),
  [combineActions(
    CmActions.addContactsToDistributionListSuccess,
    CmActions.removeContactFromListSuccess
  )]: state => state.set('distributionListCreateDialogOpen', false).set('importRequestRunning', false),
  [combineActions(
    CmActions.addContactsToDistributionListError,
    CmActions.uploadContactsError,
    CmActions.removeContactFromListError
  )]: state => state.set('distributionListCreateDialogOpen', false).set('importRequestRunning', false),
  [Actions.openDrillDownDialog]: state => state.set('showDrillDownDialog', true),
  [Actions.openDrillDownDialog]: (state, { payload }) => {
    const { entity } = payload

    let tab

    if (entity === 'journalist') {
      tab = 'journalists'
    }

    if (entity === 'outlet') {
      tab = 'outlets'
    }

    return state.set('showDrillDownDialog', true).set('drillDownTab', tab)
  },
  [Actions.closeDrillDownDialog]: state => state.set('showDrillDownDialog', false).set('drillDownTab', 'journalists'),
  [Actions.setDrillDownEntity]: (state, { payload }) => state.set('drillDownEntity', payload),
  [Actions.switchDrillDownTab]: (state, { payload }) => state.set('drillDownTab', payload),
  [combineActions(
    Actions.drillDownRequestStart,
    Actions.drillDownHistorySearch
  )]: state => {
    let newState = state

    if (state.get('drillDownEntity') === 'journalist') {
      newState = newState
        .set('journalistsDrillDownRequestRunning', true)
        .set('articlesDrillDownRequestRunning', true)
    }

    if (state.get('drillDownEntity') === 'outlet') {
      newState = newState
        .set('outletsDrillDownRequestRunning', true)
        .set('journalistsDrillDownRequestRunning', true)
    }

    return newState
  },
  [Actions.searchDrillDownJournalistsStart]: state => state.set('journalistsDrillDownRequestRunning', true),
  [Actions.searchDrillDownOutletsStart]: state => state.set('outletsDrillDownRequestRunning', true),
  [Actions.searchDrillDownArticlesStart]: state => state.set('articlesDrillDownRequestRunning', true),
  [combineActions(
    Actions.searchDrillDownJournalistsSuccess,
    Actions.searchDrillDownJournalistsError
  )]: state => state.set('journalistsDrillDownRequestRunning', false),
  [combineActions(
    Actions.searchDrillDownOutletsSuccess,
    Actions.searchDrillDownOutletsError
  )]: state => state.set('outletsDrillDownRequestRunning', false),
  [combineActions(
    Actions.searchDrillDownArticlesSuccess,
    Actions.searchDrillDownArticlesError
  )]: state => state.set('articlesDrillDownRequestRunning', false),
  [Actions.addJournalistsToDistributionList]: state => state.set('importWithDistributionLists', true),
  [Actions.importStart]: state => state.set('importWithDistributionLists', false),
  [Actions.clearModuleData]: () => initialState.set('hasStatics', true),
  [Actions.lockNavigation]: state => state.set('isNavigationLocked', true),
  [Actions.setLimitedFunctionality]: state => state.set('isLimitedFunctionality', true),
  [AppActions.resetState]: () => initialState
}, initialState)
