import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'
import { Map, List } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import {
  Paper,
  Typography,
  Link,
  Badge,
  Avatar,
  CircularProgress,
  Tooltip
} from '@mui/material'

import {
  Place as PlaceIcon,
  Insights as InsightsIcon,
  Add as AddIcon,
  Check as CheckIcon,
  ReadMore as ReadMoreIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { RaisedPrimaryButton, IconButton, Button } from 'containers/themed'
import BlurredSocialHandles from 'components/anewstip/results/blurred_social_handles'

const useStyles = makeStyles()(theme => ({
  articleMainContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  articleInnerContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: '20px'
  },
  articlesInfoWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px'
  },
  articlesAddress: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  articlesActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  statsLabel: {
    background: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '0px 5px 0px 5px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  relatedArticleContainer: {
    display: 'flex',
    flexDirection: 'row',
    background: '#e0e0e04f',
    padding: '20px 30px 20px 30px',
    gap: '20px'
  },
  relatedArticleInfoContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  },
  relatedArticleImageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageLoaderWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  relatedArticleDateContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right'
  },
  articleImage: {
    display: 'flex',
    objectFit: 'cover'
  },
  imageFailedToLoad: {
    display: 'none'
  },
  twitter: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  articleNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  drillDownButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  avatarLoaderWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  breakPoint: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  influenceScoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }
}))

const Article = ({
  article,
  importResult,
  requestRunning,
  onAddClick,
  hideButton,
  drillDown,
  isDistListsLocked,
  openDrillDownDialog,
  setDrillDownEntity,
  onDrillDownClick,
  onAddToDistributionListClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [imageHidden, setImageHidden] = useState(true)
  const [imageLoaded, setImageLoaded] = useState(false)

  const avatar = article.get('avatar')
  const [avatarLoaded, setAvatarLoaded] = useState(avatar === '')

  const id = article.get('id')
  const name = article.get('name')
  const title = article.get('title')
  const city = article.get('city')
  const state = article.get('state')
  const country = article.get('country')
  const twitter = article.getIn(['twitter', 'screenName'])
  const email = article.get('email')
  const topics = article.get('topic')
  const influenceScore = article.get('influenceScore')
  const relatedArticles = article.get('relatedArticles')
  const relatedArticle = article.get('article')
  const contactType = article.get('contactType')
  const languages = article.get('lang')

  const isImported = (importResult || List()).map(el => el.getIn(['contactImported', 'externalId'])).includes(article.get('id'))

  let currentArticle

  if (relatedArticles && relatedArticles.size > 0) {
    currentArticle = relatedArticles.first()
  } else if (relatedArticle && relatedArticles.size > 0) {
    currentArticle = relatedArticle
  }

  const handleImageError = _event => {
    setImageLoaded(true)
  }

  const handleImageLoad = _event => {
    setImageHidden(false)
    setImageLoaded(true)
  }

  const handleOnAvatarLoad = () => {
    setAvatarLoaded(true)
  }

  const handleOnAvatarError = () => {
    setAvatarLoaded(true)
  }

  const handleDrillDown = () => {
    setDrillDownEntity(contactType)

    if (contactType === 'journalist') {
      onDrillDownClick({ entity: contactType, topics, name })
    }

    if (contactType === 'outlet') {
      onDrillDownClick({ entity: contactType, topics, outletId: id })
    }

    openDrillDownDialog({ entity: contactType })
  }

  const stringAvatar = () => {
    const splitName = name.split(' ')

    return {
      children: `${splitName[0][0]}${splitName[1] !== undefined ? splitName[1][0] : ''}`
    }
  }

  const addToDistListButton = (
    <Button
      variant="contained"
      color="primary"
      disabled={isImported || isDistListsLocked}
      loading={requestRunning}
      onClick={() => onAddToDistributionListClick({
        id
      })}
    >
      {i18n.get('add_to_distribution_list')}
    </Button>
  )

  return (
    <Paper
      sx={{ padding: 2, width: '100%' }}
      elevation={2}
    >
      <div className={classes.articleMainContainer}>
        {!drillDown && (
          <>
            <div className={classes.articleInnerContainer}>
              <div className={classes.avatarContainer}>
                {!avatarLoaded
                  ? (
                    <div className={classes.avatarLoaderWrapper}>
                      <CircularProgress
                        size={25}
                        variant="indeterminate"
                      />
                    </div>
                  ) : null}
                <Badge
                  sx={{ display: avatarLoaded ? 'flex' : 'none' }}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={(
                    <Avatar
                      sx={{ width: 30, height: 30, fontSize: 15 }}
                      {...stringAvatar()}
                    />
                  )}
                >
                  <Avatar
                    sx={{ width: 100, height: 100 }}
                    alt={name}
                    src={avatar}
                    onLoad={handleOnAvatarLoad}
                    onError={handleOnAvatarError}
                  />
                </Badge>
              </div>
              <div className={classes.articlesInfoWrapper}>
                <div className={classes.articleNameWrapper}>
                  <Typography variant="h6">{name}</Typography>
                  {!drillDown && (
                    <Tooltip
                      placement="right-start"
                      arrow
                      title={i18n.get('related_information')}
                    >
                      <IconButton
                        size="small"
                        className={classes.drillDownButton}
                        onClick={handleDrillDown}
                      >
                        <ReadMoreIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <Typography>{title}</Typography>
                {contactType === 'journalist'
                  ? (
                    <BlurredSocialHandles />
                  ) : (
                    <div className={classes.twitter}>
                      asd
                      <Typography>{twitter ? `@${twitter}` : null}</Typography>
                      {twitter && email ? <Typography className={classes.breakPoint}> &#8226; </Typography> : null}
                      <Typography> {email || null} </Typography>
                    </div>
                  )}
                {city !== '' || state !== '' || country !== ''
                  ? (
                    <Typography
                      className={classes.articlesAddress}
                      mt={2}
                    >
                      <PlaceIcon fontSize="small" />
                      {city !== '' ? `${city}, ` : null}
                      {state !== '' ? `${state}, ` : null}
                      {country}
                    </Typography>
                  ) : null}
                <Typography mt={1}>
                  {topics.size > 0
                    ? `${topics.size > 1 ? i18n.get('topics')
                      : i18n.get('topic')}: ${topics.map(t => t).join(', ')}` : null}
                </Typography>
                <Typography>
                  {languages.size > 0
                    ? `${languages.size > 1 ? i18n.get('languages')
                      : i18n.get('language')}: ${languages.map(l => l).join(', ')}` : null}
                </Typography>
              </div>
              <div className={classes.articlesActions}>
                {!hideButton && (
                  <RaisedPrimaryButton
                    variant="contained"
                    color="primary"
                    disabled={isImported}
                    loading={requestRunning}
                    onClick={() => onAddClick(article.get('id'))}
                  >
                    {isImported ? <CheckIcon fontSize="small" /> : <AddIcon fontSize="small" />}
                    {isImported ? i18n.get('contact_added') : i18n.get('add_to_contacts')}
                  </RaisedPrimaryButton>
                )}
                {contactType === 'journalist' && (
                  isDistListsLocked ? (
                    <Tooltip
                      title={i18n.get('distribution_lists_limit_reached')}
                      placement="left-start"
                      arrow
                    >
                      <span>{addToDistListButton}</span>
                    </Tooltip>
                  ) : addToDistListButton
                )}
                <div className={classes.influenceScoreWrapper}>
                  <Typography className={classes.statsLabel}>
                    <InsightsIcon fontSize="medium" /> {i18n.get('influence_score').toUpperCase()} - {influenceScore || 0}
                  </Typography>
                  <Tooltip
                    title={i18n.get('anewstip_influence_score_info')}
                    placement="bottom-end"
                    arrow
                  >
                    <InfoIcon color="primary" />
                  </Tooltip>
                </div>
              </div>
            </div>
            <Typography fontSize="small">{i18n.get('related_article')}:</Typography>
          </>
        )}
        {currentArticle ? (
          <div className={classes.relatedArticleContainer}>
            <div className={classes.relatedArticleImageContainer}>
              {!imageLoaded
                ? (
                  <div className={classes.imageLoaderWrapper}>
                    <CircularProgress
                      size={30}
                      variant="indeterminate"
                    />
                  </div>
                ) : null}
              <img
                onLoad={handleImageLoad}
                onError={handleImageError}
                className={imageHidden ? classes.imageFailedToLoad : classes.articleImage}
                src={currentArticle.get('image')}
                alt=""
                height="100"
                width="100"
              />
            </div>
            <div className={classes.relatedArticleInfoContainer}>
              <Link
                target="_blank"
                rel="noopener"
                href={currentArticle.get('url')}
              ><b>{currentArticle.get('title')}</b>
              </Link>
              <Typography
                sx={{ width: '400px' }}
                noWrap
                fontSize="small"
              >{currentArticle.get('url')}
              </Typography>
              <Typography>{currentArticle.get('contentSnippet')}</Typography>
            </div>
            <div className={classes.relatedArticleDateContainer}>
              <Typography fontSize="small">{i18n.get('date')}: {moment(currentArticle.get('publishedAt')).format('L')}</Typography>
            </div>
          </div>
        ) : null}
      </div>
    </Paper>

  )
}

Article.defaultProps = {
  drillDown: false
}

Article.propTypes = {
  article: PropTypes.instanceOf(Map).isRequired,
  importResult: PropTypes.instanceOf(List),
  requestRunning: PropTypes.bool,
  hideButton: PropTypes.bool.isRequired,
  drillDown: PropTypes.bool,
  isDistListsLocked: PropTypes.bool.isRequired,

  onAddClick: PropTypes.func,
  openDrillDownDialog: PropTypes.func.isRequired,
  setDrillDownEntity: PropTypes.func.isRequired,
  onDrillDownClick: PropTypes.func.isRequired,
  onAddToDistributionListClick: PropTypes.func.isRequired
}

export default Article
