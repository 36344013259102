import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'

import { Box, CircularProgress } from '@mui/material'

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import { IconButton } from 'containers/themed'

import { Document, Page } from 'utils/pdf'

const useStyles = makeStyles()({
  pdfPaging: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    bottom: 'calc(100% - 50px)',
    left: '0',
    right: '5px',
    zIndex: '1',
    marginBottom: '12px'
  },
  pdfPagingButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  loader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
})

const PdfMedia = ({
  mediaFile,
  width,
  height
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const [totalPages, setTotalPages] = useState({})
  const [pageNumbers, setPageNumbers] = useState({})

  const documentId = mediaFile.get('id')

  const onDocumentLoadSuccess = (id, { numPages }) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [id]: 1
    }))
    setTotalPages(prevTotalPages => ({
      ...prevTotalPages,
      [id]: numPages
    }))
  }

  const changePage = (id, offset) => {
    setPageNumbers(prevPageNumbers => ({
      ...prevPageNumbers,
      [id]: prevPageNumbers[id] + offset
    }))
  }

  const previousPage = (id, e) => {
    e.preventDefault()
    e.stopPropagation()
    changePage(id, -1)
  }

  const nextPage = (id, e) => {
    e.preventDefault()
    e.stopPropagation()
    changePage(id, 1)
  }

  return (
    <Document
      file={mediaFile.get('url')}
      onLoadSuccess={props => onDocumentLoadSuccess(documentId, props)}
      loading={(
        <Box className={classes.loader}>
          <CircularProgress
            variant="indeterminate"
            size={30}
          />
        </Box>
      )}
    >
      <Page
        renderAnnotationLayer={false}
        renderTextLayer={false}
        pageNumber={pageNumbers[documentId] || 1}
        width={width - 5}
        height={height}
        loading={false}
      />
      <Box className={classes.pdfPaging}>
        {(totalPages[documentId] !== 1) && (
          <Box onClick={e => e.stopPropagation()}>
            <IconButton
              disabled={pageNumbers[documentId] <= 1}
              onClick={e => previousPage(documentId, e)}
              title={i18n.get('previous')}
              size="small"
              className={classes.pdfPagingButton}
              disableRipple
            >
              <ArrowCircleLeftIcon />
            </IconButton>
            <IconButton
              disabled={pageNumbers[documentId] >= totalPages[documentId]}
              onClick={e => nextPage(documentId, e)}
              size="small"
              className={classes.pdfPagingButton}
              disableRipple
            >
              <ArrowCircleRightIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Document>
  )
}

PdfMedia.propTypes = {
  mediaFile: PropTypes.instanceOf(Map).isRequired,
  width: PropTypes.number,
  height: PropTypes.number
}

export default PdfMedia
