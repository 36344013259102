import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_searches'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  expandedId: null,
  saving: false,
  deleting: false,
  creating: false
})

export default handleActions({
  [Actions.expandSavedSearchFeed]: (state, { payload }) => (
    state.update('expandedId', id => (id === payload ? null : payload))
  ),

  [Actions.saveSavedSearchFeedStart]: (state, { payload }) => state.set(payload ? 'saving' : 'creating', true),
  [Actions.saveSavedSearchFeedSuccess]: state => (
    state.merge({
      saving: false,
      creating: false
    })
  ),
  [Actions.saveSavedSearchFeedError]: state => (
    state.merge({
      saving: false,
      creating: false
    })
  ),

  [Actions.deleteSavedSearchFeedStart]: state => state.set('deleting', true),
  [Actions.deleteSavedSearchFeedSuccess]: state => state.set('deleting', false),
  [Actions.deleteSavedSearchFeedError]: state => state.set('deleting', false),

  [AppActions.resetState]: () => initialState
}, initialState)
