import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import {
  CircularProgress
} from '@mui/material'

import AppBar from 'containers/anewstip/AppBar'
import Results from 'containers/anewstip/Results'
import Search from 'containers/anewstip/search/Search'
import DuplicateContactsDialog from 'containers/anewstip/DuplicateContactsDialog'
import CenterMessage from 'containers/CenterMessage'
import DistributionListCreateDialog from 'containers/anewstip/DistributionListCreateDialog'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '1em',
    height: '93vh',
    display: 'flex',
    gap: '10px',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: '91vh'
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto'
    }
  },
  circularProgress: {
    width: '100vw',
    height: '92vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
  },
  facetsLoading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '20px'
  },
  centerMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh'
  },
  content: {
    maxWidth: 800
  }
}))

const Anewstip = ({
  apiKey,
  hasStatics,
  staticsRequestRunning,
  isInitialSearch,
  fetchStatics,
  onUnmount
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  useEffect(() => {
    if (!hasStatics) {
      fetchStatics()
    }

    return () => {
      onUnmount()
    }
  }, [])

  const renderMain = () => {
    if (!apiKey) {
      return (
        <div className={classes.centerMessageContainer}>
          <CenterMessage
            contentClass={classes.content}
            message={i18n.get('api_key_not_set_up_message')}
          />
        </div>
      )
    }

    return (
      <div className={classes.container}>
        {isInitialSearch ? <Search /> : (
          <>
            <div>
              <Search />
            </div>
            <div style={{ flex: 1 }}>
              <Results />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <AppBar />
      {(staticsRequestRunning || !hasStatics) ? (
        <div className={classes.circularProgress}>
          <CircularProgress
            size={60}
          />
        </div>
      ) : renderMain()}
      <DuplicateContactsDialog />
      <DistributionListCreateDialog />
    </>
  )
}

Anewstip.propTypes = {
  apiKey: PropTypes.any.isRequired,
  hasStatics: PropTypes.bool.isRequired,
  staticsRequestRunning: PropTypes.bool.isRequired,
  isInitialSearch: PropTypes.bool.isRequired,

  fetchStatics: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired
}

export default Anewstip
