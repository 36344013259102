import { handleActions } from 'redux-actions'
import { fromJS, List } from 'immutable'

import { listMove } from 'utils/immutable'
import { multiDragAwareReorder } from 'utils/multidrag'

import * as ShoppingCartActions from 'actions/shopping_cart'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  news: [],
  codes: []
})

const getCodes = state => state.get('codes')
const findCodeIndexById = (state, id) => getCodes(state).findIndex(c => c.get('id') === id)

const addNewsToNewsState = (state, news) => {
  let newNewsState = state.get('news')
  let newElements = fromJS([])
  news.forEach(n => {
    const clusteredNewsIds = n.get('clusteredNews').map(cn => cn.get('id'))
    let alreadyInState = false
    newNewsState = newNewsState.map(nns => {
      if (nns.first() === n.get('id')) {
        alreadyInState = true

        return nns.concat(clusteredNewsIds).toOrderedSet().toList()
      }

      return nns
    })

    if (!alreadyInState) {
      newElements = newElements.push(clusteredNewsIds.unshift(n.get('id')))
    }
  })

  return newNewsState.concat(newElements)
}
export default handleActions({
  [ShoppingCartActions.fetchNewsSuccess]: (state, { payload: news }) => {
    const newNews = fromJS(news).map(n => n.get('clusteredNews').map(c => c.get('id')).unshift(n.get('id')))

    return state.set('news', newNews)
  },
  [ShoppingCartActions.fetchClusterForShoppingCartSuccess]: (state, { payload: news }) => (
    state.set('news', addNewsToNewsState(state, news))
  ),
  [ShoppingCartActions.addAllNewsToShoppingCartSuccess]: (state, { payload: news }) => state.set('news', addNewsToNewsState(state, news)),
  [ShoppingCartActions.removeNewsFromShoppingCart]: (state, { payload }) => {
    const newPayload = fromJS(payload)

    const newsIds = List.isList(newPayload) ? newPayload : newPayload.get('newsIds')
    const codeIds = newPayload.get('codeIds') || state.get('codes').map(c => c.get('id'))

    const codes = state.get('codes')
      .map(code => code.update('news', news => (codeIds.includes(code.get('id')) ? news.filter(n => !newsIds.includes(n)) : news)))
      .filter(code => !code.get('news').isEmpty())

    const news = state.get('news').filter(n => codes.find(code => code.get('news').includes(n.first())))

    return state.merge({
      codes,
      news
    })
  },
  [ShoppingCartActions.moveNewsInShoppingCart]: (state, { payload: { codeId, oldIndex, newIndex, selectedNews } }) => {
    const selectedDraggableIds = selectedNews.filter(sn => sn.get('codeId') === codeId).map(sn => sn.get('newsId'))

    const newNews = multiDragAwareReorder({
      draggableIds: state.get('news').map(n => n.first()),
      selectedDraggableIds,
      sourceIndex: oldIndex,
      destinationIndex: newIndex
    })

    return state.update('news', news => news.sortBy(n => newNews.indexOf(n.first())))
  },
  [ShoppingCartActions.moveNewsInShoppingCartSublist]: (state, { payload: { codeId, oldIndex, newIndex, selectedNews } }) => {
    const codeIndex = findCodeIndexById(state, codeId)

    const selectedDraggableIds = selectedNews.filter(sn => sn.get('codeId') === codeId).map(sn => sn.get('newsId'))
    const newNews = multiDragAwareReorder({
      draggableIds: state.getIn(['codes', codeIndex, 'news']),
      selectedDraggableIds,
      sourceIndex: oldIndex,
      destinationIndex: newIndex
    })

    return state.setIn(['codes', codeIndex, 'news'], newNews)
  },
  [ShoppingCartActions.moveCodeInShoppingCart]: (state, { payload: { oldIndex, newIndex } }) => {
    const nextCodes = listMove(getCodes(state), oldIndex, newIndex)

    return state.set('codes', nextCodes)
  },
  [ShoppingCartActions.toggleNewsList]: (state, { payload: codeId }) => {
    const codeIndex = findCodeIndexById(state, codeId)
    const nextCodes = getCodes(state).update(codeIndex, code => code.set('showNews', !code.get('showNews')))

    return state.set('codes', nextCodes)
  },
  [ShoppingCartActions.expandAllNewsLists]: state => state.set('codes', getCodes(state).map(c => c.set('showNews', true))),
  [ShoppingCartActions.collapseAllNewsLists]: state => state.set('codes', getCodes(state).map(c => c.set('showNews', false))),
  [ShoppingCartActions.setCodes]: (state, { payload: codes }) => state.set('codes', codes),
  [ShoppingCartActions.setNews]: (state, { payload: news }) => state.set('news', news),

  [ShoppingCartActions.replaceCluster]: (state, { payload: { oldIds, news } }) => {
    const newIds = news.map(n => n.get('id'))
    const oldMainNewsId = oldIds.first()
    const newMainNewsId = newIds.first()

    const newNews = state.get('news').map(ids => (ids.equals(oldIds) ? newIds : ids))
    const codes = state.get('codes').map(code => code.update('news', ids => ids.map(id => (id === oldMainNewsId ? newMainNewsId : id))))

    return state.merge({ news: newNews, codes })
  },

  [AppActions.resetState]: () => initialState
}, initialState)
