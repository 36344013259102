import { connect } from 'react-redux'

import ImagePreviewDialog from 'components/content_desk_new/contents/content_edit_dialog/attachments/image_preview_dialog'

import {
  closeAttachmentsImagePreviewDialog as closeImagePreviewDialog
} from 'actions/content_desk'

import {
  getCDeskAttachmentsImagePreviewOpen,
  getCDeskAttachmentsImagePreviewSrc
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskAttachmentsImagePreviewOpen(state),
  imageSrc: getCDeskAttachmentsImagePreviewSrc(state)
})

export default connect(
  mapStateToProps,
  {
    closeImagePreviewDialog
  }
)(ImagePreviewDialog)
