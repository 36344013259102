import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/contact_management'
import * as AppActions from 'actions/app'

const queryTypeLabelMapping = {
  contact_name: 'search_for_contact',
  contact_tag: 'search_for_contact',
  distribution_list_name: 'search_for_distribution_list'
}

export const initialState = fromJS({
  sortBy: '',
  sortOrder: '',
  listSortBy: '',
  listSortOrder: '',
  tagsSortBy: '',
  tagsSortOrder: '',
  contactsPage: 1,
  distributionListsPage: 1,
  contactEditDialogOpen: false,
  contactDialogOpen: false,
  contactDeleteDialogOpen: false,
  tagsDialogOpen: false,
  tagRenameDialogOpen: false,
  tagDeleteDialogOpen: false,
  distributionListCreateDialogOpen: false,
  distributionListEditDialogOpen: false,
  distributionListViewDialogOpen: false,
  distributionListMergeDialogOpen: false,
  importHelpDialogOpen: false,
  uploadResultDialogOpen: false,
  emailExistingDialogOpen: false,
  contactsUploadDialogOpen: false,
  contactEditDialogMode: false,
  contactDialogView: 'overview',
  requestRunning: false,
  uploadedPhoto: null,
  selectedQueryType: 'contact_name',
  searchBarLabel: 'search_for_contact',
  searchRunning: false,
  searchContactsRunning: false,
  searchDistributionListsRunning: false,
  searchDistributionListRunning: false,
  activeFiltersCollapsed: true,
  autoCreateDistributionList: false,
  autoAddToExistingList: false
})

export default handleActions(
  {
    [Actions.sortBy]: (state, { payload: { sortBy, sortOrder } }) => state.merge({ sortBy, sortOrder }),
    [Actions.listSortBy]: (state, { payload: { sortBy, sortOrder } }) => state.merge({ listSortBy: sortBy, listSortOrder: sortOrder }),
    [Actions.tagsSortBy]: (state, { payload: { sortBy, sortOrder } }) => state.merge({ tagsSortBy: sortBy, tagsSortOrder: sortOrder }),
    [Actions.setContactsPage]: (state, { payload: page }) => state.set('contactsPage', page),
    [Actions.setDistributionListsPage]: (state, { payload: page }) => state.set('distributionListsPage', page),

    [Actions.openContactEditDialog]: (state, { payload: { isEditMode } }) => state
      .set('contactEditDialogOpen', true)
      .set('contactEditDialogMode', isEditMode),
    [Actions.closeContactEditDialog]: state => state.set('contactEditDialogOpen', false),

    [Actions.openContactViewDialog]: state => state.set('contactDialogOpen', true),
    [Actions.closeContactViewDialog]: state => state.set('contactDialogOpen', false),

    [Actions.openContactDeleteDialog]: state => state.set('contactDeleteDialogOpen', true),
    [Actions.closeContactDeleteDialog]: state => state.set('contactDeleteDialogOpen', false),

    [Actions.openTagDeleteDialog]: state => state.set('tagDeleteDialogOpen', true),
    [Actions.closeTagDeleteDialog]: state => state.set('tagDeleteDialogOpen', false),

    [Actions.openTagRenameDialog]: state => state.set('tagRenameDialogOpen', true),
    [Actions.closeTagRenameDialog]: state => state.set('tagRenameDialogOpen', false),

    [Actions.openTagsDialog]: state => state.set('tagsDialogOpen', true),
    [Actions.closeTagsDialog]: state => state.set('tagsDialogOpen', false),

    [combineActions(
      Actions.renameTagStart,
      Actions.deleteTagStart,
      Actions.createDistributionListStart,
      Actions.updateDistributionListStart,
      Actions.uploadContactsStart,
      Actions.deleteContactsStart,
      Actions.downloadContactsExcelStart,
      Actions.downloadDistributionListStart
    )]: state => state.set('requestRunning', true),

    [combineActions(
      Actions.createDistributionListSuccess,
      Actions.createDistributionListError,
      Actions.renameTagSuccess,
      Actions.renameTagError,
      Actions.deleteTagSuccess,
      Actions.deleteTagError,
      Actions.deleteContactsSuccess,
      Actions.deleteContactsError,
      Actions.downloadContactsExcelSuccess,
      Actions.downloadContactsExcelError,
      Actions.downloadDistributionListSuccess,
      Actions.downloadDistributionListError
    )]: state => state.set('requestRunning', false),

    [combineActions(
      Actions.deleteContactsSuccess,
      Actions.deleteContactsError
    )]: state => state.set('contactDeleteDialogOpen', false).set('contactEditDialogOpen', false),

    [Actions.openContactsUploadDialog]: state => state.set('contactsUploadDialogOpen', true),
    [Actions.closeContactsUploadDialog]: state => state.set('contactsUploadDialogOpen', false)
      .set('autoCreateDistributionList', false)
      .set('autoAddToExistingList', false),
    [Actions.uploadContactsSuccess]: state => state.set('contactsUploadDialogOpen', false).set('requestRunning', false),

    [Actions.saveContactsStart]: state => state.set('requestRunning', true),
    [Actions.saveContactsSuccess]: state => state.set('contactEditDialogOpen', false).set('requestRunning', false),
    [Actions.saveContactsError]: state => state.set('requestRunning', false),

    [Actions.openDistributionListCreateDialog]: state => state.set('distributionListCreateDialogOpen', true),
    [Actions.closeDistributionListCreateDialog]: state => state.set('distributionListCreateDialogOpen', false),

    [Actions.openImportHelpDialog]: state => state.set('importHelpDialogOpen', true),
    [Actions.closeImportHelpDialog]: state => state.set('importHelpDialogOpen', false),

    [Actions.openDistributionListEditDialog]: state => state.set('distributionListEditDialogOpen', true),
    [Actions.closeDistributionListEditDialog]: state => state.set('distributionListEditDialogOpen', false),
    [Actions.updateDistributionListSuccess]: state => state.set('distributionListEditDialogOpen', false),

    [Actions.openDistributionListViewDialog]: state => state.set('distributionListViewDialogOpen', true),
    [Actions.closeDistributionListViewDialog]: state => state.set('distributionListViewDialogOpen', false),

    [combineActions(
      Actions.addContactsToDistributionListStart,
      Actions.removeContactFromListStart
    )]: state => state.set('requestRunning', true),

    [Actions.toggleActiveFilters]: state => state.update('activeFiltersCollapsed', v => !v),

    [Actions.setSelectedQueryType]: (state, { payload }) => {
      const newState = state.set('selectedQueryType', payload)

      if (queryTypeLabelMapping[payload]) {
        return newState.set('searchBarLabel', queryTypeLabelMapping[payload])
      }

      return state
    },

    [combineActions(
      Actions.addContactsToDistributionListSuccess,
      Actions.uploadContactsError,
      Actions.removeContactFromListSuccess,
      Actions.removeContactFromListError
    )]: state => state.set('distributionListCreateDialogOpen', false).set('requestRunning', false),
    [(Actions.addContactsToDistributionListError)]: state => state.set('requestRunning', false),

    [combineActions(
      Actions.updateDistributionListSuccess,
      Actions.updateDistributionListError
    )]: state => state.set('distributionListCreateDialogOpen', false).set('requestRunning', false),

    [Actions.setUploadedPhoto]: (state, { payload: data }) => state.set('uploadedPhoto', data),

    [Actions.fetchContactsStart]: state => state.set('searchContactsRunning', true),
    [Actions.fetchDistributionListsStart]: state => state.set('searchDistributionListsRunning', true),
    [Actions.searchDistributionListStart]: state => state.set('searchDistributionListRunning', true),

    [combineActions(
      Actions.fetchContactsSuccess,
      Actions.fetchContactsError
    )]: state => state.set('searchContactsRunning', false),

    [combineActions(
      Actions.fetchDistributionListsSuccess,
      Actions.fetchDistributionListsError
    )]: state => state.set('searchDistributionListsRunning', false),

    [combineActions(
      Actions.searchDistributionListSuccess,
      Actions.searchDistributionListError
    )]: state => state.set('searchDistributionListRunning', false),

    [combineActions(
      Actions.fetchContactsStart,
      Actions.fetchDistributionListsStart,
      Actions.fetchTagsStart
    )]: state => state.set('searchRunning', true),
    [combineActions(
      Actions.fetchDistributionListsSuccess,
      Actions.fetchDistributionListsError,
      Actions.fetchContactsSuccess,
      Actions.fetchContactsError,
      Actions.fetchTagsSuccess,
      Actions.fetchTagsError
    )]: state => state.set('searchRunning', false),

    [Actions.showEmailExistingDialog]: state => state.set('emailExistingDialogOpen', true),
    [Actions.closeEmailExistingDialog]: state => state.set('emailExistingDialogOpen', false),

    [Actions.showUploadResultDialog]: state => state.set('uploadResultDialogOpen', true),
    [Actions.closeUploadResultDialog]: state => state.set('uploadResultDialogOpen', false),

    [Actions.openDistributionListMergeDialog]: state => state.set('distributionListMergeDialogOpen', true),
    [Actions.closeDistributionListMergeDialog]: state => state.set('distributionListMergeDialogOpen', false),

    [Actions.mergeDistributionListsStart]: state => state.set('requestRunning', true),
    [Actions.mergeDistributionListsSuccess]: state => state
      .set('requestRunning', false)
      .set('distributionListMergeDialogOpen', false),
    [Actions.mergeDistributionListsError]: state => state.set('requestRunning', false),

    [Actions.setAutoCreateDistributionList]: (state, { payload }) => state.set('autoCreateDistributionList', payload),
    [Actions.setAutoAddToExistingList]: (state, { payload }) => state.set('autoAddToExistingList', payload),
    [AppActions.resetState]: () => initialState
  },
  initialState
)
