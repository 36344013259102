import React from 'react'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Card,
  CardContent,
  Chip,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  Grid,
  CircularProgress,
  Typography
} from '@mui/material'
import { grey } from '@mui/material/colors'

import { Popover } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  headlineDivider: {
    margin: '1em 0em 1em 0em'
  },
  card: {
    borderRadius: 20,
    minHeight: 147,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: grey[200]
  },
  cardContent: {
    marginTop: 18,
    minHeight: 150,
    display: 'flex',
    alignContent: 'center'
  },
  kpiWrapper: {
    height: '100%'
  },
  value: {
    fontSize: '30pt',
    fontWeight: '700',
    textAlign: 'center',
    color: !theme.isDark && '#404040'
  },
  label: {
    fontSize: '12pt',
    textAlign: 'center',
    color: !theme.isDark && '#757575'
  },
  divider: {
    marginTop: '1em'
  },
  headline: {
    color: !theme.isDark && '#757575',
    marginBottom: '0.5em'
  },
  popover: {
    maxHeight: 500
  }
}))

const OtsPressRelease = ({ data, requestRunning }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const loadOrShowTotalViews = () => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return data.getIn(['owned', 'totalViews'])
  }

  const loadOrShowOnlineHits = () => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return data.getIn(['earned', 'onlineHits']).size
  }

  const renderOnlineHits = () => {
    if (requestRunning) {
      return <CircularProgress />
    }

    return (
      <List>
        {data.getIn(['earned', 'onlineHits']).map((hit, index) => (
          <ListItem
            key={index}
          >
            <ListItemText
              primary={hit.get('siteName')}
              secondary={(
                <Link
                  href={hit.get('url')}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                  title={hit.get('siteName')}
                >
                  {hit.get('url')}
                </Link>
              )}
            />
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <>
      <Divider className={classes.headlineDivider} />
      <Grid
        container
        spacing={2}
        alignItems="center"
        columns={13}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Typography
            className={classes.headline}
            variant="h6"
          >
            {i18n.get('owned')}
          </Typography>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('views')}</div>
                <div className={classes.value}>{loadOrShowTotalViews()}</div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          lg={3}
        >
          <Typography
            className={classes.headline}
            variant="h6"
          >
            {i18n.get('earned')}
          </Typography>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.kpiWrapper}>
                <div className={classes.label}>{i18n.get('online_hits')}</div>
                <div className={classes.value}>{loadOrShowOnlineHits()}</div>
                <Popover
                  classes={{
                    paper: classes.popover
                  }}
                  handle={(
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={i18n.get('view_details')}
                    />
                )}
                >
                  {renderOnlineHits()}
                </Popover>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

OtsPressRelease.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  requestRunning: PropTypes.bool.isRequired
}

export default OtsPressRelease
