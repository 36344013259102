import moment from 'moment-timezone'
// import { List } from 'immutable'
import { rgbaColorFromHex } from 'utils/color'
import { blue, blueGrey } from '@mui/material/colors'
import camelcase from 'camelcase'

// const enumerateDaysBetweenDates = (startDate, endDate) => {
//   const now = moment(startDate).startOf('day')
//   const endD = moment(endDate).endOf('day')

//   const dates = []

//   while (now.isBefore(endD) || now.isSame(endD)) {
//     dates.push(now.startOf('day').toDate())
//     now.add(1, 'days')
//   }

//   return List(dates)
// }

export const calendarEvents = events => events
  .filter(c => (c.get('scheduledFor') || c.get('plannedFor')) || (c.get('startDate') && c.get('endDate')))
  .map(event => {
    const title = event.get('name')
    const campaignName = event.getIn(['ccdCampaign', 'name']) || event.get('name')

    const eventType = event.get('eventType')

    if (eventType === 'content') {
      const type = event.get('type')
      const scheduledForDate = event.get('scheduledFor')
      const plannedForDate = event.get('plannedFor')
      const approvalStatus = camelcase(event.get('approvalStatus'))

      let time
      let status
      let date

      if (!scheduledForDate && plannedForDate) {
        status = 'planned'
        date = moment(plannedForDate).toDate()
        time = moment(plannedForDate).format('LT')
      } else {
        status = event.get('status')
        date = moment(scheduledForDate).toDate()
        time = moment(scheduledForDate).format('LT')
      }

      return {
        title,
        display: 'list-item',
        extendedProps: {
          type,
          eventType,
          campaignName,
          time,
          status,
          approvalStatus,
          content: event
        },
        date,
        start: date
      }
    }

    const start = moment(event.get('startDate')).toDate()
    const end = moment(event.get('endDate')).toDate()

    const active = event.get('active')
    const color = active ? blue[500] : blueGrey[500]

    return {
      title,
      display: 'block',
      extendedProps: {
        eventType,
        campaignName,
        campaign: event,
        color: rgbaColorFromHex(color, 1)
      },
      date: start,
      start,
      end
    }
  })

export const calendarWidgetEvents = (contents, selectedCampaignId, theme) => contents
  .filter(c => (c.get('scheduledFor') || c.get('plannedFor')) || (c.get('startDate') && c.get('endDate')))
  .filter(event => !selectedCampaignId || (
    event.getIn(['ccdCampaign', 'id']) === selectedCampaignId
  ))
  .map(event => {
    // if (event.get('eventType') === 'content') {
    const scheduledForDate = event.get('scheduledFor')
    const plannedForDate = event.get('plannedFor')

    let date

    if (!scheduledForDate && plannedForDate) {
      date = moment(plannedForDate).startOf('day').format('YYYY-MM-DD')
    } else {
      date = moment(scheduledForDate).format('YYYY-MM-DD')
    }

    return {
      start: date,
      end: moment(date).add(1, 'day').format('YYYY-MM-DD'),
      display: 'background',
      overlap: true,
      color: theme.palette.primary.main
    }
  }).filter(e => e)
