import { connect } from 'react-redux'

import {
  setSortBy,
  setPage
} from 'actions/content_desk'
import {
  getCDeskContentSortBy,
  getCDeskContentSortOrder,
  getCDeskContents,
  getCDeskSearchContentsRunning
} from 'selectors'

import Contents from 'components/content_desk_new/contents'

const mapStateToProps = (state, ownProps) => ({
  contents: ownProps.contents || getCDeskContents(state),
  loading: getCDeskSearchContentsRunning(state),
  showCampaigns: ownProps.showCampaigns,
  sortBy: getCDeskContentSortBy(state),
  sortOrder: getCDeskContentSortOrder(state)
})

export default connect(
  mapStateToProps,
  {
    onSortBy: setSortBy,
    setPage
  }
)(Contents)
