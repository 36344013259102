import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import { validDomain, validEmail } from 'utils/darknet'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  Chip,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  TextField,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  InputLabel
} from '@mui/material'
import { grey } from '@mui/material/colors'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'

import HackishnessSlider from 'components/darknet/search_form/hackishness_slider'

import { Networks } from 'static/constants'

import { Button, RaisedSecondaryButton, RaisedPrimaryButton } from 'containers/themed'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  margin: {
    marginBottom: theme.spacing(2)
  },
  fieldsetLabel: {
    fontSize: 'unset'
  },
  chips: {
    marginTop: theme.spacing(1),
    '& > div': {
      marginRight: theme.spacing(1)
    }
  },
  chip: {
    backgroundColor: theme.palette.background.default
  },
  networks: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 25,
    marginRight: 15
  },
  hackishness: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 25
  },
  network: {
    width: 150
  },
  dialogTitle: {
    borderBottomStyle: 'solid',
    borderBottomColor: grey[400],
    borderBottomWidth: '1px',
    marginBottom: '1em'
  }
}))

export default function SearchQueryDialog({
  onClose,
  onSave,
  onUpdate,
  onTest,
  data,
  testRunning,
  testDisabled,
  testResult,
  open,
  editing
}) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const [emailData, setEmailData] = useState('')
  const [emailDomainData, setEmailDomainData] = useState('')
  const [error, setError] = useState({ email: false, emailDomain: false })

  const isValidEmail = (value, context) => value !== '' && (!validEmail(value) || data.get(context).includes(value))
  const isValidDomain = (value, context) => value !== '' && (!validDomain(value) || data.get(context).includes(value))

  const networks = Networks.filter(n => n.forDarkowlSearchQuery)

  const handleSubmit = () => {
    onSave()
    onClose()
  }

  const handleNetworkChange = e => {
    const { name, checked } = e.target
    const newNetworks = { ...data.get('networks'), [name]: checked }
    onUpdate({ networks: newNetworks })
  }

  const handleChange = e => {
    const { name, value } = e.target
    onUpdate({ [name]: value })
  }

  const handleEmailDelete = chipToDelete => () => {
    onUpdate({ emails: data.get('emails').filter(domain => domain !== chipToDelete.label) })
  }

  const handleEmailDomainDelete = chipToDelete => () => {
    onUpdate({ emailDomains: data.get('emailDomains').filter(domain => domain !== chipToDelete.label) })
  }

  const handleEmailClick = () => {
    if (isValidEmail(emailData, 'emails')) {
      setError({ ...error, email: true })

      return
    }

    setError({ ...error, email: false })
    onUpdate({ emails: [...data.get('emails'), emailData] })
    setEmailData('')
  }

  const handleDomainClick = () => {
    if (isValidDomain(emailDomainData, 'emailDomains')) {
      setError({ ...error, emailDomain: true })

      return
    }

    setError({ ...error, emailDomain: false })
    onUpdate({ emailDomains: [...data.get('emailDomains'), emailDomainData] })
    setEmailDomainData('')
  }

  const handleMailKeyDown = ({ key }) => (key === 'Enter' && handleEmailClick())

  const handleDomainKeyDown = ({ key }) => (key === 'Enter' && handleDomainClick())

  const renderChips = (chipsData, deleteFun) => {
    const formattedChips = chipsData.map((chip, index) => ({
      label: chip,
      key: index
    }))

    return (
      <div className={classes.chips}>
        {formattedChips.map(d => (
          <Chip
            key={d.key}
            label={d.label}
            onDelete={deleteFun(d)}
            className={classes.chip}
          />
        ))}
      </div>
    )
  }

  const renderNetworks = () => {
    const renderedNetworks = networks.map((n, index) => (
      <FormControlLabel
        key={`${index}-${n.id}`}
        name={n.id}
        checked={Boolean(data.getIn(['networks', n.id]))}
        control={<Checkbox color="primary" />}
        label={n.name}
        labelPlacement="end"
        onChange={handleNetworkChange}
        className={classes.network}
      />
    ))

    return (<>{renderedNetworks}</>)
  }

  const renderForm = () => (
    <>
      <FormControl
        fullWidth
        className={classes.margin}
      >
        <TextField
          required
          label={i18n.get('name')}
          name="name"
          value={data.get('name')}
          onChange={handleChange}
        />
      </FormControl>

      <FormControl
        fullWidth
        className={classes.margin}
      >
        <TextField
          label={i18n.get('search_query')}
          required
          variant="outlined"
          id="outlined-search-query"
          name="searchQuery"
          value={data.get('searchQuery')}
          multiline
          rows={4}
          placeholder="example1 AND example2 OR example3"
          onChange={handleChange}
        />
      </FormControl>

      <Typography
        variant="h6"
        gutterBottom
        style={{ fontWeight: 400 }}
      >
        {i18n.get('filters')}
      </Typography>

      <FormControl
        fullWidth
        variant="outlined"
        className={classes.margin}
      >
        <InputLabel htmlFor="outlined-adornment-emails">{i18n.get('emails')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-emails"
          name="emails"
          value={emailData}
          rows={3}
          placeholder="support@example.com, no-reply@example.com"
          onKeyDown={handleMailKeyDown}
          error={error.email}
          onChange={e => setEmailData(e.target.value)}
          endAdornment={(
            <InputAdornment
              position="end"
              onClick={handleEmailClick}
              style={{ cursor: 'pointer' }}
            >Add
            </InputAdornment>
          )}
        />
        {error.email && (
          <FormHelperText error>
            {i18n.get('invalid_mail_address')}
          </FormHelperText>
        )}
        {renderChips(data.get('emails'), handleEmailDelete)}
      </FormControl>

      <FormControl
        fullWidth
        variant="outlined"
        className={classes.margin}
      >
        <InputLabel htmlFor="outlined-adornment-email-domains">{i18n.get('email_domains')}</InputLabel>
        <OutlinedInput
          id="outlined-adornment-email-domains"
          name="emailDomains"
          value={emailDomainData}
          rows={3}
          placeholder="example.com, second-example.de"
          onKeyDown={handleDomainKeyDown}
          error={error.emailDomain}
          onChange={e => setEmailDomainData(e.target.value)}
          endAdornment={(
            <InputAdornment
              position="end"
              onClick={handleDomainClick}
              style={{ cursor: 'pointer' }}
            >Add
            </InputAdornment>
          )}
        />
        {error.emailDomain && (
          <FormHelperText error>
            {i18n.get('invalid_domain')}
          </FormHelperText>
        )}
        {renderChips(data.get('emailDomains'), handleEmailDomainDelete)}
      </FormControl>

      <Grid
        container
        style={{ marginTop: '1rem' }}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <FormControl
            component="fieldset"
            variant="outlined"
            className={classes.networks}
          >
            <FormLabel
              component="legend"
              className={classes.fieldsetLabel}
            >{i18n.get('networks')}
            </FormLabel>

            <FormGroup row>
              {renderNetworks()}
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          md={6}
          xs={12}
        >
          <FormControl
            component="fieldset"
            fullWidth
            className={classes.hackishness}
          >
            <FormLabel
              component="legend"
              className={classes.fieldsetLabel}
            >{i18n.get('min_hackishness')}
            </FormLabel>
            <HackishnessSlider
              hackishness={data.get('minHackishness')}
              setHackishness={onUpdate}
            />
          </FormControl>
        </Grid>
      </Grid>

      {!testRunning && testResult && (
        <Typography
          variant="body1"
          gutterBottom
          style={{ fontWeight: 350 }}
        >
          {testResult.get('message')}
        </Typography>

      )}
    </>
  )

  const disabled = testDisabled || !data.get('searchQuery') || testRunning

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>
        {editing ? i18n.get('edit') : i18n.get('create_query')}
      </DialogTitle>

      <DialogContent>
        {renderForm()}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onClose()}
        >
          {i18n.get('close')}
        </Button>

        <RaisedSecondaryButton
          disabled={disabled}
          onClick={onTest}
          endIcon={<PlayArrowIcon />}
          loading={testRunning}
        >
          {i18n.get('test')}
        </RaisedSecondaryButton>

        <RaisedPrimaryButton
          disabled={disabled}
          onClick={handleSubmit}
        >
          {i18n.get('save')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

SearchQueryDialog.defaultProps = {
  editing: true
}

SearchQueryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTest: PropTypes.func.isRequired,

  testRunning: PropTypes.bool.isRequired,
  testDisabled: PropTypes.bool.isRequired,
  testResult: PropTypes.instanceOf(Map),
  data: PropTypes.instanceOf(Map),

  open: PropTypes.bool.isRequired,
  editing: PropTypes.bool
}
