import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/charts'

export const initialState = fromJS({
  showDialog: false,
  loading: false,
  analysis: null,
  chart: null
})

export default handleActions({
  [Actions.showAiChartAnalysisDialog]: (state, { payload: { chart } }) => state.merge({
    showDialog: true,
    analysis: null,
    chart
  }),
  [Actions.hideAiChartAnalysisDialog]: state => state.set('showDialog', false),

  [Actions.aiChartAnalysisStart]: state => state.set('loading', true),
  [Actions.aiChartAnalysisSuccess]: (state, { payload: { analysis } }) => state.merge({
    loading: false,
    analysis
  }),
  [Actions.aiChartAnalysisError]: state => state.set('loading', false),

  [AppActions.resetState]: () => initialState
}, initialState)
