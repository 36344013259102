import { connect } from 'react-redux'

import { openCalendarDialog } from 'actions/content_desk'

import { isContentDeskContentsAndCampaigns } from 'selectors'

import Actions from 'components/content_desk_new/actions/Actions'

const mapStateToProps = state => ({
  isSubmodule: isContentDeskContentsAndCampaigns(state)
})

export default connect(
  mapStateToProps,
  {
    openCalendarDialog
  }
)(Actions)
