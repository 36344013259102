import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip } from '@mui/material'
import TagIcon from '@mui/icons-material/Tag'

export default function TagChip({ label }) {
  return (
    <Box
      mr={1}
      my={1}
      display="inline-block"
    >
      <Chip
        px={2}
        icon={<TagIcon />}
        size="small"
        label={label}
      />
    </Box>
  )
}

TagChip.propTypes = {
  label: PropTypes.string.isRequired
}
