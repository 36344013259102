import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import filter from './filter'

export default combineReducers({
  ui,
  data,
  filter
})
