import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { MenuItem } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import PoolIcon from '@mui/icons-material/Pool'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Capabilities } from 'static/constants'

import { IconButton, Menu } from 'containers/themed'
import CampaignDelete from 'containers/content_desk_new/campaigns/campaign/CampaignDelete'
import CapSafe from 'containers/CapSafe'

const SwitchToSearchMenuItem = CapSafe(MenuItem, Capabilities.HAS_NEWS_POOL_MODULE)

const useStyles = makeStyles()(() => ({
  actionIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 8
  },
  actionName: {
    marginLeft: 12
  }
}))

export default function CampaignActions({
  campaign,
  onEditClick,
  onSwitchToSearchPoolClick,
  setEditMode
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleEdit = e => {
    e.stopPropagation()

    setEditMode()
    onEditClick(campaign)
  }

  return (
    <>
      <Menu
        handle={(
          <IconButton
            size="small"
          >
            <MoreVertIcon />
          </IconButton>
        )}
      >
        <MenuItem
          onClick={handleEdit}
          disabled={campaign.get('defaultAssigned') || false}
        >
          <EditIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('edit')}
          </div>
        </MenuItem>
        <SwitchToSearchMenuItem
          disabled={!campaign.get('hasReleasedContents') || campaign.get('defaultAssigned')}
          onClick={() => onSwitchToSearchPoolClick({
            field: 'ccdCampaigns',
            value: campaign
          })}
        >
          <PoolIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('view_earned_media')}
          </div>
        </SwitchToSearchMenuItem>
        <CampaignDelete campaign={campaign} />
      </Menu>
    </>
  )
}

CampaignActions.propTypes = {
  campaign: PropTypes.instanceOf(ImmutableMap).isRequired,

  onEditClick: PropTypes.func.isRequired,
  onSwitchToSearchPoolClick: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired
}
