import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'
import {
  TableContainer,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Backdrop
} from '@mui/material'
import { styled } from '@mui/material/styles'
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined'
import { IconButton } from 'containers/themed'

import TagRenameDialog from 'containers/contact_management/TagRenameDialog'
import TagDeleteDialog from 'containers/contact_management/TagDeleteDialog'
import TagSortbar from 'containers/contact_management/TagSortbar'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

export default function TagList({
  tags,
  loading,
  onRenameClick,
  onDeleteClick
}) {
  const i18n = useI18n()
  const [selectedTag, setSelectedTag] = useState(ImmutableMap())
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleOpenRenameDialog = tag => {
    setSelectedTag(tag)
    onRenameClick()
  }
  const handleOpenDeleteDialog = tag => {
    setSelectedTag(tag)
    onDeleteClick()
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const totalCount = total => {
    const label = total === 1 ? i18n.get('contact') : i18n.get('contacts')

    return `${total} ${label}`
  }

  const renderTagRow = tag => (
    <StyledTableRow
      key={`tag__${tag.get('id')}`}
    >
      <StyledTableCell>
        {tag.get('type') === 'custom'
        && (
        <LocalOfferIcon
          style={{ color: '#707070' }}
          fontSize="small"
        />
        )}
        {tag.get('type') === 'publication'
        && (
        <NewspaperOutlinedIcon
          style={{ color: '#707070' }}
          fontSize="small"
        />
        )}
      </StyledTableCell>
      <StyledTableCell>{tag.get('name')}</StyledTableCell>
      <StyledTableCell>{totalCount(tag.get('total'))}</StyledTableCell>
      <StyledTableCell sx={{ textAlign: 'right', paddingRight: t => t.spacing(2) }}>
        <IconButton
          size="small"
          onClick={() => handleOpenRenameDialog(tag)}
        >
          <RenameIcon />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          onClick={() => handleOpenDeleteDialog(tag)}
        >
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Backdrop
          style={{ position: 'absolute' }}
          open={loading}
        >
          <CircularProgress size={80} />
        </Backdrop>
        <Table
          padding="none"
          size="small"
        >
          <TableHead py={2}>
            <TagSortbar />
          </TableHead>
          <TableBody>
            {tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(tag => renderTagRow(tag))}
            {tags.size > 10 && (
            <StyledTableRow>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                count={tags.size}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                labelRowsPerPage={i18n.get('rows_per_page')}
              />
            </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!selectedTag.isEmpty() && <TagRenameDialog tag={selectedTag} />}
      {!selectedTag.isEmpty() && <TagDeleteDialog tag={selectedTag} />}
    </>
  )
}

TagList.propTypes = {
  tags: PropTypes.instanceOf(ImmutableList).isRequired,
  loading: PropTypes.bool.isRequired,
  onRenameClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
}
