import { connect } from 'react-redux'

import PersonalSettings from 'components/personal_settings'
import { getUser, getDeeplApiKey } from 'selectors'
import { updateUserRequestStart } from 'actions/user'

const mapStateToProps = state => ({
  user: getUser(state),
  hasDeeplApiKey: !!getDeeplApiKey(state)
})

export default connect(
  mapStateToProps,
  {
    onSave: updateUserRequestStart
  }
)(PersonalSettings)
