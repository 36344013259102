import { put, takeEvery, all, select } from 'redux-saga/effects'
import * as Selectors from 'selectors'

import * as Actions from 'actions/content_desk'

export function* uiEnteredQuery({ payload: query }) {
  const selectedQueryType = yield select(Selectors.getCDeskSelectedQueryType)

  if (query !== '') {
    const queries = query.split(',').map(q => q.trim()).filter(q => q)

    for (let i = 0; i < queries.length; i += 1) {
      yield put(Actions.addSearchQuery({
        query: queries[i],
        selectedQueryType
      }))
    }
  }

  yield put(Actions.search())
}

export function* setSortBy({ payload: { type } }) {
  if (type === 'contents') {
    yield put(Actions.searchContentsStart())
  }

  if (type === 'campaigns') {
    yield put(Actions.searchCampaignsStart())
  }
}

export function* uiRemoveFilter({ payload: { index, field } }) {
  yield put(Actions.removeFilter({ index, field }))
  yield put(Actions.search())
}

export function* uiUpdateFilter({ payload }) {
  yield put(Actions.updateFilter(payload))
  yield put(Actions.search())
}

export function* watchUiEnteredQuery() {
  yield takeEvery(Actions.uiEnteredQuery, uiEnteredQuery)
}

export function* watchUiRemoveFilter() {
  yield takeEvery(Actions.uiRemoveFilter, uiRemoveFilter)
}

export function* watchUiUpdateFilter() {
  yield takeEvery(Actions.uiUpdateFilter, uiUpdateFilter)
}

export function* watchSetSortBy() {
  yield takeEvery(Actions.setSortBy, setSortBy)
}

export default function* filterSaga() {
  yield all([
    watchUiEnteredQuery(),
    watchUiRemoveFilter(),
    watchUiUpdateFilter(),
    watchSetSortBy()
  ])
}
