import { Set } from 'immutable'

import { createImmutableSelector } from 'utils/reselect'
import { getNewsByIds } from './data'

const getMediaReviewsDetail = state => state.mediaReviews.get('detail')

export const getMediaReviewsSelectedNews = state => getMediaReviewsDetail(state).get('selectedNews') || Set([])
export const getMediaReviewsSelectedNewsIds = state => getMediaReviewsSelectedNews(state).map(n => {
  const a = n.split('_')

  return parseInt(a[a.length - 1], 10)
})
export const getMediaReviewsSelectedNewsObjects = createImmutableSelector(
  state => getNewsByIds(state, getMediaReviewsSelectedNewsIds(state)),
  news => news.filter(n => n)
)
