import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { rgbaColorFromHex } from 'utils/color'
import { IconButton, Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  mobileContentContainer: {
    padding: '0px 20px 20px 20px'
  },
  contentContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: theme.palette.common.black
  },
  continueButton: {
    marginTop: 20
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftContainer: {
    padding: '60px 50px 50px 50px',
    flex: '1 1 60%'
  },
  rightContainer: {
    textAlign: 'center',
    flex: '1 1 40%',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`
  },
  mobileImageContainer: {
    textAlign: 'center',
    marginTop: 20,
    padding: '20px 10px 20px 10px',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80%'
  },
  priceInfoContainer: {
    marginBottom: 20,
    clear: 'both'
  },
  price: {
    fontSize: 40,
    fontWeight: 500
  },
  excludeVat: {
    fontSize: 14,
    verticalAlign: 'middle'
  },
  priceDiffer: {
    color: theme.palette.text.secondary
  },
  naLogoContainer: {
    marginTop: 40,
    display: 'flex',
    alignItems: 'center'
  },
  naLogo: {
    marginLeft: 20
  }
}))

const OtsWelcomeDialog = ({
  open,
  onClose,
  openContent
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const handleClose = () => {
    onClose()
  }

  const handleContinue = () => {
    onClose()
    openContent({ type: 'ots_press_release' })
  }

  const otsWelcomeMessage = (
    <>
      <Typography
        variant="h5"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html: i18n.get('ots_welcome_title')
        }}
      />
      <Typography
        variant="body1"
        color="text.secondary"
        dangerouslySetInnerHTML={{
          __html: i18n.get('ots_welcome_text')
        }}
      />
    </>
  )

  const priceInfo = (
    <Box>
      <Box className={classes.priceInfoContainer}>
        <Typography variant="h5">
          {i18n.get('ots_price_info_intro')}
        </Typography>
        <Box className={classes.price}>
          955 € <span className={classes.excludeVat}>{i18n.get('price_exclude_vat')}</span>
        </Box>
        <Box className={classes.priceDiffer}>
          {i18n.get('ots_price_info_differ')}
        </Box>
      </Box>
      <Box>
        <img
          src="https://static.pressrelations.de/pics/groot/ccd/ots_welcome_pic.png"
          alt="newspaper"
          width="200"
        />
      </Box>
    </Box>
  )

  const continueButton = (
    <Button
      color="primary"
      variant="contained"
      className={classes.continueButton}
      onClick={handleContinue}
    >
      {i18n.get('create_ots_press_release')}
    </Button>
  )

  const naLogo = (
    <Box className={classes.naLogoContainer}>
      <Box>
        {i18n.get('powered_by')}
      </Box>
      <Box className={classes.naLogo}>
        <img
          src="https://static.pressrelations.de/pics/groot/ccd/na_logo.png"
          alt="News Aktuell"
          width="150"
        />
      </Box>
    </Box>
  )

  const renderContent = () => {
    if (device.get('mobile')) {
      return (
        <Box className={classes.mobileContentContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {otsWelcomeMessage}
          <Box className={classes.mobileImageContainer}>
            {priceInfo}
            {continueButton}
            {naLogo}
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Box className={classes.leftContainer}>
          {otsWelcomeMessage}
          {continueButton}
          {naLogo}
        </Box>
        <Box className={classes.rightContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.imageContainer}>
            {priceInfo}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.content}>
          {renderContent()}
        </Box>
      </Paper>
    </Dialog>
  )
}

OtsWelcomeDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  openContent: PropTypes.func.isRequired
}

export default OtsWelcomeDialog
