import React, { useState, useEffect } from 'react'
import { Map, List } from 'immutable'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  RaisedSecondaryButton,
  RaisedPrimaryButton,
  InlineDatePicker,
  InlineDateTimePicker,
  Button,
  IconButton
} from 'containers/themed'
import { Grid, Link, Typography, RadioGroup, Radio, FormControlLabel, Divider } from '@mui/material'
import { red } from '@mui/material/colors'

import FilterTimeline from 'containers/date_form/FilterTimeline'
import ActionBar from 'components/search_form/action_bar'
import { getDateQuickselects } from 'utils/date'

const useStyles = makeStyles()(theme => ({
  dateForm: {
    padding: 20,
    [theme.breakpoints.up('lg')]: {
      maxHeight: '90%',
      overflowY: 'auto'
    }
  },
  dateTypeRow: {
    marginBottom: -5,
    '& > div': {
      display: 'inline'
    }
  },
  dateRestrictionInfo: {
    color: red[500],
    marginBottom: 10
  },
  link: {
    marginBottom: 10,
    cursor: 'pointer',
    color: theme.nrx.drawer.linkColor
  },
  activeLink: {
    marginBottom: 10,
    cursor: 'pointer',
    color: theme.nrx.drawer.activeLinkColor
  },
  divider: {
    marginBottom: 10
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10
  },
  inputs: {
    '& > div': {
      width: '100%',
      '&:first-of-type': {
        marginBottom: 20
      }
    }
  },
  actionBar: {
    backgroundColor: theme.nrx.paper.paperOnBackgroundColor
  }
}))

const Row = props => (
  <Grid
    container
    direction="row"
    spacing={2}
    {...props}
  />
)

const Col = props => (
  <Grid
    item
    {...props}
  />
)

const DateForm = ({
  active,
  primary,
  secondary,
  allowedDateTypes,
  dateRange,
  dateType,
  device,
  hasTimelineFilter,
  hasDateRestriction,
  i18n,
  locale,
  onChangeDateType,
  onClose,
  timeLimit,
  timeUnit,
  uiApplyDateFilter,
  uiChooseQuickSelect,
  dateFrom: dateFromFromProps,
  dateTo: dateToFromProps
}) => {
  const { classes } = useStyles()
  const [dateFrom, setDateFrom] = useState(dateFromFromProps)
  const [dateTo, setDateTo] = useState(dateToFromProps)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (active) {
      setDateFrom(dateFrom)
      setDateTo(dateTo)
      setOpen(false)
    }
  }, [active])

  const handleApply = () => {
    let newDateFrom = dateFrom
    let newDateTo = dateTo

    if (dateType === 'article') {
      if (newDateFrom) {
        newDateFrom = moment(newDateFrom).startOf('minute').toDate()
      }

      if (newDateTo) {
        newDateTo = moment(newDateTo).endOf('minute').toDate()
      }
    }

    uiApplyDateFilter({
      dateFrom: newDateFrom,
      dateTo: newDateTo
    })
  }

  const handleChange = (name, value) => {
    if (name === 'dateFrom') {
      setDateFrom(value)
    } else {
      setDateTo(value)
    }
  }

  // const handleReset = name => e => {
  //   e.preventDefault()
  //   e.stopPropagation()

  //   if (name === 'dateFrom') {
  //     setDateFrom(null)
  //   } else {
  //     setDateTo(null)
  //   }
  // }

  const renderTimeRangeCol = () => {
    let PickerComp = InlineDatePicker
    let ampm

    if (dateType === 'article') {
      ampm = locale === 'en-us'
      PickerComp = InlineDateTimePicker
    }

    return (
      <Col
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Typography variant="h5">
          {i18n.get('time_range')}
        </Typography>
        <Divider classes={{ root: classes.divider }} />

        <div className={classes.inputs}>
          <PickerComp
            ampm={ampm}
            label={i18n.get('from_date')}
            minDate={new Date('January 01, 2002 00:00:00')}
            maxDate={dateTo}
            value={dateFrom}
            onChange={value => {
              handleChange('dateFrom', value)
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            resetable
          />

          <PickerComp
            ampm={ampm}
            label={i18n.get('to_date')}
            minDate={dateFrom}
            value={dateTo}
            onChange={value => {
              handleChange('dateTo', value)
            }}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            resetable
          />
        </div>
      </Col>
    )
  }

  const renderQuickSelectItem = range => (
    <Link
      key={range}
      display="block"
      className={dateRange === range ? classes.activeLink : classes.link}
      onClick={() => uiChooseQuickSelect(range)}
    >
      {i18n.get(range)}
    </Link>
  )

  const renderQuickSelectCol = () => (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      <Typography variant="h5">
        {i18n.get('quick_ranges')}
      </Typography>
      <Divider classes={{ root: classes.divider }} />

      <Row>
        <Col xs={6}>
          {
            getDateQuickselects(dateType)[0].map(renderQuickSelectItem)
          }
        </Col>
        <Col xs={6}>
          {
            getDateQuickselects(dateType)[1].map(renderQuickSelectItem)
          }
        </Col>
      </Row>
    </Col>
  )

  const renderDateType = () => {
    const radios = []

    if (allowedDateTypes.includes('mediareview')) {
      radios.push(
        <FormControlLabel
          key="mediareview"
          value="mediareview"
          control={<Radio />}
          label={i18n.get('media_review_date')}
        />
      )
    }

    if (allowedDateTypes.includes('article')) {
      radios.push(
        <FormControlLabel
          key="article"
          value="article"
          control={<Radio />}
          label={i18n.get('article_date')}
        />
      )
    }

    if (radios.length <= 1) {
      return null
    }

    return (
      <>
        <Typography variant="h5">
          {i18n.get('date_type')}
        </Typography>
        <Divider classes={{ root: classes.divider }} />

        <div className={classes.dateTypeRow}>
          <RadioGroup
            value={dateType}
            onChange={event => onChangeDateType(event.target.value)}
          >
            {radios}
          </RadioGroup>
        </div>

        <br />
      </>
    )
  }

  const renderRestrictionInfo = () => {
    if (!hasDateRestriction) {
      return null
    }

    const time = moment().subtract(timeLimit, timeUnit).fromNow()

    return (
      <div className={classes.dateRestrictionInfo}>
        {i18n.get('date_restriction_info')}: {i18n.get('today')} {i18n.get('till').toLowerCase()} {time}.
      </div>
    )
  }

  const ButtonComp = !primary || secondary ? RaisedSecondaryButton : RaisedPrimaryButton

  return (
    <div className={classes.dateForm}>
      {renderDateType()}
      {renderQuickSelectCol()}
      {renderTimeRangeCol()}
      <br />

      {renderRestrictionInfo()}

      {hasTimelineFilter && (
        <FilterTimeline
          dateFrom={dateFrom}
          dateTo={dateTo}
          active={active && !open}
        />
      )}
      <br />

      <ActionBar className={classes.actionBar}>
        <Button onClick={() => onClose()}>
          {i18n.get('close')}
        </Button>

        <ButtonComp onClick={handleApply}>
          {i18n.get('apply_filters')}
        </ButtonComp>
      </ActionBar>

      <div className={classes.close}>
        <IconButton
          onClick={() => onClose()}
          title={i18n.get('close')}
          size="large"
        >
          {device.get('lt-lg') ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>
    </div>
  )
}

DateForm.defaultProps = {
  primary: true,
  secondary: false,
  hasTimelineFilter: true
}

DateForm.propTypes = {
  active: PropTypes.bool.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  dateFrom: PropTypes.object, // eslint-disable-line
  dateTo: PropTypes.object, // eslint-disable-line
  dateRange: PropTypes.string, // eslint-disable-line
  dateType: PropTypes.string,
  locale: PropTypes.string, // eslint-disable-line
  i18n: PropTypes.object.isRequired,
  allowedDateTypes: PropTypes.instanceOf(List).isRequired, // eslint-disable-line
  hasDateRestriction: PropTypes.bool, // eslint-disable-line
  timeLimit: PropTypes.number.isRequired, // eslint-disable-line
  timeUnit: PropTypes.string.isRequired, // eslint-disable-line
  device: PropTypes.instanceOf(Map).isRequired,
  hasTimelineFilter: PropTypes.bool,

  uiApplyDateFilter: PropTypes.func.isRequired,
  uiChooseQuickSelect: PropTypes.func.isRequired, // eslint-disable-line
  onChangeDateType: PropTypes.func.isRequired, // eslint-disable-line
  onClose: PropTypes.func.isRequired
}

export default React.memo(DateForm, (prevProps, nextProps) => !nextProps.active)
