import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import useI18n from 'hooks/useI18n'

import { Paper, Fab } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import NoHits from 'containers/news_page/NoHits'
import AverageStatistics from 'containers/darknet/AverageStatistics'
import { Button } from 'containers/themed'

import Entry from 'components/darknet/dashboard/entry'
import ScrollToTop from 'components/darknet/scroll_to_top'

import Titles from './titles'

const useStyles = makeStyles()(theme => ({
  paper: {
    padding: '1em',
    margin: '1em 2em 2em 1em',
    [theme.breakpoints.only('xs')]: {
      margin: 0,
      padding: '1em 0'
    }
  },
  loadMoreBtn: {
    padding: '0 80px 20px 0',
    textAlign: 'right'
  }
}))

const byQueryId = (query, id) => id === query.get('id')
const getMatchingQuery = (queries, id) => queries.find(q => byQueryId(q, id)) || Map()

export default function SearchResults({ searchResults, total, loadingMore, darkowlSearchQueries, onLoadMoreClick }) {
  const i18n = useI18n()

  if (!searchResults.size) {
    return (
      <>
        <br />
        <br />
        <NoHits />
      </>
    )
  }

  const { classes } = useStyles()

  const renderEntries = () => (searchResults.map((entry, index) => {
    const queryId = entry.get('darkowlSearchQueryId')
    const matchingQuery = getMatchingQuery(darkowlSearchQueries, queryId)
    const location = entry.get('location')

    return (
      <Entry
        key={`${entry.get('id')}-${index}`}
        entry={entry}
        query={matchingQuery}
        location={location}
      />
    )
  }))

  const renderLoadMore = () => {
    const remaining = total - searchResults.size

    if (remaining < 1) {
      return null
    }

    return (
      <div className={classes.loadMoreBtn}>
        <Button
          variant="contained"
          color="primary"
          loading={loadingMore}
          disabled={loadingMore}
          onClick={() => onLoadMoreClick()}
        >
          {i18n.get('load_more_remaining', { remaining })}
        </Button>
      </div>
    )
  }

  return (
    <>
      <Paper className={classes.paper}>
        <AverageStatistics />
        <Titles />
        {renderEntries()}
      </Paper>
      <ScrollToTop>
        <Fab
          title={i18n.get('back_to_top')}
          color="primary"
          size="small"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollToTop>
      {renderLoadMore()}
    </>
  )
}

SearchResults.propTypes = {
  searchResults: PropTypes.instanceOf(List),
  total: PropTypes.number.isRequired,
  loadingMore: PropTypes.bool.isRequired,
  darkowlSearchQueries: PropTypes.instanceOf(List),

  onLoadMoreClick: PropTypes.func.isRequired
}
