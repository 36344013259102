import { fromJS } from 'immutable'
import { createImmutableSelector } from 'utils/reselect'

import store from '../store'

const getKeyMap = createImmutableSelector(
  state => state.statics.getIn(['sorted', 'customKpis']),
  customKpis => (customKpis || fromJS([])).reduce((acc, c) => acc.set(c.get('key'), c), fromJS({}))
)

export const isCustomKpi = key => getKeyMap(store.getState()).has(key)

export const getCustomKpiName = key => getKeyMap(store.getState()).getIn([key, 'label'])
