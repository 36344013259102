import { connect } from 'react-redux'

import TagList from 'components/contact_management/tags_dialog/tag_list'

import {
  getCmTags, getCmSearchRunning
} from 'selectors/contact_management'

import {
  openTagRenameDialog as onRenameClick,
  openTagDeleteDialog as onDeleteClick
} from 'actions/contact_management'

const mapStateToProps = state => ({
  tags: getCmTags(state),
  loading: getCmSearchRunning(state)
})

export default connect(mapStateToProps, {
  onRenameClick,
  onDeleteClick
})(TagList)
