import { connect } from 'react-redux'

import DuplicateContactsDialog from 'components/anewstip/duplicate_contacts_dialog/DuplicateContactsDialog'

import {
  hideDuplicateContactsDialog as onCancel,
  showDistributionListCreateDialog as onNext
} from 'actions/anewstip'

import {
  getDuplicateContactsDialogOpen,
  getAnewstipImportResult,
  getAnewstipImportWithDistributionLists
} from 'selectors/anewstip'

const mapStateToProps = state => ({
  open: getDuplicateContactsDialogOpen(state),
  withNext: getAnewstipImportWithDistributionLists(state),
  result: getAnewstipImportResult(state)
})

export default connect(
  mapStateToProps,
  {
    onCancel,
    onNext
  }
)(DuplicateContactsDialog)
