import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  headline: {
    fontWeight: '500',
    color: theme.isDark ? '#ffffff' : theme.palette.grey[800],
    userSelect: 'auto',
    a: {
      transition: 'color 500ms',
      color: theme.palette.grey[theme.isDark ? 200 : 900],
      textDecoration: 'none',

      '&:hover': {
        color: theme.palette.grey[theme.isDark ? 500 : 900]
      }
    }
  }
}))

export default function Headline({ news }) {
  const { classes } = useStyles()
  const target = news.get('url')

  const headline = news.get('headline')

  if (target) {
    return (
      <div
        className={classes.headline}
        title={news.get('headline')}
      >
        <a
          href={target}
          target="_blank"
          rel="noopener noreferrer"
          dangerouslySetInnerHTML={{
            __html: headline
          }}
        />
      </div>
    )
  }

  return (
    <div
      className={classes.headline}
      title={news.get('headline')}
      dangerouslySetInnerHTML={{
        __html: headline
      }}
    />
  )
}

Headline.propTypes = {
  news: PropTypes.object.isRequired
}
