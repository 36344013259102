import { connect } from 'react-redux'

import {
  getCDeskSelectedCalendarEvents,
  getCDeskCalendarContentsSorting
} from 'selectors'
import {
  sortContents
} from 'actions/content_desk'

import ShortContents from 'components/content_desk_new/contents/short_contents'

const mapStateToProps = state => {
  const events = getCDeskSelectedCalendarEvents(state)

  return {
    contents: events.get('contents'),
    contentsSorting: getCDeskCalendarContentsSorting(state)
  }
}

export default connect(
  mapStateToProps,
  {
    sortContents
  }
)(ShortContents)
