import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { makeStyles } from 'tss-react/mui'

import { Card as MuiCard, Checkbox } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  cardContainer: {
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    zIndex: 100,
    top: -15,
    left: -15,
    transition: 'left 500ms',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.only('xs')]: {
      left: 0,
      justifyContent: 'center'
    }
  },
  selectedIcon: {
    left: 1,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center'
    }
  },
  iconCenter: {
    left: 0,
    justifyContent: 'center'
  },
  checkboxContainer: {
    position: 'absolute',
    zIndex: 100,
    top: -14,
    left: -14,
    backgroundColor: 'inherit',
    width: 30,
    height: 30,
    borderRadius: '50%',
    [theme.breakpoints.only('xs')]: {
      left: '45%'
    },
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  checkboxIcon: {
    fontSize: 38
  },
  selectOverlay: {
    transition: 'all 500ms',
    border: '5px solid transparent',
    borderRadius: 10,
    position: 'absolute',
    zIndex: -1,
    top: -1,
    left: -2,
    height: '102%',
    width: '101.5%'
  },
  overlayActive: {
    zIndex: 50,
    cursor: 'pointer'
  },
  selected: {
    borderColor: theme.palette.grey[500]
  },
  iconRight: {
    [theme.breakpoints.up('sm')]: {
      right: -15,
      left: 'auto',
      justifyContent: 'flex-end'
    }
  },
  checkboxContainerRight: {
    [theme.breakpoints.up('sm')]: {
      right: -14,
      left: 'auto'
    }
  }
}))

export default function Card({
  checkboxPosition,
  children,
  className,
  icon,
  iconPosition,
  onClick,
  onSelect,
  raiseOnHover,
  selected,
  selectedBorder,
  selectOverlayClassName,
  showSelect,
  style
}) {
  const { classes, cx } = useStyles()
  const [hovered, setHovered] = useState(false)

  let onMouseEnter
  let onMouseLeave

  if (raiseOnHover) {
    onMouseEnter = () => setHovered(true)
    onMouseLeave = () => setHovered(false)
  }

  const isSelectVisible = onSelect && (showSelect || hovered || selected)

  const handleClick = e => {
    if (showSelect) {
      e.preventDefault()
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      onSelect()
    }
  }

  const renderIcon = () => {
    if (!icon) {
      return null
    }

    let iconClass = classes.icon

    if (isSelectVisible) {
      iconClass = `${iconClass} ${classes.selectedIcon}`
    } else if (iconPosition === 'center') {
      iconClass = `${iconClass} ${classes.iconCenter}`
    }

    if (iconPosition === 'right') {
      iconClass = `${iconClass} ${classes.iconRight}`
    }

    return (
      <div className={iconClass}>
        {icon}
      </div>
    )
  }

  const renderSelectOverlay = () => {
    const overlayClassName = cx(
      classes.selectOverlay,
      selected && selectedBorder && classes.selected,
      showSelect && classes.overlayActive,
      selectOverlayClassName
    )

    return (
      <div
        className={overlayClassName}
        onClick={handleClick}
      />
    )
  }

  const renderSelect = () => {
    if (!isSelectVisible) {
      return null
    }

    let selectClassName = classes.checkboxContainer

    if (checkboxPosition === 'right') {
      selectClassName = `${selectClassName} ${classes.checkboxContainerRight}`
    }

    return (
      <Checkbox
        className={selectClassName}
        checkedIcon={<CheckCircleIcon classes={{ root: classes.checkboxIcon }} />}
        icon={<CheckCircleOutlineIcon classes={{ root: classes.checkboxIcon }} />}
        checked={selected}
        onChange={() => onSelect()}
      />
    )
  }

  return (
    <div className={classes.cardContainer}>
      <MuiCard
        raised={selected || hovered}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={className}
        onClick={onClick}
        style={style}
      >
        {renderIcon()}
        {renderSelect()}
        {renderSelectOverlay()}

        {children}
      </MuiCard>
    </div>
  )
}

Card.defaultProps = {
  selectedBorder: true,
  checkboxPosition: 'left',
  iconPosition: 'left',
  raiseOnHover: true
}

Card.propTypes = {
  icon: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object,
  showSelect: PropTypes.bool,
  selected: PropTypes.bool,
  raiseOnHover: PropTypes.bool,
  onSelect: PropTypes.func,
  className: PropTypes.string,
  selectOverlayClassName: PropTypes.string,
  selectedBorder: PropTypes.bool,
  checkboxPosition: PropTypes.string,
  iconPosition: PropTypes.string,
  onClick: PropTypes.func
}
