import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { Icon } from '@mui/material'

import { Button, IconButton } from 'containers/themed'
import SavedSearchesMenu from 'containers/themax/saved_searches/SavedSearchesMenu'

import masterConfig from 'static/master_config'

export default function SavedSearchesActions({ device }) {
  const i18n = useI18n()

  let handle = (
    <Button
      color="inherit"
      startIcon={<Icon>{masterConfig.getIn(['icons', 'savedSearches'])}</Icon>}
    >
      {i18n.get('saved_searches')}
    </Button>
  )

  if (device.get('lt-lg')) {
    handle = (
      <IconButton
        color="inherit"
        title={i18n.get('saved_searches')}
      >
        <Icon>{masterConfig.getIn(['icons', 'savedSearches'])}</Icon>
      </IconButton>
    )
  }

  return (
    <SavedSearchesMenu handle={handle} />
  )
}

SavedSearchesActions.propTypes = {
  device: PropTypes.object.isRequired
}
