import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

import { Networks } from 'static/constants'

export const initialState = fromJS({
  formData: {
    id: null,
    name: '',
    searchQuery: '',
    emails: [],
    emailDomains: [],
    networks: Object.assign({}, ...Networks.map(el => ({ [el.id]: false }))),
    minHackishness: 0
  },
  testSearchQueryRunning: false,
  testSearchQueryDisabled: false,
  testResult: null
})

export default handleActions({
  [Actions.updateSearchQueryFormData]: (state, { payload }) => (
    state.update('formData', oldValue => (
      oldValue.merge(payload)
    ))
  ),
  [Actions.testSearchQueryStart]: state => state.set('testSearchQueryRunning', true),
  [Actions.testSearchQuerySuccess]: state => (
    state.merge({
      testSearchQueryRunning: false,
      testResult: null,
      testSearchQueryDisabled: false
    })
  ),
  [Actions.testSearchQueryError]: state => (
    state.merge({
      testSearchQueryRunning: false,
      testResult: fromJS({ success: false, message: 'Error! Invalid Search Query' }),
      testSearchQueryDisabled: false
    })
  ),
  [Actions.setSearchQueryFormTestResult]: (state, { payload }) => (
    state.merge({
      testResult: fromJS(payload),
      testSearchQueryRunning: false,
      testSearchQueryDisabled: true
    })
  ),
  [Actions.resetSearchQueryFormData]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
