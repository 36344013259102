import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { Typography, Grid, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  additionalLanguages: {
    marginTop: 10
  }
})

export default function MultiLanguageNameForm({
  renderNameInput
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          {renderNameInput('nameEn', true)}
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
        >
          {renderNameInput('nameDe')}
        </Grid>
      </Grid>

      <Accordion classes={{ root: classes.additionalLanguages }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {i18n.get('additional_languages')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              {renderNameInput('nameFr')}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              {renderNameInput('nameRu')}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              {renderNameInput('nameZh')}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={4}
            >
              {renderNameInput('nameJa')}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

MultiLanguageNameForm.propTypes = {
  renderNameInput: PropTypes.func.isRequired
}
