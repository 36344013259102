import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Paper, Tab, CircularProgress } from '@mui/material'

import { TabContext, TabList, TabPanel } from '@mui/lab'

import Result from 'containers/anewstip/results/Result'
import Dialog from 'containers/anewstip/results/Dialog'

const useStyles = makeStyles()(({
  resultsMainContainer: {
    width: '100%',
    height: '100%'
  },
  resultsContainer: {
    paddingLeft: '15px',
    paddingRight: '15px'
  },
  tabs: {
    borderBottom: '1px solid gray'
  }
}))

const Results = ({
  journalists,
  outlets,
  articles,
  resultsTab,
  isNavigationLocked,
  switchResultsTab,
  journalistsSearchRequestRunning,
  outletsSearchRequestRunning,
  articlesSearchRequestRunning
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const totalJournalistsCount = journalists.getIn(['paginator', 'totalCount'])
  const totalOutletsCount = outlets.getIn(['paginator', 'totalCount'])

  const handleTabChange = (_event, selectedTab) => {
    switchResultsTab(selectedTab)
  }

  const renderLoader = () => (
    <CircularProgress
      size={14}
      variant="indeterminate"
    />
  )

  const renderTabLabel = (label, hits, loading) => (
    <>
      {i18n.get(label)}
      <br />
      {loading && renderLoader()}
      {!loading && hits !== null && <span style={{ fontSize: 'smaller' }}>({hits >= 1000 ? `${hits}+` : hits})</span>}
    </>
  )

  return (
    <>
      <Paper
        className={classes.resultsMainContainer}
      >
        <div className={classes.resultsContainer}>
          <TabContext value={resultsTab}>
            <div className={classes.tabs}>
              <TabList
                centered
                onChange={handleTabChange}
                variant="fullWidth"
              >
                <Tab
                  label={renderTabLabel('journalists', totalJournalistsCount, journalistsSearchRequestRunning)}
                  value="journalists"
                  disabled={isNavigationLocked}
                />
                <Tab
                  label={renderTabLabel('outlets', totalOutletsCount, outletsSearchRequestRunning)}
                  value="outlets"
                />
                <Tab
                  label={renderTabLabel('articles', null, articlesSearchRequestRunning)}
                  value="articles"
                  disabled={isNavigationLocked}
                />
              </TabList>
            </div>
            <TabPanel
              sx={{ height: '100%' }}
              value="journalists"
            >
              <Result
                resultsTab={resultsTab}
                results={journalists}
              />
            </TabPanel>
            <TabPanel
              sx={{ height: '100%' }}
              value="outlets"
            >
              <Result
                resultsTab={resultsTab}
                results={outlets}
              />
            </TabPanel>
            <TabPanel
              sx={{ height: '100%' }}
              value="articles"
            >
              <Result
                resultsTab={resultsTab}
                results={articles}
              />
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
      <Dialog />
    </>
  )
}

Results.propTypes = {
  journalists: PropTypes.instanceOf(Map).isRequired,
  outlets: PropTypes.instanceOf(Map).isRequired,
  articles: PropTypes.instanceOf(Map).isRequired,
  resultsTab: PropTypes.string.isRequired,
  isNavigationLocked: PropTypes.bool.isRequired,
  journalistsSearchRequestRunning: PropTypes.bool.isRequired,
  outletsSearchRequestRunning: PropTypes.bool.isRequired,
  articlesSearchRequestRunning: PropTypes.bool.isRequired,

  switchResultsTab: PropTypes.func.isRequired
}

export default Results
