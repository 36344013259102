import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slide,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'

import { Button, IconButton } from 'containers/themed'

import { Capabilities } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    padding: '25px'
  },
  tooltip: {
    maxWidth: '280px'
  }
}))

const SaveTemplateDialog = ({ open, loading, capabilities, onClose, onChange, onSave }) => {
  const { classes } = useStyles()

  const i18n = useI18n()
  const hasPublicWriteRights = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_WRITE_RIGHTS)

  const handleChange = e => onChange({ key: e.target.name, value: e.target.value })
  const handleChangeCheckbox = e => onChange({ key: e.target.name, value: e.target.checked })

  const handleSave = () => onSave('emailEditor')

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'up' }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('save_template')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={() => onClose()}
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label={i18n.get('name')}
          name="name"
          onChange={handleChange}
          margin="normal"
          variant="standard"
          size="small"
          disabled={loading}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {hasPublicWriteRights && (
            <FormControlLabel
              sx={{ marginRight: '20px' }}
              name="shared"
              control={(
                <Checkbox
                  onChange={handleChangeCheckbox}
                  disabled={loading}
                />
              )}
              label={i18n.get('public')}
            />
          )}
          <FormControlLabel
            sx={{ marginRight: '5px' }}
            name="released"
            control={(
              <Checkbox
                onChange={handleChangeCheckbox}
                disabled={loading}
              />
            )}
            label={i18n.get('released')}
          />
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            arrow
            title={i18n.get('save_template_released_tooltip')}
            placement="right"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [8, -10]
                  }
                }
              ]
            }}
          >
            <InfoIcon />
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          disabled={loading}
          onClick={() => onClose()}
        >
          {i18n.get('cancel')}
        </Button>
        <Button
          loading={loading}
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          {i18n.get('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SaveTemplateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default SaveTemplateDialog
