import { createAction } from 'redux-actions'

const defaultPrefix = 'DARKNET/'
let prefix = defaultPrefix

export const searchRequestStart = createAction(`${prefix}SEARCH_REQUEST_START`)
export const searchRequestError = createAction(`${prefix}SEARCH_REQUEST_ERROR`)
export const searchRequestSuccess = createAction(`${prefix}SEARCH_REQUEST_SUCCESS`)

export const scoresRequestStart = createAction(`${prefix}SCORES_REQUEST_START`)
export const scoresRequestError = createAction(`${prefix}SCORES_REQUEST_ERROR`)
export const scoresRequestSuccess = createAction(`${prefix}SCORES_REQUEST_SUCCESS`)

export const saveSearchQueryRequestError = createAction(`${prefix}SAVE_SEARCH_QUERY_REQUEST_ERROR`)
export const saveSearchQueryRequestStart = createAction(`${prefix}SAVE_SEARCH_QUERY_REQUEST_START`)
export const saveSearchQueryRequestSuccess = createAction(`${prefix}SAVE_SEARCH_QUERY_REQUEST_SUCCESS`)

export const saveScoreQueryRequestError = createAction(`${prefix}SAVE_SCORE_QUERY_REQUEST_ERROR`)
export const saveScoreQueryRequestStart = createAction(`${prefix}SAVE_SCORE_QUERY_REQUEST_START`)
export const saveScoreQueryRequestSuccess = createAction(`${prefix}SAVE_SCORE_QUERY_REQUEST_SUCCESS`)

export const fetchAllQueriesStart = createAction(`${prefix}FETCH_ALL_QUERIES_START`)
export const fetchAllQueriesSuccess = createAction(`${prefix}FETCH_ALL_QUERIES_SUCCESS`)
export const fetchAllQueriesError = createAction(`${prefix}FETCH_ALL_QUERIES_ERROR`)

export const deleteQueryRequestStart = createAction(`${prefix}DELETE_QUERY_REQUEST_START`)
export const deleteQueryRequestSuccess = createAction(`${prefix}DELETE_QUERY_REQUEST_SUCCESS`)
export const deleteQueryRequestError = createAction(`${prefix}DELETE_QUERY_REQUEST_ERROR`)

export const deactivateQueryRequestStart = createAction(`${prefix}DEACTIVATE_QUERY_REQUEST_START`)
export const deactivateQueryRequestSuccess = createAction(`${prefix}DEACTIVATE_QUERY_REQUEST_SUCCESS`)
export const deactivateQueryRequestError = createAction(`${prefix}DEACTIVATE_QUERY_REQUEST_ERROR`)

export const activateQueryRequestStart = createAction(`${prefix}ACTIVATE_QUERY_REQUEST_START`)
export const activateQueryRequestSuccess = createAction(`${prefix}ACTIVATE_QUERY_REQUEST_SUCCESS`)
export const activateQueryRequestError = createAction(`${prefix}ACTIVATE_QUERY_REQUEST_ERROR`)

export const removeFilter = createAction(`${prefix}REMOVE_FILTER`)
export const invertFilter = createAction(`${prefix}INVERT_FILTER`)
export const updateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const resetFilters = createAction(`${prefix}RESET_FILTERS`)

export const addDarknetSearchQuery = createAction(`${prefix}ADD_DARKNET_SEARCH_QUERY`)
export const chartDrilldown = createAction(`${prefix}CHART_DRILLDOWN`)
export const searchResultDrilldown = createAction(`${prefix}SEARCH_RESULT_DRILLDOWN`)

prefix = `${defaultPrefix}UI/`
export const uiApplyDateFilter = createAction(`${prefix}APPLY_DATE_FILTER`)
export const uiChooseQuickSelect = createAction(`${prefix}CHOOSE_QUICK_SELECT`)
export const uiEnteredQuery = createAction(`${prefix}ENTERED_QUERY`)
export const uiOnSortByChange = createAction(`${prefix}onSortByChange`)
export const uiOnSortDirectionChange = createAction(`${prefix}onSortDirectionChange`)
export const changeDarknetView = createAction(`${prefix}CHANGE_DARKNET_VIEW`)
export const toggleAdvancedSearch = createAction(`${prefix}TOGGLE_ADVANCED_SEARCH`)
export const uiResetFilters = createAction(`${prefix}RESET_FILTERS`)
export const uiApplyFilters = createAction(`${prefix}APPLY_FILTERS`)
export const setSelectedFilters = createAction(`${prefix}SET_SELECTED_FILTERS`)
export const addSelectedFilter = createAction(`${prefix}ADD_SELECTED_FILTER`)
export const uiRemoveFilter = createAction(`${prefix}REMOVE_FILTER`)
export const uiInvertFilter = createAction(`${prefix}INVERT_FILTER`)
export const uiUpdateFilter = createAction(`${prefix}UPDATE_FILTER`)

export const openScoreQueryDialog = createAction(`${prefix}OPEN_SCORE_QUERY_DIALOG`)
export const openSearchQueryDialog = createAction(`${prefix}OPEN_SEARCH_QUERY_DIALOG`)
export const closeScoreQueryDialog = createAction(`${prefix}CLOSE_SCORE_QUERY_DIALOG`)
export const closeSearchQueryDialog = createAction(`${prefix}CLOSE_SEARCH_QUERY_DIALOG`)

export const updateScoreQueryFormData = createAction(`${prefix}UPDATE_SCORE_QUERY_FORM_DATA`)
export const resetScoreQueryFormData = createAction(`${prefix}RESET_SCORE_QUERY_FORM_DATA`)

export const updateSearchQueryFormData = createAction(`${prefix}UPDATE_SEARCH_QUERY_FORM_DATA`)
export const resetSearchQueryFormData = createAction(`${prefix}RESET_SEARCH_QUERY_FORM_DATA`)
export const setSearchQueryFormTestResult = createAction(`${prefix}SET_SEARCH_QUERY_FORM_TEST_RESULT`)

prefix = `${defaultPrefix}API/`

export const onSortByChange = createAction(`${prefix}onSortByChange`)
export const onSortDirectionChange = createAction(`${prefix}onSortDirectionChange`)
export const testSearchQueryError = createAction(`${prefix}TEST_SEARCH_QUERY_ERROR`)
export const testSearchQueryStart = createAction(`${prefix}TEST_SEARCH_QUERY_START`)
export const testSearchQuerySuccess = createAction(`${prefix}TEST_SEARCH_QUERY_SUCCESS`)
export const loadMoreDarknetEntriesStart = createAction(`${prefix}LOAD_MORE_DARKNET_ENTRIES_START`)
export const loadMoreDarknetEntriesError = createAction(`${prefix}LOAD_MORE_DARKNET_ENTRIES_ERROR`)
export const loadMoreDarknetEntriesSuccess = createAction(`${prefix}LOAD_MORE_DARKNET_ENTRIES_SUCCESS`)
