import { useState, useEffect } from 'react'

import usePrevious from 'hooks/usePrevious'

export default function useSavingSuccess(saving, delay = 2000) {
  const [showSuccess, setShowSuccess] = useState(false)
  const previousSaving = usePrevious(saving)

  useEffect(() => {
    if (previousSaving === true && saving === false) {
      setShowSuccess(true)
    }

    const timeout = setTimeout(() => {
      setShowSuccess(false)
    }, delay)

    return () => clearTimeout(timeout)
  }, [previousSaving, saving, delay])

  return showSuccess
}
