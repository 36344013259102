import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Box, Autocomplete, TextField } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { Button, IconButton } from 'containers/themed'
import CampaignSelection from 'containers/content_desk_new/contents/content_edit_dialog/CampaignSelection'
import ContentPreview from 'containers/content_desk_new/contents/ContentPreview'

import { mapToOptions } from 'utils/autocomplete'
import { emailToOtsPlainText } from 'utils/content_desk'

const useStyles = makeStyles()({
  container: {
    padding: '40px 20px 0px 20px'
  },
  campaignSelectionContainer: {
    margin: '20px 0px'
  },
  contentSelectionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '40px',
    margin: '20px 0px'
  },
  autocompleteContainer: {
    width: '100%'
  },
  createButton: {
    padding: 0,
    gap: '5px'
  }
})

const ChooseContent = ({
  contents,
  contentFormData,
  updateContent,
  fetchContents,
  onNavigationChange
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const device = useDevice()

  const otsBody = JSON.parse(contentFormData.get('body'))
  const contentId = otsBody.content.id
  const contentTitle = otsBody.content.title

  const otsContent = contentFormData.get('otsContent')

  const [value, setValue] = useState(otsContent ? { label: otsContent.get('title'), value: otsContent.get('id') } : null)
  const [inputValue, setInputValue] = useState('')
  const [isFirstRender, setIsFirstRender] = useState(true)
  const options = mapToOptions(contents)

  useEffect(() => {
    if (!otsContent && contentId && contentTitle) {
      const content = contents.find(c => c.get('id') === contentId)

      if (content) {
        updateContent({ otsContent: content, isDirty: !isFirstRender })
        setValue({ label: content.get('title'), value: content.get('id') })
        setIsFirstRender(false)
      }
    }
  }, [contents])

  useEffect(() => {
    if (!otsContent && contentId && contentTitle) {
      fetchContents({ title: contentTitle, type: 'email' })
    } else {
      fetchContents({ title: '', type: 'email' })
    }
  }, [])

  const handleInputChange = val => {
    if (val.length === 0 || val.length > 2) {
      fetchContents({ title: val, type: 'email' })
    }

    setInputValue(val)
  }

  const handleChange = option => {
    if (!option) {
      updateContent({ otsContent: null })
      setInputValue('')
      setValue(null)

      otsBody.content.id = null
      otsBody.content.title = ''

      updateContent({ body: JSON.stringify(otsBody) })

      return
    }

    if (option) {
      const content = option ? contents.find(c => c.get('id') === option.value) : null
      updateContent({ otsContent: content })
      setValue(option)

      if (content) {
        const optionBody = content.get('body')

        otsBody.content.id = content.get('id')
        otsBody.content.title = content.get('title')
        otsBody.text = emailToOtsPlainText(optionBody)

        updateContent({ body: JSON.stringify(otsBody) })
      }
    }
  }

  const handleNewButtonClick = () => {
    updateContent({ otsContent: null })
    setValue(null)

    otsBody.content.id = null
    otsBody.content.title = ''
    otsBody.text = ''

    updateContent({ body: JSON.stringify(otsBody) })
    onNavigationChange('ots_editor')
  }

  const renderNewButton = () => {
    if (device.get('lt-sm')) {
      return (
        <IconButton
          color="primary"
          onClick={() => handleNewButtonClick()}
          title={i18n.get('create_campaign')}
        >
          <AddIcon />
        </IconButton>
      )
    }

    return (
      <Button
        className={classes.createButton}
        color="primary"
        onClick={() => handleNewButtonClick()}
      >
        <AddIcon />
        {i18n.get('start_from_scratch')}
      </Button>
    )
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.campaignSelectionContainer}>
        <CampaignSelection />
      </Box>
      <Box className={classes.contentSelectionContainer}>
        <Box className={classes.autocompleteContainer}>
          <Autocomplete
            options={options}
            value={value}
            inputValue={inputValue}
            isOptionEqualToValue={(option, val) => option.value === val.value}
            getOptionLabel={option => option.label}
            onInputChange={(_event, newInputValue) => handleInputChange(newInputValue)}
            onChange={(_event, option) => handleChange(option)}
            renderInput={params => (
              <TextField
                {...params}
                label={i18n.get('choose_content_placeholder')}
                placeholder="-"
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          {renderNewButton()}
        </Box>
      </Box>
      <Box mb={3}>
        <ContentPreview
          content={otsContent}
        />
      </Box>
    </Box>
  )
}

ChooseContent.propTypes = {
  contents: PropTypes.instanceOf(List).isRequired,
  contentFormData: PropTypes.instanceOf(Map).isRequired,

  fetchContents: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  updateContent: PropTypes.func.isRequired
}

export default ChooseContent
