import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'
import useI18n from 'hooks/useI18n'

import { AppBar, Toolbar, CardActions } from '@mui/material'

const useStyles = makeStyles()({
  spacer: {
    flex: 1
  }
})

export default function Preview() {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <MenuIcon />

          <span className={classes.spacer} />

          <Button color="inherit">
            {`${i18n.get('button')} 01`}
          </Button>
          <Button
            color="secondary"
            variant="contained"
          >
            {`${i18n.get('button')} 02`}
          </Button>
        </Toolbar>
      </AppBar>

      <CardActions>
        <Button
          color="secondary"
        >
          {i18n.get('selected')}
        </Button>
        <Button
          color="primary"
        >
          {i18n.get('pin')}
        </Button>
      </CardActions>
    </div>
  )
}
