import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import WarningIcon from '@mui/icons-material/Warning'
import { Popover, Typography, Button } from '@mui/material'
import { red } from '@mui/material/colors'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  popover: {
    padding: theme.spacing(2)
  },
  button: {
    color: red[500]
  },
  hintWrapper: {
    marginTop: '25px',
    marginBottom: '25px'
  }
}))

export default function RestrictionHint({
  mediaReviewCodes,
  channels,
  countries,
  hasCodeRestriction,
  hasChannelRestriction,
  hasCountryRestriction
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const { classes } = useStyles()
  const i18n = useI18n()

  if (!(hasCodeRestriction || hasChannelRestriction || hasCountryRestriction)) {
    return null
  }

  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <div className={classes.hintWrapper}>
      <Button
        onClick={handleClick}
        classes={{ root: classes.button }}
      >
        <WarningIcon />&nbsp;{i18n.get('restriction_hint')}
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.popover }}
      >
        <Typography variant="h6">
          {i18n.get('restriction_hint_headline')}
        </Typography>

        <br />

        {hasCodeRestriction && (
          <>
            <Typography variant="subtitle2">
              {i18n.get('media_review_codes')}:
            </Typography>
            {mediaReviewCodes.map(c => (
              <div key={c.get('id')}>
                {c.get('name')}
              </div>
            ))}
          </>
        )}

        {hasChannelRestriction && (
          <>
            <br />
            <Typography variant="subtitle2">
              {i18n.get('channels')}:
            </Typography>
            {channels.map(c => (
              <div key={c.get('id')}>
                {c.get('name')}
              </div>
            ))}
          </>
        )}

        {hasCountryRestriction && (
          <>
            <br />
            <Typography variant="subtitle2">
              {i18n.get('countries')}:
            </Typography>
            {countries.map(c => (
              <div key={c.get('id')}>
                {c.get('name')}
              </div>
            ))}
          </>
        )}
      </Popover>
    </div>
  )
}

RestrictionHint.propTypes = {
  mediaReviewCodes: PropTypes.instanceOf(List).isRequired,
  channels: PropTypes.instanceOf(List).isRequired,
  countries: PropTypes.instanceOf(List).isRequired,
  hasCodeRestriction: PropTypes.bool.isRequired,
  hasChannelRestriction: PropTypes.bool.isRequired,
  hasCountryRestriction: PropTypes.bool.isRequired
}
