import { connect } from 'react-redux'

import Summary from 'components/media_reviews/media_review_detail/summary'

import { getMediaReviewSummary } from 'selectors'

const mapStateToProps = state => ({
  summary: getMediaReviewSummary(state)
})

export default connect(mapStateToProps)(Summary)
