import { connect } from 'react-redux'

import MailingList from 'components/administration/mailing_lists/mailing_list'

import {
  showMailingListDialog,
  showRemoveMailingListDialog
} from 'actions/mailing_lists'

const mapStateToProps = (state, { mailingList }) => ({
  mailingList
})

export default connect(
  mapStateToProps,
  {
    onEditClick: showMailingListDialog,
    onRemoveClick: showRemoveMailingListDialog
  }
)(MailingList)
