import React, { useState } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'

import { TableCell, TableRow } from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  tableCell: {
    '&:hover': {
      backgroundColor: theme.isDark ? '#383838' : '#e0e0e0',
      cursor: 'pointer'
    },
    borderRadius: '5px 5px 0px 0px'
  },
  selected: {
    backgroundColor: theme.isDark ? '#383838' : '#e0e0e0',
    borderRadius: '5px 5px 0px 0px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  arrowColumn: {
    width: '50px',
    paddingTop: 0,
    paddingBottom: 0
  }
}))

const defaultSortingDirections = {
  name: 'desc',
  contacts: 'desc'
}

const DistributionListsSortbar = ({ onSortBy }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [sortingDirections, setSortingDirections] = useState(
    defaultSortingDirections
  )
  const [selectedField, setSelectedField] = useState(null)

  const handleSortingClick = sortBy => {
    const sortOrder = sortingDirections[sortBy] !== 'desc' ? 'desc' : 'asc'

    setSortingDirections({ ...defaultSortingDirections, [sortBy]: sortOrder })
    setSelectedField(sortBy)
    onSortBy({ sortBy, sortOrder })
  }

  const sortTableCellIcon = () => {
    if (sortingDirections[selectedField] === 'desc') {
      return (
        <IconButton>
          <ArrowDownwardIcon
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    if (sortingDirections[selectedField] === 'asc') {
      return (
        <IconButton>
          <ArrowUpwardIcon
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    return (
      <IconButton>
        <SwapVertIcon
          onClick={() => handleSortingClick('name')}
        />
      </IconButton>
    )
  }

  return (
    <TableRow>
      <TableCell className={classes.arrowColumn}>{sortTableCellIcon()}</TableCell>
      <TableCell
        className={selectedField === 'name' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('name')}
      >
        {i18n.get('name')}
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  )
}

DistributionListsSortbar.propTypes = {
  onSortBy: PropTypes.func.isRequired
}

export default DistributionListsSortbar
