import { connect } from 'react-redux'

import DistributionListTable
  from 'components/contact_management/distribution_list_view_dialog/distribution_list_table/DistributionListTable'

import {
  getCmSelectedContact,
  getCmRequestRunning
} from 'selectors/contact_management'

import {
  removeContactFromListStart as onRemove
} from 'actions/contact_management'

const mapStateToProps = state => ({
  contact: getCmSelectedContact(state),
  loading: getCmRequestRunning(state)
})

export default connect(mapStateToProps, {
  onRemove
})(DistributionListTable)
