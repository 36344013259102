import { connect } from 'react-redux'

import Group from 'components/custom_tags/group'
import {
  showDeleteGroupDialog,
  showGroupDialog,
  showAssignCustomTagsToGroupDialog,
  setFilteredGroupId,
  filterBySelectedCustomTagGroup
} from 'actions/custom_tags'
import { getI18n, getFilteredCustomTagGroupId } from 'selectors'

const mapStateToProps = (state, { group }) => ({
  i18n: getI18n(state),
  group,
  disabled: getFilteredCustomTagGroupId(state) !== null && getFilteredCustomTagGroupId(state) !== group.get('id')
})

export default connect(
  mapStateToProps,
  {
    onDeleteClick: showDeleteGroupDialog,
    onEditClick: showGroupDialog,
    onAddCustomTagClick: showAssignCustomTagsToGroupDialog,
    onFilterClick: setFilteredGroupId,
    filterBySelectedCustomTagGroup
  }
)(Group)
