import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'

import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Avatar, CardHeader } from '@mui/material'

const useStyles = makeStyles()({
  signOut: {
    color: red[500]
  },
  validSubheader: {
    color: green[500]
  },
  invalidSubheader: {
    color: red[500],
    display: 'flex',
    alignItems: 'center',

    '& > span:first-of-type': {
      marginRight: 5
    }
  }
})

export default function ProfileInfo({ profile, onRemove }) {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [imgError, setImgError] = useState(false)

  if (!profile) {
    return null
  }

  const hasValidPhoto = !imgError && profile.get('photo')

  let avatar = (
    <Avatar />
  )

  if (hasValidPhoto) {
    avatar = <Avatar src={profile.get('photo')} />
  }

  let subheader

  if (profile.get('expiresAt')) {
    const date = moment(profile.get('expiresAt'))
    subheader = `${i18n.get('expires_at')}: ${date.format('LLL')}`

    if (date.diff(moment()) <= 432000000) {
      subheader = (
        <div className={classes.invalidSubheader}>
          <WarningIcon />
          {subheader}
        </div>
      )
    }
  }

  return (
    <>
      <CardHeader
        avatar={avatar}
        classes={{
          subheader: classes.validSubheader
        }}
        title={(
          <>
            {profile.get('username') || 'User'}&nbsp;

            <IconButton
              size="small"
              onClick={onRemove}
              title={i18n.get('remove')}
            >
              <CloseIcon classes={{ root: classes.signOut }} />
            </IconButton>
          </>
        )}
        subheader={subheader}
      />

      {hasValidPhoto && (
        <img
          referrerPolicy="no-referrer"
          style={{ display: 'none' }}
          src={profile.get('photo')}
          onError={() => setImgError(true)}
          alt=""
        />
      )}
    </>
  )
}

ProfileInfo.propTypes = {
  profile: PropTypes.instanceOf(Map),

  onRemove: PropTypes.func.isRequired
}
