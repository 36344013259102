import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { Typography, Icon } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    zIndex: 1
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column'
    }
  },
  icon: {
    fontSize: '100px !important',
    [theme.breakpoints.only('xs')]: {
      fontSize: '200px !important'
    }
  },
  background: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'none'
  },
  flash: {
    display: 'block',
    animation: `${keyframes`
            0% {
              opacity: 0.75;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.75;
            }
          `} 5s linear infinite;`
  },
  actions: {
    width: '100%',
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      fontSize: 30
    }
  },
  body: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
      fontSize: 20
    }
  }
}))

export default function CenterMessage({
  title,
  message,
  colors,
  icon,
  flash,
  actions,
  backgroundClass,
  contentClass
}) {
  const { classes, cx } = useStyles()
  const textStyle = {
    color: colors.get('accent')
  }

  let backgroundClassName = classes.background
  const backgroundStyle = {}

  if (flash) {
    backgroundClassName = `${backgroundClassName} ${classes.flash}`
    backgroundStyle.backgroundColor = colors.get('primary')
  }

  return (
    <div className={cx(classes.container, backgroundClass)}>
      <div
        className={backgroundClassName}
        style={backgroundStyle}
      />

      <div className={cx(classes.content, contentClass)}>
        <div className={classes.info}>
          <Icon
            className={classes.icon}
            style={textStyle}
          >
            {icon || 'error'}
          </Icon>

          <div>
            <Typography
              variant="h3"
              className={classes.header}
              style={textStyle}
            >
              {title}
            </Typography>
            <Typography
              variant="h4"
              className={classes.body}
              style={textStyle}
            >
              {message}
            </Typography>
          </div>
        </div>

        <div className={classes.actions}>
          {actions}
        </div>
      </div>
    </div>
  )
}

CenterMessage.defaultProps = {
  backgroundClass: '',
  flash: false,
  actions: []
}

CenterMessage.propTypes = {
  backgroundClass: PropTypes.string,
  contentClass: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  icon: PropTypes.string,
  flash: PropTypes.bool,
  actions: PropTypes.array,
  colors: PropTypes.object.isRequired
}
