/* eslint-disable max-len */
import { createImmutableSelector } from 'utils/reselect'
import { List, Map } from 'immutable'

// DATA
export const getThemaxData = state => state.themax.get('data')

export const getThemaxSavedSearches = state => getThemaxData(state).get('savedSearches')
export const getSelectedEditorialPlan = state => getThemaxData(state).get('selectedEditorialPlan')

// UI
export const getThemaxUi = state => state.themax.get('ui')
export const getThemaxQueryType = state => getThemaxUi(state).get('queryType')
export const getThemaxSearchBarLabel = state => getThemaxUi(state).get('searchBarLabel')
export const getThemaxSearchRequestRunning = state => getThemaxUi(state).get('searchRequestRunning')
export const getThemaxTabView = state => getThemaxUi(state).get('tabView')
export const getThemaxShowAdvancedSearch = state => getThemaxUi(state).get('showAdvancedSearch')
export const getThemaxShowDateForm = state => getThemaxUi(state).get('showDateForm')
export const getActiveThemaxFiltersCollapsed = state => getThemaxUi(state).get('activeFiltersCollapsed')
export const getThemaxSavedSearchesRequestRunning = state => getThemaxUi(state).get('requestRunning')
export const getThemaxModuleInitialized = state => getThemaxUi(state).get('moduleInitialized')
export const getThemaxShowDetailedViewDialog = state => getThemaxUi(state).get('showDetailedViewDialog')
export const getThemaxFetchMoreRequestRunning = state => getThemaxUi(state).get('fetchMoreRequestRunning')
export const getThemaxBookmarkRequestRunningFor = state => getThemaxUi(state).get('bookmarkRequestRunningFor')
export const getThemaxExcelExportRequestRunning = state => getThemaxUi(state).get('excelExportRequestRunning')
export const getShowWelcomeDialog = state => getThemaxUi(state).get('showWelcomeDialog')
export const getShowOrderDialog = state => getThemaxUi(state).get('showOrderDialog')
export const getShowOrderSuccessDialog = state => getThemaxUi(state).get('showOrderSuccessDialog')
export const getOrderRequestRunning = state => getThemaxUi(state).get('orderRequestRunning')

// FILTERS
export const getThemaxFilters = state => state.themax.get('filter')
export const getActiveThemaxFilters = createImmutableSelector(
  getThemaxFilters,
  filters => filters.filter(value => List.isList(value))
)
export const getActiveThemaxNumberFilters = createImmutableSelector(
  getThemaxFilters,
  filters => {
    let result = Map()

    if (filters.getIn(['reachFilter', 'filtered'])) {
      const reachMin = filters.getIn(['reachFilter', 'min'])

      if (reachMin) {
        result = result.set('reachMin', reachMin)
      }

      const reachMax = filters.getIn(['reachFilter', 'max'])

      if (reachMax) {
        result = result.set('reachMax', reachMax)
      }
    }

    if (filters.getIn(['pageAveFilter', 'filtered'])) {
      const pageAveMin = filters.getIn(['pageAveFilter', 'min'])

      if (pageAveMin) {
        result = result.set('pageAveMin', pageAveMin)
      }

      const pageAveMax = filters.getIn(['pageAveFilter', 'max'])

      if (pageAveMax) {
        result = result.set('pageAveMax', pageAveMax)
      }
    }

    return result
  }
)
export const getActiveSelectedThemaxBooleans = createImmutableSelector(
  state => {
    const booleanFilters = getThemaxFilters(state).get('booleans')

    if (getThemaxTabView(state) === 'calendar') {
      return booleanFilters.delete('bookmarkedOnly')
    }

    return booleanFilters
  },
  booleans => booleans
)

export const getThemaxSelectedDate = state => getThemaxFilters(state).get('selectedDate')
export const getThemaxEditorialPlansSortBy = state => getThemaxFilters(state).getIn(['editorialPlansSorting', 'sortBy'])
export const getThemaxEditorialPlansSortOrder = state => getThemaxFilters(state).getIn(['editorialPlansSorting', 'sortOrder'])
export const getThemaxPublicationsSortBy = state => getThemaxFilters(state).getIn(['publicationsSorting', 'sortBy'])
export const getThemaxPublicationsSortOrder = state => getThemaxFilters(state).getIn(['publicationsSorting', 'sortOrder'])

const queryFilters = queries => ({
  queries: queries.filter(tp => !tp.get('inverted')).map(q => ({ field: q.get('field'), value: q.get('value') })).toJS(),
  exclude_queries: queries.filter(tp => tp.get('inverted')).map(q => ({ field: q.get('field'), value: q.get('value') })).toJS()
})

export const getThemaxEditorialPlansFilter = createImmutableSelector(
  getThemaxFilters,
  filters => ({
    bookmarked: filters.getIn(['booleans', 'bookmarkedOnly']),
    editorial_deadline_range: filters.getIn(['selectedDate', 'range']),
    editorial_deadline_from: filters.getIn(['selectedDate', 'from']),
    editorial_deadline_to: filters.getIn(['selectedDate', 'to']),
    ...queryFilters(filters.get('topicPlanQueries'))
  })
)

export const getThemaxPublicationsFilter = createImmutableSelector(
  getThemaxFilters,
  filters => {
    const result = {}

    if (filters.get('mediaTypes').size > 0) {
      result.media_type_ids = filters.get('mediaTypes')
        .filter(mt => !mt.get('inverted'))
        .map(mt => mt.get('id')).toJS()

      result.exclude_media_type_ids = filters.get('mediaTypes')
        .filter(mt => mt.get('inverted'))
        .map(mt => mt.get('id')).toJS()
    }

    if (filters.get('mediaTopics').size > 0) {
      result.media_topic_ids = filters.get('mediaTopics')
        .filter(mt => !mt.get('inverted'))
        .map(mt => mt.get('id')).toJS()

      result.exclude_media_topic_ids = filters.get('mediaTopics')
        .filter(mt => mt.get('inverted'))
        .map(mt => mt.get('id')).toJS()
    }

    if (filters.get('countries').size > 0) {
      result.country_ids = filters.get('countries')
        .filter(c => !c.get('inverted'))
        .map(c => c.get('id')).toJS()

      result.exclude_country_ids = filters.get('countries')
        .filter(c => c.get('inverted'))
        .map(c => c.get('id')).toJS()
    }

    if (filters.getIn(['pageAveFilter', 'filtered'])) {
      const pageAveMin = filters.getIn(['pageAveFilter', 'min'])

      if (pageAveMin) {
        result.page_ave_min = pageAveMin
      }

      const pageAveMax = filters.getIn(['pageAveFilter', 'max'])

      if (pageAveMax) {
        result.page_ave_max = pageAveMax
      }
    }

    if (filters.getIn(['reachFilter', 'filtered'])) {
      const reachMin = filters.getIn(['reachFilter', 'min'])

      if (reachMin) {
        result.reach_min = reachMin
      }

      const reachMax = filters.getIn(['reachFilter', 'max'])

      if (reachMax) {
        result.reach_max = reachMax
      }
    }

    if (filters.get('publication').size > 0) {
      result.ids = filters.get('publication')
        .filter(p => !p.get('inverted'))
        .map(p => p.get('id')).toJS()

      result.exclude_ids = filters.get('publication')
        .filter(p => p.get('inverted'))
        .map(p => p.get('id')).toJS()
    }

    return { ...result, ...queryFilters(filters.get('publicationQueries')) }
  }
)
