import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_searches'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  removeActive: false,
  affectedDashboardsActive: false,
  updateActive: false,
  editActive: false,
  dispatchesActive: false,
  uploadedPhoto: null,
  affectedDashboards: []
})

export default handleActions({
  [Actions.showEditSavedSearchDialog]: state => state.merge({
    editActive: true,
    uploadedPhoto: null
  }),
  [Actions.hideEditSavedSearchDialog]: state => state.set('editActive', false),

  [Actions.saveSavedSearchSuccess]: state => state.set('editActive', false),
  [Actions.deleteSavedSearchSuccess]: state => state.merge(fromJS({
    removeActive: false,
    affectedDashboards: []
  })),

  [Actions.showRemoveSavedSearchDialog]: state => state.set('removeActive', true),
  [Actions.hideRemoveSavedSearchDialog]: state => state.set('removeActive', false),

  [Actions.showAffectedDashboardsDialog]: state => state.set('affectedDashboardsActive', true),
  [Actions.hideAffectedDashboardsDialog]: state => state.set('affectedDashboardsActive', false),

  [Actions.showUpdateSavedSearchDialog]: state => state.set('updateActive', true),
  [Actions.hideUpdateSavedSearchDialog]: state => state.set('updateActive', false),

  [Actions.showSavedSearchDispatchesDialog]: state => state.set('dispatchesActive', true),
  [Actions.hideSavedSearchDispatchesDialog]: state => state.set('dispatchesActive', false),

  [Actions.setUploadedPhoto]: (state, { payload: data }) => state.set('uploadedPhoto', data),

  [Actions.setAffectedDashboards]: (state, { payload: data }) => state.set('affectedDashboards', data),

  [AppActions.resetState]: () => initialState
}, initialState)
