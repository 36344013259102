import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import MultiFilters from 'components/multi_filters'
import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()({
  container: {
    marginBottom: '1em'
  }
})

function ContentsFilters({
  currentFilters,
  loading,
  locale,
  contentDeskContentTypes,
  searchContents,
  setContentFilters,
  setPage
}) {
  const { classes } = useStyles()
  const contentDeskContentTypesOptions = mapToOptions(contentDeskContentTypes)

  const fieldOptions = [
    {
      field: 'title',
      name: 'title',
      label: 'name',
      type: 'text'
    },
    {
      field: 'type',
      name: 'type',
      type: 'enum',
      values: contentDeskContentTypesOptions
    },
    {
      field: 'approval_status',
      name: 'approval_status',
      label: 'editing_status',
      type: 'enum',
      values: [
        { value: 'draft', label: 'draft' },
        { value: 'approved', label: 'approved' },
        { value: 'revisions_needed', label: 'revisions_needed' },
        { value: 'review_pending', label: 'review_pending' }
      ]
    },
    {
      field: 'sending_status',
      name: 'sending_status',
      label: 'sending_status',
      type: 'enum',
      values: [
        { value: 'scheduled', label: 'scheduled' },
        { value: 'released', label: 'released' },
        { value: 'failed', label: 'failed' }
      ]
    },
    {
      field: 'planned_for',
      name: 'planned_for',
      type: 'date'
    },
    {
      field: 'scheduled_for',
      name: 'scheduled_for',
      type: 'date'
    },
    {
      field: 'created_by_full_name',
      name: 'created_by_full_name',
      label: 'created_by',
      type: 'text'
    },
    {
      field: 'updated_by_full_name',
      name: 'updated_by_full_name',
      label: 'last_edited_by',
      type: 'text'
    }
  ]

  const handleOnApply = (operator, conditions) => {
    setPage({ key: 'contentPage', value: 1 })
    setContentFilters({ operator, conditions })
    searchContents()
  }

  return (
    <div className={classes.container}>
      <MultiFilters
        currentFilters={currentFilters.toJS()}
        fieldOptions={fieldOptions}
        loading={loading}
        locale={locale}
        onApply={handleOnApply}
        position="bottom-end"
      />
    </div>
  )
}

ContentsFilters.propTypes = {
  currentFilters: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,

  searchContents: PropTypes.func.isRequired,
  setContentFilters: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired
}

export default ContentsFilters
