import { delay, call, select } from 'redux-saga/effects'
import { fromJS } from 'immutable'

import { uploadGeneratedFile } from 'sagas/app'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import download from 'utils/download'

const defaultOptions = {
  retryCount: 5,
  retryFun: () => true
}

export function* retriable(saga, failSaga, action, opts = {}) {
  const options = { ...defaultOptions, ...(opts || {}) }
  const { retryCount, retryFun } = options

  for (let i = 0; i <= retryCount; i += 1) {
    try {
      return yield* saga(action)
    } catch (error) {
      if (retryFun(error)) {
        if (i < retryCount) {
          yield delay(1000 * (i + 1))
        } else {
          return yield* failSaga(error, action)
        }
      } else {
        return yield* failSaga(error, action)
      }
    }
  }

  return null
}

export function* uploadFile(data, filename = null, mimeType = null, keepUntil = null) {
  let result = {
    url: null
  }

  if (data) {
    const formData = [
      {
        key: 'file',
        value: data,
        filename: filename || undefined
      }
    ]

    if (keepUntil) {
      formData.push({
        key: 'keep_until',
        value: keepUntil
      })
    }

    if (mimeType) {
      formData.push({
        key: 'mime_type',
        value: mimeType
      })
    }

    result = yield call(Api.uploadFile, formData)
  }

  return result
}

export function* uploadPhoto(data) {
  const { url } = yield* uploadFile(data)

  return url
}

export function* downloadBlob(blob, filename, mimeType) {
  const device = yield select(Selectors.getDevice)

  if (!(device.get('ios') || device.get('ie'))) {
    yield call(download, blob, filename, mimeType)
  } else {
    yield call(uploadGeneratedFile, blob, filename, mimeType)
  }
}

export function* getCluster(news, fun = Api.search) {
  if (news) {
    const groups = yield select(Selectors.getRawNewsGroups)
    const groupId = groups.find(g => g.get('news').includes(news.get('id'))).get('id')

    if (groupId) {
      const body = yield select(Selectors.getFetchClusterStatsBody, groupId, news.get('id'), news.get('clusterId'))
      let result = yield call(fun, body)

      if (result.groups !== undefined) {
        result = fromJS((result.groups.find(g => g.id === groupId) || { news: [] }).news)
      }

      return fromJS(result)
    }

    return fromJS([])
  }

  return fromJS([])
}
