import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'
import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Button, RaisedPrimaryButton, IconButton } from 'containers/themed'
import CloseIcon from '@mui/icons-material/Close'
import TagChip from 'components/contact_management/tag_chip'

export default function TagDeleteDialog({ tag, open, running, onConfirm, onCancel }) {
  const i18n = useI18n()
  const [selectedTag, setSelectedTag] = useState(tag.toJS())

  useEffect(() => {
    setSelectedTag(tag.toJS())
  }, [tag])

  const handleClose = () => onCancel()
  const handleConfirm = () => onConfirm({ id: selectedTag.id })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(3),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('are_you_sure')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: t => t.spacing(3) }}
      >
        <TagChip
          label={selectedTag.name}
        />
        <Typography
          mt={2}
          variant="body2"
        >{i18n.get('delete_tags_text')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Box
          m={2}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <Button
            onClick={handleClose}
          >
            {i18n.get('close')}
          </Button>
          <RaisedPrimaryButton
            disabled={running}
            onClick={handleConfirm}
            loading={running}
          >
            {i18n.get('delete')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

TagDeleteDialog.propTypes = {
  tag: PropTypes.instanceOf(ImmutableMap).isRequired,
  open: PropTypes.bool.isRequired,
  running: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
