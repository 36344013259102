import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/personal_settings'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  previewUrl: '',
  uploadedPhoto: null
})

export default handleActions({
  [Actions.uiSetPreviewUrl]: (state, { payload }) => state.set('previewUrl', payload),
  [Actions.uiSetUserImage]: (state, { payload }) => state.set('uploadedPhoto', payload),
  [AppActions.resetState]: () => initialState
}, initialState)
