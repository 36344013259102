import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/saved_charts'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  saving: false,
  showDialog: false
})

export default handleActions({
  [Actions.updateSavedChartStart]: state => state.set('saving', true),
  [Actions.updateSavedChartSuccess]: state => state.set('saving', false),
  [Actions.updateSavedChartError]: state => state.set('saving', false),

  [Actions.showDialog]: state => state.set('showDialog', true),
  [Actions.hideDialog]: state => state.set('showDialog', false),

  [AppActions.resetState]: () => initialState
}, initialState)
