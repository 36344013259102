// DATA
export const getUnsplashData = state => state.unsplash.get('data')
export const getUnsplashFormData = state => getUnsplashData(state)
export const getUnsplashPhotos = state => getUnsplashData(state).get('photos')

// UI
export const getUnsplashUi = state => state.unsplash.get('ui')
export const getShowUnsplashComponent = state => getUnsplashUi(state).get('showUnsplashComponent')
export const getUnsplashRequestRunning = state => getUnsplashUi(state).get('requestRunning')
export const getUnsplashDownloadRequestRunning = state => getUnsplashUi(state).get('downloadRequestRunning')
export const getUnsplashSelectedImageId = state => getUnsplashUi(state).get('selectedImageId')
export const getIsRandomSearch = state => getUnsplashUi(state).get('isRandomSearch')

// FILTER
export const getUnsplashFilter = state => state.unsplash.get('filter')
export const getUnsplashPage = state => getUnsplashFilter(state).get('page')
export const getUnsplashPerPage = state => getUnsplashFilter(state).get('perPage')
export const getUnsplashOrderBy = state => getUnsplashFilter(state).get('orderBy')
