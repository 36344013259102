import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { Select, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  sorting: {
    display: 'flex',
    alignItems: 'center',
    '& > div:first-of-type': {
      minWidth: 200,
      [theme.breakpoints.only('xs')]: {
        minWidth: '30vw'
      }
    },
    '& > *:last-child': {
      top: 10
    }
  }
}))

export default function Sorting({
  sortBy,
  sortOrder,
  sortType,
  onSortChange
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const options = [
    { value: 'reach', label: i18n.get('reach') }
  ]

  if (sortType === 'editorialPlansSorting') {
    options.push({
      value: 'editorial_deadline',
      label: i18n.get('editorial_deadline')
    })
  } else {
    options.push({ value: 'name', label: i18n.get('name') })
  }

  const selected = options.find(opt => {
    if (sortBy === 'reach' && opt.value === 'reach') {
      return true
    }

    if (sortBy === 'name' && opt.value === 'name') {
      return true
    }

    if (sortBy === 'editorial_deadline' && opt.value === 'editorial_deadline') {
      return true
    }

    return false
  })

  const onSortByChange = value => {
    onSortChange({ sortBy: value, sortOrder, sortType })
  }

  if (!selected) {
    onSortByChange(options[0].value)

    return null
  }

  const onSortOrderChange = () => {
    onSortChange({
      sortBy,
      sortOrder: sortOrder === 'asc' ? 'desc' : 'asc',
      sortType
    })
  }

  return (
    <div className={classes.sorting}>
      <Select
        displayEmpty
        label={i18n.get('sort_by')}
        options={options}
        onChange={value => onSortByChange(value.value)}
        value={selected.value}
      />
      <IconButton
        title={i18n.get(sortOrder === 'desc' ? 'descending' : 'ascending')}
        onClick={() => onSortOrderChange()}
        size="large"
      >
        {sortOrder === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
      </IconButton>
    </div>
  )
}

Sorting.propTypes = {
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  sortType: PropTypes.string,
  onSortChange: PropTypes.func.isRequired
}
