import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { isValidFilename } from 'utils/filename'

import * as ExportActions from 'actions/export'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  coverIsGenerating: false,
  coverPreviewUrl: null,
  currentDocument: null,
  htmlPreviewIsGenerating: false,
  htmlPreviewUrl: null,
  isRunning: false,
  isValidFilename: true,
  selectedFormat: 'pdf',
  sendingMail: false,
  showDocumentDownloadDialog: false,
  showExportDialog: false,
  showSendMailDialog: false,
  txtPreviewIsGenerating: false,
  txtPreviewUrl: null,
  xmlPreviewIsGenerating: false,
  xmlPreviewUrl: null,
  jsonPreviewIsGenerating: false,
  jsonPreviewUrl: null,
  validatingSpf: false,
  validSpf: true
})

export default handleActions({
  [ExportActions.exportStart]: state => state.merge({
    isRunning: true,
    showDocumentDownloadDialog: true,
    currentDocument: null
  }),
  [ExportActions.exportStructuredFormatStart]: state => state.merge({
    isRunning: true,
    showDocumentDownloadDialog: true,
    currentDocument: null
  }),
  [ExportActions.exportBackwardsNewsHtmlStart]: state => state.merge({
    isRunning: true,
    showDocumentDownloadDialog: true,
    currentDocument: null
  }),
  [ExportActions.exportSuccess]: (state, { payload: { url, filename, mimeType, size, attachments } }) => state.merge(fromJS({
    isRunning: false,
    showDocumentDownloadDialog: true,
    currentDocument: {
      attachments: attachments || [],
      url,
      filename,
      size,
      mimeType
    }
  })),
  [ExportActions.exportError]: state => state.merge({
    isRunning: false,
    showDocumentDownloadDialog: false,
    currentDocument: null
  }),
  [ExportActions.sendMail]: state => state.merge({
    sendingMail: true
  }),
  [combineActions(ExportActions.sendMailSuccess, ExportActions.sendMailError)]: state => state.merge({
    sendingMail: false
  }),
  [ExportActions.exportCoverStart]: state => state.set('coverIsGenerating', true),
  [ExportActions.exportCoverSuccess]: (state, { payload: { url } }) => state.merge({
    coverPreviewUrl: url,
    coverIsGenerating: false
  }),
  [ExportActions.exportCoverError]: state => state.set('coverIsGenerating', false),

  [ExportActions.exportHtmlPreviewError]: state => state.set('htmlPreviewIsGenerating', false),
  [ExportActions.exportHtmlPreviewStart]: state => state.set('htmlPreviewIsGenerating', true),
  [ExportActions.exportHtmlPreviewSuccess]: (state, { payload: { url } }) => state.merge({
    htmlPreviewUrl: url,
    htmlPreviewIsGenerating: false
  }),

  [ExportActions.exportTxtPreviewError]: state => state.set('txtPreviewIsGenerating', false),
  [ExportActions.exportTxtPreviewStart]: state => state.set('txtPreviewIsGenerating', true),
  [ExportActions.exportTxtPreviewSuccess]: (state, { payload: { url } }) => state.merge({
    txtPreviewUrl: url,
    txtPreviewIsGenerating: false
  }),

  [ExportActions.exportXmlPreviewError]: state => state.set('xmlPreviewIsGenerating', false),
  [ExportActions.exportXmlPreviewStart]: state => state.set('xmlPreviewIsGenerating', true),
  [ExportActions.exportXmlPreviewSuccess]: (state, { payload: { url } }) => state.merge({
    xmlPreviewUrl: url,
    xmlPreviewIsGenerating: false
  }),

  [ExportActions.exportJsonPreviewError]: state => state.set('jsonPreviewIsGenerating', false),
  [ExportActions.exportJsonPreviewStart]: state => state.set('jsonPreviewIsGenerating', true),
  [ExportActions.exportJsonPreviewSuccess]: (state, { payload: { url } }) => state.merge({
    jsonPreviewUrl: url,
    jsonPreviewIsGenerating: false
  }),

  [ExportActions.changeExportFormatSelection]: (state, action) => state.set('selectedFormat', action.payload),
  [ExportActions.hideDocumentDownloadDialog]: state => state.set('showDocumentDownloadDialog', false),
  [ExportActions.showExportDialog]: (state, { payload }) => state.merge({
    coverPreviewUrl: null,
    htmlPreviewUrl: null,
    txtPreviewUrl: null,
    xmlPreviewUrl: null,
    jsonPreviewUrl: null,
    showExportDialog: true,
    selectedFormat: payload || state.get('selectedFormat')
  }),
  [ExportActions.showSendMailDialog]: state => state.merge({
    showSendMailDialog: true
  }),
  [ExportActions.hideSendMailDialog]: state => state.merge({
    showSendMailDialog: false
  }),
  [ExportActions.hideExportDialog]: state => state.merge({
    coverPreviewUrl: null,
    htmlPreviewUrl: null,
    txtPreviewUrl: null,
    xmlPreviewUrl: null,
    jsonPreviewUrl: null,
    showExportDialog: false
  }),
  [ExportActions.validateFilenameInExportSettings]: (state, action) => (
    state.set('isValidFilename', isValidFilename(action.payload))
  ),

  [ExportActions.validateSpfStart]: state => state.set('validSpf', true),
  [ExportActions.validateSpfStarted]: state => state.set('validatingSpf', true),
  [ExportActions.validateSpfError]: state => state.set('validatingSpf', false),
  [ExportActions.validateSpfSuccess]: (state, { payload: { status } }) => state.merge({
    validatingSpf: false,
    validSpf: status === 'ok'
  }),

  [ExportActions.resetExportUiState]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
