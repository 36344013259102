import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { makeStyles } from 'tss-react/mui'
import GroupedNewsSublist from 'containers/media_reviews/media_review_detail/GroupedNewsSublist'
import { Card, ListItem, ListItemText, Badge, Checkbox } from '@mui/material'
import { blue } from '@mui/material/colors'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  item: {
    padding: '0'
  },
  itemContent: {
    width: '100%'
  },
  listItemText: {
    margin: 0
  },
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.isDark ? theme.nrx.paper.backgroundPaperColor : theme.palette.grey[300],
    height: '50px',
    paddingLeft: 35,

    '& > div:first-of-type': {
      display: 'flex',
      alignItems: 'center',
      height: '100%'
    }
  },
  groupLabel: {
    fontWeight: 400
  },
  groupName: {
    paddingLeft: 5,
    fontWeight: '500'
  },
  news: {
    padding: '12px 20px',
    backgroundColor: theme.isDark ? theme.nrx.paper.backgroundPaperColor : theme.palette.grey[300],
    borderTop: `1px solid ${theme.palette.grey[500]}`
  },
  card: {
    marginBottom: '20px'
  },
  actions: {
    top: '50%',
    display: 'flex',
    alignItems: 'center',
    fontSize: 'large',
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    height: '80%'
  },
  badgeStyle: {
    backgroundColor: blue[900],
    color: theme.palette.common.white
  }
}))

export default function GroupedNewsListItem({
  code,
  expanded,
  i18n,
  newsCount,
  collapseTopics,
  expandTopics,
  selected,
  selectedCount,
  newsClusterIds,
  indeterminate,
  onSelect
}) {
  const { classes } = useStyles()
  const clickHandler = expanded ? collapseTopics : expandTopics

  return (
    <Card className={classes.card}>
      <ListItem classes={{ root: classes.item }}>
        <ListItemText classes={{ root: classes.listItemText }}>
          <div className={classes.itemContent}>
            <div className={classes.group}>
              <div>
                <Checkbox
                  onChange={() => onSelect({
                    codeId: code.get('id'),
                    newsIds: newsClusterIds,
                    omitIntersection: !selected
                  })}
                  checked={selected}
                  indeterminate={indeterminate}
                />
                <span className={classes.groupLabel}>
                  {i18n.get('topic').toUpperCase()}:
                </span>
                <span className={classes.groupName}>{code.get('id') ? code.get('name') : i18n.get('ungrouped')}</span>
                <Badge
                  badgeContent={selectedCount || newsCount}
                  classes={{ badge: classes.badgeStyle }}
                  style={{ marginLeft: '20px' }}
                  max={9999999}
                />
              </div>

              <div className={classes.actions}>
                <IconButton
                  onClick={() => clickHandler([code.get('id')])}
                  size="large"
                >
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </div>

            {expanded && (
              <div className={classes.news}>
                <GroupedNewsSublist
                  news={code.get('news')}
                  codeId={code.get('id')}
                />
              </div>
            )}
          </div>
        </ListItemText>
      </ListItem>
    </Card>
  )
}

GroupedNewsListItem.propTypes = {
  expanded: PropTypes.bool.isRequired,
  code: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  newsCount: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  selectedCount: PropTypes.number.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  newsClusterIds: PropTypes.instanceOf(List).isRequired,

  expandTopics: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  collapseTopics: PropTypes.func.isRequired
}
