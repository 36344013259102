import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px'
  },
  title: {
    color: !theme.isDark && '#404040'
  }
}))

const EmailExistingDialog = ({ open, onClose }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle className={classes.title}>{i18n.get('email_existing')}</DialogTitle>
      <DialogActions>
        <Button
          className={classes.button}
          variant="contained"
          onClick={onClose}
        >{i18n.get('back_title')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EmailExistingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default EmailExistingDialog
