/* global window */
import React, { forwardRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useBeforeUnload from 'hooks/useBeforeUnload'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Alert,
  AppBar,
  Box,
  Dialog,
  LinearProgress,
  Slide,
  Toolbar as MuiToolbar,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import ScheduleDialog from 'containers/content_desk_new/contents/content_edit_dialog/ScheduleDialog'
import TestMailDialog from 'containers/content_desk_new/contents/content_edit_dialog/TestMailDialog'
import Toolbar from 'containers/content_desk_new/contents/content_edit_dialog/Toolbar'
import CloseConfirmDialog from 'containers/content_desk_new/contents/content_edit_dialog/CloseConfirmDialog'
import RecipientsSelection from 'containers/content_desk_new/contents/content_edit_dialog/RecipientsSelection'
import Overview from 'containers/content_desk_new/contents/content_edit_dialog/Overview'
import RecipientsBrowser from 'containers/content_desk_new/contents/content_edit_dialog/RecipientsBrowser'

import { IconButton } from 'containers/themed'

import Navigation from 'containers/content_desk_new/contents/content_edit_dialog/Navigation'
import Settings from 'containers/content_desk_new/contents/content_edit_dialog/Settings'
import Templates from 'containers/content_desk_new/contents/content_edit_dialog/Templates'
import Editor from 'containers/content_desk_new/contents/content_edit_dialog/Editor'
import Attachments from 'containers/content_desk_new/contents/content_edit_dialog/Attachments'
import ChooseContent from 'containers/content_desk_new/contents/content_edit_dialog/ChooseContent'
import OtsEditor from 'containers/content_desk_new/contents/content_edit_dialog/OtsEditor'

const useStyles = makeStyles()(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  innerContainer: {
    borderRadius: 20,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.background.paper,
    margin: '20px 20px 80px 20px',
    width: '70%',
    minHeight: '80vh',
    [theme.breakpoints.only('xs')]: {
      margin: '20px 20px 100px 20px'
    }
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '30px 60px',
    width: '100%'
  },
  ContentContainer: {
    padding: '0px 60px'
  },
  overlay: {
    position: 'fixed',
    top: 69,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#D3D3D3',
    opacity: '.60',
    zIndex: 9999999
  },
  linearProgress: {
    width: '100vw',
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 0
  }
}))

const TransitionComponent = forwardRef((props, ref) => (
  <Slide
    direction="up"
    ref={ref}
    {...props}
  />
))

TransitionComponent.displayName = 'Transition'

export default function ContentEditDialog({
  contentFormData,
  duplicateError,
  isInCloneMode,
  isLoading,
  open,
  tabView,
  onClose,
  onRelock,
  onUnlock
}) {
  const i18n = useI18n()
  const { classes } = useStyles()
  // const { enqueueSnackbar } = useSnackbar()
  const [confDialogOpen, setConfDialogOpen] = useState(false)
  const contentFormType = contentFormData.getIn(['type', 'id'])

  useEffect(() => { // eslint-disable-line
    if (open) {
      const interval = setInterval(() => onRelock(), 30000)

      return () => clearInterval(interval)
    }
  }, [open])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [tabView])

  useBeforeUnload(e => {
    e.preventDefault()
    onUnlock()
  }, open)

  const handleClose = () => {
    if (contentFormData.get('isDirty')) {
      setConfDialogOpen(true)
    } else {
      onClose({ closeEditContentDialog: true })
    }
  }

  let dialogTitle = i18n.get('edit_content')

  if (contentFormType === 'email') {
    dialogTitle = i18n.get('create_email_content')
  }

  if (isInCloneMode) {
    dialogTitle = i18n.get('duplicate_content')
  }

  const renderCloseConfirmDialog = () => {
    if (!contentFormData.get('isDirty')) {
      return null
    }

    return (
      <CloseConfirmDialog
        open={confDialogOpen}
        onClose={() => setConfDialogOpen(false)}
      />
    )
  }

  const renderDuplicateError = () => {
    if (!duplicateError) {
      return null
    }

    return (
      <Alert
        severity="warning"
        className={classes.warning}
      >
        {i18n.get('ccd_content_duplicate_error')}
      </Alert>
    )
  }

  return (
    <>
      {renderCloseConfirmDialog()}
      <RecipientsBrowser />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={TransitionComponent}
        className={classes.dialog}
      >
        <AppBar position="sticky">
          <MuiToolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              disabled={isLoading}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant="h6"
            >
              {dialogTitle}
            </Typography>
          </MuiToolbar>
          {isLoading && <LinearProgress className={classes.linearProgress} />}
        </AppBar>
        {isLoading && <div className={classes.overlay} />}
        <Box className={classes.outerContainer}>
          <Box className={classes.innerContainer}>
            <Box className={classes.navigationContainer}>
              <Navigation />
            </Box>
            <Box className={classes.ContentContainer}>
              {renderDuplicateError()}
              {tabView === 'settings' && <Settings />}
              {tabView === 'recipients' && <RecipientsSelection />}
              {tabView === 'templates' && <Templates />}
              <div style={{ display: tabView === 'editor' ? 'block' : 'none' }}>
                <Editor />
              </div>
              {tabView === 'attachments' && <Attachments />}
              {tabView === 'overview' && <Overview />}
              {tabView === 'choose_content' && <ChooseContent />}
              {tabView === 'ots_editor' && <OtsEditor />}
            </Box>
          </Box>
        </Box>
        <Toolbar />
      </Dialog>
      <ScheduleDialog />
      {contentFormType === 'email' && <TestMailDialog />}
    </>
  )
}

ContentEditDialog.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  duplicateError: PropTypes.bool.isRequired,
  isInCloneMode: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  tabView: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onRelock: PropTypes.func.isRequired,
  onUnlock: PropTypes.func.isRequired
}
