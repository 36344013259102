import React from 'react'
import { StaticDatePicker as MuiStaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment from 'moment'

import { handleProps } from '../utils'

export default function StaticDatePicker(props) {
  const newProps = handleProps(props)

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      dateLibInstance={moment}
    >
      <MuiStaticDatePicker
        autoOk
        {...newProps}
      />
    </LocalizationProvider>
  )
}
