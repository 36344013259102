import { connect } from 'react-redux'

import Calendar from 'components/themax/calendar'

import {
  changeDate,
  applyThemaxFilters,
  setThemaxSort,
  setThemaxLimit,
  setThemaxPage,
  openDetailedViewDialog,
  setSelectedEditorialPlan
} from 'actions/themax'

import {
  getThemaxData,
  getThemaxFilters,
  getThemaxSelectedDate,
  getThemaxSearchRequestRunning
} from 'selectors'

const mapStateToProps = state => ({
  editorialPlans: getThemaxData(state).getIn(['editorialPlans', 'items']),
  bookmarkedOnly: getThemaxFilters(state).getIn(['booleans', 'bookmarkedOnly']),
  selectedDate: getThemaxSelectedDate(state),
  loading: getThemaxSearchRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    changeDate,
    setSorting: setThemaxSort,
    setLimit: setThemaxLimit,
    setPage: setThemaxPage,
    applyFilters: applyThemaxFilters,
    openDetailedViewDialog,
    setSelectedEditorialPlan
  }
)(Calendar)
