import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'

import * as NewsActions from 'actions/news'
import * as PublicationsActions from 'actions/publications'
import * as AppActions from 'actions/app'

const defaultSelectedNews = fromJS({
  articleDate: new Date(),
  mediareviewDate: moment().startOf('day').hours(12).toDate(),
  codes: [],
  customTags: [],
  images: [],
  clusterSize: 1,
  clusteredNews: [],
  imageAsIllustration: false,
  extractTextFromPdf: true
})

export const initialState = fromJS({
  selectedNews: defaultSelectedNews,
  publications: [],
  uploadedFiles: [],
  uploadedVideoFile: null
})

export default handleActions({
  [NewsActions.showEditNewsDialog]: () => initialState,
  [NewsActions.getNewsStart]: (state, { payload }) => state.set('selectedNews', payload),
  [NewsActions.getNewsSuccess]: (state, { payload: { newNews: news, oldNews } }) => {
    const selectedNews = state.get('selectedNews')

    let mergedNews = fromJS(news)

    mergedNews = mergedNews.merge({
      clusteredNews: selectedNews.get('clusteredNews'),
      clusterSize: selectedNews.get('clusterSize'),
      flags: mergedNews.get('flags').set('clusteredNews', oldNews.getIn(['flags', 'clusteredNews']))
    })

    return state.set('selectedNews', mergedNews)
  },

  [NewsActions.updateSelectedNews]: (state, { payload: news }) => state.set('selectedNews', news),
  [NewsActions.updateFieldOfSelectedNews]: (state, { payload: { field, value } }) => state.setIn(['selectedNews', field], value),

  [NewsActions.searchPublicationsSuccess]: (state, { payload: publications }) => (
    state.set('publications', fromJS(publications))
  ),

  [PublicationsActions.createPublicationSuccess]: (state, { payload: publication }) => (
    state.set('publications', fromJS([publication]))
  ),

  [NewsActions.uploadFileSuccess]: (state, { payload: file }) => (
    state.update('uploadedFiles', files => files.push(fromJS(file)))
  ),
  [NewsActions.removeUploadedFile]: (state, { payload: fileId }) => (
    state.update('uploadedFiles', files => files.filter(file => file.get('id') !== fileId))
  ),

  [NewsActions.uploadVideoFileSuccess]: (state, { payload: file }) => (
    state.set('uploadedVideoFile', fromJS(file))
  ),
  [NewsActions.removeUploadedVideoFile]: state => (
    state.set('uploadedVideoFile', null)
  ),

  [NewsActions.setImageAsIllustration]: (state, { payload }) => (
    state.setIn(['selectedNews', 'imageAsIllustration'], payload)
  ),
  [NewsActions.setExtractTextFromPdf]: (state, { payload }) => (
    state.setIn(['selectedNews', 'extractTextFromPdf'], payload)
  ),
  [NewsActions.mergeExtractedText]: (state, { payload: text }) => (
    state.setIn(['selectedNews', 'text'], (state.getIn(['selectedNews', 'text']) || '') + text)
  ),

  [NewsActions.resetNews]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
