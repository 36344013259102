import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'

import LinkedIn from 'components/content_desk_new/contents/content_preview/linked_in'
import Facebook from 'components/content_desk_new/contents/content_preview/facebook'

const useStyles = makeStyles()(theme => ({
  noPreviewContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noPreviewBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px dotted #757575',
    borderRadius: '10px',
    height: '60px',
    width: '350px',
    fontStyle: 'italic',
    color: !theme.isDark && '#757575'
  },
  htmlPreview: {
    width: '100%',
    border: 'none',
    overflow: 'hidden'
  }
}))

const ContentPreview = ({
  content,
  showContentDetailsView,
  editorDialogView,
  onChange
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const iframeRef = useRef(null)

  let contentType = content.getIn(['type', 'id']) || content.get('type') || ''
  contentType = contentType.toLowerCase()

  useEffect(() => {
    const iframe = iframeRef.current

    if (iframe) {
      const onLoad = () => {
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight + 50}px`
      }

      iframe.addEventListener('load', onLoad)

      return () => {
        iframe.removeEventListener('load', onLoad)
      }
    }

    return () => {}
  }, [content])

  const renderMain = () => {
    if (contentType === 'email') {
      const body = content.get('body') || ''

      if (body.length > 5000) {
        // scrolling is deprecated in HTML5 but chrome does not work without it
        return (
          <iframe
            ref={iframeRef}
            title="content-preview"
            className={classes.htmlPreview}
            srcDoc={body}
            scrolling="no"
          />
        )
      }
    }

    if (contentType === 'ots_press_release') {
      const body = content.getIn(['otsPressRelease', 'htmlBody']) || ''

      // scrolling is deprecated in HTML5 but chrome does not work without it
      return (
        <iframe
          ref={iframeRef}
          title="content-preview"
          className={classes.htmlPreview}
          srcDoc={body}
          scrolling="no"
        />
      )
    }

    if (contentType === 'linkedin') {
      return (
        <LinkedIn
          content={content}
          showContentDetailsView={showContentDetailsView}
          editorDialogView={editorDialogView}
          onChange={onChange}
        />
      )
    }

    if (contentType === 'facebook') {
      return (
        <Facebook
          content={content}
          showContentDetailsView={showContentDetailsView}
          editorDialogView={editorDialogView}
          onChange={onChange}
        />
      )
    }

    return (
      <div className={classes.noPreviewContainer}>
        <div className={classes.noPreviewBox}>
          {i18n.get('no_preview_available')}
        </div>
      </div>
    )
  }

  return (
    <>
      {renderMain()}
    </>
  )
}

ContentPreview.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  showContentDetailsView: PropTypes.bool.isRequired,
  editorDialogView: PropTypes.string,

  onChange: PropTypes.func
}

export default ContentPreview
