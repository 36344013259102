import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import copyToClipboard from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import {
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  Slide,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'

import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import { Button, IconButton, RaisedPrimaryButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  dialogContent: {
    minHeight: '200px'
  },
  contentHelperText: {
    whiteSpace: 'break-spaces'
  },
  spacer: {
    flex: 1
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[50]
  },
  tableCell: {
    borderBottom: 'none'
  },
  needHelpButton: {
    marginTop: '30px',
    gap: '10px',
    border: 'none',
    '&:hover': {
      border: 'none'
    }
  }
}))

const mergeTags = [
  { name: 'full_name', value: '%recipient.full_name%', example: 'variables_helper_full_name_example' },
  { name: 'first_name', value: '%recipient.first_name%', example: 'variables_helper_first_name_example' },
  { name: 'last_name', value: '%recipient.last_name%', example: 'variables_helper_last_name_example' }
]

const variables = [
  { name: 'unsubscribe', value: '%tag_unsubscribe_url%', example: 'variables_helper_unsubscribe_example' }
]

const columnWidths = {
  type: '20%',
  variable: '30%',
  example: '40%',
  action: '10%'
}

const TemplatesEditorVariables = ({
  raisedBtn,
  userLocale
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openHelp, setOpenHelp] = useState(false)
  const helpVideoSrc = userLocale === 'de' ? 'https://static.pressrelations.de/pics/groot/ccd/unsubscribe_video_de.gif'
    : 'https://static.pressrelations.de/pics/groot/ccd/unsubscribe_video_en.gif'

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleCopyVariable = variable => {
    copyToClipboard(variable, { format: 'text/plain' })

    enqueueSnackbar(
      `${i18n.get('copied')}`,
      { variant: 'info', preventDuplicate: true }
    )
  }

  const handleOpenHelpVideo = () => {
    setOpen(false)
    setOpenHelp(true)
  }

  return (
    <>
      {raisedBtn ? (
        <RaisedPrimaryButton
          variant="outlined"
          onClick={handleOpen}
          size="small"
        >{i18n.get('personalization')}
        </RaisedPrimaryButton>
      )
        : (
          <Button
            variant="outlined"
            onClick={handleOpen}
          >
            {i18n.get('personalization')}
          </Button>
        )}
      <Dialog
        maxWidth="md"
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        open={open}
      >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
          >
            {`${i18n.get('personalization')}`}
          </Typography>
          <div className={classes.spacer} />
          <IconButton
            className={classes.close}
            icon="close"
            onClick={handleClose}
            size="normal"
          />
        </Toolbar>
        <DialogContent className={classes.dialogContent}>
          <div
            className={classes.contentHelperText}
            dangerouslySetInnerHTML={{
              __html: i18n.get('variables_helper_description')
            }}
          />
          <br />
          <Typography
            fontSize={18}
            fontWeight={500}
            marginBottom={2}
            color="inherit"
          >
            {i18n.get('merge_tags')}
          </Typography>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell sx={{ width: columnWidths.type }}>{i18n.get('type')}</TableCell>
                <TableCell sx={{ width: columnWidths.variable }}>{i18n.get('variable')}</TableCell>
                <TableCell sx={{ width: columnWidths.example }}>{i18n.get('example')}</TableCell>
                <TableCell sx={{ width: columnWidths.action }}>{i18n.get('action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergeTags.map((mergeTag, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>{i18n.get(mergeTag.name)}</TableCell>
                  <TableCell className={classes.tableCell}>{i18n.get(mergeTag.value)}</TableCell>
                  <TableCell className={classes.tableCell}>{i18n.get(mergeTag.example)}</TableCell>
                  <TableCell className={classes.tableCell}><IconButton
                    icon={<ContentCopyIcon fontSize="25px" />}
                    onClick={() => handleCopyVariable(mergeTag.value)}
                    size="small"
                  />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Typography
            fontSize={18}
            fontWeight={500}
            marginTop={2}
            marginBottom={2}
            color="inherit"
          >
            {i18n.get('variables')}
          </Typography>
          <Table>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell sx={{ width: columnWidths.type }}>{i18n.get('type')}</TableCell>
                <TableCell sx={{ width: columnWidths.variable }}>{i18n.get('variable')}</TableCell>
                <TableCell sx={{ width: columnWidths.example }}>{i18n.get('example')}</TableCell>
                <TableCell sx={{ width: columnWidths.action }}>{i18n.get('action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variables.map((variable, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>{i18n.get(variable.name)}</TableCell>
                  <TableCell className={classes.tableCell}>{i18n.get(variable.value)}</TableCell>
                  <TableCell className={classes.tableCell}>{i18n.get(variable.example)}</TableCell>
                  <TableCell className={classes.tableCell}><IconButton
                    icon={<ContentCopyIcon fontSize="25px" />}
                    onClick={() => handleCopyVariable(variable.value)}
                    size="small"
                  />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Button
            className={classes.needHelpButton}
            variant="outlined"
            color="primary"
            onClick={() => handleOpenHelpVideo()}
          >
            {i18n.get('need_help_to_create_an_unsubscribed_link')}
            <PlayCircleIcon />
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xl"
        TransitionComponent={Slide}
        TransitionProps={{ direction: 'up' }}
        open={openHelp}
        onClose={() => setOpenHelp(false)}
      >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
          >
            {`${i18n.get('help')}`}
          </Typography>
          <div className={classes.spacer} />
          <IconButton
            className={classes.close}
            icon="close"
            onClick={() => setOpenHelp(false)}
            size="normal"
          />
        </Toolbar>
        <DialogContent>
          <Card>
            <CardMedia
              component="img"
              image={helpVideoSrc}
            />
          </Card>
        </DialogContent>
      </Dialog>
    </>
  )
}

TemplatesEditorVariables.defaultProps = {
  raisedBtn: false
}

TemplatesEditorVariables.propTypes = {
  raisedBtn: PropTypes.bool,
  userLocale: PropTypes.string.isRequired
}

export default TemplatesEditorVariables
