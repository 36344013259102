import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useDevice from 'hooks/useDevice'

import {
  Chip,
  TableCell,
  TableRow
} from '@mui/material'

import { blue, blueGrey } from '@mui/material/colors'

import { makeStyles } from 'tss-react/mui'

import CampaignName from 'containers/content_desk_new/campaigns/campaign/CampaignName'
import CampaignStatus from 'containers/content_desk_new/campaigns/campaign/CampaignStatus'
import CampaignProgress from 'containers/content_desk_new/campaigns/campaign/CampaignProgress'

const useStyles = makeStyles()(theme => ({
  chipUnselected: {
    '&:hover': {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main
    }
  },
  chipSelected: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  active: {
    borderLeft: '5px solid',
    borderColor: blue[500]
  },
  inactive: {
    borderLeft: '5px solid',
    borderColor: blueGrey[500]
  }
}))

const ShortCampaign = ({
  campaign,
  selectedCampaignId,
  onContentsClick
}) => {
  const { classes } = useStyles()
  const campaignSelected = selectedCampaignId === campaign.get('id')
  const campaignActive = campaign.get('active') && !campaign.get('defaultAssigned')

  let maxLength = 16

  const device = useDevice()

  if (device.get('xl')) {
    maxLength = 32
  } else if (device.get('lg')) {
    maxLength = 24
  }

  return (
    <TableRow className={campaignActive ? classes.active : classes.inactive}>
      <TableCell>
        <CampaignName
          campaign={campaign}
          maxLength={maxLength}
        />
      </TableCell>
      <TableCell>
        <CampaignProgress campaign={campaign} />
      </TableCell>
      <TableCell>
        <Chip
          className={campaignSelected ? classes.chipSelected : classes.chipUnselected}
          label={campaign.get('ccdContentsCount')}
          variant={campaignSelected ? 'filled' : 'outlined'}
          color="primary"
          onClick={() => onContentsClick(campaignSelected ? null : campaign.get('id'))}
        />
      </TableCell>
      <TableCell>
        <CampaignStatus campaign={campaign} />
      </TableCell>
    </TableRow>
  )
}

ShortCampaign.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired,
  selectedCampaignId: PropTypes.number,

  onContentsClick: PropTypes.func.isRequired
}

export default ShortCampaign
