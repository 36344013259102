import { connect } from 'react-redux'

import MediaReview from 'components/media_reviews/media_review'
import { getI18n } from 'selectors'

const mapStateToProps = (state, { mediaReview }) => ({
  i18n: getI18n(state),
  mediaReview
})

export default connect(mapStateToProps)(MediaReview)
