import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { stripDossier } from 'utils/html'

import { Map as ImmutableMap } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Divider, Box, Typography } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  infoBox: {
    '& p': {
      fontSize: '90%',
      marginTop: 0
    }
  },
  infoLabel: {
    display: 'inline-block',
    minWidth: 60,
    marginRight: 10,
    color: theme.palette.grey[900],
    fontWeight: 500
  },
  link: {
    color: '#2A9BFD'
  }
}))

export default function ContactInfo({ contact }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Box className={classes.infoBox}>
        {contact.get('mail') && (
          <Typography
            variant="body2"
            py={1}
          >
            <span className={classes.infoLabel}>{i18n.get('email')}</span>
            <a
              className={classes.link}
              href={`mailto:${contact.get('mail')}`}
            >
              {contact.get('mail')}
            </a>
          </Typography>
        )}

        {contact.get('website') && (
          <Typography
            variant="body2"
            py={1}
          >
            <span className={classes.infoLabel}>{i18n.get('website')}</span>
            <a
              className={classes.link}
              href={contact.get('website')}
            >
              {contact.get('website')}
            </a>
          </Typography>
        )}

        {contact.get('phone') && (
          <Typography
            variant="body2"
            py={1}
            sx={{ color: theme => theme.palette.grey[700] }}
          >
            <span className={classes.infoLabel}>
              {i18n.get('phone')}
            </span>
            {contact.get('phone')}
          </Typography>
        )}

        {contact.get('fax') && (
          <Typography
            variant="body2"
            py={1}
            sx={{ color: theme => theme.palette.grey[700] }}
          >
            <span className={classes.infoLabel}>
              {i18n.get('fax')}
            </span>
            {contact.get('fax')}
          </Typography>
        )}

        {contact.get('company') && (
          <Typography
            variant="body2"
            py={1}
          >
            <span className={classes.infoLabel}>
              {i18n.get('company')}
            </span>
            {contact.get('company')}
          </Typography>
        )}

        {contact.get('address') && (
          <Typography
            variant="body2"
            py={1}
            sx={{ color: theme => theme.palette.grey[700] }}
          >
            <span className={classes.infoLabel}>{i18n.get('address')}</span>
            {contact.get('address')}
          </Typography>
        )}
      </Box>
      {contact.get('dossier') && (
        <>
          <Divider style={{ margin: '1em -24px' }} />
          <Typography
            variant="subtitle2"
            pb={2}
            sx={{ fontSize: '90%' }}
          >
            {i18n.get('dossier')}
          </Typography>
          <Box>{stripDossier(contact.get('dossier'))}</Box>
        </>
      )}
      {contact.get('notes') && (
        <>
          <Divider style={{ margin: '1em -24px' }} />
          <Typography
            variant="subtitle2"
            pb={2}
            sx={{ fontSize: '90%' }}
          >
            {i18n.get('notes')}
          </Typography>
          <Box>{contact.get('notes')}</Box>
        </>
      )}
    </>
  )
}

ContactInfo.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired
}
