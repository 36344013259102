import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  uploadRunning: false,
  uploadVideoRunning: false,
  loadingNews: false,
  savingNews: false,
  creatingNews: false,
  loadingPublications: false,
  savingNewsIds: [],
  active: false,
  extractingText: false,
  summaryOptions: {
    generating: false,
    mode: 'sentences_5',
    wordCount: 50,
    bulletPointsCount: 3,
    targetLang: 'n/a'
  }
})

const removeSavingNewsId = (state, news) => state.update('savingNewsIds', ids => ids.remove(ids.indexOf(news.get('id'))))

export default handleActions({
  [NewsActions.uploadFileStart]: state => state.set('uploadRunning', true),
  [NewsActions.uploadFileSuccess]: state => state.set('uploadRunning', false),
  [NewsActions.uploadFileError]: state => state.set('uploadRunning', false),

  [NewsActions.uploadVideoFileStart]: state => state.set('uploadVideoRunning', true),
  [NewsActions.uploadVideoFileSuccess]: state => state.set('uploadVideoRunning', false),
  [NewsActions.uploadVideoFileError]: state => state.set('uploadVideoRunning', false),

  [NewsActions.getNewsStart]: state => state.set('loadingNews', true),
  [NewsActions.getNewsError]: state => state.set('loadingNews', false),
  [NewsActions.getNewsSuccess]: state => state.set('loadingNews', false),

  [NewsActions.updateNewsStart]: (state, { payload: news }) => state.update('savingNewsIds', ids => ids.push(news.get('id'))),
  [NewsActions.updateNewsError]: (state, { payload: { news } }) => removeSavingNewsId(state, news),
  [NewsActions.updateNewsSuccess]: (state, { payload: news }) => removeSavingNewsId(state, news),

  [NewsActions.createNewsStart]: state => state.set('creatingNews', true),
  [NewsActions.createNewsError]: state => state.set('creatingNews', false),
  [NewsActions.createNewsSuccess]: state => state.set('creatingNews', false),

  [NewsActions.showEditNewsDialog]: state => state.set('active', true),
  [NewsActions.hideEditNewsDialog]: state => state.set('active', false),

  [NewsActions.setPublicationsLoading]: state => state.set('loadingPublications', true),
  [NewsActions.searchPublicationsError]: state => state.set('loadingPublications', false),
  [NewsActions.searchPublicationsSuccess]: state => state.set('loadingPublications', false),

  [NewsActions.extractTextFromPdfStart]: state => state.set('extractingText', true),
  [combineActions(
    NewsActions.extractTextFromPdfSuccess,
    NewsActions.extractTextFromPdfError
  )]: state => state.set('extractingText', false),

  [NewsActions.setSummaryOption]: (state, { payload: { key, value } }) => (
    state.setIn(['summaryOptions', key], value)
  ),
  [NewsActions.automaticSummaryStart]: state => (
    state.setIn(['summaryOptions', 'generating'], true)
  ),
  [combineActions(
    NewsActions.automaticSummarySuccess,
    NewsActions.automaticSummaryError
  )]: state => state.setIn(['summaryOptions', 'generating'], false),

  [AppActions.resetState]: () => initialState
}, initialState)
