import { connect } from 'react-redux'

import MediaGallery from 'components/content_desk/content_edit_dialog/content_editor/linked_in_editor/media_gallery'
import {
  setContentFormData,
  fetchMoreMediaFilesStart,
  deleteMediaFileStart
} from 'actions/content_desk'
import {
  getCDeskContentFormData,
  getCDeskUploadedMediaFiles,
  getCDeskUploadedMediaFilesTotal,
  getCDeskMediaFilesPage,
  getCDeskMediaFilesLimit,
  getCDeskMediaFilesLoading
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state),
  uploadedMediaFiles: getCDeskUploadedMediaFiles(state),
  loadMoreDisabled: getCDeskUploadedMediaFilesTotal(state) < (getCDeskMediaFilesPage(state) - 1) * getCDeskMediaFilesLimit(state),
  requestRunning: getCDeskMediaFilesLoading(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData,
    fetchMoreMediaFilesStart,
    onImageDelete: deleteMediaFileStart
  }
)(MediaGallery)
