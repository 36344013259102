import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import Group from 'containers/custom_tags/Group'
import { StaggeredListItem } from 'components/staggered_list'
import { FlexList, FlexListItem } from 'components/flex_list'
import NewItemCard from 'components/new_item_card'
import WithI18n from 'containers/WithI18n'

const useStyles = makeStyles()({
  groups: {
    width: '102%'
  },
  newItem: {
    '& > div': {
      height: '100%',
      '& > div': {
        height: '100%'
      }
    }
  }
})

const GroupMode = ({ i18n, groups, onNewClick }) => {
  const { classes } = useStyles()

  return (
    <FlexList
      columns={5}
      className={classes.groups}
    >
      <FlexListItem className={classes.newItem}>
        <NewItemCard
          onClick={() => onNewClick()}
          label={i18n.get('create_pin_board_group')}
        />
      </FlexListItem>

      {groups.map((group, index) => (
        <FlexListItem key={group.get('id')}>
          <StaggeredListItem delay={index * 50}>
            <Group group={group} />
          </StaggeredListItem>
        </FlexListItem>
      ))}
    </FlexList>
  )
}

GroupMode.propTypes = {
  i18n: PropTypes.object.isRequired,
  groups: PropTypes.instanceOf(List).isRequired,

  onNewClick: PropTypes.func.isRequired
}

export default WithI18n()(GroupMode)
