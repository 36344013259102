import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/ai'

export const initialState = fromJS({
  showOrderSummariesDialog: false
})

export default handleActions({
  [Actions.showOrderSummariesDialog]: state => state.set('showOrderSummariesDialog', true),
  [Actions.hideOrderSummariesDialog]: state => state.set('showOrderSummariesDialog', false),

  [AppActions.resetState]: () => initialState
}, initialState)
