import { call, put, select, takeEvery, all, delay } from 'redux-saga/effects'
import { fromJS } from 'immutable'
import moment from 'moment-timezone'
import decamelizeKeysDeep from 'decamelize-keys-deep'

import { SearchFields, NewsPageModules, ChartDataSources, ChartTypes } from 'static/constants'

import { fetchChartData } from 'sagas/charts'

import * as Actions from 'actions/saved_searches'
import * as FilterActions from 'actions/filter'
import * as AppActions from 'actions/app'
import * as UiActions from 'actions/ui'
import * as NavigationActions from 'actions/navigation'
import * as NewsActions from 'actions/news'
import * as ConfigActions from 'actions/config'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'

function* getAlertConfigBody(savedSearch) {
  const sender = yield select(Selectors.getWhitelabelEmailAddress)
  const alertConfig = savedSearch.get('alertConfig', fromJS({}))

  return {
    type: alertConfig.get('type'),
    times: alertConfig.get('times'),
    days: alertConfig.get('days'),
    timezone: alertConfig.get('timezone'),
    threshold: alertConfig.get('threshold'),
    recipients: alertConfig.get('recipients'),
    recipients_sms: alertConfig.get('recipientsSms'),
    webhooks: alertConfig.get('webhooks'),
    mailing_list_ids: alertConfig.get('mailingListIds'),
    send_no_results_mail: alertConfig.get('sendNoResultsMail'),
    group_by: alertConfig.get('groupBy'),
    limit: alertConfig.get('limit'),
    prompt: alertConfig.get('prompt'),
    use_prompt: alertConfig.get('usePrompt'),
    sender
  }
}

// CAUTION: This saga is also used in sagas/analysis
export function* createSavedSearch(savedSearch) {
  const locale = yield select(Selectors.getUserLocale)
  const alertConfigBody = yield* getAlertConfigBody(savedSearch)
  const moduleName = yield select(Selectors.getViewConfigModuleName)

  const body = yield select(Selectors.getSearchBody)
  body.name_de = savedSearch.get('nameDe')
  body.name_en = savedSearch.get('nameEn')
  body.name_fr = savedSearch.get('nameFr')
  body.name_ru = savedSearch.get('nameRu')
  body.name_zh = savedSearch.get('nameZh')
  body.name_ja = savedSearch.get('nameJa')
  body.description = savedSearch.get('description') || ''
  body.color = savedSearch.get('color')
  body.photo = savedSearch.get('photo')
  body.locale = savedSearch.get('locale') || locale
  body.alert_config = alertConfigBody
  body.module_name = moduleName
  body.id = savedSearch.get('id')

  const result = yield call(Api.createSavedSearch, body)

  yield put(Actions.setExecutedSavedSearch(fromJS(result)))

  return result
}

export function* saveSavedSearch({ payload }) {
  try {
    const i18n = yield select(Selectors.getI18n)
    let savedSearch = payload
    const id = savedSearch.get('id')
    const uploadedPhoto = yield select(Selectors.getSavedSearchesUploadedPhoto)

    let photo = savedSearch.get('photo')

    if (uploadedPhoto) {
      const formData = [
        {
          key: 'file',
          value: uploadedPhoto
        }
      ]

      const { url } = yield call(Api.uploadFile, formData)
      photo = url
    }

    savedSearch = savedSearch.update('photo', p => photo || p)

    let result
    let body
    let successMessage = i18n.get('created')

    if (id) {
      const locale = yield select(Selectors.getUserLocale)
      const alertConfigBody = yield* getAlertConfigBody(savedSearch)
      successMessage = i18n.get('updated')

      body = {
        name_de: savedSearch.get('nameDe'),
        name_en: savedSearch.get('nameEn'),
        name_fr: savedSearch.get('nameFr'),
        name_ru: savedSearch.get('nameRu'),
        name_zh: savedSearch.get('nameZh'),
        name_ja: savedSearch.get('nameJa'),
        description: savedSearch.get('description') || '',
        color: savedSearch.get('color'),
        module_name: savedSearch.get('moduleName'),
        locale: savedSearch.get('locale') || locale,
        alert_config: alertConfigBody,
        photo: savedSearch.get('photo')
      }

      result = yield call(Api.updateSavedSearch, id, body)
    } else {
      result = yield* createSavedSearch(savedSearch)
    }

    yield put(Actions.saveSavedSearchSuccess(result))
    yield put(AppActions.showAppMessage({ text: `<b>${result.name}</b> ${successMessage}` }))
  } catch (error) {
    yield put(Actions.saveSavedSearchError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* deleteSavedSearch() {
  try {
    const savedSearch = yield select(Selectors.getSelectedSavedSearch)
    const id = savedSearch.get('id')

    yield call(Api.deleteSavedSearch, id)

    yield put(Actions.deleteSavedSearchSuccess(id))
    yield put(Actions.fetchSavedSearchesTimelinesStart())
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.deleteSavedSearchError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* checkAffectedDashboards({ payload: savedSearch }) {
  const id = savedSearch.get('id')
  const dashboards = yield select(Selectors.getDashboards)
  const additionalAffectedDashboards = dashboards
    .filter(d => d.get('charts')
      .reduce((r, v) => v.map(chart => chart.get('additionalSavedSearchIds', [])
        .includes(id)), []).includes(true))
  const affectedDashboards = dashboards.filter(d => d.get('savedSearchIds').includes(id)).concat(additionalAffectedDashboards)

  if (affectedDashboards.isEmpty()) {
    yield put(Actions.deleteSavedSearchStart())
  } else {
    yield put(Actions.hideRemoveSavedSearchDialog())
    yield put(Actions.setAffectedDashboards(affectedDashboards))
    yield put(Actions.showAffectedDashboardsDialog())
  }
}

export function* resolveSavedSearch({ payload: savedSearch }) {
  try {
    yield put(Actions.resolveSavedSearchStart())
    const dateFrom = yield select(Selectors.getSelectedDateFrom)
    const dateTo = yield select(Selectors.getSelectedDateTo)

    const id = savedSearch.get('id')

    const body = {
      date_from: dateFrom ? moment(dateFrom).format() : null,
      date_to: dateTo ? moment(dateTo).format() : null
    }

    const result = yield call(Api.resolveSavedSearch, id, body)

    let filters = fromJS(result)
    const booleans = filters.get(SearchFields.BOOLEANS)
    const geoBoundingBox = filters.get(SearchFields.GEO_BOUNDING_BOX)
    const analysis = filters.get(SearchFields.ANALYSIS)

    filters = filters.deleteAll([SearchFields.BOOLEANS, SearchFields.GEO_BOUNDING_BOX, SearchFields.ANALYSIS])
    filters = filters.reduce(
      (acc, values, key) => (
        acc.concat(values.map(value => ({
          filter: value,
          field: key
        })))
      ),
      fromJS([])
    )

    const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
    yield put(FilterActions.resetFilters(globalClusterDefault))
    yield put(NewsActions.resetNews())

    yield put(FilterActions.setFilter({
      field: SearchFields.BOOLEANS,
      values: booleans
    }))

    if (geoBoundingBox) {
      yield put(FilterActions.setFilter({
        field: SearchFields.GEO_BOUNDING_BOX,
        values: geoBoundingBox
      }))
    }

    if (analysis) {
      const analysisKeys = analysis.keySeq()

      for (let i = 0; i < analysisKeys.size; i += 1) {
        const analysisKey = analysisKeys.get(i)

        yield put(FilterActions.setAnalysisFilter({
          field: analysisKey,
          value: analysis.get(analysisKey)
        }))
      }
    }

    yield put(UiActions.uiAddFilters(filters))
    yield put(Actions.resolveSavedSearchSuccess())
  } catch (error) {
    yield put(Actions.resolveSavedSearchError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* fetchSavedSearchesTimelines() {
  try {
    const moduleName = yield select(Selectors.getViewConfigModuleName)

    if (moduleName) {
      const savedSearches = yield select(Selectors.getSavedSearchesByModule, moduleName)

      if (!savedSearches.isEmpty()) {
        const chart = fromJS({
          type: ChartTypes.LINE,
          dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
          dateRange: 'last_30_days',
          firstLevel: 'saved_searches_timeline',
          secondLevel: 'saved_searches',
          thirdLevel: 'buzz',
          savedSearchId: savedSearches.first().get('id'),
          additionalSavedSearchIds: savedSearches.slice(1).map(s => s.get('id'))
        })

        const result = yield call(fetchChartData, chart)

        yield put(Actions.fetchSavedSearchesTimelinesSuccess(result.savedSearchesTimeline))
      }
    } else {
      yield delay(500)
      yield put(Actions.fetchSavedSearchesTimelinesStart())
    }
  } catch (error) {
    yield put(Actions.fetchSavedSearchesTimelinesError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* fetchSavedSearchDispatches() {
  try {
    const savedSearch = yield select(Selectors.getSelectedSavedSearch)
    const result = yield call(Api.fetchSavedSearchDispatches, savedSearch.get('id'))
    yield put(Actions.fetchSavedSearchDispatchesSuccess(result))
  } catch (error) {
    yield put(Actions.fetchSavedSearchDispatchesError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* loadInitialSearch() {
  const savedSearch = yield select(Selectors.getFavoritedSavedSearch)

  if (savedSearch) {
    yield put(Actions.executeSavedSearch(savedSearch))
  }
}

export function* toggleFavorite({ payload: savedSearch }) {
  const id = savedSearch.get('id')
  const moduleName = yield select(Selectors.getViewConfigModuleName)

  if (moduleName === NewsPageModules.NEWS) {
    yield put(ConfigActions.setNewsSavedSearchId(id))
  } else if (moduleName === NewsPageModules.ANALYSIS) {
    yield put(ConfigActions.setAnalysisSavedSearchId(id))
  } else if (moduleName === NewsPageModules.PROFILE_MONITORING) {
    yield put(ConfigActions.setProfileMonitoringSavedSearchId(id))
  } else {
    yield put(ConfigActions.setNewsPoolSavedSearchId(id))
  }

  yield put(ConfigActions.updateUserConfigRequestStart())
}

export function* executeSavedSearch(action) {
  yield put(Actions.setInitialSearchLoaded(true))
  yield call(resolveSavedSearch, action)
  yield put(Actions.setExecutedSavedSearch(action.payload))

  const isSavedSearchesOverview = yield select(Selectors.isSavedSearchesOverview)

  if (isSavedSearchesOverview) {
    yield put(NavigationActions.back())
  }
}

export function* replaceSavedSearch({ payload: savedSearch }) {
  try {
    const result = yield call(createSavedSearch, savedSearch)

    yield put(Actions.replaceSavedSearchSuccess(result))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.replaceSavedSearchError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* saveSavedSearchFeed({ payload: savedSearchFeed }) {
  try {
    let result
    let body

    if (savedSearchFeed) {
      body = decamelizeKeysDeep(savedSearchFeed.toJS())

      result = yield call(Api.updateSavedSearchFeed, savedSearchFeed.get('id'), body)
    } else {
      const moduleName = yield select(Selectors.getViewConfigModuleName)
      const timezone = yield select(Selectors.getTimezone)

      body = {
        module_name: moduleName,
        timezone
      }

      result = yield call(Api.createSavedSearchFeed, body)
    }

    yield put(Actions.saveSavedSearchFeedSuccess(result))

    if (!savedSearchFeed) {
      yield put(AppActions.genericSuccessMessage())
    }
  } catch (error) {
    yield put(Actions.saveSavedSearchFeedError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* deleteSavedSearchFeed({ payload: savedSearchFeed }) {
  try {
    const id = savedSearchFeed.get('id')

    yield call(Api.deleteSavedSearchFeed, id)

    yield put(Actions.deleteSavedSearchFeedSuccess(id))
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.deleteSavedSearchFeedError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* watchSaveSavedSearch() {
  yield takeEvery(Actions.saveSavedSearchStart, saveSavedSearch)
}

export function* watchDeleteSavedSearch() {
  yield takeEvery(Actions.deleteSavedSearchStart, deleteSavedSearch)
}

export function* watchCheckAffectedDashboards() {
  yield takeEvery(Actions.checkAffectedDashboards, checkAffectedDashboards)
}

export function* watchFetchSavedSearchesTimelines() {
  yield takeEvery(Actions.fetchSavedSearchesTimelinesStart, fetchSavedSearchesTimelines)
}

export function* watchFetchSavedSearchDispatches() {
  yield takeEvery(Actions.fetchSavedSearchDispatchesStart, fetchSavedSearchDispatches)
}

export function* watchToggleFavorite() {
  yield takeEvery(Actions.toggleFavorite, toggleFavorite)
}

export function* watchLoadInitialSearch() {
  yield takeEvery(Actions.loadInitialSearch, loadInitialSearch)
}

export function* watchExecuteSavedSearch() {
  yield takeEvery(Actions.executeSavedSearch, executeSavedSearch)
}

export function* watchReplaceSavedSearch() {
  yield takeEvery(Actions.replaceSavedSearchStart, replaceSavedSearch)
}

export function* watchSaveSavedSearchFeed() {
  yield takeEvery(Actions.saveSavedSearchFeedStart, saveSavedSearchFeed)
}

export function* watchDeleteSavedSearchFeed() {
  yield takeEvery(Actions.deleteSavedSearchFeedStart, deleteSavedSearchFeed)
}

export default function* saga() {
  yield all([
    watchSaveSavedSearch(),
    watchDeleteSavedSearch(),
    watchCheckAffectedDashboards(),
    watchFetchSavedSearchesTimelines(),
    watchFetchSavedSearchDispatches(),
    watchToggleFavorite(),
    watchLoadInitialSearch(),
    watchExecuteSavedSearch(),
    watchReplaceSavedSearch(),
    watchSaveSavedSearchFeed(),
    watchDeleteSavedSearchFeed()
  ])
}
