/* eslint-disable */
import { ParagraphNode } from 'lexical'

export class ExtendedParagraphNode extends ParagraphNode {
    constructor(key) {
        super(key)
    }

    static getType() {
        return 'extended-paragraph'
    }

    static clone(node) {
        return new ExtendedParagraphNode(node.__key)
    }

    exportDOM(editor) {
        let element = super.createDOM(editor._config, editor)

        // Remove additional class and dir attribute on exported html.
        element.removeAttribute('dir')
        element.removeAttribute('class')

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        return ParagraphNode.importJSON(serializedNode)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'extended-paragraph',
            version: 1
        }
    }
}