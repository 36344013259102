import { connect } from 'react-redux'

import PublishConfirmDialog from 'components/content_desk_new/contents/publish_confirm_dialog'

import {
  createOtsDraftStart,
  closeEditContentDialog
} from 'actions/content_desk'

const mapStateToProps = (_state, ownProps) => ({
  open: ownProps.open,
  content: ownProps.content,
  approvalStatus: ownProps.approvalStatus,
  onClose: ownProps.onClose,
  onChange: ownProps.onChange,
  onSave: ownProps.onSave
})

export default connect(
  mapStateToProps,
  {
    createOtsDraft: createOtsDraftStart,
    closeEditContentDialog
  }
)(PublishConfirmDialog)
