import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import ShoppingCartGroupedNewsListItem from 'containers/shopping_cart/GroupedNewsListItem'

import { IconButton } from 'containers/themed'
import Toolbar from 'containers/shopping_cart/Toolbar'
import { GROUPED } from 'components/shopping_cart/enum'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import SimpleSortableList from 'components/sortable/simple_sortable_list'

import { List } from '@mui/material'

const useStyles = makeStyles()({
  action: {
    padding: 2
  },
  list: {
    padding: 0
  }
})

const SortableItem = ({ item }) => <ShoppingCartGroupedNewsListItem code={item} />

SortableItem.propTypes = {
  item: PropTypes.any.isRequired
}

export default function GroupedNewsList({ codes, onSortEnd, onExpand, onCollapse, allExpanded }) {
  const { classes } = useStyles()

  return (
    <div>
      <Toolbar
        tab={GROUPED}
        rightActions={
          [
            <div key="1">
              <IconButton
                classes={{ root: classes.action }}
                onClick={() => (allExpanded ? onCollapse() : onExpand())}
                size="large"
              >
                {allExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
          ]
        }
      />

      <List classes={{ root: classes.list }}>
        <SimpleSortableList
          items={codes}
          Component={SortableItem}
          onSortEnd={onSortEnd}
        />
      </List>
    </div>
  )
}

GroupedNewsList.propTypes = {
  codes: PropTypes.object.isRequired,
  allExpanded: PropTypes.bool.isRequired,

  onSortEnd: PropTypes.func.isRequired,
  onExpand: PropTypes.func.isRequired,
  onCollapse: PropTypes.func.isRequired
}
