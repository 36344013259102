import { call, put, race, take, select, takeEvery, takeLatest, all, delay, debounce } from 'redux-saga/effects'
import moment from 'moment-timezone'

import * as Actions from 'actions/export'
import * as CustomTagsActions from 'actions/custom_tags'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import * as AppActions from 'actions/app'

const calculateDuration = (news, type) => {
  let duration = news.reduce((a, n) => {
    let result

    if (type !== 'pdf') {
      result = a + 0.02
      result += n.get('clusteredNews').size * 0.02
    } else if (n.getIn(['publication', 'channelId']) === 1) {
      result = a + 1
      result += (n.get('clusteredNews').size * 0.5)
    } else {
      result = a + 0.3
      result += (n.get('clusteredNews').size * 0.15)
    }

    return result
  }, 0)

  duration = Math.round(duration)

  if (!duration) {
    return 1
  }

  return duration
}

const exportSuccess = ({ type, preview, result }) => {
  if (preview) {
    if (['html', 'backwards_news_html'].indexOf(type) > -1) {
      return put(Actions.exportHtmlPreviewSuccess(result))
    }

    if (type === 'txt') {
      return put(Actions.exportTxtPreviewSuccess(result))
    }

    if (type === 'xml') {
      return put(Actions.exportXmlPreviewSuccess(result))
    }

    if (type === 'json') {
      return put(Actions.exportJsonPreviewSuccess(result))
    }

    if (type === 'pdf') {
      return put(Actions.exportCoverSuccess(result))
    }
  }

  return put(Actions.exportSuccess(result))
}

const exportError = (isPdf, error) => {
  if (isPdf) {
    return put(Actions.exportCoverError(error))
  }

  return put(Actions.exportError(error))
}

export function* doExport({ payload: { type, preview, fetchNewsIds, mode } }) {
  try {
    let exportBody = yield select(Selectors.getExportBody, type, preview, mode)

    if (fetchNewsIds) {
      const searchBody = yield select(Selectors.getSearchBodyMaxSize, type)
      const result = yield call(Api.fetchNewsIds, searchBody)
      const timezone = yield select(Selectors.getTimezone)
      exportBody = { ...exportBody, news: result, news_ids: result, timezone }
    }

    if (!preview) {
      yield put(Actions.setLastExport(exportBody))
    }

    const result = yield call(Api.startExport, exportBody)

    if (!preview) {
      yield put(Actions.exportProgressFinish())
      yield delay(500)
    }

    yield exportSuccess({ type, preview, result })
  } catch (error) {
    yield exportError(type === 'pdf' && preview, error)
    yield put(AppActions.genericErrorMessage(error))
    yield put(AppActions.exception(error))
  }
}

export function* doStructuredFormatExport({ payload: { shoppingCartCustomTag } }) {
  try {
    let exportBody = { custom_tag_id: shoppingCartCustomTag.get('id') }

    if (shoppingCartCustomTag.get('rssEnabled')) {
      const rssFields = yield select(Selectors.getRssExportBody)
      exportBody = { ...exportBody, ...rssFields }
    } else if (shoppingCartCustomTag.get('xmlEnabled')) {
      const xmlFields = yield select(Selectors.getXmlExportBody)
      exportBody = { ...exportBody, ...xmlFields }
    } else if (shoppingCartCustomTag.get('jsonEnabled')) {
      const jsonFields = yield select(Selectors.getJsonExportBody)
      exportBody = { ...exportBody, ...jsonFields }
    }

    yield put(Actions.setLastExport(exportBody))

    const result = yield call(Api.startExport, exportBody)
    yield put(Actions.exportProgressFinish())
    yield delay(500)
    yield exportSuccess({ result })
    yield put(CustomTagsActions.afterStructuredFormatExportActionStart())
  } catch (error) {
    yield exportError(false, error)
    yield put(AppActions.genericErrorMessage(error))
    yield put(AppActions.exception(error))
  }
}

export function* exportBackwardsNewsHtml() {
  try {
    yield put(Actions.changeExportFormatSelection('backwards_news_html'))
    const searchBody = yield select(Selectors.getSearchBody)
    const size = yield select(Selectors.getBackwardsNewsHtmlLimit)

    const body = {
      type: 'backwards_news_html',
      search_params: { ...searchBody, size }
    }

    yield put(Actions.setLastExport(body))

    const result = yield call(Api.startExport, body)
    yield put(Actions.exportProgressFinish())
    yield delay(500)
    yield exportSuccess({ result })
  } catch (error) {
    yield exportError(false, error)
    yield put(AppActions.genericErrorMessage(error))
    yield put(AppActions.exception(error))
  }
}

export function* exportProgress({ payload }) {
  const options = yield select(Selectors.getExportOptions)

  let duration

  if (payload && !payload.fetchNewsIds) {
    const news = yield select(Selectors.getShoppingCartNews)
    duration = calculateDuration(news, options.get('selectedFormat'))
  } else {
    const totalCount = yield select(Selectors.getTotalNewsCount)
    const exportCount = totalCount > 10000 ? 10000 : totalCount
    duration = exportCount * 0.02
  }

  const step = 100 / duration
  yield put(Actions.exportProgressStart(duration))

  const startTime = moment()

  for (;;) {
    const elapsedTime = moment().diff(startTime, 'seconds')

    yield put(Actions.exportProgressIncreaseStep({
      step,
      elapsedTime
    }))

    const { eSuccess, mSuccess, eError, mError } = yield race({
      eSuccess: take(Actions.exportSuccess),
      mSuccess: take(Actions.sendMailSuccess),
      eError: take(Actions.exportError),
      mError: take(Actions.sendMailError),
      step: delay(1000)
    })

    if (eSuccess || mSuccess || eError || mError) {
      yield put(Actions.exportProgressStop())

      return null
    }
  }
}

export function* generatePreview() {
  const open = yield select(Selectors.getExportShowExportDialog)
  const isStructuredExport = yield select(Selectors.getShoppingCartIsStructuredExport)

  if (!isStructuredExport && open) {
    const selectedFormat = yield select(Selectors.getExportSelectedFormat)

    if (selectedFormat === 'pdf') {
      yield put(Actions.exportCoverStart({
        type: 'pdf',
        preview: true
      }))
    }

    if (selectedFormat === 'html') {
      yield put(Actions.exportHtmlPreviewStart({
        type: 'html',
        preview: true
      }))
    }

    if (selectedFormat === 'backwards_news_html') {
      yield put(Actions.exportHtmlPreviewStart({
        type: 'backwards_news_html',
        preview: true
      }))
    }

    if (selectedFormat === 'txt') {
      yield put(Actions.exportTxtPreviewStart({
        type: 'txt',
        preview: true
      }))
    }

    if (selectedFormat === 'xml') {
      yield put(Actions.exportXmlPreviewStart({
        type: 'xml',
        preview: true
      }))
    }

    if (selectedFormat === 'json') {
      yield put(Actions.exportJsonPreviewStart({
        type: 'json',
        preview: true
      }))
    }
  }
}

export function* showExportDialog() {
  yield call(generatePreview)
}

export function* sendMail({ payload: { to, subject, text, senderName, senderMail } }) {
  try {
    let from = null

    if (senderName && senderMail) {
      from = `${senderName} <${senderMail}>`
    }

    const mailBody = yield select(Selectors.getMailBody, to, subject, text, from)
    const result = yield call(Api.sendMail, mailBody)

    yield put(Actions.hideSendMailDialog())
    yield put(AppActions.genericSuccessMessage())
    yield put(Actions.sendMailSuccess(result))
  } catch (error) {
    yield put(Actions.sendMailError(error))
    yield put(AppActions.genericErrorMessage(error))
    yield put(AppActions.exception(error))
  }
}

export function* createDispatch({ payload: { to, subject, text, senderName, senderMail, scheduledFor } }) {
  try {
    let from = null

    if (senderName && senderMail) {
      from = `${senderName} <${senderMail}>`
    }

    const mailBody = yield select(Selectors.getMailBody, to, subject, text, from, scheduledFor)
    const result = yield call(Api.createDispatch, mailBody)

    yield put(Actions.hideSendMailDialog())
    yield put(AppActions.genericSuccessMessage())
    yield put(Actions.createDispatchSuccess(result))
  } catch (error) {
    yield put(Actions.createDispatchError(error))
    yield put(AppActions.genericErrorMessage(error))
    yield put(AppActions.exception(error))
  }
}

export function* cancelDispatch({ payload: id }) {
  try {
    yield call(Api.cancelDispatch, id)
    yield put(Actions.cancelDispatchSuccess(id))
  } catch (error) {
    yield put(Actions.cancelDispatchError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* uploadHtmlHeaderLogo({ payload: file }) {
  try {
    if (file) {
      const formData = [
        {
          key: 'file',
          value: file
        },
        {
          key: 'keep_until',
          value: moment().add(28, 'days').format()
        }
      ]

      const { url, filename } = yield call(Api.uploadFile, formData)

      yield put(Actions.setUploadedHeaderLogoUrl({ url, filename }))
      yield put(Actions.exportHtmlPreviewStart({
        type: 'html',
        preview: true
      }))
    }
  } catch (error) {
    yield put(Actions.uploadHtmlHeaderLogoError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* uploadHtmlAdvertisingBanner({ payload: file }) {
  try {
    if (file) {
      const formData = [
        {
          key: 'file',
          value: file
        },
        {
          key: 'keep_until',
          value: moment().add(28, 'days').format()
        }
      ]

      const { url, filename } = yield call(Api.uploadFile, formData)

      yield put(Actions.setUploadedAdvertisingBannerUrl({ url, filename }))
      yield put(Actions.exportHtmlPreviewStart({
        type: 'html',
        preview: true
      }))
    }
  } catch (error) {
    yield put(Actions.uploadHtmlAdvertisingBannerError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* validateSpf({ payload: email }) {
  try {
    yield put(Actions.validateSpfStarted())
    const result = yield call(Api.validateSpf, { email })
    yield put(Actions.validateSpfSuccess(result))
  } catch (error) {
    yield put(Actions.validateSpfError(error))
    yield put(AppActions.exception(error))
  }
}

export function* changeExportFormatSelection() {
  yield call(generatePreview)
}

export function* fetchDispatchLog() {
  try {
    const result = yield call(Api.fetchDispatchLog)

    yield put(Actions.fetchDispatchLogSuccess(result))
  } catch (error) {
    yield put(Actions.fetchDispatchLogError(error))
    yield put(AppActions.exception(error))
  }
}

export function* watchExportStart() {
  yield takeEvery(Actions.exportCoverStart, doExport)
  yield takeEvery(Actions.exportHtmlPreviewStart, doExport)
  yield takeEvery(Actions.exportTxtPreviewStart, doExport)
  yield takeEvery(Actions.exportXmlPreviewStart, doExport)
  yield takeEvery(Actions.exportJsonPreviewStart, doExport)
  yield takeEvery(Actions.exportStart, doExport)
  yield takeEvery(Actions.exportStart, exportProgress)
  yield takeEvery(Actions.exportStructuredFormatStart, doStructuredFormatExport)
  yield takeEvery(Actions.exportStructuredFormatStart, exportProgress)
  yield takeEvery(Actions.exportBackwardsNewsHtmlStart, exportBackwardsNewsHtml)
  yield takeEvery(Actions.exportBackwardsNewsHtmlStart, exportProgress)
}

export function* watchShowExportDialog() {
  yield takeEvery(Actions.showExportDialog, showExportDialog)
}

export function* watchSendMail() {
  yield takeEvery(Actions.sendMail, sendMail)
}

export function* watchCreateDispatch() {
  yield takeEvery(Actions.createDispatchStart, createDispatch)
}

export function* watchCancelDispatch() {
  yield takeEvery(Actions.cancelDispatchStart, cancelDispatch)
}

export function* watchUploadHtmlHeaderLogo() {
  yield takeLatest(Actions.uploadHtmlHeaderLogoStart, uploadHtmlHeaderLogo)
}

export function* watchUploadHtmlAdvertisingBanner() {
  yield takeLatest(Actions.uploadHtmlAdvertisingBannerStart, uploadHtmlAdvertisingBanner)
}

export function* watchValidateSpf() {
  yield debounce(200, Actions.validateSpfStart, validateSpf)
}

export function* watchChangeExportFormatSelection() {
  yield takeEvery(Actions.changeExportFormatSelection, changeExportFormatSelection)
}

export function* watchFetchDispatchLog() {
  yield takeEvery(Actions.fetchDispatchLogStart, fetchDispatchLog)
}

export default function* exportSaga() {
  yield all([
    watchExportStart(),
    watchShowExportDialog(),
    watchSendMail(),
    watchUploadHtmlHeaderLogo(),
    watchUploadHtmlAdvertisingBanner(),
    watchValidateSpf(),
    watchChangeExportFormatSelection(),
    watchFetchDispatchLog(),
    watchCreateDispatch(),
    watchCancelDispatch()
  ])
}
