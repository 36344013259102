import React from 'react'
import PropTypes from 'prop-types'
import { Map, List } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Popover } from 'containers/themed'

import useI18n from 'hooks/useI18n'
import Users from './users'
import ReactionIndicator from './reaction_indicator'

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  popover: {
    padding: 10,
    width: 200
  },
  reactPopover: {
    padding: 10
  }
})

export default function AssignedReactions({
  indicatorClassName,
  reactions,
  reactionTypes
}) {
  const { classes, cx } = useStyles()
  const i18n = useI18n()

  const assignedReactionTypeIds = reactions.keySeq().toSet()
  const total = reactions.valueSeq().toList().flatten(true).size
  const filteredReactionTypes = reactionTypes.filter(rt => assignedReactionTypeIds.has(rt.get('id').toString()))

  return (
    <Popover
      disableScrollLock
      classes={{
        paper: cx(
          classes.popover
        )
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      handle={(
        <div>
          <ReactionIndicator
            total={total}
            className={indicatorClassName}
          />
        </div>
      )}
    >
      {!total && i18n.get('nrx_reactions_missing')}

      {filteredReactionTypes.map(reactionType => (
        <Users
          key={reactionType.get('id')}
          users={reactions.get(reactionType.get('id').toString())}
          reactionType={reactionType}
        />
      ))}
    </Popover>
  )
}

AssignedReactions.propTypes = {
  indicatorClassName: PropTypes.string,
  reactions: PropTypes.instanceOf(Map).isRequired,
  reactionTypes: PropTypes.instanceOf(List).isRequired
}
