
import { connect } from 'react-redux'

import Facebook from 'components/content_desk_new/contents/content_view/statistics_data/facebook'

import { getCDeskStatisticsRequestRunning } from 'selectors'

const mapStateToProps = (state, { data }) => ({
  data,
  requestRunning: getCDeskStatisticsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(Facebook)
