import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'
import AddIcon from '@mui/icons-material/Add'
import { Button } from 'containers/themed'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    borderBottomStyle: 'solid',
    borderBottomColor: grey[400],
    borderBottomWidth: '1px',
    marginBottom: '1em'
  },
  dialogCard: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '300px'
  },
  cardContent: {
    minHeight: '150px'
  }
}))

export default function TypeDialog({ open, onClose, onSearchClick, onScoreClick }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('new_search')}
      </DialogTitle>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Card className={classes.dialogCard}>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h3"
                  style={{ fontWeight: 400 }}
                >
                  {i18n.get('see_exposure')}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {i18n.get('search_query_card_description')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={onSearchClick}
                  style={{ color: '#3498A4' }}
                >
                  {i18n.get('create_query')}
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
          >
            <Card className={classes.dialogCard}>
              <CardContent className={classes.cardContent}>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h3"
                  style={{ fontWeight: 400 }}
                >
                  {i18n.get('risk_assessment')}
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {i18n.get('score_query_card_description')}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={onScoreClick}
                >
                  {i18n.get('create_query')}
                </Button>

              </CardActions>
            </Card>
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          {i18n.get('close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TypeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onScoreClick: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired
}
