import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as ExportActions from 'actions/export'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  current: 0,
  estimatedDuration: 0,
  elapsedTime: 0
})

export default handleActions({
  [ExportActions.exportProgressIncreaseStep]: (state, { payload: { step, elapsedTime } }) => {
    const current = state.get('current') + step

    if (current >= 100) {
      return state.merge({
        current: 99.99,
        elapsedTime
      })
    }

    return state.merge({
      current,
      elapsedTime
    })
  },
  [ExportActions.exportProgressFinish]: state => state.merge({
    current: 100,
    estimatedDuration: 0,
    elapsedTime: 0
  }),
  [ExportActions.exportProgressStop]: () => initialState,
  [ExportActions.exportProgressStart]: (state, { payload: estimatedDuration }) => state.set('estimatedDuration', estimatedDuration),
  [AppActions.resetState]: () => initialState
}, initialState)
