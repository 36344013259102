import { connect } from 'react-redux'

import ScoreResultTimelineChart from 'components/darknet/static_charts/score_result_timeline_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartsById } from 'utils/darknet'

const mapStateToProps = state => ({
  charts: getChartsById(getDarknetCharts(state), DarknetCharts.SCORE_RESULT_TIMELINE)
})

export default connect(mapStateToProps, {})(ScoreResultTimelineChart)
