import { connect } from 'react-redux'

import OtsErrorDialog from 'components/content_desk_new/ots_error_dialog/'

import { getCDeskShowOtsErrorDialog } from 'selectors'

import {
  closeOtsErrorDialog
} from 'actions/content_desk'

const mapStateToProps = state => ({
  open: getCDeskShowOtsErrorDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onClose: closeOtsErrorDialog
  }
)(OtsErrorDialog)
