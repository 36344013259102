import React, { Fragment, memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, Map } from 'immutable'
import decamelize from 'decamelize'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { makeStyles } from 'tss-react/mui'

import { SearchFields } from 'static/constants'
import { formatNumber } from 'utils/number'

import ActiveFilter from 'containers/ActiveFilter'
import DarknetActiveFilter from 'containers/darknet/ActiveFilter'
import ContentDeskActiveFilter from 'containers/content_desk_new/ActiveFilter'
import ContactManagementActiveFilter from 'containers/contact_management/ActiveFilter'
import ThemaxActiveFilter from 'containers/themax/ActiveFilter'

import { Button } from 'containers/themed'
import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()(theme => ({
  activeFilterList: {
    [theme.breakpoints.only('xs')]: {
      padding: '0 10px'
    }
  },
  collapsed: {
    maxHeight: 50,
    overflow: 'hidden'
  },
  collapseIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const maxFiltersToDisplay = 10

const ActiveFilterList = ({
  collapsed,
  onCollapseClick,
  filterCount,
  activeFilters,
  activeBooleanFilters,
  activeGeoFilter,
  activeFilterType,
  activeNumberFilters
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [autoCollapsed, setAutoCollapsed] = useState(false)
  const isCollapsible = filterCount >= maxFiltersToDisplay

  const renderActiveFilter = props => {
    if (activeFilterType === 'darknet') {
      return <DarknetActiveFilter {...props} />
    }

    if (activeFilterType === 'contentDesk') {
      return <ContentDeskActiveFilter {...props} />
    }

    if (activeFilterType === 'contactManagement') {
      return <ContactManagementActiveFilter {...props} />
    }

    if (activeFilterType === 'themax') {
      return <ThemaxActiveFilter {...props} />
    }

    return <ActiveFilter {...props} />
  }

  useEffect(() => {
    if (!autoCollapsed && isCollapsible && !collapsed) {
      onCollapseClick()
      setAutoCollapsed(true)
    }
  }, [isCollapsible, autoCollapsed])

  return (
    <div className={classes.activeFilterList}>
      <div className={isCollapsible && collapsed ? classes.collapsed : null}>
        {activeFilters
          .keySeq()
          .map(field => activeFilters.get(field).map((filter, index) => (
            renderActiveFilter({ key: `${filter.get('field')} ${filter.get('id')} ${filter.get('value')}`, filter, index })
          ))).toList().flatten(true)}

        {activeBooleanFilters.keySeq().map(key => {
          const value = activeBooleanFilters.get(key)

          return (
            <Fragment key={`${SearchFields.BOOLEANS} ${key} ${value}`}>
              {value ? (
                renderActiveFilter({
                  key: `${SearchFields.BOOLEANS} ${key} ${value}`,
                  filter: fromJS({
                    id: key,
                    name: key,
                    title: key,
                    value,
                    field: SearchFields.BOOLEANS
                  }),
                  index: key
                })

              ) : null}
            </Fragment>
          )
        }).toList().flatten(true)}

        {activeGeoFilter && (
          renderActiveFilter({
            key: SearchFields.GEO_BOUNDING_BOX,
            filter: fromJS({
              label: i18n.get('location'),
              title: [
                activeGeoFilter.getIn(['topLeft', 'lat']),
                activeGeoFilter.getIn(['topLeft', 'lon']),
                activeGeoFilter.getIn(['bottomRight', 'lat']),
                activeGeoFilter.getIn(['bottomRight', 'lon'])
              ].join(','),
              field: SearchFields.GEO_BOUNDING_BOX
            })
          })
        )}

        {activeNumberFilters.keySeq().map(key => {
          const value = activeNumberFilters.get(key)

          return (
            value !== 0
            && renderActiveFilter({
              key: `${key}-${value}`,
              filter: fromJS({
                id: key,
                name: key,
                title: key,
                label: `${i18n.get(decamelize(key))}: ${formatNumber(value)}`,
                value,
                field: key
              }),
              index: key
            })
          )
        })}
      </div>

      {isCollapsible && (
        <div className={classes.collapseIconContainer}>
          {!collapsed ? null : i18n.get('filter_count_selected', { count: filterCount })}

          <Button onClick={() => onCollapseClick()}>
            {collapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            {i18n.get(collapsed ? 'expand' : 'collapse')}
          </Button>
        </div>
      )}
    </div>
  )
}

ActiveFilterList.propTypes = {
  activeFilterType: PropTypes.string,
  activeFilters: PropTypes.object.isRequired,
  activeNumberFilters: PropTypes.object,
  activeBooleanFilters: PropTypes.object.isRequired,
  activeGeoFilter: PropTypes.instanceOf(Map),
  collapsed: PropTypes.bool.isRequired,
  filterCount: PropTypes.number.isRequired,

  onCollapseClick: PropTypes.func.isRequired
}

ActiveFilterList.defaultProps = {
  activeFilterType: 'news',
  activeNumberFilters: Map()
}

export default memo(ActiveFilterList)
