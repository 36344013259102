import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions, Fade, Icon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Button, RaisedSecondaryButton } from 'containers/themed'
import useI18n from 'hooks/useI18n'
import copyToClipboard from 'copy-to-clipboard'
import { v4 } from 'uuid'

const useStyles = makeStyles()(theme => ({
  label: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    marginRight: 15
  },
  copyBtn: {
    marginLeft: '2em'
  },
  defaultColor: {
    backgroundColor: theme.palette.background.default
  },
  source: {
    cursor: 'not-allowed',
    maxWidth: '84%',
    color: theme.palette.text.main
  },
  onionDisclaimer: {
    marginBottom: '2em'
  },
  checkIcon: {
    color: 'green'
  }
}))

const TransitionComponent = React.forwardRef((props, ref) => (
  <Fade
    ref={ref}
    {...props}
  />
))

TransitionComponent.displayName = 'Transition'

export default function SourceDialog({ open, source, network, onClose }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const id = `dialog-content-${v4()}`
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    setCopied(true)
    copyToClipboard(source, id)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false)
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [copied])

  const renderOnionWarning = () => {
    if (network !== 'onion') {
      return null
    }

    return (
      <div className={classes.onionDisclaimer}>
        <Typography
          color="textSecondary"
          variant="subtitle2"
          gutterBottom
        >
          {i18n.get('onion_disclaimer_header')}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {i18n.get('onion_disclaimer_1')}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {i18n.get('onion_disclaimer_2')}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {i18n.get('onion_disclaimer_3')}
        </Typography>
      </div>
    )
  }

  const endIcon = copied ? <Icon className={`${classes.checkIcon} far fa-check`} /> : <Icon className="far fa-link" />

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      TransitionComponent={TransitionComponent}
      keepMounted
    >
      <DialogTitle>{i18n.get('source')}</DialogTitle>
      <DialogContent
        id={id}
        className={classes.defaultColor}
      >
        {renderOnionWarning()}
        <Typography
          noWrap
          className={classes.source}
        >
          {i18n.get('source')}: {source}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="inherit"
        >{i18n.get('close')}
        </Button>
        <RaisedSecondaryButton
          disabled={copied}
          onClick={handleCopy}
          endIcon={endIcon}
        >
          {i18n.get('copy')}
        </RaisedSecondaryButton>
      </DialogActions>
    </Dialog>
  )
}

SourceDialog.defaultProps = {
  open: false,
  source: ''
}

SourceDialog.propTypes = {
  open: PropTypes.bool,
  source: PropTypes.string,
  network: PropTypes.string.isRequired,

  onClose: PropTypes.func.isRequired
}
