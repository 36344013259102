import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/page_identities'
import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  best: [],
  worst: []
})

export default handleActions({
  [Actions.pageIdentityPostsRequestSuccess]: (state, { payload: { best, worst } }) => state.merge({
    best: fromJS(best.map(post => post.id)),
    worst: fromJS(worst.map(post => post.id))
  }),
  [NewsActions.resetNews]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
