import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import { makeStyles } from 'tss-react/mui'

import Tag from 'containers/custom_tags/CustomTag'
import { Button, IconButton } from 'containers/themed'

import useDevice from 'hooks/useDevice'

const useStyles = makeStyles()({
  customTag: {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      width: 100
    },
    '&:hover': {
      backgroundColor: 'rgba(245, 245, 245, 0.7)'
    }
  },
  customTagImage: {
    width: 40,
    height: 20,
    marginLeft: 10,
    marginRight: 10
  },
  spacer: {
    flex: 1
  }
})

export default function CustomTag({
  assignedLabel,
  assignLabel,
  customTag,
  isAssigned,
  isSaving,
  onAddClick,
  onRemoveClick
}) {
  const { classes } = useStyles()
  const device = useDevice()

  let buttonLabel = assignLabel
  let onClick = () => onAddClick(customTag)
  let color = 'default'

  if (isAssigned) {
    onClick = () => onRemoveClick(customTag)
    buttonLabel = assignedLabel
    color = 'secondary'
  }

  let name = customTag.get('name').slice(0, 45)

  if (name.length !== customTag.get('name').length) {
    name = `${name}...`
  }

  return (
    <div
      className={classes.customTag}
      title={customTag.get('name')}
    >
      <div className={classes.customTagImage}>
        <Tag
          customTag={customTag}
          imageOnly
          preview
        />
      </div>

      {name}

      <span className={classes.spacer} />

      {device.get('xs') ? (
        <div>
          <IconButton
            color={color}
            onClick={onClick}
            saving={isSaving}
            size="large"
          >
            <LoyaltyIcon />
          </IconButton>
        </div>
      ) : (
        <Button
          color={color}
          onClick={onClick}
          saving={isSaving}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  )
}

CustomTag.defaultProps = {
  assignedLabel: 'unassign',
  assignLabel: 'assign'
}

CustomTag.propTypes = {
  customTag: PropTypes.instanceOf(Map).isRequired,
  assignedLabel: PropTypes.string,
  assignLabel: PropTypes.string,
  isAssigned: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,

  onAddClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired
}
