import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import { Chip, Box, Divider, Typography } from '@mui/material'

export default function ContactSupplier({ contact }) {
  const i18n = useI18n()

  const supplier = !contact.get('supplier') || contact.get('supplier') === 'client' ? i18n.get('my_contact') : 'pressrelations'
  const supplierChipColor = !contact.get('supplier') || contact.get('supplier') === 'client' ? 'default' : 'primary'

  return (
    <>
      <Divider style={{ margin: '1em -24px' }} />
      <Typography
        variant="subtitle2"
        pb={2}
        sx={{ fontSize: '90%' }}
      >{i18n.get('source')}
      </Typography>
      <Box>
        <Chip
          color={supplierChipColor}
          label={supplier}
        />
      </Box>
    </>
  )
}

ContactSupplier.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired
}
