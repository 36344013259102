// UI
export const getAnewstipUi = state => state.anewstip.get('ui')
export const getAnewstipStaticsRequestRunning = state => getAnewstipUi(state).get('staticsRequestRunning')
export const getAnewstipHasStatics = state => getAnewstipUi(state).get('hasStatics')
export const getAnewstipIsSimpleSearch = state => getAnewstipUi(state).get('isSimpleSearch')
export const getAnewstipSearchRequestRunning = state => getAnewstipUi(state).get('searchRequestRunning')
export const getJournalistsSearchRequestRunning = state => getAnewstipUi(state).get('journalistsSearchRequestRunning')
export const getOutletsSearchRequestRunning = state => getAnewstipUi(state).get('outletsSearchRequestRunning')
export const getArticlesSearchRequestRunning = state => getAnewstipUi(state).get('articlesSearchRequestRunning')
export const getAnewstipCombinedSearchRequestRunning = state => getAnewstipUi(state).get('combinedSearchRequestRunning')
export const getIsInitialSearch = state => getAnewstipUi(state).get('isInitialSearch')
export const getResultsTab = state => getAnewstipUi(state).get('resultsTab')
export const getAnewstipImportRequestRunning = state => getAnewstipUi(state).get('importRequestRunning')
export const getAnewstipReplaceRequestRunning = state => getAnewstipUi(state).get('replaceRequestRunning')
export const getDuplicateContactsDialogOpen = state => getAnewstipUi(state).get('duplicateContactsDialogOpen')
export const getAnewstipDistributionListContactDialogOpen = state => getAnewstipUi(state).get('distributionListCreateDialogOpen')
export const getShowDrillDownDialog = state => getAnewstipUi(state).get('showDrillDownDialog')
export const getDrillDownTab = state => getAnewstipUi(state).get('drillDownTab')
export const getDrillDownEntity = state => getAnewstipUi(state).get('drillDownEntity')
export const getJournalistsDrillDownRequestRunning = state => getAnewstipUi(state).get('journalistsDrillDownRequestRunning')
export const getOutletsDrillDownRequestRunning = state => getAnewstipUi(state).get('outletsDrillDownRequestRunning')
export const getArticlesDrillDownRequestRunning = state => getAnewstipUi(state).get('articlesDrillDownRequestRunning')
export const getIsNavigationLocked = state => getAnewstipUi(state).get('isNavigationLocked')
export const getIsLimitedFunctionality = state => getAnewstipUi(state).get('isLimitedFunctionality')
export const getAnewstipImportWithDistributionLists = state => getAnewstipUi(state).get('importWithDistributionLists')

// STATICS
export const getAnewstipStatics = state => state.anewstip.get('statics')
export const getAnewstipSuggest = state => getAnewstipStatics(state).get('suggest')

// SEARCH
export const getAnewstipSearchData = state => state.anewstip.get('search')

// FILTER
export const getAnewstipFiltersData = state => state.anewstip.get('filters')

// SELECTED
export const getAnewstipSelectedData = state => state.anewstip.get('selected')

// DATA
export const getAnewstipData = state => state.anewstip.get('data')
export const getAnewstipJournalists = state => getAnewstipData(state).get('journalists')
export const getAnewstipOutlets = state => getAnewstipData(state).get('outlets')
export const getAnewstipArticles = state => getAnewstipData(state).get('articles')

// IMPORTS
export const getAnewstipImports = state => state.anewstip.get('imports')
export const getAnewstipImportResult = state => getAnewstipImports(state).get('importResult')
export const getAnewstipImporContactsForDistributionLists = state => getAnewstipImports(state).get('importContactsForDistributionLists')

// DRILL DOWN DATA
export const getAnewstipDrillDownData = state => state.anewstip.get('drillDownData')

// DRILL DOWN FILTERS
export const getAnewstipDrillDownFilters = state => state.anewstip.get('drillDownFilters')
export const getAnewstipDrillDownTopics = state => state.anewstip.getIn(['drillDownFilters', 'topics'])

// DRILL DOWN HISTORY
export const getAnewstipDrillDownHistory = state => state.anewstip.get('drillDownHistory')
