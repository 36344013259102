import { connect } from 'react-redux'

import Overview from 'components/content_desk_new/contents/content_edit_dialog/overview'

import {
  getCDeskConfig,
  getCDeskContentFormData
} from 'selectors'

import {
  fetchDistributionListDropdownOptionsStart
} from 'actions/content_desk'

const mapStateToProps = state => ({
  config: getCDeskConfig(state),
  contentFormData: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    fetchDistributionListDropdownOptionsStart
  }
)(Overview)
