import { connect } from 'react-redux'

import SavedChartForm from 'components/administration/saved_charts/saved_chart_form'

import { getBaseUrl, getSavedChartsSaving, getTimezone } from 'selectors'

import { deleteSavedChartStart, updateSavedChartStart } from 'actions/saved_charts'

const mapStateToProps = (state, { savedChart }) => ({
  savedChart,
  baseUrl: getBaseUrl(state),
  saving: getSavedChartsSaving(state),
  timezone: getTimezone(state)
})

export default connect(
  mapStateToProps,
  {
    onDelete: deleteSavedChartStart,
    onSave: updateSavedChartStart
  }
)(SavedChartForm)
