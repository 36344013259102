import { connect } from 'react-redux'

import OtsEditor from 'components/content_desk_new/contents/content_edit_dialog/ots_editor'

import {
  updateContent
} from 'actions/content_desk'

import {
  getCDeskContentFormData
} from 'selectors'

const mapStateToProps = state => ({
  contentFormData: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: updateContent
  }
)(OtsEditor)
