import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { createLoadable } from 'utils/loadable'

import NrxProgressBar from 'components/nrx_progress_bar'
import ContentContainer from 'components/content_container'
import ErrorBoundary from 'components/error_boundary'
import Actions from 'components/custom_tags/actions'

import AppBar from 'containers/AppBar'
import RestrictionHint from 'containers/RestrictionHint'
import GroupMode from 'containers/custom_tags/GroupMode'

const useStyles = makeStyles()(theme => ({
  centered: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customTagsViewIcon: {
    fontSize: '30px !important',
    cursor: 'pointer',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  customTagsView: {
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1em'
    }
  }
}))

const CustomTagMode = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsCustomTagMode" */ 'containers/custom_tags/CustomTagMode')
))
const CustomTagDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsCustomTagDialog" */ 'containers/custom_tags/CustomTagDialog')
))
const RemoveDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsRemoveDialog" */ 'containers/custom_tags/RemoveDialog')
))
const EmptyCustomTagDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsEmptyCustomTagDialog" */ 'containers/custom_tags/EmptyCustomTagDialog')
))
const EditGroupDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsEditGroupDialog" */ 'containers/custom_tags/EditGroupDialog')
))
const DeleteGroupDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsDeleteGroupDialog" */ 'containers/custom_tags/DeleteGroupDialog')
))
const AssignCustomTagsToGroupDialog = createLoadable(() => (
  import(/* webpackChunkName: "CustomTagsAssignCustomTagsToGroupDialog" */ 'containers/custom_tags/AssignCustomTagsToGroupDialog')
))

export default function CustomTags({ i18n, loading, showRestrictionHint, showGroups, viewType, onViewChange }) {
  const { classes } = useStyles()

  const renderCustomTagsViewSwitch = () => {
    let listIconColor = 'disabled'
    let columnIconColor = 'secondary'

    if (viewType === 'list') {
      listIconColor = 'secondary'
      columnIconColor = 'disabled'
    }

    return (
      <div className={classes.customTagsView}>
        <Icon
          className={classes.customTagsViewIcon}
          onClick={() => onViewChange('list')}
          color={listIconColor}
        >
          list
        </Icon>
        <Icon
          className={classes.customTagsViewIcon}
          onClick={() => onViewChange('card')}
          color={columnIconColor}
        >
          view_column
        </Icon>
      </div>
    )
  }

  return (
    <>
      <AppBar
        title={i18n.get('pin_boards')}
        actions={<Actions />}
      />

      <ErrorBoundary>
        <AssignCustomTagsToGroupDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <CustomTagDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <RemoveDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <EmptyCustomTagDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <EditGroupDialog />
      </ErrorBoundary>
      <ErrorBoundary>
        <DeleteGroupDialog />
      </ErrorBoundary>
      {
        loading
          ? <div className={classes.centered}> <NrxProgressBar /> </div>
          : (
            <ContentContainer>
              {renderCustomTagsViewSwitch()}
              {(showGroups && viewType === 'card') && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">
                      {i18n.get('custom_tag_groups')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ErrorBoundary>
                      <GroupMode />
                    </ErrorBoundary>
                  </AccordionDetails>
                </Accordion>
              )}

              <br />
              {showRestrictionHint && <RestrictionHint />}
              <br />

              <ErrorBoundary>
                <CustomTagMode
                  filter
                  sorting
                />
              </ErrorBoundary>
            </ContentContainer>
          )
      }
    </>
  )
}

CustomTags.propTypes = {
  i18n: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  showRestrictionHint: PropTypes.bool.isRequired,
  showGroups: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired,

  onViewChange: PropTypes.func.isRequired
}
