import { connect } from 'react-redux'

import QueryTypeDropdown from 'components/query_type_dropdown'
import { setSelectedQueryType } from 'actions/themax'
import { getThemaxQueryType, getI18n } from 'selectors'

const mapStateToProps = state => ({
  value: getThemaxQueryType(state),
  i18n: getI18n(state),
  moduleName: 'themax'
})

export default connect(
  mapStateToProps,
  {
    onChange: setSelectedQueryType
  }
)(QueryTypeDropdown)
