import { connect } from 'react-redux'

import MentionsTimelineChart from 'components/darknet/static_charts/mentions_timeline_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'
import { chartDrilldown } from 'actions/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.MENTIONS_TIMELINE)
})

export default connect(mapStateToProps, {
  drilldown: chartDrilldown
})(MentionsTimelineChart)
