import { connect } from 'react-redux'

import CloseConfirmDialog from 'components/content_desk_new/campaigns/campaign_form/close_confirm_dialog'

const mapStateToProps = (_state, ownProps) => ({
  open: ownProps.open,
  onClose: ownProps.onClose,
  onSave: ownProps.onSave
})

export default connect(
  mapStateToProps,
  {
  }
)(CloseConfirmDialog)
