import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { Map } from 'immutable'
import AppBar from 'containers/AppBar'

import { Capabilities } from 'static/constants'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'
import PasswordForm from 'containers/personal_settings/PasswordForm'

import BasicSettings from 'containers/personal_settings/BasicSettings'
import MiscSettings from 'containers/personal_settings/MiscSettings'
import SpecificSettings from 'containers/personal_settings/SpecificSettings'
import CapSafe from 'containers/CapSafe'

import useI18n from 'hooks/useI18n'

import { Card } from '@mui/material'

const SpecificSettingsComp = CapSafe(StaggeredListItem, Capabilities.HAS_AUTOMATIC_SEARCH_RESULT_TRANSLATIONS)

const useStyles = makeStyles()(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      margin: '0 auto'
    }
  }
}))

export default function PersonalSettings({ user, hasDeeplApiKey }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <>
      <AppBar
        title={i18n.get('personal_settings')}
        actions={[
          <div key="version">
            v{process.env.VERSION}
          </div>
        ]}
      />
      <br />

      <StaggeredList>
        {user.get('loginTypeId') === 1 && (
          <StaggeredListItem delay={100}>
            <Card className={classes.container}>
              <PasswordForm />
            </Card>
          </StaggeredListItem>
        )}

        <br />

        <StaggeredListItem delay={150}>
          <Card className={classes.container}>
            <BasicSettings />
          </Card>
        </StaggeredListItem>

        <br />

        <StaggeredListItem delay={200}>
          <Card className={classes.container}>
            <MiscSettings />
          </Card>
        </StaggeredListItem>

        {hasDeeplApiKey && (
          <>
            <br />
            <SpecificSettingsComp delay={250}>
              <Card className={classes.container}>
                <SpecificSettings />
              </Card>
            </SpecificSettingsComp>
          </>
        )}

        <br />
      </StaggeredList>
    </>
  )
}

PersonalSettings.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  hasDeeplApiKey: PropTypes.bool.isRequired
}
