import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import DateForm from 'containers/darknet/DateForm'
import { Drawer } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  drawer: {
    overflowY: 'auto',
    [theme.breakpoints.up('lg')]: {
      width: 500
    }
  }
}))

export default function DateDrawer({ open, onClose }) {
  const { classes } = useStyles()

  return (
    <Drawer
      classes={{
        paper: classes.drawer
      }}
      anchor="right"
      open={open}
      onClose={() => onClose()}
    >
      <DateForm />
    </Drawer>
  )
}

DateDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}
