import { connect } from 'react-redux'

import TemplatePreviewDialog from 'components/content_desk_new/contents/content_edit_dialog/templates/template_preview_dialog'

import {
  closeTemplatePreviewDialog as closePreviewDialog
} from 'actions/content_desk'

import {
  getCDeskTemplatePreviewDialogOpen,
  getCDeskTemplateDesign
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskTemplatePreviewDialogOpen(state),
  editorDesign: getCDeskTemplateDesign(state)
})

export default connect(
  mapStateToProps,
  {
    closePreviewDialog
  }
)(TemplatePreviewDialog)
