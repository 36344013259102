import { connect } from 'react-redux'

import CustomTagAssistant from 'components/custom_tags/custom_tag_dialog/custom_tag_assistant'

import { getSelectedCustomTag } from 'selectors'
import { setSelectedCustomTag } from 'actions/custom_tags'

const mapStateToProps = state => ({
  customTag: getSelectedCustomTag(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setSelectedCustomTag
  }
)(CustomTagAssistant)
