import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { fromJS } from 'immutable'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import ShortTextIcon from '@mui/icons-material/ShortText'
import SummarizeIcon from '@mui/icons-material/Summarize'
import { makeStyles } from 'tss-react/mui'
import {
  Grid,
  Icon,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction
} from '@mui/material'
import { blue } from '@mui/material/colors'

import { getTonalityColor, getAverageTonalityOfNews } from 'utils/tonality'

import ChannelIcon from 'components/channel_icon'
import { IconButton } from 'containers/themed'
import CapSafe from 'containers/CapSafe'
import { Capabilities } from 'static/constants'
import Reactions from 'containers/media_reviews/media_review_detail/news_list_item/Reactions'

import useI18n from 'hooks/useI18n'
import Headline from '../headline'

const useStyles = makeStyles()(theme => ({
  col: {
    display: 'flex',
    alignItems: 'center'
  },
  publication: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      marginLeft: 45
    }
  },
  headline: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    textOverflow: 'ellipsis',
    fontWeight: '500',
    marginLeft: '10px'
  },
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  date: {
    [theme.breakpoints.down('md')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },

    marginLeft: '50%',
    fontSize: '14px'
  },
  channelIcon: {
    color: 'rgb(112, 112, 112)',
    fontSize: 'x-large',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    [theme.breakpoints.down('md')]: {
      fontSize: 'small'
    }
  },
  listItem: {
    height: '41px',
    padding: '0 5px',
    marginBottom: '10px',
    backgroundColor: theme.isDark ? theme.palette.background.paper : theme.palette.common.white,
    borderLeft: `10px solid ${theme.palette.grey[400]}`,
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none'
  },
  selectedForSorting: {
    backgroundColor: `${theme.isDark ? theme.palette.grey[600] : blue[100]} !important`
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: `1px solid ${theme.palette.grey[500]}`,
    height: '80%'
  },
  listItemRow: {
    width: '90%',
    [theme.breakpoints.down('lg')]: {
      width: '80%'
    },
    [theme.breakpoints.down('md')]: {
      width: '75%'
    }
  },
  hidden: {
    visibility: 'hidden'
  },
  clusteredListItem: {
    marginBottom: 0
  },
  reactions: {
    marginLeft: 5,
    padding: 5
  }
}))

const SafeEditButton = CapSafe(IconButton, [Capabilities.HAS_EDITING_FEATURE, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE])
const SafeDeleteButton = CapSafe(IconButton, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)
const SafeReactions = CapSafe(Reactions, Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)

const Row = props => (
  <Grid
    container
    direction="row"
    spacing={2}
    {...props}
  />
)

const Col = props => (
  <Grid
    item
    {...props}
  />
)

export default function Item({
  news,
  subsequentlyLoadedFullText,
  expanded,
  summaryExpanded,
  codeId,
  onRemoveClick,
  onEditClick,
  onSelect,
  showSnippet,
  hideSnippet,
  showSummary,
  hideSummary,
  selected,
  selectedForSorting,
  selectNewsForSorting,
  shiftSelectNewsForSorting,
  onFullTextClick,
  fullTextVisible,
  loadFullText,
  onReactionClick
}) {
  const { classes, cx } = useStyles()
  const i18n = useI18n()
  const isClusteredNews = news.getIn(['flags', 'clusteredNews'])
  const isTopicalCluster = news.get('clusterType') === 'topical' && !isClusteredNews

  const getPublicationNameOrAuthor = () => {
    if (isTopicalCluster) {
      return `${news.get('clusteredNews').size} ${i18n.get('publications')}`
    }

    const authors = news.get('authors')
    const hasAuthors = authors.size > 0
    const isSocialMedia = news.getIn(['flags', 'socialMedia'])

    let result = ''

    if (isSocialMedia && hasAuthors) {
      result = authors.first().get('name')
    } else {
      result = news.getIn(['publication', 'name'])
    }

    return result
  }

  const handleFullTextIconClick = () => {
    onFullTextClick(!fullTextVisible)

    if (!subsequentlyLoadedFullText) {
      loadFullText(news)
    }
  }

  const tonalityStyle = () => {
    let tonality = news.get('tonality')

    if (isTopicalCluster) {
      tonality = getAverageTonalityOfNews(news)
    }

    if (tonality !== null) {
      return {
        borderLeft: `10px solid ${getTonalityColor(tonality)}`
      }
    }

    return {}
  }

  const renderEssentialData = () => {
    let date = moment(news.get('articleDate'))

    if (news.getIn(['flags', 'germanTime'])) {
      date = date.tz('Europe/Berlin')
    }

    let clusteredNewsLabel = null

    if (news.get('clusteredNews').size && !isTopicalCluster && !isClusteredNews) {
      clusteredNewsLabel = (
        <b>
          +{news.get('clusteredNews').size}
        </b>
      )
    }

    const onSnippetClick = expanded ? hideSnippet : showSnippet
    const onSummaryClick = summaryExpanded ? hideSummary : showSummary

    let summaryLabel = summaryExpanded ? 'hide_summary' : 'show_summary'

    if (isTopicalCluster) {
      summaryLabel = summaryExpanded ? 'hide_newsflash' : 'show_newsflash'
    }

    return (
      <ListItemText>
        <Row className={classes.listItemRow}>
          <Col
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            classes={{ item: classes.col }}
          >
            <Row>
              <Col
                xs={3}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                classes={{ root: classes.col }}
              >
                <Checkbox
                  classes={{ root: cx(isClusteredNews && classes.hidden) }}
                  onChange={event => {
                    event.stopPropagation()

                    onSelect({
                      codeId,
                      newsIds: news.get('clusteredNews').map(c => c.get('id')).unshift(news.get('id')),
                      omitIntersection: false
                    })
                  }}
                  checked={selected}
                />

                <ChannelIcon
                  className={classes.channelIcon}
                  circle={false}
                  id={news.getIn(['publication', 'channelId'])}
                />
              </Col>
              <Col
                xs={8}
                sm={8}
                md={8}
                lg={8}
                xl={8}
                classes={{ item: classes.col }}
              >
                <div className={`${classes.publication} ${classes.text}`}>
                  {getPublicationNameOrAuthor()} {clusteredNewsLabel}
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={7}
            sm={7}
            md={7}
            lg={6}
            xl={7}
            classes={{ item: classes.col }}
          >
            <IconButton
              classes={{ root: cx(isClusteredNews && classes.hidden) }}
              disabled={!news.get('summary')}
              title={i18n.get(summaryLabel)}
              size="small"
              onClick={() => onSummaryClick(fromJS([{ newsId: news.get('id'), codeId }]))}
            >
              {isTopicalCluster ? <SummarizeIcon /> : <ShortTextIcon />}
            </IconButton>
            <IconButton
              classes={{ root: cx((isTopicalCluster || isClusteredNews) && classes.hidden) }}
              title={i18n.get(expanded ? 'hide_snippet' : 'show_snippet')}
              size="small"
              onClick={() => onSnippetClick(fromJS([{ newsId: news.get('id'), codeId }]))}
            >
              <Icon className="fa fa-receipt" />
            </IconButton>
            <IconButton
              classes={{ root: cx((isTopicalCluster || isClusteredNews) && classes.hidden) }}
              title={i18n.get(fullTextVisible ? 'hide_fulltext' : 'show_fulltext')}
              size="small"
              onClick={handleFullTextIconClick}
            >
              <Icon className="fa fa-align-justify" />
            </IconButton>

            <div className={`${classes.headline} ${classes.text}`}>
              <Headline
                useSummaryHeadline={isTopicalCluster}
                news={news}
              />
            </div>
          </Col>
          <Col
            xs={2}
            sm={2}
            md={2}
            lg={3}
            xl={2}
            classes={{ item: classes.col }}
          >
            {!isClusteredNews && (
              <div className={`${classes.date} ${classes.text}`}>
                {date.format('L')}
              </div>
            )}
          </Col>
        </Row>
      </ListItemText>
    )
  }

  const renderRightActions = () => {
    const actions = [
      <SafeEditButton
        key="edit"
        onClick={() => onEditClick(news)}
        size="small"
      >
        <EditIcon />
      </SafeEditButton>
    ]

    if (isClusteredNews) {
      return actions
    }

    if (onRemoveClick) {
      actions.push(
        <SafeDeleteButton
          key="close"
          onClick={() => onRemoveClick(news)}
          size="small"
        >
          <CloseIcon />
        </SafeDeleteButton>
      )
    }

    return (
      <ListItemSecondaryAction classes={{ root: classes.actions }}>
        <SafeReactions
          codeId={codeId}
          news={news}
          indicatorClassName={classes.reactions}
          onReactionClick={reactionType => onReactionClick({
            newsId: news.get('id'),
            codeId,
            reactionTypeId: reactionType.get('id')
          })}
        />

        {actions}
      </ListItemSecondaryAction>
    )
  }

  const handleOnClick = event => {
    const item = fromJS([{
      codeId,
      newsId: news.get('id')
    }])

    const keyPress = {
      shiftKey: event.shiftKey,
      ctrlKey: event.ctrlKey,
      metaKey: event.metaKey
    }

    if (keyPress.shiftKey) {
      shiftSelectNewsForSorting({ newsIds: item, isReplace: false, keyPress })
    } else {
      selectNewsForSorting({ newsIds: item, isReplace: !keyPress.ctrlKey && !keyPress.metaKey, keyPress })
    }
  }

  return (
    <div>
      <ListItem
        classes={{
          root: cx(
            classes.listItem,
            selectedForSorting && !isClusteredNews && classes.selectedForSorting,
            isClusteredNews && classes.clusteredListItem
          )
        }}
        style={tonalityStyle()}
        onClick={isClusteredNews ? undefined : handleOnClick}
        selected={selectedForSorting && !isClusteredNews}
      >
        {renderEssentialData()}
        {renderRightActions()}
      </ListItem>
    </div>
  )
}

Item.propTypes = {
  news: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  summaryExpanded: PropTypes.bool.isRequired,
  codeId: PropTypes.number,
  selectedForSorting: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  subsequentlyLoadedFullText: PropTypes.object,
  fullTextVisible: PropTypes.bool,

  onRemoveClick: PropTypes.func,
  onFullTextClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  showSnippet: PropTypes.func,
  hideSnippet: PropTypes.func,
  showSummary: PropTypes.func,
  hideSummary: PropTypes.func,
  selectNewsForSorting: PropTypes.func,
  shiftSelectNewsForSorting: PropTypes.func,
  loadFullText: PropTypes.func.isRequired,
  onReactionClick: PropTypes.func.isRequired
}
