import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { fromJS, Map } from 'immutable'
import {
  TableContainer,
  TablePagination,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { styled } from '@mui/material/styles'
import DeleteIcon from '@mui/icons-material/DeleteOutline'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'

import { IconButton } from 'containers/themed'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1)
}))

const DistributionListTable = ({
  contact,
  loading,
  onRemove
}) => {
  const i18n = useI18n()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const distributionLists = contact.get('distributionLists') || []

  const handleRemove = list => {
    onRemove(fromJS({ id: contact.get('id'), listId: list.get('id') }))
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const renderDistributionListRow = list => (
    <StyledTableRow
      key={`tag__${list.get('id')}`}
    >
      <StyledTableCell sx={{ width: '60px' }}>
        <PeopleAltOutlinedIcon sx={{
          color: t => !t.isDark && t.palette.grey[800]
        }}
        />
      </StyledTableCell>
      <StyledTableCell>{list.get('name')}</StyledTableCell>
      <StyledTableCell sx={{ textAlign: 'right', paddingRight: t => t.spacing(2) }}>
        <IconButton
          size="small"
          color="error"
          loading={loading}
          onClick={() => handleRemove(list)}
        >
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  )

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          padding="none"
          size="small"
        >
          <TableHead py={2} />
          <TableBody>
            {distributionLists.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(list => renderDistributionListRow(list))}
            {distributionLists.size > 10 && (
            <StyledTableRow>
              <TablePagination
                page={page}
                rowsPerPage={rowsPerPage}
                count={distributionLists.size}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                showFirstButton
                showLastButton
                labelRowsPerPage={i18n.get('rows_per_page')}
              />
            </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

DistributionListTable.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default DistributionListTable
