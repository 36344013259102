import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { equals as stringEquals } from 'utils/string'

export const Item = ({ id, news, NewsComponent, NewsComponentProps, ...props }, ref) => {
  const item = news.find(i => stringEquals(i.get('id'), id))

  return (
    <div
      {...props}
      ref={ref}
    >
      {!!item && (
        <NewsComponent
          news={item}
          selectedForSorting
          {...NewsComponentProps}
        />
      )}
    </div>
  )
}

export default forwardRef(Item)

Item.propTypes = {
  id: PropTypes.string.isRequired,
  news: PropTypes.instanceOf(List).isRequired,
  sortingItemKey: PropTypes.instanceOf(Map).isRequired,
  selectedNewsForSorting: PropTypes.instanceOf(Set).isRequired,
  NewsComponent: PropTypes.any.isRequired,
  NewsComponentProps: PropTypes.object,
  codeId: PropTypes.any
}
