import { handleActions, combineActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as Actions from 'actions/shopping_cart'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  selectedNews: Set([])
})

export default handleActions({
  [Actions.selectNewsForSorting]: (state, { payload: { news, isReplace, keyPress } }) => {
    const selectedNews = state.get('selectedNews')
    const deselect = (
      Boolean(keyPress.ctrlKey || keyPress.metaKey)
      && (selectedNews.some(n => n.get('newsId') === news.first().get('newsId')))
    )
    let replace = isReplace

    if (!replace) {
      replace = !selectedNews.isEmpty() && selectedNews.first().get('codeId') !== news.first().get('codeId')
    }

    let result = Set([])

    if (replace) {
      result = Set(news)
    } else if (deselect) {
      result = selectedNews.subtract(news)
    } else {
      result = selectedNews.union(news)
    }

    return state.merge({
      selectedNews: result
    })
  },

  [
  combineActions(
    Actions.moveNewsInShoppingCart,
    Actions.moveNewsInShoppingCartSublist,
    Actions.resetSortingSelection
  )
  ]: () => initialState,

  [Actions.updateTabIndex]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)
