import { fillDashboard } from 'utils/charts'

export const getSavedDashboardData = state => state.savedDashboard.get('data')
export const getSavedDashboard = state => fillDashboard(getSavedDashboardData(state))

export const getSavedDashboardUi = state => state.savedDashboard.get('ui')
export const getSavedDashboardFooterType = state => getSavedDashboardUi(state).get('searchProfileGroupId')
export const getSavedDashboardShowDialog = state => getSavedDashboardUi(state).get('showDialog')
export const getSavedDashboardUrlName = state => getSavedDashboardUi(state).get('urlName')
export const getSavedDashboardBaseUrl = state => getSavedDashboardUi(state).get('baseUrl')
export const getSavedDashboardTimezone = state => getSavedDashboardUi(state).get('timezone')
export const getSavedDashboardDarkMode = state => getSavedDashboardUi(state).get('darkMode')
export const getSavedDashboardAutoScroll = state => getSavedDashboardUi(state).get('autoScroll')
export const getSavedDashboardHideShareMenu = state => getSavedDashboardUi(state).get('hideShareMenu')
export const getSavedDashboardHideFooter = state => getSavedDashboardUi(state).get('hideFooter')
export const getSavedDashboardLocale = state => getSavedDashboardUi(state).get('locale')
export const getSavedDashboardId = state => getSavedDashboardUi(state).get('id')
export const getSavedDashboardSaving = state => getSavedDashboardUi(state).get('saving')
export const getSavedDashboardCheckingUrlName = state => getSavedDashboardUi(state).get('checkingUrlName')
export const getSavedDashboardUrlNameAvailable = state => getSavedDashboardUi(state).get('urlNameAvailable')
export const getSavedDashboardAutoReload = state => getSavedDashboardUi(state).get('autoReload')
export const getSavedDashboardHeaderLogoLeftUrl = state => getSavedDashboardUi(state).get('headerLogoLeftUrl')
export const getSavedDashboardHeaderLogoLeftLink = state => getSavedDashboardUi(state).get('headerLogoLeftLink')
export const getSavedDashboardHeaderLogoRightUrl = state => getSavedDashboardUi(state).get('headerLogoRightUrl')
export const getSavedDashboardHeaderLogoRightLink = state => getSavedDashboardUi(state).get('headerLogoRightLink')
export const getSavedDashboardHeaderTitle = state => getSavedDashboardUi(state).get('headerTitle')
export const getSavedDashboardHeaderSubtitle = state => getSavedDashboardUi(state).get('headerSubtitle')
export const getSavedDashboardHeaderBackgroundColor = state => getSavedDashboardUi(state).get('headerBackgroundColor')
export const getSavedDashboardFetchRequestRunning = state => getSavedDashboardUi(state).get('fetchRequestRunning')
export const getSavedDashboardDisableCookies = state => getSavedDashboardUi(state).get('disableCookies')

export const getSavedDashboardIsValid = state => Boolean(
  getSavedDashboardUrlName(state)
  && getSavedDashboardUrlNameAvailable(state)
  && getSavedDashboardTimezone(state)
  && getSavedDashboardLocale(state)
  && !getSavedDashboardCheckingUrlName(state)
)
