import { fromJS } from 'immutable'
import { IndexTypes, NewsPageModules } from 'static/constants'
import { createImmutableSelector } from 'utils/reselect'
import { getViewConfigIndexType, getViewConfigModuleName } from './news/view_config'
import { isMediaReviewDetail as getIsMediaReviewModule } from './routing'
import {
  getNewsPoolAllowedChannels,
  getNewsAllowedCodeIds,
  getNewsAllowedChannelIds,
  getNewsAllowedCountryIds,
  getEvaluationSteps,
  getStatementBasedCoding,
  getStatementEvaluationSteps
} from './config'

const createFilterByModuleNameSelector = selector => createImmutableSelector(
  selector,
  getViewConfigModuleName,
  getIsMediaReviewModule, // this is not nice but we need to know if we are in media review detail
  (items, moduleName, isMediaReviewDetail) => {
    if ([NewsPageModules.NEWS, NewsPageModules.ANALYSIS].indexOf(moduleName) !== -1 || isMediaReviewDetail) {
      return items.filter(e => e.get('available'))
    }

    if (moduleName === NewsPageModules.PROFILE_MONITORING) {
      return items.filter(e => e.get('availableInProfileMonitoring'))
    }

    return items
  }
)

export const getStatics = state => state.statics
export const getStaticMediaReviewCodes = createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'mediaReviewCodes']))
export const getAllStaticMediaReviewCodes = state => getStatics(state).getIn(['sorted', 'mediaReviewCodes'])
export const getStaticMediaReviewTopNewsCodes = state => getStaticMediaReviewCodes(state).filter(c => c.get('topnews'))
export const getStaticCountries = createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'countries']))
export const getStaticStates = state => getStatics(state).getIn(['sorted', 'states'])
export const getAllStaticCountries = createImmutableSelector(state => getStatics(state).getIn(['sorted', 'countries']), c => c)
export const getStaticLanguages = createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'languages']))
export const getAllStaticLanguages = state => getStatics(state).getIn(['sorted', 'languages'])
export const getStaticMediaTypes = createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'mediaTypes']))
export const getAllStaticMediaTypes = state => getStatics(state).getIn(['sorted', 'mediaTypes'])
export const getAllStaticMediaTopics = state => getStatics(state).getIn(['sorted', 'mediaTopics'])
export const getStaticDarkowlLanguages = createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'darkowlLanguages']))
export const getAllStaticDarkowlLanguages = state => getStatics(state).getIn(['sorted', 'darkowlLanguages'])
export const getThemaxCountries = createImmutableSelector(state => getStatics(state).getIn(['result', 'themaxCountryIds']),
  getAllStaticCountries,
  (ids, countries) => (
    countries.filter(c => ids.includes(c.get('id')))
  ))

export const getStaticChannels = createImmutableSelector(
  state => getStatics(state).getIn(['sorted', 'channels']),
  getViewConfigIndexType,
  getViewConfigModuleName,
  getNewsPoolAllowedChannels,
  createFilterByModuleNameSelector(state => getStatics(state).getIn(['sorted', 'channels'])),
  (channels, indexType, moduleName, allowedChannels, filteredChannels) => {
    if (indexType === IndexTypes.DEFAULT) {
      return filteredChannels
    }

    const allowedChannelIds = allowedChannels.map(c => c.get('id'))

    return (
      (channels || fromJS([]))
        .filter(
          c => c.get('newsPoolEnabled') && (allowedChannelIds.isEmpty() || allowedChannelIds.includes(c.get('id')))
        )
    )
  }
)
export const getAllStaticChannels = state => getStatics(state).getIn(['sorted', 'channels'])

export const getStaticFilterGroups = state => getStatics(state).getIn(['sorted', 'filterGroups'])
export const getStaticDistributionAreas = state => getStatics(state).getIn(['sorted', 'distributionAreas'])
export const getStaticMediaReviewTypes = state => getStatics(state).getIn(['sorted', 'mediaReviewTypes'])
export const getStaticMediaReviewTypesForSubscription = state => getStaticMediaReviewTypes(state).filter(mrt => mrt.get('subscription'))
export const getStaticMediaReviewTypesForFiltering = state => getStaticMediaReviewTypes(state).filter(mrt => mrt.get('visibleForNrx'))
export const getStaticMediaSources = state => getStatics(state).getIn(['sorted', 'mediaSources'])
export const getStaticDispatchConfigs = state => getStatics(state).getIn(['sorted', 'htmlDispatchConfigs'])
export const getStaticPdfDispatchConfigs = state => getStatics(state).getIn(['sorted', 'pdfDispatchConfigs'])
export const getStaticGoogleAnalyticsWebsites = state => getStatics(state).getIn(['sorted', 'googleAnalyticsWebsites'])
export const getStaticFacebookAnalyticsPages = state => getStatics(state).getIn(['sorted', 'facebookAnalyticsPages'])
export const getStaticFacebookAnalyticsPageById = (state, id) => (
  getStatics(state).getIn(['entities', 'facebookAnalyticsPages', id.toString()])
)
export const getStaticLinkedInAnalyticsOrganizations = state => getStatics(state).getIn(['sorted', 'linkedInAnalyticsOrganizations'])
export const getStaticAnalysisCodes = state => getStatics(state).getIn(['sorted', 'analysisCodes'])
export const getStaticAnalysisCodesMap = state => getStatics(state).getIn(['entities', 'analysisCodes'])
export const getStaticAnalysisCodesNodeIds = state => getStatics(state).getIn(['entities', 'analysisCodes']).keySeq().toList()
export const getStaticAnalysisCodeById = (state, id) => getStaticAnalysisCodesMap(state).get(id.toString())
export const getStaticAnalysisCodesByIds = (state, ids) => ids.map(id => getStaticAnalysisCodeById(state, id)).filter(c => c).toList()
export const getStaticTonalities = state => getStatics(state).getIn(['sorted', 'tonalities'])
export const getStaticCdContentTypes = state => getStatics(state).getIn(['sorted', 'ccdContentTypes'])
export const getStaticSsoLinks = state => getStatics(state).getIn(['sorted', 'ssoLinks'])
export const getStaticNewsguardRanks = state => getStatics(state).getIn(['sorted', 'newsguardRanks'])
export const getStaticNewsguardOrientations = state => getStatics(state).getIn(['sorted', 'newsguardOrientations'])
export const getStaticInterfaces = state => getStatics(state).getIn(['sorted', 'interfaces'])
export const getStaticSuppliers = state => getStatics(state).getIn(['sorted', 'suppliers'])
export const getStaticReactionTypes = state => getStatics(state).getIn(['sorted', 'reactionTypes'])
export const getStaticTonalitiesByEvaluationSteps = createImmutableSelector(
  getStaticTonalities,
  getEvaluationSteps,
  getStatementEvaluationSteps,
  getStatementBasedCoding,
  (tonalities, evaluationSteps, statementEvaluationSteps, statementBasedCoding) => (
    tonalities
      .sortBy(t => Math.abs(t.get('value')))
      .slice(0, statementBasedCoding ? statementEvaluationSteps : evaluationSteps)
      .concat(tonalities.filter(t => t.get('id') === 'unknown'))
      .sortBy(t => t.get('value'))
  )
)

export const getStaticExternalAccounts = createImmutableSelector(
  state => getStatics(state).getIn(['sorted', 'externalAccounts']),
  externalAccounts => externalAccounts.groupBy(a => a.get('grootExternalAccountTypeId'))
)

export const getStaticMediaReviewCodeIds = state => getStatics(state).getIn(['result', 'mediaReviewCodes'])
export const getStaticMediaReviewTopNewsCodeIds = state => getStaticMediaReviewTopNewsCodes(state).map(c => c.get('id'))
export const getStaticCountryIds = state => getStatics(state).getIn(['result', 'countries'])
export const getStaticLanguagesIds = state => getStatics(state).getIn(['result', 'languages'])
export const getStaticMediaTypeIds = state => getStatics(state).getIn(['result', 'mediaTypes'])
export const getStaticChannelIds = state => getStatics(state).getIn(['result', 'channels'])
export const getStaticFilterGroupIds = state => getStatics(state).getIn(['result', 'filterGroups'])
export const getStaticMediaReviewTypeIds = state => getStatics(state).getIn(['result', 'mediaReviewTypes'])
export const getStaticDispatchConfigIds = state => getStatics(state).getIn(['result', 'htmlDispatchConfigs'])
export const getStaticAnalysisCodeIds = state => getStatics(state).getIn(['result', 'analysisCodes'])

export const getStaticDispatchConfigById = (state, id) => (
  getStatics(state).getIn(['entities', 'htmlDispatchConfigs', (id || '').toString()], fromJS({}))
)

export const getCreatableChannels = state => getStaticChannels(state).filter(c => c.get('creatable')).sortBy(c => c.get('id'))
export const getCreatableMediaSources = state => (
  getStatics(state).getIn(['sorted', 'mediaSources']).filter(c => c.get('creatable')).sortBy(c => c.get('id'))
)
export const getAllCreatableChannels = state => (
  getStatics(state).getIn(['sorted', 'channels']).filter(c => c.get('creatable')).sortBy(c => c.get('id'))
)

export const getRestrictedStaticMediaReviewCodes = createImmutableSelector(
  getAllStaticMediaReviewCodes,
  getNewsAllowedCodeIds,
  (all, allowed) => all.filter(c => allowed.includes(c.get('id')))
)

export const getAllowedStaticMediaReviewCodes = createImmutableSelector(
  getAllStaticMediaReviewCodes,
  getNewsAllowedCodeIds,
  (all, allowed) => {
    if (allowed.isEmpty()) {
      return all
    }

    return all.filter(c => allowed.includes(c.get('id')))
  }
)
export const getRestrictedStaticChannels = createImmutableSelector(
  getAllStaticChannels,
  getNewsAllowedChannelIds,
  (all, allowed) => all.filter(c => allowed.includes(c.get('id')))
)
export const getRestrictedStaticCountries = createImmutableSelector(
  getAllStaticCountries,
  getNewsAllowedCountryIds,
  (all, allowed) => all.filter(c => allowed.includes(c.get('id')))
)

export const getStaticPaidFeatures = state => getStatics(state).getIn(['sorted', 'paidFeatures'])
export const getStaticPaidFeatureById = (state, id) => getStatics(state).getIn(['entities', 'paidFeatures', id.toString()])

