import { connect } from 'react-redux'

import ContentEditDialog from 'components/content_desk_new/contents/content_edit_dialog'

import {
  lockContentStart,
  unlockContentStart as onClose
} from 'actions/content_desk'

import {
  getCDeskShowEditContentDialog,
  getCDeskNewDialogView,
  getCDeskContentEditTabView,
  getCDeskContentIsLoading,
  getCDeskContentDialogCloneMode,
  getCDeskContentFormData,
  getCDeskDuplicateError
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowEditContentDialog(state),
  view: getCDeskNewDialogView(state),
  tabView: getCDeskContentEditTabView(state),
  isLoading: getCDeskContentIsLoading(state),
  isInCloneMode: getCDeskContentDialogCloneMode(state),
  contentFormData: getCDeskContentFormData(state),
  duplicateError: getCDeskDuplicateError(state)
})

export default connect(
  mapStateToProps,
  {
    onRelock: () => lockContentStart(),
    onUnlock: () => onClose(),
    onClose
  }
)(ContentEditDialog)
