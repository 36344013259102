import { createAction } from 'redux-actions'

const prefix = 'UST/'

export const ustEventStarted = createAction(`${prefix}EVENT_STARTED`)
export const ustEventSuccess = createAction(`${prefix}EVENT_SUCCESS`)
export const ustEventError = createAction(`${prefix}EVENT_ERROR`)

export const ustTagStarted = createAction(`${prefix}TAG_STARTED`)
export const ustTagSuccess = createAction(`${prefix}TAG_SUCCESS`)
export const ustTagError = createAction(`${prefix}TAG_ERROR`)

export const ustPageEnter = createAction(`${prefix}PAGE_ENTER`)
export const ustPageLeave = createAction(`${prefix}PAGE_LEAVE`)

export const ustEnableRecording = createAction(`${prefix}ENABLE_RECORDING`)
export const ustInitialise = createAction(`${prefix}INITIALISE`)
