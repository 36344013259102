import { createAction } from 'redux-actions'

let prefix = 'NOTIFICATIONS'
const defaultPrefix = prefix

prefix = `${defaultPrefix}/UI/`
export const openDrawer = createAction(`${prefix}OPEN_DRAWER`)
export const closeDrawer = createAction(`${prefix}CLOSE_DRAWER`)

prefix = `${defaultPrefix}/DATA/`
export const setNotifications = createAction(`${prefix}SET_NOTIFICATIONS`)
export const updateNotifications = createAction(`${prefix}UPDATE_NOTIFICATIONS`)
export const markAsReadStart = createAction(`${prefix}MARK_AS_READ_START`)
export const markAsReadSuccess = createAction(`${prefix}MARK_AS_READ_SUCCESS`)
export const markAsReadError = createAction(`${prefix}MARK_AS_READ_ERROR`)
export const fetchNotificationsStart = createAction(`${prefix}FETCH_NOTIFICATIONS_START`)
export const fetchNotificationsSuccess = createAction(`${prefix}FETCH_NOTIFICATIONS_SUCCESS`)
export const fetchNotificationsError = createAction(`${prefix}FETCH_NOTIFICATIONS_ERROR`)

prefix = `${defaultPrefix}/FILTER/`
export const setNotificationsPage = createAction(`${prefix}SET_NOTIFICATIONS_PAGE`)
export const setLastPage = createAction(`${prefix}SET_LAST_PAGE`)
