import { connect } from 'react-redux'

import FilterDrawer from 'components/darknet/dashboard/filter_drawer'

import { toggleAdvancedSearch as onClose } from 'actions/darknet'

import { getDarknetShowAdvancedSearch, getDevice } from 'selectors'

const mapStateToProps = state => ({
  device: getDevice(state),
  open: getDarknetShowAdvancedSearch(state)
})

export default connect(
  mapStateToProps, {
    onClose
  }
)(FilterDrawer)
