import { connect } from 'react-redux'

import {
  getCDeskCalendarLoadingContentIds
} from 'selectors'

import ShortContent from 'components/content_desk_new/contents/short_contents/short_content'

const mapStateToProps = (state, ownProps) => ({
  requestRunning: !!getCDeskCalendarLoadingContentIds(state).get(ownProps.content.get('id'))
})

export default connect(
  mapStateToProps,
  {
  }
)(ShortContent)
