import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/administration'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  ccdAllowedDomains: {
    page: 1,
    limit: 5
  }
})

export default handleActions({
  [Actions.setAllowedDomainsPage]: (state, { payload }) => state.setIn(['ccdAllowedDomains', 'page'], payload),
  [AppActions.resetState]: () => initialState
}, initialState)
