import { connect } from 'react-redux'

import TemplatesEditorDialog from 'components/email_templates_library/templates_editor_dialog'

import {
  getETLibraryShowEditorDialog,
  getETLibraryShowSaveDialog,
  getCDeskEmailEditorLoadingStatus,
  getETLibraryTemplateFormData,
  getETLibrarySaveRequestRunning,
  getCapabilities
} from 'selectors'
import {
  closeEditorDialog,
  closeSaveDialog,
  openSaveDialog,
  setTemplateFormData as onChange,
  saveTemplateStart as onSave
} from 'actions/email_templates_library'

const mapStateToProps = state => ({
  emailEditorLoading: getCDeskEmailEditorLoadingStatus(state),
  isEditorDialogOpen: getETLibraryShowEditorDialog(state),
  isSaveDialogOpen: getETLibraryShowSaveDialog(state),
  data: getETLibraryTemplateFormData(state),
  loading: getETLibrarySaveRequestRunning(state),
  capabilities: getCapabilities(state)
})

export default connect(
  mapStateToProps,
  {
    closeEditorDialog,
    closeSaveDialog,
    openSaveDialog,
    onChange,
    onSave
  }
)(TemplatesEditorDialog)
