import { connect } from 'react-redux'

import EventContent from 'components/themax/calendar/event_content'

const mapStateToProps = (_state, { event }) => ({
  event
})

export default connect(
  mapStateToProps,
  {}
)(EventContent)
