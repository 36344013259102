import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import { Tooltip, MenuItem } from '@mui/material'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const useStyles = makeStyles()(() => ({
  actionIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 8
  },
  actionName: {
    marginLeft: 12
  }
}))

const CampaignDelete = ({
  campaign,
  onOpen,
  setDeleteData
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const ccdContents = campaign.get('ccdContents')
  const hasScheduledContents = ccdContents.some(el => el.get('status') === 'scheduled')
  const isDefaultCampaign = campaign.get('defaultAssigned')
  const deleteDisabled = isDefaultCampaign || hasScheduledContents

  let deleteTooltipTitle = ''

  if (hasScheduledContents) {
    deleteTooltipTitle = i18n.get('campaign_has_scheduled_contents')
  }

  if (isDefaultCampaign) {
    deleteTooltipTitle = i18n.get('campaign_default_cannot_be_deleted')
  }

  const handleDeleteDialogOpen = e => {
    if (deleteDisabled) {
      e.preventDefault()

      return
    }

    e.stopPropagation()
    setDeleteData({ id: campaign.get('id') })
    onOpen()
  }

  return (
    <MenuItem
      sx={{
        '&.Mui-disabled': {
          pointerEvents: 'auto'
        }
      }}
      disabled={deleteDisabled || false}
      onClick={handleDeleteDialogOpen}
    >
      <Tooltip
        title={deleteTooltipTitle}
        placement="left"
        arrow
      >
        <span style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <DeleteOutlineIcon className={classes.actionIcon} />
          <div className={classes.actionName}>
            {i18n.get('delete')}
          </div>
        </span>
      </Tooltip>
    </MenuItem>
  )
}

CampaignDelete.propTypes = {
  campaign: PropTypes.object.isRequired,

  onOpen: PropTypes.func.isRequired,
  setDeleteData: PropTypes.func.isRequired
}

export default CampaignDelete
