import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { List, fromJS } from 'immutable'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import { Networks } from 'static/constants'

import { mapToOptions } from 'utils/autocomplete'

import { makeStyles } from 'tss-react/mui'
import { Grid, Typography, Divider, FormLabel, FormControl } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'

import { Button, Autocomplete, IconButton, RaisedSecondaryButton } from 'containers/themed'

import ActionBar from 'components/search_form/action_bar'
import HackishnessSlider from 'components/darknet/search_form/hackishness_slider'

const useStyles = makeStyles()(theme => ({
  actionBar: {
    backgroundColor: 'inherit'
  },
  spacer: {
    flex: 0.9,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  searchForm: {
    padding: 20,
    [theme.breakpoints.up('lg')]: {
      height: '90%',
      overflowY: 'auto'
    }
  },
  tonality: {
    padding: '0 12px'
  },
  divider: {
    marginBottom: 10
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:last-child': {
      marginRight: -5
    }
  },
  hackishnessFilter: {
    padding: '0px 20px 0px 11px',
    width: '100%'
  }
}))

const mapToEntity = (entities, values) => (values || List([]))
  .map(({ value }) => {
    const found = entities.find(entity => entity.get('id') === value)

    if (found) {
      return found.toJS()
    }

    return null
  })
  .filter(e => e)

const Item = props => (
  <Grid
    item
    xl={12}
    lg={12}
    md={6}
    sm={6}
    xs={6}
    {...props}
  />
)

export default function SearchForm(props) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  const networks = fromJS(Networks.map(network => ({ ...network, name: i18n.get(network.id) })))

  const { uiApplyFilters, setSelectedFilters, uiResetFilters, onClose, languages, searchQueries } = props

  const [selectedNetworks, setSelectedNetworks] = useState(mapToOptions(props.selectedNetworks))
  const [selectedLanguages, setSelectedLanguages] = useState(mapToOptions(props.selectedLanguages))
  const [selectedHackishness, setSelectedHackishness] = useState({ minHackishness: props.selectedMinHackishness })
  const [selectedSearchQueries, setSelectedSearchQueries] = useState(mapToOptions(props.selectedSearchQueries))
  const [active, setActive] = useState(props.active)
  const [previousActive, setPreviousActive] = useState(null)

  if (active !== previousActive) {
    setSelectedNetworks(mapToOptions(props.selectedNetworks))
    setSelectedLanguages(mapToOptions(props.selectedLanguages))
    setSelectedSearchQueries(mapToOptions(props.selectedSearchQueries))
    setSelectedHackishness({ minHackishness: props.selectedMinHackishness })
    setPreviousActive(active)
    setActive(props.active)
  }

  const handleApply = () => {
    setSelectedFilters({
      networks: mapToEntity(networks, selectedNetworks),
      languages: mapToEntity(languages, selectedLanguages),
      searchQueries: mapToEntity(searchQueries, selectedSearchQueries),
      minHackishness: selectedHackishness.minHackishness
    })

    uiApplyFilters()
  }

  const handleResetFilters = () => {
    uiResetFilters()
  }

  const renderAutocomplete = (labelKey, onChange, source, value, helperText) => {
    if (!source.size) {
      return null
    }

    return (
      <Autocomplete
        key={labelKey}
        variant="outlined"
        isMulti
        isClearable
        onChange={onChange}
        options={mapToOptions(source)}
        value={value}
        placeholder=""
        label={i18n.get(labelKey)}
        textFieldProps={{
          helperText
        }}
      />
    )
  }

  return (
    <div className={classes.searchForm}>
      <div className={classes.header}>
        <Typography variant="h5">
          {i18n.get('advanced_search')}
        </Typography>
        <IconButton
          onClick={() => onClose()}
          title={i18n.get('close')}
          size="large"
        >
          {device.get('lt-lg') ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>

      <Divider classes={{ root: classes.divider }} />

      <Grid
        container
        direction="row"
        spacing={2}
      >
        <Item>
          {renderAutocomplete(
            'select_networks',
            value => setSelectedNetworks(value),
            networks,
            selectedNetworks,
            ''
          )}
        </Item>

        <Item>
          {renderAutocomplete(
            'select_languages',
            value => setSelectedLanguages(value),
            languages,
            selectedLanguages,
            ''
          )}
        </Item>

        <Item>
          {renderAutocomplete(
            'select_search_queries',
            value => setSelectedSearchQueries(value),
            searchQueries,
            selectedSearchQueries,
            ''
          )}
        </Item>

        <Item>
          <FormControl
            component="fieldset"
            className={classes.hackishnessFilter}
          >
            <FormLabel
              component="legend"
            >{i18n.get('min_hackishness')}
            </FormLabel>
            <HackishnessSlider
              hackishness={selectedHackishness.minHackishness}
              setHackishness={setSelectedHackishness}
              showTitle={false}
            />
          </FormControl>
        </Item>
      </Grid>

      {device.get('lt-lg') && (
        <>
          <br />
          <Divider />
          <br />
        </>
      )}

      <ActionBar className={classes.actionBar}>
        {device.get('lt-sm') && (
        <IconButton
          onClick={() => props.toggleHelpDialog()}
          size="large"
        >
          <HelpIcon />
        </IconButton>
        )}

        {device.get('gt-xs') && (
        <Button
          onClick={() => props.toggleHelpDialog()}
          startIcon={<HelpIcon />}
        >
          {i18n.get('help')}
        </Button>
        )}

        <div className={classes.spacer} />

        <div>
          {device.get('lt-sm') && (
          <IconButton
            onClick={onClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
          )}
          {device.get('gt-xs') && (
          <Button
            onClick={onClose}
          >
            {i18n.get('close')}
          </Button>
          )}

          {device.get('lt-sm') && (
          <IconButton
            variant="contained"
            onClick={handleResetFilters}
            size="large"
          >
            <SettingsBackupRestoreIcon />
          </IconButton>
          )}
          {device.get('gt-xs') && (
          <Button
            variant="contained"
            onClick={handleResetFilters}
          >
            {i18n.get('reset_filters')}
          </Button>
          )}

          <RaisedSecondaryButton onClick={handleApply}>
            {i18n.get('apply_filters')}
          </RaisedSecondaryButton>
        </div>
      </ActionBar>

    </div>
  )
}

SearchForm.propTypes = {
  languages: PropTypes.instanceOf(List).isRequired,
  searchQueries: PropTypes.instanceOf(List).isRequired,
  selectedMinHackishness: PropTypes.number.isRequired,
  selectedNetworks: PropTypes.instanceOf(List).isRequired,
  selectedLanguages: PropTypes.instanceOf(List).isRequired,
  selectedSearchQueries: PropTypes.instanceOf(List).isRequired,
  active: PropTypes.bool.isRequired,
  uiApplyFilters: PropTypes.func.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  uiResetFilters: PropTypes.func.isRequired,
  toggleHelpDialog: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}
