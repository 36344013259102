import { handleActions } from 'redux-actions'

import * as Actions from 'actions/news'
import * as AppActions from 'actions/app'

export const initialState = 'desc'

export default handleActions({
  [Actions.setSortDirection]: state => (state === 'desc' ? 'asc' : 'desc'),

  [AppActions.resetState]: () => initialState
}, initialState)
