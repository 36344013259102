import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'

import { ContentDeskSearchFields } from 'static/constants'

const ccdSearchFieldsMapping = {
  [ContentDeskSearchFields.CONTENT_PLAIN_TEXT]: {
    stateKeyName: 'contentSearchQueries'
  },
  [ContentDeskSearchFields.CONTENT_TITLE]: {
    stateKeyName: 'contentSearchQueries'
  },
  [ContentDeskSearchFields.CAMPAIGN_NAME]: {
    stateKeyName: 'campaignSearchQueries'
  }
}

export const initialState = fromJS({
  campaignPage: 1,
  campaignLimit: 10,
  campaignSortBy: '',
  campaignSortOrder: '',
  contentPage: 1,
  contentLimit: 10,
  contentSortBy: '',
  contentSortOrder: '',
  contentSearchQueries: [],
  campaignSearchQueries: [],
  mediaFilesPage: 1,
  mediaFilesLimit: 10,
  campaignFilters: {
    operator: 'and',
    conditions: []
  },
  contentFilters: {
    operator: 'and',
    conditions: []
  },
  kpiStatisticsFilters: {
    type: 'all',
    dateRange: 'last_30_days'
  }
})

export default handleActions({
  [Actions.addSearchQuery]: (state, { payload: { query, selectedQueryType } }) => (
    state.update(ccdSearchFieldsMapping[selectedQueryType].stateKeyName, queries => queries.push(fromJS({
      label: query,
      value: query,
      field: selectedQueryType
    })))),
  [Actions.removeFilter]: (state, { payload: { field, index } }) => (
    state.update(ccdSearchFieldsMapping[field].stateKeyName, list => list.delete(index))
  ),
  [Actions.invertFilter]: (state, { payload: { field, index } }) => (
    state.updateIn([ccdSearchFieldsMapping[field].stateKeyName, index, 'inverted'], inverted => !inverted)
  ),
  [Actions.updateFilter]: (state, { payload: { filter, index } }) => {
    const { stateKeyName } = ccdSearchFieldsMapping[filter.get('field')]

    return state.setIn([stateKeyName, index], filter.set('updated', true))
  },
  [Actions.setLimit]: (state, { payload: { key, value } }) => state.set(key, value),
  [Actions.setPage]: (state, { payload: { key, value } }) => state.set(key, value),
  [Actions.setSortBy]: (state, { payload: { type, sortBy, sortOrder } }) => {
    if (type === 'campaigns') {
      return state.merge({
        campaignSortBy: sortBy,
        campaignSortOrder: sortOrder
      })
    }

    if (type === 'contents') {
      return state.merge({
        contentSortBy: sortBy,
        contentSortOrder: sortOrder
      })
    }

    return state
  },
  [Actions.setContentFilters]: (state, { payload }) => state.set('contentFilters', fromJS(payload)),
  [Actions.setCampaignFilters]: (state, { payload }) => state.set('campaignFilters', fromJS(payload)),
  [Actions.resetFilters]: state => (
    state.merge(initialState)
  ),
  [Actions.incrementMediaFilesPage]: state => state.set('mediaFilesPage', state.get('mediaFilesPage') + 1),
  [Actions.setKpiStatisticsFilters]: (state, { payload: { key, value } }) => state.setIn(['kpiStatisticsFilters', key], value),
  [AppActions.resetState]: () => initialState
}, initialState)
