import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import useI18n from 'hooks/useI18n'
import { useSnackbar } from 'notistack'
import { Map } from 'immutable'

import { Card, CardHeader, CardContent, Grid } from '@mui/material'

import { IconButton, Input } from 'containers/themed'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import red from '@mui/material/colors/red'
import green from '@mui/material/colors/green'
import grey from '@mui/material/colors/grey'

const useStyles = makeStyles()({
  input: {
    display: 'flex',
    alignContent: 'center'
  }
})

function ShowKey({ apiKey, onDelete, loading }) {
  const i18n = useI18n()

  return (
    <>
      {apiKey.get('key')}
      <IconButton
        onClick={() => onDelete(apiKey.get('id'))}
        title={i18n.get('delete')}
        disabled={loading}
      >
        <CloseIcon style={{ color: loading ? grey[500] : red[500] }} />
      </IconButton>
    </>
  )
}

function AddKey({ onSubmit, loading }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const [input, setInput] = useState('')
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const handleCancel = () => {
    closeSnackbar()
    setInput('')
  }
  const handleChange = e => setInput(e.target.value)

  const handleSubmit = () => {
    if (input) {
      onSubmit({ name: input, typeId: 2 })

      return closeSnackbar()
    }

    return enqueueSnackbar(`${i18n.get('missing_access_token')}`, {
      variant: 'error',
      preventDuplicate: true
    })
  }

  return (
    <div className={classes.input}>
      <Input
        value={input}
        onChange={handleChange}
        label={i18n.get('anewstip_api_key')}
        disabled={loading}
      />
      <IconButton
        onClick={handleCancel}
        title={i18n.get('cancel')}
        disabled={loading}
      >
        <CancelIcon style={{ color: loading ? grey[500] : red[500] }} />
      </IconButton>
      <IconButton
        onClick={handleSubmit}
        title={i18n.get('submit')}
        disabled={loading}
      >
        <CheckIcon style={{ color: loading ? grey[500] : green[500] }} />
      </IconButton>
    </div>
  )
}

export default function JournalistSearch({
  anewstipApiKey,
  loading,
  onSubmit,
  onDelete
}) {
  const { classes } = useStyles()

  return (
    <CardContent>
      <Grid
        container
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Card>
            <CardHeader
              classes={{ root: classes.buttonCardHeader }}
              title={(
                <div className={classes.title}>
                  <span>JournalistSearch</span>

                  {/* <Description /> */}
                </div>
              )}
            />

            <CardContent>
              {!anewstipApiKey && <AddKey onSubmit={onSubmit} />}
              {anewstipApiKey && (
              <ShowKey
                apiKey={anewstipApiKey}
                onDelete={onDelete}
                loading={loading}
              />
              )}
            </CardContent>

          </Card>
        </Grid>
      </Grid>
    </CardContent>
  )
}

JournalistSearch.propTypes = {
  anewstipApiKey: PropTypes.instanceOf(Map),
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}

AddKey.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

ShowKey.propTypes = {
  apiKey: PropTypes.instanceOf(Map),
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}
