import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Alert,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  Pagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Box,
  FormControlLabel,
  Switch
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import ContactsSortbar from 'containers/contact_management/ContactsSortbar'
import ContactsItem from 'containers/contact_management/ContactsItem'
import ImportHelpDialog from 'containers/contact_management/ImportHelpDialog'

const useStyles = makeStyles()(theme => ({
  container: {
    padding: '1em',
    backgroundColor: theme.isDark ? '#1E1E1E' : null
  },
  title: {
    color: !theme.isDark && '#404040',
    marginLeft: '1em'
  },
  accordion: {
    backgroundColor: theme.isDark ? null : '#F0F0F0',
    marginLeft: '2em',
    marginRight: '2em',
    marginBottom: '5em'
  },
  pagination: {
    display: 'flex',
    marginBottom: '2em',
    justifyContent: 'center'
  },
  backdrop: {
    position: 'absolute'
  },
  circularProgress: {
    marginLeft: '1em',
    display: 'flex'
  },
  progressWrapper: {
    flexGrow: 1
  }
}))

const Contacts = ({ contacts,
  page,
  total,
  loading,
  hasSearchQueries,
  showUnsubscribed,
  onPageChange,
  toggleUnsubscribed,
  fetchContacts }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (total === 0 && hasSearchQueries) {
      setExpanded(true)
    }
  }, [total, hasSearchQueries])

  const handlePageChange = (_event, value) => {
    onPageChange(value)
  }

  const renderSortedContent = () => contacts.map(contact => (
    <ContactsItem
      contact={contact}
      key={`contact__${contact.get('id')}`}
    />
  ))

  const renderResult = () => {
    if (total === 0 && hasSearchQueries) {
      return (
        <AccordionDetails>
          <Alert severity="warning">
            {i18n.get('no_hits_title')}
          </Alert>
        </AccordionDetails>
      )
    }

    if (total > 0) {
      return (
        <AccordionDetails>
          <Backdrop
            className={classes.backdrop}
            open={loading}
          />
          <TableContainer className={classes.container}>
            <Table
              sx={{ minWidth: 650 }}
              size="medium"
            >
              <TableHead>
                <ContactsSortbar />
              </TableHead>
              <TableBody>
                {renderSortedContent()}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      )
    }

    return null
  }

  const handleToggle = () => {
    toggleUnsubscribed()
    fetchContacts()
  }

  return (
    <>
      <Accordion
        className={classes.accordion}
        disableGutters
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <Box sx={{ display: 'flex' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" />}
            sx={{
              flexGrow: 1
            }}
          >
            <Typography
              className={classes.title}
              variant="h6"
            >
              {i18n.get('all_contacts')} ({total})
            </Typography>
            <div className={classes.progressWrapper}>
              {loading ? (
                <div className={classes.circularProgress}>
                  <CircularProgress
                    size={40}
                  />
                </div>
              ) : null}
            </div>
            <Box onClick={e => e.stopPropagation()}>
              <FormControlLabel
                control={
                  (
                    <Switch
                      checked={showUnsubscribed}
                      onChange={handleToggle}
                    />
                  )
                }
                label={i18n.get('show_only_unsubscribed')}
              />
            </Box>
          </AccordionSummary>
        </Box>
        {renderResult()}
        {(total >= 10) ? (
          <Pagination
            className={classes.pagination}
            showFirstButton
            showLastButton
            count={Math.ceil(total / 10)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        ) : null}
      </Accordion>
      <ImportHelpDialog />
    </>
  )
}

Contacts.propTypes = {
  contacts: PropTypes.object.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  hasSearchQueries: PropTypes.bool.isRequired,
  showUnsubscribed: PropTypes.bool.isRequired,

  onPageChange: PropTypes.func.isRequired,
  toggleUnsubscribed: PropTypes.func.isRequired,
  fetchContacts: PropTypes.func.isRequired
}

export default Contacts
