import { connect } from 'react-redux'

import Navigation from 'components/content_desk/content_edit_dialog/navigation'
import { changeNewContentDialogView } from 'actions/content_desk'
import { getCDeskNewDialogView, getCDeskContentFormData } from 'selectors'

const mapStateToProps = state => ({
  view: getCDeskNewDialogView(state),
  content: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: changeNewContentDialogView
  }
)(Navigation)
