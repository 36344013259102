import { connect } from 'react-redux'

import SelectRecipientsForm from 'components/content_desk/content_edit_dialog/select_recipients_form'

import {
  getCDeskContentFormDataRecipients,
  getCDeskContactDropdownOptions,
  getCDeskContactDropdownOptionsLoading,
  getCDeskDistributionListDropdownOptions,
  getCDeskDistributionListDropdownOptionsLoading,
  getCDeskIsDistributionListsLocked
} from 'selectors'

import {
  fetchContactDropdownOptionsStart,
  fetchDistributionListDropdownOptionsStart,
  setContentFormData,
  saveContentStart
} from 'actions/content_desk'

const mapStateToProps = state => ({
  recipients: getCDeskContentFormDataRecipients(state),
  contactDropdownOptions: getCDeskContactDropdownOptions(state),
  contactDropdownOptionsLoading: getCDeskContactDropdownOptionsLoading(state),
  distributionListDropdownOptions: getCDeskDistributionListDropdownOptions(state),
  distributionListDropdownOptionsLoading: getCDeskDistributionListDropdownOptionsLoading(state),
  isDistListsLocked: getCDeskIsDistributionListsLocked(state)
})

export default connect(
  mapStateToProps,
  {
    fetchContactDropdownOptionsStart,
    fetchDistributionListDropdownOptionsStart,
    setContentFormData,
    saveContentStart
  }
)(SelectRecipientsForm)
