import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment-timezone'

import {
  Box,
  LinearProgress,
  Typography
} from '@mui/material'

const useStyles = makeStyles()({
  duration: {
    fontSize: '0.7rem'
  },
  progress: {
    alignItems: 'center',
    display: 'flex',
    minWidth: 100
  },
  progressBar: {
    width: '100%',
    marginRight: 4
  }
})

const CampaignProgress = ({
  campaign
}) => {
  const { classes } = useStyles()
  const campaignStart = campaign.get('startDate') ? moment(campaign.get('startDate')).format('L') : '-'
  const campaignEnd = campaign.get('endDate') ? moment(campaign.get('endDate')).format('L') : '-'
  const value = campaign.get('progress')

  return (
    <>
      <Typography
        variant="body2"
        className={classes.duration}
      >
        {`${campaignStart} - ${campaignEnd}`}
      </Typography>
      <Box className={classes.progress}>
        <Box className={classes.progressBar}>
          <LinearProgress
            variant="determinate"
            value={value}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${Math.round(
            value
          )}%`}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

CampaignProgress.propTypes = {
  campaign: PropTypes.instanceOf(Map).isRequired
}

export default CampaignProgress
