import { handleActions } from 'redux-actions'
import { fromJS, List } from 'immutable'
import moment from 'moment-timezone'

import { datesToRange, calculateIntervals } from 'utils/date'
import {
  ThemaxSearchFields,
  ThemaxReachFilterOptions as reachFilterOptions,
  ThemaxPageAveFilterOptions as pageAveFilterOptions
} from 'static/constants'

import * as Actions from 'actions/themax'
import * as AppActions from 'actions/app'

const themaxSearchFieldsMapping = {
  [ThemaxSearchFields.TOPIC_PLAN]: 'topicPlanQueries',
  [ThemaxSearchFields.PUBLICATION_NAME]: 'publicationQueries',
  [ThemaxSearchFields.PUBLICATION_INFO]: 'publicationQueries',
  [ThemaxSearchFields.REACH_MIN]: 'reachMin',
  [ThemaxSearchFields.REACH_MAX]: 'reachMax',
  [ThemaxSearchFields.PAGE_AVE_MIN]: 'pageAveMin',
  [ThemaxSearchFields.PAGE_AVE_MAX]: 'pageAveMax'
}

export const fromActiveFilterField = field => {
  if (field === ThemaxSearchFields.TOPIC_PLAN) {
    return 'topicPlanQueries'
  }

  if (field === ThemaxSearchFields.PUBLICATION_NAME) {
    return 'publicationQueries'
  }

  if (field === ThemaxSearchFields.PUBLICATION_INFO) {
    return 'publicationQueries'
  }

  return field
}

const { allowedDateIntervals: defaultAllowedDateIntervals } = calculateIntervals(null, new Date(), new Date())
export const initialState = fromJS({
  allowedDateIntervals: defaultAllowedDateIntervals,
  allDateIntervals: defaultAllowedDateIntervals,
  booleans: {
    bookmarkedOnly: false
  },
  reachFilter: {
    filtered: false,
    min: reachFilterOptions.min,
    max: reachFilterOptions.max
  },
  pageAveFilter: {
    filtered: false,
    min: pageAveFilterOptions.min,
    max: pageAveFilterOptions.max
  },
  topicPlanQueries: [],
  publicationQueries: [],
  // has the possibility to add multiple publications but for now we only support one
  // so we can keep it as a list but with singular name
  publication: [],
  mediaTypes: [],
  mediaTopics: [],
  countries: [],
  selectedDate: {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    range: 'this_month',
    interval: null,
    type: 'editorialDeadline'
  },
  editorialPlansPage: 1,
  relatedEditorialPlansPage: 1,
  publicationsPage: 1,
  limit: 30,
  editorialPlansSorting: {
    sortBy: 'editorial_deadline',
    sortOrder: 'desc'
  },
  publicationsSorting: {
    sortBy: 'reach',
    sortOrder: 'desc'
  }
})

const specialFilters = ['booleans', 'reachFilter', 'pageAveFilter']

const mapSelectedItem = (item, field) => (fromJS(item).merge({
  id: item.id,
  label: item.label || item.name,
  field
}))
const mapSelectedItems = (items, state, field, fieldNameOverride) => {
  const currentFilters = state.get(field, fromJS([]))

  // Do not replace existing filters to keep flags like inverted
  return fromJS(items.map(item => {
    const found = currentFilters.find(f => f.get('id') === item.id)

    return found || mapSelectedItem(item, fieldNameOverride || field)
  }))
}

const buildLabel = (field, value) => {
  if (field === ThemaxSearchFields.TOPIC_PLAN) {
    return `Topic Plan: ${value}`
  }

  if (field === ThemaxSearchFields.PUBLICATION_NAME) {
    return `Publication Name: ${value}`
  }

  if (field === ThemaxSearchFields.PUBLICATION_INFO) {
    return `Publication Info: ${value}`
  }

  return value
}

export default handleActions({
  [Actions.setSelectedThemaxFilters]: (state, { payload }) => {
    const { booleans, reachFilter, pageAveFilter } = payload
    const newFilters = Object.keys(payload)
      .filter(key => specialFilters.indexOf(key) === -1)
      .reduce((acc, key) => ({ ...acc, [key]: mapSelectedItems(payload[key], state, key) }), {})

    return state
      .merge(newFilters)
      .merge({
        booleans: fromJS(booleans),
        reachFilter,
        pageAveFilter
      })
  },
  [Actions.setSelectedPublicationIds]: (state, { payload }) => state.set('publication', payload),
  [Actions.removeFilter]: (state, { payload: { field, index } }) => {
    const fieldName = fromActiveFilterField(field)

    if (List.isList(state.get(fieldName))) {
      return state.update(fieldName, list => list.delete(index))
    }

    if (field === ThemaxSearchFields.REACH_MIN) {
      return state.set('reachFilter', state.get('reachFilter').set('filtered', false))
    }

    if (field === ThemaxSearchFields.REACH_MAX) {
      return state.set('reachFilter', state.get('reachFilter').set('filtered', false))
    }

    if (field === ThemaxSearchFields.PAGE_AVE_MIN) {
      return state.set('pageAveFilter', state.get('pageAveFilter').set('filtered', false))
    }

    if (field === ThemaxSearchFields.PAGE_AVE_MAX) {
      return state.set('pageAveFilter', state.get('pageAveFilter').set('filtered', false))
    }

    // should always be the booleans case
    return state.setIn([fieldName, index], false)
  },

  [Actions.invertFilter]: (state, { payload: { field, index } }) => {
    const fieldName = fromActiveFilterField(field)

    if (List.isList(state.get(fieldName))) {
      return state.updateIn([fieldName, index, 'inverted'], inverted => !inverted)
    }

    return state
  },
  [Actions.updateFilter]: (state, { payload: { filter, index } }) => {
    const key = themaxSearchFieldsMapping[filter.get('field')]
    const queries = state.get(key)

    if (List.isList(queries)) {
      return state.setIn([key, index], filter.set('updated', true))
    }

    return state
  },
  [Actions.changeDate]: (state, { payload: { from, to, range } }) => {
    const { dateInterval, allowedDateIntervals } = calculateIntervals(state.getIn(['selectedDate', 'interval']), from, to)

    return state.update('selectedDate', selectedDate => selectedDate.merge({
      from,
      to,
      range: range || datesToRange(from, to),
      interval: dateInterval,
      allowedDateIntervals
    }))
  },
  [Actions.addQuery]: (state, { payload: { query, type } }) => {
    const key = themaxSearchFieldsMapping[type]
    const queries = state.get(key)

    if (!queries.find(q => q.get('value') === query)) {
      return state.set(key, queries.push(fromJS({
        label: buildLabel(type, query),
        value: query,
        field: type,
        inverted: false
      })))
    }

    return state
  },
  [Actions.setThemaxSavedSearchFilters]: (state, { payload: { filters } }) => state.merge(fromJS(filters)),

  [Actions.setThemaxPage]: (state, { payload: { pageType, pageValue } }) => state.set(pageType, pageValue),
  [Actions.setThemaxLimit]: (state, { payload }) => state.set('limit', payload),
  [Actions.setThemaxSort]: (state, { payload: { sortBy, sortOrder, sortType } }) => state.update(
    sortType,
    oldValue => oldValue.merge(fromJS({ sortBy, sortOrder }))
  ),

  [Actions.closeDetailedViewDialog]: state => state.set('relatedEditorialPlansPage', 1),

  [Actions.resetThemaxFilters]: () => initialState,

  [AppActions.resetState]: () => initialState
}, initialState)

