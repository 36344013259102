import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import useI18n from 'hooks/useI18n'
import { mapToOptions } from 'utils/autocomplete'

import { Grid, Autocomplete, TextField } from '@mui/material'

export default function EditContactLanguages({ contact, languages, onChange, onPreferredLanguageChange }) {
  const i18n = useI18n()
  const languageIds = contact.get('languages').map(t => t.get('id'))
  const values = mapToOptions(languages.filter(t => languageIds.includes(t.get('id'))))

  const languageOptions = mapToOptions(languages)
  const preferredLanguageValue = values.find(l => l.value === contact.get('preferredLanguageId')) || ''

  const handleChange = (_e, newValues) => {
    onChange(newValues.map(v => v.value))
  }

  const handlePreferredLanguageChange = (_e, option) => {
    if (option) {
      return onPreferredLanguageChange({ key: 'preferredLanguageId', value: option.value })
    }

    return onPreferredLanguageChange({ key: 'preferredLanguageId', value: null })
  }

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <Autocomplete
          multiple
          fullWidth
          onChange={handleChange}
          options={languageOptions}
          isOptionEqualToValue={(option, value) => option.value === value?.value}
          value={values}
          clearOnBlur
          selectOnFocus
          disableCloseOnSelect
          size="small"
          renderInput={params => (
            <TextField
              {...params}
              label={i18n.get('languages')}
              placeholder=""
            />
          )}
          limitTags={10}
        />
      </Grid>
      {contact.get('languages').size > 1 && (
        <Grid
          item
          xs={12}
        >
          <Autocomplete
            onChange={handlePreferredLanguageChange}
            options={values}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            value={preferredLanguageValue}
            clearOnBlur
            selectOnFocus
            size="small"
            renderInput={params => (
              <TextField
                {...params}
                label={i18n.get('preferred_language')}
                placeholder=""
              />
            )}
            limitTags={10}
          />
        </Grid>
      )}
    </>
  )
}

EditContactLanguages.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  languages: PropTypes.instanceOf(ImmutableList).isRequired,
  onChange: PropTypes.func.isRequired,
  onPreferredLanguageChange: PropTypes.func.isRequired
}
