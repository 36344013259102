import { createAction } from 'redux-actions'

const prefix = 'UI/'

export const uiApplyDateFilter = createAction(`${prefix}APPLY_DATE_FILTER`)
export const uiApplyFilters = createAction(`${prefix}APPLY_FILTERS`)
export const uiChooseQuickSelect = createAction(`${prefix}CHOOSE_QUICK_SELECT`)
export const uiEnteredQuery = createAction(`${prefix}ENTERED_QUERY`)
export const uiRemoveFilter = createAction(`${prefix}REMOVE_FILTER`)
export const uiInvertFilter = createAction(`${prefix}INVERT_FILTER`)
export const uiAddFilters = createAction(`${prefix}ADD_FILTERS`)
export const uiResetFilters = createAction(`${prefix}RESET_FILTERS`)
export const uiSetBoolean = createAction(`${prefix}SET_BOOLEAN`)
export const uiUpdateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const uiSetAnalysisFilter = createAction(`${prefix}SET_ANALYSIS_FILTER`)
export const uiResetFilterFields = createAction(`${prefix}RESET_FILTER_FIELDS`)
export const uiSwapAnalysisCodeSelectionAndGrouping = createAction(`${prefix}SWAP_ANALYSIS_CODE_SELECTION_AND_GROUPING`)
export const uiSetSortBy = createAction(`${prefix}SET_SORT_BY`)
export const uiSetSortDirection = createAction(`${prefix}SET_SORT_DIRECTION`)
