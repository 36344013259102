import { getNewsByIds } from './data'

export * from './export'
export * from './shopping_cart'
export * from './media_review_detail'
export * from './ui'
export * from './edit_news'
export * from './shopping_news'
export * from './data'
export * from './aggregations'
export * from './view_config'
export * from './channel_status'
export * from './charts'
export * from './analysis'
export * from './filter_timeline'

export const getNews = state => state.news
export const getNewsGroupsSortOrder = state => getNews(state).get('groupSortOrder')
export const getNewsGroups = state => getNews(state).get('groups').map(group => (
  group.update('news', newsIds => getNewsByIds(state, newsIds))
)).filter(group => !group.get('news').isEmpty())
export const getRawNewsGroups = state => getNews(state).get('groups')
export const getNewsGroupIds = state => getNews(state).get('groups').keySeq().toList()
export const getNewsGroupNewsIds = state => getNews(state).get('groups').valueSeq().flatMap(group => group.get('news'))
  .toList()
export const getPagingByGroupId = (state, id) => getNews(state).getIn(['paging', id.toString()])
export const getTotalNewsCount = state => getNews(state).get('total')
export const getUnclusteredNewsCount = state => getNews(state).get('count')
export const getSortBy = state => getNews(state).get('sortBy')
export const getSortDirection = state => getNews(state).get('sortDirection')

export const getDeletedNewsIds = state => getNews(state).get('deletedNewsIds')

export const getSelectedNewsIds = state => getNews(state).get('selectedNewsIds')
export const isNewsSelected = (state, id) => getSelectedNewsIds(state).has(id)
