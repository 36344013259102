import { connect } from 'react-redux'

import Themax from 'components/themax'

import { getThemaxTabView } from 'selectors'
import { onMount, onUnmount } from 'actions/themax'

const mapStateToProps = state => ({
  view: getThemaxTabView(state)
})

export default connect(
  mapStateToProps,
  {
    onMount,
    onUnmount
  }
)(Themax)
