import { connect } from 'react-redux'

import TemplatesList from 'components/email_templates_library/templates_list'

import { getETLibraryTemplates, getETLibraryReadMode } from 'selectors'

const mapStateToProps = (state, { loadTemplate }) => ({
  loadTemplate,
  templates: getETLibraryTemplates(state).get('items'),
  readMode: getETLibraryReadMode(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(TemplatesList)
