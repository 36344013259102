import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import { Button, IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  title: {
    color: !theme.isDark && '#404040'
  },
  button: {
    marginBottom: '0.5em',
    minWidth: 120
  },
  contentText: {
    color: !theme.isDark && '#757575'
  }
}))

const CloseConfirmDialog = ({
  open,

  onClose,
  onSave
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleClose = () => {
    onClose()
  }

  const handleSave = () => {
    onSave()
  }

  return (
    <Dialog
      open={open}
    >
      <DialogTitle className={classes.title}>
        {i18n.get('not_saved_warning')}
      </DialogTitle>
      <IconButton
        onClick={() => onClose()}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {i18n.get('content_not_saved')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={handleClose}
          autoFocus
        >
          {i18n.get('close_without_saving')}
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="contained"
          onClick={handleSave}
        >
          {i18n.get('save_changes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CloseConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default CloseConfirmDialog
