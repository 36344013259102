import { connect } from 'react-redux'

import Actions from 'components/contact_management/actions/Actions'

import { getCmHasTags } from 'selectors'

import { openCalendarDialog } from 'actions/content_desk'
import { openTagsDialog } from 'actions/contact_management'

const mapStateToProps = state => ({
  hasTags: getCmHasTags(state)
})

export default connect(
  mapStateToProps,
  {
    openCalendarDialog,
    openTagsDialog
  }
)(Actions)
