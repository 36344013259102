import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import OrderForm from 'containers/paid_features/OrderForm'

import useI18n from 'hooks/useI18n'

export default function AiSummariesOrderForm({
  card,
  paidFeature,
  status
}) {
  const i18n = useI18n()

  return (
    <OrderForm
      card={card}
      paidFeature={paidFeature}
      status={status}
      unlockTitle={i18n.get('ai_summaries_unlock_title')}
      unlockSubtitle={i18n.get('ai_summaries_order_subtitle')}
      unlockText={i18n.get('ai_summaries_unlock_text')}
      subscriptionInfo={i18n.get('per_month_unlimited_users')}
      benefits={[
        i18n.get('ai_summaries_benefits_item_01'),
        i18n.get('ai_summaries_benefits_item_02'),
        i18n.get('ai_summaries_benefits_item_03'),
        i18n.get('ai_summaries_benefits_item_04'),
        i18n.get('ai_summaries_benefits_item_05'),
        i18n.get('ai_summaries_benefits_item_06')
      ]}
    />
  )
}

AiSummariesOrderForm.propTypes = {
  paidFeature: PropTypes.instanceOf(Map).isRequired,
  status: PropTypes.string,
  card: PropTypes.bool
}
