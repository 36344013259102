/* global window */
import React, { useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List, Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { lighten, alpha } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

import { Box, Grid, Typography, CircularProgress, Tabs, Tab, LinearProgress } from '@mui/material'

import AddIcon from '@mui/icons-material/Add'

import { Button } from 'containers/themed'

import TemplateConfirmDialog from 'containers/content_desk_new/contents/content_edit_dialog/templates/TemplateConfirmDialog'
import TemplatePreviewDialog from 'containers/content_desk_new/contents/content_edit_dialog/templates/TemplatePreviewDialog'

import { Capabilities } from 'static/constants'

import { isEditorDesignBlank } from 'utils/content_desk'

const useStyles = makeStyles()((theme, _props, classes) => ({
  container: {
    padding: '40px 20px 0px 20px'
  },
  titleContainer: {
    marginBottom: '15px'
  },
  circularLoader: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  overlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    border: '2px solid transparent',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
    visibility: 'hidden'
  },
  templateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '1 / 1',
    position: 'relative',
    backgroundColor: grey[200],
    borderRadius: '10px',
    border: '1px solid',
    borderColor: grey[300],
    marginBottom: '10px',
    [`&:hover .${classes.overlay}`]: {
      visibility: 'visible',
      backgroundColor: alpha(lighten(theme.palette.primary.main, 0.95), 0.9),
      borderColor: theme.palette.primary.main
    }
  },
  htmlPreview: {
    width: '75%',
    height: '75%',
    border: 'none',
    transformOrigin: '0 0',
    pointerEvents: 'none',
    borderRadius: '10px',
    boxShadow: '0px 2px 2px 2px rgba(0,0,0,0.2)'
  },
  newTemplateButton: {
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    border: '2px dashed',
    borderColor: grey[400],
    marginBottom: '10px'
  },
  addIcon: {
    fontSize: '3vw',
    color: grey[400]
  },
  lastRow: {
    justifyContent: 'flex-start'
  },
  templateName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  newTemplateLabel: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'center'
  }
}))

const Template = ({
  content,
  loading,
  singleRequestRunning,
  capabilities,
  publicTemplates,
  hasMorePublicTemplates,
  privateTemplates,
  hasMorePrivateTemplates,
  onMount,
  onUnmount,
  openConfirmDialog,
  openPreviewDialog,
  onNavigationChange,
  onChange,
  onLoadMore,
  forceLoadDesign
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const hasPublicTemplates = capabilities.get(Capabilities.HAS_CONTENT_DESK_PUBLIC_TEMPLATES_READ_RIGHTS)

  const currentEditorDesign = content.get('editorDesign')

  const parentDivRef = useRef(null)
  const [scale, setScale] = useState(1)
  const [scaleCalculated, setScaleCalculated] = useState(false)
  const [tab, setTab] = useState(0)
  const iframeWidth = 660

  const calculateScale = () => {
    if (parentDivRef.current) {
      const { offsetWidth } = parentDivRef.current
      setScale((offsetWidth * 0.75) / iframeWidth)
      setScaleCalculated(true)
    }
  }

  useEffect(() => {
    onMount()

    return () => {
      onUnmount()
    }
  }, [onMount, onUnmount])

  useEffect(() => {
    setTimeout(calculateScale, 0)
    window.addEventListener('resize', calculateScale)

    return () => {
      window.removeEventListener('resize', calculateScale)
    }
  }, [parentDivRef.current])

  const handleOpenConfirmDialog = editorDesign => {
    if (isEditorDesignBlank(currentEditorDesign)) {
      onChange({ editorDesign })
      forceLoadDesign()
      onNavigationChange('editor')
    } else {
      openConfirmDialog(editorDesign)
    }
  }

  const onEnterWaypoint = () => {
    if (hasPublicTemplates) {
      if (tab === 0 && hasMorePublicTemplates) {
        onLoadMore({ shared: true })
      }

      if (tab === 1 && hasMorePrivateTemplates) {
        onLoadMore({ shared: false })
      }
    } else if (hasMorePrivateTemplates) {
      onLoadMore({ shared: false })
    }
  }

  const htmlContent = template => `
    <style>
      html { 
        overflow: hidden; 
        user-select: none;
      }
    </style>
    <div style='width: ${iframeWidth}px; transform: scale(${scale}); transform-origin: 0 0;'>
      ${template.get('html')}
    </div>
  `

  const renderTemplates = templates => (
    templates.map((template, index) => (
      <Grid
        key={index}
        item
        xs={3}
      >
        <Box className={classes.templateContainer}>
          <iframe
            title="template-preview"
            className={classes.htmlPreview}
            srcDoc={htmlContent(template)}
          />
          <Box className={classes.overlay}>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => openPreviewDialog(template.get('html'))}
            >
              {i18n.get('preview')}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleOpenConfirmDialog(template.get('json'))}
            >
              {i18n.get('use_this_template')}
            </Button>
          </Box>
        </Box>
        <Typography className={classes.templateName}>
          {template.get('name')}
        </Typography>
      </Grid>
    ))
  )

  const renderLoader = () => (
    <div className={classes.circularLoader}>
      <CircularProgress
        variant="indeterminate"
        size={70}
      />
    </div>
  )

  const renderCreateNew = () => (
    <Grid
      item
      xs={3}
    >
      <Button
        ref={parentDivRef}
        className={classes.newTemplateButton}
        onClick={() => handleOpenConfirmDialog(null)}
      >
        <AddIcon className={classes.addIcon} />
      </Button>
      <Typography className={classes.newTemplateLabel}>
        {i18n.get('create_new')}
      </Typography>
    </Grid>
  )

  const renderTemplatesSection = () => (
    <Box sx={{ visibility: loading ? 'hidden' : 'visible' }}>
      <Grid
        className={classes.lastRow}
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="flex-start"
        wrap="wrap"
      >
        {renderCreateNew()}

        {(hasPublicTemplates && scaleCalculated && !loading) && (
          <>
            {tab === 0 && renderTemplates(publicTemplates)}
            {tab === 1 && renderTemplates(privateTemplates)}
          </>
        )}
        {(!hasPublicTemplates && scaleCalculated && !loading) && renderTemplates(privateTemplates)}
      </Grid>
    </Box>
  )

  return (
    <Box className={classes.container}>
      {!hasPublicTemplates && (
        <Box className={classes.titleContainer}>
          <Typography
            fontWeight={500}
            fontSize={18}
          >
            {i18n.get('saved_templates')}
          </Typography>
        </Box>
      )}
      {hasPublicTemplates && (
        <Tabs
          value={tab}
          onChange={(_e, newValue) => setTab(newValue)}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab
            label={i18n.get('template_library')}
            value={0}
          />
          <Tab
            label={i18n.get('saved_templates')}
            value={1}
          />
        </Tabs>
      )}
      <br />
      {loading && renderLoader()}
      {renderTemplatesSection()}
      <Waypoint onEnter={onEnterWaypoint} />
      <br />
      {(!loading && singleRequestRunning) && <LinearProgress variant="indeterminate" />}
      <br />
      <TemplateConfirmDialog />
      <TemplatePreviewDialog />
    </Box>
  )
}

Template.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,
  singleRequestRunning: PropTypes.bool.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,
  publicTemplates: PropTypes.instanceOf(List).isRequired,
  hasMorePublicTemplates: PropTypes.bool.isRequired,
  privateTemplates: PropTypes.instanceOf(List).isRequired,
  hasMorePrivateTemplates: PropTypes.bool.isRequired,

  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  openPreviewDialog: PropTypes.func.isRequired,
  onNavigationChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  forceLoadDesign: PropTypes.func.isRequired
}

export default Template
