import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from 'tss-react/mui'

import { Box } from '@mui/material'

import ContentContainer from 'components/content_container/'

import WelcomeDialog from 'containers/themax/WelcomeDialog'
import OrderDialog from 'containers/themax/OrderDialog'
import OrderSuccessDialog from 'containers/themax/OrderSuccessDialog'
import AppBar from 'containers/themax/AppBar'
import OrderProgress from 'containers/themax/OrderProgress'
import SavedSearchesOptions from 'containers/themax/saved_searches/SavedSearchesOptions'
import ActiveFilterList from 'containers/themax/ActiveFilterList'
import Navigation from 'containers/themax/Navigation'
import EditorialPlans from 'containers/themax/editorial_plans/EditorialPlans'
import MediaProfiles from 'containers/themax/MediaProfiles'
import Calendar from 'containers/themax/Calendar'
import DetailedViewDialog from 'containers/themax/DetailedViewDialog'
import EditorialPlansSorting from 'containers/themax/EditorialPlansSorting'
import PublicationsSorting from 'containers/themax/PublicationsSorting'
import ExcelDownloadButton from 'containers/themax/ExcelDownloadButton'

const useStyles = makeStyles()(theme => ({
  container: {
    paddingTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    minHeight: '90%',
    overflow: 'hidden',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: '0px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px',
      paddingRight: '20px'
    }
  },
  exportButton: {
    minWidth: 120
  },
  filtersAndSavedSearch: {
    paddingTop: '20px',
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '5px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '20px'
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '40px'
    }
  },
  navWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      margin: '0 1.5em'
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    gap: 20
  }
}))

const Themax = ({
  view,
  onMount,
  onUnmount
}) => {
  const { classes } = useStyles()

  useEffect(() => {
    onMount()

    return () => {
      onUnmount()
    }
  }, [onMount])

  const renderSorting = () => {
    if (view === 'editorial_plans') {
      return (
        <EditorialPlansSorting />
      )
    }

    if (view === 'media_profiles') {
      return (
        <PublicationsSorting />
      )
    }

    return null
  }

  return (
    <>
      <AppBar />
      <OrderProgress />
      <Box className={classes.filtersAndSavedSearch}>
        <SavedSearchesOptions />
        <ActiveFilterList />
      </Box>
      <ContentContainer className={classes.container}>
        <Navigation />
        <Box className={classes.actions}>
          <ExcelDownloadButton />
          {renderSorting()}
        </Box>
        {view === 'editorial_plans' && <EditorialPlans />}
        {view === 'media_profiles' && <MediaProfiles />}
        {view === 'calendar' && <Calendar />}
      </ContentContainer>
      <DetailedViewDialog />
      <WelcomeDialog />
      <OrderDialog />
      <OrderSuccessDialog />
    </>
  )
}

Themax.propTypes = {
  view: PropTypes.string.isRequired,

  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired
}

export default Themax
