import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { sortCharts, recalculateLayout } from 'utils/charts'
import completePreset from 'static/chart_presets/complete.json'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/charts'

export const initialState = fromJS(completePreset)

const updateFieldInCharts = (state, field, value) => state.update(
  'charts',
  charts => charts.reduce((acc, values, key) => acc.set(key, values.map(c => c.set(field, value))), fromJS({}))
)

export default handleActions({
  [Actions.setCharts]: (state, { payload: { charts, layouts } }) => {
    if (charts && layouts) {
      const newCharts = fromJS(charts)
      const newLayouts = fromJS(layouts)

      if (!newCharts.isEmpty() && !newLayouts.isEmpty()) {
        let newState = state
        newState = newState.set('charts', newCharts)
        newState = newState.set('layouts', newLayouts)
        newState = sortCharts(newState)

        return newState
      }
    }

    return state
  },
  [Actions.setPreviousToData]: (state, { payload: { charts, layouts } }) => state.set('charts', charts).set('layouts', layouts),
  [Actions.setLayouts]: (state, { payload: { breakpoint, layout } }) => {
    const newLayouts = fromJS(layout).map(s => fromJS({
      x: s.get('x'),
      y: s.get('y'),
      w: s.get('w'),
      h: s.get('h')
    }))

    return state.setIn(['layouts', breakpoint], newLayouts)
  },
  [Actions.setChart]: (state, { payload: { chart, index, breakpoint } }) => {
    if (index === null) {
      const newLayouts = state.getIn(['layouts', breakpoint]).map(layout => layout.update('y', y => y + 2))

      return state.updateIn(['charts', breakpoint], charts => charts.push(chart))
        .setIn(['layouts', breakpoint], newLayouts.push(fromJS({ w: 12, h: 4, x: 0, y: 0 })))
    }

    return state.setIn(['charts', breakpoint, index], chart)
  },
  [Actions.removeChart]: (state, { payload: { breakpoint, index } }) => (
    state
      .deleteIn(['charts', breakpoint, index])
      .deleteIn(['layouts', breakpoint, index])
  ),

  [Actions.copyFromDisplaySize]: (state, { payload: { from, to } }) => {
    const newState = sortCharts(state, v => `${v.getIn(['layout', 'y'])}${v.getIn(['layout', 'x'])}`, false)

    return state
      .setIn(['layouts', to], recalculateLayout(newState.getIn(['charts', from]), to))
      .setIn(['charts', to], newState.getIn(['charts', from]))
  },
  [Actions.setChartData]: (state, { payload: { index, breakpoint, data } }) => {
    const chart = state.getIn(['charts', breakpoint, index])

    if (chart) {
      return state.setIn(['charts', breakpoint, index], chart.merge({
        data: fromJS(data),
        loading: false
      }))
    }

    return state
  },
  [Actions.aggregationsRequestStart]: state => updateFieldInCharts(state, 'loading', true),
  [Actions.aggregationsRequestError]: state => updateFieldInCharts(state, 'loading', false),
  [Actions.aggregationsRequestSuccess]: state => updateFieldInCharts(state, 'loading', false),

  [AppActions.resetState]: () => initialState
}, initialState)
