import React from 'react'
import PropTypes from 'prop-types'
import Chart from 'components/darknet/charts/chart_export'
import { List, Map } from 'immutable'

export default function ScoreResultTimelineChart({ charts, colors }) {
  return (
    charts.map((chart, index) => (
      <Chart
        chart={chart}
        key={index}
        colors={colors}
      />
    ))
  )
}

ScoreResultTimelineChart.propTypes = {
  charts: PropTypes.instanceOf(List),
  colors: PropTypes.instanceOf(Map)
}
