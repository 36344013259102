import { normalize, schema } from 'normalizr'

export const normalizeIt = (data, schemaName, oldKey) => {
  const oKey = oldKey || `${schemaName}s`
  let generatedSchema = null
  let normalizedResult = null

  generatedSchema = new schema.Entity(schemaName)
  normalizedResult = normalize(data, {
    [oKey]: new schema.Array(generatedSchema)
  })

  return {
    entities: normalizedResult.entities[schemaName] || {},
    result: normalizedResult.result[oKey] || []
  }
}

export const sort = (result, entities) => result.map(id => entities.get(id.toString()))
