import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as ConfigActions from 'actions/config'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  groupedAnalysisCodes: [],
  groupedMediaReviewCodes: []
})

export const findAncestors = (code, codes) => {
  let collector = ''

  const sortcodes = Set(
    code.get('sortcode')
      .split('.')
      .filter(Boolean)
      .slice(0, -1)
      .map(p => {
        const prev = collector
        collector += `${p}.`

        return `${prev}${p}.`
      })
  )

  return codes.filter(c => sortcodes.has(c.get('sortcode')))
}

const isExpandable = code => !code.get('subcodes').isEmpty()
const countDots = c => {
  if (c.get('sortcode').match(/\./g)) {
    return c.get('sortcode').match(/\./g).length
  }

  return 0
}
const hasParent = (code, codes) => (
  codes.some(c => code.get('sortcode').startsWith(c.get('sortcode')) && countDots(c) < countDots(code))
)
const getCodesByMatcher = (codes, matcher) => {
  let result = fromJS([])
  const expandableCodes = codes.filter(matcher)

  expandableCodes.forEach(c => {
    result = result.concat(getCodesByMatcher(c.get('subcodes'), matcher))
  })

  return expandableCodes.concat(result)
}

const getNodeIdsByMatcher = (codes, matcher) => (
  getCodesByMatcher(codes, matcher).map(c => c.get('id')).sortBy(id => id).map(id => id.toString())
)

const findNodes = codes => codes.filter(c => !hasParent(c, codes))

const isChild = parentCode => childCode => (
  childCode.get('sortcode').startsWith(parentCode.get('sortcode')) && countDots(childCode) > countDots(parentCode)
  && childCode.get('searchProfileId') === parentCode.get('searchProfileId')
)

const filterUselessCodes = codes => codes.filterNot(c => c.get('selectableIds').isEmpty() && !c.get('selectable'))

const addSubcodes = codes => code => {
  const children = codes.filter(isChild(code))
  const ancestors = findAncestors(code, codes)
  const subcodes = findNodes(children).map(addSubcodes(codes))

  return fromJS({
    id: code.get('id'),
    selectable: code.get('selectable'),
    decendantIds: children.map(c => c.get('id')).toSet(),
    ancestorIds: ancestors.map(c => c.get('id')).toSet(),
    expandableNodeIds: getNodeIdsByMatcher(subcodes, isExpandable),
    isExpandable: !subcodes.isEmpty(),
    selectableIds: children.filter(c => c.get('selectable')).map(c => c.get('id')).toSet(),
    subcodes
  })
}

const groupCodesByType = (codes, type) => {
  const codesByType = codes.filter(c => c.get(type)).sortBy(c => c.get('sortcode'))

  return codesByType
    .groupBy(c => c.get('searchProfileId'))
    .map((group, searchProfileId) => {
      const nodes = filterUselessCodes(findNodes(group).map(addSubcodes(group)))
      const nodeIds = getNodeIdsByMatcher(nodes, () => true)
      const code = codes.find(c => c.get('id') === nodes.getIn([0, 'id']))
      const inherited = code ? code.get('inherited', false) : false

      const expandableNodeIds = getNodeIdsByMatcher(nodes, isExpandable)

      return fromJS({
        inherited,
        nodeIds,
        expandableNodeIds,
        searchProfileId,
        nodes
      })
    })
    .valueSeq()
    .toList()
    .sortBy(s => (s.get('inherited') ? 1 : 0))
}

export default handleActions({
  [ConfigActions.setStatics]: (state, { payload }) => {
    const codes = fromJS(payload.analysisCodes || [])

    return state.merge({
      groupedAnalysisCodes: groupCodesByType(codes, 'analysis'),
      groupedMediaReviewCodes: groupCodesByType(codes, 'mediaReview')
    })
  },

  [AppActions.resetState]: () => initialState
}, initialState)
