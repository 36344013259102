import { createAction } from 'redux-actions'

const prefix = 'AUTH/'

export const authenticateGoogleAnalyticsStart = createAction(`${prefix}AUTHENTICATE_GOOGLE_ANALYTICS_START`)
export const authenticateGoogleAnalyticsSuccess = createAction(`${prefix}AUTHENTICATE_GOOGLE_ANALYTICS_SUCCESS`)
export const authenticateGoogleAnalyticsError = createAction(`${prefix}AUTHENTICATE_GOOGLE_ANALYTICS_ERROR`)

export const revokeGoogleAnalyticsStart = createAction(`${prefix}REVOKE_GOOGLE_ANALYTICS_START`)
export const revokeGoogleAnalyticsSuccess = createAction(`${prefix}REVOKE_GOOGLE_ANALYTICS_SUCCESS`)
export const revokeGoogleAnalyticsError = createAction(`${prefix}REVOKE_GOOGLE_ANALYTICS_ERROR`)

export const authenticateYoutubeAnalyticsStart = createAction(`${prefix}AUTHENTICATE_YOUTUBE_ANALYTICS_START`)
export const authenticateYoutubeAnalyticsSuccess = createAction(`${prefix}AUTHENTICATE_YOUTUBE_ANALYTICS_SUCCESS`)
export const authenticateYoutubeAnalyticsError = createAction(`${prefix}AUTHENTICATE_YOUTUBE_ANALYTICS_ERROR`)

export const revokeYoutubeAnalyticsStart = createAction(`${prefix}REVOKE_YOUTUBE_ANALYTICS_START`)
export const revokeYoutubeAnalyticsSuccess = createAction(`${prefix}REVOKE_YOUTUBE_ANALYTICS_SUCCESS`)
export const revokeYoutubeAnalyticsError = createAction(`${prefix}REVOKE_YOUTUBE_ANALYTICS_ERROR`)

export const revokeFacebookStart = createAction(`${prefix}REVOKE_FACEBOOK_START`)
export const revokeFacebookSuccess = createAction(`${prefix}REVOKE_FACEBOOK_SUCCESS`)
export const revokeFacebookError = createAction(`${prefix}REVOKE_FACEBOOK_ERROR`)

export const authenticateFacebookStart = createAction(`${prefix}AUTHENTICATE_FACEBOOK_START`)
export const authenticateFacebookSuccess = createAction(`${prefix}AUTHENTICATE_FACEBOOK_SUCCESS`)
export const authenticateFacebookError = createAction(`${prefix}AUTHENTICATE_FACEBOOK_ERROR`)

export const revokeLinkedInStart = createAction(`${prefix}REVOKE_LINKED_IN_START`)
export const revokeLinkedInSuccess = createAction(`${prefix}REVOKE_LINKED_IN_SUCCESS`)
export const revokeLinkedInError = createAction(`${prefix}REVOKE_LINKED_IN_ERROR`)

export const revokeTwitterStart = createAction(`${prefix}REVOKE_TWITTER_START`)
export const revokeTwitterSuccess = createAction(`${prefix}REVOKE_TWITTER_SUCCESS`)
export const revokeTwitterError = createAction(`${prefix}REVOKE_TWITTER_ERROR`)

export const authenticateLinkedInStart = createAction(`${prefix}AUTHENTICATE_LINKED_IN_START`)
export const authenticateLinkedInSuccess = createAction(`${prefix}AUTHENTICATE_LINKED_IN_SUCCESS`)
export const authenticateLinkedInError = createAction(`${prefix}AUTHENTICATE_LINKED_IN_ERROR`)
