/* eslint-disable */
import { AutoLinkNode } from '@lexical/link'

export class ExtendedAutoLinkNode extends AutoLinkNode {
    static getType() {
        return 'extended-autolink'
    }

    static clone(node) {
        return new ExtendedAutoLinkNode(node.__url, {}, node.__key)
    }

    exportDOM(editor) {
        const element = super.createDOM(editor._config, editor)

        // Remove additional class and dir attribute on exported html.
        element.removeAttribute('dir')
        element.removeAttribute('class')

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        return AutoLinkNode.importJSON(serializedNode)
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'extended-autolink',
            version: 1
        }
    }
}