import { connect } from 'react-redux'

import {
  getCDeskCalendarSelectedDay
} from 'selectors'

import {
  sortCampaigns,
  sortContents
} from 'actions/content_desk'

import CalendarEvents from 'components/content_desk_new/calendar_events'

const mapStateToProps = state => ({
  selectedDay: getCDeskCalendarSelectedDay(state)
})

export default connect(
  mapStateToProps,
  {
    sortContents,
    sortCampaigns
  }
)(CalendarEvents)
