import { connect } from 'react-redux'

import DistributionListCreateDialog from 'components/contact_management/distribution_list_create_dialog'

import {
  getCmDistributionListCreateDialogOpen,
  getCmDistributionListsSearch,
  getCmListData,
  getCmRequestRunning,
  getCmDistributionListSearchRunning
} from 'selectors'

import {
  closeDistributionListCreateDialog as onClose,
  setDistributionList as onSelectOption,
  addContactsToDistributionListStart as onSubmit,
  updateDistributionListFormData as onFormUpdate,
  selectContact as onRemove,
  searchDistributionListStart
} from 'actions/contact_management'

const mapStateToProps = state => ({
  contacts: getCmListData(state).get('contacts'),
  open: getCmDistributionListCreateDialogOpen(state),
  lists: getCmDistributionListsSearch(state),
  title: getCmListData(state).get('name'),
  loading: getCmRequestRunning(state),
  searchRequestRunning: getCmDistributionListSearchRunning(state)
})

export default connect(mapStateToProps, {
  onClose,
  onSelectOption,
  onSubmit,
  onFormUpdate,
  onRemove,
  searchDistributionListStart
})(DistributionListCreateDialog)
