import { connect } from 'react-redux'

import CapSafe from 'containers/CapSafe'
import { Capabilities } from 'static/constants'

import CustomTagDialog from 'components/media_reviews/media_review_detail/custom_tag_dialog'
import { getShowMediaReviewsRemoveCustomTagDialog } from 'selectors'
import { unpinAll, hideRemoveCustomTagDialog } from 'actions/media_reviews'

const mapStateToProps = state => ({
  active: getShowMediaReviewsRemoveCustomTagDialog(state),
  actionLabelKey: 'unpin_short',
  titleKey: 'unpin'
})

const result = connect(
  mapStateToProps,
  {
    onHide: hideRemoveCustomTagDialog,
    onSave: unpinAll
  }
)(CustomTagDialog)

export default CapSafe(result, Capabilities.HAS_CUSTOM_TAGS_MODULE)
