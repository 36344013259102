import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { getAverageTonalityColorOfNews } from 'utils/tonality'

import Item from 'containers/media_reviews/media_review_detail/news_list_item/Item'

import useI18n from 'hooks/useI18n'

import { Grid, List, ListItem, Divider } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  topicalCluster: {
    padding: '10px 17px',
    lineHeight: '1.7rem',
    fontSize: '0.9rem',
    whiteSpace: 'pre-line'
  },
  topicalClusterItem: {
    marginTop: -10,
    marginBottom: 0,
    backgroundColor: theme.isDark ? theme.palette.grey[500] : theme.palette.grey[100]
  },
  topicalClusterList: {
    paddingTop: 0
  }
}))

export default function TopicalCluster({ news, codeId }) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const cluster = news.get('clusteredNews')
  const tonalityColor = getAverageTonalityColorOfNews(news)

  return (
    <>
      <ListItem
        disableGutters
        classes={{ root: classes.topicalClusterItem }}
        style={tonalityColor ? {
          borderLeft: `10px solid ${tonalityColor}`
        } : {}}
      >
        <Grid
          container
          direction="row"
          spacing={2}
        >
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
          >
            <Grid
              container
              direction="row"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div className={`${classes.topicalCluster} ${classes.text}`}>
                  <b>{i18n.get('newsflash')}</b><br />
                  {news.get('summary')}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
          />
        </Grid>
      </ListItem>

      <Divider />

      <List classes={{ root: classes.topicalClusterList }}>
        {cluster.map((n, index) => (
          <div key={n.get('id')}>
            <Item
              key={n.get('id')}
              news={n}
              codeId={codeId}
            />
            {index < cluster.size - 1 && <Divider />}
          </div>
        ))}
      </List>
    </>
  )
}

TopicalCluster.propTypes = {
  news: PropTypes.instanceOf(Map).isRequired,
  codeId: PropTypes.number
}
