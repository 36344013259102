import { handleActions, combineActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'

import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  showDialog: false,
  loadingCampaignIds: Map(),
  loadingContentIds: Map()
})

export default handleActions({
  [Actions.openCalendarDialog]: state => state.set('showDialog', true),
  [Actions.closeCalendarDialog]: state => state.set('showDialog', false),
  [Actions.saveCampaignFormlessStart]: (state, { payload: { id } }) => state.setIn(['loadingCampaignIds', id], true),
  [combineActions(
    Actions.saveCampaignFormlessSuccess,
    Actions.saveCampaignFormlessError
  )]: (state, { payload: { id } }) => state.setIn(['loadingCampaignIds', id], false),
  [Actions.saveContentFormlessStart]: (state, { payload: { id } }) => state.setIn(['loadingContentIds', id], true),
  [combineActions(
    Actions.saveContentFormlessSuccess,
    Actions.saveContentFormlessError
  )]: (state, { payload: { id } }) => state.setIn(['loadingContentIds', id], false),

  [AppActions.resetState]: () => initialState
}, initialState)
