import { connect } from 'react-redux'

import NoHits from 'components/news_page/no_hits'
import { getNewsRequestIsRunning, getTotalNewsCount } from 'selectors'
import { toggleHelpDialog } from 'actions/news'

const mapStateToProps = state => ({
  active: !getNewsRequestIsRunning(state) && !getTotalNewsCount(state)
})

export default connect(
  mapStateToProps,
  {
    onHelpClick: toggleHelpDialog
  }
)(NoHits)
