/* eslint-disable */
import {
    $createTextNode,
    TextNode
} from 'lexical'

const wrapElement = (element, tag) => {
    let newElement = document.createElement(tag)
    newElement.appendChild(element)
    return newElement
}

const adjustFormat = (node, element) => {
    let anyFormattingFixesDone = false

    if (node.hasFormat('bold')) {
        element = wrapElement(element, 'b')
        anyFormattingFixesDone = true
    }
    if (node.hasFormat('italic')) {
        element = wrapElement(element, 'i')
        anyFormattingFixesDone = true
    }
    if (node.hasFormat('underline')) {
        element = wrapElement(element, 'u')
        anyFormattingFixesDone = true
    }

    if (anyFormattingFixesDone) {
        element.innerHTML = element.innerHTML.replace(/<(?:em|span|strong).+?>(.+?)<\/.+?>/gm, '$1')

        if (node.getStyle() !== '') {
            element.style = node.getStyle()
        }
    }

    return element
}

export class ExtendedTextNode extends TextNode {
    constructor(text, key) {
        super(text, key)
        this.__stopTransform = false
    }

    static getType() {
        return "extended-text"
    }

    static clone(node) {
        return new ExtendedTextNode(node.__text, node.__key)
    }

    createDOM(config) {
        const nodeText = this.getTextContent()
        const nodeFormat = this.getFormat()

        // Remove formatting if text is blank.
        if (nodeText && (/^\s*$/g.test(nodeText)) && nodeFormat !== 0) {
            this.setFormat(0)
        }

        let element = super.createDOM(config)

        return element
    }

    exportDOM(editor) {
        let element = super.createDOM(editor._config, editor)

        if (element) {
            // Removes <span> tags if element has no formatting.
            if (this.getFormat() === 0 && this.getStyle() === '') {
                let textNode = document.createTextNode(element.textContent)
                let fragment = document.createDocumentFragment()
                fragment.appendChild(textNode)
                element = fragment
            }

            // Removes nested tags for bold, italic, underline.
            if (this.getFormat() !== 0) {
                element = adjustFormat(this, element)
            }
        }

        if (element && !(element instanceof DocumentFragment)) {
            const text = element.textContent

            if (text && (/^\s*$/g.test(text))) {
                let textNode = document.createTextNode(text)
                let fragment = document.createDocumentFragment()
                fragment.appendChild(textNode)
                element = fragment
            }
        }

        return {
            element
        }
    }

    static importJSON(serializedNode) {
        const node = $createTextNode(serializedNode.text)
        node.setFormat(serializedNode.format)
        node.setDetail(serializedNode.detail)
        node.setMode(serializedNode.mode)
        node.setStyle(serializedNode.style)
        node.setStopTransform(serializedNode.stopTransform)
        return node
    }

    exportJSON() {
        return {
            detail: this.getDetail(),
            format: this.getFormat(),
            mode: this.getMode(),
            style: this.getStyle(),
            text: this.getTextContent(),
            stopTransform: this.getStopTransform(),
            type: 'extended-text',
            version: 1
        }
    }

    isSimpleText() {
        return this.__type === 'extended-text' && this.__mode === 0
    }

    getStopTransform() {
        return this.__stopTransform
    }

    setStopTransform(stop) {
        const self = this.getWritable()
        self.__stopTransform = stop
    }
}