import { connect } from 'react-redux'

import { toggleHelpDialog, toggleAdvancedSearch } from 'actions/news'
import SearchForm from 'components/search_form'
import { setSelectedFilters } from 'actions/filter'
import { uiApplyFilters, uiResetFilters } from 'actions/ui'
import {
  getStaticMediaReviewCodes,
  getStaticCountries,
  getStaticLanguages,
  getStaticMediaTypes,
  getStaticChannels,
  getStaticFilterGroups,
  getStaticDistributionAreas,
  getCustomTags,
  getCustomTagGroups,
  getStaticNewsguardRanks,
  getStaticNewsguardOrientations,
  getIdentitySets,
  getStaticMediaReviewTypesForFiltering,
  getCDeskCampaigns,
  getStaticInterfaces,
  getStaticSuppliers,

  getSelectedMediaReviewCodes,
  getSelectedCountries,
  getSelectedLanguages,
  getSelectedMediaTypes,
  getSelectedChannels,
  getSelectedOutlinkDomains,
  getSelectedOutlinkUrls,
  getSelectedFilterGroups,
  getSelectedDistributionAreas,
  getSelectedCustomTags,
  getSelectedCustomTagGroups,
  getSelectedNewsguardRanks,
  getSelectedNewsguardOrientations,
  getSelectedIdentitySets,
  getSelectedMediaReviewTypes,
  getSelectedCDCampaigns,
  getSelectedInterfaces,
  getSelectedSuppliers,

  getSelectedTonalities,

  getSelectedBooleans,

  getAllowedFilterFields,

  getSelectedGeoBoundingBox,

  getI18n,
  getDevice,
  getConfig,
  getNewsUi
} from 'selectors'

const mapStateToProps = state => {
  const selectedTonalities = getSelectedTonalities(state).map(x => x.get('id'))

  return {
    active: getNewsUi(state).get('showAdvancedSearch'),

    mediaReviewCodes: getStaticMediaReviewCodes(state),
    countries: getStaticCountries(state),
    languages: getStaticLanguages(state),
    mediaTypes: getStaticMediaTypes(state),
    channels: getStaticChannels(state),
    filterGroups: getStaticFilterGroups(state),
    distributionAreas: getStaticDistributionAreas(state),
    customTags: getCustomTags(state),
    customTagGroups: getCustomTagGroups(state),
    newsguardRanks: getStaticNewsguardRanks(state),
    newsguardOrientations: getStaticNewsguardOrientations(state),
    identitySets: getIdentitySets(state),
    mediaReviewTypes: getStaticMediaReviewTypesForFiltering(state),
    ccdCampaigns: getCDeskCampaigns(state).get('items'),
    interfaces: getStaticInterfaces(state),
    suppliers: getStaticSuppliers(state),

    selectedMediaReviewCodes: getSelectedMediaReviewCodes(state),
    selectedCountries: getSelectedCountries(state),
    selectedLanguages: getSelectedLanguages(state),
    selectedMediaTypes: getSelectedMediaTypes(state),
    selectedChannels: getSelectedChannels(state),
    selectedOutlinkDomains: getSelectedOutlinkDomains(state),
    selectedOutlinkUrls: getSelectedOutlinkUrls(state),
    selectedFilterGroups: getSelectedFilterGroups(state),
    selectedDistributionAreas: getSelectedDistributionAreas(state),
    selectedCustomTags: getSelectedCustomTags(state),
    selectedCustomTagGroups: getSelectedCustomTagGroups(state),
    selectedNewsguardRanks: getSelectedNewsguardRanks(state),
    selectedNewsguardOrientations: getSelectedNewsguardOrientations(state),
    selectedIdentitySets: getSelectedIdentitySets(state),
    selectedMediaReviewTypes: getSelectedMediaReviewTypes(state),
    selectedCcdCampaigns: getSelectedCDCampaigns(state),
    selectedInterfaces: getSelectedInterfaces(state),
    selectedSuppliers: getSelectedSuppliers(state),

    negative: selectedTonalities.includes('negative'),
    neutral: selectedTonalities.includes('neutral'),
    positive: selectedTonalities.includes('positive'),
    unknown: selectedTonalities.includes('unknown'),

    hasTonality: getConfig(state).get('hasTonality'),

    booleans: getSelectedBooleans(state),

    geoBoundingBox: getSelectedGeoBoundingBox(state),

    allowedFilterFields: getAllowedFilterFields(state),

    i18n: getI18n(state),
    device: getDevice(state)
  }
}

export default connect(
  mapStateToProps,
  {
    setSelectedFilters,
    toggleHelpDialog,
    uiApplyFilters,
    uiResetFilters,
    onClose: toggleAdvancedSearch
  }
)(SearchForm)
