import React from 'react'
import PropTypes from 'prop-types'
import { List, fromJS, Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogActions, DialogContent, DialogTitle, Avatar, Stack } from '@mui/material'
import MailIcon from '@mui/icons-material/Mail'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'

import { Button, IconButton } from 'containers/themed'

import { facebookPost as initialFacebookPost } from 'static/facebook_post_config'
import { linkedInPost as initialLinkedInPost } from 'static/linked_in_post_config'
import { otsBody as initialOtsBody } from 'static/ots_body_config'

const newEditorContentTypes = ['email', 'ots_press_release']

const useStyles = makeStyles()(theme => ({
  dialog: {
    padding: '25px',
    margin: '1em 1em 1em 1em'
  },
  title: {
    color: !theme.isDark && '#404040'
  },
  otsLogo: {
    width: 20,
    height: 10
  }
}))

const ContentNewDialog = ({
  contentDeskContentTypes,
  hasContactsManagement,
  hasFacebook,
  hasLinkedIn,
  hasOts,
  open,
  pages,
  profile,
  socialOnly,
  config,
  closeNewContentDialog,
  setContentFormData,
  saveContentStart
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleBtnClick = type => {
    const filteredType = contentDeskContentTypes.filter(t => t.get('id').toLowerCase() === type).first()
    let title = i18n.get('new_content_title', { type: i18n.get(type), timestamp: moment().format() })

    if (type === 'ots_press_release') {
      setContentFormData({ key: 'body', value: JSON.stringify(initialOtsBody) })
    }

    if (type === 'linkedin') {
      title = i18n.get('new_content_title', { type: i18n.get('linked_in'), timestamp: moment().format() })
      const linkedInPost = fromJS(initialLinkedInPost)
        .set('author', profile.get('urn') || '')

      setContentFormData({ key: 'linkedInPost', value: linkedInPost })
    }

    if (type === 'facebook') {
      const defaultPage = pages.filter(p => p.get('instagram') !== true).first() || Map()
      const facebookPost = fromJS(initialFacebookPost)
        .set('pageId', defaultPage.get('id') || '')
        .set('accessToken', defaultPage.get('accessToken') || '')

      setContentFormData({ key: 'facebookPost', value: facebookPost })
    }

    if (type === 'email') {
      setContentFormData({ key: 'domain', value: config.get('domain') })
      setContentFormData({ key: 'domainRegion', value: config.get('domainRegion') })
    }

    setContentFormData({ key: 'title', value: title })

    if (filteredType) {
      setContentFormData({ key: 'type', value: filteredType, id: filteredType.get('id') })
    }

    if (newEditorContentTypes.includes(type)) {
      saveContentStart({ openEditContentDialog: true })
    } else {
      saveContentStart({ openOldEditContentDialog: true })
    }

    closeNewContentDialog()
  }

  return (
    <Dialog
      open={open}
      onClose={() => closeNewContentDialog()}
    >
      <DialogTitle className={classes.title}>
        {i18n.get('content_type')}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          {!socialOnly && (
            <>
              {hasContactsManagement && (
                <Avatar
                  variant="square"
                  sx={{ width: 56, height: 56 }}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    title={i18n.get('email')}
                    onClick={() => handleBtnClick('email')}
                  >
                    <MailIcon />
                  </IconButton>
                </Avatar>
              )}
              {hasOts && (
                <Avatar
                  variant="square"
                  sx={{ width: 56, height: 56 }}
                >
                  <IconButton
                    color="primary"
                    size="large"
                    title={i18n.get('ots_press_release')}
                    onClick={() => handleBtnClick('ots_press_release')}
                  >
                    <img
                      src="https://static.pressrelations.de/pics/groot/ccd/naLogo_Condensed.png"
                      alt="ots"
                      className={classes.otsLogo}
                    />
                  </IconButton>
                </Avatar>
              )}
            </>
          )}
          {hasLinkedIn && (
            <Avatar
              variant="square"
              sx={{ width: 56, height: 56, bgcolor: '#0072B1' }}
            >
              <IconButton
                size="large"
                title="LinkedIn"
                onClick={() => handleBtnClick('linkedin')}
              >
                <LinkedInIcon sx={{ color: '#FFFFFF' }} />
              </IconButton>
            </Avatar>
          )}
          {hasFacebook && (
            <Avatar
              variant="square"
              sx={{ width: 56, height: 56, bgcolor: '#4267B2' }}
            >
              <IconButton
                size="large"
                title="Facebook"
                onClick={() => handleBtnClick('facebook')}
              >
                <FacebookIcon sx={{ color: '#FFFFFF' }} />
              </IconButton>
            </Avatar>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeNewContentDialog}
        >
          {i18n.get('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ContentNewDialog.propTypes = {
  contentDeskContentTypes: PropTypes.instanceOf(List).isRequired,
  hasContactsManagement: PropTypes.bool.isRequired,
  hasFacebook: PropTypes.bool.isRequired,
  hasLinkedIn: PropTypes.bool.isRequired,
  hasOts: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  pages: PropTypes.instanceOf(List).isRequired,
  profile: PropTypes.instanceOf(Map).isRequired,
  socialOnly: PropTypes.bool.isRequired,
  config: PropTypes.instanceOf(Map).isRequired,

  closeNewContentDialog: PropTypes.func.isRequired,
  saveContentStart: PropTypes.func.isRequired,
  setContentFormData: PropTypes.func.isRequired
}

export default ContentNewDialog
