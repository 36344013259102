import { fromJS } from 'immutable'
import { ChartDataSources, ChartTypes } from './constants'

export const firstLevels = [
  'timeline',
  'youtubeAnalyticsVideos',
  'countries',
  'insightTrafficSourceType',
  'deviceType',
  'operatingSystem'
]

export default fromJS([
  {
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'views',
      dataSource: ChartDataSources.YOUTUBE_ANALYTICS,
      type: ChartTypes.AREA
    }
  }
])

export const thirdLevels = [
  'views',
  'comments',
  'likes',
  'shares',
  'averageViewDuration',
  'estimatedMinutesWatched',
  'estimatedRevenue',
  'subscribersGained',
  'subscribersLost'
]

const allowedThirdLevels = {
  operatingSystem: [
    'views',
    'estimatedMinutesWatched'
  ],
  deviceType: [
    'views',
    'estimatedMinutesWatched'
  ],
  insightTrafficSourceType: [
    'views',
    'estimatedMinutesWatched'
  ]
}

export const getThirdLevelsByFirstLevel = firstLevel => allowedThirdLevels[firstLevel] || thirdLevels

export const getThirdLevelByFirstLevel = (firstLevel, thirdLevel) => {
  if (thirdLevels.indexOf(firstLevel) !== -1) {
    return null
  }

  const levels = getThirdLevelsByFirstLevel(firstLevel)

  if (levels.indexOf(thirdLevel) !== -1) {
    return thirdLevel
  }

  return levels[0]
}

export const getTypeByFirstLevel = (firstLevel, type) => {
  if (thirdLevels.indexOf(firstLevel) !== -1) {
    return ChartTypes.RECTANGLE
  }

  return type === ChartTypes.RECTANGLE ? ChartTypes.AREA : type
}

