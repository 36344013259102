import { connect } from 'react-redux'

import Toolbar from 'components/shopping_cart/toolbar'
import { toggleSnippets } from 'actions/shopping_cart'
import { getShowSnippetsInShoppingCart } from 'selectors'

const mapStateToProps = (state, { tab }) => ({
  showSnippets: getShowSnippetsInShoppingCart(state),
  tab
})

export default connect(
  mapStateToProps,
  {
    onToggleSnippets: toggleSnippets
  }
)(Toolbar)
