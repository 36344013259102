import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { ChartDataSources } from 'static/constants'
import * as Actions from 'actions/analysis'
import * as AppActions from 'actions/app'

export const initialState = fromJS([
  {
    firstLevel: 'buzz',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'rectangle',
    opts: {
      showFullNumbers: true
    }
  },
  {
    firstLevel: 'mentionsCount',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'rectangle',
    opts: {
      showFullNumbers: true
    }
  },
  {
    firstLevel: 'statementsCount',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'rectangle',
    opts: {
      showFullNumbers: true
    }
  }
])

export default handleActions({
  [Actions.setMetricsChartLoading]: (state, { payload: index }) => state.setIn([index, 'loading'], true),
  [Actions.setMetricsChartData]: (state, { payload: { data, index } }) => state.mergeIn([index], {
    loading: false,
    data: fromJS(data)
  }),

  [Actions.setChartFields]: (state, { payload }) => (
    state.updateIn([0, 'firstLevel'], f => ((payload.thirdLevel && payload.thirdLevel !== 'mentionsCount') ? payload.thirdLevel : f))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
