import React from 'react'
import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

import ThemedAppBar from 'containers/AppBar'
import Actions from 'containers/anewstip/Actions'

import TransitionComponent from 'components/transition_component'

export default function AppBar() {
  const i18n = useI18n()
  const device = useDevice()

  const title = device.get('gt-md') ? <span>{i18n.get('journalist_search')}</span> : undefined

  return (
    <div>
      <TransitionComponent type="slideFadeDown">
        <ThemedAppBar
          title={title}
          actions={<Actions />}
        />
      </TransitionComponent>
    </div>
  )
}
