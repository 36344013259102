import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { makeStyles } from 'tss-react/mui'

const useGrow = makeStyles()(theme => ({
  enter: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transformOrigin: '50% top',
      transform: 'scale(0)'
    }
  },
  enterActive: {
    [theme.breakpoints.up('md')]: {
      opacity: 1,
      transform: 'scale(1)',
      transition: 'all 200ms ease-in-out'
    }
  },
  exit: {
    [theme.breakpoints.up('md')]: {
      opacity: 1,
      transformOrigin: '50% top',
      transform: 'scale(1)'
    }
  },
  exitActive: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      transform: 'scale(0)',
      transition: 'all 200ms ease-in-out'
    }
  }
}))

const useFadeSlow = makeStyles()({
  enter: {
    opacity: 0
  },
  enterActive: {
    opacity: 1,
    transition: 'all 750ms ease-in-out'
  },
  appear: {
    opacity: 0
  },
  appearActive: {
    opacity: 1,
    transition: 'all 750ms ease-in-out'
  },
  exit: {
    opacity: 1
  },
  exitActive: {
    opacity: 0,
    transition: 'all 750ms ease-in-out'
  }
})

const useFadeUp = makeStyles()({
  enter: {
    opacity: 0,
    transform: 'translateY(30px)'
  },
  enterActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'all 230ms ease-in-out'
  },
  appear: {
    opacity: 0,
    transform: 'translateY(30px)'
  },
  appearActive: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'all 230ms ease-in-out'
  },
  exit: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  exitActive: {
    opacity: 0,
    transform: 'translateY(30px)',
    transition: 'all 230ms ease-in-out'
  }
})

const useFade = makeStyles()({
  enter: {
    opacity: 0
  },
  enterActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out'
  },
  appear: {
    opacity: 0
  },
  appearActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out'
  },
  exit: {
    opacity: 1
  },
  exitActive: {
    opacity: 0,
    transition: 'all 250ms ease-in-out'
  }
})

const useRotateFade = makeStyles()({
  enter: {
    opacity: 0,
    transform: 'scale(0) rotate(360deg)'
  },
  enterActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'scale(1) rotate(0)'
  },
  appear: {
    opacity: 0,
    transform: 'scale(0) rotate(360deg)'
  },
  appearActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'scale(1) rotate(0)'
  },
  exit: {
    opacity: 1,
    transform: 'scale(1) rotate(0)'
  },
  exitActive: {
    opacity: 0,
    transition: 'all 250ms ease-in-out',
    transform: 'scale(0) rotate(360deg)'
  }
})

const useSlideFadeDown = makeStyles()({
  enter: {
    opacity: 0,
    transform: 'translateY(-50px)'
  },
  enterActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(0)'
  },
  appear: {
    opacity: 0,
    transform: 'translateY(-50px)'
  },
  appearActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(0)'
  },
  exit: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  exitActive: {
    opacity: 0,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(-50px)'
  }
})

const useSlideFadeRight = makeStyles()({
  enter: {
    opacity: 0,
    transform: 'translateX(50px)'
  },
  enterActive: {
    opacity: 1,
    transition: 'all 500ms ease-in-out',
    transform: 'translateX(0)'
  },
  appear: {
    opacity: 0,
    transform: 'translateX(50px)'
  },
  appearActive: {
    opacity: 1,
    transition: 'all 500ms ease-in-out',
    transform: 'translateX(0)'
  },
  exit: {
    opacity: 1,
    transform: 'translateX(0)'
  },
  exitActive: {
    opacity: 0,
    transition: 'all 250ms ease-in-out',
    transform: 'translateX(50px)'
  }
})

const useSlideFade = makeStyles()({
  enter: {
    opacity: 0,
    transform: 'translateY(50px)'
  },
  enterActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(0)'
  },
  appear: {
    opacity: 0,
    transform: 'translateY(50px)'
  },
  appearActive: {
    opacity: 1,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(0)'
  },
  exit: {
    opacity: 1,
    transform: 'translateY(0)'
  },
  exitActive: {
    opacity: 0,
    transition: 'all 250ms ease-in-out',
    transform: 'translateY(50px)'
  }
})

const TransitionComponent = props => {
  const { classes: grow } = useGrow()
  const { classes: fadeSlow } = useFadeSlow()
  const { classes: fade } = useFade()
  const { classes: fadeUp } = useFadeUp()
  const { classes: rotateFade } = useRotateFade()
  const { classes: slideFadeDown } = useSlideFadeDown()
  const { classes: slideFade } = useSlideFade()
  const { classes: slideFadeRight } = useSlideFadeRight()

  const { children, type, useKeys } = props
  let theme = grow
  let enter = 250
  let exit = 250

  if (type === 'fade') {
    theme = fade
  }

  if (type === 'fadeSlow') {
    enter = 750
    exit = 750
    theme = fadeSlow
  }

  if (type === 'fadeUp') {
    theme = fadeUp
  }

  if (type === 'slideFade') {
    theme = slideFade
  }

  if (type === 'slideFadeDown') {
    theme = slideFadeDown
  }

  if (type === 'rotateFade') {
    theme = rotateFade
  }

  if (type === 'slideFadeRight') {
    theme = slideFadeRight
  }

  let items = children

  if (!children) {
    items = []
  } else if (!children.map) {
    items = [children]
  }

  return (
    <TransitionGroup>
      {
        items.map((item, index) => (
          <CSSTransition
            key={useKeys ? item.key : index}
            classNames={theme}
            timeout={{
              enter,
              exit
            }}
            {...props}
          >
            {item}
          </CSSTransition>
        ))
      }
    </TransitionGroup>
  )
}

TransitionComponent.propTypes = {
  children: PropTypes.any,
  useKeys: PropTypes.bool,
  type: PropTypes.string
}

export default TransitionComponent
