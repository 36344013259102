import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material'

import { IconButton } from 'containers/themed'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

const useStyles = makeStyles()(theme => ({
  button: {
    width: '100px'
  },
  title: {
    color: !theme.isDark && '#404040',
    marginBottom: '1em'
  },
  actions: {
    marginRight: '0.5em',
    marginBottom: '0.5em'
  }
}))

const DistributionListsDelete = ({ distObject, onDelete }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    onDelete(distObject.get('id'))
    setOpen(false)
  }

  return (
    <div>
      <IconButton
        onClick={handleOpen}
        title={i18n.get('delete')}
      >
        <DeleteOutlineIcon />
      </IconButton>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
      >
        <DialogTitle className={classes.title}>{`${i18n.get('delete_list')}"${distObject.get('name')}" ?`}</DialogTitle>
        <DialogActions className={classes.actions}>
          <Button
            className={classes.button}
            color="inherit"
            onClick={handleClose}
          >
            {i18n.get('cancel')}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            startIcon={<DeleteOutlineIcon />}
            onClick={handleDelete}
          >
            {i18n.get('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

DistributionListsDelete.propTypes = {
  distObject: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default DistributionListsDelete
