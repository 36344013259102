import { connect } from 'react-redux'

import {
  saveCampaignFormlessStart
} from 'actions/content_desk'

import {
  getCDeskCalendarLoadingCampaignIds
} from 'selectors'

import CampaignStatus from 'components/content_desk_new/campaigns/campaign/campaign_status'

const mapStateToProps = (state, ownProps) => ({
  campaign: ownProps.campaign,
  requestRunning: !!getCDeskCalendarLoadingCampaignIds(state).get(ownProps.campaign.get('id'))
})

export default connect(
  mapStateToProps,
  {
    onChange: saveCampaignFormlessStart
  }
)(CampaignStatus)

