import { connect } from 'react-redux'

import RecipientsList from 'components/content_desk_new/contents/content_edit_dialog/recipients_selection/RecipientsList'

import {
  getCDeskContentFormDataRecipients
} from 'selectors'

import {
  setContentFormData,
  setRecipientBrowserOpen
} from 'actions/content_desk'

const mapStateToProps = state => ({
  recipients: getCDeskContentFormDataRecipients(state)
})

export default connect(
  mapStateToProps,
  {
    setContentFormData,
    openRecipientsBrowser: () => setRecipientBrowserOpen(true)
  }
)(RecipientsList)
