import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Paper,
  Grid,
  Typography,
  Card,
  CardContent
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { lighten } from '@mui/material/styles'
import AddIcon from '@mui/icons-material/Add'
import MailIcon from '@mui/icons-material/MailOutline'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import FacebookIcon from '@mui/icons-material/Facebook'
import InfoIcon from '@mui/icons-material/Info'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  container: {
    borderRadius: 20,
    minHeight: 200,
    backgroundColor: lighten(theme.palette.primary.main, 0.8)
  },
  loadingContainer: {
    textAlign: 'center'
  },
  card: {
    borderRadius: 20,
    cursor: 'pointer',
    boxShadow: 'none',
    height: 150,
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    '&:hover': {
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
  },
  title: {
    backgroundColor: '#FFFFFF',
    color: theme.palette.primary.main,
    borderRadius: 8,
    height: '1.6em',
    width: '1.6em',
    display: 'block',
    textAlign: 'center',
    marginBottom: 10
  },
  mailIcon: {
    fontSize: 16,
    verticalAlign: 'middle'
  },
  socialIcon: {
    fontSize: 12,
    margin: -1
  },
  otsLogo: {
    width: 20,
    height: 10
  },
  infoButton: {
    marginTop: -5
  },
  infoIcon: {
    color: grey[600]
  }
}))

export default function PrimaryNavigation({
  hasContactsManagement,
  hasLinkedIn,
  hasFacebook,
  hasOts,
  openContent,
  openCampaignForm,
  openContentDialog,
  openOtsWelcomeDialog
}) {
  const i18n = useI18n()
  const { classes } = useStyles()

  const renderEmailCreateCard = () => {
    if (!hasContactsManagement) {
      return null
    }

    return (
      <Grid
        xs={12}
        sm={6}
        lg={3}
        item
      >
        <Card
          onClick={() => openContent({ type: 'email' })}
          className={classes.card}
        >
          <CardContent>
            <Box className={classes.title}>
              <MailIcon
                className={classes.mailIcon}
              />
            </Box>
            <Typography
              variant="subtitle2"
            >
              {i18n.get('send_mail_content')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 12, mt: 1 }}
            >
              {i18n.get('send_mail_content_description')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const renderSocialMediaCreateCard = () => {
    if (!hasLinkedIn && !hasFacebook) {
      return null
    }

    return (
      <Grid
        xs={12}
        sm={6}
        lg={3}
        item
      >
        <Card
          onClick={() => openContentDialog({ socialOnly: true })}
          className={classes.card}
        >
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              className={classes.title}
            >
              {hasLinkedIn && (<LinkedInIcon className={classes.socialIcon} />)}
              {hasFacebook && (<FacebookIcon className={classes.socialIcon} />)}
            </Box>
            <Typography
              variant="subtitle2"
            >
              {i18n.get('create_social_content')}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 12, mt: 1 }}
            >
              {i18n.get('create_social_content_description')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const renderOtsCreateCard = () => {
    if (hasOts) {
      return (
        <Grid
          xs={12}
          sm={6}
          lg={3}
          item
        >
          <Card
            onClick={() => openContent({ type: 'ots_press_release' })}
            className={classes.card}
          >
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.title}
                >
                  <img
                    src="https://static.pressrelations.de/pics/groot/ccd/naLogo_Condensed.png"
                    alt="ots"
                    className={classes.otsLogo}
                  />
                </Box>
                <Box className={classes.infoButton}>
                  <IconButton
                    size="small"
                    onClick={event => {
                      event.stopPropagation()
                      openOtsWelcomeDialog()
                    }}
                  >
                    <InfoIcon
                      className={classes.infoIcon}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Typography
                variant="subtitle2"
              >
                {i18n.get('create_ots_release')}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: 12, mt: 1 }}
              >
                {i18n.get('create_ots_release_description')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )
    }

    return null
  }

  return (
    <Paper
      sx={{ p: 2 }}
      elevation={0}
      className={classes.container}
    >
      <Typography
        variant="h5"
        sx={{ m: 1 }}
      >
        {i18n.get('what_to_do')}
      </Typography>
      <Box>
        <Grid
          spacing={1}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <Grid
            xs={12}
            sm={6}
            lg={3}
            item
          >
            <Card
              onClick={() => openCampaignForm()}
              className={classes.card}
            >
              <CardContent>
                <Box className={classes.title}>
                  <AddIcon />
                </Box>
                <Typography
                  variant="subtitle2"
                >
                  {i18n.get('create_campaign')}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: 12, mt: 1 }}
                >
                  {i18n.get('create_campaign_description')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          {renderEmailCreateCard()}
          {renderOtsCreateCard()}
          {renderSocialMediaCreateCard()}
        </Grid>
      </Box>
    </Paper>
  )
}

PrimaryNavigation.propTypes = {
  hasContactsManagement: PropTypes.bool.isRequired,
  hasLinkedIn: PropTypes.bool.isRequired,
  hasFacebook: PropTypes.bool.isRequired,
  hasOts: PropTypes.bool.isRequired,
  openContent: PropTypes.func.isRequired,
  openContentDialog: PropTypes.func.isRequired,
  openCampaignForm: PropTypes.func.isRequired,
  openOtsWelcomeDialog: PropTypes.func.isRequired
}
