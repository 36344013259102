import { useSelector } from 'react-redux'
import { getColors } from 'selectors'

export default function useColors(asArray = false) {
  const colors = useSelector(getColors)

  if (asArray) {
    const colorsArray = [
      colors.get('primary'),
      colors.get('accent')
    ]

    for (let i = 1; i < 11; i += 1) {
      const colorKey = i < 10 ? `color0${i}` : 'color10'
      colorsArray.push(colors.getIn(['charts', colorKey]))
    }

    return colorsArray.filter(c => c)
  }

  return useSelector(getColors)
}
