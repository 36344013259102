import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Map, List } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment-timezone'

import { useSnackbar } from 'notistack'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'

import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Autocomplete,
  useMediaQuery
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'

import CloseConfirmDialog from 'containers/content_desk_new/campaigns/campaign_form/CloseConfirmDialog'

import { InlineDatePicker, Button, IconButton, Select } from 'containers/themed'

import { mapToOptions } from 'utils/autocomplete'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  form: {
    paddingTop: '10px',
    maxWidth: '700px'
  },
  datePicker: {
    minWidth: '100%'
  },
  dialogActions: {
    padding: '25px'
  }
}))

const CampaignForm = ({ data,
  onUpdate,
  open,
  isInEditMode,
  assignees,
  campaignNameValid,
  onClose,
  onSave,
  resetForm }) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [formHasChanged, setFormHasChanged] = useState(false)
  const [isStatusDisabled, setIsStatusDisabled] = useState(false)

  const statusOptions = [
    { value: true, label: i18n.get('active') },
    { value: false, label: i18n.get('closed') }
  ]

  useEffect(() => {
    const endDate = data.get('endDate')

    if (endDate && moment(endDate).isBefore(moment())) {
      setIsStatusDisabled(true)
    } else {
      setIsStatusDisabled(false)
    }
  }, [data])

  const handleChange = e => {
    setFormHasChanged(true)
    const { name, value } = e.target
    onUpdate({ [name]: value })
  }

  const handleAutocompleteChange = (name, el) => {
    setFormHasChanged(true)

    if (!el) {
      onUpdate({ [name]: null })
    } else {
      const { value } = el
      onUpdate({ [name]: value })
    }
  }

  const handleDateChange = (name, value) => {
    setFormHasChanged(true)
    let newValue = null

    if (value) {
      // NOTE: Not proud of this, but otherwise campaigns someone created in Sofia will be shifted one day for Berlin.
      newValue = value.add(12, 'hours')
    }

    if (name === 'endDate' && moment(value).isBefore(moment())) {
      onUpdate({ active: false })
      setIsStatusDisabled(true)
    } else if (name === 'endDate' && moment(value).isAfter(moment())) {
      onUpdate({ active: true })
      setIsStatusDisabled(false)
    }

    onUpdate({ [name]: newValue })
  }

  const handleStatusChange = option => {
    setFormHasChanged(true)
    onUpdate({ active: option.value })
  }

  const [confDialogOpen, setConfDialogOpen] = useState(false)

  const onExit = () => {
    onClose()
    closeSnackbar()
    setFormHasChanged(false)
    resetForm()
    setConfDialogOpen(false)
  }

  const handleClose = () => {
    if (formHasChanged) {
      setConfDialogOpen(true)
    } else {
      closeSnackbar()
      onClose()
      setFormHasChanged(false)
      resetForm()
    }
  }

  const handleSave = () => {
    if (!data.get('name')) {
      return enqueueSnackbar(
        `${i18n.get('campaign')} ${i18n.get('name')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if ((!data.get('startDate') && data.get('endDate'))) {
      return enqueueSnackbar(
        `${i18n.get('start_date')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if ((data.get('startDate') && !data.get('endDate'))) {
      return enqueueSnackbar(
        `${i18n.get('end_date')} ${i18n.get('is_required')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    onSave()
    onClose()
    closeSnackbar()
    setFormHasChanged(false)
    setConfDialogOpen(false)

    return resetForm()
  }

  const userOptions = mapToOptions(assignees)

  return (
    <>
      <CloseConfirmDialog
        open={confDialogOpen}
        onClose={() => onExit()}
        onSave={() => handleSave()}
      />
      <Dialog
        open={open}
        fullScreen={mobile}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          {isInEditMode ? i18n.get('edit') : i18n.get('create_campaign')}
          <IconButton
            className={classes.closeButton}
            title={i18n.get('close')}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl
            className={classes.form}
          >
            <Grid
              container
              spacing={2}
            >
              <Grid
                xs={12}
                item
              >
                <TextField
                  fullWidth
                  required
                  error={data.get('name') === '' || !campaignNameValid}
                  label={i18n.get('campaign_name') || ''}
                  value={data.get('name')}
                  name="name"
                  placeholder={i18n.get('name_your_campaign')}
                  onChange={handleChange}
                  helperText={!campaignNameValid && i18n.get('campaign_name_error')}
                  autoComplete="off"
                />
              </Grid>
              <Grid
                xs={12}
                item
              >
                <Autocomplete
                  key="assignedUserId"
                  options={mapToOptions(assignees)}
                  getOptionLabel={option => option.label}
                  value={userOptions.find(o => o.value === data.get('assignedUserId')) || null}
                  onChange={(_event, newValue) => handleAutocompleteChange('assignedUserId', newValue)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={i18n.get('assigned_user')}
                      placeholder={i18n.get('select_user')}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                />
              </Grid>
              <Grid
                xs={12}
                item
              >
                <Select
                  disabled={isStatusDisabled}
                  variant="outlined"
                  onChange={handleStatusChange}
                  options={statusOptions}
                  value={(statusOptions.find(o => o.value === data.get('active')) || {}).value}
                  label={i18n.get('status')}
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <InlineDatePicker
                  label={i18n.get('start_date')}
                  value={data.get('startDate') ? moment(data.get('startDate')) : null}
                  className={classes.datePicker}
                  onChange={value => handleDateChange('startDate', value)}
                  maxDate={moment(data.get('endDate'))}
                  renderInput={params => (
                    <TextField
                      {...params}
                    />
                  )}
                  autoComplete="off"
                />
              </Grid>
              <Grid
                xs={6}
                item
              >
                <InlineDatePicker
                  label={i18n.get('end_date')}
                  value={data.get('endDate') ? moment(data.get('endDate')) : null}
                  className={classes.datePicker}
                  onChange={value => handleDateChange('endDate', value)}
                  minDate={moment(data.get('startDate'))}
                  renderInput={params => (
                    <TextField
                      {...params}
                    />
                  )}
                  autoComplete="off"
                />
              </Grid>
              <Grid
                xs={12}
                item
              >
                <TextField
                  fullWidth
                  label={i18n.get('briefing')}
                  multiline
                  rows={10}
                  value={data.get('summary') || ''}
                  name="summary"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            key="cancel"
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            {i18n.get('cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={data.get('name') === '' || !campaignNameValid}
          >
            {i18n.get('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CampaignForm.defaultProps = {
  assignees: List()
}

CampaignForm.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  open: PropTypes.bool.isRequired,
  isInEditMode: PropTypes.bool.isRequired,
  assignees: PropTypes.instanceOf(List),
  campaignNameValid: PropTypes.bool.isRequired,

  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired
}

export default CampaignForm
