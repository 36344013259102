import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'
import { Map } from 'immutable'

import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Checkbox,
  Dialog,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { rgbaColorFromHex } from 'utils/color'
import { IconButton, Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  mobileContentContainer: {
    padding: '0px 20px 20px 20px'
  },
  contentContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  closeButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: theme.palette.common.black
  },
  orderConditions: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',

    '& a': {
      color: theme.palette.secondary.main
    }
  },
  termsAndConditionsText: {
    marginTop: 10
  },
  orderButton: {
    backgroundColor: theme.palette.primary.main,
    opacity: 1,
    margin: '20px'
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftContainer: {
    padding: '60px 50px 50px 50px',
    flex: '1 1 60%'
  },
  rightContainer: {
    textAlign: 'center',
    flex: '1 1 40%',
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`
  },
  mobilePriceContainer: {
    backgroundColor: `${rgbaColorFromHex(theme.palette.primary.main, 0.3)}`,
    textAlign: 'center',
    padding: '10px 10px 50px 10px'
  },
  priceContainer: {
    padding: '0px 50px 50px 50px'
  },
  price: {
    paddingTop: 20
  }
}))

const OrderDialog = ({
  loading,
  paidFeature,
  open,
  onClose,
  onOrderClick,
  termsAndConditionsLink,
  privacyLink
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()
  const [orderCondition1, setOrderCondition1] = useState(false)
  const [orderCondition2, setOrderCondition2] = useState(false)

  const date = `${moment().format('YYYY')}/${moment().add(1, 'Y').format('YY')}`

  const handleClose = () => {
    onClose()
  }

  const handleUnlockClick = () => {
    onOrderClick(paidFeature.get('paidFeatureId'))
    onClose()
  }

  const handleOrderCondition1 = () => {
    setOrderCondition1(!orderCondition1)
  }

  const handleOrderCondition2 = () => {
    setOrderCondition2(!orderCondition2)
  }

  const themaxOrderTitle = (
    <Typography
      variant="h4"
      gutterBottom
    >
      {i18n.get('themax_order_title', { date })}
    </Typography>
  )

  const themaxOrderSubtitle = (
    <Typography
      variant="h5"
    >
      {i18n.get('themax_order_subtitle')}
    </Typography>
  )

  const benfitsList = (
    <List>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_01')}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_02')}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_03')}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_04')}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_05')}
        </Typography>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <CheckCircleIcon color="primary" />
        </ListItemIcon>
        <Typography color="text.secondary">
          {i18n.get('themax_benefits_item_06')}
        </Typography>
      </ListItem>
    </List>
  )

  const themaxPrice = (
    <Typography
      variant="h3"
      className={classes.price}
      gutterBottom
    >
      {paidFeature.getIn(['config', 'price'])} {paidFeature.getIn(['config', 'currency'])}
    </Typography>
  )

  const themaxPriceExplaination = (
    <>
      {paidFeature.getIn(['config', 'vat']) > 0 && (
        <Typography
          variant="body1"
          color="text.secondary"
        >
          + {paidFeature.getIn(['config', 'vat'])}% {i18n.get('vat')}
        </Typography>
      )}
      <Typography
        gutterBottom
      >
        {i18n.get('themax_price_explanation')}
      </Typography>
    </>
  )

  const renderOrderConditions = () => (
    <>
      <Box className={classes.orderConditions}>
        <Checkbox
          checked={orderCondition1}
          onChange={handleOrderCondition1}
        />
        <Box
          className={classes.termsAndConditionsText}
          dangerouslySetInnerHTML={{
            __html: i18n.get('paid_feature_order_terms_and_conditions_1', { product: paidFeature.get('name') })
          }}
        />
      </Box>
      <Box className={classes.orderConditions}>
        <Checkbox
          checked={orderCondition2}
          onChange={handleOrderCondition2}
        />
        <Box
          className={classes.termsAndConditionsText}
          dangerouslySetInnerHTML={{
            __html: i18n.get('paid_feature_order_terms_and_conditions_2', { terms: termsAndConditionsLink, privacy: privacyLink })
          }}
        />
      </Box>
    </>
  )

  const orderButton = (
    <Button
      disabled={loading || !orderCondition1 || !orderCondition2}
      loading={loading}
      color="primary"
      variant="contained"
      className={classes.orderButton}
      onClick={handleUnlockClick}
    >
      {i18n.get('order_now')}
    </Button>
  )

  const renderContent = () => {
    if (device.get('mobile')) {
      return (
        <Box className={classes.mobileContentContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {themaxOrderTitle}
          {themaxOrderSubtitle}
          {benfitsList}
          <Box className={classes.mobilePriceContainer}>
            {themaxPrice}
            {themaxPriceExplaination}
            {renderOrderConditions()}
            {orderButton}
          </Box>
        </Box>
      )
    }

    return (
      <>
        <Box className={classes.leftContainer}>
          {themaxOrderTitle}
          {themaxOrderSubtitle}
          {benfitsList}
        </Box>
        <Box className={classes.rightContainer}>
          <Box className={classes.closeButtonContainer}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              className={classes.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.priceContainer}>
            {themaxPrice}
            {themaxPriceExplaination}
            {renderOrderConditions()}
            {orderButton}
          </Box>
        </Box>
      </>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.content}>
          {renderContent()}
        </Box>
      </Paper>
    </Dialog>
  )
}

OrderDialog.propTypes = {
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  paidFeature: PropTypes.instanceOf(Map).isRequired,
  termsAndConditionsLink: PropTypes.string,
  privacyLink: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onOrderClick: PropTypes.func.isRequired
}

export default OrderDialog
