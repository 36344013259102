import { connect } from 'react-redux'

import DistributionLists from 'components/content_desk_new/contents/content_edit_dialog/recipients_selection/DistributionLists'

import {
  getCDeskContentFormDataRecipients,
  getCDeskContentFormSelectedDistributionLists
} from 'selectors'

import {
  setContentFormData
} from 'actions/content_desk'

const mapStateToProps = (state, { hideOnEmpty }) => ({
  recipients: getCDeskContentFormDataRecipients(state),
  selectedDistributionLists: getCDeskContentFormSelectedDistributionLists(state),
  hideOnEmpty
})

export default connect(
  mapStateToProps,
  {
    setContentFormData
  }
)(DistributionLists)
