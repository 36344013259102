/* global window */
import React from 'react'
import PropTypes from 'prop-types'
import ChannelIcon from 'components/channel_icon'
import { IconButton } from 'containers/themed'

const CHANNELS = {
  tiktok: 28,
  facebook: 6,
  twitter: 7,
  linkedin: 21,
  instagram: 9,
  youtube: 8,
  threads: 37,
  other: 2
}

export default function SmIcon({ channel, url }) {
  if (!url) {
    return null
  }

  const handleClick = () => window.open(url, '_blank', 'noopener,noreferrer')

  return (
    <IconButton onClick={handleClick}>
      <ChannelIcon
        circle={false}
        id={CHANNELS[channel]}
      />
    </IconButton>
  )
}

SmIcon.propTypes = {
  channel: PropTypes.string.isRequired,
  url: PropTypes.string
}
