import React from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import { makeStyles } from 'tss-react/mui'

import { Button } from 'containers/themed'

import useI18n from 'hooks/useI18n'

import { Paper, Typography, Slide } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  noHits: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noHitsPaper: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[700],
    width: 750,
    padding: '20px 20px 10px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.only('xs')]: {
      width: '98%'
    }
  }
}))

export default function NoHits({ active, onHelpClick }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  return (
    <Slide
      direction="up"
      in={active}
    >
      <div className={classes.noHits}>
        <Paper classes={{ root: classes.noHitsPaper }}>
          <Typography variant="h5">
            {i18n.get('no_hits_title')}
          </Typography>

          <br />

          <Typography variant="body1">
            {i18n.get('search_hints')}:
          </Typography>

          <Typography variant="body1">
            - {i18n.get('search_hint_1')}
          </Typography>

          <Typography variant="body1">
            - {i18n.get('search_hint_2')}
          </Typography>

          <Typography variant="body1">
            - {i18n.get('search_hint_3')}
          </Typography>

          <br />
          <Button
            onClick={() => onHelpClick()}
            startIcon={<HelpIcon />}
          >
            {i18n.get('help')}
          </Button>
        </Paper>
      </div>
    </Slide>
  )
}

NoHits.propTypes = {
  active: PropTypes.bool.isRequired,

  onHelpClick: PropTypes.func.isRequired
}
