import { connect } from 'react-redux'

import ExportMenu from 'components/export_menu'
import { showExportDialog } from 'actions/export'

import { getExportOptions, getNewsIdsForExport, getI18n } from 'selectors'

const mapStateToProps = (state, { disabled }) => ({
  disabled: disabled !== undefined ? disabled : getExportOptions(state).get('isRunning') || !getNewsIdsForExport(state).size,
  i18n: getI18n(state)
})

export default connect(
  mapStateToProps,
  {
    onClick: showExportDialog
  }
)(ExportMenu)
