/* global document */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fromJS, Set } from 'immutable'
import {
  DndContext,
  closestCenter,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core'
import {
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

import { equals as stringEquals } from 'utils/string'
import SortableItem from '../sortable_item'
import Item from './item'

export default function MultisortNewsList({
  news,
  NewsComponent,
  NewsComponentProps,
  onSortEnd,
  onReset,
  selectedNews,
  codeId,
  useOverlay,
  sortingDisabled
}) {
  const [activeId, setActiveId] = useState(null)

  const filterNews = value => {
    if (!activeId) {
      return true
    }

    if (useOverlay) {
      return stringEquals(activeId, value.get('id')) || !(selectedNews.find(n => n.get('newsId') === value.get('id')))
    }

    return !(selectedNews.find(n => n.get('newsId') === value.get('id') && !stringEquals(n.get('newsId'), activeId)))
  }

  const filteredNews = news.filter(filterNews)
  const items = filteredNews.map(n => `${n.get('id')}`).toJS()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  )

  const onDragStart = event => {
    document.body.style.cursor = 'grabbing'

    const { active } = event

    setActiveId(active.id)

    if (!selectedNews.isEmpty() && !selectedNews.find(n => stringEquals(n.get('newsId'), active.id))) {
      onReset()
    }
  }

  const onDragEnd = event => {
    const { active, over } = event

    if ((active && over) && active.id !== over.id) {
      const oldIndex = filteredNews.findIndex(n => stringEquals(n.get('id'), active.id))
      const newIndex = filteredNews.findIndex(n => stringEquals(n.get('id'), over.id))

      onSortEnd({ codeId, oldIndex, newIndex, selectedNews })
    }

    setActiveId(null)

    document.body.style.cursor = ''
  }

  const onDragCancel = () => {
    document.body.style.cursor = ''

    setActiveId(null)
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <SortableContext
        items={items}
        strategy={verticalListSortingStrategy}
      >
        {filteredNews.map(n => (
          <SortableItem
            key={`${n.get('id')}`}
            id={`${n.get('id')}`}
            disabled={sortingDisabled}
          >
            <div style={useOverlay && stringEquals(activeId, n.get('id')) ? { visibility: 'hidden' } : {}}>
              <NewsComponent
                news={n}
                selectedForSorting={selectedNews.has(fromJS({ newsId: n.get('id'), codeId }))}
                {...NewsComponentProps}
              />
            </div>
          </SortableItem>
        ))}
      </SortableContext>

      {useOverlay && (
        <DragOverlay>
          {activeId ? (
            <Item
              id={activeId}
              NewsComponent={NewsComponent}
              NewsComponentProps={NewsComponentProps}
              news={news}
            />
          ) : null}
        </DragOverlay>
      )}
    </DndContext>
  )
}

MultisortNewsList.defaultProps = {
  NewsComponentProps: {},
  useOverlay: true,
  sortingDisabled: false
}

MultisortNewsList.propTypes = {
  news: PropTypes.object.isRequired,
  selectedNews: PropTypes.instanceOf(Set).isRequired,
  NewsComponent: PropTypes.any.isRequired,
  NewsComponentProps: PropTypes.object,
  codeId: PropTypes.any,
  useOverlay: PropTypes.bool,
  sortingDisabled: PropTypes.bool,

  onSortEnd: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
}
