import { connect } from 'react-redux'

import MediaReviewNewsListItem from 'components/media_reviews/media_review_detail/news_list_item'
import {
  isExpandedMediaReviewNewsByCode,
  isSummaryExpandedMediaReviewNewsByCode,
  getFullTextById
} from 'selectors'

const mapStateToProps = (state, { codeId, news }) => ({
  codeId,
  news,
  subsequentlyLoadedFullText: getFullTextById(state, news.get('id')) || null,
  expanded: isExpandedMediaReviewNewsByCode(state, news.get('id'), codeId),
  summaryExpanded: isSummaryExpandedMediaReviewNewsByCode(state, news.get('id'), codeId)
})

export default connect(mapStateToProps)(MediaReviewNewsListItem)
