import { connect } from 'react-redux'

import GroupedNewsList from 'components/media_reviews/media_review_detail/grouped_news_list'
import { moveCodeInMediaReviewStart } from 'actions/media_reviews'
import { getMediaReviewCodes, getI18n, getCapabilities } from 'selectors'
import { Capabilities } from 'static/constants'

const mapStateToProps = state => ({
  codes: getMediaReviewCodes(state),
  i18n: getI18n(state),
  sortingDisabled: !getCapabilities(state).get(Capabilities.HAS_MEDIA_REVIEW_EDITING_FEATURE)
})

export default connect(
  mapStateToProps,
  {
    onSortEnd: moveCodeInMediaReviewStart
  }
)(GroupedNewsList)
