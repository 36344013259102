export const isSavedSearchOutdated = (currentFilters, savedSearchFilters) => {
  // Helper function to compare value types
  const compareValues = (value1, value2) => {
    const type1 = Array.isArray(value1) ? 'array' : typeof value1
    const type2 = Array.isArray(value2) ? 'array' : typeof value2

    return type1 === type2 // Return true if both values are of the same type
  }

  // Recursive function to compare objects and arrays
  const compareStructures = (obj1, obj2) => {
    const keys = Object.keys(obj2) // Get all keys from obj2
    let isOutdated = false // Flag to determine if the saved search is outdated

    // Iterate over each key in obj2
    keys.forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(obj1, key)) {
        isOutdated = true // Key does not exist in obj1, mark as outdated

        return
      }

      if (!compareValues(obj1[key], obj2[key])) {
        isOutdated = true // Value types do not match, mark as outdated

        return
      }

      // Check if the value is an object (and not null) or an array
      const isObjectOrArray = value => (typeof value === 'object' && value !== null) || Array.isArray(value)
      // Check if the object or array is empty
      const isEmptyStructure = value => (Array.isArray(value) && value.length === 0)
        || (typeof value === 'object' && value !== null && Object.keys(value).length === 0)

      // If both values are objects or arrays, compare recursively
      if (isObjectOrArray(obj1[key]) && isObjectOrArray(obj2[key])) {
        // If the structure in obj1 is not empty, compare structures
        if (!isEmptyStructure(obj1[key])) {
          if (compareStructures(obj1[key], obj2[key])) {
            isOutdated = true // Nested structures are outdated, mark as outdated
          }
        }
      }
    })

    return isOutdated // Return the flag indicating if the saved search is outdated
  }

  // Initial type check for the root objects
  if (
    typeof currentFilters !== 'object'
    || currentFilters === null
    || typeof savedSearchFilters !== 'object'
    || savedSearchFilters === null
  ) {
    return true // If either object is not an object or is null, they are not comparable
  }

  return compareStructures(currentFilters, savedSearchFilters) // Compare the structures of currentFilters and savedSearchFilters
}
