import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { Capabilities } from 'static/constants'
import CapSafe from 'containers/CapSafe'
import { Button, IconButton } from 'containers/themed'

import SettingsIcon from '@mui/icons-material/Settings'
import { makeStyles } from 'tss-react/mui'

const QmIcon = CapSafe(IconButton, Capabilities.HAS_DARKNET_QUERY_MANAGER)
const QmButton = CapSafe(Button, Capabilities.HAS_DARKNET_QUERY_MANAGER)

const useStyles = makeStyles()(theme => ({
  settingsButton: {
    marginLeft: 15,
    color: theme.palette.text.secondary
  },
  iconButton: {
    [theme.breakpoints.only('sm')]: {
      padding: 8
    }
  }
}))

export default function Actions({ device, onQueryManagerClick }) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const renderQueryManagerButton = () => {
    let comp = (
      <div>
        <QmButton
          className={classes.settingsButton}
          variant="text"
          onClick={onQueryManagerClick}
          startIcon={<SettingsIcon />}
        >
          {i18n.get('query_manager')}
        </QmButton>
      </div>
    )

    if (device.get('lt-md')) {
      comp = (
        <QmIcon
          classes={{ root: classes.iconButton }}
          color="inherit"
          onClick={onQueryManagerClick}
        >
          <SettingsIcon />
        </QmIcon>
      )
    }

    return comp
  }

  return renderQueryManagerButton()
}

Actions.propTypes = {
  onQueryManagerClick: PropTypes.func.isRequired,
  device: PropTypes.instanceOf(Map)
}
