import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/identity_sets'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  identitySetIds: []
})

export default handleActions({
  [Actions.showAddIdentityToIdentitySetDialog]: (state, { payload: identity }) => identity,

  [Actions.hideAddIdentityToIdentitySetDialog]: () => initialState,

  [Actions.addIdentityToIdentitySetSuccess]: (state, { payload: { identitySet, identityId } }) => {
    if (state.get('id') === identityId) {
      return state
        .update('identitySetIds', ids => ids.push(identitySet.get('id')))
    }

    return state
  },

  [Actions.removeIdentityFromIdentitySetSuccess]: (state, { payload: { identitySet, identityId } }) => {
    if (state.get('id') === identityId) {
      return state
        .update('identitySetIds', ids => ids.filter(id => id !== identitySet.get('id')))
    }

    return state
  },

  [Actions.deleteIdentitySetSuccess]: (state, { payload: identitySetId }) => (
    state
      .update('identitySetIds', ids => ids.filter(id => id !== identitySetId))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
