import { createAction } from 'redux-actions'

const prefix = 'SUBSCRIPTIONS/'

export const saveSubscriptionsError = createAction(`${prefix}SAVE_SUBSCRIPTIONS_ERROR`)
export const saveSubscriptionsStart = createAction(`${prefix}SAVE_SUBSCRIPTIONS_START`)
export const saveSubscriptionsSuccess = createAction(`${prefix}SAVE_SUBSCRIPTIONS_SUCCESS`)
export const selectAllTopics = createAction(`${prefix}SELECT_ALL_TOPICS`)
export const deselectAllTopics = createAction(`${prefix}DESELECT_ALL_TOPICS`)
export const setSubscriptions = createAction(`${prefix}SET_SUBSCRIPTIONS`)
export const setTopics = createAction(`${prefix}SET_TOPICS`)
export const updateEditionSelection = createAction(`${prefix}UPDATE_EDITION_SELECTION`)
export const updateTopicSelection = createAction(`${prefix}UPDATE_TOPIC_SELECTION`)
