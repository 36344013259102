import React from 'react'
import { Map } from 'immutable'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'

import nrxTheme from 'stylesheets/theme.json'
import plotlightsTheme from 'stylesheets/plotlights.json'

export default Map({
  theme: Map({
    nrx: Map(nrxTheme),
    plotlights: Map(plotlightsTheme)
  }),
  icons: Map({
    publications: <LocalLibraryIcon />,
    influencers: 'group',
    customTags: 'loyalty',
    savedSearches: 'all_inbox',
    codes: 'local_offer',
    profiles: 'contacts',
    channels: Map({
      1: 'fa fa-newspaper-o',
      2: 'fa fa-globe',
      3: 'fa fa-television',
      4: 'fa fa-headphones',
      5: 'fab fa-blogger',
      6: 'fab fa-facebook',
      7: 'fab fa-x-twitter',
      8: 'fa fa-youtube',
      9: 'fa fa-instagram',
      10: 'fa fa-google-plus',
      11: 'fa fa-forumbee',
      12: 'fa fa-briefcase',
      13: 'fa fa-play',
      14: 'fa fa-foursquare',
      15: 'fa fa-mixcloud',
      16: 'fa fa-soundcloud',
      17: 'fa fa-vimeo',
      18: 'fa fa-weibo',
      19: 'fa fa-flickr',
      20: 'fa fa-ellipsis-h',
      21: 'fa fa-linkedin',
      22: 'fas fa-star-half-alt',
      23: 'fab fa-odnoklassniki',
      24: 'fab fa-vk',
      25: 'fab fa-telegram-plane',
      26: 'fab fa-yandex',
      27: 'fal fa-podcast',
      28: 'fab fa-tiktok',
      29: 'fab fa-reddit',
      30: 'fas fa-envelope',
      31: 'fas fa-comment-alt-lines',
      32: 'fas fa-book',
      33: 'fa fa-weixin',
      34: 'fab fa-zhihu',
      35: 'fab fa-tiktok',
      36: 'fab fa-xing',
      37: 'fab fa-threads',
      38: 'fab fa-mastodon',
      39: 'fab fa-bluesky'
    }),
    identityTypes: Map({
      print: 'fa fa-newspaper-o',
      internet: 'fa fa-globe',
      tv: 'fa fa-television',
      radio: 'fa fa-headphones',
      blog: 'fab fa-blogger',
      facebook: 'fab fa-facebook',
      twitter: 'fab fa-x-twitter',
      youtube: 'fa fa-youtube',
      instagram: 'fa fa-instagram',
      'google-plus': 'fa fa-google-plus',
      forum: 'fa fa-forumbee',
      dailymotion: 'fa fa-play',
      foursquare: 'fa fa-foursquare',
      mixcloud: 'fa fa-mixcloud',
      soundcloud: 'fa fa-soundcloud',
      vimeo: 'fa fa-vimeo',
      weibo: 'fa fa-weibo',
      flickr: 'fa fa-flickr',
      linkedin: 'fa fa-linkedin-square',
      review: 'fas fa-star-half-alt',
      ok: 'fab fa-odnoklassniki',
      vkontakte: 'fab fa-vk',
      telegram: 'fab fa-telegram-plane',
      moikrug: 'fab fa-yandex',
      podcast: 'fal fa-podcast',
      tiktok: 'fab fa-tiktok',
      reddit: 'fab fa-reddit',
      newsletter: 'fas fa-envelope',
      readerComments: 'fas fa-comment-alt-lines',
      littleredbook: 'fas fa-book',
      wechat: 'fa fa-weixin',
      zhihu: 'fab fa-zhihu',
      douyin: 'fab fa-tiktok',
      xing: 'fab fa-xing',
      threads: 'fab fa-threads',
      mastodon: 'fab fa-mastodon',
      bluesky: 'fab fa-bluesky'
    })
  })
})
