// DATA
export const getNewsEditNewsData = state => state.news.getIn(['editNews', 'data'])
export const getSelectedNews = state => getNewsEditNewsData(state).get('selectedNews')
export const getEditNewsPublications = state => getNewsEditNewsData(state).get('publications')
export const getEditNewsUploadedFiles = state => getNewsEditNewsData(state).get('uploadedFiles')
export const getEditNewsUploadedVideoFile = state => getNewsEditNewsData(state).get('uploadedVideoFile')

// UI
export const getNewsEditNewsUi = state => state.news.getIn(['editNews', 'ui'])
export const getEditNewsUploadRunning = state => getNewsEditNewsUi(state).get('uploadRunning')
export const getEditNewsUploadVideoRunning = state => getNewsEditNewsUi(state).get('uploadVideoRunning')
export const getShowEditNewsDialog = state => getNewsEditNewsUi(state).get('active')
export const getEditNewsSaving = state => !getNewsEditNewsUi(state).get('savingNewsIds').isEmpty()
export const getEditNewsSavingNewsIds = state => getNewsEditNewsUi(state).get('savingNewsIds')
export const getEditNewsCreating = state => getNewsEditNewsUi(state).get('creatingNews')
export const getEditNewsLoading = state => getNewsEditNewsUi(state).get('loadingNews')
export const getEditNewsPublicationsLoading = state => getNewsEditNewsUi(state).get('loadingPublications')
export const getEditNewsExtractingText = state => getNewsEditNewsUi(state).get('extractingText')
export const getSummaryOptions = state => getNewsEditNewsUi(state).get('summaryOptions')
