import React from 'react'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import { makeStyles } from 'tss-react/mui'

import { Button, Popover, IconButton } from 'containers/themed'
import Sortbar from 'containers/shopping_cart/Sortbar'

import useI18n from 'hooks/useI18n'

import { Paper, Box, Typography } from '@mui/material'

const useStyles = makeStyles()({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 5px 0 10px'
  },
  spacer: {
    flex: 1
  },
  help: {
    maxWidth: 400,
    padding: 10
  }
})

export default function Toolbar({ rightActions, showSnippets, onToggleSnippets, tab }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Paper
      className={classes.toolbar}
      square
    >
      <Sortbar
        key="sortbar"
        tab={tab}
      />

      <div className={classes.spacer} />

      <Popover
        classes={{ paper: classes.help }}
        handle={(
          <IconButton size="large">
            <HelpIcon />
          </IconButton>
        )}
      >
        <Typography
          dangerouslySetInnerHTML={{
            __html: i18n.get('sorting_hint')
          }}
        />
      </Popover>

      <Box display={{ xs: 'none', sm: 'block' }}>
        <Button
          onClick={() => onToggleSnippets()}
        >
          {i18n.get(`${showSnippets ? 'hide' : 'show'}_snippets`)}
        </Button>
      </Box>
      {rightActions}
    </Paper>
  )
}

Toolbar.propTypes = {
  rightActions: PropTypes.array,
  showSnippets: PropTypes.bool.isRequired,
  tab: PropTypes.string.isRequired,

  onToggleSnippets: PropTypes.func.isRequired
}
