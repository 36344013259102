import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Icon } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()({
  icon: {
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
      transition: 'transform 225ms',
      transform: 'scale(1.5) translateY(-5px)'
    }
  },
  selected: {
    opacity: 1
  }
})

export default function ReactionSelect({
  reactionTypes,
  selectedId,
  onClick
}) {
  const { classes, cx } = useStyles()

  return (
    <>
      {reactionTypes.map(reactionType => (
        <IconButton
          key={reactionType.get('id')}
          size="small"
          title={reactionType.get('name')}
          onClick={() => onClick(reactionType)}
        >
          <Icon
            className={
              cx(
                reactionType.get('iconClass'),
                classes.icon,
                (!selectedId || selectedId === reactionType.get('id').toString()) && classes.selected
              )
            }
            style={{ color: reactionType.get('iconColor') }}
          />
        </IconButton>
      ))}
    </>
  )
}

ReactionSelect.defaultProps = {
  onClick: () => null
}

ReactionSelect.propTypes = {
  reactionTypes: PropTypes.instanceOf(List).isRequired,
  selectedId: PropTypes.string,

  onClick: PropTypes.func
}
