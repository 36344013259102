import { connect } from 'react-redux'

import EmailExistingDialog from 'components/contact_management/contact_edit_dialog/email_existing_dialog/EmailExistingDialog'

import { getCmEmailExistingDialogOpen } from 'selectors'
import { closeEmailExistingDialog } from 'actions/contact_management'

const mapStateToProps = state => ({
  open: getCmEmailExistingDialogOpen(state)
})

export default connect(mapStateToProps, {
  onClose: closeEmailExistingDialog
})(EmailExistingDialog)
