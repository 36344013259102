import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { Menu, IconButton } from '@mui/material'

const MuiMenu = ({ children, innerRef, handle, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const newHandle = React.cloneElement(handle, {
    onClick: event => setAnchorEl(event.currentTarget)
  })

  const newChildren = React.Children.map(
    children,
    child => {
      let clickHandler = () => setAnchorEl(null)

      if (child && child.props && child.props.onClick) {
        clickHandler = (...args) => {
          child.props.onClick(...args)
          setAnchorEl(null)
        }

        return React.cloneElement(child, {
          onClick: clickHandler
        })
      }

      return child
    }
  )

  return (
    <>
      {newHandle}

      <Menu
        ref={innerRef}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
        {...props}
      >
        {newChildren}
      </Menu>
    </>
  )
}

function MenuWrapper(props, ref) {
  return (
    <MuiMenu
      {...props}
      innerRef={ref}
    />
  )
}

export default forwardRef(MenuWrapper)

MuiMenu.defaultProps = {
  handle: (
    <IconButton size="large">
      <MoreVertIcon />
    </IconButton>
  )
}

MuiMenu.propTypes = {
  children: PropTypes.any,
  handle: PropTypes.any,
  innerRef: PropTypes.any
}
