import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  query: '',
  name: '',
  jobTitle: '',
  outletName: ''
})

export default handleActions({
  [Actions.setSearchForm]: (state, { payload: { key, value } }) => state.set(key, value),
  [Actions.switchSearchType]: state => state
    .set('name', '')
    .set('jobTitle', '')
    .set('outletName', ''),
  [Actions.clearModuleData]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
