import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/mailing_lists'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  selectedMailingList: {
    name: '',
    recipients: []
  }
})

export default handleActions({
  [Actions.showMailingListDialog]: (state, action) => {
    if (action.payload) {
      return state.set('selectedMailingList', action.payload)
    }

    return state
  },
  [Actions.updateSelectedMailingList]: (state, { payload: mailingList }) => state.set('selectedMailingList', mailingList),
  [Actions.hideMailingListDialog]: () => initialState,
  [Actions.showRemoveMailingListDialog]: (state, { payload: mailingList }) => state.set('selectedMailingList', mailingList),
  [Actions.hideRemoveMailingListDialog]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
