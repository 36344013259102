import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { Icon } from '@mui/material'

import { IconButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

export default function ReactionSelectTrigger({ reactionType }) {
  const i18n = useI18n()

  return (
    <IconButton
      size="small"
      title={reactionType ? `${i18n.get('nrx_reaction_user')}: ${reactionType.get('name')}` : i18n.get('nrx_reaction_user')}
    >
      {reactionType ? (
        <Icon
          className={reactionType.get('iconClass')}
          style={{ color: reactionType.get('iconColor') }}
        />
      ) : <Icon className="fas fa-comment-plus" />}
    </IconButton>
  )
}

ReactionSelectTrigger.propTypes = {
  reactionType: PropTypes.instanceOf(Map)
}
