import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  show: false,
  loading: false,
  contactsLoading: false,
  distributionListCreateDialogOpen: false
})

export default handleActions({
  [Actions.openAddInfluencerToContactsDialog]: state => state.set('show', true),
  [Actions.closeAddInfluencerToContactsDialog]: state => state.set('show', false),
  [Actions.setDistributionListCreateDialogOpen]: (state, { payload }) => state.set('distributionListCreateDialogOpen', payload),
  [Actions.addInfluencerToContactsStart]: state => state.set('loading', true),
  [Actions.addInfluencerToContactsError]: state => state.set('loading', false),
  [Actions.addInfluencerToContactsSuccess]: (state, { payload }) => (
    state.set('show', false)
      .set('distributionListCreateDialogOpen', payload.mail && payload.mail.length > 0)
      .set('loading', false)
  ),
  [Actions.searchContactsStart]: state => state.set('contactsLoading', true),
  [combineActions(
    Actions.searchContactsSuccess,
    Actions.searchContactsError
  )]: state => state.set('contactsLoading', false),
  [AppActions.resetState]: () => initialState
}, initialState)
