/* global window */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { List, Map, fromJS } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import useI18n from 'hooks/useI18n'
import { useSnackbar } from 'notistack'
import { debounce } from 'throttle-debounce'
import getUrls from 'get-urls'

import { Grid, TextField, Switch, FormControlLabel, Alert, LinearProgress } from '@mui/material'
import { green, red } from '@mui/material/colors'
import EmojiPicker from 'emoji-picker-react'

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'

import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon'

import { Autocomplete, BrowseButton, Input, IconButton, Popover, Button } from 'containers/themed'
import FacebookMediaGallery from 'containers/content_desk/content_edit_dialog/facebook_editor/FacebookMediaGallery'
import ContentPreview from 'containers/content_desk/ContentPreview'
import EditorAiWorkspace from 'containers/content_desk/EditorAiWorkspace'
import PromptHistory from 'containers/content_desk/PromptHistory'
import Unsplash from 'containers/Unsplash'

import { facebookPost as initialFacebookPost } from 'static/facebook_post_config'

import { formatBytes } from 'utils/number'
import { mapToOptions } from 'utils/autocomplete'
import { UploadedMediaFilesSizeLimit } from 'static/constants'

const useStyles = makeStyles()(theme => ({
  postLengthInfo: {
    marginTop: '0.5em',
    textAlign: 'right'
  },
  validPostLength: {
    color: green[500]
  },
  invalidPostLength: {
    fontWeight: 'bold',
    color: red[500]
  },
  uploadButton: {
    width: '205px',
    height: '56px'
  },
  attachmentsContainer: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    '&:last-child': {
      marginBottom: '15px'
    }
  },
  attachmentName: {
    marginLeft: '10px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '30%'
  },
  attachmentMessageInput: {
    width: '60%'
  },
  removeAttachmentButton: {
    marginLeft: '10px'
  },
  previewContainer: {
    display: 'flex',
    borderRadius: '10px',
    backgroundColor: theme.isDark ? '#1E1E1E' : '#F0F0F0',
    padding: '1em',
    width: '100%',
    marginTop: '0.5em'
  },
  previewInnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    margin: 'auto'
  },
  previewLabel: {
    fontStyle: 'italic',
    color: !theme.isDark && '#757575',
    fontSize: '11pt',
    fontWeight: 500
  },
  aiModeSwitch: {
    float: 'left'
  },
  linkLoader: {
    marginTop: 5,
    height: 5
  }
}))

const FacebookEditor = ({
  contentFormData,
  pages,
  mediaFileUploading,
  aiMode,
  unsplashIsVisible,
  facebookAccountConnected,
  uploadedMediaFilesTotalSize,
  linkLoading,
  socialMediaLinkBuildFailed,
  onChange,
  onUpload,
  onSelect,
  toggleEditorAiMode,
  toggleUnsplash,
  navigate,
  buildSocialMediaLink
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  const inputRef = useRef(0)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [isEmojiClick, setIsEmojiClick] = useState(false)
  const [emojiLength, setEmojiLength] = useState(0)
  const [inputRows, setInputRows] = useState(6)
  const [matchedUrl, setMatchedUrl] = useState(null)

  const currentFacebookPost = contentFormData.get('facebookPost') || fromJS(initialFacebookPost)
  const currentPage = contentFormData.getIn(['facebookPost', 'pageId'], '')
  const currentAttachments = currentFacebookPost.get('mediaAttachments')
  const facebookPostType = currentFacebookPost.get('type')
  const currentSelectedMediaFiles = contentFormData.get('ccdMediaFiles')
  const facebookPages = pages.filter(p => p.get('instagram') !== true)

  const handleSelectionChange = () => {
    const selection = window.getSelection().toString()
    onSelect(selection)
  }

  let selectedPage

  if (currentPage) {
    selectedPage = facebookPages.filter(p => p.get('instagram') !== true).find(o => o.get('id') === currentPage)

    if (selectedPage) {
      selectedPage = { id: selectedPage.get('id'), label: selectedPage.get('name') }
    }
  }

  useEffect(() => {
    if (currentAttachments.size === 0) {
      onChange({ key: 'facebookPost', value: currentFacebookPost.set('type', 'text') })
    }
  }, [currentAttachments])

  useEffect(() => {
    if (inputRef.current && !isEmojiClick) {
      setCursorPosition(inputRef.current.selectionStart)
    }

    if (inputRef.current && isEmojiClick) {
      setCursorPosition(current => current + emojiLength)
    }

    setIsEmojiClick(false)
    setEmojiLength(0)
  }, [currentFacebookPost])

  useEffect(() => {
    if (facebookPostType !== 'text' || !matchedUrl) {
      return () => { }
    }

    const timer = setTimeout(() => {
      buildSocialMediaLink({ url: matchedUrl, socialMedia: 'facebook' })
      setMatchedUrl(null)
    }, 1000)

    return () => {
      clearTimeout(timer)
    }
  }, [matchedUrl])

  useEffect(() => {
    if (socialMediaLinkBuildFailed) {
      enqueueSnackbar(
        `${i18n.get('error_building_social_media_link')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [socialMediaLinkBuildFailed])

  const handleChange = (e, index) => {
    const { name, value } = e.target

    if (name === 'message') {
      if (facebookPostType === 'text' && currentFacebookPost.get('link').length === 0) {
        const urls = Array.from(getUrls(value, { forceHttps: true, stripWWW: false }))
        const url = urls.length > 0 ? urls[0] : null

        if (url) {
          setMatchedUrl(url)
        }
      }

      onChange({ key: 'plainText', value })
      onChange({ key: 'facebookPost', value: currentFacebookPost.set(name, value) })
    }

    if (name === 'attachmentMessage') {
      onChange({
        key: 'facebookPost',
        value: currentFacebookPost.setIn(['mediaAttachments', index],
          currentFacebookPost.getIn(['mediaAttachments', index]).set('message', value))
      })
    }
  }

  const handleRemove = index => {
    const updatedAttachments = currentAttachments.filter((_, i) => i !== index)
    const updatedSelectedMediaFiles = currentSelectedMediaFiles.filter((_, i) => i !== index)

    onChange({ key: 'ccdMediaFiles', value: updatedSelectedMediaFiles })
    onChange({ key: 'facebookPost', value: currentFacebookPost.set('mediaAttachments', updatedAttachments) })
  }

  const handlePageChange = option => {
    const pageId = facebookPages.find(o => o.get('id') === option.value).get('id')
    const accessToken = facebookPages.find(o => o.get('id') === option.value).get('accessToken')

    const newFacebookPost = currentFacebookPost
      .set('pageId', pageId)
      .set('accessToken', accessToken)

    onChange({ key: 'facebookPost', value: newFacebookPost })
  }

  const handleInputClick = () => {
    if (inputRef.current) {
      setCursorPosition(inputRef.current.selectionStart)
      setInputRows(12)
    }
  }

  const handleBlur = () => {
    setInputRows(6)
  }

  const handleEmojiClick = (emoji, _event) => {
    onChange({
      key: 'facebookPost',
      value: currentFacebookPost.set('message', `${currentFacebookPost.get('message')
        .slice(0, cursorPosition) + emoji.emoji + currentFacebookPost.get('message')
        .slice(cursorPosition)}`)
    })
    setIsEmojiClick(true)
    setEmojiLength(emoji.emoji.length)
  }

  const onMediaUpload = debounce(50, file => {
    const videoMaxSize = 1073741824 // max video file size 1GB
    const imageMaxSize = 10485760 // max image file size 10MB
    let doUpload = true

    if (!file) {
      doUpload = false
    }

    if (file && ((file.size + uploadedMediaFilesTotalSize) > UploadedMediaFilesSizeLimit)) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('exceeding_upload_storage_size_warning')}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if ((file.type.substr(0, 5) === 'video') && file.size > videoMaxSize) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('media_file_too_large', { size: formatBytes(videoMaxSize) })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if ((file.type.substr(0, 5) === 'image') && file.size > imageMaxSize) {
      doUpload = false

      enqueueSnackbar(
        `${i18n.get('media_file_too_large', { size: formatBytes(imageMaxSize) })}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    if (doUpload) {
      onUpload({ file })
    }
  }) // BrowseButton calls onChange twice, so debounce

  const renderAttachments = () => currentAttachments.map((a, i) => (
    <div
      key={i}
      className={classes.attachmentsContainer}
    >
      <div className={classes.attachmentMessageInput}>
        <TextField
          placeholder={facebookPostType === 'image' ? 'Image message' : 'Video title'}
          name="attachmentMessage"
          value={a.get('message') || ''}
          size="small"
          fullWidth
          onChange={e => handleChange(e, i)}
        />
      </div>
      <span className={classes.attachmentName}>
        {a.get('name')}
      </span>
      <IconButton
        className={classes.removeAttachmentButton}
        onClick={() => handleRemove(i)}
        title={i18n.get('remove')}
      >
        <CancelIcon
          style={{ color: red[500] }}
          fontSize="small"
        />
      </IconButton>
    </div>
  ))

  const renderWarning = () => {
    const hasRequiredPermissions = facebookAccountConnected || pages.size > 0

    if (hasRequiredPermissions) {
      return null
    }

    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Alert severity="warning">
          {i18n.get('linked_in_facebook_permission_warning')}
          <Button
            sx={{ marginLeft: '1em' }}
            onClick={() => navigate('/app/administration/external_accounts')}
            color="primary"
            variant="outlined"
          >{i18n.get('external_accounts')}
          </Button>
        </Alert>
      </Grid>
    )
  }

  const renderMediaGallery = () => (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
    >
      {unsplashIsVisible ? <Unsplash /> : <FacebookMediaGallery contentType={facebookPostType} />}
    </Grid>
  )

  const maxLength = 63206
  const postTextLength = currentFacebookPost.get('message').length
  let postLengthClasses = `${classes.postLengthInfo} ${classes.validPostLength}`

  if (postTextLength > maxLength) {
    postLengthClasses = `${classes.postLengthInfo} ${classes.invalidPostLength}`
  }

  return (
    <Grid
      container
      spacing={2}
    >
      {renderWarning()}
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Input
            required
            type="text"
            rows={inputRows}
            multiline
            inputRef={inputRef}
            value={currentFacebookPost.get('message')}
            maxLength={maxLength}
            name="message"
            onChange={handleChange}
            onClick={handleInputClick}
            onBlur={handleBlur}
            onSelect={handleSelectionChange}
          />
          <div className={classes.linkLoader}>
            {linkLoading ? (
              <LinearProgress
                color="primary"
                sx={{ borderRadius: 5 }}
              />
            ) : null}
          </div>
          <FormControlLabel
            className={classes.aiModeSwitch}
            control={(
              <Switch
                checked={aiMode}
                onChange={() => toggleEditorAiMode()}
                color="primary"
              />
            )}
            label={i18n.get('ai_suggestions')}
          />
          <div
            className={postLengthClasses}
          >
            <Popover
              handle={(
                <IconButton>
                  <InsertEmoticonIcon />
                </IconButton>
              )}
            >
              <EmojiPicker
                emojiStyle="facebook"
                emojiVersion="12.0"
                onEmojiClick={handleEmojiClick}
              />
            </Popover>
            {postTextLength}/{maxLength}
          </div>
        </Grid>
        {aiMode && (
          <Grid item>
            <EditorAiWorkspace
              contentType="facebook"
            />
          </Grid>
        )}
        {!aiMode && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
          >
            {renderAttachments()}
          </Grid>
        )}
        {!aiMode && (
          <>
            <div className={classes.previewLabel}>
              {`${i18n.get('preview')}:`}
            </div>
            <div className={classes.previewContainer}>
              <div className={classes.previewInnerContainer}>
                <ContentPreview
                  content={contentFormData}
                  onChange={onChange}
                />
              </div>
            </div>
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
      >
        {aiMode && (
          <Grid
            container
          >
            <PromptHistory />
          </Grid>
        )}
        {!aiMode && (
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <Autocomplete
                required
                onChange={option => handlePageChange(option)}
                options={mapToOptions(facebookPages)}
                placeholder=""
                value={selectedPage}
                label={i18n.get('choose_facebook_page')}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
            >
              <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <BrowseButton
                  className={classes.uploadButton}
                  accept={facebookPostType === 'text' ? 'image/*, video/*' : `${facebookPostType}/*`}
                  color="primary"
                  variant="outlined"
                  size="large"
                  loading={mediaFileUploading}
                  onChange={e => onMediaUpload(e.target.files[0])}
                >
                  <ImageOutlinedIcon />&nbsp;{i18n.get('upload_media')}
                </BrowseButton>
                <Button
                  className={classes.uploadButton}
                  startIcon={unsplashIsVisible ? <PhotoLibraryIcon /> : <ImageSearchIcon />}
                  color="primary"
                  variant="outlined"
                  onClick={toggleUnsplash}
                >
                  {`${i18n.get('image')} ${i18n.get(unsplashIsVisible ? 'library' : 'search')}`}
                </Button>
              </div>
            </Grid>
            {currentFacebookPost.get('link').length !== 0 ? null : renderMediaGallery()}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

FacebookEditor.propTypes = {
  contentFormData: PropTypes.instanceOf(Map).isRequired,
  pages: PropTypes.instanceOf(List).isRequired,
  mediaFileUploading: PropTypes.bool.isRequired,
  aiMode: PropTypes.bool.isRequired,
  unsplashIsVisible: PropTypes.bool.isRequired,
  facebookAccountConnected: PropTypes.bool.isRequired,
  uploadedMediaFilesTotalSize: PropTypes.number.isRequired,
  linkLoading: PropTypes.bool.isRequired,
  socialMediaLinkBuildFailed: PropTypes.bool.isRequired,

  onChange: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleEditorAiMode: PropTypes.func.isRequired,
  toggleUnsplash: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  buildSocialMediaLink: PropTypes.func.isRequired
}

export default FacebookEditor
