import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/notifications'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  isDrawerOpen: false,
  readRequestRunning: false,
  fetchRequestRunning: false,
  selectedIds: null
})

export default handleActions({
  [Actions.openDrawer]: state => state.set('isDrawerOpen', true),
  [Actions.closeDrawer]: state => state.set('isDrawerOpen', false),
  [Actions.markAsReadStart]: (state, { payload }) => state
    .set('readRequestRunning', true)
    .set('selectedIds', payload),
  [combineActions(
    Actions.markAsReadSuccess,
    Actions.markAsReadError
  )]: state => state
    .set('readRequestRunning', false)
    .set('selectedIds', null),
  [Actions.fetchNotificationsStart]: state => state.set('fetchRequestRunning', true),
  [combineActions(
    Actions.fetchNotificationsSuccess,
    Actions.fetchNotificationsError
  )]: state => state.set('fetchRequestRunning', false),

  [AppActions.resetState]: () => initialState
}, initialState)
