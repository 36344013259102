import { connect } from 'react-redux'

import ContentsCampaignsShowAll from 'components/content_desk_new/contents_campaigns_show_all'
import {
  openCampaignForm,
  openNewContentDialog,
  refreshContentsStatusStart,
  searchCampaignsStart,
  searchContentsStart,
  stopRefreshContentsStatus
} from 'actions/content_desk'
import {
  getCDeskCalendarCampaigns,
  getCDeskCalendarContents,
  getCDeskCampaigns,
  getCDeskContentIsLoading,
  getCDeskContents,
  getCDeskContentsCampaignsTabView
} from 'selectors'

const mapStateToProps = state => ({
  contents: getCDeskContents(state),
  campaigns: getCDeskCampaigns(state),
  calendarContents: getCDeskCalendarContents(state),
  calendarCampaigns: getCDeskCalendarCampaigns(state),
  isContentLoading: getCDeskContentIsLoading(state),
  view: getCDeskContentsCampaignsTabView(state)
})

export default connect(
  mapStateToProps,
  {
    onNewCampaignClick: openCampaignForm,
    onNewContentClick: openNewContentDialog,
    refreshContentsStatusStart,
    searchCampaigns: searchCampaignsStart,
    searchContents: searchContentsStart,
    stopRefreshContentsStatus
  }
)(ContentsCampaignsShowAll)
