import { connect } from 'react-redux'

import { NewsPageModules } from 'static/constants'

import SavedSearches from 'components/saved_searches'

import { fetchSavedSearchesTimelinesStart, setViewType, setTab } from 'actions/saved_searches'
import {
  getViewConfigModuleName,
  getSavedSearchViewType,
  getSavedSearchesTab,
  getSavedSearches
} from 'selectors'

const mapStateToProps = state => ({
  hasSavedSearches: !getSavedSearches(state).isEmpty(),
  showRestrictionHint: getViewConfigModuleName(state) === NewsPageModules.NEWS,
  viewType: getSavedSearchViewType(state),
  tab: getSavedSearchesTab(state)
})

export default connect(
  mapStateToProps,
  {
    onMount: fetchSavedSearchesTimelinesStart,
    onViewChange: setViewType,
    onTabChange: setTab
  }
)(SavedSearches)
