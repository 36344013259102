
import { connect } from 'react-redux'

import OtsPressRelease from 'components/content_desk_new/contents/content_view/statistics_data/ots_press_release'

import { getCDeskStatisticsRequestRunning } from 'selectors'

const mapStateToProps = (state, { data }) => ({
  data,
  requestRunning: getCDeskStatisticsRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(OtsPressRelease)
