import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  topics: null,
  countries: null,
  states: null,
  mediaTypes: null,
  languages: null,
  suggest: null
})

export default handleActions({
  [Actions.setStatics]: (state, { payload }) => {
    const { topics, countries, states, mediaTypes, languages } = payload

    return state
      .set('topics', fromJS(topics))
      .set('countries', fromJS(countries))
      .set('states', fromJS(states))
      .set('mediaTypes', fromJS(mediaTypes))
      .set('languages', fromJS(languages))
  },
  [Actions.setSuggestions]: (state, { payload }) => state.set('suggest', fromJS(payload)),
  [Actions.clearModuleData]: state => state.set('suggest', null),
  [AppActions.resetState]: () => initialState
}, initialState)
