import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/influencers'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  influencer: {
    location: {}
  },
  groupedIdentities: {},
  channels: [],
  contacts: {
    links: [],
    emails: [],
    phones: [],
    faxes: [],
    xings: [],
    linkedins: [],
    networks: []
  },
  totalReach: 0,
  reachChart: {
    firstLevel: 'reach',
    type: 'pie',
    data: [],
    opts: {
      forcedTheme: 'nrx',
      innerRadius: 60,
      showPercent: true
    }
  }
})

export default handleActions({
  [Actions.fetchInfluencerRequestSuccess]: (state, { payload }) => {
    const data = state.merge(fromJS(payload))

    return state.merge(data.delete('reach')).setIn(['reachChart', 'data'], data.get('reach'))
  },

  [Actions.highlightReachChart]: (state, { payload: channelId }) => {
    const chartData = state.getIn(['reachChart', 'data']).map(r => r.update('fill', () => {
      if (channelId === null || r.get('channelId') === channelId) {
        return r.get('color')
      }

      return '#e0e0e0'
    }))

    return state.setIn(['reachChart', 'data'], chartData)
  },

  [AppActions.resetState]: () => initialState
}, initialState)
