/* eslint-disable */
/* global DOMParser */
import { $getRoot, $createTextNode, $createRangeSelection, $setSelection, $isRangeSelection, $getCharacterOffsets } from 'lexical'
import { $insertGeneratedNodes } from '@lexical/clipboard'
import { $isAtNodeEnd } from '@lexical/selection'
import { $generateNodesFromDOM, $generateHtmlFromNodes } from '@lexical/html'

export const clearAndInsertNodes = (editor, nodes, root, isUserInput = true) => {
    root.clear()
    $insertGeneratedNodes(
        editor,
        nodes,
        $createRangeSelection()
    )

    if (isUserInput) {
        $getRoot().selectStart()
    }
    else {
        $setSelection(null)
    }
}

export const setFloatingElemPosition = (
    targetRect,
    floatingElem,
    anchorElem,
    verticalGap = 10,
    horizontalOffset = 5
) => {
    const scrollerElem = anchorElem.parentElement

    if (targetRect === null || !scrollerElem) {
        floatingElem.style.opacity = '0'
        floatingElem.style.transform = 'translate(-10000px, -10000px)'

        return
    }

    const floatingElemRect = floatingElem.getBoundingClientRect()
    const anchorElementRect = anchorElem.getBoundingClientRect()
    const editorScrollerRect = scrollerElem.getBoundingClientRect()

    let top = targetRect.top - floatingElemRect.height - verticalGap
    let left = targetRect.left - horizontalOffset

    if (top < editorScrollerRect.top) {
        top += floatingElemRect.height + targetRect.height + verticalGap * 2
    }

    if (left + floatingElemRect.width > editorScrollerRect.right) {
        left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset
    }

    top -= anchorElementRect.top
    left -= anchorElementRect.left

    floatingElem.style.opacity = '1'
    floatingElem.style.transform = `translate(${left}px, ${top}px)`
}

export const getDOMRangeRect = (
    nativeSelection,
    rootElement
) => {
    const domRange = nativeSelection.getRangeAt(0)

    let rect

    if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement
        while (inner.firstElementChild != null) {
            inner = inner.firstElementChild
        }
        rect = inner.getBoundingClientRect()
    } else {
        rect = domRange.getBoundingClientRect()
    }

    return rect
}

export const getSelectedNode = (
    selection
) => {
    const { anchor } = selection
    const { focus } = selection
    const anchorNode = selection.anchor.getNode()
    const focusNode = selection.focus.getNode()

    if (anchorNode === focusNode) {
        return anchorNode
    }

    const isBackward = selection.isBackward()

    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode
    }

    return $isAtNodeEnd(anchor) ? anchorNode : focusNode
}

export const removeRootParagraph = (html) => {
    const element = document.createElement('export');
    element.innerHTML = html

    if (element.childElementCount === 1) {
        const child = element.firstChild

        if (child.nodeName.toUpperCase() === 'P') {
            return child.innerHTML
        }
    }

    return html
}

export const removeEmptyRootParagraph = (html) => {
    const element = document.createElement('export');
    element.innerHTML = html

    if (element.childElementCount === 1) {
        const child = element.firstChild

        if (child.nodeName.toUpperCase() === 'P' && child.innerHTML === '') {
            return ''
        }
    }

    return html
}

export const replaceHTMLEntities = html => html
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, `'`)
    .replace(/&cent;/g, '¢')
    .replace(/&pound;/g, '£')
    .replace(/&yen;/g, '¥')
    .replace(/&euro;/g, '€')
    .replace(/&copy;/g, '©')
    .replace(/&reg;/g, '®')

export const encodeHTMLEntities = html => html.replace(/[\u00A0-\u9999<>\&]/g, i => `&#${i.charCodeAt(0)};`)

export const decodeHTMLEntities = html => {
    return (html + "").replace(/&#\d+;/gm, function (s) {
        return String.fromCharCode(s.match(/\d+/gm)[0])
    })
}

export const replaceHTMLNewLineWIthLineBreak = html => html.replace(/\n/gm, '<br/>')

export const joinClasses = (...args) => args.filter(Boolean).join(' ')

export const sliceSelectedTextNodeContent = (selection, textNode) => {
    if (
        textNode.isSelected()
        && !textNode.isSegmented()
        && !textNode.isToken()
        && ($isRangeSelection(selection))
    ) {
        const anchorNode = selection.anchor.getNode()
        const focusNode = selection.focus.getNode()
        const isAnchor = textNode.is(anchorNode)
        const isFocus = textNode.is(focusNode)

        if (isAnchor || isFocus) {
            const isBackward = selection.isBackward()
            const [anchorOffset, focusOffset] = $getCharacterOffsets(selection);
            const isSame = anchorNode.is(focusNode)
            const isFirst = textNode.is(isBackward ? focusNode : anchorNode)
            const isLast = textNode.is(isBackward ? anchorNode : focusNode)
            let startOffset = 0
            let endOffset

            if (isSame) {
                startOffset = anchorOffset > focusOffset ? focusOffset : anchorOffset
                endOffset = anchorOffset > focusOffset ? anchorOffset : focusOffset
            } else if (isFirst) {
                const offset = isBackward ? focusOffset : anchorOffset
                startOffset = offset
                endOffset = undefined
            } else if (isLast) {
                const offset = isBackward ? anchorOffset : focusOffset
                startOffset = 0
                endOffset = offset
            }

            return textNode.getTextContent().slice(startOffset, endOffset)
        }
    }

    return ''
}

export const buildNodesFromHTML = (editor, htmlContent) => {
    const parser = new DOMParser()
    const dom = parser.parseFromString(htmlContent, 'text/html')

    return $generateNodesFromDOM(editor, dom)
}

export const importNodesAsPlainText = (editor, state, isUserInput) => {
    const root = $getRoot()
    const textNode = $createTextNode(state)
    clearAndInsertNodes(editor, [textNode], root, isUserInput)
}

export const importNodesAsHTML = (editor, state, isUserInput) => {
    const root = $getRoot()
    const parser = new DOMParser()
    const dom = parser.parseFromString(replaceHTMLNewLineWIthLineBreak(state), 'text/html')
    const nodes = $generateNodesFromDOM(editor, dom)
    clearAndInsertNodes(editor, nodes, root, isUserInput)
}

let sleepSetTimeout
function sleep(ms) {
    clearInterval(sleepSetTimeout)
    return new Promise(resolve => sleepSetTimeout = setTimeout(resolve, ms))
}

const updateValue = editorInstance => {
    let newValue
    editorInstance.update(() => {
        newValue = $generateHtmlFromNodes(editorInstance, null)
    })

    return newValue
}

export const reimportLexicalEditorNodes = async (editor, value) => {
    let updatedValue = value

    editor.update(() => {
        importNodesAsHTML(editor, value, false)
    })

    let msDelay = 1
    /* eslint-disable-next-line no-underscore-dangle */
    let dirtyElements = editor._dirtyElements

    // After rebuilding the editor content there are node transforms that need to happen.
    // Once there are no more dirty elements (node transforms are done) then we return the final editor state.
    // If dirty elements are not cleaned up within a maximum of 15ms we return the last state.
    for (let i = 0; i < 3 && dirtyElements.size > 0; i++) {
        await sleep(msDelay)
        msDelay = 7
        dirtyElements = editor._dirtyElements
    }

    if (dirtyElements.size === 0) {
        updatedValue = updateValue(editor)
    }

    return updatedValue
}

export const removeInvalidTagsForOts = (text, allowedTags) => {
    // Create a new DOMParser instance
    const parser = new DOMParser()
    // Parse the input text as HTML
    const doc = parser.parseFromString(text, 'text/html')

    // Function to recursively remove invalid tags
    const removeInvalidTags = node => {
        // Get all child nodes
        const children = Array.from(node.childNodes)
        children.forEach(child => {
            if (child.nodeType === Node.ELEMENT_NODE) {
                if (!allowedTags.includes(child.nodeName)) {
                    // Replace the invalid element with its children
                    while (child.firstChild) {
                        node.insertBefore(child.firstChild, child)
                    }
                    node.removeChild(child)
                } else {
                    // Recursively process the child nodes
                    removeInvalidTags(child)
                }
            }
        })
    }

    // Start the removal process from the body of the parsed document
    removeInvalidTags(doc.body)

    // Return the cleaned HTML as a string
    return doc.body.innerHTML
};