/* global window */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Paper,
  Typography
} from '@mui/material'

import useI18n from 'hooks/useI18n'
import useDevice from 'hooks/useDevice'

import { grey } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'

/* Caution: these components are still in the old directory */
import CalendarDialog from 'containers/content_desk_new/CalendarDialog'
import OldContentEditDialog from 'containers/content_desk/ContentEditDialog'
import DeleteDialog from 'containers/content_desk/DeleteDialog'

import NrxProgressBackdrop from 'components/nrx_progress_backdrop/NrxProgressBackdrop'

import AppBar from 'containers/content_desk_new/AppBar'
import ActiveFilterList from 'containers/content_desk_new/ActiveFilterList'
import CampaignForm from 'containers/content_desk_new/campaigns/CampaignForm'
import Campaigns from 'containers/content_desk_new/campaigns/Campaigns'
import MobileCampaigns from 'containers/content_desk_new/campaigns/mobile_campaigns/MobileCampaigns'
import CampaignsPagination from 'containers/content_desk_new/contents_campaigns_show_all/CampaignsPagination'
import ContentEditDialog from 'containers/content_desk_new/contents/content_edit_dialog/ContentEditDialog'
import ContentsEditBlockDialog from 'containers/content_desk_new/contents/ContentsEditBlockDialog'
import ContactDetailsDialog from 'containers/contact_management/ContactDetailsDialog'
import ContactEditDialog from 'containers/contact_management/ContactEditDialog'
import ContentNewDialog from 'containers/content_desk_new/ContentNewDialog'
import ContentNotesDialog from 'containers/content_desk_new/contents/ContentNotesDialog'
import Contents from 'containers/content_desk_new/contents/Contents'
import MobileContents from 'containers/content_desk_new/contents/mobile_contents/MobileContents'
import ContentsCampaignsTabs from 'containers/content_desk_new/ContentsCampaignsTabs'
import CampaignsFilters from 'containers/content_desk_new/campaigns/CampaignsFilters'
import ContentsFilters from 'containers/content_desk_new/contents/ContentsFilters'
import ContentsPagination from 'containers/content_desk_new/contents_campaigns_show_all/ContentsPagination'
import ContentView from 'containers/content_desk_new/contents/content_view/ContentView'
import OtsSuccessDialog from 'containers/content_desk_new/OtsSuccessDialog'
import OtsErrorDialog from 'containers/content_desk_new/OtsErrorDialog'

import { Button } from 'containers/themed'

const useStyles = makeStyles()({
  container: {
    backgroundColor: grey[100],
    padding: '30px',
    minHeight: '100vh'
  },
  activeFilterListContainer: {
    marginBottom: 20
  },
  contentsAndCampaignsContainer: {
    borderRadius: 20,
    minHeight: 400
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20
  },
  newButtonsContainer: {
    display: 'flex',
    gap: 10
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 0 0'
  },
  circularProgress: {
    textAlign: 'center'
  }
})

export default function ContentsCampaignsShowAll({
  contents,
  campaigns,
  calendarContents,
  calendarCampaigns,
  isContentLoading,
  view,
  onNewCampaignClick,
  onNewContentClick,
  refreshContentsStatusStart,
  searchCampaigns,
  searchContents,
  stopRefreshContentsStatus
}) {
  const { classes } = useStyles()
  const i18n = useI18n()
  const device = useDevice()

  useEffect(() => {
    searchCampaigns()
    searchContents()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    refreshContentsStatusStart()

    return () => {
      stopRefreshContentsStatus()
    }
  }, [contents, campaigns, calendarContents, calendarCampaigns])

  const renderNewButtons = () => {
    if (device.get('mobile')) {
      return null
    }

    return (
      <Box className={classes.newButtonsContainer}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => onNewCampaignClick()}
        >
          {i18n.get('create_campaign')}
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => onNewContentClick()}
        >
          {i18n.get('create_content')}
        </Button>
      </Box>
    )
  }

  const renderFilterMenu = () => {
    if (device.get('mobile')) {
      return null
    }

    if (view === 'contents') {
      return <ContentsFilters />
    }

    if (view === 'campaigns') {
      return <CampaignsFilters />
    }

    return null
  }

  const renderResultsTable = () => {
    if (view === 'contents') {
      if (device.get('mobile')) {
        return <MobileContents />
      }

      return <Contents />
    }

    if (view === 'campaigns') {
      if (device.get('mobile')) {
        return <MobileCampaigns />
      }

      return <Campaigns />
    }

    return null
  }

  const renderPagination = () => {
    if (view === 'contents') {
      return <ContentsPagination />
    }

    if (view === 'campaigns') {
      return <CampaignsPagination />
    }

    return null
  }

  return (
    <>
      <NrxProgressBackdrop open={isContentLoading} />
      <AppBar />
      <Box className={classes.container}>
        <Box className={classes.activeFilterListContainer}>
          <ActiveFilterList />
        </Box>
        <Paper className={classes.contentsAndCampaignsContainer}>
          <Box className={classes.titleContainer}>
            <Typography
              variant="h5"
            >
              {i18n.get('contents_and_campaigns')}
            </Typography>
            {renderNewButtons()}
          </Box>
          <Box className={classes.navigationContainer}>
            <ContentsCampaignsTabs />
            {renderFilterMenu()}
          </Box>
          {renderResultsTable()}
          {renderPagination()}
        </Paper>
        <CampaignForm />
        <ContentNewDialog />
        <ContentEditDialog />
        <OldContentEditDialog />
        <ContactDetailsDialog />
        <ContactEditDialog />
        <ContentNotesDialog />
        <CalendarDialog />
        <ContentsEditBlockDialog />
        <ContentView />

        <DeleteDialog entity="campaign" />
        <DeleteDialog entity="content" />
        <OtsSuccessDialog />
        <OtsErrorDialog />
      </Box>
    </>
  )
}

ContentsCampaignsShowAll.propTypes = {
  contents: PropTypes.instanceOf(Map).isRequired,
  campaigns: PropTypes.instanceOf(Map).isRequired,
  calendarContents: PropTypes.instanceOf(Map).isRequired,
  calendarCampaigns: PropTypes.instanceOf(Map).isRequired,
  isContentLoading: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,

  onNewCampaignClick: PropTypes.func.isRequired,
  onNewContentClick: PropTypes.func.isRequired,
  refreshContentsStatusStart: PropTypes.func.isRequired,
  searchCampaigns: PropTypes.func.isRequired,
  searchContents: PropTypes.func.isRequired,
  stopRefreshContentsStatus: PropTypes.func.isRequired
}

