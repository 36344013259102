import { connect } from 'react-redux'

import Unsplash from 'components/unsplash'

import {
  searchUnsplashPhotosStart as startUnsplashSearch,
  fetchRandomUnsplashPhotosStart as fetchRandomPhotos,
  setUnsplashFormData as onChange,
  resetUnsplashPhotos,
  addImageToLibraryStart as addToLibrary
} from 'actions/unsplash'

import {
  getUnsplashRequestRunning,
  getUnsplashPhotos,
  getUnsplashFormData,
  getIsRandomSearch,
  getUnsplashPage,
  getUnsplashDownloadRequestRunning,
  getUnsplashSelectedImageId
} from 'selectors'

const mapStateToProps = state => ({
  requestRunning: getUnsplashRequestRunning(state),
  photosData: getUnsplashPhotos(state),
  formData: getUnsplashFormData(state),
  isRandomSearch: getIsRandomSearch(state),
  loadMoreDisabled: getUnsplashPage(state) >= getUnsplashPhotos(state).get('totalPages'),
  downloadRequestRunning: getUnsplashDownloadRequestRunning(state),
  selectedImageId: getUnsplashSelectedImageId(state)
})

export default connect(
  mapStateToProps,
  {
    startUnsplashSearch,
    fetchRandomPhotos,
    onChange,
    resetUnsplashPhotos,
    addToLibrary
  }
)(Unsplash)
