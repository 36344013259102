import { connect } from 'react-redux'

import ShoppingCartGroupedNewsSublist from 'components/shopping_cart/grouped_news_sublist'

import { getShoppingCartSelectedNewsForSorting } from 'selectors'

import { moveNewsInShoppingCartSublist, resetSortingSelection } from 'actions/shopping_cart'

const mapStateToProps = state => ({
  selectedNews: getShoppingCartSelectedNewsForSorting(state)
})

export default connect(
  mapStateToProps,
  {
    onSortEnd: moveNewsInShoppingCartSublist,
    onReset: resetSortingSelection
  }
)(ShoppingCartGroupedNewsSublist)
