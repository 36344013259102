import { connect } from 'react-redux'

import Editor from 'components/content_desk_new/contents/content_edit_dialog/editor'

import {
  setContentFormData
} from 'actions/content_desk'

import {
  getCDeskEmailEditorLoadingStatus,
  getCDeskContentFormData
} from 'selectors'

const mapStateToProps = state => ({
  emailEditorLoading: getCDeskEmailEditorLoadingStatus(state),
  content: getCDeskContentFormData(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: setContentFormData
  }
)(Editor)
