import { connect } from 'react-redux'

import DistributionListEditDialog from 'components/contact_management/distribution_list_edit_dialog'

import {
  getCmDistributionListEditDialogOpen,
  getCmListData,
  getCmRequestRunning
} from 'selectors'

import {
  closeDistributionListEditDialog,
  removeContactFromListStart,
  updateDistributionListFormData,
  updateDistributionListStart
} from 'actions/contact_management'

const mapStateToProps = state => ({
  open: getCmDistributionListEditDialogOpen(state),
  list: getCmListData(state),
  loading: getCmRequestRunning(state),
  newListName: getCmListData(state).get('name')
})

export default connect(mapStateToProps, {
  onClose: closeDistributionListEditDialog,
  onRemove: removeContactFromListStart,
  onSubmit: updateDistributionListStart,
  onUpdate: updateDistributionListFormData
})(DistributionListEditDialog)
