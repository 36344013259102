import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import { history } from 'store'
import routes from '../routes'

const Routes = () => {
  const routesElement = useRoutes(routes)

  return routesElement
}

const Root = ({ store }) => (
  <Provider store={store}>
    <>
      <Router
        history={history}
      >
        <Routes />
      </Router>
    </>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

export default Root
