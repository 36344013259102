import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import useI18n from 'hooks/useI18n'

import {
  RaisedPrimaryButton,
  Button,
  IconButton,
  Input
} from 'containers/themed'

import { validEmail } from 'utils/regex'

const useStyles = makeStyles()(theme => ({
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  input: {
    marginTop: 15
  }
}))

const TestMailDialog = ({
  sender,
  isOpen,
  requestRunning,
  onClose,
  onDispatch
}) => {
  const [recipient, setRecipient] = useState('')

  useEffect(() => {
    if (sender) {
      setRecipient(sender)
    }
  }, [sender])

  const { classes } = useStyles()
  const i18n = useI18n()

  const validateEmail = () => (
    recipient.split(/,\s*|;\s*/).reduce((acc, e) => acc && validEmail(e), true) && recipient.split(/,|;/).length < 6
  )

  const handleDispatch = () => {
    onDispatch({ test: true, recipient })
    onClose()
  }

  const handleChange = value => {
    setRecipient(value)
  }

  return (
    <Dialog
      maxWidth="sm"
      onClose={() => onClose()}
      open={isOpen}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('test_email')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{i18n.get('choose_recipient_test_mail')}</Typography>
        <Input
          type="email"
          label={i18n.get('email_address')}
          error={!validateEmail(recipient)}
          value={recipient}
          onChange={event => handleChange(event.target.value)}
          disabled={requestRunning}
          className={classes.input}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {i18n.get('close')}
        </Button>
        <RaisedPrimaryButton
          onClick={handleDispatch}
          disabled={requestRunning || !validateEmail(recipient)}
        >
          {i18n.get('send_now')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

TestMailDialog.propTypes = {
  sender: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDispatch: PropTypes.func.isRequired
}

export default TestMailDialog
