import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList } from 'immutable'
import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'

import { IconButton, Button, RaisedPrimaryButton } from 'containers/themed'

export default function ContactDeleteDialog({
  open,
  contacts,
  loading,
  onClose,
  onDelete
}) {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleClose = () => onClose()
  const handleDelete = () => onDelete()

  const contactData = contact => (
    <li
      style={{ listStyleType: 'disc' }}
      key={contact.get('id')}
    >{`${contact.get('firstName') || ''} ${contact.get('lastName')} (${contact.get('mail')})`}
    </li>
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('delete_contact')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', paddingLeft: t => t.spacing(4) }}
      >
        {i18n.get('confirm_contact_delete')}
        <ul>
          {contacts.map(contact => contactData(contact))}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={handleClose}
        >{i18n.get('cancel')}
        </Button>
        <RaisedPrimaryButton
          loading={loading}
          onClick={handleDelete}
        >{i18n.get('delete')}
        </RaisedPrimaryButton>
      </DialogActions>
    </Dialog>
  )
}

ContactDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  contacts: PropTypes.instanceOf(ImmutableList).isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
