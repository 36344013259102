import { fromJS, Map } from 'immutable'

import { sort } from 'utils/normalizr'
import { createImmutableSelector } from 'utils/reselect'
import { getNewsById } from './news/data'
import { getViewConfigIndexType } from './news/view_config'

export const getCustomTagsData = createImmutableSelector(
  getViewConfigIndexType,
  state => state.customTags.getIn(['data', 'entities']),
  state => state.customTags.getIn(['data', 'result']),
  (indexType, allEntities, allResults) => {
    const entities = allEntities.filter(
      e => e.get('newsType') === indexType
    )
    const entityIds = entities.map(e => e.get('id'))
    const result = allResults.filter(r => entityIds.get(`${r}`) === r)

    return Map({ result, entities })
  }
)

export const getCustomTags = createImmutableSelector(
  state => getCustomTagsData(state).get('result').toList(),
  state => getCustomTagsData(state).get('entities'),
  sort
)

export const getCustomtagsToShoppingCartLoading = state => state.customTags.getIn(['ui', 'loading'])

export const getCustomTagsAssignCustomTagsToNews = state => state.customTags.get('assignCustomTagsToNews')
export const getCustomTagsAssignCustomTagsToNewsSelectedNews = state => (
  getNewsById(state, getCustomTagsAssignCustomTagsToNews(state).get('selectedNewsId'))
)
export const getShowAssignCustomTagsToNewsDialog = state => getCustomTagsAssignCustomTagsToNews(state).get('show')

export const getCustomTagIds = state => getCustomTagsData(state).get('result')
export const getCustomTagById = (state, id) => getCustomTagsData(state).getIn(['entities', id.toString()])

export const getCustomTagsUi = state => state.customTags.get('ui')
export const getSelectedCustomTag = state => getCustomTagsUi(state).get('selectedCustomTag')
export const getShowCustomTagDialog = state => getCustomTagsUi(state).get('showCustomTagDialog')
export const getLastUsedCustomTagIds = state => getCustomTagsUi(state).get('lastUsedCustomTagIds')
export const getUploadedCustomTagPhoto = state => getCustomTagsUi(state).get('uploadedPhoto')
export const getCustomTagsSortBy = state => getCustomTagsUi(state).get('sortBy')
export const getShowRemoveCustomTagDialog = state => getCustomTagsUi(state).get('showRemoveCustomTagDialog')
export const getShowEmptyCustomTagDialog = state => getCustomTagsUi(state).get('showEmptyCustomTagDialog')
export const getFilteredCustomTagGroupId = state => getCustomTagsUi(state).get('filteredGroupId')
export const getGroupedAssignableCustomTags = state => getCustomTagsUi(state).get('groupedAssignableCustomTags')
export const getCustomTagsViewType = state => getCustomTagsUi(state).get('viewType')
export const getCustomTagsTab = state => getCustomTagsUi(state).get('customTagsTab')

export const getCustomTagsDetailData = state => state.customTags.getIn(['detail', 'data'])
export const getNewsForCustomTag = state => getCustomTagsDetailData(state).get('news')
export const getNewsIdsForCustomTag = state => getCustomTagsDetailData(state).get('news').map(n => n.get('id'))

export const getCustomTagsDetailUi = state => state.customTags.getIn(['detail', 'ui'])
export const getNewsForCustomTagLoading = state => getCustomTagsDetailUi(state).get('newsLoading')
export const getNewsForCustomTagFrom = state => getCustomTagsDetailUi(state).get('from')
export const getLoadingCustomTagIds = state => getCustomTagsUi(state).get('loadingCustomTagIds')

// groups
export const getCustomTagGroupsData = state => state.customTags.getIn(['groups', 'data'])
export const getGroupById = (state, id) => state.customTags.getIn(['groups', 'data']).find(g => g.get('id') === id)

const groupsSelector = state => (
  getCustomTagGroupsData(state).map(
    group => group.update(
      'customTagsCustomTagGroups',
      cgs => cgs
        .map(c => c.set('customTag', getCustomTagById(state, c.get('customTagId'))))
        .filter(c => c.get('customTag'))
        .sortBy(c => c.getIn(['customTag', 'name']).toLowerCase())
    )
  )
)

export const getRssCustomTags = createImmutableSelector(
  getCustomTags,
  customTags => customTags.filter(c => c.get('rssEnabled'))
)

export const getStructuredFormatCustomTags = createImmutableSelector(
  getCustomTags,
  customTags => customTags.filter(c => c.get('rssEnabled') || c.get('xmlEnabled') || c.get('jsonEnabled'))
)

export const getXmlCustomTags = createImmutableSelector(
  getCustomTags,
  customTags => customTags.filter(c => c.get('xmlEnabled'))
)

export const getJsonCustomTags = createImmutableSelector(
  getCustomTags,
  customTags => customTags.filter(c => c.get('jsonEnabled'))
)

export const getRssCustomTagGroup = createImmutableSelector(
  getRssCustomTags,
  customTags => {
    if (customTags.isEmpty()) {
      return null
    }

    return fromJS({
      id: 'RSS',
      name: 'RSS',
      auto: true,
      customTagsCustomTagGroups: customTags.map(customTag => fromJS({
        customTag
      }))
    })
  }
)

export const getXmlCustomTagGroup = createImmutableSelector(
  getXmlCustomTags,
  customTags => {
    if (customTags.isEmpty()) {
      return null
    }

    return fromJS({
      id: 'XML',
      name: 'XML',
      auto: true,
      customTagsCustomTagGroups: customTags.map(customTag => fromJS({
        customTag
      }))
    })
  }
)

export const getJsonCustomTagGroup = createImmutableSelector(
  getJsonCustomTags,
  customTags => {
    if (customTags.isEmpty()) {
      return null
    }

    return fromJS({
      id: 'JSON',
      name: 'JSON',
      auto: true,
      customTagsCustomTagGroups: customTags.map(customTag => fromJS({
        customTag
      }))
    })
  }
)

export const getCustomTagGroups = createImmutableSelector(
  groupsSelector,
  getRssCustomTagGroup,
  getXmlCustomTagGroup,
  getJsonCustomTagGroup,
  (groups, rssGroup, xmlGroup, jsonGroup) => groups
    .sortBy(g => g.get('name').toLowerCase())
    .push(rssGroup, xmlGroup, jsonGroup)
    .filter(g => g)
)

export const getCustomTagGroupsUi = state => state.customTags.getIn(['groups', 'ui'])
export const getCustomTagsShowEditGroupDialog = state => getCustomTagGroupsUi(state).get('showGroupDialog')
export const getCustomTagsShowDeleteGroupDialog = state => getCustomTagGroupsUi(state).get('showDeleteGroupDialog')
export const getSelectedCustomTagGroup = state => getCustomTagGroupsUi(state).get('selectedGroup')
export const getCustomTagGroupSaving = state => getCustomTagGroupsUi(state).get('saving')
export const getCustomTagGroupDeleting = state => getCustomTagGroupsUi(state).get('deleting')
export const getCustomTagGroupsHeaderId = state => getCustomTagGroupsUi(state).get('headerId')

export const getCustomTagsAssignCustomTagsToGroup = state => state.customTags.get('assignCustomTagsToGroup')
export const getCustomTagsAssignCustomTagsToGroupSelectedGroup = state => (
  getGroupById(state, getCustomTagsAssignCustomTagsToGroup(state).get('selectedGroupId'))
)
export const getCustomTagsAssignCustomTagsToGroupSelectedGroupId = state => (
  getCustomTagsAssignCustomTagsToGroup(state).get('selectedGroupId')
)
export const getShowAssignCustomTagsToGroupDialog = state => getCustomTagsAssignCustomTagsToGroup(state).get('show')

export const getFilteredCustomTags = createImmutableSelector(
  getFilteredCustomTagGroupId,
  getCustomTags,
  getCustomTagGroups,
  getRssCustomTags,
  getXmlCustomTags,
  getJsonCustomTags,
  (id, customTags, groups, rssCustomTags, xmlCustomTags, jsonCustomTags) => {
    const group = groups.find(g => g.get('id') === id)

    if (!group) {
      return customTags
    }

    if (group.get('id') === 'RSS') {
      return rssCustomTags
    }

    if (group.get('id') === 'XML') {
      return xmlCustomTags
    }

    if (group.get('id') === 'JSON') {
      return jsonCustomTags
    }

    const ids = group.get('customTagsCustomTagGroups').map(c => c.get('customTagId')).toSet()

    return customTags.filter(c => ids.has(c.get('id')))
  }
)

export const getFilteredCustomTagGroup = createImmutableSelector(
  getFilteredCustomTagGroupId,
  getCustomTagGroups,
  (id, groups) => groups.find(g => g.get('id') === id) || fromJS({})
)
