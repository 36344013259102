import { createAction } from 'redux-actions'

let prefix = 'PUBLICATIONS/'

export const publicationsRequestError = createAction(`${prefix}PUBLICATIONS_REQUEST_ERROR`)
export const publicationsRequestStart = createAction(`${prefix}PUBLICATIONS_REQUEST_START`)
export const publicationsRequestSuccess = createAction(`${prefix}PUBLICATIONS_REQUEST_SUCCESS`)
export const morePublicationsRequestError = createAction(`${prefix}MORE_PUBLICATIONS_REQUEST_ERROR`)
export const morePublicationsRequestStart = createAction(`${prefix}MORE_PUBLICATIONS_REQUEST_START`)
export const morePublicationsRequestSuccess = createAction(`${prefix}MORE_PUBLICATIONS_REQUEST_SUCCESS`)
export const searchForPublication = createAction(`${prefix}SEARCH_FOR_PUBLICATION`)
export const searchForSelectedPublications = createAction(`${prefix}SEARCH_FOR_SELECTED_PUBLICATIONS`)
export const openPublicationForm = createAction(`${prefix}OPEN_PUBLICATION_FORM`)
export const hidePublicationForm = createAction(`${prefix}HIDE_PUBLICATION_FORM`)
export const resetCreatedPublication = createAction(`${prefix}RESET_CREATED_PUBLICATION`)

prefix = `${prefix}SAVE/`

export const createPublicationError = createAction(`${prefix}CREATE_PUBLICATION_ERROR`)
export const createPublicationStart = createAction(`${prefix}CREATE_PUBLICATION_START`)
export const createPublicationSuccess = createAction(`${prefix}CREATE_PUBLICATION_SUCCESS`)

export const setSortBy = createAction(`${prefix}SET_SORT_BY`)

export const selectPublication = createAction(`${prefix}SELECT_PUBLICATION`)
export const resetPublicationSelection = createAction(`${prefix}RESET_PUBLICATION_SELECTION`)
