/* eslint-disable max-len, react/no-unescaped-entities */
import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'
import DownloadIcon from '@mui/icons-material/Download'

import { Dialog, Button } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  title: {
    fontSize: '1.5rem',
    color: !theme.isDark && '#404040'
  },
  button: {
    marginTop: '2em'
  }
}))

const ImportHelpDialog = ({ open, onClose, languageId, onDownloadTemplate }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const handleOnDownloadTemplate = () => {
    const contacts = [
      {
        first_name: 'Charles',
        last_name: 'Wilson',
        mail: 'charlesIwilson@rhyta.com',
        title: 'Mr.',
        job_title: 'Journalist',
        dossier: '',
        picture: 'https://static.pressrelations.de/pics/groot/ccd/dummy_profile_picture.jpg',
        address: '66 Thornton St Huntsham',
        phone: '079 1023 7455',
        fax: '',
        external_publication: 'The Herald',
        country: 'Australia',
        state: null,
        linkedin_url: '',
        twitter_url: '',
        facebook_url: '',
        threads_url: '',
        tiktok_url: '',
        instagram_url: '',
        youtube_url: '',
        other_url: '',
        contacts_languages: 'English, German',
        preferred_language_id: 'English'
      }
    ]

    onDownloadTemplate({ contacts, sheetName: 'Contacts', isTemplate: true })
  }

  const renderHelpText = () => {
    if (languageId === 1) {
      return (
        <div className={classes.helpText}>
          <div>
            Mit unserem Import-Tool können Sie Kontakte in großen Mengen hinzufügen, indem Sie sie in eine XLSX- oder CSV-Datei hochladen.
          </div>
          <h3>Für den Import vorbereiten</h3>
          <div>
            1. Öffnen Sie ein leeres Arbeitsblatt in Ihrem bevorzugten Tabellenkalkulationsprogramm (wie
            Microsoft Excel oder Google Sheets).
          </div>
          <div>
            2. Erstellen und beschriften Sie die ersten beiden Spalten mit <b>Mail</b> und <b>Last Name</b>. Dies sind die einzigen erforderlichen Felder.
          </div>
          <div>
            3. Wenn Sie weitere Informationen hinzufügen möchten, müssen die nächsten Spalten in der folgenden Reihenfolge angeordnet werden:
            <ul>
              <li style={{ listStyleType: 'disc' }}>Mail</li>
              <li style={{ listStyleType: 'disc' }}>Last Name</li>
              <li style={{ listStyleType: 'disc' }}>First Name</li>
              <li style={{ listStyleType: 'disc' }}>Title</li>
              <li style={{ listStyleType: 'disc' }}>Job Title</li>
              <li style={{ listStyleType: 'disc' }}>Publication</li>
              <li style={{ listStyleType: 'disc' }}>Company</li>
              <li style={{ listStyleType: 'disc' }}>Dossier</li>
              <li style={{ listStyleType: 'disc' }}>Notes</li>
              <li style={{ listStyleType: 'disc' }}>Picture (vollständige source-URL für ein gehostetes Bild)</li>
              <li style={{ listStyleType: 'disc' }}>Address</li>
              <li style={{ listStyleType: 'disc' }}>Phone</li>
              <li style={{ listStyleType: 'disc' }}>Fax</li>
              <li style={{ listStyleType: 'disc' }}>Country</li>
              <li style={{ listStyleType: 'disc' }}>State</li>
              <li style={{ listStyleType: 'disc' }}>Languages</li>
              <li style={{ listStyleType: 'disc' }}>Preferred Language</li>
              <li style={{ listStyleType: 'disc' }}>Website</li>
              <li style={{ listStyleType: 'disc' }}>LinkedIn URL</li>
              <li style={{ listStyleType: 'disc' }}>X / Twitter URL</li>
              <li style={{ listStyleType: 'disc' }}>Facebook URL</li>
              <li style={{ listStyleType: 'disc' }}>Threads URL</li>
              <li style={{ listStyleType: 'disc' }}>TikTok URL</li>
              <li style={{ listStyleType: 'disc' }}>Instagram URL</li>
              <li style={{ listStyleType: 'disc' }}>YouTube URL</li>
              <li style={{ listStyleType: 'disc' }}>Other URL</li>
            </ul>
          </div>
          <div>4. Fügen Sie Ihre Kontaktinformationen in die entsprechenden Felder ein.</div>
          <div>5. Speichern Sie die Datei im XLSX- oder CSV-Format. Sie können jetzt loslegen!</div>
          <h3>Verwenden Sie unsere Vorlagendatei</h3>
          <div>Klicken Sie auf die Schaltfläche unten, um eine XLSX-Beispieldatei herunterzuladen. Natürlich können Sie sie auch in CSV konvertieren.</div>
          <div>Stellen Sie sicher, dass Sie die Musterinformationen vor dem Import entfernen.</div>
        </div>
      )
    }

    if (languageId === 4) {
      return (
        <div className={classes.helpText}>
          <div>
            Notre outil d'importation vous permet d'ajouter des contacts en masse en les téléchargeant dans un fichier XLSX ou CSV.
          </div>
          <h3>Préparer l'importation</h3>
          <div>
            1. Ouvrez une feuille de calcul vierge dans votre programme de feuille de calcul préféré (comme Microsoft Excel ou Google Sheets).
          </div>
          <div>
            2. Créez et libellez les deux premières colonnes comme <b>Mail</b> et <b>Last Name</b>. Ce sont les seuls champs obligatoires.
          </div>
          <div>
            3. Si vous souhaitez ajouter des informations supplémentaires, les colonnes suivantes doivent être dans l'ordre suivant:
            <ul>
              <li style={{ listStyleType: 'disc' }}>Mail</li>
              <li style={{ listStyleType: 'disc' }}>Last Name</li>
              <li style={{ listStyleType: 'disc' }}>First Name</li>
              <li style={{ listStyleType: 'disc' }}>Title</li>
              <li style={{ listStyleType: 'disc' }}>Job Title</li>
              <li style={{ listStyleType: 'disc' }}>Publication</li>
              <li style={{ listStyleType: 'disc' }}>Company</li>
              <li style={{ listStyleType: 'disc' }}>Dossier</li>
              <li style={{ listStyleType: 'disc' }}>Notes</li>
              <li style={{ listStyleType: 'disc' }}>Picture (URL source complète pour une image hébergée)</li>
              <li style={{ listStyleType: 'disc' }}>Address</li>
              <li style={{ listStyleType: 'disc' }}>Phone</li>
              <li style={{ listStyleType: 'disc' }}>Fax</li>
              <li style={{ listStyleType: 'disc' }}>Country</li>
              <li style={{ listStyleType: 'disc' }}>State</li>
              <li style={{ listStyleType: 'disc' }}>Languages</li>
              <li style={{ listStyleType: 'disc' }}>Preferred Language</li>
              <li style={{ listStyleType: 'disc' }}>Website</li>
              <li style={{ listStyleType: 'disc' }}>LinkedIn URL</li>
              <li style={{ listStyleType: 'disc' }}>X / Twitter URL</li>
              <li style={{ listStyleType: 'disc' }}>Facebook URL</li>
              <li style={{ listStyleType: 'disc' }}>Threads URL</li>
              <li style={{ listStyleType: 'disc' }}>TikTok URL</li>
              <li style={{ listStyleType: 'disc' }}>Instagram URL</li>
              <li style={{ listStyleType: 'disc' }}>YouTube URL</li>
              <li style={{ listStyleType: 'disc' }}>Other URL</li>
            </ul>
          </div>
          <div>4. Collez vos coordonnées dans les champs appropriés.</div>
          <div>5. Enregistrez le fichier au format XLSX ou CSV. Vous êtes prêt à partir!</div>
          <h3>Utilisez notre fichier modèle</h3>
          <div>Cliquez sur le bouton ci-dessous pour télécharger un exemple de fichier XLSX. Bien entendu, vous pouvez également le convertir en CSV.</div>
          <div>Veillez à supprimer les informations relatives à l'échantillon avant de procéder à l'importation.</div>
        </div>
      )
    }

    if (languageId === 5) {
      return (
        <div className={classes.helpText}>
          <div>
            Наш инструмент импорта позволяет добавлять контакты в массовом порядке, загружая их в XLSX или CSV файл.
          </div>
          <h3>Подготовка к импорту</h3>
          <div>
            1. Откройте чистый рабочий лист в вашей любимой программе электронных таблиц (например, Microsoft Excel или Google Sheets)..
          </div>
          <div>
            2. Создайте и обозначьте первые два столбца как <b>Mail</b> и <b>Last Name</b>. Это единственные обязательные поля.
          </div>
          <div>
            3. Если вы хотите добавить дополнительную информацию, следующие столбцы должны располагаться в следующем порядке:
            <ul>
              <li style={{ listStyleType: 'disc' }}>Mail</li>
              <li style={{ listStyleType: 'disc' }}>Last Name</li>
              <li style={{ listStyleType: 'disc' }}>First Name</li>
              <li style={{ listStyleType: 'disc' }}>Title</li>
              <li style={{ listStyleType: 'disc' }}>Job Title</li>
              <li style={{ listStyleType: 'disc' }}>Publication</li>
              <li style={{ listStyleType: 'disc' }}>Company</li>
              <li style={{ listStyleType: 'disc' }}>Dossier</li>
              <li style={{ listStyleType: 'disc' }}>Notes</li>
              <li style={{ listStyleType: 'disc' }}>Picture (полный URL-адрес для размещенного изображения)</li>
              <li style={{ listStyleType: 'disc' }}>Address</li>
              <li style={{ listStyleType: 'disc' }}>Phone</li>
              <li style={{ listStyleType: 'disc' }}>Fax</li>
              <li style={{ listStyleType: 'disc' }}>Country</li>
              <li style={{ listStyleType: 'disc' }}>State</li>
              <li style={{ listStyleType: 'disc' }}>Languages</li>
              <li style={{ listStyleType: 'disc' }}>Preferred Language</li>
              <li style={{ listStyleType: 'disc' }}>Website</li>
              <li style={{ listStyleType: 'disc' }}>LinkedIn URL</li>
              <li style={{ listStyleType: 'disc' }}>X / Twitter URL</li>
              <li style={{ listStyleType: 'disc' }}>Facebook URL</li>
              <li style={{ listStyleType: 'disc' }}>Threads URL</li>
              <li style={{ listStyleType: 'disc' }}>TikTok URL</li>
              <li style={{ listStyleType: 'disc' }}>Instagram URL</li>
              <li style={{ listStyleType: 'disc' }}>YouTube URL</li>
              <li style={{ listStyleType: 'disc' }}>Other URL</li>
            </ul>
          </div>
          <div>4. Вставьте свою контактную информацию в соответствующие поля.</div>
          <div>5. Сохраните файл в формате XLSX или CSV. Вы готовы к работе!</div>
          <h3>Используйте наш файл-шаблон</h3>
          <div>Нажмите на кнопку ниже, чтобы загрузить образец файла XLSX. Конечно, вы также можете преобразовать его в CSV.</div>
          <div>Убедитесь, что вы удалили информацию об образце перед импортом.</div>
        </div>
      )
    }

    if (languageId === 6) {
      return (
        <div className={classes.helpText}>
          <div>
            我们的导入工具让你通过上传XLSX或CSV文件来批量添加联系人.
          </div>
          <h3>为进口做准备</h3>
          <div>
            1. 在你喜欢的电子表格程序(如Microsoft Excel或Google Sheets)中打开一个空白工作表
          </div>
          <div>
            2. 创建并标记前两栏为<b>Mail</b>和<b>Last Name</b>. 这些是唯一的必填字段.
          </div>
          <div>
            3. 如果你想添加更多的信息，接下来的栏目必须按以下顺序排列.
            <ul>
              <li style={{ listStyleType: 'disc' }}>Mail</li>
              <li style={{ listStyleType: 'disc' }}>Last Name</li>
              <li style={{ listStyleType: 'disc' }}>First Name</li>
              <li style={{ listStyleType: 'disc' }}>Title</li>
              <li style={{ listStyleType: 'disc' }}>Job Title</li>
              <li style={{ listStyleType: 'disc' }}>Publication</li>
              <li style={{ listStyleType: 'disc' }}>Company</li>
              <li style={{ listStyleType: 'disc' }}>Dossier</li>
              <li style={{ listStyleType: 'disc' }}>Notes</li>
              <li style={{ listStyleType: 'disc' }}>Picture (托管图像的完整源URL)</li>
              <li style={{ listStyleType: 'disc' }}>Address</li>
              <li style={{ listStyleType: 'disc' }}>Phone</li>
              <li style={{ listStyleType: 'disc' }}>Fax</li>
              <li style={{ listStyleType: 'disc' }}>Country</li>
              <li style={{ listStyleType: 'disc' }}>State</li>
              <li style={{ listStyleType: 'disc' }}>Languages</li>
              <li style={{ listStyleType: 'disc' }}>Preferred Language</li>
              <li style={{ listStyleType: 'disc' }}>Website</li>
              <li style={{ listStyleType: 'disc' }}>LinkedIn URL</li>
              <li style={{ listStyleType: 'disc' }}>X / Twitter URL</li>
              <li style={{ listStyleType: 'disc' }}>Facebook URL</li>
              <li style={{ listStyleType: 'disc' }}>Threads URL</li>
              <li style={{ listStyleType: 'disc' }}>TikTok URL</li>
              <li style={{ listStyleType: 'disc' }}>Instagram URL</li>
              <li style={{ listStyleType: 'disc' }}>YouTube URL</li>
              <li style={{ listStyleType: 'disc' }}>Other URL</li>
            </ul>
          </div>
          <div>4. 将你的联系信息粘贴在相关区域.</div>
          <div>5. 以XLSX或CSV格式保存文件. 你已经准备好了!</div>
          <h3>使用我们的模板文件</h3>
          <div>点击下面的按钮, 下载一个XLSX文件样. 当然, 你也可以将其转换为CSV.</div>
          <div>确保你在导入前删除样本信息.</div>
        </div>
      )
    }

    return (
      <div className={classes.helpText}>
        <div>
          Our import tool lets you add contacts in bulk by uploading them in an
          XLSX or CSV file.
        </div>
        <h3>Prepare for Import</h3>
        <div>
          1. Open a blank worksheet in your favorite spreadsheet program (like
          Microsoft Excel or Google Sheets).
        </div>
        <div>
          2. Create and label the first two columns as <b>Mail</b> and <b>Last Name</b>. Those are the only required fields.
        </div>
        <div>
          3. If you want to add more information, the next columns must be in the
          following order:
          <ul>
            <li style={{ listStyleType: 'disc' }}>Mail</li>
            <li style={{ listStyleType: 'disc' }}>Last Name</li>
            <li style={{ listStyleType: 'disc' }}>First Name</li>
            <li style={{ listStyleType: 'disc' }}>Title</li>
            <li style={{ listStyleType: 'disc' }}>Job Title</li>
            <li style={{ listStyleType: 'disc' }}>Publication</li>
            <li style={{ listStyleType: 'disc' }}>Company</li>
            <li style={{ listStyleType: 'disc' }}>Dossier</li>
            <li style={{ listStyleType: 'disc' }}>Notes</li>
            <li style={{ listStyleType: 'disc' }}>Picture (full source URL for a hosted image)</li>
            <li style={{ listStyleType: 'disc' }}>Address</li>
            <li style={{ listStyleType: 'disc' }}>Phone</li>
            <li style={{ listStyleType: 'disc' }}>Fax</li>
            <li style={{ listStyleType: 'disc' }}>Country</li>
            <li style={{ listStyleType: 'disc' }}>State</li>
            <li style={{ listStyleType: 'disc' }}>Languages</li>
            <li style={{ listStyleType: 'disc' }}>Preferred Language</li>
            <li style={{ listStyleType: 'disc' }}>Website</li>
            <li style={{ listStyleType: 'disc' }}>LinkedIn URL</li>
            <li style={{ listStyleType: 'disc' }}>X / Twitter URL</li>
            <li style={{ listStyleType: 'disc' }}>Facebook URL</li>
            <li style={{ listStyleType: 'disc' }}>Threads URL</li>
            <li style={{ listStyleType: 'disc' }}>TikTok URL</li>
            <li style={{ listStyleType: 'disc' }}>Instagram URL</li>
            <li style={{ listStyleType: 'disc' }}>YouTube URL</li>
            <li style={{ listStyleType: 'disc' }}>Other URL</li>
          </ul>
        </div>
        <div>4. Paste your contact information in the relevant fields.</div>
        <div>5. Save the file in XLSX or CSV format. You are ready to go!</div>
        <h3>Use Our Template File</h3>
        <div>Click on the button below to download a sample XLSX file. Of course, you can also convert it to CSV.</div>
        <div>Make sure you remove the sample information before you import.</div>
      </div>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={i18n.get('help')}
    >
      {renderHelpText()}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        startIcon={<DownloadIcon />}
        onClick={() => handleOnDownloadTemplate()}
      >
        {i18n.get('download')} {i18n.get('template')}
      </Button>
    </Dialog>
  )
}

ImportHelpDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  languageId: PropTypes.number.isRequired,
  onDownloadTemplate: PropTypes.func.isRequired
}

export default ImportHelpDialog
