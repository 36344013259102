import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList } from 'immutable'

import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import CloseIcon from '@mui/icons-material/Close'
import { Button, IconButton } from 'containers/themed'

import DuplicateContactItem from 'containers/anewstip/duplicate_contacts_dialog/DuplicateContactItem'

export default function DuplicateContactsDialog({
  open,
  result,
  withNext,
  onCancel,
  onNext
}) {
  const theme = useTheme()
  const i18n = useI18n()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const duplicates = result.filter(el => el.get('existingContact')) || []
  const handleClose = () => onCancel()

  if (duplicates.isEmpty()) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle
        mx={1}
        sx={{
          paddingBottom: t => t.spacing(2),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('duplicate_contacts_found')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', paddingLeft: t => t.spacing(3) }}
      >
        {duplicates.map(el => (
          <DuplicateContactItem
            key={el}
            data={el}
          />
        ))}
      </DialogContent>

      <DialogActions
        sx={{ paddingLeft: t => t.spacing(2) }}
        disableSpacing
      >
        {withNext ? (
          <Button
            onClick={onNext}
          >
            {i18n.get('next')}
          </Button>

        ) : (
          <Button
            onClick={handleClose}
          >
            {i18n.get('close')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

DuplicateContactsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  result: PropTypes.instanceOf(ImmutableList).isRequired,
  withNext: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
}
