import React from 'react'
import PropTypes from 'prop-types'

import { ChartDataSources } from 'static/constants'

import imageLinks from 'static/image_links'

export default function ChartBranding({
  dataSource,
  thirdLevel,
  firstLevel,
  className,
  inverted
}) {
  if (dataSource === ChartDataSources.GOOGLE_ANALYTICS) {
    return (
      <div className={className}>
        <img
          src={imageLinks.GOOGLE_ANALYTICS_LOGO}
          height="15px"
          alt="Google Analytics"
        />
      </div>
    )
  }

  if (dataSource === ChartDataSources.YOUTUBE_ANALYTICS) {
    return (
      <div className={className}>
        <img
          src={imageLinks.YOUTUBE_ANALYTICS_LOGO}
          height="15px"
          alt="YouTube Analytics"
        />
      </div>
    )
  }

  if (dataSource === ChartDataSources.LINKED_IN_ANALYTICS) {
    return (
      <div className={className}>
        <img
          src={imageLinks.LINKEDIN_ANALYTICS_LOGO}
          height="18px"
          alt="LinkedIn Analytics"
        />
      </div>
    )
  }

  if (dataSource === ChartDataSources.FACEBOOK_ANALYTICS) {
    return (
      <div className={className}>
        <img
          src={imageLinks.FACEBOOK_ANALYTICS_LOGO}
          height="10px"
          alt="Facebook Analytics"
        />
      </div>
    )
  }

  if (dataSource === ChartDataSources.TWITTER_ANALYTICS) {
    return (
      <div className={className}>
        <img
          src={imageLinks.TWITTER_ANALYTICS_LOGO}
          height="16px"
          alt="Twitter Analytics"
        />
      </div>
    )
  }

  if (dataSource === ChartDataSources.MOZ || (thirdLevel || '').startsWith('moz') || (firstLevel || '').startsWith('moz')) {
    return (
      <div className={className}>
        <img
          src={inverted ? imageLinks.MOZ_LOGO_WHITE : imageLinks.MOZ_LOGO}
          height="10px"
          alt="Moz"
        />
      </div>
    )
  }

  return null
}

ChartBranding.propTypes = {
  dataSource: PropTypes.string,
  thirdLevel: PropTypes.string,
  firstLevel: PropTypes.string,
  inverted: PropTypes.bool,
  className: PropTypes.string
}
