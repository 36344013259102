import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import * as Actions from 'actions/darknet'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  searchQuery: null,
  requestRunning: false,
  sortBy: 'crawlDate',
  sortDirection: 'desc',
  view: 'statistics',
  showAdvancedSearch: false,
  activeFiltersCollapsed: true,
  scoreQueryDialogOpen: false,
  searchQueryDialogOpen: false,
  editForm: false,
  loadingMore: false
})

export default handleActions({
  [Actions.searchRequestStart]: state => state.set('requestRunning', true),
  [Actions.searchRequestSuccess]: state => state.set('requestRunning', false),
  [Actions.searchRequestError]: state => state.set('requestRunning', false),

  [Actions.deleteQueryRequestStart]: state => state.set('requestRunning', true),
  [Actions.deleteQueryRequestSuccess]: state => state.set('requestRunning', false),
  [Actions.deleteQueryRequestError]: state => state.set('requestRunning', false),

  [Actions.uiOnSortByChange]: (state, { payload }) => state.set('sortBy', payload),
  [Actions.uiOnSortDirectionChange]: (state, { payload }) => state.set('sortDirection', payload),
  [Actions.changeDarknetView]: (state, { payload }) => state.set('view', payload),

  [Actions.toggleAdvancedSearch]: state => state.set('showAdvancedSearch', !state.get('showAdvancedSearch')),

  [Actions.closeScoreQueryDialog]: state => state.set('scoreQueryDialogOpen', false),
  [Actions.closeSearchQueryDialog]: state => state.set('searchQueryDialogOpen', false),

  [Actions.openScoreQueryDialog]: state => state.set('scoreQueryDialogOpen', true),
  [Actions.openSearchQueryDialog]: state => state.set('searchQueryDialogOpen', true),

  [Actions.deactivateQueryRequestStart]: state => state.set('requestRunning', true),
  [Actions.activateQueryRequestStart]: state => state.set('requestRunning', true),
  [Actions.saveScoreQueryRequestStart]: state => state.set('requestRunning', true),
  [Actions.saveScoreQueryRequestError]: state => state.set('requestRunning', false),
  [Actions.saveSearchQueryRequestStart]: state => state.set('requestRunning', true),
  [Actions.saveSearchQueryRequestError]: state => state.set('requestRunning', false),
  [Actions.fetchAllQueriesSuccess]: state => state.set('requestRunning', false),
  [Actions.loadMoreDarknetEntriesStart]: state => state.set('loadingMore', true),
  [Actions.loadMoreDarknetEntriesSuccess]: state => state.set('loadingMore', false),
  [Actions.loadMoreDarknetEntriesError]: state => state.set('loadingMore', false),
  [AppActions.resetState]: () => initialState
}, initialState)
