import { connect } from 'react-redux'

import Navigation from 'components/darknet/navigation'
import { changeDarknetView } from 'actions/darknet'
import { getI18n, getDarknetRequestRunning, getDarknetView, getDevice, getDarknetTotalCount } from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  requestRunning: getDarknetRequestRunning(state),
  total: getDarknetTotalCount(state),
  view: getDarknetView(state),
  device: getDevice(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: changeDarknetView
  }
)(Navigation)
