import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'
import { separate } from 'utils/number'
import { Paper, CircularProgress } from '@mui/material'
import { grey } from '@mui/material/colors'

import { Button } from 'containers/themed'
import useI18n from 'hooks/useI18n'

import useColors from 'hooks/useColors'

const ButtonContainer = props => <div {...props} />

const useStyles = makeStyles()(theme => ({
  chartsButton: {},
  switchActionsContainer: {
    display: 'flex',
    margin: '3em 2em 1.5em 1em',
    [theme.breakpoints.only('xs')]: {
      margin: '3em 0 1.5em 0'
    }
  },
  switchActions: {
    display: 'flex',
    width: '100%',
    height: '64px !important'
  },
  switchAction: {
    position: 'relative',
    flexGrow: 1,
    flexBasis: 0,

    '& > button': {
      minWidth: '100%',
      minHeight: '100%',

      [theme.breakpoints.only('xs')]: {
        fontSize: '7pt'
      }
    }
  },
  switchActionContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonLabel: {
    color: '#ffffff'
  },
  activeBorder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 3,
    animation: `${keyframes`
            from {
              transform: scale(0);
            }
            to {
              transform: scale(1);
            }
          `} 300ms;`
  },
  count: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 16,
    fontWeight: 300
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28
  },
  chartsActive: {
    opacity: 0.4
  },
  total: {
    color: grey[800],
    fontSize: '9pt',
    fontWeight: 500,
    textAlign: 'center'
  }
}))

export default function Navigation({
  requestRunning,
  total,
  view,

  onChange
}) {
  const i18n = useI18n()
  const colors = useColors()
  const { classes } = useStyles()

  const SpinIcon = (
    <div className={classes.iconContainer}>
      <CircularProgress
        variant="indeterminate"
        size={24}
      />
    </div>
  )

  const renderNavigationAction = (buttonView, label, isRunning, counts, icon, Comp = ButtonContainer) => {
    let totalCount = null
    let count = null

    if (typeof counts === 'number') {
      totalCount = counts
      count = counts
    } else if (Array.isArray(counts)) {
      const [t] = counts
      totalCount = t
      count = t
    }

    let number = separate(count)
    const title = null

    if (totalCount > count) {
      totalCount = separate(totalCount)
      number = `${number} (${totalCount})`
    }

    let iconComp = (
      <div className={classes.count}>
        {number}
      </div>
    )

    if (isRunning) {
      iconComp = SpinIcon
    } else if (icon) {
      iconComp = (
        <div className={classes.iconContainer}>
          {icon}
        </div>
      )
    }

    let active

    if (view === buttonView) {
      active = (
        <div
          className={classes.activeBorder}
          style={{ backgroundColor: colors.get('primary') }}
        />
      )
    }

    return (
      <Comp
        key={buttonView}
        className={classes.switchAction}
        title={title}
      >
        <Button
          onClick={() => onChange(buttonView)}
        >
          <div className={classes.switchActionContent}>
            {iconComp}
            {label}
          </div>
        </Button>

        {active}
      </Comp>
    )
  }

  const buttons = []

  buttons.push(
    renderNavigationAction(
      'statistics',
      i18n.get('statistics'),
      requestRunning,
      total
    )
  )

  buttons.push(
    renderNavigationAction(
      'search_results',
      i18n.get('search_results'),
      requestRunning,
      total
    )
  )

  if (buttons.length <= 1) {
    return null
  }

  return (
    <Paper
      className={classes.switchActionsContainer}
    >
      <div className={classes.switchActions}>
        {buttons}
      </div>
    </Paper>
  )
}

Navigation.propTypes = {
  total: PropTypes.number.isRequired,
  requestRunning: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired
}
