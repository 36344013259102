/* eslint-disable */
import React from 'react'
import useI18n from 'hooks/useI18n'

import {
    FORMAT_TEXT_COMMAND
} from 'lexical'

const TextStyle = ({ editor, blockType, isBold, isItalic, isUnderline }) => {
    const i18n = useI18n()

    const isDisabled = blockType === 'h1' || blockType === 'h2' || blockType === 'h3' || blockType === 'h4' || blockType === 'h5' || blockType === 'h6'

    return (
        <>
            <button
                disabled={isDisabled}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
                }}
                className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
                title={i18n.get('bold')}
            >
                <i className="toolbar-icon fal fa-bold" />
            </button>
            <button
                disabled={isDisabled}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
                }}
                className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
                title={i18n.get('italic')}
            >
                <i className="toolbar-icon fal fa-italic" />
            </button>
            <button
                disabled={isDisabled}
                onClick={() => {
                    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
                }}
                className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
                title={i18n.get('underline')}
            >
                <i className="toolbar-icon fal fa-underline" />
            </button>
        </>
    )
}

export default TextStyle
