import { connect } from 'react-redux'

import RestrictionHint from 'components/restriction_hint'
import {
  getRestrictedStaticMediaReviewCodes,
  getRestrictedStaticChannels,
  getRestrictedStaticCountries,
  getNewsAllowedCodeIds,
  getNewsAllowedChannelIds,
  getNewsAllowedCountryIds
} from 'selectors'

const mapStateToProps = state => ({
  mediaReviewCodes: getRestrictedStaticMediaReviewCodes(state),
  channels: getRestrictedStaticChannels(state),
  countries: getRestrictedStaticCountries(state),
  hasCodeRestriction: !getNewsAllowedCodeIds(state).isEmpty(),
  hasChannelRestriction: !getNewsAllowedChannelIds(state).isEmpty(),
  hasCountryRestriction: !getNewsAllowedCountryIds(state).isEmpty()
})

export default connect(mapStateToProps)(RestrictionHint)
