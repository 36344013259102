import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  Zoom,
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()((theme, { toolbar, padding }) => {
  const dialogContent = {}

  if (!padding) {
    dialogContent.padding = 0
  }

  if (!toolbar) {
    dialogContent.position = 'relative'
  }

  return ({
    spacer: {
      flex: 1
    },
    close: {
      color: 'white !important'
    },
    dialogContent,
    closeNoToolbar: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 300
    }
  })
})

export default function MuiDialog({
  onClose,
  open,
  title,
  actions,
  toolbar,
  padding,
  children,
  fullScreen: fScreen,
  classes,
  dialogContentClasses: dialogContentClassesFromProps,
  maxWidth,
  Transition,
  dividers,
  disableBackClick
}) {
  const { classes: customClasses, cx } = useStyles({ toolbar, padding })
  const theme = useTheme()
  const fullScreen = fScreen || useMediaQuery(theme.breakpoints.down('md'))
  const TransitionComp = Transition || (fullScreen ? Slide : Zoom)
  const TransitionProps = fullScreen ? { direction: 'up' } : undefined

  const dialogContentClasses = { ...(dialogContentClassesFromProps || {}) }
  dialogContentClasses.root = cx(customClasses.dialogContent, dialogContentClasses.root)

  return (
    <Dialog
      TransitionComponent={TransitionComp}
      TransitionProps={TransitionProps}
      fullScreen={fullScreen}
      fullWidth
      open={open}
      onClose={(!disableBackClick && onClose) ? () => onClose() : null}
      maxWidth={maxWidth}
      classes={classes}
    >
      {title && toolbar && (
        <AppBar
          enableColorOnDark
          position="static"
        >
          <Toolbar>
            <Typography
              variant="h6"
              color="inherit"
              dangerouslySetInnerHTML={{
                __html: title
              }}
            />
            <div className={customClasses.spacer} />
            {onClose && (
              <IconButton
                classes={{ root: customClasses.close }}
                icon="close"
                onClick={() => onClose()}
                size="small"
              />
            )}
          </Toolbar>
        </AppBar>
      )}

      {title && !toolbar && onClose && (
        <div className={customClasses.closeNoToolbar}>
          <IconButton
            icon="close"
            onClick={() => onClose()}
            size="large"
          />
        </div>
      )}

      <DialogContent
        classes={dialogContentClasses}
        dividers={dividers}
      >
        {children}
      </DialogContent>

      {actions && (!Array.isArray(actions) || actions.length > 0) && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  )
}

MuiDialog.defaultProps = {
  maxWidth: 'lg',
  dividers: false,
  disableBackClick: false,
  toolbar: true,
  padding: true
}

MuiDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  fullScreen: PropTypes.bool,
  actions: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.string,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  classes: PropTypes.object,
  dialogContentClasses: PropTypes.object,
  Transition: PropTypes.any,
  dividers: PropTypes.bool,
  disableBackClick: PropTypes.bool,
  toolbar: PropTypes.bool,
  padding: PropTypes.bool
}
