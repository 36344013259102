import { connect } from 'react-redux'

import ClearAllConfirmDialog from 'components/content_desk_new/contents/content_edit_dialog/recipients_browser/clear_all_confirm_dialog'

import {
  setContentFormData as onChange,
  closeRecipientsClearAllConfirmDialog as closeDialog
} from 'actions/content_desk'

import {
  getCDeskShowRecipientsClearAllConfirmDialog
} from 'selectors'

const mapStateToProps = state => ({
  open: getCDeskShowRecipientsClearAllConfirmDialog(state)
})

export default connect(
  mapStateToProps,
  {
    onChange,
    closeDialog
  }
)(ClearAllConfirmDialog)
