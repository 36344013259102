import { all } from 'redux-saga/effects'

import analysisSaga from './analysis'
import apiKeysSaga from './api_keys'
import appSaga from './app'
import authSaga from './auth'
import chartsSaga from './charts'
import configSaga from './config'
import customTagsSaga from './custom_tags'
import dashboardSaga from './dashboard'
import editMailingListSaga from './edit_mailing_list'
import editNewsSaga from './edit_news'
import environmentSaga from './environment'
import exportSaga from './export'
import filterTimelineSaga from './filter_timeline'
import gaSaga from './ga'
import identitySetsSaga from './identity_sets'
import influencersSaga from './influencers'
import mediaReviewSaga from './media_review'
import navigationSaga from './navigation'
import newsSaga from './news'
import pageIdentitiesSaga from './page_identities'
import publicationsSaga from './publications'
import savedDashboardSaga from './saved_dashboard'
import savedChartsSaga from './saved_charts'
import savedSearchesSaga from './saved_searches'
import shoppingCartSaga from './shopping_cart'
import shoppingNews from './shopping_news'
import subscriptionsSaga from './subscriptions'
import uiSaga from './ui'
import userSaga from './user'
import darknetSaga from './darknet'
import contentDeskSaga from './content_desk'
import contactManagementSaga from './contact_management'
import notificationsSaga from './notifications'
import surveySaga from './survey'
import anewstipSaga from './anewstip'
import emailTemplatesLibrarySaga from './email_templates_library'
import unsplashSaga from './unsplash'
import ustSaga from './ust'
import administrationSaga from './administration'
import themaxSaga from './themax'
import aiSaga from './ai'

export default function* rootSaga() {
  yield all([
    analysisSaga(),
    apiKeysSaga(),
    appSaga(),
    authSaga(),
    chartsSaga(),
    configSaga(),
    customTagsSaga(),
    dashboardSaga(),
    editMailingListSaga(),
    editNewsSaga(),
    environmentSaga(),
    exportSaga(),
    filterTimelineSaga(),
    gaSaga(),
    identitySetsSaga(),
    influencersSaga(),
    mediaReviewSaga(),
    navigationSaga(),
    newsSaga(),
    pageIdentitiesSaga(),
    publicationsSaga(),
    savedDashboardSaga(),
    savedChartsSaga(),
    savedSearchesSaga(),
    shoppingCartSaga(),
    shoppingNews(),
    subscriptionsSaga(),
    uiSaga(),
    userSaga(),
    darknetSaga(),
    contentDeskSaga(),
    contactManagementSaga(),
    notificationsSaga(),
    unsplashSaga(),
    surveySaga(),
    anewstipSaga(),
    emailTemplatesLibrarySaga(),
    ustSaga(),
    administrationSaga(),
    themaxSaga(),
    aiSaga()
  ])
}
