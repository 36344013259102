import { connect } from 'react-redux'

import News from 'components/content_desk_new/contents/content_view/statistics_data/news'

import { switchToSearchPool } from 'actions/content_desk'
import { getCDeskSelectedContent } from 'selectors'

const mapStateToProps = state => ({
  content: getCDeskSelectedContent(state)
})

export default connect(
  mapStateToProps,
  {
    onSwitchToSearchPoolClick: switchToSearchPool
  }
)(News)
