import React from 'react'
import PropTypes from 'prop-types'
import { Map as ImmutableMap, List as ImmutableList } from 'immutable'
import useI18n from 'hooks/useI18n'
import { mapToOptions } from 'utils/autocomplete'

import { Grid, Autocomplete, TextField } from '@mui/material'

export default function EditContactTopics({ contact, topics, onChange }) {
  const i18n = useI18n()
  const topicIds = contact.get('mediaTopics').map(t => t.get('id'))
  const values = mapToOptions(topics.filter(t => topicIds.includes(t.get('id'))))

  const topicsOptions = mapToOptions(topics)

  const handleChange = (_e, newValues) => {
    onChange(newValues.map(v => v.value))
  }

  return (
    <Grid
      item
      xs={12}
    >
      <Autocomplete
        fullWidth
        multiple
        onChange={handleChange}
        options={topicsOptions}
        isOptionEqualToValue={(option, value) => option.value === value?.value}
        value={values}
        clearOnBlur
        selectOnFocus
        disableCloseOnSelect
        label={i18n.get('themes')}
        size="small"
        renderInput={params => (
          <TextField
            {...params}
            label={i18n.get('themes')}
            placeholder=""
          />
        )}
        limitTags={10}
      />
    </Grid>
  )
}

EditContactTopics.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  topics: PropTypes.instanceOf(ImmutableList).isRequired,
  onChange: PropTypes.func.isRequired
}
