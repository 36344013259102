import { connect } from 'react-redux'

import CampaignDelete from 'components/content_desk_new/campaigns/campaign/campaign_actions/CampaignDelete'

import { openDeleteDialog, setDeleteContentData } from 'actions/content_desk'

const mapStateToProps = (_state, { campaign }) => ({
  campaign
})

export default connect(
  mapStateToProps,
  {
    onOpen: () => openDeleteDialog('campaign'),
    setDeleteData: setDeleteContentData
  }
)(CampaignDelete)
