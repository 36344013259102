import { handleActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'
import { mergeDeepKeepLists } from 'utils/immutable'

import * as NewsActions from 'actions/news'
import * as AppActions from 'actions/app'
import {
  NewsPageViews,
  DateTypes,
  DefaultExportFormats,
  SearchFields,
  SearchFieldGroups,
  IndexTypes,
  GroupingTypes,
  Capabilities,
  NewsPageModules,
  AllowedKeyFigures
} from 'static/constants'

import masterConfig from 'static/master_config'

const searchFieldsNotInDefault = [
  SearchFields.IDENTITY_SETS,
  SearchFields.SIMILARITY_CCD_CONTENTS,
  SearchFields.SIMILARITY_CCD_CAMPAIGNS
]

export const defaultFilterFields = Set(Object.values(SearchFields))
  .subtract(searchFieldsNotInDefault)
  .toList()
  .toJS()

export const searchPoolFilterFields = [
  SearchFields.CHANNELS,
  SearchFields.COUNTRIES,
  SearchFields.DISTRIBUTION_AREAS,
  SearchFields.EXCLUDE_COMMENTS,
  SearchFields.EXCLUDE_RETWEETS,
  SearchFields.EXCLUDE_VISITOR_POSTS,
  SearchFields.FILTER_GROUPS,
  SearchFields.GEO_BOUNDING_BOX,
  SearchFields.GLOBAL_CLUSTERS,
  SearchFields.LANGUAGES,
  SearchFields.MEDIA_TYPES,
  SearchFields.OUTLINK_DOMAINS,
  SearchFields.OUTLINK_URLS,
  SearchFields.WITH_OUTLINKS_ONLY,
  SearchFields.WITHOUT_OUTLINKS_ONLY,
  SearchFields.EXCLUDE_RETWEETS,
  SearchFields.CUSTOM_TAGS,
  SearchFields.NEWSGUARD_RANKS,
  SearchFields.NEWSGUARD_ORIENTATIONS,
  SearchFields.SIMILARITY_CCD_CONTENTS,
  SearchFields.SIMILARITY_CCD_CAMPAIGNS,
  SearchFields.INTERFACES,
  SearchFields.SUPPLIERS
]

const defaultConfig = fromJS({
  indexType: IndexTypes.DEFAULT,
  moduleName: NewsPageModules.NEWS,
  groupingType: GroupingTypes.CHANNEL,
  defaultDateType: null,
  hasDateRestriction: false,
  hasSavedSearchesLimit: false,
  appBarTitleKey: 'news_board',
  allowedViews: [
    NewsPageViews.NEWS,
    NewsPageViews.INFLUENCERS,
    NewsPageViews.PUBLICATIONS,
    NewsPageViews.CHARTS,
    NewsPageViews.PIN_BOARDS,
    NewsPageViews.SAVED_SEARCHES
  ],
  allowedKeyFigures: AllowedKeyFigures.DEFAULT,
  allowedDateTypes: [
    DateTypes.MEDIA_REVIEW,
    DateTypes.ARTICLE
  ],
  allowedFilterFields: defaultFilterFields,
  allowedFilterFieldGroups: Object.values(SearchFieldGroups),
  requiredFilters: [],
  exportFormats: DefaultExportFormats,
  newsPage: {
    moreOptions: [
      'select_all',
      'xlsx',
      'new_article',
      'hide_clusters'
    ],
    showGroupingSelection: true
  },
  newsGroupWidget: {
    headerFilterField: SearchFields.CHANNELS,
    showAllKeyFiguresInHeader: false,
    icon: {
      mode: 'channel',
      icon: null
    },
    paging: {
      entity: 'publication',
      field: 'channel_id'
    }
  },
  newsWidget: {
    showChannel: false,
    showCodes: true,
    showSentiment: false,
    sentimentEditable: false,
    showTonality: true,
    showActions: true,
    showSelectButton: true,
    showPinButton: true,
    showPdfButton: true,
    showEditButton: true,
    showNewIndicator: true,
    availableCodesOnly: true,
    showStatementTonality: false
  },
  influencerWidget: {
  },
  publicationWidget: {
  },
  capabilities: {
    influencers: [Capabilities.HAS_INFLUENCERS_MODULE],
    publications: [Capabilities.HAS_PUBLICATIONS_MODULE],
    influencerDetails: [Capabilities.HAS_INFLUENCER_DETAILS_FEATURE],
    savedSearches: [Capabilities.HAS_NEWS_SAVED_SEARCHES],
    savedSearchesEditing: [Capabilities.HAS_NEWS_SAVED_SEARCHES_EDITING_FEATURE],
    savedSearchesFeeds: [Capabilities.HAS_NEWS_SAVED_SEARCHES_FEEDS],
    export: [Capabilities.HAS_SHOPPING_CART],
    editing: [Capabilities.HAS_EDITING_FEATURE],
    deleting: [Capabilities.HAS_DELETE_NEWS_FEATURE],
    moreOptions: [
      Capabilities.HAS_SHOPPING_CART,
      Capabilities.HAS_EDITING_FEATURE
    ]
  },
  charts: {
    useAggregationValue: false
  },
  loadNewsOnEnter: true,
  lastSeenNewsIdCookieName: 'groot_last_seen_news_id_news'
})

const buildConfig = overrides => defaultConfig.mergeWith(mergeDeepKeepLists, fromJS(overrides))

const defaultAnalysisConfig = buildConfig({
  moduleName: NewsPageModules.ANALYSIS,
  appBarTitleKey: 'analysis',
  defaultDateType: DateTypes.ARTICLE,
  allowedViews: [
    NewsPageViews.ANALYSIS_QUERIES,
    NewsPageViews.NEWS,
    NewsPageViews.INFLUENCERS,
    NewsPageViews.PUBLICATIONS,
    NewsPageViews.SAVED_SEARCHES,
    NewsPageViews.PIN_BOARDS
  ],
  newsWidget: {
    showPdfButton: false,
    showTonality: false,
    showStatementTonality: true
  },
  capabilities: {
    influencers: [Capabilities.HAS_ANALYSIS_INFLUENCERS_MODULE],
    publications: [Capabilities.HAS_ANALYSIS_PUBLICATIONS_MODULE],
    influencerDetails: [Capabilities.HAS_ANALYSIS_INFLUENCER_DETAILS_FEATURE],
    savedSearches: [Capabilities.HAS_ANALYSIS_SAVED_SEARCHES],
    savedSearchesEditing: [Capabilities.HAS_ANALYSIS_SAVED_SEARCHES_EDITING_FEATURE],
    savedSearchesFeeds: [Capabilities.HAS_ANALYSIS_SAVED_SEARCHES_FEEDS],
    moreOptions: [
      Capabilities.HAS_SHOPPING_CART,
      Capabilities.HAS_EDITING_FEATURE
    ]
  },
  newsPage: {
    moreOptions: [
      'select_all',
      'xlsx',
      'new_article',
      'hide_clusters'
    ]
  },
  lastSeenNewsIdCookieName: 'groot_last_seen_news_id_analysis'
})

const buildAnalysisConfigConfig = overrides => defaultAnalysisConfig.mergeWith(mergeDeepKeepLists, fromJS(overrides))

const defaultSearchPoolConfig = buildConfig({
  moduleName: NewsPageModules.NEWS_POOL,
  indexType: IndexTypes.BACKWARDS_NEWS,
  groupingType: GroupingTypes.CHANNEL,
  defaultDateType: DateTypes.ARTICLE,
  hasDateRestriction: true,
  hasSavedSearchesLimit: true,
  appBarTitleKey: 'search_pool',
  allowedViews: [
    NewsPageViews.BACKWARDS_NEWS,
    NewsPageViews.INFLUENCERS,
    NewsPageViews.PUBLICATIONS,
    NewsPageViews.PIN_BOARDS,
    NewsPageViews.SAVED_SEARCHES,
    NewsPageViews.STATIC_CHARTS
  ],
  allowedKeyFigures: AllowedKeyFigures.BACKWARDS_NEWS,
  allowedDateTypes: [
    DateTypes.ARTICLE
  ],
  allowedFilterFields: searchPoolFilterFields,
  allowedFilterFieldGroups: [
    SearchFieldGroups.NEWS,
    SearchFieldGroups.PUBLICATIONS
  ],
  requiredFilters: [
    SearchFields.OUTLINK_DOMAINS,
    SearchFields.OUTLINK_URLS,
    SearchFields.NEWS_QUERIES,
    SearchFields.AUTHOR_QUERIES,
    SearchFields.PUBLICATION_QUERIES,
    SearchFields.CUSTOM_TAGS,
    SearchFields.SIMILARITY_CCD_CONTENTS,
    SearchFields.SIMILARITY_CCD_CAMPAIGNS
  ],
  exportFormats: [
    'backwards_news_xlsx',
    'backwards_news_html'
  ],
  newsPage: {
    moreOptions: [
      'select_all',
      'backwards_news_xlsx',
      'backwards_news_html'
    ],
    showGroupingSelection: true
  },
  newsGroupWidget: {
    headerFilterField: SearchFields.CHANNELS,
    showAllKeyFiguresInHeader: false,
    icon: {
      mode: 'channel',
      icon: null
    },
    paging: {
      entity: 'publication',
      field: 'channel_id'
    }
  },
  newsWidget: {
    showSentiment: true,
    sentimentEditable: true,
    showActions: true,
    showCodes: true,
    showTonality: false,
    showSelectButton: true,
    showPinButton: true,
    showPdfButton: false,
    showEditButton: false,
    showNewIndicator: true,
    availableCodesOnly: false
  },
  influencerWidget: {
  },
  publicationWidget: {
  },
  capabilities: {
    influencers: [Capabilities.HAS_NEWS_POOL_INFLUENCERS_MODULE],
    publications: [Capabilities.HAS_NEWS_POOL_PUBLICATIONS_MODULE],
    influencerDetails: [Capabilities.HAS_NEWS_POOL_INFLUENCER_DETAILS_FEATURE],
    savedSearches: [Capabilities.HAS_NEWS_POOL_SAVED_SEARCHES],
    savedSearchesEditing: [Capabilities.HAS_NEWS_POOL_SAVED_SEARCHES_EDITING_FEATURE],
    savedSearchesFeeds: [Capabilities.HAS_NEWS_POOL_SAVED_SEARCHES_FEEDS],
    export: [Capabilities.HAS_NEWS_POOL_EXPORT],
    editing: ['DUMMY_DUMMY_DUMMY_HAS_NEWS_POOL_EDITING_FEATURE'], // not available yet
    deleting: [Capabilities.HAS_NEWS_POOL_DELETE_NEWS_FEATURE],
    moreOptions: [
      Capabilities.HAS_NEWS_POOL_EXPORT
    ]
  },
  charts: {
    useAggregationValue: true
  },
  loadNewsOnEnter: false,
  lastSeenNewsIdCookieName: 'groot_last_seen_news_id_news_pool'
})

const buildSearchPoolConfigConfig = overrides => defaultSearchPoolConfig.mergeWith(mergeDeepKeepLists, fromJS(overrides))

const byCodeConfigOverrides = {
  groupingType: GroupingTypes.MEDIA_REVIEW_CODE,
  newsGroupWidget: {
    headerFilterField: SearchFields.MEDIA_REVIEW_CODES,
    showAllKeyFiguresInHeader: true,
    icon: {
      mode: 'full',
      icon: masterConfig.getIn(['icons', 'codes'])
    },
    paging: {
      entity: 'news',
      field: 'mediareview_code_id'
    }
  },
  newsWidget: {
    showChannel: true
  }
}

const byCustomTagConfigOverrides = {
  groupingType: GroupingTypes.CUSTOM_TAG,
  newsGroupWidget: {
    headerFilterField: SearchFields.CUSTOM_TAGS,
    showAllKeyFiguresInHeader: true,
    icon: {
      mode: 'full',
      icon: masterConfig.getIn(['icons', 'customTags'])
    },
    paging: {
      entity: 'news',
      field: 'custom_tag_id'
    }
  },
  newsWidget: {
    showChannel: true
  }
}

const byNewsguardRankConfigOverrides = {
  groupingType: GroupingTypes.NEWSGUARD_RANK,
  newsGroupWidget: {
    headerFilterField: SearchFields.NEWSGUARD_RANKS,
    showAllKeyFiguresInHeader: true,
    icon: {
      mode: 'newsguard',
      icon: null
    },
    paging: {
      entity: 'publication',
      field: 'newsguard_rank'
    }
  },
  newsWidget: {
    showChannel: true
  }
}

export const configPresets = fromJS({
  earned: buildConfig({}),
  earnedByCode: buildConfig(byCodeConfigOverrides),
  earnedByCustomTag: buildConfig(byCustomTagConfigOverrides),
  analysis: defaultAnalysisConfig,
  analysisByCode: buildAnalysisConfigConfig(byCodeConfigOverrides),
  analysisByCustomTag: buildAnalysisConfigConfig(byCustomTagConfigOverrides),

  profileMonitoring: buildConfig({
    moduleName: NewsPageModules.PROFILE_MONITORING,
    groupingType: GroupingTypes.PAGE_IDENTITY,
    defaultDateType: DateTypes.ARTICLE,
    appBarTitleKey: 'profile_monitoring',
    allowedFilterFields: defaultFilterFields.concat([SearchFields.IDENTITY_SETS]),
    allowedViews: [
      NewsPageViews.STATISTICS,
      NewsPageViews.PROFILES,
      NewsPageViews.POSTS,
      NewsPageViews.PIN_BOARDS,
      NewsPageViews.SAVED_SEARCHES
    ],
    newsPage: {
      moreOptions: [
        'select_all',
        'xlsx',
        'new_article'
      ],
      showGroupingSelection: false
    },
    newsGroupWidget: {
      headerFilterField: SearchFields.PAGE_IDENTITIES,
      icon: {
        mode: 'channel',
        icon: null
      },
      paging: {
        entity: 'news',
        field: 'page_identity_id'
      }
    },
    newsWidget: {
      showActions: true,
      showCodes: true,
      showTonality: false,
      showNewIndicator: false,
      availableCodesOnly: false
    },
    capabilities: {
      savedSearches: [Capabilities.HAS_PROFILE_MONITORING_SAVED_SEARCHES],
      savedSearchesEditing: [Capabilities.HAS_PROFILE_MONITORING_SAVED_SEARCHES_EDITING_FEATURE],
      savedSearchesFeeds: [Capabilities.HAS_PROFILE_MONITORING_SAVED_SEARCHES_FEEDS]
    },
    lastSeenNewsIdCookieName: 'groot_last_seen_news_id_pm'
  }),

  searchPool: defaultSearchPoolConfig,
  searchPoolByNewsguardRank: buildSearchPoolConfigConfig(byNewsguardRankConfigOverrides)
})

export const initialState = fromJS({
  indexType: IndexTypes.DEFAULT,
  moduleName: '',
  allowedViews: [],
  allowedKeyFigures: [],
  allowedDateTypes: [],
  requiredFilters: [],
  capabilities: {
    influencers: [],
    publications: [],
    influencerDetails: [],
    savedSearches: [],
    savedSearchesEditing: [],
    savedSearchesFeeds: [],
    export: [],
    editing: [],
    deleting: [],
    moreOptions: []
  },
  charts: {},
  newsPage: {
    moreOptions: []
  },
  newsGroupWidget: {},
  newsWidget: {},
  influencerWidget: {},
  publicationWidget: {}
})

export default handleActions({
  [NewsActions.setViewConfigPreset]: (state, { payload: name }) => {
    const config = configPresets.get(name)

    if (config) {
      return config
    }

    return state
  },
  [AppActions.resetState]: () => initialState
}, initialState)
