import { handleActions, combineActions } from 'redux-actions'
import { fromJS, Set } from 'immutable'

import * as MediaReviewsActions from 'actions/media_reviews'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  running: false,
  checkMediaReviewReactionsBlocked: false,
  showRemoveNewsDialog: false,
  showDiffWarning: false,
  selectedMediaReviewNewsItem: {},
  selectedMediaReviewId: null,
  deletingNews: false,
  collapsedTopics: Set([]),
  expandedNews: Set([]),
  expandedSummaries: Set([]),
  selectedNewsForSorting: Set([]),
  showCustomTagDialog: false,
  showRemoveCustomTagDialog: false,
  autoAddMediaReviewsNews: false
})

export default handleActions({
  [MediaReviewsActions.fetchNewsForMediaReviewStart]: () => initialState.set('running', true),
  [MediaReviewsActions.fetchNewsForMediaReviewSuccess]: state => state.set('running', false),
  [MediaReviewsActions.fetchNewsForMediaReviewError]: state => state.set('running', false),

  [MediaReviewsActions.removeNewsFromMediaReviewStart]: state => state.set('deletingNews', true),
  [MediaReviewsActions.removeNewsFromMediaReviewError]: state => state.set('deletingNews', false),

  [MediaReviewsActions.showCustomTagDialog]: state => state.set('showCustomTagDialog', true),
  [MediaReviewsActions.hideCustomTagDialog]: state => state
    .set('showCustomTagDialog', false)
    .set('autoAddMediaReviewsNews', false),
  [MediaReviewsActions.showRemoveCustomTagDialog]: state => state.set('showRemoveCustomTagDialog', true),
  [MediaReviewsActions.hideRemoveCustomTagDialog]: state => state.set('showRemoveCustomTagDialog', false),

  [MediaReviewsActions.selectMediaReview]: (state, { payload: mediaReviewId }) => state.merge({
    selectedMediaReviewId: mediaReviewId
  }),
  [MediaReviewsActions.showRemoveNewsDialog]: (state, { payload: news }) => state.merge({
    showRemoveNewsDialog: true, selectedMediaReviewNewsItem: news
  }),
  [MediaReviewsActions.hideRemoveNewsDialog]: state => state.merge({
    showRemoveNewsDialog: false
  }),
  [MediaReviewsActions.removeNewsFromMediaReviewSuccess]: state => state.merge({
    showRemoveNewsDialog: false,
    deletingNews: false
  }),
  [MediaReviewsActions.collapseTopics]: (state, { payload: topicIds }) => state.merge({
    collapsedTopics: state.get('collapsedTopics').union(topicIds)
  }),
  [MediaReviewsActions.expandTopics]: (state, { payload: topicIds }) => state.merge({
    collapsedTopics: state.get('collapsedTopics').subtract(topicIds)
  }),
  [MediaReviewsActions.showSnippet]: (state, { payload: newsIds }) => state.merge({
    expandedNews: state.get('expandedNews').union(newsIds)
  }),
  [MediaReviewsActions.hideSnippet]: (state, { payload: newsIds }) => state.merge({
    expandedNews: state.get('expandedNews').subtract(newsIds)
  }),
  [MediaReviewsActions.showSummary]: (state, { payload: newsIds }) => state.merge({
    expandedSummaries: state.get('expandedSummaries').union(newsIds)
  }),
  [MediaReviewsActions.hideSummary]: (state, { payload: newsIds }) => state.merge({
    expandedSummaries: state.get('expandedSummaries').subtract(newsIds)
  }),
  [MediaReviewsActions.selectNewsForSorting]: (state, { payload: { newsIds, isReplace, keyPress } }) => {
    const selectedNewsForSorting = state.get('selectedNewsForSorting')
    const deselect = (keyPress.ctrlKey || keyPress.metaKey)
      && (selectedNewsForSorting.some(n => n.get('newsId') === newsIds.first().get('newsId')))
    let replace = isReplace

    if (!replace) {
      replace = !selectedNewsForSorting.isEmpty() && selectedNewsForSorting.first().get('codeId') !== newsIds.first().get('codeId')
    }

    let result = Set([])

    if (replace) {
      result = Set(newsIds)
    } else if (deselect) {
      result = selectedNewsForSorting.subtract(newsIds)
    } else {
      result = selectedNewsForSorting.union(newsIds)
    }

    return state.merge({
      selectedNewsForSorting: result
    })
  },
  [MediaReviewsActions.deselectNewsForSorting]: (state, { payload: newsIds }) => state.merge({
    selectedNewsForSorting: state.get('selectedNewsForSorting').subtract(newsIds)
  }),

  [combineActions(
    MediaReviewsActions.moveNewsInMediaReviewStart,
    MediaReviewsActions.resetSortingSelection
  )]: state => (
    state.set('selectedNewsForSorting', initialState.get('selectedNewsForSorting'))
  ),

  [MediaReviewsActions.setAutoAddMediaReviewsNews]: (state, { payload }) => (
    state.set('autoAddMediaReviewsNews', payload)
  ),

  [combineActions(
    MediaReviewsActions.replaceMediaReviewReactionSuccess,
    MediaReviewsActions.replaceMediaReviewReactionError
  )]: state => state.set('checkMediaReviewReactionsBlocked', false),

  [MediaReviewsActions.replaceMediaReviewReactionStart]: state => state.set('checkMediaReviewReactionsBlocked', true),

  [MediaReviewsActions.showDiffWarning]: state => state.set('showDiffWarning', true),
  [MediaReviewsActions.hideDiffWarning]: state => state.set('showDiffWarning', false),

  [AppActions.resetState]: () => initialState
}, initialState)
