import { connect } from 'react-redux'

import ExcelDownloadButton from 'components/themax/excel_download_button'

import { getHasThemaxPaidVersion, getThemaxData, getThemaxExcelExportRequestRunning, getThemaxTabView } from 'selectors'
import { exportEditorialPlansStart } from 'actions/themax'

const mapStateToProps = state => ({
  excelExportRequestRunning: getThemaxExcelExportRequestRunning(state),
  hasPaidVersion: getHasThemaxPaidVersion(state),
  plansCount: getThemaxData(state).getIn(['editorialPlans', 'total']),
  view: getThemaxTabView(state)
})

export default connect(mapStateToProps, {
  onClick: exportEditorialPlansStart
})(ExcelDownloadButton)
