/* global document, window, MutationObserver */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment-timezone'
import { useTheme } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'

import { Box, Grid, useMediaQuery } from '@mui/material'

import Sidebar from 'containers/themax/calendar/Sidebar'
import EventContent from 'containers/themax/calendar/EventContent'
import NrxProgressBar from 'components/nrx_progress_bar/NrxProgressBar'

const useStyles = makeStyles()(theme => ({
  container: {
    marginTop: '10px'
  },
  calendar: {
    padding: '20px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 10px 10px 10px'
    },
    minHeight: 750,
    border: '1px solid #D1D5DC',
    [theme.breakpoints.down('sm')]: {
      border: '0px !important'
    },
    borderRadius: '0px 5px 5px 0px',
    [theme.breakpoints.down('md')]: {
      '& .fc-header-toolbar': {
        flexDirection: 'column !important',
        alignItems: 'start !important',
        gap: '10px'
      },
      '& .fc-toolbar-chunk': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }
    },
    '& .fc-more-popover': {
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        left: '0 !important'
      }
    },
    '& .fc-daygrid-more-link': {
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'initial !important',
        textAlign: 'center !important'
      }
    },
    '& .fc-button': {
      backgroundColor: `${theme.nrx.paper.backgroundPaperColor} !important`,
      color: `${theme.palette.text.primary} !important`,
      border: 'none',
      '&:hover': {
        backgroundColor: `${grey[300]} !important`,
        color: `${theme.palette.text.primary} !important`
      },
      '&:focus': {
        boxShadow: 'none !important'
      },
      '&:active': {
        backgroundColor: `${grey[300]} !important`,
        color: `${theme.palette.text.primary} !important`
      },
      '&[disabled]': {
        '&:hover': {
          backgroundColor: `${theme.nrx.paper.backgroundPaperColor} !important`
        }
      }
    },
    '& .fc-button-active': {
      border: 'none',
      backgroundColor: `${grey[300]} !important`,
      color: `${theme.palette.text.primary} !important`
    }
  },
  overlay: {
    zIndex: 10,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  progressBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
}))

export const getLocale = () => (moment.locale() || 'de')

const mapView = {
  dayGridMonth: 'month',
  dayGridWeek: 'week',
  dayGridDay: 'day'
}

const Calendar = ({
  editorialPlans,
  bookmarkedOnly,
  selectedDate,
  loading,
  changeDate,
  applyFilters,
  setSorting,
  setLimit,
  setPage,
  openDetailedViewDialog,
  setSelectedEditorialPlan
}) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const theme = useTheme()

  const wrapperRef = useRef(null)

  let fromDate = selectedDate.get('from')

  if (fromDate) {
    fromDate = moment(fromDate)
  } else {
    fromDate = moment()
  }

  const adjustPopover = () => {
    const popoverElement = document.querySelector('.fc-more-popover')

    if (popoverElement) {
      const view = document.querySelector('.fc-view-harness')
      const viewHeight = view.getBoundingClientRect().height

      const popoverBody = popoverElement.querySelector('.fc-popover-body')
      popoverBody.style.overflowY = 'auto'
      popoverBody.style.maxHeight = `${viewHeight - 20}px`

      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight
      const popoverRect = popoverElement.getBoundingClientRect()

      popoverElement.style.zIndex = '199'

      if (popoverRect.right > viewportWidth) {
        const overflowWidth = popoverRect.right - viewportWidth
        popoverElement.style.left = `${popoverElement.offsetLeft - overflowWidth - 60}px`
      }

      if (popoverRect.bottom > viewportHeight) {
        const overflowHeight = popoverRect.bottom - viewportHeight
        popoverElement.style.top = `${popoverElement.offsetTop - overflowHeight - 35}px`
      }
    }
  }

  useEffect(() => {
    if (!bookmarkedOnly) {
      setSorting({ sortBy: 'reach', sortOrder: 'desc', sortType: 'editorialPlansSorting' })
    }

    setLimit(100)
    setPage({ pageValue: 1, pageType: 'editorialPlansPage' })

    changeDate({
      from: fromDate.startOf('month').toDate(),
      to: fromDate.endOf('month').toDate()
    })
    applyFilters()

    const observer = new MutationObserver((mutationsList, _observer) => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          adjustPopover()
        }
      })
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => {
      setSorting({ sortBy: 'editorial_deadline', sortOrder: 'asc', sortType: 'editorialPlansSorting' })
      setLimit(30)

      if (observer) {
        observer.disconnect()
      }
    }
  }, [])

  const convertedEvents = editorialPlans.map(plan => {
    const momentObj = moment(plan.get('editorialDeadline'))

    const date = momentObj.format('YYYY-MM-DD')

    return {
      title: plan.getIn(['publication', 'name']),
      date,
      extendedProps: {
        id: plan.get('id'),
        editorialDeadline: plan.get('editorialDeadline'),
        advertisingDeadline: plan.get('advertisingDeadline'),
        printingDeadline: plan.get('printingDeadline')
      },
      color: theme.palette.primary.main
    }
  }).toJS()

  const handleEventClick = eventInfo => {
    const editorialPlanId = eventInfo.event.extendedProps.id
    const editorialPlan = editorialPlans.find(plan => plan.get('id') === editorialPlanId)

    setSelectedEditorialPlan(editorialPlan)
    openDetailedViewDialog()
  }

  const onCalendarNavClick = (calendarApi, view) => {
    let from
    let to

    if (view === 'month') {
      from = moment(calendarApi.getDate()).startOf('month').toDate()
      to = moment(calendarApi.getDate()).endOf('month').toDate()
    }

    if (view === 'week') {
      from = moment(calendarApi.getDate()).startOf('week').toDate()
      to = moment(calendarApi.getDate()).endOf('week').toDate()
    }

    if (view === 'day') {
      from = moment(calendarApi.getDate()).startOf('day').toDate()
      to = moment(calendarApi.getDate()).endOf('day').toDate()
    }

    changeDate({ from, to })
    applyFilters()
  }

  const renderLoader = () => (
    <Box className={classes.overlay}>
      <Box className={classes.progressBar}>
        <NrxProgressBar />
      </Box>
    </Box>
  )

  const renderEventContent = ({ event }) => <EventContent event={event} />

  const calendarConfig = {
    customButtons: {
      prev: {
        text: i18n.get('previous'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.prev()
          const viewType = calendarApi.view.type
          onCalendarNavClick(calendarApi, mapView[viewType])
        }
      },
      next: {
        text: i18n.get('next'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.next()
          const viewType = calendarApi.view.type
          onCalendarNavClick(calendarApi, mapView[viewType])
        }
      },
      today: {
        text: i18n.get('today'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.today()
          const viewType = calendarApi.view.type
          onCalendarNavClick(calendarApi, mapView[viewType])
        }
      },
      dayGridMonth: {
        text: i18n.get('month'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.changeView('dayGridMonth')
          onCalendarNavClick(calendarApi, 'month')
        }
      },
      dayGridWeek: {
        text: i18n.get('week'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.changeView('dayGridWeek')
          onCalendarNavClick(calendarApi, 'week')
        }
      },
      dayGridDay: {
        text: i18n.get('day'),
        click() {
          const calendarApi = wrapperRef.current.getApi()
          calendarApi.changeView('dayGridDay')
          onCalendarNavClick(calendarApi, 'day')
        }
      }
    },
    headerToolbar: useMediaQuery(theme.breakpoints.down('md')) ? {
      start: 'title prev,next',
      center: 'today dayGridMonth,dayGridWeek,dayGridDay',
      end: ''
    } : {
      start: 'prev,next today',
      center: 'title',
      end: 'dayGridMonth,dayGridWeek,dayGridDay'
    },
    titleFormat: {
      year: 'numeric',
      month: 'long'
    },
    initialDate: (() => {
      if (!fromDate.isSame(moment(), 'month')) {
        return fromDate.format('YYYY-MM-DD')
      }

      return null
    })(),
    plugins: [dayGridPlugin, interactionPlugin],
    dayMaxEventRows: useMediaQuery(theme.breakpoints.only('xs')) ? 0 : true,
    views: {
      dayGridDay: {
        dayMaxEventRows: true
      }
    },
    editable: false,
    height: '100%'
  }

  return (
    <>
      {loading && renderLoader()}
      <Grid
        container
        className={classes.container}
      >
        <Grid
          item
          xl={2}
          lg={2}
          md={4}
          sm={12}
          xs={12}
        >
          <Sidebar />
        </Grid>
        <Grid
          item
          xl={10}
          lg={10}
          md={8}
          sm={12}
          xs={12}
          className={classes.calendar}
        >
          <FullCalendar
            ref={wrapperRef}
            locales={allLocales}
            locale={getLocale()}
            events={loading ? [] : convertedEvents}
            eventContent={renderEventContent}
            eventClick={handleEventClick}
            {...calendarConfig}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Calendar

Calendar.propTypes = {
  editorialPlans: PropTypes.instanceOf(List).isRequired,
  bookmarkedOnly: PropTypes.bool.isRequired,
  selectedDate: PropTypes.instanceOf(Map).isRequired,
  loading: PropTypes.bool.isRequired,

  changeDate: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  setSorting: PropTypes.func.isRequired,
  setLimit: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  openDetailedViewDialog: PropTypes.func.isRequired,
  setSelectedEditorialPlan: PropTypes.func.isRequired
}
