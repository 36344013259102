import { connect } from 'react-redux'

import Recipients from 'components/content_desk_new/recipients'

import {
  getCDeskContentFormDataFilteredRecipients
} from 'selectors'

import {
  removeRecipient,
  fetchRecipientContactStart
} from 'actions/content_desk'

const mapStateToProps = state => ({
  recipients: getCDeskContentFormDataFilteredRecipients(state)
})

export default connect(
  mapStateToProps,
  {
    removeRecipient,
    onViewClick: ({ contactId: id }) => fetchRecipientContactStart({ id })
  }
)(Recipients)
