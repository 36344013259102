import { connect } from 'react-redux'

import ContactsItem from 'components/contact_management/contacts/contacts_item/ContactsItem'

import {
  openContactEditDialog,
  selectContact as onSelect,
  openContactViewDialog as onViewClick,
  openContactDeleteDialog as onDeleteClick,
  openDistributionListViewDialog as onListsClick
} from 'actions/contact_management'

import {
  getCmListData,
  getAllStaticCountries
} from 'selectors'

const mapStateToProps = (state, { contact }) => ({
  selected: getCmListData(state).get('contacts').map(c => c.get('id')).includes(contact.get('id')),
  countries: getAllStaticCountries(state)
})

export default connect(mapStateToProps, {
  onSelect,
  onEditClick: contact => openContactEditDialog({ isEditMode: true, contact }),
  onViewClick,
  onDeleteClick,
  onListsClick
})(ContactsItem)
