import store from '../store'

const SUPPORTED_CONTENT_LANGUAGES = ['de', 'en', 'fr', 'it', 'es', 'pt', 'zh', 'ja', 'ru']
const SUPPORTED_TEMPLATE_LANGUAGES = ['de', 'en', 'fr', 'es', 'zh', 'pt', 'ja', 'it', 'ru']

const getStaticLanguages = state => state.statics.getIn(['sorted', 'languages'])

export const getTemplateLanguageOptions = () => getStaticLanguages(store.getState())
  .filter(l => SUPPORTED_TEMPLATE_LANGUAGES.includes(l.get('iso6391')))
  .map(l => ({ key: l.get('id'), value: l.get('shortName'), label: l.get('name') }))

export const getContentLanguageOptions = () => getStaticLanguages(store.getState())
  .filter(l => SUPPORTED_CONTENT_LANGUAGES.includes(l.get('iso6391')))
  .map(l => ({ key: l.get('id'), value: l.get('iso6391'), label: l.get('name') }))
