import React from 'react'
import PropTypes from 'prop-types'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { Map } from 'immutable'

import SelectedAppBar from 'components/selected_app_bar'
import ExportMenu from 'containers/ExportMenu'
import CapSafe from 'containers/CapSafe'

import { Capabilities } from 'static/constants'

import useDevice from 'hooks/useDevice'
import useI18n from 'hooks/useI18n'

const ExportButton = CapSafe(ExportMenu, Capabilities.HAS_MEDIA_REVIEW_DISPATCH_FEATURE)

export default function SelectedNewsBar({
  count,
  onReset,
  onPinClick,
  onUnpinClick,
  capabilities,
  onInvertSelectionClick
}) {
  const device = useDevice()
  const i18n = useI18n()

  const actions = [
    {
      onClick: () => onInvertSelectionClick(),
      label: i18n.get('invert_selection'),
      color: device.get('lt-sm') ? 'secondary' : undefined,
      icon: <SwapHorizIcon />
    }
  ]

  if (capabilities.get(Capabilities.HAS_CUSTOM_TAGS)) {
    actions.push(
      {
        onClick: () => onPinClick(),
        label: i18n.get('pin'),
        color: device.get('lt-sm') ? 'primary' : undefined,
        icon: <LoyaltyIcon />
      },
      {
        onClick: () => onUnpinClick(),
        label: i18n.get('unpin'),
        color: device.get('lt-sm') ? 'secondary' : undefined,
        icon: <LoyaltyIcon />
      }
    )
  }

  actions.push(
    {
      comp: (
        <ExportButton
          color={device.get('lt-sm') ? 'default' : undefined}
          icon={device.get('lt-sm') ? undefined : null}
          iconButton={device.get('lt-sm')}
        />
      )
    }
  )

  return (
    <>
      <SelectedAppBar
        count={count}
        actions={actions}
        onReset={onReset}
      />
    </>
  )
}

SelectedNewsBar.propTypes = {
  count: PropTypes.number.isRequired,
  capabilities: PropTypes.instanceOf(Map).isRequired,

  onReset: PropTypes.func.isRequired,
  onPinClick: PropTypes.func.isRequired,
  onUnpinClick: PropTypes.func.isRequired,
  onInvertSelectionClick: PropTypes.func.isRequired
}
