import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/mailing_lists'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  running: false,
  removeActive: false,
  active: false
})

export default handleActions({
  [Actions.showMailingListDialog]: state => state.set('active', true),
  [Actions.hideMailingListDialog]: state => state.set('active', false),

  [Actions.showRemoveMailingListDialog]: state => state.set('removeActive', true),
  [Actions.hideRemoveMailingListDialog]: state => state.set('removeActive', false),

  [Actions.saveMailingListStart]: state => state.set('running', true),
  [Actions.saveMailingListSuccess]: state => state.set('running', false),
  [Actions.saveMailingListError]: state => state.set('running', false),

  [AppActions.resetState]: () => initialState
}, initialState)
