import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'
import { red, green, orange } from '@mui/material/colors'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
  Pagination,
  CircularProgress,
  Grid,
  Switch,
  FormControlLabel,
  Box,
  Collapse,
  Chip,
  Stack,
  Divider
} from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ArticleIcon from '@mui/icons-material/Article'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import RefreshIcon from '@mui/icons-material/Refresh'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import { Button, Input, IconButton, Select } from 'containers/themed'
import InfoDialog from 'containers/administration/content_desk_settings/allowed_domains/InfoDialog'
import StatusDialog from 'containers/administration/content_desk_settings/allowed_domains/StatusDialog'

import useI18n from 'hooks/useI18n'
import { validDomain } from 'utils/regex'
import { getDomainRegionOptions } from 'utils/mailgun'

const useStyles = makeStyles()({
  pagination: {
    display: 'flex',
    marginTop: '1em',
    justifyContent: 'center'
  },
  confirmIcon: {
    color: green[500]
  },
  cancelIcon: {
    color: red[500]
  },
  deleteRow: {
    backgroundColor: red[50]
  },
  verifiedIcon: {
    color: green[500],
    fontSize: 28
  },
  unverifiedIcon: {
    color: orange[500],
    fontSize: 28
  }
})

export default function AllowedDomains({
  config,
  domains,
  defaultDomains,
  formData,
  query,
  newDomain,
  newDomainRegion,
  newSenderDomain,
  deleteMode,
  total,
  page,
  loading,
  defaultRegionByTld,
  defaultDomainIds,
  onCreate,
  onCreateDefault,
  onUpdate,
  onDelete,
  onSearch,
  onRefresh,
  onQueryChange,
  onNewDomainChange,
  onNewDomainRegionChange,
  onNewSenderDomainChange,
  toggleDeleteMode,
  setPage,
  setFormData,
  openInfoDialog,
  openStatusDialog,
  fetchStatus,
  onConfigUpdate,
  onConfigChange
}) {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [openRow, setOpenRow] = useState(null)
  const allAllowedDomains = config.get('ccdAllowedDomains')
  const configDomain = config.get('domain')
  const configSenderEmail = config.get('senderEmail') || ''
  const configSenderEmailDomain = configSenderEmail.split('@')[1]
  const domainRegionOptions = getDomainRegionOptions()
  const showDefaultDomains = config.get('showDefaultDomains')
  const domainFound = allAllowedDomains.find(d => d.get('name') === configDomain)

  useEffect(() => {
    onNewDomainRegionChange(defaultRegionByTld)
    onSearch()
  }, [])

  const handleOnSearch = () => {
    setPage(1)
    onSearch()
  }

  const handleOnNewDomain = () => {
    onCreate()
  }

  const handleDeleteDomain = id => {
    toggleDeleteMode(id)
    setFormData({ id })
  }

  const handlePageChange = p => {
    setPage(p)
    onSearch()
  }

  const handleOnConfirm = () => {
    if (deleteMode) {
      onDelete()
    }
  }

  const handleOnCancel = () => {
    setFormData({ id: null, name: '' })

    if (deleteMode) {
      toggleDeleteMode()
    }
  }

  const handleVerify = (id, name, region) => {
    setFormData({ id, name, region })
    onRefresh()
  }

  const handleOpenTracking = (id, name, region, openTracking) => {
    setFormData({ id, name, region, openTracking })
    onUpdate({ updateType: 'openTracking' })
  }

  const handleClickTracking = (id, name, region, clickTracking) => {
    setFormData({ id, name, region, clickTracking })
    onUpdate({ updateType: 'clickTracking' })
  }

  const handleOpenInfoDialog = () => {
    openInfoDialog()
  }

  const handleDomainStatus = (name, region) => {
    setFormData({ name, region })
    fetchStatus()
    openStatusDialog()
  }

  const handleOnNewSenderDomain = (id, senderDomains) => {
    const newSenderDomains = [...senderDomains, newSenderDomain]
    setFormData({ id, senderDomains: newSenderDomains })
    onUpdate({ updateType: 'senderDomain' })
  }

  const handleDeleteSenderDomain = (id, senderDomains, senderDomain) => {
    const newSenderDomains = senderDomains.filter(sd => sd !== senderDomain)
    setFormData({ id, senderDomains: newSenderDomains })
    onUpdate({ updateType: 'senderDomain' })
  }

  const handleDefaultDomains = () => {
    if (showDefaultDomains) {
      onConfigChange({ showDefaultDomains: false })
      onConfigUpdate()
    } else {
      onConfigChange({ showDefaultDomains: true })
      onConfigUpdate()

      if (defaultDomainIds.size === 0) {
        onCreateDefault()
      }
    }
  }

  const isDomainActionTaken = id => formData.get('id') === id && (deleteMode)
  const isValidNewName = () => validDomain(newDomain) && !defaultDomains.some(d => d.get('domain') === newDomain)
  const isValidNewSenderName = () => validDomain(newSenderDomain) && !defaultDomains.some(d => d.get('domain') === newSenderDomain)
  const isDeleteDisabled = name => loading || deleteMode || name === configDomain
  const isSenderDomainDeleteDisabled = (senderDomain, name, senderDomains) => loading
    || deleteMode
    || (senderDomains.size === 1 && name === configDomain)
    || (name === configDomain && senderDomain === configSenderEmailDomain)
  const isDefaultDomain = id => defaultDomainIds.includes(id)
  const isExistingDomain = () => allAllowedDomains.some(d => d.get('name') === newDomain && d.get('region') === newDomainRegion)
  const isExistingSenderDomain = senderDomains => senderDomains.includes(newSenderDomain)

  const renderStatus = status => {
    if (status) {
      return (
        <CheckCircleOutlineIcon
          className={classes.verifiedIcon}
          fontSize="medium"
        />
      )
    }

    return (
      <WarningAmberOutlinedIcon
        className={classes.unverifiedIcon}
        fontSize="medium"
      />
    )
  }

  const renderDomains = () => (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{i18n.get('domain')}</TableCell>
            <TableCell align="center">
              {i18n.get('sending')}
            </TableCell>
            <TableCell align="center">
              {i18n.get('receiving')}
            </TableCell>
            <TableCell align="center">
              {i18n.get('tracking')}
            </TableCell>
            <TableCell align="center">
              {i18n.get('region')}
            </TableCell>
            <TableCell align="center">
              {i18n.get('open_tracking')}
            </TableCell>
            <TableCell align="center">
              {i18n.get('click_tracking')}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {domains.map((domain, index) => (
            isDomainActionTaken(domain.get('id')) ? (
              <TableRow
                key={index}
                className={deleteMode ? classes.deleteRow : ''}
              >
                {deleteMode && <TableCell colSpan={7}>{domain.get('name')}</TableCell>}
                <TableCell align="right">
                  <IconButton
                    title={i18n.get('confirm')}
                    onClick={() => handleOnConfirm()}
                    disabled={loading}
                  >
                    <CheckCircleIcon className={classes.confirmIcon} />
                  </IconButton>
                  <IconButton
                    title={i18n.get('cancel')}
                    onClick={() => handleOnCancel()}
                    disabled={loading}
                  >
                    <CancelIcon className={classes.cancelIcon} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ) : (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    {domain.get('name')}
                  </TableCell>
                  <TableCell align="center">
                    {renderStatus(domain.get('sendingVerified'))}
                  </TableCell>
                  <TableCell align="center">
                    {renderStatus(domain.get('receivingVerified'))}
                  </TableCell>
                  <TableCell align="center">
                    {renderStatus(domain.get('trackingVerified'))}
                  </TableCell>
                  <TableCell align="center">
                    {domain.get('region').toUpperCase()}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={domain.get('openTracking')}
                      disabled={loading || deleteMode || !domain.get('trackingVerified') || isDefaultDomain(domain.get('id'))}
                      onChange={() => handleOpenTracking(
                        domain.get('id'),
                        domain.get('name'),
                        domain.get('region'),
                        domain.get('openTracking')
                      )}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      checked={domain.get('clickTracking')}
                      disabled={loading || deleteMode || !domain.get('trackingVerified') || isDefaultDomain(domain.get('id'))}
                      onChange={() => handleClickTracking(
                        domain.get('id'),
                        domain.get('name'),
                        domain.get('region'),
                        domain.get('clickTracking')
                      )}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      title={i18n.get('refresh_status')}
                      color="primary"
                      onClick={() => handleVerify(domain.get('id'), domain.get('name'), domain.get('region'))}
                      disabled={loading || deleteMode || isDefaultDomain(domain.get('id'))}
                    >
                      <RefreshIcon />
                    </IconButton>
                    <IconButton
                      title={i18n.get('instructions')}
                      onClick={() => handleDomainStatus(domain.get('name'), domain.get('region'))}
                      disabled={loading || deleteMode || isDefaultDomain(domain.get('id'))}
                    >
                      <ArticleIcon />
                    </IconButton>
                    <IconButton
                      title={i18n.get('delete')}
                      onClick={() => handleDeleteDomain(domain.get('id'))}
                      disabled={isDeleteDisabled(domain.get('name')) || isDefaultDomain(domain.get('id'))}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        if (openRow !== index) {
                          onNewSenderDomainChange('')
                        }

                        setOpenRow(openRow === index ? null : index)
                      }}
                    >
                      {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                  >
                    <Collapse
                      in={openRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: '10px 10px 10px 0', display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <Input
                          sx={{ width: '25%' }}
                          type="text"
                          value={newSenderDomain}
                          error={newSenderDomain !== '' && (!isValidNewSenderName() || isExistingSenderDomain(domain.get('senderDomains')))}
                          onChange={event => onNewSenderDomainChange(event.target.value)}
                          disabled={loading || deleteMode}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  startIcon={<AddIcon />}
                                  variant="contained"
                                  color="primary"
                                  loading={loading}
                                  disabled={deleteMode || !isValidNewSenderName() || isExistingSenderDomain(domain.get('senderDomains'))}
                                  onClick={() => handleOnNewSenderDomain(domain.get('id'), domain.get('senderDomains'))}
                                >
                                  {i18n.get('add')}
                                </Button>
                              </InputAdornment>
                            ),
                            placeholder: i18n.get('new_sender_domain')
                          }}
                        />
                        <Stack
                          direction="row"
                          spacing={1}
                        >
                          {domain.get('senderDomains').map((senderDomain, chipIndex) => (
                            <Chip
                              key={chipIndex}
                              label={senderDomain}
                              disabled={isSenderDomainDeleteDisabled(senderDomain, domain.get('name'), domain.get('senderDomains'))}
                              onDelete={() => handleDeleteSenderDomain(
                                domain.get('id'),
                                domain.get('senderDomains'),
                                senderDomain
                              )}
                            />
                          ))}
                        </Stack>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              alignItems: 'center'
            }
          }}
        >
          <Typography sx={{ flexGrow: 1 }}>{i18n.get('allowed_domains')}</Typography>
          <Box
            sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}
            onClick={e => e.stopPropagation()}
          >
            <FormControlLabel
              labelPlacement="start"
              label={i18n.get('ccd_allowed_domain_toggle_default')}
              control={
                (
                  <Switch
                    checked={showDefaultDomains}
                    disabled={loading || deleteMode || (domainFound && isDefaultDomain(domainFound.get('id')))}
                    onChange={() => handleDefaultDomains()}
                  />
                )
              }
            />
            <Divider
              orientation="vertical"
              flexItem
            />
            <FormControlLabel
              control={
                (
                  <IconButton
                    color="primary"
                    onClick={() => handleOpenInfoDialog()}
                    title={i18n.get('info')}
                    disabled={loading}
                  >
                    <InfoIcon />
                  </IconButton>
                )
              }
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.panelDetails }}>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Input
                fullWidth
                type="text"
                value={query}
                label={i18n.get('quick_search')}
                onChange={event => onQueryChange(event.target.value)}
                disabled={loading || deleteMode}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        title={i18n.get('search')}
                        onClick={() => handleOnSearch()}
                        disabled={loading || deleteMode}
                      >
                        {loading && (
                          <CircularProgress
                            style={{ position: 'absolute' }}
                            size={34}
                            variant="indeterminate"
                          />
                        )}
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Input
                sx={{ '& legend': { width: '0px' } }}
                fullWidth
                type="text"
                value={newDomain}
                error={newDomain !== '' && (!isValidNewName() || isExistingDomain())}
                onChange={event => onNewDomainChange(event.target.value)}
                disabled={loading || deleteMode}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Select
                        label=""
                        variant="outlined"
                        onChange={value => onNewDomainRegionChange(value.value)}
                        options={domainRegionOptions}
                        value={newDomainRegion}
                        disabled={loading || deleteMode}
                        size="small"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        startIcon={<AddIcon />}
                        variant="contained"
                        color="primary"
                        loading={loading}
                        disabled={deleteMode || !isValidNewName() || isExistingDomain()}
                        onClick={() => handleOnNewDomain()}
                      >
                        {i18n.get('add')}
                      </Button>
                    </InputAdornment>
                  ),
                  placeholder: i18n.get('new_domain')
                }}
              />
            </Grid>
          </Grid>
          <br />
          {domains.size > 0 && renderDomains()}
          {domains.size === 0 && (
            <Typography>{i18n.get('ccd_allowed_domain_none_added')}</Typography>
          )}
          {
            (total >= 5) ? (
              <Pagination
                className={classes.pagination}
                showFirstButton
                showLastButton
                count={Math.ceil(total / 5)}
                page={page}
                onChange={(_event, value) => handlePageChange(value)}
                color="primary"
                disabled={loading || deleteMode}
              />
            ) : null
          }
        </AccordionDetails>
      </Accordion>
      <InfoDialog />
      <StatusDialog />
    </>
  )
}

AllowedDomains.propTypes = {
  config: PropTypes.instanceOf(Map).isRequired,
  domains: PropTypes.instanceOf(List).isRequired,
  defaultDomains: PropTypes.instanceOf(List).isRequired,
  formData: PropTypes.instanceOf(Map).isRequired,
  query: PropTypes.string.isRequired,
  newDomain: PropTypes.string.isRequired,
  newDomainRegion: PropTypes.string.isRequired,
  newSenderDomain: PropTypes.string.isRequired,
  deleteMode: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  defaultRegionByTld: PropTypes.string.isRequired,
  defaultDomainIds: PropTypes.instanceOf(List).isRequired,

  onCreate: PropTypes.func.isRequired,
  onCreateDefault: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  onNewDomainChange: PropTypes.func.isRequired,
  onNewDomainRegionChange: PropTypes.func.isRequired,
  onNewSenderDomainChange: PropTypes.func.isRequired,
  toggleDeleteMode: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  openInfoDialog: PropTypes.func.isRequired,
  openStatusDialog: PropTypes.func.isRequired,
  fetchStatus: PropTypes.func.isRequired,
  onConfigUpdate: PropTypes.func.isRequired,
  onConfigChange: PropTypes.func.isRequired
}
