import { connect } from 'react-redux'

import ContentsFilters from 'components/content_desk_new/contents/contents_filters/'

import {
  searchContentsStart,
  setContentFilters,
  setPage
} from 'actions/content_desk'

import {
  getCDeskContentFilters,
  getCDeskSearchContentsRunning,
  getStaticCdContentTypes,
  getUserLocale
} from 'selectors'

const mapStateToProps = state => ({
  currentFilters: getCDeskContentFilters(state),
  loading: getCDeskSearchContentsRunning(state),
  contentDeskContentTypes: getStaticCdContentTypes(state),
  locale: getUserLocale(state)
})

export default connect(
  mapStateToProps,
  {
    searchContents: searchContentsStart,
    setContentFilters,
    setPage
  }
)(ContentsFilters)
