import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import decamelize from 'decamelize'
import pixelWidth from 'string-pixel-width'
import GroupIcon from '@mui/icons-material/Group'
import SearchIcon from '@mui/icons-material/Search'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import ShortTextIcon from '@mui/icons-material/ShortText'
import PersonIcon from '@mui/icons-material/Person'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import FilterListIcon from '@mui/icons-material/FilterList'
import CheckIcon from '@mui/icons-material/Check'
import LanguageIcon from '@mui/icons-material/Language'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import FlagIcon from '@mui/icons-material/Flag'
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import EditIcon from '@mui/icons-material/Edit'
import BarChartIcon from '@mui/icons-material/BarChart'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import LinkIcon from '@mui/icons-material/Link'
import WebIcon from '@mui/icons-material/Web'
import MessageIcon from '@mui/icons-material/Message'
import TitleIcon from '@mui/icons-material/Title'
import CampaignIcon from '@mui/icons-material/Campaign'
import ContactsIcon from '@mui/icons-material/Contacts'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import OutboundIcon from '@mui/icons-material/Outbound'
import NumbersIcon from '@mui/icons-material/Numbers'
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle'
import TagIcon from '@mui/icons-material/Tag'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { Input } from 'containers/themed'
import ChannelIcon from 'components/channel_icon'
import NewsguardInfo from 'containers/NewsguardInfo'

import masterConfig from 'static/master_config'
import {
  SearchFields,
  ContentDeskSearchFields,
  ContactManagementSearchFields,
  DarknetSearchFields,
  ThemaxSearchFields
} from 'static/constants'
import NetworkIcon from 'components/darknet/network_icon'

import { green, red, teal, lightBlue } from '@mui/material/colors'

import { Avatar, Chip, Icon } from '@mui/material'

const useStyles = makeStyles()({
  container: {
    position: 'relative',
    display: 'inline-block'
  },
  unavailable: {
    filter: 'grayscale(100%)',
    opacity: 0.5
  },
  activeFilter: {
    marginRight: 10,
    marginTop: 10,
    transition: 'all 1s cubic-bezier(0.38, 0.8, 0.56, 1.12)'
  },
  label: {
    userSelect: 'auto'
  },
  labelText: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  defaultLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  prefix: {
    userSelect: 'none'
  },
  invertIcon: {
    cursor: 'pointer',
    color: green[700]
  },
  editableLabel: {
    cursor: 'pointer'
  },
  inverted: {
    color: red[700],
    animation: `${keyframes`
            from {
              opacity: 0;
              transform: rotate(90deg);
            }
            to {
              opacity: 1;
            }
          `} 225ms;`
  },
  notInverted: {
    animation: `${keyframes`
            from {
              opacity: 0;
              transform: rotate(-90deg);
            }
            to {
              opacity: 1;
            }
          `} 225ms;`
  },
  editIcon: {
    cursor: 'pointer',
    width: '30px !important',
    height: '30px !important',
    marginLeft: '0 !important',
    animation: `${keyframes`
            0% {
              transform: scale(2);
            }
            100% {
              transform: scale(1);
            }
          `} 500ms;`
  },
  statementCode: {
    color: lightBlue[700]
  },
  groupedStatementCode: {
    color: lightBlue[300]
  },
  channelIcon: {
    fontSize: '13px !important',
    marginLeft: '0 !important'
  },
  editInput: {
    fontSize: 13
  },
  newsguardIcon: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 7,
    marginLeft: 9
  },
  newsOutlinkIcon: {
    color: teal[300]
  },
  outlinkIcon: {
    color: teal[500]
  },
  networkIcon: {
    width: 28,
    height: 28
  },
  networkIconTag: {
    width: 26,
    height: 26,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mediaReviewTypeIcon: {
    marginLeft: 5,
    marginBottom: 3,
    fontSize: 20
  },
  mediaReviewTypeIconContainer: {
    height: 20,
    display: 'flex !important'
  }
})

const getCountryIcon = filter => {
  const shortName = filter.get('shortName')

  if (!shortName) {
    return <FlagIcon />
  }

  return <span className={`fi fi-${shortName.toLowerCase()}`} />
}

const getTonalityIcon = filter => {
  if (filter.get('id') === 'positive') {
    return <SentimentVerySatisfiedIcon />
  }

  if (filter.get('id') === 'negative') {
    return <SentimentVeryDissatisfiedIcon />
  }

  if (filter.get('id') === 'unknown') {
    return <RemoveCircleOutlineIcon />
  }

  return <Brightness1Icon />
}

const getStatementTonalityIcon = filter => <BarChartIcon style={{ color: filter.get('color') }} />
const notInvertableFilters = [
  SearchFields.BOOLEANS,
  SearchFields.AUTHOR_QUERIES,
  SearchFields.GEO_BOUNDING_BOX,
  SearchFields.OUTLINK_URLS,
  SearchFields.OUTLINKS,
  SearchFields.SIMILARITY_CCD_CONTENTS,
  SearchFields.SIMILARITY_CCD_CAMPAIGNS,
  DarknetSearchFields.MIN_HACKISHNESS,
  ContentDeskSearchFields.CONTENT_PLAIN_TEXT,
  ContentDeskSearchFields.CONTENT_TITLE,
  ContentDeskSearchFields.CAMPAIGN_NAME,
  ContactManagementSearchFields.CONTACT_NAME,
  ContactManagementSearchFields.CONTACT_TAG,
  ContactManagementSearchFields.DISTRIBUTION_LIST_NAME,
  ContactManagementSearchFields.DISTRIBUTION_LIST_CONTACTS,
  ThemaxSearchFields.REACH_MIN,
  ThemaxSearchFields.REACH_MAX,
  ThemaxSearchFields.PAGE_AVE_MIN,
  ThemaxSearchFields.PAGE_AVE_MAX
]

const editableFilters = [
  SearchFields.NEWS_QUERIES,
  SearchFields.PUBLICATION_QUERIES,
  SearchFields.AUTHOR_QUERIES,
  DarknetSearchFields.DARKNET_SEARCH_QUERIES,
  ContentDeskSearchFields.CONTENT_PLAIN_TEXT,
  ContentDeskSearchFields.CONTENT_TITLE,
  ContentDeskSearchFields.CAMPAIGN_NAME,
  ContactManagementSearchFields.CONTACT_NAME,
  ContactManagementSearchFields.CONTACT_TAG,
  ContactManagementSearchFields.DISTRIBUTION_LIST_NAME,
  ThemaxSearchFields.TOPIC_PLAN,
  ThemaxSearchFields.PUBLICATION_NAME,
  ThemaxSearchFields.PUBLICATION_INFO
]

export default function ActiveFilter({ hidden, filter, index, i18n, device, onRemove, onInvert, onUpdate }) {
  const { classes, cx } = useStyles()
  const [queryValue, setQueryValue] = useState(filter.get('value'))
  const [editing, setEditing] = useState(false)
  const [showEditMode, setShowEditMode] = useState(false)
  const [style, setStyle] = useState((device.get('mobile') || filter.get('updated')) ? {} : { display: 'none' })
  const [containerStyle, setContainerStyle] = useState((device.get('mobile') || filter.get('updated')) ? {} : { zIndex: 5000 })
  const compEl = useRef(null)

  const getMediaReviewTypeIcon = mediaReviewTypeFilter => (
    <Icon
      classes={{ root: classes.mediaReviewTypeIconContainer }}
      id={mediaReviewTypeFilter.get('id')}
    >
      <i className={`fa fa-clipboard-check ${classes.mediaReviewTypeIcon}`} />
    </Icon>
  )

  const getChannelIcon = channelFilter => (
    <ChannelIcon
      className={classes.channelIcon}
      id={channelFilter.get('channelId') || channelFilter.get('id')}
    />
  )

  const getNewsguardIcon = newsguardFilter => (
    <div className={classes.newsguardIcon}>
      <NewsguardInfo
        rank={newsguardFilter.get('id')}
        url={newsguardFilter.get('url')}
      />
    </div>
  )

  const getDarknetNetworkIcon = network => (
    <NetworkIcon
      network={network.get('id')}
      className={classes.networkIcon}
    />
  )

  const iconConfig = {
    [SearchFields.IDENTITIES]: <PersonIcon />,
    [SearchFields.INFLUENCERS]: <PersonIcon />,
    [SearchFields.PUBLICATIONS]: masterConfig.getIn(['icons', 'publications']),
    [SearchFields.MEDIA_REVIEW_CODES]: <LocalOfferIcon />,
    [SearchFields.CUSTOM_TAGS]: <LoyaltyIcon />,
    [SearchFields.CUSTOM_TAG_GROUPS]: <CollectionsBookmarkIcon />,
    [SearchFields.LANGUAGES]: <LanguageIcon />,
    [SearchFields.COUNTRIES]: getCountryIcon,
    [SearchFields.STATES]: <OutlinedFlagIcon />,
    [SearchFields.TONALITIES]: getTonalityIcon,
    [SearchFields.SENTIMENTS]: getTonalityIcon,
    [SearchFields.CHANNELS]: getChannelIcon,
    [SearchFields.HASHTAGS]: <Icon className="fa fa-hashtag material-icons" />,
    [SearchFields.MENTIONS]: <ChatBubbleOutlineIcon />,
    [SearchFields.EMOJIS]: null,
    [SearchFields.ENTITIES]: <ShortTextIcon />,
    [SearchFields.ENTITIES_PERSON]: <ShortTextIcon />,
    [SearchFields.ENTITIES_ORGANIZATION]: <ShortTextIcon />,
    [SearchFields.ENTITIES_TOPIC]: <ShortTextIcon />,
    [SearchFields.ENTITIES_PRODUCT]: <ShortTextIcon />,
    [SearchFields.ENTITIES_LOCATION]: <ShortTextIcon />,
    [SearchFields.MEDIA_TYPES]: <Icon className="fa fa-medium" />,
    [SearchFields.MEDIA_TOPICS]: <Icon className="fa fa-align-center material-icons" />,
    [SearchFields.NEWS_QUERIES]: <SearchIcon />,
    [SearchFields.PUBLICATION_QUERIES]: <SearchIcon />,
    [SearchFields.AUTHOR_QUERIES]: <SearchIcon />,
    [SearchFields.GEO_BOUNDING_BOX]: <LocationOnIcon />,
    [SearchFields.PAGE_IDENTITIES]: getChannelIcon,
    [SearchFields.IDENTITY_SETS]: <GroupIcon />,
    [SearchFields.BOOLEANS]: <CheckIcon />,
    [SearchFields.STATEMENT_CODES]: <BarChartIcon classes={{ root: classes.statementCode }} />,
    [SearchFields.NEWS_STATEMENT_TONALITIES]: getStatementTonalityIcon,
    [SearchFields.GROUPED_STATEMENT_CODES]: <BarChartIcon classes={{ root: classes.groupedStatementCode }} />,
    [SearchFields.NEWSGUARD_RANKS]: getNewsguardIcon,
    [SearchFields.NEWSGUARD_ORIENTATIONS]: <SwapHorizIcon />,
    [SearchFields.DOMAINS]: <WebIcon classes={{ root: classes.newsOutlinkIcon }} />,
    [SearchFields.OUTLINKS]: <LinkIcon classes={{ root: classes.newsOutlinkIcon }} />,
    [SearchFields.OUTLINK_DOMAINS]: <WebIcon classes={{ root: classes.outlinkIcon }} />,
    [SearchFields.OUTLINK_URLS]: <LinkIcon classes={{ root: classes.outlinkIcon }} />,
    [SearchFields.MEDIA_REVIEW_TYPES]: getMediaReviewTypeIcon,
    [SearchFields.SIMILARITY_CCD_CONTENTS]: <OutboundIcon />,
    [SearchFields.SIMILARITY_CCD_CAMPAIGNS]: <OutboundIcon />,
    [SearchFields.INTERFACES]: <SwapVerticalCircleIcon />,
    [SearchFields.SUPPLIERS]: <LocalShippingIcon />,
    [DarknetSearchFields.NETWORKS]: getDarknetNetworkIcon,
    [ContentDeskSearchFields.CONTENT_PLAIN_TEXT]: <MessageIcon />,
    [ContentDeskSearchFields.CONTENT_TITLE]: <TitleIcon />,
    [ContentDeskSearchFields.CAMPAIGN_NAME]: <CampaignIcon />,
    [ContactManagementSearchFields.CONTACT_NAME]: <ContactsIcon />,
    [ContactManagementSearchFields.CONTACT_TAG]: <TagIcon />,
    [ContactManagementSearchFields.DISTRIBUTION_LIST_NAME]: <PeopleAltOutlinedIcon />,
    [ContactManagementSearchFields.DISTRIBUTION_LIST_CONTACTS]: <ContactsIcon />,
    [ThemaxSearchFields.REACH_MAX]: <NumbersIcon />,
    [ThemaxSearchFields.REACH_MIN]: <NumbersIcon />,
    [ThemaxSearchFields.PAGE_AVE_MAX]: <NumbersIcon />,
    [ThemaxSearchFields.PAGE_AVE_MIN]: <NumbersIcon />,
    default: <FilterListIcon />
  }

  useEffect(() => {
    if (compEl && compEl.current && !device.get('mobile') && !filter.get('updated')) {
      const { top, left } = compEl.current.getBoundingClientRect()

      setStyle({
        position: 'absolute',
        transform: `translate(${global.mouseX - left}px, ${global.mouseY - top}px)`,
        zIndex: 5000
      })

      setTimeout(() => {
        setStyle({
          transform: 'translate(0, 0)',
          zIndex: 5000
        })
      }, 100)

      setTimeout(() => {
        setStyle({})
        setContainerStyle({})
      }, 1050)
    }
  }, [])

  if (hidden) {
    return null
  }

  const saveQuery = () => {
    const newValue = (queryValue || '').trim()

    if (newValue && filter.get('value') !== newValue) {
      onUpdate({
        filter: filter.merge({
          value: newValue,
          label: newValue
        }),
        index
      })
    }

    setEditing(false)
  }

  const onQueryChange = event => {
    setQueryValue(event.target.value)
  }

  const isChannelFilter = [SearchFields.CHANNELS, SearchFields.PAGE_IDENTITIES].indexOf(filter.get('field')) !== -1
  const isInvertable = notInvertableFilters.indexOf(filter.get('field')) === -1 && filter.get('id') !== -1
  const isEditable = editableFilters.indexOf(filter.get('field')) !== -1

  let icon = iconConfig[filter.get('field')]

  if (icon === undefined) {
    icon = iconConfig.default
  } else if (typeof icon === 'function') {
    icon = icon(filter)
  }

  let avatar = <Avatar src={filter.get('photo')} />

  if (showEditMode || editing) {
    avatar = (
      <Avatar
        title={i18n.get(editing ? 'save' : 'edit')}
        classes={{ root: classes.editIcon }}
        onClick={() => (editing ? saveQuery() : setEditing(true))}
      >
        {editing ? <CheckIcon /> : <EditIcon />}
      </Avatar>
    )
    icon = null
  } else if (isChannelFilter || !filter.get('photo') || filter.get('field') === SearchFields.NEWSGUARD_RANKS) {
    avatar = null
  } else {
    icon = null
  }

  let invertIcon

  if (isInvertable) {
    let IconComp = AddIcon

    if (filter.get('inverted')) {
      IconComp = RemoveIcon
    }

    invertIcon = (
      <IconComp
        title={i18n.get('invert')}
        classes={{
          root: cx(classes.invertIcon, filter.get('inverted') ? classes.inverted : classes.notInverted)
        }}
        onClick={() => onInvert({ field: filter.get('field'), index })}
      />
    )
  }

  let onDelete = () => onRemove({ field: filter.get('field'), index })
  let className = classes.container
  let title = i18n.get(decamelize(filter.get('title') || filter.get('field')))

  if (filter.get('locked')) {
    onDelete = undefined
    className = `${classes.container} ${classes.unavailable}`
    title = i18n.get('restricted_filter')
  }

  let onMouseMove
  let onMouseLeave
  let onKeyUp

  if (isEditable) {
    onMouseMove = () => setShowEditMode(true)
    onMouseLeave = () => setShowEditMode(false)
    onKeyUp = event => {
      if (event.key === 'Escape') {
        setEditing(false)
        setQueryValue(filter.get('value'))
      } else if (event.key === 'Enter') {
        saveQuery()
      }
    }
  }

  const labelText = filter.get('label') || i18n.get(decamelize(filter.get('name'))) || ''

  let label = (
    <>
      {invertIcon}

      {filter.get('prefix') && (
        <span
          onClick={isEditable ? () => setEditing(true) : undefined}
          className={cx(classes.prefix, isEditable && classes.editableLabel)}
        >
          {filter.get('prefix')}:&nbsp;
        </span>
      )}

      <span
        onClick={isEditable ? () => setEditing(true) : undefined}
        className={cx(classes.labelText, isEditable && classes.editableLabel)}
        title={labelText}
      >
        {labelText}
      </span>
    </>
  )

  if (editing) {
    let inputWidth = pixelWidth(queryValue, { size: 13 })
    inputWidth += 20

    if (inputWidth < 200) {
      inputWidth = 200
    }

    if (inputWidth > 500) {
      inputWidth = 500
    }

    label = (
      <Input
        autoFocus
        style={{ width: inputWidth }}
        value={queryValue}
        onChange={onQueryChange}
        variant="standard"
        InputProps={{
          classes: { root: classes.editInput }
        }}
      />
    )
  }

  return (
    <div
      ref={compEl}
      style={containerStyle}
      className={className}
    >
      <div
        className={classes.activeFilter}
        style={style}
      >
        <Chip
          classes={{
            label: cx(
              (!isInvertable || editing) ? null : classes.label,
              classes.defaultLabel,
              isChannelFilter ? classes.channelLabel : null
            ),
            root: (!isInvertable || editing) ? null : classes.label
          }}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
          onKeyUp={onKeyUp}
          avatar={avatar}
          icon={icon}
          onDelete={editing ? null : onDelete}
          title={title}
          label={label}
        />
      </div>
    </div>
  )
}

ActiveFilter.defaultProps = {
  hidden: false
}

ActiveFilter.propTypes = {
  hidden: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  i18n: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,

  onRemove: PropTypes.func.isRequired,
  onInvert: PropTypes.func,
  onUpdate: PropTypes.func.isRequired
}
