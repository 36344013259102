import { connect } from 'react-redux'

import Sorting from 'components/darknet/sorting'

import { getDarknetSortBy, getDarknetSortDirection, getI18n, getDarknetResults } from 'selectors'
import { onSortDirectionChange, onSortByChange } from 'actions/darknet'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  sortBy: getDarknetSortBy(state),
  sortDirection: getDarknetSortDirection(state),
  noHits: getDarknetResults(state).isEmpty()
})

export default connect(mapStateToProps, {
  onSortByChange,
  onSortDirectionChange
})(Sorting)
