const action = fun => {
  try {
    return fun()
  } catch(e) { // eslint-disable-line
    return null
  }
}
export const setItem = (key, value) => action(() => global.localStorage.setItem(key, value))
export const getItem = key => action(() => global.localStorage.getItem(key))
export const removeItem = key => action(() => global.localStorage.removeItem(key))
export const clear = () => action(() => global.localStorage.clear())
