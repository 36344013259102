import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { makeStyles } from 'tss-react/mui'
import { Button, IconButton } from 'containers/themed'

import WithI18n from 'containers/WithI18n'
import WithDevice from 'containers/WithDevice'
import { Paper } from '@mui/material'

const useStyles = makeStyles()({
  selected: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    width: '100%',
    height: 0,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 0,
    opacity: 0,
    transition: 'all 500ms',
    borderRadius: 0
  },
  active: {
    height: 65,
    zIndex: 300,
    opacity: 1
  },
  selectedText: {
    marginLeft: 20
  },
  spacer: {
    flex: 1
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5
    }
  },
  actions: {
    '& > *': {
      marginRight: 10
    }
  }
})

const SelectedAppBar = ({ count, actions, onReset, i18n, device }) => {
  const { classes } = useStyles()
  let className = classes.selected

  if (count) {
    className = `${className} ${classes.active}`
  }

  return (
    <Paper
      classes={{ root: className }}
      elevation={0}
    >
      <IconButton
        onClick={() => onReset()}
        size="large"
      >
        <KeyboardBackspaceIcon />
      </IconButton>

      <div className={classes.selectedText}>
        {count} {i18n.get('selected')}
      </div>

      <div className={classes.spacer} />

      <div className={classes.actions}>
        {actions.map((action, index) => {
          if (action.comp) {
            return React.cloneElement(
              action.comp,
              {
                key: index,
                onClick: action.onClick
              }
            )
          }

          if (device.get('lt-sm')) {
            const { icon, label, ...iconButtonProps } = action

            return (
              <IconButton
                key={label}
                {...iconButtonProps}
                size="large"
              >
                {icon}
              </IconButton>
            )
          }

          return (
            <Button
              key={action.label}
              {...action}
            >
              {action.label}
            </Button>
          )
        })}
      </div>
    </Paper>
  )
}

SelectedAppBar.propTypes = {
  count: PropTypes.number.isRequired,
  i18n: PropTypes.object.isRequired,
  actions: PropTypes.array.isRequired,
  device: PropTypes.instanceOf(Map).isRequired,

  onReset: PropTypes.func.isRequired
}

export default WithDevice()(WithI18n()(SelectedAppBar))
