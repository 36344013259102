import { connect } from 'react-redux'

import AvgHackishnessNetworksChart from 'components/darknet/static_charts/avg_hackishness_networks_chart'

import { getDarknetCharts } from 'selectors'
import { DarknetCharts, getChartById } from 'utils/darknet'
import { chartDrilldown } from 'actions/darknet'

const mapStateToProps = state => ({
  chart: getChartById(getDarknetCharts(state), DarknetCharts.HACKISHNESS_NETWORKS)
})

export default connect(mapStateToProps, {
  drilldown: chartDrilldown
})(AvgHackishnessNetworksChart)
