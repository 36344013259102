import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

export const initialState = null

export default handleActions({
  [Actions.setPrevious]: (state, { payload: dashboard }) => fromJS(dashboard),
  [AppActions.resetState]: () => initialState
}, initialState)
