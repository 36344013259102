import { connect } from 'react-redux'

import Article from 'components/anewstip/results/article'

import {
  openDrillDownDialog,
  setDrillDownEntity,
  drillDownRequestStart as onDrillDownClick
} from 'actions/anewstip'

const mapStateToProps = (_state, { article, hideButton, drillDown, isDistListsLocked, onAddToDistributionListClick }) => ({
  article,
  hideButton,
  drillDown,
  isDistListsLocked,
  onAddToDistributionListClick
})

export default connect(
  mapStateToProps,
  {
    openDrillDownDialog,
    setDrillDownEntity,
    onDrillDownClick
  }
)(Article)
