import React from 'react'
import PropTypes from 'prop-types'
import SelectedDateRange from 'containers/news_page/SelectedDateRange'
import { Button, IconButton } from 'containers/themed'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
  iconButton: {
    [theme.breakpoints.only('sm')]: {
      padding: 8
    }
  }
}))

export default function DateFilterButton({ whitelabelTheme, active, device, onClick }) {
  const { classes } = useStyles()
  const color = whitelabelTheme === 'plotlights' ? 'primary' : 'secondary'
  const renderDateButton = () => {
    let comp = (
      <div>

        <Button
          className={classes.dateButton}
          variant="contained"
          color={color}
          onClick={onClick}
          startIcon={<ScheduleIcon />}
        >
          <SelectedDateRange />
        </Button>
      </div>
    )

    if (device.get('lt-md')) {
      comp = (
        <IconButton
          classes={{ root: classes.iconButton }}
          color={active ? color : 'inherit'}
          onClick={onClick}
          size="large"
        >
          <ScheduleIcon />
        </IconButton>
      )
    }

    return comp
  }

  return (<>{renderDateButton()}</>)
}

DateFilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  whitelabelTheme: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  device: PropTypes.instanceOf(Map)
}
