import React from 'react'
import { PropTypes } from 'prop-types'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

const BASE_URL = 'https://static.pressrelations.de/pics/groot/plotlights/network_icons/'

export default function NetworkIcon({ network, className }) {
  let icon = network.toLowerCase()

  if (icon.includes('onion')) {
    icon = 'onion'
  }

  if (icon === 'unclassified') {
    return <MoreHorizIcon className={className} />
  }

  return (
    <img
      className={className}
      alt="network-logo"
      src={`${BASE_URL}${icon}.png`}
    />
  )
}

NetworkIcon.propTypes = {
  network: PropTypes.string.isRequired,
  className: PropTypes.any.isRequired
}
