import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'

import { makeStyles } from 'tss-react/mui'

import { Select, MenuItem, InputAdornment, Typography } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const useStyles = makeStyles()(theme => ({
  select: {
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0
    }
  },
  mediaTypeLabel: {
    fontSize: '18px',
    color: theme.palette.grey[900]
  }
}))

const SelectMedia = ({ mediaType, onChange }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const mediaTypeOptions = [
    { value: 'all', label: i18n.get('all') },
    { value: 'image', label: i18n.get('image') },
    { value: 'document', label: i18n.get('document') }
  ]

  return (
    <Select
      className={classes.select}
      variant="outlined"
      IconComponent={ExpandMoreIcon}
      onChange={e => onChange(e.target.value)}
      value={mediaType}
      startAdornment={(
        <InputAdornment position="start">
          <Typography className={classes.mediaTypeLabel}>
            {i18n.get('media_type')}:
          </Typography>
        </InputAdornment>
      )}
      inputProps={{
        sx: {
          fontWeight: '500'
        }
      }}
    >
      {mediaTypeOptions.map(({ value, label }) => (
        <MenuItem
          key={value}
          value={value}
        >
          {label}
        </MenuItem>
      ))}
    </Select>
  )
}

SelectMedia.propTypes = {
  mediaType: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired
}

export default SelectMedia
