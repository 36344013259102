import React from 'react'
import { makeStyles } from 'tss-react/mui'

import { StaggeredList, StaggeredListItem } from 'components/staggered_list'

import SavedSearchesActions from 'containers/themax/saved_searches/SavedSearchesActions'

const useStyles = makeStyles()({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tagsIcon: {
    marginRight: '0.3em'
  }
})

export default function Actions() {
  const { classes } = useStyles()

  const actions = []

  actions.push(
    <SavedSearchesActions />
  )

  return (
    <div className={classes.actionsWrapper}>
      <StaggeredList>
        <div className={classes.actionsWrapper}>
          {actions.map((action, index) => (
            <StaggeredListItem
              key={index}
              delay={index * 50}
            >
              {action}
            </StaggeredListItem>
          ))}
        </div>
      </StaggeredList>
    </div>
  )
}
