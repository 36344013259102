import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/identity_sets'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  name: ''
})

export default handleActions({
  [Actions.showIdentitySetDialog]: (state, { payload: identitySet }) => identitySet || initialState,

  [Actions.showDeleteIdentitySetDialog]: (state, { payload: identitySet }) => identitySet,

  [Actions.setSelectedIdentitySet]: (state, { payload: identitySet }) => identitySet,

  [AppActions.resetState]: () => initialState
}, initialState)
