import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/custom_tags'
import * as AppActions from 'actions/app'

export const initialState = fromJS([])

export default handleActions({
  [Actions.setCustomTags]: (state, { payload: { customTagGroups } }) => fromJS(customTagGroups),

  [Actions.saveGroupSuccess]: (state, { payload }) => {
    const group = fromJS(payload)
    const foundIndex = state.findIndex(g => g.get('id') === group.get('id'))

    if (foundIndex !== -1) {
      return state.set(foundIndex, group)
    }

    return state.push(group)
  },
  [Actions.deleteGroupSuccess]: (state, { payload: group }) => state.filter(g => g.get('id') !== group.get('id')),

  [Actions.assignCustomTagToGroupSuccess]: (state, { payload: { groupId, result } }) => state.map(g => {
    if (g.get('id') === groupId) {
      return g.update('customTagsCustomTagGroups', c => c.push(fromJS(result)))
    }

    return g
  }),

  [Actions.removeCustomTagFromGroupSuccess]: (state, { payload: { groupId, id, _customTagId } }) => state.map(g => {
    if (g.get('id') === groupId) {
      return g.update('customTagsCustomTagGroups', c => c.filterNot(cc => cc.get('id') === id))
    }

    return g
  }),

  [Actions.saveCustomTagSuccess]: (state, { payload: { customTag } }) => {
    const newCustomTag = fromJS(customTag)

    return state.map(g => g.update('customTagsCustomTagGroups', c => {
      const customTagIds = c.map(cc => cc.get('customTagId'))
      let customTagsCustomTagGroups = c

      if (newCustomTag.get('customTagGroupIds').includes(g.get('id')) && !customTagIds.includes(newCustomTag.get('id'))) {
        const customTagsCustomTagGroup = newCustomTag.get('customTagsCustomTagGroups')
          .find(nct => nct.get('customTagGroupId') === g.get('id'))

        customTagsCustomTagGroups = customTagsCustomTagGroups.push(customTagsCustomTagGroup)
      }

      return customTagsCustomTagGroups.filter(
        cc => cc.get('customTagId') !== newCustomTag.get('id') || newCustomTag.get('customTagGroupIds').includes(cc.get('customTagGroupId'))
      )
    }))
  },

  [AppActions.resetState]: () => initialState
}, initialState)
