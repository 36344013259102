import React, { useState } from 'react'
import useI18n from 'hooks/useI18n'
import PropTypes from 'prop-types'
import { TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'

import { makeStyles } from 'tss-react/mui'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import SwapVertIcon from '@mui/icons-material/SwapVert'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  tableCell: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  selected: {
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  arrowColumn: {
    width: '60px',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10
  }
}))

const defaultSortingDirections = {
  name: 'desc',
  contacts: 'desc'
}

const TagSortbar = ({ onSortBy }) => {
  const { classes } = useStyles()
  const i18n = useI18n()

  const [sortingDirections, setSortingDirections] = useState(
    defaultSortingDirections
  )
  const [selectedField, setSelectedField] = useState(null)

  const handleSortingClick = sortBy => {
    const sortOrder = sortingDirections[sortBy] !== 'desc' ? 'desc' : 'asc'

    setSortingDirections({ ...defaultSortingDirections, [sortBy]: sortOrder })
    setSelectedField(sortBy)
    onSortBy({ sortBy, sortOrder })
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }))

  const sortTableCellIcon = () => {
    if (sortingDirections[selectedField] === 'desc') {
      return (
        <IconButton>
          <ArrowDownwardIcon
            fontSize="small"
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    if (sortingDirections[selectedField] === 'asc') {
      return (
        <IconButton>
          <ArrowUpwardIcon
            fontSize="small"
            onClick={() => handleSortingClick(selectedField)}
          />
        </IconButton>
      )
    }

    return (
      <IconButton>
        <SwapVertIcon
          fontSize="small"
          onClick={() => handleSortingClick('name')}
        />
      </IconButton>
    )
  }

  return (
    <TableRow>
      <TableCell className={classes.arrowColumn}>{sortTableCellIcon()}</TableCell>
      <StyledTableCell
        className={selectedField === 'name' ? classes.selected : classes.tableCell}
        onClick={() => handleSortingClick('name')}
      >
        {i18n.get('name')}
      </StyledTableCell>
      <StyledTableCell />
      <StyledTableCell />
    </TableRow>
  )
}

TagSortbar.propTypes = {
  onSortBy: PropTypes.func.isRequired
}

export default TagSortbar
