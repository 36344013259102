import { call, put, takeEvery, takeLatest, all, select, debounce, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'

import * as Actions from 'actions/saved_dashboard'
import * as AppActions from 'actions/app'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { determineMomentLocale } from 'utils/locale'
import { uploadPhoto } from 'utils/sagas'

export function* fetchSavedDashboard({ payload: urlName }) {
  try {
    const result = yield call(Api.fetchSavedDashboard, encodeURI(urlName))

    yield put(Actions.fetchSavedDashboardSuccess(result))

    const autoReload = yield select(Selectors.getSavedDashboardAutoReload)

    if (!autoReload) {
      yield put(Actions.startAutoReload(urlName))
    }
  } catch (error) {
    yield put(Actions.fetchSavedDashboardError(error))

    if (!(error.response && error.response.statusCode === 404)) {
      yield put(AppActions.exception(error))
    }
  }
}

export function* fetchSavedDashboardInfo() {
  try {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const result = yield call(Api.fetchSavedDashboardInfo, selectedDashboard.get('id'))

    yield put(Actions.fetchSavedDashboardInfoSuccess(result))
  } catch (error) {
    yield put(Actions.fetchSavedDashboardInfoError(error))
    yield put(AppActions.exception(error))
  }
}

export function* saveSavedDashboard() {
  try {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const config = yield select(Selectors.getSavedDashboardUi)

    const uploadedHeaderLogoLeft = config.get('uploadedHeaderLogoLeft')
    const headerLogoLeftUrl = yield call(uploadPhoto, uploadedHeaderLogoLeft)

    const uploadedHeaderLogoRight = config.get('uploadedHeaderLogoRight')
    const headerLogoRightUrl = yield call(uploadPhoto, uploadedHeaderLogoRight)

    const body = {
      id: selectedDashboard.get('id'),
      url_name: config.get('urlName'),
      timezone: config.get('timezone'),
      dark_mode: config.get('darkMode'),
      auto_scroll: config.get('autoScroll'),
      hide_share_menu: config.get('hideShareMenu'),
      hide_footer: config.get('hideFooter'),
      disable_cookies: config.get('disableCookies'),
      locale: config.get('locale'),
      header_logo_left_url: headerLogoLeftUrl || config.get('headerLogoLeftUrl'),
      header_logo_left_link: config.get('headerLogoLeftLink'),
      header_logo_right_url: headerLogoRightUrl || config.get('headerLogoRightUrl'),
      header_logo_right_link: config.get('headerLogoRightLink'),
      header_title: config.get('headerTitle'),
      header_subtitle: config.get('headerSubtitle'),
      header_background_color: config.get('headerBackgroundColor')
    }

    const result = yield call(Api.createSavedDashboard, body)

    yield put(Actions.saveSavedDashboardSuccess(result))
    yield put(Actions.hideSavedDashboardDialog())
    yield put(AppActions.genericSuccessMessage())
  } catch (error) {
    yield put(Actions.saveSavedDashboardError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* deleteSavedDashboard() {
  try {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const result = yield call(Api.deleteSavedDashboard, selectedDashboard.get('id'))

    yield put(Actions.deleteSavedDashboardSuccess(result))
  } catch (error) {
    yield put(Actions.deleteSavedDashboardError(error))
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* checkUrlName() {
  try {
    const selectedDashboard = yield select(Selectors.getSelectedDashboard)
    const urlName = yield select(Selectors.getSavedDashboardUrlName)

    const body = {
      id: selectedDashboard.get('id'),
      url_name: urlName
    }

    const result = yield call(Api.checkSavedDashboardUrlName, body)

    yield put(Actions.checkUrlNameSuccess(result))
  } catch (error) {
    yield put(Actions.checkUrlNameError(error))
    yield put(AppActions.exception(error))
  }
}

export function* showSavedDashboardDialog() {
  yield put(Actions.fetchSavedDashboardInfoStart())
}

export function* startAutoReload({ payload: urlName }) {
  for (;;) {
    yield delay(300000)
    yield put(Actions.fetchSavedDashboardStart(urlName))
  }
}

export function* setSavedDashboardValue({ payload: { key } }) {
  if (key === 'urlName') {
    yield put(Actions.checkUrlNameStart())
  }
}

export function* fetchSavedDashboardSuccess({ payload: { locale, timezone } }) {
  const momentLocale = determineMomentLocale(locale, locale, timezone)

  yield call(moment.locale, momentLocale)
}

export function* watchFetchSavedDashboard() {
  yield takeLatest(Actions.fetchSavedDashboardStart, fetchSavedDashboard)
}

export function* watchFetchSavedDashboardInfo() {
  yield takeEvery(Actions.fetchSavedDashboardInfoStart, fetchSavedDashboardInfo)
}

export function* watchShowSavedDashboardDialog() {
  yield takeEvery(Actions.showSavedDashboardDialog, showSavedDashboardDialog)
}

export function* watchSaveSavedDashboard() {
  yield takeEvery(Actions.saveSavedDashboardStart, saveSavedDashboard)
}

export function* watchDeleteSavedDashboard() {
  yield takeEvery(Actions.deleteSavedDashboardStart, deleteSavedDashboard)
}

export function* watchCheckUrlName() {
  yield takeEvery(Actions.checkUrlNameStart, checkUrlName)
}

export function* watchSetSavedDashboardValue() {
  yield debounce(500, Actions.setSavedDashboardValue, setSavedDashboardValue)
}

export function* watchStartAutoReload() {
  yield takeEvery(Actions.startAutoReload, startAutoReload)
}

export function* watchFetchSavedDashboardSuccess() {
  yield takeEvery(Actions.fetchSavedDashboardSuccess, fetchSavedDashboardSuccess)
}

export default function* saga() {
  yield all([
    watchFetchSavedDashboard(),
    watchFetchSavedDashboardInfo(),
    watchShowSavedDashboardDialog(),
    watchSaveSavedDashboard(),
    watchDeleteSavedDashboard(),
    watchCheckUrlName(),
    watchSetSavedDashboardValue(),
    watchStartAutoReload(),
    watchFetchSavedDashboardSuccess()
  ])
}
