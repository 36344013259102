import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import {
  Typography,
  Link,
  Badge,
  Avatar,
  CircularProgress,
  Tooltip
} from '@mui/material'

import {
  Place as PlaceIcon,
  Insights as InsightsIcon,
  ReadMore as ReadMoreIcon,
  Info as InfoIcon
} from '@mui/icons-material'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  outletContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  outletInfoWrapper: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15px'
  },
  outletAddress: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px'
  },
  outletActions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  statsLabel: {
    background: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '0px 5px 0px 5px',
    fontSize: '10px',
    borderRadius: '5px'
  },
  twitter: {
    marginTop: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  outletNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  drillDownButton: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  avatarLoaderWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  breakPoint: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  influenceScoreWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  }
}))

const Outlet = ({ outlet, openDrillDownDialog, setDrillDownEntity, onDrillDownClick }) => {
  const entity = 'outlet'
  const { classes } = useStyles()
  const i18n = useI18n()

  const avatar = outlet.get('avatar')
  const [avatarLoaded, setAvatarLoaded] = useState(avatar === '')

  const name = outlet.get('name')
  const site = outlet.get('site')
  const twitter = outlet.getIn(['twitter', 'screenName'])
  const email = outlet.get('email')
  const city = outlet.get('city')
  const state = outlet.get('state')
  const country = outlet.get('country')
  const topics = outlet.get('topic')
  const influenceScore = outlet.get('influenceScore')
  const outletId = outlet.get('id')
  const languages = outlet.get('lang')

  const handleOnAvatarLoad = () => {
    setAvatarLoaded(true)
  }

  const handleOnAvatarError = () => {
    setAvatarLoaded(true)
  }

  const handleDrillDown = () => {
    setDrillDownEntity(entity)
    onDrillDownClick({ entity, topics, outletId, name })
    openDrillDownDialog({ entity })
  }

  const stringAvatar = () => {
    const splitName = name.split(' ')

    return {
      children: `${splitName[0][0]}${splitName[1] !== undefined ? splitName[1][0] : ''}`
    }
  }

  return (
    <div className={classes.outletContainer}>
      <div className={classes.avatarContainer}>
        {!avatarLoaded
          ? (
            <div className={classes.avatarLoaderWrapper}>
              <CircularProgress
                size={25}
                variant="indeterminate"
              />
            </div>
          ) : null}
        <Badge
          sx={{ display: avatarLoaded ? 'flex' : 'none' }}
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={(
            <Avatar
              sx={{ width: 30, height: 30, fontSize: 15 }}
              {...stringAvatar()}
            />
          )}
        >
          <Avatar
            sx={{ width: 100, height: 100 }}
            alt={name}
            src={avatar}
            onLoad={handleOnAvatarLoad}
            onError={handleOnAvatarError}
          />
        </Badge>
      </div>
      <div className={classes.outletInfoWrapper}>
        <div className={classes.outletNameWrapper}>
          <Typography variant="h6">{name}</Typography>

          <Tooltip
            placement="right-start"
            arrow
            title={i18n.get('related_information')}
          >
            <IconButton
              size="small"
              className={classes.drillDownButton}
              onClick={handleDrillDown}
            >
              <ReadMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
        <Link
          target="_blank"
          rel="noopener"
          href={site}
        >{site}
        </Link>
        <div className={classes.twitter}>
          <Typography>{twitter ? `@${twitter}` : null}</Typography>
          {twitter && email ? <Typography className={classes.breakPoint}> &#8226; </Typography> : null}
          <Typography>{email || null}</Typography>
        </div>
        {city !== '' || state !== '' || country !== ''
          ? (
            <Typography
              className={classes.outletAddress}
              mt={2}
            >
              <PlaceIcon fontSize="small" />
              {city !== '' ? `${city}, ` : null}
              {state !== '' ? `${state}, ` : null}
              {country}
            </Typography>
          ) : null}
        <Typography mt={1}>
          {topics.size > 0
            ? `${topics.size > 1 ? i18n.get('topics')
              : i18n.get('topic')}: ${topics.map(t => t).join(', ')}` : null}
        </Typography>
        <Typography>
          {languages.size > 0
            ? `${languages.size > 1 ? i18n.get('languages')
              : i18n.get('language')}: ${languages.map(l => l).join(', ')}` : null}
        </Typography>
      </div>
      <div className={classes.outletActions}>
        <div className={classes.influenceScoreWrapper}>
          <Typography className={classes.statsLabel}>
            <InsightsIcon fontSize="medium" /> {i18n.get('influence_score').toUpperCase()} - {influenceScore || 0}
          </Typography>
          <Tooltip
            title={i18n.get('anewstip_influence_score_info')}
            placement="bottom-end"
            arrow
          >
            <InfoIcon color="primary" />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

Outlet.propTypes = {
  outlet: PropTypes.instanceOf(Map).isRequired,

  openDrillDownDialog: PropTypes.func.isRequired,
  setDrillDownEntity: PropTypes.func.isRequired,
  onDrillDownClick: PropTypes.func.isRequired
}

export default Outlet
