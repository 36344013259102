import React from 'react'

import Button from './Button'

export { Button }

export const PrimaryButton = props => (
  <Button
    color="primary"
    {...props}
  />
)

export const RaisedPrimaryButton = props => (
  <Button
    variant="contained"
    color="primary"
    {...props}
  />
)

export const SecondaryButton = props => (
  <Button
    color="secondary"
    {...props}
  />
)

export const RaisedSecondaryButton = props => (
  <Button
    variant="contained"
    color="secondary"
    {...props}
  />
)
