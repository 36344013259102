import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'
import moment from 'moment-timezone'
import { makeStyles } from 'tss-react/mui'
import { red } from '@mui/material/colors'
import { capitalize } from 'utils/string'

import { Chip, TableRow, TableCell, Tooltip, Typography } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PersonIcon from '@mui/icons-material/Person'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import UnsubscribeOutlinedIcon from '@mui/icons-material/UnsubscribeOutlined'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  row: {
    backgroundColor: theme.isDark ? null : 'white',
    height: '80px'
  },
  actions: {
    display: 'flex'
  },
  personIcon: {
    color: theme.isDark ? null : '#707070'
  },
  listsChip: {
    width: '80%'
  }
}))

const ContactsItem = ({
  contact,
  selected,
  countries,
  onSelect,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onListsClick
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const country = countries.find(
    c => c.get('id') === contact.getIn(['country', 'id'])
  )
  const unsubscribed = contact.get('unsubscribed')
  const publication = contact.get('identity') ? contact.getIn(['identity', 'publication', 'name']) : contact.get('externalPublication')

  const handleViewClick = () => onViewClick(contact)
  const handleEditClick = () => onEditClick(contact)
  const handleDeleteClick = () => onDeleteClick([contact])
  const handleDistributionListsClick = () => {
    onListsClick(contact)
  }

  const renderNormalIcon = () => (selected
    ? <PersonIcon color="primary" />
    : <PersonOutlineOutlinedIcon className={classes.personIcon} />)
  const renderUnsubscribedIcon = () => (

    <Tooltip
      title={(
        <>
          <Typography>
            {`${i18n.get('title')}: ${contact.getIn(['ccdContent', 'title'])}`}
          </Typography>
          <Typography>
            {`${i18n.get('released')}: ${moment(contact.getIn(['ccdContent', 'releasedAt'])).format('lll')}`}
          </Typography>
        </>
      )}
      placement="bottom-end"
      arrow
    >
      <UnsubscribeOutlinedIcon sx={{ color: red[500] }} />

    </Tooltip>
  )

  const supplier = !contact.get('supplier') || contact.get('supplier') === 'client' ? i18n.get('my_contact') : 'pressrelations'
  const supplierChipColor = !contact.get('supplier') || contact.get('supplier') === 'client' ? 'default' : 'primary'

  return (
    <TableRow className={classes.row}>
      <TableCell align="center">
        {!unsubscribed ? renderNormalIcon() : renderUnsubscribedIcon()}
      </TableCell>
      <TableCell>{contact.get('firstName') || '-'}</TableCell>
      <TableCell>{contact.get('lastName')}</TableCell>
      <TableCell>{contact.get('mail')}</TableCell>
      <TableCell>{country ? country.get('name') : '-'}</TableCell>
      <TableCell sx={{ width: '550px' }}>{publication || '-'}</TableCell>
      <TableCell>
        <Chip
          color={supplierChipColor}
          label={supplier}
        />
      </TableCell>
      <TableCell>
        {!contact.get('distributionLists').isEmpty() ? (
          <Chip
            className={classes.listsChip}
            label={contact.get('distributionLists').size}
            onClick={handleDistributionListsClick}
          />
        ) : null}

      </TableCell>
      <TableCell>
        <div className={classes.actions}>
          <IconButton
            size="small"
            onClick={handleViewClick}
            title={i18n.get('view')}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton
            disabled={unsubscribed}
            size="small"
            onClick={handleEditClick}
            title={i18n.get('edit')}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleDeleteClick}
            title={i18n.get('delete')}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      </TableCell>
      <TableCell>
        <IconButton
          disabled={unsubscribed}
          size="small"
          onClick={() => onSelect(contact)}
          title={selected ? i18n.get('in_cart') : capitalize(i18n.get('select'))}
        >
          {selected ? <CheckIcon color="primary" /> : <AddIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

ContactsItem.propTypes = {
  contact: PropTypes.instanceOf(ImmutableMap).isRequired,
  selected: PropTypes.bool.isRequired,
  countries: PropTypes.instanceOf(ImmutableList).isRequired,
  onSelect: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onViewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onListsClick: PropTypes.func.isRequired
}

export default ContactsItem
