import { connect } from 'react-redux'

import Templates from 'components/content_desk_new/contents/content_edit_dialog/templates'

import {
  fetchReleasedTemplatesStart as onMount,
  resetTemplatesModule as onUnmount,
  fetchMoreTemplatesStart as onLoadMore
} from 'actions/email_templates_library'

import {
  openTemplateConfirmDialog as openConfirmDialog,
  openTemplatePreviewDialog as openPreviewDialog,
  changeContentEditTabView as onNavigationChange,
  updateContent as onChange,
  emailEditorForceLoadDesignStart as forceLoadDesign
} from 'actions/content_desk'

import {
  getCDeskContentFormData,
  getETLibraryFetchAllRequestRunning,
  getETLibraryFetchRequestRunning,
  getETLibraryPublicTemplates,
  getETLibraryPrivateTemplates,
  getCapabilities
} from 'selectors'

const mapStateToProps = state => ({
  content: getCDeskContentFormData(state),
  loading: getETLibraryFetchAllRequestRunning(state),
  singleRequestRunning: getETLibraryFetchRequestRunning(state),
  capabilities: getCapabilities(state),
  publicTemplates: getETLibraryPublicTemplates(state).get('items'),
  hasMorePublicTemplates: getETLibraryPublicTemplates(state).get('hasMore'),
  privateTemplates: getETLibraryPrivateTemplates(state).get('items'),
  hasMorePrivateTemplates: getETLibraryPrivateTemplates(state).get('hasMore')
})

export default connect(
  mapStateToProps,
  {
    onMount,
    onUnmount,
    openConfirmDialog,
    openPreviewDialog,
    onNavigationChange,
    onChange,
    onLoadMore,
    forceLoadDesign
  }
)(Templates)
