import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/anewstip'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  topics: [],
  name: '',
  identityId: null,
  personId: null,
  topicIds: [],
  drillDownEntityId: null,
  outletId: null,
  journalists: {
    pagination: {
      page: 1,
      pageSize: 10
    }
  },
  outlets: {
    pagination: {
      page: 1,
      pageSize: 10
    }
  },
  articles: {
    pagination: {
      cursor: '',
      pageSize: 10
    },
    sortBy: ['-publish_date']
  }
})

export default handleActions({
  [Actions.setDrillDownFiltersState]: (_state, { payload: filters }) => filters,
  [Actions.setDrillDownFiltersForm]: (state, { payload: { key, value } }) => state.set(key, value),
  [Actions.toggleDrillDownTopic]: (state, { payload: { name } }) => {
    const topics = state.get('topics').map(topic => {
      if (topic.get('name') === name) {
        return fromJS({ name, active: !topic.get('active') })
      }

      return topic
    })

    return state.set('topics', topics)
  },
  [Actions.drillDownRequestStart]: (state, { payload }) => {
    let newState = state.set('topics', payload.topics.map(topic => (fromJS({ name: topic, active: true }))))

    if (payload.entity === 'journalist') {
      const { contactId, identityId, name, personId, topicIds } = payload
      newState = newState.merge({
        contactId,
        identityId,
        name,
        personId,
        topicIds
      })
    }

    if (payload.entity === 'outlet') {
      newState = newState.set('outletId', payload.outletId).set('name', payload.name)
    }

    return newState
  },
  [combineActions(
    Actions.closeDrillDownDialog,
    Actions.clearModuleData
  )]: () => initialState,
  [AppActions.resetState]: () => initialState
}, initialState)
