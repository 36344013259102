/* eslint-disable max-len */
import { createImmutableSelector } from 'utils/reselect'
import { List as ImmutableList, Map as ImmutableMap } from 'immutable'

// DATA
export const getCmData = state => state.contactManagement.get('data')
export const getCmFormData = state => getCmData(state).get('contactFormData')
export const getCmContacts = state => getCmData(state).get('contacts')
export const getCmContactsToDelete = state => getCmData(state).get('contactsToDelete')
export const getCmDistributionLists = state => getCmData(state).get('distributionLists')
export const getCmListData = state => getCmData(state).get('listData')
export const getCmSelectedContact = state => getCmContacts(state).find(c => c.get('id') === getCmData(state).get('selectedContactId'), null, ImmutableMap())
export const getCmContactsTotal = state => getCmData(state).get('contactsTotal')
export const getCmListsTotal = state => getCmData(state).get('distributionListsTotal')
export const getCmUploadResult = state => getCmData(state).get('uploadResult')
export const getCmSelectedLists = state => getCmData(state).get('selectedLists')
export const getCmDistributionListsSearch = state => getCmData(state).get('distributionListsSearch')

// TAGS
export const getCmTags = state => getCmData(state).get('tags')
export const getCmHasTags = state => !getCmTags(state).isEmpty()

// UI
export const getCmUi = state => state.contactManagement.get('ui')
export const getCmSortBy = state => getCmUi(state).get('sortBy')
export const getCmSortOrder = state => getCmUi(state).get('sortOrder')

export const getCmListsSortBy = state => getCmUi(state).get('listSortBy')
export const getCmListsSortOrder = state => getCmUi(state).get('listSortOrder')

export const getCmTagsSortBy = state => getCmUi(state).get('tagsSortBy')
export const getCmTagsSortOrder = state => getCmUi(state).get('tagsSortOrder')

export const getCmAutoCreateDistributionList = state => getCmUi(state).get('autoCreateDistributionList')
export const getCmAutoAddToExistingList = state => getCmUi(state).get('autoAddToExistingList')
// Dialogs
export const getCmContactEditDialogOpen = state => getCmUi(state).get('contactEditDialogOpen')
export const getCmContactDialogOpen = state => getCmUi(state).get('contactDialogOpen')
export const getCmContactDeleteDialogOpen = state => getCmUi(state).get('contactDeleteDialogOpen')
export const getCmTagsDialogOpen = state => getCmUi(state).get('tagsDialogOpen')
export const getCmTagRenameDialogOpen = state => getCmUi(state).get('tagRenameDialogOpen')
export const getCmTagDeleteDialogOpen = state => getCmUi(state).get('tagDeleteDialogOpen')
export const getCmDistributionListCreateDialogOpen = state => getCmUi(state).get('distributionListCreateDialogOpen')
export const getCmDistributionListEditDialogOpen = state => getCmUi(state).get('distributionListEditDialogOpen')
export const getCmDistributionListViewDialogOpen = state => getCmUi(state).get('distributionListViewDialogOpen')
export const getCmContactsUploadDialogOpen = state => getCmUi(state).get('contactsUploadDialogOpen')
export const getCmImportHelpDialogOpen = state => getCmUi(state).get('importHelpDialogOpen')
export const getCmEmailExistingDialogOpen = state => getCmUi(state).get('emailExistingDialogOpen')
export const getCmContactDialogsOpen = state => getCmContactEditDialogOpen(state) || getCmDistributionListEditDialogOpen(state) || getCmContactDialogOpen(state) || getCmDistributionListCreateDialogOpen(state)
export const getCmUploadResultDialogOpen = state => getCmUi(state).get('uploadResultDialogOpen')
export const getCmDistributionListMergeDialogOpen = state => getCmUi(state).get('distributionListMergeDialogOpen')
export const getCmContactEditDialogMode = state => getCmUi(state).get('contactEditDialogMode')

// Selected
export const getCmSelectedTagType = state => getCmUi(state).get('selectedTagType')
export const getCmSelectedQueryType = state => getCmUi(state).get('selectedQueryType')

// Running
export const getCmRequestRunning = state => getCmUi(state).get('requestRunning')
export const getCmSearchRunning = state => getCmUi(state).get('searchRunning')
export const getCmContactsSearchRunning = state => getCmUi(state).get('searchContactsRunning')
export const getCmDistributionListsSearchRunning = state => getCmUi(state).get('searchDistributionListsRunning')
export const getCmDistributionListSearchRunning = state => getCmUi(state).get('searchDistributionListRunning')

export const getCmSearchBarLabel = state => getCmUi(state).get('searchBarLabel')

export const getCmUploadedPhoto = state => getCmUi(state).get('uploadedPhoto')

export const getCmFiltersCollapsed = state => getCmUi(state).get('activeFiltersCollapsed')

// FILTERS
export const getCmFilters = state => state.contactManagement.get('filter')
export const getCmActiveFilters = createImmutableSelector(
  getCmFilters,
  filters => filters.filter(value => ImmutableList.isList(value))
)
export const getCmContactSearchQueries = state => getCmFilters(state).get('contactSearchQueries')
export const getCmDistributionListSearchQueries = state => getCmFilters(state).get('distributionListSearchQueries')
export const getCmDistributionListContactsSearchQueries = state => getCmFilters(state).get('distributionListContactsSearchQueries')
export const getCmContactPage = state => getCmFilters(state).get('contactPage')
export const getCmContactLimit = state => getCmFilters(state).get('contactLimit')
export const getCmShowUnsubscribed = state => getCmFilters(state).get('showUnsubscribed')
export const getCmDistributionListPage = state => getCmFilters(state).get('distributionListPage')
export const getCmDistributionListLimit = state => getCmFilters(state).get('distributionListLimit')
export const getCmTagsPage = state => getCmFilters(state).get('tagsPage')
export const getCmTagsLimit = state => getCmFilters(state).get('tagsLimit')
