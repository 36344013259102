import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'

import masterConfig from 'static/master_config'

import { getChannelColor } from 'utils/channel'

const useStyles = makeStyles()({
  icon: {
    fontSize: '12pt',
    color: '#ffffff'
  }
})

const channelIcons = masterConfig.getIn(['icons', 'channels'])

const ChannelIcon = ({ className, id, circle, color }) => {
  const { classes, cx } = useStyles()
  const newClasses = cx(classes.stack, className)
  let channelIconClass = cx(channelIcons.get(id.toString()), 'fa-stack-1x', classes.icon)

  const style = {}

  if (color) {
    style.color = getChannelColor(id)
  }

  if (!circle) {
    channelIconClass = cx(channelIcons.get(id.toString()), classes.icon, className)

    return (
      <i
        style={style}
        className={channelIconClass}
      />
    )
  }

  return (
    <span className={`fa-stack ${newClasses}`}>
      <i
        className="fa fa-circle fa-stack-2x"
        style={style}
      />
      <i className={channelIconClass} />
    </span>
  )
}

const ChannelIconWrapper = (props, ref) => (
  <ChannelIcon
    {...props}
    innerRef={ref}
  />
)

export default forwardRef(ChannelIconWrapper)

ChannelIcon.defaultProps = {
  circle: true,
  color: true
}

ChannelIcon.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number,
  circle: PropTypes.bool,
  color: PropTypes.bool
}
