/* globals window */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Dialog,
  Paper,
  Typography,
  Zoom
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(theme => ({
  contentContainer: {
    width: '100%',
    padding: '0px 0px 20px 0px',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'auto',
      width: '100%',
      height: '100%'
    }
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  closeButton: {
    color: theme.palette.common.black
  },
  content: {
    padding: 40,
    textAlign: 'center'
  },
  message: {
    '& a': {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      textDecoration: 'underline'
    }
  }
}))

const OtsSuccessDialog = ({
  open,
  selectedContent,
  onClose
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const url = selectedContent.getIn(['otsPressRelease', 'url'])

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (open && url) {
      setTimeout(() => {
        window.open(url, '_blank', 'noopener,noreferrer')
      }, 2000)
    }
  }, [open, url])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      TransitionComponent={Zoom}
    >
      <Paper className={classes.contentContainer}>
        <Box className={classes.closeButtonContainer}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.content}>
          <Box>
            <img
              src="https://static.pressrelations.de/pics/groot/themax/success_flag.png"
              alt="newspaper"
              width="200"
            />
          </Box>
          <Typography
            variant="h5"
            gutterBottom
          >
            {i18n.get('ots_content_created_success_title')}
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: i18n.get('ots_content_created_succes_message', { url: selectedContent.getIn(['otsPressRelease', 'url']) })
            }}
          />
        </Box>
      </Paper>
    </Dialog>
  )
}

OtsSuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  selectedContent: PropTypes.instanceOf(Map).isRequired,

  onClose: PropTypes.func.isRequired
}

export default OtsSuccessDialog
