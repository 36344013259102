import { getAllStaticChannels } from 'selectors'
import store from '../store'

export const getChannelColor = id => {
  const channels = getAllStaticChannels(store.getState())

  const found = channels.find(c => c.get('id') === id)

  if (found) {
    return found.get('color')
  }

  return channels.find(c => c.get('id') === 20).get('color')
}
