import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { Map as ImmutableMap } from 'immutable'
import { Box, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { Button, RaisedPrimaryButton, IconButton } from 'containers/themed'
import CloseIcon from '@mui/icons-material/Close'

export default function TagRenameDialog({ tag, open, running, onSave, onCancel }) {
  const i18n = useI18n()
  const prev = tag.toJS()
  const [tagName, setTagName] = useState('')
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    setTagName(tag.get('name'))
  }, [tag])

  const handleClose = () => {
    setTagName(prev.name)
    onCancel()
  }
  const handleChange = e => {
    if (e.target.value.length < 3) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    setTagName(e.target.value)
  }
  const handleSave = () => {
    if (prev.name !== tagName) {
      onSave({ tagId: prev.id, name: tagName })
    } else {
      onCancel()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle
        sx={{
          paddingBottom: t => t.spacing(3),
          color: t => t.palette.grey[800]
        }}
      >
        {i18n.get('rename')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: t => !t.isDark && t.palette.grey[800]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ overflowX: 'hidden', padding: t => t.spacing(3) }}
      >
        <TextField
          value={tagName}
          helperText={i18n.get('rename')}
          variant="standard"
          onChange={handleChange}
        />
      </DialogContent>

      <DialogActions>
        <Box
          m={2}
          sx={{
            '& .MuiButtonBase-root': { marginLeft: 2 }
          }}
        >
          <Button
            onClick={handleClose}
          >
            {i18n.get('close')}
          </Button>
          <RaisedPrimaryButton
            disabled={disabled || running}
            onClick={handleSave}
            loading={running}
          >
            {i18n.get('save')}
          </RaisedPrimaryButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

TagRenameDialog.propTypes = {
  tag: PropTypes.instanceOf(ImmutableMap).isRequired,
  open: PropTypes.bool.isRequired,
  running: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}
