import React, { useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'

import {
  Paper,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  TableHead
} from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

import CollapsableRow from 'components/content_desk/contents/content_view/recipients_table/collapsable_row'

import { Select, Button, IconButton } from 'containers/themed'

import { capitalize } from 'utils/string'

const useStyles = makeStyles()({
  eventsActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  selectField: {
    width: 200
  }
})

const RecipientsTable = ({ data, loading, onEventsDownload }) => {
  const i18n = useI18n()
  const { classes } = useStyles()
  const [filterBy, setFilterBy] = useState('all')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  function filterData(events) {
    if (filterBy === 'permanently_failed') {
      return events.some(event => event.type === 'failed') && !events.some(event => event.type === 'delivered')
    }

    if (filterBy === 'temporary_failed') {
      return events.some(event => event.type === 'failed') && events.some(event => event.type === 'delivered')
    }

    return events.some(event => event.type === filterBy)
  }

  const filteredData = filterBy === 'all' ? data : data.filter(recipient => filterData(recipient.events))

  const typeFilterSettings = [
    { value: 'all', label: i18n.get('all') },
    { value: 'accepted', label: capitalize('accepted') },
    { value: 'delivered', label: capitalize('delivered') },
    { value: 'temporary_failed', label: capitalize('temporary failed') },
    { value: 'permanently_failed', label: capitalize('permanently failed') },
    { value: 'opened', label: capitalize('opened') },
    { value: 'clicked', label: capitalize('clicked') },
    { value: 'unsubscribed', label: capitalize('unsubscribed') },
    { value: 'complained', label: capitalize('complained') }
  ]

  const handleDownloadEvents = () => onEventsDownload({ events: data })

  return (
    <>
      <div className={classes.eventsActions}>
        <div className={classes.selectField}>
          <Select
            variant="outlined"
            onChange={value => setFilterBy(value.value)}
            options={typeFilterSettings}
            value={filterBy}
            label={i18n.get('filter_by_type')}
            size="small"
          />
        </div>
        {isMobile ? (
          <IconButton
            color="primary"
            onClick={handleDownloadEvents}
            loading={loading}
          >
            <CloudDownloadIcon />
          </IconButton>
        ) : (
          <Button
            color="primary"
            variant="contained"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownloadEvents}
            loading={loading}
          >
            {i18n.get('download_events')}
          </Button>
        )}
      </div>

      <TableContainer
        component={Paper}
        elevation={1}
      >
        <Table>
          {!isMobile && (
          <TableHead>
            <TableRow sx={{
              backgroundColor: t => !t.isDark && '#F0F0F0'
            }}
            >
              <TableCell>{i18n.get('first_name')}</TableCell>
              <TableCell>{i18n.get('last_name')}</TableCell>
              <TableCell>{i18n.get('email')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          )}
          <TableBody>
            {filteredData.map((recipient, index) => (
              <CollapsableRow
                key={index}
                recipient={recipient}
                isMobile={isMobile}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

RecipientsTable.propTypes = {
  data: PropTypes.object.isRequired,

  loading: PropTypes.bool.isRequired,
  onEventsDownload: PropTypes.func.isRequired
}

export default RecipientsTable
