import React from 'react'
import PropTypes from 'prop-types'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PeopleIcon from '@mui/icons-material/People'
import MessageIcon from '@mui/icons-material/Message'
import TitleIcon from '@mui/icons-material/Title'
import CampaignIcon from '@mui/icons-material/Campaign'
import ContactsIcon from '@mui/icons-material/Contacts'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import TagIcon from '@mui/icons-material/Tag'
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import FeedIcon from '@mui/icons-material/Feed'

import { makeStyles } from 'tss-react/mui'

import {
  NewsPageModules,
  ContentDeskSearchFields,
  ContactManagementSearchFields,
  ThemaxSearchFields
} from 'static/constants'

import { Menu, IconButton } from 'containers/themed'
import masterConfig from 'static/master_config'

import { MenuItem, Icon, ListItemIcon } from '@mui/material'

const useStyles = makeStyles()({
  icon: {
    color: '#ffffff'
  },
  button: {
    padding: 6
  }
})

export default function QueryTypeDropdown({ i18n, onChange, value, moduleName }) {
  const { classes } = useStyles()

  // default options for news page modules
  let queyTypeOptions = [
    <MenuItem
      key="news"
      disabled={value === 'news'}
      onClick={() => onChange(moduleName === NewsPageModules.PROFILE_MONITORING ? 'post' : 'news')}
    >
      <ListItemIcon>
        <MessageIcon />
      </ListItemIcon>

      {i18n.get(moduleName === NewsPageModules.PROFILE_MONITORING ? 'posts' : 'news')}
    </MenuItem>,
    <MenuItem
      key="author"
      disabled={value === 'author'}
      onClick={() => onChange(moduleName === NewsPageModules.PROFILE_MONITORING ? 'profile' : 'author')}
    >
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>

      {i18n.get(moduleName === NewsPageModules.PROFILE_MONITORING ? 'profiles' : 'authors')}
    </MenuItem>,
    <MenuItem
      key="publication"
      disabled={value === 'publication'}
      onClick={() => onChange('publication')}
    >
      <ListItemIcon>
        <Icon>
          {masterConfig.getIn(['icons', 'publications'])}
        </Icon>
      </ListItemIcon>

      {i18n.get('publications')}
    </MenuItem>
  ]

  if (moduleName === 'contentDesk') {
    queyTypeOptions = [
      <MenuItem
        key={ContentDeskSearchFields.CONTENT_PLAIN_TEXT}
        disabled={value === ContentDeskSearchFields.CONTENT_PLAIN_TEXT}
        onClick={() => onChange(ContentDeskSearchFields.CONTENT_PLAIN_TEXT)}
      >
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>

        {i18n.get(ContentDeskSearchFields.CONTENT_PLAIN_TEXT)}
      </MenuItem>,
      <MenuItem
        key={ContentDeskSearchFields.CONTENT_TITLE}
        disabled={value === ContentDeskSearchFields.CONTENT_TITLE}
        onClick={() => onChange(ContentDeskSearchFields.CONTENT_TITLE)}
      >
        <ListItemIcon>
          <TitleIcon />
        </ListItemIcon>

        {i18n.get(ContentDeskSearchFields.CONTENT_TITLE)}
      </MenuItem>,
      <MenuItem
        key={ContentDeskSearchFields.CAMPAIGN_NAME}
        disabled={value === ContentDeskSearchFields.CAMPAIGN_NAME}
        onClick={() => onChange(ContentDeskSearchFields.CAMPAIGN_NAME)}
      >
        <ListItemIcon>
          <CampaignIcon />
        </ListItemIcon>

        {i18n.get(ContentDeskSearchFields.CAMPAIGN_NAME)}
      </MenuItem>
    ]
  }

  if (moduleName === 'contactManagement') {
    queyTypeOptions = [
      <MenuItem
        key={ContactManagementSearchFields.CONTACT_NAME}
        disabled={value === ContactManagementSearchFields.CONTACT_NAME}
        onClick={() => onChange(ContactManagementSearchFields.CONTACT_NAME)}
      >
        <ListItemIcon>
          <ContactsIcon />
        </ListItemIcon>

        {i18n.get(ContactManagementSearchFields.CONTACT_NAME)}
      </MenuItem>,
      <MenuItem
        key={ContactManagementSearchFields.CONTACT_TAG}
        disabled={value === ContactManagementSearchFields.CONTACT_TAG}
        onClick={() => onChange(ContactManagementSearchFields.CONTACT_TAG)}
      >
        <ListItemIcon>
          <TagIcon />
        </ListItemIcon>

        {i18n.get(ContactManagementSearchFields.CONTACT_TAG)}
      </MenuItem>,
      <MenuItem
        key={ContactManagementSearchFields.DISTRIBUTION_LIST_NAME}
        disabled={value === ContactManagementSearchFields.DISTRIBUTION_LIST_NAME}
        onClick={() => onChange(ContactManagementSearchFields.DISTRIBUTION_LIST_NAME)}
      >
        <ListItemIcon>
          <PeopleAltOutlinedIcon />
        </ListItemIcon>

        {i18n.get(ContactManagementSearchFields.DISTRIBUTION_LIST_NAME)}
      </MenuItem>
    ]
  }

  if (moduleName === 'themax') {
    queyTypeOptions = [
      <MenuItem
        key={ThemaxSearchFields.TOPIC_PLAN}
        disabled={value === ThemaxSearchFields.TOPIC_PLAN}
        onClick={() => onChange(ThemaxSearchFields.TOPIC_PLAN)}
      >
        <ListItemIcon>
          <StickyNote2Icon />
        </ListItemIcon>

        {i18n.get(ThemaxSearchFields.TOPIC_PLAN)}
      </MenuItem>,
      <MenuItem
        key={ThemaxSearchFields.PUBLICATION_NAME}
        disabled={value === ThemaxSearchFields.PUBLICATION_NAME}
        onClick={() => onChange(ThemaxSearchFields.PUBLICATION_NAME)}
      >
        <ListItemIcon>
          <SwitchAccountIcon />
        </ListItemIcon>

        {i18n.get(ThemaxSearchFields.PUBLICATION_NAME)}
      </MenuItem>,
      <MenuItem
        key={ThemaxSearchFields.PUBLICATION_INFO}
        disabled={value === ThemaxSearchFields.PUBLICATION_INFO}
        onClick={() => onChange(ThemaxSearchFields.PUBLICATION_INFO)}
      >
        <ListItemIcon>
          <FeedIcon />
        </ListItemIcon>

        {i18n.get(ThemaxSearchFields.PUBLICATION_INFO)}
      </MenuItem>
    ]
  }

  return (
    <Menu
      handle={(
        <IconButton
          classes={{ root: classes.button }}
          size="large"
        >
          <KeyboardArrowDownIcon classes={{ root: classes.icon }} />
        </IconButton>
      )}
    >
      {queyTypeOptions}
    </Menu>
  )
}

QueryTypeDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,

  onChange: PropTypes.func.isRequired
}
