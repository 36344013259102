import { connect } from 'react-redux'

import GroupedNewsListItem from 'components/media_reviews/media_review_detail/grouped_news_list_item'
import { expandTopics, collapseTopics, selectNews } from 'actions/media_reviews'

import {
  getI18n,
  getDevice,
  isExpanded,
  getMediaReviewNewsByCodeId,
  getMediaReviewSelectedNews,
  isMediaReviewGroupedNewsListSelected
} from 'selectors'

const mapStateToProps = (state, { code }) => {
  const selectedNews = getMediaReviewSelectedNews(state)
  const selectedCount = selectedNews.filter(n => parseInt(n.split('_')[0], 10) === code.get('id')).size
  const newsByCodeId = getMediaReviewNewsByCodeId(state, code.get('id'))
  const newsClusterIds = newsByCodeId.map(n => n.get('clusteredNews').map(c => c.get('id')).unshift(n.get('id'))).flatten(true)

  return ({
    expanded: isExpanded(state, code.get('id')),
    i18n: getI18n(state),
    device: getDevice(state),
    newsCount: newsClusterIds.size,
    selected: isMediaReviewGroupedNewsListSelected(state, code.get('id')) || selectedNews.isEmpty(),
    selectedCount,
    indeterminate: selectedCount > 0 && selectedCount !== newsClusterIds.size,
    newsClusterIds
  })
}

export default connect(
  mapStateToProps,
  {
    onSelect: selectNews,
    expandTopics,
    collapseTopics
  }
)(GroupedNewsListItem)
