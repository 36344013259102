import { all } from 'redux-saga/effects'
import searchSaga from './search'
import importSaga from './import'
// import mainSaga from './main'
// import filterSaga from './filter'

export default function* anewstipSaga() {
  yield all([
    searchSaga(),
    importSaga()
    // mainSaga(),
    // filterSaga()
  ])
}
