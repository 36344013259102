import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { StaggeredListItem } from 'components/staggered_list'
import { FlexListItem } from 'components/flex_list'
import MediaReview from 'containers/media_reviews/MediaReview'

const useStyles = makeStyles()({
  mediaReview: {
    cursor: 'pointer'
  },
  item: {
    height: 260
  }
})

export default function MediaReviewItem({ mediaReview, index, navigate, delay, initialDelay, columns }) {
  const { classes } = useStyles()

  return (
    <FlexListItem
      columns={columns}
      className={classes.mediaReview}
      key={mediaReview.get('id')}
    >
      <StaggeredListItem
        delay={(index * delay) + initialDelay}
        onClick={() => navigate(`/app/media_reviews/${mediaReview.get('id')}`)}
      >
        <MediaReview
          className={classes.item}
          mediaReview={mediaReview}
        />
      </StaggeredListItem>
    </FlexListItem>
  )
}

MediaReviewItem.defaultProps = {
  initialDelay: 0,
  delay: 50
}

MediaReviewItem.propTypes = {
  mediaReview: PropTypes.instanceOf(Map).isRequired,
  index: PropTypes.number.isRequired,
  delay: PropTypes.number,
  initialDelay: PropTypes.number,
  columns: PropTypes.number,

  navigate: PropTypes.func.isRequired
}
