import { connect } from 'react-redux'

import OrderProgress from 'components/themax/order_progress'
import { openOrderDialog } from 'actions/themax'
import { getThemaxPaidFeatureStatus } from 'selectors'

const mapStateToProps = state => ({
  status: getThemaxPaidFeatureStatus(state)
})

export default connect(
  mapStateToProps,
  {
    onUnlockClick: openOrderDialog
  }
)(OrderProgress)
