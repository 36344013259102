import { connect } from 'react-redux'

import MediaProfiles from 'components/themax/media_profiles'
import { getThemaxData, getThemaxFetchMoreRequestRunning } from 'selectors'
import { themaxFetchMorePublicationsStart } from 'actions/themax'

const mapStateToProps = state => ({
  mediaProfiles: getThemaxData(state).getIn(['publications', 'items']),
  mediaProfilesTotal: getThemaxData(state).getIn(['publications', 'total']),
  fetchMoreRequestRunning: getThemaxFetchMoreRequestRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onScrollBottom: themaxFetchMorePublicationsStart
  }
)(MediaProfiles)
