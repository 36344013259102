/* global document */
import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import screenfull from 'screenfull'
import { useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { keyframes } from 'tss-react'

import { createLoadable } from 'utils/loadable'
import { Capabilities } from 'static/constants'

import AppBar from 'containers/AppBar'
import ContentContainer from 'components/content_container'
import CenterMessage from 'containers/CenterMessage'
import WithDevice from 'containers/WithDevice'
import WithI18n from 'containers/WithI18n'
import ErrorBoundary from 'components/error_boundary'
import TransitionComponent from 'components/transition_component'
import RestrictionHint from 'containers/RestrictionHint'
import { RaisedPrimaryButton } from 'containers/themed'
import CapSafe from 'containers/CapSafe'

import useDashboard from 'hooks/useDashboard'

const EditDialog = createLoadable(() => (
  import(/* webpackChunkName: "DashboardEditDialog" */ 'containers/dashboard/EditDialog')
))
const DeleteDialog = createLoadable(() => (
  import(/* webpackChunkName: "DashboardDeleteDialog" */ 'containers/dashboard/DeleteDialog')
))
const DrilldownDialog = createLoadable(() => (
  import(/* webpackChunkName: "DashboardDrilldownDialog" */ 'containers/dashboard/DrilldownDialog')
))
const SavedDashboardDialog = createLoadable(() => (
  import(/* webpackChunkName: "DashboardSavedDashboardDialog" */ 'containers/saved_dashboard/Dialog')
))
const DashboardCharts = createLoadable(() => (
  import(/* webpackChunkName: "DashboardCharts" */ 'containers/dashboard/DashboardCharts')
))
const Actions = createLoadable(() => (
  import(/* webpackChunkName: "DashboardActions" */ 'containers/dashboard/actions/Actions')
))
const Dropdown = createLoadable(() => (
  import(/* webpackChunkName: "DashboardDropdown" */ 'containers/dashboard/Dropdown')
))
const AiChartAnalysisDialog = createLoadable(() => (
  import(/* webpackChunkName: "DashboardAiChartAnalysisDialog" */ 'containers/charts/AiChartAnalysisDialog')
))

const resetBodyStyles = () => {
  document.body.style.removeProperty('overflow')
  document.body.style.removeProperty('width')
  document.body.style.removeProperty('height')
}

const NewDashboardButton = CapSafe(RaisedPrimaryButton, Capabilities.HAS_DASHBOARD_EDITING_FEATURE)

const useStyles = makeStyles()(theme => ({
  presentationActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    zIndex: 300,
    color: theme.palette.grey[700],
    top: -3
  },
  presentationActionsContent: {
    animation: `${keyframes`
            0% {
              opacity: 1;
              transform: scale(4) translateY(50px);
            },
            30% {
              opacity: 1;
              transform: scale(4) translateY(50px);
            },
            70% {
              opacity: 1;
              transform: scale(4) translateY(50px);
            },
            100% {
              opacity: 0.1;
            }
          `} 10s;`,
    opacity: 0.1,
    borderRadius: '50%',
    '&:hover': {
      opacity: 1
    }
  },
  presentationActionsInfo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    zIndex: 300,
    color: theme.palette.grey[700],
    top: 300,
    opacity: 0,
    fontSize: 36,
    textAlign: 'center',
    fontWeight: 500,
    animation: `${keyframes`
            0% {
              opacity: 0;
            }
            50% {
              opacity: 1;
              backgroundColor: ${theme.palette.grey[300]};
            }
            100% {
              opacity: 0;
            }
          `} 8s;`
  },
  loading: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '97%',
    width: '93%',
    [theme.breakpoints.only('xs')]: {
      height: '100%',
      width: '100%'
    }
  },
  lastEdit: {
    marginTop: '1%',
    display: 'flex',
    float: 'right'
  },
  centerMessage: {
    width: '100%',
    height: '100%'
  },
  dashboardContent: {
    overflow: 'hidden'
  },
  message: {
    animation: `${keyframes`
            0% {
              opacity: 0;
            }
            100%: {
              opacity: 1;
            }
          `} 10s;`
  }
}))

const Dashboard = ({
  autoRefresh,
  dashboardId,
  dashboardName,
  config,
  device,
  hasDashboards,
  height,
  i18n,
  onExitFullscreen,
  onDashboardChange,
  onMount,
  onUnmount,
  onNewClick,
  width,
  hide
}) => {
  const { classes } = useStyles()
  const { id: dashboardIdFromUrl } = useParams()

  useDashboard(config)

  useEffect(() => {
    if (autoRefresh && !screenfull.isFullscreen) {
      onExitFullscreen()
    }
  }, [screenfull.isFullscreen])

  useEffect(() => {
    onMount()

    return () => onUnmount()
  }, [onMount, onUnmount])

  useEffect(() => {
    onDashboardChange(dashboardIdFromUrl)
  }, [dashboardIdFromUrl])

  useEffect(() => {
    if (autoRefresh) {
      document.body.style.width = `${width}px`
      document.body.style.height = `${height}px`
      document.body.style.overflow = 'hidden'
    } else {
      resetBodyStyles()
    }

    return resetBodyStyles
  }, [autoRefresh])

  const title = useMemo(() => {
    if (device.get('lt-sm')) {
      return null
    }

    if (!dashboardId) {
      return i18n.get('dashboard')
    }

    return dashboardName
  }, [device.get('lt-sm'), dashboardId, dashboardName])

  return (
    <>
      <EditDialog />
      <DeleteDialog />
      <DrilldownDialog />
      <SavedDashboardDialog />
      <AiChartAnalysisDialog />

      {autoRefresh && (
        <div>
          <div className={classes.presentationActions}>
            <div className={classes.presentationActionsContent}>
              <Actions />
            </div>
          </div>

          <div className={classes.presentationActionsInfo}>
            {i18n.get('refresh_every_five_minutes')}
          </div>
        </div>
      )}

      <TransitionComponent type="slideFadeDown">
        {!autoRefresh ? (
          <AppBar
            title={title}
            actions={<Actions />}
          >
            <Dropdown />
          </AppBar>
        ) : null}
      </TransitionComponent>

      {!hide && (
        <ContentContainer className={classes.dashboardContent}>
          <ErrorBoundary>
            {!hasDashboards && (
              <CenterMessage
                title={i18n.get('no_dashboards_yet')}
                message={i18n.get('please_create_dashboard')}
                backgroundClass={classes.centerMessage}
                actions={[
                  <NewDashboardButton
                    key="new"
                    onClick={() => onNewClick()}
                  >
                    {i18n.get('new_dashboard')}
                  </NewDashboardButton>
                ]}
              />
            )}

            {hasDashboards && !dashboardId && (
              <div className={classes.message}>
                <CenterMessage
                  icon="arrow_upward"
                  title={i18n.get('no_dashboard_selected')}
                  message={i18n.get('please_select_dashboard')}
                  backgroundClass={classes.centerMessage}
                />
              </div>
            )}

            {dashboardId && hasDashboards && (
              <>
                <RestrictionHint />
                <DashboardCharts id={dashboardId} />
              </>
            )}
          </ErrorBoundary>
        </ContentContainer>
      )}
    </>
  )
}

Dashboard.propTypes = {
  i18n: PropTypes.object.isRequired,
  device: PropTypes.instanceOf(Map).isRequired,
  dashboardId: PropTypes.string,
  dashboardName: PropTypes.string,
  hasDashboards: PropTypes.bool.isRequired,
  autoRefresh: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  config: PropTypes.instanceOf(Map),
  hide: PropTypes.bool.isRequired,

  onDashboardChange: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onNewClick: PropTypes.func.isRequired,
  onExitFullscreen: PropTypes.func.isRequired
}

export default WithI18n()(WithDevice()(Dashboard))
