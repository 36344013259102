import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import useI18n from 'hooks/useI18n'
import moment from 'moment'

import { makeStyles } from 'tss-react/mui'
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import AlarmOffIcon from '@mui/icons-material/AlarmOff'

import { StaticDatePicker, StaticTimePicker, Button, IconButton } from 'containers/themed'

import { isEditorDesignBlank } from 'utils/content_desk'

const useStyles = makeStyles()((theme, { mobile }) => ({
  dialogPaper: {
    minWidth: mobile ? '100%' : '670px',
    maxWidth: mobile ? '100%' : '670px'
  },
  dialogTitle: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.grey[800]
  },
  dateTimePickerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: mobile ? 'column' : 'row'
  },
  dateTimePicker: {
    '& .MuiPickersToolbar-penIconButton': {
      display: 'none'
    },
    '& .MuiPickersToolbar-root': {
      alignItems: 'center'
    },
    '& .MuiPickersToolbar-content': {
      justifyContent: 'center'
    },
    '& .MuiClock-root': {
      paddingTop: '20px'
    }
  },
  publishInfo: {
    fontSize: '10px',
    fontWeight: '500',
    width: '100%',
    opacity: '0.7',
    paddingLeft: '40px'
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: !theme.isDark && theme.palette.grey[800]
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '25px'
  },
  unscheduleButton: {
    gap: '10px'
  },
  cancelSaveButtonsWrapper: {
    display: 'flex',
    gap: '10px'
  }
}))

const ScheduleDialog = ({
  open,
  content,
  locale,
  isPublishable,
  closeScheduleDialog,
  onChange,
  onSave
}) => {
  const mobile = useMediaQuery('(max-width:700px)')
  const { classes } = useStyles({ mobile })
  const i18n = useI18n()

  const status = content.get('approvalStatus')
  const notApproved = ['draft', 'review_pending', 'revisions_needed'].includes(status)
  const isMailContent = content.getIn(['type', 'id']) === 'email'
  const title = content.get('title')
  const senderMail = content.get('senderMail')
  const senderMailLocal = senderMail?.split('@')[0]
  const subject = content.get('subject')
  const recipients = content.get('recipients')
  const editorDesign = content.get('editorDesign')
  const plannedFor = content.get('plannedFor')
  const scheduledFor = content.get('scheduledFor')

  const initialDate = () => {
    if (plannedFor) {
      return moment(plannedFor)
    }

    if (scheduledFor) {
      return moment(scheduledFor)
    }

    return moment()
  }

  const [approveOnPublish, setApproveOnPublish] = useState(true)
  const [isSchedule, setIsSchedule] = useState(!!scheduledFor)
  const [date, setDate] = useState(initialDate())

  const isToday = date.isSame(moment(), 'day')
  const minTime = isToday ? moment().startOf('minute') : null

  useEffect(() => {
    if (open) {
      setIsSchedule(!!scheduledFor)
      setDate(initialDate())
    }
  }, [open, plannedFor, scheduledFor])

  const handlePlan = () => {
    onChange({ key: 'scheduledFor', value: null })
    onChange({ key: 'plannedFor', value: date.milliseconds(0) })
    onSave()
    closeScheduleDialog()
  }

  const handleSchedule = () => {
    onChange({ key: 'plannedFor', value: null })
    onChange({ key: 'scheduledFor', value: date.milliseconds(0) })

    if (approveOnPublish) {
      onChange({ key: 'approvalStatus', value: 'approved' })
    }

    onSave()
    closeScheduleDialog()
  }

  const handleUnschedule = () => {
    onChange({ key: 'plannedFor', value: null })
    onChange({ key: 'scheduledFor', value: null })
    onSave()
    closeScheduleDialog()
  }

  const handleClose = () => {
    setDate(initialDate())
    closeScheduleDialog()
  }

  const mailTooltipTitle = () => {
    if (isMailContent) {
      if (senderMailLocal === null || senderMailLocal === '') {
        return (
          <div>{i18n.get('email_no_sender_mail')}</div>
        )
      }

      if (subject === null || subject === '') {
        return (
          <div>{i18n.get('email_no_subject')}</div>
        )
      }

      if (recipients === null || recipients.isEmpty()) {
        return (
          <div>{i18n.get('email_no_recipients')}</div>
        )
      }

      if (isEditorDesignBlank(editorDesign)) {
        return (
          <div>{i18n.get('email_no_content')}</div>
        )
      }

      if (content.get('isDirty')) {
        return (
          <div>{i18n.get('email_content_dirty')}</div>
        )
      }
    }

    return ''
  }

  const renderUnscheduleButton = () => {
    if (mobile) {
      return (
        <IconButton
          color="secondary"
          title={i18n.get('remove_plan_or_schedule')}
          onClick={handleUnschedule}
        >
          <AlarmOffIcon />
        </IconButton>
      )
    }

    return (
      <Button
        className={classes.unscheduleButton}
        variant="outlined"
        color="secondary"
        onClick={handleUnschedule}
      >
        <AlarmOffIcon fontSize="small" />
        {i18n.get('remove_plan_or_schedule')}
      </Button>
    )
  }

  const renderScheduleCheckbox = () => {
    if (isMailContent) {
      return (
        <>
          <Checkbox
            checked={isSchedule}
            onChange={() => setIsSchedule(!isSchedule)}
          />
          {i18n.get('publish_automatically')}
          <Typography className={classes.publishInfo}>
            {i18n.get('publish_content_automatically_info')}
          </Typography>
        </>
      )
    }

    return null
  }

  const renderSetAsApproved = () => (
    <>
      <Checkbox
        checked={approveOnPublish}
        onChange={e => setApproveOnPublish(e.target.checked)}
      />
      {i18n.get('set_content_as_approved')}
    </>
  )

  const renderSaveButton = () => {
    if (isSchedule) {
      return (
        <Tooltip
          title={mailTooltipTitle()}
          placement="top"
          arrow
        >
          <Typography
            variant="h6"
            component="div"
          >
            <Button
              disabled={!isPublishable}
              variant="contained"
              color="primary"
              onClick={handleSchedule}
            >
              {i18n.get('schedule')}
            </Button>
          </Typography>
        </Tooltip>
      )
    }

    return (
      <Button
        disabled={title === null || title === ''}
        variant="contained"
        color="primary"
        onClick={handlePlan}
      >
        {i18n.get('plan')}
      </Button>
    )
  }

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      fullScreen={mobile}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.dialogTitle}>
        {isSchedule ? i18n.get('publish_at', { datetime: moment(date).format('lll') }) : i18n.get('plan')}
        <IconButton
          className={classes.closeButton}
          title={i18n.get('close')}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dateTimePickerWrapper}>
          <StaticDatePicker
            toolbarTitle={i18n.get('select_date')}
            className={classes.dateTimePicker}
            displayStaticWrapperAs="desktop"
            showToolbar
            disablePast
            ampm={locale === 'en-us'}
            value={date}
            onChange={v => setDate(v)}
          />
          <Divider
            orientation="vertical"
            flexItem
          />
          <StaticTimePicker
            toolbarTitle={i18n.get('select_time')}
            className={classes.dateTimePicker}
            displayStaticWrapperAs="desktop"
            showToolbar
            value={date}
            ampm={locale === 'en-us'}
            minTime={minTime}
            onChange={v => setDate(v)}
            components={{
              LeftArrowButton: Boolean,
              RightArrowButton: Boolean
            }}
          />
        </Box>
        {renderScheduleCheckbox()}
        {notApproved && isSchedule && renderSetAsApproved()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          {(plannedFor || scheduledFor) && renderUnscheduleButton()}
        </Box>
        <Box className={classes.cancelSaveButtonsWrapper}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
          >
            {i18n.get('cancel')}
          </Button>
          {renderSaveButton()}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

ScheduleDialog.propTypes = {
  content: PropTypes.instanceOf(Map).isRequired,
  isPublishable: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,

  closeScheduleDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
}

export default ScheduleDialog
