import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

export default function StaggeredList(props) {
  const { children } = props

  return (
    <Fragment {...props}>
      {children}
    </Fragment>
  )
}

StaggeredList.propTypes = {
  children: PropTypes.any.isRequired
}
