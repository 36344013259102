import { createAction } from 'redux-actions'

const defaultPrefix = 'IDENTITY_SETS/'
let prefix = 'IDENTITY_SETS/'

// data
export const setIdentitySets = createAction(`${prefix}SET_IDENTITY_SETS`)

export const saveIdentitySetStart = createAction(`${prefix}SAVE_IDENTITY_SET_START`)
export const saveIdentitySetSuccess = createAction(`${prefix}SAVE_IDENTITY_SET_SUCCESS`)
export const saveIdentitySetError = createAction(`${prefix}SAVE_IDENTITY_SET_ERROR`)

export const deleteIdentitySetStart = createAction(`${prefix}DELETE_IDENTITY_SET_START`)
export const deleteIdentitySetSuccess = createAction(`${prefix}DELETE_IDENTITY_SET_SUCCESS`)
export const deleteIdentitySetError = createAction(`${prefix}DELETE_IDENTITY_SET_ERROR`)

export const addIdentityToIdentitySetStart = createAction(`${prefix}ADD_IDENTITY_TO_IDENTITY_SET_START`)
export const addIdentityToIdentitySetSuccess = createAction(`${prefix}ADD_IDENTITY_TO_IDENTITY_SET_SUCCESS`)
export const addIdentityToIdentitySetError = createAction(`${prefix}ADD_IDENTITY_TO_IDENTITY_SET_ERROR`)

export const removeIdentityFromIdentitySetStart = createAction(`${prefix}REMOVE_IDENTITY_FROM_IDENTITY_SET_START`)
export const removeIdentityFromIdentitySetSuccess = createAction(`${prefix}REMOVE_IDENTITY_FROM_IDENTITY_SET_SUCCESS`)
export const removeIdentityFromIdentitySetError = createAction(`${prefix}REMOVE_IDENTITY_FROM_IDENTITY_SET_ERROR`)

// ui
prefix = `${defaultPrefix}UI/`
export const showIdentitySetDialog = createAction(`${prefix}SHOW_IDENTITY_SET_DIALOG`)
export const hideIdentitySetDialog = createAction(`${prefix}HIDE_IDENTITY_SET_DIALOG`)
export const showAddIdentityToIdentitySetDialog = createAction(`${prefix}SHOW_ADD_IDENTITY_TO_IDENTITY_SET_DIALOG`)
export const hideAddIdentityToIdentitySetDialog = createAction(`${prefix}HIDE_ADD_IDENTITY_TO_IDENTITY_SET_DIALOG`)
export const showDeleteIdentitySetDialog = createAction(`${prefix}SHOW_DELETE_IDENTITY_SET_DIALOG`)
export const hideDeleteIdentitySetDialog = createAction(`${prefix}HIDE_DELETE_IDENTITY_SET_DIALOG`)
export const setSelectedIdentitySet = createAction(`${prefix}SET_SELECTED_IDENTITY_SET`)
export const setUploadedPhoto = createAction(`${prefix}SET_UPLOADED_PHOTO`)
