import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/content_desk'
import * as AppActions from 'actions/app'

const queryTypeLabelMapping = {
  content_plain_text: 'search_for_content_plain_text',
  content_title: 'search_for_content_title',
  campaign_name: 'search_for_campaign_name'
}

export const initialState = fromJS({
  activeFiltersCollapsed: true,
  showNewContentDialog: false,
  newContentDialogSocialOnly: false,
  showOldEditContentDialog: false,
  showEditContentDialog: false,
  showViewContentDialog: false,
  showDeleteDialog: false,
  showBlockedContentEditDialog: false,
  showNewCampaignDialog: false,
  showScheduleDialog: false,
  showOldScheduleDialog: false,
  showPlanDialog: false,
  showSaveTemplateDialog: false,
  showTestMailDialog: false,
  showContentNotesDialog: false,
  showRecipientsClearAllConfirmDialog: false,
  newDialogView: 'create_content',
  emailEditorLoading: false,
  campaignDropdownOptionsLoading: false,
  contactDropdownOptionsLoading: false,
  distributionListDropdownOptionsLoading: false,
  isContentLoading: false,
  contentDialogCloneMode: false,
  campaignDialogEditMode: false,
  requestRunning: false,
  deleteData: { id: 0 },
  selectedQueryType: 'content_plain_text',
  searchBarLabel: 'search_for_content_plain_text',
  searchCampaignsRunning: false,
  searchContentsRunning: false,
  uploadedImageUrl: null,
  statisticsLoading: false,
  duplicateError: false,
  mediaFilesLoading: false,
  mediaFileUploading: false,
  mediaFilesInitialFetch: false,
  editorAiMode: false,
  editorAiRequestRunning: false,
  socialMediaLinkLoading: false,
  socialMediaLinkBuildFailed: false,
  downloadEmailEventsLoading: false,
  linkedInCompanySearchResults: fromJS([]),
  contentTabView: 'all',
  contentEditTabView: null,
  contentsCampaignsTabView: 'contents',
  loadingKpiStatistics: false,
  showClosedCampaignDialog: false,
  attachmentsMediaType: 'all',
  attachmentsDeleteDialogOpen: false,
  attachmentsDeleteMediaFileId: null,
  attachmentsImagePreviewDialogOpen: false,
  attachmentsImagePreviewSrc: null,
  recipientBrowserOpen: false,
  recipientBrowserQuery: '',
  contentIsPublishable: false,
  templateConfirmDialogOpen: false,
  templatePreviewDialogOpen: false,
  templateDesign: null,
  emailEditorForceLoadDesign: false,
  showOtsWelcomeDialog: false,
  showOtsSuccessDialog: false,
  showOtsErrorDialog: false
})

export default handleActions({
  [Actions.linkedInCompanySearchSuccess]: (state, { payload }) => state.set('linkedInCompanySearchResults', fromJS(payload)),
  [Actions.linkedInCompanySearchError]: state => state.set(
    'linkedInCompanySearchResults',
    fromJS([{ id: 0, vanityName: 'LinkedIn API-Error', logoUrl: 'https://static.pressrelations.de/pics/remove-24x24.png' }])
  ),
  [Actions.openNewContentDialog]: (state, { payload }) => (
    state
      .set('showNewContentDialog', true)
      .set('contentDialogCloneMode', false)
      .set('newContentDialogSocialOnly', !!(payload && payload.socialOnly))
  ),
  [Actions.closeNewContentDialog]: state => (
    state
      .set('showNewContentDialog', false)
      .set('newDialogView', 'create_content')
      .set('duplicateError', false)
  ),
  [Actions.openViewContentDialog]: state => state.set('showViewContentDialog', true),
  [Actions.closeViewContentDialog]: state => state.set('showViewContentDialog', false),
  [Actions.openDeleteDialog]: (state, { payload }) => state.set('showDeleteDialog', payload),
  [Actions.closeDeleteDialog]: state => state.set('showDeleteDialog', false),
  [Actions.openBlockedContentEditDialog]: state => state.set('showBlockedContentEditDialog', true),
  [Actions.closeBlockedContentEditDialog]: state => state.set('showBlockedContentEditDialog', false),
  [Actions.openSaveTemplateDialog]: state => state.set('showSaveTemplateDialog', true),
  [Actions.closeSaveTemplateDialog]: state => state.set('showSaveTemplateDialog', false),
  [Actions.setDeleteContentData]: (state, { payload }) => state.set('deleteData', payload),
  [Actions.openCampaignForm]: state => state.set('showNewCampaignDialog', true),
  [Actions.closeCampaignForm]: state => state.set('showNewCampaignDialog', false).set('campaignDialogEditMode', false),
  [Actions.setCampaignDialogEditMode]: state => state.set('campaignDialogEditMode', true),
  [Actions.openScheduleDialog]: state => state.set('showScheduleDialog', true),
  [Actions.openOldScheduleDialog]: state => state.set('showOldScheduleDialog', true),
  [Actions.closeScheduleDialog]: state => state.set('showScheduleDialog', false),
  [Actions.closeOldScheduleDialog]: state => state.set('showOldScheduleDialog', false),
  [Actions.openPlanDialog]: state => state.set('showPlanDialog', true),
  [Actions.closePlanDialog]: state => state.set('showPlanDialog', false),
  [Actions.openTestMailDialog]: state => state.set('showTestMailDialog', true),
  [Actions.closeTestMailDialog]: state => state.set('showTestMailDialog', false),
  [Actions.changeNewContentDialogView]: (state, { payload }) => state.set('newDialogView', payload),
  [Actions.emailEditorLoadingStart]: state => state.set('emailEditorLoading', true),
  [Actions.emailEditorLoadingStop]: state => state.set('emailEditorLoading', false),
  [Actions.fetchCampaignDropdownOptionsStart]: state => state.set('campaignDropdownOptionsLoading', true),
  [Actions.fetchDistributionListDropdownOptionsStart]: state => state.set('distributionListDropdownOptionsLoading', true),
  [combineActions(
    Actions.fetchContactDropdownOptionsSuccess,
    Actions.fetchContactDropdownOptionsError
  )]: state => state.set('contactDropdownOptionsLoading', false),
  [combineActions(
    Actions.fetchDistributionListDropdownOptionsSuccess,
    Actions.fetchDistributionListDropdownOptionsError
  )]: state => state.set('distributionListDropdownOptionsLoading', false),
  [combineActions(
    Actions.fetchCampaignDropdownOptionsSuccess,
    Actions.fetchCampaignDropdownOptionsError
  )]: state => state.set('campaignDropdownOptionsLoading', false),
  [combineActions(Actions.fetchMediaFilesStart, Actions.fetchMoreMediaFilesStart)]: state => state.set('mediaFilesLoading', true),
  [combineActions(
    Actions.fetchMediaFilesSuccess,
    Actions.fetchMediaFilesError,
    Actions.fetchMoreMediaFilesSuccess,
    Actions.fetchMoreMediaFilesError
  )]: state => state.set('mediaFilesLoading', false),
  [combineActions(
    Actions.saveContentStart,
    Actions.getContentStart,
    Actions.dispatchContentRequestStart,
    Actions.createOtsDraftStart
  )]: state => state.set('isContentLoading', true),
  [combineActions(
    Actions.getContentSuccess,
    Actions.getContentError,
    Actions.dispatchContentRequestSuccess,
    Actions.dispatchContentRequestError,
    Actions.createOtsDraftSuccess,
    Actions.createOtsDraftError
  )]: state => state.set('isContentLoading', false),
  [Actions.saveContentSuccess]: state => state.set('isContentLoading', false).set('duplicateError', false),
  [Actions.saveContentError]: (state, { payload }) => {
    let dupError = false

    if (payload.response) {
      if (payload.response.statusCode === 409) {
        dupError = true
      }
    }

    return state
      .set('duplicateError', dupError)
      .set('isContentLoading', false)
  },
  [Actions.openEditContentDialog]: state => state.set('showEditContentDialog', true),
  [Actions.openOldEditContentDialog]: state => state.set('showOldEditContentDialog', true),
  [Actions.closeEditContentDialog]: state => (
    state
      .set('showEditContentDialog', false)
      .set('contentDialogCloneMode', false)
      .set('duplicateError', false)
      .set('contentEditTabView', null)
  ),
  [Actions.closeOldEditContentDialog]: state => (
    state
      .set('showOldEditContentDialog', false)
      .set('contentDialogCloneMode', false)
      .set('duplicateError', false)
  ),
  [Actions.cloneContentsFormData]: state => (
    state
      .set('contentDialogCloneMode', true)
  ),
  [combineActions(
    Actions.saveCampaignStart,
    Actions.saveContentFormlessStart
  )]: state => state.set('requestRunning', true),
  [combineActions(
    Actions.saveCampaignSuccess,
    Actions.saveCampaignError,
    Actions.saveContentFormlessSuccess,
    Actions.saveContentFormlessError
  )]: state => state.set('requestRunning', false).set('showClosedCampaignDialog', false),
  [combineActions(Actions.deleteContentStart, Actions.deleteCampaignStart)]: state => state.set('requestRunning', true),
  [combineActions(
    Actions.deleteContentSuccess,
    Actions.deleteContentError,
    Actions.deleteCampaignSuccess,
    Actions.deleteCampaignError
  )]: state => state.set('requestRunning', false),
  [combineActions(Actions.deleteContentSuccess, Actions.deleteCampaignSuccess)]: state => state.set('showDeleteDialog', false),

  [Actions.setSelectedQueryType]: (state, { payload }) => {
    const newState = state.set('selectedQueryType', payload)

    if (queryTypeLabelMapping[payload]) {
      return newState.set('searchBarLabel', queryTypeLabelMapping[payload])
    }

    return state
  },
  [Actions.toggleActiveFilters]: state => state.update('activeFiltersCollapsed', v => !v),

  [Actions.searchCampaignsStart]: state => state.set('searchCampaignsRunning', true),
  [Actions.searchContentsStart]: state => state.set('searchContentsRunning', true),
  [combineActions(
    Actions.searchCampaignsSuccess,
    Actions.searchCampaignsError
  )]: state => state.set('searchCampaignsRunning', false),
  [combineActions(
    Actions.searchContentsSuccess,
    Actions.searchContentsError
  )]: state => state.set('searchContentsRunning', false),

  [Actions.uploadEditorImageSuccess]: (state, { payload: { url } }) => state.set('uploadedImageUrl', url),
  [Actions.toggleEditorAiMode]: state => state.update('editorAiMode', value => !value),
  [combineActions(
    Actions.resetUploadedImageUrl,
    Actions.uploadEditorImageError
  )]: state => state.set('uploadedImageUrl', null),
  [Actions.fetchStatisticsStart]: state => state.set('statisticsLoading', true),
  [combineActions(
    Actions.fetchStatisticsSuccess,
    Actions.fetchStatisticsError
  )]: state => state.set('statisticsLoading', false),
  [Actions.fetchCalendarEventsStart]: state => state.set('requestRunning', true),
  [combineActions(
    Actions.fetchCalendarEventsSuccess,
    Actions.fetchCalendarEventsError
  )]: state => state.set('requestRunning', false),
  [Actions.uploadFacebookMediaFileStart]: state => state.set('mediaFileUploading', true),
  [combineActions(
    Actions.uploadFacebookMediaFileSuccess,
    Actions.uploadFacebookMediaFileError
  )]: state => state.set('mediaFileUploading', false),
  [Actions.editorAiRequestStart]: state => state.set('editorAiRequestRunning', true),
  [combineActions(
    Actions.editorAiRequestSuccess,
    Actions.editorAiRequestError
  )]: state => state.set('editorAiRequestRunning', false),
  [Actions.uploadLinkedInMediaFileStart]: state => state.set('mediaFileUploading', true),
  [combineActions(
    Actions.uploadLinkedInMediaFileSuccess,
    Actions.uploadLinkedInMediaFileError
  )]: state => state.set('mediaFileUploading', false),
  [Actions.setMediaFilesInitialFetch]: (state, { payload }) => state.set('mediaFilesInitialFetch', payload),
  [Actions.buildSocialMediaLinkStart]: state => state
    .set('socialMediaLinkLoading', true)
    .set('socialMediaLinkBuildFailed', false),
  [Actions.buildSocialMediaLinkLinkedInSuccess]: state => state.set('socialMediaLinkLoading', false),
  [Actions.buildSocialMediaLinkFacebookSuccess]: state => state.set('socialMediaLinkLoading', false),
  [Actions.buildSocialMediaLinkLinkedInError]: state => state
    .set('socialMediaLinkLoading', false)
    .set('socialMediaLinkBuildFailed', true),
  [Actions.buildSocialMediaLinkFacebookError]: state => state
    .set('socialMediaLinkLoading', false)
    .set('socialMediaLinkBuildFailed', true),
  [Actions.downloadEmailEventsStart]: state => state.set('downloadEmailEventsLoading', true),
  [combineActions(
    Actions.downloadEmailEventsSuccess,
    Actions.downloadEmailEventsError
  )]: state => state.set('downloadEmailEventsLoading', false),

  [Actions.openContentNotesDialog]: state => state.set('showContentNotesDialog', true),
  [Actions.closeContentNotesDialog]: state => state.set('showContentNotesDialog', false),
  [Actions.changeContentTabView]: (state, { payload }) => state.set('contentTabView', payload),
  [Actions.changeContentEditTabView]: (state, { payload }) => state.set('contentEditTabView', payload),
  [Actions.changeContentsCampaignsTabView]: (state, { payload }) => state.set('contentsCampaignsTabView', payload),
  [Actions.fetchKpiStatisticsStart]: state => state.set('loadingKpiStatistics', true),
  [combineActions(
    Actions.fetchKpiStatisticsSuccess,
    Actions.fetchKpiStatisticsError
  )]: state => state.set('loadingKpiStatistics', false),
  [Actions.openClosedCampaignDialog]: state => state.set('showClosedCampaignDialog', true),
  [Actions.closeClosedCampaignDialog]: state => state.set('showClosedCampaignDialog', false),
  [Actions.changeAttachmentsMediaType]: (state, { payload }) => state.set('attachmentsMediaType', payload),
  [Actions.openAttachmentsDeleteDialog]: (state, { payload }) => state
    .set('attachmentsDeleteDialogOpen', true)
    .set('attachmentsDeleteMediaFileId', payload),
  [Actions.closeAttachmentsDeleteDialog]: state => state
    .set('attachmentsDeleteDialogOpen', false)
    .set('attachmentsDeleteMediaFileId', null),
  [Actions.openAttachmentsImagePreviewDialog]: (state, { payload }) => state
    .set('attachmentsImagePreviewDialogOpen', true)
    .set('attachmentsImagePreviewSrc', payload),
  [Actions.closeAttachmentsImagePreviewDialog]: state => state
    .set('attachmentsImagePreviewDialogOpen', false)
    .set('attachmentsImagePreviewSrc', null),
  [Actions.setRecipientBrowserOpen]: (state, { payload }) => state.set('recipientBrowserOpen', payload)
    .set('recipientBrowserQuery', initialState.get('recipientBrowserQuery')),
  [Actions.setRecipientBrowserQuery]: (state, { payload }) => state.set('recipientBrowserQuery', payload),
  [Actions.setContentIsPublishable]: (state, { payload }) => state.set('contentIsPublishable', payload),
  [Actions.openTemplateConfirmDialog]: (state, { payload }) => state
    .set('templateConfirmDialogOpen', true)
    .set('templateDesign', payload),
  [Actions.closeTemplateConfirmDialog]: state => state
    .set('templateConfirmDialogOpen', false)
    .set('templateDesign', null),
  [Actions.openTemplatePreviewDialog]: (state, { payload }) => state
    .set('templatePreviewDialogOpen', true)
    .set('templateDesign', payload),
  [Actions.closeTemplatePreviewDialog]: state => state
    .set('templatePreviewDialogOpen', false)
    .set('templateDesign', null),
  [Actions.emailEditorForceLoadDesignStart]: state => state.set('emailEditorForceLoadDesign', true),
  [Actions.emailEditorForceLoadDesignStop]: state => state.set('emailEditorForceLoadDesign', false),
  [Actions.openOtsWelcomeDialog]: state => state.set('showOtsWelcomeDialog', true),
  [Actions.closeOtsWelcomeDialog]: state => state.set('showOtsWelcomeDialog', false),
  [Actions.createOtsDraftSuccess]: state => state
    .set('showOtsSuccessDialog', true)
    .set('showOtsErrorDialog', false),
  [Actions.createOtsDraftError]: (state, { payload }) => {
    if (payload.response && payload.response.statusCode === 424) {
      return state.set('showOtsErrorDialog', true).set('showOtsSuccessDialog', false)
    }

    return state.set('showOtsSuccessDialog', false)
  },
  [Actions.closeOtsSuccessDialog]: state => state.set('showOtsSuccessDialog', false),
  [Actions.closeOtsErrorDialog]: state => state.set('showOtsErrorDialog', false),
  [Actions.openRecipientsClearAllConfirmDialog]: state => state.set('showRecipientsClearAllConfirmDialog', true),
  [Actions.closeRecipientsClearAllConfirmDialog]: state => state.set('showRecipientsClearAllConfirmDialog', false),

  [AppActions.resetState]: () => initialState
}, initialState)
