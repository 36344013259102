import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { makeStyles } from 'tss-react/mui'
import NrxProgressBar from 'components/nrx_progress_bar'

import { Dialog, IconButton, Button } from 'containers/themed'
import ChannelIcon from 'components/channel_icon'
import { StaggeredListItem } from 'components/staggered_list'

import useI18n from 'hooks/useI18n'

import { Grid, Divider } from '@mui/material'

const useStyles = makeStyles()(theme => ({
  channelIcon: {
    marginRight: 5
  },
  divider: {
    marginTop: 5,
    marginBottom: 5
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: '100%'
  },
  content: {
    minHeight: 250,
    overflowX: 'hidden'
  },
  news: {
    padding: 5,
    transition: 'background-color 300ms',
    borderRadius: 5
  },
  firstNews: {
    backgroundColor: theme.palette.primary.main
  },
  secondNews: {
    backgroundColor: theme.palette.secondary.main
  },
  hoveredFirstNews: {
    backgroundColor: theme.palette.primary.light
  },
  hoveredSecondNews: {
    backgroundColor: theme.palette.secondary.light
  },
  doubleArrowUp: {
    transform: 'rotate(-90deg)'
  },
  doubleArrowDown: {
    transform: 'rotate(+90deg)'
  }
}))

export default function ClusterDialog({
  active,
  news,
  newsIds,
  loading,
  activeNewsIdFirst,
  activeNewsIdSecond,
  hoveredNewsIdFirst,
  hoveredNewsIdSecond,
  onHide,
  onMoveClick,
  onMoveStart,
  onHover,
  onSaveClick
}) {
  const i18n = useI18n()
  const { classes, cx } = useStyles()

  const resetHoveredNewsIds = () => onHover({
    first: null,
    second: null
  })

  const setHoveredNewsIds = (id, newIndex) => onHover({
    first: id,
    second: news.getIn([newIndex, 'id'])
  })

  const startAnimation = (id, newIndex) => {
    onMoveStart({
      first: id,
      second: news.getIn([newIndex, 'id'])
    })

    setTimeout(() => {
      onMoveClick({ id, newIndex })
    }, 500)

    setTimeout(() => {
      onMoveStart({
        first: null,
        second: null
      })
    }, 1000)
  }

  const handleTopClick = id => startAnimation(id, 0)
  const handleUpClick = (id, index) => startAnimation(id, index - 1)
  const handleDownClick = (id, index) => startAnimation(id, index + 1)
  const handleBottomClick = id => startAnimation(id, news.size - 1)
  const isMoving = Boolean(activeNewsIdFirst && activeNewsIdSecond)

  return (
    <Dialog
      open={active}
      title={i18n.get('reorder_cluster')}
      onClose={onHide}
      maxWidth="md"
      dialogContentClasses={{ root: classes.content }}
      actions={[
        <Button
          key="cancel"
          onClick={() => onHide()}
        >
          {i18n.get('cancel')}
        </Button>,
        <Button
          key="save"
          variant="contained"
          onClick={() => {
            onSaveClick({
              oldIds: newsIds,
              news
            })
            onHide()
          }}
        >
          {i18n.get('save')}
        </Button>
      ]}
    >
      {loading && (
        <div className={classes.loading}>
          <NrxProgressBar />
        </div>
      )}

      {!loading && (
        <>
          {news.map((n, index) => (
            <StaggeredListItem
              type="rightToLeft"
              key={`${n.get('id')}_${index}`}
              delay={index * 50}
            >
              <Grid
                container
                className={cx(
                  classes.news,
                  n.get('id') === hoveredNewsIdFirst && !activeNewsIdFirst && classes.hoveredFirstNews,
                  n.get('id') === hoveredNewsIdSecond && !activeNewsIdSecond && classes.hoveredSecondNews,
                  n.get('id') === activeNewsIdFirst && classes.firstNews,
                  n.get('id') === activeNewsIdSecond && classes.secondNews
                )}
              >
                <Grid
                  item
                  container
                  xs={8}
                  sm={8}
                  md={8}
                  lg={8}
                  xl={8}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <span className={classes.channelIcon}>
                      <ChannelIcon
                        circle={false}
                        id={n.getIn(['publication', 'channelId'])}
                      />
                    </span>

                    <span>
                      {n.getIn(['publication', 'name'])}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    {n.get('headline')}
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <IconButton
                    disabled={index === 0 || isMoving}
                    onClick={() => handleTopClick(n.get('id'), index)}
                    onMouseEnter={() => setHoveredNewsIds(n.get('id'), 0)}
                    onMouseLeave={() => resetHoveredNewsIds()}
                    size="large"
                  >
                    <DoubleArrowIcon classes={{ root: classes.doubleArrowUp }} />
                  </IconButton>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <IconButton
                    disabled={index === 0 || isMoving}
                    onClick={() => handleUpClick(n.get('id'), index)}
                    onMouseEnter={() => setHoveredNewsIds(n.get('id'), index - 1)}
                    onMouseLeave={() => resetHoveredNewsIds()}
                    size="large"
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <IconButton
                    disabled={index === news.size - 1 || isMoving}
                    onClick={() => handleDownClick(n.get('id'), index)}
                    onMouseEnter={() => setHoveredNewsIds(n.get('id'), index + 1)}
                    onMouseLeave={() => resetHoveredNewsIds()}
                    size="large"
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <IconButton
                    disabled={index === news.size - 1 || isMoving}
                    onClick={() => handleBottomClick(n.get('id'), index)}
                    onMouseEnter={() => setHoveredNewsIds(n.get('id'), news.size - 1)}
                    onMouseLeave={() => resetHoveredNewsIds()}
                    size="large"
                  >
                    <DoubleArrowIcon classes={{ root: classes.doubleArrowDown }} />
                  </IconButton>
                </Grid>
              </Grid>

              {index < news.size - 1 && <Divider className={classes.divider} />}
            </StaggeredListItem>
          ))}
        </>
      )}
    </Dialog>
  )
}

ClusterDialog.propTypes = {
  active: PropTypes.bool.isRequired,
  news: PropTypes.instanceOf(List).isRequired,
  newsIds: PropTypes.instanceOf(List).isRequired,
  activeNewsIdFirst: PropTypes.number,
  activeNewsIdSecond: PropTypes.number,
  hoveredNewsIdFirst: PropTypes.number,
  hoveredNewsIdSecond: PropTypes.number,
  loading: PropTypes.bool.isRequired,

  onHide: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  onMoveClick: PropTypes.func.isRequired,
  onMoveStart: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired
}
