import moment from 'moment-timezone'
import { getLocale } from 'utils/locale'

export const round = number => {
  if (number >= 1000000000000) {
    return Math.round(number / 100000000000) / 10
  }

  if (number >= 1000000000) {
    return Math.round(number / 100000000) / 10
  }

  if (number >= 1000000) {
    return Math.round(number / 100000) / 10
  }

  if (number >= 1000) {
    return Math.round(number / 100) / 10
  }

  return number
}

const numberAbbreviations = {
  default: {
    thousand: 'k',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  de: {
    thousand: ' Tsd.',
    million: ' Mio.',
    billion: ' Mrd.',
    trillion: ' Bio.'
  }
}

export const formatNumber = number => {
  if (number === '-') {
    return number
  }

  const abr = numberAbbreviations[moment.locale()] || numberAbbreviations.default

  const rounded = round(number)

  if (number >= 1000000000000) {
    return `${rounded}${abr.trillion}`
  }

  if (number >= 1000000000) {
    return `${rounded}${abr.billion}`
  }

  if (number >= 1000000) {
    return `${rounded}${abr.million}`
  }

  if (number >= 1000) {
    return `${rounded}${abr.thousand}`
  }

  return rounded
}

export const anotherRound = (number, roundPrecision) => {
  if ((number || null) % 1 !== 0) {
    return number.toFixed(roundPrecision)
  }

  return number
}

export const renderNumber = (number, roundPrecision) => formatNumber(anotherRound(number, roundPrecision))

const decimalLength = n => {
  if (Number(n) !== n) {
    return 0
  }

  if (Number.isNaN(n)) {
    return 0
  }

  if (n % 1 === 0) {
    return 0
  }

  let e = 1; let
    p = 0
  while (Math.round(n * e) / e !== n) {
    e *= 10
    p += 1
  }

  return p
}

export const separate = (number, decimals, currency) => {
  if (number < 1) {
    return number
  }

  if (global.Intl && number) {
    if (currency) {
      return new Intl.NumberFormat(getLocale(), { style: 'currency', currency }).format(number)
    }

    if (decimals && (number % 1 !== 0)) {
      return new Intl.NumberFormat().format(number.toFixed(Math.min(decimalLength(number), decimals)))
    }

    return new Intl.NumberFormat().format(number)
  }

  return number
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}
