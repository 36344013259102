import { connect } from 'react-redux'

import MobileCampaign from 'components/content_desk_new/campaigns/mobile_campaigns/mobile_campaign'

const mapStateToProps = (_state, ownProps) => ({
  campaign: ownProps.campaign
})

export default connect(
  mapStateToProps,
  {
  }
)(MobileCampaign)
