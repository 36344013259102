import { connect } from 'react-redux'

import {
  getCDeskSelectedCalendarEvents,
  getCDeskCalendarCampaignsSorting
} from 'selectors'

import {
  sortCampaigns
} from 'actions/content_desk'

import ShortCampaigns from 'components/content_desk_new/campaigns/short_campaigns/'

const mapStateToProps = state => {
  const events = getCDeskSelectedCalendarEvents(state)

  return {
    campaigns: events.get('campaigns'),
    campaignsSorting: getCDeskCalendarCampaignsSorting(state)
  }
}

export default connect(
  mapStateToProps,
  {
    sortCampaigns
  }
)(ShortCampaigns)
