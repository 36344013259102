import { connect } from 'react-redux'

import ModuleName from 'components/administration/dispatch_log/module_name'

import { getMediaReviewIds } from 'selectors'

import { navigate } from 'actions/navigation'

const mapStateToProps = (state, { data }) => ({
  mediaReviewIds: getMediaReviewIds(state),
  data
})

export default connect(
  mapStateToProps,
  {
    navigate
  }
)(ModuleName)
