import { put, call, takeEvery, all } from 'redux-saga/effects'

import * as Actions from 'actions/auth'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'
import * as Api from 'api/bff'

export function* authenticateGoogleAnalytics({ payload: { code } }) {
  try {
    yield call(Api.authenticateGoogleAnalytics, {
      code
    })

    yield put(ConfigActions.setGoogleAnalyticsEnabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.authenticateGoogleAnalyticsSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.authenticateGoogleAnalyticsError())
  }
}

export function* authenticateYoutubeAnalytics({ payload: { code } }) {
  try {
    yield call(Api.authenticateYoutubeAnalytics, {
      code
    })

    yield put(ConfigActions.setYoutubeAnalyticsEnabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.authenticateYoutubeAnalyticsSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.authenticateYoutubeAnalyticsError())
  }
}

export function* authenticateLinkedIn({ payload: { code } }) {
  try {
    yield call(Api.authenticateLinkedIn, {
      code
    })

    yield put(ConfigActions.setLinkedInAnalyticsEnabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.authenticateLinkedInSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.authenticateLinkedInError())
  }
}

export function* revokeGoogleAnalytics() {
  try {
    yield call(Api.revokeGoogleAnalytics)

    yield put(ConfigActions.setGoogleAnalyticsDisabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.revokeGoogleAnalyticsSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.revokeGoogleAnalyticsError())
  }
}

export function* revokeYoutubeAnalytics() {
  try {
    yield call(Api.revokeYoutubeAnalytics)

    yield put(ConfigActions.setYoutubeAnalyticsDisabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.revokeYoutubeAnalyticsSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.revokeYoutubeAnalyticsError())
  }
}

export function* revokeLinkedIn() {
  try {
    yield call(Api.revokeLinkedIn)

    yield put(ConfigActions.setLinkedInAnalyticsDisabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.revokeLinkedInSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.revokeLinkedInError())
  }
}

export function* revokeTwitter() {
  try {
    yield call(Api.revokeTwitter)

    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.revokeTwitterSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.revokeTwitterError())
  }
}

export function* revokeFacebook() {
  try {
    yield call(Api.revokeFacebook)

    yield put(ConfigActions.setFacebookAnalyticsDisabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
    yield put(Actions.revokeFacebookSuccess())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
    yield put(Actions.revokeFacebookError())
  }
}

export function* authenticateFacebook({ payload }) {
  try {
    yield call(Api.authenticateFacebook, {
      at: payload.accessToken,
      user_id: payload.userID
    })

    yield put(ConfigActions.setFacebookAnalyticsEnabled())
    yield put(AppActions.genericSuccessMessage())
    yield put(ConfigActions.configRequestStart())
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchAuthenticateGoogleAnalytics() {
  yield takeEvery(Actions.authenticateGoogleAnalyticsStart, authenticateGoogleAnalytics)
}

export function* watchRevokeGoogleAnalytics() {
  yield takeEvery(Actions.revokeGoogleAnalyticsStart, revokeGoogleAnalytics)
}

export function* watchAuthenticateYoutubeAnalytics() {
  yield takeEvery(Actions.authenticateYoutubeAnalyticsStart, authenticateYoutubeAnalytics)
}

export function* watchRevokeYoutubeAnalytics() {
  yield takeEvery(Actions.revokeYoutubeAnalyticsStart, revokeYoutubeAnalytics)
}

export function* watchRevokeFacebook() {
  yield takeEvery(Actions.revokeFacebookStart, revokeFacebook)
}

export function* watchRevokeLinkedIn() {
  yield takeEvery(Actions.revokeLinkedInStart, revokeLinkedIn)
}

export function* watchRevokeTwitter() {
  yield takeEvery(Actions.revokeTwitterStart, revokeTwitter)
}

export function* watchAuthenticateFacebook() {
  yield takeEvery(Actions.authenticateFacebookStart, authenticateFacebook)
}

export function* watchAuthenticateLinkedIn() {
  yield takeEvery(Actions.authenticateLinkedInStart, authenticateLinkedIn)
}

export default function* appSaga() {
  yield all([
    watchAuthenticateGoogleAnalytics(),
    watchRevokeGoogleAnalytics(),
    watchAuthenticateYoutubeAnalytics(),
    watchRevokeYoutubeAnalytics(),
    watchRevokeFacebook(),
    watchRevokeLinkedIn(),
    watchRevokeTwitter(),
    watchAuthenticateFacebook(),
    watchAuthenticateLinkedIn()
  ])
}
