export const getAi = state => state.ai

// SETTINGS
export const getAiSettings = state => getAi(state).get('settings')
export const getAiSettingsLocale = state => getAiSettings(state).get('locale')

// ANALYSIS
export const getAiAnalysis = state => getAi(state).get('analysis')
export const getAiAnalysisLoading = state => getAiAnalysis(state).get('loading')
export const getAiAnalysisAnalysis = state => getAiAnalysis(state).get('analysis')
export const getAiAnalysisShowDialog = state => getAiAnalysis(state).get('showDialog')

// UI
export const getAiUi = state => getAi(state).get('ui')
export const getAiUiShowOrderSummariesDialog = state => getAiUi(state).get('showOrderSummariesDialog')
