import { connect } from 'react-redux'

import {
  setSortBy,
  setPage
} from 'actions/content_desk'
import {
  getCDeskCampaigns,
  getCDeskCampaignSortBy,
  getCDeskCampaignSortOrder,
  getCDeskSearchCampaignsRunning
} from 'selectors'

import Campaigns from 'components/content_desk_new/campaigns'

const mapStateToProps = state => ({
  campaigns: getCDeskCampaigns(state),
  loading: getCDeskSearchCampaignsRunning(state),
  sortBy: getCDeskCampaignSortBy(state),
  sortOrder: getCDeskCampaignSortOrder(state)
})

export default connect(
  mapStateToProps,
  {
    onSortBy: setSortBy,
    setPage
  }
)(Campaigns)
