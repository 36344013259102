import { connect } from 'react-redux'

import Sorting from 'components/themax/sorting'
import { getThemaxEditorialPlansSortBy, getThemaxEditorialPlansSortOrder } from 'selectors'
import { updateThemaxSort } from 'actions/themax'

const mapStateToProps = state => ({
  sortBy: getThemaxEditorialPlansSortBy(state),
  sortOrder: getThemaxEditorialPlansSortOrder(state),
  sortType: 'editorialPlansSorting'
})

export default connect(
  mapStateToProps,
  {
    onSortChange: updateThemaxSort
  }
)(Sorting)
