import React, { useRef, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { debounce } from 'throttle-debounce'
import { fromJS, Map } from 'immutable'

import { makeStyles } from 'tss-react/mui'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'

import ReactEmailEditor from 'react-email-editor'

import { isEditorDesignBlank } from 'utils/content_desk'

import { RaisedPrimaryButton } from 'containers/themed'
import TemplatePickerDialog from 'containers/email_templates_library/TemplatesPickerDialog'
import SaveTemplateDialog from 'containers/content_desk/content_edit_dialog/SaveTemplateDialog'
import TemplatesEditorVariables from 'containers/email_templates_library/TemplatesEditorVariables'

const useStyles = makeStyles()({
  templatesPickerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '5px',
    marginBottom: '15px'
  }
})

const EmailEditor = ({
  editorHeight,
  onChange,
  moduleScope,
  editorDesign,
  json,
  preHeader,
  newsradarId,
  imageUrl,
  onEditorLoadingStart,
  onEditorLoadingStop,
  onImageUpload,
  resetImageUrl,
  setTemplatesReadModeOnly,
  openPickerDialog,
  onSaveTemplateClick,
  fetchMoreMediaFilesStart,
  onMediaFileDelete
}) => {
  const { classes } = useStyles()
  const i18n = useI18n()
  const emailEditorRef = useRef(null)
  const theme = useTheme()
  const userIsMax = useMediaQuery(theme.breakpoints.up('xl'))
  const [uploadImageCallback, setUploadImageCallback] = useState(null)

  const handleOpenTemplates = () => {
    setTemplatesReadModeOnly(true)
    openPickerDialog()
  }

  useEffect(() => {
    if (moduleScope === 'contents' && emailEditorRef.current.editor) {
      emailEditorRef.current.editor.setBodyValues({ preheaderText: preHeader })
    }
  }, [preHeader])

  const handleDesignUpdate = useCallback(debounce(500, () => {
    emailEditorRef.current.editor.exportHtml(editorData => {
      const { design, html } = editorData

      if (moduleScope === 'contents') {
        onChange({ key: 'editorDesign', value: fromJS(design) })
        onChange({ key: 'body', value: html })
        onChange({ key: 'preHeader', value: design.body.values.preheaderText })
      }

      if (moduleScope === 'templates') {
        onChange({ key: 'json', value: fromJS(design) })
        onChange({ key: 'html', value: html })
      }
    })

    emailEditorRef.current.editor.exportPlainText(editorData => {
      if (moduleScope === 'contents') {
        const { text } = editorData

        onChange({ key: 'plainText', value: text })
      }
    })
  }), [onChange])

  useEffect(() => {
    if (imageUrl) {
      uploadImageCallback.done({ progress: 100, url: imageUrl })
      resetImageUrl()
      setUploadImageCallback(null)
    }

    return () => onEditorLoadingStop()
  }, [imageUrl])

  const onLoad = () => {
    onEditorLoadingStart()
  }

  const loadTemplate = j => {
    emailEditorRef.current.editor.loadDesign(j)
  }

  const onReady = () => {
    let currentEditorDesign

    if (moduleScope === 'contents' && !isEditorDesignBlank(editorDesign)) {
      currentEditorDesign = editorDesign.toJS()
    } else if (!isEditorDesignBlank(json)) {
      currentEditorDesign = json.toJS()
    }

    emailEditorRef.current.editor.loadDesign(currentEditorDesign)
    emailEditorRef.current.editor.registerCallback('image:removed', (image, done) => {
      onMediaFileDelete({ id: image.id })
      done()
    })
    emailEditorRef.current.editor.registerCallback('image', (file, done) => {
      done({ progress: 30 })
      onImageUpload({ file })
      setUploadImageCallback({ done })
    })
    emailEditorRef.current.editor.registerProvider('userUploads', (params, done) => {
      fetchMoreMediaFilesStart({ params, done })
    })

    if (moduleScope === 'contents') {
      emailEditorRef.current.editor.exportHtml(editorData => {
        const { design } = editorData
        onChange({ key: 'preHeader', value: design.body.values.preheaderText })
      })
    }

    emailEditorRef.current.editor.addEventListener('design:loaded', handleDesignUpdate)
    emailEditorRef.current.editor.addEventListener('design:updated', handleDesignUpdate)

    onEditorLoadingStop()
  }
  /* possibly working: 1.52.0 (if problems occur), leaving this out will result in
  stable version being used which doesn't contain important bugfixes (see for instance
  https://github.com/unlayer/react-email-editor/issues/398 */

  /* Set version to 1.52.0 because of a bug in the switch for more options in the editor content padding settings */

  return (
    <>
      {moduleScope === 'contents' && (
        <>
          <div className={classes.templatesPickerButton}>
            <TemplatesEditorVariables raisedBtn />
            <RaisedPrimaryButton
              variant="contained"
              onClick={() => onSaveTemplateClick()}
              size="small"
            >{i18n.get('save_as_template')}
            </RaisedPrimaryButton>
            <RaisedPrimaryButton
              variant="contained"
              onClick={handleOpenTemplates}
              size="small"
            >{`${i18n.get('templates_library')}`}
            </RaisedPrimaryButton>
          </div>
          <SaveTemplateDialog />
          <TemplatePickerDialog loadTemplate={loadTemplate} />
        </>
      )}
      <ReactEmailEditor
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
        minHeight={userIsMax ? '60vh' : editorHeight}
        options={{
          user: { id: newsradarId },
          version: '1.52.0', // see comment above
          appearance: {
            loader: {
              html: '<div></div>'
            }
          },
          projectId: process.env.DEPLOYMENT_TYPE === 'production' ? 156689 : 162808
        }}
      />
    </>
  )
}

EmailEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  moduleScope: PropTypes.string.isRequired,
  editorDesign: PropTypes.instanceOf(Map),
  json: PropTypes.instanceOf(Map),
  preHeader: PropTypes.string,
  newsradarId: PropTypes.number.isRequired,

  imageUrl: PropTypes.string,
  onEditorLoadingStart: PropTypes.func.isRequired,
  onEditorLoadingStop: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  resetImageUrl: PropTypes.func.isRequired,
  setTemplatesReadModeOnly: PropTypes.func.isRequired,
  openPickerDialog: PropTypes.func.isRequired,
  onSaveTemplateClick: PropTypes.func.isRequired,
  fetchMoreMediaFilesStart: PropTypes.func.isRequired,
  onMediaFileDelete: PropTypes.func.isRequired,

  editorHeight: PropTypes.string.isRequired
}

export default EmailEditor
