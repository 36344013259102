import React from 'react'
import PropTypes from 'prop-types'

export default function ChangeIcon({ current, previous }) {
  let icon = '+'

  if (current < previous) {
    icon = '-'
  }

  return <span>{icon}</span>
}

ChangeIcon.propTypes = {
  current: PropTypes.number.isRequired,
  previous: PropTypes.number.isRequired
}
