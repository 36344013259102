import { connect } from 'react-redux'

import Search from 'components/anewstip/search'

import {
  getAnewstipStatics,
  getAnewstipSearchData,
  getAnewstipFiltersData,
  getAnewstipIsSimpleSearch,
  getAnewstipCombinedSearchRequestRunning,
  getAnewstipSuggest,
  getIsInitialSearch
} from 'selectors/anewstip'

import {
  setSearchForm as onSearchChange,
  setFiltersForm as onFilterChange,
  resetEntityFilters as resetFilters,
  switchSearchType,
  combinedSearchStart as onSearch,
  searchSuggestStart as onSuggest
} from 'actions/anewstip'

const mapStateToProps = state => ({
  statics: getAnewstipStatics(state),
  searchFormData: getAnewstipSearchData(state),
  filtersFormData: getAnewstipFiltersData(state),
  isSimpleSearch: getAnewstipIsSimpleSearch(state),
  requestRunning: getAnewstipCombinedSearchRequestRunning(state),
  suggest: getAnewstipSuggest(state),
  fullScreen: getIsInitialSearch(state)
})

export default connect(
  mapStateToProps,
  {
    onSearchChange,
    onFilterChange,
    resetFilters,
    switchSearchType,
    onSearch,
    onSuggest
  }
)(Search)
