import React from 'react'

import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@mui/material'
import { grey } from '@mui/material/colors'
import { makeStyles } from 'tss-react/mui'
import { Button, RaisedSecondaryButton } from 'containers/themed'

import useI18n from 'hooks/useI18n'

const useStyles = makeStyles()({
  dialogTitle: {
    borderBottomStyle: 'solid',
    borderBottomColor: grey[400],
    borderBottomWidth: '1px',
    marginBottom: '1em'
  }
})

export default function ConfirmationDialog({ open, onClose, onConfirm }) {
  const i18n = useI18n()
  const { classes } = useStyles()

  return (
    <Dialog
      dividers
      onClose={onClose}
      open={open}
      fullScreen={false}
      maxWidth="sm"
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.get('please_confirm')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {i18n.get('delete_query_text')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={onClose}
        >
          {i18n.get('cancel')}
        </Button>
        <RaisedSecondaryButton
          onClick={onConfirm}
        >
          {i18n.get('delete')}
        </RaisedSecondaryButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
}
