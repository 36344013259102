import { all } from 'redux-saga/effects'

import mainSaga from './main'
import searchSaga from './search'

export default function* administrationSaga() {
  yield all([
    mainSaga(),
    searchSaga()
  ])
}
