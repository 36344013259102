import { connect } from 'react-redux'

import SearchBar from 'components/search_bar'
import { uiEnteredQuery } from 'actions/content_desk'
import { showAppMessage } from 'actions/app'

import {
  getI18n,
  getColors,
  getCDesksearchBarLabel,
  getCDeskSearchCampaignsRunning,
  getCDeskSearchContentsRunning,
  getWhitelabelTheme
} from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  colors: getColors(state),
  whitelabelTheme: getWhitelabelTheme(state),
  label: getCDesksearchBarLabel(state),
  loading: getCDeskSearchCampaignsRunning(state) || getCDeskSearchContentsRunning(state)
})

export default connect(
  mapStateToProps,
  {
    onSubmit: uiEnteredQuery,
    onError: showAppMessage
  }
)(SearchBar)
