import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'

import { logException } from 'utils/error'
import WithI18n from 'containers/WithI18n'

class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  static getDerivedStateFromError() {
    return {
      hasError: true
    }
  }

  componentDidCatch(error) {
    logException(error)
  }

  render() {
    const { fallbackComp, children, i18n } = this.props
    const { hasError } = this.state

    if (hasError) {
      if (fallbackComp) {
        return fallbackComp
      }

      return (
        <div
          style={{ color: '#9E9E9E' }}
          title={i18n.get('unknown_error')}
          onClick={() => this.setState({ hasError: false })}
        >
          <CancelPresentationIcon />
        </div>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  i18n: PropTypes.object.isRequired,

  children: PropTypes.any,
  fallbackComp: PropTypes.any
}

export default WithI18n()(ErrorBoundary)
