import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import previous from './previous'
import aiChartAnalysis from './ai_chart_analysis'

export default combineReducers({
  ui,
  data,
  previous,
  aiChartAnalysis
})
