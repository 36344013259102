import { connect } from 'react-redux'

import QueryTypeDropdown from 'components/query_type_dropdown'
import { setSelectedQueryType } from 'actions/contact_management'
import { getCmSelectedQueryType, getI18n } from 'selectors'

const mapStateToProps = state => ({
  value: getCmSelectedQueryType(state),
  i18n: getI18n(state),
  moduleName: 'contactManagement'
})

export default connect(
  mapStateToProps,
  {
    onChange: setSelectedQueryType
  }
)(QueryTypeDropdown)
