import React from 'react'
import PropTypes from 'prop-types'
import useI18n from 'hooks/useI18n'
import { makeStyles } from 'tss-react/mui'
import moment from 'moment-timezone'

import { Box, Typography, Tooltip } from '@mui/material'

const useStyles = makeStyles()({
  event: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    paddingLeft: '5px',
    paddingRight: '5px',
    cursor: 'pointer'
  },
  eventTitle: {
    fontSize: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tooltip: {
    padding: '5px'
  },
  tooltipTitle: {
    display: 'inline',
    fontSize: '14px',
    fontWeight: '500'
  },
  tooltipData: {
    display: 'inline',
    fontSize: '14px'
  }
})

const EventContent = ({ event }) => {
  const i18n = useI18n()
  const { classes } = useStyles()

  const formatDate = date => {
    if (!date) {
      return 'N/A'
    }

    return moment(date).format('DD MMM YYYY LT')
  }

  const tooltipData = (
    <Box className={classes.tooltip}>
      <Box>
        <Typography className={classes.tooltipTitle}>
          {`${i18n.get('publication')}: `}
        </Typography>
        <Typography className={classes.tooltipData}>
          {event.title}
        </Typography>
      </Box>
      <br />
      <Box>
        <Typography className={classes.tooltipTitle}>
          {`${i18n.get('editorial_deadline')}: `}
        </Typography>
        <Typography className={classes.tooltipData}>
          {formatDate(event.extendedProps.editorialDeadline)}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.tooltipTitle}>
          {`${i18n.get('advertising_deadline')}: `}
        </Typography>
        <Typography className={classes.tooltipData}>
          {formatDate(event.extendedProps.advertisingDeadline)}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.tooltipTitle}>
          {`${i18n.get('printing_deadline')}: `}
        </Typography>
        <Typography className={classes.tooltipData}>
          {formatDate(event.extendedProps.printingDeadline)}
        </Typography>
      </Box>
    </Box>
  )

  return (
    <Tooltip
      title={tooltipData}
      PopperProps={{
        style: { zIndex: 9999 },
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 25]
            }
          }
        ]
      }}
    >
      <Box className={classes.event}>
        <Typography className={classes.eventTitle}>{event.title}</Typography>
      </Box>
    </Tooltip>
  )
}

export default EventContent

EventContent.propTypes = {
  event: PropTypes.object.isRequired
}
