import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as CustomTagActions from 'actions/custom_tags'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  news: []
})

export default handleActions({
  [CustomTagActions.fetchNewsForCustomTagSuccess]: (state, { payload: { news } }) => state.merge({
    news: fromJS(news)
  }),
  [AppActions.resetState]: () => initialState
}, initialState)
