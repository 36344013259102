import { connect } from 'react-redux'

import AlertOptions from 'components/saved_searches/edit_dialog/alert_options'

import {
  getI18n,
  getSelectedSavedSearch,
  getMailingLists,
  getUserLocale,
  getTimezone,
  getHasAiSummariesPaidVersion,
  getIsEmployee
} from 'selectors'
import { updateSelectedSavedSearch } from 'actions/saved_searches'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  savedSearch: getSelectedSavedSearch(state),
  mailingLists: getMailingLists(state).get('data'),
  locale: getUserLocale(state),
  timezone: getTimezone(state),
  hasPaidVersion: getHasAiSummariesPaidVersion(state),
  hasAdminVersion: getIsEmployee(state)
})

export default connect(
  mapStateToProps,
  {
    onChange: updateSelectedSavedSearch
  }
)(AlertOptions)
