import { fromJS } from 'immutable'
import decamelize from 'decamelize'
import { ChartDataSources, ChartTypes } from './constants'

export const metrics = [
  // Page statistics
  'aboutPageViews',
  'allPageViews',
  'careersPageViews',
  'insightsPageViews',
  'jobsPageViews',
  'lifeAtPageViews',
  'overviewPageViews',

  // Follower statistics
  'organicFollowerGain',
  'paidFollowerGain',

  // Share statistics
  'clickCount',
  'engagement',
  'likeCount',
  'commentCount',
  'shareCount',
  'impressionCount',
  'uniqueImpressionsCount'
]

const kpis = fromJS(metrics.map(metric => ({
  value: `${decamelize(metric)}_total`,
  chart: {
    firstLevel: metric,
    secondLevel: null,
    thirdLevel: null,
    dataSource: ChartDataSources.LINKED_IN_ANALYTICS,
    type: ChartTypes.RECTANGLE
  }
})))

const config = fromJS([
  {
    value: 'li_chart_timeline',
    chart: {
      firstLevel: 'timeline',
      secondLevel: null,
      thirdLevel: 'allPageViews',
      dataSource: ChartDataSources.LINKED_IN_ANALYTICS,
      type: ChartTypes.AREA
    }
  },
  {
    value: 'social_media_analytics_feed',
    chart: {
      firstLevel: 'socialMediaAnalyticsFeed',
      secondLevel: null,
      thirdLevel: null,
      dataSource: ChartDataSources.LINKED_IN_ANALYTICS,
      type: ChartTypes.SOCIAL_MEDIA_ANALYTICS_FEED
    }
  }
]).concat(kpis)

export default config

export const findValueByChart = chart => {
  const found = config.find(c => fromJS({
    firstLevel: chart.get('firstLevel'),
    secondLevel: chart.get('secondLevel'),
    dataSource: chart.get('dataSource')
  }).equals(c.get('chart').deleteAll(['thirdLevel', 'type'])))

  if (found) {
    return found.get('value')
  }

  return null
}
