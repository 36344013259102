import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { makeStyles } from 'tss-react/mui'

import { Pagination, Stack } from '@mui/material'

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon
} from '@mui/icons-material'

import { IconButton } from 'containers/themed'

const useStyles = makeStyles()(({
  pagination: {
    width: '100%',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const ResultsPagination = ({
  resultsTab,
  results,
  resultsFilters,
  drillDownFilters,
  drillDownEntity,
  drillDown,
  isLimitedFunctionality,
  onResultsFilterChange,
  onDrillDownFilterChange,
  onSearchJournalists,
  onSearchOutlets,
  onSearchArticles,
  onDrillDownJournalists,
  onDrillDownOutlets,
  onDrillDownArticles
}) => {
  const { classes } = useStyles()

  const filters = drillDown ? drillDownFilters.get(resultsTab) : resultsFilters.get(resultsTab)
  const onFilterChange = payload => (drillDown ? onDrillDownFilterChange(payload) : onResultsFilterChange(payload))

  const onSearch = () => {
    if (resultsTab === 'journalists') {
      onSearchJournalists()
    }

    if (resultsTab === 'outlets') {
      onSearchOutlets()
    }

    if (resultsTab === 'articles') {
      onSearchArticles()
    }
  }

  const onDrillDownSearch = () => {
    if (resultsTab === 'journalists') {
      onDrillDownJournalists(drillDownEntity)
    }

    if (resultsTab === 'outlets') {
      onDrillDownOutlets()
    }

    if (resultsTab === 'articles') {
      onDrillDownArticles()
    }
  }

  const handleOnSearch = () => (drillDown ? onDrillDownSearch() : onSearch())

  if (resultsTab === 'articles') {
    const cursor = results.get('cursor')
    const previousCursor = results.get('previousCursor')

    const handlePrevPage = () => {
      const pagination = filters.get('pagination').set('cursor', previousCursor)
      onFilterChange({ key: resultsTab, value: filters.set('pagination', pagination) })
      handleOnSearch()
    }

    const handleNextPage = () => {
      const pagination = filters.get('pagination').set('cursor', cursor)
      onFilterChange({ key: resultsTab, value: filters.set('pagination', pagination) })
      handleOnSearch()
    }

    return (
      <div className={classes.pagination}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <IconButton
            color="primary"
            disabled={previousCursor === ''}
            aria-label="prev"
            size="medium"
            onClick={handlePrevPage}
          >
            <NavigateBeforeIcon />
          </IconButton>
          <IconButton
            color="primary"
            disabled={cursor === ''}
            aria-label="next"
            size="medium"
            onClick={handleNextPage}
          >
            <NavigateNextIcon />
          </IconButton>
        </Stack>
      </div>
    )
  }

  if (!results.get('paginator')) {
    return null
  }

  const { currentPage, totalCount, pageSize } = results.get('paginator').toJS()

  const handlePageChange = (_event, page) => {
    const pagination = filters.get('pagination').set('page', page)
    onFilterChange({ key: resultsTab, value: filters.set('pagination', pagination) })
    handleOnSearch()
  }

  return (
    <div className={classes.pagination}>
      <Pagination
        disabled={isLimitedFunctionality && (resultsTab === 'journalists' || resultsTab === 'articles')}
        showFirstButton
        showLastButton
        count={Math.ceil(totalCount / pageSize)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
    </div>
  )
}

ResultsPagination.defaultProps = {
  drillDown: false
}

ResultsPagination.propTypes = {
  resultsTab: PropTypes.string.isRequired,
  results: PropTypes.instanceOf(Map).isRequired,
  resultsFilters: PropTypes.instanceOf(Map).isRequired,
  drillDownFilters: PropTypes.instanceOf(Map).isRequired,
  drillDownEntity: PropTypes.string,
  drillDown: PropTypes.bool,
  isLimitedFunctionality: PropTypes.bool.isRequired,

  onResultsFilterChange: PropTypes.func.isRequired,
  onDrillDownFilterChange: PropTypes.func.isRequired,
  onSearchJournalists: PropTypes.func.isRequired,
  onSearchOutlets: PropTypes.func.isRequired,
  onSearchArticles: PropTypes.func.isRequired,
  onDrillDownJournalists: PropTypes.func.isRequired,
  onDrillDownOutlets: PropTypes.func.isRequired,
  onDrillDownArticles: PropTypes.func.isRequired
}

export default ResultsPagination
