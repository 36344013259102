import { combineReducers } from 'redux-immutable'

import ui from './ui'
import data from './data'
import filter from './filter'
import scoreQueryForm from './score_query_form'
import searchQueryForm from './search_query_form'

export default combineReducers({
  ui,
  data,
  filter,
  scoreQueryForm,
  searchQueryForm
})
