import { createImmutableSelector } from 'utils/reselect'
import { List } from 'immutable'

// DATA
export const getDarknetData = state => state.darknet.get('data')

export const getDarknetResults = state => getDarknetData(state).get('hits')
export const getDarknetCharts = state => getDarknetData(state)
  .getIn(['charts', 'search'])
  .concat(getDarknetData(state).getIn(['charts', 'scores']))
export const getDarknetStats = state => getDarknetData(state).get('stats')
export const getDarknetScores = state => getDarknetData(state).get('scores')
export const getDarknetTotalCount = state => getDarknetStats(state).get('total')

// UI
export const getDarknetUi = state => state.darknet.get('ui')
export const getDarknetRequestRunning = state => getDarknetUi(state).get('requestRunning')
export const getDarknetSortBy = state => getDarknetUi(state).get('sortBy')
export const getDarknetSortDirection = state => getDarknetUi(state).get('sortDirection')
export const getDarknetView = state => getDarknetUi(state).get('view')
export const getDarknetShowAdvancedSearch = state => getDarknetUi(state).get('showAdvancedSearch')
export const getActiveDarknetFiltersCollapsed = state => getDarknetUi(state).get('activeFiltersCollapsed')
export const getDarknetEditForm = state => getDarknetUi(state).get('editForm')
export const getDarknetLoadingMore = state => getDarknetUi(state).get('loadingMore')

export const getScoreQueryDialogOpen = state => getDarknetUi(state).get('scoreQueryDialogOpen')
export const getSearchQueryDialogOpen = state => getDarknetUi(state).get('searchQueryDialogOpen')

// FILTER
export const getDarknetSearchFilters = state => state.darknet.get('filter')

export const getActiveDarknetFilters = createImmutableSelector(
  getDarknetSearchFilters,
  filters => filters.filter(value => List.isList(value))
)

export const getActiveDarknetNumberFilters = createImmutableSelector(
  getDarknetSearchFilters,
  filters => filters.filter(value => typeof (value) === 'number')
)

// SCORE_QUERY_FORM

export const getDarknetScoreQueryForm = state => state.darknet.get('scoreQueryForm')
export const getDarknetScoreQueryFormData = state => getDarknetScoreQueryForm(state).get('formData')

// SEARCH_QUERY_FORM

export const getDarknetSearchQueryForm = state => state.darknet.get('searchQueryForm')
export const getDarknetSearchQueryFormData = state => getDarknetSearchQueryForm(state).get('formData')
export const getDarknetTestSearchQueryRunning = state => getDarknetSearchQueryForm(state).get('testSearchQueryRunning')
export const getDarknetTestSearchQueryDisabled = state => getDarknetSearchQueryForm(state).get('testSearchQueryDisabled')
export const getDarknetSearchQueryFormTestResult = state => getDarknetSearchQueryForm(state).get('testResult')
