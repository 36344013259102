import { connect } from 'react-redux'

import AppBar from 'components/app_bar'
import { toggleNavigation, logoutRequestStart } from 'actions/app'
import { back } from 'actions/navigation'
import { getI18n, getUser, isBackButtonPage, getAppBarMessage, getWhitelabelTheme } from 'selectors'

const getColor = state => (getWhitelabelTheme(state) === 'plotlights' ? 'inherit' : 'primary')

const mapStateToProps = state => ({
  i18n: getI18n(state),
  user: getUser(state),
  isBackButtonPage: isBackButtonPage(state),
  message: getAppBarMessage(state),
  color: getColor(state)
})

export default connect(
  mapStateToProps,
  {
    onLogout: logoutRequestStart,
    onNavigationToggle: toggleNavigation,
    back
  }
)(AppBar)
