import { fromJS, Map } from 'immutable'

import { getNewsByIds } from 'selectors'
import { listUniqueById } from 'utils/immutable'
import { createImmutableSelector } from 'utils/reselect'

export const getMediaReviews = state => state.mediaReviews

// DATA
export const getMediaReviewsData = state => getMediaReviews(state).get('data')
export const getMediaReviewIds = createImmutableSelector(
  getMediaReviewsData,
  data => data.map(d => d.get('id'))
)
export const getMediaReviewById = (state, id) => getMediaReviewsData(state).find(o => o.get('id') === parseInt(id, 10))
export const getMediaReviewNewsIds = (state, id) => getMediaReviewById(state, id).get('mediaReviewsNews').map(n => n.get('newsId'))

// DETAIL/DATA
export const getMediaReviewDetailData = state => getMediaReviews(state).getIn(['detail', 'data'])
export const getFullTextById = (state, newsId) => (
  getMediaReviewDetailData(state).get('loadedFullTexts').find(t => t.get('newsId') === newsId)
)
export const getMediaReviewGroupedNews = state => getMediaReviewDetailData(state).get('groupedNews')

export const getMediaReviewNews = createImmutableSelector(
  state => getMediaReviewGroupedNews(state).map(g => g.update('news', news => getNewsByIds(state, news))),
  groupedNews => groupedNews
)

export const getMediaReviewNewsUngrouped = state => listUniqueById(getMediaReviewNews(state).flatMap(g => g.get('news')))
export const getMediaReviewSorting = state => (
  getMediaReviewGroupedNews(state).map(g => (fromJS({ news_ids: g.get('news'), code_id: g.getIn(['code', 'id']) })))
)
export const getMediaReviewCodes = createImmutableSelector(
  getMediaReviewNews,
  groupedNews => groupedNews.map(g => g.get('code').set('news', g.get('news')))
)

export const getMediaReviewCodeIds = state => getMediaReviewCodes(state).map(c => c.get('id'))
export const getMediaReviewNewsByCodeId = (state, codeId) => (
  getMediaReviewNews(state).find(g => g.getIn(['code', 'id']) === codeId).get('news')
)
export const getSelectedMediaReviewNewsIds = state => (
  getMediaReviewGroupedNews(state)
    .map(g => g.get('news'))
    .flatten()
    .toSet()
)
export const getSelectedMediaReviewNewsIdsForExport = createImmutableSelector(
  getMediaReviewNewsUngrouped,
  news => (
    news.map(n => n.get('clusteredNews').map(cn => cn.get('id')).unshift(n.get('id')))
  )
)
export const getMediaReviewTotalNewsCount = state => getMediaReviewDetailData(state).get('totalCount')
export const getMediaReviewNewsCount = state => getMediaReviewDetailData(state).get('count')
export const getMediaReviewSummary = state => getMediaReviewDetailData(state).get('summary')
export const getMediaReviewReactions = state => getMediaReviewDetailData(state).get('reactions')

// DETAIL/UI
export const getMediaReviewDetailUi = state => getMediaReviews(state).getIn(['detail', 'ui'])
export const getSelectedMediaReviewNewsItem = state => getMediaReviewDetailUi(state).get('selectedMediaReviewNewsItem')
export const getSelectedMediaReviewId = state => getMediaReviewDetailUi(state).get('selectedMediaReviewId')
export const getSelectedMediaReview = state => getMediaReviewById(state, getSelectedMediaReviewId(state))
export const getShowRemoveNewsDialog = state => getMediaReviewDetailUi(state).get('showRemoveNewsDialog')
export const getMediaReviewDetailRunning = state => getMediaReviewDetailUi(state).get('running')
export const getRemoveMediaReviewNewsDeleting = state => getMediaReviewDetailUi(state).get('deletingNews')
export const isExpanded = (state, topicId) => !getMediaReviewDetailUi(state).get('collapsedTopics').has(topicId)
export const getExpandedNewsIds = state => getMediaReviewDetailUi(state).get('expandedNews')
export const isExpandedMediaReviewNewsAll = (state, newsId) => getMediaReviewDetailUi(state).get('expandedNews').has(newsId)
export const getMediaReviewDetialShowDiffWarning = state => getMediaReviewDetailUi(state).get('showDiffWarning')
export const isExpandedMediaReviewNewsByCode = (state, newsId, codeId) => getMediaReviewDetailUi(state).get('expandedNews')
  .some(expanded => (
    expanded.get('newsId') === newsId && expanded.get('codeId') === codeId
  ))
export const isSummaryExpandedMediaReviewNewsByCode = (state, newsId, codeId) => getMediaReviewDetailUi(state).get('expandedSummaries')
  .some(expanded => (
    expanded.get('newsId') === newsId && expanded.get('codeId') === codeId
  ))
export const isMediaReviewSelectedNewsForSorting = (state, newsId, codeId) => (
  getMediaReviewDetailUi(state).get('selectedNewsForSorting').some(selected => (
    selected.get('newsId') === newsId && selected.get('codeId') === codeId
  ))
)
export const getMediaReviewSelectedNewsForSorting = state => getMediaReviewDetailUi(state).get('selectedNewsForSorting')
export const getMediaReviewCheckMediaReviewReactionsBlocked = state => getMediaReviewDetailUi(state).get('checkMediaReviewReactionsBlocked')

// SELECTED
export const getMediaReviewSelectedNews = state => getMediaReviews(state).getIn(['detail', 'selectedNews'])
export const getMediaReviewSelectedNewsIds = state => getMediaReviewSelectedNews(state).map(n => {
  const a = n.split('_')

  return parseInt(a[a.length - 1], 10)
})

export const isMediaReviewNewsSelected = (state, newsId, codeId) => getMediaReviewSelectedNews(state).includes(`${codeId}_${newsId}`)

export const isMediaReviewGroupedNewsListSelected = (state, id) => (
  getMediaReviewNewsByCodeId(state, id).every(n => getMediaReviewSelectedNews(state).includes(`${id}_${n.get('id')}`))
)

export const getNewsClusterIdsOfAllCodesInMediaReview = state => (
  getMediaReviewGroupedNews(state)
    .map(group => Map({
      codeId: group.getIn(['code', 'id']),
      newsIds: getMediaReviewNewsByCodeId(state, group.getIn(['code', 'id']))
        .map(n => n.get('clusteredNews').map(c => c.get('id')).unshift(n.get('id')))
        .flatten(true)
    }))
)

export const getMediaReviewDetailSnapshot = state => getMediaReviews(state).getIn(['detail', 'snapshot'])

// FILTER
export const getMediaReviewsFilter = state => getMediaReviews(state).get('filter')
export const getMediaReviewsFilterRequestRunning = state => getMediaReviewsFilter(state).get('filterRequestRunning')

// PIN
export const getAutoAddMediaReviewsNews = state => getMediaReviewDetailUi(state).get('autoAddMediaReviewsNews')
export const getShowMediaReviewsRemoveCustomTagDialog = state => getMediaReviewDetailUi(state).get('showRemoveCustomTagDialog')
export const getShowMediaReviewsCustomTagDialog = state => getMediaReviewDetailUi(state).get('showCustomTagDialog')
