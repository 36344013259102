import { connect } from 'react-redux'

import SearchResults from 'components/darknet/dashboard/search_results'

import {
  getDarknetResults,
  getDarknetTotalCount,
  getDarknetLoadingMore,
  getDarkowlSearchQueries
} from 'selectors'

import { loadMoreDarknetEntriesStart } from 'actions/darknet'

const mapStateToProps = state => ({
  searchResults: getDarknetResults(state),
  total: getDarknetTotalCount(state),
  loadingMore: getDarknetLoadingMore(state),
  darkowlSearchQueries: getDarkowlSearchQueries(state)
})

export default connect(
  mapStateToProps,
  {
    onLoadMoreClick: loadMoreDarknetEntriesStart
  }
)(SearchResults)
