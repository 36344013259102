import { connect } from 'react-redux'

import ContentsCampaignsOverview from 'components/content_desk_new/contents_campaigns_overview'
import {
  getCDeskContentsCampaignsTabView
} from 'selectors'

const mapStateToProps = state => ({
  view: getCDeskContentsCampaignsTabView(state)
})

export default connect(
  mapStateToProps,
  {
  }
)(ContentsCampaignsOverview)
