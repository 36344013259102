import { connect } from 'react-redux'

import ImportHelpDialog from 'components/contact_management/contacts/upload_contacts/import_help_dialog/ImportHelpDialog'

import {
  getUser,
  getCmImportHelpDialogOpen
} from 'selectors'

import {
  closeImportHelpDialog,
  downloadContactsExcelStart
} from 'actions/contact_management'

const mapStateToProps = state => ({
  open: getCmImportHelpDialogOpen(state),
  languageId: getUser(state).get('languageId')
})

export default connect(mapStateToProps, {
  onClose: closeImportHelpDialog,
  onDownloadTemplate: downloadContactsExcelStart
})(ImportHelpDialog)
