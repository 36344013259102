const regularExpressions = {
  linkedinUrl: /^(https?:\/\/)?((www|[a-z]{2})\.)?linkedin.com\/[a-zA-Z0-9.?]/,
  twitterUrl: /^(https?:\/\/)?(www\.)?twitter.com\/[a-zA-Z0-9.?]/,
  facebookUrl: /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9.?]/,
  threadsUrl: /^(https?:\/\/)?(www\.)?threads.net\/@[a-zA-Z0-9.?]/,
  tiktokUrl: /^(https?:\/\/)?(www\.)?tiktok.com\/@[a-zA-Z0-9.?]/,
  instagramUrl: /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9.?]/,
  youtubeUrl: /^(https?:\/\/)?(www\.)?youtube.com\/[a-zA-Z0-9.?]/,
  otherUrl: /^(https?:\/\/)?(www\.)?[^.]+.[^.]+/
}

const allowedChannels = Object.keys(regularExpressions)
const filterChannelUrls = data => data.filter((_value, key) => allowedChannels.includes(key))

export const isValidSocialMediaUrl = (key, url) => (url ? regularExpressions[key].test(url) : true)

export const checkAllUrls = d => filterChannelUrls(d).map((value, key) => ({
  key,
  valid: isValidSocialMediaUrl(key, value)
}))
export const getInvalidChannelUrls = d => checkAllUrls(d).filter(e => !e.valid)
