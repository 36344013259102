import { connect } from 'react-redux'

import EditDialog from 'components/saved_searches/edit_dialog'

import {
  saveThemaxSavedSearchStart
} from 'actions/themax'

import {
  hideEditSavedSearchDialog,
  updateSelectedSavedSearch
} from 'actions/saved_searches'

import {
  getI18n,
  getShowEditSavedSearchDialog,
  getSelectedSavedSearch,
  getThemaxSavedSearches as getSavedSearches
} from 'selectors'

const mapStateToProps = state => ({
  i18n: getI18n(state),
  active: getShowEditSavedSearchDialog(state),
  savedSearch: getSelectedSavedSearch(state),
  savedSearches: getSavedSearches(state).get('items'),
  allowedSavedSearchesCount: 0,
  hasLimit: false,
  showChartOptions: false,
  showPhotoUpload: false,
  showAlertOptions: false
})

export default connect(
  mapStateToProps,
  {
    onHide: hideEditSavedSearchDialog,
    onSubmit: saveThemaxSavedSearchStart,
    onChange: updateSelectedSavedSearch,
    onPhotoChange: () => { }
  }
)(EditDialog)
