import { connect } from 'react-redux'

import {
  getCDeskCalendarContents,
  getCDeskRequestRunning,
  getCDeskCalendarDate,
  getCDeskCalendarSelectedDay,
  getCDeskCalendarSelectedCampaignId
} from 'selectors'
import {
  openNewContentDialog,
  fetchCalendarEventsStart,
  openCampaignForm,
  setCalendarDate,
  setCalendarSelectedDay as setSelectedDay,
  openCalendarDialog
} from 'actions/content_desk'

import CalendarWidget from 'components/content_desk_new/calendar_widget'

const mapStateToProps = state => ({
  contents: getCDeskCalendarContents(state),
  calendarDate: getCDeskCalendarDate(state),
  requestRunning: getCDeskRequestRunning(state),
  selectedDay: getCDeskCalendarSelectedDay(state),
  selectedCampaignId: getCDeskCalendarSelectedCampaignId(state)
})

export default connect(
  mapStateToProps,
  {
    onNewContentClick: openNewContentDialog,
    onNewCampaignClick: openCampaignForm,
    fetchContents: fetchCalendarEventsStart,
    setCalendarDate,
    setSelectedDay,
    openCalendarDialog
  }
)(CalendarWidget)
