/* eslint-disable */
import React from 'react'
import useI18n from 'hooks/useI18n'

import {
    REDO_COMMAND,
    UNDO_COMMAND
} from 'lexical'

const UndoRedo = ({ editor, undoDisabled, redoDisabled }) => {
    const i18n = useI18n()

    return (
        <>
            <button
                disabled={undoDisabled}
                onClick={() => {
                    editor.dispatchCommand(UNDO_COMMAND)
                }}
                className="toolbar-item spaced"
                title={i18n.get('undo')}
            >
                <i className="toolbar-icon fal fa-arrow-rotate-left"></i>
            </button>
            <button
                disabled={redoDisabled}
                onClick={() => {
                    editor.dispatchCommand(REDO_COMMAND)
                }}
                className="toolbar-item"
                title={i18n.get('redo')}
            >
                <i className="toolbar-icon fal fa-arrow-rotate-right"></i>
            </button>
        </>
    )
}

export default UndoRedo
