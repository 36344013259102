import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/notifications'
import * as AppActions from 'actions/app'

export const initialState = fromJS({
  notifications: []
})

export default handleActions({
  [Actions.setNotifications]: (state, { payload }) => state.set('notifications', fromJS(payload)),
  [Actions.fetchNotificationsSuccess]: (state, { payload }) => state
    .update('notifications', notifications => notifications.push(...fromJS(payload))),
  [Actions.updateNotifications]: (state, { payload }) => (
    state.updateIn(['notifications'], notifications => (
      notifications.map(notification => {
        const match = payload.find(p => notification.get('id') === p.grootNotificationId)

        if (match) {
          return notification.set('seenAt', match.seenAt)
        }

        return notification
      })
    ))
  ),
  [AppActions.resetState]: () => initialState
}, initialState)
