import { connect } from 'react-redux'

import MailingLists from 'components/administration/mailing_lists'
import { getMailingLists } from 'selectors'

import { showMailingListDialog } from 'actions/mailing_lists'
import { navigate } from 'actions/navigation'

const mapStateToProps = state => ({
  mailingLists: getMailingLists(state).get('data')
})

export default connect(
  mapStateToProps,
  {
    navigate,
    onNewClick: showMailingListDialog
  }
)(MailingLists)
